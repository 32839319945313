/**
 * Created by BFR on 18/02/2020.
 */
define('pimProductAttributesTabController',[
  'module',
  'underscore',
  'app',
  'pimProductAttributesTabView'
], function (
  module,
  _,
  App,
  View
) {

  'use strict';
  var PimProductAttributesTabController = {

    showDetails: function (region, model) {
      this.model = model;
      this.region = region;    
      this.view = new View({model: model});

      region.show(this.view);
    },

    onReload: function () {
      this.showDetails(this.region, this.model);
    }   
  };

  module.exports = PimProductAttributesTabController;
});
