define('ordersListUsersView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'tableFieldView',
  'usersView',
  'template!ordersListUsersView'
], function (
  App,
  Module,  
  _,
  Backbone,
  FieldView,
  UsersView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-users-view ' + FieldView.prototype.className,

    regions: {
      usersRegion: '.users-region'
    },

    modelEvents: {
      'change:userpids': 'renderUsers'
    },

    onRender: function() {
      this.usersView = new UsersView({
        model: new Backbone.Model({userpids: this.model.get('userpids')}),
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.assignUser(user.get('secId')).done(_.bind(function () {
            this.model.fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.unassignUser(user.get('secId')).done(_.bind(function () {
            this.model.fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this)
      });
      this.getRegion('usersRegion').show(this.usersView);
    },

    renderUsers: function () {
      this.usersView.allCollection.reset(this.model.get('userpids'));
      this.usersView.renderContent();
    }
  });
});
