
define('template!orderPicturesTabPictureItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="image-texture-and-background" style="position: relative;">\n  <img class="js-image-holder" src="' +
((__t = ( url )) == null ? '' : __t) +
'"/>\n  <div class="default-icon">\n    ';
 if (defaultVrp) { ;
__p += '\n    <i class="mdi-action-stars"></i>\n    ';
 } ;
__p += '\n    ';
 if (hiddenVrp) { ;
__p += '\n    <i class="mdi-action-highlight-remove"></i>\n    ';
 } ;
__p += '\n    ';
 if (ambiance) { ;
__p += '\n    <i class="mdi-device-now-wallpaper"></i>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';
 if (!defaultVrp) { ;
__p += '\n    <div class="picture-editor">\n        <i class="mdi-action-stars icon js-default" style="' +
((__t = ( defaultVrp || hiddenVrp?'display:none;':'' )) == null ? '' : __t) +
'"></i>\n        <i class="mdi-action-highlight-remove icon js-hidden" style="' +
((__t = ( defaultVrp?'display:none;':'' )) == null ? '' : __t) +
'"></i>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
};});

