define('ordersDetailOrderPrintZonesEditView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'modalsConfirmationView',
  'selectView',
  'template!ordersDetailOrderPrintZonesEditView'
], function(
  App,
  Module,
  Backbone,
  _,
  ModalsConfirmationView,
  SelectView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-detail-order-printzones-edit-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      textarea: 'textarea'
    }, ModalsConfirmationView.prototype.ui),

    regions: _.extend( {
      printzonesRegion: '.printzones-region',
      printTechnicRegion: '.print-technic-region'
    }, ModalsConfirmationView.prototype.regions),

    modelEvents: {
      'changeComposition': 'render'
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
      this.printTechnicCollection = new Backbone.Collection();

      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },

    onRender: function() {
      this.printzonesView = new SelectView({
        collection: this.collection,
        tabindex: 1
      });
      this.getRegion('printzonesRegion').show(this.printzonesView);
      this.printzonesView.on('change', _.bind(function () {
        this.renderPrintTechnics();
      }, this));

      this.renderPrintAreas();

      //----------------
      this.printTechnicView = new SelectView({
        collection: this.printTechnicCollection,
        tabindex: 1
      });
      this.getRegion('printTechnicRegion').show(this.printTechnicView);
      this.renderPrintTechnics();

      if(this.options.printTechnicId)
        this.printTechnicView.setSelected(this.options.printTechnicId + '-' + this.options.nbrColors);
    },

    renderPrintAreas: function() {
      var printAreas = this.fetch();
      this.collection.reset();
      _.each(printAreas, _.bind(function(printArea) {
        var value = printArea.name + ' (' + printArea.width + 'x' + printArea.height + 'mm)';
        this.collection.add({
          id: printArea.id,
          value: value,
          model: printArea
        }, this);
      }, this));
    },

    renderPrintTechnics: function() {
      var printTechnics = this.fetchPrintTechnic();
      this.printTechnicCollection.reset();
      _.each(printTechnics, _.bind(function(printTechnic) {
        var value = '';

        if (printTechnic.colorModeType === 'spot') {
          for (var i = 1 ; i <= printTechnic.maxColors ; i++) {
            value = printTechnic.name + ' ' + i + ' (' + printTechnic.width + 'x' + printTechnic.height + 'mm)';
            if(printTechnic.printerName)
              value += ' (' + _.i18n('printZone.printedBy') + ' ' + printTechnic.printerName + ')';

            this.printTechnicCollection.add({
              id: printTechnic.id + '-' + i,
              value: value,
              nbrColors: i,
              model: printTechnic
            });
          }
        } else {
          value = printTechnic.name + ' (' + printTechnic.width + 'x' + printTechnic.height + 'mm)';
          if(printTechnic.printerName)
            value += ' (' + _.i18n('printZone.printedBy') + ' ' + printTechnic.printerName + ')';

          this.printTechnicCollection.add({
            id: printTechnic.id,
            value: value,
            nbrColors: 1,
            model: printTechnic
          });
        }
      }, this));
    },

    fetch: function () {
      var printAreas = [];
      if (!this.options.printTechnicId) {
        _.each(this.options.product.get('printAreas'), _.bind(function (printArea) {
          var selectedPa = null;
          _.each(this.model.get('compositions'), _.bind(function (composition) {
            if(printArea.id === composition.paId)
              selectedPa = printArea;
          }, this));
          if(!selectedPa)
            printAreas.push(printArea);
        }, this));
      } else {
        _.each(this.options.product.get('printAreas'), _.bind(function (printArea) {
          _.each(printArea.printZones, _.bind(function (printTechnic) {
            if(printTechnic.id === this.options.printTechnicId)
              printAreas.push(printArea);
          }, this));
        }, this));
      }

      return printAreas;
    },

    fetchPrintTechnic: function () {
      var printZones = [];
      if (this.printzonesView.getSelected().get('model').printZones)
        printZones = this.printzonesView.getSelected().get('model').printZones;
      return printZones;
    },

    onConfirm: function() {
      var value = this.printTechnicView.getSelected();
      var nbrColors = value.get('nbrColors');
      var printTechnic = value.get('model');
      this.confirmButtonview.enabled(false);
      this.load(true);

      if(!this.options.printTechnicId) {
        App.request('order:add-printzone',
            this.model.get('purchaseOrderId'),
            printTechnic.id,
            nbrColors).then(_.bind(function () {
          this.model.fetch().done(_.bind(function () {
            ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
          }, this));
        }, this));
      } else {
        App.request('order:update-printzone',
            this.model.get('purchaseOrderId'),
            this.options.printTechnicId,
            printTechnic.id,
            nbrColors).then(_.bind(function () {
          this.model.fetch().done(_.bind(function () {
            ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
          }, this));
        }, this));
      }
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersDetailOrderPrintZonesEditView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          title: data.title,
          width: 600,
          maxHeight: 600
        });
      });
    }
  });
});
