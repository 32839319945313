
define('template!vectorizationItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="col-xs-12">' +
((__t = ( _.i18n(refProcess.status) )) == null ? '' : __t) +
'</label>\n<div class="col-xs-12">\n  ';
 if (refProcess.status === 'common.done') { ;
__p += '\n  <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'" style="width: 100%;"/>\n  ';
 } ;
__p += '\n</div>\n<label style="position: relative; bottom: -16px; left: 12px; font-weight: bold; color: grey;">Config ' +
((__t = ( index
  )) == null ? '' : __t) +
'</label>\n';

}
return __p
};});

