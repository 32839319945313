/**
 * Created by BFR on 10/06/2020.
 */
define('productCustomFieldsTabController',[
  'module',
  'underscore',
  'app',
  'productCustomFieldsTabView',
  'entities/product',
  'entities/product_custom_fields'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';

  var ProductCustomFieldsController = {
    showDetails: function (region, pm) {
      var product = App.request('products:model', {secId: pm.get('secId')});
      this.model = product;
      var productCustomFields = App.request('products-customfields:model', { secId: pm.get('secId') });
      productCustomFields.fetch().done(_.bind(function() {
        this.view = new View({ model: productCustomFields });
        region.show(this.view);
      }, this));
    }
  };

  module.exports = ProductCustomFieldsController;
});

