define('ordersDetailOrderProductOptionView',[
  'module',
  'marionette',
  'colorUtils',
  'template!ordersDetailOrderProductOptionView'
], function (
  Module,
  Marionette,
  ColorUtils,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'order-detail-order-option-view',
    template: Tpl,

    serializeData: function () {
      var data = {};

      data.name = '-';
      if(this.model.get('categoryName'))
        data.name = this.model.get('categoryName');

      data.value = '-';
      if (this.model.get('color1') !== -1) {
        data.value = this.model.get('name');
        data.color = ColorUtils.decimalToHexa(this.model.get('color1'));
      } else {
        data.value = this.model.get('name');
      }

      return data;
    }
  });
});
