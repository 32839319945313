/**
 * Created by BFR on 14/04/2021.
 */
define('adminMain',[
  'module',
  'logger',
  'adminMainRouter'
], function(
  module,
  Logger
) {
	'use strict';

  module.exports =  {
    start: function() {
      Logger.log(module, 'starting adminModule');
    },

    stop: function() {
      Logger.log(module, 'stopping adminModule');
    }
  };

});
