/**
 * Created by BFR on 02/03/2020.
 */
define('filterValueView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!filterValueTpl',
    'colorUtils'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    ColorUtils
  ) {
    'use strict';
  
    var FilterValueView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      events: {
        'change .cbx-filter-value': 'onSelectionChange'
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          this.model.set('selected', true);
        } else {
          this.model.set('selected', false);
        }
      },

      serializeData: function () {
        var templateData = JSON.parse(JSON.stringify(this.options.model.toJSON()));
        templateData.checked = templateData.selected?true:false;
        if (templateData.filter.color > -1) {
          templateData.filter.color = ColorUtils.decimalToHexa(templateData.filter.color);
        } else {
          templateData.filter.color = null;
        }
        return templateData;
      }
    });
  
    module.exports = FilterValueView;
  });
