define('activitiesUtils',[
  'module'
], function (
  module
) {
  'use strict';
  var mainActivity = {};
  mainActivity['00'] = 'Public';
  mainActivity['001'] = 'Associations';
  mainActivity['002'] = 'Particulier';
  mainActivity['003'] = 'Services de secours';
  mainActivity['01'] = 'Culture et production animale, chasse et services annexes';
  mainActivity['02'] = 'Sylviculture et exploitation forestière';
  mainActivity['03'] = 'Pêche et aquaculture';
  mainActivity['05'] = 'Extraction de houille et de lignite';
  mainActivity['06'] = 'Extraction d\'hydrocarbures';
  mainActivity['07'] = 'Extraction de minerais métalliques';
  mainActivity['08'] = 'Autres industries extractives';
  mainActivity['09'] = 'Services de soutien aux industries extractives';
  mainActivity['10'] = 'Industries alimentaires';
  mainActivity['11'] = 'Fabrication de boissons';
  mainActivity['12'] = 'Fabrication de produits à base de tabac';
  mainActivity['13'] = 'Fabrication de textiles';
  mainActivity['14'] = 'Industrie de l\'habillement';
  mainActivity['15'] = 'Industrie du cuir et de la chaussure';
  mainActivity['16'] = 'Trav. bois; fab. art. bois liège (sf mbles), vannerie &amp; sparterie';
  mainActivity['17'] = 'Industrie du papier et du carton';
  mainActivity['18'] = 'Imprimerie et reproduction d\'enregistrements';
  mainActivity['19'] = 'Cokéfaction et raffinage';
  mainActivity['20'] = 'Industrie chimique';
  mainActivity['21'] = 'Industrie pharmaceutique';
  mainActivity['22'] = 'Fabrication de produits en caoutchouc et en plastique';
  mainActivity['23'] = 'Fabrication d\'autres produits minéraux non métalliques';
  mainActivity['24'] = 'Métallurgie';
  mainActivity['25'] = 'Fabric. produits métalliques, sf machines &amp; équipements';
  mainActivity['26'] = 'Fabrication de produits informatiques, électroniques et optiques';
  mainActivity['27'] = 'Fabrication d\'équipements électriques';
  mainActivity['28'] = 'Fabrication de machines et équipements n.c.a.';
  mainActivity['29'] = 'Industrie automobile';
  mainActivity['30'] = 'Fabrication d\'autres matériels de transport';
  mainActivity['31'] = 'Fabrication de meubles';
  mainActivity['32'] = 'Autres industries manufacturières';
  mainActivity['33'] = 'Réparation et installation de machines et d\'équipements';
  mainActivity['35'] = 'Prod. &amp; distribution électricité gaz vapeur &amp; air conditionné';
  mainActivity['36'] = 'Captage, traitement et distribution d\'eau';
  mainActivity['37'] = 'Collecte et traitement des eaux usées';
  mainActivity['38'] = 'Collecte, traitement et élimination des déchets ; récupération';
  mainActivity['39'] = 'Dépollution et autres services de gestion des déchets';
  mainActivity['41'] = 'Construction de bâtiments';
  mainActivity['42'] = 'Génie civil';
  mainActivity['43'] = 'Travaux de construction spécialisés';
  mainActivity['45'] = 'Commerce et réparation d\'automobiles et de motocycles';
  mainActivity['46'] = 'Commerce de gros, à l\'exception des automobiles et des motocycles';
  mainActivity['47'] = 'Comm. détail, sf automobiles et motocycles';
  mainActivity['49'] = 'Transports terrestres et transport par conduites';
  mainActivity['50'] = 'Transports par eau';
  mainActivity['51'] = 'Transports aériens';
  mainActivity['52'] = 'Entreposage et services auxiliaires des transports';
  mainActivity['53'] = 'Activités de poste et de courrier';
  mainActivity['55'] = 'Hébergement';
  mainActivity['56'] = 'Restauration';
  mainActivity['58'] = 'Édition';
  mainActivity['59'] = 'Prod. films cinémat. vidéo &amp; prog.TV; enrg. sonore &amp; éd. musicale';
  mainActivity['60'] = 'Programmation et diffusion';
  mainActivity['61'] = 'Télécommunications';
  mainActivity['62'] = 'Programmation, conseil et autres activités informatiques';
  mainActivity['63'] = 'Services d\'information';
  mainActivity['64'] = 'Activ. services financiers, hors assurance &amp; caisses de retraite';
  mainActivity['65'] = 'Assurance';
  mainActivity['66'] = 'Activités auxiliaires de services financiers et d\'assurance';
  mainActivity['68'] = 'Activités immobilières';
  mainActivity['69'] = 'Activités juridiques et comptables';
  mainActivity['70'] = 'Activités des sièges sociaux ; conseil de gestion';
  mainActivity['71'] = 'Activ.  architecture &amp; ingénierie; contrôle &amp; analyses techniques';
  mainActivity['72'] = 'Recherche-développement scientifique';
  mainActivity['73'] = 'Publicité et études de marché';
  mainActivity['74'] = 'Autres activités spécialisées, scientifiques et techniques';
  mainActivity['75'] = 'Activités vétérinaires';
  mainActivity['77'] = 'Activités de location et location-bail';
  mainActivity['78'] = 'Activités liées à l\'emploi';
  mainActivity['79'] = 'Activ. agences voyage, voyagistes, serv. résa. &amp; activ. connexes';
  mainActivity['80'] = 'Enquêtes et sécurité';
  mainActivity['81'] = 'Services relatifs aux bâtiments et aménagement paysager';
  mainActivity['82'] = 'Activ. administratives &amp; autres activ. soutien aux entreprises';
  mainActivity['84'] = 'Administration publique et défense ; sécurité sociale obligatoire';
  mainActivity['85'] = 'Enseignement';
  mainActivity['86'] = 'Activités pour la santé humaine';
  mainActivity['87'] = 'Hébergement médico-social et social';
  mainActivity['88'] = 'Action sociale sans hébergement';
  mainActivity['90'] = 'Activités créatives, artistiques et de spectacle';
  mainActivity['91'] = 'Bibliothèques, archives, musées et autres activités culturelles';
  mainActivity['92'] = 'Organisation de jeux de hasard et d\'argent';
  mainActivity['93'] = 'Activités sportives, récréatives et de loisirs';
  mainActivity['94'] = 'Activités des organisations associatives';
  mainActivity['95'] = 'Réparation d\'ordinateurs et de biens personnels et domestiques';
  mainActivity['96'] = 'Autres services personnels';
  mainActivity['97'] = 'Activ. ménages : employeurs de personnel domestique';
  mainActivity['98'] = 'Activ. ménages : prod. biens &amp; services (usage propre)';
  mainActivity['99'] = 'Activités des organisations et organismes extraterritoriaux';

  var subActivity = {};
  subActivity['00.01Z'] = 'Administration';
  subActivity['00.02Z'] = 'CPAS';
  subActivity['00.03Z'] = 'Villes / Mairies';
  subActivity['001.01Z'] = 'Clubs sportifs';
  subActivity['001.02Z'] = 'Associations diverses';
  subActivity['001.03Z'] = 'Associations d\'étudiants';
  subActivity['002.01Z'] = 'Particulier';
  subActivity['003.01Z'] = 'Police';
  subActivity['003.02Z'] = 'Pompiers';
  subActivity['01.11Z'] = 'Culture de céréales (sf riz) légumineuses, graines oléagineuses';
  subActivity['01.12Z'] = 'Culture du riz';
  subActivity['01.13Z'] = 'Culture de légumes, de melons, de racines et de tubercules';
  subActivity['01.14Z'] = 'Culture de la canne à sucre';
  subActivity['01.15Z'] = 'Culture du tabac';
  subActivity['01.16Z'] = 'Culture de plantes à fibres';
  subActivity['01.19Z'] = 'Autres cultures non permanentes';
  subActivity['01.21Z'] = 'Culture de la vigne';
  subActivity['01.22Z'] = 'Culture de fruits tropicaux et subtropicaux';
  subActivity['01.23Z'] = 'Culture d\'agrumes';
  subActivity['01.24Z'] = 'Culture de fruits à pépins et à noyau';
  subActivity['01.25Z'] = 'Culture d\'aut. fruits d\'arbres ou d\'arbustes et de fruits a coque';
  subActivity['01.26Z'] = 'Culture de fruits oléagineux';
  subActivity['01.27Z'] = 'Culture de plantes à boissons';
  subActivity['01.28Z'] = 'Culture plantes à épices aromatiques médicinales pharmaceutiques';
  subActivity['01.29Z'] = 'Autres cultures permanentes';
  subActivity['01.30Z'] = 'Reproduction de plantes';
  subActivity['01.41Z'] = 'Élevage de vaches laitières';
  subActivity['01.42Z'] = 'Élevage d\'autres bovins et de buffles';
  subActivity['01.43Z'] = 'Élevage de chevaux et d\'autres équidés';
  subActivity['01.44Z'] = 'Élevage de chameaux et d\'autres camélidés';
  subActivity['01.45Z'] = 'Élevage d\'ovins et de caprins';
  subActivity['01.46Z'] = 'Élevage de porcins';
  subActivity['01.47Z'] = 'Élevage de volailles';
  subActivity['01.49Z'] = 'Élevage d\'autres animaux';
  subActivity['01.50Z'] = 'Culture et élevage associés';
  subActivity['01.61Z'] = 'Activités de soutien aux cultures';
  subActivity['01.62Z'] = 'Activités de soutien à la production animale';
  subActivity['01.63Z'] = 'Traitement primaire des récoltes';
  subActivity['01.64Z'] = 'Traitement des semences';
  subActivity['01.70Z'] = 'Chasse, piégeage et services annexes';
  subActivity['02.10Z'] = 'Sylviculture et autres activités forestières';
  subActivity['02.20Z'] = 'Exploitation forestière';
  subActivity['02.30Z'] = 'Récolte prod. forestiers non ligneux poussant à l\'état sauvage';
  subActivity['02.40Z'] = 'Services de soutien à l\'exploitation forestière';
  subActivity['03.11Z'] = 'Pêche en mer';
  subActivity['03.12Z'] = 'Pêche en eau douce';
  subActivity['03.21Z'] = 'Aquaculture en mer';
  subActivity['03.22Z'] = 'Aquaculture en eau douce';
  subActivity['05.10Z'] = 'Extraction de houille';
  subActivity['05.20Z'] = 'Extraction de lignite';
  subActivity['06.10Z'] = 'Extraction de pétrole brut';
  subActivity['06.20Z'] = 'Extraction de gaz naturel';
  subActivity['07.10Z'] = 'Extraction de minerais de fer';
  subActivity['07.21Z'] = 'Extraction de minerais d\'uranium et de thorium';
  subActivity['07.29Z'] = 'Extraction d\'autres minerais de métaux non ferreux';
  subActivity['08.11Z'] = 'Extraction pierres ornement. construc. calcaire industriel, gypse';
  subActivity['08.12Z'] = 'Exploit gravieres &amp; sablieres, extraction argiles &amp; kaolin';
  subActivity['08.91Z'] = 'Extraction des minéraux chimiques et d\'engrais minéraux';
  subActivity['08.92Z'] = 'Extraction de tourbe';
  subActivity['08.93Z'] = 'Production de sel';
  subActivity['08.99Z'] = 'Autres activités extractives n.c.a.';
  subActivity['09.10Z'] = 'Activités de soutien à l\'extraction d\'hydrocarbures';
  subActivity['09.90Z'] = 'Activités de soutien aux autres industries extractives';
  subActivity['10.11Z'] = 'Transformation et conservation de la viande de boucherie';
  subActivity['10.12Z'] = 'Transformation et conservation de la viande de volaille';
  subActivity['10.13A'] = 'Préparation industrielle de produits à base de viande';
  subActivity['10.13B'] = 'Charcuterie';
  subActivity['10.20Z'] = 'Transform. &amp; conserv. poisson, crustacés &amp; mollusques';
  subActivity['10.31Z'] = 'Transformation et conservation de pommes de terre';
  subActivity['10.32Z'] = 'Préparation de jus de fruits et légumes';
  subActivity['10.39A'] = 'Autre transformation et conservation de légumes';
  subActivity['10.39B'] = 'Transformation et conservation de fruits';
  subActivity['10.41A'] = 'Fabrication d\'huiles et graisses brutes';
  subActivity['10.41B'] = 'Fabrication d\'huiles et graisses raffinées';
  subActivity['10.42Z'] = 'Fabrication de margarine et graisses comestibles similaires';
  subActivity['10.51A'] = 'Fabrication de lait liquide et de produits frais';
  subActivity['10.51B'] = 'Fabrication de beurre';
  subActivity['10.51C'] = 'Fabrication de fromage';
  subActivity['10.51D'] = 'Fabrication d\'autres produits laitiers';
  subActivity['10.52Z'] = 'Fabrication de glaces et sorbets';
  subActivity['10.61A'] = 'Meunerie';
  subActivity['10.61B'] = 'Autres activités du travail des grains';
  subActivity['10.62Z'] = 'Fabrication de produits amylacés';
  subActivity['10.71A'] = 'Fabrication industrielle de pain et de pâtisserie fraîche';
  subActivity['10.71B'] = 'Cuisson de produits de boulangerie';
  subActivity['10.71C'] = 'Boulangerie et boulangerie-pâtisserie';
  subActivity['10.71D'] = 'Pâtisserie';
  subActivity['10.72Z'] = 'Fabrication de biscuits, biscottes et pâtisseries de conservation';
  subActivity['10.73Z'] = 'Fabrication de pâtes alimentaires';
  subActivity['10.81Z'] = 'Fabrication de sucre';
  subActivity['10.82Z'] = 'Fabrication de cacao, chocolat et de produits de confiserie';
  subActivity['10.83Z'] = 'Transformation du thé et du café';
  subActivity['10.84Z'] = 'Fabrication de condiments et assaisonnements';
  subActivity['10.85Z'] = 'Fabrication de plats préparés';
  subActivity['10.86Z'] = 'Fabrication d\'aliments homogénéisés et diététiques';
  subActivity['10.89Z'] = 'Fabrication d\'autres produits alimentaires n.c.a.';
  subActivity['10.91Z'] = 'Fabrication d\'aliments pour animaux de ferme';
  subActivity['10.92Z'] = 'Fabrication d\'aliments pour animaux de compagnie';
  subActivity['11.01Z'] = 'Production de boissons alcooliques distillées';
  subActivity['11.02A'] = 'Fabrication de vins effervescents';
  subActivity['11.02B'] = 'Vinification';
  subActivity['11.03Z'] = 'Fabrication de cidre et de vins de fruits';
  subActivity['11.04Z'] = 'Production d\'autres boissons fermentées non distillées';
  subActivity['11.05Z'] = 'Fabrication de bière';
  subActivity['11.06Z'] = 'Fabrication de malt';
  subActivity['11.07A'] = 'Industrie des eaux de table';
  subActivity['11.07B'] = 'Production de boissons rafraîchissantes';
  subActivity['12.00Z'] = 'Fabrication de produits à base de tabac';
  subActivity['13.10Z'] = 'Préparation de fibres textiles et filature';
  subActivity['13.20Z'] = 'Tissage';
  subActivity['13.30Z'] = 'Ennoblissement textile';
  subActivity['13.91Z'] = 'Fabrication d\'étoffes à mailles';
  subActivity['13.92Z'] = 'Fabrication d\'articles textiles, sauf habillement';
  subActivity['13.93Z'] = 'Fabrication de tapis et moquettes';
  subActivity['13.94Z'] = 'Fabrication de ficelles, cordes et filets';
  subActivity['13.95Z'] = 'Fabrication de non-tissés, sauf habillement';
  subActivity['13.96Z'] = 'Fabrication d\'autres textiles techniques et industriels';
  subActivity['13.99Z'] = 'Fabrication d\'autres textiles n.c.a.';
  subActivity['14.11Z'] = 'Fabrication de vêtements en cuir';
  subActivity['14.12Z'] = 'Fabrication de vêtements de travail';
  subActivity['14.13Z'] = 'Fabrication de vêtements de dessus';
  subActivity['14.14Z'] = 'Fabrication de vêtements de dessous';
  subActivity['14.19Z'] = 'Fabrication d\'autres vêtements et accessoires';
  subActivity['14.20Z'] = 'Fabrication d\'articles en fourrure';
  subActivity['14.31Z'] = 'Fabrication d\'articles chaussants à mailles';
  subActivity['14.39Z'] = 'Fabrication d\'autres articles à mailles';
  subActivity['15.11Z'] = 'Apprêt, tannage des cuirs; préparation et teinture des fourrures';
  subActivity['15.12Z'] = 'Fabrication d\'articles de voyage, de maroquinerie et de sellerie';
  subActivity['15.20Z'] = 'Fabrication de chaussures';
  subActivity['16.10A'] = 'Sciage et rabotage du bois, hors imprégnation';
  subActivity['16.10B'] = 'Imprégnation du bois';
  subActivity['16.21Z'] = 'Fabrication de placage et de panneaux de bois';
  subActivity['16.22Z'] = 'Fabrication de parquets assemblés';
  subActivity['16.23Z'] = 'Fabrication de charpentes et d\'autres menuiseries';
  subActivity['16.24Z'] = 'Fabrication d\'emballages en bois';
  subActivity['16.29Z'] = 'Fabrication objets divers en bois, liège, vannerie et sparterie';
  subActivity['17.11Z'] = 'Fabrication de pâte à papier';
  subActivity['17.12Z'] = 'Fabrication de papier et de carton';
  subActivity['17.21A'] = 'Fabrication de carton ondulé';
  subActivity['17.21B'] = 'Fabrication de cartonnages';
  subActivity['17.21C'] = 'Fabrication d\'emballages en papier';
  subActivity['17.22Z'] = 'Fabrication d\'articles en papier à usage sanitaire ou domestique';
  subActivity['17.23Z'] = 'Fabrication d\'articles de papeterie';
  subActivity['17.24Z'] = 'Fabrication de papiers peints';
  subActivity['17.29Z'] = 'Fabrication d\'autres articles en papier ou en carton';
  subActivity['18.11Z'] = 'Imprimerie de journaux';
  subActivity['18.12Z'] = 'Autre imprimerie (labeur)';
  subActivity['18.13Z'] = 'Activités de pré-presse';
  subActivity['18.14Z'] = 'Reliure et activités connexes';
  subActivity['18.20Z'] = 'Reproduction d\'enregistrements';
  subActivity['19.10Z'] = 'Cokéfaction';
  subActivity['19.20Z'] = 'Raffinage du pétrole';
  subActivity['20.11Z'] = 'Fabrication de gaz industriels';
  subActivity['20.12Z'] = 'Fabrication de colorants et de pigments';
  subActivity['20.13A'] = 'Enrichissement et retraitement de matières nucléaires';
  subActivity['20.13B'] = 'Fabric. d\'autres produits chimiques inorganiques de base n.c.a.';
  subActivity['20.14Z'] = 'Fabrication d\'autres produits chimiques organiques de base';
  subActivity['20.15Z'] = 'Fabrication de produits azotés et d\'engrais';
  subActivity['20.16Z'] = 'Fabrication de matières plastiques de base';
  subActivity['20.17Z'] = 'Fabrication de caoutchouc synthétique';
  subActivity['20.20Z'] = 'Fabrication de pesticides et d\'autres produits agrochimiques';
  subActivity['20.30Z'] = 'Fabrication de peintures, vernis, encres et mastics';
  subActivity['20.41Z'] = 'Fabrication de savons, détergents et produits d\'entretien';
  subActivity['20.42Z'] = 'Fabrication de parfums et de produits pour la toilette';
  subActivity['20.51Z'] = 'Fabrication de produits explosifs';
  subActivity['20.52Z'] = 'Fabrication de colles';
  subActivity['20.53Z'] = 'Fabrication d\'huiles essentielles';
  subActivity['20.59Z'] = 'Fabrication d\'autres produits chimiques n.c.a.';
  subActivity['20.60Z'] = 'Fabrication de fibres artificielles ou synthétiques';
  subActivity['21.10Z'] = 'Fabrication de produits pharmaceutiques de base';
  subActivity['21.20Z'] = 'Fabrication de préparations pharmaceutiques';
  subActivity['22.11Z'] = 'Fabrication et rechapage de pneumatiques';
  subActivity['22.19Z'] = 'Fabrication d\'autres articles en caoutchouc';
  subActivity['22.21Z'] = 'Fabrication plaques, feuilles, tubes et profilés en plastique';
  subActivity['22.22Z'] = 'Fabrication d\'emballages en matières plastiques';
  subActivity['22.23Z'] = 'Fabrication d\'éléments matières plastiques pour la construction';
  subActivity['22.29A'] = 'Fabrication de pièces techniques à base de matières plastiques';
  subActivity['22.29B'] = 'Fabrication produits de consommation courante en plastique';
  subActivity['23.11Z'] = 'Fabrication de verre plat';
  subActivity['23.12Z'] = 'Façonnage et transformation du verre plat';
  subActivity['23.13Z'] = 'Fabrication de verre creux';
  subActivity['23.14Z'] = 'Fabrication de fibres de verre';
  subActivity['23.19Z'] = 'Fabrication &amp; façonnage autres articles verre yc verre technique';
  subActivity['23.20Z'] = 'Fabrication de produits réfractaires';
  subActivity['23.31Z'] = 'Fabrication de carreaux en céramique';
  subActivity['23.32Z'] = 'Fabrication briques tuiles &amp; prod. de construction en terre cuite';
  subActivity['23.41Z'] = 'Fabrication d\'articles céramiques à usage domestique, ornemental';
  subActivity['23.42Z'] = 'Fabrication d\'appareils sanitaires en céramique';
  subActivity['23.43Z'] = 'Fabrication d\'isolateurs et pièces isolantes en céramique';
  subActivity['23.44Z'] = 'Fabrication d\'autres produits céramiques à usage technique';
  subActivity['23.49Z'] = 'Fabrication d\'autres produits céramiques';
  subActivity['23.51Z'] = 'Fabrication de ciment';
  subActivity['23.52Z'] = 'Fabrication de chaux et plâtre';
  subActivity['23.61Z'] = 'Fabrication d\'éléments en béton pour la construction';
  subActivity['23.62Z'] = 'Fabrication d\'éléments en plâtre pour la construction';
  subActivity['23.63Z'] = 'Fabrication de béton prêt à l\'emploi';
  subActivity['23.64Z'] = 'Fabrication de mortiers et bétons secs';
  subActivity['23.65Z'] = 'Fabrication d\'ouvrages en fibre-ciment';
  subActivity['23.69Z'] = 'Fabrication d\'autres ouvrages en béton, en ciment ou en plâtre';
  subActivity['23.70Z'] = 'Taille, façonnage et finissage de pierres';
  subActivity['23.91Z'] = 'Fabrication de produits abrasifs';
  subActivity['23.99Z'] = 'Fabrication d\'autres produits minéraux non métalliques n.c.a.';
  subActivity['24.10Z'] = 'Sidérurgie';
  subActivity['24.20Z'] = 'Fabric. tubes, tuyaux, profilés creux &amp; accessoir. corresp. acier';
  subActivity['24.31Z'] = 'Étirage à froid de barres';
  subActivity['24.32Z'] = 'Laminage à froid de feuillards';
  subActivity['24.33Z'] = 'Profilage à froid par formage ou pliage';
  subActivity['24.34Z'] = 'Tréfilage à froid';
  subActivity['24.41Z'] = 'Production de métaux précieux';
  subActivity['24.42Z'] = 'Métallurgie de l\'aluminium';
  subActivity['24.43Z'] = 'Métallurgie du plomb, du zinc ou de l\'étain';
  subActivity['24.44Z'] = 'Métallurgie du cuivre';
  subActivity['24.45Z'] = 'Métallurgie des autres métaux non ferreux';
  subActivity['24.46Z'] = 'Élaboration et transformation de matières nucléaires';
  subActivity['24.51Z'] = 'Fonderie de fonte';
  subActivity['24.52Z'] = 'Fonderie d\'acier';
  subActivity['24.53Z'] = 'Fonderie de métaux légers';
  subActivity['24.54Z'] = 'Fonderie d\'autres métaux non ferreux';
  subActivity['25.11Z'] = 'Fabrication de structures métalliques et de parties de structures';
  subActivity['25.12Z'] = 'Fabrication de portes et fenêtres en métal';
  subActivity['25.21Z'] = 'Fabrication radiateurs et chaudières pour le chauffage central';
  subActivity['25.29Z'] = 'Fabrication d\'autres réservoirs, citernes, conteneurs métalliques';
  subActivity['25.30Z'] = 'Fabric. générateurs vapeur sf chaudières pour chauffage central';
  subActivity['25.40Z'] = 'Fabrication d\'armes et de munitions';
  subActivity['25.50A'] = 'Forge, estampage, matriçage; métallurgie des poudres';
  subActivity['25.50B'] = 'Découpage, emboutissage';
  subActivity['25.61Z'] = 'Traitement et revêtement des métaux';
  subActivity['25.62A'] = 'Décolletage';
  subActivity['25.62B'] = 'Mécanique industrielle';
  subActivity['25.71Z'] = 'Fabrication de coutellerie';
  subActivity['25.72Z'] = 'Fabrication de serrures et de ferrures';
  subActivity['25.73A'] = 'Fabrication de moules et modèles';
  subActivity['25.73B'] = 'Fabrication d\'autres outillages';
  subActivity['25.91Z'] = 'Fabrication de fûts et emballages métalliques similaires';
  subActivity['25.92Z'] = 'Fabrication d\'emballages métalliques légers';
  subActivity['25.93Z'] = 'Fabrication d\'articles en fils métalliques, chaînes et ressorts';
  subActivity['25.94Z'] = 'Fabrication de vis et de boulons';
  subActivity['25.99A'] = 'Fabrication d\'articles métalliques ménagers';
  subActivity['25.99B'] = 'Fabrication d\'autres articles métalliques';
  subActivity['26.11Z'] = 'Fabrication de composants électroniques';
  subActivity['26.12Z'] = 'Fabrication de cartes électroniques assemblées';
  subActivity['26.20Z'] = 'Fabrication d\'ordinateurs et d\'équipements périphériques';
  subActivity['26.30Z'] = 'Fabrication d\'équipements de communication';
  subActivity['26.40Z'] = 'Fabrication de produits électroniques grand public';
  subActivity['26.51A'] = 'Fabrication d\'équipements d\'aide à la navigation';
  subActivity['26.51B'] = 'Fabrication d\'instrumentation scientifique et technique';
  subActivity['26.52Z'] = 'Horlogerie';
  subActivity['26.60Z'] = 'Fab. éqpts d\'irradiation médic. électromédic. &amp; électrothérapeut.';
  subActivity['26.70Z'] = 'Fabrication de matériels optique et photographique';
  subActivity['26.80Z'] = 'Fabrication de supports magnétiques et optiques';
  subActivity['27.11Z'] = 'Fabrication de moteurs, génératrices, transformateurs électriques';
  subActivity['27.12Z'] = 'Fabrication de matériel de distribution et de commande électrique';
  subActivity['27.20Z'] = 'Fabrication de piles et d\'accumulateurs électriques';
  subActivity['27.31Z'] = 'Fabrication de câbles de fibres optiques';
  subActivity['27.32Z'] = 'Fabrication d\'autres fils et câbles électroniques ou électriques';
  subActivity['27.33Z'] = 'Fabrication de matériel d\'installation électrique';
  subActivity['27.40Z'] = 'Fabrication d\'appareils d\'éclairage électrique';
  subActivity['27.51Z'] = 'Fabrication d\'appareils électroménagers';
  subActivity['27.52Z'] = 'Fabrication d\'appareils ménagers non électriques';
  subActivity['27.90Z'] = 'Fabrication d\'autres matériels électriques';
  subActivity['28.11Z'] = 'Fabrication moteurs &amp; turbines sf moteurs d\'avions &amp; de véhicules';
  subActivity['28.12Z'] = 'Fabrication d\'équipements hydrauliques et pneumatiques';
  subActivity['28.13Z'] = 'Fabrication d\'autres pompes et compresseurs';
  subActivity['28.14Z'] = 'Fabrication d\'autres articles de robinetterie';
  subActivity['28.15Z'] = 'Fabrication d\'engrenages et d\'organes mécaniques de transmission';
  subActivity['28.21Z'] = 'Fabrication de fours et brûleurs';
  subActivity['28.22Z'] = 'Fabrication de matériel de levage et de manutention';
  subActivity['28.23Z'] = 'Fab. machines équipts bureau (sf ordinateurs &amp; équipts périph.)';
  subActivity['28.24Z'] = 'Fabrication d\'outillage portatif à moteur incorporé';
  subActivity['28.25Z'] = 'Fabrication équipements aérauliques et frigorifiques industriels';
  subActivity['28.29A'] = 'Fabrication équipts emballage, conditionnement &amp; pesage';
  subActivity['28.29B'] = 'Fabrication d\'autres machines d\'usage général';
  subActivity['28.30Z'] = 'Fabrication de machines agricoles et forestières';
  subActivity['28.41Z'] = 'Fabrication de machines-outils pour le travail des métaux';
  subActivity['28.49Z'] = 'Fabrication d\'autres machines-outils';
  subActivity['28.91Z'] = 'Fabrication de machines pour la métallurgie';
  subActivity['28.92Z'] = 'Fabrication de machines pour l\'extraction ou la construction';
  subActivity['28.93Z'] = 'Fabrication de machines pour l\'industrie agro-alimentaire';
  subActivity['28.94Z'] = 'Fabrication de machines pour les industries textiles';
  subActivity['28.95Z'] = 'Fabrication de machines pour les industries du papier et carton';
  subActivity['28.96Z'] = 'Fabric. machines pour le travail du caoutchouc ou des plastiques';
  subActivity['28.99A'] = 'Fabrication de machines d\'imprimerie';
  subActivity['28.99B'] = 'Fabrication d\'autres machines spécialisées';
  subActivity['29.10Z'] = 'Construction de véhicules automobiles';
  subActivity['29.20Z'] = 'Fabrication de carrosseries et remorques';
  subActivity['29.31Z'] = 'Fabrication équipements électriques et électroniques automobiles';
  subActivity['29.32Z'] = 'Fabrication d\'autres équipements automobiles';
  subActivity['30.11Z'] = 'Construction de navires et de structures flottantes';
  subActivity['30.12Z'] = 'Construction de bateaux de plaisance';
  subActivity['30.20Z'] = 'Construction locomotives &amp; autre matériel ferroviaire roulant';
  subActivity['30.30Z'] = 'Construction aéronautique et spatiale';
  subActivity['30.40Z'] = 'Construction de véhicules militaires de combat';
  subActivity['30.91Z'] = 'Fabrication de motocycles';
  subActivity['30.92Z'] = 'Fabrication de bicyclettes et de véhicules pour invalides';
  subActivity['30.99Z'] = 'Fabrication d\'autres équipements de transport n.c.a.';
  subActivity['31.01Z'] = 'Fabrication de meubles de bureau et de magasin';
  subActivity['31.02Z'] = 'Fabrication de meubles de cuisine';
  subActivity['31.03Z'] = 'Fabrication de matelas';
  subActivity['31.09A'] = 'Fabrication de sièges d\'ameublement d\'intérieur';
  subActivity['31.09B'] = 'Fabrication autres meubles &amp; industries connexes de l\'ameublement';
  subActivity['32.11Z'] = 'Frappe de monnaie';
  subActivity['32.12Z'] = 'Fabrication d\'articles de joaillerie et bijouterie';
  subActivity['32.13Z'] = 'Fabrication articles bijouterie fantaisie &amp; articles similaires';
  subActivity['32.20Z'] = 'Fabrication d\'instruments de musique';
  subActivity['32.30Z'] = 'Fabrication d\'articles de sport';
  subActivity['32.40Z'] = 'Fabrication de jeux et jouets';
  subActivity['32.50A'] = 'Fabrication de matériel médico-chirurgical et dentaire';
  subActivity['32.50B'] = 'Fabrication de lunettes';
  subActivity['32.91Z'] = 'Fabrication d\'articles de brosserie';
  subActivity['32.99Z'] = 'Autres activités manufacturières n.c.a.';
  subActivity['33.11Z'] = 'Réparation d\'ouvrages en métaux';
  subActivity['33.12Z'] = 'Réparation de machines et équipements mécaniques';
  subActivity['33.13Z'] = 'Réparation de matériels électroniques et optiques';
  subActivity['33.14Z'] = 'Réparation d\'équipements électriques';
  subActivity['33.15Z'] = 'Réparation et maintenance navale';
  subActivity['33.16Z'] = 'Réparation et maintenance d\'aéronefs et d\'engins spatiaux';
  subActivity['33.17Z'] = 'Réparation et maintenance d\'autres équipements de transport';
  subActivity['33.19Z'] = 'Réparation d\'autres équipements';
  subActivity['33.20A'] = 'Installation structures métalliques, chaudronnées et tuyauterie';
  subActivity['33.20B'] = 'Installation de machines et équipements mécaniques';
  subActivity['33.20C'] = 'Concept. d\'ens. &amp; assembl s/site d\'éqpts ctrle des processus ind.';
  subActivity['33.20D'] = 'Instal. éqpts électriq, mat. électro. et optiq. ou aut. matériels';
  subActivity['35.11Z'] = 'Production d\'électricité';
  subActivity['35.12Z'] = 'Transport d\'électricité';
  subActivity['35.13Z'] = 'Distribution d\'électricité';
  subActivity['35.14Z'] = 'Commerce d\'électricité';
  subActivity['35.21Z'] = 'Production de combustibles gazeux';
  subActivity['35.22Z'] = 'Distribution de combustibles gazeux par conduites';
  subActivity['35.23Z'] = 'Commerce de combustibles gazeux par conduites';
  subActivity['35.30Z'] = 'Production et distribution de vapeur et d\'air conditionné';
  subActivity['36.00Z'] = 'Captage, traitement et distribution d\'eau';
  subActivity['37.00Z'] = 'Collecte et traitement des eaux usées';
  subActivity['38.11Z'] = 'Collecte des déchets non dangereux';
  subActivity['38.12Z'] = 'Collecte des déchets dangereux';
  subActivity['38.21Z'] = 'Traitement et élimination des déchets non dangereux';
  subActivity['38.22Z'] = 'Traitement et élimination des déchets dangereux';
  subActivity['38.31Z'] = 'Démantèlement d\'épaves';
  subActivity['38.32Z'] = 'Récupération de déchets triés';
  subActivity['39.00Z'] = 'Dépollution et autres services de gestion des déchets';
  subActivity['41.10A'] = 'Promotion immobilière de logements';
  subActivity['41.10B'] = 'Promotion immobilière de bureaux';
  subActivity['41.10C'] = 'Promotion immobilière d\'autres bâtiments';
  subActivity['41.10D'] = 'Supports juridiques de programmes';
  subActivity['41.20A'] = 'Construction de maisons individuelles';
  subActivity['41.20B'] = 'Construction d\'autres bâtiments';
  subActivity['42.11Z'] = 'Construction de routes et autoroutes';
  subActivity['42.12Z'] = 'Construction de voies ferrées de surface et souterraines';
  subActivity['42.13A'] = 'Construction d\'ouvrages d\'art';
  subActivity['42.13B'] = 'Construction et entretien de tunnels';
  subActivity['42.21Z'] = 'Construction de réseaux pour fluides';
  subActivity['42.22Z'] = 'Construction de réseaux électriques et de télécommunications';
  subActivity['42.91Z'] = 'Construction d\'ouvrages maritimes et fluviaux';
  subActivity['42.99Z'] = 'Construction d\'autres ouvrages de génie civil n.c.a.';
  subActivity['43.11Z'] = 'Travaux de démolition';
  subActivity['43.12A'] = 'Travaux de terrassement courants et travaux préparatoires';
  subActivity['43.12B'] = 'Travaux de terrassement spécialisés ou de grande masse';
  subActivity['43.13Z'] = 'Forages et sondages';
  subActivity['43.21A'] = 'Travaux d\'installation électrique dans tous locaux';
  subActivity['43.21B'] = 'Travaux d\'installation électrique sur la voie publique';
  subActivity['43.22A'] = 'Travaux d\'installation d\'eau et de gaz en tous locaux';
  subActivity['43.22B'] = 'Travaux d\'installation équipements thermiques et climatisation';
  subActivity['43.29A'] = 'Travaux d\'isolation';
  subActivity['43.29B'] = 'Autres travaux d\'installation n.c.a.';
  subActivity['43.31Z'] = 'Travaux de plâtrerie';
  subActivity['43.32A'] = 'Travaux de menuiserie bois et PVC';
  subActivity['43.32B'] = 'Travaux de menuiserie métallique et serrurerie';
  subActivity['43.32C'] = 'Agencement de lieux de vente';
  subActivity['43.33Z'] = 'Travaux de revêtement des sols et des murs';
  subActivity['43.34Z'] = 'Travaux de peinture et vitrerie';
  subActivity['43.39Z'] = 'Autres travaux de finition';
  subActivity['43.91A'] = 'Travaux de charpente';
  subActivity['43.91B'] = 'Travaux de couverture par éléments';
  subActivity['43.99A'] = 'Travaux d\'étanchéification';
  subActivity['43.99B'] = 'Travaux de montage de structures métalliques';
  subActivity['43.99C'] = 'Travaux de maçonnerie générale et gros oeuvre de bâtiment';
  subActivity['43.99D'] = 'Autres travaux spécialisés de construction';
  subActivity['43.99E'] = 'Location avec opérateur de matériel de construction';
  subActivity['45.11Z'] = 'Commerce de voitures et de véhicules automobiles légers';
  subActivity['45.19Z'] = 'Commerce d\'autres véhicules automobiles';
  subActivity['45.20A'] = 'Entretien et réparation de véhicules automobiles légers';
  subActivity['45.20B'] = 'Entretien et réparation d\'autres véhicules automobiles';
  subActivity['45.31Z'] = 'Commerce de gros d\'équipements automobiles';
  subActivity['45.32Z'] = 'Commerce de détail d\'équipements automobiles';
  subActivity['45.40Z'] = 'Commerce et réparation de motocycles';
  subActivity['46.11Z'] = 'Interm. du comm. en m.p. agricoles &amp; textiles, animaux vivants';
  subActivity['46.12A'] = 'Centrales d\'achat de carburant';
  subActivity['46.12B'] = 'Autres interm. comm. combustibles métaux minéraux prod. chimiques';
  subActivity['46.13Z'] = 'Intermédiaires du commerce en bois et matériaux de construction';
  subActivity['46.14Z'] = 'Interm. comm. machines, équipts industriels, navires et avions';
  subActivity['46.15Z'] = 'Interm. comm. en meubles, articles de ménage et quincaillerie';
  subActivity['46.16Z'] = 'Interm. comm. textiles, habillt, fourrures, chaussures &amp; art cuir';
  subActivity['46.17A'] = 'Centrales d\'achat alimentaires';
  subActivity['46.17B'] = 'Autres intermédiaires du commerce en denrées, boissons et tabac';
  subActivity['46.18Z'] = 'Interm. spécialisés commerce d\'autres produits spécifiques';
  subActivity['46.19A'] = 'Centrales d\'achat non alimentaires';
  subActivity['46.19B'] = 'Autres intermédiaires du commerce en produits divers';
  subActivity['46.21Z'] = 'Comm. de gros céréales, tabac non manuf. et aliments pour bétail';
  subActivity['46.22Z'] = 'Commerce de gros (commerce interentreprises) de fleurs et plantes';
  subActivity['46.23Z'] = 'Commerce de gros (commerce interentreprises) d\'animaux vivants';
  subActivity['46.24Z'] = 'Commerce de gros (commerce interentreprises) de cuirs et peaux';
  subActivity['46.31Z'] = 'Commerce de gros (commerce interentreprises) de fruits et légumes';
  subActivity['46.32A'] = 'Commerce de gros de viandes de boucherie';
  subActivity['46.32B'] = 'Commerce de gros de produits à base de viande';
  subActivity['46.32C'] = 'Commerce de gros de volailles et gibier';
  subActivity['46.33Z'] = 'Com. gros produits laitiers, oeufs, huiles &amp; mat. grasses comest.';
  subActivity['46.34Z'] = 'Commerce de gros (commerce interentreprises) de boissons';
  subActivity['46.35Z'] = 'Commerce de gros de produits à base de tabac';
  subActivity['46.36Z'] = 'Commerce de gros de sucre, chocolat et confiserie';
  subActivity['46.37Z'] = 'Commerce de gros de café, thé, cacao et épices';
  subActivity['46.38A'] = 'Commerce de gros de poissons, crustacés et mollusques';
  subActivity['46.38B'] = 'Commerce de gros alimentaire spécialisé divers';
  subActivity['46.39A'] = 'Commerce de gros (commerce interentreprises) de produits surgelés';
  subActivity['46.39B'] = 'Commerce de gros alimentaire non spécialisé';
  subActivity['46.41Z'] = 'Commerce de gros (commerce interentreprises) de textiles';
  subActivity['46.42Z'] = 'Commerce de gros d\'habillement et de chaussures';
  subActivity['46.43Z'] = 'Commerce de gros d\'appareils électroménagers';
  subActivity['46.44Z'] = 'Commerce de gros de vaisselle, verrerie et produits d\'entretien';
  subActivity['46.45Z'] = 'Commerce de gros de parfumerie et de produits de beauté';
  subActivity['46.46Z'] = 'Commerce de gros de produits pharmaceutiques';
  subActivity['46.47Z'] = 'Commerce de gros de meubles, de tapis et d\'appareils d\'éclairage';
  subActivity['46.48Z'] = 'Commerce de gros d\'articles d\'horlogerie et de bijouterie';
  subActivity['46.49Z'] = 'Commerce de gros d\'autres biens domestiques';
  subActivity['46.51Z'] = 'Comm. de gros d\'ordinat., d\'éqpts informatiq. périph. &amp; logiciels';
  subActivity['46.52Z'] = 'Comm. de gros d\'éqpts et composants électroniques et de télécomm.';
  subActivity['46.61Z'] = 'Commerce de gros (commerce interentreprises) de matériel agricole';
  subActivity['46.62Z'] = 'Commerce de gros (commerce interentreprises) de machines-outils';
  subActivity['46.63Z'] = 'Comm de gros de machines pour l\'extrac, la constr, le génie civil';
  subActivity['46.64Z'] = 'Comm. gros (interentr.) machines pour industrie textile &amp; habill.';
  subActivity['46.65Z'] = 'Commerce de gros de mobilier de bureau';
  subActivity['46.66Z'] = 'Commerce de gros d\'autres machines et équipements de bureau';
  subActivity['46.69A'] = 'Commerce de gros de matériel électrique';
  subActivity['46.69B'] = 'Commerce de gros de fournitures et équipements industriels divers';
  subActivity['46.69C'] = 'Comm. gros de fournitures &amp; équipts divers pour commerces &amp; sces';
  subActivity['46.71Z'] = 'Commerce de gros de combustibles et de produits annexes';
  subActivity['46.72Z'] = 'Commerce de gros de minerais et métaux';
  subActivity['46.73A'] = 'Commerce de gros de bois et de matériaux de construction';
  subActivity['46.73B'] = 'Commerce de gros d\'appareils sanitaires et produits de décoration';
  subActivity['46.74A'] = 'Commerce de gros (commerce interentreprises) de quincaillerie';
  subActivity['46.74B'] = 'Commerce de gros de fournitures pour la plomberie et le chauffage';
  subActivity['46.75Z'] = 'Commerce de gros de produits chimiques';
  subActivity['46.76Z'] = 'Commerce de gros d\'autres produits intermédiaires';
  subActivity['46.77Z'] = 'Commerce de gros (commerce interentreprises) de déchets et débris';
  subActivity['46.90Z'] = 'Commerce de gros (commerce interentreprises) non spécialisé';
  subActivity['47.11A'] = 'Commerce de détail de produits surgelés';
  subActivity['47.11B'] = 'Commerce d\'alimentation générale';
  subActivity['47.11C'] = 'Supérettes';
  subActivity['47.11D'] = 'Supermarchés';
  subActivity['47.11E'] = 'Magasins multi-commerces';
  subActivity['47.11F'] = 'Hypermarchés';
  subActivity['47.19A'] = 'Grands magasins';
  subActivity['47.19B'] = 'Autres commerces de détail en magasin non spécialisé';
  subActivity['47.21Z'] = 'Commerce de détail de fruits et légumes en magasin spécialisé';
  subActivity['47.22Z'] = 'Comm. détail viandes &amp; produits à base de viande (magas. spéc.)';
  subActivity['47.23Z'] = 'Comm. détail poissons crustacés &amp; mollusques (magasin spécialisé)';
  subActivity['47.24Z'] = 'Comm. détail pain pâtisserie &amp; confiserie (magasin spécialisé)';
  subActivity['47.25Z'] = 'Commerce de détail de boissons en magasin spécialisé';
  subActivity['47.26Z'] = 'Comm. détail de produits à base de tabac en magasin spécialisé';
  subActivity['47.29Z'] = 'Autres commerces de détail alimentaires en magasin spécialisé';
  subActivity['47.30Z'] = 'Commerce de détail de carburants en magasin spécialisé';
  subActivity['47.41Z'] = 'Comm. détail ordinateurs unités périph. &amp; logiciels (magas. spéc)';
  subActivity['47.42Z'] = 'Comm. détail matériels télécommunication (magasin spécialisé)';
  subActivity['47.43Z'] = 'Commerce de détail de matériels audio/vidéo en magasin spécialisé';
  subActivity['47.51Z'] = 'Commerce de détail de textiles en magasin spécialisé';
  subActivity['47.52A'] = 'Comm. détail de quincaillerie, peintures et verres (mag.&lt; 400 m2)';
  subActivity['47.52B'] = 'Comm. détail de quincaillerie, peintures et verres (mag.&gt; 400 m2)';
  subActivity['47.53Z'] = 'Comm. détail tapis, moquettes &amp; revêts murs &amp; sols (magas. spéc.)';
  subActivity['47.54Z'] = 'Commerce de détail appareils électroménagers (magasin spécialisé)';
  subActivity['47.59A'] = 'Commerce de détail de meubles';
  subActivity['47.59B'] = 'Commerce de détail d\'autres équipements du foyer';
  subActivity['47.61Z'] = 'Commerce de détail de livres en magasin spécialisé';
  subActivity['47.62Z'] = 'Commerce de détail de journaux et papeterie en magasin spécialisé';
  subActivity['47.63Z'] = 'Comm. détail enreg. musicaux &amp; vidéo (magasin spécialisé)';
  subActivity['47.64Z'] = 'Commerce de détail d\'articles de sport en magasin spécialisé';
  subActivity['47.65Z'] = 'Commerce de détail de jeux et jouets en magasin spécialisé';
  subActivity['47.71Z'] = 'Commerce de détail d\'habillement en magasin spécialisé';
  subActivity['47.72A'] = 'Commerce de détail de la chaussure';
  subActivity['47.72B'] = 'Commerce de détail de maroquinerie et d\'articles de voyage';
  subActivity['47.73Z'] = 'Commerce de détail produits pharmaceutiques (magasin spécialisé)';
  subActivity['47.74Z'] = 'Comm. détail d\'articles médicaux &amp; orthopédiques en magasin spéc.';
  subActivity['47.75Z'] = 'Comm. détail de parfumerie &amp; produits de beauté en magasin spéc.';
  subActivity['47.76Z'] = 'Comm. dét. fleurs, plantes, etc, animaux de cie et leurs aliments';
  subActivity['47.77Z'] = 'Comm. détail d\'articles horlogerie &amp; bijouterie (magas. spéc.)';
  subActivity['47.78A'] = 'Commerces de détail d\'optique';
  subActivity['47.78B'] = 'Commerces de détail de charbons et combustibles';
  subActivity['47.78C'] = 'Autres commerces de détail spécialisés divers';
  subActivity['47.79Z'] = 'Commerce de détail de biens d\'occasion en magasin';
  subActivity['47.81Z'] = 'Commerce de détail alimentaire sur éventaires et marchés';
  subActivity['47.82Z'] = 'Comm. détail textiles habillt &amp; chaussures s/éventaires &amp; marchés';
  subActivity['47.89Z'] = 'Autres commerces de détail sur éventaires et marchés';
  subActivity['47.91A'] = 'Vente à distance sur catalogue général';
  subActivity['47.91B'] = 'Vente à distance sur catalogue spécialisé';
  subActivity['47.99A'] = 'Vente à domicile';
  subActivity['47.99B'] = 'Vente par automate, aut. com. dét. hors mag., éventaire ou marché';
  subActivity['49.10Z'] = 'Transport ferroviaire interurbain de voyageurs';
  subActivity['49.20Z'] = 'Transports ferroviaires de fret';
  subActivity['49.31Z'] = 'Transports urbains et suburbains de voyageurs';
  subActivity['49.32Z'] = 'Transports de voyageurs par taxis';
  subActivity['49.39A'] = 'Transports routiers réguliers de voyageurs';
  subActivity['49.39B'] = 'Autres transports routiers de voyageurs';
  subActivity['49.39C'] = 'Téléphériques et remontées mécaniques';
  subActivity['49.41A'] = 'Transports routiers de fret interurbains';
  subActivity['49.41B'] = 'Transports routiers de fret de proximité';
  subActivity['49.41C'] = 'Location de camions avec chauffeur';
  subActivity['49.42Z'] = 'Services de déménagement';
  subActivity['49.50Z'] = 'Transports par conduites';
  subActivity['50.10Z'] = 'Transports maritimes et côtiers de passagers';
  subActivity['50.20Z'] = 'Transports maritimes et côtiers de fret';
  subActivity['50.30Z'] = 'Transports fluviaux de passagers';
  subActivity['50.40Z'] = 'Transports fluviaux de fret';
  subActivity['51.10Z'] = 'Transports aériens de passagers';
  subActivity['51.21Z'] = 'Transports aériens de fret';
  subActivity['51.22Z'] = 'Transports spatiaux';
  subActivity['52.10A'] = 'Entreposage et stockage frigorifique';
  subActivity['52.10B'] = 'Entreposage et stockage non frigorifique';
  subActivity['52.21Z'] = 'Services auxiliaires des transports terrestres';
  subActivity['52.22Z'] = 'Services auxiliaires des transports par eau';
  subActivity['52.23Z'] = 'Services auxiliaires des transports aériens';
  subActivity['52.24A'] = 'Manutention portuaire';
  subActivity['52.24B'] = 'Manutention non portuaire';
  subActivity['52.29A'] = 'Messagerie, fret express';
  subActivity['52.29B'] = 'Affrètement et organisation des transports';
  subActivity['53.10Z'] = 'Activ. poste dans le cadre d\'une obligation de service universel';
  subActivity['53.20Z'] = 'Autres activités de poste et de courrier';
  subActivity['55.10Z'] = 'Hôtels et hébergement similaire';
  subActivity['55.20Z'] = 'Hébergement touristique et autre hébergement de courte durée';
  subActivity['55.30Z'] = 'Terrains de camping et parcs pour caravanes, véhicules de loisirs';
  subActivity['55.90Z'] = 'Autres hébergements';
  subActivity['56.10A'] = 'Restauration traditionnelle';
  subActivity['56.10B'] = 'Cafétérias et autres libres-services';
  subActivity['56.10C'] = 'Restauration de type rapide';
  subActivity['56.21Z'] = 'Services des traiteurs';
  subActivity['56.29A'] = 'Restauration collective sous contrat';
  subActivity['56.29B'] = 'Autres services de restauration n.c.a.';
  subActivity['56.30Z'] = 'Débits de boissons';
  subActivity['58.11Z'] = 'Édition de livres';
  subActivity['58.12Z'] = 'Édition de répertoires et de fichiers d\'adresses';
  subActivity['58.13Z'] = 'Édition de journaux';
  subActivity['58.14Z'] = 'Édition de revues et périodiques';
  subActivity['58.19Z'] = 'Autres activités d\'édition';
  subActivity['58.21Z'] = 'Édition de jeux électroniques';
  subActivity['58.29A'] = 'Édition de logiciels système et de réseau';
  subActivity['58.29B'] = 'Edition de logiciels outils de développement et de langages';
  subActivity['58.29C'] = 'Edition de logiciels applicatifs';
  subActivity['59.11A'] = 'Production de films et de programmes pour la télévision';
  subActivity['59.11B'] = 'Production de films institutionnels et publicitaires';
  subActivity['59.11C'] = 'Production de films pour le cinéma';
  subActivity['59.12Z'] = 'Post-production films cinématograph. vidéo &amp; prog. de télévision';
  subActivity['59.13A'] = 'Distribution de films cinématographiques';
  subActivity['59.13B'] = 'Edition et distribution vidéo';
  subActivity['59.14Z'] = 'Projection de films cinématographiques';
  subActivity['59.20Z'] = 'Enregistrement sonore et édition musicale';
  subActivity['60.10Z'] = 'Édition et diffusion de programmes radio';
  subActivity['60.20A'] = 'Edition de chaînes généralistes';
  subActivity['60.20B'] = 'Edition de chaînes thématiques';
  subActivity['61.10Z'] = 'Télécommunications filaires';
  subActivity['61.20Z'] = 'Télécommunications sans fil';
  subActivity['61.30Z'] = 'Télécommunications par satellite';
  subActivity['61.90Z'] = 'Autres activités de télécommunication';
  subActivity['62.01Z'] = 'Programmation informatique';
  subActivity['62.02A'] = 'Conseil en systèmes et logiciels informatiques';
  subActivity['62.02B'] = 'Tierce maintenance de systèmes et d\'applications informatiques';
  subActivity['62.03Z'] = 'Gestion d\'installations informatiques';
  subActivity['62.09Z'] = 'Autres activités informatiques';
  subActivity['63.11Z'] = 'Traitement de données, hébergement et activités connexes';
  subActivity['63.12Z'] = 'Portails Internet';
  subActivity['63.91Z'] = 'Activités des agences de presse';
  subActivity['63.99Z'] = 'Autres services d\'information n.c.a.';
  subActivity['64.11Z'] = 'Activités de banque centrale';
  subActivity['64.19Z'] = 'Autres intermédiations monétaires';
  subActivity['64.20Z'] = 'Activités des sociétés holding';
  subActivity['64.30Z'] = 'Fonds de placement et entités financières similaires';
  subActivity['64.91Z'] = 'Crédit-bail';
  subActivity['64.92Z'] = 'Autre distribution de crédit';
  subActivity['64.99Z'] = 'Autres activ. serv. financiers sf assurance &amp; c. de retraite, nca';
  subActivity['65.11Z'] = 'Assurance vie';
  subActivity['65.12Z'] = 'Autres assurances';
  subActivity['65.20Z'] = 'Réassurance';
  subActivity['65.30Z'] = 'Caisses de retraite';
  subActivity['66.11Z'] = 'Administration de marchés financiers';
  subActivity['66.12Z'] = 'Courtage de valeurs mobilières et de marchandises';
  subActivity['66.19A'] = 'Supports juridiques de gestion de patrimoine mobilier';
  subActivity['66.19B'] = 'Aut. activ. auxil. serv. financ., hors assur. &amp; caisse retr. nca.';
  subActivity['66.21Z'] = 'Évaluation des risques et dommages';
  subActivity['66.22Z'] = 'Activités des agents et courtiers d\'assurances';
  subActivity['66.29Z'] = 'Aut. activités auxiliaires d\'assurance et de caisses de retraite';
  subActivity['66.30Z'] = 'Gestion de fonds';
  subActivity['68.10Z'] = 'Activités des marchands de biens immobiliers';
  subActivity['68.20A'] = 'Location de logements';
  subActivity['68.20B'] = 'Location de terrains et d\'autres biens immobiliers';
  subActivity['68.31Z'] = 'Agences immobilières';
  subActivity['68.32A'] = 'Administration d\'immeubles et autres biens immobiliers';
  subActivity['68.32B'] = 'Supports juridiques de gestion de patrimoine immobilier';
  subActivity['69.10Z'] = 'Activités juridiques';
  subActivity['69.20Z'] = 'Activités comptables';
  subActivity['70.10Z'] = 'Activités des sièges sociaux';
  subActivity['70.21Z'] = 'Conseil en relations publiques et communication';
  subActivity['70.22Z'] = 'Conseil pour les affaires et autres conseils de gestion';
  subActivity['71.11Z'] = 'Activités d\'architecture';
  subActivity['71.12A'] = 'Activité des géomètres';
  subActivity['71.12B'] = 'Ingénierie, études techniques';
  subActivity['71.20A'] = 'Contrôle technique automobile';
  subActivity['71.20B'] = 'Analyses, essais et inspections techniques';
  subActivity['72.11Z'] = 'Recherche-développement en biotechnologie';
  subActivity['72.19Z'] = 'Recherche-développement : autres sciences physiques et naturelles';
  subActivity['72.20Z'] = 'Recherche-développement en sciences humaines et sociales';
  subActivity['73.11Z'] = 'Activités des agences de publicité';
  subActivity['73.12Z'] = 'Régie publicitaire de médias';
  subActivity['73.20Z'] = 'Études de marché et sondages';
  subActivity['74.10Z'] = 'Activités spécialisées de design';
  subActivity['74.20Z'] = 'Activités photographiques';
  subActivity['74.30Z'] = 'Traduction et interprétation';
  subActivity['74.90A'] = 'Activité des économistes de la construction';
  subActivity['74.90B'] = 'Activités spécialisées, scientifiques et techniques diverses';
  subActivity['75.00Z'] = 'Activités vétérinaires';
  subActivity['77.11A'] = 'Location de courte durée voitures &amp; véhicules auto. légers';
  subActivity['77.11B'] = 'Location de longue durée voitures &amp; véhicules automobiles légers';
  subActivity['77.12Z'] = 'Location et location-bail de camions';
  subActivity['77.21Z'] = 'Location et location-bail d\'articles de loisirs et de sport';
  subActivity['77.22Z'] = 'Location de vidéocassettes et disques vidéo';
  subActivity['77.29Z'] = 'Location et location-bail autres biens personnels et domestiques';
  subActivity['77.31Z'] = 'Location et location-bail de machines et équipements agricoles';
  subActivity['77.32Z'] = 'Location et location-bail machines &amp; équipts pour la construction';
  subActivity['77.33Z'] = 'Location et location-bail machines bureau &amp; matériel informatique';
  subActivity['77.34Z'] = 'Location et location-bail de matériels de transport par eau';
  subActivity['77.35Z'] = 'Location et location-bail de matériels de transport aérien';
  subActivity['77.39Z'] = 'Location et location-bail machines, équipements et biens divers';
  subActivity['77.40Z'] = 'Loc-bail de propriété intell. &amp; sf oeuvres soumises à copyright';
  subActivity['78.10Z'] = 'Activités des agences de placement de main-d\'oeuvre';
  subActivity['78.20Z'] = 'Activités des agences de travail temporaire';
  subActivity['78.30Z'] = 'Autre mise à disposition de ressources humaines';
  subActivity['79.11Z'] = 'Activités des agences de voyage';
  subActivity['79.12Z'] = 'Activités des voyagistes';
  subActivity['79.90Z'] = 'Autres services de réservation et activités connexes';
  subActivity['80.10Z'] = 'Activités de sécurité privée';
  subActivity['80.20Z'] = 'Activités liées aux systèmes de sécurité';
  subActivity['80.30Z'] = 'Activités d\'enquête';
  subActivity['81.10Z'] = 'Activités combinées de soutien lié aux bâtiments';
  subActivity['81.21Z'] = 'Nettoyage courant des bâtiments';
  subActivity['81.22Z'] = 'Autres activités nettoyage des bâtiments et nettoyage industriel';
  subActivity['81.29A'] = 'Désinfection, désinsectisation, dératisation';
  subActivity['81.29B'] = 'Autres activités de nettoyage n.c.a.';
  subActivity['81.30Z'] = 'Services d\'aménagement paysager';
  subActivity['82.11Z'] = 'Services administratifs combinés de bureau';
  subActivity['82.19Z'] = 'Photocopie prépa. documents &amp; aut. activ. spéc. soutien de bureau';
  subActivity['82.20Z'] = 'Activités de centres d\'appels';
  subActivity['82.30Z'] = 'Organisation de foires, salons professionnels et congrès';
  subActivity['82.91Z'] = 'Activ. de recouvrement factures &amp; d\'info. financ. s/la clientèle';
  subActivity['82.92Z'] = 'Activités de conditionnement';
  subActivity['82.99Z'] = 'Autres activités de soutien aux entreprises n.c.a.';
  subActivity['84.11Z'] = 'Administration publique générale';
  subActivity['84.12Z'] = 'Adm. pub. tutelle santé form. cult. &amp; social (aut que sécu. soc.)';
  subActivity['84.13Z'] = 'Administration publique (tutelle) des activités économiques';
  subActivity['84.21Z'] = 'Affaires étrangères';
  subActivity['84.22Z'] = 'Défense';
  subActivity['84.23Z'] = 'Justice';
  subActivity['84.24Z'] = 'Activités d\'ordre public et de sécurité';
  subActivity['84.25Z'] = 'Services du feu et de secours';
  subActivity['84.30A'] = 'Activités générales de sécurité sociale';
  subActivity['84.30B'] = 'Gestion des retraites complémentaires';
  subActivity['84.30C'] = 'Distribution sociale de revenus';
  subActivity['85.10Z'] = 'Enseignement pré-primaire';
  subActivity['85.20Z'] = 'Enseignement primaire';
  subActivity['85.31Z'] = 'Enseignement secondaire général';
  subActivity['85.32Z'] = 'Enseignement secondaire technique ou professionnel';
  subActivity['85.41Z'] = 'Enseignement post-secondaire non supérieur';
  subActivity['85.42Z'] = 'Enseignement supérieur';
  subActivity['85.51Z'] = 'Enseignement de disciplines sportives et d\'activités de loisirs';
  subActivity['85.52Z'] = 'Enseignement culturel';
  subActivity['85.53Z'] = 'Enseignement de la conduite';
  subActivity['85.59A'] = 'Formation continue d\'adultes';
  subActivity['85.59B'] = 'Autres enseignements';
  subActivity['85.60Z'] = 'Activités de soutien à l\'enseignement';
  subActivity['86.10Z'] = 'Activités hospitalières';
  subActivity['86.21Z'] = 'Activité des médecins généralistes';
  subActivity['86.22A'] = 'Activités de radiodiagnostic et de radiothérapie';
  subActivity['86.22B'] = 'Activités chirurgicales';
  subActivity['86.22C'] = 'Autres activités des médecins spécialistes';
  subActivity['86.23Z'] = 'Pratique dentaire';
  subActivity['86.90A'] = 'Ambulances';
  subActivity['86.90B'] = 'Laboratoires d\'analyses médicales';
  subActivity['86.90C'] = 'Centres de collecte et banques d\'organes';
  subActivity['86.90D'] = 'Activités des infirmiers et des sages-femmes';
  subActivity['86.90E'] = 'Activité profess. rééducation appareillage &amp; pédicures-podologues';
  subActivity['86.90F'] = 'Activités de santé humaine non classées ailleurs';
  subActivity['87.10A'] = 'Hébergement médicalisé pour personnes âgées';
  subActivity['87.10B'] = 'Hébergement médicalisé pour enfants handicapés';
  subActivity['87.10C'] = 'Héberg. médicalisé adultes handicapés &amp; autre héberg. médicalisé';
  subActivity['87.20A'] = 'Hébergement social pour handicapés mentaux et malades mentaux';
  subActivity['87.20B'] = 'Hébergement social pour toxicomanes';
  subActivity['87.30A'] = 'Hébergement social pour personnes âgées';
  subActivity['87.30B'] = 'Hébergement social pour handicapés  physiques';
  subActivity['87.90A'] = 'Hébergement social pour enfants en difficultés';
  subActivity['87.90B'] = 'Hébergement social pour adultes, familles en difficultés et autre';
  subActivity['88.10A'] = 'Aide à domicile';
  subActivity['88.10B'] = 'Accueil accompagn. sans héberg. adultes handicapés ou pers. âgées';
  subActivity['88.10C'] = 'Aide par le travail';
  subActivity['88.91A'] = 'Accueil de jeunes enfants';
  subActivity['88.91B'] = 'Accueil ou accompagnement sans hébergement d\'enfants handicapés';
  subActivity['88.99A'] = 'Aut. accueil ou accompgnt sans hébergt d\'enfants et adolescents';
  subActivity['88.99B'] = 'Action sociale sans hébergement n.c.a.';
  subActivity['90.01Z'] = 'Arts du spectacle vivant';
  subActivity['90.02Z'] = 'Activités de soutien au spectacle vivant';
  subActivity['90.03A'] = 'Création artistique relevant des arts plastiques';
  subActivity['90.03B'] = 'Autre création artistique';
  subActivity['90.04Z'] = 'Gestion de salles de spectacles';
  subActivity['91.01Z'] = 'Gestion des bibliothèques et des archives';
  subActivity['91.02Z'] = 'Gestion des musées';
  subActivity['91.03Z'] = 'Gestion sites monuments historiques &amp; attractions tourist. simil.';
  subActivity['91.04Z'] = 'Gest. des jardins botaniques et zoolog. et des réserv. naturelles';
  subActivity['92.00Z'] = 'Organisation de jeux de hasard et d\'argent';
  subActivity['93.11Z'] = 'Gestion d\'installations sportives';
  subActivity['93.12Z'] = 'Activités de clubs de sports';
  subActivity['93.13Z'] = 'Activités des centres de culture physique';
  subActivity['93.19Z'] = 'Autres activités liées au sport';
  subActivity['93.21Z'] = 'Activités des parcs d\'attractions et parcs à thèmes';
  subActivity['93.29Z'] = 'Autres activités récréatives et de loisirs';
  subActivity['94.11Z'] = 'Activités des organisations patronales et consulaires';
  subActivity['94.12Z'] = 'Activités des organisations professionnelles';
  subActivity['94.20Z'] = 'Activités des syndicats de salariés';
  subActivity['94.91Z'] = 'Activités des organisations religieuses';
  subActivity['94.92Z'] = 'Activités des organisations politiques';
  subActivity['94.99Z'] = 'Autres organisations fonctionnant par adhésion volontaire';
  subActivity['95.11Z'] = 'Réparation d\'ordinateurs et d\'équipements périphériques';
  subActivity['95.12Z'] = 'Réparation d\'équipements de communication';
  subActivity['95.21Z'] = 'Réparation de produits électroniques grand public';
  subActivity['95.22Z'] = 'Réparation appareils électromén. &amp; équipts maison &amp; jardin';
  subActivity['95.23Z'] = 'Réparation de chaussures et d\'articles en cuir';
  subActivity['95.24Z'] = 'Réparation de meubles et d\'équipements du foyer';
  subActivity['95.25Z'] = 'Réparation d\'articles d\'horlogerie et de bijouterie';
  subActivity['95.29Z'] = 'Réparation d\'autres biens personnels et domestiques';
  subActivity['96.01A'] = 'Blanchisserie-teinturerie de gros';
  subActivity['96.01B'] = 'Blanchisserie-teinturerie de détail';
  subActivity['96.02A'] = 'Coiffure';
  subActivity['96.02B'] = 'Soins de beauté';
  subActivity['96.03Z'] = 'Services funéraires';
  subActivity['96.04Z'] = 'Entretien corporel';
  subActivity['96.09Z'] = 'Autres services personnels n.c.a.';
  subActivity['97.00Z'] = 'Activités des ménages  : employeurs de personnel domestique';
  subActivity['98.10Z'] = 'Activ. indifférenciées ménages : producteurs biens (usage propre)';
  subActivity['98.20Z'] = 'Activ. indifférenciées ménages  : produc. services (usage propre)';
  subActivity['99.00Z'] = 'Activités des organisations et organismes extraterritoriaux';

  module.exports = {
    mainActivity: mainActivity,
    subActivity: subActivity
  };
});
