define('translationsTableLayoutView',['module', 'marionette', 'template!translationsTableLayoutTpl'],
function(module, Marionette, translationsTableLayoutTpl) {
  'use strict';

  var TranslationsTableLayoutView = Marionette.LayoutView.extend({
    template: translationsTableLayoutTpl,

    regions: {
      filters: '#translations-filters-region',
      table: '#translations-table-region'
    }
  });

  module.exports = TranslationsTableLayoutView;
});
