
define('template!adminGlossariesListFiltersEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n    <div class="line">\n        <div class="type item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</div>\n            <div class="select-region"></div>\n        </div>\n        <div class="missing-translation item">\n            <div class="libelle">' +
((__t = ( _.i18n('glossary.missingTranslation') )) == null ? '' : __t) +
'</div>\n            <div class="select-region"></div>\n        </div>\n    </div>\n    <div class="code item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <input type="text" tabindex="3"/>\n    </div>\n    <div class="text item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.text') )) == null ? '' : __t) +
'</div>\n        <input type="text" tabindex="3"/>\n    </div>\n</div>\n<div class="footer">\n    <div class="cancel-region"></div>\n    <div class="reset-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

