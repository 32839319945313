define('webshopProductsDetailBodySlugsLanguageView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopProductsDetailBodySlugsLanguageHistoryView',
  'template!webshopProductsDetailBodySlugsLanguageView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  HistoryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-slugs-language-view',

    ui: {
      slug: '.slug input',
      history: '.history .history-region',
      historyTitle: '.history .title'
    },

    events: {
      'change @ui.slug': 'changeSlug',
      'click @ui.historyTitle': 'historyClick'
    },

    modelEvents: {
      'change:slug': 'renderSlug'
    },

    regions: {
      historyRegion: '.history-region'
    },

    serializeData: function () {
      var data = {};
      data.language = this.model.get('lang');
      return data;
    },

    onRender: function () {
      this.renderSlug();
      this.ui.history.hide();
      this.ui.historyTitle.addClass('closed');
    },

    renderSlug: function () {
      this.ui.slug.val(this.model.get('slug'));
    },

    changeSlug: function () {
      this.model.set('slug', this.ui.slug.val());
      this.trigger('change', this.ui.slug.val());
    },

    historyClick: function () {
      if(this.ui.history.is(':visible')) {
        this.ui.history.hide();
        this.ui.historyTitle.removeClass('opened');
        this.ui.historyTitle.addClass('closed');
      }
      else {
        if(!this.historyView) {
          this.historyView = new HistoryView({
            model: this.options.product,
            lang: this.model.get('lang')
          });
          this.getRegion('historyRegion').show(this.historyView);
        }

        this.ui.history.css('display', 'flex');
        this.ui.historyTitle.removeClass('closed');
        this.ui.historyTitle.addClass('opened');
      }
    }
  });
});
