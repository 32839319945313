define('tasksEditView',[
  'app',
  'module',
  'underscore',
  'modalsConfirmationView',
  'backbone',
  'jquery',
  'moment',
  'settings',
  'commentsView',
  'dateView',
  'dateUtils',
  'usersView',
  'buttonView',
  'template!tasksEditView',
  'entities/orders'
], function (
  App,
  Module,
  _,
  ModalsConfirmationView,
  Backbone,
  $,
  moment,
  Settings,
  CommentsView,
  DateView,
  dateConverter,
  UsersView,
  ButtonView,
  Tpl,
  Orders
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'tasks-edit-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      done: '.done',
      dateDue: '.date-due',
      info: '.info',
      open: '.open',
      addComment: '.buttons .add-button',
      commentsCounter: '.counter'
    }, ModalsConfirmationView.prototype.ui),

    events: {
      'change .js-info-input-date': 'onDateInputChange',

      'change @ui.done': 'onChangeDone',
      'click @ui.info': 'onOpenTarget',
      'click @ui.open': 'onOpenTarget',
      'click @ui.addComment': 'onAddComment'
    },

    initialize: function () {
      this.cloneModel = this.model.clone();

      var userpids = [];
      if(this.model.get('userpids'))
        userpids = this.model.get('userpids').slice();
      this.cloneModel.set('userpids', userpids);

      if(!this.cloneModel.get('secId')) {
        if(this.cloneModel.get('userpids') && this.cloneModel.get('userpids').length > 0)
          userpids = this.cloneModel.get('userpids');

        if(userpids.length === 0) {
          var currentUser =  Settings.get('currentUser');
          this.cloneModel.set('userpids', [currentUser], {silent: true});
        }
      }

      //----------------
      var userpidsToNotify = [];
      if(this.model.get('userpidsToNotify'))
        userpidsToNotify = this.model.get('userpidsToNotify').slice();
      this.cloneModel.set('userpidsToNotify', userpidsToNotify);

      this.commentsCollection = new Backbone.Collection();

      //----------------
      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },

    regions: _.extend({
      commentsRegion: '.comments-region',
      usersRegion: '.users-region',
      usersToNotifyRegion: '.users-to-notify-region',
      dateRegion: '.date-region',
      openRegion: '.open-region'
    }, ModalsConfirmationView.prototype.regions),

    serializeData: function () {
      var data = {};

      data.type = '';

      if (this.model.get('refCommandLine')) {
        data.type = 'order';
        data.poi = this.model.get('refCommandLine').purchaseOrderId;
        data.user = this.model.get('refCommandLine').endUserId;        
      } else if (this.model.get('refEndUser')) {
        data.type = 'client';
        data.user = this.model.get('refEndUser').endUserId;
      } else if (this.model.get('refProduct')) {
        data.type = 'product';    
        data.code = '';   
        data.name = '';

        if(this.model.get('refProduct').code)
          data.code = this.model.get('refProduct').code;

        if(this.model.get('refProduct').name)
          data.name = this.model.get('refProduct').name;
      }    

      //--------------------------

      data.dateDue = '';

      if (this.model.get('dateDue'))
        data.dateDue = dateConverter.formatT(this.model.get('dateDue'));

      //--------------------------

      data.done = false;

      if (this.model.get('done'))
        data.done = this.model.get('done');

      return data;
    },

    onShow: function () {
      this.fetchComments();

      this.commentsView = new CommentsView({
        collection: this.commentsCollection,
        add: _.bind(function () {
          this.ui.commentsCounter.html(this.commentsCollection.length);
        }, this),
        remove: _.bind(function () {
          this.ui.commentsCounter.html(this.commentsCollection.length);
        }, this)
      });

      this.getRegion('commentsRegion').show(this.commentsView);

      //--------------------------       
      var color = '';

      if (this.model.get('dateDue')) {   
        if (moment(new Date()).isAfter(this.model.get('dateDue')))
          color = 'red';
      }

      this.dateView = new DateView({
        model: new Backbone.Model({
          date: this.model.get('dateDue'),
          icon: 'clock',
          color: color
        })
      });
      this.getRegion('dateRegion').show(this.dateView);

      //--------------------------
      this.usersView = new UsersView({
        model: new Backbone.Model({userpids: this.cloneModel.get('userpids')}),
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          if(!this.cloneModel.get('userpids'))
            this.cloneModel.set('userpids', [], {silent: true});

          this.cloneModel.get('userpids').push(user.toJSON());
          deferred.resolve();
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          if(!this.cloneModel.get('userpids'))
            this.cloneModel.set('userpids', [], {silent: true});

          var index = -1;
          _.each(this.cloneModel.get('userpids'), _.bind(function(item, i) {
            if(item.secId === user.get('secId'))
              index = i;
          }, this));

          if (index !== -1)
            this.cloneModel.get('userpids').splice(index, 1);
          deferred.resolve();
          return deferred;
        }, this)
      });
      this.getRegion('usersRegion').show(this.usersView);

      //--------------------------
      this.usersToNotifyRegion = new UsersView({
        model: new Backbone.Model({userpids: this.cloneModel.get('userpidsToNotify')}),
        onSelected: _.bind(function (user) {
          if(!this.cloneModel.get('userpidsToNotify'))
            this.cloneModel.set('userpidsToNotify', [], {silent: true});

          this.cloneModel.get('userpidsToNotify').push(user.toJSON());
          var deferred = new $.Deferred();
          deferred.resolve();
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          if(!this.cloneModel.get('userpidsToNotify'))
            this.cloneModel.set('userpidsToNotify', [], {silent: true});

          var index = -1;
          _.each(this.cloneModel.get('userpidsToNotify'), _.bind(function(item, i) {
            if(item.secId === user.secId)
              index = i;
          }, this));

          if (index !== -1)
            this.cloneModel.get('userpidsToNotify').splice(index, 1);

          var deferred = new $.Deferred();
          deferred.resolve();
          return deferred;
        }, this)
      });
      this.getRegion('usersToNotifyRegion').show(this.usersToNotifyRegion);

      //----------------
      this.openButtonView = new ButtonView({
        type: 'open',
        text: _.i18n('tasks.open-target'),
        title: _.i18n('tasks.open-target'),
        tabindex: 1
      });

      this.openRegion.show(this.openButtonView);
      this.openButtonView.on('click', _.bind(this.onOpenTarget, this));

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    fetchComments: function () {
      var collection = new Backbone.Collection(this.model.get('comments'));

      var models = collection.models.sort(function (a, b) {
        return a.get('msgDate') < b.get('msgDate') ? 1 : -1;
      });

      this.commentsCollection = new Backbone.Collection(models);

      this.ui.commentsCounter.html(this.commentsCollection.length);
    },

    onConfirm: function () {
      this.load(true, _.i18n('common.saving'));

      this.cloneModel.set('comments', this.commentsCollection.toJSON());

      this.model.save(this.cloneModel.toJSON()).done(_.bind(function () {
        this.load(false);
        this.model.fetch();
        ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
      }, this));      
    },

    onOpenTarget: function () {
      if (this.model.get('refCommandLine'))
        Orders.showOrder(this.model.get('refCommandLine').purchaseOrderId, '');
      if (this.model.get('refEndUser'))
        App.navigate('customers/' + this.model.get('refEndUser').secId, {trigger: true});
      if (this.model.get('refProduct'))
        App.navigate('product/' + this.model.get('refProduct').secId, {trigger: true});

      this.windowView.close();
    },

    onChangeDone: function () {
      this.cloneModel.set('done', this.ui.done.is(':checked'));
    },

    onDateInputChange: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val();
      
      this.cloneModel.set('dateDue', dateConverter.formatTUtc(value));

      this.dateView.model.set('date', dateConverter.formatTUtc(value));
      var color = '';
      if (this.dateView.model.get('date')) {   
        if (moment(new Date()).isAfter(this.dateView.model.get('date')))
          color = 'red';
      }     

      this.dateView.model.set('color', color);

      this.cloneModel.set('dateDue', dateConverter.formatTUtc(value));
    },

    onAddComment: function () {
      this.commentsView.add();
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'tasksEditView'
      ], function (Windows, View) {

        var title = _.i18n('common.task');

        if(data.newTask)
          title = _.i18n('common.newtask');


        if (data.model.getRefCommandLine())
          title += ' | ' + _.i18n('common.order') + ' | ' + data.model.getRefCommandLine().purchaseOrderId;

        if (data.model.getRefEndUser())
          title += ' | ' + _.i18n('common.customer') + ' | ' + data.model.getRefEndUser().code;

        if (data.model.getRefProduct())
          title += ' | ' + _.i18n('common.product') + ' | ' + data.model.getRefProduct().cCode + ' | ' + data.model.getRefProduct().name;

        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: title,
          modal: true,
          width: 600,
          maxWidth: 800
        });
      });
    }
  });
});
