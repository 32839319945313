/**
 * Created by RKL on 21/08/2015.
 */
define('personalizeMain',[
  'module',
  'logger',
  'personalizeMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Personalize = {
    start: function() {
      Logger.log(module, 'starting PersonalizeModule');
    },

    stop: function() {
      Logger.log(module, 'stopping PersonalizeModule');
    }
  };

  module.exports = Personalize;
});
