/**
 * Created by Matteo on 4/08/2015.
 */
define('caccountsMain',[
  'module',
  'logger',
  'caccountsMainRouter'
], function(
  module,
  Logger
) {
  'use strict';

  var CAccounts = {
    start: function() {
      Logger.log(module, 'starting CaccountsModule');
    },

    stop: function() {
      Logger.log(module, 'stopping CaccountsModule');
    }
  };

  module.exports = CAccounts;
});
