define('mailsRouter',[
  'app',
  'require',
  'module',
  'marionette',
  'underscore',
  'itemPagesNameMixin'
], function (
  App,
  require,
  module,
  Marionette,
  _,
  ItemPagesNameMixin
) {
  'use strict';

  var MailsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'mails': 'showList',
      'mails/:from': 'showList',
      'mails/:from/:folder': 'showList'
    }
  });

  var API = {
    showList: function (from, folder) {
      require([
        'mailsView',
        'contentShowController'
      ], function (MailsView,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          this.mailsView = new MailsView({
            from: from,
            folder: folder
          });
          App.regions.getRegion('main').currentView.getRegion('content').show(this.mailsView);
          //App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.MAILS);
        });
      });
    }
  };

  App.on('before:start', function () {
    new MailsRouter({
      controller: API
    });
  });

  module.exports = MailsRouter;
});

