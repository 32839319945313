
define('template!ordersBoardHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tools">\n  <div class="scrool-buttons">\n    <div class="js-move-begin" title="' +
((__t = ( _.i18n('orders.board.begin-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/></svg>\n    </div>\n    <div class="js-move-left-2" title="' +
((__t = ( _.i18n('orders.board.left2-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n        <path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7V416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V96C0 78.3 14.3 64 32 64s32 14.3 32 32V214.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96V214.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96V416c0 12.4-7.2 23.7-18.4 29z"/>\n      </svg>\n    </div>\n    <div class="js-move-left" title="' +
((__t = ( _.i18n('orders.board.left-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">\n        <path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241V96c0-17.7-14.3-32-32-32S0 78.3 0 96V416c0 17.7 14.3 32 32 32s32-14.3 32-32V271l11.5 9.6 192 160z"/>\n      </svg>\n    </div>\n    <div class="js-move-right" title="' +
((__t = ( _.i18n('orders.board.right-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">\n        <path d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241V96c0-17.7 14.3-32 32-32s32 14.3 32 32V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V271l-11.5 9.6-192 160z"/>\n      </svg>\n    </div>\n    <div class="js-move-right-2" title="' +
((__t = ( _.i18n('orders.board.right2-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n        <path d="M18.4 445c11.2 5.3 24.5 3.6 34.1-4.4L224 297.7V416c0 12.4 7.2 23.7 18.4 29s24.5 3.6 34.1-4.4L448 297.7V416c0 17.7 14.3 32 32 32s32-14.3 32-32V96c0-17.7-14.3-32-32-32s-32 14.3-32 32V214.3L276.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S224 83.6 224 96V214.3L52.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S0 83.6 0 96V416c0 12.4 7.2 23.7 18.4 29z"/>\n      </svg>\n    </div>\n    <div class="js-move-end" title="' +
((__t = ( _.i18n('orders.board.end-button') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n        <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"/>\n      </svg>\n    </div>\n  </div>\n  <div class="clear-all-sort-region"></div>\n  <div class="unselect-all-region"></div>\n</div>\n<div class="add-region"></div>';

}
return __p
};});

