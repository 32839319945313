/**
 * Created by OLD on 06/08/2015.
 */
define('myWebSiteShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'colorpicker',
  'colorUtils',
  'imageUtils',
  'imageUploadBehavior',
  'tokenField',
  'template!myWebSiteViewTpl',
  'env'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Setting,
  colorpicker,
  ColorUtils,
  ImageUtils,
  ImageUploadBehavior,
  tokenField,
  viewTpl,
  Env
) {
  'use strict';

  var MyWebSiteView =  Marionette.ItemView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.save' : 'onSave'
    },

    ui: {
      colorBtn: '.colorpick',
      save: '.my-website-save',
      showImg: '.my-web-site-upload-img',
      subdomain: '.f-my-ws.subdomain',
      phoneNumber: '.phone-input',
      preview: '.preview-b',
      securedLogotool: '.securedLogotool',
      displayExpertButton: '.displayExpertButton',
      displayUploadTemplate : '.displayUploadTemplate',
      displayTerms : '.displayTerms',
      displayHeader : '.displayHeader',
      displayPrice : '.displayPrice',
      displayVat : '.displayVat',
      displayWelcome : '.displayWelcome',
      displayRequestOffer : '.displayRequestOffer',
      displayShippingCost : '.displayShippingCost',
      displayNotPrinted : '.displayNotPrinted',
      enableCompulogo : '.enableCompulogo',
      displayHeaderFooterLogotool: '.displayHeaderFooterLogotool',
      displayBookInCart: '.displayBookInCart',
      displayPersoQuestion: '.displayPersoQuestion'
    },

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      }
    },

    events: {
      'click @ui.colorBtn' : 'onShowColorpicker',
      'click @ui.preview' : 'onPreview',
      'change @ui.securedLogotool' : 'onSecuredLogotoolChange',
      'change @ui.subdomain': 'onFieldChange',
      'change @ui.phoneNumber': 'onFieldChange',
      'change @ui.displayExpertButton' : 'onAdvancedPropertyChanged',
      'change @ui.displayUploadTemplate' : 'onAdvancedPropertyChanged',
      'change @ui.displayTerms' : 'onAdvancedPropertyChanged',
      'change @ui.displayHeader' : 'onAdvancedPropertyChanged',
      'change @ui.displayPrice' : 'onAdvancedPropertyChanged',
      'change @ui.displayVat' : 'onAdvancedPropertyChanged',
      'change @ui.displayWelcome' : 'onAdvancedPropertyChanged',
      'change @ui.displayRequestOffer' : 'onAdvancedPropertyChanged',
      'change @ui.displayShippingCost' : 'onAdvancedPropertyChanged',
      'change @ui.displayNotPrinted' : 'onAdvancedPropertyChanged',
      'change @ui.enableCompulogo' : 'onAdvancedPropertyChanged',
      'change @ui.displayHeaderFooterLogotool' : 'onAdvancedPropertyChanged',
      'change @ui.displayBookInCart': 'onAdvancedPropertyChanged',
      'change @ui.displayPersoQuestion': 'onAdvancedPropertyChanged'
    },

    serializeData: function() {
      var templateData = this.model.toJSON();

      templateData.accentColor = ColorUtils
        .decimalToHexa(this.model.get('accentColor'))
        .split('#')[1];
      templateData.bodyColor = ColorUtils
        .decimalToHexa(this.model.get('bodyColor'))
        .split('#')[1];
      templateData.primaryColor = ColorUtils
        .decimalToHexa(this.model.get('primaryColor'))
        .split('#')[1];
      if (this.model.get('logoUid')) {
        templateData.fileUrl = Setting.url('file', 
          this.model.get('logoUid'));
      } else {
        templateData.fileUrl = null;
      }

      templateData.urlPrefix = Setting.get('websitePrefix');
      templateData.urlPostfix  = Setting.get('websitePostfix');

      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.fd = {};
      this.initTokenField();
    },

    initTokenField: function(){
      // these variables are used to fix email input field appearance
      var $email = this.$el.find('.support-email'),
          value = this.$('#emailFocusedInput').val(),
          emailTokenCount = value ? value.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('.support-email .floating-label').addClass('floating-label-focused');
      }

      this.$('#emailFocusedInput').tokenfield({
          createTokensOnBlur: true,
          inputType: 'email',
          delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function(e){
          this.onTokenFieldChange(e);
          emailTokenCount++;
      }, this))
          .on('tokenfield:tokenEdited', _.bind(function(e){
              this.onTokenFieldChange(e);
          }, this))
          .on('tokenfield:removedtoken', _.bind(function(e){
              this.onTokenFieldChange(e);
              emailTokenCount--;
          }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('#emailFocusedInput-tokenfield').off('focusin').focusin(function() {
        $email.find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function() {
        if($(this).val() === '' && emailTokenCount === 0) {
          $email.find('.floating-label').removeClass('floating-label-focused');
        }
        $email.find('.floating-label').removeClass('selected');
      });
    },

    onBeforeDestroy: function(){
        this.$('#emailFocusedInput').tokenfield('destroy');
    },

    onTokenFieldChange: function(event){
        var key = $(event.currentTarget).data('key');
        this.fd[key] = $(event.currentTarget).tokenfield('getTokensList');
    },

    onFieldChange: function(event){
        var $target =  $(event.currentTarget);
        var key = $target.data('key');
        if(key === 'subdomain') {
          var floating =  $target.parent().find('.floating-label' );
          if($target.val().match(/[^a-zA-Z0-9-_]/g)) {
            floating.css('color', '#ff5722');
            floating.text(_.i18n('mywebsite.subdomaine.invalid'));
            this.ui.save.addClass('disabled');
          } else {
            floating.css('color', '#7e7e7e');
            floating.text(_.i18n('mywebsite.subdomain'));
            this.ui.save.removeClass('disabled');
          }
        }
        this.fd[key] = $target.val();
    },

    onSecuredLogotoolChange: function(event) {
      var key = $(event.currentTarget).data('key'),
          val = this.ui.securedLogotool.is(':checked');
      this.fd[key] = val;
    },

    onAdvancedPropertyChanged: function (event) {
      var key = $(event.currentTarget).data('key'),
          val = this.ui[key].is(':checked');
      if (!this.fd.advancedProperties) {
        this.fd.advancedProperties = {};
      }
      this.fd.advancedProperties[key] = val;
    },

    onImageUploadConfirm: function(base64Value, fileExt) {
      this.ui.showImg.attr('style',
        'background-image:url(data:image/' + fileExt + ';base64,' + base64Value + ');');
      this.fd.base64file = base64Value;
      this.fd.fileExt = fileExt;
    },

    onShowColorpicker: function(event) {
      var that = this,
        target = $(event.currentTarget),
        key = target.data('key'),
        newColor;

      target.colorpicker();
      target.colorpicker('show');
      target.on('changeColor.colorpicker', function(event){
        newColor = event.color.toHex();
        that.fd[key] = newColor;
      });
    },

    onPreview: function() {
      var env = Setting.get('environment'),
        url = Setting.get('websitePrefix') + this.ui.subdomain.val() +
          Setting.get('websitePostfix');

      env = env ? Env.isProduction() === true ? '' : '/' + env + '/' : '';
      url = url + env;

      window.open(url, '_blank');
    }
  });

  module.exports = MyWebSiteView;
});
