define('adminGlossariesPopupView',[
  'module',
  'backbone',
  'underscore',
  'marionette',
  'selectView',
  'template!adminGlossariesPopupView'
], function (
  module,
  Backbone,
  _,
  Marionette,
  SelectView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'admin-glossaries-list-view',

    ui: {
      code: '.code input',
      frTranslation: '.translations .fr textarea',
      enTranslation: '.translations .en textarea',
      deTranslation: '.translations .de textarea',
      nlTranslation: '.translations .nl textarea',
      missingTranslation: '.missing-translation input',
      confirmButton: '.confirm',
      cancelButton: '.cancel'
    },

    events: {
      'change @ui.frTranslation': 'onFrChange',
      'change @ui.enTranslation': 'onEnChange',
      'change @ui.deTranslation': 'onDeChange',
      'change @ui.nlTranslation': 'onNlChange',
      'click @ui.confirmButton': 'onConfirm',
      'click @ui.cancelButton': 'onCancel'
    },

    regions: {
      typeRegion: '.type-region'
    },

    initialize: function () {
      this.model = this.model.clone();
      this.model.set('code', '');
      this.model.set('missingTranslation', false);
      this.model.get('refDynamicText').setTextInLocale('fr', '');
      this.model.get('refDynamicText').setTextInLocale('en', '');
      this.model.get('refDynamicText').setTextInLocale('de', '');
      this.model.get('refDynamicText').setTextInLocale('nl', '');
    },

    serializeData: function () {
      var data = {};

      data.code = this.model.get('code');
      data.missingTranslation = this.model.get('missingTranslation');
      data.fr = this.model.get('refDynamicText').getTextInLocale('fr');
      data.en = this.model.get('refDynamicText').getTextInLocale('en');
      data.de = this.model.get('refDynamicText').getTextInLocale('de');
      data.nl = this.model.get('refDynamicText').getTextInLocale('nl');

      return data;
    },

    onShow: function () {
      this.typeSelectView = new SelectView({
        collection: new Backbone.Collection([
          {id: 'color', value: _.i18n('glossaries.type.color')},
          {id: 'impt',  value: _.i18n('glossaries.type.impt')},
          {id: 'material',  value: _.i18n('glossaries.type.material')},
          {id: 'other',  value: _.i18n('glossaries.type.other')},
          {id: 'paName',  value: _.i18n('glossaries.type.paName')}
        ])
      });
      this.typeRegion.show(this.typeSelectView);
      this.typeSelectView.on('change', _.bind(this.onTypeChange, this));
    },

    onConfirm: function () {

      this.model.set('code', this.ui.code.val(), {silent: true});
      this.model.get('refDynamicText').setTextInLocale('fr', this.ui.frTranslation.val());
      this.model.get('refDynamicText').setTextInLocale('en', this.ui.enTranslation.val());
      this.model.get('refDynamicText').setTextInLocale('de', this.ui.deTranslation.val());
      this.model.get('refDynamicText').setTextInLocale('nl', this.ui.nlTranslation.val());
      this.model.set('missingTranslation', this.ui.missingTranslation.is(':checked'), {silent: true});

      if(this.model.get('code') === '')
        return;

      this.model.save().done(_.bind(function () {
        this.windowView.close();
        if(this.options.onConfirm)
          this.options.onConfirm();
      }, this));
    },

    onCancel: function () {
      this.windowView.close();
    },

    onTypeChange: function (model) {
      this.model.set('type', model.get('id'));
    },

    onFrChange: function () {
      this.model.get('refDynamicText').setTextInLocale('fr', this.ui.frTranslation.val());
    },

    onEnChange: function () {
      this.model.get('refDynamicText').setTextInLocale('en', this.ui.enTranslation.val());
    },

    onDeChange: function () {
      this.model.get('refDynamicText').setTextInLocale('de', this.ui.deTranslation.val());
    },

    onNlChange: function () {
      this.model.get('refDynamicText').setTextInLocale('nl', this.ui.nlTranslation.val());
    },

    onChangeCheckboxInput: function (e) {
      var inp = $(e.target);
      this.model.set(inp.data('field-name'), inp.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    }
  });
});
