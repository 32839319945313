define('ordersDetailOrderStatusStatusView',[
  'module',
  'marionette',
  'template!ordersDetailOrderStatusStatusView'
], function (
  Module,
  Marionette,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    tagName: 'option',
    className: 'orders-detail-order-status-status-view',
    template: Tpl,
    attributes: function() {
      return {
        value: this.model.get('statusConst')
      };    
    },

    serializeData: function() {
      return {
        name: this.model.get('status')
      };
    },

    setSelected: function(value) {
      this.$el.attr('selected', value);
    }
  });
});
