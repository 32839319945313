define('webshopProductsDetailBodySlugsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'selectView',
  'webshopProductsDetailBodySlugsLanguageView',
  'modalsAlertView',
  'template!webshopProductsDetailBodySlugsView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  SelectView,
  LanguageView,
  AlertView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-slugs-view',
    childView: LanguageView,
    childViewContainer: '.languages-container',
    childViewOptions: function () {
      return {
        product: this.model
      };
    },

    childEvents: {
      'change': 'changeSlug'
    },

    modelEvents: {
      'change:texts': 'fetch'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();
      _.each(this.options.webshopConfig.getLanguages(), _.bind(function (lang) {
        this.collection.add({
          slug: this.model.getSlug(lang),
          lang: lang
        });
      }, this));
    },

    changeSlug: function (view, value) {
      this.model.setSlug(view.model.get('lang'), value).done(_.bind(function () {
        if(view.ui.history.is(':visible') && view.historyView) {
          view.historyView.model.set('slug', value);
          view.historyView.reload();
          view.historyView.footerView.fetch();
        }
      }, this)).fail(_.bind(function (data) {
        if (data && data.errorCode && data.slug) {
          var message = _.i18n('webshop.errors.' + data.errorCode);
          message = message.replace('%s', data.slug);
          AlertView.prototype.open('', message);
        } else {
          AlertView.prototype.open('', _.i18n('webshop.errors.unknown'));
        }

        this.model.fetch().done(_.bind(function () {
          _.each(this.collection.models, function (model) {
            model.set('slug', this.model.getSlug(model.get('lang')));
          }, this);
        }, this));
      }, this));
    }
  });
});
