
define('template!orderStatusCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group" data-field-name="code">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input" data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="displayInKanban">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orderstatus.displayInKanban") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n            <input type="checkbox" id="displayInKanban" class="cpz-checkbox js-keep-logged-in displayInKanban">\n        </div>\n      </div>\n        <div class="form-group checkbox-group" data-field-name="validatedStatus">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orderstatus.validatedStatus") )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="checkbox" id="validatedStatus" class="cpz-checkbox js-keep-logged-in validatedStatus">\n            </div>\n        </div>\n        <div class="form-group checkbox-group" data-field-name="commentStatus">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orderstatus.commentStatus") )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="checkbox" id="commentStatus" class="cpz-checkbox js-keep-logged-in commentStatus">\n            </div>\n        </div>\n        <div class="form-group checkbox-group" data-field-name="assignUserToOrder">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orderstatus.assignUserToOrder") )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="checkbox" id="assignUserToOrder" class="cpz-checkbox js-keep-logged-in assignUserToOrder">\n            </div>\n        </div>\n        <div class="form-group checkbox-group" data-field-name="unAssignUsers">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orderstatus.unAssignUsers") )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="checkbox" id="unAssignUsers" class="cpz-checkbox js-keep-logged-in unAssignUsers">\n            </div>\n        </div>\n        <div class="form-group" data-field-name="displayOrder">\n            <label class="col-sm-3 control-label overflow-hidden">' +
((__t = ( _.i18n("common.displayOrder") )) == null ? '' : __t) +
'</label>\n            <div class="col-sm-4">\n                <input type="number" class="form-control js-display-order js-info-input" data-field-name="displayOrder" value="' +
((__t = ( displayOrder )) == null ? '' : __t) +
'"/>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="description">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="description" placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="params">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('orderstatus.params') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="params" placeholder="' +
((__t = ( _.i18n('common.params') )) == null ? '' : __t) +
'">' +
((__t = ( params )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n        <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('orderstatus.refParentStatus') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <div class="js-select-ref-parent-status"></div>\n          </div>\n        </div>\n\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

