
define('template!customersDetailBodyContactView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="tab-layout-header js-tab-layout-header">\n    <div class="p-l-10 tab-layout-info row">\n      <div class="col-left name-holder js-name-holder m-t-5 col-lg-4 p-l-10">\n        <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n\n        <p class="p-code">' +
((__t = ( email )) == null ? '' : __t) +
'</p>\n      </div>\n      <div class="js-header-region"></div>\n      <span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n    </div>\n      <div class="navbar navbar-default navbar-md row m-b-0">\n        <div class="navbar-collapse collapse">\n          <ul class="p-l-10 nav navbar-nav">\n            ';
 _.each(customerContactTabs, function(tab, index) { ;
__p += '\n              <li class="  js-tab-title contact-tab';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '"  data-role="' +
((__t = ( tab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( tab.route )) == null ? '' : __t) +
'"><a href="#' +
((__t = ( tab.route )) == null ? '' : __t) +
'-tab" data-toggle="tab">' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n            ';
 }) ;
__p += '\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n<div id="customer-contact-tab-content" class="tab-layout-content js-tabs-region height-100">\n\t\t\t';
 _.each(customerContactTabs, function(tab, index) { ;
__p += '\n\t\t\t\t<div class="tab-pane';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( tab.role )) == null ? '' : __t) +
'"  data-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( tab.route )) == null ? '' : __t) +
'" id="' +
((__t = ( tab.name )) == null ? '' : __t) +
'-tab"></div>\n\t\t\t';
 }); ;
__p += '\n</div>';

}
return __p
};});

