define('pricePopoverView',[
  'module',
  'underscore',
  'marionette',
  'template!pricePopoverTpl'
], function(
  module,
  _,
  Marionette,
  datePickerPopoverTpl
) {
  'use strict';

  var PricePopoverView = Marionette.ItemView.extend({
    template: datePickerPopoverTpl,

    ui: {
      quantity: '.js-quantity',
      priceMin: '.js-price-min',
      priceMax: '.js-price-max',
      apply: '.js-apply'
    },

    events: {
      'click @ui.apply': 'onApply',
      'focusout' : 'onFocusOut',
      'keydown' : 'onKeyDown'
    },

    className: 'pricepicker-element',

    onKeyDown: function(e) {
      var code = e.keyCode || e.which;
      if (code === 8) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    onFocusOut: function() {
      // close popover when click outside
      /*setTimeout(_.bind(function(){
        this.trigger('close:popover');
      }, this), 0);*/
    },

    onRender: function() {
      this.$el.attr('tabindex', 0);
      //this.ui.dateFrom.datepicker(this._getDatePickerOptions()).datepicker( 'widget' ).attr('tabindex', 1);
      //this.ui.dateTo.datepicker(this._getDatePickerOptions()).datepicker( 'widget' ).attr('tabindex', 2);
    },

    onApply: function() {
      var quantity = this.ui.quantity.val().trim(),
          priceMin = this.ui.priceMin.val().trim(),
          priceMax = this.ui.priceMax.val().trim();
      if(quantity && (priceMin || priceMax)) {
          this.trigger('apply', {
              quantity: quantity,
              priceMin: priceMin,
              priceMax: priceMax
          });
          this.$el.popover('hide');
      }
    },

    onClearInputs: function() {
      this.$el.focus();
      if(this._clear) {
        this.ui.quantity.val('').change();
        this.ui.priceMin.val('').change();
        this.ui.priceMax.val('').change();
        this._clear = false;
      }
    },

    onShouldClear: function() {
      this._clear = true;
    }
  });

  module.exports = PricePopoverView;
});
