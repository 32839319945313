define('ordersLabelView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersLabelView'
], function (
  Module, 
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-label-view',
  
    onRender: function () {

      if(this.model.get('color')) {
        this.$el.css('background-color', this.model.get('color'));
        this.$el.attr('title', this.model.get('name'));
      }
    }
  });
});
