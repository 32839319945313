define('ordersDetailAttachmentsCustomerView',[
  'app',
  'module',
  'underscore',
  'settings',
  'attachmentsView',
  'entities/orders'
], function(
  App,
  Module,  
  _, 
  Settings,
  AttachmentsView
) {
  'use strict';
  
  Module.exports = AttachmentsView.extend({  

    setConfig() {
      return {       
        galleryTitle: 'Customer Attachment',
        canCopy: Settings.configValue('order.attachments.showCustomerAttachments') ? true : false, 
        copyButton: {          
          title: _.i18n('orders.detail.attachments.customer.copy-button.title')
        }
      };
    },
    
    fetch: function(first, pageSize) {   
      return this.model.getAttachments(first, pageSize);
    },

    uploadItem: function(file) {   
      return this.model.uploadAttachment(file);
    },

    deleteItem(attachment) {
      return this.model.deleteAttachment(attachment);
    },

    editItem: function(attachment) {
      App.navigate('attachments/attachments/' + this.model.get('secId') + '/' + attachment.get('secId') + '/customer', {trigger: true});
    },

    copyItem: function(attachment) {
      this.model.copyAttachmentToOrder(attachment, this.options.order.get('purchaseOrderId')).done(_.bind(function(newAttachment) {
        var model = App.request('order:model', newAttachment);
        this.options.attachmentsView.orderView.addAttachment(model);
      }, this));      
    }
  });  
});

