define('adminHtmlTemplatesCreateEditView',[
  'module',
  'underscore',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!adminHtmlTemplatesCreateEditTpl',
  'savingBehavior',
  'dynamicTextView'
], function (
  module,
  _,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  DynamicTextView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      subjectRegion: '.subject-region',
      contentRegion: '.content-region'
    },

    className: 'profile-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    initialize: function (options) {
      this.model = options.tModel;
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function () {
      $.material.init();

      this.subjectDtView = new DynamicTextView({
        model: this.model.get('subject'),
        noSaving: true,
        hideGlossary: true
      });
      this.subjectDtView.dataUpdated = _.bind(function () {
        this.triggerMethod('enable:cancel:confirm');
      }, this);
      this.getRegion('subjectRegion').show(this.subjectDtView);

      this.contentDtView = new DynamicTextView({
        model: this.model.get('content'),
        noSaving: true,
        hideGlossary: true,
        textAreaMode: true
      });
      this.contentDtView.dataUpdated = _.bind(function () {
        this.triggerMethod('enable:cancel:confirm');
      }, this);
      this.getRegion('contentRegion').show(this.contentDtView);
    },

    onConfirm: function () {
      this.model.save();
    },

    onSave: function () {
      this.model = null;
      this.triggerMethod('saved');
      this.hide();
    },

    onSaveError: function (model, response) {
      if (response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
