/**
 * Created by BFR on 24/02/2020.
 */
define('translationPrintZoneView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!translationPrintZoneTpl',
    'dynamicTextView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView
  ) {
    'use strict';
  
    var TranslationPrintZoneView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        nameRegion: '.name-region',
        itNameRegion: '.itname-region'
      },

      onShow: function () {
        this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);

        this.itNameDtView = new DynamicTextView({ model: this.model.get('itName')});
        this.getRegion('itNameRegion').show(this.itNameDtView);
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = TranslationPrintZoneView;
  });
