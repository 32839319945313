
define('template!mailingViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well">\n    ' +
((__t = ( _.i18n('account.mailling') )) == null ? '' : __t) +
'\n  </div>\n</div>';

}
return __p
};});

