
define('template!headerTasksListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="tasks-container"></div>\n    <div class="show-all">' +
((__t = ( _.i18n('common.show-all-tasks') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

