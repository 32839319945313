define('graphicalRequestKanbanBoardView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'template!graphicalRequestKanbanBoardViewTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var KanbanBoardView =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      states: '#states-container'
    },

    onShow: function() {
      $.material.init();
    }

  });

  module.exports = KanbanBoardView;
});
