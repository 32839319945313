define('webshopProductsFinderView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'events',
  'webshopProductsFinderListView',
  'template!webshopProductsFinderView'
], function (
    App,
    Module,
    Marionette,
    _,
    Events,
    ListView,
    Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-finder-view',

    regions: {
      listRegion: '.list-region'
    },

    initialize: function() {
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function() {
      this.listView = new ListView({
        onSelected: _.bind(function (models) {
          if(this.options.onSelected)
            this.options.onSelected(models);
          this.windowView.close();
        }, this)
      });
      this.getRegion('listRegion').show(this.listView);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'webshopProductsFinderView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          width: 1024,
          height: 600,
          minWidth: 600,
          minHeight: 400,
          title: _.i18n('webshop.products.finder')
        });
      });
    }
  });
});

