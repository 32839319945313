/**
 * Created by OBL on 14-12-15.
 */
define('cataloguesSharedView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!cataloguesSharedTpl',
  'imageUtils'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl,
    imageUtils
    ) {
    'use strict';

    var CataloguesSharedView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'CataloguesSharedFilters',


        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click .preview-sharedCatalogue' : 'onPreviewCatalogue',
                'click .delete-shared-link': 'onDeleteCatalog'
            }, this.gridEvents);
        },

        onPreviewCatalogue: function(event){
            this.trigger('catalogueShared:preview', $(event.currentTarget).attr('data-row-id'));
        },

        onDeleteCatalog: function(event){
            this.trigger('catalogueShared:remove', $(event.currentTarget).find('span').attr('data-row-id'));
        },

        serializeData: function() {
            var templateData = {catalogue: this.options.data};

            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage'),
                currentPage: 1
            };
        },

        gridOptions: function(data) {
          var options = {
            colModel: [
              {
                label: '',
                name: 'secId',
                key: true,
                hidden: true
              },
              {
                label: '',
                name: '',
                classes: 'catalogue-link',
                formatter: this.cataloguePictureFormatter,
                search: false,
                index: 'preview'
              },
              {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'catalogue-link',
                formatter: this.defaultFormatter,
                search: true,
                index: 'code'
              },
              {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'catalogue-link',
                formatter: this.defaultFormatter,
                search: true,
                index: 'name'
              },
              {
                label: _.i18n('common.cAccount'),
                name: 'cAccountCode',
                classes: 'catalogue-link',
                formatter: this.cAccountFormatter,
                search: false,
                index: 'name'
              },
              {
                label: ' ',
                name: 'preview',
                classes: 'catalogue-share-link jq-icon',
                formatter: this.previewIconFormatter,
                search: false,
                index: 'name',
                width: 20
              },
              {
                label: ' ',
                name: 'delete',
                search: false,
                classes: 'delete-shared-link delete-link',
                formatter: this.deleteIconFormatter,
                width: 20
              }
            ]
          };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        },

        cataloguePictureFormatter: function (cellValue, options, rows) {
            var newCellValue = (rows && rows.pictureUid) ? Settings.url('file', rows.pictureUid, {size: 'thumbnail'}) :
                Settings.get('defaultCatalogueImage');
            return '<span><img data-link="' + newCellValue + '"' +
                'class="jq-picture js-image-cell" src="' + newCellValue + '"/></span>';
        },

        previewIconFormatter: function(cellValue, options) {
            return '<span class="mdi-action-visibility cell-action preview-sharedCatalogue" ' +
                'data-row-id="' + options.rowId + '"></span>';
        },
      cAccountFormatter: function (cellValue, options, rowData) {
        var imageUrl = imageUtils.getLogo(rowData.cAccountLogo, rowData.cAccountCode);
        return '<span>' +
          '<img class="img-responsive caccount-logo" src="' +
          imageUrl +
          '"> </span>';
      }
    });



    module.exports = CataloguesSharedView;
});
