
define('template!pimProductPriceCalculatorItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-2 amount">  \n    <div class="col"> \n        <span>' +
((__t = ( quantity )) == null ? '' : __t) +
'</span>\n    </div> \n</div>\n<div class="col-xs-3 unit-price">   \n    <div class="col">\n        <span>' +
((__t = ( Number(unitPrice).toFixed(2) )) == null ? '' : __t) +
'€</span>\n    </div>\n</div>\n<div class="col-xs-4 price">\n    <div class="col">\n        <span>' +
((__t = ( Number(sPrice).toFixed(2) )) == null ? '' : __t) +
'€</span>\n    </div>\n</div>\n<div class="col-xs-3 factor prices-editable">\n    <div class="col">\n        <input type="text" class="txt-factor" style="display: none;" value="' +
((__t = ( Number(factor).toFixed(2) )) == null ? '' : __t) +
'" />\n        <span class="lbl-factor">' +
((__t = ( Number(factor).toFixed(2) )) == null ? '' : __t) +
'</span>\n    </div>\n</div>\n';

}
return __p
};});

