define('ordersDetailOrderClientVatView',[
  'module',
  'marionette',
  'underscore',
  'customerUtils',
  'modalsAlertView',
  'template!ordersDetailOrderClientVatView'
], function (
  Module,
  Marionette,
  _,    
  CustomerUtils,
  AlertView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-vat-view item',

    ui: {
      value: '.value .vat',
      copyButton: '.copy',
      checkButton: '.check',
      status: '.check-vat-status'
    },  
    
    events: {
      'click @ui.copyButton': 'onCopy',
      'click @ui.checkButton': 'onCheck'
    },

    modelEvents: {      
      'change:vatNumber': 'renderContent',
      'change:vatNumberChecked': 'renderContent',
      'change:vatNumberValid': 'renderContent'
    },   
    
    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      if (this.model.get('vatNumber'))
        this.ui.value.html(this.model.get('vatNumber'));
      else
        this.ui.value.html('-');

      //-------------------

      if (this.model.get('vatNumberChecked') === true) {
        if (this.model.get('vatNumberValid') === true)
          this.ui.status.find('path').attr('fill', 'green');
        else
          this.ui.status.find('path').attr('fill', 'red');
      }
    },

    onCopy: function () {
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(this.model.get('vatNumber')).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onCheck: function () {
      this.model.checkVat().done(_.bind(function (valid, text) {
        if (valid)
          AlertView.prototype.open('', _.i18n('common.vat.valid') + ' ' + text, null, 'success');
        else
          AlertView.prototype.open('', _.i18n('common.vat.not-valid'));

        this.renderContent();
      }, this)).fail(_.bind(function() {
        AlertView.prototype.open('', _.i18n('vat.checkVatError'));
      }, this));
    }
  });
});
