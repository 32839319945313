define('productGridPaginationView',[
  'module', 
  'marionette',
  'template!productGridPaginationTpl'
], function(
  module, 
  Marionette,
  gridPaginationTpl
) {
  'use strict';
  
  var PaginationView = Marionette.ItemView.extend({
    template: gridPaginationTpl,

    ui: {
      first: '.js-first',
      last: '.js-last',
      next: '.js-next',
      prev: '.js-prev'
    },

    events: {
      'click @ui.next': '_next',
      'click @ui.first': '_first',
      'click @ui.prev': '_prev',
      'click @ui.last': '_last'
    },

    modelEvents: {
      change: 'render'
    },

    initialize: function() {
      this.model.set('totalPages', Math.floor(this.model.get('totalCount') / this.model.get('pageSize')));
      if(this.model.get('totalCount') % this.model.get('pageSize')) {
        this.model.set('totalPages', this.model.get('totalPages') + 1);
      }
      this.model.set('currentPage', 1, {silent: true});

      this.model.set('last', this._getLast(this.model.get('first')));
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.hasPrev = this.model.get('currentPage') !== 1;
      templateData.hasNext = this.model.get('currentPage') !== this.model.get('totalPages');

      return templateData;
    },

    _getLast: function(first) {
      return first + this.model.get('pageSize') < this.model.get('totalCount') ?
        first + this.model.get('pageSize') : this.model.get('totalCount');
    },

    _next: function() {
      var first;

      if(this.model.get('currentPage') === this.model.get('totalPages')) {
        return;
      }
      first = this.model.get('first') + this.model.get('pageSize');
      this.model.set({
        currentPage: this.model.get('currentPage') + 1,
        first: first,
        last: this._getLast(first)
      });
    },

    _last: function() {
      var first;
      if(this.model.get('currentPage') === this.model.get('totalPages')) {
        return;
      }

      first = (this.model.get('totalPages') - 1) * this.model.get('pageSize');
      this.model.set({
        currentPage: this.model.get('totalPages'),
        first: first,
        last: this._getLast(first)
      });
    },

    _prev: function() {
      var first;

      if(this.model.get('currentPage') === 1) {
        return;
      }
      first = this.model.get('first') - this.model.get('pageSize');
      this.model.set({
        currentPage: this.model.get('currentPage') - 1,
        first: first,
        last: this._getLast(first)
      });
    },

    _first: function() {
      if(this.model.get('currentPage') === 1) {
        return;
      }
      this.model.set({
        currentPage: 1,
        first: 0,
        last: this._getLast(0)
      });
    }
  });
  
  module.exports = PaginationView;
});
