
define('template!headerCaccountAutocompleteTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="available-caccount js-available-caccount">\n  <a>\n    <div class="col-xs-3 available-caccount-logo"  style="background-image:url(' +
((__t = ( url )) == null ? '' : __t) +
')"></div>\n    <div class="col-xs-9 caccount-name">' +
((__t = ( autocompleteValue )) == null ? '' : __t) +
'</div>\n  </a>\n</li>';

}
return __p
};});

