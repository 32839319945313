/**
 * Created by OLD on 25/01/2016.
 */
define('caccountContactsView',[
  'module',
  'marionette',
  'underscore',
  'settings',
  'jqgridView',
  'template!caccountContactsTabTpl'
], function (
  module,
  Marionette,
  _,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CAccountsContactsView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountContactsListFilters',

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    triggers: {
      'click @ui.addNew': 'onAddNewContacts'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        addNew: '.js-add-new',
        deleteIcon: '.cell-delete'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteIcon' : 'onDeleteItem'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var that = this, 
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('caccountContact.name'),
              name: 'name',
              formatter: this.getContactsNameFormatter,
              classes: 'cell-link',
              search: true,
              index: 'name',
              width: 100
            }, {
              label: _.i18n('caccountContact.firstname'),
              name: 'firstName',
              formatter: this.getContactsFirstNameFormatter,
              classes: 'cell-link',
              search: true,
              index: 'firstName',
              width: 100
            }, {
              label: _.i18n('caccountContact.lastname'),
              name: 'lastName',
              formatter: this.getContactsLastNameFormatter,
              classes: 'cell-link',
              search: true,
              index: 'lastName',
              width: 100
            }, {
              label: _.i18n('caccountContact.email'),
              name: 'email',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'email',
              width: 100
            }, {
              label: _.i18n('caccountContact.comment'),
              name: 'comment',
              formatter: this.getContactsCommentFormatter,
              classes: 'cell-link',
              search: true,
              index: 'comment',
              width: 100
            }, {
              label: '',
              name: '',
              classes: 'delete-link',
              formatter: this.deleteIconFormatter,
              search: false,
              width: 50
            }
          ],

          onSelectRow: function(rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              that.trigger('onDetailItem', rowid);
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    getContactsNameFormatter: function(cellvalue, options, rowObject) {
      if (!_.isNull(rowObject.refAddress)) {
        return  rowObject.refAddress.name;
      } else {
        return '<span></span>';
      }
    },

    getContactsFirstNameFormatter: function(cellvalue, options, rowObject) {
      if (!_.isNull(rowObject.refAddress)) {
        return  rowObject.refAddress.firstName;
      } else {
        return '<span></span>';
      }
    },

    getContactsLastNameFormatter: function(cellvalue, options, rowObject) {
      if (!_.isNull(rowObject.refAddress)) {
        return  rowObject.refAddress.lastName;
      } else {
        return '<span></span>';
      }
    },

    getContactsCommentFormatter: function(cellvalue, options, rowObject) {
      if (!_.isNull(rowObject.refAddress)) {
        return  rowObject.refAddress.comment;
      } else {
        return '<span></span>';
      }
    },

    onDeleteItem: function(e) {
      var target = $(e.currentTarget),
        itemId = target.data('row-id');

      this.trigger('onDeleteItem', itemId);
    }
  });

  module.exports = CAccountsContactsView;
});
