/**
 * Created by GSP on 15/02/2016.
 */
/**
 * Created by GSP on 15/02/2016.
 */
define('dropdownEmptyStateView',['module',
        'jquery',
        'underscore',
        'marionette',
        'template!dropdownEmptyStateTpl'],
  function(module,
           $,
           _,
           Marionette,
           dropDownItemTpl) {
    'use strict';
    var DropDownItem = Marionette.ItemView.extend({
      tagName: 'li',
      className: 'dropdown__item',
      template: dropDownItemTpl

    });

    return DropDownItem;
  });
