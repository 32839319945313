/**
 * Created by BFR on 14/04/2021.
 */
define('adminImprintTechnologiesView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!adminImprintTechnologiesTpl',
  'adminImprintTechnologiesCreateEditView'
], function (
  module,
  $,
  Marionette,
  _,
  Backbone,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'imprintTechnologiesFilters',

    onDeleteEndUser: function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.trigger('imprint-technologies:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click .js-add-new': 'onAddNew'
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              formatter: this.defaultFormatter,
              classes: 'code-link',
              search: true,
              index: 'code',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              formatter: this.dynamicTextFormater,
              classes: 'cell-link',
              search: true,
              index: 'name',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('imprintTechnology.colorModeType'),
              name: 'colorModeType',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: false,
              index: 'colorModeType',
              sortable: false,
              width: 30
            }, {
              label: _.i18n('imprintTechnology.maxNumberOfColors'),
              name: 'maxNumberOfColors',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: false,
              index: 'maxNumberOfColors',
              sortable: false,
              width: 30
            }, {
              label: _.i18n('imprintTechnology.vrpWarningMessage'),
              name: 'vrpWarningMessage',
              formatter: this.dynamicTextFormater,
              classes: 'cell-link',
              search: false,
              index: 'vrpWarningMessage',
              sortable: false,
              width: 55
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEditItem(rowid);
            }
          }, this)
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onGridFilterChange: function (e) {
      this.onGridFilterChangeV2(e);
    },

    onEditItem: function (secId) {
      var m = App.request('imprint-technologies:model', {secId: secId});
      m.fetch().done(_.bind(function () {
        var createEditView = new CreateEditLayoutView({
          itModel: m,
          newItem: false
        });
        createEditView.show({
          title: _.i18n('common.edit')
        });
        createEditView.listenTo(createEditView, 'saved', _.bind(function () {
          this.updatePagination(true);
        }, this));
      }, this));
    },

    onAddNew: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var createEditView = new CreateEditLayoutView({
        itModel: App.request('imprint-technologies:model'),
        newItem: true
      });
      createEditView.show({
        title: _.i18n('common.new')
      });
      createEditView.listenTo(createEditView, 'saved', _.bind(function () {
        this.updatePagination(true);
      }, this));
    }
  });
});
