define('filtersBannerQuickAccessView',[
  'module',
  'marionette',
  'underscore',
  'template!filtersBannerQuickAccessView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'filters-banner-quick-access-view',

    ui: {
      searchButton: '.search-button'
    },

    events: {
      'click': 'onClick'
    },

    onClick: function(e) {
      e.stopPropagation();
    }
  });
});
  
