/**
 * Created by BFR on 18/02/2020.
 */
define('productTranslationsTabView',[
  'module',
  'marionette',
  'underscore',
  'dynamicTextView',
  'template!productTranslationsTabTpl',
  'translationCatsView',
  'translationPrintAreasView'
], function(
  module, Marionette,
  _,
  DynamicTextView,
  viewTpl,
  TranslationCatsView,
  TranslationPrintAreasView
) {
  'use strict';

  var ProductTranslationsView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      nameRegion: '.name-region',
      translationCats: '.translation-cats',
      translationPrintAreas: '.translation-printareas'
    },

    ui: {
    },

    events: {
    },

    onShow: function () {
      this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
      this.getRegion('nameRegion').show(this.nameDtView);

      this.translationCatsView = new TranslationCatsView({ collection: this.model.get('productOptionCategories')});
      this.getRegion('translationCats').show(this.translationCatsView);

      this.translationPrintAreasView = new TranslationPrintAreasView({ collection: this.model.get('printAreas')});
      this.getRegion('translationPrintAreas').show(this.translationPrintAreasView);
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      return templateData;
    }
  });

  module.exports = ProductTranslationsView;
});
