define('ordersDetailOrderProductStockIconView',[
  'app',
  'module',
  'backbone',
  'underscore', 
  'tableFieldView',
  'userView',
  'template!ordersDetailOrderProductStockIconView'
], function (
  App,
  Module,
  Backbone,
  _,
  FieldView,
  UserView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-order-product-stock-icon-view table-field-view',

    ui: {
      done: '.done',
      info: '.item'
    },

    serializeData: function () {
      var data = {};
      data.title = '';

      if (this.model.get('upToDate')) {
        if (this.model.get('lastUpdateMinutes'))
          data.title = _.i18n('common.lastUpdate') + ' ' + this.model.get('lastUpdateMinutes') + ' ' + _.i18n('common.minutesAgo');
        else
          data.title = _.i18n('common.lastUpdate') + ' ' + this.model.get('lastUpdate') + ' ' + _.i18n('common.hoursAgo');
      } else
       data.title = _.i18n('common.lastUpdate') + ' ' + this.model.get('lastUpdate') + ' ' + _.i18n('common.hoursAgo');

      return data;
    },

    onRender: function () {
      if(this.model.get('upToDate')) {
        this.ui.done.css('display', 'flex');
        this.ui.info.hide();
      } else {
        this.ui.done.hide();
        this.ui.info.css('display', 'flex');
      }
    }
  });
});
