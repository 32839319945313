/**
 * Created by BFR on 29/01/2018.
 */
define('orderStatusDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!orderStatusDetailsTpl',
  'orderStatusTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  orderStatusTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var OrderStatusDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.orderstatus-tab',
      tabPane: '.tab-pane',
      code: '.p-code',
      name: '.p-name'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle'
    },

    regions: {
      orderStatusDetailTab: '#detail-tab'
    },

    behaviors: function() {
        return {
            BackToList: {
                behaviorClass: BackToListBehavior,
                redirectUrl: this.backToListUrl()
            }
        };
    },

      backToListUrl: function() {
        return 'caccount/' + Settings.get('currentCAccount').secId + '/orderstatus';
      },


    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.orderStatusTabs = orderStatusTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.orderstatus-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(orderstatus) {
      this.ui.code.text(orderstatus.code);
      this.ui.name.text(orderstatus.name);
    }
  });

  module.exports = OrderStatusDetailsView;
});
