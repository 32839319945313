define('pimResellerProductCodeItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'template!pimResellerProductCodeTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var PimResellerProductCodeItemView = Marionette.ItemView.extend({
    tagName: 'a',
    className: 'reseller-product-code',
    template: viewTpl,

    attributes: function() {
      return {
        'data-selected': true
      };
    },
    
    /*initialize: function(options){     
    },*/

    serializeData: function () {
      var templateData = {};

      templateData.text = this.model.get('code');
      return templateData;
    },
    ui: {
      btnClick: '.reseller-product-code'
    },
    events: {
      'click': 'onClick'
    },
    onClick: function() {      
      var sUrl='pim/products/'+ this.options.supId + '/resid/' + this.model.get('id');
      App.navigate(sUrl, true);
    }
  });   

  module.exports = PimResellerProductCodeItemView;
});
