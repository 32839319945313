define('catalogueMineSharingTabController',[
  'module',
  'catalogueMineSharingTabView'
], function(
  module,
  SharingTabView
) {
  'use strict';

  var SharingTabController = {
    showSharingTab: function(region, catalogueModel) {
      region.show(new SharingTabView({
        catalogueSecId: catalogueModel.get('secId')
      }));
    }
  };

  module.exports = SharingTabController;
});
