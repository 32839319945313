define('headerCompuzzView',[
  'module',
  'marionette',
  'underscore',
  'imageUtils',
  'settings',
  'template!headerCompuzzView'
], function(
  Module,
  Marionette,
  _,
  ImageUtils,
  Settings,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-compuzz-view',

    serializeData: function() {
      var data = {};

      data.url = Settings.url('base', Settings.get('compuzzLogo'));
      data.name = 'Compuzz';

      var account = Settings.get('currentCAccount');

      if (account) {
        if (account.refMainCAccountJson) {
          data.url  = ImageUtils.getLogo(
            account.refMainCAccountJson.logo,
            account.refMainCAccountJson.code, {
              action: 'res',
              params: 't'
            }
          );

          data.name = account.refMainCAccountJson.name;
        }
      }
     
      return data;
    }
  });
});
