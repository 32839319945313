define('buttonView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'template!buttonView'
], function (
  Module,
  Marionette,
  _,
  Events,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'button-view',

    attributes: function () {
      return {
        'title': this.options.title || '',
        'type': this.options.type || '',
        'tabindex': this.options.tabindex || 0
      };
    },

    events: {
      'click': 'onClick',
      'focus': 'onFocus',
      'blur': 'onBlur',
      'mousedown': 'onMouseDown'
    },

    initialize: function() {
      this.focused = false;
      this.active = true;

      if(this.options.enabled === false)
        this.active = false;

      this.enabled( this.active);
    },

    onDestroy: function() {
      Events.pop(this);
    },

    serializeData: function() {
      var data = {};

      data.type = '';
      if(this.options.type)
        data.type = this.options.type;

      data.text = '';
      if(this.options.text)
        data.text = this.options.text;

      data.icon = '';
      if(this.options.icon)
        data.icon = this.options.icon;

      return data;
    },

    enabled: function(value) {
      this.active = value;
      if(!this.active)
        this.$el.addClass('disabled');
      else
        this.$el.removeClass('disabled');
    },

    onClick: function() {
      if(!this.active)
        return;

      this.trigger('click', this);
    },

    onFocus: function() {
      Events.push(this);
      this.focused = true;
    },

    onBlur: function() {
      Events.pop(this);
      this.focused = false;
    },

    onMouseDown: function(e) {
      e.stopPropagation();
    },

    onKeyDown: function(e) {
      if ((e.which === 13 || e.which === 32) && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        this.trigger('click', this);
        return false;
      }
    }
  });
});
  
