define('app',[
  'require',
  'exports',
  'module',
  'jquery',
  'backbone',
  'marionette',
  'settings',
  'browserUtils',
  'gmailUtils',
  'windows',
  'events',
  'translation',
  'outdatedBrowser',  
  'slick',
  'autogrow'
], function (
  require,
  exports,
  module,
  $,
  Backbone,
  Marionette,
  Settings,
  BrowserUtils,
  GmailUtils,
  Windows,
  Events
) {
    'use strict';

    // Create the  Application
    var app = new Marionette.Application();   

    Windows.init(app);
    Events.init(app);
    GmailUtils.getInstance();

    //Encapsulate the backbone navigation into the application
    app.navigate = function (route, options) {
      options = options || {};     

      Backbone.history.navigate(route, options);
    };
          
    //Enable to get the current route from backbone
    app.getCurrentRoute = function () {
      return Backbone.history.fragment;
    };

    app.setAuthCookiesValues = function () {
      var cookieCAccount = Settings.get('currentCAccountCookie'),
        cookieUser = Settings.get('currentUserCookie'),
        currentCAccount, currentUser;

      if (cookieCAccount) {
        currentCAccount = JSON.parse(cookieCAccount);
      }
      if (cookieUser) {
        currentUser = JSON.parse(cookieUser);
      }

      Settings.set('currentUser', currentUser);
      Settings.set('currentCAccount', currentCAccount);
      Settings.set('lang', Settings.get('lang') || BrowserUtils.getLanguage());

    };

    app.clearAuthCookieValues = function () {
      Settings.set('tokenId', null);
      Settings.set('currentUser', null);
      Settings.set('currentUserCookie', null);
      Settings.set('currentCAccountCookie', null);
      Settings.set('currentCAccount', null);
    };

    app.startSubModule = function (moduleName, args) {
      var currentModule = moduleName ? moduleName : null;

      if (currentModule && currentModule !== '') {
        require([moduleName], function (module) {
          currentModule = module;
          if (app.currentModule === currentModule) { return; }

          if (app.currentModule) {
            app.currentModule.stop();
          }

          app.currentModule = currentModule;
          if (currentModule) {
            currentModule.start(args);
          }
        });
      }
    };

    app.on('before:start', function () {
      app.setAuthCookiesValues();
      //Manage the out of date browser
      $(document).ready(function () {
        outdatedBrowser({
          bgColor: '#f25648',
          color: '#ffffff',
          lowerThan: 'transform',
          languagePath: '../bower_components/outdatedbrowser/lang/' + Settings.get('lang') + '.html'
        });
      });

      //load translations
      var RegionContainer = Marionette.LayoutView.extend({
        el: '#app-container',
        regions: {
          main: '#main-region'
        }
      });
      app.regions = new RegionContainer();
      app.regions.getRegion('main').on('show', function () {

        Windows.show($('._compuzz-dashboard-body'), $('._compuzz-dashboard-content'), $('._compuzz-dashboard'));

        try {
          var user, account;
          if (Settings.get('environment') !== 'adtv2') {
            window.Bugsnag.releaseStage = 'development';
          }
          window.Bugsnag.appVersion = Settings.get('version');
          account = Settings.get('currentCAccount');
          user = Settings.get('currentUser');
          window.Bugsnag.user = { };
          if (account) {
            window.Bugsnag.user.name = account.code;
          }
          if (user) {
            window.Bugsnag.user.email = user.logonId;
          }
        } catch(e) { }

        var currentUser = Settings.get('currentUser');
        var currentCAccount = Settings.get('currentCAccount');
        var chatData = {
          userName: 'guest-email',
          userCAccount: 'guest-code'
        };
        if (currentUser && currentCAccount) {
          if (currentUser.hasOwnProperty('logonId')) {
            chatData.userName = currentUser.logonId;
          }
          if (currentCAccount.hasOwnProperty('code')) {
            chatData.userCAccount = currentCAccount.code;
          }
        }

      });
    });

    //Fires after the Application has started and
    // after the initializer have been executed.
    app.on('start', function () {
      if (Backbone.history) {
        Backbone.history.start();
      }
    });

    //Additional jquery methods
    //-------------------------------
    if ($.when.all === undefined) {
      $.when.all = function (deferreds) {
        var deferred = new $.Deferred();
        $.when.apply($, deferreds).then(
          function () {
            deferred.resolve(Array.prototype.slice.call(arguments));
          },
          function () {
            deferred.fail(Array.prototype.slice.call(arguments));
          });

        return deferred;
      };
    }   

    module.exports = app;
  });

