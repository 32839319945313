define('caccountCardsColorsView',['module',
    'marionette',
    'caccountCardsColorView'],
  function (module,
            Marionette,
            CardsColorView) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
      childView: CardsColorView,
      className: '',

      childEvents: {
        'item:selection:change': function () {

        }
      }
    });
  });
