
define('template!googleImageItemsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="google-image-items col-xs-12 p-l-0 p-r-0"></div>\n<div class="col-xs-12 show-more-container" style="display: none;">\n  <span class="btn-show-more clickable" style="' +
((__t = ( showMore?'display: none;':'' )) == null ? '' : __t) +
'">Show more</span>\n  <span class="btn-show-less clickable" style="' +
((__t = ( showMore?'':'display: none;' )) == null ? '' : __t) +
'">Show less</span>\n</div>\n';

}
return __p
};});

