define('filtersBannerFilterView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'template!filtersBannerFilterView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'filters-banner-filter-view',

    attributes: function() {
      return {
        'title': ''
      };
    },

    ui: {
      viewRegion: '.view-region'
    },

    events: {
      'click': 'onClick'
    },

    regions: {
      viewRegion: '.view-region'
    },

    serializeData: function() {
      var data = {};
      data.libelle = this.model.get('libelle');
      data.value = this.model.get('value');
      return data;
    },

    onShow: function() {
      if(this.model.get('view')) {
        this.getRegion('viewRegion').show(this.model.get('view'));
      }
    },

    onRender: function() {
      if(this.model.get('view'))
        this.ui.viewRegion.css('display', 'flex');
      else
        this.ui.viewRegion.css('display', 'none');
    },

    onClick: function(e) {
      e.stopPropagation();
      this.triggerMethod('close', this.model);
    }
  });  
});

