/**
 * Created by BFR on 13/02/2018.
 */
define('editEndUserAttachmentsController',[
  'module',
  'underscore',
  'app',
  'settings',
  'editEndUserAttachmentsPluginView'
], function(
  module,
  _,
  App,
  Settings,
  View
) {
  'use strict';

  var EditEndUserAttachmentsPluginController = {
    showPlugin: function(data) {
      var pluginUrl, view;
      
      pluginUrl = Settings.pluginUrl() +
        '?action=editEndUserAttachment' +
        '&endUserSecId=' + data.customerSecId +
        '&isAdmin=true' +
        '&tokenId=' + Settings.get('tokenId') +
        '&lang=' + Settings.get('lang');

      view = new View({url: pluginUrl});      
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);
    }
  };

  module.exports = EditEndUserAttachmentsPluginController;
});
