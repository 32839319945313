/**
 * Created by BFR on 07/02/2020.
 */
define('optionMixEditCollectionView',['module',
        'backbone',
        'underscore',
        'marionette',
        'optionMixEditItemView',
        'template!optionMixEditCollectionTpl'],
  function(module,
           Backbone,
           _,
           Marionette,
           OptionMixEditItemView,
           OptionMixEditCollectionTpl){
  'use strict';

  var OptionMixEditCollectionView = Marionette.CompositeView.extend({
    tagName: 'table',
    template: OptionMixEditCollectionTpl,
    childView: OptionMixEditItemView,
    childViewContainer: '.childViewOptions',
    collection: new Backbone.Collection(),
    className: 'table table-striped table-bordered table-hover',

    serializeData: function() {
      var templateData = { product: this.options.product };
      return templateData;
    },

    onBeforeDestroyCollection: function() {
      var model;
      while ((model = this.collection.first())) {
        model.destroy();
      }
    },

    getMixesConfiguration: function () {
      var mixes = [];
      _.each(this.children._views, function(mixView) {
        mixes.push(mixView.getMixConfiguration());
      });
      return mixes;
    }
  });

  module.exports = OptionMixEditCollectionView;
});
