define('ordersDetailOrderCompositionCustomPzSizeView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'template!ordersDetailOrderCompositionCustomPzSizeView'
], function (
  Module,
  App,
  Marionette,
  _,    
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-composition-custom-pz-size-view item editable',

    ui: {
      value: '.value',
      editButton: '.btn-edit-custom-pz-size',
      inputW: '.editW > input',
      inputH: '.editH > input'
    },
     
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.value': 'onEdit',
      'blur  @ui.inputW': 'onBlurClick',
      'click @ui.inputW': 'onInputClick',
      'blur  @ui.inputH': 'onBlurClick',
      'click @ui.inputH': 'onInputClick'
    },

    modelEvents: {      
      'change:name': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      }                
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },

    renderContent: function () {
      this.$el.attr('edit', this.edit);

      //-------------------

      if (this.options.compositionModel.get('customData') &&
          this.options.compositionModel.get('customData').pzWidth &&
          this.options.compositionModel.get('customData').pzHeight) {

        this.ui.value.html(this.options.compositionModel.get('customData').pzWidth + ' x ' +
            this.options.compositionModel.get('customData').pzHeight);
      }
    else
      this.ui.value.html('-');
    },

    onRender: function () {
      this.renderContent();
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = true;
      this.renderContent();     
      
      if(this.edit && this.options.compositionModel.get('customData')) {
        if (this.options.compositionModel.get('customData').pzWidth) {
          this.ui.inputW.val(this.options.compositionModel.get('customData').pzWidth);
        }
        if (this.options.compositionModel.get('customData').pzHeight) {
          this.ui.inputH.val(this.options.compositionModel.get('customData').pzHeight);
        }
        this.ui.inputW.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () { 

      if(!this.edit)
        return;

      if (!this.options.compositionModel.get('customData')) {
        this.options.compositionModel.set('customData', {});
      }

      if (!this.ui.inputW.val() && !this.ui.inputH.val()) {
        this.edit = false;
        this.renderContent();
      }

      if(((this.ui.inputW.val() !== this.options.compositionModel.get('customData').pzWidth) ||
          (this.ui.inputH.val() !== this.options.compositionModel.get('customData').pzHeight)) &&
          ((this.ui.inputW.val() && this.ui.inputH.val()) || (!this.ui.inputW.val() && !this.ui.inputH.val()))) {

        if (this.ui.inputW.val() && this.ui.inputH.val()) {
          this.edit = false;
          this.renderContent();
        }

        this.options.compositionModel.get('customData').pzWidth = this.ui.inputW.val();
        this.options.compositionModel.get('customData').pzHeight = this.ui.inputH.val();
        App.request('composition:update:customdata', this.model.get('purchaseOrderId'),
            this.options.compositionModel.get('secId'), this.options.compositionModel.get('customData'))
              .done(_.bind(function () {
          this.model.fetch();
        }, this));
      }         
    }
  });
});
