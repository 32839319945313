define('zoomPopover',['module', 'jquery', 'settings', 'underscore', 'marionette'],
function(module, $, Settings, _, Marionette) {
  'use strict';

  var ZoomPopoverBehavior = Marionette.Behavior.extend({
    defaults: {       
        'container': '#content'
    },
    
    onShow: function() {
      if(this.options.manualInit) {
        return;
      }
      this.attachPopover();
    },

    attachPopover: function() {
      var that = this,
        images = this.$('.js-image-cell'),
        link;

      _.each(images, function(image) {
        link = $(image).data('link');
        that.onShowImageZoomPopover(link, $(image));
      });
    },

    onShowImageZoomPopover: function (fileLink, target) {
      var content = '<div class="img-responsive">' +
        '<img class="img-popover" src="' + Settings.url('base', fileLink) + '"style="max-height:175px"/></div>';

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'right',
        html: 'true',
        container: $('#content')
      });
    }
  });

  module.exports = ZoomPopoverBehavior;
});
