define('customersDetailBodyMailsController',[
  'module',
  'logger',
  'underscore',
  'backbone',
  'app',
  'jquery',
  'customersDetailBodyMailsView',
  'gmailUtils',
  'emptyView',
  'entities/mails'
], function(
  Module,
  Logger,
  _,
  Backbone,
  App,
  $,
  MailsView,
  GmailUtils,
  EmptyView
) {
  'use strict';

  Module.exports =  {

    showTab: function(data, region) {
      this.region = region;

        if (data.model.contactSecId) {
            this.getMailsForContact(data);
        } else {
            this.getMails(data);
        }
    },

    getMails: function(data) {
      this.region.show(new EmptyView());

        if (this.gmailUtils) {
            this.gmailUtils.off('gmail_utils:loaded');
            this.gmailUtils.off('gmail_utils:signInNeeded');
        }

        if (!this.gmailUtils) {
            this.gmailUtils = GmailUtils.getInstance();
        }
        
        this.gmailUtils.on('gmail_utils:loaded', _.bind(function() {
            var mail = data.model.get('email');
            this.gmailUtils.listMessages(mail?mail:data.model.get('code'), _.bind(function(msgs) {
                msgs = _.sortBy(msgs, 'internalDate');

                this.view = new MailsView({
                    collection: new Backbone.Collection(msgs),
                    customer: data.model
                });
              this.region.show(this.view);
            }, this));
        }, this));

        this.gmailUtils.on('gmail_utils:signInNeeded', _.bind(function() {
            this.view = new MailsView({
                collection: null,
                customer: null
            });
          this.region.show(this.view);
        }, this));

        this.gmailUtils.connect();

    },

    getMailsForContact: function() {
    }
  };
});
