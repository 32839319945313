/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintAreaView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!sharePrintAreaTpl',
  'sharePrintZonesView',
  'entities/product_share'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  SharePrintZonesView
) {
  'use strict';

  var SharePrintAreaView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      sharePrintZones: '.share-printzones'
    },

    events: {
      'change .cbx-printarea': 'onSelectionChange'
    },

    onSelectionChange: function (event) {
      this.trigger('item:selection:change');
      var cbx = $(event.target);
      if (cbx.is(':checked')) {
        _.each(this.model.get('printZones').models, function (pz) {
          if (!pz.get('printZone')) {
            pz.set('printZone', {name: pz.get('parentPrintZone').get('name')});
          }
        });
        this.model.set('printArea', {});
      } else {
        _.each(this.model.get('printZones').models, function (pz) {
          if (pz.get('printZone')) {
            pz.set('printZone', null);
          }
        });
        this.model.set('printArea', null);
      }
      this.sharePrintZonesView.render();
    },

    onShow: function () {
      var col = this.model.get('printZones');
      if (this.model.get('parentPrintArea')) {
        var pa = this.findCheckedPrintArea(this.model.get('parentPrintArea').get('code'));
        col.checkedPrintZones = pa ? pa.printZones : [];
      } else {
        col.checkedPrintZones = [];
      }
      col.displayNew = this.options.model.collection.displayNew;
      col.modelProduct = this.model;
      this.sharePrintZonesView = new SharePrintZonesView(
        {
          collection: col,
          pa: this.model
        });
      this.sharePrintZonesView.listenTo(this.sharePrintZonesView, 'item:selection:change', _.bind(this.onItemSelectionChange, this));
      this.getRegion('sharePrintZones').show(this.sharePrintZonesView);
    },

    onItemSelectionChange: function () {
      this.trigger('item:selection:change');
    },

    findCheckedPrintArea: function (code) {
      return _.findWhere(this.options.model.collection.checkedPrintAreas, {
        code: code
      });
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.checked = templateData.printArea ? true : false;
      if (templateData.parentPrintArea) {
        templateData.width = templateData.parentPrintArea.width;
        templateData.height = templateData.parentPrintArea.height;
        templateData.unit = templateData.parentPrintArea.unit;
      } else {
        templateData.width = templateData.printArea.width;
        templateData.height = templateData.printArea.height;
        templateData.unit = templateData.printArea.unit;
      }

      if (this.options.model.collection.displayNew &&
        templateData.parentPrintArea &&
        !this.findCheckedPrintArea(templateData.parentPrintArea.code)) {
        templateData.newPa = true;
      } else {
        templateData.newPa = false;
      }

      return templateData;
    }
  });

  module.exports = SharePrintAreaView;
});
