/**
 * Created by OLD on 20/08/2015.
 */
define('graphicalRequestAllInOneRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'settings',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    Settings,
    ItemPagesNameMixin
) {
  'use strict';

  var GraphicalRequestAllInOneRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'graphical_service_all_in_one': 'showAllInOne',
      'graphical_service_all_in_one/:secId' : 'showAllInOne',
      'graphical_service_all_in_one/:secId/cs' : 'showAllInOneCs'
    }
  });

  var API = {
    showAllInOneCs: function(secId) {
      this.showAllInOne(secId, true);
    },

    showAllInOne: function(secId, csMsg) {
      require([
        'graphicalRequestAllInOneShowController',
        'contentShowController',
        'ticketsAllController'
      ], function(ShowController, ContentShowController, TicketsController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('graphicalRequestAllInOneMain');
          if ( !secId ) {
            ShowController.showAllInOne();
          } else if (secId === 'new' ) {
            ShowController.showAllInOne({isNew: true});
          } else if (secId && secId.indexOf('requester') !== -1) {
            Settings.setToMemory('graphicalRequestAllinOneFilters', {
              requester: secId.split('=')[1],
              pageSize: 10,
              sidx: 'userTimestamp.modDate',
              sord: 'desc'
            });
            App.navigate('graphical_service_all_in_one', {trigger: true});
          } else {
            TicketsController.showTickets(secId, csMsg);
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICALREQUESTS_ALL_IN_ONE);
        });
      });
    }
  };

  App.on('graphical_service_all_in_one:show', function(secId) {
    if (secId) {
      App.navigate('graphical_service_all_in_one/' + secId, {trigger: true});
    } else {
      App.navigate('graphical_service_all_in_one', {trigger: true});
    }
  });

  App.on('graphical_service_all_in_one:new', function() {
    App.navigate('graphical_service_all_in_one/new');
    API.showAllInOne('new');
  });

  App.on('before:start', function() {
    new  GraphicalRequestAllInOneRouter({
      controller: API
    });
  });

  module.exports = GraphicalRequestAllInOneRouter;
});
