/**
 * Created by BFR on 24/06/2020.
 */
define('customQuantitiesView',['module',
        'marionette',
        'customQuantityView',
        'dialogCollectionView',
        'template!customQuantitiesTpl'],
    function (module,
              Marionette,
              CustomQuantityView,
              DialogCollectionView,
              CustomQuantitiesTpl) {
        'use strict';

        module.exports = DialogCollectionView.extend({
            childViewContainer: '.custom-quantities',
            template: CustomQuantitiesTpl,
            childView: CustomQuantityView,
            className: 'col-md-12 p-t-10',

            events: {
                'click .btn-add': 'onBtnAddClick'
            },

            onBtnAddClick: function () {
              this.collection.add({quantity: 0});
            }
        });
    });
