/**
 * Created by BFR on 24/02/2020.
 */
define('translationPrintZonesView',['module',
        'marionette',
        'translationPrintZoneView'],
  function(module,
           Marionette,
           TranslationPrintZoneView){
  'use strict';

  var TranslationPrintZonesView = Marionette.CollectionView.extend({
    childView: TranslationPrintZoneView,
    className: 'col-md-12'
  });

  module.exports = TranslationPrintZonesView;
});
