define('windows',[
  'module',
  'settings',
  'underscore',
  'windowView',
  'windowModalView',
  'windowsManagmentView'
], function (
  Module,
  Settings,
  _,
  WindowView,
  WindowModalView,
  WindowsManagmentView
) {
  'use strict';

  Module.exports = {

    init: function (app) {
      this.app = app;
      this.views = [];
      this.popupViews = [];
      this.zIndex = 500;
      this.toolsView = null;
      this.modalView = null;
    },

    show: function (container, fullSizeContainer, modalContainer) {
      this.container = container;
      this.fullSizeContainer = fullSizeContainer;

      this.modalView = new WindowModalView();
      modalContainer.append(this.modalView.render().el);
      this.modalContainer = modalContainer;

      this.managmentView = new WindowsManagmentView({
        windows: this
      });

      this.container.append(this.managmentView.render().el);
    },

    open: function (params) {
      var config = {
        margin: false,
        modal: false
      };

      if (params.configName && Settings.windows[params.configName])
        config = _.extend(config, Settings.windows[params.configName]);

      if(params.width)
        config.width = params.width;
      if(params.height)
        config.height = params.height;
      if(params.minWidth)
        config.minWidth = params.minWidth;
      if(params.maxWidth)
        config.maxWidth = params.maxWidth;
      if(params.minHeight)
        config.minHeight = params.minHeight;
      if(params.maxHeight)
        config.maxHeight = params.maxHeight;
      if(params.margin)
        config.margin = params.margin;
      if(params.modal)
        config.modal = params.modal;

      var windowView = null;

      if(!config.modal) {
        _.each(this.views, function (view) {
          if (view.id === params.id)
            windowView = view;
        }, this);
      }

      if (!windowView) {
        this.onClose = params.onClose;
        windowView = new WindowView({
          windows: this,
          view: params.view(),
          id: params.id,
          title: params.title,
          config: config,
          zIndex: this.zIndex + this.views.length,
          bodyClass: params.bodyClass
        });

        windowView.view.windowView = windowView;

        if (!config.modal) {
          this.views.push(windowView);
          this.container.append(windowView.render().el);

          if (this.toolsView)
            this.toolsView.render();

          windowView.show();
          windowView.unminimize();
          windowView.focus();
        } else {
          this.modalView.open(windowView);
        }
      }
    },

    close: function (windowView) {
      if (!windowView.config.modal) {
        this.views.splice(this.views.indexOf(windowView), 1);

        _.each(this.views, function (windowViewToMove, index) {
          windowViewToMove.zIndex = this.zIndex + index;
          windowViewToMove.$el.css('z-index', windowViewToMove.zIndex);
        }, this);

        this.setNavigation();

        if (this.toolsView)
          this.toolsView.render();

        windowView.destroy();
      } else {
        this.modalView.close();
      }

      if (this.onClose) {
        this.onClose();
      }
    },

    focus: function (windowView) {
      if (windowView.config.modal)
        return;

      if (this.views.indexOf(windowView) !== this.views.length-1) {
        this.views.splice(this.views.length, 0, this.views.splice(this.views.indexOf(windowView), 1)[0]);
        _.each(this.views, function (windowViewToMove, index) {
          windowViewToMove.zIndex = this.zIndex + index;
          windowViewToMove.$el.css('z-index', windowViewToMove.zIndex);
        }, this);
      }

      if(windowView.view.url)
        this.app.navigate(windowView.view.url, {trigger: false});
    },

    minimize: function () {
      this.setNavigation();
    },

    setNavigation: function () {
      var focusedView = this.getFocusedView();

      if(focusedView && focusedView.view.url)
          this.app.navigate(focusedView.view.url, {trigger: false});
      else if(this.app.currentUrl)
          this.app.navigate(this.app.currentUrl, {trigger: false});
    },

    getFocusedView: function () {
      var focusedView = null;

      _.each(this.views, function (view) {
        if (!view.isMinimized()) {
          if (!focusedView)
            focusedView = view;
          else {
            if (view.zIndex > focusedView.zIndex)
              focusedView = view;
          }
        }
      }, this);

      return focusedView;
    }
  };
});
