define('pimProductFilterValuesView',[
  'module',
  'marionette',
  'pimProductFilterValueItemView'
], function (
  module,
  Marionette,
  PimProductFilterValueItemView
) {
  'use strict';

  module.exports = Marionette.CollectionView.extend({
    childView: PimProductFilterValueItemView
  });
});
