define('mailsListView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'mailsListSearchView',
  'mailsListMailsView',
  'template!mailsListView'
], function (
  App,
  Module,
  Marionette,
  _,
  SearchView,
  MailsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'list-view',

    regions: {
      searchRegion: '.search-region',
      mailsRegion: '.mails-region'
    },

    onRender: function() {
      this.mailsView = new MailsView({
        parentView: this
      });
      this.getRegion('mailsRegion').show(this.mailsView);

      //--------------------
      this.searchView = new SearchView({
        parentView: this
      });
      this.getRegion('searchRegion').show(this.searchView);
      this.searchView.on('search', this.onSearch, this);
    },

    onSearch: function(value) {
      this.mailsView.search(value);
    }
  });
});

