define('translationsTableController',[
    'module',
    'translationsTableView',
    'translationsTableLayoutView',
    'translationsTableFiltersView',
    'app',
    'underscore',
    'jquery',
    'imageUtils',
    'translationsFilterLanguagesLayoutView',
    'emptyView',
    'entities/translations',
    'entities/languages'
  ],
function(
  module,
  TranslationsTableView,
  TranslationsTableLayoutView,
  TranslationsTableFiltersView,
  App,
  _,
  $,
  imageUtils,
  FilterLanguageLayoutView,
  EmptyView
) {
  'use strict';

  var TranslationController = {
    translationCollection: App.request('translations:collection'),
    translationsLayoutView: undefined,
    viewsData: {isDisabled: false},

    showTranslations: function() {
      var that = this;
      this.translationsLayoutView = new TranslationsTableLayoutView();

      $.when(App.request('language:collection').getLanguages()).done(function(languageCollection) {
        that._checkAllLanguages(languageCollection);
        that.languageCollection = languageCollection;

        App.regions.getRegion('main').currentView.getRegion('content').show(that.translationsLayoutView);
        that._showFilters();
        that._showTableView();
      });

    },

    _showFilters: function() {
      var filtersView = new TranslationsTableFiltersView(),
        filterLanguageView = new FilterLanguageLayoutView({
        collection: this.languageCollection
      });

      this.translationsLayoutView.getRegion('filters').show(filtersView);
      filtersView.getRegion('languages').show(filterLanguageView);

      filtersView.listenTo(filtersView, 'translations:filter:code', _.bind(this.filterCode, this));
      filtersView.listenTo(filtersView, 'translations:filter:text', _.bind(this.filterText, this));
      this.translationsLayoutView.listenTo(filterLanguageView, 'show:dropdown', _.bind(this._disableDeletes, this));
      this.translationsLayoutView.listenTo(filterLanguageView, 'hide:dropdown', _.bind(this._enableDeletes, this));
    },

    _disableDeletes: function() {
      this.viewsData.isDisabled = true;
    },

    _enableDeletes: function() {
      this.viewsData.isDisabled = false;
    },

    _checkAllLanguages: function(languageCollection) {
      languageCollection.each(function(language) {
        language.set('checked', true);
      });
    },

    _showTableView: function() {
      var that = this,
        translationTableView,
        params = {
          code: that.translationCollection.filters.code,
          text: that.translationCollection.filters.text
        };
      that.translationsLayoutView.getRegion('table').show(new EmptyView());
      $.when(App.request('translations:count', params)).
        done(function(count) {
          that.translationCollection.fetch({
            success: function(collection) {
              translationTableView = new TranslationsTableView({
                collection: collection,
                totalCount: count,
                excelDownloadUrl: App.request('translations:excel:download'),
                languages: that.languageCollection,
                viewsData: that.viewsData
              });
              that.translationsLayoutView.getRegion('table').show(translationTableView);
              translationTableView.listenTo(translationTableView, 'translations:upload', _.bind(that.uploadTranslations, that));
            }
          });
        });
    },

    uploadTranslations: function(target) {
      var that = this,
        fileInfo = imageUtils.getFileInfo(target, 0),
        formData;

      if (!fileInfo) {
        throw 'Unable to get file info';
      }
      that.translationsLayoutView.getRegion('table').show(new EmptyView());
      imageUtils.encodeInputFileToBase64(target, 0, function(imgBytes){
        formData =  new FormData();
        formData.append('file', imgBytes);
        formData.append('filename', fileInfo.fileName);
        $.when(App.request('translations:upload', formData))
          .done(function() {
            that._showTableView();
          });
      });
    },

    filterCode: function(code) {
      this.translationCollection.filters.code = code;
      this._showTableView();
    },

    filterText: function(text) {
      this.translationCollection.filters.text = text;
      this._showTableView();
    }
  };

  module.exports = TranslationController;
});
