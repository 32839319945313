define('ordersListFiltersQuickAccessView',[
  'module',
  'marionette',
  'underscore',
  'filtersBannerQuickAccessView',
  'template!ordersListFiltersQuickAccessView'
], function (
  Module,
  Marionette,
  _,
  QuickAccessView,
  Tpl
) {
  'use strict';

  Module.exports = QuickAccessView.extend({
    template: Tpl,
    className: 'orders-list-filters-quick-access-view ' + QuickAccessView.prototype.className,

    ui: _.extend(QuickAccessView.prototype.ui, {
      user: '.user',
      poi: '.poi'
    }),

    events: _.extend(QuickAccessView.prototype.events, {
      'change @ui.user': 'onUserChange',
      'change @ui.poi': 'onPoiChange'
    }),

    modelEvents: {
      'change:endUserId': 'onRender',
      'change:purchaseOrderId': 'onRender'
    },

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      QuickAccessView.prototype.initialize.apply(this, arguments);
    },

    serializeData: function() {
      var data = {};
      data.type = this.tableView.options.type;
      data.endUserId = '';
      if(this.tableView.options.endUserId)
        data.endUserId = this.tableView.options.endUserId;
      return data;
    },

    onRender: function() {
      if(!this.tableView.options.endUserId)
        this.ui.user.val(this.model.get('endUserId'));
      this.ui.poi.val(this.model.get('purchaseOrderId'));
    },

    onUserChange: function() {
      if(!this.tableView.options.endUserId) {
        this.model.set('endUserId', this.ui.user.val().trim());
        this.trigger('confirm', this.model);
      }
    },

    onPoiChange: function() {
      this.model.set('purchaseOrderId', this.ui.poi.val().trim());
      this.trigger('confirm', this.model);
    }
  });
});
  
