define('phoneCallsTypeView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'template!phoneCallsTypeView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'table-field-view phone-calls-type-view',

    ui: {
      inbound: '.inbound',
      outbound: '.outbound'
    },   

    onRender: function() {
      if(this.model.get('type') === 'INBOUND')     
        this.ui.inbound.css('display', 'flex');
      
      if(this.model.get('type') === 'OUTBOUND')
        this.ui.outbound.css('display', 'flex');
    }
  });
});
