define('entities/product',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'underscore'
], function (
  App,
  $,
  Settings,
  Backbone,
  _
) {
  'use strict';

  var Product = Backbone.Model.extend({

    defaults: {
      skipCompuzzData: false
    },

    url: function () {
      return Settings.url('compuzz2', 'products.json/product/' + this.get('secId'), {loadproductcodevariation: true});
    },

    fetch: function (pim) {
      if (pim) {
        this.url = Settings.url('compuzz2', 'v2/pim/products/code/' + this.get('code'));
      } else {
        this.url = Settings.url('compuzz2', 'products.json/product/' + this.get('secId'), {loadproductcodevariation: true});
      }
      return Backbone.Model.prototype.fetch.call(this);
    },

    fetchByCode: function (pim) {
      if (pim) {
        this.url = Settings.url('compuzz2', 'v2/pim/products/code/' + this.get('code'));
      } else {
        this.url = Settings.url('compuzz2', 'products.json/' + this.get('code'), {loadproductcodevariation: true});
      }
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'products.json/' + this.get('secId'));
      this.set('customFields', null);
      return Backbone.Model.prototype.save.call(this);
    },

    refreshServerless: function () {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz3', 'products/' + this.get('secId') + '/refresh');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    fullFetch: function (code) {
      this.url = Settings.url('compuzz2', 'products.json/' + code, {
        loadPrintAreas: true,
        loadOptionCategories: true,
        loadProductPictures: true
      });
      return this.fetch();
    },

    idAttribute: 'secId'
  });

  var ProductSupplierCollection = Backbone.Collection.extend({
    fetch: function (params) {
      this.url = Settings.url('compuzz2', 'products.json/advancedSearch/supplier', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    getCount: function (params) {
      var that = this;

      that.trigger('count:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'products.json/advancedSearch/supplier/rowCount', params),
        success: function (count) {
          that.trigger('count:sync', count);
        }
      });
    },

    model: Product
  });

  var ProductCollection = Backbone.Collection.extend({
    fetch: function (params) {
      this.url = Settings.url('compuzz2', 'products.json/advancedSearch', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    getCount: function (params) {
      var that = this;

      that.trigger('count:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'products.json/advancedSearch/rowCount', params),
        success: function (count) {
          that.trigger('count:sync', count);
        }
      });
    },

    model: Product
  });

  var PrintZonePrice = Backbone.Model.extend({
    fetch: function (productSecId, params) {
      this.url = Settings.url('compuzz2', 'products.json/' + productSecId + '/printZones/pricesFactor', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    findByPzIdAndQuantity: function (pzId, quantity) {
      var prices = this.get(pzId.toString());
      if (prices) {
        for (var i = 0; i < prices.length; i++) {
          if (prices[i].quantity === Number(quantity)) {
            return prices[i].factor;
          }
        }
      }
      return null;
    },

    findCustomPriceByPzIdAndQuantity: function (pzId, quantity) {
      if (pzId) {
        var prices = this.get(pzId.toString());
        if (prices) {
          for (var i = 0; i < prices.length; i++) {
            if (prices[i].quantity === Number(quantity)) {
              return prices[i];
            }
          }
        }
      }
      return null;
    },

    clear: function (pzId) {
      this.set(pzId.toString(), []);
    },

    setPriceFactorForQuantity: function (pzId, quantity, factor) {
      var prices = this.get(pzId.toString());
      prices.push({
        quantity: Number(quantity),
        factor: Number(factor)
      });
    },

    setCustomPzPrice: function (pzId, quantity, pzPrice, setUpPrice, factor) {
      var prices = this.get(pzId.toString());
      prices.push({
        quantity: Number(quantity),
        price: Number(pzPrice),
        setUpPrice: Number(setUpPrice),
        factor: Number(factor)
      });
    }
  });

  var ProductPrice = Backbone.Model.extend({});

  var ProductPriceCollection = Backbone.Collection.extend({
    fetch: function (secId, params) {
      this.url = Settings.url('compuzz2', 'products.json/' + secId + '/pricesFactor', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    saveAll: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/' + secId + '/pricesFactor');
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(this.toJSON()),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    findByQuantity: function (quantity) {
      for (var i = 0; i < this.models.length; i++) {
        if (this.models[i].get('quantity') === Number(quantity)) {
          return this.models[i];
        }
      }
      return null;
    },
    model: ProductPrice
  });

  var API = {
    addProductToCatalogue: function (productSecId, catalogueSecId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'catalogues.json/secId/' + catalogueSecId + '/addItem/product/' + productSecId);
      $.ajax({
        type: 'PUT',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    addMultipleProductsToCatalogue: function (productsSecIds, catalogueSecId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'catalogues.json/secId/' + catalogueSecId + '/addItem/product');
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(productsSecIds),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    updatePrintZone: function (secId, printZone) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/product/' + secId + '/printzones');
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(printZone),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getPriceForConfiguration: function (secId, configuration, buyingPrice) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/' + secId + '/configuration/price', {buyingPrice: buyingPrice});
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(configuration),
        contentType: 'application/json',
        success: function (data) {
          if (data) {
            if (!data.totalHandlingCost) {
              data.totalHandlingCost = '0';
            }
            if (!data.shippingCost) {
              data.shippingCost = '0';
            }
          }
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    shareProduct: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/product/' + secId + '/share');
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    countProductToShare: function (filters) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz', 'products.json/advancedSearch/supplier/rowCount', _.extend({
        loadproductcodevariation: false,
        loadOptionCategories: false
      }, filters));

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getProductToShare: function (filters) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz', 'products.json/advancedSearch/supplier', _.extend({
        loadproductcodevariation: false,
        loadOptionCategories: false,
        pageSize: 1
      }, filters));

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    deleteProduct: function (productSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'products.json/product/' + productSecId);
      $.ajax({
        type: 'DELETE',
        url: url,
        success: function () {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    markSyncedDataFromSupplier: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/dataimporter/syncproducts/data/' + secId + '/marksynced');
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    updateDataFromSupplier: function (secId, cleanMissing) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/dataimporter/syncproducts/data/' + secId, {cleanMissing: cleanMissing});
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    reCreateVrpsFromSupplier: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/dataimporter/syncproducts/data/' + secId + '/vrps');
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    refreshProductChecksums: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/dataimporter/syncproducts/data/' + secId + '/refreshCheckSums');
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    refreshDataOnWebshop: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/' + secId + '/webshopDataRefresh');
      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getProductPriceFactorSup: function (secId) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/' + secId + '/pricesFactorSup');

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getPrintAreas: function (productCode) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/' + productCode + '/printareas');

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },
    getSupplierData: function (productCode) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/supplierinfo/' + productCode);

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(null);
        }
      });
      return defer.promise();
    },

    getLastCheckedData: function (productCode) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/lastchecked/' + productCode);

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getCustomFields: function (productCode) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'products.json/customfields/' + productCode);

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    updateProductProperties: function (data) {
      var url = Settings.url('compuzz2', 'products.json/update/lite'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    postTemplatePicture: function (productSecId, data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'products.json/' + productSecId + '/templateImage');
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    postCatalogPicture: function (productSecId, data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'products.json/' + productSecId + '/catalogPicture');
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('products:row-count-url', function () {
    return 'products.json/advancedSearch/rowCount';
  });

  App.reqres.setHandler('products:get-collection-url-by-catalogue', function (catalogueSecId) {
    return Settings.url('compuzz2', 'products.json/advancedSearch', API.getCatalogueQueryParam(catalogueSecId));
  });

  App.reqres.setHandler('products:row-count-url-by-catalogue', function (catalogueSecId) {
    return 'products.json/advancedSearch/rowCount?onlyFromCatalogue=true&catalogSecId=' + catalogueSecId;
  });

  App.reqres.setHandler('products:get-collection-url', function () {
    return Settings.url('compuzz2', 'products.json/advancedSearch?loadproductcodevariation=false&loadOptionCategories=false');
  });

  App.reqres.setHandler('products:model', function (productJSON) {
    return new Product(productJSON);
  });

  App.reqres.setHandler('products-supplier:count-with-filters', function (filters) {
    return API.countProductToShare(filters);
  });

  App.reqres.setHandler('products-supplier:load-with-filters-limit1', function (filters) {
    return API.getProductToShare(filters);
  });

  App.reqres.setHandler('products-supplier:share-product', function (secId) {
    return API.shareProduct(secId);
  });

  App.reqres.setHandler('products-supplier:get-collection-url', function () {
    return Settings.url('compuzz2', 'products.json/advancedSearch/supplier?loadproductcodevariation=false&loadOptionCategories=false');
  });

  App.reqres.setHandler('products-supplier:row-count-url', function () {
    return 'products.json/advancedSearch/supplier/rowCount';
  });

  App.reqres.setHandler('products-supplier:collection', function (productsJSON) {
    return new ProductSupplierCollection(productsJSON);
  });
  App.reqres.setHandler('products:collection', function (productsJSON) {
    return new ProductCollection(productsJSON);
  });
  App.reqres.setHandler('products:add-product-to-catalogue', function (productSecId, catalogueSecId) {
    return API.addProductToCatalogue(productSecId, catalogueSecId);
  });
  App.reqres.setHandler('products:add-multiple-products-to-catalogue', function (productsSecIds, catalogueSecId) {
    return API.addMultipleProductsToCatalogue(productsSecIds, catalogueSecId);
  });
  App.reqres.setHandler('products:get-price-for-configuration', function (secId, configuration, buyingPrice) {
    return API.getPriceForConfiguration(secId, configuration, buyingPrice);
  });
  App.reqres.setHandler('products:delete', function (secId) {
    return API.deleteProduct(secId);
  });
  App.reqres.setHandler('product-prices:collection', function (productsJSON) {
    return new ProductPriceCollection(productsJSON);
  });
  App.reqres.setHandler('product-printzone-prices:model', function (printZonesJSON) {
    return new PrintZonePrice(printZonesJSON);
  });

  App.reqres.setHandler('product-prices-factor-sup', function (secId) {
    return API.getProductPriceFactorSup(secId);
  });

  App.reqres.setHandler('products:update-data-from-supplier', function (secId, cleanMissing) {
    return API.updateDataFromSupplier(secId, cleanMissing);
  });

  App.reqres.setHandler('products:mark-synced-data-from-supplier', function (secId) {
    return API.markSyncedDataFromSupplier(secId);
  });

  App.reqres.setHandler('products:refresh-data-on-webshop', function (secId) {
    return API.refreshDataOnWebshop(secId);
  });

  App.reqres.setHandler('products:recreate-vrps-from-supplier', function (secId) {
    return API.reCreateVrpsFromSupplier(secId);
  });

  App.reqres.setHandler('products:refresh-product-checksums', function (secId) {
    return API.refreshProductChecksums(secId);
  });

  App.reqres.setHandler('products:refresh-product-checked', function (secId) {
    var defer = $.Deferred(),
      url;

    url = Settings.url('compuzz2', 'v2/dataimporter/syncproducts/data/' + secId + '/checked');
    $.ajax({
      type: 'POST',
      url: url,
      success: function (data) {
        defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('products:generate-printareas', function (secId, template) {
    var defer = $.Deferred(),
      url;
    url = Settings.url('compuzz2', 'v2/dataimporter/generate/printareas/' + secId, {template: template || 'textile'});
    $.ajax({
      type: 'POST',
      url: url,
      success: function (data) {
        defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('products:update-printzone', function (secId, printZone) {
    return API.updatePrintZone(secId, printZone);
  });

  App.reqres.setHandler('products:replace-product', function (oldSecId, newSecId) {
    var defer = $.Deferred();
    $.ajax({
      type: 'POST',
      url: Settings.url('compuzz2', 'products.json/replace/' + oldSecId + '/by/' + newSecId),
      success: function (data) {
        defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('products:get-product', function (index, filters) {
    var defer = $.Deferred(),
      url;
    if (!filters) {
      filters = {};
    }
    filters.pageSize = 1;
    filters.first = index;

    url = Settings.url('compuzz2', 'products.json/advancedSearch', filters);
    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        defer.resolve(data[0]);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('products:skip-compuzz-data', function (productSecId, enable) {
    var defer = $.Deferred();
    $.ajax({
      type: 'PUT',
      url: Settings.url('compuzz2', 'products.json/' + productSecId + '/skipCompuzzData', {enable: enable}),
      success: function (data) {
        defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('product:get-printareas', function (productCode) {
    return API.getPrintAreas(productCode);
  });

  App.reqres.setHandler('product:get-supplier-data', function (productCode) {
    return API.getSupplierData(productCode);
  });

  App.reqres.setHandler('product:get-last-checked-data', function (productCode) {
    return API.getLastCheckedData(productCode);
  });

  App.reqres.setHandler('product:get-custom-fields', function (productCode) {
    return API.getCustomFields(productCode);
  });

  App.reqres.setHandler('product:update', function (product) {
    return API.updateProductProperties(product);
  });

  App.reqres.setHandler('product:update-template-picture', function (productSecId, data) {
    return API.postTemplatePicture(productSecId, data);
  });

  App.reqres.setHandler('product:update-catalog-picture', function (productSecId, data) {
    return API.postCatalogPicture(productSecId, data);
  });

});

