define('headerLanguagesView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'settings',
  'template!headerLanguagesView'
], function(
  App,
  Module,
  Marionette,
  _,
  Settings,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-languages-view',

    ui: {
      item: '.item'
    },

    events: {
      'click @ui.item': 'onChange'
    },

    serializeData: function() {
      var data = {};

      data.language = Settings.get('lang');
      return data;
    },

    onChange: function (event) {
      var lang = this.$el.find(event.currentTarget).children().attr('lang');
      
      Settings.set('lang',lang);   

      var update = App.request('caccount:changeLanguage', Settings.get('currentCAccount').secId, lang);
      $.when(update).done(_.bind(function(result) {
        Settings.set('currentCAccount', result);
        window.location.reload();
      }, this));
    }
  });
});
