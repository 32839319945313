
define('template!similarItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12">\n  <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'" style="width: 100%;"/>\n</div>\n';

}
return __p
};});

