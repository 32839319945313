define('webshopCategoriesListsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopCategoriesListView',
  'webshopCategoriesTreeView',
  'template!webshopCategoriesListsView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ListView,
  TreeView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-lists-view',

    ui: {
      treeButton: '.header .tree',
      listButton: '.header .list',
      tree: '.tree-region',
      list: '.list-region'
    },

    events: {
      'click @ui.treeButton': 'showTree',
      'click @ui.listButton': 'showList'
    },

    regions: {
      treeRegion: '.tree-region',
      listRegion: '.list-region'
    },

    onRender: function () {
      if(this.options.mode === 'tree')
        this.showTree();
      else
        this.showList();
    },

    showTree: function () {
      this.ui.treeButton.addClass('selected');
      this.ui.listButton.removeClass('selected');

      this.ui.tree.css('display', 'flex');
      this.ui.list.hide();

      if(!this.treeView) {
        this.treeView = new TreeView();
        this.getRegion('treeRegion').show(this.treeView);
      }

      App.navigate('webshop/categories/tree', {trigger: false});
    },

    showList: function () {
      this.ui.treeButton.removeClass('selected');
      this.ui.listButton.addClass('selected');

      this.ui.tree.hide();
      this.ui.list.css('display', 'flex');

      if(!this.listView) {
        this.listView = new ListView();
        this.getRegion('listRegion').show(this.listView);

        App.navigate('webshop/categories', {trigger: false});
      }
    }
  });
});

