
define('template!ordersDetailPriceV2PrintPricesView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12 p-t-15">\n  <div class="table-responsive">\n    <table class="table table-price table-print-price">\n      <thead>\n      <tr>\n        <th colspan="9">Imprint selection</th>\n        <th colspan="' +
((__t = ( scales?scales.length:'' )) == null ? '' : __t) +
'">Imprint Prices</th>\n      </tr>\n      </thead>\n      <thead>\n      <tr>\n        <th>Select</th>\n        <th>Nr.</th>\n        <th colspan="2">Location</th>\n        <th colspan="2">Technic</th>\n        <th colspan="2">Size</th>\n        <th>Colors</th>\n        <th>Setup</th>\n        <th>Handling</th>\n        ';
 _.each(scales, function(scale, index) { ;
__p += '\n        ';
 if ((scales.length -1) === index) { ;
__p += '\n        <th>' +
((__t = ( scale.quantity )) == null ? '' : __t) +
'+</th>\n        ';
 } else { ;
__p += '\n        <th>' +
((__t = ( scale.quantity )) == null ? '' : __t) +
'-' +
((__t = ( Number(scales[index+1].quantity) -1 )) == null ? '' : __t) +
'</th>\n        ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n      </tr>\n      </thead>\n      <tbody class="items-region">\n      </tbody>\n    </table>\n  </div>\n</div>\n';

}
return __p
};});

