define('graphicalRequestKanbanTimePopupView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'moment',
  'template!graphicalRequestKanbanTimePopupViewTpl',
  'dialogFormView',
  'dateUtils'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  Settings,
  moment,
  viewTpl,
  DialogFormView,
  dateConverter
) {
  'use strict';

  var TimePopupView = DialogFormView.extend({
    template: viewTpl,

    events: {
      'click @ui.trackIcon': '_onOpenTimeTrackerPage'
    },

    ui: {
      trackIcon: '.open-timetracker-page'
    },

    serializeData: function() {
      return {
        estimated: dateConverter.toHHMMSS(this.options.times.estimated, 'seconds'),
        remaining: dateConverter.toHHMMSS(this.options.times.remaining, 'seconds'),
        logged: dateConverter.toHHMMSS(this.options.times.logged, 'seconds'),
        timing: this.options.times,
        persantage: this._calculateTimeProgress(),
        ticketSecId: this.options.ticketSecId
      };      
    },

    _calculateTimeProgress: function() {
      var timing = [], max;
      
      _.each(this.options.times, function(value, key) {
        timing.push({
          key: key,
          value: value
        });
      });
      max = _.max(timing, function(item) {return item.value;});
      if (max.key === 'estimated') {
        return {
          estPer: 100,
          remPer: (this.options.times.remaining * 100 / this.options.times.estimated).toFixed(0),
          logPer: (this.options.times.logged * 100 / this.options.times.estimated).toFixed(0)
        };
      } else if (max.key === 'logged') {
        return {
          logPer: 100,
          estPer: (this.options.times.estimated * 100 / this.options.times.logged).toFixed(0),
          remPer: (this.options.times.remaining * 100 / this.options.times.logged).toFixed(0)
        };
      } else if (max.key === 'remaining') {
        return {
          remPer: 100,
          estPer: (this.options.times.estimated * 100 / this.options.times.remaining).toFixed(0),
          logPer: (this.options.times.logged * 100 / this.options.times.remaining).toFixed(0)
        };
      }
    },

    onShow: function() {
      $.material.init();
    },

    _onOpenTimeTrackerPage: function() {
      this.hide();
    }
  });

  module.exports = TimePopupView;
});
