
define('template!headerUserView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="dropdown-toggle avatar-dropdown" data-toggle="dropdown">    \n  <img class="avatar" src="' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( name )) == null ? '' : __t) +
'"/>\n</div>    \n<ul class="dropdown-menu">\n  <li>\n    <div class="edit">\n      <i class="fa fa-pencil-square-o "></i>\n      <a class="">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</a>\n    </div>\n  </li>\n  <li>\n    <div class="logout">\n      <i class="fa fa-sign-out fa-1x"></i>\n      <a>' +
((__t = ( _.i18n('common.logout') )) == null ? '' : __t) +
'</a>\n    </div>\n  </li>\n</ul>';

}
return __p
};});

