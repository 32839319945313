define('windowModalView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'template!windowModalView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'window-modal-view',

    ui: {
      modals: '.modals',
      parentModals: '.parent-modals'
    },
       
    initialize: function() {
      this.views = [];
    },

    open: function(view) {
      _.each(this.views, function(view) {
        view.$el.hide();
      });
      this.views.push(view);

      this.$el.css('display', 'flex');
      this.ui.modals.append(view.render().el);

      if(view.view.onFocus)
        view.view.onFocus();
    },

    close: function() {
      if(this.views.length > 0) {
        this.views[this.views.length - 1].destroy();
        this.views.pop();
      }

      if(this.views.length === 0)
        this.$el.hide();
      else
        this.views[this.views.length - 1].$el.css('display', 'flex');
    }
  });
});  
