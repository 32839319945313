define('webshopProductsDetailBodyProductView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'selectView',
  'checkboxView',
  'webshopProductsDetailBodyProductIndexesView',
  'template!webshopProductsDetailBodyProductView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  SelectView,
  CheckboxView,
  IndexesView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-product-view',

    ui: {
      defaultCategory: '.default-category input',
      label: '.product-label input',
      youtubeVideo: '.youtube-video input'
    },

    modelEvents: {
      'change:defaultCategory': 'renderCategories',
      'change:outdated': 'renderOutdated',
      'change:status': 'renderStatus',
      'change:label': 'renderLabel',
      'change:youtubeVideo': 'renderYoutubeVideo',
      'change:attributes': 'renderGoogleShopping'
    },

    events: {
      'change @ui.defaultCategory': 'onChangeDefaultCategory',
      'change @ui.label': 'onChangeLabel',
      'change @ui.youtubeVideo': 'onChangeYoutubeVideo'
    },

    regions: {
      statusRegion: '.status-region',
      tagsRegion: '.tags-region',
      layoutRegion: '.layout-region',
      outdatedRegion: '.outdated-region',
      unfinishedRegion: '.unfinished-region',
      indexesRegion: '.indexes-region',
      logoUploadRegion: '.logo-upload-region',
      compositionEditorRegion: '.composition-editor-region',
      googleShoppingRegion: '.google-shopping-region'
    },

    serializeData: function () {
      var data = {};

      data.showDefaultCategory = false;
      if(Settings.configValue('webshop.products.showDefaultCategory'))
        data.showDefaultCategory = true;

      data.showOutdated = false;
      if(Settings.configValue('webshop.products.showOutdated'))
        data.showOutdated = true;

      data.showUnfinished = false;
      if(Settings.configValue('webshop.products.showUnfinished'))
        data.showUnfinished = true;

      data.showUploadLogo = false;
      if(Settings.configValue('webshop.products.showUploadLogo'))
        data.showUploadLogo = true;

      data.showCompositionEditor = false;
      if(Settings.configValue('webshop.products.showCompositionEditor'))
        data.showCompositionEditor = true;

      data.showPageLayout = false;
      if(Settings.configValue('webshop.products.showPageLayout'))
        data.showPageLayout = true;

      data.showYoutubeVideo = false;
      if(Settings.configValue('webshop.products.showYoutubeVideo'))
        data.showYoutubeVideo = true;

      data.showNoindex = false;
      if(Settings.configValue('webshop.products.showNoindex'))
        data.showNoindex = true;

      return data;
    },

    onRender: function () {
      App.request('webshop.categories.datalist');

      //--------------------
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '0',  value: _.i18n('common.disabled')},
          {id: '1',  value: _.i18n('common.enabled')}
        ])
      });
      this.statusRegion.show(this.statusView);
      this.statusView.on('change', _.bind(this.onStatusChange, this));
      this.renderStatus();

      //--------------------
      this.tagsView = new SelectView({
        collection: new Backbone.Collection()
      });
      this.tagsRegion.show(this.tagsView);
      this.tagsView.on('change', _.bind(this.onTagsChange, this));

      App.request('webshop.products.tags').done(_.bind(function (tags) {
        this.tagsView.collection.add({id: '', value: _.i18n('webshop.product.tag.empty')});

        _.each(tags.models, _.bind(function (tag) {
          var model = new Backbone.Model({
            id: tag.get('id'),
            value: _.i18n('webshop.product.tag.' + tag.get('code'))
          });
          this.tagsView.collection.add(model);
          this.tagsView.setSelected(this.model.getTag());
        }, this));
      }, this));

      //--------------------
      if(Settings.configValue('webshop.products.showPageLayout')) {
        this.layoutView = new SelectView({
          collection: new Backbone.Collection([
            {id: '', value: _.i18n('webshop.product.layouts.fromCategory')},
            {id: 'v1', value: 'V1'},
            {id: 'v2', value: 'V2'}
          ])
        });
        this.layoutRegion.show(this.layoutView);
        this.layoutView.on('change', _.bind(this.onLayoutChange, this));
        this.layoutView.setSelected(this.model.getLayout());
      }

      //--------------------
      if(Settings.configValue('webshop.products.showOutdated')) {
        this.outdatedView = new CheckboxView({
          title: _.i18n('webshop.product.outdated.title'),
          text: _.i18n('webshop.product.outdated'),
          value: this.model.getOutdated(),
          onChange: _.bind(function (value) {
            var defer = $.Deferred();
            this.model.setOutdated(value).done(_.bind(function () {
              this.model.fetch().done(_.bind(function () {
                this.outdatedView.setValue(this.model.getOutdated());
                defer.resolve();
              }, this));
            }, this)).fail(_.bind(function () {
              this.model.fetch();
              defer.reject();
            }, this));
            return defer.promise();
          }, this)
        });
        this.outdatedRegion.show(this.outdatedView);
      }

      //--------------------
      if(Settings.configValue('webshop.products.showUnfinished')) {
        this.unfinishedView = new CheckboxView({
          title: _.i18n('webshop.product.unfinished.title'),
          text: _.i18n('webshop.product.unfinished'),
          value: this.model.getUnfinished(),
          onChange: _.bind(function (value) {
            var defer = $.Deferred();
            this.model.setUnfinished(value).done(_.bind(function () {
              this.model.fetch().done(_.bind(function () {
                this.unfinishedView.setValue(this.model.getUnfinished());
                defer.resolve();
              }, this));
            }, this)).fail(_.bind(function () {
              this.model.fetch();
              defer.reject();
            }, this));
            return defer.promise();
          }, this)
        });
        this.unfinishedRegion.show(this.unfinishedView);
      }

      //--------------------
      if(Settings.configValue('webshop.products.showUploadLogo')) {
        this.logoUploadView = new CheckboxView({
          title: _.i18n('webshop.product.upload-logo'),
          text: _.i18n('webshop.product.upload-logo'),
          value: this.model.getOutdated(),
          onChange: _.bind(function (value) {
            var defer = $.Deferred();
            this.model.setUploadLogo(value).done(_.bind(function () {
              this.model.fetch().done(_.bind(function () {
                this.logoUploadView.setValue(this.model.getUploadLogo());
                defer.resolve();
              }, this));
            }, this)).fail(_.bind(function () {
              this.model.fetch();
              defer.reject();
            }, this));
            return defer.promise();
          }, this)
        });
        this.logoUploadRegion.show(this.logoUploadView);
      }

      //--------------------
      if(Settings.configValue('webshop.products.showCompositionEditor')) {
        this.compositionEditorView = new CheckboxView({
          title: _.i18n('webshop.product.composition-editor'),
          text: _.i18n('webshop.product.composition-editor'),
          value: this.model.getCompositionEditor(),
          onChange: _.bind(function (value) {
            var defer = $.Deferred();
            this.model.setCompositionEditor(value).done(_.bind(function () {
              this.model.fetch().done(_.bind(function () {
                this.compositionEditorView.setValue(this.model.getCompositionEditor());
                defer.resolve();
              }, this));
            }, this)).fail(_.bind(function () {
              this.model.fetch();
              defer.reject();
            }, this));
            return defer.promise();
          }, this)
        });
        this.compositionEditorRegion.show(this.compositionEditorView);
      }

      //--------------------
      this.googleShoppingView = new CheckboxView({
        title: _.i18n('webshop.product.google-shopping'),
        text: _.i18n('webshop.product.google-shopping'),
        value: this.model.getGoogleShopping(),
        onChange: _.bind(function (value) {
          var defer = $.Deferred();
          this.model.setGoogleShopping(value).done(_.bind(function () {
            this.model.fetch().done(_.bind(function () {
              defer.resolve();
            }, this));
          }, this)).fail(_.bind(function () {
            this.model.fetch();
            defer.reject();
          }, this));
          return defer.promise();
        }, this)
      });
      this.googleShoppingRegion.show(this.googleShoppingView);

      //--------------------
      if(Settings.configValue('webshop.products.showNoindex')) {
        this.indexesView = new IndexesView({
          product: this.model,
          webshopConfig: this.options.webshopConfig
        });
        this.indexesRegion.show(this.indexesView);
      }

      //--------------------
      this.renderCategories();
      this.renderLabel();
      this.renderYoutubeVideo();
      this.renderUploadLogo();
      this.renderCompositionEditor();
      this.renderUnfinished();
      this.renderLayout();
      this.renderStatus();
      this.renderGoogleShopping();
    },

    renderCategories: function () {
      if(!Settings.configValue('webshop.products.showDefaultCategory'))
        return;

      if(this.model.getDefaultCategory()) {
        App.request('webshop.categories.datalist').done(_.bind(function (categories) {
          _.each(categories, _.bind(function (category) {
            if(category.getId() === this.model.getDefaultCategory())
              this.ui.defaultCategory.val(category.getCode());
          }, this));
        }, this));
      }
    },

    renderOutdated: function () {
      if(!Settings.configValue('webshop.products.showOutdated'))
        return;
      this.outdatedView.setValue(this.model.getOutdated());
    },

    renderUploadLogo: function () {
      if(!Settings.configValue('webshop.products.showUploadLogo'))
        return;
      this.logoUploadView.setValue(this.model.getUploadLogo());
    },

    renderCompositionEditor: function () {
      if(!Settings.configValue('webshop.products.showCompositionEditor'))
        return;
      this.compositionEditorView.setValue(this.model.getCompositionEditor());
    },

    renderGoogleShopping: function () {
      this.googleShoppingView.setValue(this.model.getGoogleShopping());
    },

    renderUnfinished: function () {
      if(!Settings.configValue('webshop.products.showUnfinished'))
        return;
      this.unfinishedView.setValue(this.model.getUnfinished());
    },

    renderLayout: function () {
      if(!Settings.configValue('webshop.products.showPageLayout'))
        return;
      this.layoutView.setSelected(this.model.getLayout());
    },

    renderStatus: function () {
      this.statusView.setSelected(this.model.getStatus());
    },

    renderLabel: function () {
      this.ui.label.val(this.model.getLabel());
    },

    renderYoutubeVideo: function () {
      if(!Settings.configValue('webshop.products.showYoutubeVideo'))
        return;
      this.ui.youtubeVideo.val(this.model.getYoutubeVideo());
    },

    onChangeDefaultCategory: function () {
      var id = '';
      App.request('webshop.categories.datalist').done(_.bind(function (categories) {
        _.each(categories, _.bind(function (category) {
          if(category.getCode() === this.ui.defaultCategory.val())
            id = category.getId();
        }, this));
      }, this));

      this.model.setDefaultCategory(id).done(_.bind(function () {
        this.model.fetch();
        }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onStatusChange: function () {
      this.model.setStatus(this.statusView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
            this.renderStatus();
        }, this));
      }, this)).fail(_.bind(function () {
        this.renderStatus();
      }, this));
    },

    onLayoutChange: function () {
      this.model.setLayout(this.layoutView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onTagsChange: function () {
      this.model.setTag(this.tagsView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onChangeLabel: function () {
      this.model.setLabel(this.ui.label.val()).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onChangeYoutubeVideo: function () {
      this.model.setYoutubeVideo(this.ui.youtubeVideo.val()).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    }
  });
});
