/**
 * Created by Matteo on 11/07/2015.
 */
define('caccountGraphicalIdView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'imageUtils',
  'template!caccountGraphicalIdTpl',
  'cAccountTabs',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  Utils,
  viewTpl,
  cAccountTabs,
  BackToListBehavior
) {
  'use strict';

  var CaccountGraphicalIdView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.viewMore': 'onViewMore'
    },

    ui: {
      tab: '.graphical-id-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code',
      phone: '.p-phone',
      email: '.p-email'
    },

    events: {
      'click @ui.tab': 'onTabClick'
    },

    regions: {
      caccountObjectNamesTab: '#objectnames-tab',
      caccountFontsTab: '#fonts-tab'
    },

    behaviors: function() {
      var redirectUrl = this.options.data.type;
      if(redirectUrl === 'caccount') {
        redirectUrl = 'caccounts';
      }
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: redirectUrl
        }
      };
    },

    serializeData: function() {
        var templateData = this.model.toJSON(),
            data = this.options.data,
            cAccountRoles = Settings.get('cAccountRoles');

        templateData.cAccountTabs = [];

        if (data.type === 'caccount') {
            if (cAccountRoles) {
                _.each(cAccountTabs.graphicalIdTabs, function (tab) {
                    if (cAccountRoles[tab.role] === true) {
                        templateData.cAccountTabs.push(tab);
                    }
                });
            } else {
                templateData.cAccountTabs = cAccountTabs.graphicalIdTabs;
            }
        }
        return templateData;
    },

      onRender: function() {
          this._setLogo(this.model.get('logo'), this.model.get('code'));

          if (this.options.data.tab) {
              this.ui.tab.removeClass('active');
              this.$el.find('.tab-pane').removeClass('active');
              this.$el
                  .find('.graphical-id-tab[data-name="' + this.options.data.tab.name + '"]')
                  .addClass('active');
              this.$el
                  .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
                  .addClass('active');
          }
      },

      onTabClick: function(e) {
          var $target = $(e.currentTarget),
              tab = {
                  route: $target.data('route'),
                  role: $target.data('role'),
                  name: $target.data('name')
              };
          this.ui.tab.removeClass('active');
          this.ui.tabPane.removeClass('active');
          $target.addClass('active');
          this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

          this.triggerMethod('click:tab', tab);
      },

    _setLogo: function(logo, code) {
      var logoUrl = Utils.getLogo(logo, code, {
        action: 'res',
        params: 't'
      });

      if(logoUrl) {
        this.ui.logo.css('background-image', 'url(' + logoUrl + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    }

  });

  module.exports = CaccountGraphicalIdView;
});
