define('pimProductDetailImagesView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore',
  'contextMenu',
  'pimProductDetailImageView'
], function (
  App,
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  ContextMenu,
  ImageView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'pim-products-details-images-view',
    childView: ImageView,

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
      var that = this;
      this.$el.contextMenu({
        selector: '.pim-products-details-image-view',
        items: {
          image: {
            name: 'Use as catalogue picture',
            callback: function () {
              that.selectImage(this.find('img').attr('src'));
            }
          }
        },
        trigger: 'right'
      });
    },

    fetch: function () {
      if(this.model.get('data') && this.model.get('data').get('productPictures')) {
        this.collection.set(this.model.get('data').get('productPictures'));
      }
    },

    selectImage: function (url) {
      var formData = new FormData();

      var fd = {};

      fd.imageUrl = url;

      _.each(fd, function (value, key) {
        formData.append(key, value);
      });

      App.request('pim:products:update-catalog-picture-from-url',
          this.model.getCurrentResProduct().get('id'),
          formData).done(_.bind(function (response) {
          this.model.getCurrentResProduct().set('catalogPictureUid', response.catalogPictureUid);
        }, this))
        .fail(_.bind(function () {
        }, this));
    }
  });
});
