
define('template!headerAccountView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="caccount-selector">\n  <div class="dropdown-toggle js-account-switch" data-toggle="dropdown">\n    <img class="js-caccount-logo"/>\n    <span class="name js-caccount-name"></span>\n  </div>\n  <div class="dropdown-menu caccounts-dropdown-container"></div>\n</div>';

}
return __p
};});

