define('ordersDetailSupplierView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'marionette',
  'buttonView',
  'ordersDetailSupplierInvoiceView',
  'ordersDetailSupplierProductView',
  'ordersDetailSupplierEditView',
  'template!ordersDetailSupplierView'
], function (
  App,
  module,
  _,
  Backbone,
  Marionette,
  ButtonView,
  InvoiceView,
  ProductView,
  EditView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    className: 'orders-detail-supplier-view',
    template: Tpl,

    ui: {
      bodyContent: '.body-content',
      empty: '.empty',
      loader: '.loader-ctn'
    },

    regions: {
      invoiceRegion: '.invoice-region',
      productRegion: '.product-region',
      editButtonRegion: '.edit-button-region'
    },

    onRender: function () {
      this.editButtonView = new ButtonView({
        type: 'edit',
        text: _.i18n('order.detail.supplier.send-order'),
        title: _.i18n('order.detail.supplier.send-order'),
        enabled: false
      });
      this.getRegion('editButtonRegion').show(this.editButtonView);
      this.editButtonView.on('click', _.bind(this.onEdit, this));

      //-------------------
      this.fetch();
    },

    fetch: function() {
      App.request('order-documents-supplier:list', this.model.get('purchaseOrderId')).done(_.bind(function (documents) {
        this.documents = documents;
        this.renderContent();
      }, this));
    },

    renderContent: function() {
      if(this.documents) {
        this.invoiceView = new InvoiceView({
          order: this.model,
          model: new Backbone.Model(this.documents)
        });
        this.getRegion('invoiceRegion').show(this.invoiceView);

        /*this.productView = new ProductView({
          order: this.model,
          model: new Backbone.Model(this.documents)
        });
        this.getRegion('productRegion').show(this.productView);*/

        this.ui.bodyContent.css('display', 'flex');

        this.ui.loader.hide();
        this.editButtonView.enabled(false);
      } else {
        this.ui.empty.css('display', 'flex');
        this.ui.loader.hide();
        this.editButtonView.enabled(true);
      }
    },

    onEdit: function () {
      EditView.prototype.open({
        model: this.model,
        title: _.i18n('order.detail.supplier.send-order') + ' | ' + this.model.get('purchaseOrderId'),
        onConfirm: _.bind(function () {
          if(!this.isDestroyed)
            this.fetch();
        }, this)
      });
    }
  });
});
