define('ordersConfirmationOrderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'template!ordersConfirmationOrderView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-confirmation-order-view',

    serializeData: function() {
      var data = {};

      data.companyName = '';
      if(this.model.get('companyName'))
        data.companyName = this.model.get('companyName');

      //----------------

      data.infos = this.model.get('purchaseOrderId');

      if(this.model.get('productName'))
        data.infos += ' | ' + this.model.get('productName');

      return data;
    }
  });  
});

