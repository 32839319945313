/**
 * Created by RKL on 13/08/2015.
 */
define('caccountSAProfileTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'bootbox',
  'imageUtils',
  'validate',
  'caccountSAProfileTabView',
  'emptyView',
  'settings',
  'entities/caccounts'
], function(
  module,
  _,
  Backbone,
  App,
  bootbox,
  imageUtils,
  validateUtils,
  View,
  EmptyView,
  Settings
) {
  'use strict';

  var CaccountProfileTabController = {
    showTab: function(data) {
      this.showCAccountInfo(data);
    },

    showCAccountInfo: function(data) {
      var that = this,
        emptyView = new EmptyView(),
        fetchCountries, fetchLangs;

      this.model = App.request('caccount:entity', data.cAccountId || data.secId);
      this.tab = data.tab;
      this.data = data;

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(emptyView);

      if (!Settings.get('countries') || !Settings.get('languages')) {
        fetchCountries = App.request('caccount:countries');
        fetchLangs = App.request('caccount:languages');

        $.when(fetchCountries, fetchLangs).done(function(countries, langs) {
          Settings.set('countries', countries.toJSON());
          Settings.set('languages', langs.toJSON());

          if(data.id === 'new') {
            that.createView();
          } else {
            that.model.fetch({
              success: function() {
                that.createView();
              }
            });
          }
        });
      } else {
        if(data.id === 'new') {
          that.createView();
        } else {
          that.model.fetch({
            success: function() {
              that.createView();
            }
          });
        }
      }
    },

    createView: function() {
        var view;
        var countries = Settings.get('countries');
        view = new View({
            model: this.model,
            countries: countries,
            type: this.data.type,
            accountId: this.data.cAccountId
        });
        view.tempModel = new Backbone.Model(this.model.toJSON());
        this.view = view;

        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);

        view.listenTo(view, 'onModelSaved', _.bind(this.onModelSaved, this));
    },

    onModelSaved: function(model) {
      this.model = model;
      var account = this.model.toJSON();

      this.view.tempModel.attributes = account;
      if(this.data.type === 'caccounts') {
        this.tab = null;
      }
      this.view.triggerMethod('saved');
    }

  };

  module.exports = CaccountProfileTabController;
});
