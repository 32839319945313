
define('template!superAdminLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content superadmin-page">\n\t<div class="superadmin-tabs-container row">\n\t\t<div class="navbar navbar-default navbar-md row">\n\t\t\t<div class="navbar-header">\n\t\t\t\t<a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class="navbar-collapse collapse">\n\t\t\t\t<ul class="p-l-10 nav navbar-nav" id="superadmin-tabs">\n\t\t\t\t\t<li class="js-tab-title overview-tab ';
 if (tab === 'overview') {;
__p += 'active';
 } ;
__p += '" data-tab="overview">\n\t\t\t\t\t\t<a href="#overview-tab" data-toggle="tab">' +
((__t = ( _.i18n("superAdmin.overview.tabTitle") )) == null ? '' : __t) +
'</a>\n\t\t\t\t\t</li>\n\t\t\t\t</ul>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="superadmin-container" id="js-superadmin-content-region"></div>\n</div>';

}
return __p
};});

