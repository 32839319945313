define( 'entities/global_search',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';


  /**
   * REST API
   */
  var API = {
    search: function(searchTerm) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'globalsearch.json/' + searchTerm);

      $.ajax({
        type: 'GET',
        url: url,
        complete: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */


  App.reqres.setHandler('globalsearch', function(searchTerm) {
    return API.search(searchTerm);
  });
});

