define('orderPicturesTabPictureCollectionView',[
  'module',
  'marionette',
  'orderPicturesTabPictureItemView'
], function(
  module,
  Marionette,
  OrderPictureItemView
) {
  'use strict';

  module.exports = Marionette.CollectionView.extend({
    childView: OrderPictureItemView,
    childViewOptions: function() {
      return {
        orderModel: this.options.orderModel
      };
    }
  });
});
