/**
 * Created by OBL on 14-12-15.
 */
define('cataloguesShared',[
    'module',
    'logger',
    'cataloguesMainRouter'
], function(
    module,
    Logger
    ) {
    'use strict';

    var Catalogues = {
        start: function() {
            Logger.log(module, 'starting CataloguesSharedModules');
        },

        stop: function() {
            Logger.log(module, 'stopping CataloguesSharedModules');
        }
    };

    module.exports = Catalogues;
});
