/**
 * Created by OLD on 20/11/2015.
 */
define('fileConversionRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin
) {
  'use strict';

  var FileConversionRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'file_conversion': 'showFileConversion',
      'file_conversion/:secId' : 'showFileConversion'
    }
  });

  var API = {
    showFileConversion: function(secId) {
      require([
        'fileConversionShowController',
        'contentShowController',
        'fCticketsController'
      ], function(ShowController, ContentShowController, TicketsController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('fileConversionMain');
          if (!secId) {
            ShowController.showFileConversion();
          } else {
            TicketsController.showTickets(secId);
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICALREQUESTS_FILECONVERSION);
        });
      });
    }
  };

  App.on('file_conversion:show', function(secId) {
    if (secId) {
      App.navigate('file_conversion/' + secId);
    } else {
      App.navigate('file_conversion');
    }
    API.showFileConversion(secId);
  });

  App.on('before:start', function() {
    new  FileConversionRouter({
      controller: API
    });
  });

  module.exports = FileConversionRouter;
});
