define('catalogueMineOverviewTabView',[
  'module',
  'marionette',
  'template!catalogueMineOverviewTabTpl',
  'imageUtils',
  'settings',
  'uploadFileView'
], function(
  module,
  Marionette,
  pictureCardTpl,
  imageUtils,
  Settings,
  UploadFileView
) {
  'use strict';

  var CataloguePictureCardView = Marionette.LayoutView.extend({
    template: pictureCardTpl,
    className: 'content',

    regions: {
      uploadButton: '.js-upload-button-region'
    },

    ui: {
      catalogueImage: '.js-catalogue-image',
      edit: '.js-edit'
    },

    events: {
      'click @ui.edit': '_onEdit'
    },

    modelEvents: {
      'change:pictureUid': '_changePicture'
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.cAccountLogoUrl = imageUtils.getLogo(this.model.get('cAccountLogo'), this.model.get('code'));
      templateData.catalogueImage = this.model.get('pictureUid') ?
        imageUtils.getLogo(this.model.get('pictureUid')) :
        Settings.get('defaultCatalogueImage');

      return templateData;
    },

    onShow: function() {
      var uploadButtonView = new UploadFileView();
      this.listenTo(uploadButtonView, 'uploaded', this._onFileUploaded);
      this.getRegion('uploadButton').show(uploadButtonView);
    },

    _onFileUploaded: function(fileInfo) {
      this.model.updateCatalogueImage(fileInfo);
    },

    _onEdit: function() {
       this.trigger('catalogue:edit');
    },

    _changePicture: function() {
      this.ui.catalogueImage.attr('src', imageUtils.getLogo(this.model.get('pictureUid')));
    }
  });

  module.exports = CataloguePictureCardView;
});
