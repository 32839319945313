/**
 * Created by GSP on 4/11/2015.
 */
define('vattachmentView',['module',
        'underscore',
        'marionette',
        'template!newAttachmentTpl'],
  function(module,
           _,
           Marionette,
           newAttachmentTpl){

  'use strict';

  var AttachmentView = Marionette.ItemView.extend({
    tagName: 'li',
    template: newAttachmentTpl,

    serializeData: function() {
      var templateData = this.model.toJSON();
      _.each(templateData.attachements, function(att) {
        if (window.location.href.indexOf('https') === 0) {
          att.downloadLink = att.downloadLink.replace('http:', 'https:');
        }
      });
      return templateData;
    }
  });

  module.exports = AttachmentView;
});
