/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouShowView',[
  'module',
  'marionette',
  'app',
  'template!thankYouViewTpl',
  'tawlkToBehavior'
], function(
  module,
  Marionette,
  App,
  viewTpl,
  TawlkToBehavior
) {
  'use strict';

  var ThankYouView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      message: '.thank-you-message'
    },

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    onShow: function() {
      this.ui.message.text(this.options.target);

      setTimeout(function() {
        App.trigger('login:show');
      }, 5000);
    }
  });

  module.exports = ThankYouView;
});
