
define('template!ordersDetailOrderClientCommentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="date">\n  ' +
((__t = ( date )) == null ? '' : __t) +
'\n</div>\n<div class="body">\n' +
((__t = ( content )) == null ? '' : __t) +
'\n</div>';

}
return __p
};});

