/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminAccountStatView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'template!superAdminAccountStatTpl'
], function(
  module,
  Marionette,
  _,
  App,
  Setting,
  viewTpl
) {
  'use strict';

  var superAdminView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      next: '.next-pg',
      prev: '.prev-pg'
    },

    events: {
      'click @ui.next' : 'updatePagination',
      'click @ui.prev' : 'updatePagination'
    },

    serializeData: function() {
      var templateData = {};

      templateData.accountsStats = this.model.toJSON();
      templateData.pagin = this.options.pagin;
      return templateData;
    },
  
    onShow: function() {
      $.material.init();
      this.pagin = this.options.pagin;
    },

    updatePagination: function(e) {
      if ($(e.currentTarget).hasClass('next-pg')) {
        this.pagin.currentPage = this.pagin.currentPage + 1;     
        this.pagin.first = this.pagin.currentPage * this.pagin.pageSize;
      }
      if ($(e.currentTarget).hasClass('prev-pg')) {
        this.pagin.currentPage = this.pagin.currentPage - 1;
        this.pagin.first = this.pagin.currentPage * this.pagin.pageSize;
      }
      this.trigger('superadmin:update-account-table', this.pagin);
    }
  });

  module.exports = superAdminView;
});
