
define('template!ordersDetailOrderPrintZoneView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n  <div class="print-zone">\n    <div class="name-ctn">\n      <div class="name">' +
((__t = ( printAreaName )) == null ? '' : __t) +
'</div>\n      ';
 if(productOwner) { ;
__p += '\n      <div class="product-owner-ctn">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n          <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/>\n        </svg>\n        <div class="product-owner">' +
((__t = ( _.i18n('printZone.printedBy') + ' ' + productOwner )) == null ? '' : __t) +
'</div>\n      </div>\n      ';
 } ;
__p += '\n    </div>\n    <div class="custom-pa-name-region"></div>\n    <div class="print">' +
((__t = ( print )) == null ? '' : __t) +
'</div>\n    <div class="custom-pz-name-region"></div>\n    <div class="size">' +
((__t = ( size )) == null ? '' : __t) +
'</div>\n    <div class="custom-pz-size-region"></div>\n    ';
 if (colorModeType === 'spot') { ;
__p += '\n    <div class="colors">' +
((__t = ( colors )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n  </div>\n  <div class="buttons">\n    <svg class="btn-edit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>\n    </svg>\n    <svg class="btn-delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">\n      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>\n    </svg>\n  </div>\n</div>\n<div class="body">\n  <img class="image" src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'" loading="lazy">\n</div>\n<div class="loader-ctn">\n  <div class="loader-2"></div>\n</div>\n';

}
return __p
};});

