define('webshopProductsListPriceView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!webshopProductsListPriceView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-price-view ' + FieldView.prototype.className,

    serializeData: function() {
      var data = {};

      data.price = '';

      if(this.model.get('prices')) {
        var numberLocale = 'fr-BE';
        var configNumberLocale = Settings.configValue('priceLocale');
        if (configNumberLocale)
          numberLocale = configNumberLocale;

        _.each(this.model.get('prices'), function(price) {
          data.price += '<div class="item">';
          if(price.currencyId === 'EUR')
            data.price += '<b>€';
          if(price.currencyId === 'UAH')
            data.price += '<b>₴';
          data.price += new Intl.NumberFormat(numberLocale).format(price.value) + '</b>';
          data.price += '&nbsp;-&nbsp;';
          data.price += '<b>' + new Intl.NumberFormat(numberLocale).format(price.quantity) + '</b>' + '&nbsp;' + _.i18n('common.pcs');
          data.price += '</div>';
        });
      }

      return data;
    }
  });
});
