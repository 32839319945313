/**
 * Created by BFR on 10/06/2020.
 */
define('productDataTabView',[
  'settings',
  'module',
  'marionette',
  'jqgridView',
  'app',
  'jquery',
  'underscore',
  'template!productDataTabTpl',
  'entities/product'
], function (
  Settings,
  module,
  Marionette,
  JqGridView,
  App,
  $,
  _,
  viewTpl
) {
  'use strict';

  var ProductDataView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      queueLevel: '.queueLevel'
    },

    events: {
      'click .btn-ignore-data': 'onClickBtnIgnoreData',
      'click .btn-update-data': 'onClickBtnUpdateData',
      'click .btn-refresh-data': 'onClickBtnRefreshData',
      'click .btn-refresh-checksums': 'onClickRefreshChecksums',
      'click .btn-recreate-vrps': 'onClickReCreateVrps',
      'click .btn-update-only-new-data': 'onClickBtnUpdateOnlyNewData'
    },

    onDestroy: function () {
      $(document).unbind('keydown', this.onKeyPress);
      clearTimeout(this.refreshQueueTime);
    },

    onShow: function () {
      $(document).bind('keydown', this, this.onKeyPress);
      this.refreshQueueTime = setTimeout(this.refreshQueue, 500, this);
    },

    refreshQueue: function (that) {
      return $.ajax({
        url: 'https://admin.compuzz.com/monitor/compuzzwebhookqueuecheck.php',
        success: function (queue) {
          if (that.ui.queueLevel) {
            that.ui.queueLevel.html(queue);
          }
          that.refreshQueueTime = setTimeout(that.refreshQueue, 2000, that);
        },
        error: function (res) {
          if (that.ui.queueLevel) {
            that.ui.queueLevel.html(res.responseText);
          }
          that.refreshQueueTime = setTimeout(that.refreshQueue, 2000, that);
        }
      });
    },

    onKeyPress: function (event) {
      if (event.ctrlKey) {
        switch (event.key) {
          case 'j':
            event.data.onClickBtnUpdateOnlyNewData();
            break;
          case 'i':
            event.data.onClickBtnIgnoreData();
            break;
          case 'u':
            event.data.onClickBtnUpdateData();
            break;
          case 'r':
            event.data.onClickBtnRefreshData();
            break;
        }
      }
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON(),
        currentLocale = Settings.get('lang'),
        cleanedPAs = [],
        cleanedSupPAs = [],
        cleanedPCVS = [],
        cleanedSupPCVS = [],
        cleanedPOCS = [],
        cleanedSupPOCS = [],
        //customFields = [],
        customFieldsSup = [],
        cleanedCustomFields = [],
        cleanedCustomFieldsSup = [],
        requiredProperties = [];
      templateData.display = false;

      templateData.displayButtons = this.options.displayButtons;
      if (this.options.supplierData &&
        (!Array.isArray(this.options.supplierData) ||
          (Array.isArray(this.options.supplierData) && this.options.supplierData.length > 0))) {
        templateData.display = true;
        // actual data
        _.each(templateData.printAreas, function (printArea) {
          if (printArea.printZones.length === 0) {
            cleanedPAs.push({
              'paCode': printArea.code,
              'paName': printArea.name,
              'paDimension': printArea.width + ' x ' + printArea.height + ' ' + printArea.unit,
              'pzCode': '',
              'pzName': '',
              'pzDimension': ''
            });
          } else {
            _.each(printArea.printZones, function (printZone) {
              cleanedPAs.push({
                'paCode': printArea.code,
                'paName': printArea.name,
                'paDimension': printArea.width + ' x ' + printArea.height + ' ' + printArea.unit,
                'pzCode': printZone.imppCode,
                'pzName': printZone.name,
                'pzDimension': printZone.width + ' x ' + printZone.height + ' ' + printArea.unit
              });
            });
          }
        });
        cleanedPAs = _.chain(cleanedPAs).sortBy('pzCode').sortBy('paCode').value();

        _.each(templateData.productOptionCategories, function (poc) {
          if (poc.productOptions.length === 0) {
            cleanedPOCS.push({
                'pocCode': poc.code,
                'pocName': poc.name,
                'poCode': '',
                'poName': ''
              }
            );
          } else {
            _.each(poc.productOptions, function (po) {
              cleanedPOCS.push({
                  'pocCode': poc.code,
                  'pocName': poc.name,
                  'poCode': po.code,
                  'poName': po.name
                }
              );
            });
          }
        });
        cleanedPOCS = _.chain(cleanedPOCS).sortBy('poCode').sortBy('pocCode').value();

        _.each(templateData.productCodeVariations, function (pcv) {
          if (pcv.productOptions.length === 0) {
            cleanedPCVS.push({
              'pcvCode': pcv.code,
              'size': '',
              'poCodeSize': '',
              'color': '',
              'poCodeColor': ''
            });
          } else {
            _.each(pcv.productOptions, function (po) {
              var tempPCV = _.findWhere(cleanedPCVS, {'pcvCode': pcv.code});
              if (tempPCV) {
                if (po.categoryName === 'Size') {
                  tempPCV.size = po.name;
                  tempPCV.poCodeSize = po.code;
                } else if (po.categoryName === 'Color') {
                  tempPCV.color = po.name;
                  tempPCV.poCodeColor = po.code;
                }
              } else {
                tempPCV = {'pcvCode': pcv.code};
                if (po.categoryName === 'Size') {
                  tempPCV.size = po.name;
                  tempPCV.poCodeSize = po.code;
                } else if (po.categoryName === 'Color') {
                  tempPCV.color = po.name;
                  tempPCV.poCodeColor = po.code;
                }
                cleanedPCVS.push(tempPCV);
              }
            });
          }
        });
        requiredProperties = ['poCodeColor', 'poCodeSize'];
        this.checkMissingCodeProperties(cleanedPCVS, requiredProperties);
        cleanedPCVS = _.chain(cleanedPCVS).sortBy('poCode').sortBy('poCategoryName').sortBy('pcvCode').value();

        /*_.each(templateData.customFields.customFieldJsons, function(cf) {
          customFields.push({'cfCode':cf.code,'value':cf.value,'name':cf.name
          });
        });
        cleanedCustomFields = _.chain(customFields).sortBy('cfCode').value();*/
        // new data
        if (this.options.supplierData) {
          var supplierProductInfo = JSON.parse(this.options.supplierData);

          _.each(supplierProductInfo.printAreas, function (printArea) {
            if (printArea.printZones.length === 0) {
              cleanedSupPAs.push({
                'paCode': printArea.code,
                'paName': printArea.name.values[currentLocale],
                'paDimension': printArea.width + ' x ' + printArea.height + ' ' + printArea.unit,
                'pzCode': '',
                'pzName': '',
                'pzDimension': ''
              });
            }
            _.each(printArea.printZones, function (printZone) {
              cleanedSupPAs.push({
                'paCode': printArea.code,
                'paName': printArea.name.values[currentLocale],
                'paDimension': printArea.width + ' x ' + printArea.height + ' ' + printArea.unit,
                'pzCode': printZone.refImprintTechnology.code,
                'pzName': printZone.refImprintTechnology.name.values[currentLocale.toUpperCase()],
                'pzDimension': printZone.width + ' x ' + printZone.height + ' ' + printArea.unit
              });
            });
          });
          cleanedSupPAs = _.chain(cleanedSupPAs).sortBy('pzCode').sortBy('paCode').value();

          _.each(supplierProductInfo.productOptionCategories, function (poc) {
            if (poc.productOptions.length === 0) {
              cleanedSupPOCS.push({
                  'pocCode': poc.code,
                  'pocName': poc.name.values[currentLocale],
                  'poCode': '',
                  'poName': ''
                }
              );
            }
            _.each(poc.productOptions, function (po) {
              cleanedSupPOCS.push({
                  'pocCode': poc.code,
                  'pocName': poc.name.values[currentLocale],
                  'poCode': po.code,
                  'poName': po.name.values[currentLocale]
                }
              );
            });
          });
          cleanedSupPOCS = _.chain(cleanedSupPOCS).sortBy('poCode').sortBy('pocCode').value();

          // no category name in supplier info , we retrieve it from productOptionCategories
          _.each(supplierProductInfo.productCodeVariations, function (pcv) {
            if (pcv.productOptions.length === 0) {
              cleanedSupPCVS.push({
                'pcvCode': pcv.code,
                'size': '',
                'poCodeSize': '',
                'color': '',
                'poCodeColor': ''
              });
            }
            _.each(pcv.productOptions, function (po) {
              var tempPCV = _.findWhere(cleanedSupPCVS, {'pcvCode': pcv.code});
              var catName;
              if (tempPCV) {
                catName = _.findWhere(cleanedSupPOCS, {'poCode': po.code}).pocName;
                if (catName === 'Color') {
                  tempPCV.color = po.name.values[currentLocale];
                  tempPCV.poCodeColor = po.code;
                } else {
                  tempPCV.size = po.name.values[currentLocale];
                  tempPCV.poCodeSize = po.code;
                }
              } else {
                tempPCV = {'pcvCode': pcv.code};
                catName = _.findWhere(cleanedSupPOCS, {'poCode': po.code}).pocName;
                if (catName === 'Color') {
                  tempPCV.color = po.name.values[currentLocale];
                  tempPCV.poCodeColor = po.code;
                } else {
                  tempPCV.size = po.name.values[currentLocale];
                  tempPCV.poCodeSize = po.code;
                }
                cleanedSupPCVS.push(tempPCV);
              }
            });
          });


          /*_.each(supplierProductInfo.customFields, function (cf) {
            customFieldsSup.push({
              'cfCode': cf.code, 'value': cf.value.values[currentLocale], 'name': cf.name.values[currentLocale]
            });
          });
          cleanedCustomFieldsSup = _.chain(customFieldsSup).uniq('cfCode').sortBy('cfCode').value();*/

          templateData.cleanedSupCustomFields = customFieldsSup;
          requiredProperties = ['poCodeColor', 'poCodeSize'];
          this.checkMissingCodeProperties(cleanedSupPCVS, requiredProperties);
          cleanedSupPCVS = _.chain(cleanedSupPCVS).sortBy('poCode').sortBy('pcvCode').value();
        }
        var lineHeight = 28;
        this.compareActualAndNew(cleanedPAs, cleanedSupPAs);
        templateData.cleanedPAs = _.chain(cleanedPAs).sortBy('pzCode').sortBy('paCode').value();
        templateData.cleanedSupPAs = _.chain(cleanedSupPAs).sortBy('pzCode').sortBy('paCode').value();
        templateData.PADivHeight = 50 + ((cleanedPAs.length > cleanedSupPAs.length) ? cleanedPAs.length * lineHeight :
          cleanedSupPAs.length * lineHeight);
        this.compareActualAndNew(cleanedPOCS, cleanedSupPOCS);
        templateData.cleanedPOCS = _.chain(cleanedPOCS).sortBy('poCode').sortBy('pocCode').value();
        templateData.cleanedSupPOCS = _.chain(cleanedSupPOCS).sortBy('poCode').sortBy('pocCode').value();
        templateData.POCSDivHeight = 50 + ((cleanedPOCS.length > cleanedSupPOCS.length) ? cleanedPOCS.length * lineHeight :
          cleanedSupPOCS.length * lineHeight);
        this.compareActualAndNew(cleanedPCVS, cleanedSupPCVS);
        templateData.cleanedPCVS = _.chain(cleanedPCVS).sortBy('poCode').sortBy('poCategoryName').sortBy('pcvCode').value();
        templateData.cleanedSupPCVS = _.chain(cleanedSupPCVS).sortBy('poCode').sortBy('pcvCode').value();
        templateData.PCVSDivHeight = 50 + ((cleanedPCVS.length > cleanedSupPCVS.length) ? cleanedPCVS.length * lineHeight :
          cleanedSupPCVS.length * lineHeight);
        this.compareActualAndNew(cleanedCustomFields, cleanedCustomFieldsSup);
        templateData.cleanedCustomFieldsSup = _.chain(cleanedCustomFieldsSup).sortBy('cfCode').value();
        templateData.cleanedCustomFields = _.chain(cleanedCustomFields).sortBy('cfCode').value();
        templateData.CFDivHeight = 80 + ((cleanedCustomFields.length > cleanedCustomFieldsSup.length) ?
          cleanedCustomFields.length * lineHeight :
          cleanedCustomFieldsSup.length * lineHeight);
      }
      return templateData;
    },

    onClickBtnIgnoreData: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:mark-synced-data-from-supplier', this.options.model.get('secId'));
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onClickBtnUpdateOnlyNewData: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:update-data-from-supplier', this.options.model.get('secId'), 'false');
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onClickBtnUpdateData: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:update-data-from-supplier', this.options.model.get('secId'), true);
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onClickBtnRefreshData: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:refresh-data-on-webshop', this.options.model.get('secId'));
      this.options.model.refreshServerless();
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onClickReCreateVrps: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:recreate-vrps-from-supplier', this.options.model.get('secId'));
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onClickRefreshChecksums: function () {
      $('.js-loading-block').show();
      var uddateDataRequest = App.request('products:refresh-product-checksums', this.options.model.get('secId'));
      $.when(uddateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    compareActualAndNew: function (a, b) {
      var currentIndex = -1;
      _.each(a, function (aV) {
        currentIndex++;
        var searchObj = {};
        for (var propCode in aV) {
          if (propCode.includes('Code')) {
            searchObj[propCode] = aV[propCode];
          }
        }
        var result = _.findWhere(b, searchObj);
        if (!result) {
          b.splice(currentIndex, 0, aV);
          if (b.length > currentIndex) {
            b[currentIndex].lineClass = 'red-colour strikeout';
          }
          aV.lineClass = 'red-colour strikeout';
        } else {
          for (var prop in aV) {
            if (result[prop] !== undefined && aV[prop] !== undefined &&
              result[prop].toLowerCase().trim() !== aV[prop].toLowerCase().trim()) {
              result[prop + 'Style'] = 'color:red;';
            }
          }
        }
      });
      currentIndex = -1;
      _.each(b, function (aV) {
        currentIndex++;
        var searchObj = {};
        for (var propCode in aV) {
          if (propCode.includes('Code')) {
            searchObj[propCode] = aV[propCode];
          }
        }
        var result = _.findWhere(a, searchObj);
        if (!result) {
          b[currentIndex].lineClass = 'red-colour';
          a.splice(currentIndex, 0, _.clone(b[currentIndex]));
          if (a.length > currentIndex) {
            a[currentIndex].lineClass = 'missing-data';
          }
        } else {
          for (var prop in aV) {
            if (result[prop] !== undefined && aV[prop] !== undefined &&
              result[prop].toLowerCase().trim() !== aV[prop].toLowerCase().trim()) {
              aV[prop + 'Style'] = 'color:red;';
              if (a.length > currentIndex) {
                a[currentIndex][prop + 'Style'] = 'color:red;';
              }
            }
          }
        }
      });
    },
    checkMissingCodeProperties: function (cleanedPCVS, requiredProperties) {
      _.each(cleanedPCVS, function (pcv) {
        _.each(requiredProperties, function (prop) {
          var propExists = _.find(Object.keys(pcv), function (property) {
            return property === prop;
          });
          if (propExists === undefined) {
            pcv[prop] = '';
          }
        });
      });
    }
  });

  module.exports = ProductDataView;
});

