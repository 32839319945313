/**
 * Created by BFR on 08/06/2022.
 */
define('changeDifficultyLevelPopupView',[
  'module',
  'jquery',
  'underscore',
  'settings',
  'template!changeDifficultyLevelPopupTpl',
  'dialogFormView'
], function (
  module,
  $,
  _,
  Settings,
  viewTpl,
  DialogFormView
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: viewTpl,

    ui: {
      inputName: '.js-info-input'
    },

    events: {
      'change @ui.inputName': 'onSupplierNumberChange'
    },

    onSupplierNumberChange: function (e) {
      var $target = $(e.currentTarget),
        selected = $target.find('option:selected'),
        value = selected.val();
      
      if (!value) {
        this.options.difficultyLevel = null;
      } else {
        this.options.difficultyLevel = Number(value);
      }
    },

    serializeData: function () {
      var templateData = {
        difficultyLevel: this.options.difficultyLevel
      };
      return templateData;
    },

    onShow: function () {
      $.material.init();
    }
  });
});
