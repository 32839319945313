define('ordersDetailOrderStatusView',[
  'module',
  'app',
  'jquery',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'changeDatePopupView',
  'changeSupplierNumberPopupView',
  'ordersDetailOrderStatusTrackingsView',
  'ordersDetailOrderStatusTypesView',
  'ordersDetailOrderStatusStatusesView',
  'ordersDetailOrderStatusCustomerReferenceView',
  'template!ordersDetailOrderStatusView'
], function (
  Module,
  App,
  $,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  ChangeDatePopupView,
  ChangeSupplierNumberPopupView,
  OrdersDetailOrderStatusTrackingsView,
  OrdersDetailOrderStatusTypesView,
  OrdersDetailOrderStatusStatusesView,
  CustomerReferenceView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-status-view',

    ui: {
      type: '.type .value',
      editExpeditionDateButton: '.btn-edit-expedition-date',
      editDeadLineDateButton: '.btn-edit-deadline-date',
      editValidationDateButton: '.btn-edit-validation-date',
      editSupplierNumberButton: '.btn-edit-supplier-number',
      copySupplierNumberButton: '.btn-copy-supplier-number',
      status: '.status .value',
      createDate: '.creation-date .value',
      expDelivery: '.express-delivery .value',
      expDeliveryDate: '.delivery-date .value',
      expeditionDate: '.exp-shipping-date',
      validationDateContainer: '.validation-date',
      validationDate: '.validation-date .value',
      deadlineDate: '.deadline-date',
      paymentTransactionLink: '.payment-link .value',
      paymentMethod: '.payment-method .value',
      tracking: '.tracking .value',
      trackingStatus: '.tracking-status .value',
      supplierNumber: '.supplier-number .value',
      externalId: '.external-id .value',
      customerReference: '.customer-reference .value'
    },

    regions: {
      trackingsRegion: '.trackings-region',
      typesRegion: '.types-region',
      statusesRegion: '.statuses-region',
      customerReferenceRegion: '.customer-reference-region'
    },

    events: {
      'click @ui.editExpeditionDateButton': 'onEditExpeditionDate',
      'click @ui.editDeadLineDateButton': 'onEditDeadLineDate',
      'click @ui.editValidationDateButton': 'onEditValidationDate',
      'click @ui.editSupplierNumberButton': 'onEditSupplierNumber',
      'click @ui.copySupplierNumberButton': 'onCopySupplierNumber'
    },

    modelEvents: {
      'change:supplierNumber': 'renderContent',
      'change:expDeliveryDate': 'renderContent',
      'change:deliveryDate': 'renderContent',
      'change:expShippingDate': 'renderContent',
      'change:validationDate': 'renderContent',
      'change:deadlineDate': 'renderContent',
      'change:paymentMethod': 'renderContent',
      'change:paymentTransactionNr': 'renderContent',
      'change:nbrPackSent': 'renderContent',
      'change:nbrPackDelivered': 'renderContent',
      'change:expressShip': 'renderContent'
    },

    onShow: function() {
      var trackingsView = new OrdersDetailOrderStatusTrackingsView({
        model: this.model,
        customer: this.options.customer
      });
      this.getRegion('trackingsRegion').show(trackingsView);

      //-------------------
      var typesView = new OrdersDetailOrderStatusTypesView({
        model: this.model
      });
      this.getRegion('typesRegion').show(typesView);

      //-------------------
      var statusesView = new OrdersDetailOrderStatusStatusesView({
        model: this.model 
      });
      this.getRegion('statusesRegion').show(statusesView);

      //-------------------
      this.customerReferenceView = new CustomerReferenceView({
        model: this.model
      });
      this.getRegion('customerReferenceRegion').show(this.customerReferenceView);
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {      

      if (this.model.get('supplierNumber'))
        this.ui.supplierNumber.html(this.model.get('supplierNumber'));
      else
        this.ui.supplierNumber.html('-');

      //-------------------
      if (this.model.get('createDate'))
        this.ui.createDate.html(DateUtils.toMDYHHMMFormat(this.model.get('createDate')));
      else
        this.ui.createDate.html('-');

      //-------------------
      if (this.model.get('expDeliveryDate'))
        this.ui.expDeliveryDate.html(DateUtils.toDateFormatString(this.model.get('expDeliveryDate')));
      else
        this.ui.expDeliveryDate.html('-');

      if (this.model.get('expressShip') &&
      this.model.get('savedPrice') &&
      this.model.get('savedPrice').expressShippingCost) {
        var expressShipping = _.i18n('common.yes');

        var numberLocale = 'fr-BE';
        var configNumberLocale = Settings.configValue('priceLocale');
        if (configNumberLocale)
          numberLocale = configNumberLocale;

        expressShipping += ' (€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(parseFloat(this.model.get('savedPrice').expressShippingCost)) + ')';

        this.ui.expDelivery.html(expressShipping);
      }
      else
        this.ui.expDelivery.html('-');

      //-------------------
      if (this.model.get('expShippingDate'))
        this.ui.expeditionDate.html(DateUtils.toDateFormatString(this.model.get('expShippingDate')));
      else
        this.ui.expeditionDate.html('-');

      //-------------------
      if (Settings.configValue('orderDetail.hideValidationDate') === true)
        this.ui.validationDateContainer.hide();
      else
        this.ui.validationDateContainer.show();

      //-------------------

      if (this.model.get('validationDate'))
        this.ui.validationDate.html(DateUtils.toDateFormatString(this.model.get('validationDate')));
      else
        this.ui.validationDate.html('-');

      //-------------------

      if (this.model.get('deadlineDate'))
        this.ui.deadlineDate.html(DateUtils.toDateFormatString(this.model.get('deadlineDate')));
      else
        this.ui.deadlineDate.html('-');

      //-------------------      

      if (this.model.get('paymentMethod'))
        this.ui.paymentMethod.html(this.model.get('paymentMethod'));
      else
        this.ui.paymentMethod.html('-');

      //-------------------

      if (this.model.get('externalId'))
        this.ui.externalId.html(this.model.get('externalId'));
      else
        this.ui.externalId.html('-');

      //-------------------

      if (this.model.get('customerReference'))
        this.ui.customerReference.html(this.model.get('customerReference'));
      else
        this.ui.customerReference.html('-');

      //------------------- 

      var paymentTransactionNr = this.model.get('paymentTransactionNr');

      var link = '';

      if (paymentTransactionNr) {
        paymentTransactionNr = paymentTransactionNr.replaceAll('"', '');
        if ((this.model.get('paymentMethod') === 'Mollie' ||
            this.model.get('paymentMethod') === 'Mollie-iDeal') && Settings.configValue('mollieOrg')) {
          link = 'https://www.mollie.com/dashboard/'+Settings.configValue('mollieOrg')+'/payments/' + paymentTransactionNr;
        } else if (this.model.get('paymentMethod') === 'Paypal')
          link = 'https://www.paypal.com/activity/payment/' + paymentTransactionNr;
      }

      if (link) {
        var sHtml = '<a href="' + link + '" target="_blank">' + paymentTransactionNr + '</a>';
        this.ui.paymentTransactionLink.html(sHtml);
      } else
        this.ui.paymentTransactionLink.html('-');   

      //-------------------     
      
      var value = '';
        
      if (this.model.get('nbrPackSent') > 0 || this.model.get('nbrPackDelivered') > 0)
        value = _.i18n('common.on-delivery');

      if(this.model.get('nbrPackDelivered') > 0 && 
      this.model.get('nbrPackDelivered') === this.model.get('nbrPackSent')) 
        value = _.i18n('common.delivered');
           
      if(value === '')
        this.ui.trackingStatus.html('-');
      else
        this.ui.trackingStatus.html(value);
    },

    onEditDeadLineDate: function () {
      this.openChangeDatePopup('deadlineDate', _.i18n('deadlineDate.popup.title'));
    },

    onEditExpeditionDate: function () {
      this.openChangeDatePopup('expShippingDate', _.i18n('expShippingDate.popup.title'));
    },

    onEditValidationDate: function () {
      this.openChangeDatePopup('validationDate', _.i18n('validationDate.popup.title'));
    },

    openChangeDatePopup: function (propertyName, title) {
      var popupView = new ChangeDatePopupView({
        date: this.model.get(propertyName),
        label: title,
        offset: 0
      });
      popupView.show({
        title: title,
        className: 'vectorizationAddNew',
        buttons: {
          reset: {
            label: _.i18n('common.reset'),
            className: 'btn-default',
            callback: _.bind(function () {            
              var obj = {
                purchaseOrderId: this.model.get('purchaseOrderId')
              };
              obj[propertyName] = null;
              App.request('order:update', obj).done(_.bind(function () {
                this.model.fetch();
              }, this));
            }, this)
          },
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function () {
              return true;
            }
          },
          main: {
            label: _.i18n('common.save'),
            className: 'btn btn-primary',
            callback: _.bind(function () {              
              var obj = {
                purchaseOrderId: this.model.get('purchaseOrderId'),
                refCommand: {
                  updateProperties: true
                }
              };
              obj[propertyName] = popupView.options.date;
              App.request('order:update', obj).done(_.bind(function () {
                this.model.fetch();
              }, this));
            }, this)
          }
        }
      });
    },

    onEditSupplierNumber: function () {
      var popupView = new ChangeSupplierNumberPopupView({
        supplierNumber: this.model.get('supplierNumber')
      });
      popupView.show({
        title: _.i18n('name.popup.title'),
        className: 'vectorizationAddNew',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function () {
              return true;
            }
          },
          main: {
            label: _.i18n('common.save'),
            className: 'btn btn-primary',
            callback: _.bind(function () {              
              App.request('order:update', {
                purchaseOrderId: this.model.get('purchaseOrderId'),
                supplierNumber: popupView.options.supplierNumber.trim()
              }).done(_.bind(function () {
                this.model.fetch();
              }, this));
            }, this)
          }
        }
      });
    },

    onCopySupplierNumber: function () {
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(this.model.get('supplierNumber')).select();
      document.execCommand('copy');
      $temp.remove();
    }
  });
});
