/**
 * Created by OLD on 20/11/2015.
 */
define('fCmessageModel',[
  'module',
  'marionette',
  'underscore',
  'app',
  'backbone',
  'settings'
], function (
  module,
  Marionette,
  _,
  App,
  Backbone,
  Settings
) {
  'use strict';

  var MessageModel = Backbone.Model.extend({
    defaults: {
      name: null,
      content: null,
      files: []
    },

    postUrl: function (urlParams, ticketSecID) {
      var params = {
        newMessageUrl: encodeURIComponent(Settings.url('base', '#' + App.getCurrentRoute())),
        lang: Settings.get('lang')
      };
      return Settings.url('compuzz2', 'tickets/' +
        ticketSecID + '/messages', _.extend(params, urlParams));
    }

  });

  return MessageModel;
});
