define('webshopProductsListFiltersEditView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'selectView',
  'checkboxView',
  'webshopCategoriesFinderView',
  'filtersBannerEditView',
  'template!webshopProductsListFiltersEditView',
  'entities/webshop/categories'
], function(
  App,
  Module,
  Backbone,
  _,
  SelectView,
  CheckboxView,
  CategoriesFinderView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'webshop-products-list-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webshop.products.list.filters.edit'),

    ui: _.extend({
      code: '.body .code input',
      name: '.body .name input',
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to',
      category: '.body .category input'
    }, FiltersBannerEditView.prototype.ui),

    events: {
      'click @ui.category': 'onCategoryClick'
    },

    regions: _.extend({
      statusSelectRegion: '.status .select-region',
      tagSelectRegion: '.tag .select-region',
      outdatedRegion: '.outdated .checkbox-region'
    }, FiltersBannerEditView.prototype.regions),

    onShow: function() {
      var collection = new Backbone.Collection();
      collection.add({id: '', value: _.i18n('common.all')});

      //----------------
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: '0',  value: _.i18n('common.disabled')},
          {id: '1',  value: _.i18n('common.enabled')}
        ]),
        tabindex: 3
      });
      this.statusSelectRegion.show(this.statusView);
      this.statusView.setSelected(this.model.get('status'));

      //----------------
      App.request('webshop.products.tags').done(_.bind(function (tags) {
        _.each(tags.models, _.bind(function (tag) {
          var model = new Backbone.Model({
            id: tag.get('id'),
            value: _.i18n('webshop.product.tag.' + tag.get('code'))
          });
          collection.add(model);
          this.tagView = new SelectView({
            collection: collection,
            tabindex: 4
          });

          this.tagSelectRegion.show(this.tagView);
          this.tagView.setSelected(this.model.get('tag'));
        }, this));
      }, this));

      //----------------
      this.outdatedView = new CheckboxView({
        title: '',
        text: _.i18n('webshop.product.outdated'),
        value: this.model.get('outdated'),
        tabindex: 8
      });
      this.outdatedRegion.show(this.outdatedView);


      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      this.ui.code.val(this.model.get('code'));
      this.ui.name.val(this.model.get('name'));
      if(this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if(this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);

      App.request('webshop.categories.datalist').done(_.bind(function (categories) {
        _.each(categories, _.bind(function (category) {
          if(category.get('id') === this.model.get('category'))
            this.ui.category.val(category.get('code'));
        }, this));
      }, this));
    },

    onCategoryClick: function() {
      /*Windows.open({
        view: _.bind(function () {
          return new CategoriesFinderView({

          });
        }, this),
        title: _.i18n('webshop.products.finder'),
        width: 400,
        modal: true
      });*/
    },

    onConfirm: function() {
      App.request('webshop.categories.datalist').done(_.bind(function (categories) {
        var categoryId = '';
        var category = _.find(categories, _.bind(function (cat) {
          if(cat.get('code') === this.ui.category.val())
            return cat;
        }, this));
        if(category)
          categoryId = category.get('id');

        this.model.set({
          status: this.statusView.getSelected().get('id'),
          tag: this.tagView.getSelected().get('id'),
          code: this.ui.code.val().trim(),
          name: this.ui.name.val().trim(),
          dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
          dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : '',
          category: categoryId,
          outdated: this.outdatedView.getValue()
        });

        FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
      }, this));
    },

    onReset: function() {
      this.statusView.setSelected('');
      this.tagView.setSelected('');
      this.ui.code.val('');
      this.ui.name.val('');
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
      this.ui.category.val('');
    }
  });  
});

