
define('template!graphicalRequestKanbanFilterViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="filters-blinder hide"></div>\n<div class="ui-jqgrid">\n  <table class="ui-jqgrid-htable ui-common-table" style="width:100%">\n    <thead>\n      <tr class="ui-jqgrid-labels" role="row">        \n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</span>\n        </th>        \n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('common.subject') )) == null ? '' : __t) +
'</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>User</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('common.date.create') )) == null ? '' : __t) +
'</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('common.date.mod') )) == null ? '' : __t) +
'</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('vectorization.tickets.requester') )) == null ? '' : __t) +
'</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('common.urgency') )) == null ? '' : __t) +
'</span>\n        </th>\n        <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n          <span>' +
((__t = ( _.i18n('vectorization.tariffScale') )) == null ? '' : __t) +
'</span>\n        </th>\n      </tr>\n      <tr class="ui-search-toolbar" role="row">\n        <tr class="ui-search-toolbar" role="row">\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_type">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <select role="select" name="type" id="gs_type" clearsearch="true" size="1" class="ui-widget-content ui-corner-all type-select">\n                        <option ';
 if(filters && filters.type === ''){ ;
__p += 'selected';
};
__p += '\n                          role="option" value="">all</option>\n                        <option ';
 if(filters && filters.type === 'vectorization'){;
__p += 'selected';
};
__p += '\n                          role="option" value="vectorization">vectorization</option>\n                        <option ';
 if(filters && filters.type === 'conversion'){;
__p += 'selected';
};
__p += '\n                          role="option" value="conversion">File conversion</option>\n                        <option ';
 if(filters && filters.type === 'remove_background'){;
__p += 'selected';
};
__p += '\n                          role="option" value="remove_background">Clipping</option>\n                        <option ';
 if(filters && filters.type === 'colorization'){;
__p += 'selected';
};
__p += '\n                          role="option" value="colorization">Colorization</option>\n                      </select>\n                      <a name="type" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>          \n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_name">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <input type="text" name="name" id="gs_name" clearsearch="true" role="textbox" class="ui-widget-content ui-corner-all"\n                      ';
 if (filters && filters.name){;
__p += ' value="' +
((__t = ( filters.name )) == null ? '' : __t) +
'" ';
};
__p += '>\n                      <a name="name" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_user">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <select role="select" name="userSecId" id="gs_user" clearsearch="true" size="1" class="ui-widget-content ui-corner-all">\n                        <option ';
 if(filters && filters.userSecId === ''){;
__p += 'selected';
};
__p += ' role="option" value="">all</option>\n                        ';
 _.each(users, function(user) { ;
__p += '\n                        <option ';
 if(filters && filters.userSecId === user.secId ){;
__p += 'selected';
};
__p += ' role="option" value="' +
((__t = ( user.secId )) == null ? '' : __t) +
'">' +
((__t = ( user.fullName )) == null ? '' : __t) +
'</option>\n                        ';
 }); ;
__p += '\n                      </select>\n                      <a name="userSecId" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_date">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <input type="period-picker-popover" name="date" id="gs_date" clearsearch="true" role="textbox" class="ui-widget-content ui-corner-all js-date-field" data-original-title="" title="" ';
 if (filters && filters.date){;
__p += ' value="' +
((__t = ( filters.date )) == null ? '' : __t) +
'" ';
};
__p += '>\n                      <a name="date" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_modDate">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <input type="period-picker-popover" name="modDate" id="gs_modDate" clearsearch="true" role="textbox" class="ui-widget-content ui-corner-all js-mod-date-field" data-original-title="" title="" ';
 if (filters && filters.modDate){;
__p += ' value="' +
((__t = ( filters.modDate )) == null ? '' : __t) +
'" ';
};
__p += '>\n                      <a name="modDate" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_requester">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <input type="text" name="requester" id="gs_requester" clearsearch="true" role="textbox" class="ui-widget-content ui-corner-all" ';
 if (filters && filters.requester){;
__p += ' value="' +
((__t = ( filters.requester )) == null ? '' : __t) +
'" ';
};
__p += '>\n                      <a name="requester" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_urgent">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>\n                    <td class="ui-search-input" headers="">\n                      <select role="select" name="urgent" id="gs_urgent" clearsearch="true" size="1" class="ui-widget-content ui-corner-all">\n                        <option ';
 if(filters && filters.urgent === ''){;
__p += 'selected';
};
__p += ' role="option" value="">all</option>\n                        <option ';
 if(filters && filters.urgent === 'false'){;
__p += 'selected';
};
__p += ' role="option" value="false">Not urgent</option>\n                        <option ';
 if(filters && filters.urgent === 'true'){;
__p += 'selected';
};
__p += ' role="option" value="true">Urgent</option>\n                      </select>\n                      <a name="urgent" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>                   \n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>          \n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-vector_tariffScale">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>                    \n                    <td class="ui-search-input" headers="">\n                      <select role="select" name="tariffScale" id="gs_tariffScale" clearsearch="true" size="1" class="ui-widget-content ui-corner-all">\n                        ';
 _.each(tariffScale, function(tariff) { ;
__p += '\n                        <option ';
 if(filters && parseInt(filters.tariffScale,10) === tariff.send ){;
__p += 'selected';
};
__p += ' role="option" value="' +
((__t = ( tariff.send )) == null ? '' : __t) +
'">' +
((__t = ( tariff.label )) == null ? '' : __t) +
'</option>\n                        ';
 }); ;
__p += '\n                      </select>\n                      <a name="tariffScale" title="Reset Search Value" style="padding-right: 0.3em;padding-left: 0.3em;" class="clearsearchclass">\n                        x\n                      </a>\n                    </td>                    \n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n        </tr>\n      </tr>\n    </thead>\n  </table>\n</div>';

}
return __p
};});

