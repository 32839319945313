/**
 * Created by BFR on 13/03/2020.
 */
define('productsSupplierMain',[
  'module',
  'logger',
  'productsSupplierMainRouter'
], function(
  module,
  Logger
) {
  'use strict';
	
  var ProductsSupplier = {
    start: function() {
      Logger.log(module, 'starting ProductsSupplierModule');
    },

    stop: function() {
      Logger.log(module, 'stopping ProductsSupplerModule');
    }
  };

  module.exports = ProductsSupplier;
});
