define('caccountContactsInfoTabView',[
  'module',
  'marionette',
  'template!caccountContactsInfoTabTpl',
  'plivoUtils'
], function(
  module,
  Marionette,
  infoTabTpl,
  PlivoUtils
) {
  'use strict';

  var CAccountsContactsInfoTabView = Marionette.ItemView.extend({
    template: infoTabTpl,

    triggers: {
      'click @ui.editBtn' : '_onEditContactInfo'
    },

    ui: {
      editBtn: '.js-edit',
      phoneBtn: '.p-phone'
    },

    events: {
      'click @ui.phoneBtn': 'onBtnPhoneClick'
    },

    onBtnPhoneClick: function() {
      PlivoUtils.callNumber(this.model.get('tel'));
    },

    serializeData: function() {
      return this.model.toJSON();
    }
  });

  module.exports = CAccountsContactsInfoTabView;
});
