
define('template!pricelistsTabEmptyTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n\t<div class="row pricelist-select-container">\n\t\t<div class="col-xs-5">\n\t\t\t<div class="form-group col-xs-6">\n\t\t\t\t<a class="btn add-new-pricelist-data-b add-btn-text">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n('vectorization.new') )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

