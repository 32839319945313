
define('template!editVariationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="variation" style="position: relative;">\n  <div class="row">\n    <div class="col-xs-4 var">\n      <div class="var">\n        <div class="row">\n          <div class="col-xs-9">\n            <div class="var-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="var-info">\n              <div class="var-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-8 options">\n      <div class="options">\n        <div class="options-title">\n          Options\n        </div>\n        <div class="row options-header">\n          <div class="col-xs-2 code">Code</div>\n          <div class="col-xs-4 name">Name</div>\n          <div class="col-xs-2 color">Color</div>\n          <div class="col-xs-4 images">Images</div>\n        </div>\n        <div class="options-region"></div>\n      </div>\n    </div>\n  </div>\n  ';
 if (!readOnly) { ;
__p += '\n  <div class="btn-container btn-variation-container" style="display: none;">\n    <i class="mdi mdi-action-delete btn-delete-var clickable btn-frame"></i>\n    <i class="mdi mdi-editor-mode-edit btn-edit-var clickable btn-frame"></i>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

