define('ordersListClientView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!ordersListClientView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-client-view table-field-view',

    modelEvents: {
      'change:customerName': 'render',
      'change:endUserId': 'render'
    },

    serializeData: function() {
      var data = {};

      data.customerName = '';
      /*if(this.model.get('customerName'))
        data.customerName = this.model.get('customerName');*/

      //---------

      data.endUserId = '';
      if(this.model.get('endUserId'))
        data.endUserId = this.model.get('endUserId');

      return data;
    }
  });
});
