define('caccountContactsDetailsData',[
	'underscore'
], function (_) {
  'use strict';

  var caccountsContactsDetailTabs = [
    {
      route: 'info',
      name: 'info',
      title: _.i18n('caccountContact.tabTitle.info')
    }
  ];

  return caccountsContactsDetailTabs;
});
