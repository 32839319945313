/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintAreasView',['module',
        'marionette',
        'sharePrintAreaView',
        'template!sharePrintAreasTpl'],
  function(module,
           Marionette,
           SharePrintAreaView,
           viewTpl){
  'use strict';

  var SharePrintAreasView = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.printarea-items',
    childView: SharePrintAreaView,
    className: 'col-md-12 p-t-10',

    childEvents: {
      'item:selection:change': function () {
        this.trigger('item:selection:change');
      }
    },

    events: {
      'click .btn-add-printarea': 'onAddPrintAreaClick'
    },

    onAddPrintAreaClick: function() {

    }
  });

  module.exports = SharePrintAreasView;
});
