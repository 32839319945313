define('ordersDeliveryView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'underscore',
  'settings',
  'dateUtils',
  'ordersDetailTrackingListView',
  'template!ordersDeliveryView'
], function (
  App,
  Module,
  Marionette,
  Backbone, 
  _,
  Settings,
  DateUtils,
  OrdersDetailTrackingListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-delivery-view',    

    ui: {
      clock: '.clock',
      truck: '.truck',
      printer: '.printer',
      dates: '.dates',
      expeditionDate: '.expedition-date',
      deadlineDate: '.deadline-date'
    },

    events: {
      'click': 'onShowTracking'
    },    

    modelEvents: {
      'change:nbrPackSentToPrinter': 'render',
      'change:nbrPackDeliveredToPrinter': 'render',
      'change:nbrPackSent': 'render',
      'change:nbrPackDelivered': 'render',
      'change:expShippingDate': 'render',
      'change:deadlineDate': 'render'
    },

    onRender: function() {
      if(this.options.readOnly === true)
        this.$el.addClass('read-only');
      else
        this.$el.removeClass('read-only');

      //--------------
      var mode = ''; // No tracking
      var hasTracking = false;

      if (this.model.get('nbrPackSentToPrinter') > 0 || this.model.get('nbrPackDeliveredToPrinter') > 0) {
        hasTracking = true;
        mode = 'printer';  // Tracking to printer
      }
        
      if (this.model.get('nbrPackSent') > 0 || this.model.get('nbrPackDelivered') > 0) {
        hasTracking = true;
        mode = 'truck'; // Tracking to customer
      }
      
      if(mode === 'printer')
        this.ui.printer.css('display', 'flex');
      else
        this.ui.printer.css('display', 'none');

      if(mode === 'truck')
        this.ui.truck.css('display', 'flex');
      else
        this.ui.truck.css('display', 'none');

      if(mode === '')
        this.ui.clock.css('display', 'flex'); 
      else
        this.ui.clock.css('display', 'none');      

      //--------------
      var expeditionDate = null;
      var deadlineDate = null;
      var date = null;
      var dateTitle = '';
      var sDate = '';
      var monthSize = Settings.getConfig('orders.deliveryDateShortMonthSize', 3);

      if (this.model.get('expShippingDate')) {
        if (typeof (this.model.get('expShippingDate')) === 'object')
          expeditionDate = this.model.get('expShippingDate');
        else
          expeditionDate = new Date(this.model.get('expShippingDate'));

        date = expeditionDate;

        dateTitle += 'Exp: ' + DateUtils.toDateFormatString(expeditionDate);

        sDate = 'E.&nbsp;' + expeditionDate.getDate();

        sDate += ' ' + expeditionDate.toLocaleString('default', {month: 'long'}).substring(0, monthSize);
        this.ui.expeditionDate.html(sDate);
        this.ui.expeditionDate.show();
      } else
        this.ui.expeditionDate.hide();

      if (this.model.get('deadlineDate')) {
        if (typeof (this.model.get('deadlineDate')) === 'object')
          deadlineDate = this.model.get('deadlineDate');
        else
          deadlineDate = new Date(this.model.get('deadlineDate'));

        date = deadlineDate;

        if(dateTitle !== '')
            dateTitle += '\n';
        dateTitle += 'Deadline: ' + DateUtils.toDateFormatString(deadlineDate);

        sDate = 'D.&nbsp;' + deadlineDate.getDate();
        sDate += ' ' + deadlineDate.toLocaleString('default', {month: 'long'}).substring(0, monthSize);
        this.ui.deadlineDate.html(sDate);
        this.ui.deadlineDate.show();
      } else {
        this.ui.deadlineDate.hide();
      }

      if (this.model.get('deadlineDate') && mode !== 'truck')
        this.$el.addClass('deadline');
      else
        this.$el.removeClass('deadline');

      //--------------
      /*if(mode === 'truck') {
        if (this.model.get('nbrPackDelivered') === this.model.get('nbrPackSent'))
          this.$el.addClass('delivered');  
        else
          this.$el.removeClass('delivered');
      } */

      //--------------
      this.$el.removeClass('out-of-date');
      if(date &&
        date < new Date() &&
        mode === 'truck' &&
        this.model.get('nbrPackDelivered') < this.model.get('nbrPackSent')) {
        this.$el.addClass('out-of-date');
      }

      if(dateTitle !== '')
        this.ui.dates.attr('title', dateTitle);

      if(expeditionDate && deadlineDate)
        this.ui.dates.addClass('small');
      else
        this.ui.dates.removeClass('small');
     
      //--------------

      this.$el.hide();

      this.$el.removeClass('has-tracking'); 
      this.$el.attr('late-delivery', false);
      this.$el.removeClass('delivered'); 
      //this.$el.attr('not-delivered', false);

      if (hasTracking) {        
        this.$el.addClass('has-tracking'); 
        this.$el.css('display', 'flex');

        if (this.model.get('nbrPackDelivered') > 0 &&
            this.model.get('nbrPackDelivered') === this.model.get('nbrPackSent'))
          this.$el.addClass('delivered');

        /*if (this.model.get('nbrPackDelivered') > 0)
          this.$el.addClass('delivered'); //client has been delivered (green)          
        else
          this.$el.attr('not-delivered'); //client has not been delivered yet (orange)
         */
        /*if (date) {
          if (this.model.get('nbrPackDelivered') === 0 && date < new Date())
            this.$el.attr('late-delivery'); //red            
        }*/
      } else {                       
        if (date)
          this.$el.css('display', 'flex');   
      }
    },

    onShowTracking: function(e) {
      if(this.options.readOnly === true)
        return;

      e.preventDefault();
      e.stopPropagation();
      OrdersDetailTrackingListView.prototype.open(this.model);
    }
  });
});
  
