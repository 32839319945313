define('productGridLayoutView',[
  'module',
  'marionette',
  'template!productGridLayoutTpl',
  'productGridFiltersView',
  'underscore',
  'addNewPictureButtonView',
  'productGridCollectionView',
  'productGridPaginationView',
  'app',
  'settings',
  'productViewSwitcherView',
  'backbone',
  'entities/product'
], function(
  module,
  Marionette,
  gridLayoutTpl,
  FiltersView,
  _,
  AddNewButtonView,
  GridCollectionView,
  PaginationView,
  App,
  Settings,
  ViewSwitcherView,
  Backbone
) {
  'use strict';

  var GridLayoutView = Marionette.LayoutView.extend({
    template: gridLayoutTpl,
    className: 'content',
    regions: {
      filters: '.js-filters-region',
      viewSwitcher: '.js-view-switcher-region',
      addNew: '.js-add-new-region',
      list: '.js-list-region',
      pagination: '.js-pagination-region',
      loader: '.js-loader-region'
    },

    ui: {
      loader: '.js-loader'
    },

    filtersName: 'productsListFilters',

    productsCollection: undefined,
    productFilters: {
      first: 0,
      pageSize: 12
    },
    pagination: undefined,

    initialize: function() {
      var pageSize = this.productFilters.pageSize;
      this.productsCollection = App.request('products:collection');
      if(Settings.getFromMemory(this.filtersName)) {
        this.productFilters = Settings.getFromMemory(this.filtersName);
        this.productFilters.pageSize = pageSize;
      }
      this.pagination = new Backbone.Model({
        first: this.productFilters.first,
        pageSize: this.productFilters.pageSize
      });

      this.listenTo(this.pagination, 'change:currentPage', this._fetchProducts);
      this.listenTo(this.productsCollection, 'request', this._showLoader);
      this.listenTo(this.productsCollection, 'sync', this._showList);
      this.listenTo(this.productsCollection, 'count:sync', this._showPagination);
    },

    onShow: function() {
      var filtersView = new FiltersView({
        filters: this.productFilters
      });

      this.listenTo(filtersView, 'change', this._filterChange);

      this._fetchCount();

      this.getRegion('filters').show(filtersView);
      this.getRegion('viewSwitcher').show(new ViewSwitcherView({
        currentView: 'grid'
      }));

      $.material.init();
    },

    _fetchCount: function() {
      this._fetchProducts();
      this.productsCollection.getCount(this.productFilters);
    },

    _fetchProducts: function() {
      this.productFilters.first = this.pagination.get('first');
      this.productFilters.pageSize = this.pagination.get('pageSize');
      Settings.setToMemory(this.filtersName, this.productFilters);
      this.productsCollection.fetch(this.productFilters);
    },

    _showLoader: function() {
      this.ui.loader.show();
    },

    _showList: function() {
      this.ui.loader.hide();
      this.getRegion('list').show(new GridCollectionView({
        collection: this.productsCollection
      }));
    },

    _showPagination: function(count) {
      this.pagination.set('totalCount', count);
      this.getRegion('pagination').show(new PaginationView({
        model: this.pagination
      }));
    },

    _filterChange: function(filters) {
      var that = this;
      _.each(filters, function(value, filterName) {
        that.productFilters[filterName] = value;
      });
      this.pagination.set({
        currentPage: 1,
        first: 0
      }, {silent: true});
      that._fetchCount();
    }
  });

  module.exports = GridLayoutView;
});
