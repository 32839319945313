/**
 * Created by BFR on 24/02/2020.
 */
define('customFieldView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!customFieldTpl',
    'dynamicTextView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView
  ) {
    'use strict';
  
    var CustomFieldView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        nameRegion: '.name-region',
        valueRegion: '.value-region'
      },

      onShow: function () {
        this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);
        this.valueDtView = new DynamicTextView({ model: this.model.get('value')});
        this.getRegion('valueRegion').show(this.valueDtView);
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = CustomFieldView;
  });

