define('ordersDetailView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'modalsAlertView',
  'ordersDetailHeaderView',
  'ordersDetailBodyView',
  'template!ordersDetailView',
  'entities/orders'
], function (
  App,
  Module,
  Marionette,
  _,
  AlertView,
  HeaderView,
  BodyView,
  Tpl,
  Orders
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-view',    

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region'      
    },   

    ui: { 
      closeButton: '.close-button'
    },

    events: {
      'click @ui.closeButton': 'onClose'
    },

    initialize: function () {
      this.model = App.request('order-view:model');
      this.poi = this.options.poi;
      this.tabName = this.options.tabName;
      this.popup = null;
      this.headerView = null;
      this.view = null;
    },

    setSelectedTab: function(tabName) {
      this.tabName = tabName;
      this.bodyView.selectTab(tabName);
    },

    onShow: function() {

    },

    onRender: function() {
      this.model.set('poi', this.poi, {silent: true});
      this.model.fetch(true).done(_.bind(function () {

        this.customer = App.request('customers:entity:end-user-id', this.model.get('order').get('endUserId'));
        this.customer.fetch().done(_.bind(function() {
          this.headerView = new HeaderView({
            model: this.model,
            customer: this.customer,
            parentView: this
          });
          this.getRegion('headerRegion').show(this.headerView);  
                      
          this.bodyView = new BodyView({
            model: this.model,
            tabName: this.tabName,
            customer: this.customer,
            parentView: this
          });
          this.getRegion('bodyRegion').show(this.bodyView);     
          
          this.bodyView.selectTab(this.tabName);
        }, this));

        //--------------------
        this.listenTo(this.model.get('order'), 'deleted', _.bind(function() {
          this.orderDeleted();
        }, this));

        this.listenTo(this.model.get('order'), 'change', _.bind(function (model) {
          if (model.hasChanged('endUserId'))
            this.render();
        }, this));

      }, this));           
    },

    orderDeleted: function () {
      AlertView.prototype.open('', _.i18n('common.orders.orderDeleted'), _.bind(function () {
        Orders.closeOrder();
      }, this));
    },

    onClose: function() {
      Orders.closeOrder();
    }
  });
});

