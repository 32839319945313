/**
 * Created by RKL on 21/08/2015.
 */
define('customersMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'underscore',
  'marionette',
  'app',
  'customersDetailBodyContactTabs',
  'itemPagesNameMixin',
  'entities/caccounts',
  'entities/customers/customers'
], function(
  require,
  exports,
  module,
  Logger,
  _,
  Marionette,
  App,
  CustomersDetailBodyContactTabs,
  ItemPagesNameMixin,
  Accounts
) {
  'use strict';

  var CustomersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'customers': 'showList',
      'customers/code/:code(/:tab)': 'showByCode',
      'customers(/:id)(/:tab)(/:secId)(/:reload)': 'showDetail',
      'customers/:id/contacts/:secId/details/:tabName': 'showContactsDetailsShow'
    }
  });

  var API = {
    showByCode: function (code, tab) {
      this.customer = App.request('customers:entity:end-user-id', code);
      this.customer.fetch().done(_.bind(function (customer) {
        if (tab) {
          App.navigate('customers/' + customer.secId + '/' + tab, {trigger: true});
        } else {
          this.showDetail(customer.secId, tab ? tab : 'profile');
        }
      }, this));
    },

    showList: function () {
      require([
        'customersListController',
        'contentShowController'
      ], function (ListController, ContentShowController) {
        ContentShowController.showContent().done(function () {
          ListController.showCustomersList();
          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
        });
      });
    },

    showDetail: function(id, tabName) {
      require([
        'customersDetailView',
        'contentShowController'
      ], function (View, ContentShowController) {
        if(Accounts.detailView && Accounts.detailView.isDestroyed)
          Accounts.detailView = null;

        if(Accounts.detailView && Accounts.detailView.model.get('secId') !== id) {
          Accounts.detailView.destroy();
          Accounts.detailView = null;
        }

        //if (!Accounts.detailView) {
          Accounts.detailView = new View({
            id: id,
            tabName: tabName
          });

          ContentShowController.showContent().done(_.bind(function () {
            App.regions.getRegion('main').currentView.getRegion('content').show(Accounts.detailView);
            App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
          }, this));
        //} else {
         // Accounts.detailView.setSelectedTab(tabName);
       // }
      });
    },

    showContactsDetailsShow: function(id, contactSecId, tab) {
      require([
        'customersDetailBodyContactController',
        'contentShowController'
      ], function(
        DetailsShowController,
        ContentShowController
      ) {
        ContentShowController.showContent().done(function() {
          var data;
          data = {
            id: id,
            tab: _.findWhere(CustomersDetailBodyContactTabs, {name: tab}),
            secId: contactSecId,
            type: 'customer'
          };
           if (id) {
            DetailsShowController.fetchContact(data);
          } else {
            DetailsShowController.showContact(data);
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
        });
      });
    }
  };

  App.on('customers:show', function(id, tabName) {
    if (!tabName)
      tabName = 'profile';

    App.navigate('customers/' + id + '/' + tabName, {trigger: true});
  });

  App.on('before:start', function() {
    new CustomersRouter({
      controller: API
    });
  });

  module.exports = CustomersRouter;
});
