define('webshopProductsDetailBodyCategoriesToolsView',[
  'app',
  'module',
  'underscore',
  'tableFieldView',
  'webshopProductsDetailBodyCategoriesTagsEditView',
  'template!webshopProductsDetailBodyCategoriesToolsView'
], function (
  App,
  Module,  
  _,
  FieldView,
  TagsEditView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-categories-tools-view ' + FieldView.prototype.className,

    ui: {
      deleteButton: '.delete-button',
      tagsButton: '.tags-button'
    },

    events: {
      'click @ui.deleteButton': 'onDelete',
      'click @ui.tagsButton': 'onTags'
    },

    onDelete: function (e) {
      e.stopPropagation();
      this.tableView.model.deleteCategory(this.model.getId()).done(_.bind(function () {
        this.tableView.bodyView.collection.remove(this.model);
        this.tableView.footerView.fetch();
      }, this));
    },

    onTags: function (e) {
      e.stopPropagation();

      TagsEditView.prototype.open({
        onConfirm: _.bind(function () {

        }, this)
      });
    }
  });
});
