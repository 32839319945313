define('phoneNumbersView',[
    'module',
    'underscore',
    'backbone',
    'app',
    'tableView',
    'entities/phone/numbers'
  ], function (
    Module,
    _,
    Backbone,
    App,
    TableView
  ) {
    'use strict';
  
    Module.exports = TableView.extend({
      className: 'phone-numbers-view table-view ' + TableView.prototype.className,

      setFields: function() {
        return [{
          name: 'Country',
          label: _.i18n('common.country'),
          bind: 'countryName',         
          className: 'country'
        }, {
          name: 'Number',
          label: _.i18n('phone.number.number'),
          bind: 'number',         
          className: 'number'
        }];
      },

      fetch: function(first, pageSize) {   
      
        var defer = $.Deferred();  
        
        var url = App.request('phone-numbers:get-collection-url');
        url += '&pageSize=' + pageSize;
        url += '&first=' + first; 
  
        $.ajax({
          type: 'GET',
          url: url,
          success: _.bind(function (items) {
            var models = [];         
            
            _.each(items, _.bind(function(item) {
              var model = new Backbone.Model();

              if(item.refCountry.name)
                model.set('countryName', item.refCountry.name);

              if(item.number)
                model.set('number', item.number);

              models.push(model);
            }, this));
         
            defer.resolve(models);
          }, this)  
        });
  
        return defer.promise();
      }         
    });
  });
