define('ordersDetailSupplierEditProductPrintZoneColorView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailSupplierEditProductPrintZoneColorView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'color-view',
    template: Tpl,

    serializeData: function () {
      var data = {};

      data.name = '-';
      if (this.model.get('pms'))
        data.name = this.model.get('pms');

      return data;
    }
  });
});
