
define('template!sharePrintZonesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<i class="mdi-content-add btn-add-printzone"></i>\n<div class="printzone-items">\n\n</div>';

}
return __p
};});

