
define('template!caccountPriceFactorsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="top">\n  <div class="left sa-item">\n    <div class="sa">\n      <div class="sa-code">FactorV2</div>\n    </div>\n    <div class="body">\n      <div class="row">\n        <label class="col-xs-9">30% margin buying price value</label>\n        <input class="col-xs-2 txt-param txt-param1 p-l-0 p-r-0" value="' +
((__t = ( factors.param1 )) == null ? '' : __t) +
'"/>\n      </div>\n      <div class="row">\n        <label class="col-xs-9">Minimum buying price order value</label>\n        <input class="col-xs-2 txt-param txt-param2 p-l-0 p-r-0" value="' +
((__t = ( factors.param2 )) == null ? '' : __t) +
'"/>\n      </div>\n      <div class="row">\n        <label class="col-xs-9">Maximum buying price staggers</label>\n        <input class="col-xs-2 txt-param txt-param3 p-l-0 p-r-0" value="' +
((__t = ( factors.param3 )) == null ? '' : __t) +
'"/>\n      </div>\n      <div class="row">\n        <label class="col-xs-12">FactorV2 per supplier</label>\n        <div class="col-xs-12">\n          <select class="ddl-suppliers-factorv2">\n            ';
 _.each(suppliers, function(sup) { ;
__p += '\n            <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="btn btn-add-factorsV2">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="col-xs-12 supplier-factorsv2-region"></div>\n      </div>\n    </div>\n  </div>\n    <div class="right sa-item">\n          <div class="sa">\n            <div class="sa-code">Factor</div>\n          </div>\n          <div class="col-xs-12">\n            <select class="ddl-suppliers">\n              ';
 _.each(suppliers, function(sup) { ;
__p += '\n              <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n              ';
 }); ;
__p += '\n            </select>\n          </div>\n          <div class="col-xs-12 supplier-factors-region"></div>\n          <div class="col-xs-12">\n            <div class="btn new-cAccount-button btn-add-factor">\n              <i class="mdi-content-add"></i>\n              <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="btn new-cAccount-button btn-delete-factor">\n              <i class="mdi-content-delete"></i>\n              <span>' +
((__t = ( _.i18n('factors.delete') )) == null ? '' : __t) +
'</span>\n            </div>\n          </div>\n        </div>\n</div>\n<div class="bottom">\n  <div class="left sa-item">\n    <div class="sa">\n      <div class="sa-code">Factor Imprint</div>\n    </div>\n    <div class="body">\n      <div class="row">\n        <div class="col-xs-12">\n          <select class="ddl-suppliers-imprint">\n            ';
 _.each(suppliers, function(sup) { ;
__p += '\n            <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-xs-12">\n          <div class="print-codes-region"></div>\n        </div>\n        <div class="col-xs-12">\n          <div class="supplier-print-factors-region"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="right sa-item">\n    <div class="sa">\n      <div class="sa-code">Transport Cost</div>\n    </div>\n    <div class="body">\n      <div>\n        <select class="ddl-suppliers-transport">\n          ';
 _.each(suppliers, function(sup) { ;
__p += '\n            <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n          ';
 }); ;
__p += '\n        </select>\n      </div>\n      <div class="sites">\n        <div class="item selected" data-id="">Default</div>\n        <div class="item" data-id="fr">FR</div>\n        <div class="item" data-id="be">BE</div>\n        <div class="item" data-id="at">AT</div>\n        <div class="item" data-id="de">DE</div>\n        <div class="item" data-id="en">EN</div>\n        <div class="item" data-id="it">IT</div>\n        <div class="item" data-id="dk">DK</div>\n        <div class="item" data-id="es">ES</div>\n        <div class="item" data-id="ua">UA</div>\n      </div>\n\n      <div>\n        <div class="transport-region"></div>\n      </div>\n      <div class="supplier-print-factors-region"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

