/**
 * Created by BFR on 26/12/2019.
 */
define('orderUtils',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'settings',
  'jquery',
  'productSelectDialogView',
  'pimProductSelectDialogView',
  'productConfigDialogView',
  'customersPopupSelectView',
  'ordersProductTypeSelectorView',
  'entities/orders',
  'contextMenu',
  'entities/caccounts/htmltemplates'
], function (
  module,
  _,
  App,
  bootbox,
  Settings,
  $,
  ProductSelectDialog,
  PimProductSelectDialog,
  ProductConfigDialog,
  CustomersPopupSelectView,
  OrdersProductTypeSelectorView
) {
  'use strict';

  var OrderUtils = {

    printOurselfPrice: {
      mumuku: {
        costSetup: 10,
        setup: 20,
        costUnit: 1,
        unit: 2
      },
      flex: {
        costSetup: 15,
        setup: 25,
        costUnit: 1.25,
        unit: 2.25
      },
      flexDigital: {
        surface: [{
          from: 0,
          to: 20.88,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 1.23
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 1.05
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 0.76
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 0.65
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 0.51
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 0.47
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 0.43
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 0.40
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 0.36
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 0.35
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 0.34
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 0.28
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 0.25
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 0.23
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 0.22
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 0.17
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 0.13
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.13
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.12
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.12
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.11
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.11
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.11
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.11
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.11
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.1
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.1
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.1
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.1
          }
          ]
        }, {
          from: 20.88,
          to: 51.62,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 1.59
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 1.37
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 1.06
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 0.91
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 0.76
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 0.72
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 0.65
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 0.62
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 0.58
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 0.55
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 0.53
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 0.47
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 0.39
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 0.35
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 0.27
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 0.22
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 0.20
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 0.20
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 0.19
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 0.18
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.17
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.16
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.15
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.14
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.14
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.14
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.13
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.13
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.13
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.12
          }
          ]
        }, {
          from: 51.62,
          to: 117.48,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 2.23
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 1.91
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 1.59
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 1.37
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 1.21
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 1.12
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 1.04
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 1.02
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 0.92
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 0.82
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 0.74
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 0.57
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 0.53
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 0.41
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 0.33
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 0.31
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 0.28
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 0.27
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 0.26
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 0.26
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.23
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.22
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.21
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.21
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.20
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.20
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.19
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.19
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.19
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.18
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.18
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.17
          }
          ]
        }, {
          from: 117.48,
          to: 256.08,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 3.35
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 2.87
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 2.55
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 2.18
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 2.06
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 1.92
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 1.77
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 1.49
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 1.2
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 1.16
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 1.1
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 0.70
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 0.63
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 0.58
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 0.54
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 0.52
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 0.49
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 0.41
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 0.40
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 0.37
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.35
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.33
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.32
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.31
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.31
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.29
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.29
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.29
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.28
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.28
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.27
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.26
          }
          ]
        }, {
          from: 256.08,
          to: 545.14,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 5.35
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 4.59
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 4.33
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 3.71
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 2.52
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 2.32
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 2.05
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 1.72
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 1.48
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 1.41
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 1.33
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 1.14
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 1.05
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 1.01
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 0.95
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 0.85
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 0.78
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 0.66
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 0.64
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 0.59
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.56
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.54
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.51
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.49
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.49
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.47
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.46
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.46
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.45
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.44
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.44
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.42
          }
          ]
        }, {
          from: 545.14,
          to: 1135.24,
          scale: [{
            qty: 10,
            costSetup: 0,
            costUnit: 9.1
          }, {
            qty: 15,
            costSetup: 0,
            costUnit: 7.8
          }, {
            qty: 20,
            costSetup: 0,
            costUnit: 5.3
          }, {
            qty: 30,
            costSetup: 0,
            costUnit: 4.3
          }, {
            qty: 40,
            costSetup: 0,
            costUnit: 3.1
          }, {
            qty: 50,
            costSetup: 0,
            costUnit: 2.8
          }, {
            qty: 60,
            costSetup: 0,
            costUnit: 2.6
          }, {
            qty: 70,
            costSetup: 0,
            costUnit: 2.5
          }, {
            qty: 80,
            costSetup: 0,
            costUnit: 2.4
          }, {
            qty: 90,
            costSetup: 0,
            costUnit: 2.3
          }, {
            qty: 100,
            costSetup: 0,
            costUnit: 2.2
          }, {
            qty: 150,
            costSetup: 0,
            costUnit: 2
          }, {
            qty: 200,
            costSetup: 0,
            costUnit: 1.9
          }, {
            qty: 250,
            costSetup: 0,
            costUnit: 1.75
          }, {
            qty: 300,
            costSetup: 0,
            costUnit: 1.6
          }, {
            qty: 350,
            costSetup: 0,
            costUnit: 1.45
          }, {
            qty: 400,
            costSetup: 0,
            costUnit: 1.33
          }, {
            qty: 450,
            costSetup: 0,
            costUnit: 1.12
          }, {
            qty: 500,
            costSetup: 0,
            costUnit: 1.08
          }, {
            qty: 600,
            costSetup: 0,
            costUnit: 1
          }, {
            qty: 700,
            costSetup: 0,
            costUnit: 0.95
          }, {
            qty: 800,
            costSetup: 0,
            costUnit: 0.91
          }, {
            qty: 900,
            costSetup: 0,
            costUnit: 0.86
          }, {
            qty: 1000,
            costSetup: 0,
            costUnit: 0.84
          }, {
            qty: 1200,
            costSetup: 0,
            costUnit: 0.83
          }, {
            qty: 1500,
            costSetup: 0,
            costUnit: 0.8
          }, {
            qty: 2000,
            costSetup: 0,
            costUnit: 0.79
          }, {
            qty: 2500,
            costSetup: 0,
            costUnit: 0.78
          }, {
            qty: 3000,
            costSetup: 0,
            costUnit: 0.77
          }, {
            qty: 3500,
            costSetup: 0,
            costUnit: 0.75
          }, {
            qty: 4000,
            costSetup: 0,
            costUnit: 0.74
          }, {
            qty: 4500,
            costSetup: 0,
            costUnit: 0.71
          }
          ]
        }]
      },
      flexThermo: {
        costSetup: 25,
        setup: 35,
        costUnit: 3,
        unit: 5
      }
    },

    loadTotalPrintSurface: function (orderModel) {
      var surface = 0;
      var defer = $.Deferred();
      var i, results = [],
        deferred,
        deferreds = [], url, comp;
      // 1. Make multiple AJAX calls to a Server
      for (i = 0; i < orderModel.get('compositions').length; i++) {
        comp = orderModel.get('compositions')[i];
        url = Settings.url('x2png', 'compositions/' + comp.secId + '/getMeasure');
        /*jshint loopfunc:true */
        deferred = $.ajax({
          type: 'GET',
          url: url,
          success: function (result) {
            // 2. Store the results in an array
            results.push(result);
          }
        });
        deferreds.push(deferred);
      }

      $.when.apply($, deferreds).then(function () {
        for (var j = 0; j < results.length; j++) {
          var result = results[j];
          surface += Number(result.width) * Number(result.height);
        }
        defer.resolve(surface / 100);
      });
      return defer.promise();
    },

    getAvailableStatuses: function (commantType) {
      var params = {
            locale: Settings.get('lang')
          };

      if (commantType === 'offer') {
        params.tag = 'offer';
      }

      return $.ajax({
        url: Settings.url('compuzz2', 'commands.json/command/status', params)
      });
    },

    getPrintOurselfPrice: function (quantity, it, surface) {
      var price = OrderUtils.printOurselfPrice[it],
        currentSurface, currentScale, previousScale,
        currentPrice;

      if (price.surface) {
        for (var i = 0; i < price.surface.length; i++) {
          currentSurface = price.surface[i];
          if (currentSurface.from < surface && currentSurface.to >= surface) {
            for (var j = 0; j < currentSurface.scale.length; j++) {
              currentScale = currentSurface.scale[j];
              if (currentScale.qty > quantity) {
                currentPrice = previousScale;
                break;
              }
              previousScale = currentScale;
            }
            break;
          }
        }
      }

      if (currentPrice) {
        currentPrice.setup = currentPrice.costSetup * 1.21;
        currentPrice.unit = currentPrice.costUnit * 1.21;
      }

      return currentPrice;
    },  

    calculateFactorV2: function (factorsV2, scales, quantity) {
      var indexScaleReference, i = 0, indexOfQuantity;
      _.each(scales, function (scale) {
        if (Number(scale.get('total')) > factorsV2.param1 && !indexScaleReference) {
          indexScaleReference = i;
        }
        if (scale.get('quantity') === quantity) {
          indexOfQuantity = i;
        }
        i++;
      });
      if (!indexScaleReference) {
        indexScaleReference = scales.length - 1;
      }
      if (!factorsV2.scales || factorsV2.scales.length === 0) {
        return 1 / (1 - 0.30 + (indexOfQuantity - indexScaleReference) * 0.01);
      } else {
        var factor = _.findWhere(factorsV2.scales, {idx: indexOfQuantity - indexScaleReference});
        if (!factor) {
          factor = factorsV2.scales[factorsV2.scales.length - 1];
        }
        var margin = factor.margin / 100;
        return 1 / (1 - margin);
      }
    },

    onProductConfigured: function (defer, configuration, productModel, type, customer, pim) {
      if (type) {
        type = type === 'offer'?'offer':'order';
        var prom;
        if (productModel.get('pim')) {
          prom = App.request('kanban:create-from-configuration-pim',
            type,
            productModel.get('cCode'),
            customer,
            customer,
            configuration
          );
        } else {
          prom = App.request('kanban:create-from-configuration',
            type,
            productModel.get('secId'),
            customer,
            customer,
            configuration
          );
        }
        prom.then(_.bind(function () {
          if (!Settings.configValue('hidePopupSameCustomer')) {
            bootbox.dialog({
              title: _.i18n('offer.continueTitle'),
              message: _.i18n('offer.continueText'),
              buttons: {
                success: {
                  label: _.i18n('offer.continueSameCustomer'),
                  className: 'btn-success',
                  callback: _.bind(function () {
                    this.openProductTypeDialog(defer, type, customer);
                  }, this)
                },
                main: {
                  label: _.i18n('offer.continueOtherCustomer'),
                  className: 'btn-primary',
                  callback: _.bind(function () {
                    this.openEndUserDialog(defer, type, pim);
                  }, this)
                },
                close: {
                  callback: function () {
                    defer.resolve();
                  }
                }
              }
            });
          } else {
            defer.resolve();
          }
        }, this));
      } else {
        defer.resolve(configuration, productModel, type, customer);
      }
    },

    openEndUserDialog: function (defer, type) {
      var userDialog = new CustomersPopupSelectView({
        onUserSelected: _.bind(function(model) {
          userDialog.hide();          
          if(model)
            OrderUtils.openProductTypeDialog(defer, type, model.get('endUserId'));
        }, this)
      });

      userDialog.show({title: _.i18n('dialog.offer.selectCustomer')});
    },

    openProductTypeDialog: function (defer, orderType, customer, orderToUpdate) {
      if (Settings.configValue('orders.newOrderSelectProductType')) {
        OrdersProductTypeSelectorView.prototype.open({
          onConfirm: _.bind(function (pim) {
            this.openProductDialog(defer, orderType, pim, customer, orderToUpdate);
          }, this)
        });
      } else {
        this.openProductDialog(defer, orderType, Settings.configValue('orders.newOrderFromPim'), customer, orderToUpdate);
      }
    },

    openProductDialog: function (defer, orderType, pim, customer, orderToUpdate) {
      if (pim) {
        this.productSelectDialog = new PimProductSelectDialog({
          multiSelect: false,
          displayLeaveButton: false
        });
      } else {
        this.productSelectDialog = new ProductSelectDialog({
          multiSelect: false,
          displayLeaveButton: false
        });
      }

      this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected',
          _.bind(function (products) {
            this.onProductsSelected(defer, orderType, customer, pim, products, orderToUpdate);
          }, this));

      this.productSelectDialog.show({
        title: _.i18n('dialog.offer.selectProduct'),
        className: 'product-selection-dialog'
      });
    },

    onProductsSelected: function (defer, type, customer, pim, products, orderToUpdate) {
      if (orderToUpdate) {
        if (pim) {
          $.when(orderToUpdate.changeProductPim(products[0].rowid)).always(_.bind(function () {
            this.configureProduct(defer, products, type, customer, pim);
          }, this));
        } else {
          $.when(orderToUpdate.changeProduct(products[0].rowid)).always(_.bind(function () {
            this.configureProduct(defer, products, type, customer, pim);
          }, this));
        }
      } else {
        this.configureProduct(defer, products, type, customer, pim);
      }
    },

    configureProduct: function (defer, products, type, customer, pim) {
      products = _.pluck(products, 'rowid');
      if (pim) {
        var selectedPimProduct = App.request('pim:products:model', {id: products[0]});
        selectedPimProduct.fetch().done(_.bind(function () {
          var selectedProduct = App.request('products:model', {code: selectedPimProduct.get('resellerData').models[0].get('code')});
          selectedProduct.fetch(true).done(_.bind(function () {
            this.productConfigDialog = new ProductConfigDialog({product: selectedProduct});
            this.productSelectDialog.hide();
            this.productConfigDialog.show({
              title: _.i18n('dialog.offer.configureOffer'),
              className: 'product-selection-dialog'
            });
            this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured', _.bind(function (configuration) {
              this.onProductConfigured(defer, configuration, this.productConfigDialog.model, type, customer, pim);
              this.productConfigDialog.hide();
            }, this));
          }, this));
        }, this));
      } else {
        var selectedProduct = App.request('products:model', {secId: products[0]});
        selectedProduct.fetch().done(_.bind(function () {
          selectedProduct.fullFetch(selectedProduct.get('cCode')).done(_.bind(function () {
            this.productConfigDialog = new ProductConfigDialog({product: selectedProduct});
            this.productSelectDialog.hide();
            this.productConfigDialog.show({
              title: _.i18n('dialog.offer.configureOffer'),
              className: 'product-selection-dialog'
            });
            this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured', _.bind(function (configuration) {
              this.onProductConfigured(defer, configuration, this.productConfigDialog.model, type, customer, pim);
              this.productConfigDialog.hide();
            }, this));
          }, this));
        }, this));
      }
    }
  };

  module.exports = OrderUtils;
});

