define('productAddNewPicturePopupView',[
  'module',
  'dialogView',
  'template!productAddNewPicturePopupTpl',
  'app'
], function(
  module,
  DialogView,
  addNewPicturePopupTpl,
  App
) {
  'use strict';

  var AddNewPicturePopupView = DialogView.extend({
    template: addNewPicturePopupTpl,

    ui: {
      fromEmpty: '.js-from-empty',
      fromUpload: '.js-from-upload'
    },

    events: {
      'click @ui.fromEmpty': '_fromEmpty',
      'change @ui.fromUpload': '_fromUpload'
    },

    _fromEmpty: function() {
      App.navigate('product/' + this.options.productSecId + '/pictures/new/editProductPicture', {trigger: true});
      this.hide();
    },

    _fromUpload: function() {
      throw 'From Upload not yet implemented';
    }
  });

  module.exports = AddNewPicturePopupView;
});
