
define('template!fColdBrowserFormTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<form action="' +
((__t = ( url )) == null ? '' : __t) +
'" method="post" enctype="multipart/form-data">\n  <div class="col-xs-12 p-l-0 p-r-0">\n    <div class="form-group ticket-name-new">\n      <span class="p-0 col-xs-3">' +
((__t = (_.i18n('common.logo') )) == null ? '' : __t) +
'</span>\n      <div class="p-0 col-xs-9">\n        <input type="file" name="file" class="form-control">\n      </div>\n    </div>\n\n    <div class="form-group ticket-name-new">\n      <span class="p-0 col-xs-3">' +
((__t = (_.i18n('common.subject') )) == null ? '' : __t) +
'</span>\n      <div class="p-0 col-xs-9">\n        <input type="text" class="form-control" name="name">\n      </div>\n    </div>\n     <!-- Textarea -->\n    <div class="form-group row m-r-0 m-l-0">\n      <div class="textarea-container">\n        <span class="p-0 col-xs-3">' +
((__t = (_.i18n('common.message') )) == null ? '' : __t) +
'</span>\n        <div class="p-0 col-xs-9">\n          <textarea class="form-control textarea-input" rows="2" id="textArea" name="content"></textarea>\n        </div>\n      </div>\n    </div>\n    <!-- Urgency -->\n    <div class="form-group checkbox-group">\n      <div class="checkbox checkbox-primary">\n        <span class="p-0 col-xs-3">' +
((__t = ( _.i18n('vectReq.urgency') )) == null ? '' : __t) +
'</span>\n        <label>\n          <input type="checkbox" class="js-ticket-urgent" name="isUrgent">\n        </label>\n      </div>\n    </div>\n\n    <div class="col-xs-12 text-center">\n      <button type="submit" class="btn btn-primary send-to-d-b">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n    </div>\n  </div>\n</form>';

}
return __p
};});

