define('webshopCategoriesFinderView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'template!webshopCategoriesFinderView'
], function (
    App,
    Module,
    Backbone,
    Marionette,
    _,
    Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-finder-view'
  });
});

