define('headerNotificationsPopupListView',[
  'module',
  'underscore',
  'app',
  'settings',
  'backbone',
  'tableView',
  'tableDatetimeFieldView',
  'adminNotificationsCreatorView',
  'adminNotificationsModificationView',
  'adminNotificationsInfosView',
  'adminNotificationsToolsView',
  'entities/admin/notifications'
], function (
    Module,
    _,
    App,
    Settings,
    Backbone,
    TableView,
    DatetimeFieldView,
    CreatorView,
    ModificationView,
    InfosView,
    ToolsView
) {
  'use strict';

  Module.exports = TableView.extend({

    className: 'header-notifications-list-view table-view',
    pageSize: 10,
    header: false,

    setFields: function() {
      return [{
        name: 'Avatar',
        className: 'avatar',
        view: CreatorView
      }, {
        name: 'Infos',
        className: 'infos',
        view: InfosView
      }, {
        name: 'Modification',
        className: 'modification',
        view: ModificationView
      }, {
        name: 'Date',
        bind: 'creatDate',
        className: 'date',
        view: DatetimeFieldView
      }, {
        name: 'Buttons',
        className: 'buttons',
        view: ToolsView,
        onClose: _.bind(function () {
          this.options.popupView.close();
        }, this),
        onSeen: _.bind(function () {
          this.options.popupView.options.fetchCount();
        }, this)
      }];
    },

    fetch: function (first, pageSize) {
      var defer = $.Deferred();

      App.request('notifications:entities', first, pageSize).done(_.bind(function (notifications) {
        defer.resolve(notifications.models);
      }, this));

      return defer.promise();
    }
  });
});
