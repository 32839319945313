/**
 * Created by BFR on 25/06/2020.
 */
define('productImprintView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!productImprintTpl',
  'entities/product'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl
) {
  'use strict';

  var ProductImprintView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'col-xs-12',

    regions: {},

    ui: {
      lblPrice: '.lbl-price',
      txtPrice: '.txt-price',
      lblFactorizedPrice: '.lbl-factorized-price',
      txtFactorizedPrice: '.txt-factorized-price',
      selectPrintFactorCode: '.select-print-factor-code',
      customPrice: '.custom-price'
    },

    events: {
      'change @ui.selectPrintFactorCode': 'onPrintFactorCodeChange',
      'change .cbx-enable-pz': 'onCbxEnablePzChange',
      'click @ui.lblPrice': 'onLblPriceClick',
      'change @ui.txtPrice': 'onTxtPriceChange',
      'focusout @ui.txtPrice': 'onTxtPriceChange',
      'click @ui.lblFactorizedPrice': 'onLblFactorizedPriceClick',
      'change @ui.txtFactorizedPrice': 'onTxtFactorizedPriceChange',
      'focusout @ui.txtFactorizedPrice': 'onTxtFactorizedPriceChange',
      'click .btn-reset': 'onResetClick'
    },

    onResetClick: function (event) {
      var tin = this.$el.find(event.target);
      this.options.model.collection.printZonePrices.clear(tin.data('pz-id'));
      this.model.get('pz').printZone.factorCode = null;
      App.request('products:update-printzone', 'test', this.model.get('pz').printZone).then(_.bind(function () {
        this.render();
      }, this));
    },

    onLblPriceClick: function (event) {
      var index = this.$el.find(event.target).data('index');
      this.$el.find(this.ui.lblPrice[index]).hide();
      this.$el.find(this.ui.txtPrice[index]).show();
      this.$el.find(this.ui.txtPrice[index]).focus();
      this.$el.find(this.ui.txtPrice[index]).select();
    },

    onTextPriceKeyDown: function (e) {
      $(e.target.nextSibling).focus();
    },

    onTxtPriceChange: function (event) {
      var tin = this.$el.find(event.target),
        index = tin.data('index'),
        currTin, newFactor, i;
      this.$el.find(this.ui.lblPrice[index]).show();
      tin.hide();
      this.options.model.collection.printZonePrices.clear(tin.data('pz-id'));
      for (i = 0; i < this.ui.txtPrice.length; i++) {
        currTin = this.$el.find(this.ui.txtPrice[i]);
        var currFactorizedTin = this.$el.find(this.ui.txtFactorizedPrice[i]);
        if (this.customPrice) {
          if (i === index) {
            newFactor = 1;
          } else {
            newFactor = Number(currFactorizedTin.val()) / Number(currTin.val().replace(',', '.'));
          }
          if (currTin.data('quantity') === -1) {
            this.options.model.collection.printZonePrices.setCustomPzPrice
            (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')),
              Number($(this.ui.txtPrice[i]).val()), newFactor);
          } else {
            this.options.model.collection.printZonePrices.setCustomPzPrice
            (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')), 0, newFactor);
          }

        } else {
          newFactor = Number(currTin.val().replace(',', '.')) / Number(currTin.data('price'));
          this.options.model.collection.printZonePrices.setPriceFactorForQuantity(currTin.data('pz-id'),
            currTin.data('quantity'), newFactor);
        }
      }
      this.render();
    },

    onLblFactorizedPriceClick: function (event) {
      var index = this.$el.find(event.target).data('index');
      this.$el.find(this.ui.lblFactorizedPrice[index]).hide();
      this.$el.find(this.ui.txtFactorizedPrice[index]).show();
      this.$el.find(this.ui.txtFactorizedPrice[index]).focus();
      this.$el.find(this.ui.txtFactorizedPrice[index]).select();
    },

    onTextFactorizedPriceKeyDown: function (e) {
      $(e.target.nextSibling).focus();
    },

    onTxtFactorizedPriceChange: function (event) {
      var tin = this.$el.find(event.target),
        index = tin.data('index'),
        currTin, newFactor, i;
      this.$el.find(this.ui.lblFactorizedPrice[index]).show();
      tin.hide();
      this.options.model.collection.printZonePrices.clear(tin.data('pz-id'));
      for (i = 0; i < this.ui.txtFactorizedPrice.length; i++) {
        currTin = this.$el.find(this.ui.txtFactorizedPrice[i]);
        newFactor = Number(currTin.val().replace(',', '.')) / Number(currTin.data('price'));
        if (currTin.data('quantity') === -1) {
          this.options.model.collection.printZonePrices.setCustomPzPrice
          (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')),
            Number($(this.ui.txtPrice[i]).val()), newFactor);
        } else {
          this.options.model.collection.printZonePrices.setCustomPzPrice
          (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')), 0, newFactor);
        }
      }
      this.render();
    },

    onCbxEnablePzChange: function (event) {
      var cbx = $(event.target), currentPa;
      _.each(this.options.model.collection.productShare.get('printAreas').models, _.bind(function (pa) {
        if (this.options.model.get('pa').parentPrintArea.id === pa.get('parentPrintArea').get('id')) {
          currentPa = pa;
        }
      }, this));

      if (cbx.is(':checked')) {
        if (currentPa) {
          if (!currentPa.get('printArea')) {
            currentPa.set('printArea', {});
          }
          _.each(currentPa.get('printZones').models, _.bind(function (pz) {
            if (this.options.model.get('pz').parentPrintZone.id === pz.get('parentPrintZone').get('id')) {
              if (!pz.get('printZone')) {
                pz.set('printZone', {name: pz.get('parentPrintZone').get('name')});
              }
            }
          }, this));
        }
      } else {
        if (currentPa) {
          _.each(currentPa.get('printZones').models, _.bind(function (pz) {
            if (this.options.model.get('pz').parentPrintZone.id === pz.get('parentPrintZone').get('id')) {
              pz.set('printZone', null);
            }
          }, this));
          var deletePa = true;
          _.each(currentPa.get('printZones').models, _.bind(function (pz) {
            if (pz.get('printZone')) {
              deletePa = false;
            }
          }, this));
          if (deletePa) {
            currentPa.set('printArea', null);
          }
        }
      }
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.useFactorV2 = this.options.model.collection.useFactorV2;
      templateData.setupFactor = 1;
      var printCode;
      templateData.activated = false;
      templateData.currentPzPrice = [];
      templateData.factorEditable = true;
      if (templateData.pa.printArea) {
        templateData.pa2 = templateData.pa.printArea;
      } else {
        templateData.pa2 = templateData.pa.parentPrintArea;
      }
      if (templateData.pz.printZone) {
        templateData.pz2 = templateData.pz.printZone;
        templateData.activated = true;
        printCode = templateData.pz.printZone.factorCode;
      } else {
        templateData.pz2 = templateData.pz.parentPrintZone;
        templateData.factorEditable = false;
      }
      templateData.printFactors = this.options.model.collection.supplierPrintFactors.toJSON();
      var printFactor = {
        factor: 1.3,
        factorSetup: 1.3,
        factorHandling: 1.3
      };
      if (printCode) {
        _.each(templateData.printFactors, function (factor) {
          if (factor.printCode === printCode) {
            printFactor = factor;
          }
        });
      }
      templateData.customPrice = this.customPrice = this.options.customPrice;

      _.each(this.options.model.collection.price, _.bind(function (price) {
        if (price.impt.length === 0 || price.productUnitPrice === '0' || this.customPrice) {
          var that = this, setUpPrice;
          that.customPrice = templateData.customPrice = true;
          var pzId = this.options.model.get('pz').printZone ? this.options.model.get('pz').printZone.id :
            this.options.model.get('pz').parentPrintZone.id;
          if (pzId !== null) {
            if (that.options.model.collection.printZonePrices.get(pzId) &&
              that.options.model.collection.printZonePrices.get(pzId).length) {
              _.each(this.options.model.collection.printZonePrices.get(pzId), _.bind(function (impt) {
                var customPriceValue, currentPzId, customPrice, factor;
                if (pzId) {
                  currentPzId = that.options.model.get('pz').printZone.id;
                  customPrice = that.options.model.collection.printZonePrices.findCustomPriceByPzIdAndQuantity(
                    currentPzId, impt.quantity);
                  customPriceValue = customPrice.price;
                  factor = customPrice.factor ? customPrice.factor : 1;
                } else {
                  customPrice = that.options.model.collection.printZonePrices.findCustomPriceByPzIdAndQuantity(
                    null, impt.quantity);
                  customPriceValue = customPrice.price;
                  factor = customPrice.factor ? customPrice.factor : 1;
                }
                if (!setUpPrice) {
                  setUpPrice = (impt.quantity === -1) ? impt.price : 0;
                  templateData.setupFactor = customPrice.factor ? customPrice.factor : 1;
                }
                if (impt.quantity === Number(price.quantity)) {
                  templateData.currentPzPrice.push({
                    quantity: price.quantity,
                    secId: currentPzId,
                    price: (customPriceValue) ? customPriceValue : '0',
                    setUpPrice: setUpPrice,
                    factor: Number(factor).toFixed(2)
                  });
                }
              }), this);
            } else {
              templateData.currentPzPrice.push({
                quantity: price.quantity,
                secId: pzId,
                price: '0'
              });
            }
          }
        } else {
          _.each(price.impt, _.bind(function (impt) {
            if (impt.secId === templateData.pz2.id) {
              impt.imptSetupPrice = impt.imptSetupPrice ? Number(impt.imptSetupPrice).toFixed(2) : '0';
              impt.setupFactor =
                this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, -1) ||
                printFactor.factorSetup;
              impt.imptSetupPriceSale = (Number(impt.imptSetupPrice) * impt.setupFactor).toFixed(2);

              if (impt.imptHandlingUnitPrice) {
                impt.handlingFactor =
                  this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, -2) ||
                  printFactor.factorHandling;
                impt.imptHandlingPriceSale = (Number(impt.imptHandlingUnitPrice) * impt.handlingFactor).toFixed(2);
              } else {
                impt.imptHandlingPrice = '0';
                impt.imptHandlingPriceSale = '0';
              }

              impt.imptUnitUnitPriceFactor =
                this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, price.quantity) ||
                printFactor.factor;
              impt.imptUnitUnitPriceSale = (impt.imptUnitUnitPrice * impt.imptUnitUnitPriceFactor).toFixed(2);
              impt.quantity = price.quantity;
              templateData.currentPzPrice.push(impt);
            }
          }, this));
        }
      }, this));
      return templateData;
    },

    onShow: function () {
      if (this.customPrice) {
        this.ui.customPrice.addClass('prices-editable');
      }
    },

    onPrintFactorCodeChange: function () {
      this.model.get('pz').printZone.factorCode = this.ui.selectPrintFactorCode.val();
      App.request('products:update-printzone', 'test', this.model.get('pz').printZone).then(_.bind(function () {
        this.render();
      }, this));
    }
  });

  module.exports = ProductImprintView;
});
