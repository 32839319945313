/**
 * Created by GSP on 4/11/2015.
 */
define('fCattachmentView',['module',
        'marionette',
        'template!fCnewAttachmentTpl'],
  function(module,
           Marionette,
           newAttachmentTpl){

  'use strict';

  var AttachmentView = Marionette.ItemView.extend({
    tagName: 'li',
    template: newAttachmentTpl
  });

  module.exports = AttachmentView;
});
