define('adminNotificationsToolsView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'entities/orders',
  'template!adminNotificationsToolsView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Orders,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-notifications-tools-view table-field-view',

    modelEvents: {
      'change:read': 'renderSeen'
    },

    ui: {
      seen: '.seen-button',
      target: '.target-button'
    },

    events: {
      'click @ui.target': 'onOpenTarget',
      'click @ui.seen': 'onSeen'
    },

    onRender: function () {
      this.renderSeen();
    },

    renderSeen: function () {
      if(this.model.get('read') === true)
        this.ui.seen.addClass('activated');
      else
        this.ui.seen.removeClass('activated');
    },

    onSeen: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.model.read().done(_.bind(function () {
        if(this.field.onSeen)
          this.field.onSeen();
      }, this));
    },

    onOpenTarget: function (e) {
      e.stopPropagation();
      switch(this.model.get('content').type) {
        case 'ORDER_STATUS_CHANGED':
        case 'ORDER_NEW_COMMENT':
        case 'ORDER_NEW_PAYMENT':
        case 'ORDER_COMMENT_TAGGED':
        case 'ORDER_VALIDATED':
        case 'ORDER_INVALIDATED':
        case 'ORDER_ASSIGNED':
        case 'ORDER_COMMENT_MODIFIED_TAGGED':
          Orders.showOrder(this.model.get('content').data.poi, '');
          break;
        case 'TASK_NEW':
        case 'TASK_NEW_COMMENT':
        case 'TASK_COMMENT_DELETED':
        case 'TASK_DONE':
          App.navigate('admin/tasks/' + this.model.get('content').entitySecId, {trigger: true});
          break;
        case 'CUSTOMER_COMMENT_TAGGED':
          App.navigate('customers/' + this.model.get('content').entitySecId, {trigger: true});
          break;
        default:
          if (this.model.get('content').data && this.model.get('content').data.from) {
            App.navigate('customers/code/' + this.model.get('content').data.from, {trigger: true});
          }
      }

      if(this.field.onClose)
        this.field.onClose();
    }
  });
});
