/**
 * Created by RKL on 26/08/2015.
 */
define('editImagePluginController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'editImagePluginView',
  'entities/customers/customers',
  'entities/session'
], function(
  module,
  Backbone,
  _,
  App,
  Settings,
  bootbox,
  View
) {
  'use strict';

  var EditImagePluginController = {
    showPlugin: function(routeArgs) {
      var location = window.location,
        userId, userCode, path,
        returnUrl, session;

      if (routeArgs.length >= 4) {
        this.aProfileId = routeArgs[1];
        userId = routeArgs[0];
        userCode = routeArgs[2];
        path = '#' + routeArgs[3];

        if(routeArgs.length >= 5){
          this.dsv = routeArgs[4];
        }
        if(routeArgs.length >= 6){
          this.groupId = routeArgs[5];
        }
        
        returnUrl = location.origin + location.pathname + path + '/' + userId;
        if(routeArgs.length >= 7 && routeArgs[6] === 'logosimages'){
          returnUrl += '/' + routeArgs[6] +  '/' + this.groupId;
        }
        returnUrl += '/reload';

        session = {
          clientCode: userCode,
          userCode: userCode,
          ttc: false,
          languageCode: 'en',
          webServiceUrl: 'random',
          locale: 'en_US',
          returnUrl: returnUrl
        };

        this.createSession(session);
      }
    },

    createSession: function(session) {
      var that = this,
        sessionRequest;

      session.webServiceUrl = 'random';
      sessionRequest = App.request('session:create-session', session);
      $.when(sessionRequest).done(function (result) {
        that.sessionId = result.sessionId;
        that.onSessionCreated();
      });
    },

    onSessionCreated: function () {
      var dsv = this.dsv ? this.dsv : 'new',
        groupId = this.groupId ? this.groupId : '',
        params = {
          oloSessionId: this.sessionId,
          isAdmin: true,
          aProfileSecId: this.profileId,
          dsv: dsv,
          selectedDataset: groupId,
          lang: Settings.get('lang')
        },
        pluginUrl = Settings.url('compuzzPlugin', '', params),
        view = new View({url: pluginUrl});
      
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    }
  };

  module.exports = EditImagePluginController;
});
