
define('template!customersDetailBodyProfileView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="profile-content">\n  <div class="infos-region"></div>\n  <div class="addresses-region"></div>\n  <div class="siren-region"></div>\n</div>\n<div class="buttons">\n  <div class="edit-button-region"></div>\n</div>';

}
return __p
};});

