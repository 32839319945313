
define('template!productSimilarTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-3">\n    ' +
((__t = ( name )) == null ? '' : __t) +
'\n</div>\n<div class="col-xs-1 h-16" title="' +
((__t = ( comment )) == null ? '' : __t) +
'">\n    <i class="mdi-communication-comment" ></i>\n</div>\n<div class="col-xs-3">\n    ' +
((__t = ( itemNumber )) == null ? '' : __t) +
'\n</div>\n<div class="col-xs-2 h-16">\n    <i class="btn-edit mdi-image-edit clickable"></i>\n</div>\n<div class="col-xs-1">\n    €' +
((__t = ( price )) == null ? '' : __t) +
'\n</div>\n<div class="btn btn-remove-similar p-l-0 p-r-0 p-t-0 p-b-0 m-b-0 m-t-0 col-xs-1" style="height: 20px;">\n    <i class="mdi-content-remove"></i>\n</div>';

}
return __p
};});

