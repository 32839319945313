
define('template!caccountTransportView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<form>\n  <div class="row">\n    <label class="col-xs-8">Delivery days</label>\n    <input class="col-xs-4 txt-input txt-delivery-days" data-property="deliveryDays" value="' +
((__t = ( deliveryDays )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Small Order Amount</label>\n    <input class="col-xs-4 txt-input txt-small-order-amount" data-property="smallOrderAmount"\n           value="' +
((__t = ( smallOrderAmount )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Small Order Fee</label>\n    <input class="col-xs-4 txt-input txt-small-order-fee" data-property="smallOrderFee" value="' +
((__t = ( smallOrderFee )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Delivery Threshold</label>\n    <input class="col-xs-4 txt-input txt-delivery-thresholds" data-property="deliveryThreshold" value="' +
((__t = ( deliveryThreshold )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Type</label>\n    <select class="txt-input" data-property="transport" data-subproperty="mode">\n      <option value="B" ' +
((__t = ( transport.mode === 'B' ? 'selected' : '')) == null ? '' : __t) +
'>Number of boxes</option>\n      <option value="Q" ' +
((__t = ( transport.mode === 'Q' ? 'selected' : '')) == null ? '' : __t) +
'>Quantity of pieces</option>\n      <option value="T" ' +
((__t = ( transport.mode === 'T' ? 'selected' : '')) == null ? '' : __t) +
'>Total price of order</option>\n      <option value="W" ' +
((__t = ( transport.mode === 'W' ? 'selected' : '')) == null ? '' : __t) +
'>Weight of order</option>\n    </select>\n  </div>\n  <div class="transport-prices-region sa-list"></div>\n\n  <br>\n  <hr>\n  <div style="text-decoration: underline; font-size: 16px; font-weight: 600">Express delivery</div>\n  <br>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Express Available</label>\n    <input type="checkbox" style="width: 18px; height: 18px;" id="expressAvailable" class="express-available" ' +
((__t = ( expressAvailable?'checked':'')) == null ? '' : __t) +
'>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Express Delivery Threshold</label>\n    <input class="col-xs-4 txt-input txt-express-delivery-threshold" data-property="expressDeliveryThreshold" value="' +
((__t = ( expressDeliveryThreshold )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Type</label>\n    <select class="txt-input" data-property="expressTransport" data-subproperty="mode">\n      <option value="B" ' +
((__t = ( expressTransport.mode === 'B' ? 'selected' : '')) == null ? '' : __t) +
'>Number of boxes</option>\n      <option value="Q" ' +
((__t = ( expressTransport.mode === 'Q' ? 'selected' : '')) == null ? '' : __t) +
'>Quantity of pieces</option>\n      <option value="T" ' +
((__t = ( expressTransport.mode === 'T' ? 'selected' : '')) == null ? '' : __t) +
'>Total price of order</option>\n      <option value="W" ' +
((__t = ( expressTransport.mode === 'W' ? 'selected' : '')) == null ? '' : __t) +
'>Weight of order</option>\n    </select>\n  </div>\n  <div class="transport-express-prices-region sa-list"></div>\n</form>';

}
return __p
};});

