
define('template!caccountTransportItemPriceTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<form class="sa-item col-xs-12 p-b-5">\n  <div class="sa">\n    <div class="sa-code" style="margin-left: -15px;">' +
((__t = ( currentIndex )) == null ? '' : __t) +
'</div>\n  </div>\n  </div>\n  <div class="row m-r-0">\n    <label class="col-xs-8">Start index</label>\n    <input class="col-xs-4 txt-price-input txt-from-data p-l-0 p-r-0" data-property="index" value="' +
((__t = ( index )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5 m-r-0">\n    <label class="col-xs-8">Price</label>\n    <input class="col-xs-4 txt-price-input txt-price p-l-0 p-r-0" data-property="price" value="' +
((__t = ( price )) == null ? '' : __t) +
'"/>\n  </div>\n</form>';

}
return __p
};});

