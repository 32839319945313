/**
 * Created by RKL on 21/08/2015.
 */
define('productsListController',[
  'module',
  'logger',
  'underscore',
  'app',
  'productsListView',
  'bootbox',
  'settings',
  'entities/product'
], function(
  module,
  Logger,
  _,
  App,
  View,
  bootbox
) {
  'use strict';

  var ProductsController = {
    showProducts: function() {
      var view = new View();
      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onDelete', _.bind(this.onDeleteProduct, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    onGridLoaded: function() {
      var data = {
        url: App.request('products:get-collection-url'),
        rowCountUrl: App.request('products:row-count-url')
      };

      this.view.displayGrid(data, true);
    },

    onDeleteProduct: function(productSecId) {
      var that = this,
        deleteRequest;

      bootbox.confirm(_.i18n('warning.deleteProduct'), function (result) {
        if (result) {
          deleteRequest = App.request('products:delete', productSecId);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination('reloadGrid');
          });
        }
      });
    }
  };

  module.exports = ProductsController;
});
