define('ordersBoardView',[
  'app',
  'module',
  'jquery',
  'backbone',
  'marionette',
  'underscore',
  'ordersBoardHeaderView',
  'ordersBoardFiltersView',
  'ordersBoardKanbanView',
  'template!ordersBoardView'
], function (
  App,
  Module,
  $,
  Backbone,
  Marionette,
  _,
  HeaderView,
  FiltersView,
  KanbanView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-board-view',

    regions: {
      filtersRegion: '.filters-region',
      headerRegion: '.header-region',
      kanbanRegion: '.kanban-region'
    },

    onShow: function () {
      this.headerView = new HeaderView({
        boardView: this,
        type: this.options.type
      });
      this.getRegion('headerRegion').show(this.headerView);

      //-------------------
      this.filtersView = new FiltersView({
        boardView: this,
        type: this.options.type,
        endUserId: this.options.endUserId
      });
      this.getRegion('filtersRegion').show(this.filtersView);

      //-------------------
      this.kanbanView = new KanbanView({
        boardView: this,
        parentStatus: this.options.parentStatus,
        type: this.options.type,
        filtersView: this.filtersView
      });
      this.getRegion('kanbanRegion').show(this.kanbanView);
    }
  });
});
  
