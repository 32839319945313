define('webshopProductsFinderListToolsView',[
  'module',
  'tableFieldView',
  'template!webshopProductsFinderListToolsView'
], function (
  Module,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-finder-list-tools-view ' + FieldView.prototype.className
  });
});
