
define('template!customersPopupSelectResultView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="company-name"></div>\n<div class="contact">\n  <div class="name"></div>\n  <div class="email"></div>\n</div>';

}
return __p
};});

