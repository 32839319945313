
define('template!graphicalRequestKanbanTimePopupViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row kanban-board-time-popup p-15">\n  <p class="col-xs-12">\n    <a href="#timetracker/vectorization/' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" class="open-timetracker-page">\n      <i class="fa fa-calendar fa-lg icon-sub"></i>\n    </a>\n  </p>\n  <p class="col-xs-2">Estimated:</p>\n  <div class="col-xs-8">\n    <div class="progress">\n      <div class="progress-bar progress-bar-info" style="width: ' +
((__t = ( persantage.estPer )) == null ? '' : __t) +
'%"></div>\n    </div>\n  </div>\n  <p class="col-xs-2 text-right">\n    ';
 if (timing.estimated < 0) { ;
__p += '\n    <span>-</span>\n    ';
 } ;
__p += '\n    ' +
((__t = ( estimated )) == null ? '' : __t) +
'\n  </p>\n\n  <p class="col-xs-2">Remaining:</p>\n  <div class="col-xs-8">\n    <div class="progress">\n      <div class="progress-bar progress-bar" style="width: ' +
((__t = ( persantage.remPer )) == null ? '' : __t) +
'%"></div>\n    </div>\n  </div>\n  <p class="col-xs-2 text-right">\n    ';
 if (timing.remaining < 0) { ;
__p += '\n    <span class="minus-sign">-</span>\n    ';
 } ;
__p += '\n    ' +
((__t = ( remaining )) == null ? '' : __t) +
'\n  </p>\n\n  <p class="col-xs-2">Logged:</p>\n  <div class="col-xs-8">\n    <div class="progress">\n      <div class="progress-bar progress-bar-success" style="width: ' +
((__t = ( persantage.logPer )) == null ? '' : __t) +
'%"></div>\n    </div>\n  </div>\n  <p class="col-xs-2 text-right">\n    ';
 if (timing.logged < 0) { ;
__p += '\n    <span>-</span>\n    ';
 } ;
__p += '\n    ' +
((__t = ( logged )) == null ? '' : __t) +
'\n  </p>\n</div>';

}
return __p
};});

