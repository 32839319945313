/**
 * Created by BFR on 25/06/2020.
 */
define('productImprintsView',['module',
        'marionette',
        'productImprintView'],
  function(module,
           Marionette,
           ProductImprintView){
  'use strict';

  var ProductImprintsView = Marionette.CollectionView.extend({
    childView: ProductImprintView,
    className: 'col-xs-12',
    childViewOptions: function() {
      return {customPrice: this.options.customPrice};
    },
    childEvents: {
    }
  });

  module.exports = ProductImprintsView;
});
