define('customersDetailBodyOffersView',[
  'app',
  'module',
  'underscore',
  'ordersListView'
], function (
  App,
  Module,
  _,
  ListView
) {
  'use strict';

  Module.exports = ListView.extend({
    className: 'customer-detail-offers-view ' + ListView.prototype.className,

    getFiltersParams: function(filters) {
      var params = ListView.prototype.getFiltersParams.apply(this, [filters]);
      params.endUserId = this.options.endUserId;
      params.endUserIdFullMatch = 'true';
      return params;
    }
  });
});
