/**
 * Created by OLD on 28/09/2015.
 */
define('timetrackerView',[
  'module',
  'marionette',
  'app',
  'template!timetrackerTpl'
], function(
  module,
  Marionette,
  App,
  viewTpl  
) {
  'use strict';

  var Timetracker =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      filtersTT: '.filters-tt',
      tableTT: '.calendar-tt'
    }
  });

  module.exports = Timetracker;
});
