define('headerTasksView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'headerTasksListView',
  'template!headerTasksView',
  'entities/admin/tasks'
], function(
  App,
  Module,
  Marionette,
  _,
  TasksListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-tasks-view', 
    
    ui: {
      content: '> .content',
      count: '.count',
      list: '.list',
      icon: '.icon',
      loader: '.loader-2'
    },

    events: {
      'click @ui.content': 'onOpen'
    },

    regions: {
      listRegion: '.list-region'
    },

    initialize: function () {
      this.count = 0;
      this.countNotDone = 0;
    },

    onShow: function () {
      this.listView = new TasksListView({
        doneChange: _.bind(function() {
          this.fetchCount();
        }, this)
      });
      this.getRegion('listRegion').show(this.listView);

      this.fetchCount();
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      if (this.count > 0)
        this.ui.content.addClass('activated');
      else
        this.ui.content.removeClass('activated');

      //---------------

      if (this.countNotDone > 0)
        this.ui.count.css('display', 'flex');
      else
        this.ui.count.hide();  
      
      this.ui.count.html(this.countNotDone);
    },   

    fetchCount: function () {
      var tasksCount = App.request('tasks:open:count');
      var tasksNotDoneCount = App.request('tasks:open:count:notdone');

      $.when(tasksCount, tasksNotDoneCount).done(_.bind(function (count, countNotDone) {    
        this.count = count;
        this.countNotDone = countNotDone;
        this.renderContent();
       
      }, this));

      setTimeout(_.bind(this.fetchCount, this), 15000);
    },

    onOpen: function () {
      this.fetchCount();

      this.ui.icon.hide();
      this.ui.loader.show();

      this.listView.open().done(_.bind(function () {
        this.ui.icon.show();
        this.ui.loader.hide();
      }, this));
    }
  });
});
