
define('template!ordersDetailPriceV2View', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="print-price-region"></div>\n<div class="price-region"></div>\n<div class="loader loading">Loading...</div>';

}
return __p
};});

