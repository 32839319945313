
define('template!popupCreateOptionCategoryTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'>\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') + '/' + _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code"\n                 placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-7 name-region p-t-5"></div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.displayType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="displayType" value="' +
((__t = ( displayType )) == null ? '' : __t) +
'">\n            <option ' +
((__t = ( displayType === 'color' ? 'selected': '' )) == null ? '' : __t) +
'>color</option>\n            <option ' +
((__t = ( displayType === 'text' ? 'selected': '' )) == null ? '' : __t) +
'>text</option>\n            <option ' +
((__t = ( displayType === 'image' ? 'selected': '' )) == null ? '' : __t) +
'>image</option>\n            <option ' +
((__t = ( displayType === 'counter' ? 'selected': '' )) == null ? '' : __t) +
'>counter</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="productCodeVariable">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("optionCategory.productCodeVariable") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" id="productCodeVariable" class="productCodeVariable" ' +
((__t = ( productCodeVariable?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="mixable">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("optionCategory.mixable") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" id="mixable" class="mixable" ' +
((__t = ( mixable?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

