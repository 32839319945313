define('duplicatePopupBehavior',[
  'module',
  'marionette',
  'underscore'
], function(
  module,
  Marionette,
  _
) {
  'use strict';


  /**
   * Behavior needs an input element with class js-unique or class could be specified through an option to behavior.
   * When view model triggers error event and response code is 409 (Duplicate) the popover is shown field
   * receives invalid class and closest div with attr data-field-name receives has-error class.
   * On focus everything is cleared.
   * */
  var DuplicatePopupBehavior = Marionette.Behavior.extend({
    defaults: {
      inputSelector: '.js-unique'
    },

    modelEvents: {
      error: '_onError'
    },

    onShow: function() {
      var popoverOptions = {
        content: _.i18n('common.code.duplicate'),
        placement: 'top',
        trigger: 'manual'
      };
      this.ui.input = this.view.$(this.options.inputSelector);
      this.ui.input.popover(popoverOptions);
      this.ui.input.focus(_.bind(this._onFocus, this));
    },

    onDestroy: function() {
      this.ui.input.off('focus');
    },

    _onError: function(model, resp) {
      if(resp.status === 409) {
        this._showDuplicatePopup();
      }
    },

    _showDuplicatePopup: function() {
      this.ui.input.popover('show');
      this.ui.input.addClass('invalid');
      this.ui.input.closest('div[data-field-name').addClass('has-error');
    },

    _onFocus: function() {
      this.ui.input.removeClass('invalid');
      this.ui.input.closest('div[data-field-name').removeClass('has-error');
      this.ui.input.popover('hide');
    }
  });

  module.exports = DuplicatePopupBehavior;
});
