define('ordersDetailOrderClientCommentsView',[
  'module',
  'marionette',
  'backbone',
  'underscore',
  'entities/orders',
  'ordersDetailOrderClientCommentView',
  'template!ordersDetailOrderClientCommentsView'
], function (
  Module,
  Marionette,
  Backbone,
  _,    
  Orders,
  CommentView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'orders-detail-order-client-comments-view item',   
    childView: CommentView,
    childViewContainer: '.comments-ctn',

    ui: {
      noComments: '.no-comments'
    },

    modelEvents: {      
      'change:comments': 'updateCollection'
    },

    initialize: function () {
      this.collection = new Backbone.Collection(this.model.get('comments'));
    },

    filter: function (model) {
      if(model.get('type') && model.get('type') === 'customer')
        return true;
      else
        return false;
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      var models = _.filter(this.collection.models, function(model) {
        return (model.get('type') && model.get('type') === 'customer');
      });

      if(models.length === 0)
        this.ui.noComments.show();
      else
        this.ui.noComments.hide();
    },

    updateCollection: function () { 
      this.collection = this.model.get('comments');
    }
  });
});
