/**
 * Created by BFR on 06/09/2022.
 */
define('ordersDetailPriceV2PrintPriceView',[
  'app',
  'module',
  'marionette',
  'jquery',
  'underscore',
  'template!ordersDetailPriceV2PrintPriceView',
  'entities/orders'
], function (
  App,
  module,
  Marionette,
  $,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,
    tagName: 'tr',

    ui: {
      cbxEnableDisablePz: '.checkbox-enable-disable-pz',
      selectNbrColors: '.select-nbr-colors',
      btnSaveChangeEnablePz: '.btn-save-change-enable-pz',
      btnSaveChangeNbrColors: '.btn-save-change-nbr-colors'
    },

    events: {
      'change @ui.cbxEnableDisablePz': 'onCbxEnableDisablePzChange',
      'change @ui.selectNbrColors': 'onSelectNbrColorsChange',
      'click @ui.btnSaveChangeEnablePz': 'onBtnSaveChangeEnablePz',
      'click @ui.btnSaveChangeNbrColors': 'onBtnSaveChangeNbrColors'
    },

    onSelectNbrColorsChange: function () {
      this.ui.btnSaveChangeNbrColors.show();
    },

    onCbxEnableDisablePzChange: function () {
      this.ui.btnSaveChangeEnablePz.show();
    },

    onBtnSaveChangeNbrColors: function () {
      App.request('order:change-composition-nbr-colors',
        this.model.get('composition').secId, this.ui.selectNbrColors.val()).done(_.bind(function () {

        this.ui.btnSaveChangeNbrColors.hide();
      }, this));
    },

    onBtnSaveChangeEnablePz: function () {
      if (this.model.get('checked') !== this.ui.cbxEnableDisablePz.is(':checked')) {
        if (this.ui.cbxEnableDisablePz.is(':checked')) {
          App.request('order:add-printzone', this.model.get('order').get('purchaseOrderId'),
            this.model.get('pz').id,
              (this.model.get('pz').colorModeType==='spot'?1:undefined)).done(_.bind(function () {
            this.ui.btnSaveChangeEnablePz.hide();
          }, this));
        } else {
          App.request('order:remove-print-zone', this.model.get('order').get('purchaseOrderId'),
            this.model.get('pz').id).done(_.bind(function () {
            this.ui.btnSaveChangeEnablePz.hide();
          }, this));
        }
      } else {
        this.ui.btnSaveChangeEnablePz.hide();
      }
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.index = this._index + 1;
      return templateData;
    }

  });
});
