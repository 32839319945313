
define('template!customersDetailBodyProfileAddressView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\n    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n</div>\n<div class="body">\n    <div class="company-name item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="first-name item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( firstName )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="last-name item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( lastName )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="address-l1 item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( addressL1 )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="address-l2 item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( addressL2 )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="house-number item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( houseNumber )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="zip item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
':</div>\n        </div>\n        <div class="value">' +
((__t = ( zip )) == null ? '' : __t) +
' / ' +
((__t = ( city )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="country-code item">\n        <div class="libelle">\n            <div class="text">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="value">' +
((__t = ( countryCode )) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
};});

