define( 'entities/caccounts/invoices',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Invoice = Backbone.Model.extend({

  });
  
  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function() {
        return 'v2/invoices/rowCount';
    },

    getInvoicesUrl: function() {
      return Settings.url('compuzz2', 'v2/invoices');
    },

    getModel: function() {
        return new Invoice();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccount-invoices:row-count-url', function() {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('caccount-invoices:get-url', function() {
    return API.getInvoicesUrl();
  });

  App.reqres.setHandler('caccount-invoices:get-model', function() {
        return API.getModel();
  });

  return;
});
