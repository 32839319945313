
define('template!rBticketsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="with-header">\n  <div class=" tickets-content col-xs-12">\n    <div class="messages-container">\n    </div>\n  </div>\n  <div class="js-tickets-header tickets-header p-t-5">\n      ';
 if(vectorizator) { ;
__p += '\n    <div class="col-left">\n      <div class="logo-holder" style="background-image: url(' +
((__t = ( logoUrl )) == null ? '' : __t) +
');"></div>\n    </div>\n      <span class="caccount-more-info">\n        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>\n        <a id="caccount-more-info">' +
((__t = ( _.i18n('common.more.info'))) == null ? '' : __t) +
'</a>\n      </span>\n      ';
 } else { ;
__p += '\n    <label class="m-label p-15 hidden-xs">' +
((__t = ( _.i18n("common.messages") )) == null ? '' : __t) +
'</label>\n      ';
 } ;
__p += '\n    <span class="badge primary-bg counter-m" data-count="' +
((__t = ( length )) == null ? '' : __t) +
'">' +
((__t = ( length )) == null ? '' : __t) +
'</span>\n      ';
 if (vectorizator) { ;
__p += '\n    <span class="vertical-divider"></span>\n    <a href="#timetracker/remove_background/' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" class="open-timetracker-page">\n      <i class="fa fa-calendar fa-lg icon-sub"></i>\n    </a>\n      ';
 } ;
__p += '\n    <span class="vertical-divider"></span>\n    <span id="ticket-state">' +
((__t = ( ticketStates[ticketState].label )) == null ? '' : __t) +
'</span>\n    <span class="vertical-divider"></span>\n    <span class="subject-area">' +
((__t = ( _.i18n("common.subject") )) == null ? '' : __t) +
': <input class="form-control subject-input" value="' +
((__t = ( subject )) == null ? '' : __t) +
'"/></span>\n    <div class="loading-block js-loading-block">\n      <span class="loading-message js-loading-message label"></span>\n    </div>\n    <span class="back-to-list f-r m-t-0 display-inline-block">\n      <i class="mdi-navigation-arrow-back"></i>\n      <span class="hidden-xs">' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n    </span> \n    <div id="change-status-region" class="p-r-10 f-r"></div>\n    <div id="change-type-dropdown-region" class="p-r-10 f-r"></div>\n  </div>\n  <div class="footer-vbox js-footer">\n      ';
 if (ticketState === 'CLOSE') { ;
__p += '\n    <div class="footer-blinder"></div>\n      ';
 } ;
__p += '\n    <!-- Footer content -->\n    <div class="message-form">\n      <div class="row">\n        <div class="attachments-addon hidden">\n          <ul class="attachments-addon-list list-inline"></ul>\n        </div>\n      </div>\n      <div class="clearfix">\n          ';
 if (vectorizator) { ;
__p += '\n        <div class="hidden-xs hidden-md col-xs-2">\n          <div class="dropup dropdown ">\n            <button class="form-control dropdown-toggle text-support-chooser" type="button" id="text-support-dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              <span class="pull-left">' +
((__t = ( _.i18n("common.text-support") )) == null ? '' : __t) +
'</span>\n              <span class="caret pull-right"></span>\n            </button>\n            <ul class="dropdown-menu col-xs-12 text-support-dropdownMenu-data" aria-labelledby="text-support-dropdownMenu">\n                ';
 _.each(textsSupport, function(text) { ;
__p += '\n            <li class="text-support-filter-item">\n                <a data-key="' +
((__t = ( text.code )) == null ? '' : __t) +
'" class="ellipsis" title="' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'" href="#">' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'</a>\n            </li>\n            ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n          ';
 } ;
__p += '\n          ';
 if (vectorizator) { ;
__p += '\n        <div class="visible-xs visible-md col-xs-1">\n          <div class="dropup dropdown ">\n            <a class="btn btn-link  withripple small-icon-hd dropdown-toggle text-support-chooser" id="text-support-dropdownMenu-xs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              <span ><i class=" vert-align-middle mdi-action-list"></i><span class=" vert-align-middle caret"></span></span>\n            </a>\n            <ul class="dropdown-menu col-xs-12 text-support-dropdownMenu-data" aria-labelledby="text-support-dropdownMenu-xs">\n                ';
 _.each(textsSupport, function(text) { ;
__p += '\n            <li class=" withripple text-support-filter-item">\n                <a data-key="' +
((__t = ( text.code )) == null ? '' : __t) +
'" class="ellipsis" title="' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'" href="#">' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'</a>\n            </li>\n            ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n          ';
 } ;
__p += '\n        <div class="col-xs-11 col-lg-7">\n          <div class=" row">\n            <div class="col-xs-2 col-lg-1">\n              <label for="browseAttachFile" class="control-label attach-btn">\n                <a class="col-xs-1 btn btn-fab btn-fab-mini btn-raised btn-sm btn-primary send-new-m-b"><i class="mdi-editor-attach-file"></i></a>\n              </label>\n              <input id="browseAttachFile" multiple type="file" name="filename" accept="*" class="hidden attach-input"/>\n            </div>\n            <div class="col-xs-8 col-lg-10">\n              <textarea class="create-new-message" rows="2" id="textArea" placeholder="' +
((__t = ( _.i18n("common.type.message") )) == null ? '' : __t) +
'"></textarea>\n            </div>\n            <div class="col-xs-2 col-lg-1">\n              <a class="btn btn-fab btn-fab-mini btn-raised btn-sm action-btn send-new-m-b js-send-new-m-b">\n                <i class="mdi-content-send"></i>\n              </a>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

