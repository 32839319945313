define('pimTabsLayoutView',[
  'module',
  'app',
  'marionette',
  'settings',
  'template!pimTabsLayoutTpl',
  'backToListBehavior',
  'imageUtils',
  'jquery',
  'underscore',
  'pimTabsData',
  'rolesMixin',
  'tasksCreatorBehavior',
  'pimResellerProductCodeCollectionView',
  'pimUtil',
  'entities/product'
], function (
  module,
  App,
  Marionette,
  Settings,
  pimTabsLayoutTpl,
  BackToListBehavior,
  imageUtils,
  $,
  _,
  PimTabsData,
  RolesMixin,
  TasksCreatorBehavior,
  PimResellerProductCodeCollectionView,
  PimUtil
) {
  'use strict';

  var PimTabsLayoutView = Marionette.LayoutView.extend({
    template: pimTabsLayoutTpl,
    tagName: 'div',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.js-tab-layout-tab',
      tabsRegion: '.js-tabs-region',
      header: '.js-tab-layout-header',
      warningSkipCompuzzData: '.warning-skip-compuzz-data',
      btnUseCompuzzData: '.btn-use-compuzz-data',
      btnSkipCompuzzData: '.btn-skip-compuzz-data'
    },

    regions: {
      tabs: '.js-tabs-region',
      resellerProductCodes: '.reseller-product-codes'
    },

    events: {
      'click .previous-product': 'onPreviousClick',
      'click .next-product': 'onNextClick',
      'click .btn-replace-product': 'onReplaceProductClick',
      'click @ui.btnUseCompuzzData': 'onClickUseCompuzzData',
      'click @ui.btnSkipCompuzzData': 'onClickSkipCompuzzData',
      'click @ui.tabs': 'onClickTab'
    },

    /*modelEvents: {
      'change' : 'updateImage'
    }, */

    initialize: function () {
      this.fd = {};

      var resProduct = this.model.getCurrentResProduct();
      if (resProduct)
        resProduct.on('change', this.updateImage, this);
    },

    behaviors: function () {
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: 'pim/products'
        }
      };
    },

    onClickTab: function (e) {
      var tab = this.$el.find(e.target);
      if (tab) {
        var name = tab.closest('.js-tab-layout-tab').attr('data-name');

        if (name === 'prices' && this.model.get('resellerData').length === 0) {
          name = 'overview';
        }

        var id = this.model.get('id');

        if (this.options.pimResProduct) {
          var resId = this.options.pimResProduct.get('id');
          App.navigate('/pim/products/' + id + '/resid/' + resId + '/' + name, {trigger: true});
        } else {
          App.navigate('/pim/products/' + id + '/' + name, {trigger: true});
        }

        App.execute('pim:select-product-tab', name);
      }
    },

    onKeyPress: function (event) {
      if (event.ctrlKey) {
        switch (event.key) {
          case 'n':
            event.data.onNextClick();
            break;
          case 'p':
            event.data.onPreviousClick();
            break;
        }
      }
    },

    onPreviousClick: function () {
      var index = Settings.get('pimListData').currentIndex;
      if (index > 0) {
        index -= 1;
      } else {
        index = Settings.get('pimListData').rowCount - 1;
      }
      var requestRs = App.request('pim:products:get-product', index,
        Settings.get('pimListData').filters);
      requestRs.done(_.bind(function (p) {
        Settings.get('pimListData').currentIndex = index;
        this.destroy();
        App.navigate('pim/products/' + p.id + '/' + this.tab, {trigger: true});
      }, this));
    },

    onNextClick: function () {
      var index = Settings.get('pimListData').currentIndex;
      if (index === (Settings.get('pimListData').rowCount - 1)) {
        index = 0;
      } else {
        index += 1;
      }
      var requestRs = App.request('pim:products:get-product', index,
        Settings.get('pimListData').filters);
      requestRs.done(_.bind(function (p) {
        Settings.get('pimListData').currentIndex = index;
        this.destroy();
        App.navigate('pim/products/' + p.id + '/' + this.tab, {trigger: true});
      }, this));
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.tabs = [];

      if (!templateData.supplier) {
        templateData.supplier = '';
      }

      if (Settings.get('pimListData')) {
        templateData.displayNextPrevious = true;
      } else {
        templateData.displayNextPrevious = false;
      }

      _.each(PimTabsData, function (tab) {
        if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
          templateData.tabs.push(tab);
        }
      });

      //------------- 

      templateData.resProduct = this.model.getCurrentResProduct();
      if (templateData.resProduct) {
        templateData.status = templateData.resProduct.get('status');
      } else {
        templateData.status = null;
      }

      //-------------
      templateData.name = PimUtil.getTranslation(templateData.data.name.values);

      return templateData;
    },

    onRender: function () {
    },

    updateImage: function () {
      var logo = this.model.getImage();
      if (logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }

      var content = '<div class="img-responsive2"><img class="img-popover" src="' + logo + '"/></div>';

      var target = this.ui.logo;

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'right',
        html: 'true',
        container: $('#content')
      }).data('bs.popover').tip().addClass('jqgrid-popover');
    },

    onShow: function () {
      $(document).bind('keydown', this, this.onKeyPress);

      this._setHeight();

      var _this = this;

      this.updateImage();

      this.resellerProductCodesView = new PimResellerProductCodeCollectionView({
        collection: this.model.get('resellerData'),
        childViewOptions: function () {
          return {
            supId: _this.model.get('id'),
            pimResProduct: _this.options.pimResProduct
          };
        }
      });

      this.getRegion('resellerProductCodes').show(this.resellerProductCodesView);

      var that = this;
      $(window).on('resize._setHeight', _.bind(this._setHeight, this));
      App.off('view:destroy');
      App.on('view:destroy', function (newRoute) {
        that.destroy();
        App.navigate(newRoute, {trigger: true});
      });
    },

    onShowActive: function (tabName) {
      this.tab = tabName;
      this.ui.tabs.each(_.bind(function (index, tab) {
        var $tab = $(tab);

        if ($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      }, this));
    },

    onDestroy: function () {
      $(document).unbind('keydown', this.onKeyPress);
      $(window).off('resize._setHeight');

      var resProduct = this.model.getCurrentResProduct();
      if (resProduct) {
        resProduct.off('change');
      }
    },

    _setHeight: _.throttle(function () {
      var h = $('#content-region').height() - $('.with-header').height();

      if ($('.tab-layout-content').height() < h) {
        $('.tab-layout-content').height(h);
      } else {
        this.ui.tabsRegion.height();
      }
      /* var windowHeight = $(window).height(),
         headerHeight = $('header').height() || 75,
         detailsHeaderHeight = this.ui.header.outerHeight(),
         height = windowHeight - headerHeight - detailsHeaderHeight + 5;

       this.ui.tabsRegion.height(height);*/
    }, 500)
  });

  module.exports = PimTabsLayoutView;
});
