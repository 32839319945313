define('ordersDetailInvoicesPricesView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!ordersDetailInvoicesPricesView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-prices-view ' + FieldView.prototype.className,

    serializeData: function() {
      var data = {};

      var numberLocale = 'fr-BE';
      var configNumberLocale = Settings.configValue('priceLocale');
      if (configNumberLocale)
        numberLocale = configNumberLocale;

      var price = this.model.get('total');
      var total = this.model.get('total') + this.model.get('vat');

      data.price = '€' + new Intl.NumberFormat(numberLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2}).format(price);

      data.vat = '€' + new Intl.NumberFormat(numberLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2}).format(this.model.get('vat'));

      data.total = '€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2}).format(total);

      return data;
    }
  });
});
