/**
 * Created by OLD on 04/09/2015.
 */
define('colorUtils',[
  'module'
], function (
  module
) {
  'use strict';

  var ColorConverter = {
    decimalToHexa: function (decimal) {
      var hexa = parseInt(decimal, 10).toString(16);

      while (hexa.length < 6) {
        hexa = '0' + hexa;
      }
      return '#' + hexa;
    },

    rgb2Hex: function (rgb) {
      if (rgb.search('rgb') === -1) {
        return rgb;
      } else {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        return this.hex(rgb[1]) + this.hex(rgb[2]) + this.hex(rgb[3]);
      }
    },

    rgbToHexa: function (r, g, b) {
      return this.hex(r) + this.hex(g) + this.hex(b);
    },

    hex: function (x) {
      return ('0' + parseInt(x, 10).toString(16)).slice(-2);
    }

  };

  module.exports = ColorConverter;
});

