define('pimProductSelectedAttributeValueView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'pimProductSelectedAttributeValuesView',
  'template!pimProductSelectedAttributeValueTpl',
  'pimUtil'
], function(
  module,           
  Marionette,
  App,
  _,
  PimProductSelectedAttributeValuesView,
  viewTpl,
  PimUtil
){
  'use strict';

  var PimProductSelectedAttributeValueView = Marionette.ItemView.extend({   
    template: viewTpl,
    className: 'value',   

    onRender: function () {    
     
    },

    modelEvents: {
      'change': 'render'
    },   
  
    serializeData: function () {
      var templateData = {};

      if(this.model.get('code') !== '')
        templateData.value = this.model.get('code');
      else {
        var aNames = this.model.get('value').values;

        templateData.value = PimUtil.getTranslation(aNames);   
      }

      return templateData;
    }
  });

  module.exports = PimProductSelectedAttributeValueView;
});
