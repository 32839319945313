
define('template!editPimPrintAreaTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="printarea">\n    <div class="row">\n        <div class="col-xs-3">\n            <div class="pa">\n                <div class="pa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n                <div class="row">\n                    <div class="col-xs-12">\n                        <div class="pa-info">\n                            <div class="pa-name">' +
((__t = ( name )) == null ? '' : __t) +
' <div class="glossary-dt-value pa-name-region"></div></div>\n                            <div class="pa-size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-9">\n            <div class="pz">\n                <div class="pz-title">Print zones</div>\n                <div class="pz-header ' +
((__t = ( showFastDelivery?'printzone-grid-production-time':'printzone-grid' )) == null ? '' : __t) +
'">\n                    ';
 if (showFastDelivery) { ;
__p += '\n                        <div class="productionTime">Prod t.</div>\n                    ';
 } ;
__p += '\n                    <div class="imppCode">Imprint.</div>\n                    <div class="name" style="text-align: center">Name</div>\n                    <div class="size">Size</div>\n                    <div class="setupCode">Setup</div>\n                    <div class="unitCode">Code</div>\n                    <div class="image">Image</div>\n                </div>\n                <div class="pz-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

