
define('template!pimAttributeValueTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-11">\n        <div class="code">\n            <input type="text" value="' +
((__t = ( code )) == null ? '' : __t) +
'" name="attribute-value" id="attribute-value" clearsearch="true" role="textbox" class="">\n        </div>\n    </div>\n    <div class="col-xs-1">\n        <div class="buttons">\n            <i class="mdi-action-delete delete-value-button clickable" title="delete attribute value"></i>\n        </div>\n    </div>\n</div>    \n';

}
return __p
};});

