define('popupOptionCategoryView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'settings',
  'dialogFormView',
  'dynamicTextView',
  'template!popupCreateOptionCategoryTpl'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  Settings,
  DialogFormView,
  DynamicTextView,
  popupCreateOptionCategoryTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreateOptionCategoryTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      productCodeVariable: '#productCodeVariable',
      mixable: '#mixable'
    },

    regions: {
      nameRegion: '.name-region'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel',
      'change @ui.productCodeVariable': 'onChangeProductCodeVariable',
      'change @ui.mixable': 'onChangeMixable'
    },

    modelEvents: {
      sync: 'onSave'
    },

    onChangeProductCodeVariable: function () {
      this.model.set('productCodeVariable', this.ui.productCodeVariable.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeMixable: function () {
      this.model.set('mixable', this.ui.mixable.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    initialize: function () {
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onRender: function () {
      $.material.init();
      this.nameDtView = new DynamicTextView({
        model: this.model.get('name'),
        noSaving: true,
        owner: this.model.get('refProduct').get('supplierSecId')
      });
      this.getRegion('nameRegion').show(this.nameDtView);
    },

    onConfirm: function () {
      this.model.save();
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.model = null;
      this.hide();
    }
  });
});
