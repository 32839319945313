define('mailsMailAttachmentView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'template!mailsMailAttachmentView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'attachment-view',
    
    ui: {
      name: '.name',
      remove: '.delete',
      reload: '.reload',
      pdfCanvas: '.pdf-canvas',
      image: '.image',
      loading: '.loading'
    },

    regions: {
      viewRegion: '.view-region'
    },

    serializeData: function () {
      return {
        name: this.model.get('fileName'),
        url: this.options.mailModel.getDowloadUrl(this.options.mailSecId, this.model.get('fileName')),
        fileName: this.model.get('fileName')
      };
    },

    onShow: function() {
    /*  if(this.model.get('content-type') === 'application/pdf')
        this.view = new PdfView({model: this.model});

      if(this.model.get('content-type') === 'image/jpeg' ||
          this.model.get('content-type') === 'image/jpg' ||
          this.model.get('content-type') === 'image/png' ||
          this.model.get('content-type') === 'image/x-png')
        this.view = new ImageView({model: this.model});

      if(!this.view)
        this.view = new DefaultView({model: this.model});

      this.getRegion('viewRegion').show(this.view);

      this.fetch().done(_.bind(function () {
        this.trigger('loaded');
      }, this));*/
    },

    fetch: function() {
      var defer = $.Deferred();
    /*  if(this.model.get('loaded') === true)
        return defer.resolve();
      else {
        var req = new XMLHttpRequest();
        req.addEventListener('load', _.bind(function (result) {
          if(result.currentTarget.status === 200) {
            this.model.set('arrayBuffer', result.currentTarget.response);
            this.model.set('base64', this.arrayBufferToBase64(this.model.get('arrayBuffer')));
            this.model.set('content-disposition', 'attachment; filename=' + this.model.get('name'));
            this.model.set('loaded', true);
          }
          defer.resolve();
        }, this));
        req.open('GET', this.model.get('url'));
        req.responseType = 'arraybuffer';
        req.send();
      }*/
      return defer.promise();
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  });
});
  
