/**
 * Created by GSP on 15/02/2016.
 */
define('dropdownCompositeView',['module',
        'jquery',
        'underscore',
        'backbone',
        'marionette',
        'dropdownItemView',
        'dropdownEmptyStateView',
        'template!dropdownTpl',
        'template!dropdownEmptyStateTpl'],
  function(module,
           $,
           _,
           Backbone,
           Marionette,
           DropdownItemView,
           DropdownEmptyStateView,
           dropdownTpl){
    'use strict';
    var DropDown = Marionette.CompositeView.extend({
      defaults:{
        modelProperty: 'label'
      },
      collection: new Backbone.Collection(),
      childView: DropdownItemView,
      childViewOptions: function() {
        return {
          modelProperty: this.options.modelProperty,
          modelDisplayProperty : this.options.modelDisplayProperty
        };
      },
      emptyView: DropdownEmptyStateView,
      childViewContainer: '.dropdown__list',
      template: dropdownTpl,

      childEvents: {
        'item:selected': 'onChildItemSelected'
      },

      initialize: function() {
        this.options.modelProperty = this.options.modelProperty || this.defaults.modelProperty;
        this.options.modelDisplayProperty =  this.options.modelDisplayProperty || this.options.modelProperty;
        this.originalCollection = this.collection.clone();
      },

      attachHtml: function(collectionView, childView, index){
        if(index === 0) {
          childView.$el.find('.dropdown__item-link' ).addClass('dropdown__item-link--active');
        }
        Marionette.CollectionView.prototype.attachHtml.apply(this, arguments);
      },

      onBeforeShow: function() {
        if(this.collection.length > 0) {
          var filtered;
          if(this.options.filter) {
            filtered = this.filterByProperty(this.options.filter.key, this.options.filter.value);
          } else if (this.options.filters) {
            filtered = this.filtersByProperties(this.options.filters);
          } else {
            this.preselectItem();
          }
        }
      },

      findByProperty: function(key, value) {
        var found;
        if(typeof  key === 'string' && value) {
          found = this.collection.find( function ( model ) {
            return model.get(key) === value;
          });
          return found;
        }
      },



      filterByProperty: function(key, value, options) {
        var filtered = this.originalCollection.models;
        if (options === undefined || options.reset === true) {
          this.collection.reset(this.originalCollection.models);
        }

        if(typeof  key === 'string') {
          filtered = this.collection.filter(function (model) {
            if (options && options.actions.exclude === true) {
              return model.get(key) !== value;
            } else {
              return model.get(key) === value;
            }
          });
        }
        if (options === undefined || options.applyFilter === true) {
          this.filterApply(filtered);
        }
        return filtered;
      },

      filtersByProperties: function(filters) {   
        var filtered = this.originalCollection.models;
        this.collection.reset(this.originalCollection.models);
        if (_.isArray(filters)) {
          var options =  {reset: false, applyFilter: true, actions: {}};
          _.each(filters, function(filter) {
            _.defaults(options.actions, filter.actions);
            filtered = this.filterByProperty(filter.key, filter.value, options);
          }, this);
          this.filterApply(filtered);
        }

        return filtered;
      },
      
      /**
       * Enable to apply the new filetered collection
       * @param {array} filtered - filtered collection
       */
      filterApply: function (filtered) {
          this.collection.reset(filtered);

          this.render(); // if the popup has not been rendered, the model updates are not applied      }

          this.preselectItem();
      },

      preselectItem: function() {
        if(!this.options.emptyIfNull){
            this.selectedItem = this.collection.at(0);
        }
        if(typeof this.options.modelSelectValue === 'string') {
          var filteredModel = this.findByProperty( this.options.modelProperty, this.options.modelSelectValue );
          if ( filteredModel ) {
            this.selectedItem = filteredModel;
          }
        }

        this.$el.find('.dropdown__label' ).text( this.selectedItem ? this.selectedItem.get(this.options.modelDisplayProperty) : '');
        if(this.options.onItemPreselected){
          this.options.onItemPreselected(this.selectedItem);
        }
      },

      selectItemFromOutside: function(model) {
        this.selectedItem = model;

        this.$el.find('.dropdown__label' ).text( this.selectedItem ? this.selectedItem.get(this.options.modelDisplayProperty) : '');
      },

      onChildItemSelected: function(childView) {
        this.selectedItem = childView.model;
        this.$el.find('.dropdown__label' ).text(childView.model.get(this.options.modelDisplayProperty));
        if(this.options.onItemSelected){
            this.options.onItemSelected(childView.model);
        }
      }
    });

    return DropDown;
  });
