/**
 * Created by VSH on 24/03/2016.
 */
define('graphicalRequestsWizardMixins',[
  'module',
  'jquery'
], function(
  module,
  $
) {
  'use strict';

  var GraphicalWizardMixins = {

    wizardUI: {
      wizardNav: '.wizard-navigation',
      wizardProg: '.wizard-progress-line',
      wizardPager: '.pager.wizard',
      wizardNext: '.pager.wizard .next',
      wizardFirst: '.pager.wizard .first',
      wizardPrevious: '.pager.wizard .previous',
      wizardConfirm: '.pager.wizard .confirm',
      wizardCancel: '.pager.wizard .cancel'
    },

    wizardEvents: {
      'click @ui.wizardNav a'     : 'navigateWizard',
      'click @ui.wizardNext a' : 'wizardNext',
      'click @ui.wizardFirst a' : 'wizardFirst',
      'click @ui.wizardPrevious a' : 'wizardPrevious'
    },

    wizardState: {
      step: 0
    },

    wizardInit: function(lastAllowedStep){
      this.wizardState.step = 0;
      this.wizardState.lastAllowedStep = lastAllowedStep;
    },

    checkAllowed: function(step) {
      var lastAllowed = this.wizardState.lastAllowedStep,
          current = this.wizardState.step,
          $tab, $itemsInError = this.$('#tab' + step).find('.has-error:visible');
      if (!$itemsInError.length) {
        if (typeof lastAllowed === 'undefined' || lastAllowed >= step) return true;
      }
      $tab = this.$('#tab' + (current + 1));
      $tab.children().addClass('error');
      if (typeof this.customError === 'function') {
        this.customError(step, $itemsInError);
      }
      return false;
    },

    setLastAllowed: function(lastAllowed) {
      this.$('#tab' + lastAllowed);
      this.wizardState.lastAllowedStep = lastAllowed;
    },

    validateWizardStep: function(step, allowed) {
      if (this.wizardState.lastAllowedStep > allowed) return;
      var $tab = this.$('#tab' + (step + 1));
      $tab.children().removeClass('error');
      allowed = allowed ? allowed : step + 1;
      this.wizardState.lastAllowedStep = allowed;
    },

    checkLastStep: function(step) {
      if (this.wizardState.last === step) {
        this.ui.wizardNext.addClass('hidden');
        this.ui.wizardConfirm.removeClass('hidden');
        this.ui.wizardCancel.removeClass('hidden');
      } else {
        this.ui.wizardNext.removeClass('hidden');
        this.ui.wizardConfirm.addClass('hidden');
        this.ui.wizardCancel.addClass('hidden');
      }
    },

    navigateWizard: function(e) {
      var $target = $(e.currentTarget),
          parentLi = $target.parent()[0],
          prStep = this.wizardState.step,
          $navlis = this.ui.wizardNav.find('li'),
          stepIndex = $navlis.index(parentLi),
          percent = stepIndex * (100 / ($navlis.length - 1));

      this.wizardState.last = $navlis.length - 1;
      if (prStep === stepIndex ||
          !this.checkAllowed(stepIndex)) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      $navlis.each(function(index, item){
        var $item = $(item);
        if (index < stepIndex) {
          $item.addClass('done');
        } else {
          $item.removeClass('done');
        }
      });
      this.ui.wizardProg.width(percent + '%');
      this.wizardState.step = stepIndex;
      this.checkLastStep(stepIndex);
      if (stepIndex === 0) {
        this.ui.wizardFirst.addClass('disabled');
        this.ui.wizardPrevious.addClass('disabled');
      } else {
        this.ui.wizardFirst.removeClass('disabled');
        this.ui.wizardPrevious.removeClass('disabled');
      }
    },

    wizardNext: function() {
      var $navlis = this.ui.wizardNav.find('li'),
          step = this.wizardState.step,
          last = $navlis.length - 1,
          $target;

      if (!this.checkAllowed(step + 1)) return;
      this.wizardState.last = last;
      if (step < last) {
        $target = $navlis.eq(step + 1).find('a');
        $target.click();
      }
    },

    wizardPrevious: function() {
      var $navlis = this.ui.wizardNav.find('li'),
          step = this.wizardState.step,
          last = $navlis.length - 1,
          $target;

      this.wizardState.last = last;
      if (step > 0) {
        $target = $navlis.eq(step - 1).find('a');
        $target.click();
      }
    },

    wizardFirst: function() {
      var $navlis = this.ui.wizardNav.find('li'),
          step = this.wizardState.step,
          last = $navlis.length - 1,
          $target;

      this.wizardState.last = last;
      if (step !== 0) {
        $target = $navlis.eq(0).find('a');
        $target.click();
      }
    }

  };

  module.exports = GraphicalWizardMixins;
});
