define('ordersDetailTrackingListRowToolsView',[
  'app',
  'module',
  'settings',
  'underscore', 
  'bootbox',  
  'gmailUtils',
  'dateUtils',
  'emailView',
  'modalsConfirmationView',
  'modalsAlertView',
  'tableFieldView',
  'ordersDetailTrackingEditView',
  'template!ordersDetailTrackingListRowToolsView',
  'entities/customers/customers',
  'entities/caccounts/htmltemplates'
], function (
  App,
  Module,  
  Settings,
  _,
  Bootbox,
  GmailUtils,
  DateUtils,
  EmailView,
  ConfirmationView,
  AlertView,
  FieldView,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-list-row-tools-view ' + FieldView.prototype.className,

    ui: {
      prevewButton: '.preview',
      editButton: '.edit',
      deliveredButton: '.delivered', 
      mailButton: '.mail',      
      deleteButton: '.delete'
    },

    events: {
      'click @ui.prevewButton': 'onPreview',
      'click @ui.deliveredButton': 'onDelivered',
      'click @ui.mailButton': 'onSendMail',
      'click @ui.editButton': 'onEdit',
      'click @ui.deleteButton': 'onDelete'
    },

    modelEvents: {
      'change:trackingStatus': 'renderDelivered'
    },

    onRender: function() {
      this.renderDelivered();

      if(!this.model.trackingUrl())
        this.ui.prevewButton.addClass('disabled');
    },

    renderDelivered: function() {
      if(this.model.get('trackingStatus') === 'delivered')
        this.ui.deliveredButton.addClass('disabled');
      else
        this.ui.deliveredButton.removeClass('disabled');
    },

    onPreview: function () {
      this.customer = App.request('customers:entity:end-user-id', this.tableView.model.get('endUserId'));
      this.customer.fetch().done(_.bind(function() {
        if(this.model.trackingUrl(this.customer))
          window.open(this.model.trackingUrl(this.customer), '_blank');
      }, this));
    },

    onDelete: function () {
      ConfirmationView.prototype.open({
        text: _.i18n('orders.tracking.delete.confirmation'),
        onConfirm: _.bind(function() {
          this.model.remove().done(_.bind(function() {
            this.tableView.bodyView.deleteRow(this.options.rowView);
            this.tableView.model.fetch();
          }, this));
        },this)
      });
    },

    onEdit: function() {
      EditView.prototype.open(this.model, this.tableView.model);
    },

    onDelivered: function () {
      if(this.model.get('trackingStatus') === 'delivered')
        return;

      var model = this.model.clone();
      model.set('trackingStatus', 'delivered', {silent: true});
      model.set('deliveryDate', new Date(), {silent: true});
      model.unset('refCommandLine', { silent: true });
      this.tableView.model.saveTracking(model).done(_.bind(function(data) {
        this.model.set(data);
        this.tableView.model.fetch();
      }, this));
    },

    onSendMail: function () {
      var endUserId = this.tableView.model.get('endUserId');
      var endUser = App.request('customers:entity:end-user-id', endUserId);     
     
      endUser.fetch().done(_.bind(function () {
        var locale = endUser.get('locales') || Settings.get('lang').toLowerCase();

        var orderJson = this.tableView.model.toJSON();

        var deliveryDate = '';
        if(this.model.get('deliveryDate'))
          deliveryDate = DateUtils.toDateFormatString(this.model.get('deliveryDate'));

        orderJson.productNameDt = {values: {}};
        orderJson.trackings = [this.model.toJSON()];

        var from = App.request('mails:getFromMail', Settings.configValue('mails.tracking.from'), locale);
        App.request('mails:checkFrom', from).done(_.bind(function () {
          var tracking = this.model.toJSON();
          tracking.trackingUrl = this.model.trackingUrl(this.tableView.options.customer);

          EmailView.prototype.open({
            from: from,
            to: endUser.get('email') || endUser.get('endUserId'),
            allowFromSelection: Settings.configValue('mails.tracking.allowFromSelection'),
            templateTypes: ['MAIL_SIGN', 'MAIL_ORDER_TRACKING'],
            defaultTemplate: 'MAIL_ORDER_TRACKING',
            templateData: {
              trackings: [tracking],
              orders: [orderJson],
              customer: endUser.toJSON(),
              deliveryDate: deliveryDate,
              customerName: this.tableView.model.get('customerName'),
              productName: this.tableView.model.get('productName'),
              trackingUrl: this.model.trackingUrl(this.tableView.options.customer),
              user: Settings.get('currentUser')
            },
            templateLocale: locale,
            id: 'tracking.' + this.model.get('secId'),
            onMailSend: _.bind(function (mails) {
              var model = this.model.clone();
              model.set('sentDate', new Date(), {silent: true});
              model.set('sentEmail', mails, {silent: true});
              model.unset('refCommandLine', {silent: true});
              model.save(this.tableView.options.poi).done(_.bind(function() {
                this.model.set('sentDate', model.get('sentDate'));
                this.model.set('sentEmail', model.get('sentEmail'));
                this.model.set('refCommandLine', model.get('refCommandLine'));
                this.tableView.model.fetch();
              }, this));
            }, this)
          });
        }, this)).fail(_.bind(function () {
          AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
        }, this));
      }, this));
    }
  });
});
