/**
 * Created by OLD on 12/01/2016.
 */
define('cataloguesAddNewProductPopupView',[
    'module',
    'app',
    'settings',
    'underscore',    
    'template!cataloguesAddNewProductPopupTpl',
    'dialogFormView',
    'imageUtils',
    'stateMessages'
], function (
    module,
    App,
    Settings,
    _,
    viewTpl,
    DialogFormView,
    ImageUtils,
    StateMessages
    ) {
    'use strict';

    var PopupAddNewCatalogueView = DialogFormView.extend({
        template: viewTpl,

        behaviors: {
            StateMessages: {
                behaviorClass: StateMessages
            }
        },

        ui: {
            upload: '.upload-product-picture',
            preview: '.product-picture-img',
            input: '.js-form-input',
            logoHolder: '.logo-holder',
            imageContainer : '.catalogue-image-container'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'focus @ui.input': 'onFocus',
            'change @ui.upload' : 'onChangeLogo',
            'click @ui.logoHolder' : 'onChangeFileInput'
        },

        serializeData: function() {
            var fileUrl = this.model ?
                Settings.url('file', this.model.get('pictureUid'), {size: 'thumbnail'}) : Settings.get('defaultCatalogueImage');
            return {
                code : this.model ? this.model.get('code') : '',
                name : this.model ? this.model.get('name') : '',
                imageUrl : fileUrl
            };
        },

        onRender: function() {
            this.formData = new FormData();

            if(this.model && this.model.get('pictureUid')){
                this.ui.imageContainer.addClass('hidden');
            }
            else{
                this.ui.imageContainer.removeClass('hidden');
            }
        },

        onChangeFileInput: function() {
            this.ui.upload.click();
        },

        onChangeLogo: function() {
            var file = this.ui.upload[0].files[0];

            this.formData.append('file', file);
            ImageUtils.encodeFileToBase64(file,
              _.bind(this.onPreviewFile, this));

        },

        onPreviewFile: function(base64File) {
            var that = this;

            this.model.set('image', {
                content: base64File,
                extension: this.ui.upload[0].files[0].name.split('.')[1],
                name: this.ui.upload[0].files[0].name
            });
            this.ui.preview.attr('src', base64File).load(function() {
                that.ui.logoHolder.addClass('hidden');
                that.ui.preview.removeClass('hidden');
            });
        }
    });

    module.exports = PopupAddNewCatalogueView;
});
