/**
 * Created by RKL on 21/08/2015.
 */
define('mailingMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App
) {
  'use strict';

  var MailingRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'mailing': 'showView'
    }
  });

  var API = {
    showView: function() {
      require([
        'mailingShowController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('mailingMain');
          ShowController.showMailing();
          App.execute('set:active:nav:item', 'mailing');
        });
      });
    }
  };

  App.on('mailing:show', function() {
    App.navigate('mailing');
    API.showView();
  });

  App.on('before:start', function() {
    new  MailingRouter({
      controller: API
    });
  });

  module.exports = MailingRouter;
});
