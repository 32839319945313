define('webshopCategoriesDetailBodyUrlsDomainView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'template!webshopCategoriesDetailBodyUrlsDomainView'
], function (
  App,
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-urls-domain-view',

    serializeData: function () {
      var data = {};
      data.domain = this.model.get('domain');
      data.url = this.model.get('url');
      return data;
    }
  });
});
