/**
 * Created by RKL on 10/09/2015.
 */
define('dialogFormView',[
  'module',
  'underscore',
  'settings',
  'dialogView',
  'formView',
  'dialogMixin'
], function (
  module,
  _,
  Settings,
  DialogView,
  FormView,
  DialogMixin
) {
  'use strict';

  var DialogFormView = FormView.extend(
    _.extend({
      getAutocompleteOptions: function(baseUrl, url, valueKey, apiKey, name, callback) {
        return {
          type: 'remote',
          alwaysOpen: true,
          remote: Settings.url(baseUrl, url),
          valueKey: valueKey,
          apiKey: apiKey,
          placeholder: _.i18n('select.placeholder'),
          value: null,
          name: name,
          onPressKey: true,
          lazyLoad: true,
          keys: {
            query: 'search',
            limit: 'pageSize'
          },
          callback: callback
        };
      }
    }, DialogMixin)
  );

  module.exports = DialogFormView;
});
