define('phoneCallsCustomerNumberView',['app',
  'module',
  'underscore',
  'plivoUtils',
  'tableFieldView',
  'template!phoneCallsCustomerNumberView'
], function (
  App,
  Module,  
  _,
  PlivoUtils,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'table-field-view phone-numbers-customer-number-view',

    ui: {
      number: '.number'
    },

    events: {
      'click @ui.number': 'onCall'
    },

    serializeData: function() {
      var data = {};
      data.number = this.model.get('customerPhoneNumber');
      return data;
    },

    onRender: function() {
      if(this.model.get('customerPhoneNumber'))         
        this.ui.number.html(this.model.get('trackingNumber'));            
    },

    onCall: function(e) {
      e.stopPropagation();
      if(this.model.get('customerPhoneNumber') && this.model.get('customerPhoneNumber') !== '')
        App.trigger('phone:numbering', {
          phoneNumber: this.model.get('customerPhoneNumber')
        });
    }
  });
});
