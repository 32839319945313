/**
 * Created by Matteo on 19/07/2015.
 */
define('unauthorizedShowView',[
  'module',
  'marionette',
  'app',
  'template!unauthorizedViewTpl'
], function(
  module,
  Marionette,
  App,
  viewTpl
) {
  'use strict';

  var AnauthorizedView = Marionette.ItemView.extend({
    template: viewTpl
  });

  module.exports = AnauthorizedView  ;
});
