/**
 * Created by BFR on 16/06/2020.
 */
define('productPricesTabController',[
  'module',
  'underscore',
  'app',
  'jquery',
  'productPricesTabView',
  'emptyView',
  'entities/product',
  'entities/product_share',
  'entities/caccounts',
  'entities/product_competitor'
], function (
  module,
  _,
  App,
  $,
  View,
  EmptyView
) {

  'use strict';
  var ProductPricesController = {
    showDetails: function (region, product) {
      if (region) {
        this.region = region;
      } else {
        region = this.region;
      }
      if (product) {
        this.model = product;
      } else {
        product = this.model;
      }

      var emptyView = new EmptyView();
      region.show(emptyView);

      var config = {
        quantity: 500,
        options: [],
        printZonesColors: {}
      };
      _.each(product.get('productOptionCategories'), function (poc) {
        config.options.push(poc.productOptions[0].id);
      });
      var sup = App.request('caccount:get-supplier-print-factors-ca-id', product.get('manufacturerId'));
      sup.done(_.bind(function (supplierPrintFactors) {
        var productShare = App.request('products-share:model', {secId: product.get('secId')});
        productShare.fetch().done(_.bind(function () {
          _.each(productShare.get('printAreas').models, function (pa) {
            _.each(pa.get('printZones').models, function (pz) {
              if (pz.get('printZone')) {
                config.printZonesColors[pz.get('printZone').get('id')] = 1;
              } else {
                config.printZonesColors[pz.get('parentPrintZone').get('id')] = 1;
              }
            });
          });

          $.when(App.request('caccount:get-factors')).done(_.bind(function (factorsV2) {
            $.when(App.request('caccount:get-factors-by-supplier-id', this.model.get('manufacturerId')))
              .done(_.bind(function (factorsV2Supplier) {
                if (factorsV2Supplier) {
                  factorsV2 = factorsV2Supplier;
                }

                var pzPrices = App.request('product-printzone-prices:model');
                var compsCol = App.request('product-competitor:collection');
                var simsCol = App.request('product-similar:collection');
                var pricesCol = App.request('product-prices:collection');

                $.when(pzPrices.fetch(this.model.get('secId')),
                  compsCol.fetch(this.model.get('secId')),
                  simsCol.fetch(this.model.get('secId')),
                  pricesCol.fetch(this.model.get('secId'))).done(_.bind(function () {
                  var request = App.request('products:get-price-for-configuration', this.model.get('secId'), config, true);
                  $.when(request).done(_.bind(function (price) {
                    App.request('product-prices-factor-sup', this.model.get('secId')).done(_.bind(function (pricesFactorSup) {
                      _.each(price.scale, function (scale, index) {
                        scale.factorModel = pricesCol.findByQuantity(scale.quantity);
                        if (scale.factorModel === null) {
                          scale.factorModel = {
                            quantity: Number(scale.quantity),
                            factor: 1.3,
                            defaultFactor: pricesFactorSup.length > index ? pricesFactorSup[index].factor : 1.3
                          };
                          scale.previousFactor = scale.factorModel.factor;
                        } else {
                          scale.factorModel = scale.factorModel.toJSON();
                          scale.factorModel.defaultFactor = pricesFactorSup.length > index ? pricesFactorSup[index].factor : 1.3;
                          scale.previousFactor = scale.factorModel.factor;
                        }
                      });
                      this.view = new View({
                        model: product,
                        price: price,
                        productPrices: pricesCol,
                        pricesFactorSup: pricesFactorSup,
                        productShare: productShare,
                        printZonePrices: pzPrices,
                        supplierPrintFactors: supplierPrintFactors,
                        competitorsCollection: compsCol,
                        similarsCollection: simsCol,
                        config: config,
                        useFactorV2: ((!pricesCol || pricesCol.length === 0) && factorsV2),
                        factorsV2: factorsV2
                      });
                      emptyView.destroy();
                      this.view.listenTo(this.view, 'product-prices:update-prices', _.bind(this.onPricesUpdate, this));
                      this.view.listenTo(this.view, 'product-prices:refresh', _.bind(this.showDetails, this));
                      region.show(this.view);
                    }, this));
                  }, this));
                }, this));
              }, this));
          }, this));
        }, this));
      }, this));
    },

    onPricesUpdate: function (factors) {
      this.view.ui.approveLoading.show();
      var pricesCol = App.request('product-prices:collection', factors);
      pricesCol.saveAll(this.model.get('secId')).done(_.bind(function (models) {
        this.view.generateOldestLabel(models);
        this.view.ui.approveLoading.hide();
      }, this));
    }
  };

  module.exports = ProductPricesController;
});
