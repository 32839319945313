/**
 * Created by RKL on 21/08/2015.
 */
define('customersMain',[
  'module',
  'logger',
  'customersMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Customers = {
    start: function() {
      Logger.log(module, 'starting CustomersModule');
    },

    stop: function() {
      Logger.log(module, 'stopping CustomersModule');
    }
  };

  module.exports = Customers;
});
