
define('template!shareProductTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n    ' +
((__t = ( parentCode )) == null ? '' : __t) +
' --> ' +
((__t = ( code )) == null ? '' : __t) +
' ' +
((__t = ( inProgress?'(Sharing)':'(Shared)' )) == null ? '' : __t) +
'\n</div>';

}
return __p
};});

