/**
 * Created by ASK on 28/0/2021.
 */
define('pimAttributesView',[
  'module', 
  'jquery',
  'underscore',
  'backbone',
  'app',
  'marionette',
  'template!pimAttributesTpl',
  'pimAttributeView',
  'pimAddAttributeView',
  'autocompleteView',
  'entities/pim_attribute'
], function (
  module,
  $,
  _,
  Backbone,
  App,
  Marionette,
  viewTpl,
  PimAttributeView,
  PimAddAttributeView,
  AutocompleteView
) {
  'use strict';

  var PimAttributesView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'pim-page attributes',

    regions: {
      attributeListRegion: '.attribute-list-region',
      attributeViewRegion: '.attribute-view-region',
      addAttributeViewRegion: '.attribute-add-view-region'
    },

    ui: {
      selectAttribute: '.select-attribute',
      addAttributeButton: '.new-attribute-button',
      deleteAttributeButton: '.delete-attribute-button',
      saveButton: '.save-button',
      newAttributeForm: '.newAtributeForm'
    },

    events: {
      'change @ui.selectAttribute': 'onAttributeChange',
      'click @ui.addAttributeButton': 'onAddAttribute',
      'click @ui.deleteAttributeButton': 'onDeleteAttribute',
      'click @ui.saveButton': 'onSave'
    },

    initialize: function() {    

      this.attributeModel = App.request('pim:attribute:model', {'secId': '', 'code': '', 'name': []}); 
      this.attributeValuesCollection = App.request('pim:attribute:values:collection');

      this.viewModel = new Backbone.Model();   
      this.viewModel.set('currentSecId', '');     

      //----------

      this.attributesAutocompleteView = new AutocompleteView({
        type: 'remote',
        alwaysOpen: false,
        remote: App.request('pim:attributes:get-collection-url'),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('select-attribute.placeholder'),
        value: null,
        name: 'code',
        onPressKey: true,
        lazyLoad: true,
        keys: {
            query: 'code',
            limit: 'pageSize'
        },
        callback: _.bind(this.onAttributeChange, this)
      });

      //----------

      this.mode = 'view';
    },    

    onShow: function()
    {     
      this.getRegion('attributeListRegion').show(this.attributesAutocompleteView);

      //------------

      this.addAttributeView = new PimAddAttributeView({
        'model': this.attributeModel, 
        'valuesCollection': this.attributeValuesCollection
      });
      this.getRegion('addAttributeViewRegion').show(this.addAttributeView);
    },

    onAttributeChange: function(property, selectedModel)
    {      
      this.viewModel.set('currentSecId', selectedModel.get('secId'));              
      this.attributeModel.set({
        'secId': selectedModel.get('secId')
      }, {
        'silent': true
      }).fetch().done(this.updateValues());  
    },

    updateValues: function()
    {      
      if(this.attributeModel.get('secId')) {
        this.attributeView = new PimAttributeView({
          'model': this.attributeModel, 
          'valuesCollection': this.attributeValuesCollection, 
          'viewModel': this.viewModel
        });
        
        this.getRegion('attributeViewRegion').show(this.attributeView);  
      }      
    },

    onAddAttribute: function()
    {     
      this.mode = 'newAttribute';

      this.$el.find('.body .view').hide();
      this.$el.find('.body .newAttribute').show();
    },

    onSave: function()
    {      
      if(this.mode === 'view') {
        this.attributeModel.save().done(_.bind(function() {
          _.each(this.attributeValuesCollection.models, _.bind(function(valueModel) {
              if(valueModel.changed.code) {
                valueModel.save(this.attributeModel.get('secId'));
              }
          }, this));        
        }, this));      
      }

      if(this.mode === 'newAttribute') {
        if(this.$el.find('.new-attribute-code').val() !== '') {
          var attributeModel = App.request('pim:attribute:model'); 
          attributeModel.set('code', this.$el.find('.new-attribute-code').val());
          attributeModel.save();

          this.mode = 'view';

          this.$el.find('.body .view').show();
          this.$el.find('.body .newAttribute').hide();
          //this.updateValues();
        }
      }
    },

    onDeleteAttribute: function()
    {
      this.attributeModel.destroy();
    }
   
  });

  module.exports = PimAttributesView;
});

