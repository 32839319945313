/**
 * Created by dev on 15-01-16.
 */
define('invoiceSampleView',[
    'module',
    'marionette',
    'app',
    'template!invoiceSampleTpl'
], function(
    module,
    Marionette,
    App,
    viewTpl
    ) {
    'use strict';

    var invoiceSampleView =  Marionette.ItemView.extend({
        template: viewTpl,

        ui: {
        },

        onShow: function() {
            $.material.init();
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.sampleAllowed = this.options.invoiceAvailable;
            templateData.sampleUrl = this.options.sampleUrl;

            return templateData;
        }
    });

    module.exports = invoiceSampleView;
});
