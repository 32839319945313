/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMain',[
  'module',
  'logger',
  'superAdminMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var SuperAdmin = {
    start: function() {
      Logger.log(module, 'starting superAdminModule');
    },

    stop: function() {
      Logger.log(module, 'stopping superAdminModule');
    }
  };

  module.exports = SuperAdmin;
});
