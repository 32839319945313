/**
 * Created by BFR on 02/01/2020.
 */
define('changeSupplierNumberPopupView',[
    'module',
    'jquery',
    'underscore',
    'settings',
    'template!changeSupplierNumberPopupTpl',
    'dialogFormView'
], function(
    module,
    $,
    _,
    Settings,
    viewTpl,
    DialogFormView
) {
    'use strict';

    var SupplierNumberPopupView = DialogFormView.extend({
        template: viewTpl,

        ui: {
            inputName: '.js-info-input'
        },

        events: {
            'change @ui.inputName': 'onSupplierNumberChange'
        },

        onSupplierNumberChange: function() {
            this.options.supplierNumber = this.ui.inputName.val();
        },

        serializeData: function() {
            var templateData = {
                supplierNumber: this.options.supplierNumber
            };
            return templateData;
        },

        onShow: function() {
            $.material.init();
        }
    });

    module.exports = SupplierNumberPopupView;
});
