define('tasksInfosView',[
  'app',
  'module',
  'settings',
  'backbone',
  'underscore',
  'moment',
  'dateView',
  'tableFieldView',
  'template!tasksInfosView'
], function (
  App,
  Module,
  Settings,
  Backbone,
  _,
  moment,
  DateView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-infos-view ' + FieldView.prototype.className,

    regions: {
      dateRegion: '.date-region'
    },

    serializeData: function () {
      var data = {};
      data.type = '';

      if (this.model.get('refCommandLine')) {
        data.type = 'order';

        //------------

        data.user = this.parseClientName(this.model.get('refCommandLine'));

        //------------

        data.sameOrderCount = 0;
        if(this.model.get('refCommandLine').nbrOrderSameCustomer)
          data.sameOrderCount = this.model.get('refCommandLine').nbrOrderSameCustomer;

        //------------

        var html = this.model.get('refCommandLine').purchaseOrderId;
        var price = '';

        var numberLocale = 'fr-BE';
        var configNumberLocale = Settings.configValue('priceLocale');
        if (configNumberLocale)
          numberLocale = configNumberLocale;

        if(this.model.get('refCommandLine').price)
          price += '€' + new Intl.NumberFormat(numberLocale).format(this.model.get('refCommandLine').price);

        if(this.model.get('refCommandLine').quantity) {
          if(price !== '')
            price += ' - ';
          price += new Intl.NumberFormat(numberLocale).format(this.model.get('refCommandLine').quantity) + ' ' + _.i18n('common.pcs');
        }

        if(price !== '') {
          if(html !== '')
            html += ' | ';
          html += price;
        }

        data.line2 = html;

      } else if (this.model.get('refEndUser')) {
        data.type = 'client';
        data.user = this.parseClientName(this.model.get('refEndUser'));
      } else if (this.model.get('refProduct')) {
        data.type = 'product';
        data.code = '';
        data.name = '';

        if(this.model.get('refProduct').cCode)
          data.code = this.model.get('refProduct').cCode;

        if(this.model.get('refProduct').name)
          data.name = ' - ' + this.model.get('refProduct').name;
      }

      return data;
    },

    parseClientName: function(model) {
      var properties = Settings.configValue('board.card.mainTitleProperty');
      var clientName = '';
      _.each(properties, function (property) {
        if (model[property] && clientName === '')
          clientName = model[property];
      }, this);
      if (clientName === '')
        clientName = model.endUserId;
      return clientName;
    }

  });
});
