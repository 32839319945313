define('ordersDetailOrderProductMixedOptionView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'ordersDetailOrderProductOptionView',
  'template!ordersDetailOrderProductMixedOptionView'
], function (
  Module,
  Marionette,
  _,
  Backbone,
  OptionView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: OptionView,
    className: 'orders-detail-order-mixed-options-view',
    childViewContainer: '.options-container',

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      _.each(this.model.get('options'), function (option) {
        this.collection.add(option);
      }, this);
    }
  });
});
