/**
 * Created by BFR on 20/10/2022.
 */
define('caccountConfigController',[
  'module',
  'app',
  'jquery',
  'underscore',
  'settings',
  'caccountConfigView',
  'entities/caccounts/config'
], function (
  module,
  App,
  $,
  _,
  Settings,
  View
) {
  'use strict';

  module.exports = {
    showTab: function () {
      var configModel = App.request('caccount-config:model', {secId: Settings.get('currentCAccount').secId});
      configModel.fetch().done(function () {
        this.view = new View({
          model: configModel
        });
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
      });
    }
  };
});
