define('ordersConfirmationView',[
  'app',
  'module',
  'underscore',
  'modalsConfirmationView',
  'ordersConfirmationOrdersView',
  'template!ordersConfirmationView'
], function(
  App,
  Module,
  _,
  ModalsConfirmationView,
  OrdersView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-confirmation-view ' + ModalsConfirmationView.prototype.className,

    regions: _.extend( {
      ordersRegion: '.orders-region'
    }, ModalsConfirmationView.prototype.regions),

    onShow: function() {
      this.ordersView = new OrdersView({
        orders: this.options.orders
      });
      this.getRegion('ordersRegion').show(this.ordersView);

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    // Static methods
    open: function (text, orders, onConfirm) {
      require([
        'windows',
        'ordersConfirmationView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              text: text,
              orders: orders,
              onConfirm: onConfirm
            });
          }, this),
          modal: true,
          maxWidth: 800
        });
      });
    }
  });
});
