define('translation',[
  'module',
  'jquery',
  'underscore',
  'polyglot',
  'lodash',
  'bootbox',
  'settings',
  'logger'
], function (module, $, _, Polyglot, lodash, bootbox, Settings) {
  'use strict';

  var polyglot = new Polyglot();

  /**
   * Create the global object with pair/key (localized/text key) eg: fr: {common.save: save}
   * @param translationObj
   * @param tObject
   */
  function setTranslation(translationObj, tObject) {
    var code, patt;

    if (tObject && tObject.code && tObject.text) {
      patt = Settings.get('application') + '.';
      code = tObject.code.replace(patt, '');

      _.each(tObject.text.localized, function (item, key) {
        if (!translationObj[key]) {
          translationObj[key] = {};
        }
        translationObj[key][code] = tObject.text.localized[key];
      });

      //if the localized is not available for a text we add the localized with the english translation
      if (!tObject.text.localized[Settings.get('lang')]) {
        if (!translationObj[Settings.get('lang')]) {
          translationObj[Settings.get('lang')] = {};
        }
        translationObj[Settings.get('lang')][code] = tObject.text.localized.en;
      }
    }
  }

  var Translation = {
    isLoaded: false,
    fetchTranslations: function (app, withoutToken) {
      if (withoutToken === undefined) {
        withoutToken = true;
      }
      polyglot.prefix = Settings.get('application');
      var server = (app ? 'compuzz2' : 'compuzz3');
      var translationObj = {};
      return $.ajax({
        async: false,
        url: Settings.url(server, 'v2/texts/app/' +
          (app || Settings.get('application')), null, withoutToken)
      }).always(_.bind(function (data) {
        _.each(data, function (item) {
          setTranslation(translationObj, item);
        });
        polyglot.extend(translationObj);
        //Override polyglot.t function and add localized prefix eg: fr.xxxxxx
        //if the localize is not specify use the application localized
        lodash.i18n = _.bind(function (key, localized) {
          return polyglot.t((localized || Settings.get('lang')) + '.' + key);
        }, polyglot);
        _.i18n = lodash.i18n;

        bootbox.addLocale(Settings.get('lang'), {
          OK: _.i18n('common.ok'),
          CANCEL: _.i18n('common.cancel'),
          CONFIRM: _.i18n('common.confirm')
        });
        bootbox.setLocale(Settings.get('lang'));
      }, this));
    },

    /**
     * Filter the translation result by key filter
     * @param translations object
     * @param filter
     */
    filterTranslations: function (translations, filter) {
      var result = [];
      filter = filter || '.title';
      _.each(translations, function (item) {
        if (item.code.indexOf(filter) !== -1) {
          result.push(item);
        }
      });
      return result;
    },

    /**
     * Convert a title Text support to subject key
     * @param key
     * @returns {XML|*|string|void}
     */
    getTextSupport: function (key) {
      if (key.split('.')[0] === Settings.get('textSupport')) {
        return key.replace('.title', '.subject');
      } else {
        throw 'it is not a title text support';
      }
    },

    /**
     * Convert a title Order Text support to subject key
     * @param key
     * @returns {XML|*|string|void}
     */
    getOrderTextSupport: function (key) {
      if (key.split('.')[0] === Settings.get('orderTextSupport')) {
        return key.replace('.title', '.subject');
      } else {
        throw 'it is not a title text support';
      }
    }

  };

  module.exports = Translation;
});
