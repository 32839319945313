/**
 * Created by dev on 08/06/2016.
 */
/**
 * Created by RKL on 22/09/2015.
 */
define('translatePopoverBehavior',[
  'module',
  'underscore',
  'jquery',
  'marionette',
  'settings'
], function (
  module,
  _,
  $,
  Marionette,
  Settings
) {
  'use strict';

  var TranslatePopoverBehavior =  Marionette.Behavior.extend({

    events: {
      'click @ui.translateBtn': 'onGetTranslation'
    },

    ui: {
      translateBtn: '.translation'
    },

    onRender: function() {
      var target = this.options.target || '.item-to-translate';
      this.languageFrom =   this.view.model.get('translateFrom') || 'es';
      this.languagesTo =  this.options.languagesTo || 'es';
      if (target && typeof target === 'string' ) {
        this.$target = this.$( target );
        this.textToTranslate = this.$target.data('toTranslate');
      }
    },

    onBeforeDestroy: function() {
      var target = this.options.target || '.item-to-translate';
      this.$(target).popover('destroy');
    },

    _getPromises: function() {
      var $request = [];
      _.each(this.languagesTo, function(languageTo){
        var $deferred = this._getTranslatedContent( {
          from: this.languageFrom,
          text: this.textToTranslate,
          to: languageTo
        });
        $request.push($deferred);
      }, this);
      return $request;
    },

    _createTooltip: function($request) {
      var content = '',
        self = this;
      
      $.when.all($request).then( _.bind(function(dataObjects) {
        _.each(dataObjects, function(data, index) {
          var response = _.last(data );
          if(response.status === 200) {
            content += '<strong>' + this.languagesTo[index] + '</strong>' + '<br /> <br />' +
                '<p class="prewarp">' + _.escape($( _.last(data ).responseText ).text().replace(/<[^>]br>|<br[^>]*>/g, '\n')) + '</p>' +
                       ' <br /> <br />';
          }
        }, this);
        if(content) {
          self.translatedContent = content;
          self.$target.popover( {
            html: true,
            content: content,
            trigger: 'focus',
            container: 'body'
          } );
          self.$target.popover('show');
        }
      }, this));
    },

    _getTranslatedContent: function(options) {
      return $.ajax( {
        url: Settings.url( 'compuzzTranslate', '',
          {
            from: options.from,
            text: options.text,
            to: options.to
          } )
      } );
    },

    onGetTranslation: function() {
      if(this.textToTranslate && typeof this.languageFrom === 'string') {
        if (!this.translatedContent) {
          this._createTooltip(this._getPromises());
        } else {
          this.$target.popover('show');
        }
        
      }
    }

  });

  module.exports = TranslatePopoverBehavior;
});
