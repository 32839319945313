
define('template!ordersDetailAttachmentsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="order-attachments-region"></div>\r\n\r\n<div class="customer-attachments">\r\n    <div class="customer-title">    \r\n        ' +
((__t = ( _.i18n("orderDetail.customer-attachments") )) == null ? '' : __t) +
'\r\n    </div>\r\n    <div class="customer-attachments-region"></div>\r\n</div>';

}
return __p
};});

