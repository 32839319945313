define('mailsMenuView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'buttonView',
  'selectView',
  'settings',
  'emailView',
  'template!mailsMenuView',
  'jstree'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,  
  ButtonView,
  SelectView,
  Settings,
  EmailView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'menu-view',

    ui: {
      folders: '.folders'
    },

    regions: {
      newMailRegion: '.new-mail-region',
      mailsRegion: '.mails-region'
    },

    initialize: function() {
      this.mailSecId = undefined;
      if(this.options.from)
        this.mailSecId = this.options.from;

      this.folder = 'INBOX';
      if(this.options.folder)
        this.folder = this.options.folder;
    },

    onRender: function() {
      this.newButton = new ButtonView({
        type: 'add',
        text: _.i18n('mails.new'),
        title: _.i18n('mails.new')
      });

      this.getRegion('newMailRegion').show(this.newButton);
      this.newButton.on('click', this.onNewMail, this);

      //--------------------
      this.mailsCollection = new Backbone.Collection();
      this.mailsView = new SelectView({
        collection: this.mailsCollection
      });
      this.getRegion('mailsRegion').show(this.mailsView);
      this.mailsView.on('change', _.bind(this.onMailChange, this));

      App.request('mails:getFromMails').done(_.bind(function(addresses) {
        _.each(addresses, function (address) {
          this.mailsCollection.add({id: address.secId, value: address.email});
        }, this);

        if(addresses.length > 0) {
          if(!this.mailSecId)
            this.mailSecId = addresses[0].secId;
          this.options.listView.mailsView.setMailSecId(this.mailSecId);
          this.mailsView.setSelected(this.mailSecId);
          this.ui.folders.jstree('refresh');
        }

      }, this));

      //--------------------
      this.ui.folders.jstree({
        'core': {
          'data': _.bind(this.fetch, this),
          'check_callback': true,
          'multiple' : false
        },
        'plugins': ['dnd']
      }).on('open_node.jstree', _.bind(function(e, data) {
        if(data.node.state.fetch)
          return;

        this.fetch(data.node, _.bind(function(folders) {
          data.node.state.fetch = true;

          this.ui.folders.jstree('delete_node', data.node.id + '/load');

          _.each(folders, _.bind(function(folder) {
            this.ui.folders.jstree('create_node', data.node, folder, 'last');
          }, this));

          this.ui.folders.jstree('open_node', data.node);
        }, this));
      }, this)).on('select_node.jstree', _.bind(function(e, data) {
        this.trigger('folder:selected', data.node.id, data.node.original.messageCount);
        App.navigate('mails/' + this.mailSecId + '/' + encodeURIComponent(data.node.id), {trigger: false});
      }, this)).on('loaded.jstree', _.bind(function() {
        this.ui.folders.jstree('select_node', this.folder);
      }, this)).on('refresh.jstree', _.bind(function() {
        this.ui.folders.jstree('select_node', this.folder);
      }, this));
    },

    fetch: function (node, cb) {
      if(!this.mailSecId)
        cb([]);

      var params = {};
      params.folder = null;

      if(node.id !== '#')
        params.folder = node.id;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('mails:get-folders-url', this.mailSecId, params),
        success: _.bind(function (items) {
          var folders = [];

          _.each(items, _.bind(function(item) {

            var id = '';
            if(node.id !== '#')
              id = node.id + '/';
            id += item.name;

            var folder = {
              id: id,
              text: item.name,
              children: [],
              messageCount: item.messageCount
            };

            if(item.hasChildren)
              folder.children.push({ id: id + '/load', text: 'Loading...', children: [] });

            folders.push(folder);
          }, this));
          cb(folders);
        }, this)
      });
    },

    onMailChange: function() {
      this.mailSecId = this.mailsView.getSelected().get('id');
      this.options.listView.mailsView.setMailSecId(this.mailSecId);
      this.ui.folders.jstree('refresh');
      App.navigate('mails/' + this.mailSecId, {trigger: false});
    },

    onNewMail: function() {
      if(!this.mailSecId)
        return;
      var from = null;
      _.each(this.mailsCollection.models, function(model) {
        if(model.get('id') === this.mailSecId)
          from = model.get('value');
      }, this);

      if(!from)
        return;

      EmailView.prototype.open({
        from: from,
        allowFromSelection: true,
        title: '',
        id: 'new-mail',
        templateTypes: ['MAIL_SIGN'],
        defaultTemplate: 'MAIL_SIGN',
        templateData: {
          user: Settings.get('currentUser'),
          customer: { }
        },
        templateLocale: Settings.get('lang')
      });
    }
  });
});

