define('pimUtil',[
  'module',
  'settings',
  'underscore'
], function(
  module,
  Settings,
  _
) {
  'use strict';
 	
  var pimUtil = {
    getTranslation: function(arr) {
      var result = '';

      if(arr.length > 0)
          result = arr[0].value;

      _.each(arr, function (value, lang) { 
        if(lang.toUpperCase() === 'EN')
          result = value;
      });

      if(Settings.get('lang')) {
        _.each(arr, function (value, lang) {         
          if(lang.toUpperCase() === Settings.get('lang').toUpperCase())
            result = value;
        });
      }
      return result;
    }
  };

  module.exports = pimUtil;
});
