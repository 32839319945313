/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App
) {
  'use strict';

  var ThankYouRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'thank_you': 'showThankYou'
    }
  });

  var API = {
    showThankYou: function(target) {
      require(['thankYouShowController'], function(ShowController) {
        App.startSubModule('thankYouMain');
        ShowController.showThankYou(target);
      });
    }
  };

  App.on('thank_you:show', function(target) {
    App.navigate('thank_you');
    API.showThankYou(target);
  });

  App.on('before:start', function() {
    new ThankYouRouter({
      controller: API
    });
  });

  module.exports = ThankYouRouter;
});
