
define('template!productTabsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="product-page">\n\n<div class="with-header">\n  <div class="tab-layout-header js-tab-layout-header">\n    <div class="tab-layout-info row">\n      <div class="logo-container col-left col-lg-2">\n        <!-- Logo -->\n        <div class="original-img-wrapper zoom-img">\n          <div class="main text-center">\n            <div class="logo-holder js-logo-holder"></div>\n          </div>\n        </div>\n      </div>\n      <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n        <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n        ';
 if (!design2) { ;
__p += '\n        <p class="p-code">' +
((__t = ( cCode )) == null ? '' : __t) +
'</p>\n        ';
 } else { ;
__p += '\n        <p class="p-code">' +
((__t = ( cCode )) == null ? '' : __t) +
' (' +
((__t = ( baseCode )) == null ? '' : __t) +
')</p>\n        ';
 } ;
__p += '\n      </div>\n      ';
 if (!design2) { ;
__p += '\n      <div id="order-header-extra-1" class="col-left name-holder">\n        <p>' +
((__t = ( _.i18n('product.code') )) == null ? '' : __t) +
': ' +
((__t = ( baseCode )) == null ? '' : __t) +
'</p>\n        <p>' +
((__t = ( _.i18n('offerList.productOwner') )) == null ? '' : __t) +
': ' +
((__t = ( manufacturerCode )) == null ? '' : __t) +
'</p>\n      </div>\n      ';
 } ;
__p += '\n      <div class="m-t-0 col-lg-4">\n        ';
 if (!design2) { ;
__p += '\n        ';
 if (outDated) { ;
__p += '\n        <i class="mdi-action-info-outline" style="color: red;" title="' +
((__t = ( _.i18n('productList.outDated') )) == null ? '' : __t) +
'"></i>\n        ';
 } ;
__p += '\n        <button class="btn btn-replace-product add-btn-text">\n          <span>' +
((__t = ( _.i18n('products.replace') )) == null ? '' : __t) +
'</span>\n        </button>\n        ';
 if (displaySkipCompuzzData) { ;
__p += '\n        <button class="btn add-btn-text btn-use-compuzz-data" style="' +
((__t = ( (!skipCompuzzData?'display: none;':'') )) == null ? '' : __t) +
'">Use\n          compuzz data\n        </button>\n        <button class="btn add-btn-text btn-skip-compuzz-data" style="' +
((__t = ( (skipCompuzzData?'display: none;':'') )) == null ? '' : __t) +
'">Skip\n          compuzz data\n        </button>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </div>\n      <span class="back-to-list js-back-to-list p-r-15"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n      ';
 if (!design2) { ;
__p += '\n      <i class="mdi-action-assignment-turned-in create-task f-r p-t-10"></i>\n      ';
 } ;
__p += '\n    </div>\n    <div class="row warning-skip-compuzz-data" style="height: 30px; background-color: orange; ' +
((__t = ( (!skipCompuzzData?'display: none;':'') )) == null ? '' : __t) +
'">\n      <label class="col-xs-12">This product doesn\'t use compuzz data</label>\n    </div>\n      <div class="navbar navbar-default navbar-md row">\n        <div class="navbar-collapse collapse">\n          <ul class="nav navbar-nav">\n            ';
 _.each(tabs, function (tab) { ;
__p += ' \n              <li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a href="#product/' +
((__t = ( secId )) == null ? '' : __t) +
'/' +
((__t = ( tab.name )) == null ? '' : __t) +
'">' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n            ';
 }) ;
__p += '\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n<div class="tab-layout-content js-tabs-region height-100"></div>\n';
 if (displayNextPrevious) { ;
__p += ' \n  <div class="clickable previous-product" title="ctrl+p"\n       style="position: absolute; top:115px; bottom: 0px; left:0px; width: 30px; display: flex; justify-content: center; align-items: center;">\n    <i class="mdi-hardware-keyboard-arrow-left" style="font-size: 60px;"></i>\n  </div>\n<div class="clickable next-product" title="ctrl+n"\n     style="position: absolute; top:115px; bottom: 0px; right:0px; width: 30px; display: flex; justify-content: center; align-items: center;">\n  <i class="mdi-hardware-keyboard-arrow-right" style="font-size: 60px;"></i>\n</div>\n';
 } ;
__p += '\n</div>';

}
return __p
};});

