/**
 * Created by OLD on 28/09/2015.
 */
define('timetrackerMain',[
  'module',
  'logger',
  'timetrackerRouter'
], function(
  module,
  Logger
) {
  'use strict';
	
  var Timetracker = {
    start: function() {
      Logger.log(module, 'starting TimetrackerModule');
    },

    stop: function() {
      Logger.log(module, 'stopping TimetrackerModule');
    }
  };

  module.exports = Timetracker;
});
