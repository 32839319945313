/**
 * Created by BFR on 05/03/2020.
 */
define('productFilterTabController',[
  'module',
  'underscore',
  'app',
  'productFilterTabView',
  'entities/product',
  'entities/product_filter'
], function (
  module,
  _,
  App,
  View
) {

  'use strict';
  var ProductFiltersController = {

    showDetails: function (region, product) {
      this.model = product;

      var productFilters = App.request('products-filter:collection');
      productFilters.fetch(product.get('secId')).done(_.bind(function () {
        App.request('products-filter:get-filter-types', {secId: product.get('secId')}).then(function (productFilterTypes) {
          this.view = new View({
            collection: productFilters,
            productFilterTypes: productFilterTypes,
            product: product
          });
          region.show(this.view);
        });
      }, this));
    }
  };

  module.exports = ProductFiltersController;
});
