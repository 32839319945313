define('pimTabsLayoutController',[
  'module',
  'app',
  'underscore',
  'pimTabsLayoutView',
  'jquery',
  'entities/pim_product'
], function (
  module,
  App,
  _,
  PimTabsLayoutView,
  $
) {
  'use strict';

  var PimTabsLayoutController = {
    _layoutView: undefined,
   
    setProduct: function(id) {

      var defer = $.Deferred(); 
     
      if(!this.pimProduct || this.pimProduct.get('id') !== id) {
        this.pimProduct = App.request('pim:products:model', {id: id}); 
        this.pimProduct.setCurrentResProduct(null);
        this.pimProduct.fetch().done(_.bind(function()
        {       
          defer.resolve();   
        }, this));  
      } 
      else
        defer.resolve();  

      return defer.promise();
    },
    setResProduct: function(resId) {      
      if(this.pimProduct)   
        this.pimProduct.setCurrentResProduct(resId);       
    },
    showLayout: function (tabName) {
      var defer = $.Deferred();    
         
      this._layoutView = new PimTabsLayoutView({model: this.pimProduct});
      App.regions.getRegion('main').currentView.getRegion('content').show(this._layoutView);
      this._layoutView.triggerMethod('show:active', tabName);

      defer.resolve(this._layoutView.getRegion('tabs'), this.pimProduct);
     
      return defer.promise();
    }
  };

  module.exports = PimTabsLayoutController;
});
