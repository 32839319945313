define('competitorCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!competitorCreateEditLayoutTpl'
], function(
  module,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  competitorCreateEditLayoutTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: competitorCreateEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel',
      'click .bootbox-close-button': 'onCancel'
    },

    modelEvents: {
      sync: 'onSave'
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
    },

    onConfirm: function() {
      this.model.save();
    },

    onEscape: function() {
      if (!this.newItem) {
        this.model.fetch();
        this.model = null;
      }
    },

    onCancel: function() {
      if (!this.newItem) {
        this.model.fetch();
        this.model = null;
      }
      this.hide();
    },

    onSave: function() {
      this.triggerMethod('saved');
      if (this.options.newItem) {
        this.collection.add(this.model);
        this.model = null;
      } else {
        this.model = null;
      }
      this.hide();
    }
  });
});
