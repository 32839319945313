
define('template!ordersDetailOrderStatusTrackingsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="empty">-</div>\n<div class="trackingContainer"></div>';

}
return __p
};});

