define('userSelectorView',[
  'app',
  'module',
  'backbone',
  'modalsConfirmationView',
  'underscore',
  'settings',
  'buttonView',
  'userSelectorSelectedUsersView',
  'userSelectorUsersView',
  'template!userSelectorView'
], function (
  App,
  Module,
  Backbone,
  ModalsConfirmationView,
  _,
  Settings,
  ButtonView,
  SelectedUsersView,
  UsersView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'user-selector-view ' + ModalsConfirmationView.prototype.className,

    regions: {
      selectedUsersRegion: '.selected-users-region',
      usersRegion: '.users-region',
      assignToMeRegion: '.assign-to-me-region'
    },

    initialize: function () {
      this.multiselect = true;
      if(this.options.multiselect === false)
        this.multiselect = false;

      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },
    
    ui: _.extend({
      selector: '.selector input'
    }, ModalsConfirmationView.prototype.ui),
    
    events: {
      'keydown @ui.selector': function () {
        this.usersView.update(this.ui.selector.val());
      }
    },

    onShow: function () {
      this.selectedUsersView = new SelectedUsersView({users: this.options.users});
      this.getRegion('selectedUsersRegion').show(this.selectedUsersView);
      this.selectedUsersView.on('onUnAssign', this.onUnSelect, this);

      //----------------
      this.usersView = new UsersView({});
      this.getRegion('usersRegion').show(this.usersView);     
      this.usersView.on('onAssign', this.onSelect, this);

      //----------------
      this.assignToMeButtonView = new ButtonView({
        type: 'add-user',
        text: _.i18n('common.assignToMe'),
        title: _.i18n('common.assignToMe')
      });

      this.assignToMeRegion.show(this.assignToMeButtonView);
      this.assignToMeButtonView.on('click', _.bind(this.onAssignToMe, this));    },

    onAssignToMe: function () {
      var currentUser =  Settings.get('currentUser');
      if (!currentUser) return;

      var user = new Backbone.Model(currentUser);

      var found = this.selectedUsersView.collection.find(function(model){
        return model.get('secId') === user.get('secId');
      });

      if (found) return;

      if(!this.multiselect)
        this.selectedUsersView.collection.reset();

      if(this.options.onSelected) {
        this.options.onSelected(user).done(_.bind(function () {
          this.selectedUsersView.collection.push(user);
        }, this));
      } else
        this.selectedUsersView.collection.push(user);
    },

    onSelect: function (user) {
      var found = this.selectedUsersView.collection.find(function(model){
        return model.get('secId') === user.get('secId');
      });

      if (found) return;

      if(!this.multiselect)
        this.selectedUsersView.collection.reset();

      if(this.options.onSelected) {
        this.options.onSelected(user).done(_.bind(function () {
          this.selectedUsersView.collection.push(user);
        }, this));
      } else
        this.selectedUsersView.collection.push(user);
    },

    onUnSelect: function (user) {
      if(this.options.onUnSelected) {
        this.options.onUnSelected(user).done(_.bind(function () {
          this.selectedUsersView.collection.remove(user);
        }, this));
      }
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'userSelectorView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: 'Select Users',
          modal: true,
          width: 800
        });
      });
    }
  });
});

