define('ordersListFiltersLabelView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersListFiltersLabelView'
], function (
  Module, 
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-list-filters-label-view',

    events: {
      'click': 'onClick'
    },

    modelEvents: {
      'change': 'onRender'
    },

    onRender: function () {
      if(this.model.get('color')) {
        this.$el.css('background-color', this.model.get('color'));
        this.$el.attr('title', this.model.get('name'));
      }
    }
  });
});
