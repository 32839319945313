define('entities/endUserInvoice',[
    'app',
    'backbone',
    'settings'
], function (
    App,
    Backbone,
    Settings
) {
    'use strict';

    var EndUserInvoice = Backbone.Model.extend({

        idAttribute: 'secId',

        fetch: function() {
            this.url = Settings.url('compuzz2', 'commands.json/' + undefined + '/invoice/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        }
    });

    App.reqres.setHandler('endUserInvoice:model', function (data) {
        return new EndUserInvoice(data);
    });
});

