
define('template!shareProductsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 text-center count-container">\n\n</div>\n<div class="col-xs-12 products-view" style="height: 300px; max-height: 300px; overflow: scroll;">\n\n</div>\n<div class="loading-block js-loading-block loader" style="top: 10px;"></div>\n<div class="row">\n    <div class="pull-right p-r-15">\n        <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</button>\n    </div>\n</div>';

}
return __p
};});

