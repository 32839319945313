/**
 * Created by RKL on 13/08/2015.
 */
define('caccountObjectNamesTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'validate',
  'bootbox',
  'caccountObjectNamesTabView',
  'caccountObjectNamesAddView',
  'settings',
  'entities/caccounts/objectnames'
], function(
  module,
  _,
  Backbone,
  App,
  validateUtils,
  bootbox,
  View,
  PopupView
) {
  'use strict';

  var CAccountsObjectNamesTabController = {
    showTab: function(data) {
      var view = new View();

      this.view = view;
      this.cAccountModel = data.model;
      this.cAccountId = data.cAccountId;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onCreateItem', _.bind(this.onCreateItem, this));
      view.listenTo(view, 'onDeleteItem', _.bind(this.onDeleteItem, this));
      view.listenTo(view, 'onEditItem', _.bind(this.onEditItem, this));

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('caccountObjectNamesTab').show(view);
    },
    onGridLoaded: function() {
      var data = {
          url: App.request('caccount-objnames:get-url', this.cAccountId),
          rowCountUrl: App.request(
            'caccount-objnames:row-count-url',
            this.cAccountId
          )
        };

      this.view.displayGrid(data, true);
    },

    onCreateItem: function() {
      var model = App.request('caccount-objnames:get-model');

      this.onDisplayPopup(model);
    },

    onEditItem: function(rowId) {
      var that = this,
        data = {},
        getItemRequest,
        model;

      data.cAccountId = this.cAccountId;
      data.secId = rowId;
      getItemRequest = App.request('caccount-objnames:get-item', data);

      $.when(getItemRequest).done(function(resp) {
        model = new Backbone.Model(resp);
        that.onDisplayPopup(model);
      });
    },

    onDisplayPopup: function(model) {
      var that = this;

      this.popupView = new PopupView({model: model});
      this.popupView.show({title: _.i18n('popup.objectName.details'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function() { return true; }
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              return that.onConfirmPopup(that.popupView.model);
            }
          }
        }
      });
    },

    onConfirmPopup: function(model) {
      var that =this,
        data = {},
        postRequest;

      if (!this.validate()) { return false; }

      model.get('refCAccountJson').secId = this.cAccountId;
      data.model = model;
      data.cAccountId = this.cAccountId;
      postRequest = App.request('caccount-objnames:post-item', data);

      $.when(postRequest).done(function() {
        that.view.updatePagination(true);
      });
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'name', type: 'required'},
        {name: 'code', type: 'required'}
      ];

      return this.popupView.validate(fieldsToValidate);
    },

    onDeleteItem: function(itemId) {
      var that = this,
        data = {},
        deleteRequest;

      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          data.cAccountId = that.cAccountId;
          data.itemId = itemId;
          deleteRequest = App.request('caccount-objnames:delete-item', data);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination(true);
          });
        }
      });
    }
  };

  module.exports = CAccountsObjectNamesTabController;
});
