define('customersPopupSelectView',[
  'module',
  'app',
  'jqgridView',
  'underscore', 
  'windows',
  'dialogView',
  'settings',
  'jquery',
  'customersPopupSelectResultsView',
  'customersPopupEditView',
  'template!customersPopupSelectView',
  'entities/customers/customers'
], function (
  Module,
  App,
  JqGridView,
  _,
  Windows,
  DialogView,
  Settings,
  $,
  CustomersPopupSelectResultsView,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = DialogView.extend({
    template: Tpl,
    className: 'select-customer-view', 

    ui: {
      searchInput: '.search-input',
      searchButton: '.search-button',
      confirmButton: '.js-confirm',
      newCustomer: '.new-customer'
    },

    events: {
      'change @ui.searchInput': 'onSearch',
      'click @ui.searchButton': 'onSearch',
      'click .js-cancel-popup': 'onCancelClick',
      'click @ui.confirmButton': 'onConfirmClick',
      'click @ui.newCustomer': 'onNewCustomerClick'
    },

    regions: {
      resultsRegion: '.results-region'
    },

    initialize: function () {
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
      this.windowClickHandler = _.bind(this.onWindowClick, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
      $(document).on('click', this.windowClickHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape')
        if(this.resultsView) {
          this.resultsView.destroy();
          this.resultsView = undefined;
        }          
    },

    onWindowClick: function(event) {  
      if(this.resultsView) {
        if (!event.target.closest(this.resultsView.className) &&
        !event.target.closest('.search-input') &&
        !event.target.closest('.search-button')) 
        {
          this.resultsView.destroy();
          this.resultsView = undefined;
        }      
      }    
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
      $(document).off('click', this.windowClickHandler);
    },

    onSearch: function () { 
      this.resultsView = new CustomersPopupSelectResultsView({
        query: this.ui.searchInput.val(),
        onUserSelected: this.options.onUserSelected
      });
      this.getRegion('resultsRegion').show(this.resultsView);      
    },

    onCancelClick: function () {
      this.hide();
    },   

    onConfirmClick: function () {
      var autoCompleteVal = this.getRegion('customerAutoComplete').currentView.ui.autocomplete.val();
      if (!this.selectedCustomer || this.selectedCustomer.get('code') !== autoCompleteVal) {
        if (autoCompleteVal) {
          this.trigger('customer-selected', App.request('customer:model', {
            code: autoCompleteVal,
            endUserId: autoCompleteVal
          }));
          this.trigger('onSave');
          this.hide();
        }
      } else {
        this.trigger('customer-selected', this.selectedCustomer);
        this.trigger('onSave');
        this.hide();
      }
    },  

    onNewCustomerClick: function() {
      App.request('caccount:countries').done(_.bind(function(countries) {
        this.hide();
        Windows.open({
          view: _.bind(function () {
            return new EditView({
              customerModel: App.request('customer:model'),
              newItem: true,
              countries: countries.toJSON(),
              onUserSelected: this.options.onUserSelected
            });
          }, this),
          id: 'customer:create',
          title: _.i18n('customers.new-customer'),
          configName: 'customerEdit'
        });
      }, this));    
    }  
  });
});
