/**
 * Created by OLD on 12/10/2015.
 */
define('timetrackerFiltersView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'dateUtils',
  'template!timetrackerFiltersTpl',
  'settings',
  'moment',
  'jqueryui'
], function(
  module,
  Marionette,
  App,
  _,
  dateUtils,
  viewTpl,
  settings,
  moment
) {
  'use strict';

  var TimetrackerFilters =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      dateFrom: '.date-from',
      dateTo: '.date-to',
      date: '.date-timetracker',
      userSpan: '.filter-by-user-tt',
      userItem: 'li.user-filter-item',
      typeItem: 'li.item-type-tt',
      typeSpan: '.filter-by-type-tt',
      usersDropdown: '.users-dropdown-data',
      caret: '.caret.user-span',
      close: 'span.mdi-navigation-close',
      userChooser: '.user-chooser',
      dateToForm: '.date-to-form',
      validationLabel: '.date-validation-label'
    },

    events: {
      'click @ui.userItem' : 'onFilterUser',
      'click @ui.typeItem' : 'onFilterType',
      'focus @ui.dateTo' : 'clearValidation'
    },

    serializeData: function() {
      var templateData = {};

      _.each(this.options.userList, function (list) {
        list.fileUrl = settings.url('file', list.logo, {size: 'thumbnail'});
      });
      templateData.users = this.options.userList;
      templateData.dateFrom = moment(parseInt(this.options.filters.dateFrom, 10)).format('DD/MM/YYYY');
      templateData.dateTo = moment(parseInt(this.options.filters.dateTo, 10)).format('DD/MM/YYYY');

      return templateData;
    },

    clearValidation: function() {
      this.ui.dateToForm.removeClass('has-error');
      this.ui.validationLabel.text('');
    },

    onValidate: function() {
      var dFrom = parseInt(moment(this.ui.dateFrom.val(), 'DD/MM/YYYY').format('x'), 10),
        dTo = parseInt(moment(this.ui.dateTo.val(), 'DD/MM/YYYY').format('x'), 10);

      if (dTo - dFrom <= 0) {
        this.ui.dateToForm.addClass('has-error');
        this.ui.validationLabel.text(_.i18n('common.FromDateLessToDate'));
      } else {
        this.clearValidation();
        this.setDateData();
      }
    },

    setDateData: function() {
      this.filters.dateFrom = parseInt(moment(this.ui.dateFrom.val(), 'DD/MM/YYYY').startOf('day').format('x'), 10);
      this.filters.dateTo = parseInt(moment(this.ui.dateTo.val(), 'DD/MM/YYYY').endOf('day').format('x'), 10);
      this.filters.logonId = this.ui.userSpan.attr('data-logonId') === 'all' ? 
        null : this.ui.userSpan.attr('data-logonId');
      this.filters.type = this.ui.typeSpan.attr('data-type');
      this.onSendRequest();
    },

    onSendRequest: function() {
      this.trigger('timetracking:change-date', this.filters);
    },

    onFilterUser: function(e) {
      var logonId = $(e.currentTarget).attr('data-logonId'),
        user = $(e.currentTarget).text();

      this.ui.userSpan.html(user);
      this.ui.userSpan.attr('data-logonId', logonId);
      this.onValidate();
    },

    onFilterType: function(e) {
      var type = $(e.currentTarget).attr('data-type'),
        typeVal = $(e.currentTarget).text();

      this.ui.typeSpan.html(typeVal);
      this.ui.typeSpan.attr('data-type', type);      
      this.onValidate();
    },

    onRender: function() {
      var that = this;

      moment.locale(settings.get('lang'));
      $.material.init();
      this.filters = this.options.filters;

      this.ui.date.datepicker({
        autoSize: true,
        dateFormat: 'dd/mm/yy',
        dayNamesMin: moment.weekdaysShort(),
        firstDay: 1,
        maxDate: 0,
        prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
        nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>',
        beforeShow: function() {
          $('#ui-datepicker-div')
            .removeClass('tt-datepicker');
          $('#ui-datepicker-div')
            .addClass('tt-datepicker');
        },
        onSelect: function() {
          that.onValidate();
        }
      });
      this.ui.dateFrom.datepicker({
        setDate: this.options.filters.dateFrom
      });
    }
  });

  module.exports = TimetrackerFilters;
});
