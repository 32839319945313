define('headerTasksListView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'headerTaskView',
  'template!headerTasksListView'
], function(
  App,
  module,
  Backbone,
  Marionette,
  _,
  TaskView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'header-tasks-list-view',
    childView: TaskView,
    childViewContainer: '.tasks-container',
    collection: new Backbone.Collection(),

    childEvents: {      
      close: function () {
        this.close();
      },
      doneChange: function (view, value) {
        this.options.doneChange(value);
      }
    },

    ui: {
      showAll: '.show-all'
    },

    events: {
      'click @ui.showAll': 'onShowAll'
    },

    initialize: function () {
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
      this.windowClickHandler = _.bind(this.onWindowClick, this);

      this.first = 0;
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
      $(document).on('click', this.windowClickHandler);

      this.observer = new window.IntersectionObserver(_.bind(function(aEntries) {
        if(aEntries.length > 0 && aEntries[0].isIntersecting)
          this.fetch();
      }, this), {
        root: this.$el[0],
        rootMargin: '30px',
        threshold: 0.5
      });
    },

    onDestroy: function() {    
      $(document).off('keydown', this.windowKeydownHandler);
      $(document).off('click', this.windowClickHandler);
    },

    fetch: function () {
      var defer = $.Deferred();
      App.request('tasks:open:entities', this.first, 10).done(_.bind(function (openTasks) {
        this.collection.push(openTasks.models);
        this.first = this.collection.length;

        if(openTasks.models.length > 0) {
          var view = this.children.last();

          if(view)
            this.observer.observe(view.$el[0]);
        }

        defer.resolve(openTasks.models);
      }, this));
      return defer.promise();
    },

    onShowAll: function () {
      var baseURL = window.location.protocol + '//' + window.location.hostname;
      if (window.location.port) {
        baseURL += ':' + window.location.port;
      }
      var url = baseURL + '#tasks';
      window.open(url);

      //App.navigate('tasks', {trigger: true});
      this.close(); 
    },

    open: function () {
      var defer = $.Deferred();

      if(!this.$el.is(':visible')) {
        this.fetch().done(_.bind(function () {
          if(this.collection.length > 0)
            this.$el.css('display', 'flex');
          defer.resolve();
        }, this));
      } else {
        defer.resolve();
      }

      return defer.promise();
    },

    close: function () {
      if(!this.$el.is(':visible'))
        return;

      this.collection.reset();
      this.first = 0;
      this.$el.hide();
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape')
        this.close();    
    },

    onWindowClick: function(event) {       
      if (!event.target.closest('.header-tasks-list-view'))
        this.close(); 
    } 
  });
});
