
define('template!customersDetailBodyProfileInfosView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <div class="title">' +
((__t = ( _.i18n('orders.detail.client-title') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n    <div class="column">\n        <div class="code item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( endUserId ? endUserId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="email item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( email )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="invoice-mail item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.invoiceEmail') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( invoiceEmail )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="phone item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( phone )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="mobile-phone item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.mobilePhone') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( mobile )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="client-code item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                </div>\n            </div>\n            <div class="value">' +
((__t = ( clientCode )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 if(showFirstContactPhone) { ;
__p += '\n            <div class="first-contact-phone item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('endUser.firstContactPhone') )) == null ? '' : __t) +
'</div>\n                    <div class="tools">\n                        <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                    </div>\n                </div>\n                <div class="value">' +
((__t = ( firstContactPhone )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if(showFirstContactVoiceMail) { ;
__p += '\n            <div class="first-contact-voice-mail item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('endUser.firstContactVoiceMail') )) == null ? '' : __t) +
'</div>\n                    <div class="tools">\n                        <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                    </div>\n                </div>\n                <div class="value">' +
((__t = ( firstContactVoiceMail )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        <div class="language item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('endUsersDetail.language') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( locales )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="create-date item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.date.create') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( creatDate )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n    <div class="column">\n        ';
 if(showCompanyNumber) { ;
__p += '\n            <div class="company-number item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('endUsersDetail.companyNumber') )) == null ? '' : __t) +
'</div>\n                    <div class="tools">\n                        <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                    </div>\n                </div>\n                <div class="value">' +
((__t = ( companyNumber )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        <div class="vat item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.vatNumber') )) == null ? '' : __t) +
'</div>\n                <div class="tools">\n                    <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                    <svg class="check-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>\n                </div>\n            </div>\n            <div class="value">\n                <div class="vat-number">' +
((__t = ( vatNumber )) == null ? '' : __t) +
'</div>\n                <div class="icon status"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#EFEFEF" d="M208 48c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM152 352V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l44.9 74.7c-16.1 17.6-28.6 38.5-36.6 61.5c-1.9-1.8-3.5-3.9-4.9-6.3L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152zm424 16c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zm-76.7-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"/></svg></div>\n            </div>\n        </div>\n        ';
 if(showKvk) { ;
__p += '\n            <div class="kvk item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('common.kvk') )) == null ? '' : __t) +
'</div>\n                    <div class="tools">\n                        <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                    </div>\n                </div>\n                <div class="value">' +
((__t = ( siret )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if(showSiret) { ;
__p += '\n            <div class="siret item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'</div>\n                    <div class="tools">\n                        <svg class="copy-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n                        <div class="to-vat-button" title="' +
((__t = ( _.i18n('common.siret-to-vat.title') )) == null ? '' : __t) +
'">' +
((__t = ( _.i18n('common.siret-to-vat') )) == null ? '' : __t) +
'</div>\n                    </div>\n                </div>\n                <div class="value">' +
((__t = ( siret )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if(showMainActivity) { ;
__p += '\n            <div class="main-activity item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('endUsersDetail.mainActivity') )) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="value">' +
((__t = ( mainActivity )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if(showSubActivity) { ;
__p += '\n            <div class="sub-activity item">\n                <div class="libelle">\n                    <div class="text">' +
((__t = ( _.i18n('endUsersDetail.subActivity') )) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="value">' +
((__t = ( subActivity )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n        <div class="emplyees-number item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('endUsersDetail.nbrEmployees') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( nbrEmployees )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 if (webshop) { ;
__p += '\n        <div class="webshop item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.webshop') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( webshop )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 } ;
__p += '\n\n        <div class="vip item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.vip') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( vip )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n    <div class="column">\n        <div class="user-comment item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="login item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('endUsersDetail.login') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( login )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="website item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( webSite )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="paymentTerms item">\n            <div class="libelle">\n                <div class="text">' +
((__t = ( _.i18n('orders.paymentDelay') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="value">' +
((__t = ( paymentTerms )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

