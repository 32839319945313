
define('template!pimTabsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pim-page">\n  <div class="with-header">\n    <div class="tab-layout-header js-tab-layout-header">\n      <div class="tab-layout-info row">\n        <div class="logo-container col-left col-lg-2">\n          <!-- Logo -->\n          <div class="original-img-wrapper zoom-img">\n            <div class="main text-center">\n              <div class="logo-holder js-logo-holder"></div>\n            </div>\n          </div>\n        </div>\n        <div class="name-holder js-name-holder col-lg-3">\n          <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n          <p class="p-code reseller-product-codes"></p>\n        </div>\n        <div class="col-lg-2">\n          <div class="supplier-product-code">\n            <div class="libelle">' +
((__t = ( _.i18n('product.code') )) == null ? '' : __t) +
':</div>\n            <div class="value">' +
((__t = ( data.code )) == null ? '' : __t) +
'</div>\n          </div>\n          <div class="supplier-name">\n            <div class="libelle">' +
((__t = ( _.i18n('offerList.productOwner') )) == null ? '' : __t) +
':</div>\n            <div class="value">' +
((__t = ( supplier )) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n        <div class="col-lg-3">\n          <div class="status-code">\n            <div class="libelle">' +
((__t = ( _.i18n('product.status') )) == null ? '' : __t) +
':</div>\n            <div class="value">' +
((__t = ( status )) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n\n        <div class="m-t-0 col-lg-2"></div>\n        <span class="back-to-list js-back-to-list p-r-15"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n\n      </div>\n      <div class="navbar navbar-default navbar-md row">\n        <div class="navbar-collapse collapse">\n          <ul class="nav navbar-nav">\n            ';
 _.each(tabs, function (tab) { ;
__p += '\n            ';

            var disabled = '';
            if(resProduct === null && tab.name === 'prices') {
            var disabled = 'disabled';
            }
            ;
__p += '\n            <li class="js-tab-title tab-layout-tab js-tab-layout-tab ' +
((__t = ( disabled )) == null ? '' : __t) +
' js-' +
((__t = ( tab.name )) == null ? '' : __t) +
'"\n                data-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a href="javascript:;">' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n            ';
 }) ;
__p += '\n          </ul>\n          ';
 if (displayNextPrevious) { ;
__p += '\n          <div class="nav-buttons">\n            <div class="clickable previous-product" title="ctrl+p">\n              <i class="mdi-hardware-keyboard-arrow-left"></i>\n            </div>\n            <div class="clickable next-product" title="ctrl+n">\n              <i class="mdi-hardware-keyboard-arrow-right"></i>\n            </div>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="tab-layout-content js-tabs-region height-100"></div>\n</div>';

}
return __p
};});

