
define('template!headerView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="account-region"></div> \n<div class="languages-region"></div> \n<div class="search-region"></div>\n<div class="compuzz-region"></div>\n<div class="windows-tools-region"></div>\n<div class="phone-region"></div>\n\n';
 if (displayNotifications) { ;
__p += '\n  <div class="notifications-region"></div>\n';
 } ;
__p += '\n\n';
 if (displayTasks) { ;
__p += '\n  <div class="tasks-region"></div>\n';
 } ;
__p += '\n\n<div class="user-region"></div>';

}
return __p
};});

