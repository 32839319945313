
define('template!productImprintTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '    <div class="col-xs-5">\n        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n            ' +
((__t = ( index )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n            <input type="checkbox" class="cbx-enable-pz" ' +
((__t = ( activated?'checked':'' )) == null ? '' : __t) +
' />\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ' +
((__t = ( pa2.name )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ' +
((__t = ( pz2.name )) == null ? '' : __t) +
' (' +
((__t = ( pz2.width )) == null ? '' : __t) +
'X' +
((__t = ( pz2.height )) == null ? '' : __t) +
'mm)\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n          ' +
((__t = ( pz2.printerName )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n            ' +
((__t = ( pz2.maxColors )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0 custom-price prices-editable ';
 if (useFactorV2) { ;
__p += 'col-xs-offset-1';
 } ;
__p += '">\n          ';
 if (currentPzPrice.length > 0) { ;
__p += '\n            ';
 if (currentPzPrice[0].imptSetupPrice) { ;
__p += '\n                €' +
((__t = ( currentPzPrice[0].imptSetupPrice )) == null ? '' : __t) +
'\n            ';
 } else if (customPrice) { ;
__p += '\n                ';
 if (factorEditable) { ;
__p += '\n                    <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'"  data-index="0" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'" />\n            ';
 }  ;
__p += '\n                <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="0">€' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'</span>\n\n            ';
 if (factorEditable) { ;
__p += '\n            <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'"  data-index="0" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( currentPzPrice[0].setUpPrice * setupFactor)) == null ? '' : __t) +
'" />\n            ';
 }  ;
__p += '\n            <span class="' +
((__t = ( factorEditable?'lbl-factorized-price':'' )) == null ? '' : __t) +
'" data-index="0">€' +
((__t = ( currentPzPrice[0].setUpPrice * setupFactor )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n          ';
 } ;
__p += '\n        </div>\n      ';
 if (!useFactorV2) { ;
__p += '\n        <div class="col-xs-1 p-l-0 p-r-0 prices-editable">\n            ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n                ';
 if (factorEditable) { ;
__p += '\n                    <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].imptSetupPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].setupFactor )) == null ? '' : __t) +
'" data-index="0" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( Number(currentPzPrice[0].imptSetupPriceSale).toFixed(2) )) == null ? '' : __t) +
'" />\n                ';
 } ;
__p += '\n                <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="0">€' +
((__t = ( Number(currentPzPrice[0].imptSetupPriceSale).toFixed(2) )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n        </div>\n      ';
 } ;
__p += '\n        <div class="col-xs-1 p-l-0 p-r-0">\n          ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n            €' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'\n          ';
 } ;
__p += '\n        </div>\n      ';
 if (!useFactorV2) { ;
__p += '\n        <div class="col-xs-1 p-l-0 p-r-0 prices-editable">\n          ';
 if (currentPzPrice.length > 0) { ;
__p += '\n            ';
 if (!customPrice) { ;
__p += '\n              ';
 if (factorEditable) { ;
__p += '\n              <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n              ';
 } ;
__p += '\n              <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="1">€' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n            ';
 } else { ;
__p += '\n              ';
 if (factorEditable) { ;
__p += '\n              <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n              ';
 } ;
__p += '\n              <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="1" style="display:none">€' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n              ';
 if (factorEditable) { ;
__p += '\n              <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n              ';
 } ;
__p += '\n              <span class="' +
((__t = ( factorEditable?'lbl-factorized-price':'' )) == null ? '' : __t) +
'" data-index="1" style="display:none">€' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n          ';
 } ;
__p += '\n        </div>\n      ';
 } ;
__p += '\n    </div>\n        <!--<div class="col-xs-1 p-l-0 p-r-0">\n        </div>-->\n    </div>\n    <div class="col-xs-7">\n        <div class="col-xs-12">\n            ';
 var sizePercent = 100 / (currentPzPrice.length + 1); ;
__p += '\n            ';
 if (!customPrice) { ;
__p += '\n            ';
 _.each(currentPzPrice, function(impt, index) { ;
__p += '\n                <div class="col-xs-1 p-l-0 p-r-0" style="width: ' +
((__t = ( (sizePercent / (useFactorV2?1:2)) )) == null ? '' : __t) +
'%;">\n                    €' +
((__t = ( impt.imptUnitUnitPrice )) == null ? '' : __t) +
'\n                </div>\n                ';
 if (!useFactorV2) { ;
__p += '\n                      <div class="col-xs-1 p-l-0 prices-editable" style="width: ' +
((__t = ( (sizePercent / 2) )) == null ? '' : __t) +
'%;">\n                          ';
 if (factorEditable) { ;
__p += '\n                          <input type="text" class="txt-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.imptUnitUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( impt.imptUnitUnitPriceFactor )) == null ? '' : __t) +
'" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( impt.imptUnitUnitPriceSale )) == null ? '' : __t) +
'" />\n                          ';
 } ;
__p += '\n                          <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'">€' +
((__t = ( impt.imptUnitUnitPriceSale )) == null ? '' : __t) +
'</span>\n                      </div>\n                ';
 } ;
__p += '\n            ';
 }); ;
__p += '\n          ';
 if (!useFactorV2) { ;
__p += '\n            <div class="col-xs-1 p-l-0 p-r-0" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n                <select class="select-print-factor-code" ' +
((__t = ( factorEditable?'':'disabled' )) == null ? '' : __t) +
'>\n                    <option value=" "></option>\n                    ';
 _.each(printFactors, function(pf) { ;
__p += '\n                        <option value="' +
((__t = ( pf.printCode )) == null ? '' : __t) +
'" ' +
((__t = ( pf.printCode===pz2.factorCode?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( pf.printCode )) == null ? '' : __t) +
'</option>\n                    ';
 }); ;
__p += '\n                </select>\n              ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n                <i class="mdi-navigation-refresh btn-reset clickable" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'"></i>\n              ';
 } ;
__p += '\n            </div>\n          ';
 } ;
__p += '\n            ';
 }  else { ;
__p += '\n                ';
 _.each(currentPzPrice, function(impt, index) { ;
__p += '\n\n                <div class="col-xs-1 p-l-0 prices-editable" style="width: ' +
((__t = ( (sizePercent / 2) )) == null ? '' : __t) +
'%;">\n                    ';
 if (factorEditable) { ;
__p += '\n                    <input type="text" class="txt-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" data-factor="1" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" />\n                    ';
 } ;
__p += '\n                    <span class="' +
((__t = ( factorEditable?'lbl-price':'' )) == null ? '' : __t) +
'" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'">€' +
((__t = ( impt.price )) == null ? '' : __t) +
'</span>\n                </div>\n            <div class="col-xs-1 p-l-0 p-r-0 prices-editable" style="width: ' +
((__t = ( (sizePercent / 2) )) == null ? '' : __t) +
'%;">\n                ';
 if (factorEditable) { ;
__p += '\n                <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" data-factor="1" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( Number(impt.price * impt.factor).toFixed(2))) == null ? '' : __t) +
'" />\n                ';
 } ;
__p += '\n                <span class="' +
((__t = ( factorEditable?'lbl-factorized-price':'' )) == null ? '' : __t) +
'" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'">€' +
((__t = ( Number(impt.price * impt.factor).toFixed(2))) == null ? '' : __t) +
'</span>\n            </div>\n                ';
 }); ;
__p += '\n                ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n                <i class="mdi-navigation-refresh btn-reset clickable" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'"></i>\n                ';
 } ;
__p += '\n            ';
 } ;
__p += '\n        </div>\n    </div>';

}
return __p
};});

