/**
 * Created by RKL on 21/08/2015.
 */
define('productsMain',[
  'module',
  'logger',
  'productsMainRouter'
], function(
  module,
  Logger
) {
  'use strict';
	
  var Products = {
    start: function() {
      Logger.log(module, 'starting ProductsModule');
    },

    stop: function() {
      Logger.log(module, 'stopping ProductsModule');
    }
  };

  module.exports = Products;
});
