
define('template!invitationsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content invitations-page">\n    <section class="mp-referral-program js-referral-program">\n        <article class="referrals-content row">\n            <div class="col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-4 col-md-5 col-md-offset-5 col-lg-4 col-lg-offset-6">\n                <div class="row p-t-20">\n                    <h1>' +
((__t = ( _.i18n('invitations.title1') )) == null ? '' : __t) +
'</h1>\n                </div>\n                <div class="row">\n                    <small>' +
((__t = ( _.i18n('invitations.title2') )) == null ? '' : __t) +
'</small>\n                </div>\n                <div class="referral-invite row">\n                    <div class="row p-t-30 m-t-20">\n                        <a href="#" class="btn-import-contacts gmail">' +
((__t = ( _.i18n('invitations.inviteGmailContacts') )) == null ? '' : __t) +
'</a>\n                    </div>\n                    <div class="row p-t-15">\n                        <p class="or">' +
((__t = ( _.i18n('common.or') )) == null ? '' : __t) +
'</p>\n                    </div>\n                    <div class="row p-t-10">\n                        <form class="email-send js-email-inline cf">\n                            <input type="text" class="js-email-input" id="referral-direct-email" placeholder="' +
((__t = ( _.i18n('invitations.addEmails') )) == null ? '' : __t) +
'">\n                            <input type="submit" class="btn-lrg-standard sendButton" value="' +
((__t = ( _.i18n('common.send') )) == null ? '' : __t) +
'">\n                        </form>\n                    </div>\n                    <div class="email-preview row">\n                        <div class="col-sm-8 p-0">\n                            <p class="msg-text text-left">' +
((__t = ( _.i18n('invitations.separateEmailByComma') )) == null ? '' : __t) +
'</p>\n                        </div>\n                        <!--<div class="col-sm-4 p-0">\n                            <a href="#">' +
((__t = ( _.i18n('invitations.visualizeEmail') )) == null ? '' : __t) +
'<em> &#9658</em></a>\n                        </div>-->\n                    </div>\n                    <div class="invite-friends row">\n                        <div class="row">\n                            <p>' +
((__t = ( _.i18n('invitations.moreWaysToShare') )) == null ? '' : __t) +
'</p>\n                        </div>\n                        <div class="social-share-icons js-social-share-icons icons-large row">\n                            <a class="btn-share facebook hint--bottom" href="' +
((__t = ( facebookUrl )) == null ? '' : __t) +
'">Facebook</a>\n                            <a class="btn-share twitter hint--bottom" href="' +
((__t = ( twitterUrl )) == null ? '' : __t) +
'">Twitter</a>\n                            <a class="btn-share gplus hint--bottom" href="' +
((__t = ( googlePlusUrl )) == null ? '' : __t) +
'">Google+</a>\n                            <a class="btn-share linkedin hint--bottom" href="' +
((__t = ( linkedInUrl )) == null ? '' : __t) +
'">LinkedIn</a>\n                        </div>\n                    </div>\n                </div>\n                <div class="row">\n                    <p class="program-terms">' +
((__t = ( _.i18n('invitations.rewardText') )) == null ? '' : __t) +
'</p>\n                </div>\n                <div class="row p-t-10">\n                    <a href="#" class="btn-tac reward-conditions">' +
((__t = ( _.i18n('invitations.rewardConditions') )) == null ? '' : __t) +
'</a>\n                </div>\n            </div>\n        </article>\n    </section>\n    <div class="row">\n        <div class="col-lg-8 col-lg-offset-2">\n            <div class="row text-center">\n                <h1>' +
((__t = ( _.i18n('invitations.trackTitle1') )) == null ? '' : __t) +
'</h1>\n            </div>\n            <div class="row text-center">\n                <h3>' +
((__t = ( _.i18n('invitations.trackTitle2') )) == null ? '' : __t) +
'</h3>\n            </div>\n            <div class="row">\n                <div class="row">\n                    <div class="col-lg-6"><h3>' +
((__t = ( _.i18n('invitations.email') )) == null ? '' : __t) +
'</h3></div>\n                    <div class="col-lg-3 text-center"><h3>' +
((__t = ( _.i18n('invitations.status') )) == null ? '' : __t) +
'</h3></div>\n                    <div class="col-lg-3 text-center"><h3>' +
((__t = ( _.i18n('invitations.updated') )) == null ? '' : __t) +
'</h3></div>\n                </div>\n                <div class="row">\n                    <hr class="col-lg-12 m-t-5 m-b-5">\n                </div>\n            </div>\n            <div class="row invited-list">\n\n            </div>\n            <div class="row text-center p-b-30">\n                <a href="#" class="show-more">' +
((__t = ( _.i18n('invitations.showMore') )) == null ? '' : __t) +
'</a>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

