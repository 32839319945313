define('ordersDetailOrderClientCommentView',[
  'module',
  'marionette',
  'underscore',
  'dateUtils',
  'template!ordersDetailOrderClientCommentView'
], function(
  Module,
  Marionette,
  _,
  DateUtils,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({    
    className: 'comment-view',
    template: Tpl,

    serializeData: function () {
      var templateData = {};

      templateData.date = '';
      if(this.model.get('msgDate'))
        templateData.date = DateUtils.toMDYHHMMFormat(this.model.get('msgDate'));

      templateData.content = '-';
      if(this.model.get('content'))
        templateData.content = this.model.get('content').replace(/\n/g, '</br>');   
     
      return templateData;
    }
  });   
});
