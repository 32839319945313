
define('template!mailsMenuView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="new-mail-region"></div>\n<div class="mails-region"></div>\n<div class="folders"></div>';

}
return __p
};});

