/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintZoneView',[
    'module',
    'marionette',
    'app',
    'jquery',
    'underscore',
    'template!sharePrintZoneTpl',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    $,
    _,
    viewTpl
  ) {
    'use strict';
  
    var SharePrintZoneView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
      },

      onShow: function () {
      },

      events: {
        'change .cbx-printzone': 'onSelectionChange',
        'click .btn-delete-printzone': 'onBtnDeleteClick'
      },

      onBtnDeleteClick: function() {
        this.model.destroy({wait: true});
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          this.model.set('printZone', { });
        } else {
          this.model.set('printZone', null);
        }
      },

      findCheckedPrintZone: function(code) {
        return _.findWhere(this.options.model.collection.checkedPrintZones, {
          imppCode: code
        });
      },

      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.printZone?true:false;
        templateData.differentPrinter = false;
        if (templateData.printZone && templateData.printZone.name) {
          templateData.name = templateData.printZone.name;
        } else if (templateData.parentPrintZone) {
          templateData.name = templateData.parentPrintZone.name;
        } else {
          templateData.name = '';
        }
        if (templateData.parentPrintZone) {
          templateData.width = templateData.parentPrintZone.width;
          templateData.height = templateData.parentPrintZone.height;
          templateData.printerName = templateData.parentPrintZone.printerName;
        } else {
          templateData.width = templateData.printZone.width;
          templateData.height = templateData.printZone.height;
          templateData.printerName = templateData.printZone.printerName;
        }

        if (this.options.model.collection.displayNew &&
            templateData.parentPrintZone &&
            !this.findCheckedPrintZone(templateData.parentPrintZone.imppCode)) {
          templateData.newPz = true;
        } else {
          templateData.newPz = false;
        }

        return templateData;
      }
    });
  
    module.exports = SharePrintZoneView;
  });
