/**
 * Created by BFR on 02/03/2020.
 */
define('filterView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!filterTpl',
    'filterValuesView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    FilterValuesView
  ) {
    'use strict';
  
    var FilterView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        filterValues: '.filter-values'
      },

      events: {
      },

      onShow: function () {
        this.filterValuesView = new FilterValuesView({ collection: this.model.get('filtersValues')});
        this.filterValuesView.listenTo(this.filterValuesView, 'item:selection:change', _.bind(this.onItemSelectionChange, this));
        this.getRegion('filterValues').show(this.filterValuesView);
      },

      onItemSelectionChange: function() {
        this.trigger('item:selection:change');
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = FilterView;
  });
