
define('template!caccountConfigGmailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (connectedUser) { ;
__p += '\n' +
((__t = ( _.i18n('common.connectedWithUser') )) == null ? '' : __t) +
': ' +
((__t = ( connectedUser )) == null ? '' : __t) +
'\n';
 } else { ;
__p += '\n' +
((__t = ( _.i18n('common.disconnected') )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n<div class="row">\n  ';
 if (connectedUser) { ;
__p += '\n  <button class="m-l-15 btn btn-primary btn-disconnect-gmail">' +
((__t = ( _.i18n('common.disconnect') )) == null ? '' : __t) +
'</button>\n  ';
 } else { ;
__p += '\n  <button class="m-l-15 btn btn-primary btn-connect-gmail">' +
((__t = ( _.i18n('common.connect') )) == null ? '' : __t) +
'</button>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

