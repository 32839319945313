/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowController',[
  'module',
  'underscore',
  'app',
  'sidebarShowView',
  'settings'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';
  var SidebarController = {
    showSidebar: function() {
      var view = new View();

      view.listenTo(view, 'sidebar:resize', this.onResizeSidebar);
      App.regions.getRegion('main').currentView.getRegion('sidebar').show(view);
    },

    setActiveNavItem: function(name) {
      var view = App.regions.getRegion('main').currentView.getRegion('sidebar').currentView;

      if (view) {
        view.setActiveItem(name);
      }
    },

    onResizeSidebar: function(obj) {
      obj.view.onResize();
      App.trigger('sidebar:resize');
    }
  };

  module.exports = SidebarController;
});
