/**
 * Created by BFR on 21/04/2022.
 */
define('vectorizationCollectionView',['module',
    'marionette',
    'app',
    'underscore',
    'vectorizationItemView'
  ],
  function (module,
            Marionette,
            App,
            _,
            VectorizationItemView) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
      childView: VectorizationItemView,
      className: 'col-xs-12 p-l-0 p-r-0'
    });
  });
