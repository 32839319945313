define( 'entities/command_tickets',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function(
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  /**
   * REST API
   */
  var API = {

    getTicketByPurchaseOrder : function(id, csMsg) {
      var params = {
          addMandrillInfo: true
        },
        defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commandtickets/command/' + id + '/csmsg/' + csMsg, params),
        type: 'GET',
        data : '',
        contentType: 'application/json',
        complete: function(resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    saveMessage: function(secId, messageModel, csMsg) {

      var params = {
        newMessageUrl: encodeURIComponent(Settings.url('base', '#' + App.getCurrentRoute())),
        lang: Settings.get('lang'),
        csMsg: csMsg
      };


      var defer = $.Deferred(),
        url =Settings.url('compuzz2', 'commandtickets/' +
          secId + '/messages', _.extend({}, params));

      $.ajax({
        type: 'POST',
        url: url,
        contentType: 'application/json',
        data: JSON.stringify(messageModel),
        complete: function(resp) {
          defer.resolve(resp.responseJSON);
        }
      });

      return defer.promise();
    },

    deleteMessage: function(secId) {
      var defer = $.Deferred(),
        url =Settings.url('compuzz2', 'commandtickets/message/' +
          secId);

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function(resp) {
          defer.resolve(resp.responseJSON);
        }
      });
      return defer.promise();
    },

    getTicketsByCustomer : function(secId) {
      var params = {
          addMandrillInfo: true
        },
        defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commandtickets/customer/' + secId, params),
        type: 'GET',
        data : '',
        contentType: 'application/json',
        complete: function(resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('commandtickets:purchaseorder', function(poi, csMsg) {
    return API.getTicketByPurchaseOrder(poi, csMsg);
  });
  App.reqres.setHandler('commandtickets:save-message', function(tSecId, messageModel, csMsg) {
    return API.saveMessage(tSecId, messageModel, csMsg);
  });
  App.reqres.setHandler('commandtickets:delete-message', function(uid) {
    return API.deleteMessage(uid);
  });

  App.reqres.setHandler('commandtickets:get-by-customer', function(secId) {
    return API.getTicketsByCustomer(secId);
  });
});

