/**
 * Created by BFR on 29/01/2018.
 */
define('orderStatusMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'orderStatusTabs',
  'orderStatusDetailsController'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  OrderStatusTabs,
  OrderStatusDetailsController
) {
  'use strict';

  var OrderStatusRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'orderstatus/new': 'showOrderStatusDetailsNew',
      'orderstatus(/:secId)(/:tab)' : 'showOrderStatusDetails'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showOrderStatusDetailsNew: function() {
      this.showOrderStatusDetails(null, null, true);
    },

      showOrderStatusDetails: function(secId, tab) {
      this.checkContent().done(function() {
        require([
          'caccountOrderStatusTabController'
        ], function(OrderStatusController) {
          if (OrderStatusController.createEditView) {
              OrderStatusController.createEditView.hide(true);
              OrderStatusController.createEditView = null;
          }

          if (secId) {
              OrderStatusDetailsController.fetchOrderStatus({
              secId: secId,
              tab: _.findWhere(OrderStatusTabs, {name: tab})
            });
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERSTATUS);
        });
      });
    }
  };

  App.on('orderStatus:show', function() {
    App.navigate('orderStatus');
  });

  App.on('before:start', function() {
    new OrderStatusRouter({
      controller: API
    });
  });

  module.exports = OrderStatusRouter;
});
