define('ordersPaymentView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'ordersConfirmationView',
  'template!ordersPaymentView'
], function (
  App,
  Module,
  Marionette,
  _,
  OrdersConfirmationView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-payment-view',    

    ui: {
      paid: '.paid',
      notPaid: '.not-paid',
      invoicePaid: '.invoice-paid',
      invoiceNotPaid: '.invoice-not-paid'
    },

    events: {
      'click': 'onClick'
    },     

    modelEvents: {
      'change:toInvoice': 'render',
      'change:payedByCustomer': 'render'
    },

    onRender: function() {
      this.ui.paid.hide();
      this.ui.notPaid.hide();
      this.ui.invoicePaid.hide();
      this.ui.invoiceNotPaid.hide();
      
      if (!this.model.get('toInvoice')) {
        if (this.model.get('payedByCustomer'))
          this.ui.paid.css('display', 'flex');
        else
          this.ui.notPaid.css('display', 'flex');
      } else {
        if (this.model.get('payedByCustomer'))
          this.ui.invoicePaid.css('display', 'flex');
        else
          this.ui.invoiceNotPaid.css('display', 'flex');
      }
    },

    onClick: function(e) {
      e.stopPropagation();
      e.preventDefault();

      if(this.model.get('payedByCustomer')) {
        OrdersConfirmationView.prototype.open(
            _.i18n('orders.unpay.confirmation.modal.text'),
            [this.model],
            _.bind(function () {
              this.model.updateCustomerPayment(false).done(_.bind(function() {
                this.model.fetch();
              }, this));
            }, this)
        );
      } else {
        this.model.updateCustomerPayment(true).done(_.bind(function() {
          this.model.fetch();
        }, this));
      }
    }
  });
});
  
