define('tableFooterPageView',[
  'module',
  'marionette',
  'underscore',
  'template!tableFooterPageView'
], function(
  Module,  
  Marionette,
  _, 
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.ItemView.extend({
    template: Tpl,       
    className: 'table-footer-page-view',

    events: {
        'click': 'onPageClick'
    },

    initialize: function() {
      this.tableView = this.options.tableView;
      this.bodyView = this.options.tableView.bodyView;
      this.footerView = this.options.footerView;
    },          

    serializeData: function() {
      var data = {};

      data.page = this.model.get('page');
      return data;
    },

    onRender: function() {
      if(this.model.get('page') === this.footerView.currentPage) {
        this.$el.addClass('selected');
      }
    },

    onPageClick: function() {
      this.footerView.setCurrentPage(this.model.get('page'));
    }
  });  
});

