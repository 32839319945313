/**
 * Created by BFR on 09/03/2020.
 */
define('filterTypeView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!filterTypeTpl'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl
  ) {
    'use strict';
  
    var FilterTypeView =  Marionette.LayoutView.extend({
      template: viewTpl,
      className: 'col-xs-12',
  
      regions: {
      },

      events: {
        'click .filter-type' : 'onClick',
        'click .delete-filter-type' : 'onDeleteClick'
      },

      onClick: function() {
        this.trigger('item:select');
      },

      onDeleteClick: function() {
        this.trigger('item:delete');
      },

      serializeData: function () {
        var templateData = this.options.model.toJSON();
        if (templateData.selected === undefined) {
            templateData.selected = false;
        }
        return templateData;
      }
    });
  
    module.exports = FilterTypeView;
  });
