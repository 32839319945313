
define('template!caccountAddCreditStripesPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="loader empty-page-loader stripe-loader" style="display: none;">Loading...</div>\n    <div class="my-vector-popup-container stripe-container">\n        <form class="form-horizontal">\n            <fieldset>\n                <div class="col-xs-12 icon-container">\n                    <div class="p-l-80">\n                        <a href="https://stripe.com" target="_blank"><div class="stripe-logo"></div></a>\n                    </div>\n                </div>\n                <div class="col-xs-12 p-0 labels-container">\n                    <label class="stripe-method-marked-list-item">\n                        1. ' +
((__t = ( _.i18n("credit.popup.paypal.howMany") )) == null ? '' : __t) +
'*\n                    </label>\n                </div>\n                <div class="col-xs-12 p-0 radio-container m-b-10">\n                    <div class="col-xs-2 col-md-2 p-0 with-background">\n                        <div class="radio radio-primary p-0 text-center">\n                            <label class="radio-count-label" for="credit-option-5">5</label>\n                            <div class="p-t-10 p-b-10">\n                                <span class="uppercase bold-text">credits</span>\n                            </div>\n                            <label class="radio-chooser">\n                                <input type="radio" name="optionsRadios" id="credit-option-5" value="5" checked="" data-field-name="quantity">\n                            </label>\n                            <div class="p-t-10">\n                                <span>*+2 &#8364;</span></br>\n                                <span>' +
((__t = ( _.i18n("credit.popup.stripe.adminCosts") )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2 col-md-2 p-0 with-background hide-border-small">\n                        <div class="radio radio-primary p-0 text-center">\n                            <label class="radio-count-label" for="credit-option-30">30</label>\n                            <div class="p-t-10 p-b-10">\n                                <span class="uppercase bold-text">credits</span>\n                            </div>\n                            <label class="radio-chooser">\n                                <input type="radio" name="optionsRadios" id="credit-option-30" value="30" checked="" data-field-name="quantity">\n                            </label>\n                            <div class="p-t-10">\n                                <span>*' +
((__t = ( _.i18n("credit.popup.stripe.no") )) == null ? '' : __t) +
'</span></br>\n                                <span>' +
((__t = ( _.i18n("credit.popup.stripe.adminCosts") )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2 col-md-2 p-0 with-background">\n                        <div class="radio radio-primary p-0 text-center">\n                            <label class="radio-count-label" for="credit-option-100">100</label>\n                            <div class="p-t-10 p-b-10">\n                                <span class="uppercase bold-text">credits</span>\n                            </div>\n                            <label class="radio-chooser">\n                                <input type="radio" name="optionsRadios" id="credit-option-100" value="100" checked="" data-field-name="quantity">\n                            </label>\n                            <div class="p-t-10">\n                                <span>*' +
((__t = ( _.i18n("credit.popup.stripe.no") )) == null ? '' : __t) +
'</span></br>\n                                <span>' +
((__t = ( _.i18n("credit.popup.stripe.adminCosts") )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2 col-md-2 p-0 with-background hide-border-small">\n                        <div class="radio radio-primary p-0 text-center">\n                            <label class="radio-count-label" for="credit-option-200">200</label>\n                            <div class="p-t-10 p-b-10">\n                                <span class="uppercase bold-text">credits</span>\n                            </div>\n                            <label class="radio-chooser">\n                                <input type="radio" name="optionsRadios" id="credit-option-200" value="200" checked="true" data-field-name="quantity">\n                            </label>\n                            <div class="p-t-10">\n                                <span>*' +
((__t = ( _.i18n("credit.popup.stripe.no") )) == null ? '' : __t) +
'</span></br>\n                                <span>' +
((__t = ( _.i18n("credit.popup.stripe.adminCosts") )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                    </div>\n                    <!-- Quantity  -->\n                    <div class="form-group col-md-4 col-xs-4">\n                      <table class="stripe-calculation-table">\n                        <tr class="">\n                          <td class="stripe-calculation-left">' +
((__t = ( _.i18n("credit.popup.stripe.credits") )) == null ? '' : __t) +
':</td>\n                          <td class="stripe-calculation-right stripe-price"></td>\n                        </tr>\n                        <tr class="">\n                          <td class="stripe-calculation-left">' +
((__t = ( _.i18n("credit.popup.stripe.fee") )) == null ? '' : __t) +
':</td>\n                          <td class="stripe-calculation-right stripe-price-add"></td>\n                        </tr>\n                        <tr class="">\n                          <td class="p-b-15 stripe-calculation-left m-b-15">\n                            ' +
((__t = ( _.i18n("credit.popup.stripe.tax") )) == null ? '' : __t) +
' (<span class="stripe-tax-percentage"></span>):</td>\n                          <td class="p-b-15 stripe-calculation-right stripe-price-vat m-b-15"></td>\n                        </tr>\n                        <tr class="stripe-culculation-border-top">\n                          <td class="stripe-calculation-left bold-text m-t-5">' +
((__t = ( _.i18n("credit.popup.stripe.total") )) == null ? '' : __t) +
':</td>\n                          <td class="stripe-calculation-right bold-text stripe-price-total m-t-5"></td>\n                        </tr>\n                      </table>\n                    </div>\n                </div>\n                <div class="col-xs-12 p-0">\n                    <div class="col-xs-12 label-info-container text-left p-l-0">\n                        <span>' +
((__t = ( _.i18n("credit.popup.stripe.creditConditions") )) == null ? '' : __t) +
'</span>\n                    </div>\n                </div>\n                <div class="form-group" data-field-name="quantity">\n                    <label class="col-xs-5 control-label user-quantity-setter">\n                        ' +
((__t = ( _.i18n('credit.popup.paypal.custom.value') )) == null ? '' : __t) +
'\n                    </label>\n                    <div class="col-xs-2">\n                        <input type="number" class="form-control input-number input-number-quantity" value="' +
((__t = ( quantity )) == null ? '' : __t) +
'" min="0" max="999999" data-field-name="quantity"/>\n                    </div>\n                </div>\n            </fieldset>\n        </form>\n    </div>\n    <div class="row stripe-container">\n        <form action="' +
((__t = ( stripeUrl )) == null ? '' : __t) +
'" method="post" id="payment-form" class="p-t-20 col-xs-12">\n            <div class="form-group">\n                <label class="stripe-method-marked-list-item" for="card-element">\n                    2. ' +
((__t = ( _.i18n("credit.popup.stripe.cardInfo") )) == null ? '' : __t) +
'\n                </label>\n                <div id="card-element">\n                    <!-- a Stripe Element will be inserted here. -->\n                </div>\n                <!-- Used to display form errors -->\n                <div id="card-errors"></div>\n            </div>\n            <div class="form-group m-t-20 m-b-20">\n                <a class="need-help-link pull-left button-box-model" href="mailto:support@compuzz.com">\n                    ' +
((__t = ( _.i18n("common.need.help") )) == null ? '' : __t) +
'\n                </a>\n                <button class="btn-stripe-pay pull-right btn btn-primary">\n                    ' +
((__t = ( _.i18n("credit.popup.stripe.proceedPayment") )) == null ? '' : __t) +
'\n                </button>\n            </div>\n        </form>\n    </div>    \n    <div class="loading-block js-loading-block">\n        <span class="loading-message js-loading-message label"></span>\n    </div>\n</div>';

}
return __p
};});

