
define('template!productPriceScaleTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-1 p-l-0 prices-editable">\n    ';
 if(!useFactorV2) { ;
__p += '\n    <input type="text" class="txt-quantity" style="display: none; height: 20px; width: 50px;"\n           value="' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'"/>\n    <span class="lbl-quantity">' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'</span>\n    ';
 } else { ;
__p += '\n    <div class="col" style="color: #333333;">' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n  </div>\n  ';
 if(!useFactorV2) { ;
__p += '\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( price )) == null ? '' : __t) +
'\n  </div>\n  ';
 } ;
__p += '\n  <div class="col-xs-1 p-l-0 prices-editable">\n    ';
 if(!useFactorV2) { ;
__p += '\n    <input type="text" class="txt-sprice" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( sPrice )) == null ? '' : __t) +
'"/>\n    <span class="lbl-sprice">€' +
((__t = ( sPrice )) == null ? '' : __t) +
' </span>\n    ';
 } else { ;
__p += '\n    <div class="col" style="color: #333333;">' +
((__t = ( sPrice )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n  </div>\n  <div class="col-xs-1 p-l-0">\n    ' +
((__t = ( Number(factorModel.factor).toFixed(3) )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( print.unit )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( print.handling )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( print.setup )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( smallOrderFee )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( shippingCost )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( sTotal )) == null ? '' : __t) +
'\n  </div>\n  <div class="col-xs-1 p-l-0">\n    ' +
((__t = ( percentMargin )) == null ? '' : __t) +
'%\n  </div>\n  <div class="col-xs-1 p-l-0">\n    €' +
((__t = ( margin )) == null ? '' : __t) +
'\n    ';
 if(!useFactorV2) { ;
__p += '\n    <div class="btn btn-remove-scale p-l-0 p-r-0 p-t-0 p-b-0 m-b-0 m-t-0" style="height: 20px;">\n      <i class="mdi-content-remove"></i>\n    </div>\n    ';
 } ;
__p += '\n  </div>\n</div>';

}
return __p
};});

