/**
 * Created by BFR on 24/06/2020.
 */
define('caccountSupplierFactorsView',['module',
    'marionette',
    'caccountSupplierFactorView'],
  function (module,
            Marionette,
            SupplierFactorView) {
    'use strict';

    var SupplierFactorsView = Marionette.CollectionView.extend({
      childView: SupplierFactorView,
      className: 'col-md-12 p-t-10',

      childEvents: {
        'item:selection:change': function () {

        }
      }
    });

    module.exports = SupplierFactorsView;
  });
