/**
 * Created by BFR on 24/02/2020.
 */
define('editPrintAreaView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'settings',
  'underscore',
  'template!editPrintAreaTpl',
  'dynamicTextView',
  'editPrintZonesView',
  'popupPrintAreaView',
  'bootbox',
  'popupPrintZoneView',
  'pluginUtils'
], function (
  module,
  Marionette,
  App,
  $,
  Settings,
  _,
  viewTpl,
  DynamicTextView,
  EditPrintZonesView,
  PopupPrintAreaView,
  bootbox,
  PopupPrintZoneView,
  PluginUtils
) {
  'use strict';

  var EditPrintAreaView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'pa-item',

    modelEvents: {
      sync: 'render'
    },

    regions: {
      editPrintZones: '.pz-region'
    },

    ui: {
      btnContainer: '.btn-pa-container'
    },

    events: {
      'click .btn-edit-pa': 'onEditPaClick',
      'click .btn-delete-pa': 'onDeletePaClick',
      'click .btn-add-printzone': 'onAddPrintZoneClick',
      'click .btn-edit-xml': 'onBtnEditXmlClick',
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut'
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    onShow: function () {
      var pzCol = this.model.get('printZones');
      pzCol.modelProduct = this.model.collection.modelProduct;
      this.editPrintZonesView = new EditPrintZonesView({collection: pzCol});
      this.getRegion('editPrintZones').show(this.editPrintZonesView);
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.name = this.options.model.get('name').getTextInLocale(Settings.get('lang'));
      if (this.model.get('secId')) {
        templateData.textureTemplate3dUrl = Settings.url('x2png', 'printarea/'+this.model.get('secId').split('_')[0]+'/template3d');
      } else {
        templateData.textureTemplate3dUrl = '';
      }
      return templateData;
    },

    onDeletePaClick: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.destroy();
          /*customerComment.destroy().done(function () {
            that.render();
          });*/
        }
      }, this));

    },

    onAddPrintZoneClick: function () {
      var m = {
        refPrintArea: this.model,
        width: this.model.get('width'),
        height: this.model.get('height')
      };

      var popupPrintZoneView = new PopupPrintZoneView({
        model: App.request('products-rel:printzone:model', m)
      });
      popupPrintZoneView.newItem = true;

      popupPrintZoneView.show({
        title: _.i18n('common.add')
      });
    },

    onEditPaClick: function () {
      var popupPrintZoneView = new PopupPrintAreaView({
        model: this.model,
        collection: this.collection
      });
      popupPrintZoneView.newItem = false;

      popupPrintZoneView.show({
        title: _.i18n('common.edit')
      });
    },

    onBtnEditXmlClick: function () {
      PluginUtils.callPlugin({
        action: 'editPrintAreaXml',
        printAreaSecId: this.model.get('secId')
      });
    }
  });

  module.exports = EditPrintAreaView;
});
