define('webshopProductsFinderListFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'webshopProductsFinderListFiltersEditView',
  'webshopProductsFinderListFiltersQuickAccessView',
  'filtersBannerView'
], function(
    Module,
    Backbone,
    _,
    Settings,
    moment,
    EditView,
    QuickAccessView,
    FiltersBannerView
) {
  'use strict';

  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,
    quickAccessViewClass: QuickAccessView,

    initialize: function() {
      this.tableView = this.options.tableView;

      var commonFilters = Settings.get('webshopProductsFinderList', true);
      if(commonFilters) {
        this.model = new Backbone.Model(JSON.parse(commonFilters));
      } else {
        this.model = new Backbone.Model({
          code: '',
          name: '',
          supplierName: '',
          supplierCode: ''
        });
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {

      //----------------
      if(this.model.get('code') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'code',
          libelle: _.i18n('orderList.productCode'),
          value: this.model.get('code')
        }));
      }

      //----------------
      if(this.model.get('name') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'name',
          libelle: _.i18n('orderList.productName'),
          value: this.model.get('name')
        }));
      }

      //----------------
      if(this.model.get('supplierCode') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'supplierCode',
          libelle: _.i18n('common.supplierCode'),
          value: this.model.get('supplierCode')
        }));
      }

      //----------------
      if(this.model.get('supplierName') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'supplierName',
          libelle: _.i18n('common.supplierName'),
          value: this.model.get('supplierName')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('code') !== model.get('code'))
        needFetch = true;

      if(this.model.get('name') !== model.get('name'))
        needFetch = true;

      if(this.model.get('supplierCode') !== model.get('supplierCode'))
        needFetch = true;

      if(this.model.get('supplierName') !== model.get('supplierName'))
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);

      Settings.set('webshopProductsFinderList', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });
});

