
define('template!graphicalRequestKanbanColumnTicketViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(tickets, function(ticket, index) { ;
__p += '\n    ' +
((__t = ( renderTemplate(templateItem, {ticket: ticket, state: state, index: index}) )) == null ? '' : __t) +
'\n';
 }); ;


}
return __p
};});

