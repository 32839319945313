
define('template!adminGlossariesPopupView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n  <div class="type item">\n    <div class="libelle">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</div>\n    <div class="type-region"></div>\n  </div>\n  <div class="code item">\n    <div class="libelle">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n    <input type="text" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n  </div>\n  <div class="translations">\n    <div class="fr item">\n      <div class="libelle">FR</div>\n      <textarea>' +
((__t = ( fr )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="en item">\n      <div class="libelle">EN</div>\n      <textarea>' +
((__t = ( en )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="de item">\n      <div class="libelle">DE</div>\n      <textarea>' +
((__t = ( de )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="nl item">\n      <div class="libelle">NL</div>\n      <textarea>' +
((__t = ( nl )) == null ? '' : __t) +
'</textarea>\n    </div>\n  </div>\n\n  <div class="missing-translation item">\n    <div class="libelle">' +
((__t = ( _.i18n("glossary.missingTranslation") )) == null ? '' : __t) +
'</div>\n    <input type="checkbox">\n  </div>\n</div>\n<div class="footer">\n  <div class="cancel item">\n    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg>\n    <div class="text">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="confirm item">\n    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">&gt;<path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>\n    <div class="text">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</div>\n  </div>\n</div>';

}
return __p
};});

