/**
 * Created by BFR on 10/06/2020.
 */
define('productDataTabController',[
  'module',
  'underscore',
  'settings',
  'app',
  'productDataTabView',
  'entities/product'
], function(
  module,
  _,
  Settings,
  App,
  View
) {
  'use strict';

  var ProductDataController = {
    showDetails: function (region, product) {
      App.request('product:get-supplier-data', product.get('cCode')).done(_.bind(function (supplierData) {
        region.show(new View({
          model: product,
          supplierData: supplierData,
          displayButtons: product.get('manufacturerCode') === Settings.get('currentCAccount').code
        }));
      }, this));
    }
  };

  module.exports = ProductDataController;
});

