/**
 * Created by BFR on 10/06/2020.
 */
define('productStockTabController',[
  'module',
  'underscore',
  'settings',
  'app',
  'productStockTabView'
], function(
  module,
  _,
  Settings,
  App,
  View
) {
  'use strict';

  var ProductStockController = {
    showDetails: function (region, stocks) {
        region.show(new View({model: stocks}));
    }
  };

  module.exports = ProductStockController;
});

