define('headerUserEditView',[
  'app',
  'module',
  'underscore',
  'settings',  
  'dialogFormView',
  'imageUtils',
  'template!headerUserEditView',
  'entities/caccounts/userpids'
], function(
  App,
  Module,
  _,
  Settings,  
  DialogFormView,
  ImageUtils,
  Tpl
) {
  'use strict';

  Module.exports = DialogFormView.extend({
    template: Tpl,
    className: 'header-user-edit-view', 

    ui: {
      upload: '.upload-user-avatar',
      preview: '.user-avatar-img',
      save: '.btn-s-md-save',
      input: '.js-form-input',
      changePassword: '.js-change-password',
      notificationsEnabled: '.notificationsEnabled'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus',
      'change .checkbox-input': 'onChangeCheckboxInput',
      'click @ui.changePassword': 'onChangePassword',
      'click @ui.save': 'onSave', 
      'change @ui.upload' : 'onChangeLogo'
    },

    initialize: function() {
      this.formData = new FormData();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      if (templateData.notificationsEnabled === undefined)
        templateData.notificationsEnabled = true;

      if (this.model.get('logo'))
        templateData.fileUrl =
            Settings.url('file', this.model.get('logo'), {size: 'thumbnail'});
      else
        templateData.fileUrl = null;
      
      return templateData;
    },

    onChangeCheckboxInput: function () {       
      this.triggerMethod('enable:cancel:confirm');
    },    

    onChangePassword: function() {    
      var model = App.request('session:change:password');

      model.save({}, {
        success: _.bind(function(model, response) {
          this.hide();
          App.navigate('reset_password' + '?tokenfp=' + response.value, {trigger: true});
        }, this),
        error: _.bind(function() {
          //this.onShowMessage('danger', _.i18n('common.request.error'));
        }, this)
      });
    },

    onSave: function() {     

      var fieldsToValidate = [
        {name: 'firstName', type: 'required'},
        {name: 'lastName', type: 'required'}
      ];

      if (this.validate(fieldsToValidate)) {
        this.formData.append('secId', this.model.get('secId'));
        this.formData.append('firstName', this.model.get('firstName'));
        this.formData.append('lastName', this.model.get('lastName'));
        this.formData.append('notificationsEnabled', this.ui.notificationsEnabled.is(':checked'));

        var postRequest = App.request('caccount-admins:post-item', this.formData);
        postRequest.done(_.bind(function(data) {
          Settings.set('currentUserCookie', JSON.stringify(data));
          Settings.set('currentUser', data);

          if(this.options.saved) {
            this.options.saved();
          }
          
          this.hide();
        }, this));
      }
    },

    onChangeLogo: function() {
      var file = this.ui.upload[0].files[0];
      this.formData.append('file', file);
      ImageUtils.encodeFileToBase64(file, _.bind(this.previewFile, this));      
    },

    previewFile: function(base64File) {
      this.ui.preview.attr('style', 'background-image: url(' + base64File + ')');
    }
  });
});
