define('headerTaskView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'settings',
  'moment',
  'userView',
  'dateView',
  'tasksEditView',
  'commentView',
  'entities/orders',
  'template!headerTaskView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  Settings,
  moment,
  UserView,
  DateView,
  EditView,
  CommentView,
  Orders,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    className: 'header-tasks-list-task-view',
    template: Tpl,

    ui: {
      done: '.done',
      date: '.date',
      dateValue: '.date .value'
    },

    events: {
      'click @ui.done': 'doneChange',
      'click': 'onOpenTarget'
    },

    regions: {
      userCreatorRegion: '.user-creator-region',
      dateRegion: '.date-region',
      commentRegion: '.comment-region'
    },

    serializeData: function () {
      var data = {};

      data.type = '';
     
      if (this.model.get('refCommandLine')) {
        data.type = 'order';

        //------------

        data.user = this.parseClientName(this.model.get('refCommandLine')); 

        //------------
        
        data.sameOrderCount = 0;      
        if(this.model.get('refCommandLine').nbrOrderSameCustomer)
          data.sameOrderCount = this.model.get('refCommandLine').nbrOrderSameCustomer;

        //------------

        var html = this.model.get('refCommandLine').purchaseOrderId;
        var price = '';

        var numberLocale = 'fr-BE';
        var configNumberLocale = Settings.configValue('priceLocale');
        if (configNumberLocale)
          numberLocale = configNumberLocale;
  
        if(this.model.get('refCommandLine').price) {          

          price += '€' + new Intl.NumberFormat(numberLocale).format(this.model.get('refCommandLine').price);  
        }

        
        if(this.model.get('refCommandLine').quantity) {
          if(price !== '')
            price += ' - ';

          price += new Intl.NumberFormat(numberLocale).format(this.model.get('refCommandLine').quantity) + ' ' + _.i18n('common.pcs');
        }     

        if(price !== '') {
          if(html !== '')
            html += ' | ';
          html += price;
        }      

        data.line2 = html;   

      } else if (this.model.get('refEndUser')) {
        data.type = 'client';
        data.user = this.parseClientName(this.model.get('refEndUser'));
      } else if (this.model.get('refProduct')) {
        data.type = 'product';    
        data.code = '';   
        data.name = '';

        if(this.model.get('refProduct').cCode)
          data.code = this.model.get('refProduct').cCode;

        if(this.model.get('refProduct').name)
          data.name = ' - ' + this.model.get('refProduct').name;
      }

      //--------------------------

      data.done = false;

      if(this.model.get('done')) 
        data.done = true;

      //--------------------------

      data.checked = this.model.get('done')? 'checked' : '';  

      //--------------------------

      return data;      
    },

    onShow: function () {   
      this.userView = new UserView({
        model: new Backbone.Model(this.model.get('refCreator'))
      });
      this.getRegion('userCreatorRegion').show(this.userView);

      //--------------------------   
      
      var color = '';

      if (this.model.get('dateDue')) {   
        if (moment(new Date()).isAfter(this.model.get('dateDue')))
          color = 'red';
      }

      this.dateView = new DateView({
        model: new Backbone.Model({
          date: this.model.get('dateDue'),
          icon: 'clock',
          color: color
        })
      });
      this.getRegion('dateRegion').show(this.dateView);

      //--------------------------
  
      if(this.model.get('comments') && this.model.get('comments').length > 0) {
        this.commentView = new CommentView({
          model: new Backbone.Model(this.model.get('comments')[this.model.get('comments').length-1]),
          showButtons: false
        });
        this.getRegion('commentRegion').show(this.commentView);
      }      
    },

    doneChange: function (e) {
      e.stopPropagation();   
      
      this.model.set('done', this.ui.done.is(':checked'));
      this.model.save(this.model.toJSON()).done(_.bind(function () {
        this.triggerMethod('doneChange', this.model.get('done'));
        this.model.fetch();
      }, this));
    },

    onOpenTarget: function (e) {
      e.stopPropagation();
      e.preventDefault();

      EditView.prototype.open({
        model: this.model.clone()
      });

      this.triggerMethod('close');
    },

    parseClientName: function(model) {
      var properties = Settings.configValue('board.card.mainTitleProperty');
      var clientName = '';
      _.each(properties, function (property) {
        if (model[property] && clientName === '')
          clientName = model[property];
      }, this);
      if (clientName === '')
        clientName = model.endUserId;
      return clientName;
    }
  });
});
