define('orderdDetailCompositionController',[
  'module',
  'app',
  'windows',
  'underscore',
  'backbone',
  'orderDetailCompositionView',
  'compuzzProUtils',
  'editorView',
  'entities/session'
], function (
  Module,
  App,
  Windows,
  _,
  Backbone,
  CompositionTabView,
  CompuzzProUtils,
  EditorView
) {
  'use strict';

  Module.exports = {
    showCompositionTab: function (region, orderModel, customer, product) {
      this.renderCompositionsTab(region, orderModel, customer, product);
    },

    renderCompositionsTab: function(region, orderModel, customer, product) {
      var that = this;
      this.region = region;
      orderModel.set('compositions', _.sortBy(orderModel.get('compositions'), function (composition) {
        return composition.paCode;
      }));
      this.customer = customer;
      this.product = product;
      this.orderModel = orderModel;
      var coll = that.getCompositionCollection(orderModel);
      coll.orderModel = orderModel;
      coll.productModel = product;
      this.view = new CompositionTabView({
        collection: coll,
        productModel: product
      });
      this.view.listenTo(this.view, 'editor:open', _.bind(this.onEditorOpen, this));
      this.view.listenTo(this.orderModel, 'change', _.bind(function() {
        this.renderCompositionsTab(region, orderModel, customer, product);
      }, this));
      this.view.orderModel = orderModel;
      this.view.printAreas = product.get('printAreas');

      CompuzzProUtils.getInstance().addListener(this.view, CompuzzProUtils.events.COMPUZZ_PRO_REFRESH_COMPOSITION,
          _.bind(function () {
            this.orderModel.fetch().done(_.bind(function () {
              this.showCompositionTab(this.region, this.orderModel, this.customer, this.product);
            }, this));
          }, this));

      region.show(this.view);
    },

    getCompositionCollection: function (orderModel) {
      var compositionCollection = new Backbone.Collection(orderModel.get('compositions'));

      return compositionCollection;
    },

    onEditorOpen: function (poi, printZone, editorUrl, pim) {
      var productCode = this.view.orderModel.attributes.productCode;
      if (pim) {
        productCode = this.view.orderModel.get('productBaseCode');
        var options = this.view.orderModel.get('options');
        if (options) {
          options = _.map(options, function (option) {
            return option.id;
          });
        }
        this.createSessionAndOpenEditor(productCode, poi, printZone, editorUrl, options, pim);
      } else {
        this.createSessionAndOpenEditor(productCode, poi, printZone, editorUrl);
      }
    },

    createSessionAndOpenEditor: function(productCode, poi, printZone, editorUrl, options, pim) {
      App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz'},
          poi).done(_.bind(function (session) {
        var sessionTokenRequest = App.request('session:createToken', session.sessionId);

        $.when(sessionTokenRequest).done(_.bind(function (resp) {
          var tokenId = resp.tokenId;
          var url = editorUrl +
              '/purchaseorder/' + poi +
              '/product/' + encodeURIComponent(productCode) +
              '/printzone/' + printZone +
              '?tokenId=' + tokenId + '&expert=true';

          if (options) {
            url += '&options=' + options.join(',');
          }
          if (pim) {
            url += '&pim=true';
          }

          Windows.open({
            view: _.bind(function () {
              return new EditorView({
                url: url
              });
            }, this),
            id: 'editor.composition.' + poi + '.' + printZone,
            title: 'Edit composition',
            configName: 'fullScreen',
            onClose: _.bind(function() {
              this.orderModel.fetch();
            }, this)
          });
        }, this));
      }, this));
    }
  };
});

