/**
 * Created by JDE on 07/01/2016.
 */
define('pimProductSelectDialogView',[
  'underscore',
  'app',
  'jquery',
  'settings',
  'module',
  'template!pimProductSelectDialogTpl',
  'template!pimProductSelectDialogProductTpl',
  'dialogFormView',
  'jqgridView',
  'bootbox',
  'entities/pim_product',
  'entities/caccounts/share'
], function (_,
             App,
             $,
             Settings,
             module,
             viewTpl,
             productTpl,
             DialogFormView,
             JqGridView,
             bootbox) {
  'use strict';

  var ProductSelectionView = JqGridView.extend({
    template: productTpl,
    filtersName: 'productsSelectionListFilters',

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list-dialog',
        jqGridPager: '#jq-grid-pager-list-dialog'
      }, this.gridUi);
    },

    initialize: function () {
      this.selectDefault = _.i18n('common.all');
    },

    imagePopoverContainer: '.product-selection-dialog',

    events: function () {
      return _.extend({}, this.gridEvents);
    },

    serializeData: function () {
      this.selectedProducts = Settings.get('selectedProducts') || [];
      return {type: 'product'};
    },

    gridOptions: function (data) {
      var that = this,
        defaultsOptions, options;

      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {label: '', name: 'id', key: true, hidden: true},
          {
            label: ' ',
            name: 'data.catalogPictureUrl',
            align: 'left',
            search: false,
            classes: 'product-link image-cell',
            formatter: this.pictureUidFormatter,
            width: 30
          },
          {
            label: _.i18n('productList.productCode'),
            name: 'resellerData',
            search: true,
            index: 'resellerCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.resellerFormatter,
            width: 50
          },
          {
            label: _.i18n('productList.productCodeMan'),
            name: 'data.code',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter,
            width: 50
          },
          {
            label: _.i18n('productList.productName'),
            name: 'data.name.values',
            search: false,
            index: 'productName',
            classes: 'product-link',
            formatter: this.nameFormatter,
            width: 120
          },
          {
            label: _.i18n('productList.productManufacturer'),
            name: 'supplier',
            search: true,
            index: 'supplierSecId'/*, stype: 'select'*/,
            classes: 'product-link',
            formatter: this.defaultFormatter,
            width: 80,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              multiple: false,
              value: data.manufacturers,
              defaultValue: that.selectDefault
            }
          }
        ],

        serializeGridData: _.bind(function (postData) {
          if (postData.supplierSecId === that.selectDefault) {
            delete postData.supplierSecId;
          }
          
          if (postData.productCode) {
            postData.code = postData.productCode;
          }

          if (postData.productName) {
            postData.name = postData.productName;
          }

          postData.showInWebshop = true;

          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        }, this),

        gridComplete: function () {
          that.onGridComplete();
          that.hidePopovers();
          that.attachPopover();
          that.checkProductsSelection();
        },

        beforeSelectRow: function (rowid, event) {
          // check if product selection
          var grid = $(this);
          if (that.multiSelect === true) {
            if (event.ctrlKey || event.altKey) {  // alt instead ctrl for mac os
              that.manageCtrlKey(grid, rowid);
            } else if (event.shiftKey) {
              that.manageShiftKey(grid, rowid);
            } else {
              that.manageClickOnRow(grid, rowid);
              return true;
            }
          } else {
            that.manageClickOnRow(grid, rowid);
            return true;
          }
          return false;
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    pictureUidFormatter: function (cellValue, colModel, model) {
      var pimProduct = App.request('pim:products:model', model);
      return '<span><img data-link="' + pimProduct.getDefaultImage() + '"' +
        'class="jq-picture js-image-cell" src="' + pimProduct.getDefaultImage() + '"/></span>';
    },

    nameFormatter: function (aNames) {
      var sResult = '';
      _.each(aNames, function (value, lang) {
        if (Settings.get('lang')) {
          if (lang === Settings.get('lang') || (lang === 'en' && !sResult)) {
            sResult = value;
          }
        }
      });
      return sResult;
    },

    resellerFormatter: function (aResellers) {
      var sResult = '';
      _.each(aResellers, function (value) {
        if (sResult !== '') {
          sResult += ', ';
        }
        sResult += '<span className="">' + value.code + '</span>';
      });
      return sResult;
    },

    manageClickOnRow: function (grid, rowid) {
      var $row = grid.find('#' + rowid);

      if ($row.hasClass('selected')) {
        this.onRowSingleselect(rowid, false);
      } else {
        this.onRowSingleselect(rowid, true);
      }
    },

    manageCtrlKey: function (grid, rowid) {
      var maxSelectedProductsNumber = 100,
        $row = grid.find('#' + rowid);
      if ($row.hasClass('selected')) {
        $row.removeClass('selected');
        this.onRowMultiselect(rowid, false);
      } else {
        if (this.selectedProducts.length === maxSelectedProductsNumber) {
          this.trigger('onshowWarningOutOfRange');
        } else {
          $row.addClass('selected');
          this.onRowMultiselect(rowid, true);
        }
      }
    },

    manageShiftKey: function (grid, rowid) {
      var firstSelectedProduct = this.getFirstSelectedProduct(),
        lastSelectedRow = grid.find('#' + rowid),
        gridRows = grid.find('tr'),
        lastIndex = gridRows.index(lastSelectedRow),
        lastSelectedProduct = {
          rowid: rowid,
          page: this.pagination.currentPage,
          index: lastIndex
        };

      if (!firstSelectedProduct) firstSelectedProduct = lastSelectedProduct;
      if (firstSelectedProduct.page > lastSelectedProduct.page) {
        this.swapProducts(firstSelectedProduct, lastSelectedProduct);
      } else if (firstSelectedProduct.page === lastSelectedProduct.page) {
        if (firstSelectedProduct.index > lastSelectedProduct.index) {
          this.swapProducts(firstSelectedProduct, lastSelectedProduct);
        }
      }
      if (this.checkIfOnlyCurrentPageSelection(firstSelectedProduct, lastSelectedProduct)) {
        this.selectWithoutRequest(firstSelectedProduct, lastSelectedProduct);
      } else {
        this.getCollectionToPage(firstSelectedProduct, lastSelectedProduct);
      }
    },

    checkIfOnlyCurrentPageSelection: function (first, last) {
      return first.page === last.page;
    },

    selectWithoutRequest: function (first, last) {
      var grid = this.ui.jqGrid,
        list = grid.find('tr');

      this.selectedProducts = [];
      list.each(_.bind(function (index, row) {
        var $row = $(row),
          rowid = $row.attr('id');
        if (index >= first.index &&
          index <= last.index) {
          this.selectedProducts.push({
            rowid: rowid,
            page: this.pagination.currentPage,
            index: index
          });
        }
      }, this));
      this.checkProductsSelection();
    },

    swapProducts: function (first, last) {
      for (var key in last) {
        var temp = first[key];
        first[key] = last[key];
        last[key] = temp;
      }
    },

    getFirstSelectedProduct: function () {
      var firstSelected,
        lastPage = this.getLastPage(),
        firstIndex = this.pagination.pageSize;
      if (this.selectedProducts.length === 0) {
        return null;
      } else {
        _.each(this.selectedProducts, function (product) {
          if (product.page < lastPage) {
            lastPage = product.page;
            firstIndex = product.index;
            firstSelected = product;
          } else if (product.page === lastPage) {
            if (product.index < firstIndex) {
              firstSelected = product;
              firstIndex = product.index;
            }
          }
        });
        return firstSelected;
      }
    },

    getLastPage: function () {
      var lastPage;
      if (this.selectedProducts.length > 0) {
        lastPage = _.max(this.selectedProducts, function (product) {
          return product.page;
        }).page;
      } else {
        lastPage = this.pagination.currentPage;
      }
      return lastPage;
    },

    getCollectionToPage: function (first, last) {
      var firstProductIndex = (first.page - 1) * this.pagination.pageSize + (first.index - 1),
        lastProductIndex = (last.page - 1) * this.pagination.pageSize + last.index,
        size = lastProductIndex - firstProductIndex || 1,
        params = {
          pageSize: size,
          first: firstProductIndex,
          _search: false,
          nd: new Date().getTime(),
          rows: size,
          page: 1,
          sord: 'asc'
        };
      if (size > 100) {
        this.trigger('onshowWarningOutOfRange');
      } else {
        this.trigger('onLoadSelectedProducts', first, last, params);
      }
    },

    onSelectProducts: function (first, last, products) {
      var pageSize = this.pagination.pageSize,
        page, productIndex;

      this.selectedProducts = [];
      _.each(products, _.bind(function (product, index) {
        page = Math.floor((index + first.index - 1) / pageSize);
        productIndex = index + first.index - page * pageSize;
        this.selectedProducts.push({
          rowid: product,
          page: page + first.page,
          index: productIndex
        });
      }, this));
      this.checkProductsSelection();
    },

    onRowMultiselect: function (rowid, select) {
      var index,
        grid = this.ui.jqGrid,
        gridList = grid.find('tr'),
        row = grid.find('#' + rowid),
        product = {
          rowid: rowid,
          page: this.pagination.currentPage,
          index: gridList.index(row)
        };
      if (select) {
        this.selectedProducts.push(product);
      } else {
        index = this.selectedProducts.indexOf(_.findWhere(this.selectedProducts, {rowid: rowid}));
        this.selectedProducts.splice(index, 1);
      }
      this.onSelectedProductsChanged();
    },

    onRowSingleselect: function (rowid, select) {
      var grid = this.ui.jqGrid,
        gridList = grid.find('tr'),
        row = grid.find('#' + rowid),
        product = {
          rowid: rowid,
          page: this.pagination.currentPage,
          index: gridList.index(row)
        };
      this.selectedProducts = [];
      if (select) {
        this.selectedProducts.push(product);
      }
      this.checkProductsSelection();
    },

    checkProductsSelection: function () {
      var $list = this.ui.jqGrid.find('tr');
      $list.each(_.bind(function (index, item) {
        var $item = $(item);
        if (_.some(this.selectedProducts, {rowid: $item.attr('id')})) {
          $item.addClass('selected');
        } else {
          $item.removeClass('selected');
        }
      }, this));
      this.onSelectedProductsChanged();
    },

    onSelectedProductsChanged: function () {
      if (this.selectedProducts.length > 0) {
        this.trigger('buttonStateChanged', true);
      } else {
        this.trigger('buttonStateChanged', false);
      }
      Settings.set('selectedProducts', this.selectedProducts);
    },

    clearSelection: function () {
      Settings.set('selectedProducts', []);
      this.selectedProducts = [];
      this.checkProductsSelection();
    }

  });

  var PopupProductSelectView = DialogFormView.extend({
    template: viewTpl,

    className: 'product-selection-dialog',

    regions: {
      productSelection: '.select-product-region'
    },

    ui: {
      'leave': '.leave-button',
      'addSelected': '.add-selected-products-button',
      'blinder': '#dialog-blinder'
    },

    events: {
      'click @ui.leave': 'leavePopup',
      'click @ui.addSelected': 'onAddSelected'
    },

    onShow: function () {
      App.regions.addRegions(this.regions);
      this.productSelectionView = new ProductSelectionView();

      this.productSelectionView.listenTo(this.productSelectionView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.productSelectionView.listenTo(this.productSelectionView, 'buttonStateChanged', _.bind(this.onButtonStateChanged, this));
      this.productSelectionView.listenTo(this.productSelectionView, 'onLoadSelectedProducts',
        _.bind(this.onLoadSelectedProducts, this));
      this.productSelectionView.listenTo(this.productSelectionView, 'onshowWarningOutOfRange',
        _.bind(this.showWarningSelectedNumberOutOfRange, this));
      App.regions.getRegion('productSelection').show(this.productSelectionView);
    },

    serializeData: function () {
      if (typeof this.options.displayLeaveButton === 'boolean') {
        this.displayLeaveButton = this.options.displayLeaveButton;
      } else {
        this.displayLeaveButton = true;
      }
      return {displayLeaveButton: this.displayLeaveButton};
    },

    showWarningSelectedNumberOutOfRange: function () {
      this.onBlind();
      bootbox.alert(_.i18n('common.errorOccuredSelectedItemsNumberOutOfRange'), function () {
        this.onBlindEnd();
        return;
      });
    },

    onBlind: function () {
      this.ui.blinder.show();
    },

    onBlindEnd: function () {
      this.ui.blinder.hide();
    },

    onGridLoaded: function () {
      App.request('caccounts:get-share-from-sorted').done(_.bind(function (list) {
        var selectDefault = this.productSelectionView.selectDefault,
          gridStatuses = selectDefault + ':' + selectDefault;

        _.each(list, function (status) {
          gridStatuses += ';' + status.caccountFrom.secId + ':' +
            status.caccountFrom.code;
        });

        var data = {
          url: App.request('pim:products:get-collection-url'),
          rowCountUrl: App.request('pim:products:row-count-url'),
          manufacturers: gridStatuses
        };

        this.productSelectionView.displayGrid(data, true);
      }, this));
    },

    onLoadSelectedProducts: function (first, last, params) {
      this.trigger('onLoadSelectedProducts', first, last, params);
    },

    onSelectProducts: function (first, last, products) {
      this.productSelectionView.triggerMethod('selectProducts', first, last, products);
    },

    onButtonStateChanged: function (enabled) {
      if (enabled) {
        this.ui.addSelected.removeClass('disabled');
      } else {
        this.ui.addSelected.addClass('disabled');
      }
    },

    onHide: function () {
      Settings.set('selectedProducts', []);
    },

    leavePopup: function () {
      this.hide();
    },

    onAddSelected: function () {
      var selectedProducts = Settings.get('selectedProducts');
      this.trigger('products-selected', selectedProducts);
      this.productSelectionView.clearSelection();
      if (this.displayLeaveButton === false) {
        this.leavePopup();
      }
    }
  });

  module.exports = PopupProductSelectView;
});
