
define('template!caccountSupplierFactorsV2Tpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="btn btn-delete-factorsV2">\n  <i class="mdi-content-delete"></i>\n  <span>' +
((__t = ( _.i18n('factors.delete') )) == null ? '' : __t) +
'</span>\n</div>\n<div class="row">\n  <label class="col-xs-3">Base margin buying price value</label>\n  <input class="col-xs-3 txt-supplier-param1 p-l-0 p-r-0" value="' +
((__t = ( param1 )) == null ? '' : __t) +
'"/>\n</div>\n<div class="row">\n  <label class="col-xs-3">Minimum buying price order value</label>\n  <input class="col-xs-3 txt-supplier-param2 p-l-0 p-r-0" value="' +
((__t = ( param2 )) == null ? '' : __t) +
'"/>\n</div>\n<div class="row">\n  <label class="col-xs-3">Maximum buying price order value</label>\n  <input class="col-xs-3 txt-supplier-param3 p-l-0 p-r-0" value="' +
((__t = ( param3 )) == null ? '' : __t) +
'"/>\n</div>\n<div class="col-xs-12">\n  <div class="col-xs-12 supplier-factorsv2-view">\n\n  </div>\n</div>\n';

}
return __p
};});

