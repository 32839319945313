/**
 * Created by OBL on 16-12-15.
 */
define('cataloguesSharedAddView',[
    'module',
    'template!cataloguesSharedAddTpl',
    'dialogView', 'settings'
], function (
    module,
    viewTpl,
    DialogView, Settings
    ) {
    'use strict';

    var CatalogueSharedAddView = DialogView.extend({
        template: viewTpl,

        ui: {
            ok: '.sa-accept',
            cancel: '.js-cancel'
        },

        events: {
            'click @ui.ok': 'onAccept',
            'click @ui.cancel': 'onCancel'
        },

        onAccept: function() {
            this.trigger('catalogue:share:add', this.model.get('secId'));
        },

        onCancel: function () {
            this.trigger('catalogue:share:cancel');
            this.hide();
        },

        serializeData: function() {
            var tplData = this.model.toJSON();
            tplData.cAccountLogoUrl = Settings.url('file', tplData.cAccountLogo, {size: 'thumbnail'});
            if(tplData.pictureUid){
                tplData.pictureUrl = Settings.url('file', tplData.pictureUid, {size: 'thumbnail'});
            }
            else{
                tplData.pictureUrl = Settings.get('defaultCatalogueImage');
            }

            tplData.shareLink = window.location.href +
                '#catalogues/shared/add/'+tplData.secId;
            return tplData;
        }
    });

    module.exports = CatalogueSharedAddView;
});
