
define('template!ordersDetailSupplierInvoiceView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n  <div class="title">' +
((__t = ( _.i18n('orders.detail.supplier.title') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n  <div class="column">\n    <div class="id item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.id') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( id )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="date item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.date') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( date )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="amount item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.amount') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( amount )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="delivery-cost item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.delivery-costs') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( deliveryCosts )) == null ? '' : __t) +
'</div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

