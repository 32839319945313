define('ordersBoardKanbanView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'settings',
  'underscore',
  'events',
  'contextMenu',
  'ordersController',
  'ordersBoardCardsView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  Settings,
  _,
  Events,
  ContextMenu,
  Controller,
  CardsView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    childView: CardsView,
    className: 'orders-board-kanban-view',

    events: {
      'mousemove': 'onMouseOver',
      'mouseleave': 'onMouseLeave'
    },

    childEvents: {
      'onDrag': function() {
        this.draging = true;
      }, 
      'onStopDrag': function() {
        this.draging = false;
      }
    },
    
    childViewOptions: function() {
      return {
        //users: this.users,
        statuses: this.statuses,
        type: this.options.type, 
        filtersView: this.options.filtersView,
        kanbanView: this,
        boardView: this.options.boardView
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.scroller = null;
      this.draging = false;     
      this.multiSelect = [];

      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onRender: function() {
      this.$el.attr('data-layout-type', 'default');      
      if (Settings.configValue('board.card.layoutType') === 'zaprinta')
        this.$el.attr('data-layout-type', 'zaprinta');

      //-------------------
      if(!Settings.configValue('test.hideBoardContextMenu')) {
        this.$el.contextMenu({
          selector: '.card-view',
          build: _.bind(function () {
            return {
              items: Controller.contextMenu(
                this.options.type,
                this.getSelectedOrders(),
                this.lastSelected,
                _.bind(function() {
                  this.unSelectAll();
                }, this))
            };
          }, this)
        });
      }
    },

    onShow: function () {
      var promises = [];

      promises.push(App.request('orders:kanban-statuses', this.options.type));
      promises.push(App.request('orders:statuses', this.options.type));

      $.when.apply($, promises).then(_.bind(function(statuses, allStatuses) {
        this.statuses = allStatuses;

        var selectedStatuses = statuses.models;

        if (Settings.configValue('test.showOnlyOneBoardStatus'))
            selectedStatuses = statuses.models[0];

        if (this.options.parentStatus) {
         
          var parent = statuses.findWhere({statusConst: this.options.parentStatus});
          if(parent) {
            parent.set('isSubDirectory', true);

            _.each(parent.get('childStatus'), _.bind(function(status) {            
              var model = new Backbone.Model(status);
              selectedStatuses.push(model);
            }, this));

            selectedStatuses = parent.get('childStatus');
            selectedStatuses.unshift(parent);
          }          
        }
        
        this.collection.push(selectedStatuses); 
      }, this));
    },

    getOrder: function(poi) {
      var order = null;
      this.children.each(_.bind(function(view) {
        _.each(view.collection.models, _.bind(function (o) {
          if(o.get('poi') === poi)
            order = o;
        }, this));
      }, this));

      return order;
    },

    getSelectedCards: function () {
      var views = [];

      this.children.each(_.bind(function(cardsView) {
        _.each(cardsView.children._views, function (view) {
          if(view.model.get('orders'))
            views = views.concat(view.cardsView.getSelectedCards());
          else
            if(view.isSelected())
              views.push(view);
        });
      }, this));    

      return views;
    },

    getSortedViews: function () {
      var views = [];

      this.children.each(_.bind(function(cardsView) {
        if(cardsView.sortData[cardsView.model.get('status')] &&
            cardsView.sortData[cardsView.model.get('status')].field !== '')
          views.push(cardsView);
      }, this));

      return views;
    },

    getSelectedOrders: function () {
      var orders = [];
      _.each(this.getSelectedCards(), function (orderView) {
        orders.push(orderView.model);
      }, this);
      return orders;
    },

    unSelectAll: function () {
      this.children.each(_.bind(function(cardsView) {
        cardsView.unSelectAll();
      }, this));
    },

    removeOrder: function(order) { 
      this.children.each(_.bind(function(cardsView) {
        cardsView.removeOrder(order);
      }, this));
    },

    updateCards: function (orders) {
      this.children.each(_.bind(function(cardsView) {
        cardsView.updateCards(orders);
      }, this));
    },

    clearAllSort: function () {
      this.children.each(_.bind(function(cardsView) {
        cardsView.resetSort();
      }, this));
    },

    hideSortPanels: function () {
      this.children.each(_.bind(function(cardsView) {        
        cardsView.hideSortPanel();
      }, this));
    },   

    getStatusView: function(status) {
      var result = null;

      this.children.each(_.bind(function(view) {
        if(view.model.get('statusConst') === status)
          result = view;
      }, this));       

      return result;
    },

    onMouseOver: function (e) {
      if (!this.draging) {
        if (this.scroller !== null)
          clearInterval(this.scroller);

        return;
      }

      var pos = e.pageX - $(e.currentTarget).offset().left;
      var maxWidth = this.$el.width();

      if (pos < 1)
        pos = 1;

      if (pos > maxWidth)
        pos = maxWidth;

      var activeAreaSize = maxWidth / 100 * 40;
      var speed = 1;

      if (pos > maxWidth - activeAreaSize) {
        speed = Math.round((maxWidth - pos) / 2);
        if (this.scroller !== null)
          clearInterval(this.scroller);
        this.scroller = setInterval(_.bind(function() {
          this.$el.animate({scrollLeft: '+=4'}, 0);
        }, this), speed);
        return;
      }

      if (pos < activeAreaSize) {
        speed = Math.round(pos / 2);
        if (this.scroller !== null)
          clearInterval(this.scroller);
        this.scroller = setInterval(_.bind(function() {
          this.$el.animate({scrollLeft: '-=4'}, 0);
        }, this), speed);
        return;
      }
    },

    onMouseLeave: function () {
      if (this.scroller !== null)
        clearInterval(this.scroller);
    },

    onKeyDown: function(e) {
      if ((e.ctrlKey || e.altKey) && e.which === 36) {
        e.preventDefault();
        e.stopPropagation();
        this.$el.animate({scrollLeft: '0'}, 100);
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.shiftKey && e.which === 37) {
        e.preventDefault();
        e.stopPropagation();
        this.$el.animate({scrollLeft: '-=620'}, 100);
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.which === 37) {
        e.preventDefault();
        e.stopPropagation();
        this.$el.animate({scrollLeft: '-=310'}, 100);
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.shiftKey && e.which === 39) {
        e.preventDefault();
        e.stopPropagation();
        this.$el.animate({scrollLeft: '+=620'}, 100);
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.which === 39) {
        e.preventDefault();
        e.stopPropagation();
        this.$el.animate({scrollLeft: '+=310'}, 100);
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.shiftKey && e.which === 8) {
        e.preventDefault();
        e.stopPropagation();
        this.unSelectAll();
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.which === 8) {
        e.preventDefault();
        e.stopPropagation();
        this.clearAllSort();
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.which === 35) {
        e.preventDefault();
        e.stopPropagation();
        var iWidth = this.collection.length * 310;
        this.$el.animate({scrollLeft: iWidth}, 100);
        return false;
      }
    }
  });
});
  
