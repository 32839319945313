/**
 * Created by BFR on 14/04/2021.
 */
define('adminImprintTechnologiesController',[
  'module',
  'jquery',
  'underscore',
  'app',
  'adminImprintTechnologiesView',
  'settings',
  'bootbox',
  'entities/admin/imprint_technologies'
], function(
  module,
  $,
  _,
  App,
  View,
  Settings,
  bootbox
) {
  'use strict';

  module.exports = {

    onGridLoaded: function() {
      var data = {
          url: App.request('imprint-technologies:get-url'),
          rowCountUrl: App.request('imprint-technologies:row-count-url')
        };

      this.view.displayGrid(data, true);
    },

    _showView: function() {
      this.view = new View({
      });

      this.view.listenTo(this.view, 'imprint-technologies:remove', _.bind(this.onDelete, this));
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    onDelete: function(secId){
        bootbox.confirm(_.i18n('customer.confirmDelete'), _.bind(function(result) {
            if(result){
                App.request('imprintTechnology:delete', secId)
                    .done(_.bind(function(){
                        this.view.updatePagination(true);
                    }, this));
            }
        }, this));
    }
  };
});
