define('caccountConfigGmailView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!caccountConfigGmailTpl',
  'gmailUtils'
], function (
  module,
  Marionette,
  App,
  _,
  viewTpl,
  GmailUtils
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click .btn-connect-gmail': 'onClickConnect',
      'click .btn-disconnect-gmail': 'onClickDisconnect'
    },

    onClickConnect: function () {
      this.gmailUtils.signIn();
    },

    onClickDisconnect: function () {
      this.gmailUtils.signOut();
    },

    initialize: function () {
      if (this.gmailUtils) {
        this.gmailUtils.off('gmail_utils:loaded');
        this.gmailUtils.off('gmail_utils:signInNeeded');
      }

      if (!this.gmailUtils) {
        this.gmailUtils = GmailUtils.getInstance();
      }

      this.onGmailLoaded = _.bind(function () {
        this.connectedUser = this.gmailUtils.getConnectedUser();
        this.render();
      }, this);

      this.onGmailSignInNeeded =  _.bind(function() {
        this.connectedUser = null;
        this.render();
      }, this);

      this.gmailUtils.on('gmail_utils:loaded', this.onGmailLoaded);
      this.gmailUtils.on('gmail_utils:signInNeeded', this.onGmailSignInNeeded);
      this.gmailUtils.connect();
    },

    serializeData: function () {
      return {
        connectedUser: this.connectedUser
      };
    },

    onDestroy: function () {
      this.gmailUtils.off('gmail_utils:loaded', this.onGmailLoaded);
      this.gmailUtils.off('gmail_utils:signInNeeded', this.onGmailSignInNeeded);
    }
  });
});
