
define('template!uploadFileTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="change-logo-input">\n  <i class="mdi-file-file-upload clickable flat-primary"></i>\n</label>\n<input type="file" class="hidden js-change-logo" id="change-logo-input" accept="image/*">';

}
return __p
};});

