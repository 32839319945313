/**
 * Created by RKL on 13/08/2015.
 */
define('caccountSAGraphicalRequestsTabView',[
  'module',
  'marionette',
  'underscore',
  'jquery',
  'app',
  'settings',
  'dropdownCompositeView',
  'template!superAdminGraphicalRequestsTabTpl',
  'dropzone'
], function (
  module,
  Marionette,
  _,
  $,
  App,
  Settings,
  DropdownCompositeView,
  viewTpl,
  Dropzone

) {
  'use strict';

  var CAccountsSAGraphicalRequestsTabView = Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
    },

    events:  {
      'change input[type=radio]': 'onRadioOptionChange'
    },

    regions: {
      dropdownOutputFileRegion: '.output-file-dropdown-region'
    },

    serializeData: function() {
      return {
        outputFiles: this.options.outputFiles
      };
    },

    onShow: function() {
      $.material.init();
    },

    onRender: function() {
      var self = this;
      // create ext dropdown for third step
      this.getRegion('dropdownOutputFileRegion').show(new DropdownCompositeView(
        {
          modelProperty: 'extension',
          filter: {key: 'isVectorFormat', value: true},
          collection: this.options.outputFileCollection.clone(),
          onItemSelected: function(model) {
            self.outputFile = model.get('extension');
          },
          onItemPreselected: function(model) {
            self.outputFile = model.get('extension');
          }
        }));
      this.initDropZone();
      this.filterOutPutFileCollection('vectorization');
    },

    onRadioOptionChange: function(e) {
      var $target = $( e.currentTarget);
      if ($target.is(':checked')) {
        this.filterOutPutFileCollection($target.val());
      }
    },

    initDropZone: function() {
      Dropzone.autoDiscover = false;
      //Override method for hot fix
      Dropzone.prototype.updateTotalUploadProgress = function() {
        var activeFiles, file, totalBytes, totalBytesSent, totalUploadProgress, _i, _len, _ref;
        totalBytesSent = 0;
        totalBytes = 0;
        activeFiles = this.getActiveFiles();
        if (activeFiles.length) {
          //Bugfix
          var sentFiles = this.getFilesWithStatus(Dropzone.SUCCESS);
          for (var _iSent = 0; _iSent < sentFiles.length; _iSent++) {
            totalBytesSent += sentFiles[_iSent].upload.bytesSent;
            totalBytes += sentFiles[_iSent].upload.total;
          }
          //Bugfix end
          _ref = this.getActiveFiles();
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            file = _ref[_i];
            totalBytesSent += file.upload.bytesSent;
            totalBytes += file.upload.total;
          }
          totalUploadProgress = 100 * totalBytesSent / totalBytes;
        } else {
          totalUploadProgress = 100;
        }
        return this.emit('totaluploadprogress', totalUploadProgress, totalBytes, totalBytesSent);
      };
      var self = this;
      var eventHandlers = {
        'processing': function() {
          this.options.url = Settings.url('compuzz', 'tickets/mass',
            {cAccountSecId: self.options.cAccountSecId,
              outputFile: self.outputFile });
        },

        'queuecomplete': function() {
          this.options.autoProcessQueue = false;
          self.$('#add-files,#submit,#cancel').prop('disabled', false);
        },

        'totaluploadprogress': function(uploadProgress) {
          self.$('#total-progress .progress-bar').css('width', uploadProgress + '%');
        }

      };
      var config = {
        url: Settings.url('compuzz', 'tickets/mass',
          {cAccountSecId: self.options.cAccountSecId,
          outputFile: self.outputFile }),
        autoProcessQueue: false,
        dictDefaultMessage : '',
        clickable:  self.$('#add-files')[0],
        error: function(file, response) {
          var message;
          if($.type(response) === 'string')
            message = response; //dropzone sends it's own error messages in string
          else
            message = response.label;
          file.previewElement.classList.add('dz-error');
          var _ref = file.previewElement.querySelectorAll('[data-dz-errormessage]');
          var _results = [];
          var node, _i, _len;
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            node = _ref[_i];
            _results.push(node.textContent = message);
          }
          return _results;
        },
        init: function () {
          _.each(eventHandlers, function(handler, event) {
            this.on(event, handler);
          }, this);
          self.$('#submit').off('click' ).on('click', _.bind(function(e){
            if(self.$('.form-control:invalid' ).length === 0 &&
               self.$('.form-control[required][value=]' ).length === 0) {
              self.$('#add-files,#submit,#cancel').prop('disabled', true);
              this.options.autoProcessQueue = true;
              this.processQueue();
            } else {
              e.preventDefault();
              e.stopPropagation();
            }
          }, this));
          self.$('#cancel').off('click' ).on('click', _.bind(function(){
            this.options.autoProcessQueue = false;
            this.removeAllFiles(true);
            self.$('#add-files,#submit,#cancel').prop('disabled', false);
          }, this));
        }
      };
      this.$('#mass-upload-dropzone').dropzone(config);
    },

    filterOutPutFileCollection: function(ticketType) {
      switch (ticketType) {
        case 'vectorization':
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', true);
          break;
        case 'remove_background':
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', false);
          break;
        case 'conversion':
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty();
          break;
        default:
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty();


      }
    }
  });

  module.exports = CAccountsSAGraphicalRequestsTabView;
});
