define('caccountCardsColorView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!caccountCardsColorTpl'
], function (
  module,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      deleteButton: '.delete'
    },

    events: {
      'change .txt-name': 'onInputChange',
      'click @ui.deleteButton': 'onDelete'
    },

    onInputChange: function (e) {
      this.model.set('name', this.$el.find(e.currentTarget).val());
    },

    onDelete: function () {
      var collection = this.model.collection;
      collection.remove(this.model);
    }
  });
});
