/**
 * Created by BFR on 10/06/2020.
 */
define('productStockTabView',[
  'settings',
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'dateUtils',
  'template!productStockTabTpl'
], function(
  Settings,
  module,
  Marionette,
  App,
  $,
  _,
  DateUtil,
  viewTpl
) {
  'use strict';

  var ProductStockView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      tableDiv:'.js-table-div'
    },
    serializeData: function() {
      var templateData = {};
      templateData.stock = this.options.model;
      _.each(templateData.stock, function(st) {
        if (st.newArrivalETA) {
          st.newArrivalETA = DateUtil.toDateFormatString(st.newArrivalETA);
        }
      });
      return templateData;
    },
    onShow: function() {
      this.ui.tableDiv.height(this.$el.parent().height()-100);
    }

  });

  module.exports = ProductStockView;
});

