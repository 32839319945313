/**
 * Created by BFR on 16/09/2022.
 */
define('emailAttachmentViewOld',['module',
    'marionette',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!emailAttachmentTpl'],
  function (module,
            Marionette,
            _,
            $,
            DialogFormView,
            EmailAttachmentTpl) {
    'use strict';

    module.exports = DialogFormView.extend({
      template: EmailAttachmentTpl,
      className: '',

      ui: {
        pdfCanvas: '.pdf-canvas',
        image: '.image',
        loading: '.js-loading-block'
      },

      events: {
        'click .js-remove-icon': 'onDeleteClick'
      },

      serializeData: function () {
        return {
          hideDelete: this.options.hideDelete
        };
      },

      onDeleteClick: function () {
        this.model.destroy();
      },

      onShow: function () {
        var that = this;

        function _arrayBufferToBase64(buffer) {
          var binary = '';
          var bytes = new Uint8Array(buffer);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        }

        function reqListener(result) {
          that.arrayBuffer = result.currentTarget.response;
          that.model.set('base64', _arrayBufferToBase64(result.currentTarget.response));
          that.model.set('content-disposition', result.currentTarget.getResponseHeader('content-disposition'));
          that.model.set('content-type', result.currentTarget.getResponseHeader('content-type'));
          that.render();
        }

        var req = new XMLHttpRequest();
        req.addEventListener('load', reqListener);
        req.open('GET', this.model.get('url'));
        req.responseType = 'arraybuffer';
        req.send();
      },

      onRender: function () {
        if(this.model.get('type') === 'pdf') {
          this.ui.pdfCanvas.css('display', 'flex');
          this.ui.image.hide();
          this.renderPdf();
        } else {
          this.ui.pdfCanvas.hide();
          this.ui.image.css('display', 'flex');
          this.renderImage();
        }
      },

      renderPdf: function () {
        if (this.model.get('base64')) {

          // Loaded via <script> tag, create shortcut to access PDF.js exports.
          var pdfjsLib = window['pdfjs-dist/build/pdf'];

          // The workerSrc property shall be specified.
          pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

          // Asynchronous download of PDF
          var loadingTask = pdfjsLib.getDocument(this.arrayBuffer);
          loadingTask.promise.then(_.bind(function (pdf) {
            //console.log('PDF loaded');

            // Fetch the first page
            var pageNumber = 1;
            pdf.getPage(pageNumber).then(_.bind(function (page) {
              //console.log('Page loaded');

              var scale = 1.5;
              var viewport = page.getViewport({scale: scale});

              // Prepare canvas using PDF page dimensions
              var canvas = this.ui.pdfCanvas[0];
              var context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              };
              var renderTask = page.render(renderContext);
              renderTask.promise.then(function () {
                //console.log('Page rendered');
              });
            }, this));
          }, this), _.bind(function (reason) {
            this.destroy();
            // PDF loading error
            console.error(reason);
          }, this));
        } else {
          this.ui.loading.show();
        }
      },

      renderImage: function () {
        if (this.model.get('url')) {
          this.ui.image.attr('src', this.model.get('url'));
        }
      }
    });
  });
