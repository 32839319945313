/**
 * Created by OLD on 25/11/2015.
 */
define('pricelistAddNewPopupView',[
  'module',
  'template!pricelistAddNewPopupTpl',
  'dialogFormView'
], function(
  module,
  viewTpl,
  DialogFormView
  ) {
  'use strict';

  var AddNewView = DialogFormView.extend({
    template: viewTpl,

    ui: {
      input: '.js-form-input',
      inputAlgo: '.input-priceAlgo',
      select: '.select-priceAlgo'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus',
      'change @ui.inputAlgo': 'changeExample',
      'change @ui.select': 'changeExample'
    },

    onShow: function() {
      $.material.init();
      this.ui.select.change();
    },

      changeExample: function() {
          var basePrice = 50;
          var label = '';
          var param = this.$el.find('.input-priceAlgo').val();
          if(param){
              param = parseFloat(param);
          }
          else{
              this.$el.find('.input-priceAlgo').val('1.5');
              param = 1.5;
              this.model.set('algoParam1', param);
          }

          switch(this.$el.find('.select-priceAlgo').val()){
              case 'RSP_MULTIPLY':
                  label += (basePrice+ '€' + ' x (' + param  + ') = ' + ( basePrice * param))+ '€';
                  break;
              case 'RSP_ADD':
                  label += (basePrice+ '€' + ' + (' + param  + ') = ' + ( basePrice + param)) + '€';
                  break;
          }


          if(label){
              this.$el.find('.price-algo-example').html(label);
          }
      }
  });

  module.exports = AddNewView;
});
