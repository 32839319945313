define('pimAttributeValuesView',[
  'module',        
  'marionette',
  'pimAttributeValueView'
], function(
  module,           
  Marionette,
  PimAttributeValueView
){
  'use strict';

  var PimAttributeValuesView = Marionette.CollectionView.extend({
    childView: PimAttributeValueView, 
    className: 'list',
    childViewOptions: function() {     
      return {
        attributeModel: this.options.attributeModel
      };
    },
    onRender: function () {
      if(this.options.viewModel.get('currentSecId') !== '') {
        this.collection.fetch(this.options.viewModel.get('currentSecId'));
      }
    }
  });

  module.exports = PimAttributeValuesView;
});
