/**
 * Created by OLD on 20/08/2015.
 */
define('caccountAddCreditsPopupView',[
  'module',
  'settings',
  'template!caccountAddCreditsChooseMethodPopupTpl',
  'template!caccountAddCreditsBankTransferPopupTpl',
  'template!caccountAddCreditsPopupTpl',
  'template!caccountAddCreditStripesPopupTpl',
  'dialogFormView',
  'stateMessages',
  'jquery',
  'app',
  'underscore',
  'template!emptyViewTpl',
  'entities/caccounts/credits'
], function(
  module,
  Settings,
  chooseMethodTpl,
  bankTransferTpl,
  viewTpl,
  stripeTransfertTpl,
  DialogFormView,
  StateMessages,
  $,
  App,
  _,
  emptyTpl
  ) {
  'use strict';

  var AddCreditPopupView = DialogFormView.extend({
    template: viewTpl,
    chooseMethodTpl: chooseMethodTpl,
    bankTransferTpl: bankTransferTpl,
    stripeTransfertTpl: stripeTransfertTpl,
    loadingTpl: emptyTpl,

    ui: {
      input: '.radio-chooser input',
      creditInput: '.input-number-quantity',
      payPalBtn: '.credit-paypal-btn',
      stripeTransfertBtn: '.stripetransfert-btn',
      bankTransfertBtn: '.banktransfert-btn',
      modalFooterBtn: '.modal-footer button',
      needHelp: '.need-help-link',
      bankTransferApply: '.banktransfer-apply-b',
      stripePrice: '.stripe-price',
      stripePriceAdd: '.stripe-price-add',
      stripePriceVat: '.stripe-price-vat',
      stripePriceTotal: '.stripe-price-total',
      stripeTaxPercentage: '.stripe-tax-percentage',
      stripeLoader: '.stripe-loader',
      stripeContainer: '.stripe-container'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'change @ui.creditInput' : 'onInputChange',
      'focus @ui.creditInput': 'onFocus',
      'click @ui.payPalBtn' : 'choosePayPalMethod',
      'click @ui.bankTransfertBtn' : 'chooseBankTransferMethod',
      'click @ui.bankTransferApply' : 'applyBankTransferBtn',
      'click @ui.stripeTransfertBtn' : 'chooseStripeMethod'
    },

    modelEvents: {
      change: 'onChangeModel'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    serializeData: function() {
      return {
        cAccountName: Settings.get('currentCAccount' ).code,
        quantity: this.model.get('quantity'),
        stripeUrl: Settings.url('compuzz', 'credits.json/refund/stripe')
      };
    },

    initialize: function() {
      // Create a Stripe client
      /*jshint newcap: false */
      this.stripe = window.Stripe(Settings.get('stripeApiKey'));
    },

    onFocus: function(e) {
      var $target = $(e.currentTarget),
        type = $target.attr('type');

      if (type !== 'radio') {
        $('input[type="radio"]').prop('checked', false);
        $('input[type="radio"]').removeAttr('checked');
      }
    },

    onChangeModel: function() {
      this.ui.creditInput.val(this.model.get('quantity')).change();
      this.refreshPriceTotal();
    },

    choosePayPalMethod: function () {
      this.trigger('creditpopup:choose-paypal');
    },

    chooseStripeMethod: function () {
      this.trigger('creditpopup:choose-stripe');
    },

    chooseBankTransferMethod: function() {
      this.trigger('creditpopup:choose-banktransfer');
    },

    applyBankTransferBtn: function() {
      this.trigger('creditpopup:apply-banktransfer');
    },

    onRender: function () {
      $.material.init();

      if (this.options.stripe) {
        // Create an instance of Elements
        var elements = this.stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
          base: {
            color: '#32325d',
            lineHeight: '24px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        };

        // Create an instance of the card Element
        var card = elements.create('card', {style: style});

        // Add an instance of the card Element into the `card-element` <div>
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
          var displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });

        // Handle form submission
        var form = document.getElementById('payment-form');
        form.addEventListener('submit', _.bind(function(event) {
          event.preventDefault();
          this.ui.stripeContainer.hide();
          this.ui.stripeLoader.show();

            this.stripe.createPaymentMethod(
                'card',
                card
            ).then(_.bind(function(result) {
                if (result.error) {
                    // Display result.error.message in UI
                } else {
                    // The PaymentMethod has successfully
                    // been created
                    this.stripeResponseHandler(result);
                }
            }, this));

          //this.stripe.createSource(card).then(_.bind(this.stripeResponseHandler, this));
        }, this));

        this.refreshPriceTotal();
      }
    },

    refreshPriceTotal: function() {
      if (this.model.get('quantity')) {
        var refundStripe = App.request('caccount-credits:getPriceDetail', this.model.get('quantity'));
        $.when(refundStripe).done(_.bind(function(detail){
          if (detail.add) {
            this.ui.stripePriceAdd.parent().show();
          } else {
            this.ui.stripePriceAdd.parent().hide();
          }
          this.ui.stripePrice.html(detail.total + ' &#8364;');
          this.ui.stripePriceAdd.html(detail.add + ' &#8364;');
          this.ui.stripePriceVat.html((detail.tax?detail.tax:'0') + ' &#8364;');
          this.ui.stripePriceTotal.html(detail.totalInclTax + ' &#8364;');
          var tax = detail.tax ? parseFloat(detail.tax) : 0;
          var credit = parseFloat(detail.total);
          var fee = detail.add ? parseFloat(detail.add) : 0;
          var convertTax = ( tax / ( credit + fee ) ) * 100;
          this.ui.stripeTaxPercentage.html((convertTax?convertTax.toFixed():0) + '&#37;');
        }, this));
      }
    },

    stripeResponseHandler: function(response) {
      // Grab the form:
      if (response.error) { // Problem!
          this.trigger('creditpopup:error-stripe', response.error.message);
          this.hide();
      } else { // Source was created!

        var refundStripe = App.request('caccount-credits:refundStripe2', response.paymentMethod.id, this.model.get('quantity'));
        $.when(refundStripe).done(_.bind(function(result){
          this.handleServerResponse(result);
        }, this));
      }
    },

      /*jshint camelcase: false */
    handleServerResponse: function (response) {
        if (response.error) {
            this.trigger('creditpopup:error-stripe', response.error.message);
            this.hide();
        } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            this.handleAction(response);
        } else {
            // Show success message
            this.trigger('creditpopup:success-stripe');
            this.hide();
        }
    },

    handleAction: function (response) {
        this.stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(_.bind(function(result) {
            if (result.error) {
                this.trigger('creditpopup:error-stripe', result.error.message);
                this.hide();
            } else {
                var refundStripe = App.request('caccount-credits:placeStripe2', result.paymentIntent.id);
                $.when(refundStripe).done(_.bind(function(result) {
                    this.handleServerResponse(result);
                }, this));
            }
        }, this));
    },

    onDomRefresh: function () {
      if (this.options.paypal) {
        $('.add-credits-popup').find('.modal-footer').removeClass('hidden');
        this.ui.needHelp.attr('style', 'bottom:-1em;');
      } else {
        $('.add-credits-popup').find('.modal-footer').addClass('hidden');
      }
    },

    resetOptions: function() {
      this.options.paypal = false;
      this.options.bankTransfer = false;
      this.options.stripe = false;
      this.options.loading = false;
    },

    getTemplate: function() {
      if (this.options.paypal) {
        return this.template;
      } else if (this.options.bankTransfer) {
        return this.bankTransferTpl;
      } else if (this.options.stripe) {
        return this.stripeTransfertTpl;
      } else if (this.options.loading) {
        return this.loadingTpl;
      } else {
        return this.chooseMethodTpl;
      }
    }
  });

  module.exports = AddCreditPopupView;
});
