define('userSelectorUsersView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'backbone',
  'userUtils',
  'userSelectorUserView'
], function (
  App,
  Module, 
  Marionette,
  _,
  Backbone,
  UserUtils,
  UserView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'user-selector-users-view',
    childView: UserView,

    childEvents: {
      'onSelect': function (view) {
        this.trigger('onAssign', view.model);
      }
    },
    
    initialize: function () {  

      this.filterVal = '';

      $.when(App.request('kanban-tickets:get-users')).done(_.bind(function (users) {      
        this.collection = new Backbone.Collection(UserUtils._parseUsers(users)); 
        this.render();
      }, this));        
    },  

    update: function (filterVal) {  
      this.filterVal = filterVal;
      this.render();
    },

    filter: function (model) {
      if(this.filterVal === '')
        return true;

      if(model.get('fullName').toLowerCase().indexOf(this.filterVal.toLowerCase()) >= 0 || 
      model.get('email').toLowerCase().indexOf(this.filterVal.toLowerCase()) >= 0)
        return true;
      else
        return false;
    }   
  });
});
