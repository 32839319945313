
define('template!caccountAddCreditsBankTransferPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="my-vector-popup-container">\n        <form class="form-horizontal">\n            <fieldset>\n\t            <div class="col-xs-12">\n\t            \t<div class="form-group text-center">\n\t\t                <div class="default-image m-b-10"></div>\n\t\t                <div><span>' +
((__t = ( _.i18n("credit.popup.transfer.line1") )) == null ? '' : __t) +
'</span></div>\n\t\t            </div>\n\t\t            <div class="form-group text-left">\n\t\t            \t<div class="m-b-10"><span>' +
((__t = ( _.i18n("credit.popup.transfer.line2") )) == null ? '' : __t) +
'</span></div>\n\t\t            \t<div class="m-b-10"><span>' +
((__t = ( _.i18n("credit.popup.transfer.line3") )) == null ? '' : __t) +
'</span></div>\n\t\t            \t<div class="m-b-10"><span>' +
((__t = ( _.i18n("credit.popup.transfer.line4") )) == null ? '' : __t) +
'</span></div>\n\t\t            \t<div class="text-right"><span>' +
((__t = ( _.i18n("credit.popup.transfer.line5") )) == null ? '' : __t) +
'</span></div>\n\t\t            \t<div class="text-right"><span>' +
((__t = ( _.i18n("credit.popup.transfer.line6") )) == null ? '' : __t) +
'</span></div>\n\t\t            </div>\n\t\t        </div>\n\t\t        <div class="col-xs-12 p-l-0 p-r-0">\n\t\t        \t<hr>\n\t\t        </div>\n\t\t        <div class="col-xs-6">\n\t\t        \t<div class="form-group text-left">\n\t\t                <div><label>Eventus SPRL</label></div>\n\t\t                <div><label>IBAN:</label><span> BE75 1030 5958 5451</span></div>\n\t\t                <div><label>BIC:</label><span> NICABEBB</span></div>\n\t\t                <div><label>REF:</label><span> ' +
((__t = ( cAccountName )) == null ? '' : __t) +
'*</span></div>\n\t\t                <div class="m-t-10">\n\t\t                \t<span class="normall-bold-compuzz">*' +
((__t = ( _.i18n("credit.popup.transfer.reminder") )) == null ? '' : __t) +
'</span>\n\t\t                </div>\n\t\t            </div>\n\t\t        </div>\n\t\t        <div class="col-xs-6">\n\t\t        \t<div class="form-group text-left">\n\t\t                <div><span>Eventus SPRL</span></div>\n\t\t                <div><span>rue Perkiets 4</span></div>\n\t\t                <div><span>B-4837 Membach - Belgium</span></div>\n\t\t                <div><label>Tel:</label><span> +32 4268 11 79</span></div>\n                        <div><label>Vat:</label><span> BE875865260</span></div>\n                        <div><label>Email:</label><span> billing@compuzz.com</span></div>\n\t\t            </div>\n\t\t        </div>\n\t\t        <div class="col-xs-12 p-r-0">\n\t\t        \t<a class="btn btn-primary pull-right banktransfer-apply-b">' +
((__t = ( _.i18n("common.ok") )) == null ? '' : __t) +
'</a>\n\t\t        </div>\n            </fieldset>\n        </form>\n    </div>\n</div>';

}
return __p
};});

