/**
 * Created by BFR on 18/02/2020.
 */
define('glossaryPopupView',[
    'module',
    'app',
    'underscore',
    'settings',
    'template!glossaryPopupTpl',
    'dialogView',
    'autocompleteView',
    'entities/product_translation'
  ], function(
    module, 
    App,
    _,
    Settings,
    viewTpl,
    DialogView,
    AutocompleteView
  ) {
    'use strict';
  
    var GlossaryPopupView = DialogView.extend({
      template: viewTpl,
      
      regions: {
        glossaryAutoComplete: '.glossary-auto-complete'
      },
  
      ui: {
        confirmButton: '.js-confirm'
      },
  
      events: {
        'click .js-cancel-popup': 'onCancelClick',
        'click @ui.confirmButton': 'onConfirmClick'
      },

      onCancelClick: function() {
        this.model.set(this.backup);
        this.model = null;
        this.hide();
      },

      glossarySecId: null,

      onConfirmClick: function() {
        var glossary = App.request('products-translation:glossary-model', { secId: this.glossarySecId });
        glossary.fetch().done(_.bind(function() {
            this.model.set('refGlossary', glossary);
            this.model.save().done(_.bind(function() {
                this.model = null;
                this.trigger('onSave');
                this.hide();
              }, this));
        }, this));
      },

      onShow: function() {
        $.material.init();
        var glossaryAutoComplete = new AutocompleteView(
            this.getAutocompleteOptions()
        );
        this.getRegion('glossaryAutoComplete').show(glossaryAutoComplete);
      },

      getAutocompleteOptions: function() {
        return {
            type: 'remote',
            alwaysOpen: true,
            remote: Settings.url('compuzz2', 'dynamictexts.json/glossaries', { owner: this.options.owner }),
            valueKey: 'name',
            apiKey: 'name',
            placeholder: _.i18n('selecteglossary.placeholder'),
            value: null,
            name: 'name',
            onPressKey: true,
            lazyLoad: true,
            keys: {
                query: 'name',
                limit: 'pageSize'
            },
            callback: _.bind(this.onSelectGlossary, this)
        };
      },

      onSelectGlossary: function(field, model) {
        if (model) {
            this.ui.confirmButton.prop('disabled', false);
            this.glossarySecId = model.get('secId');
        }
      },

      serializeData: function () {
        this.backup = this.model.toJSON();
        var templateData = this.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = GlossaryPopupView;
  });
