define('graphicalRequestKanbanMain',[
  'module',
  'logger',
  'graphicalRequestKanbanRouter'
], function(
  module,
  Logger
) {
  'use strict';
  
  var GraphicalRequestKanban = {
    start: function() {
      Logger.log(module, 'starting GraphicalRequestKanbanModule');
    },

    stop: function() {
      Logger.log(module, 'stopping GraphicalRequestKanbanModule');
    }
  };

  module.exports = GraphicalRequestKanban;
});
