/**
 * Created by BFR on 24/06/2020.
 */
define('caccountSupplierFactorView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!caccountSupplierFactorTpl'
], function (
  module,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  var SupplierFactorView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      txtFactor: '.txt-factor'
    },

    events: {
      'change @ui.txtFactor': 'onTxtFactorChange'
    },

    onTxtFactorChange: function () {
      this.model.set('factor', Number(this.ui.txtFactor.val().replace(',', '.')));
      this.model.save();
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      return templateData;
    }
  });

  module.exports = SupplierFactorView;
});
