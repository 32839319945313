define('contentView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'settings',  
  'tawlkToBehavior',
  'plivoUtils',
  'rolesMixin',
  'headerView',
  'template!contentView'
], function (
  Module,
  Marionette,
  $,
  _,
  Settings,
  TawlkToBehavior,
  PlivoUtils,
  RolesMixin,
  HeaderView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'content-view',

    regions: {
      headerRegion: '.header-region',
      sidebar: '#sidebar-region',
      content: '#content-region',
      orderRegion: '.orders-detail-region',
      zoom: '#gallery-zoom'
    },

    ui: {
      aside: '.aside-md'
    },  

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    onShow: function() {
      PlivoUtils.refreshInterface();

      this.headerView = new HeaderView();
      this.getRegion('headerRegion').show(this.headerView);
    },       

    onSidebarShow: function() {
      if (this.ui.aside.hasClass('nav-off-screen')) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .hide();
      } else {
        this.ui.aside
          .addClass('nav-off-screen')
          .show();
      }
    },

    onResizeSidebar: function() {
      if (this.ui.aside.hasClass('nav-xs')) {
        this.ui.aside.removeClass('nav-xs');
      } else {
        this.ui.aside.addClass('nav-xs');
      }
    },

    onResizeWindow: function () {
      var width = $(document.body).width();

      if (width > 768) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .show();
      }
      if (width < 767) {
        this.ui.aside.removeClass('nav-xs');
      }
    }
  });
});
