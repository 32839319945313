define('webshopProductsDetailBodyCategoriesView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'windows',
  'tableView',
  'tableDatetimeFieldView',
  'webshopCategoriesDetailView',
  'webshopCategoriesListImageView',
  'webshopCategoriesListInfosView',
  'webshopCategoriesListStatusView',
  'webshopCategoriesListFiltersView',
  'webshopProductsDetailBodyCategoriesToolsView',
  'webshopProductsDetailBodyCategoriesHeaderToolsView',
  'webshopProductsDetailBodyCategoriesTagsView',
  'entities/webshop/categories'
], function (
  Module,
  _,
  App,
  Backbone,
  Windows,
  TableView,
  DatetimeFieldView,
  DetailView,
  ImageView,
  InfosView,
  StatusView,
  FiltersView,
  ToolsView,
  HeaderToolsView,
  TagsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-products-detail-body-categories-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });

      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'createdAt',
        className: 'date',
        view: DatetimeFieldView
      });

      fields.push({
        name: 'Infos',
        label: _.i18n('common.category'),
        className: 'infos',
        view: InfosView
      });

      fields.push({
        name: 'Tags',
        label: _.i18n('common.tags'),
        className: 'tags',
        view: TagsView
      });

      fields.push({
        name: 'Status',
        label: _.i18n('common.status'),
        className: 'status',
        view: StatusView
      });

      fields.push({
        name: 'Tools',
        className: 'tools',
        headerView: HeaderToolsView,
        view: ToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('webshop.product.categories', this.model.getId(), params),
        success: _.bind(function (products) {
          var models = [];

          _.each(products, _.bind(function(item) {
            var model = App.request('webshop.category.model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.product.categories.count', this.model.getId(), params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(key !== 'dateFrom' &&
            key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    },

    onRowDoubleClick: function(view) {
      Windows.open({
        view: _.bind(function () {
          return new DetailView({
            id: view.model.getId()
          });
        }, this),
        id: 'webshop.category:' + view.model.getId(),
        title: _.i18n('webshop.category.title'),
        width: '80%',
        height: '80%'
      });
    }
  });
});
