
define('template!editPrintAreaTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="printarea" style="position: relative;">\n  <div class="row">\n    <div class="col-xs-3">\n      <div class="pa">\n        <div class="pa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="pa-info">\n              <div class="pa-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n              <div class="pa-size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-9">\n      <div class="pz">\n        <div class="pz-title">\n          Print zones\n          <i class="mdi mdi-av-my-library-add btn-add-printzone clickable p-l-10"\n             style="font-size: 18px;"></i>\n        </div>\n        <div class="row pz-header">\n          <div class="col-xs-1 imppCode">Imprint.</div>\n          <div class="col-xs-4 name">Name</div>\n          <div class="col-xs-2 size">Size</div>\n          <div class="col-xs-2 setupCode">Setup</div>\n          <div class="col-xs-1 unitCode">Code</div>\n        </div>\n        <div class="pz-region"></div>\n      </div>\n    </div>\n  </div>\n  <div class="btn-container btn-pa-container" style="display: none;">\n    ';
 if (!readOnly) { ;
__p += '\n    <i class="mdi mdi-image-color-lens btn-edit-xml btn-frame"></i>\n    <i class="mdi mdi-action-delete btn-delete-pa btn-frame"></i>\n    <i class="mdi mdi-editor-mode-edit btn-edit-pa btn-frame"></i>\n    ';
 } ;
__p += '\n    ';
 if (textureTemplate3dUrl) { ;
__p += '\n    <div title="3D texture template" class="pull-right clickable" style="display: inline">\n      <a download="' +
((__t = ( code )) == null ? '' : __t) +
'.png" href="' +
((__t = ( textureTemplate3dUrl )) == null ? '' : __t) +
'"><svg class="btn-3d-texture" width="17px" height="18px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M12 6L6 12" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M42 36L36 42" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 6L6 22" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M32 6L6 32" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M42 6L6 42" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M42 16L16 42" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M42 26L26 42" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg></a>\n    </div>\n    ';
 } ;
__p += '\n  </div>\n</div>';

}
return __p
};});

