/**
 * Created by BFR on 07/04/2022.
 */
define('pimProductCompetitorsView',['module',
    'marionette',
    'app',
    'underscore',
    'productCompetitorView',
    'template!productCompetitorsTpl',
    'competitorCreateEditLayoutView',
    'entities/pim_product_competitor'
  ],
  function (module,
            Marionette,
            App,
            _,
            ProductCompetitorView,
            itemTpl,
            CompetitorCreateEditLayoutView) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
      template: itemTpl,
      childViewContainer: '.competitors-items',
      childView: ProductCompetitorView,

      initialize: function () {
      },

      events: {
        'click .btn-add-competitor': 'onBtnAddCompetitorClick'
      },

      onBtnAddCompetitorClick: function () {
        var competitorCreateEditLayoutView = new CompetitorCreateEditLayoutView({
          model: App.request('pim:product-competitor:model', {productSecId: this.options.model.getCurrentResProduct().get('id')}),
          collection: this.collection,
          newItem: true
        });

        competitorCreateEditLayoutView.show({
          title: _.i18n('competitors.title')
        });
      }
    });
  });
