
define('template!caccountObjectNamesAddTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <form class="form-horizontal">\n      <fieldset>\n        \n       <!-- Code  -->\n        <div class="form-group" data-field-name="code">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
' *" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Name  -->\n        <div class="form-group" data-field-name="name">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
' *" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Type -->\n        <div class="form-group" data-field-name="name">\n          <div class="col-xs-12">\n            <div class="object-type-label">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</div>\n            <div class="type-select"></div>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

