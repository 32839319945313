define('entities/common',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings',
  'backboneRelational',
  'entities/common'
], function(
  App,
  $,
  Backbone,
  _,
  Settings
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.CommentModel = Backbone.RelationalModel.extend();

  /**
   * Model
   */
  /**
   * REST API
   */
  var API = {


    getRowCount: function(urlParams,requestOnNewBackend) {
      var params = {},
        backendDomain= (requestOnNewBackend)?'compuzz2':'compuzz',
      defer = $.Deferred();

      _.each(urlParams.filters, function (item, key) {
        if (item) {
          params[key] = item;
        }
      });
       $.ajax({
        url: Settings.url(backendDomain, urlParams.rowCountUrl, params),
        success: function(result) {
          defer.resolve(result);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('common:get-row-count', function(urlParams,requestOnNewBackend) {
    return API.getRowCount(urlParams,requestOnNewBackend);
  });


  App.reqres.setHandler('common:comment:model', function (modelJson) {
    if (!modelJson) {
      modelJson = {};
    }
    return app.ns.CommentModel.findOrCreate(modelJson);
  });

  App.reqres.setHandler('common:countries', function () {
    var defer = $.Deferred();
    if (!Settings.get('countries')) {
      App.request('caccount:countries').done(function(countries) {
        Settings.set('countries', countries.toJSON());
        defer.resolve(Settings.get('countries'));
      });
    } else
      defer.resolve(Settings.get('countries'));

    return defer.promise();
  });
});

