define('webshopCategoriesListImageView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'galleryView',
  'tableFieldView',
  'template!webshopCategoriesListImageView',
  'entities/product'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  GalleryView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-list-image-view ' + FieldView.prototype.className,

    modelEvents: {
      'change:medias': 'render'
    },

    events: {
      'click': 'onClick'
    },

    serializeData: function() {
      var data = {};
      data.src = this.model.getPreviewImageUrl();
      return data;
    },

    onClick: function () {
      var urls = this.model.getImageUrls();
      if(urls.length === 0)
        return;

      GalleryView.prototype.open(
          'webshop.category.gallery.' + this.model.getId(),
          _.i18n('webshop.category.gallery.title') + ' | ' + this.model.getName(),
          new Backbone.Model({urls: [this.model.getImageUrls()]}));
    }
  });
});
