define('ordersDetailInvoicesSentDateView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'dateUtils',
  'template!ordersDetailInvoicesSentDateView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  DateUtils,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-sent-date-view ' + FieldView.prototype.className,

    modelEvents: {
      'change:sentDate': 'render'
    },

    serializeData: function() {
      var data = {};
      data.date = '-';

      if(this.model.get('sentDate'))
        data.date = DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));

      return data;
    }
  });
});
