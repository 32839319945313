define('caccountListView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!caccountListTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CaccountListView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountsListFilters',

    triggers: {
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    serializeData: function() {
      var templateData = {type: this.options.data.type};

      return templateData;
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    gridOptions: function(data) {
      var options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              classes: 'sub-ca-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'name'
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              classes: 'sub-ca-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'code'
            }, {
              label: _.i18n('common.email'),
              name: 'contactEmail',
              classes: 'sub-ca-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'contactEmail'
            }, {
              label: _.i18n('common.country'),
              name: 'country',
              classes: 'sub-ca-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'country'
            }
          ],

          onSelectRow: function(rowid, bool, e) {
            var currentCAccount = Settings.get('currentCAccount');

            if (e.type === 'click') {
              if (rowid === currentCAccount.secId) {
                App.trigger('cAccount:show', rowid, false, 'caccount');
              } else {
                App.trigger('cAccount:show', rowid);
              }
            }
          }
        };

      options = _.defaults(options, this.gridInitOptions(data));

      return options;
    }
  });

  module.exports = CaccountListView;
});
