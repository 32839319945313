define('translationsTableFiltersView',['module', 'marionette', 'template!translationsTableFiltersTpl'],
function(module, Marionette, translationsTableFiltersTpl) {
  'use strict';

  var FiltersView = Marionette.LayoutView.extend({
    template: translationsTableFiltersTpl,

    ui: {
      codeFilter: '.js-code-filter',
      textFilter: '.js-text-filter'
    },

    events: {
      'change @ui.codeFilter': 'onCodeFilter',
      'change @ui.textFilter': 'onTextFilter'
    },

    regions: {
      languages: '#translations-filters-languages-region'
    },

    onRender: function() {
      $.material.init();
    },

    onCodeFilter: function(e) {
      e.preventDefault();
      this.trigger('translations:filter:code', this.ui.codeFilter.val());
    },

    onTextFilter: function(e) {
      e.preventDefault();
      this.trigger('translations:filter:text', this.ui.textFilter.val());
    }

  });

  module.exports = FiltersView;
});
