/**
 * Created by OLD on 20/11/2015.
 */
define('rBticketsController',[
  'module',
  'logger',
  'underscore',
  'backbone',
  'app',
  'rBticketsView',
  'cAccountMoreInfoView',
  'bootbox',
  'settings',
  'rolesMixin',
  'translation',
  'entities/tickets',
  'entities/caccounts/credits'
], function(
  module,
  Logger,
  _,
  Backbone,
  App,
  CollectionView,
  CAccountMoreInfoView,
  bootbox,
  Settings,
  RolesMixin,
  Translation
) {
  'use strict';

  var TicketsController = {
    showTickets: function(secId) {
      this.secId = secId;
      this.getTickets(secId);
    },

    getTickets: function(secId) {
      var that = this,
        ticketRequest = App.request('tickets:get-data', secId),
        cAccountDuplicateId,
        collection, view;

      $.when(ticketRequest)
        .done(function(response) {
          collection = new Backbone.Collection(response.get('messages'));
          view = new CollectionView({collection: collection, ticket: response, attachedFiles: []});

          that.view = view;
          view.listenTo(view, 'state:change', _.bind(that.changeState, that));
          view.listenTo(view, 'type:change', _.bind(that.changeType, that));
          $.when(App.request('tickets:mark-read', response.get('id')))
            .done(function(response) {
              Logger.log(response);
            });
          view.listenTo(view, 'ticket:change-subject', _.bind(that.changeSubject, that));
          view.listenTo(view, 'show:more:caccount:info', _.bind(that.showCAccountInfo, that));

          cAccountDuplicateId = App.request('caccount:duplicate:ip', response.get('cAccountJsonLight' ).secId);
          $.when(cAccountDuplicateId).done(function(cAccounts) {
            that.view.cAccountDuplicateIP = cAccounts ;
            if ( cAccounts && cAccounts.length ) {
              that.view.setCAccountWarning();
            }
          });

          //fetch the support text only for the vector team
          if(Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
            Translation.fetchTranslations(Settings.get('textSupport') ).always(function(result) {
              view.options.textsSupport = Translation.filterTranslations(result);
              App.regions.getRegion('main').currentView.getRegion('content').show(view);
            });
          } else {
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
          }
        });
    },

    showCAccountInfo: function(model) {
      var balanceRequest = App.request('caccount-credits:get-balance', model.get('secId' ) );
      $.when(balanceRequest).done( _.bind(function(balance, cAccounts) {
        model.set('balance', balance);
        model.set('cAccountDuplicateIP', this.view.cAccountDuplicateIP);
        if(cAccounts && cAccounts.length) {
          this.view.setCAccountWarning();
        }
        var popupView = new CAccountMoreInfoView({model: model});
        popupView.show({
          title: _.i18n('common.more.info'),
          className: 'caccount-more-info'
        });
      }, this));
    },

    changeState: function(state) {
      var that = this;

      this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
      $.when(App.request('tickets:change-state', this.secId, state)).
        done(function() {
          that.view.triggerMethod('hide:message');
          that.view.triggerMethod('changeStateUI', state);
        }).
        fail(function() {
          that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
        });
    },

      changeType: function(type) {
          var that = this;

          this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
          $.when(App.request('tickets:change-type', this.secId, type)).
              done(function() {
                  location.reload();
              }).
              fail(function() {
                  that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
              });
      },

    changeSubject: function(subject) {
      var that = this, 
        subjectRequest = App.request('tickets:change-subject', subject, this.secId);

      this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
      $.when(subjectRequest).done(function() {
        that.view.triggerMethod('hide:message');
      }).fail(function() {
        that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
      });
    }
  };

  module.exports = TicketsController;
});
