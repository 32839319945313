define('customersDetailBodyProfileInfosView',[
  'app',
  'module',
  'underscore',
  'marionette',
  'settings',
  'dateUtils',
  'customerUtils',
  'modalsAlertView',
  'ordersController',
  'template!customersDetailBodyProfileInfosView'
], function (
  App,
  Module,
  _,
  Marionette,
  Settings,
  DateUtils,
  CustomerUtils,
  AlertView,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,

    className: 'customers-detail-profile-infos-view',

    modelEvents: {
      'change:email': 'render',
      'change:invoiceEmail': 'render',
      'change:tel': 'render',
      'change:mobile': 'render',
      'change:firstContactPhone': 'render',
      'change:firstContactVoiceMail': 'render',
      'change:companyNumber': 'render',
      'change:vatNumber': 'render',
      'change:vatNumberChecked': 'render',
      'change:vatNumberValid': 'render',
      'change:kvkNumber': 'render',
      'change:siret': 'render',
      'change:mainActivity': 'render',
      'change:subActivity': 'render',
      'change:nbrEmployees': 'render',
      'change:comment': 'render',
      'change:login': 'render',
      'change:webSite': 'render',
      'change:creatDate': 'render',
      'change:webShopLocale': 'render',
      'change:code': 'render',
      'change:vip': 'render'
    },

    ui: {
      codeCopyButton: '.code .copy-button',
      email: '.email .value',
      emailCopyButton: '.email .copy-button',
      invoiceEmailCopyButton: '.invoice-email .copy-button',
      phone: '.phone .value',
      phoneCopyButton: '.phone .copy-button',
      mobilePhone: '.mobile-phone',
      mobilePhoneCopyButton: '.mobile-phone .copy-button',
      firstContactPhone: '.first-contact-phone',
      firstContactPhoneCopyButton: '.first-contact-phone .copy-button',
      firstContactVoiceMailCopyButton: '.first-contact-voice-mail .copy-button',
      companyNumberCopyButton: '.company-number .copy-button',
      vat: '.vat .value .vat',
      kvk: '.kvk .value',
      vatCopyButton: '.vat .copy-button',
      vatCheckButton: '.vat .check-button',
      vatCheckStatus: '.vat .status',
      kvkCopyButton: '.kvk .copy-button',
      siretCopyButton: '.siret .copy-button',
      siretToVatButton: '.siret .to-vat-button',
      clientCodeCopyButton: '.client-code .copy-button'
    },

    events: {
      'click @ui.codeCopyButton': 'onCopyEndUserId',
      'click @ui.emailCopyButton': 'onCopyMail',
      'click @ui.invoiceEmailCopyButton': 'onCopyInvoiceMail',
      'click @ui.phone': 'onCallPhone',
      'click @ui.phoneCopyButton': 'onCopyPhone',
      'click @ui.mobilePhone': 'onCallMobilePhone',
      'click @ui.mobilePhoneCopyButton': 'onCopyMobilePhone',
      'click @ui.firstContactPhone': 'onCallFirstContactPhone',
      'click @ui.firstContactPhoneCopyButton': 'onCopyFirstContactPhone',
      'click @ui.firstContactVoiceMailCopyButton': 'onCopyFirstContactVoiceMail',
      'click @ui.companyNumberCopyButton': 'onCopyCompanyNumber',
      'click @ui.vatCopyButton': 'onCopyVatNumber',
      'click @ui.vatCheckButton': 'onCheckVat',
      'click @ui.kvkCopyButton': 'onCopyKvkNumber',
      'click @ui.siretCopyButton': 'onCopySiret',
      'click @ui.siretToVatButton': 'onToVat',
      'click @ui.clientCodeCopyButton': 'onCopyClientCode'
    },

    serializeData: function () {
      var data = this.model.toJSON();

      data.email = '-';
      if(this.model.get('email')) {
        data.email = '<a href="https://mail.google.com/mail/u/0/?view=cm&to=' + this.model.get('email') + '" target="_blank">' +
            this.model.get('email') + '</a></br> ' + _.i18n('orderDetail.history.tabTitle') + ': ' +
            '<a href="https://mail.google.com/mail/#search/from%3A(' + this.model.get('email') + ')" target="_blank">' +
            _.i18n('common.received') + '</a>, ' +
            '<a href="https://mail.google.com/mail/#search/to%3A(' + this.model.get('email') + ')" target="_blank">' +
            _.i18n('common.sent') + '</a>';
      }

      //-------------------
      data.invoiceEmail = '-';
      if(this.model.get('invoiceEmail'))
        data.invoiceEmail = this.model.get('invoiceEmail');

      //-------------------
      data.phone = '-';
      if(this.model.get('tel'))
        data.phone = this.model.get('tel');

      //-------------------
      data.mobile = '-';
      if(this.model.get('mobile'))
        data.mobile = this.model.get('mobile');

      //-------------------
      data.firstContactPhone = '-';
      if(this.model.get('firstContactPhone'))
        data.firstContactPhone = this.model.get('firstContactPhone');

      //-------------------
      data.showFirstContactPhone = false;
      if (Settings.configValue('customer.profile.showFirstContactPhone'))
        data.showFirstContactPhone = true;

      //-------------------
      data.firstContactVoiceMail = '-';
      if(this.model.get('firstContactVoiceMail'))
        data.firstContactVoiceMail = this.model.get('firstContactVoiceMail');

      //-------------------
      data.showFirstContactVoiceMail = false;
      if (Settings.configValue('customer.profile.showFirstContactVoiceMail'))
        data.showFirstContactVoiceMail = true;

      //-------------------
      var clientCode = this.model.getCustomerNumber();
      data.clientCode = '-';
      if(clientCode)
        data.clientCode = clientCode;

      //-------------------
      data.locales = '-';
      if(this.model.get('locales'))
        data.locales = this.model.get('locales');

      //-------------------
      data.creatDate = '-';
      if (this.model.get('creatDate')) {
        var date = DateUtils.toMDYHHMMFormat(this.model.get('creatDate'));
        if (date)
          data.creatDate = date;
      }

      //-------------------
      data.companyNumber = '-';
      if(this.model.get('companyNumber'))
        data.companyNumber = this.model.get('companyNumber');

      //-------------------
      data.showCompanyNumber = false;
      if (Settings.configValue('customer.profile.showExternalNumber'))
        data.showCompanyNumber = true;

      //-------------------
      data.vatNumber = '-';
      if(this.model.get('vatNumber'))
        data.vatNumber = this.model.get('vatNumber');

      //-------------------
      data.kvkNumber = '-';
      if(this.model.get('kvkNumber'))
        data.kvkNumber = this.model.get('kvkNumber');

      //-------------------
      data.showKvk = false;
      if (Settings.configValue('customer.profile.showKvk'))
        data.showKvk = true;

      //-------------------
      data.siret = '-';
      if(this.model.get('siret'))
        data.siret = this.model.get('siret');

      //-------------------
      data.showSiret = false;
      if (Settings.configValue('customer.profile.showSiret'))
        data.showSiret = true;

      //-------------------
      data.mainActivity = '-';
      if(this.model.get('mainActivity'))
        data.mainActivity = this.model.get('mainActivity');

      //-------------------
      data.showMainActivity = false;
      if (Settings.configValue('customer.profile.showMainActivity'))
        data.showMainActivity = true;

      //-------------------
      data.subActivity = '-';
      if(this.model.get('subActivity'))
        data.subActivity = this.model.get('subActivity');

      //-------------------
      data.showSubActivity = false;
      if (Settings.configValue('customer.profile.showSubActivity'))
        data.showSubActivity = true;

      //-------------------
      data.nbrEmployees = '-';
      if(this.model.get('nbrEmployees'))
        data.nbrEmployees = this.model.get('nbrEmployees');

      //-------------------
      data.comment = '-';
      if(this.model.get('comment'))
        data.comment = this.model.get('comment');

      //-------------------
      data.login = '-';
      if(this.model.get('login'))
        data.login = this.model.get('login');

      //-------------------
      data.webSite = '-';
      if(this.model.get('webSite'))
        data.webSite = '<a href="'+ this.model.get('webSite') +'" target="_blank">'+ this.model.get('webSite') +'</a>';

      if (Settings.isMultiWebshop()) {
        data.webshop = '-';
        if (this.model.get('webShopLocale')) {
          data.webshop = this.model.get('webShopLocale') + ' (' + Settings.get('webshopUrl')[this.model.get('webShopLocale')] + ')';
        }
      } else {
        data.webshop = null;
      }

      //-------------------
      data.paymentTerms = '-';
      if(this.model.get('paymentTerms'))
        data.paymentTerms = this.model.get('paymentTerms') + ' ' + _.i18n('common.days');

      //-------------------
      data.vip = _.i18n('common.no');
      if (this.model.get('vip') === true)
        data.vip = _.i18n('common.yes');

      return data;
    },

    onRender: function () {
      if (this.model.get('vatNumberChecked') === true) {
        if (this.model.get('vatNumberValid') === true)
          this.ui.vatCheckStatus.find('path').attr('fill', 'green');
        else
          this.ui.vatCheckStatus.find('path').attr('fill', 'red');
      }

      //-------------------
      var kvkRegex = /^\d{8}$/;
      if (!kvkRegex.test(this.model.get('siret')) && Settings.configValue('customer.profile.showKvk'))
        this.ui.kvk.addClass('invalid');
      else
        this.ui.kvk.removeClass('invalid');
    },

    onCopyMail: function () {
      if (this.model.get('email'))
        Controller.copyToClipboard(this.model.get('email'));
    },

    onCopyInvoiceMail: function () {
      if (this.model.get('invoiceEmail'))
        Controller.copyToClipboard(this.model.get('invoiceEmail'));
    },

    onCopyPhone: function () {
      if (this.model.get('tel'))
        Controller.copyToClipboard(this.model.get('tel').toString());
    },

    onCopyMobilePhone: function () {
      if (this.model.get('mobile'))
        Controller.copyToClipboard(this.model.get('mobile').toString());
    },

    onCopyFirstContactPhone: function () {
      if (this.model.get('firstContactPhone'))
        Controller.copyToClipboard(this.model.get('firstContactPhone').toString());
    },

    onCopyFirstContactVoiceMail: function () {
      if (this.model.get('firstContactVoiceMail'))
        Controller.copyToClipboard(this.model.get('firstContactVoiceMail'));
    },

    onCopyCompanyNumber: function () {
      if (this.model.get('companyNumber'))
        Controller.copyToClipboard(this.model.get('companyNumber').toString());
    },

    onCopyVatNumber: function () {
      if (this.model.get('vatNumber'))
        Controller.copyToClipboard(this.model.get('vatNumber').toString());
    },

    onCopyKvkNumber: function () {
      if (this.model.get('kvkNumber'))
        Controller.copyToClipboard(this.model.get('kvkNumber'));
    },

    onCopySiret: function () {
      if (this.model.get('siret'))
        Controller.copyToClipboard(this.model.get('siret'));
    },

    onCopyEndUserId: function () {
      if (this.model.get('endUserId'))
        Controller.copyToClipboard(this.model.get('endUserId'));
    },

    onCopyClientCode: function () {
      var clientCode = this.model.getCustomerNumber();
      if(clientCode)
        Controller.copyToClipboard(clientCode.toString());
    },

    onCallPhone: function (e) {
      e.stopPropagation();
      if(this.model.get('tel')) {
        App.trigger('phone:numbering', {
          phoneNumber: this.model.get('tel'),
          country: this.model.get('countryCode')
        });
      }
    },

    onCallMobilePhone: function (e) {
      e.stopPropagation();
      if(this.model.get('mobile')) {
        App.trigger('phone:numbering', {
          phoneNumber: this.model.get('mobile'),
          country: this.model.get('countryCode')
        });
      }
    },

    onCallFirstContactPhone: function (e) {
      e.stopPropagation();
      if(this.model.get('firstContactPhone')) {
        App.trigger('phone:numbering', {
          phoneNumber: this.model.get('firstContactPhone'),
          country: this.model.get('countryCode')
        });
      }
    },

    onCheckVat: function () {
      this.model.checkVat().done(_.bind(function (valid, text) {
        if (valid) {
          this.ui.vatCheckStatus.find('path').attr('fill', 'green');
          AlertView.prototype.open('', _.i18n('common.vat.valid') + ' ' + text, null, 'success');
        } else {
          this.ui.vatCheckStatus.find('path').attr('fill', 'red');
          AlertView.prototype.open('', _.i18n('common.vat.not-valid'));
        }

        this.ui.vatCheckStatus.attr('title', text);

      }, this)).fail(_.bind(function() {
        AlertView.prototype.open('', _.i18n('vat.checkVatError'));
      }, this));
    },

    onToVat: function () {
      var vat = this.model.siretToVat(this.model.get('siret'));
      this.model.set('vatNumber', vat);
      this.model.save();
    }
  });
});
