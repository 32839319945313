define( 'entities/caccounts/orderstatus',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var CAccountOrderStatus = Backbone.Model.extend({
    defaults: {
      code: '',
      name: '',
      tag: '',
      description: '',
      displayInKanban: false,
      displayOrder: 0,
      validatedStatus: false,
      commentStatus: false,
      assignUserToOrder: false,
      params: '',
      refParentStatus: null
    },

    idAttribute: 'secId',

    url: function() {
        return Settings.url('compuzz2', 'v2/caccounts/commandStatus/' + this.get('secId'));
    },

    postUrl: function() {
        return Settings.url('compuzz2', 'v2/caccounts/commandStatus');
    },

    deleteUrl: function() {
        return Settings.url('compuzz2', 'v2/caccounts/commandStatus/' + this.get('secId'));
    },

    save: function() {
        this.url = this.postUrl();
        return Backbone.Model.prototype.save.call(this);
    }

  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(tag) {
      var appendUrl = tag ? ('?tag=' + tag) : '';
      return 'v2/caccounts/commandStatus/rowCount' + appendUrl;
    },

    getOrderStatusUrl: function(tag) {
      return Settings.url('compuzz2', 'v2/caccounts/commandStatus', {tag: tag});
    },

    deleteOrderStatusItem: function(orderStatusSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/caccounts/commandStatus/' + orderStatusSecId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('orderstatus-tab:row-count-url', function(secId, tag) {
      return API.getRowCountUrl(tag);
  });

  App.reqres.setHandler('orderstatus-tab:get-url', function(secId, tag) {
    return API.getOrderStatusUrl(tag);
  });

  App.reqres.setHandler('orderstatus-tab:new-model', function(val) {
    var newOne = new CAccountOrderStatus(val);
    return newOne;
  });

  App.reqres.setHandler('orderstatus-tab:delete', function(orderStatusSecId) {
    return API.deleteOrderStatusItem(orderStatusSecId);
  });
});

