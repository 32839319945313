/**
 * Created by OLD on 20/08/2015.
 */
define('vectorShowController',[
  'module',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'vectorShowView',
  'vectorPopupView',
  'galleryView',
  'fileExtensionData',
  'bootbox',
  'entities/tickets',
  'entities/caccounts/credits'
], function(
  module,
  $,
  _,
  Backbone,
  App,
  Settings,
  ImageUtils,
  View,
  PopupView,
  GalleryView,
  FileExtensionData,
  bootbox
) {
  'use strict';

  var VectorizationTableController = {
    showVectorization: function() {
      var that = this,
        contentView =  App.regions.getRegion('main' ).currentView,
        view;

      if (contentView) {
        $.when(App.request('caccount-credits:get-balance'))
          .done(function(balance) {
            view = new View({balance: balance});
            that.balance = balance;
            that.view = view;

            view.listenTo(view, 'onCreateNew', _.bind(that.onCreateNew, that));
            view.listenTo(view, 'onGridLoaded', _.bind(that.onGridLoaded, that));
            view.listenTo(view, 'tickets:show', _.bind(that.onTicketsShow, that));
            view.listenTo(view, 'tickets:update:tariffScale', _.bind(that.onTariffScaleChange, that));
            view.listenTo(view, 'ticket:update:urgency', _.bind(that.onUpdateUrgency, that));
            view.listenTo(view, 'onNavigateToCredit', _.bind(that.onNavigateToCredit, that));

            App.off('loader:hide');
            App.on('loader:hide', _.bind(that.onHideLoader, that));
            contentView.getRegion('content' ).show(view);
        });
      }
    },

    onCreateNew: function() {
      var that = this,
        popupView = new PopupView({
          balance: this.balance,
          outputFileCollection: new Backbone.Collection(FileExtensionData.extensions)});

      popupView.show({
        title: _.i18n('vectorization.popup.title'),
        className: 'vectorizationAddNew'
      });
      popupView.listenTo(popupView, 'popup:view:close', 
        _.bind(that.createTicket, that));
      popupView.listenTo(popupView, 'gallery:init', 
        _.bind(that.initGallery, that));
      popupView.listenTo(popupView, 'onNavigateToCredit',
      _.bind(that.onNavigateToCredit, that));
      that.popupView = popupView;
    },

    onNavigateToCredit: function() {
      var caSecId = Settings.get('currentCAccount').secId;

      if (this.popupView) {
        this.popupView.hide();
      }
      App.navigate('/caccount/'+ caSecId + '/credits', {trigger: true});
      App.trigger('vectorization:open-add-credit-popup');
    },

    initGallery: function() {
      var fileUid = this.popupView.requestUid,
        fileSrc = Settings.url(
          'x2png',
          'filerequest/vectorize/preview/big/' + fileUid
        ),
        data =[];

      this.popupView.ui.loader.show();
      data.push(fileSrc);
      App.regions.getRegion('zoom').show(new GalleryView({data: data}));
    },

    createTicket: function(ticketName) {
      this.view.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));
      if (this.popupView && ticketName) {
        var files = [{
                       content: this.popupView.imgOriginalBase64.split(',')[1],
                       extension: this.popupView.extension || '',
                       name: this.popupView.fileName || ''
                     }],
          ticketData = {
            name: this.popupView.ui.ticketName.val(),
            content: this.popupView.ui.ticketMessage.val(),
            urgent: this.popupView.ui.ticketUrgent.prop('checked'),
            files: files,
            configuration: {
              outputType: this.popupView.dropdownOutputFileRegion.currentView.selectedItem.get('extension') || ''
            }
          },
          newTicketRequest = App.request('tickets:send-new', ticketData, 'vectorization');

        this.popupView.hide();
        $.when(newTicketRequest).done(_.bind(function(resp) {
          if (resp.status === 500) {
            bootbox.alert(_.i18n('ticket.errorOccuredTryAgainOrSendAMail'), function () {
              return;
            });
          } else {
            App.trigger('vectorization:show', resp.responseJSON.secId);
          }
        }, this));
      }
    },

    onTariffScaleChange: function(ticketSecID, tariffScale) {
      var that = this;
      $.when(App.request('tickets:update:tariffScale', ticketSecID, tariffScale)).
        done(function() {
          that.view.updatePagination();
        });
    },

    onGridLoaded: function() {
      var that = this,
        data = {
          url: App.request('tickets:get-collection-url', { type: 'vectorization' }),
          rowCountUrl: App.request('tickets:row-count-url', { type: 'vectorization' }),
          displayRowToolTip: true
        };
        that.view.displayGrid(data);
    },

    onTicketsShow: function(secId) {
      App.trigger('vectorization:show', secId);
    },

    onHideLoader: function() {
      this.popupView.ui.loader.hide();
    },

    onUpdateUrgency: function(ticketCID, checked) {
      var that = this;
      $.when(App.request('ticket:update:urgency', ticketCID, checked)).
        done(function() {
          that.view.updatePagination();
        });
    }
  };

  module.exports = VectorizationTableController;
});
