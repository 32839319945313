
define('template!productDataTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-horizontal">\n    <button class="btn-refresh-data">Refresh webshop data</button>\n    <label>Update Queue : </label>\n    <label class="queueLevel"></label>\n    ';
 if (display) {;
__p += '\n    <div style="margin: 10px 25px 0px 25px;">\n        <div class="col-xs-12 col-lg-6">\n            <h1 style="text-align: center">' +
((__t = ( _.i18n('common.new.data') )) == null ? '' : __t) +
'</h1>\n            ';
 if (typeof cleanedSupPAs !== 'undefined') { ;
__p += '\n            <div style="">\n                ' +
((__t = ( _.i18n('print.imprint.possibilities') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="90%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.number'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.location'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.technic') )) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedSupPAs, function(cleanedPa) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedPa.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (  cleanedPa.paCodeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedPa.paNameStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedPa.paDimensionStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paDimension)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedPa.pzCodeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedPa.pzNameStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedPa.pzDimensionStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzDimension)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n            ';
 } ;
__p += '\n            ';
 if (typeof cleanedSupPOCS !== 'undefined') { ;
__p += '\n            <div style="">\n                ' +
((__t = ( _.i18n('common.options') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="70%">\n                    <div class="table-header">\n                        <thead style="background-color: lightgray;">\n                        <th>' +
((__t = ( _.i18n('common.cat.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.cat.name'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.opt.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.opt.name'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedSupPOCS, function(cleanedSupPOC) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedSupPOC.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (  cleanedSupPOC.pocCodeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPOC.pocCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedSupPOC.pocNameStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPOC.pocName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedSupPOC.poCodeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPOC.poCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (  cleanedSupPOC.poNameStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPOC.poName)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n            ';
 } ;
__p += '\n            ';
 if (typeof cleanedSupPCVS !== 'undefined') { ;
__p += '\n            <div style="">\n                ' +
((__t = ( _.i18n('product.code.variation') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="100%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.colors'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedSupPCVS, function(cleanedSupPCV) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedSupPCV.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = ( cleanedSupPCV.pcvCodeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPCV.pcvCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = ( cleanedSupPCV.colorStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPCV.color)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = ( cleanedSupPCV.sizeStyle )) == null ? '' : __t) +
'">' +
((__t = (cleanedSupPCV.size)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                    ';
 } ;
__p += '\n                </table>\n            </div>\n            <div style="">\n                ' +
((__t = ( _.i18n('product.custom.field') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="100%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.name'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.value'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedCustomFieldsSup, function(customField) { ;
__p += '\n                    <tr class="' +
((__t = ( customField.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (customField.codeStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.cfCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (customField.nameStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.name)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (customField.valueStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.value)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n        </div>\n        <div class="col-xs-12 col-lg-6">\n            <h1 style="text-align: center">' +
((__t = ( _.i18n('common.actual.data') )) == null ? '' : __t) +
'</h1>\n            <div style="">\n                ';
 if (displayButtons) { ;
__p += '\n                <div style="float: right;margin: -40px 90px 9px 0px;">\n                    ';
 if (toUpdateFromSupplier) { ;
__p += '\n                    <button class="btn-ignore-data" title="ctrl+i">Ignore data</button>\n                    <button class="btn-update-data" title="ctrl+u">Update data</button>\n                    <button class="btn-update-only-new-data" title="ctrl+j">Update data (Only add new)</button>\n                    ';
 } ;
__p += '\n                    <button class="btn-recreate-vrps">ReCreate vrps</button>\n                    <button class="btn-refresh-checksums">Refresh checksums</button>\n                </div>\n                ';
 } ;
__p += '\n                ' +
((__t = ( _.i18n('print.imprint.possibilities') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="90%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.number'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.location'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.technic') )) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedPAs, function(cleanedPa) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedPa.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (cleanedPa.paCodeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPa.paNameStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPa.paDimensionStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.paDimension)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPa.pzCodeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPa.pzNameStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPa.pzDimensionStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPa.pzDimension)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n            <div style="">\n                ' +
((__t = ( _.i18n('common.options') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="70%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.cat.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.cat.name'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.opt.code'))) == null ? '' : __t) +
'</th>\n                        <th\n                        ' +
((__t = ( _.i18n('common.opt.name'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedPOCS, function(cleanedPOC) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedPOC.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (cleanedPOC.pocCodeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPOC.pocCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPOC.pocNameStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPOC.pocName)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPOC.poCodeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPOC.poCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPOC.poNameStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPOC.poName)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n            <div style="">\n                ' +
((__t = ( _.i18n('product.code.variation') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="100%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.colors'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('print.size'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedPCVS, function(cleanedPCV) { ;
__p += '\n                    <tr class="' +
((__t = ( cleanedPCV.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (cleanedPCV.pcvCodeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPCV.pcvCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPCV.colorStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPCV.color)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (cleanedPCV.sizeStyle)) == null ? '' : __t) +
'">' +
((__t = (cleanedPCV.size)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n            <div style="">\n                ' +
((__t = ( _.i18n('product.custom.field') )) == null ? '' : __t) +
'\n                <table class="product-data-table" width="100%">\n                    <div class="table-header">\n                        <thead>\n                        <th>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.name'))) == null ? '' : __t) +
'</th>\n                        <th>' +
((__t = ( _.i18n('common.value'))) == null ? '' : __t) +
'</th>\n                        </thead>\n                    </div>\n                    ';
 _.each(cleanedCustomFields, function(customField) { ;
__p += '\n                    <tr class="' +
((__t = ( customField.lineClass )) == null ? '' : __t) +
'">\n                        <td style="' +
((__t = (customField.codeStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.cfCode)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (customField.nameStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.name)) == null ? '' : __t) +
'</td>\n                        <td style="' +
((__t = (customField.valueStyle)) == null ? '' : __t) +
'">' +
((__t = (customField.value)) == null ? '' : __t) +
'</td>\n                    </tr>\n                    ';
 }) ;
__p += '\n                </table>\n            </div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

