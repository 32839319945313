define('headerUserView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'imageUtils',
  'notificationCenter',
  'headerUserEditView',
  'template!headerUserView'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ImageUtils,
  NotificationCenter,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-user-view',

    ui: {
      edit: '.edit',
      logout: '.logout'      
    },

    events: {
      'click @ui.edit': 'onEdit',
      'click @ui.logout': 'onLogout'
    },

    serializeData: function() {
      var data = {};

      data.url = '';
      data.name = '';

      var user = Settings.get('currentUser');

      if (user) {
        data.url = ImageUtils.getLogo(
          user.logo,
          user.firstName + ' ' + user.lastName, {
            action: 'res',
            params: 't'
          }, 'rect'
        );

        data.name = user.logonId;
      }        
     
      return data;
    },

    onEdit: function() {
      if (!this.model ||
          this.model.get('secId') !== Settings.get('currentUser').secId) {
        this.model = new Backbone.Model(Settings.get('currentUser'));
      }
      
      this.editView = new EditView({
        model: this.model,
        saved: _.bind(function() {
          this.render();
        }, this)
      });
      this.editView.show({
        title: _.i18n('account.edit.popup.title'),
        className: 'headerPopupeditData'
      });
    },

    onLogout: function () {
      Settings.set('tokenId', null);
      Settings.set('currentUser', null);
      Settings.set('currentUserCookie', null);
      Settings.set('currentCAccountCookie', null);
      Settings.set('currentCAccount', null);
      NotificationCenter.getInstance().unSubscribe();
      App.trigger('login:show');
    }
  });
});
