define('webshopProductsFinderListFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'filtersBannerEditView',
  'template!webshopProductsFinderListFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'webshop-products-finder-list-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webshop.products.finder.list.filters.edit.title'),

    ui: _.extend({
      code: '.body .code input',
      name: '.body .name input',
      supplierName: '.body .supplier-name input',
      supplierCode: '.body .supplier-code input'
    }, FiltersBannerEditView.prototype.ui),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onRender: function() {
      this.ui.code.val(this.model.get('code'));
      this.ui.name.val(this.model.get('name'));
      this.ui.supplierCode.val(this.model.get('supplierCode'));
      this.ui.supplierName.val(this.model.get('supplierName'));

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        code: this.ui.code.val().trim(),
        name: this.ui.name.val().trim(),
        supplierCode: this.ui.supplierCode.val().trim(),
        supplierName: this.ui.supplierName.val().trim()
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.ui.code.val('');
      this.ui.name.val('');
      this.ui.supplierCode.val('');
      this.ui.supplierName.val('');
    }
  });  
});

