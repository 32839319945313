define('adminWebhookHistoryView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'tableView',
  'moment',
  'settings',
  'tableDatetimeFieldView',
  'adminWebhookHistoryContentView',
  'adminWebhookHistoryFiltersView',
  'entities/webhook_history'
], function (
  Module,
  _,
  App,
  Backbone,
  TableView,
  moment,
  Settings,
  DatetimeFieldView,
  ContentView,
  FiltersView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'admin-webhook-history-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    setFields: function() {
      return [{
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'creatDate',
        className: 'date',
        view: DatetimeFieldView
      }, {
        name: 'Type',
        label: _.i18n('common.type'),
        bind: 'type',
        className: 'type'
      }, {
        name: 'Status',
        label: _.i18n('common.status'),
        bind: 'status',
        className: 'status'
      }, {
        name: 'Message',
        label: _.i18n('common.message'),
        bind: 'msg',
        className: 'message'
      }, {
        name: 'Content',
        label: _.i18n('common.content'),
        className: 'content',
        view: ContentView
      }];
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params._search = 'false';
      params.rows = 'pageSize';

      if (sortField !== '') {
        var sortParamName = '';
        if (sortField === 'Date')
          sortParamName = 'userTimestamp.creatDate';

        if (sortParamName !== '') {
          params.sidx = sortParamName;
          params.sord = sortDirection;
        }
      }

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('webhook-history-url', params),
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function(item) {
            var model = new Backbone.Model(item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webhook-history-:row-count-url', this.getFiltersParams(filters)),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(key !== 'dateFrom' && key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    }
  });
});
