/**
 * Created by BFR on 01/08/2022.
 */
define('caccountSupplierFactorV2View',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!caccountSupplierFactorV2Tpl'
], function (
  module,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      txtFactor: '.txt-factor-margin'
    },

    events: {
      'change @ui.txtFactor': 'onTxtFactorChange'
    },

    onTxtFactorChange: function () {
      this.model.set('margin', Number(this.ui.txtFactor.val()));
      this.trigger('item:change');
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      return templateData;
    }
  });
});
