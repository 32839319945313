 define('superAdminTicketsGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'chartView'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  ChartView
) {
  'use strict';

  var SuperAdminGraphView =  ChartView.extend({
    serializeData: function() {
      var that = this, 
        tplData = this.model.toJSON(),
        filters = this.options.filters,
        statusData = this.options.statusData,
        labels = [],
        datasets = [];
        
      if ( !_.isUndefined(tplData) && !_.isEmpty(tplData)) {
        _.each(tplData, function(item) {
          if (filters.type === 'Month') {
            labels.push(moment(item.date).format('MMMM'));
          } else if (filters.type === 'Day') {
            labels.push(moment(item.date).format('DD/MM/YYYY'));
          } else if (filters.type === 'Week') {
            labels.push(moment(item.date).format('W'));
          } else if (filters.type === 'Year') {
            labels.push(moment(item.date).format('YYYY'));
          }

          if (filters.statusFilters.length === 0) {
            _.each(statusData, function(status, key) {
              status.data.push(item.values[key] || 0);
            });
          } else {
            _.each(statusData, function(status, key) {
              if (_.indexOf(filters.statusFilters, key) > -1) {
                status.data.push(item.values[key] || 0);
              } else {
                status.data.push(0);
              }
            });
          }
        });

        if (filters.statusFilters.length === 0) {
          _.each(statusData, function(status) {
            datasets.push(that.getDatasetItem(status));
          });
        } else {
          _.each(statusData, function(status, key) {
            if (_.indexOf(filters.statusFilters, key) > -1) {
              datasets.push(that.getDatasetItem(status));
            }
          });
        }

        this.data = {
          labels : labels,
          datasets : datasets
        };
      }
      this.generateLegend();

      return this.data;
    },

    getDatasetItem: function(status) {
      return {
        fillColor : status.color,
        strokeColor : status.color,
        pointColor : 'rgba(220,220,220,1)',
        pointStrokeColor : '#fff',
        data : status.data
      };
    },

    generateLegend: function() {
      var statusData = this.options.statusData,
        filters = this.options.filters,
        labels = '';

      if (filters.statusFilters.length === 0) {
        _.each(statusData, function(status, key) {
          labels += '<li><div class="legend-color-point" style="background-color:' +
            status.color + ';"></div>' + key + '</li>';
        });
      } else {
        _.each(statusData, function(status, key) {
          if (_.indexOf(filters.statusFilters, key) > -1) {
            labels += '<li><div class="legend-color-point" style="background-color:' +
              status.color + ';"></div>' + key + '</li>';
          }
        });
      }

      this.options.legend = '<ul class="legend-chart-view">' + labels + '<br>' +
          '<li><span>' + _.i18n('superAdmin.period') + ': '  + this.options.filters.type + '</span></li>' +
        '</ul>';     
    }
  });

  module.exports = SuperAdminGraphView;
});
