/**
 * Created by OLD on 20/08/2015.
 */
define('graphicalRequestAllInOneMain',[
  'module',
  'logger',
  'graphicalRequestAllInOneRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var GraphicalRequestAllInOne = {
    start: function() {
      Logger.log(module, 'starting GraphicalRequestAllInOnModule');
    },

    stop: function() {
      Logger.log(module, 'stopping GraphicalRequestAllInOnModule');
    }
  };

  module.exports = GraphicalRequestAllInOne;
});
