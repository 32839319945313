define('ordersDetailSupplierProductInfosView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'ordersDetailSupplierProductInfosOptionsView',
  'template!ordersDetailSupplierProductInfosView',
  'entities/product'
], function (
  App,
  module,
  Marionette,
  _,
  OptionsView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'infos-view',

    regions: {
        optionsRegion: '.options-region'
    },

    serializeData: function() {
      var data = {};

      var product = this.model.get('products')[0];

      data.name = '-';
      if(this.options.product.get('name'))
        data.name = this.options.product.get('name');

      data.code = '-';
      if(product.cdproduct)
        data.code = product.cdproduct;

      data.quantity = '-';
      /*if((this.options.product)
        data.quantity = this.model.get('amount') + ' ' + _.i18n('common.pcs');*/

      return data;
    },

    onShow: function () {
      this.optionsView = new OptionsView({
        model: this.model,
        product: this.options.product
      });
      this.getRegion('optionsRegion').show(this.optionsView);
    }
  });
});
