/**
 * Created by BFR on 13/03/2020.
 */
define('productsSupplierMainRouter',[
  'require',
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'contentShowController',
  'itemPagesNameMixin'
], function (
  require,
  module,
  Marionette,
  App,
  _,
  Settings,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var localStorageKeyProductView = 'productSupplierView';

  var ProductsSupplierRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'productsSupplier': 'showProducts'
    }
  });

  var API = {
    showProducts: function() {
      App.startSubModule('productsSupplierMain');
      API.showProductsTable();
    },

    showProductsTable: function() {
        require([
            'productsSupplierListController',
            'contentShowController'
        ], function(ShowController, ContentShowController) {
            ContentShowController.showContent().done(function() {
                ShowController.showProducts();
                App.execute('set:active:nav:item', ItemPagesNameMixin.SUPPLIERS_PRODUCTS);
            });
        });
    }

  };

  App.on('productsSupplier:show', function () {
    App.navigate('productsSupplier');
    API.showProducts();
  });

  App.on('productsSupplier:show:grid', function() {
    Settings.setToMemory(localStorageKeyProductView, 'grid');
    App.navigate('productsSupplier');
    API.showProductsGrid();
  });

  App.on('before:start', function () {
    new ProductsSupplierRouter({
      controller: API
    });
  });

  module.exports = ProductsSupplierRouter;
});
