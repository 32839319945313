define('translationsFilterLanguageListView',[
  'module',
  'marionette',
  'template!translationsFilterLanguageListTpl',
  'jquery',
  'underscore'
], function(
  module,
  Marionette,
  filterLanguageTpl,
  $,
  _
) {
  'use strict';

  var FilterLanguageListView = Marionette.ItemView.extend({
    template: filterLanguageTpl,

    ui: {
      languageInput: 'input',
      selectAllCheckbox: 'input[data-abbreviation=all]'
    },

    events: {
      'click .js-language': 'onItemClick',
      'change @ui.languageInput': 'onLanguageChanged'
    },

    collectionEvents: {
      change: 'render'
    },

    serializeData: function() {
      var templateData = {
          allChecked: this._isAllChecked()
        };
      return _.extend(templateData, {items: this.collection.toJSON()});
    },

    onRender: function() {
      $.material.init();
    },

    onLanguageChanged: function(e) {
      var $target = $(e.currentTarget);
      if($target.data('abbreviation') === 'all') {
        this._toggleAll($target.is(':checked'));
        return;
      }
      this.collection.get($target.data('abbreviation')).set('checked', $target.is(':checked'));
      this.ui.selectAllCheckbox.prop('checked', this._isAllChecked());
    },

    _toggleAll: function(isChecked) {
      this.ui.languageInput.prop('checked', isChecked);
      this.collection.each(function(model) {
        model.set('checked', isChecked, {silent: true});
      });
      this.collection.trigger('change');
    },

    _isAllChecked: function() {
      return this.collection.filter(function(language) {
          return !language.get('checked');
        }).length === 0;
    },

    onItemClick: function(e) {
      //disable bootstrap navbar default behavior
      e.stopPropagation();
    }
  });

  module.exports = FilterLanguageListView;
});
