define('tasksFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'tasksFiltersEditView',
  'filtersBannerView'
], function(
  Module,
  Backbone,
  _,
  Settings,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
        var settings = Settings.get('adminTasksFilters', true);
      if(settings) {
        this.model = new Backbone.Model(JSON.parse(settings));
      } else {
        this.model = new Backbone.Model({
          onlyMine: ''
        });
        Settings.set('adminTasksFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {
      if(this.model.get('onlyMine') === true) {
        this.collection.add(new Backbone.Model({
          id: 'onlyMine',
          libelle: _.i18n('task.onlyMine'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('onlyMine') !== model.onlyMine)
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('adminTasksFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.filters.set(this.model.toJSON());
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

