
define('template!pimAttributesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">PIM Attributes</div>\n    <div class="buttons">\n        <a class="btn new-attribute-button action-btn-text" title="Add new attribute">\n            <i class="mdi-content-add"></i>\n            <span>NEW ATTRIBUTE</span>\n        </a>        \n        <a class="btn save-button action-btn-text">\n            <i class="mdi-content-save"></i>\n            <span>Save</span>\n        </a>\n    </div>\n</div>\n<div class="body">       \n    <div class="view">\n        <div class="row">\n            <div class="col-xs-6">\n                <div class="attribute-list-region"></div>\n            </div>     \n            <div class="col-xs-6">\n                <i class="mdi-action-delete delete-attribute-button clickable" title="delete attribute value"></i>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-xs-6">\n                <div class="attribute-view-region"></div>\n            </div>     \n        </div>\n    </div>\n    <div class="newAttribute" style="display:none">\n        <div class="row">\n            <div class="col-xs-6">\n                <div class="attribute-add-view-region"></div>\n            </div>     \n        </div>\n    </div>\n</div>';

}
return __p
};});

