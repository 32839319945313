define('tasksView',[
  'module',
  'underscore',
  'app',
  'tableView',
  'tableDatetimeFieldView',
  'tasksCreatorView',
  'tasksDatedueView',
  'tasksInfosView',
  'tasksCommentsView',
  'tasksFiltersView',
  'tasksHeaderToolsView',
  'tasksToolsView',
  'tasksEditView',
  'entities/admin/tasks'
], function (
    Module,
    _,
    App,
    TableView,
    DatetimeFieldView,
    CreatorView,
    DatedueView,
    InfosView,
    CommentsView,
    FiltersView,
    HeaderToolsView,
    ToolsView,
    EditView
) {
  'use strict';

  Module.exports = TableView.extend({

    className: 'tasks-view ' + TableView.prototype.className,
    pageSize: 20,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    setFields: function() {
      return [{
        name: 'Creator',
        label: _.i18n('tasks.creator'),
        className: 'creator',
        view: CreatorView
      }, {
        name: 'Infos',
        label: _.i18n('tasks.list.context'),
        className: 'infos',
        view: InfosView
      }, {
        name: 'Comments',
        label: _.i18n('common.comments'),
        className: 'comments',
        view: CommentsView
      }, {
        name: 'DateDue',
        className: 'datedue',
        view: DatedueView,
        label: _.i18n('tasks.datedue')
      }, {
        name: 'Buttons',
        className: 'buttons',
        headerView: HeaderToolsView,
        view: ToolsView
      }];
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params.first = first;
      params.sidx = 'creatDate';
      params.sort = 'desc';

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('tasks:get-url', params),
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function(item) {
            var model = App.request('tasks:model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        url: App.request('tasks:row-count-url', params),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(value !== '')
          params[key] = value;
      }, this);

      return params;
    },

    onRowClick: function(view) {
      EditView.prototype.open({
        model: view.model
      });
    }
  });
});
