define('userSelectorUserView',[
  'module',
  'marionette',
  'underscore',
  'settings',
  'imageUtils',
  'template!userSelectorUserView'
], function (
  Module, 
  Marionette,
  _,
  Settings,
  ImageUtils,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'user-selector-user-view',

    ui: {
      name: '.name',  
      icon: '.icon'  
    },

    events: {
      'click' : 'onSelect'
    },

    onRender: function () {
      if(this.options.showDeleteIcon)
        this.ui.icon.css('display', 'flex');
    },

    serializeData: function () {
      var data = {};

      data.name = '';

      if(this.model.get('firstName'))
        data.name = this.model.get('firstName');

      if(this.model.get('lastName')) {
        if(data.name !== '')
          data.name += ' ';
        data.name += this.model.get('lastName');
      }
      //-----------------

      data.logo = '';

      if(this.model.get('logo')) {
        if (this.model.get('logo'))
          data.logo = Settings.url('file', this.model.get('logo'), {size: 'thumbnail'});
        else if (this.model.get('logonId'))         
          data.logo = ImageUtils.getLogo(null, this.model.get('logonId'), null, 'circle'); 
      }
        
      return data;
    },

    onSelect: function () {      
      this.trigger('onSelect', this);     
    }
  });
});
