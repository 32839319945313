define('pimAttributeValueView',[
  'module',        
  'marionette',
  'template!pimAttributeValueTpl'
], function(
  module,           
  Marionette,
  viewTpl
){
  'use strict';

  var PimAttributeValuesView = Marionette.ItemView.extend({
    template: viewTpl,
    className: 'value',

    ui: {
      deleteButton: '.delete-value-button',
      valueInput: '#attribute-value'
    },

    events: {       
      'click @ui.deleteButton': 'onDelete',
      'change @ui.valueInput': 'onValueChange'
    },  

    onValueChange: function () {  
      this.model.set('code', this.ui.valueInput.val(), {'silent': true});
    },

    onDelete: function () {  
      //this.model.collection.remove(this.model);
      this.model.destroy(this.options.attributeModel.get('secId'), this.model.get('secId'));
    },

    serializeData: function () {     
      return {'code': this.model.get('code')};
    }
  });

  module.exports = PimAttributeValuesView;
});
