
define('template!filterTypesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="product-filter-types col-xs-12">\n    </div>\n</div>';

}
return __p
};});

