define('ordersDetailOrderClientImageView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailOrderClientImageView'
], function(
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({    
    className: 'image-view',
    template: Tpl,  

    ui: {    
      image: 'img'
    },

    onRender: function() {
      this.ui.image.attr('src', this.model.get('url'));

      this.$el.hide();
      //this.$el.attr('data-index', this.model.get('index'));     
    },

    show: function() {
      this.$el.show();
    },

    hide: function() {
      this.$el.hide();
    }
  });   
});
