define('galleryView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'galleryImageView',
  'template!galleryView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  ImageView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'gallery-view',
    childView: ImageView,
    childViewContainer: '.items',

    ui: {    
      prev: '.prev',
      next: '.next'
    },  
    
    events: {    
      'click @ui.prev': 'onPrev',
      'click @ui.next': 'onNext'
    },

    modelEvents: {
      'change:urls': 'fetch'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },
    
    onShow: function() {
      this.fetch();
    },

    onRender: function() {
      this.renderCurrentImage();
      this.renderNavigation();
    },

    fetch: function() {
      this.index = 0;

      this.collection.reset();

      if(this.model.get('urls')) {
        _.each(this.model.get('urls'), function (url, index) {
          var model = new Backbone.Model({
            'url': url,
            'index': index
          });
          this.collection.add(model);
        }, this);
      }

      this.renderCurrentImage();
      this.renderNavigation();
    },

    renderCurrentImage: function() {
      for (var childView in this.children._views) {
        if(this.children._views[childView].model.get('index') === this.index)
          this.children._views[childView].show();
        else
          this.children._views[childView].hide();
      }
    },

    renderNavigation: function() {
      if(this.collection.models.length > 1) {
        this.ui.prev.css('display', 'flex');
        this.ui.next.css('display', 'flex');
      } else {
        this.ui.prev.hide();
        this.ui.next.hide();
      }
    },

    onPrev: function() {
      this.index--;

      if(this.index < 0)
        this.index = this.collection.length-1;

      this.renderCurrentImage();
      this.renderNavigation();
    },

    onNext: function() {
      this.index++;

      if(this.index >= this.collection.length)
        this.index = 0;

      this.renderCurrentImage();
      this.renderNavigation();
    },

    // Static methods
    open: function (id, title, model) {
      require([
        'windows',
        'galleryView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model
            });
          }, this),
          id: id,
          title: title,
          height: '80%',
          width: '60%'
        });
      });
    }
  });
});
