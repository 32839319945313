define('ordersDetailTrackingListView',[
  'module',
  'app',
  'underscore',
  'tableDateFieldView',
  'ordersDetailTrackingEditView',
  'ordersDetailTrackingListNumberView',
  'ordersDetailTrackingListCarrierView',
  'ordersDetailTrackingListRowToolsView',
  'ordersDetailTrackingListHeaderToolsView',
  'tableView',
  'entities/tracking'
], function (
  Module,
  App,
  _,
  DateView,
  EditView,
  NumberView,
  CarrierView,
  RowToolsView,
  HeaderToolsView,
  TableView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'orders-detail-tracking-list-view ' + TableView.prototype.className,

    setFields: function () {
      return [{
        name: 'Carrier',
        label: _.i18n('tracking.carrier'),
        title: _.i18n('tracking.carrier'),
        view: CarrierView,
        className: 'carrier'
      }, {
        name: 'Target',
        label: _.i18n('tracking.destinationType'),
        title: _.i18n('tracking.destinationType'),
        onRender: _.bind(function (view) {
          if (view.model.get('destinationType') === 'tocustomer')
            view.$el.html(_.i18n('tracking.toCustomer'));

          if (view.model.get('destinationType') === 'toprinter')
            view.$el.html(_.i18n('tracking.toPrinter'));
        }, this),
        className: 'target',
        modelEvent: 'change:destinationType'
      }, {
        name: 'TrackingNumber',
        label: _.i18n('tracking.trackingNumber'),
        title: _.i18n('tracking.trackingNumber'),
        className: 'number',
        view: NumberView
      }, {
        name: 'Status',
        label: _.i18n('tracking.trackingStatus'),
        title: _.i18n('tracking.trackingStatus'),
        className: 'status',
        bind: 'trackingStatus',
        modelEvent: 'change:trackingStatus'
      }, {
        name: 'DeliveryDate',
        label: _.i18n('tracking.deliveryDate'),
        title: _.i18n('tracking.deliveryDate'),
        view: DateView,
        className: 'delivery-date',
        bind: 'deliveryDate',
        modelEvent: 'change:deliveryDate'
      }, {
        name: 'MailDate',
        label: _.i18n('tracking.mailDate'),
        title: _.i18n('tracking.mailDate'),
        view: DateView,
        className: 'mail-date',
        bind: 'sentDate',
        modelEvent: 'change:sentDate'
      }, {
        name: 'Buttons',
        className: 'buttons',
        view: RowToolsView,
        headerView: HeaderToolsView
      }];
    },

    fetch: function (first, pageSize) {
      var defer = $.Deferred();

      var params = {
        first: first,
        pageSize: pageSize
      };

      App.request('tracking-tab:list',
          this.model.get('purchaseOrderId'),
          params).done(_.bind(function (items) {
        var models = [];

        _.each(items, _.bind(function (item) {
          models.push(App.request('tracking-tab:new-model', item));
        }, this));

        defer.resolve(models);
      }, this));

      return defer.promise();
    },

    onRowDoubleClick: function (view) {
      EditView.prototype.open(view.model, this.model);
    },

    //Static methods
    open: function (model) {
      require([
        'windows',
        'ordersDetailTrackingListView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model
            });
          }, this),
          id: 'Trackings.' + model.get('purchaseOrderId'),
          title: 'Trackings: ' + model.get('purchaseOrderId') + ' | ' + model.get('companyName'),
          configName: 'Trackings',
          width: 1200,
          height: 400,
          minWidth: 400,
          minHeight: 400
        });
      });
    }
  });
});
