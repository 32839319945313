define('windowsToolsView',[
  'module',
  'marionette',
  'underscore',
  'windows',
  'template!windowsToolsView'
], function (
  Module,
  Marionette,
  _,
  Windows,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'windows-tools-view', 
    
    ui: {
      managmentButton: '.managment-button',
      minimizeAllButton: '.minimize-all-button'
    },

    events: {
      'click @ui.managmentButton': 'onManagmentButtonClick',
      'click @ui.minimizeAllButton': 'onMinimizeAllButtonClick'
    },

    initialize: function () {    
      Windows.toolsView = this; 
    },

    onRender: function () {
      if(Windows.views.length === 0) {
        this.ui.managmentButton.attr('data-status', 'empty');
        this.ui.minimizeAllButton.attr('data-status', 'empty');
      }          
      else {
        if(Windows.managmentView.isOpened())
          this.ui.managmentButton.attr('data-status', 'opened');
        else  
          this.ui.managmentButton.attr('data-status', 'closed');

        //-----------
              
        Windows.views.forEach(_.bind(function (view) {
          if(!view.isMinimized())
            this.ui.minimizeAllButton.attr('data-status', 'enabled');
        }, this));
      }      
    },

    onManagmentButtonClick: function () {
      if(Windows.managmentView.isOpened())
        Windows.managmentView.hide();
      else
        Windows.managmentView.show();
    },

    onMinimizeAllButtonClick: function () {
      Windows.views.forEach(function (view) {
        if(!view.isMinimized())
          view.minimize();
      });
    }
  });
});
