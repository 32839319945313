/**
 * Created by dev on 10-02-16.
 */
define('autoLoginMain',[
    'module',
    'logger',
    'autoLoginRouter'
], function(
    module,
    Logger
    ) {
    'use strict';

    var AutoLogin = {
        start: function() {
            Logger.log(module, 'starting AutoLoginModule');
        },

        stop: function() {
            Logger.log(module, 'stopping AutoLoginModule');
        }
    };

    module.exports = AutoLogin;
});
