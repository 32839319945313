define('ordersLabelsEditView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'settings',
  'modalsConfirmationView',
  'ordersLabelsEditLabelsView',
  'template!ordersLabelsEditView'
], function(
  App,
  Module,
  _,
  Backbone,
  Settings,
  ModalsConfirmationView,
  LabelsView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-labels-edit-view ' + ModalsConfirmationView.prototype.className,

    regions: _.extend({
      labelsRegion: '.labels-region'
    }, ModalsConfirmationView.prototype.regions),

    initialize: function () {
      this.orders = [];
      _.each(this.options.orders, function(order) {
        var clone = order.clone();
        clone.set('originalModel', order);
        this.orders.push(clone);
      }, this);

      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {
      this.labelsView = new LabelsView({
        orders: this.orders,
        tabindex: 1
      });
      this.getRegion('labelsRegion').show(this.labelsView);

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    onConfirm: function () {
      _.each(this.orders, function(order) {
        order.get('originalModel').updateLabels(order.get('cardColor')).done(_.bind(function() {
          order.get('originalModel').fetch();
        }, this));
      }, this);

      ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
    },

    // Static methods
    open: function (orders) {
      require([
        'windows',
        'ordersLabelsEditView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              orders: orders
            });
          }, this),
          title: _.i18n('orders.labels.edit.title'),
          modal: true
        });
      });
    }
  });
});
