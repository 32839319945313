
define('template!ordersDetailSupplierEditInvoicePackingListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="canvas-ctn">\n  <canvas class="canvas"></canvas>\n</div>';

}
return __p
};});

