
define('template!headerNotificationsPopupView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tools">\n    <div class="seen">' +
((__t = ( _.i18n('common.read-all') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="list-region"></div>\n<div class="show-all">' +
((__t = ( _.i18n('common.show-all-notifications') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

