/**
 * Created by BFR on 24/02/2020.
 */
define('editOptionView',[
  'module',
  'marionette',
  'app',
  'settings',
  'underscore',
  'template!editOptionTpl',
  'bootbox',
  'popupOptionView'

], function (
  module,
  Marionette,
  App,
  Settings,
  _,
  viewTpl,
  bootbox,
  PopupOptionView
) {
  'use strict';

  var EditOptionView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'option',

    modelEvents: {
      sync: 'render'
    },

    ui: {
      btnContainer: '.btn-pz-container'
    },

    events: {
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut',
      'click .btn-delete-po': 'onDeletePoClick',
      'click .btn-edit-po': 'onEditPoClick'
    },

    regions: {
      nameRegion: '.name-region'
    },

    onMouseOver: function () {
      if (this.options.editable === true)
        this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      if (this.options.editable === true)
        this.ui.btnContainer.hide();
    },

    onShow: function () {
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.name = this.options.model.get('name').getTextInLocale(Settings.get('lang'));
      var color1 = this.model.get('color1');
      if (color1) {
        templateData.color1 = color1.toHex();
      }
      var color2 = this.model.get('color2');
      if (color2) {
        templateData.color2 = color2.toHex();
      }
      var color3 = this.model.get('color3');
      if (color3) {
        templateData.color3 = color3.toHex();
      }
      var color4 = this.model.get('color4');
      if (color4) {
        templateData.color4 = color4.toHex();
      }
      return templateData;
    },

    onDeletePoClick: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.destroy();
        }
      }, this));

    },

    onEditPoClick: function () {
      var popupPrintZoneView = new PopupOptionView({
        model: this.model
      });
      popupPrintZoneView.show({
        title: _.i18n('common.edit')
      });
    }
  });

  module.exports = EditOptionView;
});
