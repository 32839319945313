define('adminGlossariesListFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'adminGlossariesListFiltersEditView',
  'filtersBannerView'
], function(
  Module,
  Backbone,
  _,
  Settings,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
      var settings = Settings.get('adminGlossariesFilters', true);
      if(settings) {
        this.model = new Backbone.Model(JSON.parse(settings));
      } else {
        this.model = new Backbone.Model({
          type: '',
          code: '',
          text: '',
          missingTranslation: ''
        });
        Settings.set('adminGlossariesFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {
      if(this.model.get('type') !== '') {
        var types = new Backbone.Collection([
          {id: '', value: _.i18n('glossaries.type.all')},
          {id: 'color', value: _.i18n('glossaries.type.color')},
          {id: 'impt',  value: _.i18n('glossaries.type.impt')},
          {id: 'material',  value: _.i18n('glossaries.type.material')},
          {id: 'other',  value: _.i18n('glossaries.type.other')},
          {id: 'paName',  value: _.i18n('glossaries.type.paName')}
        ]);

        var typeValue = '';
        _.each(types.models, _.bind(function (type) {
          if(type.get('id') === this.model.get('type'))
            typeValue = type.get('value');
        }, this));
        this.collection.add(new Backbone.Model({
          id: 'type',
          libelle: _.i18n('common.type'),
          value: typeValue
        }));
      }

      //----------------
      if(this.model.get('missingTranslation')) {
        var missingTranslation = this.model.get('missingTranslation') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'missingTranslation',
          libelle: _.i18n('glossary.missingTranslation'),
          value: missingTranslation
        }));
      }

      //----------------
      if(this.model.get('code')) {
        this.collection.add(new Backbone.Model({
          id: 'code',
          libelle: _.i18n('common.code'),
          value: this.model.get('code')
        }));
      }

      //----------------
      if(this.model.get('text')) {
        this.collection.add(new Backbone.Model({
          id: 'text',
          libelle: _.i18n('common.text'),
          value: this.model.get('text')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('type') !== model.type)
        needFetch = true;
      if(this.model.get('code') !== model.code)
        needFetch = true;
      if(this.model.get('text') !== model.text)
        needFetch = true;
      if(this.model.get('missingTranslation') !== model.missingTranslation)
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('adminGlossariesFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.filters.set(this.model.toJSON());
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

