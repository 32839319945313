define('graphicalRequestKanbanShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'template!graphicalRequestKanbanViewTpl',
  'notificationCenter',
  'backbone',
  'graphicalRequestKanbanColumnView',
  'template!graphicalRequestKanbanColumnTicketItemViewTpl',
  'jqueryui'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  viewTpl,
  NotificationCenter,
  Backbone,
  ColumnView,
  ticketItemTpl
) {
  'use strict';

  var KanbanView =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      kanbanFilters: '#kanban-filters-region',
      kanbanBoard: '#kanban-board-region'
    },

    onShow: function() {
      $.material.init();
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.CHANGE_INFO_TICKET, this.onMessage);
    },

    onMessage: function(response) {
      var item = this.$('ul').find('li[data-id=\''+response.content.secId+'\']'),
        parent = this.$(item[0].parentNode), newParent, ticketList,
        model = new Backbone.Model(response.content),
        ticket = new ColumnView().getTicketObject(model);

      if (response.content.state === 'CANCELED') {
        item[0].remove();
      } else {
        if (response.content.state && parent.data('state') !== response.content.state.toLowerCase() || response.content.ontop) {
          item[0].remove();
          newParent = this.$('ul[data-state=\'' + response.content.state.toLowerCase() + '\']');
          newParent.prepend(item[0]);
          ticketList = Settings.get('ticket-' + response.content.state.toLowerCase());
          ticketList.push(ticket);
        }
        item.replaceWith(Marionette.Renderer.render(ticketItemTpl, { ticket: ticket, state: null, index: null }));
      }
    }
  });

  module.exports = KanbanView;
});
