define('webshopProductsDetailBodyTabsView',[
  'app',
  'module',
  'underscore', 
  'rolesMixin',
  'tabsView',
  'webshopProductsDetailBodyProductView',
  'webshopProductsDetailBodyTextsView',
  'webshopProductsDetailBodySlugsView',
  'webshopProductsDetailBodyCategoriesView',
  'webshopProductsDetailBodyUrlsView',
  'webshopProductsDetailBodyImagesView'
], function (
  App,
  Module,
  _,
  RolesMixin,
  TabsView
) {
  'use strict';

  Module.exports = TabsView.extend({
    fetch: function () {
      this.collection.push({
        name: 'product',
        url: '',
        title: _.i18n('webshop.products.product.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_PRODUCT,
        defaultSelected: true,
        view: 'webshopProductsDetailBodyProductView'
      });
      this.collection.push({
        name: 'texts',
        url: 'texts',
        title: _.i18n('webshop.products.texts.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_TEXTS,
        view: 'webshopProductsDetailBodyTextsView'
      });
      this.collection.push({
        name: 'slugs',
        url: 'slugs',
        title: _.i18n('webshop.products.slugs.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_SLUGS,
        view: 'webshopProductsDetailBodySlugsView'
      });
      this.collection.push({
        name: 'categories',
        url: 'categories',
        title: _.i18n('webshop.products.categories.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_CATEGORIES,
        view: 'webshopProductsDetailBodyCategoriesView'
      });
      this.collection.push({
        name: 'urls',
        url: 'urls',
        title: _.i18n('common.urls'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_URLS,
        view: 'webshopProductsDetailBodyUrlsView'
      });
      this.collection.push({
        name: 'images',
        url: 'images',
        title: _.i18n('common.images'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_IMAGES,
        view: 'webshopProductsDetailBodyImagesView'
      });
    }
  });
});
