define('ordersDetailOrderProductCustomNameView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'template!ordersDetailOrderProductCustomNameView'
], function (
  Module,
  App,
  Marionette,
  _,    
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-product-custom-name-view item editable',

    ui: {
      value: '.value',
      editButton: '.btn-edit-custom-name',
      input: '.edit > input'
    },
     
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.value': 'onEdit',
      'blur  @ui.input': 'onBlurClick',
      'click @ui.input': 'onInputClick'
    },

    modelEvents: {      
      'change:name': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      } else if (event.key === 'Enter') {
        this.update();
      }
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },   

    onRender: function () {    
      this.renderContent();
    },

    renderContent: function () {       
      this.$el.attr('edit', this.edit);

      //-------------------

      if (!this.model.get('name'))
        this.ui.value.html('-');
      else
        this.ui.value.html(this.model.get('name'));
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = !this.edit;     
      this.renderContent();     
      
      if(this.edit) {
        this.ui.input.val(this.model.get('name'));
        this.ui.input.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () {

      if(!this.edit)
        return;
      
      this.edit = false;
      this.renderContent();

      if(this.ui.input.val() !== this.model.get('name')) {
        App.request('order:update', {
          purchaseOrderId: this.model.get('purchaseOrderId'),
          refCommand: {
            updateProperties: true,
            name: this.ui.input.val().trim()
          }
        }).done(_.bind(function () {
          this.model.fetch();
        }, this));
      }         
    }
  });
});

