
define('template!productConfigDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="container-fluid">\n  <div class="container-fluid">\n    <div class="form-product-config-container form-horizontal col-xs-12">\n      <div class="form-inputs-container">\n        ';
 if (product) { ;
__p += '\n        <div class="form-group" data-field-name="quantity">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('selectproduct.placeholder') )) == null ? '' : __t) +
'</label>\n          <label class="p-l-15 p-t-7">' +
((__t = ( product.cCode + " - " + product.name )) == null ? '' : __t) +
'</label>\n          <i class="mdi mdi-editor-mode-edit btn-edit-product clickable"></i>\n        </div>\n        ';
 } ;
__p += '\n        ';
 if ((quantityOnly || !mixable) && !availableOptions) { ;
__p += '\n        <div class="form-group" data-field-name="quantity">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t);
 if (product) { ;
__p += ' (min:\n            ' +
((__t = ( product.minimumPacksPerOrder || 1 )) == null ? '' : __t) +
')';
 } ;
__p += '</label>\n          <div class="col-xs-8">\n            <input type="number" class="form-control floating-label js-info-input input-quantity"\n                   data-field-name="quantity" placeholder="' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'"\n                   value="' +
((__t = ( quantity )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 if (!quantityOnly && !availableOptions) { ;
__p += '\n        ';
 if (product.printAreas.length > 0) { ;
__p += '\n        <div class="form-group p-l-30" data-field-name="name">\n          <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('common.printings') )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n        <div class="config-printareas-region">\n\n        </div>\n        ';
 if (product.productOptionCategories.length > 0) { ;
__p += '\n        <div class="form-group p-l-30" data-field-name="name">\n          <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('common.options') )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n        ';
 for (var i = 0 ; i < product.productOptionCategories.length ; i++) { ;
__p += '\n        ';
 if (!product.productOptionCategories[i].mixable) { ;
__p += '\n        <div class="form-group" data-field-name="option-category-' +
((__t = ( i )) == null ? '' : __t) +
'" style="height:40px;">\n          <label class="col-xs-4 control-label">' +
((__t = ( product.productOptionCategories[i].name )) == null ? '' : __t) +
'</label>\n          <div class="dropdown col-xs-8">\n            <button class="form-control dropdown-toggle dropdown__button" type="button"\n                    id="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n              <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n              <span class="caret pull-right m-t-5 dropdown__caret"></span>\n            </button>\n            <ul class="dropdown-menu dropdown-menu-option" role="menu"\n                aria-labelledby="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'">\n              ';
 for (var j = 0 ; j < product.productOptionCategories[i].productOptions.length ; j++) { ;
__p += '\n              <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                         data-option-id="' +
((__t = ( product.productOptionCategories[i].productOptions[j].id )) == null ? '' : __t) +
'"\n                                         href="#">' +
((__t = (
                product.productOptionCategories[i].productOptions[j].name )) == null ? '' : __t) +
'</a>\n              </li>\n              ';
 } ;
__p += '\n            </ul>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n\n        ';
 if (mixable) { ;
__p += '\n        <div class="mix-table table-responsive row padding-all-0">\n          <div class="row mix-table-body">\n\n          </div>\n          <div class="btn btn-orange custom-second-color add-mix-line">\n            <i class="glyphicon glyphicon-plus"></i>\n            ' +
((__t = ( _.i18n('perso.addOption') )) == null ? '' : __t) +
'\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (availableOptions) { ;
__p += '\n        ';
 for (var i = 0 ; i < availableOptions.length ; i++) { ;
__p += '\n\n\n        <div class="form-group" data-field-name="option-category-' +
((__t = ( i )) == null ? '' : __t) +
'" style="height:40px;">\n          <label class="col-xs-4 control-label">' +
((__t = ( availableOptions[i].name )) == null ? '' : __t) +
'</label>\n          <div class="dropdown col-xs-8">\n            <button class="form-control dropdown-toggle dropdown__button" type="button"\n                    id="dropdownMenu-changeoption-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n              ';
if (availableOptions[i].selected) {;
__p += '\n              <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">' +
((__t = ( availableOptions[i].selected.name )) == null ? '' : __t) +
'</span>\n              ';
 } else { ;
__p += '\n              <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n              ';
} ;
__p += '\n              <span class="caret pull-right m-t-5 dropdown__caret"></span>\n            </button>\n            <ul class="dropdown-menu dropdown-menu-option" role="menu"\n                aria-labelledby="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'">\n              ';
 for (var j = 0 ; j < availableOptions[i].productOptions.length ; j++) { ;
__p += '\n              <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                         data-option-id="' +
((__t = ( availableOptions[i].productOptions[j].id )) == null ? '' : __t) +
'"\n                                         href="#">' +
((__t = ( availableOptions[i].productOptions[j].name )) == null ? '' : __t) +
'\n              </a></li>\n              ';
 } ;
__p += '\n            </ul>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (!quantityOnly) { ;
__p += '\n        <div class="form-group">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n          <textarea type="text" class="form-control floating-label input-comment"\n                    placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'" style="height: 100px;"></textarea>\n          </div>\n        </div>\n        <div class="form-group p-l-30" data-field-name="name">\n          <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('common.attachments') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="row upload-image-block" style="">\n          <div class="col-xs-offset-4 col-xs-8">\n            <div class="image-holder js-image-holder pgallery_item upload-image-button">\n              <input id="browseAttachFile" multiple type="file" name="filename" accept="*"\n                     class="hidden attach-input js-change-logo2"/>\n              <div class="bootstrap-filestyle">\n                <label class="js-logo-holder2">\n                  <i class="mdi-content-add abs-icon-addon"></i>\n                </label>\n              </div>\n            </div>\n          </div>\n          <div class="col-xs-offset-4 col-xs-8 attachments">\n\n          </div>\n        </div>\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </div>\n\n  <!-- SAVE / CANCEL -->\n  <div class="buttons-container">\n    <div class="form-group pull-right">\n      <a class="btn btn btn-s-md-cancel btn-primary " data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n      <a class="btn btn btn-s-md-save btn-primary js-confirm btn-s-md-save">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

