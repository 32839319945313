
define('template!ordersDetailTrackingEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n  <div class="target">\n    <div class="title">' +
((__t = ( _.i18n('tracking.destinationType') )) == null ? '' : __t) +
'</div>\n    <div class="items">\n      <div class="customer item">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>\n        <div class="text">' +
((__t = ( _.i18n('tracking.toCustomer') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="printer item">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>\n        <div class="text">' +
((__t = ( _.i18n('tracking.toPrinter') )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n  </div> \n  <div class="url">\n    <div class="title">' +
((__t = ( _.i18n('tracking.trackingUrl') )) == null ? '' : __t) +
'</div>\n    <textarea></textarea>\n  </div>\n  <div class="carrier-region"></div>\n  <div class="number">\n    <div class="title">' +
((__t = ( _.i18n('tracking.trackingNumber') )) == null ? '' : __t) +
'</div>\n    <input type="text" class="form-control floating-label" placeholder="' +
((__t = ( _.i18n('tracking.trackingNumber') )) == null ? '' : __t) +
'">\n  </div>\n  <div class="status">\n    <div class="title">' +
((__t = ( _.i18n('tracking.trackingStatus') )) == null ? '' : __t) +
'</div>\n    <input type="text" class="form-control floating-label" placeholder="' +
((__t = ( _.i18n('tracking.trackingStatus') )) == null ? '' : __t) +
'">\n  </div>\n  <div class="error-message">\n    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>\n    <div class="text">' +
((__t = ( _.i18n('tracking.error.tracking-already-exists') )) == null ? '' : __t) +
'</div>\n  </div>\n</div>\n<div class="footer">\n  <div class="cancel-region"></div>\n  <div class="confirm-region"></div>\n</div>';

}
return __p
};});

