define('webshopProductsDetailBodyCategoriesTagsEditView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'modalsConfirmationView',
  'selectView',
  'template!webshopProductsDetailBodyCategoriesTagsEditView',
  'entities/webshop/products'
], function(
  App,
  Module,
  _,
  Backbone,
  ModalsConfirmationView,
  SelectView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-categories-tags-view ' + ModalsConfirmationView.prototype.className,

    regions: _.extend({
      tagsRegion: '.tags-region'
    }, ModalsConfirmationView.prototype.regions),

    initialize: function () {

    },

    onShow: function () {
      this.tagsView = new SelectView({
        collection: new Backbone.Collection()
      });
      this.tagsRegion.show(this.tagsView);

      App.request('webshop.products.tags').done(_.bind(function (tags) {
        this.tagsView.collection.add({id: '', value: _.i18n('webshop.product.tag.empty')});

        _.each(tags.models, _.bind(function (tag) {
          var model = new Backbone.Model({
            id: tag.get('id'),
            value: _.i18n('webshop.product.tag.' + tag.get('code'))
          });
          this.tagsView.collection.add(model);
          this.tagsView.setSelected(this.model.getTag());
        }, this));
      }, this));

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    onConfirm: function () {

      //----------------
      ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'webshopProductsDetailBodyCategoriesTagsEditView'
      ], function (Windows, View) {
        var title = _.i18n('webshop.product.tags.edit.title');
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          title: title,
          width: 350,
          maxHeight: 600
        });
      });
    }
  });
});
