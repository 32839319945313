define('tableHeaderFieldView',[
  'module',
  'marionette',
  'underscore',
  'template!tableHeaderFieldView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'table-field-view', 
    name: '',
    sort: false,     
    sortDirection: 'asc',
    sortEnabled: false,
    
    ui: {
      label: '.text',
      sortUp: '.sort-up',
      sortDown: '.sort-down'
    },

    events: {     
      'click': 'onSortChange'
    },

    attributes: function() {   
      var att = {};

      if(this.options.field.title)
        att.title = this.options.field.title;

      if(this.options.field.sort === true)
        att['data-sort'] = 'true';

      return att;   
    },  

    initialize: function () {
      this.tableView = this.options.tableView;
      this.field = this.options.field;

      if(this.field.name)
        this.name = this.field.name;
    
      if(this.field.sort)
        this.sort = this.field.sort;
    },
    
    onBeforeRender: function() {
      if(this.field.className)
        this.$el.addClass(this.field.className);
    },   

    onRender: function() {
     
      try { 
        if(this.field.onRenderHeader) {
          this.field.onRenderHeader(this);
        } else {
          if(this.field.label) {
            this.ui.label.html(this.field.label);
          } 
        }
      } catch(e) {
        console.log(e);
      } 

      if(this.sort)  
        this.showSort();     
    },

    onSortChange: function() {
      if(!this.sort)
        return;

      if(this.sortDirection === 'asc')
        this.sortDirection = 'desc';
      else if(this.sortDirection === 'desc')
        this.sortDirection = 'asc';

      this.showSort();

      this.trigger('sort:change', this.sortDirection);
    },

    showSort: function() {     
      if(this.sortDirection === 'asc') {        
        this.ui.sortUp.css('display', 'none');
        this.ui.sortDown.css('display', 'flex');
      }
        
      if(this.sortDirection === 'desc') {  
        this.ui.sortUp.css('display', 'flex');
        this.ui.sortDown.css('display', 'none');
      }
    },

    enableSort: function(value) {
      this.sortEnabled = value;
      if(this.sortEnabled)
        this.$el.attr('data-sort-enabled', 'true');
      else
        this.$el.attr('data-sort-enabled', 'false');
    }
  });
});  
