define('webshopProductsListPositionView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!webshopProductsListPositionView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-position-view ' + FieldView.prototype.className,

    serializeData: function() {
      var data = {};
      data.position = this.model.getPosition();
      return data;
    }
  });
});
