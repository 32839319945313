/**
 * Created by BFR on 24/02/2020.
 */
define('translationPrintAreasView',['module',
        'marionette',
        'translationPrintAreaView'],
  function(module,
           Marionette,
           TranslationPrintAreaView){
  'use strict';

  var TranslationPrintAreasView = Marionette.CollectionView.extend({
    childView: TranslationPrintAreaView,
    className: 'col-md-12 p-t-10'
  });

  module.exports = TranslationPrintAreasView;
});
