define('webshopCategoriesDetailHeaderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'dateUtils',
  'galleryView',
  'template!webshopCategoriesDetailHeaderView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  DateUtils,
  GalleryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-header-view',

    ui: {
      imageContainer: '.left .image',
      image: '.left img',
      name: '.name',
      code: '.code',
      createdAt: '.created-at .date',
      updatedAt: '.updated-at .date'
    },

    modelEvents: {
      'change:medias': 'renderImage',
      'change:texts': 'renderContent',
      'change:createdAt': 'renderContent',
      'change:updatedAt': 'renderContent',
      'change:code': 'renderContent'
    },

    events: {
      'click @ui.imageContainer': 'onClick'
    },

    serializeData: function() {
      var data = {};
      data.src = this.model.getPreviewImageUrl();
      return data;
    },

    onRender: function () {
      this.renderImage();
      this.renderContent();
    },

    renderImage: function () {
      this.ui.image.attr('src', this.model.getPreviewImageUrl());
    },

    renderContent: function () {
      this.ui.name.html(this.model.getName());
      this.ui.code.html(this.model.getCode());
      this.ui.createdAt.html(DateUtils.toMDYHHMMFormat(this.model.get('createdAt')));
      this.ui.updatedAt.html(DateUtils.toMDYHHMMFormat(this.model.get('updatedAt')));
    },

    onClick: function () {
      var urls = this.model.getImageUrls();
      if(urls.length === 0)
        return;

      GalleryView.prototype.open(
        'webshop.category.gallery.' + this.model.getId(),
        _.i18n('webshop.category.gallery.title') + ' | ' + this.model.getName(),
        new Backbone.Model({urls: [this.model.getImageUrls()]}));
    }
  });
});
