define('customersDetailBodyTasksView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'tasksView',
  'entities/admin/tasks',
  'entities/customers/customers'
], function (
    App,
    Module,
    Backbone,
    Marionette,
    _,
    View
) {
  'use strict';

  Module.exports = View.extend({

    init: function() {
      this.newTaskModel = App.request('tasks:model');
      this.newTaskModel.set('refEndUser', {
        secId: this.model.get('secId'),
        code: this.model.get('code'),
        endUserId: this.model.get('endUserId')
      });
    },

    fetch: function (first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params.first = first;
      params.sidx = 'creatDate';
      params.sort = 'desc';

      if (this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('customers:tasks', this.model.get('endUserId'),  params),
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function (item) {
            var model = App.request('tasks:model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function (filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if (this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        url: App.request('customers:tasks.row-count', this.model.get('endUserId'), params),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  });
});

