/**
 * Created by BFR on 24/02/2020.
 */
define('editPrintZonesView',['module',
    'marionette',
    'editPrintZoneView'],
  function (module,
            Marionette,
            EditPrintZoneView) {
    'use strict';

    var EditPrintZonesView = Marionette.CollectionView.extend({
      childView: EditPrintZoneView
    });

    module.exports = EditPrintZonesView;
  });
