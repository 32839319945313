
define('template!webshopProductsDetailBodySlugsLanguageView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="language">' +
((__t = ( language )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n    <div class="slug">\n        <input type="text">\n    </div>\n    <div class="history">\n        <div class="title">\n            <div class="libelle">' +
((__t = ( _.i18n('webshop.slugs.history.title') )) == null ? '' : __t) +
'</div>\n            <div class="icons">\n                <div class="opened icon">\n                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>\n                </div>\n                <div class="closed icon">\n                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>\n                </div>\n            </div>\n        </div>\n        <div class="history-region"></div>\n    </div>\n</div>\n';

}
return __p
};});

