/**
 * Created by Matteo on 19/07/2015.
 */
define('unauthorizedShowController',[
  'module',
  'app',
  'unauthorizedShowView'
], function(
  module,
  App,
  View
) {
  'use strict';

  var UnauthorizedController = {
    showUnauthorized: function() {
      var view = new View();

      App.regions.getRegion('main').show(view);
    }
  };

  module.exports = UnauthorizedController;
});
