define('pimAttributeView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'template!pimAttributeTpl',
  'pimAttributeValuesView'
], function(
  module,           
  Marionette,
  App,
  _,
  viewTpl,
  PimAttributeValuesView
){
  'use strict';

  var PimAttributeView = Marionette.LayoutView.extend({   
    template: viewTpl,
    className: 'attribute',

    regions: {
      valuesRegions: '.values-region'
    },

    ui: {
      editCodeInput: '.attribute-code',
      newAttributeValueButton: '.new-attribute-value-button',
      valuesContainer: '.values-region'   
    },

    events: {
      'change @ui.editCodeInput': 'onCodeChange',
      'click @ui.deleteButton': 'onDelete',
      'click @ui.newAttributeValueButton': 'onNewAttributeValue'
    },

    modelEvents: {
      'change': 'render'
    },

    onCodeChange: function () {    
      this.model.set('code', this.ui.editCodeInput.val());
    },

    onRender: function () {     
      this.attributeValuesView = new PimAttributeValuesView({
        'collection': this.options.valuesCollection, 
        'attributeModel': this.model,
        'viewModel': this.options.viewModel
      });
      this.getRegion('valuesRegions').show(this.attributeValuesView);
    },

    onNewAttributeValue: function()
    {
      this.options.valuesCollection.add({
        'code': ''
      });

      this.ui.valuesContainer.animate({ scrollTop: this.ui.valuesContainer.find('.list').height() }, 0);
    },

    serializeData: function () {
      var templateData = {};

      templateData.code = this.model.get('code');
      return templateData;
    }
  });

  module.exports = PimAttributeView;
});
