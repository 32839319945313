
define('template!orderStatusDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well mbamodel-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( code ? code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n\n\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.displayInKanban') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">\n                <input disabled="disabled" type="checkbox" class="cpz-checkbox2 js-ticket-urgent" ' +
((__t = ( displayInKanban ? 'checked' : '' )) == null ? '' : __t) +
' />\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.validatedStatus') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">\n                <input disabled="disabled" type="checkbox" class="cpz-checkbox2 js-ticket-urgent" ' +
((__t = ( validatedStatus ? 'checked' : '' )) == null ? '' : __t) +
' />\n            </div>\n        </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.assignUserToOrder') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">\n              <input disabled="disabled" type="checkbox" class="cpz-checkbox2 js-ticket-urgent" ' +
((__t = ( assignUserToOrder ? 'checked' : '' )) == null ? '' : __t) +
' />\n          </div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.unAssignUsers') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">\n              <input disabled="disabled" type="checkbox" class="cpz-checkbox2 js-ticket-urgent" ' +
((__t = ( unAssignUsers ? 'checked' : '' )) == null ? '' : __t) +
' />\n          </div>\n      </div>\n    </div>\n\n    <div class="row">\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.displayOrder') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( displayOrder ? displayOrder : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( description ? description : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.params') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( params ? params : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('orderstatus.refParentStatus') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refParentStatus ? refParentStatus.name : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

