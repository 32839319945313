
define('template!selectOptionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
((__t = ( value )) == null ? '' : __t);

}
return __p
};});

