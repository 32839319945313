define('tableView',[
  'module',
  'marionette',
  'underscore',
  'tableHeaderView',
  'tableBodyView', 
  'tableFooterView',
  'template!tableView',
  'contextMenu'
], function(
  Module,  
  Marionette,
  _,
  HeaderView,
  BodyView, 
  FooterView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'table-view',  
    fields: [],  
    pageSize: 50,
    defaultSortField: '',
    defaultSortDirection: 'desc',
    header: true,
    pageMode: 'infinite',
    filtersViewClass: null,
    filters: null,
    sortable: false,
    selectable: false,
    sortActivated: false,
    filtersActivated: true,
    footerMaxPages: 10,
    showPaginator: true,
    showPageSizeSeletor: true,

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region',
      filtersRegion: '.filters-region',
      footerRegion: '.footer-region'
    },

    initialize: function() {
      this.fields = this.setFields();
      this.fetchRequest = null;
      this.fetchCountRequest = null;
      this.init();
    },

    onDestroy: function() {
      if(this.fetchRequest)
          this.fetchRequest.abort();
      if(this.fetchCountRequest)
          this.fetchCountRequest.abort();
    },

    onShow: function() {
      if(this.header) {
        this.headerView = new HeaderView({
          tableView: this
        });
        this.getRegion('headerRegion').show(this.headerView);
      } else {
        this.$el.find('.header-region').hide();
      }

      //------------
      if(this.filtersViewClass) {
        this.filtersView = new this.filtersViewClass({
          tableView: this
        });

        this.getRegion('filtersRegion').show(this.filtersView);
      } else {
        this.$el.find('.filters-region').hide();
      }

      //------------
      this.bodyView = new BodyView({
        tableView: this,
        fetch: _.bind(this.fetch, this)
      });

      this.getRegion('bodyRegion').show(this.bodyView);

      //------------
      if(this.pageMode === 'pagination') {
        this.footerView = new FooterView({
          view: this,
          fetch: _.bind(this.fetchCount, this)
        });
        this.getRegion('footerRegion').show(this.footerView);
      } else {
        this.$el.find('.footer-region').hide();
      }
    },

    onRender: function() {
      if(!this.header)
        this.$el.find('.header-region').hide();

      if(this.contextMenu) {
        this.$el.contextMenu({
          selector: '.table-row-view',
          build: _.bind(function () {
            //this.contextMenu.id = $(e.currentTarget).attr('data-id');
            return {
              items: this.contextMenu()
            };
          }, this)
        });
      }
    },

    rowAttributes: function() {
      return {};
    },

    rowModelEvents: function() {
      return {};
    },

    init: function() {},
  
    fetch: function() { 
      var defer = $.Deferred();     
      defer.resolve([]);        
      return defer.promise();
    },

    fetchCount: function() {
      var defer = $.Deferred();
      defer.resolve([]);
      return defer.promise();
    },

    reload: function() {
      this.bodyView.reload();
    },

    reset: function() {
      this.bodyView.reset();
    },

    refresh: function() {
      this.first = 0;
      this.bodyView.fetch();
      this.footerView.fetch();
    },

    setFields: function() {
      return [];
    },

    getSelected: function() {
      return this.bodyView.getSelected();
    },

    selectAll: function() {
      this.bodyView.selectAll();
    },

    unSelectAll: function() {
      this.bodyView.unSelectAll();
    }
  });  
});

