
define('template!webshopProductsDetailBodyTextsLanguageView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <div class="language">' +
((__t = ( language )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n    <div class="texts">\n        <div class="name item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n            <input type="text" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n        <div class="description item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n            <textarea>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </div>\n        ';
 if(showExtraDescription) { ;
__p += '\n            <div class="extra-description item">\n                <div class="libelle">' +
((__t = ( _.i18n('webshop.product.texts.extra-description') )) == null ? '' : __t) +
'</div>\n                <textarea>' +
((__t = ( extraDescription )) == null ? '' : __t) +
'</textarea>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n    ';
 if(showSeo) { ;
__p += '\n        <div class="seo">\n            <div class="title">' +
((__t = ( _.i18n('common.seo') )) == null ? '' : __t) +
'</div>\n            <div class="meta-title item">\n                <div class="libelle">' +
((__t = ( _.i18n('common.meta-title') )) == null ? '' : __t) +
'</div>\n                <input type="text" value="' +
((__t = ( metaTitle )) == null ? '' : __t) +
'">\n            </div>\n            <div class="meta-description item">\n                <div class="libelle">' +
((__t = ( _.i18n('common.meta-description') )) == null ? '' : __t) +
'</div>\n                <textarea>' +
((__t = ( metaDescription )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

