/**
 * Created by OLD on 23/11/2015.
 */
define('priceController',[
  'module',
  'logger',
  'underscore',
  'app',
  'priceLayoutView'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var PricesController = {
    showPrices: function(tab) {
      var defer = $.Deferred(), 
        view;

      if (true === true) {
        view = new View({tab: tab});
        this.view = view;
        view.listenTo(view, 'onChangeTab', _.bind(this.onChangeTab, this));

        App.regions.getRegion('main').currentView.getRegion('content').show(view);
        defer.resolve();
      } else {
        defer.resolve();
      }
      return defer.promise();
    },

    onChangeTab: function(tab) {
      App.navigate('prices/' + tab, {trigger: true});
    }
  };

  module.exports = PricesController;
});
