define('adminWebhookHistoryFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'adminWebhookHistoryFiltersEditView',
  'filtersBannerView'
], function(
  Module,
  Backbone,
  _,
  Settings,
  moment,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
      var settings = Settings.get('adminWebhookHistoryFilters', true);
      if(settings) {
        this.model = new Backbone.Model(JSON.parse(settings));
      } else {
        this.model = new Backbone.Model({
          dateFrom: '',
          dateTo: '',
          type: '',
          status: ''
        });
        Settings.set('adminWebhookHistoryFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    onDeleteFilter: function(model, filterModel) {
      if(filterModel.get('id') === 'dates') {
        model.set('dateFrom', '');
        model.set('dateTo', '');
      } else
        model.set(filterModel.get('id'), '');
    },

    setCollection: function() {
      var dateValue = '';

      if(this.model.get('dateFrom') !== '' && this.model.get('dateTo') !== '') {
        dateValue = moment(new Date(this.model.get('dateFrom'))).format('DD/MM/YYYY');
        dateValue += ' - ';
        dateValue += moment(new Date(this.model.get('dateTo'))).format('DD/MM/YYYY');
        this.collection.add(new Backbone.Model({
          id: 'dates',
          libelle: _.i18n('webhook-history.dates'),
          value: dateValue
        }));
      }

      //----------------
      if(this.model.get('type') !== '') {
        var types = new Backbone.Collection([
          {id: '', value: _.i18n('webhook-history.type.all')},
          {id: 'INVOICE_CREATED', value: _.i18n('webhook-history.type.INVOICE_CREATED')}
        ]);

        var typeValue = '';
        _.each(types.models, _.bind(function (type) {
          if(type.get('id') === this.model.get('type'))
            typeValue = type.get('value');
        }, this));
        this.collection.add(new Backbone.Model({
          id: 'type',
          libelle: _.i18n('common.type'),
          value: typeValue
        }));
      }

      //----------------
      if(this.model.get('status') !== '') {
        var status = new Backbone.Collection([
          {id: '', value: _.i18n('webhook-history.type.all')},
          {id: 'OK', value: 'OK'},
          {id: 'ERROR', value: 'ERROR'},
          {id: 'TODO', value: 'TODO'}
        ]);

        var statusValue = '';
        _.each(status.models, _.bind(function (status) {
          if(status.get('id') === this.model.get('status'))
            statusValue = status.get('value');
        }, this));
        this.collection.add(new Backbone.Model({
          id: 'status',
          libelle: _.i18n('common.status'),
          value: statusValue
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('dateFrom') !== model.dateFrom ||
          this.model.get('dateTo') !== model.dateTo)
        needFetch = true;

      if(this.model.get('type') !== model.type)
        needFetch = true;

      if(this.model.get('status') !== model.status)
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('adminWebhookHistoryFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.filters.set(this.model.toJSON());
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

