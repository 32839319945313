define('userView',[
  'module',
  'marionette',
  'underscore',
  'settings',
  'imageUtils',
  'template!userView'
], function (
  Module,
  Marionette,
  _,
  Settings,
  ImageUtils,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,    
    className: 'user-view',
    showTitle: true,

    ui: {
      logo: '.logo'
    },

    modelEvents: {
      'change:logo': 'render',
      'change:logonId': 'render',
      'change:firstName': 'render',
      'change:lastName': 'render'
    },

    initialize: function () {
      if(this.options.showTitle === false)
        this.showTitle = false;
    },

    serializeData: function () {
      var data = {};
      data.src = '';

      if (this.model.get('logo'))
        data.src = Settings.url('file', this.model.get('logo'), { size: 'thumbnail'});
      else if (this.model.get('logonId'))
        data.src = ImageUtils.getLogo(null, this.model.get('logonId'), null, 'circle');

      return data;
    },

    onRender: function () {
      if(this.showTitle) {
        var title = '';
        if (this.model.get('firstName'))
          title = this.model.get('firstName');

        if (this.model.get('lastName')) {
          if (title !== '')
            title += ' ';
          title += this.model.get('lastName');
        }
        this.$el.attr('title', title);
      }
    }
  });
});
  
