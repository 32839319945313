define('collectionViewInvited',['module',
        'backbone',
        'marionette',
        'jquery',
        'collectionItemViewInvited'],
    function(module,
             Backbone,
             Marionette,
             $,
             CollectionItemViewInvited){
        'use strict';

        var CollectionViewInvited = Marionette.CollectionView.extend({
            childView: CollectionItemViewInvited,
            collection: new Backbone.Collection(),

            onBeforeDestroyCollection: function() {
                var model;
                while ((model = this.collection.first())) {
                    model.destroy();
                }
            },

            onRender: function() {
                $.material.init();
            }
        });

        module.exports = CollectionViewInvited;
    });
