define('uploadFileView',[
  'module',
  'marionette',
  'template!uploadFileTpl',
  'imageUtils'
], function(
  module,
  Marionette,
  uploadFileTpl,
  imageUtils
) {
  'use strict';

  var UploadFileView = Marionette.ItemView.extend({
    className: 'upload-file',
    template: uploadFileTpl,

    ui: {
      changeLogo: '.js-change-logo'
    },

    events: {
      'change @ui.changeLogo': '_changeLogo'
    },

    _changeLogo: function() {
      var target = this.ui.changeLogo[0],
        fileInfo = imageUtils.getFileInfo(target, 0);

      if (fileInfo) {
        this.trigger('uploaded', fileInfo);
      }
    }
  });

  module.exports = UploadFileView;
});
