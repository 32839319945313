define('tableDatetimeFieldView',[
  'module', 
  'dateUtils',
  'tableFieldView'
], function (
  Module,
  DateUtils,
  FieldView
) {
  'use strict';

  Module.exports = FieldView.extend({
    className: 'table-field-datetime-view ' + FieldView.prototype.className,

    onRender: function() {
      if(this.options.field.bind && this.model.get(this.options.field.bind) )         
        this.$el.html(DateUtils.toMDYHHMMFormat(this.model.get(this.options.field.bind)));            
    }
  });
});
