define('webshopCategoriesDetailBodyTabsView',[
  'app',
  'module',
  'underscore', 
  'rolesMixin',
  'tabsView',
  'webshopCategoriesDetailBodyCategoryView',
  'webshopCategoriesDetailBodyTextsView',
  'webshopCategoriesDetailBodySlugsView',
  'webshopCategoriesDetailBodyProductsView',
  'webshopCategoriesDetailBodyUrlsView'
], function (
  App,
  Module,
  _,
  RolesMixin,
  TabsView
) {
  'use strict';

  Module.exports = TabsView.extend({
    fetch: function () {
      this.collection.push({
        name: 'category',
        url: '',
        title: _.i18n('webshop.categories.category.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_CATEGORY,
        defaultSelected: true,
        view: 'webshopCategoriesDetailBodyCategoryView'
      });
      this.collection.push({
        name: 'texts',
        url: 'texts',
        title: _.i18n('webshop.categories.texts.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_TEXTS,
        view: 'webshopCategoriesDetailBodyTextsView'
      });
      this.collection.push({
        name: 'slugs',
        url: 'slugs',
        title: _.i18n('webshop.categories.slugs.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_SLUGS,
        view: 'webshopCategoriesDetailBodySlugsView'
      });
      this.collection.push({
        name: 'products',
        url: 'products',
        title: _.i18n('webshop.categories.products.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_PRODUCTS,
        view: 'webshopCategoriesDetailBodyProductsView'
      });
      this.collection.push({
        name: 'urls',
        url: 'urls',
        title: _.i18n('common.urls'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_URLS,
        view: 'webshopCategoriesDetailBodyUrlsView'
      });
      this.collection.push({
        name: 'images',
        url: 'images',
        title: _.i18n('common.images'),
        role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES_IMAGES,
        view: 'webshopCategoriesDetailBodyImagesView'
      });
    }
  });
});
