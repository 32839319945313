
define('template!adminImprintTechnologiesCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="code">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 name-region">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="colorModeType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.colorModeType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="colorModeType" value="' +
((__t = ( colorModeType )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('imprintTechnology.colorModeType') )) == null ? '' : __t) +
'">\n            <option value="quadri" ';
  if (!colorModeType || colorModeType === 'quadri') { ;
__p += ' selected ';
 } ;
__p += ' >Quadri</option>\n            <option value="quadriPlusSpot" ';
  if (colorModeType === 'quadriPlusSpot') { ;
__p += ' selected ';
 } ;
__p += ' >QuadriPlusSpot</option>\n            <option value="spot" ';
  if (colorModeType === 'spot') { ;
__p += ' selected ';
 } ;
__p += ' >Spot</option>\n            <option value="fixedFilling" ';
  if (colorModeType === 'fixedFilling') { ;
__p += ' selected ';
 } ;
__p += ' >FixedFilling</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="maxNumberOfColors">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.maxNumberOfColors') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="maxNumberOfColors" placeholder="' +
((__t = ( _.i18n('imprintTechnology.maxNumberOfColors') )) == null ? '' : __t) +
'" value="' +
((__t = ( maxNumberOfColors )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group checkbox-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("imprintTechnology.colorSeparation") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" data-field-name="colorSeparation" class="checkbox-input colorSeparation" ' +
((__t = ( colorSeparation?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group checkbox-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("imprintTechnology.colorSeparationCrop") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" data-field-name="colorSeparationCrop" class="checkbox-input colorSeparationCrop" ' +
((__t = ( colorSeparationCrop?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="minStrokeWidth">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.minStrokeWidth') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="minStrokeWidth" placeholder="' +
((__t = ( _.i18n('imprintTechnology.minStrokeWidth') )) == null ? '' : __t) +
'" value="' +
((__t = ( minStrokeWidth )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="individualPrinting">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("imprintTechnology.individualPrinting") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" id="individualPrinting" class="individualPrinting" ' +
((__t = ( individualPrinting?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.vrpWarningMessage') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 vrp-warning-message-region">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="vrpWarningMessageFontSize">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.vrpWarningMessageFontSize') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="vrpWarningMessageFontSize" placeholder="' +
((__t = ( _.i18n('imprintTechnology.vrpWarningMessageFontSize') )) == null ? '' : __t) +
'" value="' +
((__t = ( vrpWarningMessageFontSize )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="vrpWarningMessageColor">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.vrpWarningMessageColor') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="vrpWarningMessageColor" placeholder="' +
((__t = ( _.i18n('imprintTechnology.vrpWarningMessageColor') )) == null ? '' : __t) +
'" value="' +
((__t = ( vrpWarningMessageColor )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="filter">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.filter') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="filter" value="' +
((__t = ( filter )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('imprintTechnology.filter') )) == null ? '' : __t) +
'">\n            <option value="NONE" ';
  if (!filter) { ;
__p += ' selected ';
 } ;
__p += ' >None</option>\n            <option value="EMBOSS" ';
  if (filter === 'EMBOSS') { ;
__p += ' selected ';
 } ;
__p += ' >Embossing</option>\n            <option value="DEBOSS" ';
  if (filter === 'DEBOSS') { ;
__p += ' selected ';
 } ;
__p += ' >Debossing</option>\n            <option value="EMBROIDERY" ';
  if (filter === 'EMBROIDERY') { ;
__p += ' selected ';
 } ;
__p += ' >Embroidery</option>\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

