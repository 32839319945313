define('ordersDetailSupplierInvoiceView',[
  'module',
  'marionette',
  'underscore',
  'dateUtils',
  'settings',
  'template!ordersDetailSupplierInvoiceView'
], function (
  module,
  Marionette,
  _,
  DateUtils,
  Settings,
  Tpl

) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    className: 'invoice-view',
    template: Tpl,

    serializeData: function () {
      var data = {};

      data.id = '-';
      if (this.model.get('id'))
        data.id = this.model.get('id');

      data.date = '-';
      if (this.model.get('date')) {
        if (this.model.get('date').indexOf('00:00:00.000+00:00') > -1) {
          data.date = DateUtils.toDateFormatString(this.model.get('date'));
        } else {
          data.date = DateUtils.toMDYHHMMFormat(this.model.get('date'));
        }
      }

      var numberLocale = 'fr-BE';
      var configNumberLocale = Settings.configValue('priceLocale');
      if (configNumberLocale)
        numberLocale = configNumberLocale;

      data.amount = '-';
      if (this.model.get('amount'))
        data.amount = '€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(this.model.get('amount'));

      data.deliveryCosts = '-';
      if (this.model.get('delivery_costs'))
        data.deliveryCosts = '€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(this.model.get('delivery_costs'));

      return data;
    }
  });
});
