/**
 * Created by BFR on 18/02/2020.
 */
define('pimProductAttributesTabView',[
  'module',
  'app',
  'jquery',
  'marionette',  
  'underscore',
  'backbone',
  'autocompleteView',
  'pimProductAttributeView',
  'template!pimProductAttributesTabTpl',
  'pimProductSelectedAttributesView',
  'pimButtonsView',
  'entities/pim_attribute',
  'entities/pim_product'
], function (
  module,
  App,
  $,
  Marionette,
  _,
  Backbone,
  AutocompleteView,
  PimProductAttributeView,
  viewTpl,
  PimProductSelectedAttributesView,
  PimButtonsView
) {
  'use strict';

  var PimProductAttributesTabView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'pim-page product-attributes',

    regions: {
      attributeViewRegion: '.attribute-view-region',
      selectedAttributesView: '.selected-attributes-region',
      supplierAttributesView: '.supplier-attributes-region',
      attributeListRegion: '.attribute-list-region',
      buttonsRegion: '.buttons-region'
    },     

    ui: {
      blockLoading: '.js-loading-block'
    },

    initialize: function() {   
      
      this.attributeValuesCollection = App.request('pim:attribute:values:collection');

      this.viewModel = new Backbone.Model();   
      this.viewModel.set('currentSecId', '');                  

      this.resProduct = this.model.getCurrentResProduct();

      //this.on('pim:product-attribute:updateView', updateView);
    },

    onAttributeChange: function(property, selectedModel)
    {         
      this.viewModel.set('currentSecId', selectedModel.get('secId')); 

      this.attributeModel = App.request('pim:attribute:model', {'secId': selectedModel.get('secId')});
      this.attributeModel.fetch().done(_.bind(this.updateValues, this));  
    },

    updateValues: function()
    {      
      if(this.attributeModel.get('secId')) {
        this.attributeView = new PimProductAttributeView({
          'model': this.attributeModel, 
          'valuesCollection': this.attributeValuesCollection, 
          'viewModel': this.viewModel,
          'productAttribute': this.productAttributeModel
        });
        
        this.getRegion('attributeViewRegion').show(this.attributeView);  
      }      
    },

    onCancel: function()
    {
      this.viewModel.set('currentSecId', ''); 
      this.render();
    },

    onSave: function()
    {
      this.ui.blockLoading.show();  

      this.productAttributeModel.save(this.model.get('id')).done(_.bind(function(){
        this.ui.blockLoading.hide();
      }, this));
    },

    onRender: function () {            

      this.attributesAutocompleteView = new AutocompleteView({
        type: 'remote',
        alwaysOpen: false,
        remote: App.request('pim:attributes:get-collection-url'),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('select-attribute.placeholder'),
        value: null,
        name: 'code',
        onPressKey: true,
        lazyLoad: true,
        keys: {
            query: 'code',
            limit: 'pageSize'
        },
        callback: _.bind(this.onAttributeChange, this)
      });   

      this.getRegion('attributeListRegion').show(this.attributesAutocompleteView);  

      //-----------

      var collection = App.request('pim:product:attribute:collection', this.model.get('data').get('customFields'));

      this.supplierAttributeView = new PimProductSelectedAttributesView({
        collection: collection,
        productAttributeModel: null
      });       

      this.getRegion('supplierAttributesView').show(this.supplierAttributeView); 

      //----------       

      this.pimButtonsView = new PimButtonsView({
        model: this.productAttributeModel,
        pimProductAttributesTabView: this
      });

      this.getRegion('buttonsRegion').show(this.pimButtonsView);    

      //-----------

      this.productAttributeModel = App.request('pim:product:attribute:model', this.model.get('id')); 

      this.productAttributeModel.fetch(this.model.get('id')).done(_.bind(function() {
       
        this.selectedAttributeView = new PimProductSelectedAttributesView({
          collection: this.productAttributeModel.get('customFields'),
          productAttributeModel: this.productAttributeModel
        });       

        this.getRegion('selectedAttributesView').show(this.selectedAttributeView);          

      }, this));     
    },

    onShow: function () {  
      this.selectedAttributeView = new PimProductSelectedAttributesView({
        collection: this.productAttributeModel.get('customFields'),
        productAttributeModel: this.productAttributeModel
      });       

      this.getRegion('selectedAttributesView').show(this.selectedAttributeView);    
    },

    serializeData: function () {
      var templateData = this.model.toJSON();       
      
      return templateData;
    } 

  });

  module.exports = PimProductAttributesTabView;
});
