
define('template!changeDatePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label class="col-xs-3 control-label">' +
((__t = ( label )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-9">\n        <input type="datepicker" class="form-control date js-info-input" data-field-name="date" placeholder="' +
((__t = ( date )) == null ? '' : __t) +
'" value="' +
((__t = ( date )) == null ? '' : __t) +
'">\n        <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n    </div>\n</div>\n';

}
return __p
};});

