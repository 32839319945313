define('customersDetailBodyContactView',[
  'module',
  'marionette',
  'app',
  'customersDetailBodyContactTabs',
  'template!customersDetailBodyContactView',
  'imageUtils',  
  'backToListBehavior'
], function(
  Module,
  Marionette,
  App,
  customerContactData,
  layoutTpl,
  imageUtils,
  BackToListBehavior
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: layoutTpl,
    className: 'customer-contacts-details-view',

    ui: {
      tab: '.contact-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder'
    },

    events: {
      'click @ui.tab': 'onTabClick'
    },

    regions: {
      tabContent: '#customer-contact-tab-content'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'customers/' + App.getCurrentRoute().split('/')[1] + '/contacts'
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.customerContactTabs = customerContactData;
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.contact-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    }
  });
});
