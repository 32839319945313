/**
 * Created by BFR on 24/02/2020.
 */
define('editPimCatsView',['module',
        'marionette',
        'editPimCatView'],
  function(module,
           Marionette,
           EditPimCatView){
  'use strict';

  var EditPimCatsView = Marionette.CollectionView.extend({
    childView: EditPimCatView,
    className: 'cat-list',
    childViewOptions: function() {
      // do some calculations based on the model
      return {
        pimProduct: this.options.pimProduct
      };
    }
  });

  module.exports = EditPimCatsView;
});
