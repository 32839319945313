
define('template!caccountShareLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="col-md-12 col-lg-12 col-xxl-12">\n\t\t<div class="share-title">\n\t\t\t' +
((__t = ( _.i18n("cAccountDetail.share.from") )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t<div class="share-from-container"></div>\n\t</div>\n</div>\n';
 if (displayShareTo) { ;
__p += '\n<div class="col-md-12 col-lg-12 col-xxl-6">\n    <div class="share-title">\n        ' +
((__t = ( _.i18n("cAccountDetail.share.to") )) == null ? '' : __t) +
'\n    </div>\n    <div class="share-to-container"></div>\n</div>\n';
 } ;


}
return __p
};});

