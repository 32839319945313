define('emailAttachmentsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'settings',
  'dropzone',
  'imageUtils',
  'emailAttachmentView',
  'template!emailAttachmentsView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  Settings,
  Dropzone,
  ImageUtils,
  EmailAttachmentView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'email-attachments-view',
    childView: EmailAttachmentView,
    childViewContainer: '.attachments',
    childEvents: {
      loaded: function () {
        this.trigger('change');
      },
      deleted: function (view) {
        this.collection.remove(view.model);
        this.trigger('change');
      }
    },

    ui: {
      add: '.add',
      addInput: 'input',
      attachments: '.attachments'
    },

    events: {
      'click @ui.add': 'onAdd',
      'change @ui.addInput': 'onUploadAttachment'
    },

    onRender: function () {
      var that = this;
      this.ui.attachments.dropzone({
        url: Settings.url('compuzz'),
        autoProcessQueue: true,
        uploadMultiple: true,

        init: function () {
          this.on('addedfile', function(file) {
            var reader;

            if (file) {
              reader = new FileReader();
              reader.onload = _.bind(function () {
                var model = new Backbone.Model({
                  'name': file.name,
                  'arrayBuffer': reader.result,
                  'base64': that.arrayBufferToBase64(reader.result),
                  'content-disposition': 'attachment; filename="' + file.name + '"',
                  'content-type': file.type,
                  'loaded': true,
                  'local': true
                });
                that.collection.add(model);
              }, this);
              reader.onerror = function () {

              };
              reader.readAsArrayBuffer(file);
            }

            this.removeAllFiles();
          });
        },
        dictDefaultMessage: _.i18n('common.file.drag.drop')
      });
    },

    onAdd: function () {
      this.ui.addInput.trigger('click');
    },

    onUploadAttachment: function (e) {
      var target = e.target;
      //var fileInfo = ImageUtils.getFileInfo(target, 0);

      if (e && e.target && e.target.files && e.target.files[0]) {
        var file, reader;
        file = ImageUtils.getFile(target, 0);

        if (file) {
          reader = new FileReader();

          reader.onload = _.bind(function () {
            var model = new Backbone.Model({
              'name': file.name,
              'arrayBuffer': reader.result,
              'base64': this.arrayBufferToBase64(reader.result),
              'content-disposition': 'attachment; filename="' + file.name + '"',
              'content-type': file.type,
              'loaded': true,
              'local': true
            });
            this.collection.add(model);
          }, this);
          reader.onerror = function () {

          };
          reader.readAsArrayBuffer(file);
        }
      }
    },

    arrayBufferToBase64: function(buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  });
});
