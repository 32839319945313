define( 'entities/product_custom_fields',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'underscore',
  'backboneRelational',
  'entities/product_translation'
], function(
  App,
  $,
  Settings,
  Backbone,
  _
) {
  'use strict';
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = { ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = { ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.CustomFields = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'customFieldValues',
      relatedModel: 'CustomField',
      collectionType: 'CustomFieldCollection'
    }],

    idAttribute: 'secId',

    url: function() {
      return Settings.url('compuzz2', 'products.json/customfields/' + this.get('secId'));
    },

    setTextInLocale: function(lang, value) {
      this.get('dynamicTextLocalizeds').setTextInLocale(lang, value);
    },

    getTextInLocale: function(lang) {
      if (this.get('refGlossary')) {
        return this.get('refGlossary').get('refDynamicText').getTextInLocale(lang);
      } else {
        var text = this.get('dynamicTextLocalizeds').getTextInLocale(lang);
        if (text !== undefined) {
          return text;
        } else if (text === undefined && this.get('refDynamicText')) {
          return this.get('refDynamicText').getTextInLocale(lang);
        } else {
          return '';
        }
      }
    },

    isUseGlossary: function () {
      if (this.get('refGlossary') && !this.get('readyOnly')) {
        return true;
      } else if (this.get('refDynamicText') && !this.get('refDynamicText').get('readyOnly')) {
        return this.get('refDynamicText').isUseGlossary();
      } else {
        return false;
      }
    },

    isUseParentGlossary: function () {
      if (this.isUseGlossary() && !this.get('refGlossary')) {
        return true;
      } else {
        return false;
      }
    },

    getGlossary: function () {
      return this.get('refGlossary')?this.get('refGlossary'):(this.get('refDynamicText')?this.get('refDynamicText').getGlossary():null);
    },

    isOverridden: function(lang) {
      if (this.get('refDynamicText')) {
        return this.get('dynamicTextLocalizeds').isOverridden(lang);
      } else {
        return false;
      }
    },

    revertText: function(lang) {
      this.get('dynamicTextLocalizeds').revertText(lang);
    },

    getTranslationCollection: function() {
      var array = [];

      var langs = Settings.get('currentCAccount').languageSelectionJson.languages;
      _.each(langs, _.bind(function(lang) {
        array.push(new Backbone.Model({
          lang: lang.locale,
          value: this.getTextInLocale(lang.locale),
          dt: this
        }));
      }, this));
      return new Backbone.Collection(array);
    }
  });

  app.ns.CustomField = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    },
    {
      type: Backbone.HasOne,
      key: 'value',
      relatedModel: 'DynamicText'
    }],
    idAttribute: 'id'
  });

  app.ns.CustomFieldCollection = Backbone.Collection.extend({
    model: 'CustomField',
    comparator: 'code'
  });



  App.reqres.setHandler('products-customfields:model', function (productCustomFieldJson) {
    var tt = Backbone.Relational.store.find(app.ns.CustomFields, productCustomFieldJson.secId );
    if (tt) {
      return tt;
    } else {
      return new app.ns.CustomFields(productCustomFieldJson);
    }
  });

  App.reqres.setHandler('products-customfields:glossary-model', function (glossaryJson) {
    var tt = Backbone.Relational.store.find(app.ns.Glossary, glossaryJson.secId );
    if (tt) {
      return tt;
    } else {
      return new app.ns.Glossary(glossaryJson);
    }
  });
});

