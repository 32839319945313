define('customersDetailBodyMailView',[
  'module',
  'underscore',
  'marionette',
  'jquery',
  'app',
  'settings',
  'dateUtils',
  'gmailUtils',
  'template!customersDetailBodyMailView'
], function (
  Module,
  _,
  Marionette,
  $,
  App,
  Settings,
  dateConverter,
  GmailUtils,
  viewTpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click .googleConnect': 'onClickConnect'
    },

    ui: {
      'messageIframe': '.message-iframe',
      'messageContainer': '.message-container'
    },

    serializeData: function () {
      var templateData;
      templateData = this.model.toJSON();
      return templateData;
    },

    onClickConnect: function () {
      var gmailUtils = GmailUtils.getInstance();
      gmailUtils.signIn();
    },

    onShow: function () {
      $.material.init();
      if (this.ui.messageIframe) {
        $('body', $(this.ui.messageIframe)[0].contentWindow.document).html(this.model.get('contentDoc'));
        setTimeout(_.bind(this.fitContent, this), 500);
      }
    },

    fitContent: function () {
      this.ui.messageIframe[0].height = this.ui.messageIframe[0].contentWindow.document.body.scrollHeight;
    }
  });
});
