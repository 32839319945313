
define('template!caccountViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  <div class="with-header">\n    <div class="caccount-details-header">\n      <div class="caccount-info-container row">\n        <div class="logo-container col-left col-lg-2">\n          <!-- Logo -->\n          <div class="original-img-wrapper zoom-img">\n            <div class="main text-center">\n              <div class="logo-holder js-logo-holder"></div>\n            </div>\n          </div>\n        </div>\n        <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n          <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n          <p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n        </div>\n\n        ';
 if (phone) { ;
__p += '\n        <div class="vertical-divider col-lg-1"></div>\n        <div class="col-left js-phone-holder m-t-10 col-lg-2">\n          <i class="mdi-notification-phone-in-talk icon holder-i"></i>\n          <span class="p-phone">' +
((__t = ( phone )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (contactEmail) { ;
__p += '\n        <div class="vertical-divider col-lg-1"></div>\n        <div class="col-left js-email-holder m-t-10 col-lg-2">\n          <i class="mdi-content-mail icon holder-i"></i>\n          <span class="p-email">' +
((__t = ( contactEmail )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (switchBtn) { ;
__p += '\n        <div class="form-group switch-button pull-right">\n          <a class="btn btn-primary js-switch-caccount">' +
((__t = ( _.i18n('common.switch') )) == null ? '' : __t) +
'</a>\n        </div>\n        ';
 } ;
__p += '\n      </div>\n      <div class="caccount-tabs-container tab-layout-header js-tab-layout-header">\n        <div class="navbar navbar-default navbar-md row ">\n          <div class="navbar-header">\n            <a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n            </a>\n          </div>\n          <div class="p-l-0 navbar-collapse collapse">\n            <ul class="nav navbar-nav" id="sub-caccount-tabs">\n              ';
 _.each(cAccountTabs, function(cAccountTab, index) { ;
__p += '\n              <li class=" js-tab-title caccount-tab';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '"\n                  data-role="' +
((__t = ( cAccountTab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'"\n                  data-route="' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'">\n                <a href="#' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'-tab" data-toggle="tab">' +
((__t = ( cAccountTab.title )) == null ? '' : __t) +
'</a>\n              </li>\n              ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="caccount-details-container tab-layout-content">\n    <div id="caccount-tab-content" class="tab-content content">\n      ';
 _.each(cAccountTabs, function(cAccountTab, index) { ;
__p += '\n      <div class="tab-pane';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( cAccountTab.role )) == null ? '' : __t) +
'"\n           data-name="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'"\n           id="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'-tab"></div>\n      ';
 }); ;
__p += '\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

