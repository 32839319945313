/**
 * Created by BFR on 20/09/2022.
 */
define('adminHtmlTemplatesController',[
  'module',
  'jquery',
  'underscore',
  'app',
  'adminHtmlTemplatesView',
  'entities/caccounts/htmltemplates'
], function (
  module,
  $,
  _,
  App,
  View
) {
  'use strict';

  module.exports = {

    onGridLoaded: function () {
      var data = {
        url: App.request('htmltemplates:get-url'),
        rowCountUrl: App.request('htmltemplates:row-count-url')
      };

      this.view.displayGrid(data, true);
    },

    _showView: function () {
      this.view = new View({});

      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    }
  };
});
