/**
 * Created by RKL on 05/08/2015.
 */
define('resetShowController',[
  'module',
  'underscore',
  'app',
  'resetShowView',
  'settings',
  'entities/session'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';

  var  ResetPwdViewController = {

    showReset: function() {
      var view = new View();

      view.listenTo(view, 'reset:password', _.bind(this.checkPassword, this));

      App.regions.getRegion('main').show( view );
    },

    checkPassword: function(obj) {
      var pass1 = obj.view.ui.password.val(),
        pass2 = obj.view.ui.passwordConfirm.val();

      this.view = obj.view;
      if (pass1 && pass1 === pass2) {
        this.sendRequest(pass1);
      } else {
        if (!pass1) {
          this.view.passwordError('empty');
        } else {
          this.view.passwordError('nonEqual');
        }
      }
    },

    sendRequest: function(password) {
      var that = this,
        model = App.request('session:reset:password'),
        params = App.getCurrentRoute().split('?')[1].split('&'),
        tokenFp;

      _.each(params, function(param) {
        if (param.indexOf('tokenfp') > -1) {
          tokenFp = param.split('=')[1];
        }
      });

      model.save({password: password, tokenFp: tokenFp}, {
        success: function() {
          App.trigger('thank_you:show', 'fromReset');
        },
        error: function(model, error) {
          if (error.status === 200) {
            App.trigger('thank_you:show', 'fromReset');
          } else {
            that.view.passwordError('reset:data:valid', 'requestError');
          }
        }
      });
    }
  };

  module.exports = ResetPwdViewController;
});
