
define('template!translationsPopupTextsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(items, function(language){ ;
__p += '\n  ';
 if(language.checked) { ;
__p += '\n    <div class="form-group row m-t-20" data-field-name="' +
((__t = ( language.abbreviation.toLowerCase() )) == null ? '' : __t) +
'">\n      <div class="col-xs-11">\n        <textarea class="form-control floating-label"\n                  placeholder="' +
((__t = ( language.name )) == null ? '' : __t) +
'" rows="2"\n                  data-field-name="' +
((__t = ( language.abbreviation.toLowerCase() )) == null ? '' : __t) +
'">' +
((__t = ( model[language.abbreviation.toLowerCase()] )) == null ? '' : __t) +
'</textarea>\n      </div>\n      <div class="col-xs-1">\n        <i class="mdi-action-delete md-grey-cl js-remove-icon clickable" data-abbreviation="' +
((__t = ( language.abbreviation )) == null ? '' : __t) +
'"></i>\n      </div>\n    </div>\n  ';
 } ;
__p += '\n';
 }) ;


}
return __p
};});

