define('mailsMailLabelsAddView',[
  'app',
  'backbone',
  'module',
  'underscore',
  'modalsConfirmationView',
  'selectView',
  'template!mailsMailLabelsAddView'
], function(
  App,
  Backbone,
  Module,
  _,
  ModalsConfirmationView,
  SelectView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'mails-mail-labels-add-view ' + ModalsConfirmationView.prototype.className,

    regions:  _.extend({
      selectBody: '.body-content',
      footer: '.footer'
    }, ModalsConfirmationView.prototype.regions),

    initialize: function() {
      this.collection = new Backbone.Collection();
    },

    fetch: function (node, cb) {
      if(!this.options.mailSecId)
        cb([]);

      var params = {};
      params.folder = 'all';

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('mails:get-folders-url', this.options.mailSecId, params),
        success: _.bind(function (items) {
          var mailLabels = this.model.get('labels');
          _.each(items, _.bind(function(item) {

            // If item is not yet in the list by comparing the id field, add it
            if (!_.find(mailLabels, { id: item.id })) {
              this.collection.push({
                  id: item.id,
                  value: item.fullName,
                  fullName: item.fullName,
                  name: item.name,
                  backgroundColor: item.backgroundColor,
                  textColor: item.textColor
                });
            }
          }, this));
        }, this)
      });
    },

    onShow: function () {
      // Use the SelectView to build a <select> HTML tag with <option> based on this.collection
      this.mySelect = new SelectView({
        collection: this.collection
      });

      // Inject the view in the region
      this.getRegion('selectBody').show(this.mySelect);
      this.mySelect.on('change', _.bind(this.onFolderChange, this));

      // Trigger the AJAX call
      this.fetch();

      // Continue with parent's view onShow
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    onFolderChange: function (model) {
      var selectedFolder = {
        id: model.get('id'),
        name: model.get('name'),
        backgroundColor: model.get('backgroundColor'),
        textColor: model.get('textColor')
      };
      this.model.set('selectedFolder', selectedFolder);
    },

    onConfirm: function () {
      var value = this.model.get('selectedFolder');
      this.model.addLabel(this.options.mailSecId, this.model.get('folder'), value).done(_.bind(function() {
        var labels = this.model.get('labels').slice();
        labels.push(value);
        this.model.set('labels', labels);
      }, this));
      this.windowView.close();
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'mailsMailLabelsAddView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          width: 400,
          maxWidth: 800,
          title: _.i18n('mails.labels.add')
        });
      });
    }
  });
});
