/**
 * Created by BFR on 17/06/2020.
 */
define('pimProductPriceCalculatorItemView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!pimProductPriceCalculatorItemTpl'
], function (
  module,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'row',
      regions: {
      },

    ui: {
      lblFactor: '.lbl-factor',
      txtFactor: '.txt-factor',
      lblProductUnitPrice: '.lbl-prod-unit-price',
      txtProductUnitPrice: '.txt-prod-unit-price'
    },

    events: {
      'click @ui.lblFactor': 'onLblFactorClick',
      'change @ui.txtFactor': 'onTxtFactorChange',
      'focusout @ui.txtFactor': 'onTxtFactorChange',
      'keydown @ui.txtFactor': 'onTxtFactorKeyPress',
      'click @ui.lblProductUnitPrice': 'onLblProductUnitPriceClick',
      'change @ui.txtProductUnitPrice': 'onTxtProductUnitPriceChange',
      'focusout @ui.txtProductUnitPrice': 'onTxtProductUnitPriceChange',
      'keydown @ui.txtProductUnitPrice': 'onTxtProductUnitPriceKeyPress'
    },

    onTxtFactorKeyPress: function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous', {field: 'factor'});
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next', {field: 'factor'});
        }
      }
    },

    onTxtFactorChange: function () {
      this.ui.lblFactor.show();
      this.ui.txtFactor.hide();
      this.options.model.get('factorModel').factor = Number(this.ui.txtFactor.val().replace(',', '.'));
      this.options.model.get('factorModel').defaultFactor = Number(this.ui.txtFactor.val().replace(',', '.'));
      this.render();
    },

    onTxtProductUnitPriceChange: function () {
      this.ui.lblFactor.show();
      this.ui.txtFactor.hide();
      this.options.model.get('factorModel').price = Number(this.ui.txtProductUnitPrice.val().replace(',', '.'));
      this.options.model.get('factorModel').defaultPrice = Number(this.ui.txtProductUnitPrice.val().replace(',', '.'));
      this.render();
    },

    onLblFactorClick: function () {
      this.ui.lblFactor.hide();
      this.ui.txtFactor.show();
      this.ui.txtFactor.focus();
      this.ui.txtFactor.select();
    },

    onTxtProductUnitPriceKeyPress: function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous', {field: 'productUnitPrice'});
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next', {field: 'productUnitPrice'});
        }
      }
    },

    onLblProductUnitPriceClick: function () {
      this.ui.lblProductUnitPrice.hide();
      this.ui.txtProductUnitPrice.show();
      this.ui.txtProductUnitPrice.focus();
      this.ui.txtProductUnitPrice.select();
    },

    onShow: function () {
      if (!this.options.customPrice) {
        this.ui.lblProductUnitPrice.removeClass('lbl-prod-unit-price').removeClass('txt-prod-unit-price');
        this.ui.lblProductUnitPrice.parent().removeClass('prices-editable');
      }
      //this.options.model.get('factorModel').factor = this.options.model.get('factorModel').defaultFactor;
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.unitPrice = (Number(this.options.model.get('factorModel').price) > 0) ? this.options.model.get('factorModel').price
        : templateData.productUnitPrice;
      templateData.sPrice = Number(Number(templateData.unitPrice) *
        this.options.model.get('factorModel').defaultFactor).toFixed(2);
      templateData.factor = Number(this.options.model.get('factorModel').defaultFactor).toFixed(3);
      return templateData;
    }
  });
});
