define('productTabsLayoutController',[
  'module',
  'app',
  'underscore',
  'productTabsLayoutView',
  'jquery',
  'entities/product'
], function (
  module,
  App,
  _,
  ProductTabsLayoutView,
  $
) {
  'use strict';

  var ProductTabsLayoutController = {
    _layoutView: undefined,

    showLayout: function (tabName, secId) {
      var defer = $.Deferred();

      if (!App.regions.getRegion('main').currentView.getRegion('content').currentView ||
        App.regions.getRegion('main').currentView.getRegion('content').currentView !== this._layoutView ||
        !this.product || this.product.get('secId') !== secId) {

        this.product = App.request('products:model', {secId: secId});
        this.product.fetch().done(_.bind(function () {
          this._layoutView = new ProductTabsLayoutView({
            model: this.product
          });
          App.regions.getRegion('main').currentView.getRegion('content').show(this._layoutView);
          this._layoutView.triggerMethod('show:active', tabName);

          defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
        }, this));
      } else {
        this._layoutView.triggerMethod('show:active', tabName);
        defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
      }
      return defer.promise();
    }
  };

  module.exports = ProductTabsLayoutController;
});
