
define('template!editorTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<iframe class="iframeEditor" id="iframeEditor" src="' +
((__t = ( url )) == null ? '' : __t) +
'" frameborder="0" seamless>\n</iframe>';

}
return __p
};});

