
define('template!ordersDetailSupplierProductInfosView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="column">\n  <div class="name item">\n    <div class="libelle">\n      <div class="text">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="value">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="quantity item">\n    <div class="libelle">\n      <div class="text">' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="value">' +
((__t = ( quantity )) == null ? '' : __t) +
'</div>\n  </div>\n</div>\n<div class="column">\n  <div class="code item">\n    <div class="libelle">\n      <div class="text">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="value">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n  </div>\n</div>\n<div class="column">\n  <div class="options-region"></div>\n</div>';

}
return __p
};});

