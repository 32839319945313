/**
 * Created by OLD on 07/08/2015.
 */
define('resetShowView',[
  'module',
  'underscore',
  'logger',
  'marionette',
  'app',
  'template!resetViewTpl',
  'tawlkToBehavior'
], function(
  module,
  _,
  Logger,
  Marionette,
  App,
  viewTpl,
  TawlkToBehavior
) {
  'use strict';

  var ResetPwdView =  Marionette.ItemView.extend({
    template: viewTpl,
    errorCodes: {
      empty: _.i18n('login.enter.password'),
      nonEqual: _.i18n('resetpassword.enter.notequal'),
      requestError: _.i18n('common.request.error')
    },

    triggers: {
      'click @ui.resetPassword' : 'reset:password'
    },

    ui: {
      password: '.js-password',
      passwordConfirm: '.js-password-confirm',
      resetPassword: '.js-reset-password',
      form: '.reset-pass-form'
    },

    events: {
      'click @ui.passwordConfirm'   : 'onSelect',
      'focus @ui.passwordConfirm'   : 'onSelect'
    },

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    onSelect: function() {
      this.ui.form.find('.floating-label').text('Confirm your password');
      this.ui.form.removeClass('has-error');
    },

    passwordError: function(error) {
      this.ui.form.addClass('has-error');
      this.ui.passwordConfirm.addClass('invalid');
      this.ui.form.find('.floating-label').text(error);
    }
  });

  module.exports = ResetPwdView;
});
