define('pimProductSelectedAttributeView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'pimProductSelectedAttributeValuesView',
  'template!pimProductSelectedAttributeTpl'
], function (
  module,
  Marionette,
  App,
  _,
  PimProductSelectedAttributeValuesView,
  viewTpl
) {
  'use strict';

  var PimProductSelectedAttributeView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'sa-item sa-item-col-3',

    regions: {
      valuesRegion: '.values-region'
    },

    initialize: function () {
      if (this.options.productAttributeModel)
        this.options.productAttributeModel.bind('change', this.render);
    },

    onDestroy: function () {
      if (this.options.productAttributeModel)
        this.options.productAttributeModel.unbind('change');
    },

    onRender: function () {
      this.pimProductSelectedAttributeValuesView = new PimProductSelectedAttributeValuesView({
        collection: this.model.get('cfValues')
      });

      this.getRegion('valuesRegion').show(this.pimProductSelectedAttributeValuesView);
    },

    modelEvents: {
      'change': 'render'
    },

    serializeData: function () {
      var templateData = {};

      templateData.code = this.model.get('code');
      return templateData;
    }
  });

  module.exports = PimProductSelectedAttributeView;
});
