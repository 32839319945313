/**
 * Created by BFR on 24/02/2020.
 */
define('editPrintAreasView',['module',
    'marionette',
    'editPrintAreaView'],
  function (module,
            Marionette,
            EditPrintAreaView) {
    'use strict';

    var EditPrintAreasView = Marionette.CollectionView.extend({
      childView: EditPrintAreaView,
      className: 'pa-list'
    });

    module.exports = EditPrintAreasView;
  });
