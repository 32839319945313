define('tariffScaleData',['module', 'underscore'],
function(module, _) {
    'use strict';

    module.exports = function(){
        var scales = [
            {
                send: 0,
                label: _.i18n('vectorization.tariffScale.free')
            }
        ];
        for (var i = 1 ; i <= 200 ; i++) {
            scales.push({
                send: i,
                  label : i
            });
        }
        return scales;
    }();
});
