
define('template!caccountCreditsAccountFormPopupTplFirst', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="vat__container">\n      <div class="col-xs-12 normal-light-compuzz">\n        <p class="billingHeader">' +
((__t = ( _.i18n('caccount.credits.firstLogin.text') )) == null ? '' : __t) +
'</p>\n      </div>\n      <div class="error-label col-md-12">\n          ' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg">  </div>\n      </div>\n      <form class="form-horizontal col-xs-12">\n        <!--<div class="col-xs-12 text-center">-->\n        <!--<div class="form-group" data-field-name="name"> -->\n        <!--<div class="col-xs-12">-->\n        <!--<input type="text" class="form-control floating-label"-->\n        <!--placeholder="' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'"-->\n        <!--data-field-name="name"/>-->\n        <!--</div>-->\n        <!--</div>-->\n        <!--<div class="form-group" data-field-name="contactEmail">-->\n        <!--<div class="col-xs-12">-->\n        <!--<input type="email" class="form-control floating-label js-info-input" data-field-name="contactEmail" value="' +
((__t = ( contactEmail )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'">-->\n        <!--</div>-->\n        <!--</div>-->\n        <!--<div class="form-group" data-field-name="address"> -->\n        <!--<div class="col-xs-12">-->\n        <!--<input type="text" class="form-control floating-label"-->\n        <!--placeholder="' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'" value="' +
((__t = ( address )) == null ? '' : __t) +
'"-->\n        <!--data-field-name="address"/>-->\n        <!--</div>-->\n        <!--</div>-->\n        <!--<div class="form-group" data-field-name="zip"> -->\n        <!--<div class="col-xs-4">-->\n        <!--<input type="text" class="form-control floating-label"-->\n        <!--placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'"-->\n        <!--data-field-name="zip"/>-->\n        <!--</div>-->\n        <!--<div class="col-xs-8">-->\n        <!--<input type="text" class="form-control floating-label"-->\n        <!--placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'"-->\n        <!--data-field-name="city"/>-->\n        <!--</div>-->\n        <!--</div> -->\n        <!--<div class="form-group js-countries">-->\n        <!--<div class="col-xs-12">-->\n        <!--' +
((__t = ( _.i18n('common.country') )) == null ? '' : __t) +
'-->\n        <!--<div class="js-select-countries-region"></div>-->\n        <!--</div>-->\n        <!--</div>-->\n        <div class="form-group group-activityarea" data-field-name="activityArea">\n          <div class="col-xs-12">\n            <select id="activityarea" class="form-control activityarea" data-field-name="activityArea">\n              <option value="">' +
((__t = ( _.i18n('account.activityarea.select') )) == null ? '' : __t) +
'</option>\n              <option value="graphic-designer" ' +
((__t = ( activityArea==='graphic-designer'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.graphic-designer') )) == null ? '' : __t) +
'</option>\n              <option value="photographer" ' +
((__t = ( activityArea==='photographer'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.photographer') )) == null ? '' : __t) +
'</option>\n              <option value="printing-house" ' +
((__t = ( activityArea==='printing-house'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.printing-house') )) == null ? '' : __t) +
'</option>\n              <option value="reseller-of-promotional-products" ' +
((__t = ( activityArea==='reseller-of-promotional-products'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.reseller-of-promotional-products') )) == null ? '' : __t) +
'</option>\n              <option value="manufacturer-of-promotional-products" ' +
((__t = ( activityArea==='manufacturer-of-promotional-products'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.manufacturer-of-promotional-products') )) == null ? '' : __t) +
'</option>\n              <option value="other" ' +
((__t = ( activityArea==='other'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.other') )) == null ? '' : __t) +
'</option>\n            </select>\n          </div>\n        </div>\n        <div class="form-group text-left" data-field-name="vatNumber">\n          <div class="radio pull-left radio-primary">\n            <label>\n              <input type="radio" class="subject-to-vat-radio" name="subject-to-vat" value="false">\n                ' +
((__t = ( _.i18n('caccount.credits.notSubjectToVat') )) == null ? '' : __t) +
'\n            </label>\n          </div>\n          <div class="radio pull-left radio-primary" >\n            <label>\n              <input type="radio"  class="subject-to-vat-radio"  name="subject-to-vat" value="true" checked="">\n                ' +
((__t = ( _.i18n('caccount.credits.subjectToVat') )) == null ? '' : __t) +
'\n            </label>\n            <div class="col-xs-12 pull-right subject-to-vat-radio " id="vat-area">\n              <input type="text" class="form-control floating-label uppercase-input m-t-15"\n                     placeholder="' +
((__t = ( _.i18n('common.vatNumberWithEntry') )) == null ? '' : __t) +
'" value="' +
((__t = ( vatNumber )) == null ? '' : __t) +
'"\n                     data-field-name="vatNumber"/>\n            </div>\n          </div>\n        </div>\n\n    </form>\n    <div class="row">\n      <div class="pull-right p-r-10 ">\n        <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n        <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n</div>\n<div class="welcome__container">\n  <p class="welcome__msg">' +
__e( _.i18n('welcome.firstRequest.msg') ) +
'</p>\n  <button id="welcome__start" class="btn btn-primary">' +
__e( _.i18n('welcome.firstRequest.start') ) +
'</button>\n</div>';

}
return __p
};});

