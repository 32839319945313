/**
 * Created by BFR on 24/02/2020.
 */
define('editPimPrintZonesView',['module',
        'marionette',
        'editPimPrintZoneView'],
  function(module,
           Marionette,
           EditPimPrintZoneView){
  'use strict';

  var EditPimPrintZonesView = Marionette.CollectionView.extend({
    childView: EditPimPrintZoneView,
    childViewOptions: function() {     
      return {
        pimProduct: this.options.pimProduct
      };
    }
  });

  module.exports = EditPimPrintZonesView;
});
