
define('template!vectorWarningTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <div class="row">\n      <div class="col-xs-12">\n        <div class="check-credit-container col-xs-12">\n          <p class="attention-icon"><i class="mdi-alert-error"></i></p>\n          <p class="general-attention-msg">\n            ' +
((__t = ( _.i18n("vectorization.popup.credits.insufficient") )) == null ? '' : __t) +
'\n          </p>\n          <p class="common-attention-msg">\n            ' +
((__t = ( _.i18n("vectorization.popup.credit.unavailable.info") )) == null ? '' : __t) +
'\n          </p>\n          <a class="col-xs-12 btn btn-primary fill-up-b">\n            ' +
((__t = ( _.i18n("common.fill.up") )) == null ? '' : __t) +
'\n          </a>\n          <a class="pull-right need-help-link"\n           href="mailto:' +
((__t = ( needHelpEmail )) == null ? '' : __t) +
'?subject=' +
((__t = ( subject )) == null ? '' : __t) +
'">\n            ' +
((__t = ( _.i18n("common.need.help") )) == null ? '' : __t) +
'\n          </a>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

