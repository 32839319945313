define('graphicalRequestAllInOneTariffScaleView',['module', 'marionette', 'tariffScaleData', 'template!graphicalRequestAllInOneTariffScaleViewTpl'],
function(module, Marionette, tariffScales, tariffScaleTpl) {
  'use strict';

  var TariffScaleView = Marionette.ItemView.extend({
    template: tariffScaleTpl,

    serializeData: function() {
      return {
        tariffScales: tariffScales,
        tariffScale: this.options.tariffScale,
        cid: this.options.cid
      };
    }
  });

  module.exports = TariffScaleView;
});
