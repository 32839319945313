
define('template!customersDetailBodyView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tabs-region"></div>\n<div class="body-container">\n  <div class="tabs-body">\n    <div class="profile region"></div>\n    <div class="orders region"></div>\n    <div class="offers region"></div>\n    <div class="attachments region"></div>\n    <div class="contacts region"></div>\n    <div class="calls region"></div>\n    <div class="mails region"></div>\n    <div class="customer-messages region"></div>\n    <div class="invoices region"></div>\n    <div class="tasks region"></div>\n  </div>\n  <div class="customer-internal-comments-region"></div>\n</div>';

}
return __p
};});

