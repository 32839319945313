/**
 * Created by BFR on 25/06/2020.
 */
define('pimProductImprintsView',['module',
        'marionette',
        'pimProductImprintView'],
  function(module,
           Marionette,
           PimProductImprintView){
  'use strict';

  var PimProductImprintsView = Marionette.CollectionView.extend({
    childView: PimProductImprintView,
    className: 'table-body',
    childViewOptions: function() {
      return {customPrice: this.options.customPrice};
    }
  });

  module.exports = PimProductImprintsView;
});
