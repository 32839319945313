define('ordersListInfosView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!ordersListInfosView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-infos-view table-field-view',

    modelEvents: {
      'change:companyName': 'render',
      'change:price': 'render',
      'change:quantity': 'render'
    },

    serializeData: function() {
      var data = {};

      data.client = '';
      if(this.model.get('companyName') && !this.tableView.options.endUserId)
        data.client = this.model.get('companyName');

      //---------

      data.order = '';
      data.price = '';

      if(this.model.get('purchaseOrderId'))
        data.order = this.model.get('purchaseOrderId');

      var numberLocale = 'fr-BE';
      var configNumberLocale = Settings.configValue('priceLocale');
      if (configNumberLocale)
        numberLocale = configNumberLocale;

      if(this.model.get('price'))
        data.price += '<b>€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2}).format(this.model.get('price')) + '</b>';

      if (this.model.get('buyingPrice')) {
        if (data.price !== '')
          data.price += '&nbsp;-&nbsp;';
        var margin = Math.round(((this.model.get('price') - this.model.get('buyingPrice')) /
            this.model.get('price')) * 100);
        if (margin < 20) {
          data.price += '<span style="color:red;">' + margin + '%' + '</span>';
        } else {
          data.price += margin + '%';
        }
      }

      if(this.model.get('quantity')) {
        if(data.price !== '')
          data.price += '&nbsp;-&nbsp;';
        data.price += new Intl.NumberFormat(numberLocale).format(this.model.get('quantity'));
        data.price += '&nbsp;' + _.i18n('common.pcs');
      }

      return data;
    }
  });
});
