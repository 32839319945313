
define('template!messagesViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="caccount-details-container">\n  <div class="tab-content content">\n    <div class="tab-pane active" data-role="" data-name="messages" data-route="messages" id="messages-tab"></div>\n    <div class="tab-pane active" data-role="" data-name="messages-cs" data-route="messages-cs" id="messages-cs-tab"></div>\n  </div>\n</div>';

}
return __p
};});

