/**
 * Created by RKL on 13/08/2015.
 */
define('caccountOfferStatusTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'stateMessages',
  'template!caccountOfferStatusTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  StateMessages,
  viewTpl
) {
  'use strict';

  var CAccountsOfferStatusTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountOfferStatusFilters',

    triggers: {
      'click @ui.createFont': 'onCreateOfferStatus'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-fonts',
        jqGridPager: '#jq-grid-pager-fonts',
        createFont: '.js-add-offerstatus',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click .cell-delete': 'onDelete'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var defaultsOptions, options;

      this.filtersName = data.type + 'FontsFilters';
      this.sidx = 'userTimestamp.modDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.code'),
            name: 'code',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: false,
            index: 'code',
            sortable: false
          }, {
            label: _.i18n('common.name'),
            name: 'name',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: false,
            index: 'name',
            sortable: false
          }, {
            label: _.i18n('orderstatus.displayInKanban'),
            name: 'displayInKanban',
            align: 'left',
            classes: 'cell-link',
            formatter: this.checkBoxFormatter,
            search: false,
            index: 'displayInKanban',
            sortable: false
          }, {
            label: _.i18n('orderstatus.refParentStatus'),
            name: 'refParentStatus.name',
            align: 'left',
            classes: 'cell-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'refParentStatus.name',
            sortable: false
          }, {
            label: _.i18n('common.description'),
            name: 'description',
            align: 'left',
            classes: 'cell-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'displayOnKanban',
            sortable: false
          }, {
            label: _.i18n('common.params'),
            name: 'params',
            align: 'left',
            classes: 'cell-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'params',
            sortable: false
          }, {
            label: '',
            name: '',
            classes: 'delete-link js-delete-font',
            formatter: this.deleteIconFormatter,
            search: false,
            width: 50
          }
        ],

        onSelectRow: function(rowId, bool, e) {
            if (e && !$(e.target).hasClass('cell-delete') && e.type === 'click') {
                App.navigate('/orderstatus/' + rowId, {trigger: true});
            }
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    checkBoxFormatter: function(cellvalue, options) {
        var checked = cellvalue ? 'checked' : '',
            htmlEl = '<div class="m-l-15">' +
                '<input disabled="disabled" type="checkbox" data-ticket-cid="' + options.rowId +
                '" class="cpz-checkbox js-ticket-urgent" ' + checked + ' ' +
                '></div>';
        return htmlEl;
    },

    onDelete: function(e) {
      var target = $(e.currentTarget),
        fontId = target.data('row-id');

      this.trigger('onDelete', fontId);
    }
  });

  module.exports = CAccountsOfferStatusTabView;
});

