
define('template!ordersDetailSupplierEditInvoiceView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n  <div class="title">' +
((__t = ( _.i18n('orders.detail.supplier.invoice') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n  <div class="column">\n    <div class="delivery item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.deliveryAddress') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( deliveryAddress )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="email item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">' +
((__t = ( email )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="packing-list item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.supplier.invoice.packingList') )) == null ? '' : __t) +
'</div>\n      </div>\n\n      ';
 if(showPackingList) { ;
__p += '\n        <a href="javascript:;" class="value">' +
((__t = ( packingList )) == null ? '' : __t) +
'</a>\n        <div class="preview">\n          <canvas class="canvas"></canvas>\n        </div>\n      ';
 } else { ;
__p += '\n        <div class="value">' +
((__t = ( packingList )) == null ? '' : __t) +
'</div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

