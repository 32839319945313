
define('template!phoneCallsCustomerNumberView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="number" title="' +
((__t = ( number )) == null ? '' : __t) +
'">' +
((__t = ( number )) == null ? '' : __t) +
'</div>';

}
return __p
};});

