/**
 * Created by BFR on 10/06/2020.
 */
define('productCustomFieldsTabView',[
  'app',
  'settings',
  'module',
  'marionette',

  'underscore',
  'customFieldsView',
  'template!productCustomFieldsTabTpl'
], function(
  App,
  Settings,
    module,
  Marionette,

  _,
  CustomFieldsView,
  viewTpl
) {
  'use strict';

  var ProductCustomFieldsView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      nameRegion: '.name-region'
    },

    ui: {

    },

    events: {

    },

    onShow: function () {
      this.nameDtView = new CustomFieldsView({ collection: this.model.get('customFieldValues')});
      this.getRegion('nameRegion').show(this.nameDtView);
    },

    serializeData: function() {
      var templateData = {};
      templateData.display =
        ( this.model.get('customFieldValues') && this.model.get('customFieldValues').length > 0)?true:false;

      return templateData;
    }
  });

  module.exports = ProductCustomFieldsView;
});

