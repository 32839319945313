/**
 * Created by BFR on 24/02/2020.
 */
define('editCatView',[
  'module',
  'marionette',
  'app',
  'settings',
  'underscore',
  'template!editCatTpl',
  'dynamicTextView',
  'editOptionsView',
  'bootbox',
  'popupOptionCategoryView',
  'popupOptionView'
], function (
  module,
  Marionette,
  App,
  Settings,
  _,
  viewTpl,
  DynamicTextView,
  EditOptionsView,
  bootbox,
  PopupOptionCategoryView,
  PopupOptionView
) {
  'use strict';

  var EditCatView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'cat-item',

    modelEvents: {
      sync: 'render'
    },

    events: {
      'click .btn-edit-poc': 'onEditPocClick',
      'click .btn-delete-poc': 'onDeletePocClick',
      'click .btn-add-option': 'onAddOptionClick',
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut'
    },

    ui: {
      btnContainer: '.btn-pa-container'
    },

    regions: {
      nameRegion: '.name-region',
      editOptions: '.options-region'
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    onShow: function () {
      this.editOptionsView = new EditOptionsView
      (
        {
          collection: this.model.get('productOptions'),
          editable: true
        });
      this.getRegion('editOptions').show(this.editOptionsView);
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.name = this.options.model.get('name').getTextInLocale(Settings.get('lang'));
      return templateData;
    },

    onDeletePocClick: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.destroy();
        }
      }, this));
    },

    onEditPocClick: function () {
      var popupPrintZoneView = new PopupOptionCategoryView({
        model: this.model,
        collection: this.collection
      });
      popupPrintZoneView.newItem = false;

      popupPrintZoneView.show({
        title: _.i18n('common.edit')
      });
    },

    onAddOptionClick: function () {
      var popupOptionView = new PopupOptionView({
        model: App.request('products-rel:option:model', {
          refProductOptionCategory: this.model
        })
      });
      popupOptionView.newItem = true;

      popupOptionView.show({
        title: _.i18n('common.add')
      });
    }

  });

  module.exports = EditCatView;
});
