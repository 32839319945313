define('productViewSwitcherView',[
  'module',
  'marionette',
  'app',
  'template!productViewSwitcherTpl',
  'settings'
], function(
  module,
  Marionette,
  App,
  viewSwitcherTpl,
  Settings
) {
  'use strict';

  var localStorageKeyProductView = 'productView';

  var ViewSwitcherView = Marionette.ItemView.extend({
    template: viewSwitcherTpl,
    events: {
      'click .js-grid': '_showGrid',
      'click .js-table': '_showTable'
    },
    className: 'view-switcher',

    serializeData: function() {
      return {
        currentView: this.options.currentView
      };
    },

    _showGrid: function() {
      if(Settings.getFromMemory(localStorageKeyProductView) === 'grid') {
        return;
      }
      Settings.setToMemory(localStorageKeyProductView, 'grid');
      App.trigger('products:show');
    },

    _showTable: function() {
      if(!Settings.getFromMemory(localStorageKeyProductView) ||
        Settings.getFromMemory(localStorageKeyProductView) === 'table') {
        return;
      }
      Settings.setToMemory(localStorageKeyProductView, 'table');
      App.trigger('products:show');
    }
  });

  module.exports = ViewSwitcherView;
});
