
define('template!webshopProductsListSupplierView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(supplierName != '') { ;
__p += '\n<div class="supplier-name">' +
((__t = ( supplierName )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';
 if(supplierCode != '') { ;
__p += '\n    <div class="supplier-code">' +
((__t = ( supplierCode )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
};});

