define('translationsFilterLanguageButtonView',[
  'module',
  'marionette',
  'template!translationsFilterLanguageButtonTpl'
], function(
  module,
  Marionette,
  filterButtonTpl
) {
  'use strict';

  var FilterLanguageButtonView = Marionette.ItemView.extend({
    template: filterButtonTpl,

    collectionEvents: {
      change: 'render'
    }
  });

  module.exports = FilterLanguageButtonView;
});
