
define('template!userSelectorView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n  <div class="selected-users-region"></div>\n  <div class="selector">\n    <input type="text" placeholder="' +
((__t = ( _.i18n('common.search') )) == null ? '' : __t) +
'">\n    <div class="assign-to-me-region"></div>\n  </div>  \n  <div class="users-region"></div>\n</div>';

}
return __p
};});

