define('entities/caccounts/config',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'backboneRelational'
], function (
  App,
  $,
  Settings,
  Backbone
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  app.ns.CAccountConfig = Backbone.RelationalModel.extend({
    defaults: {},
    relations: [{
      type: Backbone.HasMany,
      key: 'cardColors',
      relatedModel: 'CAccountConfigCardsColor',
      collectionType: 'CAccountConfigCardsColors'
    }],
    idAttribute: 'secId',

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/caccounts/config');
      return Backbone.RelationalModel.prototype.fetch.call(this);
    }
  });

  app.ns.CAccountConfigCardsColor = Backbone.RelationalModel.extend({
    defaults: {
      name: ''
    },
    relations: [],
    idAttribute: 'color'
  });

  app.ns.CAccountConfigCardsColors = Backbone.Collection.extend({
    model: app.ns.CAccountConfigCardsColor
  });

  App.reqres.setHandler('caccount-config:model', function (item) {
    var tt = Backbone.Relational.store.find(app.ns.CAccountConfig, item.secId);
    if (tt) {
      return tt;
    } else {
      return new app.ns.CAccountConfig(item);
    }
  });
});

