/**
 * Created by RKL on 21/08/2015.
 */
define('mailingMain',[
  'module',
  'logger',
  'mailingMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Mailing = {
    start: function() {
      Logger.log(module, 'starting MailingModule');
    },

    stop: function() {
      Logger.log(module, 'stopping MailingModule');
    }
  };

  module.exports = Mailing;
});
