
define('template!webshopCategoriesDetailBodyTextsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="languages-container"></div>';

}
return __p
};});

