/**
 * Created by RKL on 07/09/2015.
 */
define('customersListView',[
  'module',
  'marionette',
  'underscore',
  'windows',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!customersListView',
  'customersPopupEditView',
  'entities/customers/customers'
], function (
  module,
  Marionette,
  _,
  Windows,
  Backbone,
  availableActions,
  ActionButtonsView,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  EditView
) {
  'use strict';

  var CustomersListView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'customersListFilters',

    regions: {
      actions: '.js-action-buttons-region'
    },

    onShow: function() {
      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);
    },

    _getActionButtonsView: function() {
      var actions = ['import', 'importContacts'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function(action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function(action) {
      switch ( action ) {
        case 'import':
          this.$('#endUser-excel-input' ).trigger('click');
          break;
        case 'importContacts':
          this.$('#endUserContact-excel-input' ).trigger('click');
          break;
        case 'export':
          window.open(Settings.url('compuzz2', 'endusers.json/excel'), '_self');
          break;
        case 'exportContacts':
          window.open(Settings.url('compuzz', 'endusers.json/contacts/excel'), '_self');
          break;
      }
    },

    onDeleteEndUser: function(event){
        event.preventDefault();
        event.stopImmediatePropagation();
       this.trigger('customers:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        importBtn: '.endUser-import',
        uploadEndUsers: '.js-upload-endUsers',
        uploadEndUsersContacts: '.js-upload-endUsers-contacts',
        exportBtn: '.endUser-export'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click .js-add-new': 'onAddNew',
        'change @ui.uploadEndUsers': 'onUploadEndUsers',
        'change @ui.uploadEndUsersContacts': 'onUploadEndUsersContacts',
         'click .delete-user-link': 'onDeleteEndUser'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.customerNumber'),
              name: 'customerNumber',
              formatter: this.customerNumberFormatter,
              classes: 'cell-link',
              search: true,
              index: 'number',
              width: 55            
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'name',
              width: 55
            }
          ],

          onSelectRow: function(rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              App.trigger('customers:show', rowid);
            }
          }
        };

      if (Settings.configValue('customer.list.displayNameInsteadOfCode')) {
        options.colModel.push({
          label: _.i18n('common.firstName'),
          name: 'firstName',
          formatter: this.defaultFormatter,
          classes: 'agents-link',
          search: true,
          index: 'firstName',
          width: 55
        });

        options.colModel.push({
          label: _.i18n('common.lastName'),
          name: 'lastName',
          formatter: this.defaultFormatter,
          classes: 'agents-link',
          search: true,
          index: 'lastName',
          width: 55
        });
      } else {
        options.colModel.push({
          label: _.i18n('common.code'),
          name: 'code',
          formatter: this.defaultFormatter,
          classes: 'agents-link',
          search: true,
          index: 'code',
          width: 55
        });
      }


      options.colModel.push({
        label: _.i18n('common.date'),
        name: 'creatDate',
        formatter: this.dateFormatter,
        classes: 'cell-link',
        search: true,
        index: 'creatDate',
        width: 55
      });

      options.colModel.push({
        label: _.i18n('common.addressL1'),
        name: 'addressL1',
        formatter: this.defaultFormatter,
        index: 'addressL1',
        search: true,
        width: 65
      });

      options.colModel.push({
        label: _.i18n('common.zip'),
        name: 'zip',
        formatter: this.defaultFormatter,
        classes: 'cell-link',
        search: true,
        index: 'zip',
        width: 35
      });

      options.colModel.push({
        label: _.i18n('common.city'),
        name: 'city',
        formatter: this.defaultFormatter,
        classes: 'agents-link',
        search: true,
        index: 'city',
        width: 50
      });

      options.colModel.push({
        label: _.i18n('common.countryCode'),
        name: 'countryCode',
        formatter: this.defaultFormatter,
        classes: 'agents-link',
        search: true,
        index: 'countryCode',
        width: 45
      });

      options.colModel.push({
        label: _.i18n('common.email'),
        name: 'email',
        formatter: this.defaultFormatter,
        classes: 'agents-link',
        search: true,
        index: 'email',
        width: 55
      });

      options.colModel.push({
        label: _.i18n('common.companyNumber'),
        name: 'companyNumber',
        formatter: this.defaultFormatter,
        classes: 'agents-link',
        search: true,
        index: 'companyNumber',
        width: 50
      });

      options.colModel.push({
        label: _.i18n('common.vip'),
        name: 'vip',
        formatter: this.vipFormatter,
        classes: 'vip',
        search: true,
        index: 'vip',
        width: 30,
        stype: 'select',
        searchrules: {select: true},
        searchoptions: {
          value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
        }
      });

      options.colModel.push({
        label: ' ',
        name: 'delete',
        search: false,
        classes: 'delete-user-link delete-link',
        formatter: this.deleteIconFormatter,
        width: 20
      });

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    customerNumberFormatter: function (cellvalue, options, model) {

      var customerModel = App.request('customer:model', model);
      var html = '';
      html = '<span class="cell-date">';
      html += customerModel.getCustomerNumber();
      html += '</span>';

      return html;
    },

    vipFormatter: function (cellvalue, options, model) {

      var customerModel = App.request('customer:model', model);

      if(customerModel.get('vip') === true)
        return _.i18n('common.yes');
      else
        return _.i18n('common.no');
    },

    onAddNew: function(e) {
      e.preventDefault();
      e.stopPropagation();
     
      Windows.open({
        view: _.bind(function () {
          return new EditView({
            customerModel: App.request('customer:model'),
            newItem: true,
            countries: this.options.countries
          });
        }, this),
        title: _.i18n('customers.new-customer'),
        configName: 'customerEdit'
      });
    }, 

    onUploadEndUsers: function(e) {
          var that = this,
              target = e.target,
              fileInfo;

          if (e && e.target && e.target.files && e.target.files[0]) {
              fileInfo = imageUtils.getFileInfo(target, 0);

              if (fileInfo) {
                  imageUtils.encodeInputFileToBase64(target, 0, _.bind(function(imgBytes) {
                      fileInfo.base64Value = imgBytes;
                      that.uploadExcel(fileInfo);
                  }, this));
              }
          }
    },

    onUploadEndUsersContacts: function(e) {
      var that = this,
          target = e.target,
          fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
          fileInfo = imageUtils.getFileInfo(target, 0);

          if (fileInfo) {
              imageUtils.encodeInputFileToBase64(target, 0, _.bind(function(imgBytes) {
                  fileInfo.base64Value = imgBytes;
                  that.uploadExcelContacts(fileInfo);
              }, this));
          }
      }
    },

    uploadExcel: function(fileInfo) {
       this.ui.uploadEndUsers.val('');
       this.trigger('customers:import-excel', fileInfo);
    },

    uploadExcelContacts: function(fileInfo) {
      this.ui.uploadEndUsersContacts.val('');
      this.trigger('customers:import-excel-contacts', fileInfo);
    }
  });

  module.exports = CustomersListView;
});
