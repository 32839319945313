define('fileExtensionData',[
  'module'
], function(module) {
  'use strict';

  module.exports = {
    extensions: [{
      extension: '.ai (Adobe Illustrator CS2)',
      isVectorFormat: true
    },{
      extension: '.ai (Adobe Illustrator CS4)',
      isVectorFormat: true
    },{
      extension: '.ai (Adobe Illustrator CS5)',
      isVectorFormat: true
    },{
      extension: '.ai (Adobe Illustrator CS6)',
      isVectorFormat: true
    },{
      extension: '.ai (Adobe Illustrator CC)',
      isVectorFormat: true
    },{
      extension: '.pdf (PDF 1.4)',
      isVectorFormat: true
    },{
      extension: '.pdf (PDF 1.5)',
      isVectorFormat: true
    },{
      extension: '.pdf (PDF 1.6)',
      isVectorFormat: true
    },{
      extension: '.pdf (PDF 1.7)',
      isVectorFormat: true
    },{
      extension: '.psd (Photoshop Document)',
      isVectorFormat: false
    },{
      extension: '.cdr (Corel Draw X3)',
      isVectorFormat: true
    },{
      extension: '.cdr (Corel Draw X4)',
      isVectorFormat: true
    },{
      extension: '.cdr (Corel Draw X5)',
      isVectorFormat: true
    },{
      extension: '.cdr (Corel Draw X6)',
      isVectorFormat: true
    },{
      extension: '.cdr (Corel Draw X7)',
      isVectorFormat: true
    },{
      extension: '.indd (Adobe Indesign CS5)',
      isVectorFormat: true
    },{
      extension: '.indd (Adobe Indesign CS6)',
      isVectorFormat: true
    },{
      extension: '.indd (Adobe Indesign CC)',
      isVectorFormat: true
    },{
      extension: '.eps (Encapsulated Post Script Illustrator CS4)',
      isVectorFormat: true
    },{
      extension: '.eps (Encapsulated Post Script Illustrator CS5)',
      isVectorFormat: true
    },{
      extension: '.eps (Encapsulated Post Script Illustrator CS6)',
      isVectorFormat: true
    },{
      extension: '.eps (Encapsulated Post Script Illustrator CC)',
      isVectorFormat: true
    },{
      extension: '.svg (Scalable Vector Graphics 1.1)',
      isVectorFormat: true
    },{
      extension: '.svg (Scalable Vector Graphics 1.2)',
      isVectorFormat: true
    },{
      extension: '.bmp',
      isVectorFormat: false
    },{
      extension: '.gif',
      isVectorFormat: false
    },{
      extension: '.jpg',
      isVectorFormat: false
    },{
      extension: '.png',
      isVectorFormat: false
    },{
      extension: '.tiff',
      isVectorFormat: false
    }]
  };
});
