
define('template!customQuantitiesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="custom-quantities">\n\n</div>\n<i class="fa fa-plus btn-add clickable"></i>';

}
return __p
};});

