define('headerNotificationsView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'headerNotificationsPopupView',
  'template!headerNotificationsView'
], function(
  App,
  Module,
  Marionette,
  _,
  NotificationsPopupView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-notifications-view',  

    ui: {
      content: '> .content',
      count: '.count',
      list: '.list',
      icon: '.icon',
      loader: '.loader-2'
    },

    events: {
      'click @ui.content': 'onOpen'
    },

    regions: {
      popupRegion: '.popup-region'
    },

    initialize: function () {
      this.count = 0;
    },

    onShow: function () {
      this.popupView = new NotificationsPopupView({
        fetchCount: _.bind(function() {
          this.fetchCount();
        }, this)
      });
      this.getRegion('popupRegion').show(this.popupView);

      this.fetchCount();
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {    
      if (this.count > 0) {
        if (this.ui.content) {
          this.ui.content.addClass('activated');
        }
        this.ui.count.css('display', 'flex');
      }        
      else {
        if (this.ui.content) {
          this.ui.content.removeClass('activated');
        }
        this.ui.count.hide();
      }        

      if (this.countUnread > 0) {
        this.ui.count.html(this.countUnread);
        this.ui.count.show();
      } else {
        this.ui.count.hide();
      }
    },

    fetchCount: function () {     
      App.request('notifications:count', false).done(_.bind(function (count) {
        this.countUnread = count;
        App.request('notifications:count').done(_.bind(function (count) {
          this.count = count;
          this.renderContent();
        }, this));
      }, this));

      setTimeout(_.bind(this.fetchCount, this), 15000);
    },

    onOpen: function () {   
      this.fetchCount();

      this.ui.icon.hide();
      this.ui.loader.show();

      this.popupView.open().done(_.bind(function () {
        this.ui.icon.show();
        this.ui.loader.hide();
      }, this));
    }
  });
});
