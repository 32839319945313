define('entities/composition_vectorization',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function (
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var CompositionSimilar = Backbone.Model.extend({
    defaults: {}
  });

  var CompositionSimilarCollection = Backbone.Collection.extend({
    model: CompositionSimilar,

    url: function () {
      return Settings.url('compuzz2', 'personalization.json/compositions/' + this.compositionSecId + '/similars');
    }
  });

  var CompositionGoogleImage = Backbone.Model.extend({
    defaults: {}
  });

  var CompositionGoogleImageCollection = Backbone.Collection.extend({
    model: CompositionGoogleImage,

    url: function () {
      return Settings.url('compuzz2', 'personalization.json/compositions/' + this.compositionSecId + '/googleimages');
    }
  });

  var CompositionVectorization = Backbone.Model.extend({
    defaults: {}
  });

  var CompositionVectorizationCollection = Backbone.Collection.extend({
    model: CompositionVectorization,

    url: function () {
      return Settings.url('compuzz2', 'personalization.json/compositions/' + this.compositionSecId + '/vectorizations');
    },

    fetch: function () {
      var prom = Backbone.Collection.prototype.fetch.call(this);
      prom.done(_.bind(function () {
        var allDone = true;
        _.each(this.models, function (model) {
          if (model.get('refProcess').status !== 'common.done') {
            allDone = false;
          }
        });
        if (!allDone) {
          setTimeout(_.bind(this.fetch, this), 5000);
        }
      }, this));
      return prom;
    }
  });

  App.reqres.setHandler('composition-vectorization:get-collection', function (compositionSecId) {
    var compositionVectorizationCollection = new CompositionVectorizationCollection();
    compositionVectorizationCollection.compositionSecId = compositionSecId;
    return compositionVectorizationCollection;
  });

  App.reqres.setHandler('composition-similar:get-collection', function (compositionSecId) {
    var compositionSimilarCollection = new CompositionSimilarCollection();
    compositionSimilarCollection.compositionSecId = compositionSecId;
    return compositionSimilarCollection;
  });

  App.reqres.setHandler('composition-google-image:get-collection', function (compositionSecId) {
    var compositionGoogleImageCollection = new CompositionGoogleImageCollection();
    compositionGoogleImageCollection.compositionSecId = compositionSecId;
    return compositionGoogleImageCollection;
  });
});
