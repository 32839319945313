
define('template!productPicturesTabLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="container-fluid well my-website-form-container" style="max-width: unset;">\n  <div>\n    <h2 class="my-website-title">' +
((__t = ( _.i18n('pictures.catalogPicture') )) == null ? '' : __t) +
'</h2>\n    <!-- Upload block -->\n    <div class="form-group">\n      <div class="col-lg-12">\n        <div class="logo-m-ws">\n          <div class="my-ws-img-container transparent-bg">\n            <div class="catalog-upload-img" style="';
 if (cataloguePictureUrl) { ;
__p += 'background-image: url(' +
((__t = ( cataloguePictureUrl )) == null ? '' : __t) +
'); ';
 } ;
__p += '"></div>\n          </div>\n          ';
 if (cataloguePictureUrl) { ;
__p += '\n            <label class="download-title p-l-0 p-r-0"><a href="' +
((__t = ( cataloguePictureUrl + '&cmds=on' )) == null ? '' : __t) +
'"> <i class="mdi-file-file-download"></i></a></label>\n          ';
 } ;
__p += '\n          <label for="uploadCatalogInputFile" class="upload-title p-l-0 p-r-0"><i class="mdi-file-file-upload"></i></label>\n          <input id="uploadCatalogInputFile" data-key="file" data-property="catalog" multiple type="file" name="filename" class="hidden js-change-logo f-my-ws" accept="image/*"/>\n        </div>\n      </div>\n    </div>\n    <h2 class="my-website-title">' +
((__t = ( _.i18n('pictures.templateImage') )) == null ? '' : __t) +
'</h2>\n    <!-- Upload block -->\n    <div class="form-group">\n      <div class="col-lg-12">\n        <div class="logo-m-ws">\n          <div class="my-ws-img-container transparent-bg">\n            <div class="template-upload-img" style="';
 if (templateFileUrl) { ;
__p += 'background-image: url(' +
((__t = ( templateFileUrl )) == null ? '' : __t) +
'); ';
 } ;
__p += '"></div>\n          </div>\n          ';
 if (templateFileUid) { ;
__p += '\n            <label class="download-title p-l-0 p-r-0"><a href="' +
((__t = ( templateFileUrl + '&cmds=on' )) == null ? '' : __t) +
'"> <i class="mdi-file-file-download"></i></a></label>\n          ';
 } ;
__p += '\n          <label for="uploadInputFile" class="upload-title p-l-0 p-r-0"><i class="mdi-file-file-upload"></i></label>\n          <input id="uploadInputFile" data-key="file" data-property="template" multiple type="file" name="filename" class="hidden js-change-logo f-my-ws" accept="image/*"/>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="js-new-picture-region f-l"></div>\n  <div class="js-pictures-region"></div>\n  <div class="js-loader-region"></div>\n</div>\n';

}
return __p
};});

