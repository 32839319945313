define('entities/webshop/webshop',[
  'app',
  'backbone',
  'settings',
  'underscore',
  'backboneRelational'
], function (
  App,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------
  app.ns.WebshopConfig = Backbone.RelationalModel.extend({
    fetch: function () {
      this.url = Settings.url('compuzz2', 'webshop/webshop/config');
      return Backbone.Model.prototype.fetch.call(this);
    },

    getLanguages: function () {
      var languages = [];
      _.each(this.attributes, function (site) {
        if (!languages.includes(site.language))
          languages.push(site.language);
      });
      return languages;
    }
  });

  var API = {
    getConfig: function () {
      var defer = $.Deferred();
      if (this.config)
        defer.resolve(this.config);
      else {
        this.config = App.request('webshop.config.model');
        this.config.fetch().done(_.bind(function () {
          defer.resolve(this.config);
        }, this));
      }
      return defer.promise();
    }
  };

  App.reqres.setHandler('webshop.config.model', function (data) {
    if (!data)
      data = {};
    return app.ns.WebshopConfig.findOrCreate(data);
  });

  App.reqres.setHandler('webshop.config', function () {
    return API.getConfig();
  });
});

