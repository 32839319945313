
define('template!superAdminOverviewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '\t\t<div class="tab-content content">\t\t\t\n\t\t\t<div class="tab-pane active" data-tab="overview" id="overview-tab">\n\t\t\t\t<div class="row">\n\t\t\t\t\t<div class="col-lg-3 col-md-6">\n\t\t\t\t\t\t<div class="account-well" id="accounts-stats-region"></div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-lg-3 col-md-6">\n\t\t\t\t\t\t<div class="credit-well" id="credits-stats-region"></div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-lg-6 col-md-12">\n\t\t\t\t\t\t<div class="col-lg-12 col-md-12 well">\n\t\t\t\t\t\t\t<h2>' +
((__t = ( _.i18n("superAdmin.creditsBoughtSpent") )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t<i class="mdi-content-create pull-right credits-graph"></i>\n\t\t\t\t\t\t\t</h2>\n\t\t\t\t\t\t\t<div id="credits-graph-region"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="row">\n\t\t\t\t\t<div class="col-lg-6 col-md-12">\n\t\t\t\t\t\t<div class="col-lg-12 col-md-12 well">\n\t\t\t\t\t\t\t<h2>' +
((__t = ( _.i18n("superAdmin.accountsCreated") )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t<i class="mdi-content-create pull-right account-graph"></i>\n\t\t\t\t\t\t\t</h2>\n\t\t\t\t\t\t\t<div id="account-graph-region"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-lg-6 col-md-12">\n\t\t\t\t\t\t<div class="col-lg-12 col-md-12 well">\n\t\t\t\t\t\t\t<h2>' +
((__t = ( _.i18n("superAdmin.ticketsByState") )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t<i class="mdi-content-create pull-right tickets-graph"></i>\n\t\t\t\t\t\t\t</h2>\n\t\t\t\t\t\t\t<div id="tickets-graph-region"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>';

}
return __p
};});

