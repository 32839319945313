/**
 * Created by BFR on 05/03/2020.
 */
define('productFilterTabView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'settings',
  'template!productFilterTabTpl',
  'colorUtils',
  'filterTypesView',
  'filtersView',
  'backbone',
  'backboneRelational',
  'entities/product_filter'
], function(
  module, 
  App,
  Marionette,
  _,
  Settings,
  viewTpl,
  ColorUtils,
  FilterTypesView,
  FiltersView,
  Backbone
) {
  'use strict';

  var ProductFiltersView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      productFilterTypes: '.product-filter-types',
      productFilters: '.product-filters'
    },

    ui: {
      save: '.js-save-product-type',
      blockLoading: '.js-loading-block',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    events: {
      'click @ui.save': 'onSaveClick',
      'change .cbx-filter-type': 'onChangeFilterType',
      'click @ui.cancel': 'onCancelClick',
      'click @ui.confirm': 'onConfirmClick'
    },

    onCancelClick: function() {
      this.currentModel.set(this.backup);
      this.render();
      this.onShow();
    },

    onDestroy: function() {
      Backbone.Relational.store.unregister(this.options.collection);
    },

    onConfirmClick: function() {
      this.ui.blockLoading.show();
      this.currentModel.save().done(_.bind(function() {
        this.ui.blockLoading.hide();
        this.ui.cancel.prop('disabled', true);
        this.ui.confirm.prop('disabled', true);
      }, this));
    },

    onChangeFilterType: function(event) {
      var cbx = $(event.target);
      this.ui.save.prop('disabled', false);
      this.ui.cancel.prop('disabled', true);
      this.ui.confirm.prop('disabled', true);

      this.currentModel = App.request('products-filter:model', { secId: cbx.val(), productSecId: this.options.product.get('secId') });
      this.currentModel.set('filterType', { secId: cbx.val() });
    },

    onSaveClick: function() {
      this.ui.blockLoading.show();
      this.currentModel.save().done(_.bind(function() {
        this.ui.save.prop('disabled', true);
        this.options.collection.fetch(this.options.product.get('secId')).done(_.bind(function() {
          this.ui.blockLoading.hide();
        }, this));
      }, this));
    },

    onRender: function() {
      _.each(this.options.collection.models, function(m, index) {
        if (index === 0) {
            m.set('selected', true);
        } else {
            m.set('selected', false);
        }
      });
      this.filterTypesView = new FilterTypesView({ collection: this.options.collection });
      this.filterTypesView.listenTo(this.filterTypesView, 'item:select', _.bind(this.onProductFilterTypeSelected, this));
      this.filterTypesView.listenTo(this.filterTypesView, 'item:delete', _.bind(this.onProductFilterTypeDelete, this));
      this.getRegion('productFilterTypes').show(this.filterTypesView);

      if (this.options.collection.models.length > 0) {
        this.onProductFilterTypeSelected(this.options.collection.models[0]);
      }
    },

    onProductFilterTypeDelete: function(item) {
      item.destroy().done(_.bind(function() {
        this.currentModel = null;
        this.render();
      }, this));
    },

    onProductFilterTypeSelected: function(item) {
      this.currentModel = item;
      this.backup = this.currentModel.toJSON();
      this.filtersView = new FiltersView({ collection: this.currentModel.get('filters')});
      this.filtersView.listenTo(this.filtersView, 'item:selection:change', _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('productFilters').show(this.filtersView);
    },

    onEnableCancelConfirm: function() {
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    },

    serializeData: function () {
      var templateData = {};
      templateData.productFilterTypes = this.options.productFilterTypes;
      templateData.product = this.options.product.toJSON();
      if (templateData.product.cataloguePictureUid) {
        templateData.product.imgUrl = Settings.url('file', templateData.product.cataloguePictureUid, { size: 'thumbnail' });
      } else {
        templateData.product.imgUrl = '';
      }
      _.each(templateData.product.productOptionCategories, function(poc) {
        _.each(poc.productOptions, function(po) {
          if (po.color1 > -1) {
            po.color1 = ColorUtils.decimalToHexa(po.color1);
          }
        });
      });
      return templateData;
    }
  });

  module.exports = ProductFiltersView;
});
