
define('template!adminNotificationsCreatorView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="user-region"></div>\n\n';
 if(showName == true) { ;
__p += '\n    <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';

}
return __p
};});

