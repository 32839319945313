define('ordersDetailOrderStatusTypeView',[
  'module',
  'marionette',
  'template!ordersDetailOrderStatusTypeView'
], function (
  Module,
  Marionette,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    tagName: 'option',
    className: 'orders-detail-order-status-type-view',
    template: Tpl,
    attributes: function() {
      return {
        value: this.model.get('code')
      };     
    },

    serializeData: function() {
      return {
        name:this.model.get('name')
      };
    },

    setSelected: function(value) {
      this.$el.attr('selected', value);
    }
  });
});
