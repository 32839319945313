
define('template!graphicalRequestKanbanAssignAutocompleteTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="available-users js-available-users">\n  <a>\n    ';
 if(fileUrl) { ;
__p += '\n      <div class="col-xs-3 available-user-logo" style="background-image:url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n    ';
 } else { ;
__p += '\n      <i class="mdi-action-face-unlock"></i>\n    ';
 } ;
__p += '\n    <div class="col-xs-9 user-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n  </a>\n</li>';

}
return __p
};});

