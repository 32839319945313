define('ordersLabelsView',[
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore',
  'ordersLabelView',
  'ordersLabelsEditView',
  'template!ordersLabelsView'
], function (
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  LabelView,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'orders-labels-view',
    childView: LabelView,
    childViewContainer: '.labels-container',

    ui: {
      addButton: '.add-button',
      labelsContainer: '.labels-container'
    },

    events: {
      'click @ui.labelsContainer': 'onEdit',
      'click @ui.addButton': 'onEdit'
    },

    modelEvents: {
      'change:cardColor': 'fetch'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    /*collectionEvents: {
      change: 'setAddButton'
    },*/

    onShow: function () {
      this.fetch();
    },

    onRender: function () {
      if(this.isDestroyed)
        return;

      if(this.options.readOnly === true)
        this.$el.addClass('read-only');
      else
        this.$el.removeClass('read-only');

      this.renderAddButton();
    },

    renderAddButton: function() {
      var cpt = 0;

      if(this.model.get('cardColor')) {
        var selectedIds =  this.model.get('cardColor').split(',');

        _.each(selectedIds, function(color) {
          if(color !== '')
            cpt++;
        }, this);
      }    
      
      if(cpt === 0 && this.options.readOnly !== true) {
        this.ui.addButton.css('display', 'flex');
        this.ui.labelsContainer.hide();
      } else {
        this.ui.addButton.hide();
        this.ui.labelsContainer.css('display', 'flex');
      }
    },

    fetch: function () {  
      this.collection.reset();

      if (this.model.get('cardColor')) {
        var selectedIds =  this.model.get('cardColor').split(',');

        var cAccountConfig = Settings.get('cAccountConfig');     
     
        if (cAccountConfig && cAccountConfig.get('cardColors')) {
          _.each(cAccountConfig.get('cardColors').models, function(color) {
            _.each(selectedIds, function (rgb) {
              if(rgb.toUpperCase() === color.get('color').replace('#', '').toUpperCase())
                this.collection.add(color);
            }, this);
          }, this);
        }
      }

      this.renderAddButton();
    },
    
    onEdit: function (e) {
      if(this.options.readOnly === true)
        return;

      e.stopPropagation();
      EditView.prototype.open([this.model]);
    }
  });
});
