define('webshopProductsListTagsView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'template!webshopProductsListTagsView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-tags-view ' + FieldView.prototype.className,

    regions: {
      'selectRegion': '.select-region'
    },

    modelEvents: {
      'change:attributes': 'setSelected'
    },

    onRender: function () {
      var collection = new Backbone.Collection();
      collection.add({id: '', value: ''});

      App.request('webshop.products.tags').done(_.bind(function (tags) {
        _.each(tags.models, _.bind(function (tag) {
          var model = new Backbone.Model({
            id: tag.get('id'),
            value: _.i18n('webshop.product.tag.' + tag.get('code'))
          });
          collection.add(model);
          this.selectView = new SelectView({
            collection: collection
          });
          this.selectRegion.show(this.selectView);
          this.selectView.on('change', _.bind(this.onSelectChange, this));
          this.setSelected();
        }, this));
      }, this));
    },

    setSelected: function () {
      this.selectView.setSelected(this.model.getTag());
    },

    onSelectChange: function () {
      this.model.setTag(this.selectView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    }
  });
});
