/**
 * Created by OLD on 20/08/2015.
 */
define('caccountDetailsTabGraphicalRequest',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'stateMessages',
  'dateUtils',
  'jqgridView',
  'template!caccountRequestViewTpl',
  'ticketStates',
  'datePickerPopoverMixin',
  'rolesMixin'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  StateMessages,
  dateConverter,
  JqGridView,
  viewTpl,
  ticketStates,
  datePickerPopoverMixin,
  RolesMixin
) {
  'use strict';

  var VectorizationTableView = JqGridView.extend({
    GRID_MIN_WIDTH: 850,
    template: viewTpl,

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-vector'
      }, this.gridUi);
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions, options;

      this.sidx = 'userTimestamp.modDate';
      this.sord = 'desc';
      this.pagination.pageSize = 5;
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: '<i class="mdi-maps-local-post-office icon"></i>',
            name: 'messages',
            classes: 'tickets',
            formatter: this.messageFormatter,
            search: false,
            sortable: false,
            index: 'unreadMessages',
            width: 45
          }, {
            label: _.i18n('common.type'),
            name: 'type',
            classes: 'tickets-type',
            formatter: this.typeFormatter,
            search: false,
            sortable: false,
            stype:'select',
            searchrules: {type: true},
            searchoptions: {
              value: that.graphicalTypes
            },
            index: 'type',
            width: 75  
          }, {
            label: _.i18n('vectorization.tickets.column.attachment'),
            name: 'attachment',
            align: 'left',
            classes: 'tickets-link image-cell complexity-colored',
            formatter: this.attachFormatter,
            search: false,
            sortable: false,
            width: 75
          }, {
            label: _.i18n('common.subject'),
            name: 'name',
            classes: 'tickets-link',
            formatter:  this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'name'
          }, {
            label: _.i18n('common.date.mod'),
            name: 'modDate',
            classes: 'tickets-link',
            formatter: this.dateFormatter,
            search: false,
            sortable: false,
            index: 'modDate',
            sorttype: 'date', 
            searchrules: {date: true},
            searchoptions: {
              dataInit: datePickerPopoverMixin.jqGridDataInit
            },
            width: 130
          }, {
            label: _.i18n('common.status'),
            name: 'ticketState',
            classes: 'tickets-link',
            formatter: this.stateFormatter,
            search: false,
            sortable: false,
            index: 'ticketState',
            stype:'select', 
            searchrules: {select: true},
            searchoptions: {
              value: that.stateStatuses
            },
            width: 100
          }
        ],

        serializeGridData: function(postData) {

          postData.sidx = 'userTimestamp.modDate';
          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        },

        onSelectRow: function (rowid, bool, e) {
          var cellUrgency = that.$('.cell-urgency');

          if (e.type === 'click' && cellUrgency.has(e.target).length === 0) {
            that.trigger('tickets:show', rowid);
          }
        }
      };
      options = _.defaults(options, defaultsOptions);

      return options;
    },

    stateFormatter: function(cellvalue) {
      var ticketState = ticketStates[cellvalue],
        state = ticketState ? ticketState.label : '';

      return '<span>' + state + '</span>';
    },

    typeFormatter: function(cellvalue) {
      var $class = '';
      if (cellvalue === 'vectorization') {
        $class = 'mdi-image-grain';
      } else if (cellvalue === 'conversion') {
        $class = 'mdi-av-repeat';
      } else if (cellvalue === 'remove_background') {
        $class = 'mdi-content-content-cut';
      } else if (cellvalue === 'colorization') {
        $class = 'mdi-image-palette';
      }

      return '<span><i class="' + $class + '"></i></span>';
    },

    messageFormatter: function(cellvalue, options, rowObject) {
      var currentUserPid = Settings.get('currentUser' ),
        unReadMessageCounter = 0, messages,
        founded = false;
      _.each( rowObject.messages, function(message, index) {
        if (index > 1) {
          founded = _.some(message.userpidReaders,function(userpidReader) {
            return userpidReader.secId === currentUserPid.secId;
          });

          if (!founded && (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] && message.direction === 'from' ||
              !Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] && message.direction === 'to') &&
              ticketStates[rowObject.ticketState] !== ticketStates.CLOSE) {
            unReadMessageCounter++;
          }
        }
      });
      if (unReadMessageCounter > 0) {
        messages = '<span class="badge action-bg up count tickets">' +
          unReadMessageCounter + '</span>';
        return '<i class="i i-chat3 tickets"></i>' + messages;
      } else {
        return '';
      }

    },

    attachFormatter: function(cellvalue, options, rowObject) {
      var result,
          fileLink, fileName = '', 
          attachment = rowObject.messages[0].attachements[0];

      if (attachment) {
        fileLink = Settings.url('base', attachment.fileLink);
        fileName = attachment.fileName;
      } else {
        fileLink = Settings.get('notFoundImage');
      }

      result = '<img class="jq-picture attach-img js-image-cell" ' +
          'data-link="' + fileLink + '" data-name="' + fileName + '" src="' + fileLink + '">';

      return result;
    },

    dateFormatter: function(cellvalue) {
      var date = dateConverter.toDateFormatString(cellvalue),
        time = dateConverter.toTimeFormatString(cellvalue);

      return '<span class="cell-date">' + date + ' ' + time + '</span>';
    }

  });

  module.exports = VectorizationTableView;
});
