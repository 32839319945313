/**
 * Created by JDE on 06/01/2016.
 */
define('catalogueMineProductsTabView',[
    'module',
    'jqgridView',
    'app',
    'underscore',
    'template!catalogueMineProductsTabTpl',
    'bootbox'
], function (module,
             JqGridView,
             App,
             _,
             viewTpl,
             bootbox) {
    'use strict';

    var ProductsView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'productsListFilters',

        triggers: {
            'click @ui.addNewProduct': 'addNewProduct' //Caught by controller
        },

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                addNewProduct: '.new-product-button',
                deleteFromCatalogue: '.delete-link'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'click @ui.deleteFromCatalogue': 'onDeleteProduct'
            }, this.gridEvents);
        },

        serializeData: function () {
            return {type: 'product'};
        },

        onDeleteProduct: function (event) {
            bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
                if (result) {
                    this.trigger('deleteProduct', $(event.currentTarget).attr('data-row-id'));
                }
            }, this));
        },

        gridOptions: function (data) {

            var defaultsOptions, options;

            defaultsOptions = this.gridInitOptions(data);
            options = {
                colModel: [
                    {label: '', name: 'secId', key: true, hidden: true},
                    {
                        label: ' ',
                        name: 'cataloguePictureUid',
                        align: 'left',
                        search: false,
                        classes: 'product-link image-cell',
                        formatter: this.pictureUidFormatter
                    },
                    {
                        label: _.i18n('productList.productCode'),
                        name: 'code',
                        search: true,
                        index: 'productCode',
                        sorttype: 'integer',
                        classes: 'product-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: _.i18n('productList.productName'),
                        name: 'name',
                        search: true,
                        index: 'productName',
                        classes: 'product-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: _.i18n('productList.productManufacturer'),
                        name: 'manufacturerCode',
                        search: false,
                        index: 'globalSearchField'/*, stype: 'select'*/,
                        classes: 'product-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: _.i18n('productList.status'),
                        name: 'status',
                        search: false,
                        classes: 'product-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                serializeGridData: _.bind(function (postData) {
                    if (postData.productCode) {
                        postData.code = postData.productCode;
                    }

                    if (postData.productName) {
                        postData.name = postData.productName;
                    }

                    defaultsOptions.serializeGridData.apply(this, arguments);

                    //Add the catalogSecId AFTER calling super.serializeGridData because
                    //the catalogSecId must not be stored in the cookies
                    if (this.model) {
                        postData.catalogSecId = this.model.get('secId');
                        postData.onlyFromCatalogue = true;
                    }

                    return postData;
                }, this),

                onSelectRow: function (rowid, bool, e) {
                    App.trigger('catalogue:product:selected', rowid);


                    if($(e.target).hasClass('product-link') && e.type ==='click') {
                        App.trigger('product:show', rowid);
                    }
                }
            };

            options = _.defaults(options, defaultsOptions);

            return options;
        }
    });

    module.exports = ProductsView;
});
