/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminOverviewView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'settings',
  'template!superAdminOverviewTpl'
], function(
  module,
  Marionette,
  _,
  App,
  moment,
  Setting,
  viewTpl
) {
  'use strict';

  var superAdminView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      edit: 'i.mdi-content-create'
    },

    events: {
      'click @ui.edit' : 'onEdit'
    },

    regions: {
      accounsStats: '#accounts-stats-region',
      creditsStats: '#credits-stats-region',
      creditsGraph: '#credits-graph-region',
      accountGraph: '#account-graph-region',
      ticketsGraph: '#tickets-graph-region'
    },

    onShow: function() {
      $.material.init();
      moment.locale(Setting.get('lang'));
    },

    onEdit: function (e) {
      this.trigger('superadmin:on-edit', $(e.currentTarget));
    }
  });

  module.exports = superAdminView;
});
