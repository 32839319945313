define('adminGlossariesListMissingView',[
  'module',
  'underscore',
  'tableFieldCheckboxView'
], function (
  Module,
  _,
  TableFieldCheckboxView
) {
  'use strict';

  Module.exports = TableFieldCheckboxView.extend({
    className: 'admin-glossaries-list-missing-view table-field-checkbox-view table-field-view',

    setValue: function(value) {
      var defer = $.Deferred();

      if(this.model.get('missingTranslation') !== value) {
        this.model.set('missingTranslation', value);
        this.model.save()
          .done(_.bind(function() {
            defer.resolve();
          }, this))
          .fail(_.bind(function() {
            defer.reject();
          }, this));
      } else
        defer.resolve();

      return defer.promise();
    }
  });
});
