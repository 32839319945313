define('tableFieldCheckboxView',[
  'module',
  'underscore',
  'tableFieldView',
  'template!tableFieldCheckboxView'
], function (
  Module,
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'table-field-checkbox-view table-field-view',

    ui: {
      unchecked: '.unchecked',
      checked: '.checked',
      loader: '.loader-2'
    },

    events: {
      'click @ui.unchecked': 'onUncheckedClick',
      'click @ui.checked': 'onCheckedClick'
    },

    initialize: function () {
      this.tableView = this.options.tableView;
      this.rowView = this.options.rowView;
      this.field = this.options.field;
    },

    onRender: function() {
      var value = this.getValue();
      if(value) {
        this.ui.checked.css('display', 'flex');
        this.ui.unchecked.hide();
      } else {
        this.ui.checked.hide();
        this.ui.unchecked.css('display', 'flex');
      }
    },

    getValue: function() {
      if(this.field.bind)
        return this.model.get(this.field.bind);
      return false;
    },

    onUncheckedClick: function() {
      this.ui.loader.css('display', 'flex');
      this.ui.checked.hide();
      this.ui.unchecked.hide();

      this.setValue(true).done(_.bind(function() {
        this.render();
        this.ui.loader.hide();
      }, this)).fail(_.bind(function() {
        this.render();
        this.ui.loader.hide();
      }, this));
    },

    onCheckedClick: function() {
      this.ui.loader.css('display', 'flex');
      this.ui.checked.hide();
      this.ui.unchecked.hide();

      this.setValue(false).done(_.bind(function() {
        this.render();
        this.ui.loader.hide();
      }, this)).fail(_.bind(function() {
        this.render();
        this.ui.loader.hide();
      }, this));
    },

    setValue: function(value) {
      var defer = $.Deferred();

      if(this.field.bind)
        this.model.set(this.field.bind, value);

      defer.resolve();
      return defer.promise();
    }
  });
});
