/**
 * Created by OLD on 30/10/2015.
 */
define('invitationsRouter',[
    'underscore',
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    _,
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin
) {
    'use strict';

    var InvitationsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'invitations': 'showInvitations'
        }
    });

    var API = {

        checkContent: function() {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function(ContentShowController) {
                ContentShowController.showContent().done(function() {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showInvitations: function() {
            this.checkContent().done(function() {
                require([
                    'invitationsController'
                ], function (OverviewController) {
                    OverviewController.showInvitations();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.INVITATIONS);
                });
            });
        }
    };

    App.on('before:start', function() {
        new InvitationsRouter({
            controller: API
        });
    });

    module.exports = InvitationsRouter;
});
