
define('template!customersPopupAddressView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg">  </div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group" data-field-name="name">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group" data-field-name="firstName">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group" data-field-name="lastName">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group addressL1" data-field-name="addressL1">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL1 )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group addressL2" data-field-name="addressL2">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL2 )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group houseNumber" data-field-name="houseNumber">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="houseNumber" placeholder="' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( houseNumber )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <div class="col-xs-4 p-l-0" data-field-name="zip">\n                        <input type="text" class="form-control floating-label js-info-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'">\n                    </div>\n                    <div class="col-xs-8 p-r-0" data-field-name="city">\n                        <input type="text" class="form-control floating-label js-info-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <div class="js-select-countries-region"></div>\n                </div>\n            </div>\n            <div class="form-group" data-field-name="tel">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="tel" placeholder="' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'" value="' +
((__t = ( tel )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

