
define('template!ordersListImageView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<img src="' +
((__t = ( src )) == null ? '' : __t) +
'" loading="lazy">';

}
return __p
};});

