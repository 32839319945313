define('emailAttachmentPdfView',[
  'module',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentPdfView'
], function (
  Module,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-pdf-view',    

    ui: {
      canvasContainer: '.canvas-ctn',
      canvas: '.canvas-ctn .canvas',
      loader: '.loader-2',
      error: '.error'
    },

    modelEvents: {
      'change:arrayBuffer': 'render',
      'change:error': 'renderError',
      'change:loaded': 'renderLoader'
    },

    events: {
      'click': 'onClick'
    },

    initialize: function () {
      if(!this.model.get('loaded'))
        this.model.set('loaded', false, {silent: true});
    },
    
    onRender: function () {
      this.renderAttachment();
      this.renderLoader();
      this.renderError();
    },

    renderAttachment: function() {
      if (this.options.zoom === true)
        this.$el.addClass('zoom');

      if (this.model.get('arrayBuffer')) {
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var loadingTask = pdfjsLib.getDocument(this.model.get('arrayBuffer'));
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
            this.ui.loader.hide();

            var scale = 0.2;

            if (this.options.zoom === true)
              scale = 2;
            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.canvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //console.log('Page rendered');
            });

            this.ui.canvasContainer.css('display', 'flex');
          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      }
    },

    renderLoader: function () {
      if (this.model.get('loaded') === false) {
        this.ui.canvasContainer.hide();
        this.ui.error.hide();
        this.ui.loader.css('display', 'flex');
      }
      else {
        this.ui.loader.hide();
      }
    },

    renderError: function () {
      if (this.model.get('error')) {
        this.ui.canvasContainer.hide();
        this.ui.loader.hide();
        this.ui.error.css('display', 'flex');
      } else {
        this.ui.error.hide();
      }
    },

    onClick: function () {
      if(this.options.zoom === true)
        return;

      require([
        'windows',
        'emailAttachmentPdfView'
      ], _.bind(function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: this.model,
              zoom: true
            });
          }, this),
          modal: true,
          title: this.model.get('name'),
          height: '80%',
          width: '60%'
        });
      }, this));
    }
  });
});
  
