
define('template!ordersDetailOrderClientCommentsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '\n    <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="comments-ctn"></div>     \n    <div class="no-comments" style="display:none;">-</div> \n\n';

}
return __p
};});

