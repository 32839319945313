define('pimButtonsView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'template!pimButtonsTpl'
], function(
  module,           
  Marionette,
  App,
  _,
  viewTpl
){
  'use strict';

  var PimButtonsView = Marionette.LayoutView.extend({   
    template: viewTpl,
    className: 'buttons-view',

    initialize: function() {  
      //this.model.bind('change', this.render);
    },

    ui: {     
      saveButton: '.save-button',
      cancelButton: '.cancel-button'
    },

    events: {
      'click @ui.saveButton': 'onSave',
      'click @ui.cancelButton': 'onCancel'
    },

    onSave: function()
    {
      this.options.pimProductAttributesTabView.triggerMethod('save');
    },

    onCancel: function()
    {
      this.options.pimProductAttributesTabView.triggerMethod('cancel');
      //this.model.fetch(this.model.get('id'));
    },

    /*onDestroy: function() {
      this.model.unbind('change');
    },*/

    /*onRender: function () {   
      var a = '';
    },*/
  
    serializeData: function () {
      var templateData = {};

      templateData.disabled = '';
      
      return templateData;
    }
  });

  module.exports = PimButtonsView;
});
