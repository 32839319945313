/**
 * Created by GSP on 19/04/2016.
 */
define('cAccountMoreInfoView',[
  'module',
  'underscore',
  'template!cAccountMoreInfoViewTpl',
  'dialogView'], function(module, _, viewTpl, DialogView) {
  'use strict';

  var CAccountMoreInfoPopupView = DialogView.extend( {
    template: viewTpl,

    serializeData: function() {
      _.defaults(this.model.attributes, {
        contactEmail : null,
        city : null,
        telephone : null,
        address : null,
        zip : null,
        country : null
      });
      return this.model.toJSON();
    }

  });
  module.exports = CAccountMoreInfoPopupView;
});
