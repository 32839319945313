define('productConfigDialogView',[
  'module',
  'underscore',
  'backbone',
  'app',
  'jquery',
  'settings',
  'template!productConfigDialogTpl',
  'dialogFormView',
  'optionMixEditCollectionView',
  'configPrintAreaCollectionView',
  'imageUploadBehavior2'
], function (
  module,
  _,
  Backbone,
  App,
  $,
  Settings,
  viewTpl,
  DialogFormView,
  OptionMixEditCollectionView,
  ConfigPrintAreaCollectionView,
  ImageUploadBehavior2
) {
  'use strict';

  var ConfigProductView = DialogFormView.extend({
    template: viewTpl,

    events: {
      'click .dropdown-menu-option a': 'onChangeOption',
      'click .dropdownMenu-changeoption a': 'onChangeOption',
      'click .js-confirm': 'onConfigurationSelected',
      'change .input-quantity': 'onQuantityChange',
      'change .input-comment': 'onCommentChange',
      'click .add-mix-line': 'onAddMixLineClick',
      'click .btn-edit-product': 'onEditProductClick'
    },

    regions: {
      mixOptionRegion: '.mix-table-body',
      configPrintAreasRegion: '.config-printareas-region'
    },

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior2
      }
    },

    ui: {
      attachments: '.attachments'
    },

    onEditProductClick: function () {
      this.trigger('change-product');
    },

    onImageUploadConfirm: function (base64Value, fileExt, property, fileName) {
      this.currentConfiguration.attachments.push({
        content: base64Value,
        extension: fileExt,
        name: fileName
      });
      this.ui.attachments.append($('<label>' + fileName + '</label></br>'));
    },

    notShowRemoveBackgroundPopup: true,
    loadImageInMemory: true,

    serializeData: function () {
      var templateData = {quantity: 50};
      templateData.options = null;
      templateData.availableOptions = null;
      if (this.options.product) {
        templateData.product = this.options.product.toJSON();
        var mixable = false;
        if (templateData.product.productOptionCategories) {
          for (var i = 0; i < templateData.product.productOptionCategories.length; i++) {
            if (templateData.product.productOptionCategories[i].mixable) {
              mixable = true;
            }
          }
        }
        this.mixable = templateData.mixable = mixable;
        this.model = this.options.product;
        templateData.quantityOnly = false;
      } else if (this.options.quantity) {
        this.purchaseOrderId = this.options.purchaseOrderId;
        templateData.quantity = this.quantity = this.options.quantity;
        templateData.quantityOnly = true;
        templateData.product = null;
      } else if (this.options.selectedOptions) {
        var filteredSelectedOptions = [], that = this;
        templateData.availableOptions = this.options.availableOptions;
        _.each(templateData.availableOptions, function (availableOption) {
          var selectedOne = _.findWhere(that.options.selectedOptions, {catId: availableOption.id});
          if (selectedOne) {
            _.each(availableOption.productOptions, function (productOption) {
              if (productOption.id === selectedOne.id) {
                availableOption.selected = productOption;
              }
            });
          }
        });

        _.each(this.options.selectedOptions, function (selectedOption) {

          var filteredSelectedOption = _.findWhere(templateData.availableOptions, {id: selectedOption.catId});
          if (filteredSelectedOption) {
            filteredSelectedOptions.push(filteredSelectedOption);
          }
        });
        templateData.quantityOnly = false;
        templateData.mixable = false;
        templateData.product = null;
        templateData.options = filteredSelectedOptions;

      }
      return templateData;
    },

    onShow: function () {
      $.material.init();
      this.currentConfiguration = {
        quantity: 50,
        printZones: {},
        printZonesImages: {},
        options: {},
        attachments: []
      };

      if (this.options.product) {
        var Col = Backbone.Collection.extend({
          model: Backbone.Model.extend({
            idAttribute: 'idCode'
          }),
          idCode: function () {
            return this.get('id') + this.get('code');
          }
        });
        var paCollection = new Col(this.options.product.get('printAreas'));
        this.configPrintAreaCollectionView = new ConfigPrintAreaCollectionView({
          collection: paCollection
        });
        this.configPrintAreaCollectionView.listenTo(this.configPrintAreaCollectionView, 'selected:printzone:change',
          _.bind(this.onSelectedPrintZoneChange, this));
        this.configPrintAreaCollectionView.listenTo(this.configPrintAreaCollectionView, 'selected:printzone:image:change',
          _.bind(this.onSelectedPrintZoneImageChange, this));
        this.getRegion('configPrintAreasRegion').show(this.configPrintAreaCollectionView);
      }

      if (this.purchaseOrderId) {
        this.currentConfiguration.purchaseOrderId = this.purchaseOrderId;
      } else {
        if (this.mixable) {
          var collection = new Backbone.Collection();
          this.productJson = this.options.product.toJSON();
          var defaultModel = new Backbone.Model({

            productOptionCategories: this.productJson.productOptionCategories,
            quantity: 100
          });
          collection.push(defaultModel);
          this.optionMixEditCollectionView = new OptionMixEditCollectionView({
            collection: collection,
            product: this.productJson
          });
          this.getRegion('mixOptionRegion').show(this.optionMixEditCollectionView);
        }
      }
    },

    onSelectedPrintZoneChange: function (paId, pzId, nbrColor) {
      if (pzId === -1) {
        delete this.currentConfiguration.printZones[paId];
      } else {
        this.currentConfiguration.printZones[paId] = {
          pzId: pzId,
          nbrColor: nbrColor
        };
      }
    },

    onSelectedPrintZoneImageChange: function (paId, pzId, fileContent) {
      if (pzId === -1) {
        delete this.currentConfiguration.printZonesImages[paId];
      } else {
        this.currentConfiguration.printZonesImages[paId] = {
          pzId: pzId,
          fileContent: fileContent
        };
      }
    },

    onAddMixLineClick: function () {
      var defaultModel = new Backbone.Model({
        productOptionCategories: this.productJson.productOptionCategories,
        quantity: 100
      });
      this.optionMixEditCollectionView.collection.push(defaultModel);
    },

    onQuantityChange: function (e) {
      e.preventDefault();
      var target = $(e.target);
      this.currentConfiguration.quantity = target.val();
    },

    onCommentChange: function (e) {
      e.preventDefault();
      var target = $(e.target);
      this.currentConfiguration.comment = target.val();
    },

    onChangeOption: function (e) {
      e.preventDefault();
      var target = $(e.target),
        index = target.data('index'),
        optionId = target.data('optionId');
      this.$el.find('.option-' + index + '-label').text(target.text());
      this.currentConfiguration.options[index] = optionId;
    },

    validate: function () {
      var errors = [];
      if (this.options.product) {
        _.each(this.options.product.get('productOptionCategories'), _.bind(function (cat, index) {
          if (!this.currentConfiguration.options[index]) {
            errors.push({name: 'option-category-' + index});
          }
        }, this));

        if (!_.isEmpty(errors)) {
          this.onError(errors);
          return false;
        }
      }
      return true;
    },

    onConfigurationSelected: function () {
      /*if (!this.validate()) {
        return false;
      }*/

      if (this.options.selectedOptions) {
        this.trigger('options-changed', this.currentConfiguration);
      } else {
        if (this.mixable) {
          this.currentConfiguration.optionsMix = this.optionMixEditCollectionView.getMixesConfiguration();
          this.currentConfiguration.quantity = 0;
          _.each(this.currentConfiguration.optionsMix, _.bind(function (mix) {
            this.currentConfiguration.quantity += mix.quantity;
          }, this));
        }
      }
      this.trigger('product-configured', this.currentConfiguration);
    }
  });
  module.exports = ConfigProductView;
});

