
define('template!ordersDetailPriceV2PrintPriceView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<th><input class="checkbox-enable-disable-pz" style="margin: 0px;" type="checkbox" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
' />\n  <i class="mdi-action-check-circle icon btn-save-change-enable-pz clickable" style="display: none; vertical-align: inherit;"></i></th>\n<th>' +
((__t = ( index )) == null ? '' : __t) +
'</th>\n<th colspan="2">' +
((__t = ( pa.name )) == null ? '' : __t) +
'</th>\n<th colspan="2">' +
((__t = ( pz.name )) == null ? '' : __t) +
'</th>\n<th colspan="2">' +
((__t = ( pz.width + 'x' + pz.height + 'mm' )) == null ? '' : __t) +
'</th>\n';
 if (checked && pz.colorModeType === 'spot') { ;
__p += '\n<th>\n  <select class="select-nbr-colors">\n  ';
 for(var i = 1 ; i <= pz.maxColors ; i++) { ;
__p += '\n    <option ';
  if (composition.nbrColors === i) { ;
__p += ' selected ';
 } ;
__p += '>' +
((__t = ( i )) == null ? '' : __t) +
'</option>\n  ';
 } ;
__p += '\n  </select>\n  <i class="mdi-action-check-circle icon btn-save-change-nbr-colors clickable" style="display: none; vertical-align: inherit;"></i>\n</th>\n';
 } else { ;
__p += '\n<th>' +
((__t = ( pz.maxColors )) == null ? '' : __t) +
'</th>\n';
 } ;
__p += '\n<th>' +
((__t = ( setup )) == null ? '' : __t) +
'</th>\n<th>' +
((__t = ( handlingPrice )) == null ? '' : __t) +
'</th>\n';
 _.each(scales, function(scale) { ;
__p += '\n<th>' +
((__t = ( scale.unit )) == null ? '' : __t) +
'</th>\n';
 }); ;


}
return __p
};});

