
define('template!caccountSupplierFactorV2Tpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-12 p-t-5">\n    <label>' +
((__t = ( idx )) == null ? '' : __t) +
'</label><input class="txt-factor-margin m-l-15" style="width: 50px;" value="' +
((__t = ( margin )) == null ? '' : __t) +
'"/>\n  </div>\n</div>\n';

}
return __p
};});

