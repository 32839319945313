/**
 * Created by OLD on 31/08/2015.
 */
define('internalMessageTabView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'bootbox',
  'imageUtils',  
  'stateMessages',
  'internalMessageMessagesView',
  'template!internalMessageTabTpl',
  'notificationCenter',
  'attachmentCollectionView',
  'imageUtils',
  'rolesMixin',
  'tawlkToBehavior',
  'dropdownCompositeView',
  'zoomFullScreenBehavior',
  'translation'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  App,
  Settings,
  bootbox,
  ImageUtils,
  StateMessages,
  ItemView,
  viewTpl,
  NotificationCenter,
  AttachmentCollectionView,
  imageUtils,
  RolesMixin,
  TawlkToBehavior,
  DropdownCompositeView,
  ZoomFullScreenBehavior,
  Translation
) {
  'use strict';

  var MessageModel = Backbone.Model.extend({
    defaults: {
      name: null,
      content: null,
      files: []
    }
  });

  var CommandTicketsView = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.messages-container',
    childView: ItemView,
    childViewOptions: {},

    className: 'internal-messages-view',

    ui: {
      sendMessage: '.js-send-new-m-b',
      content: '.command-tickets-content',
      textarea: '.create-new-message',
      attach: '.attach-input',
      attachBtn: '.attach-btn',
      attachBox: '.attachments-addon',
      attachList: '.attachments-addon-list',
      preview: '.img-preview-m',
      footer: '.js-footer',
      actionRequired : '.js-action-required',
      actionRequiredInternal : '.js-action-required-internal',
      actionRequiredUkr : '.js-action-required-ukr',
      blockLoading: '.js-loading-block'
    },

    events: {
      'click @ui.sendMessage' : 'sendMessage',
      'click @ui.attachBtn': 'onFileBrowse',
      'change @ui.attach' : 'selectAttach',
      /*'autogrow @ui.footer': 'onTextAreaGrow',*/
      'click .dropdown-menu li a': 'onTextSupportSelected',
      'change @ui.actionRequired': 'onActionRequiredCheckbox',
      'change @ui.actionRequiredInternal': 'onActionRequiredInternalCheckbox',
      'change @ui.actionRequiredUkr': 'onActionRequiredUkrCheckbox'
    },

    childEvents: {
      'onUploadImage' : 'onUploadImage',
      'attachedViewShown' : 'checkIfNeedHeightScroll',
      'deleteMessage' : 'deleteMessage'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      },
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      },
      ZoomFullScreenBehavior: {
        behaviorClass: ZoomFullScreenBehavior,
        target: '.js-image-cell',
        gallery: true
      }
    },

    onRender: function() {
      if (this.options.csMsg) {
        $('#content-region').addClass('active-cs-message-tab');
      } else {
        $('#content-region').removeClass('active-cs-message-tab');
      }
    },

    onShow: function () {
      this.messagesCounter = 0;
      $.material.init();
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.NEW_VECTORISATION_TICKET_MESSAGE, this.onMessage);
      this.showAttachmentsView();    
      this.ui.textarea.autogrow();    
    },

    onTabClick: function(e) {
      Settings.set('lastMsgTab', $(e.currentTarget).data('name'));
    },

    onTextSupportSelected: function(e) {
      e.preventDefault();
      var lang = this.options.customer.locales || 'en';
      this.ui.textarea.val(
        _.i18n(Translation.getOrderTextSupport($(e.currentTarget).data('key')),
          lang.toLowerCase()));
    },

    onUploadImage: function(childView) {
      var formdata = new FormData(),
        file = childView.ui.uploadInput[0].files[0],
        request, params, src, link;

      formdata.append('file', file);
      request = App.request('userRequestFile:upload', formdata);
      childView.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));

      $.when(request).done(function(resp) {
        params = { logoUID: resp };
        src = Settings.url('x2pdf', 'vectorteam/preparefile', params);

        link = window.document.createElement('a');
        link.href = src;
        link.download = 'download.pdf';
        link.click();

        childView.triggerMethod('hideMessage');
      });
    },

    onDeleteFileAttachment: function(childView, attId, ticketSecId, messageSecId) {
      this.trigger('tickets:delete:fileAttachment', attId, ticketSecId, messageSecId);
    },

    showAttachmentsView: function() {
      this.attachmentsView = new AttachmentCollectionView({el: this.ui.attachList});
      this.attachmentsView.render();
    },

    onMessage: function (response) {
      if (this.options.ticket.get('secId') !== response.content.ticketSecId) {
        //if the message is not for me then ask notification center to display a notification.
        NotificationCenter.getInstance().processNotification(response);
      } else {
        if (!_.findWhere(this.collection.toArray(), {id:response.content.id})) {
          var messageModel = new MessageModel(response.content);
          this.addMessage(messageModel);
        }
      }
    },

    serializeData: function() {
      var templateData = this.collection.toJSON();
      //var config = this.options.ticket.get('configuration');
      if (this.options.csMsg) {
        templateData.tempMessage = Settings.getFromMemory('commandTicketMessageCsMsg');
      } else {
        templateData.tempMessage = Settings.getFromMemory('commandTicketMessage');
      }

      this.childViewOptions.csMsg = this.options.csMsg;
      //templateData.outputType = config ? config.outputType : '';
      templateData.ticketSecId = this.options.ticket.secId;
      templateData.user = this._getAssignUserData();
      templateData.textsSupport = this.options.textsSupport;
      templateData.actionRequired = this.options.actionRequired;
      templateData.actionRequiredInternal = this.options.actionRequiredInternal;
      templateData.actionRequiredUkr = this.options.actionRequiredUkr;
      templateData.csMsg = this.options.csMsg;
      return templateData;
    },

    _getAssignUserData: function() {
      var user = this.options.ticket.refUser;

      if (user) {
        return {
          userImg: (function() {
            if (user.logo) {
              return Settings.url('file', user.logo, {size: 'thumbnail'});
            } else if (user.logonId) {
              var userName = user.firstName + ' ' + user.lastName;
              return imageUtils.getLogo(
                user.logo, 
                userName.trim() ? userName : user.logonId, 
                'circle'
              );
            } else {
              return null;
            }
          })(),
          userName: user.logonId
        };
      } else {
        return {userImg: null,userName: null};
      }
    },

    /* Scroll messages to the bottom of visible area*/
    checkIfNeedHeightScroll: function() {
      this.messagesCounter++;
      if (this.messagesCounter === this.collection.length) {
        this.heightScroll();
      }
    },

    heightScroll: function (target) {
      var content = this.ui.content;

      if (target === 'attachment:add') {
        content.addClass('m-b-45');
      } else {
        content.removeClass('m-b-45');
      }

      //Get the last message position and height
      var $lastMsg = this.$('.messages-container').find('.tickets-message-container' ).last();
      if ($lastMsg.length) {
        //Set the new height on the message container
        /*this.ui.content.css('height', $lastMsg.position().top +
          $lastMsg.height() +
          this.ui.footer.height()
        );*/
      }
      //Scroll at the bottom of the page      
      setTimeout(function() {
        $('#content-region').animate({scrollTop: $('.messages-container').height()}, 'slow');
      }, 0);
    },

    sendMessage: function() {
      var that = this,
        messageContent = this.ui.textarea.val(),
        textarea = this.ui.textarea,
        messageModel;
      that._disableSend();
      if ((messageContent !== '' || that.options.attachedFiles !== [])) {
        textarea.parent().removeClass('has-error');

        messageModel = new MessageModel();
        messageModel.set('content', messageContent);
        messageModel.set('name', that.options.ticket.name || ' ');
        messageModel.set('files', that.options.attachedFiles);
        //messageModel.url = messageModel.postUrl({csMsg:this.csMsg===true}, that.options.ticket.secId);
        textarea.prop('disabled', true);

        this.trigger('command:ticket:save-message', that.options.ticket.secId, messageModel);

      } else {
        textarea.parent().addClass('has-error');
        that._enableSend();
      }
    },

    addMessage: function (messageModel) {
      this.ui.textarea.prop('disabled', false);
      this.ui.textarea.val('');
      this.collection.add(messageModel);
      this.options.attachedFiles = [];
      this.ui.attachBox.addClass('hidden');
      this.heightScroll();
      this._triggerDeleteTextArea();
      this.attachmentsView.collection.reset();
      this._enableSend();
    },

    deleteMessage: function(message) {
      var that = this;
      bootbox.confirm(_.i18n('warning.deleteMessage'), function (result) {
        if (result) {
          that.trigger('message:delete',message);
        }
      });
    },

    onFileBrowse: function(e) {
      e.preventDefault();
      this.ui.attach.click();
    },

    selectAttach: function(event) {
      var files = this.ui.attach[0].files;
      this.fileToLoad = files.length;
      this.fileLoaded = 0;
      if (this.fileToLoad === 0) {
        return;
      }
      this.ui.sendMessage.addClass('disabled');
      this.startMultiUpload(event.target);
    },

    startMultiUpload: function(fileInput){
      this.fileInputTmp = fileInput;
      this.postLoadedAttach(fileInput);
    },

    postLoadedAttach: function (fileInput) {
      if (fileInput) {
        this.fileInfo = ImageUtils.getFileInfo(fileInput, this.fileLoaded);

        //Load and encode the image
        ImageUtils.encodeInputFileToBase64(fileInput,
        this.fileLoaded, _.bind(this.onAttachmentEncoded, this));
      }
    },

    onAttachmentEncoded: function(base64File) {
      var file;
      file = {
        name: this.fileInfo.fileName,
        content: base64File, //base64 file encoded
        extension: this.fileInfo.fileExt //file extension
      };
      this.fileInfo.base64Value = base64File;

      this.options.attachedFiles.push(file);
      this.fileLoaded++;
      this.showAttach(file);
    },

    showAttach: function(file) {
      var attachmentModel = new Backbone.Model();

      attachmentModel.set('name', file.name);
      attachmentModel.set('extension', file.extension);
      this.attachmentsView.collection.add(attachmentModel);
      this.ui.attachBox.removeClass('hidden');
      if (this.fileLoaded < this.fileToLoad) {
        this.startMultiUpload(this.fileInputTmp);
      }
      else{
          this.ui.attach.val('');
      }
      this.heightScroll('attachment:add');
      this.ui.sendMessage.removeClass('disabled');
    },

    onActionRequiredCheckbox: function(e) {
      var checked = $(e.currentTarget).is(':checked');
      this.ui.blockLoading.show();
      $('input:checkbox').attr('disabled',true);
      this.trigger('command:action-required:change', checked);
    },

    onActionRequiredInternalCheckbox: function(e) {
      var checked = $(e.currentTarget).is(':checked');
      this.ui.blockLoading.show();
      $('input:checkbox').attr('disabled',true);
      this.trigger('command:action-required-internal:change', checked);
    },

    onActionRequiredUkrCheckbox: function(e) {
      var checked = $(e.currentTarget).is(':checked');
      this.ui.blockLoading.show();
      $('input:checkbox').attr('disabled',true);
      this.trigger('command:action-required-ukr:change', checked);
    },

    changeStatus: function(state) {
      this.ui.backToList.css('pointer-events', 'none');
      this.trigger('state:change', state);
    },

    _disableSend: function() {
      this.ui.sendMessage.addClass('disabled');
      this.ui.attachBtn.addClass('disabled')
        .find('a.send-new-m-b').addClass('disabled');
      this.ui.attach.attr('disabled', true);
    },

    _enableSend: function() {
      this.ui.sendMessage.removeClass('disabled');
      this.ui.attachBtn.removeClass('disabled')
        .find('a.send-new-m-b').removeClass('disabled');
      this.ui.attach.attr('disabled', false);
    },

    _triggerDeleteTextArea: function() {
      var e = $.Event('keyup', {which: 8});
      this.ui.textarea.trigger(e);
    },

    onBeforeDestroy: function() {
      if (this.options.csMsg) {
        Settings.setToMemory('commandTicketMessageCsMsg',this.ui.textarea.val());
      } else {
        Settings.setToMemory('commandTicketMessage',this.ui.textarea.val());
      }
     
      if (this.attachmentsView) {
        this.attachmentsView.destroy();
      }
      if(this.changeStatusView) {
        this.attachmentsView.destroy();
      }
      if ($('#content-region').hasClass('active-cs-message-tab')) {
        $('#content-region').removeClass('active-cs-message-tab');
      }      
    },

    onTextAreaGrow: function() {
      //this.heightScroll();
    },
   
    onChangeTicketUrgency: function(e) {
      var $input = $(e.currentTarget),
        ticketID = $input.data('ticket-cid'),
        checked = $input.is(':checked');
      
      this.trigger('ticket:update:urgency', ticketID, checked);        
    },

    onTicketTariffScaleChange: function(e) {
      var $target = $(e.currentTarget);

      this.trigger(
        'tickets:update:tariffScale', 
        $target.data('ticket-cid'), 
        $target.find(':selected').val()
      );    
    },

    setCAccountWarning: function() {
        this.ui.cAccountMoreInfo.parent().addClass('caccount-more-info-warning');
        this.$('.caccount-more-info > i' ).removeClass('fa-info-circle' ).addClass('fa-exclamation-triangle');
    },

    hideLoadingBlock: function() {
       this.ui.blockLoading.hide();
      $('input:checkbox').attr('disabled',false);

    }

  });

  module.exports = CommandTicketsView;
});

