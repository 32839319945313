
define('template!googleContactPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="checkbox checkbox-primary">\n    <label>\n        <input type="checkbox" class="send-email" ';
 if (selected) { ;
__p += 'checked="checked"';
 } ;
__p += ' >\n    </label>\n    <span class="p-l-5">' +
((__t = ( email )) == null ? '' : __t) +
'</span>\n</div>';

}
return __p
};});

