define('logotoolUtils',[
  'module',
  'settings',
  'envHandler'
], function(
  module,
  Settings,
  EnvironmentHandler
) {
  'use strict';

  var logotoolUtils = {

    /**
     * Return logotool default page url
     * @param subdomain
     */
    getLogotoolDefaultUrl: function () {
      var env = Settings.get('environment'),
          url = Settings.get('websitePrefix') + Settings.get('currentCAccount').subDomain +
              Settings.get('websitePostfix');

      env = (env && env.trim()) ? env : '';

      if (!env) {
        return 'http://localhost:8084/';
      } else {
        return url + EnvironmentHandler.getValue(env, 'logoToolSubDomain');
      }
    },

    /**
     * Return logotool products page
     * @param paramsObj
     * @returns {string}
     */
    getLogotoolUrl: function (paramsObj) {
      return this.getLogotoolDefaultUrl() + this.getQueryParams(paramsObj);
    },

    /**
     * Return logotool catalogues page url
     * @param paramsObj
     */
    getLogotoolCataloguesUrl: function (paramsObj) {
      return this.getLogotoolDefaultUrl() + this.getQueryParams(paramsObj) + '#catalogues';
    },

    /**
     * Return logotool catalogues page url
     * @param subdomain
     * @param paramsObj is supposed to contain (catalogueSecId)
     */
    getLogotoolCatalogueUrl: function (paramsObj) {
      if (paramsObj.catalogueSecId) {
        return this.getLogotoolDefaultUrl() + this.getQueryParams(paramsObj) + '#catalogues/' + paramsObj.catalogueSecId;
      } else {
        return this.getLogotoolCataloguesUrl(paramsObj);
      }
    },

    getLogotoolEditOrderUrl: function (paramsObj) {
      return this.getLogotoolDefaultUrl() + this.getQueryParams(paramsObj) +
        '#orderprocess/order/' + paramsObj.purchaseOrderId + '/product/' + paramsObj.productCode;
    },
    /**
     * When the url is built, add all default query params (such as endUserId, tpl...)
     * @param url
     * @param paramsObj
     */
    getQueryParams: function (paramsObj) {
      var queryParams = '?';
      if (paramsObj.endUserSecId) {
        queryParams += 'u=' + paramsObj.endUserSecId + '&';
      }

      if (paramsObj.createTemplate) {
        queryParams += 'tpl=true&';
      }

      if(paramsObj.returnUrl) {
        queryParams += 'ret=' + encodeURIComponent(paramsObj.returnUrl) + '&';
      }

      //Remove last '&'
      queryParams = queryParams.substring(0, queryParams.length - 1);
      return queryParams;
    }
  };

  module.exports = logotoolUtils;
});
