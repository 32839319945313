define('tasksHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'windows',
  'buttonView',
  'tableHeaderFieldView',
  'tasksEditView',
  'template!tasksHeaderToolsView'
], function (
  App,
  Module,  
  _,
  Windows,
  ButtonView,
  FieldView,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-header-tools-view',

    regions: {
      addRegion: '.add-region'
    },

    onRender: function() {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('tasks.add'),
        title: _.i18n('tasks.add')
      });

      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));
    },

    onAdd: function() {
      var model = App.request('tasks:model');
      if((this.options.tableView.newTaskModel))
        model = this.options.tableView.newTaskModel;

      EditView.prototype.open({
        model: model,
        newTask: true,
        onConfirm: _.bind(function () {
          this.tableView.bodyView.fetch();
          this.tableView.footerView.fetch();
        }, this)
      });
    }
  });
});
