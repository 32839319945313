define('catalogueMineOverviewTabController',[
  'module',
  'catalogueMineOverviewTabView',
    'cataloguesAddNewProductPopupView',
    'app', 'underscore'
], function(
  module,
  MineOverviewTabView,CataloguesUpdatePopupView,
  App,_
) {
  'use strict';

  var CatalogueOverviewTabController = {
    showOverviewTab: function(region, catalogueModel) {
      this.model = catalogueModel;
      this.view = new MineOverviewTabView({
          model: this.model
      });
      this.view.listenTo(this.view, 'catalogue:edit', _.bind(this.editCatalogue, this));
      region.show(this.view);
    },

    editCatalogue : function(){
        this.popupView = new CataloguesUpdatePopupView({
            model: this.model.clone()
        });
        this.popupView.listenTo(this.popupView.model, 'sync', _.bind(this.onConfirmPopup, this));
        this.popupView.show({
            title: _.i18n('catalogues.new.title'),
            buttons: {
                cancel: {
                    label: _.i18n('common.cancel'),
                    className: 'btn-default',
                    callback: function() { return true; }
                },
                success: {
                    label: _.i18n('common.confirm'),
                    className: 'btn-primary',
                    callback: _.bind(function() {
                        this.popupView.model.saveModel();
                        return false;
                    }, this)
                }
            }
        });
    },

      onConfirmPopup: function() {
          this.model.set(this.popupView.model.attributes);
          this.view.render();
          this.popupView.hide();
          App.trigger('catalogue:changed', this.model);
      }
  };

  module.exports = CatalogueOverviewTabController;
});
