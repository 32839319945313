
define('template!productGridItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="panel panel-default grid-card">\n  <div class="panel-body list-group">\n    <div class="text-center grid-image">\n      <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n    </div>\n    <hr/>\n    <p class="m-0 ellipsis" title="' +
((__t = ( code )) == null ? '' : __t) +
'"><strong>' +
((__t = ( code )) == null ? '' : __t) +
'</strong></p>\n    <p class="md-grey-cl ellipsis" title="' +
((__t = ( name )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n  </div>\n</div>';

}
return __p
};});

