define('ordersListFiltersEditLabelView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'template!ordersListFiltersEditLabelView'
], function (
  Module, 
  Marionette,
  _,
  Events,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-list-filters-edit-label-view',

    ui: {
      selected: '.selected'
    },

    events: {
      'click': 'onClick'
    },

    modelEvents: {
      'change': 'onRender'
    },

    onRender: function () {
      if(this.model.get('color')) {
        this.$el.css('background-color', this.model.get('color'));
        this.$el.attr('title', this.model.get('name'));
      }

      if(this.model.get('selected'))
        this.ui.selected.css('display', 'flex');
      else
        this.ui.selected.hide();

      if(this.model.get('focused')) {
        Events.push(this);
        this.$el.addClass('focus');
      }
      else {
        Events.pop(this);
        this.$el.removeClass('focus');
      }
    },

    onClick: function () {
      this.model.set('selected', !this.model.get('selected'));
      this.trigger('onSelect', this.model);
    },

    onKeyDown: function(e) {
      if (e.which === 32 && this.model.get('focused') === true) {
        e.preventDefault();
        e.stopPropagation();
        this.model.set('selected', !this.model.get('selected'));
        return false;
      }
    }
  });
});
