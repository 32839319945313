
define('template!editCatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="category" style="position: relative;">\n  <div class="row">\n    <div class="col-xs-4 cat">\n      <div class="cat">\n        <div class="row">\n          <div class="col-xs-9">\n            <div class="cat-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="cat-info">\n              <div class="cat-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-8 options">\n      <div class="options">\n        <div class="options-title">\n          Options\n          <i class="mdi mdi-av-my-library-add btn-add-option clickable p-l-10"\n             style="font-size: 18px;"></i>\n        </div>\n        <div class="row options-header">\n          <div class="col-xs-2 code">Code</div>\n          <div class="col-xs-4 name">Name</div>\n          <div class="col-xs-2 color">Color</div>\n          <div class="col-xs-4 images">Images</div>\n        </div>\n        <div class="options-region"></div>\n      </div>\n    </div>\n  </div>\n  ';
 if (!readOnly) { ;
__p += '\n  <div class="btn-container btn-pa-container" style="display: none;">\n    <i class="mdi mdi-action-delete btn-delete-poc clickable btn-frame"></i>\n    <i class="mdi mdi-editor-mode-edit btn-edit-poc clickable btn-frame"></i>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

