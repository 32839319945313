define('webshopProductsDetailView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'windows',
  'webshopProductsDetailHeaderView',
  'webshopProductsDetailBodyView',
  'template!webshopProductsDetailView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Windows,
  HeaderView,
  BodyView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-view',

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region'      
    },

    modelEvents: {
      'change:texts': 'renderWindowTitle'
    },

    initialize: function () {
      this.model = App.request('webshop.product.model', {code: this.options.code});
      this.url = 'webshop/products/' + this.options.code;
    },

    onShow: function () {
      this.model.fetch().done(_.bind(function () {
        this.renderWindowTitle();

        this.headerView = new HeaderView({
          model: this.model
        });
        this.getRegion('headerRegion').show(this.headerView);

        this.bodyView = new BodyView({
          model: this.model,
          tabName: this.options.tabName
        });
        this.getRegion('bodyRegion').show(this.bodyView);
        this.bodyView.on('selectTab', _.bind(this.selectTab, this));
      }, this));
    },

    renderWindowTitle: function () {
      this.windowView.setTitle(_.i18n('webshop.product.title') + ' | ' + this.model.getName());
    },

    selectTab: function (tab) {
      this.url = 'webshop/products/' + this.options.code;
      if(tab.get('url') !== '')
        this.url += '/' + tab.get('url');
      App.navigate(this.url, {trigger: false});
    },

    // Static methods
    open: function (code, tabName) {
      require([
        'webshopProductsDetailView'
      ], function (View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              code: code,
              tabName: tabName
            });
          }, this),
          onOpened: function (windowView) {
            windowView.view.bodyView.tabsView.setSelected(tabName);
            windowView.view.bodyView.selectTab(windowView.view.bodyView.tabsView.getSelected());
          },
          id: 'webshop.product:' + code,
          title: _.i18n('webshop.product.title'),
          width: '80%',
          height: '80%'
        });
      });
    }
  });
});

