/**
 * Created by GSP on 16/07/2015.
 */
define('entities/session',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function (
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Session = Backbone.Model.extend({
    url: function (userName, password) {
      var params = {username: userName, password: password};

      return Settings.url('compuzz2', 'sessions.json/token/byuser', params);
    }
  });

  /**
   * REST API
   */
  var API = {
    postSessionTokenByUserEntity: function () {
      var session = new Session({});

      session.url = Settings.url('compuzz2', 'sessions.json/token/byuser');

      return session;
    },

    getSessionByToken: function (data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'sessions.json/user/byToken', data);

      $.get(url).done(
        function (resp) {
          defer.resolve(resp);
        }
      );

      return defer.promise();
    },

    registerNew: function (data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'v2/caccounts/quick', data);

      $.ajax({
        type: 'POST',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    postForgotPassword: function () {
      var session = new Session({});
      session.url = Settings.url('compuzz2','v2/userpid/forgotPassword');
      return session;
    },

    postChangePassword: function () {
      var session = new Session({});
      session.url = Settings.url('compuzz2','v2/userpid/changePassword');
      return session;
    },

    postResetPassword: function () {
      var session = new Session({});
      session.url = Settings.url('compuzz2','v2/userpid/updatePassword');
      return session;
    },

    createSession: function (session) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'sessions.json'),
        type: 'POST',
        data: JSON.stringify(session),
        dataType: 'json',
        contentType: 'application/json',
        success: function (result) {
          defer.resolve(result);
        }
      });

      return defer.promise();
    },

    createSessionFromPoi: function (session, poi) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'sessions.json/frompoi/' + poi),
        type: 'POST',
        data: JSON.stringify(session),
        dataType: 'json',
        contentType: 'application/json',
        success: function (result) {
          defer.resolve(result);
        }
      });

      return defer.promise();
    },

    getCreatedToken: function (sessionId) {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'sessions.json/token/createBySessionId/' + sessionId);
      $.get(url).done(
        function (resp) {
          defer.resolve(resp);
        }
      );

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('session:token:user:entity:new', function () {
    return API.postSessionTokenByUserEntity();
  });

  App.reqres.setHandler('session:forgot:password', function () {
    return API.postForgotPassword();
  });

  App.reqres.setHandler('session:change:password', function () {
    return API.postChangePassword();
  });

  App.reqres.setHandler('session:reset:password', function () {
    return API.postResetPassword();
  });

  App.reqres.setHandler('session:create-session', function (session) {
    return API.createSession(session);
  });

  App.reqres.setHandler('session:create-session-from-poi', function (session, poi) {
    return API.createSessionFromPoi(session, poi);
  });

  App.reqres.setHandler('session:register:new', function (data) {
    return API.registerNew(data);
  });

  App.reqres.setHandler('session:userByToken', function (data) {
    return API.getSessionByToken(data);
  });

  App.reqres.setHandler('session:createToken', function (sessionId) {
    return API.getCreatedToken(sessionId);
  });
});

