define('imageUploadBehavior',[
  'module',
  'marionette',
  'imageUtils',
  'imageSelectionPopupView',
  'app',
  'underscore',
  'entities/images'
], function (
  module,
  Marionette,
  imageUtils,
  ImageSelectionPopupView,
  App,
  _
) {
  'use strict';

  var ImageUploadBehavior = Marionette.Behavior.extend({
    ui: {
      changeLogo: '.js-change-logo'
    },

    events: {
      'click .js-logo-holder': '_onClickLogoHolder',
      'change @ui.changeLogo': '_onChangeLogo'
    },

    triggers: {
      cancel: 'image:upload:cancel'
    },

    fileExt: 'png',

    _onClickLogoHolder: function () {
      this.ui.changeLogo.val(null);
      this.ui.changeLogo.trigger('click');
    },

    _onChangeLogo: function (event) {
      this.triggerMethod('alert');
      var target = event.target,
        fileInfo = imageUtils.getFileInfo(target, 0);
      this.imageInfo = App.request('images:model');
      this.listenTo(this.imageInfo, 'sync', _.bind(this._showImageOrPopup, this, target));
      this.listenTo(this.imageInfo, 'request', _.bind(this._showUploadLabel, this, target));

      if (fileInfo && !this.view.loadImageInMemory) {
        this.imageInfo.getImageInfo(fileInfo.formData, fileInfo.fileLastModified);
      } else if (this.view.loadImageInMemory) {
        this._showLogo(target);
      }
    },

    _showUploadLabel: function () {
      this.view.triggerMethod('uploading');
    },

    _showImageOrPopup: function (target) {
      if (!this.view.notShowRemoveBackgroundPopup) {
        this.view.triggerMethod('hide:label');
        switch (this.imageInfo.get('hasWhite')) {
          case 'out':
            this._showImageSelectionPopup('out', target);
            break;
          case 'all':
            this._showImageSelectionPopup('all', target);
            break;
          case 'error':
          case 'none':
            this._showLogo(target);
            break;
          default:
            throw 'hasWhite has wrong value: ' + this.imageInfo.get('hasWhite');
        }
      } else {
        this._showLogo(target);
      }
    },

    _showImageSelectionPopup: function (hasWhite, target) {
      var imageSelectionView = new ImageSelectionPopupView({
        hasWhite: hasWhite,
        imageUid: this.imageInfo.get('fileUid')
      });
      imageSelectionView.target = target;
      imageSelectionView.show({title: _.i18n('logotypeSelection.title')});

      this.listenTo(imageSelectionView, 'confirm', this._triggerConfirm);
      this.listenTo(imageSelectionView, 'cancel', this._triggerCancel);
    },

    _showLogo: function (target) {
      var that = this,
        fileInfo = imageUtils.getFileInfo(target, 0);
      imageUtils.encodeInputFileToBase64(target, 0, function (imageBase64) {
          that._triggerConfirm(imageBase64, fileInfo.fileExt, target, fileInfo.fileName);
        }
      );
    },

    _triggerConfirm: function (imageBase64, fileExt, target, fileName) {
      this.view.triggerMethod('image:upload:confirm', imageBase64, fileExt, this.$el.find(target).data('property'), fileName);
    },

    _triggerCancel: function () {
      this.view.triggerMethod('image:upload:cancel');
    }
  });

  module.exports = ImageUploadBehavior;
});
