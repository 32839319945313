define('jqueryInputValidator',['jquery'], function ($) {
    'use strict';

    function _isValidOptions(options) {
        return (typeof options.successClass === 'string' &&
            typeof options.errorClass === 'string' &&
            typeof options.sucessMessage === 'string' &&
            typeof options.errorMessage === 'string');
    }

    function _checkIsRequired($el, options) {
        if ($.trim($el.val()).length) {
            _applySuccessSettings($el, options);
        } else {
            _applyErrorSettings($el, options);
        }
    }

    function _applySuccessSettings($el, options) {
        if (_isjQuery(options.sucessTarget)) {
            options.sucessTarget.addClass(options.successClass);
        } else {
            $el.addClass(options.successClass);
        }
        if (_isjQuery(options.sucessMessageTarget)) {
            if (options.sucessMessage) {
                $el.sucessMessageTarget.text(options.sucessMessage);
            }
        }
    }

    function _applyErrorSettings($el, options) {
        if (_isjQuery(options.errorTarget)) {
            options.errorTarget.addClass(options.errorClass);
        } else {
            $el.addClass(options.errorClass);
        }

        if (_isjQuery(options.errorMessageTarget)) {
            if (options.errorMessage) {
                $el.errorMessageTarget.text(options.errorMessage);
            }
        }

    }

    function _isjQuery(obj) {
        return (obj && (obj instanceof window.jQuery || obj.constructor.prototype.jquery));
    }

    $.fn.extend({
        validate: function (options) {
            options = $.extend({}, $.validate.defaults, options);

            this.each(function () {
                new $.validate($(this), options);
            });
            return this;
        }
    });

    $.validate = function ($el, options) {
        if (!$el.is(':input')) return;
        if (!_isValidOptions(options)) return;
        $.each(options.rules, function (index, rule) {
            if (rule.isRequired === true) {
                _checkIsRequired($el, options);
            }
        });
    };

    $.validate.defaults = {
        successClass: '.validator-success',
        sucessTarget: null,
        sucessMessageTarget: null,
        sucessMessage: '',
        errorClass: '.validator-error',
        errorTarget: null,
        errorMessageTarget: null,
        errorMessage: '',
        rules: []
    };
});
