define('ordersDetailCompositionProofsheetHistoryView',[
  'module',
  'app',
  'backbone',
  'marionette',
  'underscore',
  'ordersDetailCompositionProofsheetHistoryItemView',
  'template!ordersDetailCompositionProofsheetHistoryView'
], function (
  module,
  App,
  Backbone,
  Marionette,
  _,
  ItemView,
  Tpl
) {
  'use strict';

  var PrintZonesView = Marionette.CompositeView.extend({
    template: Tpl,
    childView: ItemView,
    className: 'orders-detail-body-composition-proofsheet-history-view',
    childViewContainer: '.items',
    childViewOptions: function() {
      return {
          order: this.model
      };
    },

    modelEvents: {
      'change:proofsheetFileUid': 'fetch',
      'change:proofsheetHistory': 'fetch'
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
    },

    onShow: function() {
      this.fetch();
    },

    fetch: function() {
      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.collection.reset();

      var params = {};
      params.pageSize = 100000;
      params.first = 0;
      params.sidx = 'creatDate';
      params.sord = 'desc';

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('order:history-get', this.model.get('purchaseOrderId'), params),
        success: _.bind(function (items) {
          _.each(items, function(item) {
            if(item.field === 'proofsheetFileUid' && item.value) {
              if(item.value !== this.model.get('proofsheetFileUid'))
                this.collection.add(item);
            }
          }, this);

          if(this.collection.models.length === 0)
            this.$el.hide();
          else
            this.$el.css('display', 'flex');

        }, this)
      });
    }
  });

  module.exports = PrintZonesView;
});
