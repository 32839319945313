define('ordersDetailOrderProductQuantityView',[
  'module',
  'marionette',
  'settings',
  'underscore',
  'modalsAlertView',
  'template!ordersDetailOrderProductQuantityView'
], function (
  Module,
  Marionette,
  Settings,
  _,
  AlertView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-product-quantity-view amount item editable',
    
    ui: {
      quantity: '.quantity',
      unit: '.unit',
      editButton: '.edit-button',
      input: '.edit > input',
      loader: '.loader-2'
    },

    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.quantity': 'onEdit',
      'click @ui.unit': 'onEdit',
      'blur  @ui.input': 'onBlurClick',
      'click @ui.input': 'onInputClick'
    },

    modelEvents: {      
      'change:quantity': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      }                
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },    

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      this.$el.attr('edit', this.edit);

      //-------------------

      if (this.model.get('quantity')) {
        var locale = 'fr-BE';
        var configLocale = Settings.configValue('priceLocale');
        
        if (configLocale)
          locale = configLocale;
  
        this.ui.quantity.html(new Intl.NumberFormat(locale).format(this.model.get('quantity')));

        if(this.options.product.get('minimumPacksPerOrder') &&
            this.options.product.get('minimumPacksPerOrder') > this.model.get('quantity')) {
          this.ui.quantity.css('color', 'red');
          this.ui.unit.css('color', 'red');
        }
        else {
          this.ui.unit.css('color', 'black');
          this.ui.quantity.css('color', 'black');
        }
      } else
        this.ui.quantity.html('-');   
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = !this.edit;     
      this.renderContent();     
      
      if(this.edit) {
        this.ui.input.val(this.model.get('quantity'));
        this.ui.input.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () {
      if(!this.edit)
        return;

      this.ui.loader.show();

      if(parseInt(this.ui.input.val(), 10) !== this.model.get('quantity')) {

        if(this.model.get('validated')) {
          AlertView.prototype.open('', _.i18n('orders.quantity.edit.error.validated'));
          this.edit = false;
          this.renderContent();
          this.ui.loader.hide();
          return;
        }

        this.model.changeQuantity(this.ui.input.val()).done(_.bind(function () {
          this.model.fetch().done(_.bind(function () {
            this.ui.loader.hide();
            this.edit = false;
            this.renderContent();
          }, this));
        }, this));
      } else {
        this.ui.loader.hide();
        this.edit = false;
        this.renderContent();
      }
    }
  });
});
