define('customersPopupEditView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'formView',
  'imageUtils',
  'customerUtils',
  'imageUploadBehavior',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'phoneParserView',
  'buttonView',
  'settings',
  'activitiesUtils',
  'template!customersPopupEditView'
], function (
  Module,
  Marionette,
  Backbone,
  $,
  FormView,
  imageUtils,
  CustomerUtils,
  ImageUploadBehavior,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  PhoneParserView,
  ButtonView,
  Settings,
  ActivitiesUtils,
  Tpl
) {
  'use strict';

  Module.exports = FormView.extend({
    template: Tpl,
    className: 'customers-popup-edit-view',
    ui: {
      logo: '.js-logo-holder',
      logoDiv: 'div.js-logo-holder',
      imgBlock: 'div.profile-img-block',
      changeLogo: '.js-change-logo',
      removeLogo: '.js-remove-logo',
      input: '.js-info-input',
      inputDelivery: '.js-info-input-delivery',
      endUserIdInput: '.js-end-user-id',
      vatArea: '.vat-area',
      subjectToVat: '#subjectToVat',
      vatInput: '#vat-input',
      siretInput: '.siret-input',
      langlabel: '.lang-label',
      subscribed: '#subscribed',
      vip: '#vip',
      invalidVat: '.invalid-vat-number',
      validVat: '.valid-vat-number',
      vatServerNotReachable: '.vat-server-not-reachable',
      checkingVatNumber: '.checking-vat-number',
      mainActivityInput: '.js-activity-input',
      subActivityInput: '.js-sub-activity-input',
      subActivityAutocomplete: '.sub-activity-autocomplete',
      kvkContainer: '.kvk-ctn',
      kvkInput: '.kvk-ctn input',
      siretContainer: '.siret-ctn',
      vatFromSiret: '.btn-vat-from-siret',
      deliveryName: '.delivery-name input',
      deliveryFirstName: '.delivery-firstname input',
      deliveryLastName: '.delivery-lastname input',
      deliveryAddressL1: '.delivery-addressL1 input',
      deliveryAddressL2: '.delivery-addressL2 input',
      deliveryHouseNumber: '.delivery-houseNumber input',
      deliveryZip: '.delivery-zip input',
      deliveryCity: '.delivery-city input',
      deliveryCountryCode: '.delivery-country-code',
      loader: '.footer .loader-2',
      error: '.error',
      errorMessage: '.error .message',
      phoneNumber: '.phone-number input',
      mobilePhoneNumber: '.mobile-phone-number input'
    },

    events: {
      'click @ui.removeLogo': 'onRemoveLogo',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'change @ui.inputDelivery': 'onInputDeliveryChange',
      'change @ui.subjectToVat': 'onChangeSubjectToVat',
      'change @ui.subscribed': 'onChangeSubscribed',
      'change @ui.vip': 'onChangeVip',
      'input @ui.kvkInput': 'onKvkInputChange',
      'click .dropdown-menu-lang a': 'onChangeLanguage',
      'click .js-copy-address': 'onCopyInvoiceAddress',
      'change @ui.vatInput': 'onChangeVAT',
      'paste @ui.vatInput': 'onPasteVAT',
      'paste @ui.phoneNumber': 'onPhoneNumberParse',
      'paste @ui.mobilePhoneNumber': 'onMobilePhoneNumberParse',
      'click .btn-vat-from-siret': 'onVatFromSiretClick',
      'change @ui.subActivityInput': 'onSubActivityChange'
    },

    regions: {
      selectCountries: '.js-select-countries-region',
      selectCountriesDelivery: '.js-select-countries-delivery-region',
      confirmButtonRegion: '.confirm-button-region',
      cancelButtonRegion: '.cancel-button-region'
    },

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      },
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    initialize: function (options) {
      this.model = App.request('customer:model', options.customerModel.toJSON());
      //this.deliveryModel = new Backbone.Model(this.model.get('refDeliveryAddress'));

      this.fieldsToValidate = [{name: 'endUserId', type: 'required'}];

      if (Settings.isMultiWebshop()) {
        this.fieldsToValidate.push({name: 'webShopLocale', type: 'required'});
      }
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;

      //-------------------
      if (Settings.isMultiWebshop()) {
        templateData.webshops = Settings.getWebshopLocales();
      } else {
        templateData.webshops = null;
      }

      //-------------------
      templateData.mainActivities = [];
      for (var prop in ActivitiesUtils.mainActivity) {
        templateData.mainActivities.push({
          code: prop,
          value: ActivitiesUtils.mainActivity[prop]
        });
      }
      templateData.mainActivities = _.sortBy(templateData.mainActivities, 'value');

      //-------------------
      templateData.showPaymentTerms = false;
      if (Settings.configValue('orders.newInvoicePaymentTerms')) {
        templateData.showPaymentTerms = true;
      }

      return templateData;
    },

    onShow: function () {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.getRegion('selectCountries').show(countriesView);


      var countriesViewDelivery = new AutocompleteView(
        this._getCountriesDeliveryAutocompleteOptions()
      );
      this.getRegion('selectCountriesDelivery').show(countriesViewDelivery);

      this.displayLogo(this.model.get('logoUid'), this.model.get('code'));

      this.ui.vatArea.toggleClass('disabled', this.model.get('subjectToVat'));

      var subjectToVat = this.model.get('subjectToVat') || false;
      this.ui.vatArea.toggleClass('hidden', !subjectToVat);
      this.ui.subjectToVat.prop('checked', subjectToVat);

      var newLang = this.model.get('locales') || 'None';
      this.ui.langlabel.text(newLang);

      var subscribed = this.model.get('subscribed') || true;
      this.ui.subscribed.prop('checked', subscribed);

      var vip = false;

      if(this.model.get('vip'))
        vip = this.model.get('vip');
      this.ui.vip.prop('checked', vip);

      $.material.init();

      this.initMail();
      this.initInvoiceMail();
      if (this.model.get('subjectToVat') &&
        (!this.model.get('vatNumberChecked') || !this.model.get('vatNumberValid'))) {
        this.checkVat(this.model.get('vatNumber'));
      }

      //this.onSubActivityChange();
      var subActivities = [];
      for (var prop in ActivitiesUtils.subActivity) {
        subActivities.push(ActivitiesUtils.subActivity[prop]);
      }
      this.ui.subActivityInput.autocomplete({
        source: _.sortBy(subActivities),
        appendTo: this.ui.subActivityAutocomplete
      });

      //-------------------
      this.cancelButtonView = new ButtonView({
        type: 'cancel',
        text: _.i18n('common.cancel'),
        title: _.i18n('common.cancel')
      });
      this.getRegion('cancelButtonRegion').show(this.cancelButtonView);
      this.cancelButtonView.on('click', _.bind(this.onCancel, this));

      //-------------------
      this.confirmButtonView = new ButtonView({
        type: 'confirm',
        text: _.i18n('common.confirm'),
        title: _.i18n('common.confirm')
      });
      this.getRegion('confirmButtonRegion').show(this.confirmButtonView);
      this.confirmButtonView.on('click', _.bind(this.onConfirm, this));
    },

    onRender: function () {
      this.ui.kvkContainer.css('display', 'none');
      this.ui.siretContainer.css('display', 'none');
      this.ui.vatFromSiret.css('display', 'none');
      this.ui.loader.hide();

      if (Settings.configValue('customer.profile.showKvk')) {
        this.ui.kvkContainer.css('display', 'flex');
      }

      if (Settings.configValue('customer.profile.showSiret')) {
        this.ui.siretContainer.css('display', 'flex');
        this.ui.vatFromSiret.css('display', 'flex');
      }

      //-------------------


    },

    onCancel: function () {
      this.windowView.close();
    },

    onCopyInvoiceAddress: function () {
      var delAddress = this.model.get('refDeliveryAddress');
      delAddress.name = this.model.get('name');
      delAddress.firstName = this.model.get('firstName');
      delAddress.lastName = this.model.get('lastName');
      delAddress.addressL1 = this.model.get('addressL1');
      delAddress.addressL2 = this.model.get('addressL2');
      delAddress.houseNumber = this.model.get('houseNumber');
      delAddress.zip = this.model.get('zip');
      delAddress.city = this.model.get('city');
      delAddress.countryCode = this.model.get('countryCode');

      this.ui.deliveryName.val(delAddress.name);
      this.ui.deliveryFirstName.val(delAddress.firstName);
      this.ui.deliveryLastName.val(delAddress.lastName);
      this.ui.deliveryAddressL1.val(delAddress.addressL1);
      this.ui.deliveryAddressL2.val(delAddress.addressL2);
      this.ui.deliveryHouseNumber.val(delAddress.houseNumber);
      this.ui.deliveryZip.val(delAddress.zip);
      this.ui.deliveryCity.val(delAddress.city);
      this.ui.deliveryCountryCode.val(delAddress.countryCode);

      var countriesViewDelivery = new AutocompleteView(
        this._getCountriesDeliveryAutocompleteOptions()
      );
      this.getRegion('selectCountriesDelivery').show(countriesViewDelivery);
    },

    onSubActivityChange: function () {
      var selectedMainActivity = this.ui.subActivityInput.val();
      if (selectedMainActivity) {
        var subActivityClass;
        for (var prop in ActivitiesUtils.subActivity) {
          if (ActivitiesUtils.subActivity[prop] === selectedMainActivity) {
            subActivityClass = prop;
          }
        }
        if (subActivityClass) {
          for (var prop1 in ActivitiesUtils.mainActivity) {
            if (prop1 === subActivityClass.split('.')[0]) {
              this.ui.mainActivityInput.val('');
              this.ui.mainActivityInput.find('option[data-class="' + prop1 + '"]').prop('selected', true).change();
            }
          }
          this.triggerMethod('enable:cancel:confirm');
        } else {
          this.ui.subActivityInput.val('').change();
        }
      }
    },

    /*jshint -W061 */
    onVatFromSiretClick: function () {
      var vat = this.model.siretToVat(this.ui.siretInput.val());
      this.ui.vatInput.val(vat);
      this.ui.vatInput.change();
    },

    onInputDeliveryChange: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val(),
        fieldName = $target.data('field-name');

      this.onChangeDelivery(fieldName, value);
    },

    onChangeDelivery: function (fieldName, value) {
      //this.deliveryModel.set(fieldName, value);
      this.model.get('refDeliveryAddress')[fieldName] = value;
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeVAT: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val();
      this.ui.vatInput.val(value);
      this.ui.vatInput.change();
      this.checkVat(value);
    },

    onPasteVAT: function (e) {
      e.preventDefault();
      var value = (e.originalEvent.clipboardData || window.clipboardData).getData('text');
      value = value.replace(/[\s.]/g, '');
      this.ui.vatInput.val(value);
      this.ui.vatInput.change();
      this.checkVat(value);
    },

    onPhoneNumberParse: function (e) {
      e.preventDefault();
      var value = (e.originalEvent.clipboardData || window.clipboardData).getData('text');

      PhoneParserView.prototype.parse({
        number: value,
        countryCode: this.model.get('countryCode'),
        onParsed: _.bind(function (value) {
          this.ui.phoneNumber.val(value);
        }, this)
      });
    },

    onMobilePhoneNumberParse: function (e) {
      e.preventDefault();
      var value = (e.originalEvent.clipboardData || window.clipboardData).getData('text');

      PhoneParserView.prototype.parse({
        number: value,
        countryCode: this.model.get('countryCode'),
        onParsed: _.bind(function (value) {
          this.ui.mobilePhoneNumber.val(value);
        }, this)
      });
    },

    checkVat: function (value) {

      CustomerUtils.checkVat(value).done(_.bind(function (valid) {
        if (valid) {
          this.model.set('vatNumberChecked', true);
          this.model.set('vatNumberValid', true);
          this.ui.vatServerNotReachable.attr('style', 'display:none');
          this.ui.validVat.attr('style', 'display:inline;color:green');
          this.ui.invalidVat.attr('style', 'display:none');
          this.triggerMethod('enable:cancel:confirm');

        } else {
          this.ui.invalidVat.attr('style', 'display:inline;color:orange');
          this.ui.validVat.attr('style', 'display:none;color:green');
          this.model.set('vatNumberChecked', true);
          this.model.set('vatNumberValid', false);
          this.ui.vatServerNotReachable.attr('style', 'display:none');
        }
      }, this)).fail(_.bind(function () {
        bootbox.alert(_.i18n('vat.checkVatError'));
      }, this));


      /*var that = this;
      if (!value.match('^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$')) {
        this.ui.invalidVat.attr('style', 'display:inline;color:orange');
      } else {
        var url = Settings.url('compuzz2', 'v2/vatservice/checkVatNumber?countryCode=' +
          value.substr(0, 2) +
          '&VATNumber=' + value.substr(2, value.length));
        this.ui.checkingVatNumber.attr('style', 'display:inline;color:color:#85ac85;');

        $('.checkingVatNumber').fadeOut(500).fadeIn(500);
        $.ajax({
          type: 'GET',
          url: url,
          success: function (resp) {
            if (resp === false) {
              that.ui.invalidVat.attr('style', 'display:inline;color:orange');
              that.ui.validVat.attr('style', 'display:none;color:green');
              that.model.set('vatNumberChecked', true);
              that.model.set('vatNumberValid', false);
              that.ui.vatServerNotReachable.attr('style', 'display:none');
            } else {
              that.model.set('vatNumberChecked', true);
              that.model.set('vatNumberValid', true);
              that.ui.vatServerNotReachable.attr('style', 'display:none');
              that.ui.validVat.attr('style', 'display:inline;color:green');
              that.ui.invalidVat.attr('style', 'display:none');
              that.triggerMethod('enable:cancel:confirm');

            }
            that.ui.checkingVatNumber.attr('style', 'display:none');
          },
          error: function () {
            that.ui.vatServerNotReachable.attr('style', 'display:inline;color:orange');
            that.ui.validVat.attr('style', 'display:none;color:green');
            that.ui.checkingVatNumber.attr('style', 'display:none');
          }
        });
      }*/
    },

    initMail: function () {
      // these variables are used to fix email input field appearance
      var value = this.$('#emailInput').val(),
        customerValue = this.$('#CustomerEmailInput').val(),
        emailTokenCount = value ? value.split(';').length : 0,
        customerEmailTokenCount = customerValue ? customerValue.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="email"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#emailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        emailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="email"]').off('focusin').focusin(function () {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function () {
        if ($(this).find('#emailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });

      this.$('#CustomerEmailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        customerEmailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          customerEmailTokenCount--;
        }, this));
    },

    initInvoiceMail: function () {
      // these variables are used to fix email input field appearance
      var value = this.$('#InvoiceEmailInput').val(),
        emailTokenCount = value ? value.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="invoiceEmail"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#invoiceEmailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        emailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="invoiceEmail"]').off('focusin').focusin(function () {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function () {
        if ($(this).find('#emailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });

      /*this.$('#CustomerEmailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        customerEmailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          customerEmailTokenCount--;
        }, this));*/
    },

    onChangeLanguage: function (e) {
      e.preventDefault();
      var newLang = $(e.target).text();
      this.model.set('locales', newLang);
      this.ui.langlabel.text(newLang);
      this.triggerMethod('enable:cancel:confirm');
    },

    onTokenFieldInputChange: function (e) {
      this.onInputChange(e);
      this.triggerMethod('enable:cancel:confirm');
    },

    onKvkInputChange: function () {
      var value = this.ui.kvkInput.val();

      var cleanKVKNumber = value.replace(/[^\d]/g, '');
      this.ui.kvkInput.val(cleanKVKNumber);

      var kvkRegex = /^\d{8}$/;
      if (!kvkRegex.test(cleanKVKNumber))
        this.ui.kvkContainer.addClass('invalid');
      else
        this.ui.kvkContainer.removeClass('invalid');
    },

    displayLogo: function (logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.removeClass('hidden');
        this.ui.imgBlock.removeClass('hidden');
        this.ui.logo.attr('src', image);
        if (!this.ui.logoDiv.hasClass('hidden')) {
          this.ui.logoDiv.addClass('hidden');
        }
      } else {
        if (remove) {
          this.ui.logo.attr('src', '');
        }
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      }

      if (!this.model.get('logoUid')) {
        this.ui.removeLogo.hide();
      } else {
        this.ui.removeLogo.show();
      }
    },

    onImageUploadConfirm: function (base64Value, fileExt) {
      this.model.set('encodedValue', base64Value);
      this.model.set('extension', fileExt);
      this.ui.logo.removeClass('hidden');
      this.ui.imgBlock.removeClass('hidden');

      if (!base64Value) {
        this.ui.logo.attr('src', imageUtils.getLogo(base64Value, this.model.get('code')));
        this.ui.removeLogo.hide();
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      } else {
        this.ui.logo.attr('src', 'data:image/png;base64,' + base64Value);
        this.ui.removeLogo.show();
      }

      if (!this.ui.logoDiv.hasClass('hidden') && base64Value) {
        this.ui.logoDiv.addClass('hidden');
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onRemoveLogo: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.ui.changeLogo.val('');
          this.model.set('logoUid', null);
          this.onImageUploadConfirm(null, null);
        }
      }, this));
    },

    onConfirm: function () {
      if (this.options.newItem === true) {
        this.fieldsToValidate = [{name: 'endUserId', type: 'required'}];
        if (Settings.isMultiWebshop()) {
          this.fieldsToValidate.push({name: 'webShopLocale', type: 'required'});
        }
      }

      this.checkVat(this.model.get('vatNumber'));

      this.ui.error.hide();

      this.model.set('tel', this.ui.phoneNumber.val());
      this.model.set('mobile', this.ui.mobilePhoneNumber.val());

      if (this.validate(this.fieldsToValidate)) {
        this.ui.loader.css('display', 'flex');
        this.model.save();
      }
    },

    onChangeSubscribed: function () {
      this.model.set('subscribed', this.ui.subscribed.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeVip: function () {
      this.model.set('vip', this.ui.vip.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeSubjectToVat: function () {
      this.model.set('subjectToVat', this.ui.subjectToVat.is(':checked'));
      this.ui.vatArea.toggleClass('hidden', !(this.ui.subjectToVat.is(':checked')));

      if (this.model.get('subjectToVat') === false) {
        this.model.set('vatNumber', '');
        this.model.set('vatNumberValid', false);
        this.model.set('vatNumberChecked', false);
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onChangePriceList: function (model) {
      this.model.set('priceListCode', model.get('code'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onSave: function (model) {
      var modelJSON = model.toJSON();
      this.options.customerModel.set(modelJSON);
      this.options.customerModel.trigger('change', this.options.customerModel);
      this.options.customerModel.trigger('change:refDeliveryAddress', this.options.customerModel);

      //this.options.customerModel.set('refDeliveryAddress', this.deliveryModel.toJSON());

      this.options.customerModel.trigger('change', this.options.customerModel);
      this.triggerMethod('saved');
      App.trigger('customer:header:rerender', modelJSON);

      if (this.options.onUserSelected)
        this.options.onUserSelected(this.model);
      else
        App.navigate('customers/' + model.get('secId'), {trigger: true});

      this.ui.loader.hide();
      this.windowView.close();
    },

    onSaveError: function (model, response) {
      this.ui.loader.hide();
      if (response.status === 409) {
        this.ui.errorMessage.html(_.i18n('endUser.duplicatedEndUserIdError'));
        this.ui.error.css('display', 'flex');
      }
    },

    _getCountriesAutocompleteOptions: function () {
      var countryCode, countryValue, country;

      countryCode = this.model.get('countryCode') || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    _getCountriesDeliveryAutocompleteOptions: function () {
      var countryCode, countryValue, country;

      countryCode = this.model.get('refDeliveryAddress').countryCode || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountryDelivery, this)
      };
    },

    onChangeCountryDelivery: function (fieldName, model) {
      var code = model ? model.get('code') : '';
      this.onChangeDelivery(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeCountry: function (fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onBeforeDestroy: function () {
      this.$('#emailInput').tokenfield('destroy');
      this.$('#CustomerEmailInput').tokenfield('destroy');
    }
  });
});
