define('graphicalRequestKanbanAssignPopupView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'template!graphicalRequestKanbanAssignPopupViewTpl',
  'template!graphicalRequestKanbanAssignAutocompleteTpl',
  'template!graphicalRequestKanbanAssignUserFaceTpl',
  'dialogFormView',
  'autocompleteView'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  Settings,
  viewTpl,
  autocompleteTpl,
  faceTpl,
  DialogFormView,
  AutocompleteView
) {
  'use strict';

  var FaceView = Marionette.ItemView.extend({
    template: faceTpl,

    serializeData: function() {
      return {
        fileUrl: this.options.logo
      };
    }
  });

  var AssignPopupView = DialogFormView.extend({
    template: viewTpl,

    events: {
      'click @ui.assignToMe': 'onAssignToMe',
      'click @ui.unassignedBtn': 'onUnAssign'
    },

    ui: {
      assignToMe: '.js-assign-to-me',
      unassigned: '.unassigned-container',
      unassignedBtn: '.js-unassign-ticket'
    },

    regions: {
        selectUsers: '.js-select-users-region',
        userface: '.js-userface-region'
    },

    onShow: function() {
      $.material.init();
      this.usersView = new AutocompleteView(
          this._getUsersAutocompleteOptions()
      );      
      this._renderSelectedFace();
      this.getRegion('selectUsers').show(this.usersView);
    },

    _renderSelectedFace: function(logo) {
      var assignedUser = _.findWhere(
        this.options.users, 
        {logonId: this.options.assignedUser}
      );

      if (this.faceView) {
        this.faceView.destroy();
      }
      if (!logo) {
        logo = assignedUser ? assignedUser.logo : null;
      }
      this.faceView = new FaceView({
        logo: logo && logo !=='icon' ? Settings.url('file', logo, {size: 'thumbnail'}) : null
      });
      this.getRegion('userface').show(this.faceView);
    },

    _getUsersAutocompleteOptions: function() {
      var assignedUser = this.options.assignedUser ? this.options.assignedUser : null,        
        autocompleteUser = _.findWhere(this.options.users, {logonId: assignedUser});

      return {
        data: this.options.users,
        valueKey: 'fullName',
        apiKey: 'secId',
        limit: this.options.users.length,
        placeholder: 'Unassigned',
        name: 'user',
        value: autocompleteUser ? this._getUserFullName(autocompleteUser) : null,
        callback: _.bind(this._onChangeUser, this),
        childTemplate: autocompleteTpl,
        childViewSerializeData: this._autocompleteViewSerializeData
      };
    },

    _getUserFullName: function(_user) {
      if (_user.firstName && _user.lastName) {
        return _user.firstName + ' ' + _user.lastName;
      } else if (_user.firstName || _user.lastName) {
        return _user.firstName ? _user.firstName : _user.lastName;
      } else if (_user.nickName) {
        return _user.nickName;
      } else {
        return _user.logonId;
      }
    },

    _autocompleteViewSerializeData: function() {
      return {
        fileUrl: this.model.get('logo') ? Settings.url('file', this.model.get('logo'), {size: 'thumbnail'}) : null,
        name: this.model.get('fullName')
      };
    },

    _onChangeUser: function(fieldName, user) {
      this._renderSelectedFace(user.get('logo') ? user.get('logo'): 'icon');
      this.selectedUser = user;
    },

    onAssignToMe: function() {
      var _assignedUser = this.options.assignedUser,
        _currentUserLogonId = Settings.get('currentUser').logonId,
        _currentUserName = this._getUserFullName(Settings.get('currentUser'));

      if (_assignedUser === _currentUserLogonId) {
        if (!this.selectedUser) {
          return;
        } else if (_assignedUser === this.selectedUser.get('logonId')) {
          return;
        }
      }
      var currentSecId = Settings.get('currentUser').secId,
        currentUser = _.findWhere(this.options.users, {secId: currentSecId});

      this.usersView.trigger('autocomplete:selected', new Backbone.Model(currentUser));
      this.usersView.$el.find('input.autocomplete').val(_currentUserName);
    },

    onUnAssign: function() {
      this.usersView.trigger('autocomplete:selected', new Backbone.Model({}));
      this.usersView.$el.find('input.autocomplete').val(null);
    }
  });

  module.exports = AssignPopupView;
});
