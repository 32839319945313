
define('template!ordersDetailPriceV2PricesView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12 p-t-15">\n  <div class="table-responsive">\n    <table class="table table-price">\n      <thead>\n      <tr>\n        <th colspan="10">Buying prices\n          <i class="fa fa-pencil p-l-15 btn-custom-scales clickable" title="' +
((__t = ( _.i18n('price.setCustomScales') )) == null ? '' : __t) +
'"></i>\n          <i class="fa fa-refresh p-l-15 btn-reset-price clickable" title="' +
((__t = ( _.i18n('price.resetPrice') )) == null ? '' : __t) +
'"></i>\n        </th>\n        <th colspan="3">Sales Prices</th>\n        <th colspan="3">Margin</th>\n      </tr>\n      </thead>\n      <thead>\n      <tr>\n        <th>Amount</th>\n        <th>Unit Price</th>\n        <th>Setup</th>\n        <th>Print</th>\n        <th>Handling</th>\n        <th>Shipping</th>\n        <th>Express Shipping</th>\n        <th>SmallOrderFee</th>\n        <!--<th>Status</th>-->\n        <!--<th>Tot./pc</th>-->\n        <th colspan="2">Total</th>\n        <th>Tot./pc</th>\n        <th colspan="2">Total</th>\n        <th>Tot./pc</th>\n        <th>Total</th>\n        <th>Express Shipping</th>\n        <th colspan="2">Total %</th>\n        <th>Status</th>\n      </tr>\n      </thead>\n      <tbody class="items-region">\n      </tbody>\n      <thead>\n      <tr>\n        <th colspan="4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</th>\n        <th colspan="2">' +
((__t = ( _.i18n('price.addCost') )) == null ? '' : __t) +
'</th>\n      </tr>\n      </thead>\n      <tbody class="add-cost-body">\n      <tr>\n        <th colspan="4" style="position: relative;"><input type="text" class="form-control floating-label add-cost-desc"\n                                                           placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'"\n                                                           value="' +
((__t = ( addDesc )) == null ? '' : __t) +
'"></th>\n        <th colspan="2"><input type="number" class="form-control floating-label add-cost"\n                               placeholder="' +
((__t = ( _.i18n('price.addCost') )) == null ? '' : __t) +
'" value="' +
((__t = ( addCost )) == null ? '' : __t) +
'"></th>\n        <th style="border: 0px;">\n          <button class="btn btn-save-price btn-primary" style="display: none; position: absolute; right: 10px;">' +
((__t = (
            _.i18n('common.save') )) == null ? '' : __t) +
'\n          </button>\n        </th>\n      </tr>\n      <tr>\n        <th colspan="2">\n          <button class="btn btn-express-delivery btn-primary">' +
((__t = ( expressShippingButtonText )) == null ? '' : __t) +
'</button>\n        </th>\n      </tr>\n      ';
 if(expressDeliveryCost > 0) { ;
__p += '\n        <tr>\n          <td colspan=5>\n           <b>' +
((__t = ( _.i18n('order.expressDelivery') + ': €' + expressDeliveryCost )) == null ? '' : __t) +
' </b>\n          </td>\n        </tr>\n      ';
 } ;
__p += '\n      </tbody>\n    </table>\n  </div>\n</div>\n';

}
return __p
};});

