/**
 * Created by OLD on 02/11/2015.
 */
define('superAdminPopupView',[
  'module',
  'underscore',
  'template!superAdminCreditPopupTpl',
  'template!superAdminAccountPopupTpl',
  'template!superAdminTicketPopupTpl',
  'dialogView',
  'moment',
  'settings',
  'jqueryui'
], function(
  module,
  _,
  creditTpl,
  accountTpl,
  ticketsTpl,
  DialogView,
  moment,
  settings
  ) {
  'use strict';

  var SuperAdminSetParamsPopupView = DialogView.extend({

    ui: {
      dateFrom: '.date-from',
      dateTo: '.date-to',
      date: '.date-superadmin',
      typeSpan: '.filter-by-type-sa',
      statusSpan: '.filter-by-status-sa',
      typeItem: 'li.item-type-sa',
      statusItem: 'li.item-status-sa',
      statusCheckbox: '.js-status-checkbox',
      dateToForm: '.date-to-form',
      validationLabel: '.date-validation-label',
      save: '.save-sa'
    },

    events: {
      'focus @ui.dateTo' : 'clearValidation',      
      'click @ui.typeItem' : 'onFilterType',      
      'click @ui.statusItem' : 'onFilterStatus',
      'click @ui.save' : 'onSendRequest',
      'click .js-clear-filters': 'onClearFilters'
    },

    getTemplate: function() {
      switch(this.popupType || this.options.filters.popupType) {
      case 'superadmin:stats-credit-graph':
        return creditTpl;
      case 'superadmin:stats-account-graph':
        return accountTpl;
      case 'superadmin:stats-tickets-graph' :
        return ticketsTpl;
      default:
        throw 'Unsupported popupType: ' + this.popupType || this.options.filters.popupType;
      }
    },

    serializeData: function() {
      var templateData = {},
        filters = this.options.filters;

      templateData.dateFrom = moment(parseInt(filters.dateFrom, 10)).format('DD/MM/YYYY');
      templateData.dateTo = moment(parseInt(filters.dateTo, 10)).format('DD/MM/YYYY');
      templateData.statusData = this.options.statusData;
      templateData.filters = filters;

      return templateData;
    },
    
    onRender: function() {
      var that = this;

      moment.locale(settings.get('lang'));
      $.material.init();
      this.filters = this.options.filters;

      this.ui.date.datepicker({
        autoSize: true,
        dateFormat: 'dd/mm/yy',
        dayNamesMin: moment.weekdaysShort(),
        firstDay: 1,
        maxDate: 0,
        prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
        nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>',
        beforeShow: function() {
          $('#ui-datepicker-div')
            .removeClass('sa-datepicker');
          $('#ui-datepicker-div')
            .addClass('sa-datepicker');
        },
        onSelect: function() {
          that.onValidate();
        }
      });
      this.ui.dateFrom.datepicker({
        setDate: this.options.filters.dateFrom
      });
    },

    onValidate: function() {
      var dFrom = parseInt(moment(this.ui.dateFrom.val(), 'DD/MM/YYYY').format('x'), 10),
        dTo = parseInt(moment(this.ui.dateTo.val(), 'DD/MM/YYYY').format('x'), 10);

      if (dTo - dFrom <= 0) {
        this.ui.dateToForm.addClass('has-error');
        this.ui.validationLabel.text(_.i18n('common.dateToLessDateFrom'));
      } else {
        this.clearValidation();
        this.setDateData();
      }
    },

    onFilterType: function(e) {
      var type = $(e.currentTarget).attr('data-type'),
        typeVal = $(e.currentTarget).text();

      this.ui.typeSpan.html(typeVal);
      this.ui.typeSpan.attr('data-type', type);
      this.onValidate();
    },

    onFilterStatus: function(e) {
      var that = this,
        target = $(e.currentTarget),
        status = target.attr('data-type');

      e.stopPropagation();
      this.clearStatusFilters();
      
      _.each(this.ui.statusCheckbox, function(checkbox) {
        var checkboxStatus = $(checkbox).data('type');
        
        if (status === 'ALL') {
          $(checkbox).prop('checked', true);
          that.setStatusFilters(checkboxStatus);
        } else {
          if ($(checkbox).is(':checked')) {
            that.setStatusFilters(checkboxStatus);
          }
        }
      });

    },

    setStatusFilters: function(status) {
      this.filters.statusFilters.push(status);
    },

    clearStatusFilters: function() {
      this.filters.statusFilters = [];
    },

    clearValidation: function() {
      this.ui.dateToForm.removeClass('has-error');
      this.ui.validationLabel.text('');
    },

    setDateData: function() {
      this.filters.dateFrom = parseInt(moment(this.ui.dateFrom.val(), 'DD/MM/YYYY').format('x'), 10);
      this.filters.dateTo = parseInt(moment(this.ui.dateTo.val(), 'DD/MM/YYYY').format('x'), 10);
      this.filters.type = this.ui.typeSpan.attr('data-type');
    },

    onSendRequest: function() {
      this.trigger('superadmin:change-date', this.filters);
    },

    onClearFilters: function() {
      this.trigger('clear:filters', this.filters);
    }
  });


  module.exports = SuperAdminSetParamsPopupView;
});
