define('customersPopupSelectResultView',[
  'module', 
  'underscore',
  'tableFieldView',
  'template!customersPopupSelectResultView'
], function (
  Module,
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'select-customer-result-view',

    ui: {
      companyName: '.company-name',
      contact: '.contact',
      name: '.name',
      email: '.email'
    },

    events: {
      'click': 'onSelectCustomer'
    },

    onRender: function() {
            
      if(this.model.get('companyName')) {
        this.ui.companyName.html(this.model.get('companyName'));
        this.ui.companyName.show();            
      }        

      var name = '';
      if(this.model.get('firstName'))
      name = this.model.get('firstName');

      if(this.model.get('lastName')) {
        if(name !== '')
          name += ' ';
        name += this.model.get('lastName');
      }

      if(name === '') {
        if(this.model.get('endUserId'))
        name = this.model.get('endUserId');
      }

      if(name === '') {
        if(this.model.get('email'))
          name = this.model.get('email');
      }
      
      if(name !== '') {
        this.ui.name.html(name);
        this.ui.email.css('display', 'flex');
      } else {
        this.ui.name.hide();
      }    

      //-----------
           
      var email = '';
      if(this.model.get('email'))
        email = this.model.get('email');

      if(email !== '' && email !== name) {
        this.ui.email.html(email);
        this.ui.email.css('display', 'flex');
      } else {
        this.ui.email.hide();
      }      
    },

    onSelectCustomer: function() {
      this.options.tableView.options.onUserSelected(this.model);
    }
  });
});
