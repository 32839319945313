/**
 * Created by bfr on 13/10/2021.
 */
define('tasksCreatorBehavior',[
  'app',
  'module',
  'underscore',
  'jquery',
  'marionette',
  'tasksEditView',
  'entities/admin/tasks',
  'entities/kanban_tickets'
], function (
  App,
  module,
  _,
  $,
  Marionette,
  EditView
) {
  'use strict';

  module.exports = Marionette.Behavior.extend({

    events: {
      'click .create-task': 'createTask'
    },

    ui: {},

    editTask: function (model) {
      this.showEditPopup(model);
    },

    createTask: function () {
      var model = App.request('tasks:model');

      if (this.options.refCommandLine) {
        model.set('refCommandLine', {
          purchaseOrderId: this.options.refCommandLine.get('purchaseOrderId'),
          endUserId: this.options.refCommandLine.get('endUserId')
        });
      }

      if (this.options.refEndUser) {
        model.set('refEndUser', {
          secId: this.options.refEndUser.get('secId'),
          code: this.options.refEndUser.get('code'),
          endUserId: this.options.refEndUser.get('endUserId')
        });
      }

      if (this.options.refProduct) {
        model.set('refProduct', {
          secId: this.options.refProduct.get('secId'),
          cCode: this.options.refProduct.get('cCode'),
          code: this.options.refProduct.get('code'),
          name: this.options.refProduct.get('name')
        });
      }

      this.showEditPopup(model);
    },

    /*loadUsersAndOpenPopup: function (model) {
      if (_.isEmpty(this.users) || !this.users) {
        $.when(App.request('kanban-tickets:get-users'))
          .done(_.bind(function (_users) {
            this.users = UserUtils._parseUsers(_users);
            this.showEditPopup(model, this.users);
          }, this));
      } else {
        this.showEditPopup(model, this.users);
      }
    },*/

    showEditPopup: function (model) {
      EditView.prototype.open({
        model: model,
        newTask: true
      });
    }
  });
});
