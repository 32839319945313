define('pimProductDetailImageView',[
  'module',
  'marionette',
  'underscore',
  'template!pimProductDetailImageView'
], function (
  Module, 
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'pim-products-details-image-view',

    serializeData: function () {
      var data = {};
        data.src = '';

        if(this.model.get('imageUrl'))
          data.src = this.model.get('imageUrl');

      return data;
    },
  
    onRender: function () {
      if(this.model.get('color')) {
        this.$el.css('background-color', this.model.get('color'));
        this.$el.attr('title', this.model.get('name'));
      }
    }
  });
});
