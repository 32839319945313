define('adminGlossariesListCodeView',[
  'module',
  'underscore',
  'tableFieldTextEditView'
], function (
  Module,
  _,
  TableFieldTextEditView
) {
  'use strict';

  Module.exports = TableFieldTextEditView.extend({
    className: 'admin-glossaries-list-code-view table-field-view',

    setValue: function(value) {
      var defer = $.Deferred();

      if(this.model.get('code') !== value) {
        this.model.set('code', value);
        this.model.save()
            .done(_.bind(function() {
          defer.resolve();
        }, this))
            .fail(_.bind(function() {
          defer.reject();
        }, this));
      } else
        defer.resolve();

      return defer.promise();
    }
  });
});
