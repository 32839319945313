
define('template!pimProductPriceScaleTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-1 amount">\n    <div class="col">\n      ';
 if(!useFactorV2) { ;
__p += '\n      <input type="text" class="txt-quantity" style="display: none;" value="' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'"/>\n      <span class="lbl-quantity">' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'</span>\n      ';
 } else { ;
__p += '\n      <div class="col">' +
((__t = ( factorModel.quantity )) == null ? '' : __t) +
'</div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n  ';
 if(!useFactorV2) { ;
__p += '\n  <div class="col-xs-1 price">\n    <div class="col">' +
((__t = ( Number(price).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  ';
 } ;
__p += '\n  <div class="col-xs-1 price edit">\n    <div class="col">\n      ';
 if(!useFactorV2) { ;
__p += '\n      <input type="text" class="txt-sprice" style="display: none;" value="' +
((__t = ( sPrice )) == null ? '' : __t) +
'"/>\n      <span class="lbl-sprice">' +
((__t = ( sPrice )) == null ? '' : __t) +
'</span>\n      ';
 } else { ;
__p += '\n      <div class="col">' +
((__t = ( sPrice )) == null ? '' : __t) +
'</div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n  <div class="col-xs-1 factor">\n    <div class="col">' +
((__t = ( Number(factorModel.factor).toFixed(3) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 imprint">\n    <div class="col">' +
((__t = ( Number(print.unit).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 handling">\n    <div class="col">' +
((__t = ( Number(print.handling).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 setup">\n    <div class="col">' +
((__t = ( Number(print.setup).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 smOrdFee">\n    <div class="col">' +
((__t = ( Number(smallOrderFee).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 shipping">\n    <div class="col">' +
((__t = ( Number(shippingCost).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 total">\n    <div class="col">' +
((__t = ( Number(sTotal).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 total-unit">\n    <div class="col">' +
((__t = ( Number(totalUnit).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 marginPercent">\n    <div class="col">' +
((__t = ( Number(percentMargin).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="col-xs-1 marginEuro">\n    <div class="col"> ' +
((__t = ( Number(margin).toFixed(2) )) == null ? '' : __t) +
'</div>\n  </div>\n  ';
 if(!useFactorV2) { ;
__p += '\n  <div class="col-xs-1 delete">\n    <div class="col">\n      <i class="mdi-action-delete btn-remove-scale clickable"></i></div>\n  </div>\n  ';
 } else { ;
__p += '\n  <div class="col-xs-1 p-l-0">\n    <div class="col">' +
((__t = ( total )) == null ? '' : __t) +
'</div>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

