define('ordersListStatusView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'ordersController',
  'template!ordersListStatusView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-status-view table-field-view',

    regions: {
      'selectRegion': '.select-region'
    },

    modelEvents: {
      'change:status': 'setSelected'
    },

    onRender: function () {
      var collection = new Backbone.Collection();
      this.tableView.getStatuses().done(_.bind(function (statuses) {
        _.each(statuses.models, _.bind(function (status) {
          var model = new Backbone.Model({
            id: status.get('statusConst'),
            value: status.get('status')
          });
          collection.add(model);
        }, this));
      }, this));

      this.selectView = new SelectView({
        collection: collection
      });
      this.selectRegion.show(this.selectView);
      this.selectView.on('change', _.bind(this.onSelectChange, this));

      this.setSelected();
    },

    setSelected: function () {
      this.selectView.setSelected(this.model.get('status'));
    },

    onSelectChange: function (model) {
      this.model.updateStatus(model.get('id'))
      .done(_.bind(function () {
        if(this.tableView.filtersView.model.get('status') !== '' &&
            this.tableView.filtersView.model.get('status') !== model.get('id')) {
          this.tableView.bodyView.collection.remove(this.rowView.model);
          this.tableView.footerView.fetch();
        } else
          this.model.fetch();

        //--------------------
        try {
          App.request('orders:statuses', this.tableView.options.type).done(_.bind(function (statuses) {
            var status = null;
            _.each(statuses.models, _.bind(function (item) {
              if(item.get('statusConst') === model.get('id'))
                status = item;
            }, this));

            if(status.get('params')) {
              var params = JSON.parse(status.get('params'));
              Controller.sendMail([this.model], params);
            }
          }, this));
        } catch (e) {
          console.log(e);
        }
      }, this))
      .fail(_.bind(function () {
        this.setSelected();
      }, this));
    }
  });
});
