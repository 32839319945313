
define('template!ordersAlertView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="body">\n  <div class="icon">\n    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>  </div>\n  <div class="body-content">\n    <div class="text">' +
((__t = ( text )) == null ? '' : __t) +
'</div>\n    ';
 if(reason !== '') { ;
__p += '\n      <div class="reason">' +
((__t = ( reason )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n    <div class="order">\n      ';
 if(companyName !== '') { ;
__p += '\n      <div class="company-name">' +
((__t = ( companyName )) == null ? '' : __t) +
'</div>\n      ';
 } ;
__p += '\n      <div class="infos">' +
((__t = ( infos )) == null ? '' : __t) +
'</div>\n    </div>\n  </div>\n</div>\n<div class="footer">\n  <div class="cancel-region"></div>\n  <div class="confirm-region"></div>\n</div>';

}
return __p
};});

