define('catalogueMineTabLayoutView',[
  'module',
  'marionette',
  'template!catalogueMineTabLayoutTpl',
  'backToListBehavior',
  'imageUtils',
  'jquery'
], function(
  module,
  Marionette,
  tabsLayoutTpl,
  BackToListBehavior,
  imageUtils,
  $
) {
  'use strict';

  var CatalogueTabsLayoutView = Marionette.LayoutView.extend({
    template: tabsLayoutTpl,
    tagName: 'div',
    className: 'height-100',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.navbar-nav>li'
    },

    regions: {
      tabs: '.js-tabs-region'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'catalogues/mine'
      }
    },

    modelEvents: {
      'change:pictureUid': '_changePicture'
    },

    onRender: function() {
      var logo = imageUtils.getLogo(this.model.get('pictureUid'), this.model.get('code'));
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    },

    update: function(modelChanged){
        this.model = modelChanged;
    },

    onShowActive: function(tabName) {
      this.ui.tabs.each(function(index, tab) {
        var $tab = $(tab);
        if($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      });
    },

    _changePicture: function() {
      this.ui.logo.css('background-image', 'url(' + imageUtils.getLogo(this.model.get('pictureUid')) + ')');
    }
  });

  module.exports = CatalogueTabsLayoutView;
});
