define('productPicturesTabController',[
  'module',
  'productPicturesTabLayoutView',
  'app',
  'entities/product_picture'
], function(
  module,
  PicturesTabLayoutView,
  App
) {
  'use strict';

  var ProductPicturesTabController = {
    showPicturesTab: function(region, product) {
      region.show(new PicturesTabLayoutView({
        collection: App.request('product-picture:collection'),
        product: product
      }));
    }
  };

  module.exports = ProductPicturesTabController;
});
