/**
 * Created by BFR on 07/04/2022.
 */
define('pimProductSimilarsView',['module',
    'marionette',
    'app',
    'underscore',
    'productSimilarView',
    'template!productSimilarsTpl',
    'similarCreateEditLayoutView',
    'entities/pim_product_similar'
  ],
  function (module,
            Marionette,
            App,
            _,
            ProductSimilarView,
            itemTpl,
            SimilarCreateEditLayoutView) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
      template: itemTpl,
      childViewContainer: '.similars-items',
      childView: ProductSimilarView,

      initialize: function () {
      },

      events: {
        'click .btn-add-similar': 'onBtnAddSimilarClick'
      },

      onBtnAddSimilarClick: function () {
        var similarCreateEditLayoutView = new SimilarCreateEditLayoutView({
          model: App.request('pim:product-similar:model', {productSecId: this.options.model.getCurrentResProduct().get('id')}),
          collection: this.collection,
          newItem: true
        });

        similarCreateEditLayoutView.show({
          title: _.i18n('similars.title')
        });
      }
    });
  });
