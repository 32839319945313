/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarMain',[
  'module',
  'logger',
  'app'
], function(
  module,
  Logger,
  App
) {
  'use strict';
  
  var Sidebar = {
    start: function() {
      Logger.log(module, 'starting SidebarModule');
      require(['sidebarShowController'], function(ShowController){
        ShowController.showSidebar();
      });
    },

    stop: function() {
      Logger.log(module, 'stopping SidebarModule');
    }
  };

  App.commands.setHandler('set:active:nav:item', function(name){
    require(['sidebarShowController'], function(ShowController){
      ShowController.setActiveNavItem(name);
    });
  });

  App.on('sidebar:show', function() {
    Sidebar.start();
  });

  module.exports = Sidebar;
});
