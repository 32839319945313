
define('template!adminNotificationsInfosView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(type === 'order') { ;
__p += '\n    <div class="order">\n        <div class="line">\n            <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n\n            ';
 if(sameOrderCount > 0) { ;
__p += '\n                <div class="same-order">\n                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path></svg>\n                    <div class="count">' +
((__t = ( sameOrderCount )) == null ? '' : __t) +
'</div>\n                </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="line2">' +
((__t = ( line2 )) == null ? '' : __t) +
'</div>\n    </div>\n';
 } ;
__p += '\n';
 if (type === 'customer') { ;
__p += '\n    <div class="client">\n        <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n    </div>\n';
 } ;
__p += '\n';
 if(type === 'task') { ;
__p += '\n    <div class="task">\n        ';
 if(tasktype === 'product') { ;
__p += '\n            <div class="product">\n                <div class="code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n                <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } if(tasktype === 'client') { ;
__p += '\n            <div class="client">\n                <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } if(tasktype === 'order') { ;
__p += '\n            <div class="order">\n                <div class="line">\n                    <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n\n                    ';
 if(sameOrderCount > 0) { ;
__p += '\n                        <div class="same-order">\n                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path></svg>\n                            <div class="count">' +
((__t = ( sameOrderCount )) == null ? '' : __t) +
'</div>\n                        </div>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="line2">' +
((__t = ( line2 )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n';
 } ;


}
return __p
};});

