define('menuItems',[
  'underscore',
  'settings',
  'itemPagesNameMixin',
  'rolesMixin'
], function (_,
             Settings,
             ItemPagesNameMixin,
             RolesMixin) {
  'use strict';

  var SidebarMenuItems = {
    build: function () {
      return [{
        //ACCOUNT
        title: _.i18n('menu.account'),
        itemPageName: ItemPagesNameMixin.ACCOUNT,
        link: function () {
          return '';
        },
        role: RolesMixin.DASHBOARD_ACCOUNT,
        icon: 'fa fa-user fa-lg',
        subMenu: [
          {
            title: _.i18n('menu.myAccount'),
            itemPageName: ItemPagesNameMixin.MY_ACCOUNT,
            link: function () {
              return 'caccount/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
            },
            role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT,
            icon: 'fa fa-home fa-lg',
            subMenu: []
          },
          {
            title: _.i18n('menu.account.graphicalId'),
            itemPageName: ItemPagesNameMixin.GRAPHICAL_ID,
            link: function () {
              return 'graphicalIdentity/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
            },
            role: RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID,
            icon: 'mdi-communication-quick-contacts-mail',
            subMenu: []
          }
        ]
      }, {
        title: _.i18n('menu.mails'),
        itemPageName: ItemPagesNameMixin.MAILS,
        link: function () {
          return 'mails';
        },
        role: RolesMixin.DASHBOARD_MAILS,
        svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5v13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1V200.5c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2V456c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V276.2zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5V456c0 30.9 25.1 56 56 56H456c30.9 0 56-25.1 56-56V200.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z"/></svg>', // jshint ignore:line
        subMenu: []
      }, {
        //WEBSHOP
        title: _.i18n('menu.webshop'),
        itemPageName: ItemPagesNameMixin.WEBSHOP,
        link: function () {
          return '';
        },
        role: RolesMixin.DASHBOARD_WEBSHOP,
        svg: '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>', // jshint ignore:line
        subMenu: [
          {
            title: _.i18n('menu.webshop.products'),
            itemPageName: ItemPagesNameMixin.WEBSHOP_PRODUCTS,
            link: function () {
              return 'webshop/products';
            },
            role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS,
            svg: '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path opacity="1" fill="#1E3050" d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z"/></svg>', // jshint ignore:line
            subMenu: []
          },
          {
            title: _.i18n('menu.webshop.categories'),
            itemPageName: ItemPagesNameMixin.WEBSHOP_CATEGORIES,
            link: function () {
              return 'webshop/categories';
            },
            role: RolesMixin.DASHBOARD_WEBSHOP_CATEGORIES,
            svg: '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path opacity="1" fill="#1E3050" d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/></svg>', // jshint ignore:line
            subMenu: []
          }
        ]
      },
        //PRODUCTS
        {
          title: _.i18n('menu.products'),
          itemPageName: ItemPagesNameMixin.PRODUCTS,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_PRODUCTS,
          icon: 'fa fa-cubes fa-lg',
          subMenu: [
            {
              title: _.i18n('menu.products.myProducts'),
              itemPageName: ItemPagesNameMixin.MY_PRODUCTS,
              link: function () {
                return 'products';
              },
              role: RolesMixin.DASHBOARD_PRODUCTS_PRODUCTS,
              icon: 'fa fa-home fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.products.prices'),
              itemPageName: ItemPagesNameMixin.PRODUCTS_PRICELISTS,
              link: function () {
                return 'prices';
              },
              role: RolesMixin.DASHBOARD_PRODUCTS_PRICELISTS,
              icon: 'fa fa-tags fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.products.catalogues'),
              itemPageName: ItemPagesNameMixin.PRODUCTS_CATALOGUES,
              link: function () {
                return 'catalogues/mine';
              },
              role: RolesMixin.DASHBOARD_PRODUCTS_CATALOGUES,
              icon: 'mdi-image-photo-album',
              subMenu: []
            }]
        },
        //PIM
        {
          title: _.i18n('menu.pim'),
          itemPageName: ItemPagesNameMixin.PIM,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_PIM,
          icon: 'fa fa-cubes fa-lg',
          subMenu: [
            {
              title: _.i18n('menu.pim.products'),
              itemPageName: ItemPagesNameMixin.PIM_PRODUCTS,
              link: function () {
                return 'pim/products';
              },
              role: RolesMixin.DASHBOARD_PIM_PRODUCTS,
              icon: 'fa fa-home fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.pim.attributes'),
              itemPageName: ItemPagesNameMixin.PIM_PRODUCT_ATTRIBUTES,
              link: function () {
                return 'pim/attributes';
              },
              role: RolesMixin.DASHBOARD_PIM_PRODUCTS,
              icon: 'fa fa-home fa-lg',
              subMenu: []
            }]
        },
        //CUSTOMERS
        {
          title: _.i18n('menu.clients'),
          itemPageName: ItemPagesNameMixin.MY_CLIENTS,
          link: function () {
            return 'customers';
          },
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS,
          icon: 'fa fa-users fa-lg'
        },
        //OFFERS
        { 
          title: _.i18n('menu.offerManagement'),
          itemPageName: ItemPagesNameMixin.OFFERS,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_OFFERS,     
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>', // jshint ignore:line
          subMenu: [
            {
              title: _.i18n('menu.offerManagement.list'),
              itemPageName: ItemPagesNameMixin.OFFERS_LIST,
              link: function () {
                return 'offers';
              },
              role: RolesMixin.DASHBOARD_OFFERS,
              icon: 'fa fa-list-ul fa-lg',
              subMenu: []
            }, {
              title: _.i18n('menu.orderManagement.board'),
              itemPageName: ItemPagesNameMixin.OFFERS_BOARD,
              link: function () {
                return 'offers_kanban';
              },
              role: RolesMixin.DASHBOARD_OFFERS,
              svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 64V416H224V160H64zm384 0H288V416H448V160z"/></svg>', // jshint ignore:line
              subMenu: []
            }]
        },
        //ORDERS
        {
          title: Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] ?
            _.i18n('menu.orderManagement') : _.i18n('menu.ordersManagement'),
          itemPageName: ItemPagesNameMixin.ORDERS,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_ORDERS,
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM576 368c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zm-76.7-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"/></svg>', // jshint ignore:line
          subMenu: [
            {
              title: _.i18n('menu.orderManagement.list'),
              itemPageName: ItemPagesNameMixin.ORDERS_LIST,
              link: function () {
                return 'orders';
              },
              role: RolesMixin.DASHBOARD_ORDERS,
              icon: 'fa fa-list-ul fa-lg',
              subMenu: []
            }, {
              title: _.i18n('menu.orderManagement.board'),
              itemPageName: ItemPagesNameMixin.ORDERS_BOARD,
              link: function () {
                return 'orders_kanban';
              },
              role: RolesMixin.DASHBOARD_ORDERS,
              svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 64V416H224V160H64zm384 0H288V416H448V160z"/></svg>', // jshint ignore:line
              subMenu: []
            }]
        },
        
        //SUPPLIERS
        {
          title: _.i18n('menu.suppliers'),
          itemPageName: ItemPagesNameMixin.SUPPLIERS,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_SUPPLIERS,
          icon: 'fa fa-truck fa-lg',
          subMenu: [
            {
              title: _.i18n('menu.suppliers.catalogues'),
              itemPageName: ItemPagesNameMixin.SUPPLIERS_CATALOGUES,
              link: function () {
                return 'catalogues/shared';
              },
              role: RolesMixin.DASHBOARD_SUPPLIERS_CATALOGUES,
              icon: 'mdi-image-photo-album',
              subMenu: []
            },
            {
              title: _.i18n('menu.suppliers.products'),
              itemPageName: ItemPagesNameMixin.SUPPLIERS_PRODUCTS,
              link: function () {
                return 'productsSupplier';
              },
              role: RolesMixin.DASHBOARD_SUPPLIERS_PRODUCTFINDER,
              icon: 'fa fa-home fa-lg',
              subMenu: []
            }]
        },
        //GRAPHICAL REQUEST
        {
          title: _.i18n('menu.graphicalRequests'),
          itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS,
          icon: 'mdi-action-extension',
          subMenu: [
            {
              title: _.i18n('menu.graphicalRequests.kanban'),
              itemPageName: ItemPagesNameMixin.GRAPHICAL_SERVICE_KANBAN,
              link: function () {
                return 'graphical_service_kanban';
              },
              role: RolesMixin.VECTORIZATOR,
              icon: 'mdi-image-grain',
              subMenu: []
            },
            {
              title: _.i18n('menu.graphicalRequests.allInOne'),
              itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_ALL_IN_ONE,
              link: function () {
                return 'graphical_service_all_in_one';
              },
              role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_ALL_IN_ONE,
              icon: 'mdi-image-grain',
              subMenu: []
            },
            {
              title: _.i18n('menu.vectorizationRequest'),
              itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_VECTORIZATION,
              link: function () {
                return 'vectorization';
              },
              role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_VECTORIZATION,
              icon: 'mdi-image-grain',
              subMenu: []
            },
            {
              title: _.i18n('menu.fileconversion'),
              itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_FILECONVERSION,
              link: function () {
                return 'file_conversion';
              },
              role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_FILECONVERSION,
              icon: 'mdi-av-repeat',
              subMenu: [],
              isNew: false
            },
            {
              title: _.i18n('menu.backRemove'),
              itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_REMOVEBACKGROUND,
              link: function () {
                return 'remove_background';
              },
              role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_REMOVEBACKGROUND,
              icon: 'mdi-content-content-cut',
              subMenu: [],
              isNew: false
            }
          ]
        },
        //ADMIN
        {
          title: _.i18n('menu.admin'),
          itemPageName: ItemPagesNameMixin.ADMIN,
          link: function () {
            return 'admin';
          },
          role: RolesMixin.DASHBOARD_ADMIN,
          icon: 'fa fa-cog fa-lg',
          subMenu: [
            {
              title: _.i18n('menu.subCaccount'),
              itemPageName: ItemPagesNameMixin.ADMIN_SUBACCOUNTS,
              link: function () {
                return 'subcaccounts';
              },
              role: 'DASHBOARD_SUBACCOUNTCREATOR',
              icon: 'fa fa-briefcase fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.translations'),
              itemPageName: ItemPagesNameMixin.ADMIN_TRANSLATIONS,
              link: function () {
                return 'translations';
              },
              role: RolesMixin.DASHBOARD_ADMIN_TRANSLATIONS,
              icon: 'fa fa-language fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.timetracker'),
              itemPageName: ItemPagesNameMixin.ADMIN_TIMETRACKING,
              link: function () {
                return 'timetracker';
              },
              role: RolesMixin.DASHBOARD_TIMETRACKER,
              icon: 'fa fa-calendar fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.mywebsite'),
              itemPageName: ItemPagesNameMixin.ADMIN_MY_WEBSITE,
              link: function () {
                return 'my_website';
              },
              role: RolesMixin.DASHBOARD_MYWEBSITE,
              icon: 'fa fa-sitemap fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.imprintTechnologies'),
              itemPageName: ItemPagesNameMixin.ADMIN_IMPRINT_TECHNOLOGIES,
              link: function () {
                return 'admin/imprintTechnologies';
              },
              role: RolesMixin.DASHBOARD_ADMIN,
              icon: 'fa fa-print fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.glossaries'),
              itemPageName: ItemPagesNameMixin.ADMIN_IMPRINT_GLOSSARIES,
              link: function () {
                return 'admin/glossaries';
              },
              role: RolesMixin.DASHBOARD_ADMIN,
              icon: 'fa fa-file-text fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.webhookHistory'),
              itemPageName: ItemPagesNameMixin.ADMIN_WEBHOOK_HISTORY,
              link: function () {
                  return 'admin/webhookHistory';
              },
              role: RolesMixin.DASHBOARD_ADMIN_WEBHOOK_HISTORY,
              icon: 'fa fa-history fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.htmlTemplates'),
              itemPageName: ItemPagesNameMixin.ADMIN_HTMLTEMPLATES,
              link: function () {
                return 'admin/htmlTemplates';
              },
              role: RolesMixin.DASHBOARD_ADMIN_HTMLTEMPLATES,
              icon: 'fa fa-file-text fa-lg',
              subMenu: []
            }
          ]
        },
        //PHONE
        {

          title: _.i18n('menu.phone'),
          itemPageName: ItemPagesNameMixin.DASHBOARD_PHONE,
          link: function () {
            return '';
          },
          role: RolesMixin.DASHBOARD_PHONE,
          icon: 'fa fa-phone fa-lg',
          subMenu: [
            {
              title: _.i18n('menu.phone.numbers'),
              itemPageName: ItemPagesNameMixin.PHONE_NUMBERS,
              link: function () {
                return 'phone/numbers';
              },
              role: RolesMixin.DASHBOARD_PHONE,
              icon: 'fa fa-phone fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.calls'),
              itemPageName: ItemPagesNameMixin.PHONE_CALLS,
              link: function () {
                return 'calls';
              },
              role: RolesMixin.DASHBOARD_PHONE,
              icon: 'fa fa-phone fa-lg',
              subMenu: []
            }
          ]
        },
        //SUPER ADMIN
        {
          title: _.i18n('menu.superAdmin'),
          itemPageName: ItemPagesNameMixin.SUPERADMIN,
          link: function () {
            return 'superAdmin';
          },
          role: RolesMixin.DASHBOARD_SUPERADMIN,
          icon: 'fa fa-cogs fa-lg',
          subMenu: [
            {
              title: _.i18n('superAdmin.overview.tabTitle'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_OVERVIEW,
              link: function () {
                return 'superAdmin/overview';
              },
              role: RolesMixin.DASHBOARD_SUPERADMIN,
              icon: 'fa fa-globe fa-lg',
              subMenu: []
            },
            {
              title: _.i18n('menu.superadmin.caccounts'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_CACCOUNTS,
              link: function () {
                return 'superAdmin/caccounts';
              },
              role: RolesMixin.DASHBOARD_SUPERADMIN,
              icon: 'fa fa-male',
              subMenu: []
            }
          ]
        }, {
          title: _.i18n('menu.invitations'),
          itemPageName: ItemPagesNameMixin.INVITATIONS,
          link: function () {
            return 'invitations';
          },
          role: RolesMixin.DASHBOARD_INVITATIONS,
          icon: 'fa fa-paper-plane fa-lg'
        }, {
          title: _.i18n('menu.tasks'),
          itemPageName: ItemPagesNameMixin.ADMIN_TASKS,
          link: function () {
            return 'tasks';
          },
          role: RolesMixin.DASHBOARD_TASKS,
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg>', // jshint ignore:line
          subMenu: []
        }, {
          title: _.i18n('menu.notifications'),
          itemPageName: ItemPagesNameMixin.ADMIN_NOTIFICATIONS,
          link: function () {
            return 'admin/notifications';
          },
          role: RolesMixin.DASHBOARD_NOTIFICATIONS,
          svg: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"></path></svg>', // jshint ignore:line
          subMenu: []
        }];
    }
  };
  return SidebarMenuItems;
});
