define('urlUtil',['underscore'], function (_) {
  'use strict';

  var URLUtil = {
    parseQueryString: function ( queryString ) {
      var querystring = queryString || location.search;
      // remove any preceding url and split
      querystring = querystring.substring( querystring.indexOf( '?' ) + 1 ).split( '&' );
      var params = {}, pair, d = decodeURIComponent;
      // march and parse
      for ( var i = querystring.length - 1; i >= 0; i-- ) {
        pair = querystring[i].split( '=' );
        params[d( pair[0] )] = d( pair[1] );
      }

      return params;
    },

    addPathParam: function(pathParam, url) {
      url = window.location.href || url;
      if(_.indexOf(url.split('/'), pathParam) === -1) {
        url = url + '/' + pathParam;
      }
      return url;
    },

    clearQueryString: function () {
      var loc = window.location;
      if ('pushState' in history)
        history.replaceState({}, document.title, '/');
      else {
        loc.hash = '';
      }
    }
  };
  return URLUtil;
});

