define('pimAddAttributeView',[
  'module',        
  'marionette',
  'template!pimAddAttributeTpl'
], function(
  module,           
  Marionette,
  viewTpl
){
  'use strict';

  var PimAddAttributeView = Marionette.LayoutView.extend({
    template: viewTpl,

    onShow: function()
    {          
    }
  });

  module.exports = PimAddAttributeView;
});
