define('collectionViewGoogleContacts',['module',
        'backbone',
        'marionette',
        'jquery',
        'collectionItemViewGoogleContacts'],
    function(module,
             Backbone,
             Marionette,
             $,
             CollectionItemViewGoogleContacts){
        'use strict';

        var GoogleContactsCollectionView = Marionette.CollectionView.extend({
            childView: CollectionItemViewGoogleContacts,
            collection: new Backbone.Collection(),

            onBeforeDestroyCollection: function() {
                var model;
                while ((model = this.collection.first())) {
                    model.destroy();
                }
            },

            onRender: function() {
                $.material.init();
            }
        });

        module.exports = GoogleContactsCollectionView;
    });
