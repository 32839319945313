
define('template!productTranslationsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-6">\n                <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8 name-region"></div>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-6">\n                <h2 class="col-xs-12 text-center">Option categories</h2>\n            </div>\n            <div class="col-xs-6">\n                <h2 class="col-xs-12 text-center">Options</h2>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-6">\n                <h5 class="col-xs-4 text-right">Code</h5>\n                <h5 class="col-xs-8">Name</h5>\n            </div>\n            <div class="col-xs-6">\n                <h5 class="col-xs-4 text-right">Code</h5>\n                <h5 class="col-xs-8">Name</h5>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="translation-cats col-xs-12">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-6">\n                <h2 class="col-xs-12 text-center">Print areas</h2>\n            </div>\n            <div class="col-xs-6">\n                <h2 class="col-xs-12 text-center">Print zones</h2>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-6">\n                <h5 class="col-xs-4 text-right">Code</h5>\n                <h5 class="col-xs-8">Name</h5>\n            </div>\n            <div class="col-xs-6">\n                <h5 class="col-xs-2 text-right">Code</h5>\n                <h5 class="col-xs-5">Name</h5>\n                <h5 class="col-xs-5">It Name</h5>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="translation-printareas col-xs-12">\n        </div>\n    </div>\n</div>';

}
return __p
};});

