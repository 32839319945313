define('selectOptionView',[
  'module',
  'marionette',
  'underscore',
  'template!selectOptionView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,    
    className: 'select-option-view',
    tagName: 'option',

    modelEvents: {
      'change:selected': 'render'
    },

    attributes: function() {
      return {
        value: this.model.get('id')
      };
    },

    serializeData: function() {
      var data = {};
      data.value = this.model.get('value');
      return data;
    }
  });
});
  
