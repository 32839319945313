define('pimProductFilterValueItemView',[
  'module',
  'marionette',
  'jquery',
  'template!pimProductFilterValueItemTpl'
], function (
  module,
  Marionette,
  $,
  PimProductFilterValueItemTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: PimProductFilterValueItemTpl,
    className: 'value',

    modelEvents: {
      'change': 'render'
    },

    events: {
      'change .cbx-select': 'onSelectionChange'
    },

    onSelectionChange: function (event) {
      var cbx = $(event.target);
      this.model.set('selected', cbx.is(':checked'));
    },

    onRender: function () {
      if (this.model.get('hide')) {
        this.$el.hide();
      } else {
        this.$el.show();
      }
    }
  });
});
