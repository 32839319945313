/**
 * Created by BFR on 06/09/2022.
 */
define('ordersDetailPriceV2PrintPricesView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'jquery',
  'underscore',
  'template!ordersDetailPriceV2PrintPricesView',
  'ordersDetailPriceV2PrintPriceView',
  'entities/product',
  'entities/pim_product'
], function (
  App,
  module,
  Backbone,
  Marionette,
  $,
  _,
  viewTpl,
  orderPrintPriceV2ItemView
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.items-region',
    childView: orderPrintPriceV2ItemView,

    events: {
      'change .select-nbr-colors': 'onSelectNbrColorsChange'
    },

    initialize: function () {
      var config = {
        quantity: 500,
        options: [],
        printZonesColors: {}
      };

      _.each(this.options.product.get('productOptionCategories'), function (poc) {
        config.options.push(poc.productOptions[0].id);
      });

      _.each(this.options.product.get('printAreas'), function (pa) {
        _.each(pa.printZones, function (pz) {
          config.printZonesColors[pz.id] = 1;
        });
      });

      var request;
      if (this.options.product.get('pim')) {
        request = App.request('pim:products:get-price-for-configuration', this.options.product.get('secId'), config, true);
      } else {
        request = App.request('products:get-price-for-configuration', this.options.product.get('secId'), config, true);
      }
      $.when(request).done(_.bind(function (price) {
        this.price = price;
        var items = [];
        _.each(price.impt, _.bind(function (impt) {
          var comp = _.findWhere(this.model.get('compositions'), {pzId: impt.secId});
          var item = {
            setup: Number(impt.imptSetupPrice).toFixed(2),
            handlingPrice: impt.imptHandlingPrice ? Number(impt.imptHandlingPrice).toFixed(2) : '0',
            scales: [],
            checked: !!comp,
            composition: comp,
            order: this.model
          };
          _.each(this.options.product.get('printAreas'), function (pa) {
            _.each(pa.printZones, function (pz) {
              if (pz.id === impt.secId) {
                item.pa = pa;
                item.pz = pz;
              }
            });
          });
          this.scales = price.scale;

          if (this.options.factorsV2 && !price.customScales) {
            this.scales = _.filter(price.scale, _.bind(function (scale) {
              if (Number(scale.total) === 0 || (Number(scale.total) > this.options.factorsV2.param2 &&
                (!this.options.factorsV2.param3 ||
                  this.options.factorsV2.param3 === 0 ||
                  Number(scale.total) < this.options.factorsV2.param3))) {
                return true;
              }
              return false;
            }, this));
          }

          _.each(this.scales, function (scale) {
            var imptS = _.findWhere(scale.impt, {secId: impt.secId});
            var s = {
              quantity: scale.quantity,
              unit: Number(imptS.imptUnitUnitPrice).toFixed(2)
            };
            item.scales.push(s);
          });

          items.push(item);
        }, this));
        this.collection = new Backbone.Collection(items);
        this.render();
      }, this));
    },

    onSelectNbrColorsChange: function () {

    },

    serializeData: function () {
      var templateData = {};
      templateData.scales = this.scales || [];
      return templateData;
    }
  });
});
