/**
 * Created by RKL on 05/08/2015.
 */
define('resetMain',[
  'module',
  'logger',
  'resetMainRouter'
], function(
  module,
  Logger
) {
	'use strict';

  var ResetPwd = {
    start: function() {
      Logger.log(module, 'starting ResetModule');
    },

    stop: function() {
      Logger.log(module, 'stopping ResetModule');
    }
  };

  module.exports = ResetPwd;
});
