/**
 * Created by BFR on 17/06/2020.
 */
define('pimProductPriceScalesView',['module',
        'marionette',
        'pimProductPriceScaleView'],
  function(module,
           Marionette,
           PimProductPriceScaleView){
  'use strict';

  var PimProductPriceScalesView = Marionette.CollectionView.extend({
    childView: PimProductPriceScaleView,
    className: 'table-body',
    childViewOptions: function() {
      return {customPrice: this.options.customPrice};
    },
    childEvents: {
      'select:next': 'onSelectNextChild',
      'select:previous': 'onSelectPreviousChild',
      'select:next-qty': 'onSelectNextChildQty',
      'select:previous-qty': 'onSelectPreviousChildQty'
    },

    onSelectPreviousChild: function(childView) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            previous.onLblPriceClick();
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextChild: function(childView) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          this.children._views[property].onLblPriceClick();
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    },

    onSelectPreviousChildQty: function(childView) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            previous.onLblQuantityClick();
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextChildQty: function(childView) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          this.children._views[property].onLblQuantityClick();
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    }
  });

  module.exports = PimProductPriceScalesView;
});
