
define('template!tasksEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n  <div class="users-region"></div>\n  <div class="info">\n    ';
 if(type === 'product') { ;
__p += '\n      <div class="product">     \n        <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n        <div class="code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>        \n      </div>\n    ';
 } if(type === 'client') { ;
__p += '\n      <div class="client">     \n        <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 } if(type === 'order') { ;
__p += '\n      <div class="order">\n        <div class="poi">' +
((__t = ( poi )) == null ? '' : __t) +
'</div>       \n        <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>        \n      </div>\n    ';
 } ;
__p += '\n    <div class="open-target" title="' +
((__t = ( _.i18n('tasks.open-target') )) == null ? '' : __t) +
'">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"/></svg>    \n    </div>\n  </div>\n  <div class="date-region"></div>\n</div>\n<div class="body">\n  <div class="comments">\n    <div class="header">\n        <div class="title-ctn">\n            <div class="title">' +
((__t = ( _.i18n('common.comments') )) == null ? '' : __t) +
'</div>\n            <div class="counter"></div>\n        </div>\n        <div class="buttons">\n            <svg class="add-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 384c-79.5 0-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144zm16-208c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z"/></svg>\n        </div>\n    </div>\n    <div class="comments-region"></div>\n  </div>\n  <div class="item" data-field-name="dateDue">\n    <div class="title">' +
((__t = ( _.i18n('task.dateDue') )) == null ? '' : __t) +
'</div>\n    <div class="value">\n      <input type="datetime-local"\n        class="form-control js-datePicker date-due js-info-input-date inputBackground"\n        data-field-name="dateDue"\n        style="width: auto;"\n        placeholder="" value="' +
((__t = ( dateDue )) == null ? '' : __t) +
'">\n    </div>\n  </div>\n  <div class="item" data-field-name="userpidsToNotify">\n    <div class="title">' +
((__t = ( _.i18n('task.userpidsToNotify') )) == null ? '' : __t) +
'</div>\n    <div class="value">\n      <div class="users-to-notify-region"></div>\n    </div>\n  </div>\n  <div class="item" data-field-name="done">\n    <div class="title">' +
((__t = ( _.i18n('task.done') )) == null ? '' : __t) +
'</div>\n    <div class="value">\n      <input type="checkbox" style="width: 20px; height: 40px;" id="done" class="done" ' +
((__t = ( done?'checked':'')) == null ? '' : __t) +
'>\n    </div>\n  </div>\n</div>\n<div class="footer">\n  <div class="left">\n    <div class="load">\n      <div class="loader-2"></div>\n      <div class="text"></div>\n    </div>\n  </div>\n  <div class="right">\n    <div class="open-region"></div>\n    <div class="cancel-region"></div>\n    <div class="confirm-region"></div>\n  </div>\n</div>';

}
return __p
};});

