/**
 * Created by BFR on 24/02/2020.
 */
define('translationPrintAreaView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!translationPrintAreaTpl',
    'dynamicTextView',
    'translationPrintZonesView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView,
    TranslationPrintZonesView
  ) {
    'use strict';
  
    var TranslationPrintAreaView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        nameRegion: '.name-region',
        translationPrintZones: '.translation-printzones'
      },

      onShow: function () {
        this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);

        this.translationPrintZonesView = new TranslationPrintZonesView({ collection: this.model.get('printZones')});
        this.getRegion('translationPrintZones').show(this.translationPrintZonesView);
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = TranslationPrintAreaView;
  });
