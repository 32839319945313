define('webshopProductsListSupplierView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!webshopProductsListSupplierView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-supplier-view ' + FieldView.prototype.className,

    serializeData: function() {
      var data = {};

      data.supplierCode = this.model.getSupplierCode();
      data.supplierName = this.model.getSupplierName();

      return data;
    }
  });
});
