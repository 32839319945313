define('ordersDetailAttachmentsView',[
  'module',
  'marionette',
  'underscore',
  'settings',
  'ordersDetailAttachmentsOrderView',
  'ordersDetailAttachmentsCustomerView',
  'template!ordersDetailAttachmentsView'
], function (
  Module,
  Marionette,  
  _,
  Settings,
  OrderView,
  CustomerView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-attachments-view',  

    regions: {
      orderAttachmentsRegion: '.order-attachments-region',
      customerAttachmentsRegion: '.customer-attachments-region'
    },  
    
    ui: {
      customerAttachments: '.btn-edit-client'
    },

    onShow: function () {

      this.orderView = new OrderView({
        model: this.model,
        customer: this.options.customer,
        attachmentsView: this
      });
      this.getRegion('orderAttachmentsRegion').show(this.orderView);

      //------------

      if (Settings.configValue('order.attachments.showCustomerAttachments') === true) {
        this.customerView = new CustomerView({
          model: this.options.customer,
          order: this.model,
          attachmentsView: this
        });
        this.getRegion('customerAttachmentsRegion').show(this.customerView);
      } else {
        this.$el.find('.customer-attachments').hide();
      }   
    }
  });
});

