
define('template!priceBillingCodePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="billingcodes-add-new-container">\n\t<div class="container-fluid">\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.code") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control code js-form-input"\n\t\t\t\t\t\tdata-field-name="code"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.code") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( code )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.name") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control name js-form-input"\n\t\t\t\t\t\tdata-field-name="name"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.name") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( name )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="billingCodeType">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.billingCodeType") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<select class="form-control billingCodeType js-form-input" data-field-name="billingCodeType" value="' +
((__t = ( billingCodeType )) == null ? '' : __t) +
'">\t\t              \n\t\t                <option value="PROD_BC" ';
  if (billingCodeType === 'PROD_BC') { ;
__p += ' selected ';
 } ;
__p += ' >PROD_BC</option>\n\t\t                <option value="SKU_BC" ';
  if (billingCodeType === 'SKU_BC') { ;
__p += ' selected ';
 } ;
__p += ' >SKU_BC</option>\n\t\t                <option value="OPT_BC" ';
  if (billingCodeType === 'OPT_BC') { ;
__p += ' selected ';
 } ;
__p += ' >OPT_BC</option>\n\t\t                <option value="PRT_SETUP" ';
  if (billingCodeType === 'PRT_SETUP') { ;
__p += ' selected ';
 } ;
__p += ' >PRT_SETUP</option>\n\t\t                <option value="PRT_UNIT" ';
  if (billingCodeType === 'PRT_UNIT') { ;
__p += ' selected ';
 } ;
__p += ' >PRT_UNIT</option>\n\t\t            </select>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

