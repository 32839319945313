define('customersDetailBodyProfileSirenView',[
  'app',
  'module',
  'underscore',
  'marionette',
  'settings',
  'template!customersDetailBodyProfileSirenView'
], function (
  App,
  Module,
  _,
  Marionette,
  Settings,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,

    className: 'customers-detail-profile-siren-view',

    modelEvents: {
      'change:vatNumber': 'render'
    },

    ui: {
      infos: '.infos'
    },

    onRender: function () {
      this.$el.hide();
      if (this.model.get('vatNumber') && this.model.get('vatNumber').indexOf('FR') === 0) {
        $.ajax({
          method: 'get',
          url: Settings.url('https://bxbp3ch1m7.execute-api.eu-west-1.amazonaws.com/prod/api-insee', '', {
            vatNumber: this.model.get('vatNumber')
          }),
          contentType: 'application/json',
          success: _.bind(function (data) {
            delete data.siret.header;
            delete data.siren.header;
            this.deleteNullProperties(data);
            if (this.ui.infos && this.ui.infos.text) {
              this.ui.infos.text(JSON.stringify(data, null, 4));
            }
            this.$el.css('display', 'flex');
          }, this)
        });
      }
    },

    deleteNullProperties: function (obj) {
      for (var prop in obj) {
        if (obj[prop] === null) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          this.deleteNullProperties(obj[prop]);
        }
      }
    }
  });
});
