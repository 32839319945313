/**
 * Created by OLD on 20/11/2015.
 */
define('fileConversionShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'stateMessages',
  'dateUtils',
  'jqgridView',
  'template!fileConversionViewTpl',
  'notificationCenter',
  'bootbox',
  'fCticketStates',
  'datePickerPopoverMixin',
  'rolesMixin'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  StateMessages,
  dateConverter,
  JqGridView,
  viewTpl,
  NotificationCenter,
  bootbox,
  ticketStates,
  datePickerPopoverMixin,
  RolesMixin
) {
  'use strict';

  var FileConversionTableView = JqGridView.extend({
    GRID_MIN_WIDTH: 850,
    template: viewTpl,
    filtersName: 'fileConversionFilters',

    triggers: {
      'click @ui.addNew' : 'onCreateNew',
      'change @ui.uploadInput': 'onUploadImage',
      'click @ui.creditBtn' : 'onNavigateToCredit'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-vector',
        jqGridPager: '#jq-grid-pager-vector',
        addNew: '.new-vector-button',
        uploadInput: '.js-upload-vectorization',
        urgencyCheckbox: 'input[type=checkbox]',
        creditBtn : '.credit-info-block'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'change @ui.urgencyCheckbox': 'onChangeUrgency'
      }, this.gridEvents);
    },

    serializeData: function() {
      return {
        vectorizator: Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR],
        balance: this.options.balance
      };
    },

    onShow: function() {
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.NEW_CONVERSION_TICKET, this.onNewTicket);
    },

    onNewTicket: function(response) {
      this.updatePagination('reloadGrid');
      NotificationCenter.getInstance().processNotification(response);
    },

    onChangeUrgency: function(e) {
      var that = this,
        $input = $(e.currentTarget),
        ticketID = $input.data('ticket-cid'),
        checked = $input.is(':checked');
      if(!checked) {
        // Don't need confirmation if unchecking.
        that.trigger('ticket:update:urgency', ticketID, checked);
        return;
      }
      bootbox.confirm(
        _.i18n('warning.updateTicketUrgency'),
        function (result) {
          if (result) {
            that.trigger('ticket:update:urgency', ticketID, checked);
          } else {
            $input.prop('checked', !checked);
          }
        }
      );
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions, options;

      this.sidx = 'userTimestamp.modDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: '<i class="mdi-maps-local-post-office icon"></i>',
            name: 'messages',
            classes: 'tickets',
            formatter: this.messageFormatter,
            search: false,
            sortable: false,
            index: 'unreadMessages',
            width: 45
          }, {
            label: _.i18n('vectorization.tickets.column.attachment'),
            name: 'attachment',
            align: 'left',
            classes: 'tickets-link image-cell complexity-colored',
            formatter: this.attachFormatter,
            search: false,
            sortable: false,
            width: 75
          }, {
            label: _.i18n('common.subject'),
            name: 'name',
            classes: 'tickets-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'name'
          }, {
            label: _.i18n('common.date.create'),
            name: 'date',
            classes: 'tickets-link',
            formatter:  this.dateFormatter,
            search: true,
            index: 'date',
            sorttype: 'date', 
            searchrules: {date: true},
            searchoptions: {
              dataInit: datePickerPopoverMixin.jqGridDataInit
            },
            width: 130
          }, {
            label: _.i18n('common.date.mod'),
            name: 'modDate',
            classes: 'tickets-link',
            formatter: this.dateFormatter,
            search: true,
            index: 'modDate',
            sorttype: 'date', 
            searchrules: {date: true},
            searchoptions: {
              dataInit: datePickerPopoverMixin.jqGridDataInit
            },
            width: 130
          }, {
            label: _.i18n('vectorization.tickets.requester'),
            name: 'requester',
            index: 'requester',
            classes: 'tickets-link',
            formatter:  this.defaultFormatter,
            search: true,
            width: 175
          }, {
            label: _.i18n('common.urgency'),
            name: 'urgent',
            classes: 'tickets-link cell-urgency',
            formatter: this.urgencyFormatter,
            search: true,
            index: 'urgent',
            stype:'select',
            searchrules: {select: true},
            searchoptions: {
              value: that.typeStatuses
            },
            width: 100
          }, {
            label: _.i18n('common.status'),
            name: 'ticketState',
            classes: 'tickets-link',
            formatter: this.stateFormatter,
            search: true,
            index: 'ticketState',
            stype:'select', 
            searchrules: {select: true},
            searchoptions: {
              value: that.stateStatuses
            },
            width: 100
          }
        ],

        serializeGridData: function(postData) {
          var columnsWithSelect = [
              'ticketState',
              // 'tariffScale',
              'urgent'
            ];

          _.each(columnsWithSelect, function(select) {
            if (postData[select] === that.selectDefault) {
              delete postData[select];
            }
          });

          if (postData.date) {
            postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.date);
            postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.date);
          } else {
            delete postData.dateFrom;
            delete postData.dateTo;
            delete postData.date;
          }

          if (postData.modDate) {
            postData.modDateFrom = datePickerPopoverMixin.parseDateFrom(postData.modDate);
            postData.modDateTo = datePickerPopoverMixin.parseDateTo(postData.modDate);
          } else {
            delete postData.modDateFrom;
            delete postData.modDateTo;
          }

          if (postData.sidx === 'date') {
            postData.sidx = 'userTimestamp.creatDate';
          }

          if (postData.sidx === 'modDate') {
            postData.sidx = 'userTimestamp.modDate';
          }

          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        },

        onSelectRow: function (rowid, bool, e) {
          var cellUrgency = that.$('.cell-urgency');

          if (e.type === 'click' && cellUrgency.has(e.target).length === 0) {
            that.trigger('tickets:show', rowid);
          }
        }
      };
      options = _.defaults(options, defaultsOptions);

      return options;
    },

    getSelectValues: function() {
      var that = this,
        typeStatuses = [
          {
            send: 'false',
            display: _.i18n('common.state.noturgency')
          }, {
            send: 'true',
            display: _.i18n('common.state.urgent')
          }
        ],
        stateStatuses = [
          {
            send: ticketStates.OPEN.state,
            display: ticketStates.OPEN.label
          }, {
            send: ticketStates.PROGRESS.state,
            display: ticketStates.PROGRESS.label
          }, {
            send: ticketStates.REVIEW.state,
            display: ticketStates.REVIEW.label
          }, {
            send: ticketStates.PENDING.state,
            display: ticketStates.PENDING.label
          }, {
            send: ticketStates.DONE.state,
            display: ticketStates.DONE.label
          }, {
            send: ticketStates.CANCELED.state,
            display: ticketStates.CANCELED.label
          }, {
            send: ticketStates.CLOSE.state,
            display: ticketStates.CLOSE.label
          }
        ];

      this.typeStatuses = this.selectDefault + ':' + this.selectDefault;
      _.each(typeStatuses, function(status) {
        that.typeStatuses += ';' + status.send + ':' + status.display;
      });

      this.stateStatuses = this.selectDefault + ':' + this.selectDefault;
      _.each(stateStatuses, function(status) {
        that.stateStatuses += ';' + status.send + ':' + status.display;
      });

      this.selectValue = {
        ticketState: stateStatuses[0].display,
        type: typeStatuses[0].display
      };
    },

    onGridFilterChange: function(e) {
      var that = this,
        filters = {},
        value;

      this.triggerClear(e);

      that.$('#gs_date').popover('hide');
      that.$('#gs_modDate').popover('hide');
      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            if (column.index === 'date' && value) {
              filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
              filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
            } else if (column.index === 'modDate' && value) {
              filters.modDateFrom = datePickerPopoverMixin.parseDateFrom(value);
              filters.modDateTo = datePickerPopoverMixin.parseDateTo(value);
            }
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
    },

    urgencyFormatter: function(cellvalue, options, rowObj) {
      var checked = cellvalue ? 'checked' : '',
        disabled = (ticketStates[rowObj.ticketState] === ticketStates.CLOSE ||
        !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_SET_URGENT]) ? 'disabled' : '',
        htmlEl = '<div class="form-group checkbox-group">' + 
          '<div class="checkbox checkbox-primary ' + disabled + '"><label>' +
          '<input type="checkbox" data-ticket-cid="' + options.rowId +
          '" class="js-ticket-urgent" ' + checked + ' ' + disabled +
          '></label></div></div>';
      return htmlEl;
    },

    stateFormatter: function(cellvalue) {
      var ticketState = ticketStates[cellvalue],
        state = ticketState ? ticketState.label : '';

      return '<span>' + state + '</span>';
    },

    messageFormatter: function(cellvalue, options, rowObject) {
      var currentUserPid = Settings.get('currentUser' ),
        unReadMessageCounter = 0, messages,
        founded = false;
      _.each( rowObject.messages, function(message, index) {
        if (index > 1) {
          founded = _.some(message.userpidReaders,function(userpidReader) {
            return userpidReader.secId === currentUserPid.secId;
          });
          if (!founded && (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] && message.direction === 'from' ||
              !Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] && message.direction === 'to') &&
              ticketStates[rowObject.ticketState] !== ticketStates.CLOSE) {
            unReadMessageCounter++;
          }
        }
      });
      if (unReadMessageCounter > 0) {
        messages = '<span class="badge action-bg up count tickets">' +
          unReadMessageCounter + '</span>';
        return '<i class="i i-chat3 tickets"></i>' + messages;
      } else {
        return '';
      }

    },

    attachFormatter: function(cellvalue, options, rowObject) {
      var result,
          fileLink, fileName = '', 
          attachment = rowObject.messages[0].attachements[0];

      if (attachment) {
        fileLink = Settings.url('base', attachment.fileLink);
        fileName = attachment.fileName;
      } else {
        fileLink = Settings.get('notFoundImage');
      }

      result = '<img class="jq-picture attach-img js-image-cell" ' +
          'data-link="' + fileLink + '" data-name="' + fileName + '" src="' + fileLink + '">';

      return result;
    },

    dateFormatter: function(cellvalue) {
      var date = dateConverter.toDateFormatString(cellvalue),
        time = dateConverter.toTimeFormatString(cellvalue);

      return '<span class="cell-date">' + date + ' ' + time + '</span>';
    }
  });

  module.exports = FileConversionTableView;
});
