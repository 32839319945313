define('windowsManagmentWindowView',[
  'module',
  'marionette',
  'underscore',
  'template!windowsManagmentWindowView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,    
    className: 'windows-managment-window-view',   
    
    ui: {      
      unminimizeButton: '.windows-ctn > .header .unminimize-button',
      minimizeButton: '.windows-ctn > .header .minimize-button',
      closeButton: '.windows-ctn > .header .close-button',
      showButton: '.windows-ctn > .header .show-button',
      html: '.windows-ctn > .body > .content > .html',
      title: '.title',
      shield: '.windows-ctn > .shield'
    },

    events: {
      'click @ui.shield': 'onShieldClick',
      'click @ui.minimizeButton': 'onMinimizeButtonClick',
      'click @ui.unminimizeButton': 'onUnminimizeButtonClick',
      'click @ui.closeButton': 'onCloseButtonClick',
      'click @ui.showButton': 'onShowButtonClick'      
    },

    initialize: function () {     
      this.windowView = this.model.get('windowView');
      this.windowView.managmentView = this;
    },

    onDestroy: function() {
      this.windowView.managmentView = null;
    },

    onRender: function() {  
      this.ui.html.append(this.model.get('html'));  

      if(this.windowView.isMinimized())
        this.minimize();
      else
        this.unminimize();
        
      if(this.windowView.title)
        this.ui.title.text(this.windowView.title);

      if(this.windowView.margin)
        this.ui.html.css('padding', 10);
    },

    minimize: function() {
      this.$el.addClass('minimized');
      this.ui.minimizeButton.css('display', 'none');
      this.ui.unminimizeButton.css('display', 'flex');
    },

    unminimize: function() {
      this.$el.removeClass('minimized');
      this.ui.minimizeButton.css('display', 'flex');
      this.ui.unminimizeButton.css('display', 'none');
    },

    onShieldClick: function() {
      this.windowView.unminimize();
      this.windowView.focus();
      this.options.managmentView.hide();
    },

    onMinimizeButtonClick: function() {
      this.windowView.minimize();
      this.minimize();
    },

    onUnminimizeButtonClick: function() {
      this.windowView.unminimize();
      this.unminimize();
    },

    onCloseButtonClick: function() {
      this.windowView.close();
      this.options.managmentView.remove(this);
    },

    onShowButtonClick: function() {
      this.windowView.unminimize();
      this.windowView.focus();
      this.options.managmentView.hide();
    }
  });
});
  
