define('ordersDetailBodyTabView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailBodyTabView'
], function (
  App,
  Module, 
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-detail-body-tab-view', 
    
    modelEvents: {
      'change': 'renderContent'
    },
    
    events: {
      'click': function() {
        this.trigger('select');
      } 
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      this.$el.html(this.model.get('title'));

      if(this.model.get('selected'))
        this.$el.addClass('selected');
      else
        this.$el.removeClass('selected');
    }
  });
});
