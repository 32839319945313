define('ordersDetailOrderCompositionCustomPzNameView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'template!ordersDetailOrderCompositionCustomPzNameView'
], function (
  Module,
  App,
  Marionette,
  _,    
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-composition-custom-pz-name-view item editable',

    ui: {
      value: '.value',
      editButton: '.btn-edit-custom-pz-name',
      input: '.edit > input'
    },
     
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.value': 'onEdit',
      'blur  @ui.input': 'onBlurClick',
      'click @ui.input': 'onInputClick'
    },

    modelEvents: {      
      'change:name': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      }                
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },   

    onRender: function () {    
      this.renderContent();
    },

    renderContent: function () {       
      this.$el.attr('edit', this.edit);

      //-------------------

      if (this.options.compositionModel.get('customData') && this.options.compositionModel.get('customData').itName)
        this.ui.value.html(this.options.compositionModel.get('customData').itName);
      else
        this.ui.value.html('-');
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = !this.edit;     
      this.renderContent();     
      
      if(this.edit && this.options.compositionModel.get('customData') &&
          this.options.compositionModel.get('customData').itName) {
        this.ui.input.val(this.options.compositionModel.get('customData').itName);
        this.ui.input.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () { 

      if(!this.edit)
        return;
      
      this.edit = false;
      this.renderContent();

      if (!this.options.compositionModel.get('customData')) {
        this.options.compositionModel.set('customData', {});
      }

      if(this.ui.input.val() !== this.options.compositionModel.get('customData').itName) {
        this.options.compositionModel.get('customData').itName = this.ui.input.val();
        App.request('composition:update:customdata', this.model.get('purchaseOrderId'),
            this.options.compositionModel.get('secId'), this.options.compositionModel.get('customData'))
              .done(_.bind(function () {
          this.model.fetch();
        }, this));
      }         
    }
  });
});
