define('tableRowView',[
  'module',
  'marionette',
  'underscore',
  'tableFieldView',
  'template!tableRowView'
], function (
  Module,
  Marionette,
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({ 
    template: Tpl,   
    className: 'table-row-view',

    events: {
      'dblclick': 'onRowDoubleClick',
      'click': 'onRowClick',
      'mousedown': 'onMouseDown'
    },

    attributes: function() {
      return this.options.tableView.rowAttributes(this);
    },

    modelEvents: function () {
      return this.options.tableView.rowModelEvents(this);
    },

    initialize: function () {
      this.tableView = this.options.tableView;
      this.bodyView = this.tableView.bodyView;
      this.selected = false;
      if(this.model.get('selected') === true)
        this.select();
    },
   
    onShow: function() {     
      _.each(this.tableView.fields, function(field) {
        if(field.showBody !== false) {
          var ViewClass = FieldView;
          if(field.view)
            ViewClass = field.view;

          var fieldView = new ViewClass({
            tableView: this.tableView,
            rowView: this,
            field: field,
            model: this.model
          });
          this.$el.append(fieldView.render().el);
        }
      }, this);
    },

    onRowDoubleClick: function(e) {
      if(this.tableView.onRowDoubleClick)
        this.tableView.onRowDoubleClick(this, e);
    },

    onRowClick: function(e) {
      if(this.tableView.onRowClick)
        this.tableView.onRowClick(this, e);
    },

    onMouseDown: function(e) {
      if(this.tableView.selectable || this.tableView.sortActivated) {
        if (e.which === 3) {
          if(this.isSelected()) {
            return;
          } else {
            this.tableView.unSelectAll();
            this.select();
          }

          this.tableView.lastSelected = this;

          if(this.tableView.onSelected)
            this.tableView.onSelected(this.tableView.getSelected());
          return;
        }

        if (e.ctrlKey || e.altKey) {
          if (this.isSelected())
            this.unSelect();
          else
            this.select();
        } else if (e.shiftKey) {
          if (!this.tableView.lastSelected)
            return;

          e.preventDefault();

          var first = 0;
          var last = 0;

          if (this.bodyView.collection.indexOf(this.model) > this.bodyView.collection.indexOf(this.tableView.lastSelected.model)) {
            first = this.bodyView.collection.indexOf(this.tableView.lastSelected.model);
            last = this.bodyView.collection.indexOf(this.model);
          } else {
            first = this.bodyView.collection.indexOf(this.model);
            last = this.bodyView.collection.indexOf(this.tableView.lastSelected.model);
          }

          for (var i = first; i <= last; i++)
            this.bodyView.children.findByModel(this.bodyView.collection.at(i)).select();
        } else {
          this.tableView.unSelectAll();
          this.select();
        }

        this.tableView.lastSelected = this;
      }

      if(this.tableView.onMouseDown)
        this.tableView.onMouseDown(this, e);

      if(this.tableView.onSelected)
        this.tableView.onSelected(this.tableView.getSelected());
    },

    isSelected: function() {
      return this.selected;
    },

    select: function() {
      this.selected = true;
      this.$el.addClass('selected');
    },

    unSelect: function() {
      this.selected = false;
      this.$el.removeClass('selected');
    }
  });
});  
