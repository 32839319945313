define('tableFieldSelectEditView',[
  'module',
  'selectView'
], function (
  Module,
  SelectView
) {
  'use strict';

  Module.exports = SelectView.extend({
    className: 'table-field-select-edit-view table-field-edit-view'
  });
});

