/**
 * Created by OLD on 20/11/2015.
 */
define('fCmessagesView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'dateUtils',
  'template!fCmessagesTpl',
  'rolesMixin',
  'zoomPopover',
  'translatePopoverBehavior'
], function(
  module,
  _,
  Marionette,
  App,
  Settings,
  dateConverter,
  viewTpl,
  RolesMixin,
  ZoomPopoverBehavior,
  TranslatePopoverBehavior
) {
  'use strict';

  var MessagesView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      toolTip: '[data-toggle="tooltip"]',
      imageCells: '.js-image-cell',
      attachmentFiles: '.js-attachment-file'
    },

    behaviors: {
      ZoomPopover: {
        behaviorClass: ZoomPopoverBehavior,
        manualInit: true,
        container: '.message-container'
      },

      TranslatePopHover: {
        behaviorClass: TranslatePopoverBehavior,
        target: '.translation',
        languagesTo: [Settings.get('lang'), 'uk']
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();

      templateData.time = dateConverter.toTimeFormatString(templateData.date);
      templateData.day = dateConverter.toDateFormatString(templateData.date);
      templateData.content = templateData.content || '';
      _.each(templateData.attachements, function(item) {
        if(item.fileLink) {
          item.fileLink = Settings.url('base', item.fileLink);
        }
        if (item.downloadLink && window.location.href.indexOf('https') === 0) {
          item.downloadLink = item.downloadLink.replace('http:', 'https:');
        }
      });

      if (templateData.userPid && templateData.userPid.logo) {
        templateData.fileUrl = Settings.url('file', templateData.userPid.logo, {size: 'thumbnail'});
      } else {
        templateData.fileUrl = null;
      }
      templateData.vectorizator = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR];
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.showAttachments();
      this.ui.toolTip.tooltip();
    },

    showAttachments: function() {
      var that = this,
          counter = 0,
          attachements = this.model.get('attachements');
      _.each(attachements, function(item, index) {
        var imageCell, attachmentFile;
        if(item.fileLink) {
          imageCell = that.ui.imageCells.filter('.js-' + index);
          attachmentFile = that.ui.attachmentFiles.filter('.js-' + index);
          $('<img/>').attr('src', Settings.url('base', item.fileLink)).load(function() {
            $(this).remove();
            imageCell.css('background-image', 'url(' + Settings.url('base', item.fileLink) + ')');
            imageCell.show();
            that.triggerMethod('show:image:zoom:popover', item.fileLink, imageCell);
            counter++;
            that.checkAttCounter(attachements,counter);
          }).error(function() {
            counter++;
            that.checkAttCounter(attachements,counter);
            $(this).remove();
            attachmentFile.show();
          });
        }
      });
      if (attachements.length === 0){
        this.trigger('attachedViewShown');
      }
    },

    //trigger it only once for message when images have loaded
    checkAttCounter: function(attachements,counter) {
      var length = attachements.length;
      if (length === counter) {
        this.trigger('attachedViewShown');
      }
    },

    onBeforeDestroy: function() {
      this.ui.toolTip.tooltip('destroy');
    }
  });

  module.exports = MessagesView;
});
