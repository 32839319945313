/**
 * Created by Matteo on 11/07/2015.
 */
define('messagesShowController',[
  'module',
  'underscore',
  'app',
  'messagesShowView',
  'ticketsAllController',
  'settings',
  'rolesMixin'
], function(
  module,
  _,
  App,
  View,
  TicketsAllController,
  Settings,
  RolesMixin
) {
  'use strict';

  var CaccountDetailsController = {

    showMessages: function(secId, tab) {
      this.tab = tab;
      this.secId = secId;
      var view = new View({model: this.model, tab: tab});

      this.view = view;
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
      this.displayTabs(secId, tab);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
    },

    displayTabs: function(secId, tab) {
      var region;
      if (tab === 'messages' &&
          !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_MSG]) {
        this.view.options.tab = 'messages';
        this.onTabClick('messages-cs');
        return;
      }
      switch(tab) {
        case 'messages':
            region = this.view.getRegion('messagesTab');
            TicketsAllController.showTickets(secId, false, region, tab);
            break;
        case 'messages-cs':
            region = this.view.getRegion('messagesCsTab');
            TicketsAllController.showTickets(secId, true, region, tab);
            break;
      }
    },

    onTabClick: function(tab) {
      if (this.tab !== tab || this.secId) {
        this.tab = tab;
        App.navigate('graphical_service_kanban/' + this.secId + '/' + tab);
        this.displayTabs(this.secId, tab);
      }
    }
  };

  module.exports = CaccountDetailsController;
});
