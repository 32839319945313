define('ordersBoardGroupCardsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'settings',
  'underscore',
  'events',
  'contextMenu',
  'ordersController',
  'ordersBoardCardView',
  'template!ordersBoardGroupCardsView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  Settings,
  _,
  Events,
  ContextMenu,
  Controller,
  CardView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: CardView,
    className: 'orders-board-kanban-cards-group-cards-view',
    childViewContainer: '.cards-container',

    ui: {
      cardsContainer: '.cards-container'
    },

    childViewOptions: function() {
      return {
        type: this.options.type,
        kanbanView: this.options.kanbanView,
        boardView: this.options.boardView,
        cards: this.options.cards,
        groupView: this.options.groupView,
        groupCardsView: this
      };
    },

    collectionEvents: {
      'add': function () {
        this.updateZIndexes();
        this.updateMinimized();

      },
      'remove': function () {
        this.updateZIndexes();
        this.updateMinimized();
      },
      'change:priority': function (view) {
        this.trigger('change:priority', view);
      },
      'change:cardColor': function (view) {
        this.trigger('change:cardColor', view);
      },
      'change:userpids': function (view) {
        this.trigger('change:userpids', view);
      },
      'change:nbrPackSentToPrinter': function (view) {
        this.trigger('change:nbrPackSentToPrinter', view);
      },
      'change:nbrPackDeliveredToPrinter': function (view) {
        this.trigger('change:nbrPackDeliveredToPrinter', view);
      },
      'change:nbrPackSent': function (view) {
        this.trigger('change:nbrPackSent', view);
      },
      'change:nbrPackDelivered': function (view) {
        this.trigger('change:nbrPackDelivered', view);
      },
      'change:expShippingDate': function (view) {
        this.trigger('change:expShippingDate', view);
      },
      'change:deadlineDate': function (view) {
        this.trigger('change:deadlineDate', view);
      },
      'change:actionRequiredInternal': function (view) {
        this.trigger('change:actionRequiredInternal', view);
      },
      'change:actionRequiredUkr': function (view) {
        this.trigger('change:actionRequiredUkr', view);
      }
    },

    /*filter: function (model) {
      if(!this.options.groupCardsView.model.get('minimized'))
        return true;

      var index = this.collection.indexOf(model);

      if(index < 3)
        return true;
      else
        return false;
    },*/

    initialize: function () {
      this.status = this.options.status;
    },

    onRender: function () {
      this.renderSortable();
    },

    renderSortable: function () {
      this.ui.cardsContainer.sortable({
        connectWith: '.cards-container',
        placeholder: 'card-placeholder',
        opacity: 0.8,
        cursor: 'move',
        start: _.bind(function (event, ui) {
          this.trigger('onDrag');

          this.options.kanbanView.multiSelect = [];

          var view = null;
          _.each(this.children._views, function (v) {
            if(v.$el[0] === ui.item[0])
              view = v;
          }, this);

          if(!view)
            return;

          view.model.set('grouped', false);

          this.options.kanbanView.multiSelect.push(view);

          this.children.each(_.bind(function(selectedView) {
            if(selectedView.isSelected() && selectedView.cid !== view.cid) {
              this.options.kanbanView.multiSelect.push(selectedView);
              selectedView.$el.hide();
            }
          }, this));

          /*_.each(this.getSelectedCards(), _.bind(function (selectedView) {
            if(selectedView.model.get('purchaseOrderId') !== view.model.get('purchaseOrderId')) {
              if(!selectedView.model.get('orders')) {
                this.options.kanbanView.multiSelect.push(selectedView.model);
                selectedView.$el.hide();
              }
            }
          }, this));*/
        }, this),
        receive: _.bind(function (event, ui) {
          var position = ui.item.index();
          ui.item.remove();
          this.trigger('onStopDrag');

          var orders = [];

          _.each(this.options.kanbanView.multiSelect, _.bind(function (view) {
            if(view.type === 'card')
              orders.push(view.model);
            if(view.type === 'group')
              orders = orders.concat(view.model.get('orders').models);
          }, this));

          _.each(orders, _.bind(function (order) {
            var oldStatus = order.get('status');
            order.updateStatus(this.status).done(_.bind(function () {
              try {
                this.options.kanbanView.getStatusView(oldStatus).fetchCount();
                this.options.kanbanView.getStatusView(this.status).fetchCount();
              } catch (e) {
                console.log(e);
              }
            }, this));

            try {
              if(order.get('status') === this.status)
                this.options.kanbanView.getStatusView(this.status).removeOrder(order);

              order.set('status', this.status);
              order.set('position', position);
              this.options.kanbanView.getStatusView(this.status).addOrder(order);
            } catch (e) {
              console.log(e);
            }
          }, this));

          //-------------------
          /*var usersOrders = [];

          _.each(orders, _.bind(function (order) {
            var userOrder = null;
            _.each(usersOrders, _.bind(function (uo) {
              if (uo.userId === order.get('endUserId'))
                userOrder = uo;
            }, this));

            if (userOrder === null) {
              userOrder = {
                userId: order.get('endUserId'),
                orders: []
              };
              usersOrders.push(userOrder);
            }

            userOrder.orders.push(order);
          }, this));*/

          Controller.sendMail(orders, this.options.mailParams);

          //-------------------
          this.options.kanbanView.unSelectAll();
        }, this),
        stop: _.bind(function (event, ui) {
          this.trigger('onStopDrag');

          //-------------------
          _.each(this.options.kanbanView.multiSelect, _.bind(function (view) {
            view.$el.css('display', 'flex');
          }, this));

          //-------------------
          var view = null;
          _.each(this.children._views, function (v) {
            if(v.$el[0] === ui.item[0])
              view = v;
          }, this);

          if(view) {
            view.model.set('position', ui.item.index());
            view.model.set('grouped', true);

            this.options.kanbanView.getStatusView(this.status).removeOrder(view.model);
            this.options.kanbanView.getStatusView(this.status).addOrder(view.model);
          }
        }, this)
      });
    },

    getSelectedCards: function () {
      var views = [];

      this.children.each(_.bind(function(view) {
        if(view.isSelected())
          views.push(view);
      }, this));

      return views;
    },

    unSelectAll: function () {
      _.each(this.children._views, function (view) {
        view.unSelect();
      });
    },

    select: function () {
      _.each(this.children._views, function (view) {
        view.select();
      });
    },

    updateZIndexes: function () {
      _.each(this.children._views, function (view) {
        view.renderZIndex();
      });
    },

    updateMinimized: function () {
      _.each(this.children._views, function (view) {
        view.renderMinimized();
      });
    }
  });
});
  
