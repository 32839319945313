define('ordersDetailTrackingEditCarrierView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'template!ordersDetailTrackingEditCarrierView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-edit-carrier-view',
    selected: null,

    ui: {
      items: '.items',
      item: '.items > .item'
    },  

    events: {
      'click @ui.item': 'onSelect'      
    }, 

    initialize: function () {
      this.selected = this.model.get('carrier');
    },

    onRender: function () {
      this.ui.items.find('> .' + this.selected).addClass('selected');
    },

    select: function (carrier) {
      this.selected = carrier;
      this.ui.items.find('> .item').removeClass('selected');
      this.ui.items.find('> .' + this.selected).addClass('selected');     
    },

    onSelect: function (event) {

      this.selected = $(event.currentTarget).attr('data-id');
      this.ui.items.find('> .item').removeClass('selected');
      this.ui.items.find('> .' + this.selected).addClass('selected');
      this.trigger('select', this.selected);
    }
  });
});
