
define('template!pricelistAddNewPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pricelist-add-new-container">\n\t<div class="container-fluid">\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n("popup.price.code") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control code js-form-input"\n\t\t\t\t\t\tdata-field-name="code"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = (_.i18n("popup.price.code") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( code )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n("popup.priceList.name") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control name js-form-input"\n\t\t\t\t\t\tdata-field-name="name"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = (_.i18n("popup.priceList.name") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( name )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\n            <div class="form-group" data-field-name="example">\n                <div class="col-xs-12">\n                    <span class="">' +
((__t = ( _.i18n("popup.priceList.algo.info") )) == null ? '' : __t) +
'<br/>' +
((__t = ( _.i18n("popup.priceList.algo.example"))) == null ? '' : __t) +
'</span>\n                    <span class="price-algo-example" > </span>\n                </div>\n            </div>\n\n\t\t\t<div class="form-group" data-field-name="priceAlgo">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n("popup.priceList.algo") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-5">\n\t\t\t\t\t<select class="form-control priceAlgo js-form-input select-priceAlgo" data-field-name="priceAlgo" value="' +
((__t = ( priceAlgo )) == null ? '' : __t) +
'">\n                        <option value="RSP_MULTIPLY"';
  if (priceAlgo === "RSP_MULTIPLY") { ;
__p += ' selected ';
 } ;
__p += ' >\n                            ' +
((__t = (_.i18n("popup.priceList.RSP_MULTIPLY"))) == null ? '' : __t) +
'\n                        </option>\n\t\t                <option value="RSP_ADD" ';
  if (priceAlgo === "RSP_ADD") { ;
__p += ' selected ';
 } ;
__p += ' >\n                            ' +
((__t = (_.i18n("popup.priceList.RSP_ADD") )) == null ? '' : __t) +
'\n                        </option>\n\t\t            </select>\n\t\t\t\t</div>\n                <div class="col-xs-3" data-field-name="algoParam1">\n                    <input\n                            class="form-control algoParam1 js-form-input input-priceAlgo"\n                            data-field-name="algoParam1"\n                            placeholder=\'' +
((__t = (_.i18n("popup.priceList.algoParam") )) == null ? '' : __t) +
'\'\n                            type="number"\n                            value="' +
((__t = ( algoParam1 )) == null ? '' : __t) +
'">\n                </div>\n\t\t\t</div>\n\n\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

