define('commentEditView',[
  'module',
  'jquery',
  'underscore',
  'textareaView',
  'modalsConfirmationView',
  'trumbowyg',
  'trumbowygMention',
  'events',
  'template!commentEditView'
], function(
  Module,
  $,
  _,
  TextareaView,
  ModalsConfirmationView,
  Trumbowyg,
  TrumbowygMention,
  Events,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'comment-edit-view ' + ModalsConfirmationView.prototype.className,
    confirmWithEnter: false,

    ui: _.extend({
      textarea: 'textarea'
    }, ModalsConfirmationView.prototype.ui),

    regions: _.extend( {
      textRegion: '.text-region'
    }, ModalsConfirmationView.prototype.regions),

    onShow: function() {
      /*this.textView = new TextareaView({
        value: this.model.get('comment'),
        tabindex: 1
      });
      this.getRegion('textRegion').show(this.textView);*/

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      this.trumbowyg = this.ui.textarea.trumbowyg({
        removeformatPasted: true,
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fontsize']
        ],
        plugins: {
          allowTagsFromPaste: true,
          mention: {
          }
        }
      });
      this.ui.textarea.trumbowyg('html', this.model.get('comment'));

      this.trumbowyg.on('tbwinit', function(){
        var editor = $('.trumbowyg-editor').get(0);
        var range = document.createRange();
        var sel = window.getSelection();
        range.selectNodeContents(editor);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);

        $('.trumbowyg-editor').focus().select();
      });
    },

    onDestroy: function() {
      this.trumbowyg.trumbowyg('destroy');
      ModalsConfirmationView.prototype.onDestroy.apply(this, arguments);
    },

    onConfirm: function () {
      var value = this.ui.textarea.trumbowyg('html');
      value = value.replace(/<p><\/p>/g, '');
      value = value.replace(/<p>\n<\/p>/g, '');
      if(this.options.onConfirm)
        this.options.onConfirm(value);
      this.windowView.close();
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'commentEditView'
      ], function (Windows, View) {
        var title = '';
        if(data.title)
          title = data.title;
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          title: title,
          width: '60%',
          maxHeight: 600
        });
      });
    },


    onKeyDown: function(e) {
      if (e.which === 9) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      ModalsConfirmationView.prototype.onKeyDown.apply(this, arguments);
    }
  });
});
