/**
 * Created by BFR on 18/02/2020.
 */
define('productEditsTabView',[
  'module',
  'app',
  'jquery',
  'marionette',
  'underscore',
  'dynamicTextView',
  'template!productEditsTabTpl',
  'editCatsView',
  'editPrintAreasView',
  'editVariationsView',
  'settings',
  'rolesMixin',
  'popupPrintAreaView',
  'popupOptionCategoryView',
  'popupProductView',
  'popupVariationView',
  'productPicturesTabCollectionView',
  'addNewPictureButtonView',
  'pluginUtils',
  'entities/product_rel',
  'entities/product_picture',
  'addNewPictureButtonView'
], function (
  module,
  App,
  $,
  Marionette,
  _,
  DynamicTextView,
  viewTpl,
  EditCatsView,
  EditPrintAreasView,
  EditVariationsView,
  Settings,
  RolesMixin,
  PopupPrintAreaView,
  PopupOptionCategoryView,
  PopupProductView,
  PopupVariationView,
  PictureCollectionView,
  AddNewPictureButtonView,
  PluginUtils
) {
  'use strict';

  var ProductEditsView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      newPicture: '.js-new-picture-region',
      pictures: '.js-pictures-region',
      nameRegion: '.name-region',
      descriptionRegion: '.description-region',
      editCats: '.edit-cats',
      editPrintAreas: '.edit-printareas',
      editVariations: '.edit-variations'
    },

    modelEvents: {
      sync: 'render'
    },

    ui: {
      btnContainer: '.btn-pa-container'
    },

    events: {
      'click .btn-edit-product': 'onEditProductClick',
      'click .btn-generate-textile-pas': 'onGeneratePaClick',
      'click .btn-add-printarea': 'onAddPrintAreaClick',
      'click .btn-add-optioncategory': 'onAddOptionCategoryClick',
      'click .btn-add-variation': 'onAddVariationClick',
      'mouseover .product-edit': 'onMouseOver',
      'mouseout .product-edit': 'onMouseOut'
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    initialize: function () {
      this.modelTrans = this.options.modelTrans;
    },

    onDestroy: function () {
      $(document).unbind('keydown', this.onKeyPress);
    },

    onShow: function () {
      $(document).bind('keydown', this, this.onKeyPress);
    },

    onRender: function () {
      var optCatCol = this.model.get('productOptionCategories');
      this.editCatsView = new EditCatsView({collection: optCatCol});
      this.getRegion('editCats').show(this.editCatsView);

      var paCol = this.model.get('printAreas');
      paCol.modelProduct = this.model;
      this.editPrintAreasView = new EditPrintAreasView({collection: paCol});
      this.getRegion('editPrintAreas').show(this.editPrintAreasView);

      this.nameDtView = new DynamicTextView({model: this.model.get('name'), owner: this.model.get('supplierSecId')});
      this.getRegion('nameRegion').show(this.nameDtView);

      this.descriptionDtView = new DynamicTextView({model: this.model.get('description'), owner: this.model.get('supplierSecId')});
      this.getRegion('descriptionRegion').show(this.descriptionDtView);

      var variationsCol = this.model.get('productCodeVariations');
      this.editVariationsView = new EditVariationsView({collection: variationsCol});
      this.getRegion('editVariations').show(this.editVariationsView);

      this.picturesView = new PictureCollectionView({
        collection: App.request('product-picture:collection'),
        productSecId: this.model.get('secId'),
        productCode: this.model.get('code'),
        templateFileUid: this.model.get('templateFileUid')
      });
      this.getRegion('pictures').show(this.picturesView);
      this.picturesView.collection.fetchByProduct(this.model.get('secId'));

      this.customAddNewPictureButton = new AddNewPictureButtonView({
        productSecId: this.model.get('secId')
      });
      this.customAddNewPictureButton.listenTo(this.customAddNewPictureButton, 'click', _.bind(this._showPopup, this));
      this.getRegion('newPicture').show(this.customAddNewPictureButton);
    },

    _showPopup: function () {
      PluginUtils.callPlugin({
        action: 'editProductPicture',
        productSecId: this.model.get('secId')
      });
    },

    onKeyPress: function (event) {
      if (event.ctrlKey) {
        switch (event.key) {
          case 'g':
            event.data.onGeneratePaClick();
            break;
        }
      }
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.design2 = true;
      } else {
        templateData.design2 = false;
      }
      return templateData;
    },

    onGeneratePaClick: function () {
      $('.js-loading-block').show();
      var updateDataRequest = App.request('products:generate-printareas', this.options.model.get('secId'), 'textile');
      $.when(updateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
        this.trigger('product-overview:reload');
      }, this));
    },

    onAddPrintAreaClick: function () {
      var popupPrintZoneView = new PopupPrintAreaView({
        model: App.request('products-rel:printarea:model', {refProduct: this.model}),
        collection: this.editPrintAreasView.collection
      });
      popupPrintZoneView.model.collection = this.editPrintAreasView.collection;
      popupPrintZoneView.newItem = true;

      popupPrintZoneView.show({
        title: _.i18n('common.add')
      });
    },

    onAddOptionCategoryClick: function () {
      var popupOptionCategoryView = new PopupOptionCategoryView({
        model: App.request('products-rel:optioncategory:model', {refProduct: this.model}),
        product: this.editCatsView.collection
      });
      popupOptionCategoryView.model.collection = this.editCatsView.collection;
      popupOptionCategoryView.newItem = true;

      popupOptionCategoryView.show({
        title: _.i18n('common.add')
      });
    },

    onAddVariationClick: function () {

      var variationModel = App.request('products-rel:optionvariation:model', {refProduct: this.model});    

      var popupVariationView = new PopupVariationView({
        model: variationModel
      });
    
      popupVariationView.newItem = true;

      popupVariationView.show({
        title: _.i18n('common.add')
      });
    },

    onEditProductClick: function () {
      var popupProductView = new PopupProductView({
        model: this.model,
        collection: this.collection
      });
      popupProductView.newItem = false;

      popupProductView.show({
        title: _.i18n('common.edit')
      });
    }
  });

  module.exports = ProductEditsView;
});
