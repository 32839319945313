/**
 * Created by BFR on 13/02/2018.
 */
define('editEndUserAttachmentsPluginView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'iframeFullScreen',
  'template!editEndUserAttachmentsPluginTpl',
  'bootbox'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  IframeFullScreen,
  layoutTpl,
  bootbox
) {
  'use strict';

  var EditEndUserAttachmentsPluginView = Marionette.ItemView.extend({
    template: layoutTpl,

    behaviors: {
      IframeFullScreen: {
        behaviorClass: IframeFullScreen
      }
    },

    events: {
      'click .btn_open_compuzz_plugin': 'onOpenCompuzzPluginClick'
    },

    onOpenCompuzzPluginClick: function () {
      $.ajax({
        type: 'GET',
        url: this.options.url,
        success: function () {
        },
        error: function () {
          bootbox.alert('Compuzz plugin is not running or is outdated \n It can be downloaded on http://web.compuzz.com/app/plugin/');
        }
      });
    },

    /*onRender: function() {
      this.$el = this.$el.children();
      this.$el.unwrap();
      this.setElement(this.$el);
    },*/

    serializeData: function () {
      return {
        url: this.options.url,
        title: 'Edit Attachments'
      };
    }
  });

  module.exports = EditEndUserAttachmentsPluginView;
});
