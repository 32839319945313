define('ordersDetailTrackingEditView',[
  'app',
  'module',
  'backbone',
  'jquery',
  'urlUtil',
  'modalsConfirmationView',
  'savingBehavior',
  'underscore',  
  'entities/orders',
  'ordersDetailTrackingEditCarrierView',
  'template!ordersDetailTrackingEditView'
], function (
  App,
  Module,
  Backbone,
  $,
  UrlUtil,
  ModalsConfirmationView,
  SavingBehavior,
  _,
  Orders,
  CarrierView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-edit-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      target: '> .body > .target',
      targetItem: '> .body > .target > .items > .item',
      trackingUrl: '> .body > .url > textarea',
      trackingNumber: '> .body > .number > input',
      trackingStatus: '> .body > .status > input',
      errorMessage: '> .body > .error-message'
    }, ModalsConfirmationView.prototype.ui),

    events: {
      'click @ui.targetItem': 'onSelectTarget',
      'change @ui.trackingUrl': 'onPasteUrl',
      'paste @ui.trackingUrl': 'onPasteUrl'
    }, 
    
    regions: _.extend( {
      carrierRegion: '.carrier-region'
    }, ModalsConfirmationView.prototype.regions),

    initialize: function (options) {
      this.onSaved = options.confirm;
      this.selectedTarget = '';
      this.validated = false;

      if(this.model.get('destinationType') === 'tocustomer')
        this.selectedTarget = 'tocustomer';
        
      if(this.model.get('destinationType') === 'toprinter')
        this.selectedTarget = 'toprinter';

      $(document).keyup(_.bind(function(e) {        
        if (e.which === 27) this.hide();
      }, this));

      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {      
      this.carrierView = new CarrierView({
        model: this.model
      });      
      this.getRegion('carrierRegion').show(this.carrierView);

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    onRender: function () {
      if(this.selectedTarget === 'tocustomer')
        this.ui.target.find('> .items > .customer').addClass('selected');
           
      if(this.selectedTarget === 'toprinter')
        this.ui.target.find('> .items > .printer').addClass('selected');

      //---------------
      this.ui.trackingUrl.val(this.model.get('trackingUrl'));

      //---------------
      this.ui.trackingNumber.val(this.model.get('trackingNumber'));

      //---------------
      this.ui.trackingStatus.val(this.model.get('trackingStatus'));
    },

    onSelectTarget: function (e) {
      this.ui.target.find('> .items > .item').removeClass('selected');

      if(e.currentTarget === this.ui.target.find('> .items > .customer')[0]) {
        this.ui.target.find('> .items > .customer').addClass('selected');
        this.selectedTarget = 'tocustomer';
      }        

      if(e.currentTarget === this.ui.target.find('> .items > .printer')[0]) {
        this.ui.target.find('> .items > .printer').addClass('selected');
        this.selectedTarget = 'toprinter';
      }
    },

    onConfirm: function () {
      //if (this.validated) {
        var model = this.model.clone();
        model.set('secId', this.model.get('secId'));
        model.set('trackingUrl', this.ui.trackingUrl.val());
        model.set('trackingNumber', this.ui.trackingNumber.val());
        model.set('trackingStatus', this.ui.trackingStatus.val());
        model.set('destinationType', this.selectedTarget);
        model.set('carrier', this.carrierView.selected);
        model.unset('refCommandLine');
        this.options.order.saveTracking(model).done(_.bind(function(data) {
          this.model.set(data);
          this.options.order.fetch();
          if(this.options.confirm)
            this.options.confirm(this.model);
        }, this));

        ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
      //}
    },

    onPasteUrl: function (e) {
      e.stopPropagation();
      var url;

      if (e.type === 'paste' && e.originalEvent.clipboardData.getData('text/plain')) {
        url = e.originalEvent.clipboardData.getData('text/plain');
        this.ui.trackingUrl.val(url);  
      } else if (this.ui.trackingUrl.val()) {
        url = this.ui.trackingUrl.val();
      }

      e.preventDefault();
      if (url) {
        var params;
        if (url.indexOf('dpd') !== -1) {
          this.carrierView.select('dpd');
          this.ui.trackingNumber.val(url.split('/').pop());

        } else if (url.indexOf('tnt') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('tnt');

          if (params.cons)
            this.ui.trackingNumber.val(params.cons);

        } else if (url.indexOf('dhl') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('dhl');

          if (params.tc)
            this.ui.trackingNumber.val(params.tc);
          
        } else if (url.indexOf('bpost') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('bpost');

          if (params.itemCode)
            this.ui.trackingNumber.val(params.itemCode);
          
        } else if (url.indexOf('gls-group') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('gls');

          if (params.match)
            this.ui.trackingNumber.val(params.match);
          
        } else if (url.indexOf('chronopost') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('chronopost');

          if (params.listeNumerosLT)
            this.ui.trackingNumber.val(params.listeNumerosLT);
          
        }
        else if (url.indexOf('ups') !== -1) {
          params = UrlUtil.parseQueryString(url);
          this.carrierView.select('ups');

          if (params.InquiryNumber1)
            this.ui.trackingNumber.val(params.InquiryNumber1);
          
          if (params.tracknum) 
            this.ui.trackingNumber.val(params.tracknum);
        }

      }
    },

    // Static methods
    open: function (model, order, confirm) {
      require([
        'windows',
        'ordersDetailTrackingEditView'
      ], function (Windows, View) {
        var title = _.i18n('orders.detail.tracking.edit.title') + ': ' + model.get('trackingNumber');
        if(!model.get('trackingNumber'))
          title = _.i18n('orders.detail.tracking.new.title');
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model,
              order: order,
              confirm: confirm
            });
          }, this),
          title: title,
          modal: true,
          maxWidth: 800
        });
      });
    }
  });
});

