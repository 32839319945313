/**
 * Created by OLD on 25/11/2015.
 */
define('billingCodeTableView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!billingCodeTableTpl',
  'entities/price/price_billing_table'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var BillingCodeTableView = JqGridView.extend({
    filterEnable: false,
    GRID_MIN_WIDTH: 300,
    template: viewTpl,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-billing-price',
        jqGridPager: '#jq-grid-pager-billing-price',
        jqgRow: 'tr.jqgrow',
        activeCode: 'tr.btn-primary td.billing-code-link>span',
        deleteBtn: '.bililing-delete-link .cell-delete',
        editLink: '.bililing-edit-link .cell-edit'
      }, this.gridUi);     
    },

    events: function() {
      return _.extend({
        'click @ui.deleteBtn' : 'onDeletePriceCode',
        'click @ui.editLink' : 'onEditPriceCode'
      }, this.gridEvents);
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 20,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        tabName = this.options.tabName,
        _colModel = [{
          label: '',
          name: 'secId',
          key: true,
          hidden: true
        },
        {
          label: '',
          name: 'billingCodeType',
          classes: 'billing-codetype-link',
          formatter: this.defaultFormatter,
          hidden: true
        },
        {
          label: _.i18n('popup.price.code'),
          name: 'code',
          classes: 'billing-code-link',
          formatter: this.defaultFormatter,
          search: true,
          index: 'code',
          width: 50
        },
        {
          label: _.i18n('popup.price.name'),
          name: 'name',
          classes: 'billing-link',
          formatter: this.defaultFormatter,
          search: true,
          index: 'name',
          width: 75
        },
        {
          label: '',
          name: '',
          classes: 'bililing-delete-link',
          formatter: this.deleteIconFormatter,
          search: false,
          width: 8
        }];
      
      if (tabName !== 'pricelists') {
        _colModel.splice(4, 0, {
          label: '',
          name: '',
          classes: 'bililing-edit-link hidden',
          formatter: this.editIconFormatter,
          search: false,
          width: 8
        });
      }
      defaultsOptions = this.gridInitOptions(data);
      options = {colModel: _colModel};
      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onLoadComplete: function(data) {
      if (this.options.tabName !== 'pricelists') {
        this.$el.find('.bililing-edit-link').removeClass('hidden');
      }
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      var codeType;

      if (this.codeTableData) {
        codeType = _.findWhere(this.codeTableData, {secId: rowId}).code;
        this.ui.jqgRow.removeClass('btn-primary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-primary');
        this.trigger('billingcode-table:loaded', codeType, rowId);
      } else {
        return;
      }
    },

    onDeletePriceCode: function(e) {
      var rowId = $(e.currentTarget).attr('data-row-id');

      this.trigger('billingcodes:delete-code', rowId);
    },

    onEditPriceCode: function(e) {
      var rowId = $(e.currentTarget).attr('data-row-id');

      this.trigger('billingcodes:update-code', rowId);
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('billingcodes:check-rowcount');
      }
    }
  });

  module.exports = BillingCodeTableView;
});
