
define('template!graphicalRequestKanbanColumnTicketItemViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li data-id="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'"\n    class="js-card-item card-item context-menu-' +
((__t = ( ticket.state?ticket.state.toLowerCase():'' )) == null ? '' : __t) +
'\n  ';
 if (ticket.flagged) { ;
__p += ' marked ';
 } ;
__p += '\n  ';
 if (state && index && ((state === 'open'  && index > 49 ) || (state === 'done' && index > 4))) { ;
__p += ' hide ';
 } ;
__p += '">\n  <div class="row">\n    <div class="col-xs-3 p-r-0">\n      <!-- <div class="file-ext-icon">' +
((__t = ( ticket.ext )) == null ? '' : __t) +
'</div> -->\n      <div data-id="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'" class="card-vectorization-type card-icon-' +
((__t = ( ticket.type )) == null ? '' : __t) +
'"></div>\n      <div class="type-icon m-t-20 text-center">\n        ';
 if (ticket.type === 'vectorization') { ;
__p += '\n        <i class="mdi-image-grain"></i>\n        ';
 } ;
__p += '\n        ';
 if (ticket.type === 'conversion') { ;
__p += '\n        <i class="mdi-av-repeat"></i>\n        ';
 } ;
__p += '\n        ';
 if (ticket.type === 'remove_background') { ;
__p += '\n        <i class="mdi-content-content-cut"></i>\n        ';
 } ;
__p += '\n        ';
 if (ticket.type === 'colorization') { ;
__p += '\n        <i class="mdi-image-palette"></i>\n        ';
 } ;
__p += '\n      </div>\n      ' +
((__t = ( ticket.tariffScale )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-xs-9 p-l-5">\n      <div class="card-description">\n        <div class="kanban-description-1st-line">\n          <span class="kanban-description-date">' +
((__t = ( ticket.date )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="kanban-description-1st-line">\n          <span class="bold-text">' +
((__t = ( ticket.accountCode )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="kanban-description-subject">' +
((__t = ( ticket.name )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="assign-container">\n        <span class="pull-left p-t-5">\n          <!-- <i data-id="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'" class="mdi-navigation-more-horiz context-menu-' +
((__t = ( ticket.state?ticket.state.toLowerCase():'' )) == null ? '' : __t) +
'"></i> -->\n        </span>\n        <span data-id="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'" class="flagg-btn ';
 if (ticket.flagged) { ;
__p += ' flagged ';
 } ;
__p += '">\n          <i class="mdi-content-flag" text="flagg it"></i>\n        </span>\n        <span data-id="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'" class="pull-right assigned-face-block clickable js-assign-to">\n          ';
 if (ticket.userImg && ticket.userImg.indexOf('data:image') === -1) { ;
__p += '\n          <div class="assigned-user-logo" style="background-image:url(' +
((__t = ( ticket.userImg )) == null ? '' : __t) +
')"></div>\n          ';
 } else if (ticket.userImg && ticket.userImg.indexOf('data:image') !== -1) { ;
__p += '\n          <img class="logo-holder js-assigned-user-no-logo" src="' +
((__t = ( ticket.userImg )) == null ? '' : __t) +
'"/>\n          ';
 } else { ;
__p += '\n          <i class="mdi-action-face-unlock"></i>\n          ';
 } ;
__p += '\n        </span>\n        <button disabled data-ticketsecid="' +
((__t = ( ticket.id )) == null ? '' : __t) +
'" class="start-track-btn tracking-button">\n          <i class="mdi-image-timer"></i>\n        </button>        \n      </div>\n    </div>\n  </div>\n  <div class="left-status-marker ';
 if (ticket.urgent) { ;
__p += ' urgent ';
 } ;
__p += '"></div>\n  <div class="right-status-marker ';
 if (ticket.notEnoughCredit) { ;
__p += ' urgent ';
 } else if (ticket.olderThan2Days) { ;
__p += ' medium ';
 } ;
__p += '"></div>\n</li>';

}
return __p
};});

