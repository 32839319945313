/**
 * Created by OLD on 20/08/2015.
 */
define('superAdminAddCreditsView',[
  'module',
  'settings',
  'template!superAdminAddCreditsTpl',
  'dialogFormView',
  'stateMessages'
], function(
  module,
  Settings,
  viewTpl,
  DialogFormView,
  StateMessages
  ) {
  'use strict';

  var AddCreditPopupView = DialogFormView.extend({
    template: viewTpl,

    ui: {
      creditInput: '.input-number-quantity',
      btnSwitch: '.btn-switchToNormal'
    },

    events: {
      'change @ui.creditInput' : 'onInputChange',
      'focus @ui.creditInput': 'onFocus',
      'click @ui.btnSwitch' : 'onSwitch'
    },

    modelEvents: {
      change: 'onChangeModel'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    serializeData: function() {
      return {
        cAccountName: Settings.get('currentCAccount' ).code,
        quantity: this.model.get('quantity')
      };
    },

    onChangeModel: function() {
      this.ui.creditInput.val(this.model.get('quantity')).change();
    },

    onSwitch: function(){
        this.trigger('supercreditpopup:switchToNormalView');
    },

    onRender: function () {
      $.material.init();      
    },

    getTemplate: function() {
        return this.template;
    }
  });

  module.exports = AddCreditPopupView;
});
