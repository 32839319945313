
define('template!productSimilarsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12">\n        <div class="col-xs-12 prices-header-table">\n            <div class="col-xs-3">\n                ' +
((__t = ( _.i18n("product.similar") )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-1 h-16">\n            </div>\n            <div class="col-xs-3">\n                ' +
((__t = ( _.i18n("product.itemNumber") )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-2 h-16">\n            </div>\n            <div class="col-xs-1">\n                ' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-12 similars-items">\n\n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-12">\n        <div class="col-xs-12">\n            <div class="btn btn-add-similar p-l-0 p-r-0 p-t-0 p-b-0">\n                <i class="mdi-content-add"></i>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

