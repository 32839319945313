
define('template!editPimImageTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="image-ctn transparent-bg">\n    <div class="image-region" style="';
 if (cataloguePictureUrl) { ;
__p += 'background-image: url(' +
((__t = ( cataloguePictureUrl )) == null ? '' : __t) +
'); ';
 } ;
__p += '"></div>\n    <div class="image-loader-region"></div>\n</div>\n\n<div class="buttons-ctn">\n    ';
 if (cataloguePictureUrl) { ;
__p += '\n        \n        <div class="button-ctn">\n            <a href="' +
((__t = ( cataloguePictureUrl )) == null ? '' : __t) +
'">\n                <i class="mdi-file-file-download"></i>\n            </a>\n        </div>                \n    ';
 } ;
__p += '\n    \n    ';
 if(allowUpload) { ;
__p += '\n        <label class="button-ctn" for="uploadCatalogInputFile">\n            <i class="mdi-file-file-upload"></i>\n        </label>   \n    ';
 } ;
__p += '\n</div>\n\n<input id="uploadCatalogInputFile" data-key="file" data-property="catalog" multiple type="file" name="filename" class="hidden js-change-logo f-my-ws" accept="image/*"/>\n\n';

}
return __p
};});

