define('ordersDetailSupplierEditProductPrintZoneColorsView',[
  'module',
  'app',
  'backbone',
  'marionette',
  'underscore',
  'ordersDetailSupplierEditProductPrintZoneColorView'
], function (
  Module,
  App,
  Backbone,
  Marionette,
  _,
  ColorView
) {
  'use strict';   

  Module.exports = Marionette.CollectionView.extend({
    childView: ColorView,
    className: 'colors-view',
    childViewContainer: '.colors-container',

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();
      this.collection.add(this.model.get('colors'));
    }
  });
});
