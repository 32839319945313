
define('template!pimProductSelectedAttributeValueTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 Code">' +
((__t = ( value )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

