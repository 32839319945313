/**
 * Created by BFR on 18/02/2020.
 */
define('pimProductEditsTabView',[
  'module',
  'app',
  'jquery',
  'windows',
  'marionette',
  'underscore',
  'bootbox',
  'dynamicTextView',
  'template!pimProductEditsTabTpl',
  'editPimCatsView',
  'editPimImageView',
  'pimProductDetailImagesView',
  'editPimPrintAreasView',
  'editPimVariationsView',
  'settings',
  'productEditsTabView',
  'contextMenu',
  'entities/product_rel'
], function (
  module,
  App,
  $,
  Windows,
  Marionette,
  _,
  bootbox,
  DynamicTextView,
  viewTpl,
  EditPimCatsView,
  EditPimImageView,
  PimProductDetailImagesView,
  EditPimPrintAreasView,
  EditPimVariationsView,
  Settings,
  ProductView
) {
  'use strict';

  var PimProductEditsView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'overview',

    regions: {
      nameRegion: '.name-region',
      editCats: '.edit-cats',
      editPrintAreas: '.edit-printareas',
      editImage: '.edit-image',
      imagesRegion: '.images-region',
      editVariations: '.edit-variations'
    },

    ui: {
      blockLoading: '.js-loading-block'
    },

    events: {
      'click .btn-generate-textile-pas': 'onGeneratePaClick',
      'click .btn-edit-supplier-product': 'onEditSupplierProductClick',
      'click .btn-save-options-categories': 'onBtnSaveOptionsCategoriesClick'
    },

    onEditSupplierProductClick: function () {

      if (this.model.get('compuzzProductSecId')) {
        this.fetchCompuzzProduct();
      } else {
        this.ui.blockLoading.show();
        this.model.createCompuzzProduct().done(_.bind(function () {
          if (this.model.get('compuzzProductSecId')) {
            this.fetchCompuzzProduct();
          } else {
            bootbox.alert('Unable to create the supplier product');
          }
          this.ui.blockLoading.hide();
        }, this));
      }
    },

    fetchCompuzzProduct: function () {
      var product = App.request('products-rel:model', {secId: this.model.get('compuzzProductSecId')});
      var productFetch = product.fetch();

      $.when(productFetch).done(_.bind(function () {
        var pView = new ProductView({
          model: product
        });
        pView.el.className += ' tab-layout-content';
        Windows.open({
          view: _.bind(function () {
            return pView;
          }, this),
          id: 'history:order:' + product.get('secId'),
          title: 'Supplier edit product : ' + product.get('code'),
          configName: 'fullScreen',
          bodyClass: 'product-page'
        });
      }, this));
    },

    onRender: function () {
      this.editPimCatsView = new EditPimCatsView({
        pimProduct: this.model,
        collection: this.model.get('data').get('productOptionCategories')
      });

      this.getRegion('editCats').show(this.editPimCatsView);

      this.editPimPrintAreasView = new EditPimPrintAreasView({
        pimProduct: this.model,
        collection: this.model.get('data').get('printAreas')
      });

      this.getRegion('editPrintAreas').show(this.editPimPrintAreasView);

      this.editPimImageView = new EditPimImageView({model: this.model});
      this.getRegion('editImage').show(this.editPimImageView);

      //----------------
      this.imagesView = new PimProductDetailImagesView({model: this.model});
      this.getRegion('imagesRegion').show(this.imagesView);


      var variationsCol = this.model.get('data').get('productCodeVariations');
      this.editVariationsView = new EditPimVariationsView({
        pimProduct: this.model,
        pimProductId: this.model.get('id'),
        pimSupplierData: this.model.get('supplierData'),
        collection: variationsCol
      });
      this.getRegion('editVariations').show(this.editVariationsView);
    },

    onShow: function () {
      var that = this;
      this.$el.contextMenu({
        selector: '.context-menu-pz',
        callback: function () {
        },
        items: {
          image: {
            name: 'Use as catalogue picture',
            callback: function () {
              that.selectImage(this.find('img').attr('data-link'));
            }
          }
        },
        trigger: 'right'
      });

      this.$el.contextMenu({
        selector: '.context-menu-option',
        callback: function () {
        },
        items: {
          image: {
            name: 'Use as catalogue picture',
            callback: function () {
              that.selectImage(this.data('link'));
            }
          },
          setDefault: {
            name: 'Set as default',
            callback: function () {
              that.setDefaultImage(this.data('index'));
            }
          }
        },
        trigger: 'right'
      });
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.allowEditCompuzz = !!Settings.configValue('allowEditSupplierProduct');
      //-------------
      templateData.name = '';
      _.each(templateData.data.name.values, function (value, lang) {
        if (Settings.get('lang')) {
          if (lang === Settings.get('lang')) {
            templateData.name = value;
          }
        }
      });
      templateData.description = '';
      if (templateData.data.description) {
        _.each(templateData.data.description.values, function (value, lang) {
          if (Settings.get('lang')) {
            if (lang === Settings.get('lang')) {
              templateData.description = value;
            }
          }
        });
      }
      return templateData;
    },

    /*onGeneratePaClick: function () {
      $('.js-loading-block').show();
      var updateDataRequest = App.request('products:generate-printareas', this.options.model.get('secId'), 'textile');
      $.when(updateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
        this.trigger('product-overview:reload');
      }, this));
    },*/

    setDefaultImage: function (index) {
      this.model.getCurrentResProduct().set('defaultIndexPicture', index);
      $.when(this.model.getCurrentResProduct().save()).done(_.bind(function () {
        this.editPimCatsView.render();
      }, this));
    },

    selectImage: function (url) {

      var formData = new FormData(),
        productRequest;

      var fd = {};

      fd.imageUrl = url;

      _.each(fd, function (value, key) {
        formData.append(key, value);
      });

      productRequest = App.request('pim:products:update-catalog-picture-from-url', this.model.getCurrentResProduct().get('id'), formData);
      $.when(productRequest)
        .done(_.bind(function (response) {
          this.model.getCurrentResProduct().set('catalogPictureUid', response.catalogPictureUid);
          //this.editPimImageView.render();
        }, this))
        .fail(_.bind(function () {
          this.editPimImageView.render();
        }, this));
    },

    onBtnSaveOptionsCategoriesClick: function () {

      var productOptionCategories = [];

      _.each(this.model.get('data').get('productOptionCategories').models, _.bind( function (poc){
        var productOptionCategory = {
          pimId: poc.get('pimId'),
          productOptions: []
        };
        _.each(poc.get('productOptions').models, _.bind( function (poco){
          productOptionCategory.productOptions.push({
            pimId: poco.get('pimId'),
            activated: poco.get('activated')
          });
        }, this));
        productOptionCategories.push(productOptionCategory);
      }, this));

      this.model.getCurrentResProduct().set('pimPoResellers', productOptionCategories);

      this.ui.blockLoading.show();

      var p1 = this.model.getCurrentResProduct().saveOptionsCategories();


      $.when(p1).done(_.bind(function () {
        this.ui.blockLoading.hide();
        this.render();// Refresh the variations
      }, this));
    }
  });

  module.exports = PimProductEditsView;
});
