
define('template!shareOptionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row"  ' +
((__t = ( newPo?'style="color: orange; font-weight: bold;"':'' )) == null ? '' : __t) +
'>\n    ';
 if (parentOption) { ;
__p += '\n    <div class="col-xs-1 m-t--7 m-b--5">\n        <input type="checkbox" class="cbx-option m-t-0" style="width: 20px; height: 35px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n    </div>\n    ';
 } ;
__p += '\n    ';
 if (color) { ;
__p += '\n        <div class="col-xs-1" style="width: 20px; height: 20px; background-color: ' +
((__t = ( color )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n    <div class="col-xs-10">' +
((__t = ( newPo?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( checked?option.name:parentOption.name )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

