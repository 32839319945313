
define('template!webshopCategoriesFinderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="list-region"></div>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\nqfqdqqs<br>\n<div class="buttons">\n    <div class="cancel-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

