/**
 * Created by OLD on 23/11/2015.
 */
define('priceTabsController',[
  'module',
  'logger',
  'underscore',
  'app',
  'backbone',
  'settings',
  'bootbox',
  'priceBillingTabView',
  'priceListsTabView',
  'emptyView',
  'billingCodeTableView',
  'billingDetailTableView',
  'billingCodePopupView',
  'billingDetailPopupView',
  'pricelistAddNewPopupView',
  'entities/price/price_billing_table'
], function(
  module,
  Logger,
  _,
  App,
  Backbone,
  Settings,
  bootbox,
  BillingView,
  PriceView,
  EmptyView,
  BillingCodeView,
  BillingDetailView,
  PopupCodeView,
  PopupDetailView,
  PopupAddNewView
) {
  'use strict';

  var PricesController = {
    showTab: function(tab) {
      if (tab === 'billingcodes') {
        this.view = new BillingView();
        this.tabName = 'billingcodes';
        this.showBillingTab();
      } else if (tab === 'pricelists') {
        this.view = new PriceView();
        this.tabName = 'pricelists';
        this.showPriceListTab();
      }
    },

    showBillingTab: function() {
      this.view.listenTo(this.view, 'billingcodes:on-post', _.bind(this.onSendPostBillingCode, this));
      this.view.listenTo(this.view, 'add-new-code:open', _.bind(this.onShowAddNewCodePopup, this));
      this.view.listenTo(this.view, 'add-new-pricelist:open', _.bind(this.onShowAddNewPriceListPopup, this));
      this.view.listenTo(this.view, 'billingcodes:import-csv', _.bind(this.onImportCsv, this));

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('priceTabContent').show(this.view);
      this.view.getRegion('billingCodeTable').show(new EmptyView());
      this.view.getRegion('billingDetailTable').show(new EmptyView());
      this.onShowBillingCodeTable();
    },

    showPriceListTab: function(param) {
      var that = this,
        priceListRequest = App.request('pricelist:get-collection-url'),
        selectedItem = null;
      
      $.when(priceListRequest).done(function(resp) {
        that.view = new PriceView({model: new Backbone.Model(resp)});
        that.view.listenTo(that.view, 'pricelist:show-add-new-popup', _.bind(that.onShowAddNewListItemPopup, that));
        that.view.listenTo(that.view, 'pricelist:show-edit-priceitem-popup', _.bind(that.onShowAddNewListItemPopup, that));
        that.view.listenTo(that.view, 'pricelist:delete-priceitem-popup', _.bind(that.onDeletePriceDropdownItem, that));
        that.view.listenTo(that.view, 'add-new-code:open', _.bind(that.onShowAddNewCodePopup, that));
        that.view.listenTo(that.view, 'pricedropdown:set-new-price-id', _.bind(that.setNewPriceListDropdownId, that));

        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('priceTabContent').show(that.view);
        if (resp.length !== 0) {

          if (param === 'add') {
            // select last added item
            selectedItem = resp[resp.length - 1];

            that.view.preselectDropdownItem(selectedItem.id, selectedItem.name);
            that.setNewPriceListDropdownId(selectedItem.id);

            return;
          } else if (param === 'update') {
            // select currentrly updated item
            selectedItem = _.findWhere(resp, {id: +that.listCode});

            that.view.preselectDropdownItem(selectedItem.id, selectedItem.name);
            that.setNewPriceListDropdownId(selectedItem.id);

            return;
          } else if (param === 'delete') {
            that.listCode = resp[0].id;
          }

          that.listCode = that.listCode ? that.listCode : resp[0].id;
          that.onShowBillingCodeTable();
        } else {
          that.listCode = null;
        }
      });
    },

    onShowBillingCodeTable: function() {
      this.billingCodeView = new BillingCodeView({tabName: this.tabName});

      this.billingCodeView.listenTo(this.billingCodeView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.billingCodeView.listenTo(this.billingCodeView, 'billingcode-table:loaded', _.bind(this.onLoadSubViews, this));
      this.billingCodeView.listenTo(this.billingCodeView, 'billingcodes:delete-code', _.bind(this.onDeleteCode, this));
      this.billingCodeView.listenTo(this.billingCodeView, 'billingcodes:update-code', _.bind(this.onShowAddNewCodePopup, this));
      this.billingCodeView.listenTo(this.billingCodeView, 'billingcodes:check-rowcount', _.bind(this.onCheckBillingCodeRowCount, this));
      this.view.getRegion('billingCodeTable').show(this.billingCodeView);
    },

    onShowBillingDetailTable: function() {
      this.billingDetailView = new BillingDetailView({tabName: this.tabName});

      this.billingDetailView.listenTo(this.billingDetailView, 'pricelists:delete-priceItem', _.bind(this.onDeletePriceItem, this));
      this.billingDetailView.listenTo(this.billingDetailView, 'pricelists:update-item', _.bind(this.onShowAddNewPriceListPopup, this));

      this.view.getRegion('billingDetailTable').show(this.billingDetailView);
      this.onGridDetailLoaded();
    },

    onCheckBillingCodeRowCount: function() {
      this.view.ui.addNewPrice.addClass('disabled');
      this.onShowBillingDetailTable();
    },

    onGridLoaded: function() {
      var data = {};

      if (this.tabName === 'pricelists') {
        data = {
          url: App.request('billingcodes:get-collection-url', this.listCode),
          rowCountUrl: App.request('billingcodes:row-count-url')
        };
      } else {
        data = {
          url: App.request('billingcodes:get-collection-url'),
          rowCountUrl: App.request('billingcodes:row-count-url')
        };
      }
      this.billingCodeView.displayGrid(data, true);
    },

    onLoadSubViews: function(codeType, secId) {
      if (this.billingDetailView) {
        this.billingDetailView.destroy();       
      }
      this.billingCodeType = codeType;
      this.secId = secId;
      this.onShowBillingDetailTable();
      this.onSetFileActionParams();
    },

    onGridDetailLoaded: function() {
      var data = {};

      if (this.billingCodeType) {
        if (this.tabName === 'pricelists') {
          data = {
            url: App.request('billingdetails:get-collection-url', this.billingCodeType, this.listCode),
            rowCountUrl: App.request('billingdetails:row-count-url', this.billingCodeType, this.listCode)
          };
        } else {
          data = {
            url: App.request('billingdetails:get-collection-url', this.secId),
            rowCountUrl: App.request('billingdetails:row-count-url', this.secId)
          };
        }
        this.billingDetailView.displayGrid(data, true);
      }
    },

    setNewPriceListDropdownId: function(listCode) {
      this.listCode = listCode;
      this.billingCodeView.destroy();
      this.onShowBillingCodeTable();
    },

    onSetFileActionParams: function() {
      if (this.view.ui.exportBtn) {
        var code = this.billingCodeView.ui.activeCode.text();
        if(!code && this.view && this.view.ui && this.view.ui.activePriceItem){
          code = this.view.ui.activePriceItem.attr('data-id');
        }

        var exportUrl = Settings.url('compuzz', 'prices.json/priceItems/excel', {listCode: code});
        this.view.ui.exportBtn.removeClass('disabled');
        this.view.ui.exportBtn.attr('href', exportUrl);
        if (this.tabName === 'billingcodes') {
          this.view.ui.addNewPrice.removeClass('disabled');
        }
      }
    },

    onDeletePriceDropdownItem: function(_priceitemId) {
      var that = this;

      bootbox.confirm(_.i18n('common.deleteConfirm'), function (result) {
        if (result) {
          $.when(App.request('pricedlist:send-deleteitem', _priceitemId)).done(function() {
            that.listCode = '';
            if (that.view) {
              that.view.destroy();       
            }
            that.showPriceListTab('delete');
          });
        }
      });
    },

    onDeleteCode: function(codeSecId) {
      var that = this;

      bootbox.confirm(_.i18n('common.deleteConfirm'), function (result) {
        if (result) {
          $.when(App.request('billingcode:send-delete', codeSecId)).done(function() {
            that.billingCodeView.destroy();
            that.onShowBillingCodeTable();
          });
        }
      });
    },

    onDeletePriceItem: function(listSecId) {
      var that = this;
      
      bootbox.confirm(_.i18n('common.deleteConfirm'), function (result) {
        if (result) {
          $.when(App.request('pricelists:send-delete', listSecId, that.secId)).done(function() {
            that.billingDetailView.ui.jqGrid.trigger('reloadGrid');
          });
        }
      });
    },

    onEditBillingCode: function(model) {
      var that = this;

      if (this.tabName === 'pricelists') {
        $.when(App.request('billingcode:send-update', model.toJSON(), that.listCode)).done(function() {
          that.popupCodeView.hide();
          that.billingCodeView.ui.jqGrid.trigger('reloadGrid');
        });
      } else {
        $.when(App.request('billingcode:send-update', model.toJSON()))
          .done(function() {
            that.popupCodeView.hide();
            that.billingCodeView.ui.jqGrid.trigger('reloadGrid');
          })
          .fail(function () {
            that.popupCodeView.hide();
            that.showErrorPopup(_.i18n('warning.codeExists'), 'billingcodesSubmitError');
          });
      }
    },

    onSendUpdatePriceItem: function(model) {
      var that = this;

      if (this.tabName === 'pricelists') {
        $.when(App.request('pricelists:send-update', model.toJSON(), this.secId, this.listCode)).done(function() {
          that.popupDetailView.hide();
          that.billingDetailView.ui.jqGrid.trigger('reloadGrid');
        });
      } else {
        $.when(App.request('pricelists:send-update', model.toJSON(), this.secId)).done(function() {
          that.popupDetailView.hide();
          that.billingDetailView.ui.jqGrid.trigger('reloadGrid');
        });
      }
    },

    onShowAddNewCodePopup: function(_secId) {
      var that = this,
        _title = _secId ? _.i18n('popup.billingCode.edit') : _.i18n('popup.price.add'),
        data, params, model;
      
      if (_secId) {
        data = this.billingCodeView.ui.jqGrid.getRowData(_secId);
        params = {            
          name: $(data.name).text(),
          code: $(data.code).text(),
          billingCodeType: $(data.billingCodeType).text(),
          secId: _secId
        };
      }

      model = App.request('billingcode:get-model', params);

      this.popupCodeView = new PopupCodeView({
        model: model
      });
      this.popupCodeView.show({
        title: _title,
        className: 'billingcodesAddNew',
        buttons: {
          cancel: {
              label: _.i18n('common.cancel'),
              className: 'btn-default',
              callback: function() { return true; }
          },
          main: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary b-add-new-billing-code',
            callback: function(e) {
              if (_secId) {
                that.onEditBillingCode(that.popupCodeView.model);
              } else {
                $(e.currentTarget).addClass('disabled');
                that.onAddNewBillingCode(that.popupCodeView.model);
              }
              return false;
            }
          }
        }
      });
    },

    onShowAddNewPriceListPopup: function(priceSecId) {
      var that = this,
        _title = priceSecId ? _.i18n('popup.price.edit') : _.i18n('popup.price.new'),
        model, data, params;
      
      if (priceSecId) {
        data = this.billingDetailView.ui.jqGrid.getRowData(priceSecId);
        params = {
          secId: priceSecId,
          quantity: $(data.quantity).text(),
          costPrice1: $(data.costPrice1).text(),
          leadTime1: $(data.leadTime1).text(),
          rsPrice1: $(data.rsPrice1).text(),
          editable: true,
          tabName: this.tabName
        };
      }
      
      model = App.request('billingdetails:get-model', params);
      this.popupDetailView = new PopupDetailView({
        model: model
      });

      this.popupDetailView.show({
        title: _title,
        className: 'billingcodesAddNewPriceList',
        buttons: {
          cancel: {
              label: _.i18n('common.cancel'),
              className: 'btn-default',
              callback: function() { return true; }
          },
          main: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary b-add-new-billing-pricelist',
            callback: function() {
              if (priceSecId) {
                that.onSendUpdatePriceItem(that.popupDetailView.model);
              } else {
                that.onAddNewBillingPriceList(that.popupDetailView.model);
              }
              return false;
            }
          }
        }
      });
    },

    onShowAddNewListItemPopup: function(_pricelistId) {
      var that = this,
        _title = _pricelistId ? _.i18n('popup.priceList.edit') : _.i18n('popup.priceList.add'),
        model, data, params;

      if (_pricelistId) {
        data = _.findWhere(this.view.model.toJSON(), {id: +_pricelistId});
        params = {
          id: +_pricelistId,
          code: data.code,
          name: data.name,
          validityFrom: null,
          validityTo: null,
          prices: [],
          priceAlgo: data.priceAlgo,
          algoParam1: data.algoParam1
        };
      }
      
      model = App.request('pricelist:get-price-model', params);

      this.priceAddNewPopupView = new PopupAddNewView({model: model});

      this.priceAddNewPopupView.show({
        title: _title,
        className: 'pricelistAddNew',
        buttons: {
          cancel: {
              label: _.i18n('common.cancel'),
              className: 'btn-default',
              callback: function() { return true; }
          },
          main: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary b-add-new-pricelist-popup',
            callback: function() {
              if (_pricelistId) {
                that.onSendPriceDropdownUpdateRequest(that.priceAddNewPopupView.model);
              } else {
                that.onAddNewPriceItem(that.priceAddNewPopupView.model);
              }
              return false;
            }
          }
        }
      });
    },

    onAddNewBillingPriceList: function(model) {
      var that = this,
        postRequest = App.request('billingdetails:send-post', model.toJSON(), this.secId);

      $.when(postRequest)
        .done(function() {
          that.popupDetailView.hide();
          that.onLoadSubViews(that.billingCodeType, that.secId);
        }).fail(function () {
          that.popupDetailView.hide();
          that.showErrorPopup(_.i18n('price.alreadyExists'), 'addNewPriceError');
        });
    },    

    onAddNewPriceItem: function(model) {
      var that = this;

      $.when(App.request('pricelist:post-new-price', model.toJSON()))
        .done(function() {
          that.priceAddNewPopupView.hide();
          if (that.view) {
            that.view.destroy();       
          }
          that.showPriceListTab('add');
        }).fail(function (jqXHR) {
          if(jqXHR.status === 409) {
            that.priceAddNewPopupView.hide();
            that.showErrorPopup(_.i18n('price.list.alreadyExists'), 'addNewPriceError');
          }
        });
    },

    onSendPriceDropdownUpdateRequest: function(model) {
      var that = this;

      $.when(App.request('pricelist:update-pricedropdown', model.toJSON()))
        .done(function() {
          that.priceAddNewPopupView.hide();
          if (that.view) {
            that.view.destroy();       
          }
          that.showPriceListTab('update');
        });
    },

    onAddNewBillingCode: function(model) {
      this.onSendPostBillingCode(model.toJSON());
    },

    onSendPostBillingCode: function(data) {
      var that = this,
        postRequest;

      if (this.tabName === 'pricelists') {
        postRequest = App.request('billingcode:send-post', data, this.listCode);
      } else {
        postRequest = App.request('billingcode:send-post', data);
      }

      $.when(postRequest)
        .done(function() {
          that.popupCodeView.hide();
          that.billingCodeView.destroy();
          that.onShowBillingCodeTable();
        })
        .fail(function () {
          that.popupCodeView.hide();
          that.showErrorPopup(_.i18n('warning.codeExists'), 'billingcodesSubmitError');
        });
    },

    showErrorPopup: function (message, className) {
      bootbox.dialog({
        message: '<p>' + message + '</p>',
        title: _.i18n('common.request.error'),
        className: className
      });
    },

    onImportCsv: function (fileInfo) {
      var that = this;
      this.view.getRegion('billingCodeTable').show(new EmptyView());
      $.when(App.request('billingcodes:upload-csv', fileInfo)).done(function () {
        that.billingCodeView.destroy();
        that.onShowBillingCodeTable();
      });
    }
  };

  module.exports = PricesController;
});
