define('customersDetailBodyInvoicesView',[
  'module',
  'backbone',
  'underscore',
  'app',
  'tableView',
  'tableDatetimeFieldView',
  'ordersDetailInvoicesPricesView',
  'ordersDetailInvoicesSentDateView',
  'ordersDetailInvoicesToolsView',
  'entities/endUserInvoice'
], function (
    Module,
    Backbone,
    _,
    App,
    TableView,
    DatetimeFieldView,
    PricesView,
    SentDateView,
    ToolsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'customers-detail-body-invoices-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'invoiceDate',
        className: 'date',
        view: DatetimeFieldView
      });

      fields.push({
        name: 'number',
        label: _.i18n('common.number'),
        bind: 'formatedInvoiceNumber',
        className: 'number'
      });

      fields.push({
        name: 'Type',
        label: _.i18n('common.type'),
        bind: 'invoiceType',
        className: 'type'
      });

      fields.push({
        name: 'Amount',
        className: 'amount',
        label: _.i18n('common.amount'),
        view: PricesView
      });

      fields.push({
        name: 'paymentDelay',
        label: _.i18n('orders.paymentDelay'),
        bind: 'paymentDelay',
        className: 'paymentDelay'
      });

      fields.push({
        name: 'sentDate',
        label: _.i18n('common.sentDate'),
        className: 'sentDate',
        view: SentDateView
      });

      fields.push({
        name: 'Tools',
        className: 'tools',
        view: ToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize) {
      var defer = $.Deferred();

      var params = {};
      params.pageSize = pageSize;
      params.first = first;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: this.model.getInvoicesUrl(params),
        success: _.bind(function (items) {
          var models = [];
          _.each(items, _.bind(function(item) {
            var model = App.request('endUserInvoice:model', item);
            models.push(model);
          }, this));
          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function() {
      var defer = $.Deferred();

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: this.model.getInvoicesRowCountUrl({}),
        success: _.bind(function (counts) {
          defer.resolve(counts);
        }, this)
      });

      return defer.promise();
    }
  });
});
