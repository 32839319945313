define( 'entities/caccounts/contacts',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var CAccountContacts = Backbone.Model.extend({
    defaults: {
      addressL1: '',
      addressL2: '',
      city: '',
      comment: '',
      countryCode: '',
      fax: '',
      firstName: '',
      lastName: '',
      name: '',
      tel: '',
      zip: '',
      email: '',
      secId: ''
    },

    _saveModel: function(caccountSecId) {
      var model = new Backbone.Model({
        refAddress: {
          addressL1: this.get('addressL1'),
          addressL2: this.get('addressL2'),
          city: this.get('city'),
          comment: this.get('comment'),
          countryCode: this.get('countryCode'),
          fax: this.get('fax'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          name: this.get('name'),
          tel: this.get('tel'),
          zip: this.get('zip')
        },
        email: this.get('email'),
        secId: this.get('secId') ? this.get('secId') : null
      });
      model.listenTo(model, 'sync', _.bind(this._modelSaved, this));
      model.url = Settings.url('compuzz', 'v2/caccountcontact/caccount/' + caccountSecId);
      model.save();
    },

    _modelSaved: function() {
      this.trigger('save:event:done');
    },

    idAttribute: 'secId'
  });

  var ContactItemModel = Backbone.Model.extend({
    defaults: {
    },

    _fetchModel: function(contactSecId) {
      var defer = $.Deferred();

      this.contactSecId = contactSecId;
      this.url = Settings.url('compuzz', 'v2/caccountcontact/' + contactSecId);
      this.fetch().done(function(){
        defer.resolve();
      });

      return defer.promise();
    },

    parse: function(models) {
      var model;

      if (_.isArray(models)) {
        model = _.findWhere(models, {secId: this.contactSecId});
      } else {
        model = models;
      }
      return {
        addressL1: model.refAddress.addressL1,
        addressL2: model.refAddress.addressL2,
        city: model.refAddress.city,
        comment: model.refAddress.comment,
        countryCode: model.refAddress.countryCode,
        fax: model.refAddress.fax,
        firstName: model.refAddress.firstName,
        lastName: model.refAddress.lastName,
        name: model.refAddress.name,
        tel: model.refAddress.tel,
        zip: model.refAddress.zip,
        email: model.email,
        secId: model.secId ? model.secId : null
      };
    },

    _convertToCustom: function() {
      this.set('refAddress', {
          addressL1: this.get('addressL1'),
          addressL2: this.get('addressL2'),
          city: this.get('city'),
          comment: this.get('comment'),
          countryCode: this.get('countryCode'),
          fax: this.get('fax'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          name: this.get('name'),
          tel: this.get('tel'),
          zip: this.get('zip')
        });
      this.set('email', this.get('email'));
      this.set('secId', this.get('secId'));
    },

    _updateModel: function() {
      this._convertToCustom();
      this.url = Settings.url('compuzz', 'v2/caccountcontact');
      this.save();
    },

    idAttribute: 'secId'
  });
  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(caccountSecId) {
      return 'v2/caccountcontact/caccount/'+caccountSecId+'/rowCount?';
    },

    getCustomersContactsUrl: function(caccountSecId) {
      return Settings.url('compuzz', 'v2/caccountcontact/caccount/'+caccountSecId);
    },

    deleteContactsItem: function(contactSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'v2/caccountcontact/' + contactSecId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccounts:contacts:row-count-url', function(caccountSecId) {
      return API.getRowCountUrl(caccountSecId);
  });

  App.reqres.setHandler('caccounts:contacts:get-url', function(caccountSecId) {
    return API.getCustomersContactsUrl(caccountSecId);
  });

  App.reqres.setHandler('caccounts:contacts:get-model:item', function() {
    return new ContactItemModel();
  });

  App.reqres.setHandler('caccounts:contacts:new-model', function() {
    return new CAccountContacts();
  });

  App.reqres.setHandler('caccounts:contacts:delete-contact', function(contactSecId) {
    return API.deleteContactsItem(contactSecId);
  });
});
