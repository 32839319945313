/**
 * Created by RKL on 18/08/2015.
 */
define('pimResellerProductCodeCollectionView',[
  'module',
  'marionette',
  'pimResellerProductCodeItemView'
], function (
  module,
  Marionette,
  ItemView
) {
  'use strict';

  var PimResellerProductCodeCollectionView = Marionette.CollectionView.extend({
    childView: ItemView
    
    /*childViewOptions: {
      test: "sfsrfqsdfsdfs",
      goes: "here"
    }*/
  });

  module.exports = PimResellerProductCodeCollectionView;
});
