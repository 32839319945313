/**
 * Created by BFR on 02/03/2020.
 */
define('productShareTabView',[
  'module',
  'app',
  'jquery',
  'marionette',
  'underscore',
  'template!productShareTabTpl',
  'sharePrintAreasView',
  'shareOptionCategoriesView',
  'shareProductCodeVariationsView',
  'entities/product'
], function (
  module,
  App,
  $,
  Marionette,
  _,
  viewTpl,
  SharePrintAreasView,
  ShareOptionCategoriesView,
  ShareProductCodeVariationsView
) {
  'use strict';

  var ProductShareView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      sharePrintAreas: '.share-printareas',
      shareOptionCategories: '.share-optioncategories',
      shareProductCodeVariations: '.share-productcodevariations'
    },

    ui: {
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      blockLoading: '.js-loading-block'
    },

    events: {
      'click @ui.cancel': 'onCancelClick',
      'click @ui.confirm': 'onConfirmClick',
      'click .btn-checked': 'onCheckedClick'
    },

    onCancelClick: function () {
      this.model.set(this.backup);
      this.render();
      this.onShow();
    },

    onConfirmClick: function () {
      this.ui.blockLoading.show();
      this.model.get('parentProduct').set('customFields', null);
      this.model.get('product').set('customFields', null);
      this.model.save().done(_.bind(function () {
        this.ui.blockLoading.hide();
        this.ui.cancel.prop('disabled', true);
        this.ui.confirm.prop('disabled', true);
      }, this));
    },

    onCheckedClick: function () {
      $('.js-loading-block').show();
      var updateDataRequest = App.request('products:refresh-product-checked', this.options.model.get('product').get('secId'));
      $.when(updateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
      }, this));
    },

    onShow: function () {
      var col = this.model.get('printAreas');
      col.checkedPrintAreas = this.options.lastCheckedData ? this.options.lastCheckedData.printAreas : [];
      col.displayNew = this.options.lastCheckedData ? true : false;
      this.sharePrintAreasView = new SharePrintAreasView({
        collection: col
      });
      this.sharePrintAreasView.listenTo(this.sharePrintAreasView, 'item:selection:change', _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('sharePrintAreas').show(this.sharePrintAreasView);

      var colCategories = this.model.get('optionCategories');
      colCategories.checkedOptionCategories = this.options.lastCheckedData ? this.options.lastCheckedData.productOptionCategories : [];
      colCategories.displayNew = this.options.lastCheckedData ? true : false;
      this.shareOptionCategoriesView = new ShareOptionCategoriesView({
        collection: colCategories
      });
      this.shareOptionCategoriesView.listenTo(this.shareOptionCategoriesView, 'item:selection:change',
        _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('shareOptionCategories').show(this.shareOptionCategoriesView);

      col = this.model.get('productCodeVariations');
      col.checkedProductCodeVariations = this.options.lastCheckedData ? this.options.lastCheckedData.productCodeVariations : [];
      col.optionCategories = colCategories;
      col.displayNew = this.options.lastCheckedData ? true : false;
      this.shareProductCodeVariationsView = new ShareProductCodeVariationsView({
        collection: col
      });
      this.shareProductCodeVariationsView.listenTo(this.shareProductCodeVariationsView, 'item:selection:change',
        _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('shareProductCodeVariations').show(this.shareProductCodeVariationsView);
    },

    serializeData: function () {
      this.backup = this.options.model.toJSON();
      var templateData = this.options.model.toJSON();
      return templateData;
    },

    onEnableCancelConfirm: function () {
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    }
  });

  module.exports = ProductShareView;
});
