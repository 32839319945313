define('attachmentsView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'dropzone',
  'settings',
  'attachmentView',
  'template!attachmentsView'
], function(
  Module,  
  Backbone,
  Marionette,
  _, 
  Dropzone,
  Settings,
  AttachmentView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: AttachmentView,    
    childViewContainer: '.attachments-container',
    className: 'attachments-view',   
    pageSize: 10,
    first: 0,   

    childViewOptions: function() {
      return {
        config: this.config
      };
    },

    initialize: function () {   
      this.config = _.extend({
        canEdit: true,
        canDelete: true,
        canDownload: true,
        canCopy: true,
        canZoom: true,
        canFlag: false
      }, this.setConfig());

      this.collection = new Backbone.Collection();

      this.attachmentsToUload = [];
      this.uploadTimer = null;
    },

    setConfig: function() { return {}; },   

    childEvents: {
      'edit': function(view) { 
        if(this.editItem)
          return this.editItem(view.model.get('attachment'));
      },
      'delete': function(view) { 
        if(this.deleteItem)
          return this.deleteItem(view.model.get('attachment')).done(_.bind(function() {
            this.collection.remove(view.model);
          }, this));
      },     
      'download': function(view) { 
        if(this.downloadItem)
          return this.downloadItem(view.model.get('attachment'));
      },
      'copy': function(view) { 
        if(this.copyItem)
          return this.copyItem(view.model.get('attachment'));
      }
    },

    ui: {   
      dropZone: '.drop-zone',
      dropZoneIcon: '.drop-zone svg',
      attachmentsContainer: 'attachments-container'
    },

    events: {  
      'click @ui.dropZoneIcon': function () { this.ui.dropZone.click(); }
    },  

    onShow: function() {
    
      this.observer = new window.IntersectionObserver(_.bind(function(aEntries) {
        if(aEntries.length > 0 && aEntries[0].isIntersecting)         
          this._fetch();          
      }, this), {
        root: this.ui.attachmentsContainer[0],
        rootMargin: '30px',
        threshold: 0.5
      });    

      this._fetch(); 
    },

    onRender: function() {
      var that = this;

      this.ui.dropZone.dropzone({
        url: Settings.url('compuzz'),
        autoProcessQueue: true,
        uploadMultiple: true,

        init: function () {          
          this.on('addedfile', function(file) {

            var attachmentToUpload = new Backbone.Model({
                file: file,
                fileName: file.name,
                attachment: null,
                uploading: true,
                error: false
            });

            that.collection.add(attachmentToUpload);
            that.attachmentsToUload.push(attachmentToUpload);

            if(that.uploadTimer)
                clearTimeout(that.uploadTimer);

            that.uploadTimer = setTimeout(function () {
              that.uploadAll();
            }, 1000);

            this.removeAllFiles();
          });        
        },
        dictDefaultMessage: _.i18n('common.file.drag.drop')
      });
    },

    uploadAll: function() {
      if(this.uploadItem) {
        var promiseList = [];

        _.each(this.attachmentsToUload, _.bind(function (attachmentToUload) {
          promiseList.push(_.bind(function () {
            return new Promise(_.bind(function(resolve) {
              this.uploadItem(attachmentToUload.get('file')).done(_.bind(function (attachment) {
                attachmentToUload.set('uploading', false);
                attachmentToUload.set('error', false);
                attachmentToUload.set('attachment', attachment);
                this.attachmentsToUload = [];
                resolve();
              }, this)).fail(_.bind(function () {
                attachmentToUload.set('uploading', false);
                attachmentToUload.set('error', true);
                this.attachmentsToUload = [];
                resolve();
              }, this));
            }, this));
          }, this));
        }, this));

        promiseList.reduce(function (previousPromise, currentFunction) {
          return previousPromise.then(currentFunction);
        }, Promise.resolve());
      }
    },
    
    _fetch: function() {

      if(!this.fetch)
        return;

      this.observer.disconnect(); 

      this.fetch(this.first, this.pageSize)
      .done(_.bind(function (items) {

        _.each(items, _.bind(function(attachment) {
          var model = new Backbone.Model({
            file: null,
            fileName: attachment.get('fileName'),
            attachment: attachment
          });
          if(!this.collection.findWhere({id: attachment.get('id')}))
            this.collection.add(model);
        }, this));        
        
        this.first = this.collection.length;

        if(items.length > 0) {
          var view = this.children.last();  
        
          if(view)
            this.observer.observe(view.$el[0]);
        } 
      }, this)); 
    },
    
    addAttachment: function(attachment) {
      var model = new Backbone.Model({
        file: null,
        fileName: attachment.get('fileName'),
        attachment: attachment
      });
      this.collection.add(model, {at: 0});
    }
  });  
});

