define('events',[
  'module',
  'jquery',
  'underscore'
], function (
  module,
  $,
  _
) {
  'use strict';

  module.exports = {
    init: function (app) {
      this.app = app;
      this.views = [];
      $(document).on('keydown', _.bind(this.onKeyDown, this));
    },

    push: function (view) {
      if(!view.onKeyDown)
        return;

      if(this.views.includes(view))
        return;

      this.views.push(view);
    },

    pop: function (view) {
      var index = -1;

      for(var i = 0; i < this.views.length; i++) {
        if(this.views[i].cid === view.cid) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        this.views.splice(index, 1);
      }
    },

    onKeyDown: function(e) {
      for(var i = this.views.length - 1; i >= 0; i--) {
        if(this.views[i].onKeyDown) {
          var view = this.views[i];
          if(view.onKeyDown(e) === false) {
            return;
          }
        }
      }
    }
  };
});
