define('customersDetailBodyCallsView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'moment',
  'settings',
  'phoneCallsView'
], function (
  Module,
  _,
  App,
  Backbone,
  moment,
  Settings,
  CallsView
) {
  'use strict';

  Module.exports = CallsView.extend({

    className: 'customer-detail-calls-view ' + CallsView.prototype.className,

    fetch: function(first, pageSize, sortField, sortDirection, filters) {

      var defer = $.Deferred();

      var params = {
        pageSize: pageSize,
        first: first
      };

      if (filters.from && filters.to) {
        params.dateFrom = new Date(filters.from);
        params.dateTo = new Date(filters.to);
      }

      if (sortField !== '') {
        var sortParamName = '';
        if (sortField === 'Date')
          sortParamName = 'userTimestamp.creatDate';

        if (sortParamName !== '') {
          params.sidx = sortParamName;
          params.sord = sortDirection;
        }
      }

      params.phoneNumber = '-';
      if(this.model.get('tel'))
        params.phoneNumber = this.model.get('tel').replace('+', '%2B');

      $.ajax({
        type: 'GET',
        url: App.request('calls:get-collection-url-phone-number', params),
        success: _.bind(function (items) {
          var models = [];
          _.each(items, _.bind(function(item) {
            var model = new Backbone.Model(item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();
      var params = {};

      if(filters.from && filters.to) {
        params.dateFrom = new Date(filters.from);
        params.dateTo = new Date(filters.to);
      }

      params.phoneNumber = '-';
      if(this.model.get('tel'))
        params.phoneNumber = this.model.get('tel').replace('+', '%2B');

      $.ajax({
        type: 'GET',
        url: App.request('calls:row-count-url-phone-number', params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    }
  });
});
