define('ordersDetailInvoicesToolsView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'tableFieldView',
  'settings',
  'pdfView',
  'ordersController',
  'emailAttachmentViewOld',
  'template!ordersDetailInvoicesToolsView',
  'entities/orders',
  'entities/endUserInvoice'
], function (
  App,
  Module,
  Backbone,
  _,
  FieldView,
  Settings,
  PdfView,
  Controller,
  EmailAttachmentView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-tools-view ' + FieldView.prototype.className,

    ui: {
      creditNote: '.credit-note',
      preview: '.preview',
      sendMail: '.send-mail'
    },

    events: {
      'click @ui.preview': 'onPreview',
      'click @ui.sendMail': 'onSendMail'
    },

    serializeData: function() {
      var data = {};
      data.downloadUrl = Settings.url('dl', 'proforma', {invoiceSecId: this.model.get('secId')});
      return data;
    },

    onRender: function() {
      if(this.model.get('invoiceType') === 'creditNote')
        this.ui.creditNote.addClass('disabled');
      else
        this.ui.creditNote.removeClass('disabled');
    },

    onPreview: function() {
      /*PdfView.prototype.open({
        url: Settings.url('dl', 'proforma', {invoiceSecId: this.model.get('secId')}),
        title: ''
      });*/


      var emailAttachmentView = new EmailAttachmentView({
        model: new Backbone.Model({
          url: Settings.url('dl', 'proforma', {invoiceSecId: this.model.get('secId')}),
          type: 'pdf'
        }),
        hideDelete: true
      });
      emailAttachmentView.show({
        title: 'Invoice preview',
        className: 'send-email-dialog'
      });
    },

    onSendMail: function() {
      var endUser = App.request('customers:entity:end-user-id', this.options.tableView.model.get('endUserId'));
      endUser.fetch().done(_.bind(function () {
        Controller.sendInvoiceMail(endUser, this.model.toJSON(), this.options.tableView.model.get('payedByCustomer'), _.bind(function () {
          this.model.fetch();
        }, this));
      }, this));
    }
  });
});
