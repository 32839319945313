/**
 * Created by OLD on 06/08/2015.
 */
define('myWebSiteMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App
) {
  'use strict';

  var MyWebSiteRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'my_website': 'showMyWebSite'
    }
  });

  var API = {
    showMyWebSite: function() {
      require([
        'myWebSiteShowController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('myWebSiteMain');
          ShowController.showMyWebSite();
          App.execute('set:active:nav:item', 'my_website');
        });
      });
    }
  };

  App.on('my_website:show', function() {
    App.navigate('my_website');
    API.showMyWebSite();
  });

  App.on('before:start', function() {
    new MyWebSiteRouter({
      controller: API
    });
  });

  module.exports = MyWebSiteRouter;
});
