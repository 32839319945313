define('tableBodyView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'tableRowView'
], function(
  Module,  
  Backbone,
  Marionette,
  _, 
  TableRowView
) {
  'use strict';
  
  Module.exports = Marionette.CollectionView.extend({
    childView: TableRowView,    
    className: 'table-body-view',
    first: 0,
    sortField: '',
    sortDirection: 'desc',

    childViewOptions: function() {
      return {
        tableView: this.options.tableView
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.tableView = this.options.tableView;
      this.filter = this.tableView.filter;
      this.sortField = this.tableView.defaultSortField;
      this.sortDirection = this.tableView.defaultSortDirection;
      if(this.tableView.headerView)
        this.listenTo(this.tableView.headerView, 'sort:change', this.onSortChange);

      this.$el.on('mousedown', this.onMouseDown.bind(this));
    },

    onDestroy: function () {
      this.$el.off('mousedown', this.onMouseDown.bind(this));
    },

    onShow: function() {
      if(this.tableView.pageMode === 'infinite') {
        this.observer = new window.IntersectionObserver(_.bind(function(aEntries) {
          if(aEntries.length > 0 && aEntries[0].isIntersecting)         
            this.fetch();          
        }, this), {
          root: this.$el[0],
          rootMargin: '30px',
          threshold: 0.5
        });    
      }

      this.fetch();
    },
    
    fetch: function() {
      var defer = $.Deferred();

      if(this.tableView.pageMode === 'infinite') {
        this.observer.disconnect();

        this.options.fetch(this.first, this.tableView.pageSize, this.sortField, this.sortDirection, this.tableView.filters)
        .done(_.bind(function (models) {
          this.collection.push(models);
          this.first = this.collection.length;

          if(models.length > 0) {
            var view = this.children.last();

            if(view)
              this.observer.observe(view.$el[0]);
          }

          defer.resolve();
        }, this));
      }

      if(this.tableView.pageMode === 'pagination') {
        if(this.tableView.footerView)
          this.tableView.footerView.loader(true);

        this.options.fetch(this.first, this.tableView.pageSize, this.sortField, this.sortDirection, this.tableView.filters)
        .done(_.bind(function (models) {
          this.collection.reset();
          this.collection.push(models);
          if(this.tableView.footerView)
            this.tableView.footerView.loader(false);
          defer.resolve();
        }, this));
      }

      return defer.promise();
    },

    onSortChange: function(sortField, sortDirection) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.first = 0;

      if(this.tableView.pageMode === 'infinite')
        this.collection.reset();

      this.fetch();
    },

    reload: function() {
      this.first = 0;

      if(this.tableView.pageMode === 'infinite')
        this.collection.reset();

      this.fetch();
    },

    reset: function() {
      this.first = 0;
      this.collection.reset();
    },

    deleteRow: function(row) {
      this.collection.remove(row.model);
    },

    addRow: function(model) {
      this.collection.add(model, {at: 0});
    },

    selectAll: function() {
      this.children.each(function(child) {
        child.select();
      });
    },

    unSelectAll: function() {
      this.children.each(function(child) {
        child.unSelect();
      });
    },

    getSelected: function () {
      var selected = [];
      this.children.each(function(child) {
        if(child.isSelected())
          selected.push(child);
      });
      return selected;
    },

    onAttach: function () {
      if (this.tableView.sortable) {
        this.$el.sortable({
          disabled: true,
          connectWith: '.table-body-view',
          placeholder: 'table-view-row-placeholder',
          opacity: 0.8,
          cursor: 'move',
          start: _.bind(function (event, ui) {
            this.dragDropRows = [];
            var row = this.getRow(ui.item);
            row.select();

            _.each(this.tableView.getSelected(), _.bind(function (row) {
              this.dragDropRows.push(row);
            }, this));

            _.each(this.tableView.getSelected(), _.bind(function (view) {
              if(view.$el[0] !== row.$el[0])
                view.$el.hide();
            }, this));
          }, this),
          stop: _.bind(function (event, ui) {
            if(this.dragDropRows.length > 0) {
              var row = this.getRow(ui.item);

              var reversedRows = this.dragDropRows.slice().reverse();
              _.each(reversedRows, _.bind(function (view) {
                if(view.$el[0] !== row.$el[0]) {
                  view.$el.insertAfter(row.$el, 0);
                  view.$el.css('display', 'flex');
                }
              }, this));

              var orderedRows = [];
              orderedRows.push(row);
              _.each(this.dragDropRows, _.bind(function (view) {
                if(view.$el[0] !== row.$el[0]) {
                  orderedRows.push(view);
                }
              }, this));

              var models = [];
              _.each(orderedRows, _.bind(function (view) {
                models.push(view.model);
                view.unSelect();
              }, this));

              var position = this.first + ui.item.index();
              this.tableView.updatePosition(models, position);
            }
          }, this)
        });
      }
    },

    getRow: function (item) {
      var row = null;
      _.each(this.children._views, function (view) {
        if(view.$el[0] === item[0])
          row = view;
      }, this);

      return row;
    },

    onMouseDown: function (event) {
      if (this.tableView.sortActivated) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  });  
});

