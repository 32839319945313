
define('template!shareOptionCategoryTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-0">\n        <div class="col-xs-6" ' +
((__t = ( newPoc?'style="color: orange;font-weight: bold;"':'' )) == null ? '' : __t) +
' >\n            ';
 if (parentOptionCategory) { ;
__p += '\n            <div class="col-xs-1 m-t--7 m-b--5">\n                <input class="cbx-optioncategory m-t-0" type="checkbox" style="width: 20px; height: 35px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n            </div>\n            ';
 } ;
__p += '\n            <div class="col-xs-2">' +
((__t = ( parentOptionCategory?parentOptionCategory.code:'' )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-4">' +
((__t = ( newPoc?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( checked?optionCategory.name:parentOptionCategory.name )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="share-options col-xs-6">\n        </div>\n    </div>\n    <hr class="col-xs-12 m-t-0 m-b-0"/>\n</div>';

}
return __p
};});

