
define('template!personalizeViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well">\n    Personalize Page\n  </div>\n</div>';

}
return __p
};});

