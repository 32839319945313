
define('template!graphicalRequestKanbanAssignPopupViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <div class="form-group js-users">\n      <div class="row">\n        <label class="col-xs-2 normal-text m-b-0 m-t-10 control-label">' +
((__t = ( _.i18n("task.assign") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-10 js-select-users-region"></div>\n        <div class="js-userface-region unassigned-container"></div>\n      </div>\n    </div>\n    <div class="form-group js-users">\n      <div class="row">\n        <label class="col-xs-offset-2 col-xs-5 control-label">\n          <a class="normal-text clickable js-assign-to-me">' +
((__t = ( _.i18n("common.assignToMe") )) == null ? '' : __t) +
'</a>\n        </label>\n        <label class="col-xs-5 control-label">\n          <a class="normal-text clickable js-unassign-ticket">' +
((__t = ( _.i18n("common.unAssign") )) == null ? '' : __t) +
'</a>\n        </label>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

