define('translationsTranslationView',[
  'module',
  'marionette',
  'template!translationsTranslationTpl',
  'translationsPopupFormView',
  'underscore',
  'bootbox'
], function(
  module,
  Marionette,
  translationsTranslationTpl,
  PopupFormView,
  _,
  bootbox
) {
  'use strict';

  var TranslationView = Marionette.ItemView.extend({
    template: translationsTranslationTpl,
    tagName: 'tr',

    className: 'clickable',

    ui: {
      'delete': '.js-delete'
    },

    events: {
      click: '_showEditPopup',
      'click @ui.delete': '_onDelete'
    },

    modelEvents: {
      change: 'render'
    },

    serializeData: function() {
      return {
        languages: this.options.languages,
        model: this.model
      };
    },

    _showEditPopup: function() {
      var selection = window.getSelection(),
        popupFormView;
      if(this.options.viewsData.isDisabled) {
        return;
      }
      if(!selection.toString()) {
        popupFormView = new PopupFormView({
          translationsModel: this.model
        });
        this.listenTo(popupFormView, 'translation:saved', this._onTranslationSaved);
        popupFormView.show({title: _.i18n('translationsList.textAreaDynamicTextEditor')});
      }
    },

    _onTranslationSaved: function(model) {
      this.model.set(model.toJSON());
      this.model.trigger('change');
    },

    _onDelete: function(e) {
      var model = this.model;
      if(this.options.viewsData.isDisabled) {
        return;
      }

      e.stopPropagation();
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
        if (result) {
          model.destroyTranslation();
        }
      }, this));
    }
  });

  module.exports = TranslationView;
});
