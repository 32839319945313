
define('template!popupCreatePrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="refImprintTechnology">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printzone.imprinttechnology') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 imprint-technology-region"></div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printzone.maxColors') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-4">\n          <input type="number" class="form-control floating-label js-info-input input-max-colors"\n                 data-field-name="maxColors"\n                 placeholder="' +
((__t = ( _.i18n('printzone.maxColors') )) == null ? '' : __t) +
'" value="' +
((__t = ( maxColors )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printZone.x/y') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-4">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="x"\n                 placeholder="' +
((__t = ( _.i18n('common.x') )) == null ? '' : __t) +
'" value="' +
((__t = ( x )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-4">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="y"\n                 placeholder="' +
((__t = ( _.i18n('common.y') )) == null ? '' : __t) +
'" value="' +
((__t = ( y )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.width/height') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-4">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="width"\n                 placeholder="' +
((__t = ( _.i18n('common.width') )) == null ? '' : __t) +
'" value="' +
((__t = ( width )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-4">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="height"\n                 placeholder="' +
((__t = ( _.i18n('common.height') )) == null ? '' : __t) +
'" value="' +
((__t = ( height )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printZone.setupCode') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="setupCode"\n                 placeholder="' +
((__t = ( _.i18n('printZone.setupCode') )) == null ? '' : __t) +
'" value="' +
((__t = ( setupCode )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printZone.unitCode') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="unitCode"\n                 placeholder="' +
((__t = ( _.i18n('printZone.unitCode') )) == null ? '' : __t) +
'" value="' +
((__t = ( unitCode )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="minStrokeWidth">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('imprintTechnology.minStrokeWidth') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="minStrokeWidth" placeholder="' +
((__t = ( _.i18n('imprintTechnology.minStrokeWidth') )) == null ? '' : __t) +
'" value="' +
((__t = ( minStrokeWidth )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('printZone.fixedFilling') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="col-xs-12 set-colors">\n            <input class="fixed-filling-items" type="radio" id="bg-use-color" name="bg-color" value="color" ' +
((__t = ( fixedFillingType==='color'?'checked':'' )) == null ? '' : __t) +
'>\n            <label for="bg-use-color">' +
((__t = ( _.i18n('printArea.useColor') )) == null ? '' : __t) +
'</label>\n            <div class="color-input-item" style="display: inline;">\n              <span class="hidden"></span>\n              <label class="btn btn-default btn-flat btn-color colorpick p-t-0 m-t-0 p-l-0 m-l-0" data-key="fixedColor">\n                  <span class="btn-group">\n                      <div class="color-block add-on" style="background-color:' +
((__t = ( color )) == null ? '' : __t) +
';" ' +
((__t = ( color?'':'disabled' )) == null ? '' : __t) +
'></div>\n            </span>\n            <input value="' +
((__t = ( color)) == null ? '' : __t) +
'" ' +
((__t = ( color?'':'disabled' )) == null ? '' : __t) +
'>\n            </label>\n          </div>\n        </div>\n        <div class="col-xs-12">\n          <input class="fixed-filling-items" type="radio" id="bg-option-color" name="bg-color" value="option-color" ' +
((__t = ( fixedFillingType==='productOptionCategoryColor'?'checked':'' )) == null ? '' : __t) +
'>\n          <label for="bg-option-color">' +
((__t = ( _.i18n('printArea.optionColor') )) == null ? '' : __t) +
'</label>\n          <select class="bg-option-color" ' +
((__t = ( fixedFillingType==='productOptionCategoryColor'?'':'disabled' )) == null ? '' : __t) +
'>\n          ';
 _.each(optionCategories, function(category) { ;
__p += '\n          <option value="' +
((__t = ( category.secId )) == null ? '' : __t) +
'" ' +
((__t = ( category.id === fixedProductOptionCategoryColor ? 'selected': '' )) == null ? '' : __t) +
'>' +
((__t = ( category.name )) == null ? '' : __t) +
'</option>\n          ';
 }) ;
__p += '\n          </select>\n        </div>\n      </div>\n    </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

