define('ordersConfirmationOrdersView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'ordersConfirmationOrderView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  OrderView
) {
  'use strict';
  
  Module.exports = Marionette.CollectionView.extend({
    className: 'orders-confirmation-orders-view',
    childView: OrderView,

    initialize: function() {
      this.collection = new Backbone.Collection(this.options.orders);
    }
  });  
});

