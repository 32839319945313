define('attachmentView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'galleryView',
  'pdfView', 
  'settings',
  'bootbox',
  'template!attachmentView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  GalleryView,
  PdfView,
  Settings,
  Bootbox,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'attachments-attachment-view',

    ui: {
      fileName: '.file-name',     
      deleteButton: '.delete-button',     
      zoomButton: '.zoom-button',
      downloadButton: '.download-button',
      copyButton: '.copy-button',
      flagButton: '.flag-button',
      flagIcon: '.flag-icon',
      imageContent: '.image-content',
      image: '.image-ctn img',
      loader: '.loader-2',
      error: '.error'
    },

    modelEvents: {
      'change': 'onRenderSafe',
      'change:attachment': 'render'
    },

    onRenderSafe: function() {
      if (!this.isDestroyed) {
        this.render();
      }
    },

    events: {
      'click @ui.deleteButton': 'deleteItem',
      'click @ui.downloadButton': 'downloadItem',
      'click @ui.zoomButton': 'zoomItem',    
      'click @ui.copyButton': 'copyItem',
      'click @ui.flagButton': 'flagItem'
    },

    onRender: function () {
      
      if (this.model.get('fileName')) {
        this.$el.attr('title', this.model.get('fileName'));
        this.ui.fileName.html(this.model.get('fileName'));
      }

      if(this.model.get('uploading'))
        this.ui.loader.css('display', 'flex');
       else
        this.ui.loader.hide();

      if(this.model.get('error'))
        this.ui.error.css('display', 'flex');
      else
        this.ui.error.hide();

      if(!this.model.get('attachment')) {
        this.ui.imageContent.hide();
      } else {
        var attachment = this.model.get('attachment');

        this.ui.imageContent.css('display', 'flex');

        if (attachment.get('fileLink'))
          this.ui.image.attr('src', Settings.url('base', attachment.get('fileLink')));

        if (attachment.get('flag')) {
          this.ui.flagIcon.css('display', 'flex');
        } else {
          this.ui.flagIcon.css('display', 'none');
        }

        if (this.options.config.canDownload && attachment.get('downloadLink')) {
          this.ui.downloadButton.attr('href', attachment.get('downloadLink'));
          this.ui.downloadButton.css('display', 'flex');
        } else {
          this.ui.downloadButton.css('display', 'none');
        }

        if (this.options.config.canFlag) {
          this.ui.flagButton.css('display', 'flex');
        } else {
          this.ui.flagButton.css('display', 'none');
        }
        //-----------------
        if (this.options.config.canDelete)
          this.ui.deleteButton.css('display', 'flex');
        else
          this.ui.deleteButton.css('display', 'none');

        if (this.options.config.canZoom)
          this.ui.zoomButton.css('display', 'flex');
        else
          this.ui.zoomButton.css('display', 'none');

        if (this.options.config.canCopy)
          this.ui.copyButton.css('display', 'flex');
        else
          this.ui.copyButton.css('display', 'none');

        //-----------------
        if (this.options.config.copyButton && this.options.config.copyButton.title)
          this.ui.copyButton.attr('title', this.options.config.copyButton.title);
      }
    },

    downloadItem: function () {
      this.trigger('download');
    },

    flagItem: function () {
      var attachment = this.model.get('attachment');
      attachment.set('flag', !attachment.get('flag'));
      attachment.save();
      this.render();
    },

    deleteItem: function () {
      Bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result)
          this.trigger('delete'); 
      }, this));
    },

    zoomItem: function () {
      var title = 'Attachment';
      var attachment = this.model.get('attachment');

      if(this.options.config.galleryTitle)
        title = this.options.config.galleryTitle + ' | ' + attachment.get('fileName');
      else
        title = 'Attachment: ' + attachment.get('fileName');

      var fileType = '';
      if(attachment.get('fileName')) {
        var fileItems = attachment.get('fileName').split('.');
        if(fileItems.length > 1)
          fileType = fileItems[fileItems.length - 1];
      }

      if(fileType === 'pdf') {
        PdfView.prototype.open({
          url: attachment.get('fileLink').replace('&action=preview', ''),
          title: title
        });
      } else {
        GalleryView.prototype.open(
            'attachment.image:' + attachment.get('secId'),
            title,
            new Backbone.Model({urls: [attachment.get('fileLink').replace('&action=preview', '')]}));
      }   
    },

    copyItem: function () {
      this.trigger('copy');
    }
  });
});
