/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminCAccountsController',[
    'module',
    'underscore',
    'app',
    'superAdminCAccountsView',
    'settings',
    'entities/caccounts'
], function(
    module,
    _,
    App,
    View
    ) {
    'use strict';

    var SuperAdminCaccountListController = {
        showCaccountList: function(data) {
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('caccount:get-collection-url'),
                rowCountUrl: App.request('caccount:row-count-url')
            };

            this.view.displayGrid(data);
        }
    };

    module.exports = SuperAdminCaccountListController;
});
