/**
 * Created by OBL on 14-12-15.
 */
define('cataloguesMineView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!cataloguesMineTpl'
], function (module,
             Marionette,
             _,
             App,
             Settings,
             JqGridView,
             viewTpl) {
  'use strict';

  var CataloguesMineView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'CataloguesMineFilters',

    triggers: {
      'click @ui.addNewCatalogue': 'catalogue:addNewCatalogue' //Caught by controller
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        addNewCatalogue: '.new-catalogue-button'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click .share-catalogue': 'onShareCatalogue',
        'click .preview-catalogue': 'onPreviewCatalogue',
        'click .delete-link': 'onDeleteCatalog'
      }, this.gridEvents);
    },

    onShareCatalogue: function (event) {
      this.trigger('catalogue:share', $(event.currentTarget).attr('data-row-id'));
    },

    onPreviewCatalogue: function (event) {
      this.trigger('catalogue:preview', $(event.currentTarget).attr('data-row-id'));
    },

    onDeleteCatalog: function (event) {
      this.trigger('catalogue:delete', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    serializeData: function () {
      var templateData = {catalogue: this.options.data};

      return templateData;
    },

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage'),
        currentPage: 1
      };
    },

    gridOptions: function (data) {
      var options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          },
          {
            label: '',
            name: 'pictureUid',
            classes: 'catalogue-link',
            formatter: this.cataloguePictureFormatter,
            search: false,
            index: 'preview'
          },
          {
            label: _.i18n('common.code'),
            name: 'code',
            classes: 'catalogue-link',
            formatter: this.defaultFormatter,
            search: true,
            index: 'code'
          },
          {
            label: _.i18n('common.name'),
            name: 'name',
            classes: 'catalogue-link',
            formatter: this.defaultFormatter,
            search: true,
            index: 'name'
          },
          {
            label: ' ',
            name: 'share',
            classes: 'catalogue-share-link jq-icon',
            formatter: this.shareIconFormatter,
            search: false,
            index: 'name',
            width: 20
          },
          {
            label: ' ',
            name: 'preview',
            classes: 'catalogue-link jq-icon',
            formatter: this.previewIconFormatter,
            search: false,
            index: 'name',
            width: 20
          },
          {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
        ],
        onSelectRow: function (rowid, bool, e) {
            if(!$(e.target).hasClass('js-icon') && e.type ==='click') {
                App.navigate('catalogues/mine/' + rowid, {trigger: true});
            }
        }
      };

      options = _.defaults(options, this.gridInitOptions(data));

      return options;
    },

    cataloguePictureFormatter: function (cellValue, options, rows) {
      var newCellValue = (rows && rows.pictureUid) ? Settings.url('file', rows.pictureUid, {size: 'thumbnail'}) :
        Settings.get('defaultCatalogueImage');
      return '<span><img data-link="' + newCellValue + '"' +
        'class="jq-picture js-image-cell" src="' + newCellValue + '"/></span>';
    },
    shareIconFormatter: function (cellValue, options) {
      return '<span class="fa fa-share-alt fa-lg cell-action share-catalogue js-icon" ' +
        'data-row-id="' + options.rowId + '"></span>';
    },

    previewIconFormatter: function (cellValue, options) {
      return '<span class="mdi-action-visibility cell-action preview-catalogue js-icon" ' +
        'data-row-id="' + options.rowId + '"></span>';
    }

  });

  module.exports = CataloguesMineView;
});
