define('ordersHistoryChangeView',[
  'module', 
  'backbone',
  'underscore',
  'settings',
  'dateUtils',
  'tableFieldView',
  'template!ordersHistoryChangeView'
], function (
  Module,
  Backbone,
  _,
  Settings,
  DateUtils,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-history-change-view ' + FieldView.prototype.className,

    ui: {
      oldValue: '.old-value',
      newValue: '.new-value',
      icon: '.icon'
    },

    onRender: function() {
      var oldValue = '';
      var newValue = '';

      var oldValueModel = null;
      if(this.model.get('oldValue'))
        oldValueModel = new Backbone.Model(this.model.get('oldValue'));

      if(_.contains(['refUserpid', 'refUser'], this.model.get('field'))) {
        if(oldValueModel)      
          oldValue = this.renderUser(oldValueModel); 

        newValue = this.renderUser(this.model);
      } else if(_.contains(['payedByCustomer', 'urgent', 'flagged', 'ontop'], this.model.get('field'))) {
        if(oldValueModel)      
          oldValue = _.i18n('common.' + (oldValueModel.get('value') ? 'yes' : 'no'));

        newValue = _.i18n('common.' + (this.model.get('value') ? 'yes' : 'no'));
      } else if(_.contains(['ticketState'], this.model.get('field'))) {    
        if(oldValueModel)     
          oldValue = oldValueModel.get('value').toLowerCase();

        newValue = _.i18n('vectorization.status.' + this.model.get('value').toLowerCase());
      } else if(_.contains(['price', 'buyingPrice'], this.model.get('field'))) {  
        if(oldValueModel)       
          oldValue = oldValueModel.get('value') + ' €';

        newValue = this.model.get('value') + ' €';
      } else if(_.contains(['commandType'], this.model.get('field'))) {       
        if(oldValueModel)  
          oldValue = _.i18n('common.' + (oldValueModel.get('value') === 'offer' ? 'offer' : 'command'));
          
        newValue = _.i18n('common.' + (this.model.get('value') === 'offer' ? 'offer' : 'command'));
      } else if(_.contains(['status'], this.model.get('field'))) {   
         
        if (this.tableView.statuses) {
          if(oldValueModel) {
            var oldStatus = this.tableView.statuses.find(_.bind(function (item) {
              return item.get('statusConst') === oldValueModel.get('value');
            }, this));

            if (oldStatus)
              oldValue = oldStatus.get('status');
          }

          var newStatus = this.tableView.statuses.find(_.bind(function (item) {
            return item.get('statusConst') === this.model.get('value');
          }, this));

          if (newStatus)
            newValue = newStatus.get('status');
        }

        if(oldValue === '')
          oldValue = oldValueModel.get('value');
                      
        if(newValue === '')
          newValue = this.model.get('value');
        
      } else if(_.contains(['ticketType'], this.model.get('field'))) { 
        if(oldValueModel) {
          switch (oldValueModel.get('value').toLowerCase()) {
            case 'vectorization':
              oldValue = _.i18n('vectorization');
              break;
            case 'conversion':
              oldValue = _.i18n('conversion.title');
              break;
            case 'remove_background':
              oldValue = _.i18n('backRemove.title');
              break;
            case 'colorization':
              oldValue = _.i18n('colorization.title');
              break;
            default:
              oldValue = _.i18n('ticketType.' + oldValueModel.get('value').toLowerCase());
          }
        }
       
        switch (this.model.get('value').toLowerCase()) {
          case 'vectorization':
            newValue = _.i18n('vectorization');
            break;
          case 'conversion':
            newValue = _.i18n('conversion.title');
            break;
          case 'remove_background':
            newValue = _.i18n('backRemove.title');
            break;
          case 'colorization':
            newValue = _.i18n('colorization.title');
            break;
          default:
            newValue = _.i18n('ticketType.' + oldValueModel.get('value').toLowerCase());
        }
        
      } else if(_.contains(['proofsheetFileUid'], this.model.get('field'))) {
        if (oldValueModel && oldValueModel.get('value')) {
          oldValue = '<a href="' + Settings.url('file', oldValueModel.get('value')) + '" target="_blank">' +
              _.i18n('common.download') + '</a>';
        }
        if(this.model.get('value')) {
          newValue = '<a href="' + Settings.url('file', this.model.get('value')) + '" target="_blank">' +
              _.i18n('common.download') + '</a>';
        }
      } else if(_.contains(['comment'], this.model.get('field'))) {
      } else if(_.contains(['expShippingDate'], this.model.get('field'))) {
        oldValue = DateUtils.toDateFormatString(this.model.get('value'));
        if(oldValueModel && oldValueModel.get('value'))
          oldValue = DateUtils.toDateFormatString(oldValueModel.get('value'));
        if(this.model.get('value'))
          newValue = DateUtils.toDateFormatString(this.model.get('value'));
      } else if(_.contains(['refImprintTechnology'], this.model.get('field')) && this.model.get('value')) {
        if (oldValueModel && oldValueModel.get('value')) {
            oldValue = oldValueModel.get('value').codeName;
        }
        if (this.model.get('value')) {
            newValue = this.model.get('value').codeName;
        }
      }
      else {
        if(oldValueModel && oldValueModel.get('value') && oldValueModel.get('value') !== '') {
          if(this.model.get('field') === 'deadlineDate')
            oldValue = DateUtils.toDateFormatString(oldValueModel.get('value'));
          else
            oldValue = oldValueModel.get('value');
        }

        if(this.model.get('value')) {
          if(this.model.get('field') === 'deadlineDate')
            newValue = DateUtils.toDateFormatString(this.model.get('value'));
          else
            newValue = this.model.get('value');
        }
      }

      //----------------

      if(oldValue !== '') {
        this.ui.oldValue.html(oldValue);
        this.ui.oldValue.css('display', 'flex');
        this.ui.icon.css('display', 'flex');
      } else {
        this.ui.oldValue.hide();
        this.ui.icon.hide();
      }

      this.ui.newValue.html(newValue);
    },

    renderUser: function(model) {
      var html = '';

      if(model.get('refUserpid')) {
        if(model.get('refUserpid').firstName)
          html += model.get('refUserpid').firstName;
        
        if(html !== '')
          html += ' ';

        if(model.get('refUserpid').lastName)
          html += model.get('refUserpid').lastName;
      }     
        
      return html;
    }
  });
});
