
define('template!pimAttributeTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row editCode">\n    <div class="col-xs-3">Attribute code: </div>\n    <div class="col-xs-9"><input type="text" value="' +
((__t = ( code )) == null ? '' : __t) +
'" name="attribute-code" class="attribute-code"></div>\n</div>\n\n<div class="values-region"></div>   \n<a class="btn new-attribute-value-button action-btn-text" title="Add new attribute value">\n    <i class="mdi-content-add"></i>\n    <span>NEW ATTRIBUTE VALUE</span>\n</a>';

}
return __p
};});

