
define('template!sharePrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row" ' +
((__t = ( newPz?'style="color: orange;font-weight: bold;"':'' )) == null ? '' : __t) +
' >\n    ';
 if (parentPrintZone) { ;
__p += '\n    <div class="col-xs-1 m-t--7 m-b--5">\n      <input type="checkbox" class="cbx-printzone m-t-0" style="width: 20px; height: 35px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n    </div>\n    ';
 } ;
__p += '\n    <div class="col-xs-11">' +
((__t = ( newPz?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( name )) == null ? '' : __t) +
' (' +
((__t = ( (width + "x" + height + "mm") )) == null ? '' : __t) +
') (' +
((__t = ( _.i18n('printZone.printedBy') + ' ' + printerName )) == null ? '' : __t) +
')</div>\n    ';
 if (!parentPrintZone) { ;
__p += '\n        <i class="mdi-content-remove btn-delete-printzone"></i>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

