define('phoneMainRouter',[
  'require',
  'module',
  'underscore',
  'marionette',
  'itemPagesNameMixin',
  'app'
], function (
  require,
  Module,
  _,
  Marionette,
  ItemPagesNameMixin,
  App
) {
  'use strict';

  var Router = Marionette.AppRouter.extend({
    appRoutes: {
      'phone/numbers': 'showNumbersView',
      'calls': 'showCallsView'
    }
  });


  App.on('before:start', function () {
    new Router({
      controller: {

        showNumbersView: function () {
            require([
              'contentShowController',
              'phoneNumbersView'
            ], function (   
              ContentShowController,
              View         
            ) {
              ContentShowController.showContent().done(function () {
                var view = new View({});      
                App.regions.getRegion('main').currentView.getRegion('content').show(view);
                App.execute('set:active:nav:item', ItemPagesNameMixin.PHONE_NUMBERS);
              });
            });
        },
    
        showCallsView: function () {
          require([
            'contentShowController',
            'phoneCallsView'
          ], function (
            ContentShowController,
            View
          ) {
            ContentShowController.showContent().done(function () {
              ContentShowController.showContent().done(function () {
                var view = new View({});      
                App.regions.getRegion('main').currentView.getRegion('content').show(view);
                App.execute('set:active:nav:item', ItemPagesNameMixin.PHONE_CALLS);
              });
            });
          });
        } 
      }
    });
  });

  Module.exports = Router;
});
