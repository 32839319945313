/**
 * Created by RKL on 13/08/2015.
 */
define('caccountObjectNamesTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!caccountObjectNamesTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CAccountsObjectNamesTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountObjectNamesFilters',

    triggers: {
      'click @ui.addItem' : 'onCreateItem'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-objnames',
        jqGridPager: '#jq-grid-pager-objnames',
        addItem: '.js-add-objectname',
        deleteItem: '.cell-delete'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteItem': 'onDeleteItem'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'name'
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'code'
            }, {
              label: _.i18n('common.type'),
              name: 'type',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'type',
              stype:'select', 
              searchrules: {select: true},
              searchoptions: {
                value: that.typeStatuses,
                defaultValue: that.allString
              }
            }, {
              label: '',
              name: '',
              classes: 'delete-link',
              formatter: this.deleteIconFormatter,
              search: false,
              width: 50
            }
          ],

          onSelectRow: function(rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              that.trigger('onEditItem', rowid);
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    getSelectValues: function() {
      var that = this,
        typeStatuses = [
          {
            send: 'image',
            display: _.i18n('common.image')
          }, {
            send: 'text',
            display: _.i18n('common.text')
          }
        ];

      this.allString = _.i18n('common.all');

      this.typeStatuses = '' + ':' + this.allString;
      _.each(typeStatuses, function(status) {
        that.typeStatuses += ';' + status.send + ':' + status.display;
      });

      this.selectValue = {
        type: this.typeStatuses
      };
    },

    onDeleteItem: function(e) {
      var target = $(e.currentTarget),
        itemId = target.data('row-id');

      this.trigger('onDeleteItem', itemId);
    }
  });

  module.exports = CAccountsObjectNamesTabView;
});
