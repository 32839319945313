/**
 * Created by OBL on 16-12-15.
 */
define('cataloguesMineShareView',[
    'module',
    'template!cataloguesMineShareTpl',
    'dialogView',
    'tokenField',
    'settings'
], function (
    module,
    viewTpl,
    DialogView, tokenField, Settings
    ) {
    'use strict';

    var CataloguesMineShareView = DialogView.extend({
        template: viewTpl,

        ui: {
            send: '.send-sa',
            cancel: '.js-cancel'
        },

        events: {
            'click @ui.send': 'onSend',
            'click @ui.cancel': 'onCancel'
        },

        onSend: function() {
            this.trigger('catalogue:share:send',this.model.get('secId'),
                this.$('#tokenfield-typeahead').tokenfield('getTokensList'));
        },

        onShow: function(){
          this.$('#tokenfield-typeahead').tokenfield({
              createTokensOnBlur : true,
              inputType: 'email'
          });
        },

        onCancel: function () {
            this.trigger('cancel');
            this.hide();
        },

        serializeData: function() {
            var tplData = this.model.toJSON();
            tplData.cAccountLogoUrl = Settings.url('file', tplData.cAccountLogo, {size: 'thumbnail'});
            if(tplData.pictureUid){
                tplData.pictureUrl = Settings.url('file', tplData.pictureUid, {size: 'thumbnail'});
            }
            else{
                tplData.pictureUrl = tplData.pictureUrl = Settings.get('defaultCatalogueImage');
            }
            tplData.shareLink = window.location.protocol +
                '//' + window.location.host + '/' +
                '#catalogues/shared/add/'+tplData.secId;
            return tplData;
        },

        onBeforeDestroy: function() {
          this.$('#tokenfield-typeahead' ).tokenfield('destroy');
        }
    });

    module.exports = CataloguesMineShareView;
});
