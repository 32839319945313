/**
 * Created by BFR on 24/02/2020.
 */
define('editPimPrintAreasView',['module',
        'marionette',
        'editPimPrintAreaView'],
  function(module,
           Marionette,
           EditPimPrintAreaView){
  'use strict';

  var EditPimPrintAreasView = Marionette.CollectionView.extend({
    childView: EditPimPrintAreaView,
    className: 'pa-list',
    childViewOptions: function() {      
      return {
        pimProduct: this.options.pimProduct
      };
    }
  });

  module.exports = EditPimPrintAreasView;
});
