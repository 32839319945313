
define('template!pricelistsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n\t<div class="row pricelist-select-container">\n\t\t<div class="col-xs-12">\t\t\t\n \t\t\t<div class="form-group col-xs-4 price-selector-block p-l-0"> \t\t\t\t\n\t\t      \t<a data-target="#" class="col-xs-12 dropdown-toggle pricelist-select btn btn-primary btn-flat" data-toggle="dropdown" data-field-name="id">\n\t\t\t        <span class="selected-price-item" data-id="' +
((__t = ( data[0].id )) == null ? '' : __t) +
'">' +
((__t = ( data[0].name )) == null ? '' : __t) +
'</span>\n\t\t\t        <span class="caret"></span>\n\t\t\t    </a>\n\t\t\t\t<ul class="dropdown-menu col-xs-12">\n\t\t\t\t\t';
 _.each(data, function(item) { ;
__p += '\n\t\t\t\t\t<li class="pricedropdown-item \n\t\t\t\t\t\t';
 if (item.id === data[0].id) { ;
__p += ' active ';
 } ;
__p += '" \n\t\t\t\t\t\tdata-name="' +
((__t = ( item.name )) == null ? '' : __t) +
'" \n\t\t\t\t\t\tdata-id="' +
((__t = ( item.id )) == null ? '' : __t) +
'" \n\t\t\t\t\t\tdata-code="' +
((__t = ( item.code )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t<a>' +
((__t = ( item.name )) == null ? '' : __t) +
'</a>\n\t\t\t\t\t</li>\n\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t</ul>\n\t\t    </div>\n \t\t\t<div class="form-group col-xs-6">\n \t\t\t\t<a class="btn btn-default btn-flat btn-sm add-new-pricelist-data-b">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t</a>\n \t\t\t\t<a class="btn btn-default btn-flat btn-sm edit-selected-pricelist-b">\n\t\t\t\t\t<i class="mdi-content-create"></i>\n\t\t\t\t</a>\n\t\t\t\t<a class="btn btn-default btn-flat btn-sm remove-selected-pricelist-b">\n\t\t\t\t\t<i class="mdi-action-delete"></i>\n\t\t\t\t</a>\n\t\t\t</div>\n \t\t</div>\n \t</div>\n \t<div class="row billing-tab-container">\n\t\t<div class="col-md-6 left-area col-sm-12">\n \t\t\t<div class="form-group">\n\t\t\t\t<a href="" class="btn add-btn-text btn-sm pricelist-export disabled">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n("price.export") )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a>\n\t\t\t\t<!-- <a class="btn add-btn-text btn-sm add-new-code-b hidden">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>Add new code</span>\n\t\t\t\t</a> -->\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<div class="pricelist-code-table"></div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-md-6 right-area m-t-55 col-sm-12">\n\t\t\t<div class="form-group">\n\t\t\t\t<div class="pricelist-detail-table"></div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

