define('ordersDetailInvoicesProformatPreviewView',[
  'app',
  'module',
  'underscore',
  'settings',
  'modalsConfirmationView',
  'ordersController',
  'template!ordersDetailInvoicesProformatPreviewView'
], function(
  App,
  Module,
  _,
  Settings,
  ModalsConfirmationView,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-proformat-preview-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      paymentDelay: '.paymentDelay input',
      loader: '.loader-2',
      error: '.error',
      canvas: '.canvas',
      canvasContainer: '.canvas-ctn'
    }, ModalsConfirmationView.prototype.ui),

    onShow: function () {
      this.fetch();

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    renderCanvas: function () {
      if (this.arrayBuffer) {
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var loadingTask = pdfjsLib.getDocument(this.arrayBuffer);
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
            this.ui.loader.hide();

            var scale = 2;
            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.canvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //console.log('Page rendered');
            });

            this.ui.canvasContainer.css('display', 'flex');
          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      }
    },

    fetch: function () {
      var pois = [];
      _.each(this.options.collection.models, function (order) {
        pois.push(order.get('purchaseOrderId'));
      }, this);

      var url = Settings.url('dl', 'proforma', {pois: pois.join(',')});

      var req = new XMLHttpRequest();
      req.addEventListener('load', _.bind(function (result) {
        this.arrayBuffer = result.currentTarget.response;
        this.renderCanvas();
      }, this));

      req.addEventListener('error', _.bind(function () {
        this.ui.loader.hide();
        this.ui.error.css('display', 'flex');
      }, this));

      req.open('GET', url);
      req.responseType = 'arraybuffer';
      req.send();
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersDetailInvoicesProformatPreviewView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          title: '',
          height: '80%',
          width: '60%'
        });
      });
    }
  });
});
