define('ordersListFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'selectView',
  'checkboxView',
  'settings',
  'filtersBannerEditView',
  'ordersListFiltersEditLabelsView',
  'template!ordersListFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  SelectView,
  CheckboxView,
  Settings,
  FiltersBannerEditView,
  LabelsView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'orders-list-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('orders.filters.edit'),

    ui: _.extend({
      purchaseOrderId: '.body .purchaseOrderId input',
      companyName: '.body .company-name input',
      endUserId: '.body .end-user input',
      productBaseCode: '.body .product-code input',
      productOwner: '.body .product-owner input',
      quantityFrom: '.body .quantity .quantity-from',
      quantityTo: '.body .quantity .quantity-to',
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to',
      priceFrom: '.body .price .price-from',
      priceTo: '.body .price .price-to'
    }, FiltersBannerEditView.prototype.ui),

    regions: _.extend({
      paymentRegion: '.payment-region',
      validatedRegion: '.validated-region',
      statusesRegion: '.statuses-region',
      actionRequiredInternalRegion: '.action-required-internal-region',
      actionRequiredUkrRegion: '.action-required-ukr-region',
      assignedToMeRegion: '.only-assigned-to-me-region',
      vipRegion: '.vip-region',
      notAssignedToMeRegion: '.only-not-assigned-to-me-region',
      notAssignedRegion: '.only-not-assigned-region',
      invoiceRegion: '.invoice-region',
      labelsRegion: '.labels-region',
      labelsToExcludeRegion: '.labels-to-exclude-region',
      webshopRegion: '.webshop-region'
    }, FiltersBannerEditView.prototype.regions),

    /*events:  {
      'click': 'onClick'
    },*/

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    serializeData: function() {
      var data = {};
      data.type = this.tableView.options.type;
      data.endUserId = '';
      if(this.tableView.options.endUserId)
        data.endUserId = this.tableView.options.endUserId;
      return data;
    },

    onShow: function() {
      var collection = new Backbone.Collection();

      var model = new Backbone.Model({
        id: '',
        value: _.i18n('common.all')
      });
      collection.add(model);

      this.tableView.getStatuses().done(_.bind(function (statuses) {
        _.each(statuses.models, _.bind(function (status) {
          var model = new Backbone.Model({
            id: status.get('statusConst'),
            value: status.get('status')
          });
          collection.add(model);
          this.statusesView = new SelectView({
            collection: collection,
            tabindex: 6
          });
          this.statusesRegion.show(this.statusesView);
          this.statusesView.setSelected(this.model.get('status'));
        }, this));
      }, this));

      //----------------
      this.paymentView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'true',  value: _.i18n('common.yes')},
          {id: 'false',  value: _.i18n('common.no')}
        ]),
        tabindex: 13
      });
      this.paymentRegion.show(this.paymentView);
      this.paymentView.setSelected(this.model.get('payedByCustomer'));

      //----------------
      this.validatedView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'true',  value: _.i18n('common.yes')},
          {id: 'false',  value: _.i18n('common.no')}
        ]),
        tabindex: 13
      });
      this.validatedRegion.show(this.validatedView);
      this.validatedView.setSelected(this.model.get('validated'));

      //----------------
      this.actionRequiredInternalView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'true',  value: _.i18n('common.yes')},
          {id: 'false',  value: _.i18n('common.no')}
        ]),
        tabindex: 14
      });
      this.actionRequiredInternalRegion.show(this.actionRequiredInternalView);
      this.actionRequiredInternalView.setSelected(this.model.get('actionRequiredInternal'));

      //----------------
      this.actionRequiredUkrView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'true',  value: _.i18n('common.yes')},
          {id: 'false',  value: _.i18n('common.no')}
        ]),
        tabindex: 15
      });
      this.actionRequiredUkrRegion.show(this.actionRequiredUkrView);
      this.actionRequiredUkrView.setSelected(this.model.get('actionRequiredUkr'));

      //----------------
      var webshopCollection = new Backbone.Collection();
      webshopCollection.add({id: '', value: ''});
      var webhopUrls = Settings.getWebshopLocales();
      _.each(webhopUrls, function(webhopUrl) {
        webshopCollection.add({id: webhopUrl.code, value: webhopUrl.value});
      });

      this.webshopView = new SelectView({
        collection: webshopCollection,
        tabindex: 14
      });
      this.webshopRegion.show(this.webshopView);
      this.webshopView.setSelected(this.model.get('webshop'));

      //----------------
      this.assignedToMeView = new CheckboxView({
        title: '',
        text: _.i18n('orders.onlyMine'),
        value: this.model.get('showOnlyMine'),
        tabindex: 16
      });
      this.assignedToMeRegion.show(this.assignedToMeView);
      this.assignedToMeView.on('change', _.bind(function (value) {
        if(value) {
          this.notAssignedToMeView.setValue(false);
          this.notAssignedView.setValue(false);
        }
      }, this));

      //----------------
      this.vipView = new CheckboxView({
        title: '',
        text: _.i18n('common.vip'),
        value: this.model.get('vip'),
        tabindex: 17
      });
      this.vipRegion.show(this.vipView);

      //----------------
      this.notAssignedToMeView = new CheckboxView({
        title: '',
        text: _.i18n('orders.notAssignedToMe'),
        value: this.model.get('notAssignedToMe'),
        tabindex: 18
      });
      this.notAssignedToMeRegion.show(this.notAssignedToMeView);
      this.notAssignedToMeView.on('change', _.bind(function (value) {
        if(value) {
          this.assignedToMeView.setValue(false);
          this.notAssignedView.setValue(false);
        }
      }, this));

      //----------------
      this.notAssignedView = new CheckboxView({
        title: '',
        text: _.i18n('orders.notAssigned'),
        value: this.model.get('notAssigned'),
        tabindex: 19
      });
      this.notAssignedRegion.show(this.notAssignedView);
      this.notAssignedView.on('change', _.bind(function (value) {
        if(value) {
          this.assignedToMeView.setValue(false);
          this.notAssignedToMeView.setValue(false);
        }
      }, this));

      //----------------
      this.invoiceView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'true',  value: _.i18n('common.yes')},
          {id: 'false',  value: _.i18n('common.no')}
        ]),
        tabindex: 20
      });
      this.invoiceRegion.show(this.invoiceView);
      this.invoiceView.setSelected(this.model.get('toInvoice'));

      //----------------
      this.labelsView = new LabelsView({
        model: this.model,
        tabindex: 21
      });
      this.labelsRegion.show(this.labelsView);
      this.labelsView.setSelected(this.model.get('cardColor'));

      //----------------
     /* this.labelsToExcludeView = new LabelsView({
        model: this.model,
        tabindex:21
      });
      this.labelsToExcludeRegion.show(this.labelsToExcludeView);
      this.labelsToExcludeView.setSelected(this.model.get('cardColorToExclude'));*/

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      this.ui.purchaseOrderId.val(this.model.get('purchaseOrderId'));
      this.ui.companyName.val(this.model.get('companyName'));
      if(!this.tableView.options.endUserId)
        this.ui.endUserId.val(this.model.get('endUserId'));
      this.ui.productBaseCode.val(this.model.get('productBaseCode'));
      this.ui.productOwner.val(this.model.get('productOwner'));
      this.ui.quantityFrom.val(this.model.get('quantityFrom'));
      this.ui.quantityTo.val(this.model.get('quantityTo'));
      this.ui.priceFrom.val(this.model.get('priceFrom'));
      this.ui.priceTo.val(this.model.get('priceTo'));
      if(this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if(this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        purchaseOrderId: this.ui.purchaseOrderId.val().trim(),
        payedByCustomer: this.paymentView.getSelected().get('id'),
        validated: this.validatedView.getSelected().get('id'),
        actionRequiredInternal: this.actionRequiredInternalView.getSelected().get('id'),
        actionRequiredUkr: this.actionRequiredUkrView.getSelected().get('id'),
        status: this.statusesView.getSelected().get('id'),
        companyName: this.ui.companyName.val().trim(),
        productBaseCode: this.ui.productBaseCode.val().trim(),
        productOwner: this.ui.productOwner.val().trim(),
        quantityFrom: this.ui.quantityFrom.val(),
        quantityTo: this.ui.quantityTo.val(),
        priceFrom: this.ui.priceFrom.val(),
        priceTo: this.ui.priceTo.val(),
        dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
        dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : '',
        showOnlyMine: this.assignedToMeView.getValue(),
        notAssignedToMe: this.notAssignedToMeView.getValue(),
        notAssigned: this.notAssignedView.getValue(),
        toInvoice: this.invoiceView.getSelected().get('id'),
        cardColor: this.labelsView.getSelected().join(','),
        //cardColorToExclude: this.labelsToExcludeView.getSelected().join(','),
        webshop: this.webshopView.getSelected().get('id'),
        vip: this.vipView.getValue()
      });

      if(!this.tableView.options.endUserId)
        this.model.set({endUserId: this.ui.endUserId.val()});

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.ui.purchaseOrderId.val('');
      this.paymentView.setSelected('');
      this.validatedView.setSelected('');
      this.actionRequiredInternalView.setSelected('');
      this.actionRequiredUkrView.setSelected('');
      this.statusesView.setSelected('');
      this.ui.companyName.val('');
      if(!this.tableView.options.endUserId)
        this.ui.endUserId.val('');
      this.ui.productBaseCode.val('');
      this.ui.productOwner.val('');
      this.ui.quantityFrom.val('');
      this.ui.quantityTo.val('');
      this.ui.priceFrom.val('');
      this.ui.priceTo.val('');
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
      this.assignedToMeView.setValue(false);
      this.notAssignedToMeView.setValue(false);
      this.notAssignedView.setValue(false);
      this.labelsView.setSelected([]);
      //this.labelsToExcludeView.setSelected([]);
      this.webshopView.setSelected('');
      this.invoiceView.setSelected('');
      this.vipView.setValue(false);
    }
  });  
});

