define('entities/statistics',[
  'app',
  'jquery',
  'settings'
], function(
  App,
  $,
  Settings
) {
  'use strict';
  /**
   * REST API
   */
  var API = {
    getCustomerStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/count/customers');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getOrdersCountStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/count/orders');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getOffersCountStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/count/offers');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getVectorStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/count/vectorizations');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCreditStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/count/credits');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getMonthStatistic: function() {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/stats/monthactivity');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getOrdersStatistic: function() {
      var defer = $.Deferred(),
        date = new Date(),
        params = {
          year: date.getFullYear()
        },
        url;

      url = Settings.url('compuzz2',
        'v2/stats/count/ordersperyear', params);
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getOffersStatistic: function() {
      var defer = $.Deferred(),
        date = new Date(),
        params = {
          year: date.getFullYear()
        },
        url;

      url = Settings.url('compuzz2',
        'v2/stats/count/offersperyear', params);
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getProductsStatistic: function() {
      var defer = $.Deferred(),
        params = {
          first: '0',
          pageSize: '20'
        },
        url;

      url = Settings.url('compuzz2',
        'v2/stats/count/topproducts', params);
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:get-statistic', function() {
    return API.getCustomerStatistic();
  });
  App.reqres.setHandler('orders:get-statistic', function() {
    return API.getOrdersStatistic();
  });
  App.reqres.setHandler('offers:get-statistic', function() {
    return API.getOffersStatistic();
  });
  App.reqres.setHandler('ordersCount:get-statistic', function() {
    return API.getOrdersCountStatistic();
  });
  App.reqres.setHandler('offersCount:get-statistic', function() {
    return API.getOffersCountStatistic();
  });
  App.reqres.setHandler('vectors:get-statistic', function() {
    return API.getVectorStatistic();
  });
  App.reqres.setHandler('credits:get-statistic', function() {
    return API.getCreditStatistic();
  });
  App.reqres.setHandler('month:get-statistic', function() {
    return API.getMonthStatistic();
  });
  App.reqres.setHandler('products:get-statistic', function() {
    return API.getProductsStatistic();
  });
});
