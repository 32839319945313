
define('template!pricesLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="row price-table-page m-l-0">\n  \t<div class="navbar navbar-default navbar-md">\n      <div class="navbar-header">\n        <a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n          <span class="icon-bar"></span>\n          <span class="icon-bar"></span>\n          <span class="icon-bar"></span>\n        </a>\n      </div>\n      <div class="navbar-collapse collapse">\n        <ul class="nav navbar-nav price-page-container">\n          <li class="js-tab-title billing-codes-tab ';
 if (tab === 'billingcodes') {;
__p += 'active';
 } ;
__p += '" data-tab="billingcodes">\n            <a href="#billing-codes-tab" data-toggle="tab">' +
((__t = ( _.i18n("price.billingCodes") )) == null ? '' : __t) +
'</a>\n          </li>\n          <li class="js-tab-title price-lists-tab ';
 if (tab === 'pricelists') {;
__p += 'active';
 } ;
__p += '" data-tab="pricelists">\n            <a href="#price-lists-tab" data-toggle="tab">' +
((__t = ( _.i18n("price.priceLists") )) == null ? '' : __t) +
'</a>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="content">\n  <div class="pricepage-content-container well" id="js-pricepage-content-region"></div>\n</div>';

}
return __p
};});

