
define('template!phoneCallsTypeView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="inbound" title="' +
((__t = (_.i18n('calls.inbound') )) == null ? '' : __t) +
'">\n  INBOUND\n</div>\n<div class="outbound" title="' +
((__t = (_.i18n('calls.outbound') )) == null ? '' : __t) +
'">\n  OUTBOUND\n</div>';

}
return __p
};});

