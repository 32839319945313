/**
 * Created by GSP on 4/08/2015.
 */
define('orderprocessView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'template!orderprocessViewTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var OrderProcessPluginView =  Marionette.ItemView.extend({
    template: viewTpl,

    onRender: function() {
      this.$el = this.$el.children();
      this.$el.unwrap();
      this.setElement(this.$el);
    },

    serializeData: function() {
      return {
        url: this.options.url,
        productCode: this.options.productCode,
        productName: this.options.productName ,
        poi: this.options.poi
      };
    }
  });

  module.exports = OrderProcessPluginView;
});
