define('tableFieldSelectView',[
  'module',
  'backbone',
  'underscore',
  'tableFieldSelectEditView',
  'tableFieldView'
], function (
  Module,
  Backbone,
  _,
  TableFieldSelectEditView,
  FieldView
) {
  'use strict';

  Module.exports = FieldView.extend({
    className: 'table-field-select-view table-field-view',

    events: {
      'dblclick': 'onEdit'
    },

    initialize: function () {
      this.edit = false;
      this.tableView = this.options.tableView;
      this.rowView = this.options.rowView;
      this.field = this.options.field;
    },

    onDestroy: function() {
      $(document).off('keyup', this.keyUpBind);
    },

    onRender: function() {
      this.renderContent();
    },

    onBeforeRender: function() {
      if(this.field.className)
        this.$el.addClass(this.field.className);

      this.keyUpBind = _.bind(function(e) {
        if (e.which === 27) this.onCloseEdit();
      }, this);

      $(document).on('keyup', this.keyUpBind);

      this.clickBind = _.bind(function(e) {
        if (!this.el.contains(e.target))
          this.onCloseEdit();
      }, this);
      $(document).on('click', this.clickBind);
    },

    renderContent: function() {
      try {
        if(this.edit) {
          this.editView = new TableFieldSelectEditView({
            model: this.model,
            collection: new Backbone.Collection(this.field.options),
            field: this.field
          });
          this.editView.setSelected(this.getValue());
          this.editView.render();
          this.$el.html(this.editView.el);
          this.$el.addClass('edit');
        } else {
          _.each(this.field.options, _.bind(function(option) {
            if(option.id === this.getValue()) {
              this.$el.html(option.value);
              this.$el.attr('title', option.value);
            }

          }, this));
          this.$el.removeClass('edit');
        }
      } catch(e) {
        console.log(e);
      }
    },

    getValue: function() {
      if(this.field.bind)
        if(this.model.get(this.field.bind))
          return this.model.get(this.field.bind);
      return '';
    },

    setValue: function() {
      var defer = $.Deferred();
      defer.resolve();
      return defer.promise();
    },

    onEdit: function() {
      if(this.edit)
        return;

      this.edit = true;
      this.renderContent();
    },

    onCloseEdit: function() {
      if(!this.edit)
        return;

      this.edit = false;
      this.setValue(this.editView.getSelected()).done(_.bind(function() {
        this.renderContent();
      }, this)).fail(_.bind(function() {
        this.renderContent();
      }, this));
    }
  });
});
