define('pdfView',[
  'module',
  'marionette',
  'underscore',
  'template!pdfView'
], function(
  Module,  
  Marionette,
  _, 
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'pdf-view',  

    ui: {
      content: '.content'
    },

    onRender: function() {
      if(this.options.url) 
        this.ui.content.attr('src', this.options.url);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'pdfView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: data.title,
          modal: true,
          height: data.height || '80%',
          width: data.width || '80%'
        });
      });
    }
  });  
});

