define('mailsListMailView',[
  'app',
  'module',
  'backbone',
  'tableFieldView',
  'dateUtils',
  'underscore',
  'mailsListMailLabelsView',
  'template!mailsListMailView'
], function (
  App,
  Module,
  Backbone,
  FieldView,
  DateUtils,
  _,
  LabelsView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'mail-view ' + FieldView.prototype.className,

    regions: {
      labelsRegion: '.labels-region'
    },

    attributes: function() {
      var attributes = {};

      if(this.model.get('attachments') && this.model.get('attachments').length > 0)
        attributes['data-attachments'] = true;
      else
        attributes['data-attachments'] = false;

      return attributes;
    },

    serializeData: function() {
      var data = {};
      data.mail = this.model.getAddress();
      data.name = this.model.getName();
      data.subject = this.model.get('subject');

      data.date = DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));

      data.hasAttachments = false;
      if(this.model.get('hasAttachments'))
        data.hasAttachments = true;

      return data;
    },

    onRender: function() {
      this.labelsView = new LabelsView({
        model: this.model
      });
      this.getRegion('labelsRegion').show(this.labelsView);
    }
  });
});
