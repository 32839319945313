
define('template!ordersDetailCompositionProofsheetHistoryView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">History</div>\n </div>\n<div class="items"></div>\n';

}
return __p
};});

