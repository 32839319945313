/**
 * Created by OLD on 20/08/2015.
 */
define('vectorMain',[
  'module',
  'logger',
  'vectorRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Vectorization = {
    start: function() {
      Logger.log(module, 'starting VectorizationModule');
    },

    stop: function() {
      Logger.log(module, 'stopping VectorizationModule');
    }
  };

  module.exports = Vectorization;
});
