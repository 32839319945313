define('ordersDetailSupplierProductInfosOptionView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailSupplierProductInfosOptionView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'option-view',
    template: Tpl,

    serializeData: function () {
      var data = {};

      data.name = '-';
      data.value = '-';

      _.each(this.options.product.get('productCodeVariations'), function (variation) {
        _.each(variation.productOptions, function(option) {
          if(option.code === this.model.get('code')) {

            data.type = '';

            _.each(this.options.product.get('productOptionCategories'), function (category) {
              if(category.id === option.catId) {
                data.name = category.name;
                data.type = category.type;
              }
            }, this);

            if(data.type === 'color') {
              data.value = option.name;
            }
          }
        }, this);
      }, this);

      return data;
    }
  });
});
