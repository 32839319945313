define('zoomFullScreenBehavior',['module', 'jquery', 'settings', 'underscore', 'backbone', 'marionette', 'app', 'galleryView'],
    function (module, $, Settings, _, Backbone, Marionette, App, GalleryView) {
        'use strict';

        var ZoomFullScreenBehavior = Marionette.Behavior.extend({
            defaults: {
                regionContainer: 'main',
                region: 'zoom',
                target: '.zoom-fullscreen-item'
            },

            /**
             * Bind dynamicly the click event on target option
             */
            events: function () {
                var _events = {};
                _events['click ' + this.getOption('target')] = this.onClick;
                return _events;
            },

            onShow: function () {
                this.setStyles();
            },

  
            onClick: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                var data = [];
                this.createItem($(e.currentTarget), data);
                this.renderGallery(data);
            },

            /**
             * Enable to create the gallery item
             * @param $item - jquery target element
             * @param data - array of the bakckbone target models
             */
            createItem: function ($item, data) {
                var link = $item.data('link').replace('params=t', 'params=m'),
                    name = $item.data('name');
                var model = new Backbone.Model({
                    zoomLink: link,
                    downloadLink: '',
                    fileName: name
                });
                data.push(model);
            },

            /**
             * Add cursor pointer on target item
             */
            setStyles: function () {
                this.$el.find(this.getOption('target')).css('cursor', 'pointer');
            },

            /**
             * Render the photoswipe gallery view
             * @param data - array of the bakckbone target models
             */
            renderGallery: function (data) {
                App.regions.getRegion(this.getOption('regionContainer'))
                    .currentView.getRegion(this.getOption('region'))
                    .show(new GalleryView({ data: data }));
            }

        });

        module.exports = ZoomFullScreenBehavior;
    });
