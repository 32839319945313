define('tableHeaderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'tableHeaderFieldView',
  'template!tableHeaderView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl, 
    className: 'table-header-view',
    selectedSortField: '',
    views: [],    

    childEvents: {
      'sort:change': 'onSortChange'
    },

    initialize: function () {
      this.tableView = this.options.tableView;
    },

    onShow: function() {      
      _.each(this.tableView.fields, function(field) {
        if(field.showHeader !== false) {
          var ViewClass = FieldView;
          if(field.headerView)
            ViewClass = field.headerView;

          var fieldView = new ViewClass({
            tableView: this.options.tableView,
            field: field
          });
          this.views.push(fieldView);
          this.$el.append(fieldView.render().el);
        }
      }, this);  
      
      this.setSelectedSortField();  
    },

    onSortChange: function(childView, sortDirection) {
      this.selectedSortField = childView.name;
      this.setSelectedSortField();   
      this.trigger('sort:change', childView.name, sortDirection);
    },

    setSelectedSortField: function() {   
      _.each(this.views, function(view) {
        if(view.name === this.options.selectedSortField)
          view.enableSort(true);
        else
          view.enableSort(false);
        
      }, this); 
    }
  });
});
  
