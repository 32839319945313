define('entities/pim_product_similar',[
  'app',
  'jquery',
  'settings',
  'backbone'
], function (
  App,
  $,
  Settings,
  Backbone
) {
  'use strict';

  var ProductSimilar = Backbone.Model.extend({
    defaults: {
      name: '',
      itemNumber: '',
      price: 0,
      comment: ''
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/similars/' + this.get('secId'));
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/similars/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/similars/');
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId'
  });

  var ProductSimilarCollection = Backbone.Collection.extend({
    fetch: function (productSecId) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + productSecId + '/similars/', {
        first: 0,
        pageSize: 99
      });
      return Backbone.Collection.prototype.fetch.call(this);
    },

    getCount: function (params) {
      var that = this;

      that.trigger('count:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/similars/rowCount', params),
        success: function (count) {
          that.trigger('count:sync', count);
        }
      });
    },
    model: ProductSimilar
  });

  App.reqres.setHandler('pim:product-similar:collection', function (productSimilarsJSON) {
    return new ProductSimilarCollection(productSimilarsJSON);
  });
  App.reqres.setHandler('pim:product-similar:model', function (productSimilarJSON) {
    return new ProductSimilar(productSimilarJSON);
  });
});
