
define('template!pimProductAttributesTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">PIM Product Attributes</div>\n    <div class="buttons-region"></div>    \n</div>\n<div class="body">       \n    <div class="view">\n        <div class="row">\n            <div class="col-xs-3">\n                <div class="attribute-list-region"></div>\n                <div class="attribute-view-region"></div>\n            </div>            \n            <div class="col-xs-9">\n                <div class="attributes-title">Reseller Attributes</div>\n                <div class="selected-attributes-region"></div>\n                <div class="attributes-title">Supplier Attributes</div>\n                <div class="supplier-attributes-region"></div>               \n            </div> \n        </div>       \n    </div>  \n</div>\n<div class="loading-block js-loading-block loader"></div>';

}
return __p
};});

