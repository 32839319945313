define('ordersDetailCompositionProofsheetView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'pdfView', 
  'settings',
  'modalsConfirmationView',
  'dropzone',
  'imageUtils',
  'compuzzProUtils',
  'ordersDetailCompositionProofsheetHistoryView',
  'template!ordersDetailCompositionProofsheetView'
], function (
  App,
  Module,
  Marionette,
  _,
  PdfView,
  Settings,
  ConfirmationView,
  Dropzone,
  ImageUtils,
  CompuzzProUtils,
  HistoryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-body-composition-proofsheet-view',

    ui: {
      content: '.content',
      imageContainer: '.image-ctn',
      loader: '.loader-ctn',
      uploadButtonContainer: '.content .upload-button-container',
      uploadInput: '.content .upload-input',
      deleteButton: '.content .delete-button',
      zoomButton: '.content .zoom-button',
      downloadButton: '.content .download-button',
      copyButton: '.content .copy-button'
    },

    regions: {
      historyRegion: '.history-region'
    },

    modelEvents: {
      'change:proofsheetFileUid': 'render'
    },

    events: {
      'click @ui.deleteButton': 'onDelete',
      'click @ui.uploadButtonContainer': 'onUpload',
      'click @ui.downloadButton': 'onDownload',
      'click @ui.zoomButton': 'onZoom'
    },

    serializeData: function () {
      var data = {};

      data.src = '';

      if(this.model.get('proofsheetFileUid'))
        data.src = Settings.url('file', this.model.get('proofsheetFileUid')) + '&action=preview';

      return data;
    },

    onRender: function () {
      this.historyView = new HistoryView({
        model: this.model
      });
      this.getRegion('historyRegion').show(this.historyView);

      //----------
      if(this.model.get('proofsheetFileUid')) {
        this.ui.loader.css('display', '');
        this.ui.imageContainer.css('display', 'flex');
        this.ui.uploadButtonContainer.css('display', 'none');
      } else {
        this.ui.loader.css('display', '');
        this.ui.imageContainer.css('display', 'none');
        this.ui.uploadButtonContainer.css('display', 'flex');
      }

      //----------
      var that = this;

      this.ui.content.dropzone({
        url: Settings.url('compuzz'),
        autoProcessQueue: true,
        uploadMultiple: false,
        acceptedFiles: 'application/pdf',
        init: function () {
          this.on('addedfile', function(file) {
            that.showLoader(true);

            var formdata = new FormData();
            formdata.append('file', file);
            formdata.append('filename', file.name);
            var fileInfo = {
              fileType: file.type,
              fileExt: file.name.substring( file.name.lastIndexOf( '.' ) ),
              fileName: file.name,
              fileLastModified: file.lastModified,
              formData: formdata,
              base64Value: ''
            };
            App.request('order:upload-custom-proofsheet', that.model.get('purchaseOrderId'), fileInfo).then(_.bind(function () {
              CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
              that.model.fetch();
            }, this));

          });
        },
        dictDefaultMessage: _.i18n('common.file.drag.drop')
      });
    },

    onUpload: function () {
      this.ui.content.click();
    },

    showLoader: function () {
      this.ui.imageContainer.css('display', 'none');
      this.ui.uploadButtonContainer.css('display', 'none');
      this.ui.loader.css('display', 'flex');
    },

    onDownload: function () {
      var url = Settings.url('file', this.model.get('proofsheetFileUid'));
      if(url === '')
        return;

      var fileName = this.model.get('purchaseOrderId') + '.pdf';

      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';
      anchor.download = fileName;
      anchor.click();
    },

    onDelete: function () {
      var uid = this.model.get('proofsheetFileUid');
      if(uid === '')
        return;

      ConfirmationView.prototype.open({
        text: _.i18n('orders.detail.composition.proofsheet.delete.confirmation'),
        onConfirm: _.bind(function () {
          App.request('order:delete-custom-proofsheet', this.model.get('purchaseOrderId')).then(_.bind(function () {
            this.model.fetch();
          }, this));
        }, this)
      });
    },

    onZoom: function () {
      PdfView.prototype.open({
        url: Settings.url('file', this.model.get('proofsheetFileUid')),
        title: 'Proofsheet: ' + this.model.get('purchaseOrderId')
      });
    }
  });
});

