define('translationsFilterLanguagesLayoutView',[
  'module',
  'template!translationsFilterLanguagesLayoutTpl',
  'marionette',
  'translationsFilterLanguageListView',
  'translationsFilterLanguageButtonView',
  'jquery'
], function(
  module,
  filterLanguageLayoutTpl,
  Marionette,
  FilterLanguageListView,
  FilterLanguageButtonView,
  $
) {
  'use strict';

  var FilterLanguageLayoutView = Marionette.LayoutView.extend({
    template: filterLanguageLayoutTpl,

    regions: {
      button: '#translations-filter-button-region',
      list: '#translations-filter-list-region'
    },

    events: {
      'show.bs.dropdown .js-dropdown': '_onShowDropdown',
      'hide.bs.dropdown .js-dropdown': '_onHideDropdown'
    },

    onShow: function() {
      this.getRegion('button').show(new FilterLanguageButtonView({
        collection: this.collection
      }));
      this.getRegion('list').show(new FilterLanguageListView({
        collection: this.collection
      }));
      $.material.init();
    },

    _onShowDropdown: function() {
      this.trigger('show:dropdown');
    },

    _onHideDropdown: function() {
      this.trigger('hide:dropdown');
    }
  });

  module.exports = FilterLanguageLayoutView;
});
