define( 'entities/caccounts/objectnames',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var ObjectNameModel = Backbone.Model.extend({
    defaults: {
      code: null,
      name: null,
      type: 'text',
      secId: null,
      refCAccountJson: {}
    },

    createFromTemplate: function(data) {
      //v2/datasetvalues/fromTemplate/{endUserId}/{templateSecId}?tokenId={tokenId}&endUserId={EndUserId}&oniSecId={ObjectNameSecId}&templateSecId={TemplateSecId}
      var that = this,
        url,
        path = 'v2/datasetvalues/fromTemplate/' + this.get('endUserId') + '/' + data.templateSecId;

      url = Settings.url('compuzz', path, {
          templateGroupSecId: data.templateGroupSecId,
          endUserId: this.get('endUserId'),
          oniSecId: this.get('objectNameSecId')
        });
      $.ajax({
        url: url,
        type: 'POST',
        success: function(model) {
          that.set(model);
          that.trigger('sync');
        },
        error: function(resp) {
          that.trigger('error', resp);
        }
      });
    },


    idAttribute: 'secId'
  });

  /**
   * REST API
   */
  var API = {
    getObjectNamesUrl: function(id) {
      return Settings.url(
        'compuzz',
        'v2/caccounts/' + id + '/objectnames'
      );
    },

    getRowCountUrl: function(id) {
      return 'v2/caccounts/' + id + '/objectnames/rowCount';
    },

    getModel: function(params) {
      return new ObjectNameModel(params);
    },

    getItem: function(data) {
      var model =  new ObjectNameModel(),
        defer = $.Deferred();

      model.url = Settings.url(
        'compuzz',
        'v2/caccounts/' + data.cAccountId + '/objectnames/' + data.secId
      );

      model.fetch().done(function(resp) {
        defer.resolve(resp);
      }).fail(function(error) {
        if (error.responseJSON) {
          defer.resolve(error.responseJSON);
        }
      });

      return defer.promise();
    },

    postItem: function(data) {
      var model =  data.model,
        params = {
          first: 0,
          pageSize: 10
        },
        defer = $.Deferred();

      model.url = Settings.url(
        'compuzz',
        'v2/caccounts/' + data.cAccountId + '/objectnames',
        params
      );
      model.idAttribute = 'secId';

      model.save().done(function(resp) {
        defer.resolve(resp);
      });

      return defer.promise();
    },

    deleteItem: function(data) {
      var defer = $.Deferred(),
        url = Settings.url(
          'compuzz',
          'v2/caccounts/' + data.cAccountId + '/objectnames/' + data.itemId
        );

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount-objnames:get-url', function(id) {
    return API.getObjectNamesUrl(id);
  });

  App.reqres.setHandler('caccount-objnames:row-count-url', function(id) {
    return API.getRowCountUrl(id);
  });

  App.reqres.setHandler('caccount-objnames:get-model', function(params) {
    return API.getModel(params);
  });

  App.reqres.setHandler('caccount-objnames:post-item', function(data) {
    return API.postItem(data);
  });

  App.reqres.setHandler('caccount-objnames:get-item', function(data) {
    return API.getItem(data);
  });

  App.reqres.setHandler('caccount-objnames:delete-item', function(data) {
    return API.deleteItem(data);
  });
});
