define('ordersListFiltersEditLabelsView',[
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore',
  'events',
  'ordersListFiltersEditLabelView'
], function (
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  Events,
  LabelView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'orders-list-filters-edit-labels-view',
    childView: LabelView,

    attributes: function () {
      return {
        'title': this.options.title || '',
        'tabindex': this.options.tabindex || 0
      };
    },

    events: {
      'focus': 'onFocus',
      'blur': 'onBlur'
    },

    childEvents: {
      'onSelect': 'onSelected'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.focused = false;
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {  
      this.collection.reset();

      var cAccountConfig = Settings.get('cAccountConfig');
      if (cAccountConfig && cAccountConfig.get('cardColors')) {
        _.each(cAccountConfig.get('cardColors').models, function(color) {
          var model = new Backbone.Model(color.toJSON());
          this.collection.add(model);
        }, this);
      }
    },

    getSelected: function () {
      var selected = [];
      _.each(this.collection.models, function(model) {
        if(model.get('selected'))
          selected.push(model.get('color').replace('#', '').toUpperCase());
      }, this);
      return selected;
    },

    setSelected: function (ids) {
      if(!ids)
        return;

      _.each(this.collection.models, function(model) {
        if(ids.includes(model.get('color').replace('#', '').toUpperCase()))
          model.set('selected', true);
        else
          model.set('selected', false);
      }, this);
    },

    onFocus: function() {
      this.focused = true;

      var index = 0;
      _.each(this.collection.models, function(model, modelIndex) {
        if(modelIndex === index)
          model.set('focused', true);
        else
          model.set('focused', false);
      }, this);
    },

    onSelected: function(view, model) {
      this.focused = true;

      var index = this.collection.indexOf(model);
      _.each(this.collection.models, function(model, modelIndex) {
        if(modelIndex === index)
          model.set('focused', true);
        else
          model.set('focused', false);
      }, this);
    },

    onBlur: function() {
      this.focused = false;
      _.each(this.collection.models, function(model) {
        model.set('focused', false);
      }, this);
    },

    onKeyDown: function(e) {
      var index = 0;

      if (e.which === 37 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        _.each(this.collection.models, function(model) {
          if(model.get('focused'))
            index = this.collection.indexOf(model);
        }, this);

        index--;
        if(index < 0)
          index = this.collection.models.length-1;

        _.each(this.collection.models, function(model, modelIndex) {
          if(modelIndex === index)
            model.set('focused', true);
          else
            model.set('focused', false);
        }, this);

        return false;
      }

      if (e.which === 39 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        _.each(this.collection.models, function(model) {
          if(model.get('focused'))
            index = this.collection.indexOf(model);
        }, this);

        index++;
        if(index >= this.collection.models.length)
          index = 0;

        _.each(this.collection.models, function(model, modelIndex) {
          if(modelIndex === index)
            model.set('focused', true);
          else
            model.set('focused', false);
        }, this);

        return false;
      }
    }
  });
});
