define('productGridFiltersView',[
  'module',
  'marionette',
  'template!productGridFiltersTpl',
  'backbone'
], function(
  module,
  Marionette,
  filtersTpl,
  Backbone
) {
  'use strict';

  var FiltersModel = Backbone.Model.extend({
    defaults: {
      productCode: '',
      productName: '',
      manufacturer: '',
      status: ''
    }
  });

  var FiltersView = Marionette.ItemView.extend({
    template: filtersTpl,

    ui: {
      codeInput: '.js-code-input',
      nameInput: '.js-name-input',
      manufacturerInput: '.js-manufacturer-input',
      statusInput: '.js-status-input',
      sortIcons: '.js-sort-icon'
    },

    className: 'grid-header p-l-10',
    
    events: {
      'click .js-change-code-sort': '_changeCodeSort',
      'click .js-change-name-sort': '_changeNameSort',
      'click .js-change-manufacturer-sort': '_changeManufacturerSort',
      'click .js-change-status-sort': '_changeStatusSort',
      'change @ui.codeInput': '_changeCodeInput',
      'change @ui.nameInput': '_changeNameInput',
      'change @ui.manufacturerInput': '_changeManufacturerInput',
      'change @ui.statusInput': '_changeStatusInput',
      'click .js-code-clear': '_clearCode',
      'click .js-name-clear': '_clearName',
      'click .js-manufacturer-clear': '_clearManufacturer',
      'click .js-status-clear': '_clearStatus'
    },

    modelEvents: {
      'change:sidx': '_showSort',
      'change:sord': '_showSort'
    },

    initialize: function(options) {
      this.model = new FiltersModel(options.filters);
    },

    onShow: function() {
      this._showSort();
    },

    _showSort: function() {
      var $icons,
        disabledSord;
      this.ui.sortIcons.hide();
      if(this.model.get('sidx')) {
        $icons = this.ui.sortIcons.filter('[data-sort-value=' + this.model.get('sidx') + ']');
        $icons.show();
        disabledSord = this.model.get('sord') === 'asc' ? 'desc' : 'asc';
        $icons.find('span').removeClass('ui-state-disabled');
        $icons.find('span[sort=' + disabledSord + ']').addClass('ui-state-disabled');
      }
    },

    _changeCodeSort: function() {
      this._changeSort('productCode');
    },

    _changeNameSort: function() {
      this._changeSort('productName');
    },

    _changeManufacturerSort: function() {
      this._changeSort('globalSearchField');
    },

    _changeStatusSort: function() {
      this._changeSort('status');
    },

    _changeSort: function(sortValue) {
      if(this.model.get('sidx') === sortValue) {
        this.model.set('sord', this.model.get('sord') === 'asc' ? 'desc' : 'asc');
      } else {
        this.model.set({
          sord: 'asc',
          sidx: sortValue
        });
      }
      this._triggerChangeSort(sortValue, this.model.get('sord'));
    },

    _changeCodeInput: function() {
      this.trigger('change', {
        productCode: this.ui.codeInput.val()
      });
    },

    _changeNameInput: function() {
      this.trigger('change', {
        productName: this.ui.nameInput.val()
      });
    },

    _changeManufacturerInput: function() {
      this.trigger('change', {
        manufacturer: this.ui.manufacturerInput.val()
      });
    },

    _changeStatusInput: function() {
      this.trigger('change', {
        status: this.ui.statusInput.val()
      });
    },

    _clearCode: function() {
      this.ui.codeInput.val('').change();
    },

    _clearName: function() {
      this.ui.nameInput.val('').change();
    },

    _clearManufacturer: function() {
      this.ui.manufacturerInput.val('').change();
    },

    _clearStatus: function() {
      this.ui.statusInput.val('').change();
    },

    _triggerChangeSort: function(sidx, sord) {
      this.trigger('change', {
        sidx: sidx,
        sord: sord
      });
    }
  });

  module.exports = FiltersView;
});
