define('dateView',[
  'module',
  'marionette',
  'underscore',
  'template!dateView'
], function(
  Module,  
  Marionette,
  _, 
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'date-view',     

    modelEvents: {
      'change': 'render'
    },

    serializeData: function () {
      var data = {};

      data.date = '';

      if(this.model.get('date')) {
        var date = new Date(this.model.get('date'));
        var sDate = date.getDate();
        data.date = sDate + ' ' + date.toLocaleString('default', {month: 'long'}).substring(0, 3);
      }

      //--------------------------            

      data.icon = '';

      if(this.model.get('icon'))
        data.icon = this.model.get('icon');

      return data;
    },

    onRender: function() {
      if(this.model.get('date'))
        this.$el.css('display', 'flex');
      else
        this.$el.css('display', 'none');

      this.$el.attr('color', this.model.get('color'));
    }
   
  });  
});

