define('ordersBoardFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'ordersBoardFiltersEditView',
  'ordersListFiltersLabelsView',
  'ordersBoardFiltersQuickAccessView',
  'filtersBannerView',
  'ordersController'
], function(
    Module,
    Backbone,
    _,
    Settings,
    moment,
    EditView,
    LabelsView,
    QuickAccessView,
    FiltersBannerView,
    Controller
) {
  'use strict';

  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,
    quickAccessViewClass: QuickAccessView,

    initialize: function() {
      Controller.filtersView = this;
      var settings = Settings.get('ordersFilters', true);
      if(settings && !this.options.endUserId) {
        this.model = new Backbone.Model(JSON.parse(settings));
        this.model.set('status', '');
      } else {
        this.model = new Backbone.Model({
          purchaseOrderId: '',
          endUserId: '',
          companyName: '',
          productBaseCode: '',
          productOwner: '',
          quantityFrom: '',
          quantityTo: '',
          priceFrom: '',
          priceTo: '',
          dateFrom: '',
          dateTo: '',
          actionRequiredUkr: '',
          actionRequiredInternal: '',
          payedByCustomer: '',
          showOnlyMine: '',
          notAssignedToMe: '',
          notAssigned: '',
          toInvoice: '',
          cardColor: '',
          //cardColorToExclude: '',
          webshop: '',
          validated: '',
          vip: ''
        });
        Settings.set('ordersFilters', JSON.stringify(this.model.attributes), true);
      }

      if(this.options.endUserId) {
        this.model.set('endUserId', this.options.endUserId);
        Settings.set('ordersFilters', JSON.stringify(this.model.attributes), true);
      }

      this.boardView = this.options.boardView;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {
      if(this.model.get('purchaseOrderId') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'purchaseOrderId',
          libelle: this.options.type === 'command' ? _.i18n('orderList.orderNumber') : _.i18n('orderList.offerNumber'),
          value: this.model.get('purchaseOrderId')
        }));
      }

      //----------------
      if(this.model.get('endUserId') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'endUserId',
          libelle: _.i18n('orderList.endUser'),
          value: this.model.get('endUserId')
        }));
      }

      //----------------
      if(this.model.get('companyName') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'companyName',
          libelle: _.i18n('common.companyName'),
          value: this.model.get('companyName')
        }));
      }

      //----------------
      if(this.model.get('productBaseCode') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'productBaseCode',
          libelle: _.i18n('orderList.productCode'),
          value: this.model.get('productBaseCode')
        }));
      }

      //----------------
      if(this.model.get('productOwner') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'productOwner',
          libelle: _.i18n('orderList.productOwner'),
          value: this.model.get('productOwner')
        }));
      }

      //----------------
      if(this.model.get('quantityFrom') !== '' || this.model.get('quantityTo') !== '') {
        var quantityFrom = this.model.get('quantityFrom') === '' ? '*' : this.model.get('quantityFrom');
        var quantityTo = this.model.get('quantityTo') === '' ? '*' : this.model.get('quantityTo');
        this.collection.add(new Backbone.Model({
          id: 'quantities',
          libelle: _.i18n('common.quantity'),
          value: quantityFrom + ' - ' + quantityTo
        }));
      }

      //----------------
      if(this.model.get('priceTo') !== '' || this.model.get('priceFrom') !== '') {
        var priceFrom = this.model.get('priceFrom') === '' ? '*' : this.model.get('priceFrom');
        var priceTo = this.model.get('priceTo') === '' ? '*' : this.model.get('priceTo');
        this.collection.add(new Backbone.Model({
          id: 'prices',
          libelle: _.i18n('common.price'),
          value: priceFrom + ' - ' + priceTo
        }));
      }

      //----------------
      var dateValue = '';

      if(this.model.get('dateFrom') !== '' && this.model.get('dateTo') !== '') {
        dateValue = moment(new Date(this.model.get('dateFrom'))).format('DD/MM/YYYY');
        dateValue += ' - ';
        dateValue += moment(new Date(this.model.get('dateTo'))).format('DD/MM/YYYY');
        this.collection.add(new Backbone.Model({
          id: 'dates',
          libelle: _.i18n('orderList.dates'),
          value: dateValue
        }));
      }

      //----------------
      if(this.model.get('actionRequiredUkr') !== '') {
        var actionRequiredUkr = this.model.get('actionRequiredUkr') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'actionRequiredUkr',
          libelle: _.i18n('orderList.actionRequiredUkr'),
          value: actionRequiredUkr
        }));
      }

      //----------------
      if(this.model.get('actionRequiredInternal') !== '') {
        var actionRequiredInternal = this.model.get('actionRequiredInternal') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'actionRequiredInternal',
          libelle: _.i18n('orderList.actionRequiredInternal'),
          value: actionRequiredInternal
        }));
      }

      //----------------
      if(this.model.get('payedByCustomer') !== '') {
        var payedByCustomer = this.model.get('payedByCustomer') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'payedByCustomer',
          libelle: _.i18n('orderList.payedByCustomer'),
          value: payedByCustomer
        }));
      }

      //----------------
      if(this.model.get('validated')) {
        var validated = this.model.get('validated') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'validated',
          libelle: _.i18n('common.validated'),
          value: validated
        }));
      }

      //----------------
      if(this.model.get('toInvoice') !== '') {
        var toinvoice = this.model.get('toInvoice') === 'true' ? _.i18n('common.yes') : _.i18n('common.no');
        this.collection.add(new Backbone.Model({
          id: 'toInvoice',
          libelle: _.i18n('orders.filters.toInvoice'),
          value: toinvoice
        }));
      }

      //----------------
      if(this.model.get('webshop')) {
        var webshopCollection = new Backbone.Collection();
        webshopCollection.add({id: '', value: ''});
        var webhopUrls = Settings.getWebshopLocales();
        _.each(webhopUrls, function(webhopUrl) {
          webshopCollection.add({id: webhopUrl.code, value: webhopUrl.value});
        });

        var webshop = webshopCollection.get(this.model.get('webshop'));
        if(webshop) {
          this.collection.add(new Backbone.Model({
            id: 'webshop',
            libelle: _.i18n('common.webshop'),
            value: webshop.get('value')
          }));
        }
      }

      //----------------
      if(this.model.get('showOnlyMine') === true) {
        this.collection.add(new Backbone.Model({
          id: 'showOnlyMine',
          libelle: _.i18n('orders.onlyMine'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      if(this.model.get('notAssignedToMe') === true) {
        this.collection.add(new Backbone.Model({
          id: 'notAssignedToMe',
          libelle: _.i18n('orders.notAssignedToMe'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      if(this.model.get('notAssigned') === true) {
        this.collection.add(new Backbone.Model({
          id: 'notAssigned',
          libelle: _.i18n('orders.notAssigned'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      if (this.model.get('vip') === true) {
        this.collection.add(new Backbone.Model({
          id: 'vip',
          libelle: _.i18n('common.vip'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      this.cardColorView = new LabelsView({
        model: this.model,
        selectedIds: this.model.get('cardColor')
      });

      if(this.model.get('cardColor') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'cardColor',
          libelle: _.i18n('orders.labels'),
          view: this.cardColorView
        }));
      }

      //----------------
      /*this.cardColorToExcludeView = new LabelsView({
        model: this.model,
        selectedIds: this.model.get('cardColorToExclude')
      });

      if(this.model.get('cardColorToExclude') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'cardColorToExclude',
          libelle: _.i18n('orders.labels-to-exclude'),
          view: this.cardColorToExcludeView
        }));
      }*/

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    onDeleteFilter: function(model, filterModel) {
      if(filterModel.get('id') === 'dates') {
        model.set('dateFrom', '');
        model.set('dateTo', '');
      } else if(filterModel.get('id') === 'quantities') {
        model.set('quantityFrom', '');
        model.set('quantityTo', '');
      } else if(filterModel.get('id') === 'prices') {
        model.set('priceFrom', '');
        model.set('priceTo', '');
      } else
        model.set(filterModel.get('id'), '');
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('purchaseOrderId') !== model.get('purchaseOrderId'))
        needFetch = true;
      if(this.model.get('endUserId') !== model.get('endUserId'))
        needFetch = true;
      if(this.model.get('companyName') !== model.get('companyName'))
        needFetch = true;
      if(this.model.get('productBaseCode') !== model.get('productBaseCode'))
        needFetch = true;
      if(this.model.get('productOwner') !== model.get('productOwner'))
        needFetch = true;
      if(this.model.get('quantityFrom') !== model.get('quantityFrom') ||
          this.model.get('quantityTo') !== model.get('quantityTo'))
        needFetch = true;
      if(this.model.get('priceTo') !== model.get('priceTo') ||
          this.model.get('priceFrom') !== model.get('priceFrom'))
        needFetch = true;
      if(this.model.get('dateFrom') !== model.get('dateFrom') ||
          this.model.get('dateTo') !== model.get('dateTo'))
        needFetch = true;
      if(this.model.get('actionRequiredUkr') !== model.get('actionRequiredUkr'))
        needFetch = true;
      if(this.model.get('actionRequiredInternal') !== model.get('actionRequiredInternal'))
        needFetch = true;
      if(this.model.get('webshop') !== model.get('webshop'))
        needFetch = true;
      if(this.model.get('payedByCustomer') !== model.get('payedByCustomer'))
        needFetch = true;
      if(this.model.get('validated') !== model.get('validated'))
        needFetch = true;
      if(this.model.get('showOnlyMine') !== model.get('showOnlyMine'))
        needFetch = true;
      if(this.model.get('notAssignedToMe') !== model.get('notAssignedToMe'))
        needFetch = true;
      if(this.model.get('notAssigned') !== model.get('notAssigned'))
        needFetch = true;
      if(this.model.get('toInvoice') !== model.get('toInvoice'))
        needFetch = true;
      if(this.model.get('cardColor') !== model.get('cardColor'))
        needFetch = true;
      /*if(this.model.get('cardColorToExclude') !== model.get('cardColorToExclude'))
        needFetch = true;*/
      if(this.model.get('webshop') !== model.get('webshop'))
        needFetch = true;
      if (this.model.get('vip') !== model.get('vip'))
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('ordersFilters', JSON.stringify(this.model.attributes), true);
    }
  });
});

