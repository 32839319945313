
define('template!similarCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('product.itemNumber') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="itemNumber" placeholder="' +
((__t = ( _.i18n('product.itemNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( itemNumber )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('price.price') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n              <input type="number" class="form-control floating-label js-info-input" data-field-name="price" placeholder="' +
((__t = ( _.i18n('price.price') )) == null ? '' : __t) +
'" value="' +
((__t = ( price )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n              <textarea type="text" class="form-control floating-label js-info-input" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n          </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

