define('webshopProductsDetailBodyImagesTypeView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'buttonView',
  'webshopProductsDetailBodyImagesTypeListView',
  'template!webshopProductsDetailBodyImagesTypeView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ButtonView,
  ListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'type-view',

    regions: {
      addButtonRegion: '.add-button-region',
      listRegion: '.list-region'
    },

    onRender: function () {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('common.add-image'),
        title: _.i18n('common.add-image')
      });
      this.addButtonRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));

      //----------------------------
      this.listView = new ListView({
        model: this.options.category,
        type: this.model.get('code')
      });
      this.listRegion.show(this.listView);
    },

    serializeData: function () {
      var data = {};

      data.title = this.model.get('label');

      return data;
    },

    onAdd: function () {
      this.listView.add();
    }
  });
});
