/**
 * Created by RKL on 13/08/2015.
 */
define('caccountCreditsTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'dateUtils',
  'jqgridView',
  'template!caccountCreditsTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  dateConverter,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CAccountCreditsTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'creditsFilters',

    triggers: {
      'click @ui.addCredit' : 'onAddCredits'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-credits',
        jqGridPager: '#jq-grid-pager-credits',
        addCredit: '.js-add-credit'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    serializeData: function() {
      return {
        balance: this.options.balance
      };
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions, options;

      this.sidx = 'creatDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.date'),
            name: 'creatDate',
            classes: 'credits-link',
            formatter: this.dateFormatter,
            search: true,
            index: 'creatDate',
            sorttype: 'date',
            searchrules: {date: true}, 
            searchoptions: {
              dataInit: function(element) {
                $(element).datepicker({
                  id: 'validityFrom_datePicker',
                  dateFormat: 'dd/mm/yy',
                  changeYear: true,
                  showOn: 'focus',
                  prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                  nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                });
              }
            }
          }, {
            label: _.i18n('common.type'),
            name: 'type',
            classes: 'credits-link',
            formatter: this.defaultFormatter,
            search: true,
            index: 'type',
            stype:'select',
            searchrules: {select: true},
            searchoptions: {
              value: that.types,
              defaultValue: _.i18n('common.all')
            }
          }, {
            label: _.i18n('credits.numberOfCredits'),
            name: 'numberOfCredit',
            align: 'left',
            classes: 'credits-link',
            formatter: this.creditsFormatter,
            search: false,
            index: 'numberOfCredit'
          }, {
            label: _.i18n('credits.itemKey'),
            name: 'itemKey',
            align: 'left',
            classes: 'credits-link',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'itemKey'
          }, {
            label: _.i18n('common.preview'),
            name: 'previewUrl',
            align: 'left',
            classes: 'credits-link image-cell',
            formatter: this.previewFormatter,
            search: false,
            sortable: false,
            index: 'previewUrl'
          }, {
            label: _.i18n('credits.identifier'),
            name: 'identifier',
            align: 'left',
            classes: 'credits-link',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'identifier'
          }
        ],

        serializeGridData: function(postData) {
          var date;

          if (postData.creatDate) {
            date = new Date(dateConverter.toMDYFormat(postData.creatDate));
            postData.dateFrom = date.getTime();
            postData.dateTo = Date.now();
          } else if (!that.sorting) {
            delete postData.dateFrom;
            delete postData.dateTo;
          }
          that.sorting = false;

          if (postData.type === that.allString) {
            delete postData.type;
          }
          postData.sortField = postData.sidx;
          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    showImageZoomPopover: function(fileLink, target) {
      var content = '<div class="img-responsive">' +
        '<img class="img-popover" src="' + Settings.url('base', fileLink) + '"/></div>';

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'left',
        html: 'true',
        container: $('#content')
      }).data('bs.popover').tip().addClass('jqgrid-popover');
    },

    getSelectValues: function() {
      var that = this,
        types = [
          {
            send: 'export',
            display: 'export'
          }, {
            send: 'refill',
            display: 'refill'
          }, {
            send: 'vectorization_auto',
            display: 'vectorization_auto'
          }, {
            send: 'vectorization_manual',
            display: 'vectorization_manual'
          }, {
            send: 'vectorization_manual_urgent',
            display: 'vectorization_manual_urgent'
          }
        ];

      this.allString = _.i18n('common.all');

      this.types = this.allString + ':' + this.allString;
      _.each(types, function(status) {
        that.types += ';' + status.send + ':' + status.display;
      });

      this.selectValue = {
        type: this.allString
      };
    },

    onGridFilterChange: function() {
      var that = this,
        filters = {},
        value, date;
      
      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.allString ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            date = new Date(dateConverter.toMDYFormat(value));
            filters.dateFrom = date.getTime();
            filters.dateTo = Date.now();
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination(true);
    },

    previewFormatter:  function(cellValue, colModel, rowObj) {
      if ((rowObj.type === 'refill' && !rowObj.invoiceAvailable) || rowObj.itemKey === 'FREE') {
        return '';
      } else if (rowObj.type === 'refill' && rowObj.invoiceAvailable && rowObj.itemKey !== 'FREE') {
         return '';
        /*return '<a class="btn primary-btn-text btn-sm" href="' + Settings.url('compuzz', 'v2/invoices/' + rowObj.secId) + '">' +
          '<i class="mdi-action-get-app"></i><span>' + _.i18n('common.downloadInvoice') + '</span></a>';*/
      } else {
        var _getImageCellValue = function (cellValue) {
          if (!cellValue) {
            return Settings.get('notFoundImage');
          } else {
            return Settings.url('file', cellValue, {size: 'thumbnail'});
          }
        };
        
        cellValue = _getImageCellValue(cellValue);

        return '<span><img data-link="' + cellValue + '"' +
          'class="jq-picture js-image-cell" src="' + cellValue + '"/></span>';
      }
    },
    creditsFormatter: function(cellValue, options) {
      if (cellValue === null) { cellValue = ''; }

      if (cellValue.toString().substring(0, 1) !== '-') {
        cellValue = '+' + cellValue;
      }

      return '<span class="cell-default" data-row-id="' +
        options.rowId + '">' + cellValue + '</span>';
    }
  });

  module.exports = CAccountCreditsTabView;
});
