define('ordersDetailOrderHistoryView',[
  'module',
  'marionette',
  'underscore', 
  'ordersHistoryView',
  'template!ordersDetailOrderHistoryView'
], function (
  module,
  Marionette,
  _,
  HistoryView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-history-view',

    regions: {
      historyRegion: '.history-region'
    },

    modelEvents: {
      'change': 'reload'
    },

    onShow: function() {        
      
      this.historyView = new HistoryView({
        model: this.model
      });      
      this.getRegion('historyRegion').show(this.historyView);
    },

    reload: function() {
      this.historyView.reload();
    }
  });
});
