define('webshopProductsDetailBodyCategoriesHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'windows',
  'buttonView',
  'tableHeaderFieldView',
  'webshopProductsDetailBodyCategoriesAddView',
  'template!webshopProductsDetailBodyCategoriesHeaderToolsView'
], function (
  App,
  Module,  
  _,
  Windows,
  ButtonView,
  FieldView,
  AddView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-categories-header-tools-view ' + FieldView.prototype.className,

    regions: {
      addRegion: '.add-region'
    },

    onRender: function() {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('common.add'),
        title: _.i18n('common.add')
      });

      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));
    },

    onAdd: function() {

      Windows.open({
        view: _.bind(function () {
          return new AddView({
            onConfirm: _.bind(function (code) {
              App.request('webshop.categories.datalist').done(_.bind(function (categories) {
                _.each(categories, _.bind(function (category) {
                  if(category.getCode() === code)
                    this.tableView.model.addCategory(category.getId()).done(_.bind(function () {
                      this.tableView.reload();
                      this.tableView.footerView.fetch();
                    }, this));
                }, this));
              }, this));

            }, this)
          });
        }, this),
        id: 'wehshop.product.categories.add',
        title: _.i18n('wehshop.product.categories.add'),
        modal: true,
        width: 600
      });
    }
  });
});
