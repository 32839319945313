/**
 * Created by BFR on 21/04/2022.
 */
define('googleImageCollectionView',['module',
    'marionette',
    'app',
    'underscore',
    'googleImageItemView',
    'template!googleImageItemsTpl'
  ],
  function (module,
            Marionette,
            App,
            _,
            SimilarItemView,
            viewTpl) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
      RESULT_TO_SHOW: 3,
      template: viewTpl,
      childView: SimilarItemView,
      childViewContainer: '.google-image-items',
      className: 'col-xs-12 p-l-0 p-r-0',

      showMore: false,

      ui: {
        showMoreContainer: '.show-more-container',
        btnShowMore: '.btn-show-more',
        btnShowLess: '.btn-show-less'
      },

      events: {
        'click @ui.btnShowMore': 'onClickShowMore',
        'click @ui.btnShowLess': 'onClickShowLess'
      },

      onClickShowMore: function () {
        this.showMore = true;
        this.render();
      },

      onClickShowLess: function () {
        this.showMore = false;
        this.render();
      },

      filter: function (child, index) {
        if (!this.showMore && index >= this.RESULT_TO_SHOW) {
          return false;
        }
        return true;
      },

      serializeData: function () {
        return {
          showMore: this.showMore
        };
      },

      onAddChild: function () {
        if (this.collection.models.length > this.RESULT_TO_SHOW) {
          this.ui.showMoreContainer.show();
        }
      }
    });
  });
