define('entities/product_share',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'backboneRelational'
], function (
  App,
  $,
  Settings,
  Backbone
) {
  'use strict';
  var app = {ns: {}};
  Backbone.Relational.store.addModelScope(app.ns);

  app.ns.ProductJsonShare = Backbone.RelationalModel.extend({
    idAttribute: 'secId',
    relations: [{
      type: Backbone.HasOne,
      key: 'product',
      relatedModel: 'ProductJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentProduct',
      relatedModel: 'ProductJson'
    }, {
      type: Backbone.HasMany,
      key: 'printAreas',
      relatedModel: 'PrintAreaJsonShare',
      collectionType: 'PrintAreaJsonShareCollection',
      reverseRelation: {
        key: 'refProduct',
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: 'optionCategories',
      relatedModel: 'OptionCategoryJsonShare',
      collectionType: 'OptionCategoryJsonShareCollection'
    }, {
      type: Backbone.HasMany,
      key: 'productCodeVariations',
      relatedModel: 'ProductCodeVariationJsonShare',
      collectionType: 'ProductCodeVariationJsonShareCollection'
    }],

    url: function () {
      return Settings.url('compuzz2', 'products.json/product/' + this.get('secId') + '/share');
    }
  });

  app.ns.ProductJson = Backbone.RelationalModel.extend({
    idAttribute: 'secId'
  });

  app.ns.PrintAreaJson = Backbone.RelationalModel.extend({
    idAttribute: 'id'
  });

  app.ns.ProductCodeVariationJson = Backbone.RelationalModel.extend({
    idAttribute: 'id'
  });

  app.ns.PrintZoneJson = Backbone.RelationalModel.extend({
    defaults: {
      width: '',
      height: ''
    },
    idAttribute: 'id',
    save: function (productSecId) {
      this.url = Settings.url('compuzz2', 'products.json/product/' +
        productSecId + '/printareas/' + this.get('paSecId').split('_')[0] + '/printzones');
      return Backbone.Model.prototype.save.call(this);
    },
    destroy: function (params) {
      this.url = Settings.url('compuzz2', 'products.json/product/' +
        undefined + '/printareas/' + (this.get('paSecId') ? this.get('paSecId').split('_')[0] : undefined) +
        '/printzones/' + this.get('id'));
      return Backbone.Model.prototype.destroy.call(this, params);
    }
  });

  app.ns.ProductCodeVariationJsonShare = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'productCodeVariation',
      relatedModel: 'ProductCodeVariationJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentProductCodeVariation',
      relatedModel: 'ProductCodeVariationJson'
    }]
  });

  app.ns.PrintAreaJsonShare = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'printArea',
      relatedModel: 'PrintAreaJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentPrintArea',
      relatedModel: 'PrintAreaJson'
    }, {
      type: Backbone.HasMany,
      key: 'printZones',
      relatedModel: 'PrintZoneJsonShare',
      collectionType: 'PrintZoneJsonShareCollection'
    }]
  });

  app.ns.PrintAreaJsonShareCollection = Backbone.Collection.extend({
    model: 'PrintAreaJsonShare'
  });

  app.ns.ProductCodeVariationJsonShareCollection = Backbone.Collection.extend({
    model: 'ProductCodeVariationJsonShare'
  });

  app.ns.PrintZoneJsonShare = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'printZone',
      relatedModel: 'PrintZoneJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentPrintZone',
      relatedModel: 'PrintZoneJson'
    }],
    destroy: function (params) {
      this.get('printZone').destroy();
      return Backbone.Model.prototype.destroy.call(this, params);
    }
  });

  app.ns.PrintZoneJsonShareCollection = Backbone.Collection.extend({
    model: 'PrintZoneJsonShare'
  });

  app.ns.OptionCategoryJson = Backbone.RelationalModel.extend({
    idAttribute: 'id'
  });

  app.ns.OptionJson = Backbone.RelationalModel.extend({
    idAttribute: 'id'
  });

  app.ns.OptionCategoryJsonShare = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'optionCategory',
      relatedModel: 'OptionCategoryJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentOptionCategory',
      relatedModel: 'OptionCategoryJson'
    }, {
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'OptionJsonShare',
      collectionType: 'OptionJsonShareCollection'
    }]
  });

  app.ns.OptionCategoryJsonShareCollection = Backbone.Collection.extend({
    model: 'OptionCategoryJsonShare'
  });

  app.ns.OptionJsonShare = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'option',
      relatedModel: 'OptionJson'
    }, {
      type: Backbone.HasOne,
      key: 'parentOption',
      relatedModel: 'OptionJson'
    }]
  });

  app.ns.OptionJsonShareCollection = Backbone.Collection.extend({
    model: 'OptionJsonShare'
  });

  App.reqres.setHandler('products-share:model', function (productJsonShare) {
    var tt = Backbone.Relational.store.find(app.ns.ProductJsonShare, productJsonShare.secId);
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductJsonShare(productJsonShare);
    }
  });

  App.reqres.setHandler('products-share:model-printzone', function (printZoneJson) {
    return new app.ns.PrintZoneJson(printZoneJson);
  });

  App.reqres.setHandler('products-share:model-printzone-share', function (printZoneJsonShare) {
    return new app.ns.PrintZoneJsonShare(printZoneJsonShare);
  });

});
