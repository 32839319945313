/**
 * Created by Matteo on 11/07/2015.
 */
define('caccountListController',[
  'module',
  'underscore',
  'app',
  'caccountListView',
  'settings',
  'entities/caccounts'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';
  
  var CaccountListController = {
    showCaccountList: function(data) {
      var view = new View({data: data});

      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    onGridLoaded: function() {
      var data = {
          url: App.request('caccount:get-collection-url'),
          rowCountUrl: App.request('caccount:row-count-url')
        };

      this.view.displayGrid(data);
    }
  };

  module.exports = CaccountListController;
});
