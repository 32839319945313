/**
 * Created by BFR on 24/02/2020.
 */
define('editPimPrintAreaView',[
  'module',
  'settings',
  'marionette',
  'app',
  'underscore',
  'template!editPimPrintAreaTpl',
  'dynamicTextView',
  'editPimPrintZonesView',
  'pimUtil'
], function (
  module,
  Settings,
  Marionette,
  App,
  _,
  viewTpl,
  DynamicTextView,
  EditPimPrintZonesView,
  PimUtil
) {
  'use strict';

  var EditPimPrintAreaView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'pa-item',

    regions: {
      editPrintZones: '.pz-region',
      nameRegion: '.pa-name-region'
    },

    events: {
      'mouseover': 'onMouseOver'
    },

    onShow: function () {
      if (this.model.get('name').get('glossaryDt')) {
        this.nameDtView = new DynamicTextView({
          model: this.model.get('name').get('glossaryDt'),
          hideGlossary: true,
          showWebIcon: true
        });
        this.getRegion('nameRegion').show(this.nameDtView);
      }

      this.editPimPrintZonesView = new EditPimPrintZonesView({
        pimProduct: this.options.pimProduct,
        collection: this.model.get('printZones')
      });
      this.getRegion('editPrintZones').show(this.editPimPrintZonesView);
    },

    onMouseOver: function () {

    },

    serializeData: function () {
      var templateData = {};

      templateData.width = this.model.get('width');
      templateData.height = this.model.get('height');
      templateData.code = this.model.get('code');
      templateData.showFastDelivery = Settings.configValue('showFastDelivery');

      var aNames = this.model.get('name').get('values');
      templateData.name = PimUtil.getTranslation(aNames);

      return templateData;
    }
  });

  module.exports = EditPimPrintAreaView;
});
