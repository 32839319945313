/**
 * Created by RKL on 26/08/2015.
 */
define('emptyView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'template!emptyViewTpl'
], function (
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var emptyView =  Marionette.ItemView.extend({
    template: viewTpl
  });

  module.exports = emptyView;
});
