
define('template!graphicalRequestAllInOneViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="well vectorization-container jq-grid-table-page">\n    <h2>' +
((__t = ( _.i18n('header.graphical.requests.all.in.one.title') )) == null ? '' : __t) +
'</h2>\n    <div class="vectorization-table">\n      <div class="loading-block js-loading-block z-i-10">\n        <span class="loading-message js-loading-message label"></span>\n      </div>\n      ';
 if (!vectorizator) { ;
__p += '\n        <div class="form-group add-new-block col-xs-2 z-i-10 p-l-0">\n          <a href="#vectorization/new" class="btn  new-vector-button add-btn-text">\n            <i class="mdi-content-add"></i>\n            <span>' +
((__t = ( _.i18n('vectorization.new') )) == null ? '' : __t) +
'</span>\n          </a>\n        </div>\n\n        <div class="form-group credit-info-block col-xs-2 z-i-10 clickable">        \n          <div class="credits-balance panel panel-default col-xs-12">\n              <div class="col-xs-4 number-of-balence">\n                  <span class="credits-balance-value">' +
((__t = ( balance )) == null ? '' : __t) +
'</span>\n              </div>\n              <div class="col-xs-8 balance-label">\n                  <span class="credit-balance-label">' +
((__t = ( _.i18n('credits.balance') )) == null ? '' : __t) +
'</span>\n              </div>\n          </div>\n        </div>\n        <div class="form-group report-block col-xs-2 z-i-10 p-l-0 pull-right">\n            <a class="btn report-button primary-btn-text">\n                <i class="mdi-file-file-download"></i>\n                <span>' +
((__t = ( _.i18n('vectorization.report') )) == null ? '' : __t) +
'</span>\n            </a>\n        </div>\n      ';
 } ;
__p += '\n\n      <table id="jq-grid-vector" class="vectorTable" role="grid"></table>\n      <div id="jq-grid-pager-vector"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

