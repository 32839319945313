/**
 * Created by BFR on 14/04/2022.
 */
define('configPrintAreaItemView',['module',
    'backbone',
    'underscore',
    'marionette',
    'imageUploadBehavior',
    'template!configPrintAreaItemTpl'],
  function (module,
            Backbone,
            _,
            Marionette,
            ImageUploadBehavior,
            tpl) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
      template: tpl,
      className: 'form-group printzone-item-selection',
      style: 'height: 40px',

      ui: {
        uploadImageBlock: '.upload-image-block',
        imageContainer: '.image-ctn',
        imageSelected: '.image-selected',
        deleteButton: '.delete-button'
      },

      events: {
        'click .dropdown-menu-printzone a': 'onChangePrintZone',
        'click @ui.deleteButton': 'onDelete'
      },

      behaviors: {
        ImageUpload: {
          behaviorClass: ImageUploadBehavior
        }
      },

      notShowRemoveBackgroundPopup: true,
      loadImageInMemory: true,

      onImageUploadConfirm: function (base64Value, fileExt, property, fileName) {
        this.ui.imageSelected.attr('src', 'data:image/png;base64,' + base64Value);
        this.ui.uploadImageBlock.hide();
        this.ui.imageContainer.show();
        this.trigger('selected:printzone:image:change', this.model.get('id'), this.currentPzId, {
          content: base64Value,
          extension: fileExt,
          name: fileName
        });
      },

      onChangePrintZone: function (e) {
        e.preventDefault();
        var target = this.$el.find(e.target),
          nbrColor = target.data('nb-color');
        this.currentPzId = target.data('pzId');
        this.$el.find('.printzone-label').text(target.text());
        this.trigger('selected:printzone:change', this.model.get('id'), this.currentPzId, nbrColor);
        if (this.currentPzId !== '-1') {
          this.ui.uploadImageBlock.show();
        } else {
          this.ui.uploadImageBlock.hide();
          this.trigger('selected:printzone:image:change', this.model.get('id'), this.currentPzId, null);
        }
      },

      onDelete: function () {
        this.trigger('selected:printzone:image:change', this.model.get('id'), -1, null);
        this.ui.imageSelected.attr('src', '');
        this.ui.uploadImageBlock.show();
        this.ui.imageContainer.hide();
      }
    });
  });
