
define('template!pimButtonsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="buttons">            \n    <a class="btn cancel-button action-btn-text ' +
((__t = ( disabled )) == null ? '' : __t) +
'">\n        <i class="mdi-navigation-cancel"></i>\n        <span>Cancel</span>\n    </a>\n    <a class="btn save-button action-btn-text ' +
((__t = ( disabled )) == null ? '' : __t) +
'">\n        <i class="mdi-content-save"></i>\n        <span>Save</span>\n    </a>\n</div>';

}
return __p
};});

