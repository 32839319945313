define('ordersListLabelsView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'ordersLabelsView',
  'template!ordersListLabelsView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  LabelsView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-labels-view ' + FieldView.prototype.className,

    regions: {
      labelsRegion: '.labels-region'
    },

    onRender: function() {
      this.labelsView = new LabelsView({
        model: this.model
      });
      this.getRegion('labelsRegion').show(this.labelsView);
    }
  });
});
