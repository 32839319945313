
define('template!translationsTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="jumbotron overflow-x-auto">\n  <div class="form-group">\n    <div class="col-left">\n      <a class="btn add-btn-text js-add-translation">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n('translations.new') )) == null ? '' : __t) +
'</span>\n      </a>\n    </div>\n    <div class="col-left p-l-15 p-t-15">\n      <label class="clickable upload-block" for="translations-upload">\n        <div class="input-group">\n          <i class="mdi-file-file-upload"></i>\n          <input type="file" id="translations-upload" class="hidden"\n                 accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">\n          <span class="action-label">' +
((__t = ( _.i18n('common.upload') )) == null ? '' : __t) +
'</span>\n        </div>\n      </label>\n    </div>\n    <div class="vertical-divider col-left"></div>\n    <div class="col-left p-t-15">\n      <div class="input-group">\n        <a class="download" href="' +
((__t = ( excelDownloadUrl )) == null ? '' : __t) +
'">\n          <i class="mdi-file-file-download"></i>\n          <span class="action-label">' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n        </a>\n      </div>\n    </div>\n  </div>\n  <table class="table table-hover ">\n    <thead>\n        <tr>\n          <th>' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</th>\n          ';
 languages.each(function(language){ ;
__p += '\n            ';
 if(language.get('checked')) { ;
__p += '\n              <th>' +
((__t = ( language.get('abbreviation') )) == null ? '' : __t) +
'</th>\n            ';
 } ;
__p += '\n          ';
 }) ;
__p += '\n        </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    <tfoot>\n      <tr>\n        <td colspan="100%">\n          <div class="row md-grey-cl ui-jqgrid">\n            <div class="col-xs-5"></div>\n            <div class="col-xs-2 pagination-control">\n              <span class="ui-icon ui-icon-seek-first js-pagination-first" title="' +
((__t = ( _.i18n('jqgrid.pgfirst') )) == null ? '' : __t) +
'"></span>\n              <span class="ui-icon ui-icon-seek-prev js-pagination-prev"  title="' +
((__t = ( _.i18n('jqgrid.pgprev') )) == null ? '' : __t) +
'"></span>\n\n              ' +
((__t = ( _.i18n('common.page').charAt(0).toUpperCase() + _.i18n('common.page').slice(1) )) == null ? '' : __t) +
' <span class="js-current-page dark-grey-cl">' +
((__t = ( pagination.currentPage )) == null ? '' : __t) +
'</span> ' +
((__t = ( _.i18n('jqgrid.of') )) == null ? '' : __t) +
' ' +
((__t = ( pagination.totalPages )) == null ? '' : __t) +
'\n\n              <span class="ui-icon ui-icon-seek-next js-pagination-next" title="' +
((__t = ( _.i18n('jqgrid.pgnext') )) == null ? '' : __t) +
'"></span>\n              <span class="ui-icon ui-icon-seek-end js-pagination-last" title="' +
((__t = ( _.i18n('jqgrid.pglast') )) == null ? '' : __t) +
'"></span>\n            </div>\n            <div class="f-r p-r-15 pagination-view">' +
((__t = ( _.i18n('jqgrid.view') )) == null ? '' : __t) +
' <span class="js-page-index">' +
((__t = ( pagination.first + 1 )) == null ? '' : __t) +
'</span> - <span class="js-page-last-index">' +
((__t = ( pagination.first + pagination.pageSize )) == null ? '' : __t) +
'</span> ' +
((__t = ( _.i18n('jqgrid.of') )) == null ? '' : __t) +
' ' +
((__t = ( pagination.totalCount )) == null ? '' : __t) +
'</div>\n          </div>\n        </td>\n      </tr>\n    </tfoot>\n    <div class="js-table-loading table-loading">' +
((__t = ( _.i18n('jqgrid.loadtext') )) == null ? '' : __t) +
'</div>\n  </table>\n</div>';

}
return __p
};});

