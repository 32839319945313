/**
 * Created by BFR on 09/03/2020.
 */
define('filterTypesView',[
    'module',
    'marionette',
    'underscore',
    'filterTypeView',
    'template!filterTypesTpl'
  ], function (
    module,
    Marionette,
    _,
    ItemView,
    templatesTpl
  ) {
    'use strict';
  
    var CollectionView = Marionette.CollectionView.extend({
      template: templatesTpl,
      childViewContainer: '.product-filter-types',
      childView: ItemView,

      childEvents: {
        'item:select': 'onItemSelect',
        'item:delete': 'onItemDelete'
      },

      onItemSelect: function(item) {
        _.each(this.collection.models, function(model) {
            model.set('selected', false);
        });
        item.model.set('selected', true);
        this.render();
        this.trigger('item:select', item.model);
      },

      onItemDelete: function(item) {
        this.trigger('item:delete', item.model);
      }
    });
  
    module.exports = CollectionView;
  });
