/**
 * Created by BFR on 02/03/2020.
 */
define('shareProductCodeVariationsView',['module',
        'marionette',
        'shareProductCodeVariationView',
        'template!shareProductCodeVariationsTpl'],
  function(module,
           Marionette,
           ShareProductCodeVariationView,
           viewTpl){
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.productcodevariation-items',
    childView: ShareProductCodeVariationView,
    className: 'col-md-12 p-t-10',

    childEvents: {
      'item:selection:change': function () {
        this.trigger('item:selection:change');
      }
    }
  });
});
