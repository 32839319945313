/**
 * Created by BFR on 25/05/2018.
 */
define('productPriceTabController',[
  'module',
  'underscore',
  'app',
  'productPriceTabView',
  'jquery',
  'settings',
  'entities/product'
], function (
  module,
  _,
  App,
  View,
  $
) {

  'use strict';
  var ProductPriceController = {
    showDetails: function (region, pm) {
      this.model = pm;
      this.view = new View({model: this.model});
      this.view.listenTo(this.view, 'get-price-for-configuration', _.bind(this.onGetPriceForConfiguration, this));
      region.show(this.view);
    },

    onGetPriceForConfiguration: function (configuration) {
      var config = {
        quantity: configuration.quantity,
        options: [],
        printZonesColors: {}
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      var requestRs = App.request('products:get-price-for-configuration', this.model.get('secId'), config);
      var request = App.request('products:get-price-for-configuration', this.model.get('secId'), config, true);
      $.when(requestRs, request).done(_.bind(function (rsPrice, price) {
        this.view.refreshRsPrice(rsPrice);
        this.view.refreshPrice(price);
        var productPricePlusShipping = Number(price.productPrice) + Number(price.shippingCost);
        var rsProductPricePlusShipping = Number(rsPrice.productPrice) + Number(rsPrice.shippingCost);
        var marg = {
          totalNotPrintMarg: (Number(rsPrice.total) - productPricePlusShipping).toFixed(2),
          unitNotPrintMarg: ((Number(rsPrice.total) - productPricePlusShipping) / Number(price.quantity)).toFixed(2),
          totalTotalProductMarg: (rsProductPricePlusShipping - productPricePlusShipping).toFixed(2),
          unitTotalProductMarg: ((rsProductPricePlusShipping - productPricePlusShipping) / Number(price.quantity)).toFixed(2),
          totalProductMarg: (Number(rsPrice.productPrice) - Number(price.productPrice)).toFixed(2),
          unitProductMarg: ((Number(rsPrice.productPrice) - Number(price.productPrice)) / Number(price.quantity)).toFixed(2),
          totalShippingMarg: Number(rsPrice.shippingCost - price.shippingCost).toFixed(2),
          unitShippingMarg: (Number(rsPrice.shippingCost - price.shippingCost) / Number(price.quantity)).toFixed(2),
          handlingMarg: (Number(rsPrice.totalHandlingCost) - Number(price.totalHandlingCost)).toFixed(2),
          totalUnitMarg: (Number(rsPrice.totalUnitCost) - Number(price.totalUnitCost)).toFixed(2),
          unitUnitMarg: (Number(rsPrice.totalUnitCost) - Number(price.totalUnitCost) / Number(price.quantity)).toFixed(2),
          totalSetupMarg: (Number(rsPrice.totalSetupCost) - Number(price.totalSetupCost)).toFixed(2),
          unitSetupMarg: ((Number(rsPrice.totalSetupCost) - Number(price.totalSetupCost)) / Number(price.quantity)).toFixed(2),
          totalMarg: Number(rsPrice.total - price.total).toFixed(2),
          unitMarg: Number(rsPrice.totalUnit - price.totalUnit).toFixed(2)
        };
        this.view.refreshMargPrice(marg);
        this.view.refreshScales(rsPrice, price);
      }, this));
    }
  };

  module.exports = ProductPriceController;
});
