define('ordersDetailInvoicesCreditNoteView',[
  'app',
  'module',
  'underscore',
  'buttonView',
  'modalsConfirmationView',
  'tableFieldView',
  'template!ordersDetailInvoicesCreditNoteView'
], function (
  App,
  Module,  
  _,
  ButtonView,
  ConfirmationView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-credit-note-view ' + FieldView.prototype.className,

    regions: {
        buttonRegion: '.button-region'
    },

    onRender: function() {
      if(this.model.get('refCreditNote'))
        return;

      if(this.model.get('invoiceType') === 'creditNote')
        return;

      this.buttonView = new ButtonView({
        type: 'add',
        text: _.i18n('common.creditNote'),
        title: _.i18n('common.creditNote')
      });
      this.getRegion('buttonRegion').show(this.buttonView);
      this.buttonView.on('click', _.bind(this.onCredit, this));
    },

    onCredit: function() {
      if(this.model.get('invoiceType') === 'creditNote')
        return;

      if(this.model.get('invoiceType') === 'creditNote')
        return;

      ConfirmationView.prototype.open({
        text: _.i18n('orders.invoices.create-credit-note.confirm'),
        onConfirm: _.bind(function () {
          App.request('order-invoices:credit-note', this.model.get('secId')).then(_.bind(function () {
            this.tableView.reload();
            this.tableView.footerView.fetch();
          }, this));
        }, this)
      });
    }
  });
});
