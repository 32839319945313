
define('template!invoiceSampleTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well  col-md-8 ">\n    <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('account.profile.invoiceExample') )) == null ? '' : __t) +
'</span>\n<small><span class="col-xs-12 card-title h6">' +
((__t = ( _.i18n('account.profile.invoiceDownloadLabel') )) == null ? '' : __t) +
'</span></small>\n<small><span class="col-xs-12 card-title h6">' +
((__t = ( _.i18n('account.profile.invoiceDownloadLabel2') )) == null ? '' : __t) +
'</span></small>\n';
 if(sampleAllowed){;
__p += '\n\n<object  class="col-md-12 invoice-viewer" data="' +
((__t = ( sampleUrl )) == null ? '' : __t) +
'" type="application/pdf">\n    <embed  class="col-md-12 invoice-viewer" src="' +
((__t = ( sampleUrl )) == null ? '' : __t) +
'" type="application/pdf" >\n        <a href="' +
((__t = ( sampleUrl )) == null ? '' : __t) +
'" download>' +
((__t = ( _.i18n('account.profile.invoiceErrorDownload') )) == null ? '' : __t) +
'</a>\n    </embed>\n</object>\n';
 } else{;
__p += '\n    <span class="invoice-unavailable">\n        ' +
((__t = ( _.i18n('account.profile.invoiceUnavailable') )) == null ? '' : __t) +
'\n    </span>\n';
 };
__p += '\n</div>';

}
return __p
};});

