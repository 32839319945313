define('webshopProductsDetailBodyImagesView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopProductsDetailBodyImagesTypeView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  TypeView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'webshop-products-detail-body-images-view',
    childView: TypeView,

    childViewOptions: function () {
      return {
        category: this.model
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.fetch();
    },

    fetch: function () {
      App.request('webshop.products.images.types').done(_.bind(function (types) {
        this.collection.reset(types);
      }, this));
    }
  });
});
