
define('template!editOptionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-2 code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-4 name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-2 color">\n    ';
 if(color1 !== null) { ;
__p += '\n    <div class="color-round" style="background-color:' +
((__t = ( color1 )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n    ';
 if(color2 !== null) { ;
__p += '\n    <div class="color-round" style="background-color:' +
((__t = ( color2 )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n    ';
 if(color3 !== null) { ;
__p += '\n    <div class="color-round" style="background-color:' +
((__t = ( color3 )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n    ';
 if(color4 !== null) { ;
__p += '\n    <div class="color-round" style="background-color:' +
((__t = ( color4 )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n  </div>\n  <div class="col-xs-2 images">\n  </div>\n  ';
 if (!readOnly) { ;
__p += '\n  <div class="col-xs-2 btn-pz-container" style="display: none; height: 12px;">\n    <i class="mdi mdi-editor-mode-edit btn-edit-po clickable btn-frame pull-right"></i>\n    <i class="mdi mdi-action-delete btn-delete-po clickable btn-frame pull-right"></i>\n  </div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

