define('ordersDetailOrderProductCustomDescriptionView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'template!ordersDetailOrderProductCustomDescriptionView'
], function (
  Module,
  App,
  Marionette,
  _,    
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-product-custom-description-view item editable',

    ui: {
      value: '.value',
      editButton: '.btn-edit-custom-description',
      input: '.edit > textarea'
    },
     
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.value': 'onEdit',
      'blur  @ui.input': 'onBlurClick',
      'click @ui.input': 'onInputClick'
    },

    modelEvents: {      
      'change:customDescription': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      } else if (event.key === 'Enter') {
          if (event.ctrlKey) {
            this.newRow(event);
          }else {
            this.update();
          }
      }
    },
    newRow: function(event) {
        event.preventDefault();

        var textarea = event.target;
        var start = textarea.selectionStart;
        var end = textarea.selectionEnd;
        var value = textarea.value;

        textarea.value = value.substring(0, start) + '\n' + value.substring(end);
        textarea.selectionStart = textarea.selectionEnd = start + 1;
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },   

    onRender: function () {    
      this.renderContent();
    },

    renderContent: function () {       
      this.$el.attr('edit', this.edit);

      //-------------------
      var d = this.model.get('customDescription');
      if (!d)
        this.ui.value.html('-');
      else
        this.ui.value.html(d?d.replace(/\n/g, '<br>'):d);
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = !this.edit;     
      this.renderContent();     
      
      if(this.edit) {
        this.ui.input.val(this.model.get('customDescription'));
        this.ui.input.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () { 

      if(!this.edit)
        return;
      
      this.edit = false;
      this.renderContent();

      if(this.ui.input.val() !== this.model.get('customDescription')) {
        App.request('order:update', {
          purchaseOrderId: this.model.get('purchaseOrderId'),
          refCommand: {
            updateProperties: true,
            customDescription: this.ui.input.val().trim()
          }
        }).done(_.bind(function () {
          this.model.fetch();
        }, this));
      }         
    }
  });
}

);

