define('webshopProductsFinderListFiltersQuickAccessView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'filtersBannerQuickAccessView',
  'template!webshopProductsFinderListFiltersQuickAccessView',
  'entities/caccounts/share'
], function (
  App,
  Module,
  Marionette,
  _,
  QuickAccessView,
  Tpl
) {
  'use strict';

  Module.exports = QuickAccessView.extend({
    template: Tpl,
    className: 'webshop-products-finder-list-quick-access-view ' + QuickAccessView.prototype.className,

    ui: _.extend(QuickAccessView.prototype.ui, {
      code: '.code',
      name: '.name',
      supplierName: '.supplier-name',
      supplierCode: '.supplier-code'
    }),

    events: _.extend(QuickAccessView.prototype.events, {
      'change @ui.code': 'onCodeChange',
      'change @ui.supplierCode': 'onSupplierCodeChange',
      'change @ui.supplierName': 'onSupplierNameChange'
    }),

    modelEvents: {
      'change:code': 'onRender',
      'change:supplierName': 'onRender',
      'change:supplierCode': 'onRender'
    },

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      QuickAccessView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {
      App.request('caccounts:get-share-from-sorted').done(_.bind(function (list) {

        var html = '<datalist id="supplier-names">';

        _.each(list, _.bind(function (item) {
          if (item.caccountFrom.code !== '') {
            html += '<option value="' + item.caccountFrom.code + '">' + item.caccountFrom.code + '</option>';
          }
        }, this));

        html += '</datalist>';

        if ($('body').find('#supplier-names').length === 0)
          $('body').append(html);
      }, this));
    },

    onRender: function() {
      this.ui.code.val(this.model.get('code'));
      this.ui.supplierCode.val(this.model.get('supplierCode'));
      this.ui.supplierName.val(this.model.get('supplierName'));
    },

    onCodeChange: function() {
      this.model.set('code', this.ui.code.val().trim());
      this.trigger('confirm', this.model);
    },

    onSupplierCodeChange: function() {
      this.model.set('supplierCode', this.ui.supplierCode.val().trim());
      this.trigger('confirm', this.model);
    },

    onSupplierNameChange: function() {
      this.model.set('supplierName', this.ui.supplierName.val().trim());
      this.trigger('confirm', this.model);
    }
  });
});
  
