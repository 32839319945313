define('backToListBehavior',[
  'module',
  'marionette',
  'app'
], function(
  module,
  Marionette,
  App
) {
  'use strict';

  /**
   * Redirects after button click
   * Requires in html tag with class "js-back-to-list"
   *  Example(<span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i><%= _.i18n("common.back.to.list") %></span>)
   * Requires redirectUrl passed as an option
   */
  var BackToListBehavior = Marionette.Behavior.extend({
    ui: {
      'backToList': '.js-back-to-list'
    },

    events: {
      'click @ui.backToList': '_onBackToList'
    },

    initialize: function() {
      if(!this.options.redirectUrl) {
        throw 'Redirect url is not specified';
      }
    },

    _onBackToList: function() {
      App.navigate(this.options.redirectUrl, {trigger: true});
    }
  });

  module.exports = BackToListBehavior;
});
