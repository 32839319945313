
define('template!filterTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-10">\n        <div class="col-xs-6">\n            <div class="col-xs-6">' +
((__t = ( productFilterDisplayName )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="filter-values col-xs-6">\n        </div>\n    </div>\n    <hr class="col-xs-12 m-t-0"/>\n</div>';

}
return __p
};});

