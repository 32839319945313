define('slugUtils',[
  'module'
], function (
    module
) {
  'use strict';

  var SlugUtils = {
    sanitize: function (value) {
      var utf8 = {
        'á': 'a',
        'à': 'a',
        'â': 'a',
        'ã': 'a',
        'ä': 'a',
        'Á': 'A',
        'À': 'A',
        'Â': 'A',
        'Ã': 'A',
        'Ä': 'A',
        'Í': 'I',
        'Ì': 'I',
        'Î': 'I',
        'Ï': 'I',
        'í': 'i',
        'ì': 'i',
        'î': 'i',
        'ï': 'i',
        'é': 'e',
        'è': 'e',
        'ê': 'e',
        'ë': 'e',
        'É': 'E',
        'È': 'E',
        'Ê': 'E',
        'Ë': 'E',
        'ó': 'o',
        'ò': 'o',
        'ô': 'o',
        'õ': 'o',
        'ö': 'o',
        'Ó': 'O',
        'Ò': 'O',
        'Ô': 'O',
        'Õ': 'O',
        'Ö': 'O',
        'ú': 'u',
        'ù': 'u',
        'û': 'u',
        'ü': 'u',
        'Ú': 'U',
        'Ù': 'U',
        'Û': 'U',
        'Ü': 'U',
        'ç': 'c',
        'Ç': 'C',
        'ñ': 'n',
        'Ñ': 'N',
        '–': '-',
        '’': ' ',
        '‘': ' ',
        '‹': ' ',
        '›': ' ',
        '‚': ' ',
        '“': ' ',
        '”': ' ',
        '«': ' ',
        '»': ' ',
        '„': ' ',
        ' ': ' ',
        'æ': 'ae',
        'Æ': 'AE',
        'ø': 'oe',
        'Ø': 'OE',
        'å': 'aa',
        'Å': 'AA'
      };

      value = value.replace(/[áàâãäÁÀÂÃÄÍÌÎÏíìîïéèêëÉÈÊËóòôõöÓÒÔÕÖúùûüÚÙÛÜçÇñÑ–’‘‹›‚“”«»„æÆøØåÅ ]/g, function (m) {
        return utf8[m];
      });

      value = value.trim();
      value = value.toLowerCase();
      value = value.replace(/[^a-z0-9\u0400-\u04FF]/g, ' ');
      value = value.trim();
      value = value.replace(/\s+/g, '-');
      return value;
    }
  };

  module.exports = SlugUtils;
});

