define('entities/customers/customers',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function (
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var AttachmentModel = Backbone.Model.extend({
    defaults: {
      id: 0,
      downloadLink: null,
      fileLink: null,
      fileName: null
    }
  });

  /**
   * Model
   */
  var Customer = Backbone.Model.extend({
    idAttribute: 'secId',
    defaults: function() {
      return {      
        aProfile: null,
        cAccountId: null,
        city: null,
        code: null,
        comment: null,
        companyName: null,
        companyNumber: null,
        countryCode: null,
        email: null,
        invoiceEmail: null,
        emailCustomerService: null,
        encodedValue: null,
        endUserId: null,
        extension: null,
        fax: null,
        firstName: null,
        houseNumber: null,
        lastName: null,
        locales: null,
        login: null,
        logoUid: null,
        name: null,
        password: null,
        refAgent: null,
        secId: null,
        street: null,
        tel: null,
        mobile: null,
        userType: null,
        vatNumber: null,
        webSite: null,
        workName: null,
        zip: null,
        addressL1: null,
        addressL2: null,
        other: null,
        subjectToVat: false,
        priceListCode: null,
        subscribed: true,
        refDeliveryAddress: {},
        mainActivity: null,
        subActivity: null,
        nbrEmployees: null,
        siret: null,
        firstContactPhone: null,
        firstContactMail: null,
        firstContactVoiceMail: null,
        webShopLocale: null,
        paymentTerms: null,
        vip: false
      };
    },

    initialize: function () {
    },

    setAsCollection: function (isCollection, isCompuLogo) {
      var that = this,
        params = {
          value: isCollection ? 'true' : 'false',
          isCompuLogo: isCompuLogo ? 'true' : 'false'
        },
        url = Settings.url('compuzz', 'endusers.json/' + this.get('secId') + '/setascollection', params);

      $.ajax({
        url: url,
        method: 'PUT',
        success: function (resp) {
          that.set(resp);
          that.trigger('sync');
        },
        error: function (resp) {
          that.trigger('error', resp);
        }
      });
    },

    /*jshint -W061 */
    siretToVat: function (siret) {
      if(!siret)
        return;

      var sSiren = siret.substr(0, 9).replaceAll(' ', '');

      // Clef TVA = ( ( (Siren Modulo 97) * 3 ) + 12 ) Modulo 97
      var sClef = '';
      sClef = (((eval(sSiren) % 97) * 3) + 12) % 97;
      if (eval(sClef) < 10) {
        sClef = '0' + sClef;
      }

      return 'FR' + sClef + sSiren;
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'endusers.json');
    },

    deleteUrl: function () {
      return Settings.url('compuzz2', 'endusers.json/' + this.get('secId'));
    },

    save: function () {
      this.url = this.postUrl();
      this.set('code', this.get('endUserId'));
      return Backbone.Model.prototype.save.call(this);
    },

    getLanguage: function (language) {
      var locales = language || this.get('locales');
      if (locales) {
        if (locales.length > 2) {
          locales = locales.substr(0, 2);
        }
      } else {
        locales = 'fr';
      }
      return locales;
    },

    getCustomerNumber: function () {
      if (this.get('secId')) {
        return (2000000000 + Number(this.get('secId').split('_')[0]));
      }
      return 'N/A';
    },

    getAttachments: function (first, pageSize) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        url: Settings.url('compuzz2', 'attachments/', {
          endUserSecId: this.get('secId'),
          first: first,
          pageSize: pageSize
        }),
        contentType: 'application/json',
        success: function (items) {
          var attachments = [];
          _.each(items, _.bind(function (item) {
            attachments.push(new AttachmentModel(item));
          }, this));

          defer.resolve(attachments);
        }
      });

      return defer.promise();
    },

    uploadAttachment: function (file) {

      var formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);

      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'attachments/users/' + this.get('secId') + '/upload'),
        data: formData,
        processData: false,
        contentType: false,
        success: function (items) {
          if (items.length === 1)
            defer.resolve(new AttachmentModel(items[0]));
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    deleteAttachment: function (attachment) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz2', 'attachments/users/' + this.get('secId') + '/' + attachment.get('id')),
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    copyAttachmentToOrder: function (attachment, poi) {
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'attachments/users/' + this.get('secId') + '/copy/' + attachment.get('id') + '/order/' + poi),
        success: function (attachment) {
          defer.resolve(attachment);
        }
      });

      return defer.promise();
    },

    checkVat: function () {
      var defer = $.Deferred();
      if (!this.get('vatNumber')) {
        defer.resolve(false);
        return defer.promise();
      }

      if (!this.get('vatNumber').match('^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$'))
        defer.resolve(false);
      else {
        var params = {
          countryCode: this.get('vatNumber').substr(0, 2),
          VATNumber: this.get('vatNumber').substr(2, this.get('vatNumber').length)
        };

        var url = Settings.url('compuzz2', 'v2/vatservice/checkVatNumber', params);

        $.ajax({
          type: 'GET',
          url: url,
          success: _.bind(function (resp) {
            if (resp === 'false') {
              this.set('vatNumberValid', false);
              defer.resolve(false);
            }
            else {
              this.set('vatNumberValid', true);
              defer.resolve(true, resp);
            }

            this.set('vatNumberChecked', true);
            this.save();
          }, this),
          error: function () {
            defer.reject();
          }
        });
      }

      return defer.promise();
    },

    getInvoicesUrl: function (params) {
      return Settings.url('compuzz2', 'endusers.json/' + this.get('endUserId') + '/invoice', params);
    },

    getInvoicesRowCountUrl: function (params) {
      return Settings.url('compuzz2', 'endusers.json/' + this.get('endUserId') + '/invoice/rowCount', params);
    }
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function (lead) {
      if (lead) {
        return 'endusers.json/rowCount?userType=lead';
      } else {
        return 'endusers.json/rowCount';
      }
    },

    getCustomersUrl: function (lead) {
      var data = {};
      if (lead) {
        data.userType = 'lead';
      }
      return Settings.url('compuzz2', 'endusers.json/endusers', data);
    },

    getCustomerModel: function (id) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz2', 'endusers.json/' + id);

      return customer;
    },

    getCustomerModelByEndUserId: function (endUserId) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz2', 'endusers.json/code/' + endUserId);

      return customer;
    },

    updatePhoneNumber: function (endUserSecId, phoneNumber) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/phone/' + phoneNumber);

      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    isUserNotified: function (endUserSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/isUserNotified');

      $.ajax({
        type: 'GET',
        url: url,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    notifyMe: function (endUserSecId, notify) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/getNotified', {notified: notify ? 'true' : 'false'});

      $.ajax({
        type: 'PUT',
        url: url,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getTasksRowCountUrl: function (endUserId, params) {
      return Settings.url('compuzz2', 'endusers.json/'+ endUserId +'/tasks/rowCount', params);
    },

    getTasks: function (endUserId, params) {
      return Settings.url('compuzz2', 'endusers.json/' + endUserId + '/tasks', params);
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:row-count-url', function (lead) {
    return API.getRowCountUrl(lead);
  });

  App.reqres.setHandler('customers:get-url', function (lead) {
    return API.getCustomersUrl(lead);
  });

  App.reqres.setHandler('customers:entity', function (id) {
    return API.getCustomerModel(id);
  });

  App.reqres.setHandler('customers:entity:end-user-id', function (endUserId) {
    return API.getCustomerModelByEndUserId(endUserId);
  });

  App.reqres.setHandler('customer:model', function (modelJSON) {
    return new Customer(modelJSON);
  });

  App.reqres.setHandler('customer:update-phone', function (endUserSecId, phoneNumber) {
    return API.updatePhoneNumber(endUserSecId, phoneNumber);
  });

  App.reqres.setHandler('customer:is-user-notified', function (endUserSecId) {
    return API.isUserNotified(endUserSecId);
  });

  App.reqres.setHandler('customer:notify-me', function (endUserSecId, notify) {
    return API.notifyMe(endUserSecId, notify);
  });

  App.reqres.setHandler('customers:tasks.row-count', function (endUserId, params) {
    return API.getTasksRowCountUrl(endUserId, params);
  });

  App.reqres.setHandler('customers:tasks', function (endUserId, params) {
    return API.getTasks(endUserId, params);
  });
});

