define('ordersDetailInvoicesNewView',[
  'app',
  'module',
  'underscore',
  'modalsConfirmationView',
  'ordersController',
  'template!ordersDetailInvoicesNewView'
], function(
  App,
  Module,
  _,
  ModalsConfirmationView,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-detail-invoices-new-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend( {
      paymentDelay: '.paymentDelay input'
    }, ModalsConfirmationView.prototype.ui),

    serializeData: function () {
      var data = {};

      data.paymentTerms = null;
      if(this.options.paid)
        data.paymentTerms = -1;
      else
        if(this.options.customer.get('paymentTerms'))
          data.paymentTerms = this.options.customer.get('paymentTerms');

      return data;
    },

    onConfirm: function () {
       App.request('order:generate-invoice', this.options.pois, this.ui.paymentDelay.val()).done(_.bind(function (invoice) {
         this.options.onConfirm(invoice);
         this.windowView.close();
       }, this)).fail(_.bind(function () {
         this.options.onFail();
       }, this));
      this.confirmButtonview.enabled(false);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersDetailInvoicesNewView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          width: 300,
          height: 150,
          maxWidth: 800
        });
      });
    }
  });
});
