/**
 * Created by BFR on 21/04/2022.
 */
define('googleImageItemView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'template!googleImageItemTpl'
], function (
  module,
  Marionette,
  App,
  _,
  Settings,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,
    className: 'col well p-l-0 m-l-15 p-r-0 p-t-15 p-b-15 vectorization-item',

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.imageUrl = templateData.url;
      templateData.index = this.model.collection.models.indexOf(this.model) + 1;
      return templateData;
    }
  });
});
