define('rBticketStates',[
  'module',
  'underscore'
], function(module, _) {
  'use strict';

  module.exports = {
    OPEN: {
      label: _.i18n('vectorization.status.open'),
      state: 'OPEN'
    },
    PROGRESS: {
      label: _.i18n('vectorization.status.progress'),
      state: 'PROGRESS'
    },
    REVIEW: {
      label: _.i18n('vectorization.status.review'),
      state: 'REVIEW'
    },
    PENDING: {
      label: _.i18n('vectorization.status.pending'),
      state: 'PENDING'
    },
    DONE: {
      label: _.i18n('vectorization.status.done'),
      state: 'DONE'
  },
    CANCELED: {
      label: _.i18n('vectorization.status.canceled'),
      state: 'CANCELED'
    },
    CLOSE: {
      label: _.i18n('vectorization.status.close'),
      state: 'CLOSE'
    }
  };
});
