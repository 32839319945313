
define('template!timetrackerPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12">\n  ';
 if (_.size(screens) !== 0) { ;
__p += '\n  <p class="tt-popup-user-title p-l-5">\n    <span>' +
((__t = ( _.i18n("common.user") )) == null ? '' : __t) +
': ' +
((__t = ( screens[0].user.firstName )) == null ? '' : __t) +
' ' +
((__t = ( screens[0].user.lastName )) == null ? '' : __t) +
'</span>\n  </p>\n  ';
 _.each(screens, function(screen) { ;
__p += '\n    <p class="task-label-tt">\n      ';
 if (screen.ticketSecId) { ;
__p += '\n        <span>' +
((__t = ( _.i18n("common.task") )) == null ? '' : __t) +
': <a href="#graphical_service_kanban/' +
((__t = ( screen.ticketSecId )) == null ? '' : __t) +
'/messages-cs">' +
((__t = ( _.i18n("timetracking.task.detail") )) == null ? '' : __t) +
'</a></span>\n      ';
 } ;
__p += '\n      <span class="p-l-15">' +
((__t = ( _.i18n("common.date") )) == null ? '' : __t) +
': ' +
((__t = ( screen.start )) == null ? '' : __t) +
' - ' +
((__t = ( screen.end )) == null ? '' : __t) +
'</span>\n    </p>\n    ';
 if (screen.comment) { ;
__p += '\n        <p class="task-label-tt">\n          <span class="">' +
((__t = ( _.i18n("common.comment") )) == null ? '' : __t) +
': ' +
((__t = ( screen.comment )) == null ? '' : __t) +
'</span>\n        </p>\n    ';
 } ;
__p += '\n  ';
 _.each(screen.snaptshots, function(snaptshot) { ;
__p += '\n    <div class="thumbnail timetracking-image-in-popup" data-uid="' +
((__t = ( snaptshot.fileUid )) == null ? '' : __t) +
'">\n      <a class="btn json-calendar-image" data-toggle="modal-popover" data-file-uid="' +
((__t = ( snaptshot.fileUid )) == null ? '' : __t) +
'">\n        <img src="' +
((__t = ( snaptshot.fileUrl )) == null ? '' : __t) +
'" class="to-popover-timetracking-show">\n      </a>\n      <div class="caption">\n        <p class="text-ellipsis m-b-none">\n        ' +
((__t = ( snaptshot.dateTime )) == null ? '' : __t) +
'\n        </p>\n        <div class="progress">\n          <div data-toggle="tooltip" title="' +
((__t = ( snaptshot.activity )) == null ? '' : __t) +
'%" class="clickable progress-bar ' +
((__t = ( snaptshot.activity>50?'progress-bar-success':snaptshot.activity>25?'progress-bar-warning':'progress-bar-danger' )) == null ? '' : __t) +
'" role="progressbar"\n               aria-valuenow="' +
((__t = ( snaptshot.activity )) == null ? '' : __t) +
'" aria-valuemin="0" aria-valuemax="100" style="width:' +
((__t = ( snaptshot.activity )) == null ? '' : __t) +
'%">\n              ' +
((__t = ( snaptshot.activity )) == null ? '' : __t) +
'%\n          </div>\n        </div>\n      </div>\n    </div>\n  ';
 }); ;
__p += '\n  ';
 }); ;
__p += '\n  ';
 } else { ;
__p += '\n  <span>' +
((__t = ( _.i18n("common.noData") )) == null ? '' : __t) +
'</span>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

