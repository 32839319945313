define( 'entities/product_filter',[
  'app',
  'settings',
  'backbone',
  'backboneRelational'
], function(
  App,
  Settings,
  Backbone
) {
  'use strict';
  var app = { ns: {}};
  Backbone.Relational.store.addModelScope(app.ns);

  app.ns.ProductFilters = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'filters',
      relatedModel: 'Filters',
      collectionType: 'FiltersCollection'
    },{
      type: Backbone.HasOne,
      key: 'filterType',
      relatedModel: 'ProductFilterType'
    }],

    idAttribute: 'secId',

    destroy: function() {
      this.url = Settings.url('compuzz2', 'products.json/product/' + this.get('productSecId') + '/filters/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    url: function() {
      return Settings.url('compuzz2', 'products.json/product/' + this.get('productSecId') + '/filters');
    }
  });

  app.ns.ProductFilterType = Backbone.RelationalModel.extend({
    idAttribute: 'secId'
  });

  app.ns.Filters = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'filtersValues',
      relatedModel: 'ProductFilterValueSelect',
      collectionType: 'ProductFilterValueSelectCollection'
    }],

    idAttribute: 'secId'
  });

  app.ns.FiltersCollection = Backbone.Collection.extend({
    model: 'Filters'
  });

  app.ns.ProductFilterValueSelect = Backbone.RelationalModel.extend({
    idAttribute: 'secId'
  });

  app.ns.ProductFilterValueSelectCollection = Backbone.Collection.extend({
    model: 'ProductFilterValueSelect',
    comparator: function(item) { 
      return item.get('filter').displayName.toLowerCase(); 
    }
  });

  app.ns.ProductFiltersCollection = Backbone.Collection.extend({
    model: app.ns.ProductFilters,
    idAttribute: 'secId',

    fetch: function(secId) {
      this.url = Settings.url('compuzz2', 'products.json/product/' + secId + '/filters');
      return Backbone.Collection.prototype.fetch.call(this);
    }
  });

  App.reqres.setHandler('products-filter:model', function (productFiltersJson) {
    var tt = Backbone.Relational.store.find(app.ns.ProductFilters, productFiltersJson.secId );
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductFilters(productFiltersJson);
    }
  });

  App.reqres.setHandler('products-filter:collection', function () {
    return new app.ns.ProductFiltersCollection();
  });

  App.reqres.setHandler('products-filter:get-filter-types', function () {
    var url = Settings.url('compuzz2', 'v2/productfiltertypes/all'),
    defer = $.Deferred();

    $.ajax({
      method: 'get',
      url: url,
      success: function(productFilterTypes) {
        defer.resolve(productFilterTypes);
      }
    });

    return defer.promise();
  });
});
