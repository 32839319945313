/**
 * Created by BFR on 20/07/2017.
 */
define('caccountInvoicesTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'caccountInvoicesTabView',
  'entities/caccounts/invoices',
  'settings'
], function(
  module,
  _,
  App,
  bootbox,
  View
) {
  'use strict';

  var CAccountsCreditsTabController = {

    showTab: function() {
      var view = new View();

      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);
    },

    onGridLoaded:function() {
      var data = {
          url: App.request('caccount-invoices:get-url'),
          rowCountUrl: App.request('caccount-invoices:row-count-url')
        };
        
      this.view.displayGrid(data, true);
    }
  };

  module.exports = CAccountsCreditsTabController;
});
