define('webshopProductsListInfosView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!webshopProductsListInfosView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-infos-view ' + FieldView.prototype.className,

    modelEvents: {
      'change:code': 'render',
      'change:texts': 'render'
    },

    serializeData: function() {
      var data = {};

      data.code = this.model.getCode();
      data.name = this.model.getName();

      return data;
    }
  });
});
