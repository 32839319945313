define('filtersBannerView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'events',
  'windows',
  'filtersBannerFiltersView',
  'template!filtersBannerView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  Events,
  Windows,
  FiltersView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'filters-banner-view',
    editViewClass: null,
    quickAccessViewClass: null,

    ui: {
      empty: '.empty',
      filtersRegion: '.filters-region',
      quickAccessRegion: '.quick-access-region'
    },

    regions: {
      filtersRegion: '.filters-region',
      quickAccessRegion: '.quick-access-region'
    },

    events: {
      'click': 'onClick'
    },

    attributes: function() {
      return {
        'title': _.i18n('common.filters-banner.title')
      };
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function() {
      this.filtersView = new FiltersView({
        collection: this.collection,
        model: this.model
      });
      this.getRegion('filtersRegion').show(this.filtersView);
      this.filtersView.on('close', _.bind(this._onDeleteFilter, this));

      //----------------
      if(this.quickAccessViewClass) {
        this.quickAccessModel = this.model.clone();
        this.quickAccessView = new this.quickAccessViewClass({
          model: this.quickAccessModel,
          bannerView: this
        });
        this.getRegion('quickAccessRegion').show(this.quickAccessView);
        this.quickAccessView.on('confirm', _.bind(this.onConfirm, this));
      }

      //----------------
      this.setCollection();
    },

    onRender: function() {
      if(this.quickAccessViewClass)
        this.ui.quickAccessRegion.css('display', 'flex');
      else
        this.ui.quickAccessRegion.hide();
    },

    setCollection: function() {
      this.renderEmpty();
    },

    renderEmpty: function() {
      if(this.collection.models.length === 0) {
        this.ui.filtersRegion.hide();
        this.ui.empty.css('display', 'flex');
      } else {
        this.ui.filtersRegion.css('display', 'flex');
        this.ui.empty.hide();
      }
    },

    onClick: function(e) {
      e.stopPropagation();
      this.edit();
    },

    edit: function() {
      if(this.editViewClass) {
        Windows.open({
          view: _.bind(function () {
            this.editView = new this.editViewClass({
              model: this.model.clone(),
              bannerView: this
            });
            this.editView.on('confirm', _.bind(this.onConfirm, this));
            return this.editView;
          }, this),
          modal: true,
          width: 600
        });
      }
    },

    fetch: function(model) {
      this.model.set(model.toJSON());
    },

    onConfirm: function(model) {
      this.fetch(model);
      if(this.quickAccessModel)
        this.quickAccessModel.set(this.model.toJSON());
      this.collection.reset();
      this.setCollection();
      this.trigger('change', this.model);
    },

    onKeyDown: function(e) {
      if ((e.ctrlKey || e.altKey) && e.which === 70) {
        e.preventDefault();
        e.stopPropagation();
        this.edit();
        return;
      }

      if ((e.ctrlKey || e.altKey) && e.shiftKey && e.which === 70) {
        e.preventDefault();
        e.stopPropagation();
        console.log('clear filters');
        return;
      }
    },

    _onDeleteFilter: function(model) {
      var cloneModel = this.model.clone();

      this.onDeleteFilter(cloneModel, model);

      this.fetch(cloneModel);

      if(this.quickAccessModel)
        this.quickAccessModel.set(this.model.toJSON());
      this.collection.reset();
      this.setCollection();
      this.trigger('change', this.model);
    },

    onDeleteFilter: function(model, filterModel) {
      model.set(filterModel.get('id'), '');
    },

    hasFilter: function() {
      return this.collection.models.length > 0;
    }
  });
});

