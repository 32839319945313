define('ordersAlertView',[
  'app',
  'module',
  'underscore',
  'modalsAlertView',
  'template!ordersAlertView'
], function(
  App,
  Module,
  _,
  ModalsAlertView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsAlertView.extend({
    template: Tpl,
    className: 'orders-alert-view ' + ModalsAlertView.prototype.className,

    serializeData: function() {
      var data = {};

      data.text = this.options.text;

      data.reason = '';
      if(this.options.reason)
        data.reason = this.options.reason;

      //----------------
      data.companyName = '';
      if(this.options.order.get('companyName'))
        data.companyName = this.options.order.get('companyName');

      //----------------

      data.infos = this.options.order.get('purchaseOrderId');

      if(this.options.order.get('productName'))
        data.infos += ' | ' + this.options.order.get('productName');

      return data;
    },

    // Static methods
    open: function (text, reason, order) {
      require([
        'windows',
        'ordersAlertView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              text: text,
              reason: reason,
              order: order
            });
          }, this),
          modal: true,
          maxWidth: 800
        });
      });
    }
  });
});
