/**
 * Created by OLD on 20/11/2015.
 */
define('rewardConditionsPopupView',[
    'module',
    'underscore',
    'settings',
    'photoswipe',
    'photoswipeUi',
    'template!rewardConditionsPopupTpl',
    'dialogFormView'
], function(
    module,
    _,
    Settings,
    PhotoSwipe,
    PhotoSwipeUI,
    viewTpl,
    DialogFormView
) {
    'use strict';

    var RewardConditionsPopupView = DialogFormView.extend({
        template: viewTpl,

        ui: {
        },

        events: {
        },

        regions: {
            extentionRegion: '.extention-autocomplete'
        }
    });

    module.exports = RewardConditionsPopupView;
});
