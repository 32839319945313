
define('template!translationsFilterLanguageButtonTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p +=
((__t = ( _.i18n('common.languages') )) == null ? '' : __t);
 _.each(items, function(language){ if(language.checked) {;
__p += ' <span class="label ' +
((__t = ( language.label )) == null ? '' : __t) +
'">' +
((__t = ( language.abbreviation )) == null ? '' : __t) +
'</span>';
 }}) ;
__p += ' <span class="caret"></span>\n';

}
return __p
};});

