
define('template!productEditsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="js-new-picture-region f-l"></div>\n  <div class="js-pictures-region"></div>\n</div>\n<div class="row">\n  <div class="col-xs-12 product-edit">\n    <div class="pa-list">\n      <div class="pa-item" style="position: relative;">\n        <div class="pa">\n          <div class="pa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8 name-region"></div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8 description-region"></div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.width') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( width )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.height') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( height )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.deepness') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( deepness )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.weight') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( weight )) == null ? '' : __t) +
' gr</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.minimumOrderQuantity') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( minimumOrderQuantity )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 if (!readOnly) { ;
__p += '\n        <div class="btn-container btn-pa-container" style="display: none;">\n          <i class="mdi mdi-editor-mode-edit btn-edit-product clickable btn-frame"></i>\n        </div>\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </div>\n</div>\n<!--\n<div class="row">\n  <div class="col-lg-12">\n    ';
 if (!design2) { ;
__p += '\n    <button class="btn btn-primary disabled-grey btn-generate-textile-pas" title="ctrl+g">' +
((__t = (
      _.i18n('common.generateTextilePas') )) == null ? '' : __t) +
'\n    </button>\n    ';
 } ;
__p += '\n  </div>\n</div> -->\n<div class="row">\n  <h2 class="text-left p-l-15" style="display: inline-block;">Print areas</h2>\n  <i class="mdi mdi-av-my-library-add btn-add-printarea clickable p-l-10 m-b-10"\n     style="height: 20px; font-size: 18px;"></i>\n</div>\n<div class="row">\n  <div class="edit-printareas col-xs-12"></div>\n</div>\n<div class="row">\n  <h2 class="text-left p-l-15" style="display: inline-block;">Options categories</h2>\n  <i class="mdi mdi-av-my-library-add btn-add-optioncategory clickable p-l-10 m-b-10"\n     style="height: 20px; font-size: 18px;"></i>\n</div>\n<div class="row">\n  <div class="edit-cats col-xs-12"></div>\n</div>\n<div class="row">\n  <h2 class="text-left p-l-15" style="display: inline-block;">Variations</h2>\n  <i class="mdi mdi-av-my-library-add btn-add-variation clickable p-l-10 m-b-10"\n     style="height: 20px; font-size: 18px;"></i>\n</div>\n<div class="row">\n  <div class="edit-variations col-xs-12"></div>\n</div>';

}
return __p
};});

