define('webshopProductsDetailBodyUrlsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'webshopProductsDetailBodyUrlsDomainView',
  'template!webshopProductsDetailBodyUrlsView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DomainView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-urls-view',
    childView: DomainView,
    childViewContainer: '.domains-container',
    childViewOptions: function () {
      return {
        product: this.model
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
      this.fetchRequest = null;
    },

    serializeData: function () {
      var data = {};
      var url = Settings.configValue('webshop.url');
      data.adminUrl = url + '/admin/default/jqadm/get/product/' + this.model.getId();
      return data;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      var params = {
        code: this.model.getCode()
      };

      this.collection.reset();

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.product.urls', params),
        success: _.bind(function (items) {
          this.collection.add(items);
        }, this)
      });
    }
  });
});
