
define('template!customFieldTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-2 text-right">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n<div class="col-xs-5 name-region h-20"></div>\n<div class="col-xs-5 value-region h-20"></div>\n';

}
return __p
};});

