/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminLayoutView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'template!superAdminLayoutTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var superAdminView =  Marionette.LayoutView.extend({
    template: viewTpl,

    events: {
      'click @ui.tabTitle': 'onChangeTab'
    },

    ui: {
      tabTitle: '.js-tab-title'
    },

    regions: {
      saTabContentRegion: '#js-superadmin-content-region'
    },

    serializeData: function() {
      return {tab: this.options.tab};
    },

    onShow: function() {
      $.material.init();
    },

    onChangeTab: function(e) {
      var target = $(e.currentTarget),
        tab = target.data('tab');

      this.trigger('onChangeTab', tab);
    }
  });

  module.exports = superAdminView;
});
