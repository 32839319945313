define('adminGlossariesListTypeView',[
  'module',
  'underscore',
  'tableFieldSelectView'
], function (
  Module,
  _,
  TableFieldSelectView
) {
  'use strict';

  Module.exports = TableFieldSelectView.extend({
    className: 'admin-glossaries-list-type-view table-field-view',

    setValue: function(value) {
      var defer = $.Deferred();

      if(this.model.get('type') !== value.get('id')) {
        this.model.set('type', value.get('id'));
        this.model.save()
            .done(_.bind(function() {
              defer.resolve();
            }, this))
            .fail(_.bind(function() {
              defer.reject();
            }, this));
      } else
        defer.resolve();

      return defer.promise();
    }
  });
});
