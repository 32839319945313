define('jqgridActionsButtonFormatterActions',[
  'module',
  'underscore'
], function (
  module,
  _
) {
  'use strict';

  module.exports = {
    edit: {
      icon: 'mdi-editor-mode-edit',
      text: _.i18n('common.edit'),
      event: 'edit'
    },
    'delete': {
      icon: 'mdi-action-delete',
      text: _.i18n('common.delete'),
      event: 'delete'
    },
    'archive': {
      icon: 'mdi-action-archive',
      text: _.i18n('common.archive'),
      event: 'archive'
    },
    'unarchive': {
      icon: 'mdi-action-unarchive',
      text: _.i18n('common.unarchive'),
      event: 'unarchive'
    },
    upload: {
      icon: 'mdi-file-file-upload',
      text: _.i18n('common.upload'),
      event: 'upload'
    },
    'import': {
      icon: '',
      text: _.i18n('common.import'),
      event: 'import'
    },
    'export': {
      icon: '',
      text: _.i18n('common.export'),
      event: 'export'
    },
    'exportContacts': {
      icon: '',
      text: _.i18n('common.exportContacts'),
      event: 'exportContacts'
    },
    'importContacts': {
      icon: '',
      text: _.i18n('common.importContacts'),
      event: 'importContacts'
    },
    'duplicate': {
      icon: '',
      text: _.i18n('common.duplicate'),
      event: 'duplicate'
    },
    'proofsheet': {
      icon: '',
      text: _.i18n('common.proofsheet'),
      event: 'proofsheet'
    },
    'copy': {
      icon: '',
      text: _.i18n('common.copy'),
      event: 'copy'
    },
    'cut': {
      icon: '',
      text: _.i18n('common.cut'),
      event: 'cut'
    },
    'paste': {
      icon: '',
      text: _.i18n('common.paste'),
      event: 'paste'
    },
    'unselect': {
      icon: '',
      text: _.i18n('common.unSelectAll'),
      event: 'unselect'
    },
    'uploadCustomProofsheet': {
      icon: '',
      text: _.i18n('common.uploadCustomProofsheet'),
      event: 'uploadCustomProofsheet'
    },
    'uploadCustomExport': {
      icon: '',
      text: _.i18n('common.uploadCustomExport'),
      event: 'uploadCustomExport'
    },   
    'productSheet': {
      icon: '',
      text: _.i18n('common.productSheet'),
      event: 'productSheet'
    },
    'toInvoice': {
      icon: '',
      text: _.i18n('common.toInvoice'),
      event: 'toInvoice'
    },
    'downloadProforma': {
      icon: '',
      text: _.i18n('offerList.proforma'),
      event: 'downloadProforma'
    },
    'downloadDeliveryNote': {
      icon: '',
      text: _.i18n('common.downloadDeliveryNote'),
      event: 'downloadDeliveryNote'
    },
    'convertPimToCompuzz': {
      icon: '',
      text: _.i18n('common.convertPimToCompuzz'),
      event: 'convertPimToCompuzz'
    },
    'downloadProofsheet': {
      icon: '',
      text: _.i18n('common.downloadProofsheet'),
      event: 'downloadProofsheet'
    }
  };
});
