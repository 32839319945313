define('customersDetailBodyAttachmentsView',[
  'app',
  'module',
  'underscore',
  'attachmentsView'
], function(
  App,
  Module,  
  _, 
  AttachmentsView
) {
  'use strict';
  
  Module.exports = AttachmentsView.extend({
    className: 'customers-detail-attachments-view attachments-view',
    setConfig() {
      return {
        canCopy: false
      };
    },
    
    fetch: function(first, pageSize) {  
      return this.model.getAttachments(first, pageSize);
    },

    uploadItem: function(file) {   
      return this.model.uploadAttachment(file);
    },

    deleteItem(attachment) {
      return this.model.deleteAttachment(attachment);
    },

    editItem: function(attachment) {
      App.navigate('attachments/attachments/' + this.model.get('secId') + '/' + attachment.get('secId') + '/caccount', {trigger: true});
    }
  });  
});

