define('tasksCreatorView',[
  'app',
  'module',
  'backbone',
  'underscore', 
  'tableFieldView',
  'userView',
  'template!tasksCreatorView'
], function (
  App,
  Module,
  Backbone,
  _,
  FieldView,
  UserView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-creator-view ' + FieldView.prototype.className,

    regions: {
      userRegion: '.user-region'
    },

    serializeData: function () {
      var data = {};

      var name = '';

      if(this.model.get('refCreator')) {
        if(this.model.get('refCreator').firstName)
          name = this.model.get('refCreator').firstName;

        if(this.model.get('refCreator').lastName) {
          if(name !== '')
            name += ' ';
          name += this.model.get('refCreator').lastName;
        }
      }

      if(name !== '')
        data.name = name;

      return data;
    },

    onRender: function() {
      if (this.model.get('refCreator')) {
        this.userView = new UserView({
          model: new Backbone.Model(this.model.get('refCreator'))
        });

        this.getRegion('userRegion').show(this.userView);
      }
    }
  });
});
