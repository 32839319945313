/**
 * Created by BFR on 12/12/2017.
 */
define('plivoUtils',[
    'module',
    'app',
    'logger',
    'jquery',
    'underscore',
    'settings',
    'entities/customers/contacts'
], function (module,
             App,
             Logger,
             $,
             _,
             Settings) {
    'use strict';


    var PlivoUtils = {
        plivoPhoneNumber:null,
        customerToSwitchTo:null,
        displayContainer:false,
        displayIncoming:false,
        eventCall: [],
        bell: new Audio('https://s3.eu-central-1.amazonaws.com/email-images-compuzz/ring_ring.mp3'),

        refreshInterface: function() {
          this.callEventCall();
          if (this.displayContainer) {
              //$('#btn-container').show();
          }
          if (this.displayIncoming) {
              //$('#incoming_callbox').show('slow');
          }
        },

        findConnectedUsers: function() {
            var url = Settings.url('compuzzApi', '/plivo/onlineusers'),
            defer = $.Deferred();
            $.ajax({
                method: 'get',
                url: url,
                contentType: 'application/json',
                success: function(users) {
                    defer.resolve(users);
                },
                error: function() {
                    defer.fail();
                }
            });
            return defer.promise();
        },

        forwardCall: function(to) {
            var url = Settings.url('compuzzPlivo', '/forward/init'),
            defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                data: JSON.stringify({
                    callUid:  this.plivoWebSdk.client.callUUID,
                    forwardTo: to,
                    from: this.plivoWebSdk.client.userName
                }),
                contentType: 'application/json',
                success: function(users) {
                    defer.resolve(users);
                }
            });
    
            return defer.promise();
        },

        // Make outgoing calls
        callNumber: function(number) {
            // The destination phone number to connect the call to
            if (this.isNotEmpty(number)) {
                Settings.setToMemory('plivoCurrentCallNumber',number);
                  
                var extraHeaders = {'X-PH-CaccountSecId': Settings.get('currentCAccount').secId
                .replace(/_/g,'ZZ').replace(/-/g, 'YY'),
                'X-PH-Lang': Settings.get('lang'), 'X-PH-UserPid': Settings.get('currentUser').secId
                  .replace(/_/g,'ZZ').replace(/-/g, 'YY') };

                this.plivoWebSdk.client.call(number.replace(/ /g, ''), extraHeaders);

            } else {
                App.trigger('phone:status', 'Invalid Destination');
            }
        },

        // Login with SIP Endpoint
        login: function () {
            // SIP Endpoint username and password
            var username = Settings.get('plivoUser');
            var password = Settings.get('plivoPassword');

            // Login
            this.plivoWebSdk.client.login(username, password);
        },

        logout: function() {
            App.trigger('phone:status', 'Logged out');
            this.plivoWebSdk.client.logout();
        },

        isNotEmpty: function(n) {
            return n.length > 0;
        },

        callEventCall: function() {
            for (var i = 0 ; i < this.eventCall.length ; i++) {
                this.eventCall[i]();
            }
        },

        onCalling: function() {
            console.log('onCalling');
            App.trigger('phone:update', {
                status: 'calling',
                direction: 'out',
                phoneNumber: Settings.getFromMemory('plivoCurrentCallNumber')
            });     
        },

        onMediaPermission: function(result) {
            if (result) {
                console.log('get media permission');
            } else {
                alert('you don\'t allow media permission, you will can\'t make a call until you allow it');
            }
        },

        webrtcNotSupportedAlert: function() {
            //$('#txtStatus').text('');
            alert('Your browser doesn\'t support WebRTC. You need Chrome 25 to use this demo');
        },

        onLogin: function() {
            App.trigger('phone:status', 'Logged in');
        },

        onLoginFailed: function() {
            App.trigger('phone:status', 'Login Failed');
        },

        onCallRemoteRinging: function() {
            App.trigger('phone:status', 'Ringing...');
        },

        onCallAnswered: function() {
            this.stopBell();
            this.callEventCall();
            console.log('onCallAnswered');
            App.trigger('phone:status', 'Call Answered');
            this.showPlivoHeader('out');
            this.displayContainer = true;

            App.trigger('phone:update', {
                status: 'calling',
                direction: 'in',
                phoneNumber: Settings.getFromMemory('plivoCurrentCallNumber')
            });
        },

        onReady: function() {
            console.log('onReady...');
            this.login();
        },

        playBell: function() {
          this.bell.play();
          this.bell.onended = _.bind(function() {
              this.bell.play();
          }, this);
        },

        stopBell: function() {
            this.bell.onended = null;
            if (this.bell.stop) {
                this.bell.stop();
            }
        },

        // Incoming calls
        /*jshint camelcase:false */
        onIncomingCall: function(account_name, extraHeaders) {
            this.callEventCall();
            this.playBell();
            console.log('onIncomingCall:' + account_name);
            console.log('account=' + extraHeaders['X-Ph-Caccount']);
            console.log('extraHeaders=');
            for (var key in extraHeaders) {
                console.log('key='+key+'.val='+extraHeaders[key]);
            }

            App.trigger('phone:update', {
                status: 'incoming-call',
                direction: 'in',
                phoneNumber: account_name.split('@')[0]
            });
          
            //App.trigger('phone:status', 'Incoming Call From: ' + account_name.split('@')[0]);
           
            App.trigger('phone:calling:phone-number', extraHeaders['X-Ph-Caccount'] + ' : ' +
            (extraHeaders['X-Ph-Countrycode']?(extraHeaders['X-Ph-Countrycode'] + ' : '):'') +
            (extraHeaders['X-Ph-Customer']?extraHeaders['X-Ph-Customer']:account_name.split('@')[0]));    

            this.showPlivoHeader('in');
            var phoneNumber = account_name.split('@')[0];
            Settings.setToMemory('plivoCurrentCallNumber', phoneNumber);
            App.request('customers:contacts:get-by-phone', encodeURI(phoneNumber))
              .done(_.bind(function(result) {
                  if (result && result.responseText) {
                      this.customerToSwitchTo = result.responseText;
                      //$('.js-show-customer-offers').attr('data-route','#customers/' + this.customerToSwitchTo + '/offers');
                      //$('.js-show-customer-offers').show();
                  } else {
                      Settings.setToMemory('plivoCurrentCallNumber', phoneNumber);
                      //$('.js-link-phone-to-customer').attr('data-phoneNumber',phoneNumber);
                      //$('.js-link-phone-to-customer').show();
                  }

                  this.displayNotification('New Incomoing call');
              },this));
        },

        displayNotification: function(title, message) {
            var hideDelay = 30000;
            if (window.Notification && window.Notification.permission === 'granted') {
                var options = {
                    body: message?message.replace(new RegExp('<br>', 'g'), ' '):'',
                    icon: Settings.url('base', Settings.get('defaultLogo'))
                };

                var notification = new window.Notification(title, options);
                notification.onclick = function () {
                    window.focus();
                };
                notification.onshow = function() {
                    window.setTimeout(function(){
                        notification.close();
                    }, hideDelay);
                };
            }
        },

        onCallTerminated: function() {
            console.log('onCallTerminated');
            this.customerToSwitchTo = null;
            this.plivoPhoneNumber = null;
            this.hidePlivoHeader('out');
            this.displayContainer = false;
            this.hideLinkButtons();

            App.trigger('phone:update', {
                status: 'phone'
            });
        },

        answer: function() {
            this.plivoPhoneNumber = Settings.getFromMemory('plivoCurrentCallNumber');
            this.stopBell();
            console.log('answering');
            App.trigger('phone:status', 'Answering...');
            this.plivoWebSdk.client.answer();
            App.trigger('phone:status', 'Answered the Call');
            this.hidePlivoHeader('in');
            this.displayIncoming = false;            
        },

        // Reject incoming calls
        // 
        reject: function() {
            this.stopBell();          
            App.trigger('phone:status', 'Rejected the Call');
            this.plivoWebSdk.client.reject();
            this.hidePlivoHeader('in');
            this.displayIncoming = false;          
            App.trigger('phone:status', 'Ready');
            this.hideLinkButtons();

            App.trigger('phone:update', {
                status: 'phone'
            });
        },

        ignore: function() {
            this.stopBell();           
            App.trigger('phone:status', 'Ignore the Call');
            this.plivoWebSdk.client.ignore();
            this.hidePlivoHeader('in');
            this.displayIncoming = false;            
            App.trigger('phone:status', 'Ready');
            this.hideLinkButtons();

            App.trigger('phone:update', {
                status: 'phone'
            });
        },

        mute: function() {
            this.plivoWebSdk.client.mute();
            App.trigger('phone:calling:mute', true);
         },

        unmute: function() {
            this.plivoWebSdk.client.unmute();
            App.trigger('phone:calling:mute', false);
        },

        //
        hangup: function() {
            this.plivoWebSdk.client.hangup();
            App.trigger('phone:update', {
                status: 'phone'
            });
        },

        onCallFailed: function() {
            console.log('canceled');
            this.hidePlivoHeader('out');
            this.displayContainer = false;
            this.displayIncoming = false;
            this.customerToSwitchTo = null;
            this.plivoPhoneNumber = null;
           
            this.hideLinkButtons();

            App.trigger('phone:update', {
                status: 'phone'
            });
        },

        dtmf: function(digit) {
            console.log('send dtmf='+digit);
            this.plivoWebSdk.client.sendDtmf(digit);
        },

        plivoWebSdk: null,

        init: function() {
            if (Settings.get('plivoUser') && Settings.get('plivoPassword')) {
                var defaultSettings = {
                    debug: 'ERROR',
                    permOnClick: true,
                    codecs: ['OPUS','PCMU'],
                    enableIPV6: false,
                    closeProtection: true,
                    allowMultipleIncomingCalls: false,
                    audioConstraints: {
                        optional: [ {
                                googAutoGainControl: false
                            },
                            {googEchoCancellation:false}
                            ]},
                    enableTracking: true};

                try {
                    this.plivoWebSdk = new window.Plivo(defaultSettings);
                    this.plivoWebSdk.client.on('onReady', _.bind(this.onReady, this));
                    this.plivoWebSdk.client.on('onWebrtcNotSupported', _.bind(this.webrtcNotSupportedAlert, this));
                    this.plivoWebSdk.client.on('onLogin', _.bind(this.onLogin, this));
                    this.plivoWebSdk.client.on('onLoginFailed', _.bind(this.onLoginFailed, this));
                    this.plivoWebSdk.client.on('onCallRemoteRinging', _.bind(this.onCallRemoteRinging, this));
                    this.plivoWebSdk.client.on('onIncomingCallCanceled', _.bind(this.onCallFailed, this));
                    this.plivoWebSdk.client.on('onCallFailed', _.bind(this.onCallFailed, this));
                    this.plivoWebSdk.client.on('onCallAnswered', _.bind(this.onCallAnswered, this));
                    this.plivoWebSdk.client.on('onCallTerminated', _.bind(this.onCallTerminated, this));
                    this.plivoWebSdk.client.on('onCalling', _.bind(this.onCalling, this));
                    this.plivoWebSdk.client.on('onIncomingCall', _.bind(this.onIncomingCall, this));
                    this.plivoWebSdk.client.on('onMediaPermission', _.bind(this.onMediaPermission, this));
                    this.plivoWebSdk.client.setRingTone(false);
                    this.plivoWebSdk.client.setRingToneBack(true);
                    console.log('initPhone ready!');

                    this.onReady();
                    
                } catch(e) {
                    console.log(e);
                }               
            }
        },

        showPlivoHeader: function(target) {
           
            switch(target) {
                case 'out':
                    //$('#btn-container').show();                    
                    break;
                case 'in':
                    //$('#incoming_callbox').show('slow');                   
                break;
            }
            if (this.customerToSwitchTo !== null) {
                //$('.js-show-customer-offers').attr('data-route','#customers/' + this.customerToSwitchTo + '/offers');
                //$('.js-show-customer-offers').show();
            } else if (this.plivoPhoneNumber !== null){
                Settings.setToMemory('plivoCurrentCallNumber', this.plivoPhoneNumber);
                //$('.js-link-phone-to-customer').attr('data-phoneNumber',this.plivoPhoneNumber);
                //$('.js-link-phone-to-customer').show();
            }
            window.setTimeout(function(){               
                }, 1000);
        },

        hidePlivoHeader: function(target) {
            this.hideLinkButtons();
            this.stopBell();
            switch(target) {
                case 'out':
                    //$('#btn-container').hide();                   
                break;
                case 'in':
                    $('#incoming_callbox').hide();                   
                break;
            }
        },

        hideLinkButtons: function() {
        }
    };

    module.exports = PlivoUtils;
});
