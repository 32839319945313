define('caccountTransportExpressPricesView',[
  'module',
  'marionette',
  'app',
  'template!caccountTransportExpressPricesView',
  'caccountTransportItemPriceView'
], function (
  module,
  Marionette,
  App,
  Tpl,
  caccountTransportItemPriceView
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: caccountTransportItemPriceView,
    childViewContainer: '.prices-region',

    events: {
      'click .btn-add-transport-price': 'onAddTransportPriceClick',
      'click .btn-delete-transport-price': 'onRemoveTransportPriceClick'
    },

    childEvents: {
      'save': function () {
        this.model.save(this.options.supplierUid);
      }
    },

    onAddTransportPriceClick: function () {
      this.collection.addNew();
    },

    onRemoveTransportPriceClick: function () {
      this.collection.remove(this.collection.at(this.collection.length - 1));
      this.model.save(this.options.supplierUid);
    }
  });
});
