define('orderPicturesTabPictureItemView',[
  'module',
  'marionette',
  'template!orderPicturesTabPictureItemTpl',
  'bootbox',
  'jquery',
  'settings',
  'app',
  'underscore',
  'pluginUtils'
], function (
  module,
  Marionette,
  pictureItemTpl,
  bootbox,
  $,
  Settings,
  App,
  _,
  PluginUtils
) {
  'use strict';

  var PictureItemView = Marionette.ItemView.extend({
    template: pictureItemTpl,
    className: 'picture-elements',

    ui: {
      edit: '.js-edit',
      print: '.js-print',
      'delete': '.js-delete',
      hide: '.js-hidden',
      defaultVrp: '.js-default'
    },

    events: {
      'click @ui.edit': '_edit',
      'click @ui.print': '_print',
      'click @ui.delete': '_delete',
      'click @ui.hide': '_hideShowClick',
      'click @ui.defaultVrp': '_defaultVrpClick'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      if (this.model.get('orderModel').get('defaultVrp') === this.model.get('secId')) {
        templateData.defaultVrp = true;
      } else {
        templateData.defaultVrp = false;
      }
      if (this.model.get('orderModel').get('hiddenVrps') &&
          this.model.get('orderModel').get('hiddenVrps').indexOf(this.model.get('secId')) !== -1) {
        templateData.hiddenVrp = true;
      } else {
        templateData.hiddenVrp = false;
      }
      return templateData;
    },

    _defaultVrpClick: function() {
      this.model.get('orderModel').setDefaultVrp(this.model.get('secId'));
    },

    _hideShowClick: function() {
      if (this.model.get('orderModel').get('hiddenVrps') &&
          this.model.get('orderModel').get('hiddenVrps').indexOf(this.model.get('secId')) !== -1) {
        this.model.get('orderModel').showVrp(this.model.get('secId')).fail(function() {
            bootbox.alert(_.i18n('common.error'));
        });
      } else {
        this.model.get('orderModel').hideVrp(this.model.get('secId')).fail(function() {
          bootbox.alert(_.i18n('common.error'));
        });
      }
    },

    _edit: function () {
      PluginUtils.callPlugin({
        action: 'editProductPicture',
        productPictureSecId: this.model.get('secId')
      });
    },

    _print: function () {
      PluginUtils.callPlugin({
        action: 'placePrintArea',
        productPictureSecId: this.model.get('secId')
      });
    },

    _delete: function () {
      var model = this.model;
      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          model.destroy();
        }
      });
    },

    _ambiance: function () {
      this.model.set('ambiance', !this.model.get('ambiance'));
      this.model.save().then(_.bind(function () {
        this.render();
      }, this));
    }
  });

  module.exports = PictureItemView;
});
