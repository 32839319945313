
define('template!ordersDetailOrderProductView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n  <div class="title">' +
((__t = ( _.i18n('orders.detail.product-title') )) == null ? '' : __t) +
'</div>\n  <div class="edit">\n    <div title="' +
((__t = ( _.i18n('dashboard.orders.editProductButtonTitle') )) == null ? '' : __t) +
'">\n      <svg class="btn-view-product" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z"/></svg>\n    </div>\n    <div title="' +
((__t = ( _.i18n('orders.selectProductTitle') )) == null ? '' : __t) +
'">\n      <svg class="btn-select-product" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n    </div>\n  </div>   \n</div>\n<div class="body">\n  <div class="top">\n    <div class="column">\n      <div class="product-name item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('offerList.productName') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-product-name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value btn-edit-product"></div>\n      </div>     \n      <div class="custom-name-region"></div>      \n      <div class="custom-description-region"></div>\n      <div class="quantity-region"></div>\n    </div>\n    <div class="column">\n      <div class="base-code item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('orderList.productCode') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-product-code" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="supplier-code item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('orderList.supplierProductCode') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-supplier-product-code" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>          \n    </div>         \n    <div class="column options-region"></div>\n  </div>\n  <div class="stock-region"></div>\n</div>';

}
return __p
};});

