
define('template!orderStatusDetailsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  <div class="with-header">\n    <div class="caccount-details-header">\n      <div class="caccount-info-container row">\n        <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n          <p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n          <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n        </div>\n        <span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n      </div>\n      <div class="caccount-tabs-container">\n        <div class="navbar navbar-default navbar-md row ">\n          <div class="navbar-header">\n            <a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n            </a>\n          </div>\n          <div class="navbar-collapse collapse">\n            <ul class="nav navbar-nav" id="sub-caccount-tabs">\n              ';
 _.each(orderStatusTabs, function(orderStatusTab, index) { ;
__p += '\n              <li class="  js-tab-title orderstatus-tab';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '"\n                  data-role="' +
((__t = ( orderStatusTab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( orderStatusTab.name )) == null ? '' : __t) +
'"\n                  data-route="' +
((__t = ( orderStatusTab.route )) == null ? '' : __t) +
'"><a href="#' +
((__t = ( orderStatusTab.route )) == null ? '' : __t) +
'-tab"\n                                                              data-toggle="tab">' +
((__t = ( orderStatusTab.title )) == null ? '' : __t) +
'</a>\n              </li>\n              ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="caccount-details-container">\n    <div class="tab-content content">\n      ';
 _.each(orderStatusTabs, function(orderStatusTab, index) { ;
__p += '\n      <div class="tab-pane';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( orderStatusTab.role )) == null ? '' : __t) +
'"\n           data-name="' +
((__t = ( orderStatusTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( orderStatusTab.route )) == null ? '' : __t) +
'"\n           id="' +
((__t = ( orderStatusTab.name )) == null ? '' : __t) +
'-tab"></div>\n      ';
 }); ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

