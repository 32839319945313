define('ordersDetailCompositionProofsheetHistoryItemView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'modalsConfirmationView',
  'pdfView',
  'moment',
  'template!ordersDetailCompositionProofsheetHistoryItemView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ConfirmationView,
  PdfView,
  moment,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'orders-detail-body-composition-proofsheet-history-item-view',
    template: Tpl,

    ui: {
      zoomButton: '.zoom-button',
      deleteButton: '.delete-button'
    },

    events: {
      'click @ui.zoomButton': 'onZoom',
      'click @ui.deleteButton': 'onDelete'
    },

    serializeData: function() {
      var data = {};
      data.src = '';
      if(this.model.get('value'))
        data.src = Settings.url('file', this.model.get('value')) + '&action=preview';

      data.date = '';
      if(this.model.get('creatDate'))
        data.date = moment(this.model.get('creatDate')).format('DD/MM/YYYY HH:mm');

      return data;
    },

    onZoom: function () {
      PdfView.prototype.open({
        url: Settings.url('file', this.model.get('value')),
        title: 'Proofsheet: ' + this.model.get('value')
      });
    },

    onDelete: function () {
      var secId = this.model.get('secId');
      if(secId === '')
        return;

      ConfirmationView.prototype.open({
        text: _.i18n('orders.detail.composition.proofsheet.delete.confirmation'),
        onConfirm: _.bind(function () {
          this.options.order.deleteCustomProofsheetHistory(secId).done(_.bind(function () {
            this.options.order.trigger('change:proofsheetHistory');
          }, this));
        }, this)
      });
    }
  });
});
