define('jqgridActionsButtonFormatterView',[
  'module',
  'underscore',
  'marionette',
  'template!jqgridActionsButtonFormatterTpl',
  'jquery'
], function(
  module,
  _,
  Marionette,
  editButtonTpl,
  $
) {
  'use strict';

  var ActionsButtonView = Marionette.ItemView.extend({
    template: editButtonTpl,

    ui: {
      actionItems: 'li',
      actionsButton: '.js-actions-button'
    },

    events: {
      'click @ui.actionItems': '_onClick',
      'shown.bs.dropdown .js-dropdown': '_onDropdownOpen',
      'hide.bs.dropdown .js-dropdown': '_onDropdownClose'
    },

    serializeData: function() {
      return {
        items: this.collection.toJSON(),
        dontShowIcons: this.options.dontShowIcons
      };
    },

    _onClick: function(e) {
      this.trigger('action', $(e.currentTarget).data('event'), this.options.colModel);
    },

    _onDropdownOpen: function() {
      this.ui.actionsButton.addClass('active');
    },

    _onDropdownClose: function() {
      this.ui.actionsButton.removeClass('active');
    },

    setButtonsState: function(state) {
      if (!state) return;
      var $items = this.$('.jqgrid-actions-li');
      _.each($items, _.bind(function(item){
        var $item = $(item),
            event = $item.data('event');
        if (state[event] === true) {
          $item.removeClass('disabled');
        } else if (state[event] === false){
          $item.addClass('disabled');
        }
      }, this));
    }

  });

  module.exports = ActionsButtonView;
});
