define('phoneCallsFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'phoneCallsFiltersEditView',
  'filtersBannerView'
], function(
  Module,
  Backbone,
  _,
  Settings,
  moment,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
      var settings = Settings.get('phoneCallsFilters', true);
      if(settings) {
        this.model = new Backbone.Model(JSON.parse(settings));
      } else {
        this.model = new Backbone.Model({
          dateFrom: '',
          dateTo: ''
        });
        Settings.set('phoneCallsFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {
      var dateValue = '';

      if(this.model.get('dateFrom') !== '' && this.model.get('dateTo') !== '') {
        dateValue = moment(new Date(this.model.get('dateFrom'))).format('DD/MM/YYYY');
        dateValue += ' - ';
        dateValue += moment(new Date(this.model.get('dateTo'))).format('DD/MM/YYYY');
        this.collection.add(new Backbone.Model({
          id: 'dates',
          libelle: _.i18n('phone.calls.dates'),
          value: dateValue
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    onDeleteFilter: function(model, filterModel) {
      if(filterModel.get('id') === 'dates') {
        model.set('dateFrom', '');
        model.set('dateTo', '');
      } else
        model.set(filterModel.get('id'), '');
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('dateFrom') !== model.dateFrom ||
          this.model.get('dateTo') !== model.dateTo)
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('phoneCallsFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

