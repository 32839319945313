define('attachmentCollectionView',['module',
        'backbone',
        'marionette',
        'vattachmentView'],
  function(module,
           Backbone,
           Marionette,
           AttachmentView){
  'use strict';

  var AttachmentCollectionView = Marionette.CollectionView.extend({
    childView: AttachmentView,
    collection: new Backbone.Collection(),


    onBeforeDestroyCollection: function() {
      var model;
      while ((model = this.collection.first())) {
        model.destroy();
      }
    }
  });

  module.exports = AttachmentCollectionView;
});
