
define('template!dropdownTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="">\n  <div class="dropdown">\n    <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n      <span class="pull-left dropdown__label"></span>\n      <span class="caret pull-right m-t-5 dropdown__caret"></span>\n    </button>\n    <ul class="dropdown-menu dropdown-menu-right dropdown__list" aria-labelledby="dropdownMenu">\n    </ul>\n  </div>\n</div>';

}
return __p
};});

