define('datePickerPopoverView',[
  'module',
  'underscore',
  'marionette',
  'template!vectorDatePickerPopoverTpl',
  'dateUtils'
], function(
  module,
  _,
  Marionette,
  datePickerPopoverTpl,
  dateUtils
) {
  'use strict';

  var DatePickerPopoverView = Marionette.ItemView.extend({
    template: datePickerPopoverTpl,

    ui: {
      dateFrom: '.js-date-from',
      dateTo: '.js-date-to',
      apply: '.js-apply'
    },

    events: {
      'click @ui.apply': 'onApply',
      'change @ui.dateFrom': '_removeError',
      'change @ui.dateTo': '_removeError',
      'focusout' : 'onFocusOut',
      'keydown' : 'onKeyDown'
    },

    className: 'datepicker-element',

    onKeyDown: function(e) {
      var code = e.keyCode || e.which;
      if (code === 8) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    onFocusOut: function() {
      var $target,
          datepicker;

      // close popover when click outside
      setTimeout(_.bind(function(){
        $target = $(document.activeElement);
        datepicker = $target.parents('.datepicker-element').length > 0 || 
          $target.parents('.ui-datepicker').length > 0 ||
          $target.hasClass('ui-datepicker') ||
          $target.get(0) === this.$el.get(0) ||
          $target.hasClass('popover-shown');

        if (!datepicker) this.trigger('close:popover');
      }, this), 0);
    },

    onRender: function() {
      this.$el.attr('tabindex', 0);
      this.ui.dateFrom.datepicker(this._getDatePickerOptions()).datepicker( 'widget' ).attr('tabindex', 1);
      this.ui.dateTo.datepicker(this._getDatePickerOptions()).datepicker( 'widget' ).attr('tabindex', 2);
    },

    onApply: function() {
      var dateFrom = this.ui.dateFrom.val().trim(),
        dateTo = this.ui.dateTo.val().trim();
      if(dateFrom && !dateTo) {
        dateTo = dateUtils.toDateFormatString(new Date());
        this.ui.dateTo.val(dateTo).change();
      }
      if(this._isValid()) {
        this.trigger('apply', {
          dateFrom: dateFrom,
          dateTo: dateTo
        });
        this.$el.popover('hide');
      }
    },

    onClearInputs: function() {
      this.$el.focus();
      if(this._clear) {
        this.ui.dateFrom.val('').change();
        this.ui.dateTo.val('').change();
        this._clear = false;
      }
    },

    onShouldClear: function() {
      this._clear = true;
    },

    _getDatePickerOptions: function() {
      var dateFormat = dateUtils.getDateFormat();

      return {
        dateFormat: dateFormat,
        changeYear: true,
        constrainInput: true,
        showOn: 'both',
        buttonText: '<i class="mdi-action-event"></i>',
        prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
        nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
      };
    },

    _isValid: function() {
      var dateFrom = this.ui.dateFrom.val().trim(),
        dateTo = this.ui.dateTo.val().trim(),
        dateDiffMilisec = new Date(dateUtils.toMDYFormat(dateFrom)).getTime() -
          new Date(dateUtils.toMDYFormat(dateTo)).getTime();

      if(dateFrom && !dateTo) {
        this._showError(this.ui.dateTo);
        return false;
      } else if(!dateFrom && dateTo) {
        this._showError(this.ui.dateFrom);
        return false;
      } else if(dateDiffMilisec > 0) {
        this._showError(this.ui.dateTo);
        this._showError(this.ui.dateFrom);
        return false;
      }
      return true;
    },

    _showError: function($elem) {
      this.$el.focus();
      $elem.addClass('invalid').closest('.form-group').addClass('has-error');
    },

    _removeError: function() {
      this.$el.focus();
      this.ui.dateFrom.removeClass('invalid').closest('.form-group').removeClass('has-error');
      this.ui.dateTo.removeClass('invalid').closest('.form-group').removeClass('has-error');
    }
  });

  module.exports = DatePickerPopoverView;
});
