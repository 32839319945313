define('pimProductFilterItemView',[
  'module',
  'marionette',
  'underscore',
  'pimProductFilterItemView',
  'template!pimProductFilterItemTpl',
  'pimProductFilterValuesView'
], function (
  module,
  Marionette,
  _,
  PimProductFilterItemView,
  PimProductFilterItemTpl,
  PimProductFilterValuesView
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    RESULT_TO_SHOW: 2,
    template: PimProductFilterItemTpl,
    className: 'sa-item sa-item-col-3',

    ui: {
      btnShowMore: '.btn-show-more',
      btnShowLess: '.btn-show-less'
    },

    regions: {
      filterValuesRegion: '.values-region'
    },

    events: {
      'click @ui.btnShowMore': 'onClickShowMore',
      'click @ui.btnShowLess': 'onClickShowLess'
    },

    onClickShowMore: function () {
      _.each(this.model.get('values').models, function (value) {
        value.set('hide', false);
      });
      this.ui.btnShowMore.hide();
      this.ui.btnShowLess.show();
    },

    onClickShowLess: function () {
      this.hideAllExceptXFirst(this.RESULT_TO_SHOW);
      this.ui.btnShowMore.show();
      this.ui.btnShowLess.hide();
    },

    hideAllExceptXFirst: function (nbr) {
      _.each(this.model.get('values').models, function (value, index) {
        if (index >= nbr) {
          value.set('hide', true);
        }
      });
    },

    onShow: function () {
      this.hideAllExceptXFirst(this.RESULT_TO_SHOW);
      this.pimProductFilterValuesView = new PimProductFilterValuesView({
        collection: this.model.get('values')
      });
      this.getRegion('filterValuesRegion').show(this.pimProductFilterValuesView);
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.displayShowMore = this.model.get('values').models.length > this.RESULT_TO_SHOW;
      return templateData;
    }
  });
});
