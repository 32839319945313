/**
 * Created by Matteo on 11/07/2015.
 */
define('loginMain',[
  'module',
  'logger',
  'loginMainRouter'
], function(
  module,
  Logger
) {
  'use strict';

  var Login = {
    start: function() {
      Logger.log(module, 'starting LoginModule');
    },

    stop: function() {
      Logger.log(module, 'stopping LoginModule');
    }
  };

  module.exports = Login;
});
