
define('template!sharePrintAreaTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-0">\n        <div class="col-xs-6" ' +
((__t = ( newPa?'style="color: orange;font-weight: bold;"':'' )) == null ? '' : __t) +
' >\n            ';
 if (parentPrintArea) { ;
__p += '\n            <div class="col-xs-1 m-t--7 m-b--5">\n                <input class="cbx-printarea m-t-0" type="checkbox" style="width: 20px; height: 35px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n            </div>\n            ';
 } ;
__p += '\n            <div class="col-xs-2">' +
((__t = ( parentPrintArea?parentPrintArea.code:printArea.code )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-4">' +
((__t = ( newPa?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( checked?printArea.name:(parentPrintArea?parentPrintArea.name:'') )) == null ? '' : __t) +
' (' +
((__t = ( (width + "x" + height + unit) )) == null ? '' : __t) +
')</div>\n        </div>\n        <div class="share-printzones col-xs-6">\n\n        </div>\n    </div>\n    <hr class="col-xs-12 m-t-0 m-b-0"/>\n</div>';

}
return __p
};});

