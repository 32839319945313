
define('template!emailAttachmentTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="min-height: 200px; position: relative;">\n  <canvas class="pdf-canvas"></canvas>\n  <img class="image" style="height: 150px; max-width: 100%; object-fit: contain">\n  <div class="loading-block js-loading-block loader" style="top: 0px;">\n    <span class="loading-message js-loading-message label"></span>\n  </div>\n  ';
 if (!hideDelete) { ;
__p += '\n  <i class="mdi-action-delete icon js-remove-icon clickable" style="position: absolute; right: 0px;"></i>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

