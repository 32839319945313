define('productGridController',[
  'module',
  'productGridLayoutView'
], function(
  module,
  GridLayoutView
) {
  'use strict';

  var GridLayoutController = {
    showProductsGrid: function(region) {
      region.show(new GridLayoutView());
    }
  };

  module.exports = GridLayoutController;
});
