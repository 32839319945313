define('entities/admin/tasks',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'backboneRelational',
  'entities/product_translation',
  'entities/common'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Task = Backbone.Model.extend({
    defaults: {
      refCAccount: null,
      done: false,
      refCreator: null,
      comments: [],
      userpids: null,
      dateDue: null,
      refCommandLine: null,
      refPhoneCall: null,
      refEndUser: null,
      refEndUserContact: null,
      refProduct: null
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/tasks/' + this.get('secId'));
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/tasks');
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/tasks/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    /*save: function () {
      var defer = $.Deferred();
      this.url = this.postUrl();
      Backbone.Model.prototype.save.call(this).done(_.bind(function () {
        this.fetch();
        defer.resolve();
      }, this)).fail(_.bind(function () {
        this.fetch();
        defer.reject();
      }, this));

      return defer.promise();
    },*/

    save: function (data) {
      var defer = $.Deferred();

      var method = 'POST';
      if(this.get('secId')) {
        data.secId = this.get('secId');
        method = 'PUT';
      }

      $.ajax({
        method: method,
        url: Settings.url('compuzz2', 'v2/tasks/'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getRefCommandLine: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refCommandLine;
      } else {
        return this.get('refCommandLine');
      }
    },

    getRefEndUser: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refEndUser;
      } else {
        return this.get('refEndUser');
      }
    },

    getRefProduct: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refProduct;
      } else {
        return this.get('refProduct');
      }
    },

    idAttribute: 'secId'

    /*relations: [{
      type: Backbone.HasMany,
      key: 'comments',
      relatedModel: 'CommentModel',
      collectionType: 'TaskCommentsCollection'
    }]*/
  });

  /*var TaskCommentsCollection = Backbone.Collection.extend({
    model: app.ns.CommentModel,

    url: function () {
      return Settings.url('compuzz2', 'commands.json');
    },

    comparator: function (a, b) {
      if (a.get('msgDate') < b.get('msgDate')) return 1;
      else return -1;
    }
  });*/

  var TaskCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz2', 'v2/tasks');
    },
    model: app.ns.Task
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function (params) {
        return Settings.url('compuzz2', 'v2/tasks/rowCount', params);
    },

    getTasksUrl: function (params) {
        return Settings.url('compuzz2', 'v2/tasks', params);
    },

    getOpenTaskCount: function (notDone) {
      var defer = $.Deferred();
      var param = {
        forMe: true
      };
      if (notDone) {
        param.done = 'false';
        param.onlyDue = 'true';
      }
      $.ajax({
        url: Settings.url('compuzz2', 'v2/tasks/rowCount', param),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getOpenTasks: function (first, pageSize) {
      var openTasks = new TaskCollection({}),
        defer = $.Deferred(),
        params = {
          first: first,
          pageSize: pageSize,
          forMe: true
        };

      openTasks.url = Settings.url('compuzz2', 'v2/tasks/', params);
      openTasks.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    deleteTask: function (secId) {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'v2/tasks/' + secId);
      return $.ajax({
        method: 'DELETE',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
    }
  };

  App.reqres.setHandler('tasks:model', function (taskJSON) {
    var tt;
    if (taskJSON) {
      tt = Backbone.Relational.store.find(app.ns.Task, taskJSON.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Task(taskJSON);
    }
  });

  App.reqres.setHandler('tasks:row-count-url', function (params) {
    return API.getRowCountUrl(params);
  });

  App.reqres.setHandler('tasks:get-url', function (params) {
    return API.getTasksUrl(params);
  });

  App.reqres.setHandler('tasks:open:count', function () {
    return API.getOpenTaskCount();
  });

  App.reqres.setHandler('tasks:delete', function (secId) {
    return API.deleteTask(secId);
  });

  App.reqres.setHandler('tasks:open:count:notdone', function () {
    return API.getOpenTaskCount(true);
  });

  App.reqres.setHandler('tasks:open:entities', function (first = 0, pageSize = 10) {
    return API.getOpenTasks(first, pageSize);
  });

});
