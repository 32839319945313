define('webshopProductsListCategoryView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'template!webshopProductsListCategoryView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-category-view ' + FieldView.prototype.className,

    ui: {
      input: 'input'
    },

    events: {
        'change @ui.input': 'onChange'
    },

    modelEvents: {
      'change:defaultCategory': 'render'
    },

    onRender: function() {
      if (this.model.getDefaultCategory()) {
        App.request('webshop.categories.datalist').done(_.bind(function (categories) {
          _.each(categories, _.bind(function (category) {
            if (category.getId() === this.model.getDefaultCategory())
              this.ui.input.val(category.getCode());
              this.ui.input.attr('title', category.getName());
          }, this));
        }, this));
      }
    },

    onChange: function () {
      var id = '';
      App.request('webshop.categories.datalist').done(_.bind(function (categories) {
        _.each(categories, _.bind(function (category) {
          if (category.getCode() === this.ui.input.val())
            id = category.getId();
        }, this));
      }, this));

      this.model.setDefaultCategory(id).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    }
  });
});
