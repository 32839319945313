
define('template!pimProductImprintPricesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var sizePercent = 100 / (currentPzPrice.length + 1); ;
__p += '\n';
 if (!customPrice) { ;
__p += '\n    ';
 _.each(currentPzPrice, function(impt, index) { ;
__p += '\n        <div class="col-xs-1"  style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n            <div class="price">\n                <div class="col">\n                    <span>' +
((__t = ( Number(impt.imptUnitUnitPrice).toFixed(2) )) == null ? '' : __t) +
'</span>\n                </div>\n                ';
 if (!useFactorV2) { ;
__p += '\n                <div class="col">\n                    <input type="text" class="txt-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.imptUnitUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( impt.imptUnitUnitPriceFactor )) == null ? '' : __t) +
'" data-index="' +
((__t = ( index)) == null ? '' : __t) +
'" style="display: none;" value="' +
((__t = ( impt.imptUnitUnitPriceSale )) == null ? '' : __t) +
'" />\n                    <span class="lbl-price" data-index="' +
((__t = ( index)) == null ? '' : __t) +
'">' +
((__t = ( Number(impt.imptUnitUnitPriceSale).toFixed(2) )) == null ? '' : __t) +
'</span>\n                </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    ';
 }); ;
__p += '\n';
 if (!useFactorV2) { ;
__p += '\n    <div class="col-xs-1 buttons" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n        <select class="select-print-factor-code">\n            <option value=" "></option>\n            ';
 _.each(printFactors, function(pf) { ;
__p += '             \n                <option value="' +
((__t = ( pf.printCode )) == null ? '' : __t) +
'" ' +
((__t = ( pf.printCode===selectedFactor?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( pf.printCode )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n        </select>\n        ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n            <i class="mdi-navigation-refresh btn-reset clickable" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'"></i>\n        ';
 } ;
__p += '\n    </div>\n';
 } ;
__p += '\n';
 }  else { ;
__p += '\n    ';
 _.each(currentPzPrice, function(impt, index) { ;
__p += '\n        <div class="col-xs-1 prices-editable" style="width: ' +
((__t = ( (sizePercent / 2) )) == null ? '' : __t) +
'%;">\n            <input type="text" class="txt-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" data-factor="1" data-index="' +
((__t = ( index)) == null ? '' : __t) +
'" style="display: none;;" value="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" />\n            <span class="lbl-price" data-index="' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( impt.price )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="col-xs-1 prices-editable" style="width: ' +
((__t = ( (sizePercent / 2) )) == null ? '' : __t) +
'%;">\n            <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( impt.secId )) == null ? '' : __t) +
'" data-quantity="' +
((__t = ( impt.quantity )) == null ? '' : __t) +
'" data-price="' +
((__t = ( impt.price )) == null ? '' : __t) +
'" data-factor="1" data-index="' +
((__t = ( index + 2 )) == null ? '' : __t) +
'" style="display: none;" value="' +
((__t = ( Number(impt.price * impt.factor).toFixed(2))) == null ? '' : __t) +
'" />\n            <span class="lbl-factorized-price" data-index="' +
((__t = ( index)) == null ? '' : __t) +
'">' +
((__t = ( Number(impt.price * impt.factor).toFixed(2))) == null ? '' : __t) +
'</span>\n        </div>\n    ';
 }); ;
__p += '\n    ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n        <i class="mdi-navigation-refresh btn-reset clickable" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'"></i>\n    ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
};});

