define('entities/pim_product_search_filters',[
  'app',
  'settings',
  'backbone',
  'backboneRelational'
], function (
  App,
  Settings,
  Backbone
) {
  'use strict';
  var app = {ns: {}};
  Backbone.Relational.store.addModelScope(app.ns);

  app.ns.PimProductSearchFilter = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'values',
      relatedModel: 'PimSearchFilterValue',
      collectionType: 'PimSearchFilterValueCollection'
    }],
    idAttribute: 'id'
  });

  app.ns.PimSearchFilterValue = Backbone.RelationalModel.extend({
    defaults: {
      selected: false
    },
    idAttribute: 'id'
  });

  app.ns.PimSearchFilterValueCollection = Backbone.Collection.extend({
    model: 'PimSearchFilterValue',
    comparator: 'value'
  });

  app.ns.PimProductSearchFilterCollection = Backbone.Collection.extend({
    model: app.ns.PimProductSearchFilter,

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/filters');
      return Backbone.Collection.prototype.fetch.call(this);
    }
  });

  App.reqres.setHandler('products-search-filter:collection', function () {
    return new app.ns.PimProductSearchFilterCollection();
  });
});
