(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        plugins: {
            mention: {
                init: function (trumbowyg) {
                    this.trumbowyg = trumbowyg;
                    this.range = null;
                    this.caretPos = 0;
                    this.popupHighlightedIndex = 0;
                    this.users = [];
                    this.groups = [];
                    this.userInput = '';

                    if ($('#mentions-view').length === 0) {
                        var html = '<div id="mentions-view">';
                        html += '<div class="items"></div>';
                        html += '</div>';

                        $('body').append(html);
                        this.popupEl = $('#mentions-view');
                    }

                    this.setEvents(this);

                    $(document).on('click', _.bind(function(e) {
                        if (!$(e.target).closest('#mentions-view').length) {
                            this.closePopup();
                        }
                    }, this));

                    this.windowKeydownHandler = _.bind(this.keydownHandler, this);
                    $(document).on('keydown', this.windowKeydownHandler);

                    require([
                        'events'
                    ], _.bind(function (Events) {
                        Events.push(this);
                    }, this));
                },

                destroy: function () {
                    $(document).off('keydown', this.windowKeydownHandler);

                    require([
                        'events'
                    ], _.bind(function (Events) {
                        Events.pop(this);
                    }, this));
                },

                setEvents: function(mention) {
                    $(function()
                    {
                        mention.trumbowyg.$ta.on('tbwchange', _.bind(function() {
                            if(!mention.trumbowyg.o.plugins.mention)
                                return;

                            var sel = window.getSelection();
                            if (sel.rangeCount > 0) {
                                mention.range = sel.getRangeAt(0);
                                mention.caretPos = mention.range.endOffset;

                                var content = '';
                                if (mention.range.endContainer.wholeText)
                                    content = mention.range.endContainer.wholeText;
                                // Extraire le texte à partir du début jusqu'à la position du curseur
                                var preText = content.substring(0, mention.caretPos);

                                let startPos = Math.max(0, mention.caretPos - 100); // Ajuster la longueur si nécessaire
                                let partText = preText.substring(startPos);
                                let selectedWords = partText.match(/(^|\s)@[^@\s]*/g);
                                mention.userInput = '';

                                if (selectedWords) {
                                    mention.userInput = selectedWords[selectedWords.length - 1].trim();
                                }


                                if(mention.popupEl.is(':visible')) {
                                    mention.setUserList(
                                        mention.userInput.replace('@', '').trim().toLowerCase());
                                } else {
                                    if(mention.userInput !== '') {
                                        mention.setUserList();

                                        var selection = window.getSelection();
                                        var range = selection.getRangeAt(0);
                                        var rect = range.getBoundingClientRect();

                                        mention.popupEl.css({top: rect.top + 20, left: rect.left});
                                        mention.popupEl.show();

                                        mention.setPopupSelectedUser();
                                    }
                                }
                            }

                        }, this));
                    });
                },

                setUserList: function(filter) {
                    require([
                        'app'
                    ], _.bind(function (App) {
                        this.popupEl.find('.items').empty();

                        var getUsers = App.request('kanban-tickets:get-users');
                        var getUserGroups = App.request('kanban-tickets:get-user-groups');

                        $.when(getUsers, getUserGroups).done(_.bind(function(users, groups) {
                            this.users = users;
                            this.groups = groups;

                            users.sort(function(a, b) {
                                var nameA = (a.firstName.trim() + ' ' + a.lastName.trim()).toLowerCase();
                                var nameB = (b.firstName.trim() + ' ' + b.lastName.trim()).toLowerCase();
                                return nameA.localeCompare(nameB);
                            });

                            this.popupEl.find('.items').empty();

                            _.each(groups, _.bind(function(group) {

                                var html = '<div class="item" data-type="group" data-id="'+ group.secId +'" ';
                                html += 'data-name="'+ group.name.trim() +'">';
                                html += '<div class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg></div>'; // jshint ignore:line
                                html += '<div class="text">'+ group.name.trim() +'</div>';
                                html += '</div>';

                                if(!filter) {
                                    this.popupEl.find('.items').append(html);
                                } else {
                                    var groupName = group.name.trim().toLowerCase();
                                    if(groupName.includes(filter))
                                        this.popupEl.find('.items').append(html);
                                }
                            }, this));

                            _.each(users, _.bind(function(user) {
                                var html = '<div class="item" data-type="user" data-id="'+ user.secId +'" ';
                                html += 'data-name="'+ user.firstName.trim() + ' ' + user.lastName.trim() +'">';
                                html += '<div class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg></div>'; // jshint ignore:line
                                html += '<div class="text">'+ user.firstName.trim() + ' ' + user.lastName.trim() +
                                    '</div>';
                                html += '</div>';

                                if(!filter) {
                                    this.popupEl.find('.items').append(html);
                                } else {
                                    var userName = (user.firstName.trim() + ' ' +
                                        user.lastName.trim()).toLowerCase();
                                    if(userName.includes(filter))
                                        this.popupEl.find('.items').append(html);
                                }
                            }, this));

                            this.popupHighlightedIndex = 0;
                            this.setPopupSelectedUser();

                            this.popupEl.find('.item').on('click', _.bind(this.itemSelected, this));
                        }, this));
                    }, this));
                },

                itemSelected: function(e) {
                    e.stopPropagation();
                    var item = $(e.target).closest('.item');
                    var secId = item.attr('data-id');
                    var name = item.attr('data-name');
                    var type = item.attr('data-type');
                    this.insertItem(secId, name, type);
                },

                insertItem: function(secId, name, type) {

                    var popupEl = $('#mentions-view');
                    popupEl.hide();

                    var textarea = $('.trumbowyg-editor');

                    if (this.range) {
                        if(this.range.endContainer.wholeText) {
                            var text = this.range.endContainer.wholeText;
                            text = this.removerSpecialChars (text,this.caretPos);
                            this.range.endContainer.textContent = text; this.caretPos -= this.userInput.length;
                        }

                        var range = document.createRange();
                        var sel = window.getSelection();
                        range.selectNodeContents(textarea.get(0));
                        range.setEnd(this.range.endContainer, this.caretPos);
                        range.collapse(false);
                        sel.removeAllRanges();
                        sel.addRange(range);

                        var html = '';

                        if(type === 'group') {
                            html = '&nbsp;<group data-id="'+ secId +'">';
                            html += '@' + name;
                            html += '</group>';
                            html += '&nbsp;';
                        } else {
                            html = '&nbsp;<user data-id="'+ secId +'">';
                            html += '@' + name;
                            html += '</user>';
                            html += '&nbsp;';
                        }

                        this.trumbowyg.execCmd('insertHTML', html);
                        textarea.focus().select();
                    }
                },

                removerSpecialChars: function(str,carPos) {

                    if (str.startsWith('@')) {
                        str = str.slice(1);
                    }
                    if (str.endsWith('@')) {
                        str = str.slice(0, -1);
                    }
                    // Vérifier que carPos est dans les limites de la chaîne
                    if (carPos >= 0 && carPos < str.length) {
                        // Obtenir le caractère à la position carPos
                        let charAtPos = str.charAt(carPos-1);
                        if (charAtPos === '@') {
                            str = str.slice(0, carPos - 1) + str.slice(carPos);
                        }
                    }

                   return str;
                },

                keydownHandler: function(e) {
                    if (e.keyCode === 27) {
                        e.stopPropagation();

                        return;
                    }

                    if (e.keyCode === 13 || e.keyCode === 9) {
                        if(this.popupEl.is(':visible')) {
                            e.preventDefault();
                            e.stopPropagation();

                            var item = this.popupEl.find('.items > .item')[this.popupHighlightedIndex];
                            var secId = item.getAttribute('data-id');
                            var type = item.getAttribute('data-type');

                            var item = null;
                            var name = '';
                            if(type === 'group') {
                                _.each(this.groups, function (g) {
                                    if (g.secId === secId) {
                                        name = g.name;
                                        item = g;
                                    }
                                }, this);
                            } else {
                                _.each(this.users, function (u) {
                                    if (u.secId === secId) {
                                        name = u.firstName.trim() + ' ' + u.lastName.trim();
                                        item = u;
                                    }
                                }, this);
                            }

                            if(item)
                                this.insertItem(item.secId, name, type);
                        }

                        this.closePopup();
                    }

                    if(this.popupEl.is(':visible')) {
                        if (e.key === 'ArrowUp') {
                            e.preventDefault();
                            this.popupHighlightedIndex -= 1;
                            if (this.popupHighlightedIndex < 0)
                                this.popupHighlightedIndex = 0;
                        } else if (e.key === 'ArrowDown') {
                            e.preventDefault();
                            this.popupHighlightedIndex += 1;
                            if (this.popupHighlightedIndex >= this.popupEl.find('.items > .item').length)
                                this.popupHighlightedIndex = this.popupEl.find('.items > .item').length - 1;
                        }

                        this.setPopupSelectedUser();
                    }
                },

                setPopupSelectedUser: function() {
                    if(this.popupEl.find('.items > .item').length === 0)
                        return;

                    var secId = this.popupEl.find('.items')[0].children[this.popupHighlightedIndex].getAttribute('data-id');

                    this.popupEl.find('.item').removeClass('highlighted');
                    this.popupEl.find('.item[data-id="'+ secId +'"]').addClass('highlighted');
                    this.popupEl.find('.items').scrollTop(this.popupHighlightedIndex * 26);
                },

                closePopup: function() {
                    this.popupEl.hide();
                    //$(document).off('keydown', this.keydownHandler);
                },

                onKeyDown: function(e) {
                    if (e.which === 27 && this.popupEl.is(':visible')) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.closePopup();
                        return false;
                    }

                    if (e.which === 13) {
                        return false;
                    }
                }
            }
        }
    });
})(jQuery);

define("trumbowygMention", ["jquery","trumbowyg"], function(){});

