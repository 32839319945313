
define('template!adminNotificationsModificationView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(modificationTitle !== '') { ;
__p += '\n        <div class="title">' +
((__t = ( modificationTitle )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';
 if(modificationValue !== '') { ;
__p += '\n    <div class="value">' +
((__t = ( modificationValue )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
};});

