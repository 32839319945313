
define('template!emptyDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="no-items">\n\t<p>\n\t\t' +
((__t = ( _.i18n("common.noItem") )) == null ? '' : __t) +
'\n\t</p>\n</div>';

}
return __p
};});

