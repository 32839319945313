define('entities/superadmin',[
  'app',
  'jquery',
  'settings'
], function(
  App,
  $,
  Settings
) {
  'use strict';
  /**
   * REST API
   */
  var API = {
    getAccountRowCount: function() {
      var defer = $.Deferred(),
      params = {
        first: '0',
        pageSize: '0'
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'v2/stats/caccounts/rowcount', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCreditstRowCount: function() {
      var defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'credits.json/count/rowcount'),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getAccountStatistic: function(paging) {
      var defer = $.Deferred(),
      params = {
        first: paging ? paging.first : 0,
        pageSize: 10
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'v2/stats/caccounts', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCreditStatistic: function(paging) {
      var defer = $.Deferred(),
      params = {
        first: paging ? paging.first : 0,
        pageSize: 10
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'credits.json/count', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCreditGraph: function(filters) {
      var defer = $.Deferred(),
      params = {
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        periodType: filters.type
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'v2/stats/timelaps/credits', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getAccountGraph: function(filters) {
      var defer = $.Deferred(),
      params = {
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        periodType: filters.type
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'v2/stats/timelaps/caccounts', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getTicketsGraph: function(filters) {
      var defer = $.Deferred(),
      params = {
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        periodType: filters.type,
        states: filters.states
      };

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'v2/stats/timelaps/tickets', params),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('superadmin:account-rowcount', function() {
    return API.getAccountRowCount();
  });
  App.reqres.setHandler('superadmin:credits-rowcount', function() {
    return API.getCreditstRowCount();
  });
  App.reqres.setHandler('superadmin:stats-account', function(paging) {
    return API.getAccountStatistic(paging);
  });
  App.reqres.setHandler('superadmin:stats-credit', function(paging) {
    return API.getCreditStatistic(paging);
  });
  App.reqres.setHandler('superadmin:stats-credit-graph', function(filters) {
    return API.getCreditGraph(filters);
  });
  App.reqres.setHandler('superadmin:stats-account-graph', function(filters) {
    return API.getAccountGraph(filters);
  });
  App.reqres.setHandler('superadmin:stats-tickets-graph', function(filters) {
    return API.getTicketsGraph(filters);
  });
});
