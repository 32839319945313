
define('template!ordersListFiltersEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="body">\n    <div class="line">\n        ';
 if(endUserId === '') { ;
__p += '\n            <div class="end-user item">\n                <div class="libelle">' +
((__t = ( _.i18n('orderList.endUser') )) == null ? '' : __t) +
'</div>\n                <input type="text" name="endUserId" tabindex="1">\n            </div>\n        ';
 } ;
__p += '\n        <div class="purchaseOrderId item">\n            <div class="libelle">' +
((__t = ( type === 'command' ? _.i18n('orderList.orderNumber') : _.i18n('orderList.offerNumber') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="purchaseOrderId" tabindex="2">\n        </div>\n    </div>\n    <div class="line">\n        <div class="product-code item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.productCode') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productCode" tabindex="3">\n        </div>\n        <div class="product-owner item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.productOwner') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productOwner" tabindex="4">\n        </div>\n    </div>\n    <div class="line">\n        <div class="company-name item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="companyName" tabindex="5">\n        </div>\n        <div class="status item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.status') )) == null ? '' : __t) +
'</div>\n            <div class="statuses-region region"></div>\n        </div>\n    </div>\n    <div class="line">\n        <div class="create-date item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.orderCreatDate') )) == null ? '' : __t) +
'</div>\n            <div class="dates">\n                <input type="date" class="date-from" name="orderCreateDateStart" tabindex="7"/>\n                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"/></svg>\n                <input type="date" class="date-to" name="orderCreateDateEnd" tabindex="8"/>\n            </div>\n        </div>\n    </div>\n    <div class="line">\n        <div class="quantity item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</div>\n            <div class="quantities">\n                <input class="quantity-from" type="number" name="quantityFrom" placeholder="' +
((__t = ( _.i18n('common.from') )) == null ? '' : __t) +
'" tabindex="9">\n                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"/></svg>\n                <input class="quantity-to" type="number" name="quantityTo" placeholder="' +
((__t = ( _.i18n('common.to') )) == null ? '' : __t) +
'" tabindex="10">\n            </div>\n        </div>\n    </div>\n    <div class="line">\n        <div class="price item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.price') )) == null ? '' : __t) +
'</div>\n            <div class="prices">\n                <input class="price-from" type="number" name="priceFrom" placeholder="' +
((__t = ( _.i18n('common.from') )) == null ? '' : __t) +
'" tabindex="11">\n                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"/></svg>\n                <input class="price-to" type="number" name="priceFo" placeholder="' +
((__t = ( _.i18n('common.to') )) == null ? '' : __t) +
'" tabindex="12">\n            </div>\n        </div>\n    </div>\n    <div class="line">\n        <div class="payment item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.payedByCustomer') )) == null ? '' : __t) +
'</div>\n            <div class="payment-region region"></div>\n        </div>\n        <div class="invoice item">\n            <div class="libelle">' +
((__t = ( _.i18n('orders.filters.toInvoice') )) == null ? '' : __t) +
'</div>\n            <div class="invoice-region region"></div>\n        </div>\n        <div class="validated item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.validated') )) == null ? '' : __t) +
'</div>\n            <div class="validated-region region"></div>\n        </div>\n    </div>\n    <div class="line">\n        <div class="action-required-internal item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.actionRequiredInternal') )) == null ? '' : __t) +
'</div>\n            <div class="action-required-internal-region region"></div>\n        </div>\n        <div class="action-required-ukr item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.actionRequiredUkr') )) == null ? '' : __t) +
'</div>\n            <div class="action-required-ukr-region region"></div>\n        </div>\n    </div>\n    <div class="line">\n      <div class="webshop item">\n          <div class="libelle">' +
((__t = ( _.i18n('common.webshop') )) == null ? '' : __t) +
'</div>\n          <div class="webshop-region region"></div>\n      </div>\n    </div>\n    <div class="line">\n      <div class="only-assigned-to-me-region"></div>\n      <div class="vip-region"></div>\n      <div class="only-not-assigned-to-me-region" style="display:none"></div>\n      <div class="only-not-assigned-region" style="display:none"></div>\n    </div>\n    <div class="labels item">\n        <div class="libelle">' +
((__t = ( _.i18n("orders.labels") )) == null ? '' : __t) +
'</div>\n        <div class="labels-region"></div>\n    </div>\n    <div class="labels-to-exclude item" style="display:none">\n        <div class="libelle">' +
((__t = ( _.i18n("orders.labels-to-exclude") )) == null ? '' : __t) +
'</div>\n        <div class="labels-to-exclude-region"></div>\n    </div>\n</div>\n<div class="footer">\n    <div class="cancel-region"></div>\n    <div class="reset-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

