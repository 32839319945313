
define('template!pimProductFilterValueItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-2 Code"><input type="checkbox" class="cbx-select" ' +
((__t = ( selected?'checked':'')) == null ? '' : __t) +
'></div>\n  <div class="col-xs-10 Code">' +
((__t = ( value )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

