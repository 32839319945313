/**
 * Created by RKL on 13/08/2015. 
 */
define('caccountCreditsTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'caccountCreditsTabView',
  'caccountAddCreditsPopupView',
  'caccountAddCreditsPopupReturnView',
  'entities/caccounts/credits',
  'settings',
  'caccountCreditsAccountFormPopupView'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  AddPopupView,
  AddPopupReturnView,
  Credits,
  Settings,
  AccountFormPopupView
) {
  'use strict';

  var CAccountsCreditsTabController = {
    showTab: function(data) {
      var view = new View(),
        balanceRequest = App.request('caccount-credits:get-balance');

      view.listenTo(view, 'onAddCredits', _.bind(this.onAddCredits, this));

      this.view = view;
      $.when(balanceRequest).done(function(balance) {
        view.options.balance = balance;
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);
      });

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));

      var currentRoute = App.getCurrentRoute();
      if(currentRoute.indexOf('payment') > 0){
           this.onPlacePayment(data);
      }

      if (data.secId) {
          this.validateStripe(data.secId);
      }
      App.off('vectorization:open-add-credit-popup');
      App.on('vectorization:open-add-credit-popup', _.bind(this.onAddCredits, this));
    },

    validateStripe: function(creditUid) {
        var sate = 'progress';
        var resultPopup = new AddPopupReturnView({type : sate});

        resultPopup.listenTo(resultPopup, 'before:destroy',
            _.bind(this.refreshGrid, this));

        resultPopup.show({
            title: _.i18n('credit.paypal.popup.title'),
            buttons: {
                main: {
                    label:  _.i18n('common.ok'),
                    className: 'btn-primary btn-validate-refill'
                }
            }
        });

        App.request('caccount-credits:placeStipe', creditUid,
            this.getParameterByName('client_secret', window.location.href),
            this.getParameterByName('source', window.location.href)).done(_.bind(function() {
          resultPopup.type = 'success';
          resultPopup.render();
        }, this)).fail(function() {
            resultPopup.type = 'cancel';
            resultPopup.render();
        });
    },

    errorStripe: function(errorMessage) {
      var sate = 'error';
      var resultPopup = new AddPopupReturnView({type : sate, errorMessage: errorMessage});

      resultPopup.listenTo(resultPopup, 'before:destroy',
          _.bind(this.refreshGrid, this));

      resultPopup.show({
          title: _.i18n('credit.paypal.popup.title'),
          buttons: {
              main: {
                  label:  _.i18n('common.ok'),
                  className: 'btn-primary btn-validate-refill'
              }
          }
      });
    },

    successStripe: function() {
        var sate = 'success';
        var resultPopup = new AddPopupReturnView({type : sate});

        resultPopup.listenTo(resultPopup, 'before:destroy',
            _.bind(this.refreshGrid, this));

        resultPopup.show({
            title: _.i18n('credit.paypal.popup.title'),
            buttons: {
                main: {
                    label:  _.i18n('common.ok'),
                    className: 'btn-primary btn-validate-refill'
                }
            }
        });
    },

    onAddCredits: function() {
      var that = this;

      if (!Settings.get('countries')) {
        App.request('caccount:countries').done(function(countries) {
          Settings.set('countries', countries.toJSON());
          that.showAccountFormPopup();
        });
      } else {
        this.showAccountFormPopup();
      }
    },

    showAccountFormPopup: function() {
      var ca = Settings.get('currentCAccount'),
      accountFormPopupView = new AccountFormPopupView({
        model: App.request('caccount:model', ca),
        countries: Settings.get('countries')
      });
        accountFormPopupView.listenTo(accountFormPopupView, 'saved', _.bind(this.onAccountSaved, this));
        accountFormPopupView.listenTo(accountFormPopupView, 'error', _.bind(this.onAccountSaveError, this));
      accountFormPopupView.show({
          title: _.i18n('account.profile.billingInfo'),
          className: 'billingConfirm'});

      this.accountFormPopupView = accountFormPopupView;
    },

    onAccountSaved: function(model) {
      var account = model.toJSON();

      Settings.set('currentCAccountCookie', JSON.stringify(account));
      Settings.set('currentCAccount', account);
      App.trigger('header:update-caccount');
      App.trigger('caccount:header:rerender', account);
      this.showAddCreditsPopup();
    },

    onAccountSaveError: function(error) {
        if(error.status === 406 && error.responseJSON){
            this.accountFormPopupView.onSaveError(error.responseJSON);
        }
    },

    showAddCreditsPopup: function() {
      var that = this,
        model = App.request('caccount-credits:get-model');

      that.popupView = new AddPopupView({model: model});
      that.popupView.listenTo(that.popupView, 'creditpopup:choose-paypal', _.bind(that.choosePayPalMethod, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:choose-stripe', _.bind(that.chooseStripeMethod, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:choose-banktransfer', _.bind(that.chooseBankMethod, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:apply-banktransfer', _.bind(that.applyBankMethod, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:displayloading', _.bind(that.displayLoading, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:success-stripe', _.bind(that.successStripe, that));
      that.popupView.listenTo(that.popupView, 'creditpopup:error-stripe', _.bind(that.errorStripe, that));

      that.popupView.show({title: _.i18n('credit.popup.title'),
          className: 'add-credits-popup',
          buttons: {
              cancel: {
                  label: _.i18n('common.cancel'),
                  className: 'btn-default',
                  callback: function() { return true; }
              },
              main: {
                  label: '<i class="fa fa-paypal"></i> ' +
                      _.i18n('credit.paypal.confirm'),
                  className: 'btn-primary btn-paypal',
                  callback: function() {
                      that.onShowMessage();
                      that.onPayWithPaypal(that.popupView.model);
                      return false;
                  }
              }
          }
      });
    },

    applyBankMethod: function() {
      this.popupView.hide();
    },

    chooseStripeMethod: function() {
      this.popupView.resetOptions();
      this.popupView.options.stripe = true;
      this.popupView.render();
    },

    choosePayPalMethod: function() {
      this.popupView.resetOptions();
      this.popupView.options.paypal = true;
      this.popupView.render();
    },

    chooseBankMethod: function() {
        this.popupView.resetOptions();
      this.popupView.options.bankTransfer = true;
      this.popupView.render();
    },

    displayLoading: function() {
        this.popupView.resetOptions();
        this.popupView.options.loading = true;
        this.popupView.render();
    },

    onPayWithPaypal: function(data){
        var postRequest = App.request('caccount-credits:refund-paypal', data);

        $.when(postRequest).done(function(data) {
            window.location.replace(data);
        });
    },

    onShowMessage: function() {
      this.popupView.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));
    },

    getParameterByName: function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, '' ));
    },

    onPlacePayment: function(data){
        var sate = data.state.indexOf('success') > -1 ? 'progress' : data.state;
        var resultPopup = new AddPopupReturnView({type : sate});

        //data.state = sate;
        if(!(data.creditId)){
            data.creditId = this.getParameterByName('credit', window.location.href);
        }
        if(!(data.payerId)){
            data.payerId = this.getParameterByName('PayerID', window.location.href);
        }
        if(!(data.paymentId)){
            data.paymentId = this.getParameterByName('paymentId', window.location.href);
        }
        if(!(data.paypalTokenId)){
            data.paypalTokenId = this.getParameterByName('token', window.location.href);
        }

        resultPopup.listenTo(resultPopup, 'before:destroy', 
		_.bind(this.refreshGrid, this));

        resultPopup.show({
            title: _.i18n('credit.paypal.popup.title'),
            buttons: {
                main: {
                    label:  _.i18n('common.ok'),
                    className: 'btn-primary btn-validate-refill'
                }
            }
        });

        var postRequest = App.request('caccount-credits:place-paypal', data);

        $.when(postRequest).done(_.bind(function(data) {
            resultPopup.type = 'success';
            resultPopup.render(data);
        }, this));
    },

      refreshGrid: function(){
          var ca = Settings.get('currentCAccount');
          App.navigate('/caccount/'+ ca.secId, {trigger: false});
          App.navigate('/caccount/'+ ca.secId+'/credits', {trigger: true});
      },

    onGridLoaded:function() {
      var data = {
          url: App.request('caccount-credits:get-url'),
          rowCountUrl: App.request('caccount-credits:row-count-url')
        };
        
      this.view.displayGrid(data, true);
    }
  };

  module.exports = CAccountsCreditsTabController;
});
