
define('template!ordersDetailOrderProductOptionsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="libelle">\n  <div class="text">' +
((__t = ( _.i18n('common.options') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="options-container"></div>';

}
return __p
};});

