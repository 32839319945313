
define('template!rewardConditionsPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n    <p>\n        ' +
((__t = ( _.i18n('rewardConditions').replace(/[\r\n]/g, '<br />') )) == null ? '' : __t) +
'\n    </p>\n</div>';

}
return __p
};});

