define('galleryImageView',[
  'module',
  'marionette',
  'underscore',
  'template!galleryImageView'
], function(
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({    
    className: 'image-view',
    template: Tpl, 

    ui: {    
      image: 'img'
    },

    onShow: function() {
      this.ui.image.attr('src', this.model.get('url'));
    },

    onRender: function() {
      this.ui.image.on('mousedown', function(event) {
        event.preventDefault();
      });
    },

    show: function() {
      this.$el.css('display', 'flex');
    },

    hide: function() {
      this.$el.hide();
    }
  });   
});
