define('tableFieldTextEditView',[
  'module',
  'underscore',
  'tableFieldTextEditEditView',
  'tableFieldView'
], function (
  Module,
  _,
  TableFieldTextEditEditView,
  FieldView
) {
  'use strict';

  Module.exports = FieldView.extend({
    className: 'table-field-text-edit-view table-field-view',

    events: {
      'dblclick': 'onEdit'
    },

    initialize: function () {
      this.edit = false;
      this.tableView = this.options.tableView;
      this.rowView = this.options.rowView;
      this.field = this.options.field;
    },

    onDestroy: function() {
      $(document).off('keyup', this.keyUpBind);
    },

    onRender: function() {
      this.renderContent();
    },

    onBeforeRender: function() {
      if(this.field.className)
        this.$el.addClass(this.field.className);

      this.keyUpBind = _.bind(function(e) {
        if (e.which === 27) this.onCloseEdit();
      }, this);

      $(document).on('keyup', this.keyUpBind);

      this.clickBind = _.bind(function(e) {
        if (!this.el.contains(e.target))
          this.onCloseEdit();
      }, this);
      $(document).on('click', this.clickBind);
    },

    renderContent: function() {
      try {
        if(this.edit) {
          this.editView = new TableFieldTextEditEditView({
            model: this.model,
            field: this.field,
            view: this
          });
          this.editView.render();
          this.$el.html(this.editView.el);
          this.$el.addClass('edit');
          this.editView.$el.focus();
          //this.editView.$el.select();
        } else {
          this.$el.html(this.getValue());
          this.$el.attr('title', this.getValue());
          this.$el.removeClass('edit');
        }
      } catch(e) {
        console.log(e);
      }
    },

    getValue: function() {
      if(this.field.bind)
        if(this.model.get(this.field.bind))
          return this.model.get(this.field.bind);
      return '';
    },

    setValue: function(value) {
      var defer = $.Deferred();

      if(this.field.bind)
        this.model.set(this.field.bind, value);

      defer.resolve();
      return defer.promise();
    },

    onEdit: function() {
      if(this.edit)
        return;

      this.edit = true;
      this.renderContent();
    },

    onCloseEdit: function() {
      if(!this.edit)
        return;

      this.edit = false;
      this.setValue(this.editView.getValue()).done(_.bind(function() {
        this.renderContent();
      }, this)).fail(_.bind(function() {
        this.renderContent();
      }, this));
    }
  });
});
