define('adminNotificationsInfosView',[
  'app',
  'module',
  'underscore',
  'settings',
  'dateUtils',
  'tableFieldView',
  'template!adminNotificationsInfosView'
], function (
  App,
  Module,  
  _,
  Settings,
  DateUtils,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-notifications-infos-view table-field-view',

    ui: {

    },

    parseClientName: function(model) {
      var properties = Settings.configValue('board.card.mainTitleProperty');
      var clientName = '';
      _.each(properties, function (property) {
        if (model[property] && clientName === '')
          clientName = model[property];
      }, this);
      if (clientName === '')
        clientName = model.endUserId;
      return clientName;
    },

    serializeData: function () {

      var data = {};

      var html = '';
      var price = '';
      var numberLocale = '';
      var configNumberLocale = '';

      data.type = '';
      data.user = this.model.get('content').data.endUserId;
      data.tasktype = '';

      if (this.model.get('content').type === 'CUSTOMER_COMMENT_TAGGED') {
        data.type = 'customer';
        data.user = this.parseClientName(this.model.get('content').data.refEndUser);
      }

      if (this.model.get('content').type === 'ORDER_STATUS_CHANGED' ||
          this.model.get('content').type === 'ORDER_ASSIGNED' ||
          this.model.get('content').type === 'ORDER_VALIDATED' ||
          this.model.get('content').type === 'ORDER_INVALIDATED' ||
          this.model.get('content').type === 'ORDER_NEW_COMMENT' ||
          this.model.get('content').type === 'ORDER_COMMENT_TAGGED' ||
          this.model.get('content').type === 'ORDER_NEW_PAYMENT' ||
          this.model.get('content').type === 'ORDER_COMMENT_MODIFIED_TAGGED') {

        data.type = 'order';

        //------------

        data.sameOrderCount = 0;
        if(this.model.get('content').data.nbrOrderSameCustomer)
          data.sameOrderCount = this.model.get('content').data.nbrOrderSameCustomer;

        //------------

        html = this.model.get('content').data.poi;
        price = '';

        numberLocale = 'fr-BE';
        configNumberLocale = Settings.configValue('priceLocale');
        if (configNumberLocale)
          numberLocale = configNumberLocale;

        if(this.model.get('content').data.price) {
          price += '€' + new Intl.NumberFormat(numberLocale).format(this.model.get('content').data.price);
        }

        if(this.model.get('content').data.quantity) {
          if(price !== '')
            price += ' - ';

          price += new Intl.NumberFormat(numberLocale).format(
              this.model.get('content').data.quantity) + ' ' + _.i18n('common.pcs');
        }

        if(price !== '') {
          if(html !== '')
            html += ' | ';
          html += price;
        }

        data.line2 = html;
      }

      //------------

      if (this.model.get('content').type === 'TASK_NEW' ||
          this.model.get('content').type === 'TASK_NEW_COMMENT' ||
          this.model.get('content').type === 'TASK_COMMENT_MODIFIED' ||
          this.model.get('content').type === 'TASK_COMMENT_DELETED' ||
          this.model.get('content').type === 'TASK_DONE') {
        data.type = 'task';

        if(this.model.get('content').data.refEndUser) {
          data.tasktype = 'client';
          data.user = this.parseClientName(this.model.get('content').data.refEndUser);
          data.line2 = '';
        }

        if (this.model.get('content').data.refProduct) {
          data.tasktype = 'product';
          data.code = '';
          data.name = '';

          if(this.model.get('content').data.refProduct.cCode)
            data.code = this.model.get('content').data.refProduct.cCode;

          if(this.model.get('content').data.refProduct.name)
            data.name = ' - ' + this.model.get('content').data.refProduct.name;
        }

        if (this.model.get('content').data.refCommandLine) {
          data.tasktype = 'order';

          data.user = this.parseClientName(this.model.get('content').data.refCommandLine);

          data.sameOrderCount = 0;
          if(this.model.get('content').data.refCommandLine.nbrOrderSameCustomer)
            data.sameOrderCount = this.model.get('content').data.refCommandLine.nbrOrderSameCustomer;

          //------------

          html = this.model.get('content').data.refCommandLine.purchaseOrderId;
          price = '';

          numberLocale = 'fr-BE';
          configNumberLocale = Settings.configValue('priceLocale');
          if (configNumberLocale)
            numberLocale = configNumberLocale;

          if(this.model.get('content').data.refCommandLine.price) {
            price += '€' + new Intl.NumberFormat(numberLocale).format(this.model.get('content').data.refCommandLine.price);
          }

          if(this.model.get('content').data.refCommandLine.quantity) {
            if(price !== '')
              price += ' - ';

            price += new Intl.NumberFormat(numberLocale).format(
                this.model.get('content').data.refCommandLine.quantity) + ' ' + _.i18n('common.pcs');
          }

          if(price !== '') {
            if(html !== '')
              html += ' | ';
            html += price;
          }

          data.line2 = html;
        }
      }

      return data;
    }
  });
});
