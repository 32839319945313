define('caccountDetailsTabOrdersView',[
         'module',
         'marionette',
         'underscore',
         'app',
         'chartView'
       ], function (
  module,
  Marionette,
  _,
  App,
  ChartView
) {
  'use strict';

  var CAccountDetailsTabOrdersView =  ChartView.extend({
    serializeData: function() {
       this.data = {
        labels : [
          _.i18n('month.january'),
          _.i18n('month.february'),
          _.i18n('month.march'),
          _.i18n('month.april'),
          _.i18n('month.may'),
          _.i18n('month.june'),
          _.i18n('month.july'),
          _.i18n('month.august'),
          _.i18n('month.september'),
          _.i18n('month.october'),
          _.i18n('month.november'),
          _.i18n('month.december')
        ],
        datasets : [
          {
            fillColor : 'rgba(0, 100, 255, 0.64)',
            strokeColor : 'rgba(220,220,220,1)',
            pointColor : 'rgba(220,220,220,1)',
            pointStrokeColor : '#fff',
            text: 'Offers',
            display: true,
            data : [
              this.model.get('offers').january,
              this.model.get('offers').february,
              this.model.get('offers').march,
              this.model.get('offers').april,
              this.model.get('offers').may,
              this.model.get('offers').june,
              this.model.get('offers').july,
              this.model.get('offers').august,
              this.model.get('offers').september,
              this.model.get('offers').october,
              this.model.get('offers').november,
              this.model.get('offers').december
            ]
          },
          {
            fillColor : 'rgba(0, 190, 164, 0.64)',
            strokeColor : 'rgba(220,220,220,1)',
            pointColor : 'rgba(220,220,220,1)',
            pointStrokeColor : '#fff',
            text: 'Orders',
            data : [
              this.model.get('orders').january,
              this.model.get('orders').february,
              this.model.get('orders').march,
              this.model.get('orders').april,
              this.model.get('orders').may,
              this.model.get('orders').june,
              this.model.get('orders').july,
              this.model.get('orders').august,
              this.model.get('orders').september,
              this.model.get('orders').october,
              this.model.get('orders').november,
              this.model.get('orders').december
            ]
          }
        ]
      };
      var currentMont = new Date().getMonth() + 1;
      var t = this.data.labels.slice(currentMont);
      var r = this.data.labels.slice(0, currentMont);
      for (var i = 0 ; i < r.length ; i++) {
        t.push(r[i]);
      }
      this.data.labels = t;

      t = this.data.datasets[0].data.slice(currentMont);
      r = this.data.datasets[0].data.slice(0, currentMont);
      for (var j = 0 ; j < r.length ; j++) {
        t.push(r[j]);
      }
      this.data.datasets[0].data = t;

      t = this.data.datasets[1].data.slice(currentMont);
      r = this.data.datasets[1].data.slice(0, currentMont);
      for (var k = 0 ; k < r.length ; k++) {
        t.push(r[k]);
      }
      this.data.datasets[1].data = t;
      return this.data;
    }
  });

  module.exports = CAccountDetailsTabOrdersView;
});
