define( 'entities/mywebsite',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';
  /**
   * Model
   */
  var MyWebSite = Backbone.Model.extend( {
    url: function() {
      return Settings.url('compuzz', 'v2/websites/current');
    }
  });

  /**
   * REST API
   */
  var API = {    
    getMyWebSiteData: function() {
      var website = new MyWebSite(),
        defer = $.Deferred();

      website.url = Settings.url('compuzz', 'v2/websites/current');
      website.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    postMyWebSite: function(data) {
      var website = new MyWebSite(),
        defer = $.Deferred();

      website.url = Settings.url('compuzz', 'v2/websites/current');
      $.ajax({
        type: 'POST',
        url: website.url,
        data: data,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('my-website:get-data', function() {
    return API.getMyWebSiteData();
  });
  App.reqres.setHandler('my-website:post-data', function(data) {
    return API.postMyWebSite(data);
  });
});
