define('mailsListMailsView',[
  'app',
  'module',
  'underscore',
  'tableView',
  'tableDatetimeFieldView',
  'mailsListMailView',
  'mailsMailView',
  'entities/mails'
], function (
    App,
    Module,
    _,
    TableView,
    DatetimeFieldView,
    ListMailView,
    MailView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'mails-view ' + TableView.prototype.className,
    pageSize: 20,
    pageMode: 'pagination',
    header: false,
    selectable: true,
    footerMaxPages: 2,
    searchValue: '',
    showPaginator: false,
    showPageSizeSeletor: false,

    init: function() {
      this.folder = undefined;
      this.mailSecId = undefined;
      this.messageCount = 0;
    },

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Mail',
        className: 'mail',
        view: ListMailView
      });

      return fields;
    },

    setMailSecId: function(secId) {
      this.mailSecId = secId;
      this.refresh();
    },

    setFolder: function(folderId, messageCount) {
      if(this.folder === folderId)
        return;

      this.folder = folderId;
      this.messageCount = messageCount;
      this.refresh();
    },

    fetch: function(first, pageSize) {
      var defer = $.Deferred();

      if(this.folder === undefined || this.mailSecId === undefined)
        return defer.resolve([]);

      var params = {};
      params.folder = this.folder;
      params.pageSize = pageSize;
      params.first = first;
      params.sidx = 'creatDate';
      params.sort = 'desc';
      params.search = this.searchValue;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('mails:get-url', this.mailSecId, params),
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function(item) {
            var model = App.request('mails:model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this),
        error: function() {
          defer.resolve([]);
        }
      });

      return defer.promise();
    },

    fetchCount: function() {
      var defer = $.Deferred();
      defer.resolve(this.messageCount);
      return defer.promise();
    },

    onRowClick: function(view) {
      //this.options.onRowClick(view);

      this.mailView = new MailView({
        model: view.model,
        mailSecId: this.mailSecId,
        parentView: this
      });
      this.options.parentView.options.parentView.getRegion('mailRegion').show(this.mailView);
    },

    search: function(value) {
      this.searchValue = value;
      this.refresh();
    }
  });
});
