/**
 * Created by GSP on 4/11/2015.
 */
define('collectionItemViewInvited',['module',
        'marionette',
        'jquery',
        'template!invitedItemTpl',
        'dateUtils'],
    function(module,
             Marionette,
             $,
             InvitedItemTpl,
             DateUtils){

        'use strict';

        var CollectionItemViewInvited = Marionette.ItemView.extend({
            tagName: 'div',
            template: InvitedItemTpl,

            serializeData: function() {
                var modelJson = this.model.toJSON();
                modelJson.date = DateUtils.toDateFormatString(modelJson.date);
                return modelJson;
            }
        });

        module.exports = CollectionItemViewInvited;
    });
