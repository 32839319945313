/**
 * Created by BFR on 14/11/2023.
 */
define('editorView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'template!editorTpl'
], function(
    module,
    Marionette,
    _,
    App,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: viewTpl,
        className: 'iframeEditor',

        onShow: function() {
            window.addEventListener('message', _.bind(this.messageReceived, this), false);
        },

        onDestroy: function() {
            window.removeEventListener('message', _.bind(this.messageReceived, this), false);
        },

        messageReceived: function (event) {
            if (event.data.event === 'editorEvent') {
                this.windowView.close();
            }
        },

        serializeData: function() {
            return {
                url: this.options.url + '&iframePostMessage=editorEvent'
            };
        }
    });
});
