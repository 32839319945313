
define('template!popupCreateOptionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'>\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') + '/' + _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code"\n                 placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-7 name-region p-t-5"></div>\n      </div>\n      ';
 var colors = [color1, color2, color3, color4]; ;
__p += '\n      ';
 _.each(colors, function(color, index) { ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.color') )) == null ? '' : __t) +
' ' +
((__t = ( index + 1 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 set-colors">\n          <div class="color-input-item p-l-0" style="display: inline;">\n            <span class="hidden"></span>\n            <label class="btn btn-default btn-flat btn-color colorpick p-t-0 m-t-0 p-l-0 m-l-0" data-key="color' +
((__t = ( index + 1 )) == null ? '' : __t) +
'">\n              <span class="btn-group">\n                <div class="color-block add-on" style="background-color:' +
((__t = ( color )) == null ? '' : __t) +
';" ' +
((__t = ( color?'':'disabled' )) == null ? '' : __t) +
'></div>\n              </span>\n              <input value="' +
((__t = ( color )) == null ? '' : __t) +
'" ' +
((__t = ( color?'':'disabled' )) == null ? '' : __t) +
'>\n            </label>\n            <i class="mdi-action-delete btn-color-delete" data-key="color' +
((__t = ( index + 1 )) == null ? '' : __t) +
'" style="vertical-align: top;"></i>\n          </div>\n        </div>\n      </div>\n      ';
 }); ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.images') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          ';
 for (var i = 1 ; i <= 4 ; i++) { ;
__p += '\n          <div class="col-xs-6 logo-m-ws">\n            ' +
((__t = ( i )) == null ? '' : __t) +
'\n            <div class="my-ws-img-container transparent-bg" style="width: 90px;">\n              <div class="template-upload-img image-content-' +
((__t = ( i )) == null ? '' : __t) +
'" style="';
 if (images[''+i]) { ;
__p += 'background-image: url(' +
((__t = ( images[''+i].url + '&action=res&params=t' )) == null ? '' : __t) +
'); ';
 } ;
__p += '"></div>\n            </div>\n            ';
 if (images[''+i]) { ;
__p += '\n              <label class="download-title p-l-0 p-r-0"><a href="' +
((__t = ( images[''+i].url + '&cmds=on' )) == null ? '' : __t) +
'"> <i class="mdi-file-file-download"></i></a></label>\n            ';
 } ;
__p += '\n            <label for="uploadInputFile' +
((__t = (i)) == null ? '' : __t) +
'" class="upload-title p-l-0 p-r-0"><i class="mdi-file-file-upload"></i></label>\n            <input id="uploadInputFile' +
((__t = (i)) == null ? '' : __t) +
'" data-key="file" data-property="' +
((__t = ( i )) == null ? '' : __t) +
'" multiple type="file" name="filename" class="hidden js-change-logo f-my-ws" accept="image/*"/>\n            ';
 if (images[''+i]) { ;
__p += '\n              <i class="mdi-action-delete btn-image-delete" data-key="picture' +
((__t = ( i )) == null ? '' : __t) +
'Uid" style="vertical-align: top;"></i>\n            ';
 } ;
__p += '\n          </div>\n          ';
 } ;
__p += '\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

