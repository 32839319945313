define('ordersDetailTrackingListNumberView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'template!ordersDetailTrackingListNumberView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-list-number-view ' + FieldView.prototype.className,

    ui: {
      number: '.number',
      copyButton: '.copy-button'
    },

    events: {
      'click @ui.copyButton': 'onCopyNumber'
    },

    modelEvents: {
      'change:trackingNumber': 'onRender'
    },

    onRender: function() {
      if(this.model.get('trackingNumber'))         
        this.ui.number.html(this.model.get('trackingNumber'));            
    },

    onCopyNumber: function() {
      if(this.model.get('trackingNumber')) {
        var $temp = $('<input>');
        $('body').append($temp);
        $temp.val(this.model.get('trackingNumber')).select();
        document.execCommand('copy');
        $temp.remove();
      }      
    }
  });
});
