define('adminNotificationsView',[
  'module',
  'underscore',
  'app',
  'settings',
  'backbone',
  'tableView',
  'tableDatetimeFieldView',
  'adminNotificationsCreatorView',
  'adminNotificationsModificationView',
  'adminNotificationsInfosView',
  'adminNotificationsToolsView',
  'entities/admin/notifications'
], function (
    Module,
    _,
    App,
    Settings,
    Backbone,
    TableView,
    DatetimeFieldView,
    CreatorView,
    ModificationView,
    InfosView,
    ToolsView
) {
  'use strict';

  Module.exports = TableView.extend({

    className: 'admin-notifications-view table-view',
    pageSize: 50,
    pageMode: 'pagination',

    setFields: function() {
      return [{
        name: 'Creator',
        label: _.i18n('notifications.creator'),
        className: 'creator',
        view: CreatorView,
        showName: true
      }, {
        name: 'Date',
        label: _.i18n('notifications.date'),
        bind: 'creatDate',
        className: 'date',
        view: DatetimeFieldView
      }, {
        name: 'Infos',
        label: _.i18n('notifications.context'),
        className: 'infos',
        view: InfosView
      }, {
        name: 'Modification',
        label: _.i18n('notifications.modification'),
        className: 'modification',
        view: ModificationView
      }, {
        name: 'Buttons',
        className: 'buttons',
        view: ToolsView
      }];
    },

    fetch: function(first, pageSize) {

      var defer = $.Deferred();

      var url = App.request('notifications:get-url');
      url += '&pageSize=' + pageSize;
      url += '&first=' + first;

      $.ajax({
        type: 'GET',
        url: url,
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function(item) {
            var model = App.request('notifications:model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function() {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/usernotifications/rowCount', {}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  });
});
