/**
 * Created by OLD on 28/09/2015.
 */
define('timetrackerRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
    'itemPagesNameMixin'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
  'use strict';

  var TimetrackerRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'timetracker': 'showView',
      'timetracker/:gpaphicalService/:secId': 'showTimetrackerFromTicket'
    }
  });

  var API = {
    showView: function() {
      require([
        'timetrackerController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('timetrackerMain');
          ShowController.showTimetracker();
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_TIMETRACKING);
        });
      });
    },

    showTimetrackerFromTicket: function(gpaphicalService, secId) {
      require([
        'timetrackerFromTicketController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('timetrackerMain');
          ShowController.showTimetracker(gpaphicalService, secId);
        });
      });
    }
  };

  App.on('timetracker:show', function() {
    App.navigate('timetracker');
    API.showView();
  });

  App.on('before:start', function() {
    new  TimetrackerRouter({
      controller: API
    });
  });

  module.exports = TimetrackerRouter;
});
