/**
 * Created by BFR on 05/09/2020.
 */
define('productSimilarView',[
    'module',
    'marionette',
    'app',
    'jquery',
    'underscore',
    'template!productSimilarTpl',
    'similarCreateEditLayoutView'
  ], function(
    module,
    Marionette,
    App,
    $,
    _,
    viewTpl,
    SimilarCreateEditLayoutView
  ) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
      template: viewTpl,
      className: 'col-xs-12',

      modelEvents: {
        'change': 'render'
      },
  
      regions: {
      },

      ui: {
      },

      events: {
        'click .btn-remove-similar': 'onBtnRemoveSimilarClick',
        'click .btn-edit': 'onEditClick'
      },

      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      },

      onEditClick: function() {
        var similarCreateEditLayoutView = new SimilarCreateEditLayoutView({
          model: this.model
        });

        similarCreateEditLayoutView.show({
          title: _.i18n('similars.title')
        });
      },

      onBtnRemoveSimilarClick: function() {
        this.model.destroy();
      }
    });
  });
