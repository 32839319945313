define('tasksDatedueView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'moment',
  'dateView',
  'tableFieldView',
  'template!tasksDatedueView'
], function (
  App,
  Module,
  Backbone,
  _,
  moment,
  DateView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-datedue-view ' + FieldView.prototype.className,

    regions: {
      dateRegion: '.date-region'
    },

    modelEvents: {
        'change:dateDue': 'render'
    },

    serializeData: function () {
      var data = {};
      return data;
    },

    onRender: function() {

      var color = '';

      if (this.model.get('dateDue')) {
        if (moment(new Date()).isAfter(this.model.get('dateDue')))
          color = 'red';

        this.dateView = new DateView({
          model: new Backbone.Model({
            date: this.model.get('dateDue'),
            icon: 'clock',
            color: color
          })
        });
        this.getRegion('dateRegion').show(this.dateView);
      }
    }
  });
});
