define('tasksCommentsView',[
  'app',
  'module',
  'backbone',
  'underscore', 
  'tableFieldView',
  'commentView',
  'template!tasksCommentsView'
], function (
  App,
  Module,
  Backbone,
  _,
  FieldView,
  CommentView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-comments-view ' + FieldView.prototype.className,

    regions: {
      commentRegion: '.comment-region'
    },

    modelEvents: {
      'change:comments': 'onRender'
    },

    onRender: function () {
      if(this.model.get('comments') && this.model.get('comments').length > 0) {
        this.commentView = new CommentView({
          model: new Backbone.Model(this.model.get('comments')[this.model.get('comments').length-1]),
          showButtons: false
        });
        this.getRegion('commentRegion').show(this.commentView);
      } else {
        this.getRegion('commentRegion').empty();
      }
    }
  });
});
