/**
 * Created by BFR on 24/02/2020.
 */
define('editCatsView',['module',
    'marionette',
    'editCatView'],
  function (module,
            Marionette,
            EditCatView) {
    'use strict';

    var EditCatsView = Marionette.CollectionView.extend({
      childView: EditCatView,
      className: 'cat-list'
    });

    module.exports = EditCatsView;
  });
