define('webshopCategoriesDetailBodyCategoryIndexesView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopCategoriesDetailBodyCategoryIndexView',
  'template!webshopCategoriesDetailBodyCategoryIndexesView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  IndexView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-category-indexes-view',
    childView: IndexView,
    childViewContainer: '.indexes-container',

    childViewOptions: function () {
      return {
        category: this.options.category
      };
    },

    initialize: function () {
      this.webshopConfig = this.options.webshopConfig;
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      _.each(this.options.webshopConfig.getLanguages(), _.bind(function (language) {
        var model = new Backbone.Model({
          lang: language,
          value: this.options.category.getIndex(language)
        });
        this.collection.add(model);
      }, this));
    }
  });
});
