/**
 * Created by RKL on 21/08/2015.
 */
define('mailingShowView',[
  'module',
  'marionette',
  'app',
  'template!mailingViewTpl'
], function(
  module,
  Marionette,
  App,
  viewTpl
) {
  'use strict';

  var MailingView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
    },

    onShow: function() {
      $.material.init();
    }
  });

  module.exports = MailingView;
});
