define('entities/webshop/categories',[
  'app',
  'backbone',
  'settings',
  'underscore',
  'jquery',
  'bootbox',
  'backboneRelational'
], function (
  App,
  Backbone,
  Settings,
  _,
  $,
  Bootbox
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  app.ns.WebshopCategory = Backbone.RelationalModel.extend({
    idAttribute: 'id',
    fetch: function () {
      var params = {
        locale: Settings.get('lang')
        };

      if(this.get('code'))
        params.code = this.get('code');
      else
        params.id = this.getId();

      this.url = Settings.url('compuzz2', 'webshop/category/get', params);

      return Backbone.Model.prototype.fetch.call(this);
    },

    getId: function () {
      return parseInt(this.get('id'), 10);
    },

    getCode: function () {
      return this.get('code');
    },

    setCode: function (value) {
      var defer = $.Deferred();

      if (this.getCode() === value) {
        defer.resolve();
        return defer.promise();
      }

      this.update('code/update', value).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getStatus: function () {
      return this.get('status');
    },

    setStatus: function (status) {
      var defer = $.Deferred();

      if (this.getStatus() === status) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        code: this.getCode(),
        status: status
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/status/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    addProducts: function (ids) {
      var defer = $.Deferred();

      var data = {
        categoryId: this.getId(),
        productIds: ids
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/add'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    addProductList: function (products) {
      var defer = $.Deferred();

      var data = {
        categoryId: this.getId(),
        products: products
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/add-list'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    deleteProduct: function (id) {
      var defer = $.Deferred();

      var data = {
        categoryId: this.getId(),
        productId: id
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/delete'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    updateProductPosition: function (ids, position) {
      var defer = $.Deferred();

      var data = {
        categoryId: this.getId(),
        productIds: ids,
        position: position
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/update-position'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseText.message);
        }
      });
      return defer.promise();
    },

    getName: function (lang) {
      var name = this.getText('name', lang);
      if (name === '')
        name = this.get('label');
      return name;
    },

    setName: function (lang, value) {
      return this.setText('name', lang, value);
    },

    getLabel: function () {
      return this.get('label');
    },

    setLabel: function (value) {
      var defer = $.Deferred();

      if (this.getLabel() === value) {
        defer.resolve();
        return defer.promise();
      }

      this.update('label/update', value).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getSeoLabel: function (lang) {
      return this.getText('filter-specific-name', lang);
    },

    setSeoLabel: function (lang, value) {
      return this.setText('filter-specific-name', lang, value);
    },

    getDescription: function (lang) {
      return this.getText('short', lang);
    },

    setDescription: function (lang, value) {
      return this.setText('short', lang, value);
    },

    getSeoProductDescription: function (lang) {
      return this.getText('product-description', lang);
    },

    setSeoProductDescription: function (lang, value) {
      return this.setText('product-description', lang, value);
    },

    getMetaTitle: function (lang) {
      return this.getText('title', lang);
    },

    setMetaTitle: function (lang, value) {
      return this.setText('title', lang, value);
    },

    getMetaDescription: function (lang) {
      return this.getText('meta-description', lang);
    },

    setMetaDescription: function (lang, value) {
      return this.setText('meta-description', lang, value);
    },

    getSlug: function (lang) {
      if (lang)
        return this.getText('url', lang);
      else
        return this.getText('url');
    },

    setSlug: function (lang, value) {
      var defer = $.Deferred();

      if (this.getSlug() === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        id: this.getId(),
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/slug/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          var d = null;
          if(data.responseJSON.data)
            d = data.responseJSON.data;
          defer.reject(d);
        }
      });
      return defer.promise();
    },

    getIndex: function (lang) {
      var result = true;
      _.each(this.get('noIndex'), _.bind(function (p, l) {
        if (l === lang) {
          result = p;
        }
      }, this));

      return result;
    },

    setIndex: function (lang, value) {
      var defer = $.Deferred();

      if (this.getIndex(lang) === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        categorytId: this.getId(),
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/seo/index/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    getLayout: function () {
      var id = '';
      if (this.get('config')) {
        _.each(this.get('config'), _.bind(function (value, code) {
          if (code === 'layout')
            id = value;
        }, this));
      }
      return id;
    },

    setLayout: function (id) {
      var defer = $.Deferred();

      if (this.getLayout() === id) {
        defer.resolve();
        return defer.promise();
      }

      this.update('layout/update', id).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getProductsLayout: function () {
      var id = '';
      if (this.get('config')) {
        _.each(this.get('config'), _.bind(function (value, code) {
        if (code === 'products-layout')
          id = value;
        }, this));
      }
      return id;
    },

    setProductsLayout: function (id) {
      var defer = $.Deferred();

      if (this.getLayout() === id) {
        defer.resolve();
        return defer.promise();
      }

      this.update('products-layout/update', id).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getDefaultSort: function () {
      var id = '';
      if (this.get('config')) {
        _.each(this.get('config'), _.bind(function (value, code) {
          if (code === 'default-sort')
            id = value;
        }, this));
      }
      return id;
    },

    setDefaultSort: function (id) {
      var defer = $.Deferred();

      if (this.getLayout() === id) {
        defer.resolve();
        return defer.promise();
      }

      this.update('default-sort/update', id).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getText: function (type, lang) {
      if (!lang)
        lang = Settings.get('lang');

      var text = '';
      if (this.get('texts')) {
        _.each(this.get('texts'), _.bind(function (t) {
          if (t.type === type && t.lang === lang)
            text = t.content;
        }, this));
      }
      return text;
    },

    setText: function (type, lang, value) {
      var defer = $.Deferred();

      if (this.getText(type, lang) === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        id: this.getId(),
        code: type,
        lang: lang,
        content: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/text/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (error) {
          Bootbox.alert(_.i18n('common.request.error'));
          defer.reject(error);
        }
      });
      return defer.promise();
    },

    getPreviewImageUrl: function () {
      var url = Settings.get('notFoundImage');
      if (this.get('medias')) {
        _.each(this.get('medias'), _.bind(function (media) {
          if (media.type === 'banner-image')
            url = Settings.configValue('webshop.imagesUrl') + '/' + media.previewUrl;
        }, this));
      }
      return url;
    },

    getImageUrls: function () {
      var urls = [];
      if (this.get('medias')) {
        _.each(this.get('medias'), _.bind(function (media) {
          urls.push(Settings.configValue('webshop.imagesUrl') + '/' + media.url);
        }, this));
      }
      return urls;
    },

    getImages: function (type) {

      var images = [];

      _.each(this.get('medias'), _.bind(function (media) {
        if(media.type === type)
          images.push(media);
      }, this));

      return images;
    },

    addImage: function (file, type) {
      var defer = $.Deferred();

      var data = new FormData();
      data.append('file', file);
      data.append('code', this.getCode());
      data.append('type', type);
    
      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'webshop/category/images/add'),
        data: data,
        processData: false,
        contentType: false,
        success: function (result) {
          var image = null;
          if(result.image)
            image = result.image;
          defer.resolve(image);
        },
        error: function (error) {
          defer.reject(error);
        }
      });
      return defer.promise();
    },

    deleteImage: function (id) {
      var defer = $.Deferred();

      var data = {
        categoryCode: this.getCode(),
        id: id
      };

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'webshop/category/images/delete'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    update: function(url, value) {
      var defer = $.Deferred();
      var data = {
        id: this.getId(),
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/' + url),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    clearCache: function () {
      var defer = $.Deferred();
      var data = {
        id: this.getId()
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/clean-cache'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    }
  });

  var API = {
    getCategoryDatalist: function () {
      var defer = $.Deferred();

      if (this.categoriesDatalist) {
        defer.resolve(this.categoriesDatalist);
        return defer.promise();
      }

      $.ajax({
        type: 'GET',
        url: App.request('webshop.categories.search'),
        success: _.bind(function (categories) {
          var html = '<datalist id="webshop-categories">';

          var models = [];
          _.each(categories, _.bind(function (item) {
            var model = App.request('webshop.category.model', item);
            if (model.get('code') !== '') {
              models.push(model);
              html += '<option value="' + model.get('code') + '">' + model.getName() + '</option>';
            }
          }, this));

          html += '</datalist>';

          if ($('body').find('#webshop-categories').length === 0)
            $('body').append(html);

          this.categoriesDatalist = models;
          defer.resolve(this.categoriesDatalist);
        }, this)
      });
      return defer.promise(this.categoriesDatalist);
    },

    getImagesTypes: function () {
      var defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'webshop/categories/images/types', {}),
        success: _.bind(function (types) {
          defer.resolve(types);
        }, this)
      });
      return defer.promise();
    }
  };

  App.reqres.setHandler('webshop.category.model', function (data) {
    if (!data)
      data = {};
    return app.ns.WebshopCategory.findOrCreate(data);
  });

  App.reqres.setHandler('webshop.categories.search', function (params) {
    return Settings.url('compuzz2', 'webshop/categories/search', params);
  });

  App.reqres.setHandler('webshop.categories.count', function (params) {
    return Settings.url('compuzz2', 'webshop/categories/count', params);
  });

  App.reqres.setHandler('webshop.categories.tree', function (params) {
    return Settings.url('compuzz2', 'webshop/categories/tree', params);
  });

  App.reqres.setHandler('webshop.categories.tree.move', function () {
    return Settings.url('compuzz2', 'webshop/categories/tree/move/');
  });

  App.reqres.setHandler('webshop.category.history', function (id, params) {
    return Settings.url('compuzz2', 'webshop/category/' + id + '/index/history', params);
  });

  App.reqres.setHandler('webshop.category.urls', function (params) {
    return Settings.url('compuzz2', 'webshop/category/urls', params);
  });

  App.reqres.setHandler('webshop.category.history.count', function (id, params) {
    return Settings.url('compuzz2', 'webshop/category/' + id + '/index/history/count', params);
  });

  App.reqres.setHandler('webshop.category.products', function (id, params) {
    return Settings.url('compuzz2', 'webshop/category/' + id + '/products', params);
  });

  App.reqres.setHandler('webshop.category.products.import', function (id, params) {
    return Settings.url('compuzz2', 'webshop/category/' + id + '/products/import', params);
  });

  App.reqres.setHandler('webshop.category.products.count', function (id, params) {
    return Settings.url('compuzz2', 'webshop/category/' + id + '/products/count', params);
  });

  App.reqres.setHandler('webshop.categories.datalist', function () {
    return API.getCategoryDatalist();
  });

  App.reqres.setHandler('webshop.categories.images.types', function () {
    return API.getImagesTypes();
  });
});

