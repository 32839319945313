define('entities/caccounts/htmltemplates',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings',
  'backboneRelational',
  'entities/product_rel'
], function (
  App,
  $,
  Backbone,
  _,
  Settings
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  /**
   * Model
   */
  var HtmlTemplate = Backbone.RelationalModel.extend({

    defaults: {
      subject: new app.ns.DynamicText(),
      content: new app.ns.DynamicText(),
      templateType: 'MAIL_ORDER'
    },

    fetchByType: function () {
      this.url = Settings.url('compuzz2', 'v2/htmltemplates', {
        htmlTemplateType: this.get('templateType')
      });
      return Backbone.Model.prototype.fetch.call(this);
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/htmltemplates/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/htmltemplates');
      return Backbone.Model.prototype.save.call(this);
    },

    buildSubject: function (data, defaultValue, lang) {
      var template = this.get('subject') ? this.get('subject').getTextInLocale(lang) : null;
      if (template) {
        return _.template(template, data);
      }
      return null;
    },

    build: function (data, defaultValue, lang) {
      var template = this.get('content') ? this.get('content').getTextInLocale(lang) : null;
      if (template) {
        return _.template(template, data);
      }
      return null;
    },

    relations: [{
      type: Backbone.HasOne,
      key: 'content',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasOne,
      key: 'subject',
      relatedModel: 'DynamicText'
    }],

    idAttribute: 'secId'
  });

  App.reqres.setHandler('htmltemplates:model', function (value) {
    var col = Backbone.Relational.store.getCollection(HtmlTemplate);
    var model;
    if (value) {
      model = _.find(col.models, function (model) {
        return (value.templateType && value.templateType === model.get('templateType')) ||
            (value.secId && value.secId === model.get('secId'));
      });
    }
    if (model) {
      return model;
    } else {
      return new HtmlTemplate(value);
    }
  });

  App.reqres.setHandler('htmltemplates:row-count-url', function () {
    return 'v2/htmltemplates/rowCount';
  });

  App.reqres.setHandler('htmltemplates:get-url', function () {
    return Settings.url('compuzz2', 'v2/htmltemplates/advancedSearch');
  });
});
