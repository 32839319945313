/**
 * Created by Matteo on 11/07/2015.
 */
define('loginShowController',[
  'module',
  'underscore',
  'app',
  'loginShowView',
  'settings',
  'plivoUtils'
], function(
  module,
  _,
  App,
  View,
  Settings,
  PlivoUtils
) {
  'use strict';

  var LoginController = {
    showLogin: function() {
      if (!Settings.get('currentUser') || !Settings.get('tokenId') ||
          !Settings.get('currentCAccount')) {

        if (Settings.get('environment') === 'adtv2') {
          window.location = this.getLoginAccountUrl();
        } else {
          var view = new View();

          this.view = view;
          view.listenTo(view, 'login:sign:in', _.bind(this.onSignIn, this));
          view.listenTo(view, 'login:keyup', _.bind(this.onSignIn, this));

          App.regions.getRegion('main').show(view);
        }
      } else {
        App.trigger('index:show');
      }
    },

    getLoginAccountUrl: function() {
      var url = Settings.get('compuzzLoginAccountUrl')[Settings.get('lang')];
      if (!url) {
          url = Settings.get('compuzzLoginAccountUrl').en;
      }
      return url;
    },

    onSignIn: function() {
      var that = this,
        userName = this.view.ui.userName.val(),
        password = this.view.ui.password.val();

      if (!_.isEmpty(userName) && !_.isEmpty(password)) {
        require(['entities/session'], function() {
          var newSessionToken = App.request('session:token:user:entity:new');

          newSessionToken.save({username: userName, password: password}, {
            success: function(model, response) {
              Settings.set('plivoUser', model.get('plivoUser'));
              Settings.set('plivoPassword', model.get('plivoPassword'));
              Settings.set('tokenId', model.get('tokenId'));
              Settings.set('currentUserCookie', JSON.stringify(response));
              Settings.set('currentUser', response);
              PlivoUtils.init();
              if(response){
                  // jshint camelcase: false
                  /*window.Intercom('boot', {
                   app_id: 'v368pys1',
                   name: response.firstName + response.lastName,
                   email: response.email,
                   created_at:  response.creatDate
                   });*/
                  // jshint camelcase: true
              }
              that.view.ui.form.submit();
              App.trigger('caccounts:list');
            },
            error: function() {
              that.view.onError(true);
            }
          });
        });
      } else {
        this.view.onError();
      }
    }

  };

  module.exports = LoginController;
});
