/**
 * Created by RKL on 10/09/2015.
 */
define('dialogView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'dialogMixin',
  'template!emptyDialogTpl'
], function(
  module,
  Logger,
  Marionette,
  _,
  DialogMixin,
  EmptyDialogTpl
) {
  'use strict';

  var DialogView = Marionette.LayoutView.extend(
    _.extend({
      getTemplate: function() {
        if (this.options.collection && this.options.collection.length === 0) {
          return EmptyDialogTpl;
        } else {
          return this.template;
        }
      },

      onShow: function() {
        $.material.init();
      },

      onDestroy: function() {
        if (this.model) {
          this.model.clear();
        }
      }

    }, DialogMixin)
  );

  module.exports = DialogView;
});
