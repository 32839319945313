define('customersDetailBodyContactTabs',[
  'underscore',
  'rolesMixin'
], function (_, RolesMixin) {
  'use strict';

  var customersContactsDetailTabs = [
    {
      route: 'info',
      name: 'info',
      role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_INFO,
      title: _.i18n('customerContact.tabTitle.info')
    },
    {
      route: 'calls',
      name: 'calls',
      role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_CALLS,
      title: _.i18n('customerContact.tabTitle.calls')
    },
    {
      route: 'mails',
      name: 'mails',
      role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_MAILS,
      title: _.i18n('customerContact.tabTitle.mails')
    }
  ];

  return customersContactsDetailTabs;
});
