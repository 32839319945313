define('webshopCategoriesListStatusView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'template!webshopCategoriesListStatusView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-list-status-view ' + FieldView.prototype.className,

    regions: {
      'selectRegion': '.select-region'
    },

    modelEvents: {
      'change:status': 'setSelected'
    },

    onRender: function () {
      this.selectView = new SelectView({
        collection: new Backbone.Collection([
          {id: '0',  value: _.i18n('common.disabled')},
          {id: '1',  value: _.i18n('common.enabled')},
          {id: '-1',  value: _.i18n('common.review')}
        ])
      });
      this.selectRegion.show(this.selectView);
      this.selectView.on('change', _.bind(this.onSelectChange, this));

      this.setSelected();
    },

    setSelected: function () {
      this.selectView.setSelected(this.model.getStatus());
    },

    onSelectChange: function (model) {
      this.model.setStatus(model.get('id'))
      .done(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
            this.setSelected();
        }, this));
      }, this))
      .fail(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
          this.setSelected();
        }, this));
      }, this));
    }
  });
});
