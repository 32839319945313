define('customersDetailHeaderView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'imageUtils',
  'buttonView',
  'tasksCreatorBehavior',
  'template!customersDetailHeaderView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ImageUtils,
  ButtonView,
  TasksCreatorBehavior,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customers-detail-header-view',

    ui: {
      logo: '.logo',
      name: '.infos .name',
      clientName: '.infos .client-name',
      number: '.infos .number',
      code: '.infos .code',
      phone: '.phone',
      phoneValue: '.phone .value',
      mail: '.mail',
      mailValue: '.mail .value',
      vatValid: '.vat-valid',
      notificationsEnabled: '.notifications .enabled',
      notificationsDisabled: '.notifications .disabled'
    },

    events: {
      'click @ui.notificationsEnabled': 'onEnabledNotifications',
      'click @ui.notificationsDisabled': 'onDisabledNotifications',
      'click @ui.phone': 'onPhoneClick'
    },

    modelEvents: {
      'change:logoUid': 'renderLogo',
      'change:code': 'renderLogo',
      'change:name': 'renderContent',
      'change:firstName': 'renderContent',
      'change:lastName': 'renderContent',
      'change:tel': 'renderContent',
      'change:email': 'renderContent',
      'change:subjectToVat': 'renderContent',
      'change:vatNumberChecked': 'renderContent'
    },

    regions: {
      backRegion: '.back-region'
    },

    behaviors: function () {
      return {
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refEndUser: this.options.model
        }
      };
    },

    onShow: function () {
     this.backButtonView = new ButtonView({
        type: 'list',
        text: _.i18n('common.back.to.list'),
        title: _.i18n('common.back.to.list')
      });

      this.backRegion.show(this.backButtonView);
      this.backButtonView.on('click', _.bind(this.onBackList, this));
    },

    onRender: function () {
      this.renderLogo();
      this.renderContent();
      this.renderNotification();
    },

    renderLogo: function () {
      var logo = ImageUtils.getLogo(this.model.get('logoUid'), this.model.get('code'));
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    },

    renderContent: function () {
      this.ui.name.html('');
      if(this.model.get('name'))
        this.ui.name.html(this.model.get('name'));

      //--------------------------

      var html = '';
      if(this.model.get('firstName'))
        html += this.model.get('firstName');

      if(this.model.get('lastName')) {
        if(html !== ' ')
          html += ' ';

        html += this.model.get('lastName');
      }

      this.ui.clientName.html(html);

      //--------------------------

      this.ui.number.html('');
      if(this.model.getCustomerNumber())
        this.ui.number.html('N°: ' + this.model.getCustomerNumber());

      //--------------------------

      this.ui.phone.hide();
      this.ui.phoneValue.html('');
      if(this.model.get('tel')) {
        this.ui.phoneValue.html(this.model.get('tel'));
        this.ui.phone.css('display', 'flex');
      }

      //--------------------------

      this.ui.mail.hide();
      this.ui.mailValue.html('');
      if(this.model.get('email')) {
        this.ui.mailValue.html(this.model.get('email'));
        this.ui.mail.css('display', 'flex');
      }

      //--------------------------

      this.ui.vatValid.hide();
      if(this.model.get('subjectToVat') && !this.model.get('vatNumberChecked'))
        this.ui.vatValid.css('display', 'flex');
    },

    renderNotification: function () {
     $.when(App.request('customer:is-user-notified', this.model.get('secId')).done(_.bind(function (res) {
        if (res > 0) {
          this.ui.notificationsEnabled.css('display', 'flex');
          this.ui.notificationsDisabled.hide();
        } else {
          this.ui.notificationsEnabled.hide();
          this.ui.notificationsDisabled.css('display', 'flex');
        }
      }, this)));
    },

    onEnabledNotifications: function () {
      $.when(App.request('customer:notify-me', this.model.get('secId'), false).done(_.bind(function () {
        this.renderNotification();
      }, this)));
    },

    onDisabledNotifications: function () {
      $.when(App.request('customer:notify-me', this.model.get('secId'), true).done(_.bind(function () {
          this.renderNotification();
      }, this)));
    },

    onPhoneClick: function (e) {
      e.stopPropagation();
      App.trigger('phone:numbering', {
        phoneNumber: this.model.get('tel'),
        country: this.model.get('countryCode')
      });
    },

    onBackList: function () {
      App.navigate('customers', {trigger: true});
    }
  });
});
