
define('template!headerSearchView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="left">\n  <input class="js-search-input" type="text" size="30" value="' +
((__t = ( currentSearch )) == null ? '' : __t) +
'" spellcheck="false">\n  <div class="progress progress-striped active js-progress-bar" style="display: none">\n    <div class="progress-bar" style="width:100%"></div>\n  </div>\n</div>\n<div class="right">    \n  <svg class="js-search-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="lightgrey" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"/></svg>\n  </div>  \n</div>\n<div class="js-search-result" style="display:none">\n  <div class="group" data-type="product" style="display:none;">\n    <div class="name">' +
((__t = ( _.i18n('menu.products') )) == null ? '' : __t) +
'</div>\n    <div class="items"></div>\n    <div class="more" style="display:none;">' +
((__t = ( _.i18n('common.view.more') )) == null ? '' : __t) +
'</div>\n    <div class="less" style="display:none;">' +
((__t = ( _.i18n('common.view.less') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="group" data-type="command" style="display:none;">\n    <div class="name">' +
((__t = ( _.i18n('menu.ordersManagement') )) == null ? '' : __t) +
'</div>\n    <div class="items"></div>\n    <div class="more" style="display:none;">' +
((__t = ( _.i18n('common.view.more') )) == null ? '' : __t) +
'</div>\n    <div class="less" style="display:none;">' +
((__t = ( _.i18n('common.view.less') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="group" data-type="offer" style="display:none;">\n    <div class="name">' +
((__t = ( _.i18n('menu.offerManagement') )) == null ? '' : __t) +
'</div>\n    <div class="items"></div>\n    <div class="more" style="display:none;">' +
((__t = ( _.i18n('common.view.more') )) == null ? '' : __t) +
'</div>\n    <div class="less" style="display:none;">' +
((__t = ( _.i18n('common.view.less') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="group" data-type="customer" style="display:none;">\n    <div class="name">' +
((__t = ( _.i18n('menu.clients') )) == null ? '' : __t) +
'</div>\n    <div class="items"></div>\n    <div class="more" style="display:none;">' +
((__t = ( _.i18n('common.view.more') )) == null ? '' : __t) +
'</div>\n    <div class="less" style="display:none;">' +
((__t = ( _.i18n('common.view.less') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="group" data-type="pimProduct" style="display:none;">\n    <div class="name">' +
((__t = ( _.i18n('menu.pim') + ' ' + _.i18n('menu.pim.products') )) == null ? '' : __t) +
'</div>\n    <div class="items"></div>\n    <div class="more" style="display:none;">' +
((__t = ( _.i18n('common.view.more') )) == null ? '' : __t) +
'</div>\n    <div class="less" style="display:none;">' +
((__t = ( _.i18n('common.view.less') )) == null ? '' : __t) +
'</div>\n  </div>\n </div>\n';

}
return __p
};});

