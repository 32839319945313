
define('template!filterValueTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-1 m-b--5" >\n        <input type="checkbox" class="cbx-filter-value" style="width: 20px; height: 18px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n      </div>\n    <div class="col-xs-11">\n      ';
 if (filter.color) { ;
__p += '\n        <div style="width: 18px; height: 18px; display: inline-block; background-color: ' +
((__t = ( filter.color )) == null ? '' : __t) +
'" ></div>\n      ';
 } ;
__p += '\n      ' +
((__t = ( filter.displayName )) == null ? '' : __t) +
'\n    </div>\n</div>\n';

}
return __p
};});

