
define('template!headerTaskView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="user-creator-region"></div>\n\n<div class="info-container">\n    ';
 if(type === 'product') { ;
__p += '\n        <div class="info product">     \n            <div class="code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n            <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n        </div>\n    ';
 } if(type === 'client') { ;
__p += '\n        <div class="info client">\n            <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>        \n        </div>\n    ';
 } if(type === 'order') { ;
__p += '\n        <div class="info order">\n            <div class="line">\n                <div class="user">' +
((__t = ( user )) == null ? '' : __t) +
'</div>\n\n                ';
 if(sameOrderCount > 0) { ;
__p += '            \n                    <div class="same-order">\n                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path></svg>\n                        <div class="count">' +
((__t = ( sameOrderCount )) == null ? '' : __t) +
'</div> \n                    </div>\n                ';
 } ;
__p += '\n            </div>\n            <div class="line2">' +
((__t = ( line2 )) == null ? '' : __t) +
'</div>            \n        </div>\n    ';
 } ;
__p += '\n   \n    <div class="comment-region"></div>\n</div>\n\n<div class="date-region"></div>\n<div class="status">\n    <input type="checkbox" class="cpz-checkbox done" ' +
((__t = ( checked )) == null ? '' : __t) +
'>\n</div>\n<div class="target">\n    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"/></svg>\n</div>    \n';

}
return __p
};});

