
define('template!webshopCategoriesListInfosView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(name != '') { ;
__p += '\n    <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';
 if(code != '') { ;
__p += '\n    <div class="code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
};});

