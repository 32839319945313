define('ordersDetailSupplierEditInvoiceView',[
  'module',
  'marionette',
  'underscore',
  'ordersController',
  'ordersDetailSupplierEditInvoicePackingListView',
  'template!ordersDetailSupplierEditInvoiceView'
], function (
  module,
  Marionette,
  _,
  Controller,
  PackingListView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'delivery-view',

    ui: {
      packingList: '.packing-list a',
      packingListPreview: '.packing-list .preview',
      packingListCanvas: '.packing-list .preview .canvas'
    },

    events: {
      'click @ui.packingList': 'onPackingList',
      'click @ui.packingListPreview': 'onPackingList'
    },

    onRender: function() {
      this.renderPackingList();
    },

    serializeData: function() {
      var data = {};

      data.deliveryAddress = Controller.createAddress(this.model.get('delivery'), '<br>');

      if(data.deliveryAddress.trim() === '')
        data.deliveryAddress = '-';

      data.email = '-';
      if(this.model.get('emailAddress'))
        data.email = this.model.get('emailAddress');

      data.packingList = '-';
      if (this.model.get('packingList') && this.model.get('packingList').filename)
        data.packingList = this.model.get('packingList').filename;

      data.showPackingList = false;
      if (this.model.get('packingList') && this.model.get('packingList').content)
        data.showPackingList = true;

      return data;
    },

    renderPackingList: function () {
      if (this.model.get('packingList') && this.model.get('packingList').content) {
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var dataURI = 'data:application/pdf;base64, ' + this.model.get('packingList').content;
        var loadingTask = pdfjsLib.getDocument(dataURI);
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
            var scale = 1;

            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.packingListCanvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //console.log('Page rendered');
            });

          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      }
    },

    onPackingList: function () {
      PackingListView.prototype.open({
        model: this.model
      });
    }
  });
});
