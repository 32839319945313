define('headerNotificationsPopupView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'headerNotificationsPopupListView',
  'template!headerNotificationsPopupView',
  'entities/admin/notifications'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-notifications-popup-view',

    ui: {
      showAll: '.show-all',
      seen: '.seen'
    },

    events: {
      'click @ui.showAll': 'onShowAll',
      'click @ui.seen': 'onSeen'
    },

    regions: {
        listRegion: '.list-region'
    },

    initialize: function () {
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
      this.windowClickHandler = _.bind(this.onWindowClick, this);
      $(document).on('keydown', this.windowKeydownHandler);
      $(document).on('click', this.windowClickHandler);
    },

    onDestroy: function() {    
      $(document).off('keydown', this.windowKeydownHandler);
      $(document).off('click', this.windowClickHandler);
    },

    onRender: function () {
      this.listView = new ListView({
        popupView: this
      });
      this.getRegion('listRegion').show(this.listView);
    },

    onShowAll: function () {
      var baseURL = window.location.protocol + '//' + window.location.hostname;
      if (window.location.port) {
        baseURL += ':' + window.location.port;
      }
      var url = baseURL + '#admin/notifications';
      window.open(url);

      //App.navigate('admin/notifications', {trigger: true});
      this.close();
    },

    onSeen: function () {
      App.request('notifications:read-all').done(_.bind(function () {
        _.each(this.listView.bodyView.collection.models, function (model) {
          model.set('read', true);
        }, this);

        this.options.fetchCount();
      }, this));
    },

    open: function () {
      var defer = $.Deferred();
      this.listView.reset();

      if(!this.$el.is(':visible')) {
        this.listView.bodyView.fetch().done(_.bind(function () {
          if(this.listView.bodyView.collection.models.length > 0)
            this.$el.css('display', 'flex');
          defer.resolve();
        }, this));
      } else {
        defer.resolve();
      }

      return defer.promise();
    },

    close: function () {
      if(!this.$el.is(':visible'))
        return;

      this.listView.reset();
      this.$el.hide();
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape')
        this.close();    
    },

    onWindowClick: function(event) {       
      if (!event.target.closest('.header-notifications-popup-view'))
        this.close(); 
    }
  });
});
