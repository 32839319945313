define('webshopCategoriesListFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'webshopCategoriesListFiltersEditView',
  'filtersBannerView'
], function(
  Module,
  Backbone,
  _,
  Settings,
  moment,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
      var settings = Settings.get('webshopCategoriesListFilters', true);
      if(settings) {
        this.model = new Backbone.Model(JSON.parse(settings));
      } else {
        this.model = new Backbone.Model({
          code: '',
          name: '',
          status: '',
          dateFrom: '',
          dateTo: ''
        });
        Settings.set('webshopCategoriesListFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },


    setCollection: function() {
      if(this.model.get('status') !== '') {
        var status = '';
        if(this.model.get('status') === '0')
          status = _.i18n('common.disabled');
        if(this.model.get('status') === '1')
          status = _.i18n('common.enabled');
        if(this.model.get('status') === '-1')
            status = _.i18n('common.review');

        this.collection.add(new Backbone.Model({
          id: 'status',
          libelle: _.i18n('common.status'),
          value: status
        }));
      }

      //----------------
      var dateValue = '';

      if(this.model.get('dateFrom') !== '' && this.model.get('dateTo') !== '') {
        dateValue = moment(new Date(this.model.get('dateFrom'))).format('DD/MM/YYYY');
        dateValue += ' - ';
        dateValue += moment(new Date(this.model.get('dateTo'))).format('DD/MM/YYYY');
        this.collection.add(new Backbone.Model({
          id: 'dates',
          libelle: _.i18n('common.dates'),
          value: dateValue
        }));
      }

      //----------------
      if(this.model.get('code') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'code',
          libelle: _.i18n('common.code'),
          value: this.model.get('code')
        }));
      }

      //----------------
      if(this.model.get('name') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'name',
          libelle: _.i18n('common.name'),
          value: this.model.get('name')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    onDeleteFilter: function(model, filterModel) {
      if(filterModel.get('id') === 'dates') {
        model.set('dateFrom', '');
        model.set('dateTo', '');
      } else
        model.set(filterModel.get('id'), '');
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('dateFrom') !== model.get('dateFrom') ||
          this.model.get('dateTo') !== model.get('dateTo'))
        needFetch = true;

      if(this.model.get('code') !== model.get('code'))
        needFetch = true;

      if(this.model.get('name') !== model.get('name'))
        needFetch = true;

      if(this.model.get('status') !== model.get('status'))
        needFetch = true;

      if(this.model.get('tag') !== this.model.get('tag'))
        needFetch = true;

      if(this.model.get('category') !== model.get('category'))
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('webshopCategoriesListFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

