
define('template!caccountAddCreditsPopupSuccessTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="icon-preview">\n        <i class="mdi-action-done"></i>\n        <span>' +
((__t = ( _.i18n('credit.paypal.popup.success') )) == null ? '' : __t) +
'</span>\n    </div>\n</div>';

}
return __p
};});

