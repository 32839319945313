
define('template!customersDetailBodyMailsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="mails-content col-xs-12">\n      ';
 if (!connected) { ;
__p += '\n        <button class="googleConnect">Connect</button>\n      ';
 } ;
__p += '\n    <div class="messages-container">\n    </div>\n  </div>\n  <div class="js-footer footer-vbox" style="height: 130px;">\n    <!-- Footer content -->\n    <div class="message-form">\n      <div class="row">\n        <div class="attachments-addon hidden">\n          <ul class="attachments-addon-list list-inline"></ul>\n        </div>\n      </div>\n      <div class="clearfix">\n        <div class="col-xs-11 col-lg-7">\n          <div class=" row">\n            <div class="col-xs-2 col-lg-1">\n              <label for="browseAttachFile" class="control-label attach-btn">\n                <a class="col-xs-1 btn btn-fab btn-fab-mini btn-raised btn-sm btn-primary send-new-m-b"><i class="mdi-editor-attach-file"></i></a>\n              </label>\n              <input id="browseAttachFile" multiple type="file" name="filename" accept="*" class="hidden attach-input"/>\n            </div>\n            <div class="col-xs-8 col-lg-10">\n              <div class="row">\n                  <input type="text" name="subject" class="input-subject col-xs-12 p-l-2" placeholder="' +
((__t = ( _.i18n("mails.subject") )) == null ? '' : __t) +
'"/>\n              </div>\n              <div class="row">\n                <textarea class="create-new-message" rows="2" id="textArea" placeholder="' +
((__t = ( _.i18n("mails.content") )) == null ? '' : __t) +
'"></textarea>\n              </div>\n            </div>\n            <div class="col-xs-2 col-lg-1">\n              <a class="btn btn-fab btn-fab-mini btn-raised btn-sm action-btn send-new-m-b js-send-new-m-b">\n                <i class="mdi-content-send"></i>\n              </a>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

