define('shareCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'underscore',
  'app',
  'savingBehavior',
  'dialogFormView',
  'template!shareCreateEditLayoutTpl'
], function(
  module,
  Marionette,
  Backbone,
  $,
  _,
  App,
  SavingBehavior,
  DialogFormView,
  createEditLayoutTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
    },

    className: 'profile-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.model = options.customerModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.triggerMethod('saved');
      App.trigger('customer:header:rerender', modelJSON);
      this.hide();
    }
  });
});
