
define('template!productSelectDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="dialog-blinder" class="dialog-blinder"></div>\n<div>\n    <div class="select-product-region"></div>\n    <div class="user-action-buttons-block">\n        ';
 if (displayLeaveButton === true) { ;
__p += '\n    \t<a class="btn leave-button pull-right">\n\t\t\t<span>' +
((__t = ( _.i18n('common.quit') )) == null ? '' : __t) +
'</span>\n        \t<div class="ripple-wrapper"></div>\n        </a>\n        ';
 } ;
__p += '\n        <a class="btn add-selected-products-button pull-right disabled">\n\t\t\t<span>' +
((__t = ( _.i18n('selectProduct.addSelected') )) == null ? '' : __t) +
'</span>\n        \t<div class="ripple-wrapper"></div>\n        </a>\n    </div>\n</div>';

}
return __p
};});

