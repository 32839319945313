
define('template!editPrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-1 imppCode">' +
((__t = ( imppCode )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-4 name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-2 size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-2 setupCode">' +
((__t = ( setupCode )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-1 unitCode">' +
((__t = ( unitCode )) == null ? '' : __t) +
'</div>\n  ';
 if (!readOnly) { ;
__p += '\n  <div class="col-xs-2 btn-pz-container" style="display: none; height: 12px;">\n    <i class="mdi mdi-editor-mode-edit btn-edit-pz btn-frame pull-right"></i>\n    <i class="mdi mdi-action-delete btn-delete-pz btn-frame pull-right"></i>\n    <i class="mdi mdi-image-color-lens btn-edit-pz-xml btn-frame pull-right"></i>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

