define('ordersDetailSupplierEditProductPrintZoneImagesView',[
    'module',
    'backbone',
    'marionette',
    'underscore',
    'ordersDetailSupplierEditProductPrintZoneImageView'
], function(
    Module,
    Backbone,
    Marionette,
    _,
    ImageView
) {
    'use strict';

    Module.exports = Marionette.CollectionView.extend({
        childView: ImageView,
        className: 'images-view'
    });
});

