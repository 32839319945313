
define('template!adminHtmlTemplatesCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.subject') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 subject-region">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.content') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 content-region">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="templateType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="templateType" value="' +
((__t = ( templateType )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'">\n            <option value="PDF_INVOICE" ';
  if (!templateType || templateType === 'PDF_INVOICE') { ;
__p += ' selected ';
 } ;
__p += ' >PDF_INVOICE</option>\n            <option value="PDF_PROFORMA_INVOICE" ';
  if (!templateType || templateType === 'PDF_PROFORMA_INVOICE') { ;
__p += ' selected ';
 } ;
__p += ' >PDF_PROFORMA_INVOICE</option>\n            <option value="PDF_DELIVERY_NOTE" ';
  if (!templateType || templateType === 'PDF_DELIVERY_NOTE') { ;
__p += ' selected ';
 } ;
__p += ' >PDF_DELIVERY_NOTE</option>\n            <option value="MAIL_OFFER" ';
  if (!templateType || templateType === 'MAIL_OFFER') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_OFFER</option>\n            <option value="MAIL_OFFER_MODIFIED" ';
  if (!templateType || templateType === 'MAIL_OFFER_MODIFIED') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_OFFER_MODIFIED</option>\n            <option value="MAIL_ORDER" ';
  if (!templateType || templateType === 'MAIL_ORDER') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_ORDER</option>\n            <option value="MAIL_ORDER_SUPPLIER" ';
  if (!templateType || templateType === 'MAIL_ORDER_SUPPLIER') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_ORDER_SUPPLIER</option>\n            <option value="MAIL_INVOICE" ';
  if (!templateType || templateType === 'MAIL_INVOICE') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_INVOICE</option>\n            <option value="MAIL_INVOICE_NOT_PAID" ';
  if (!templateType || templateType === 'MAIL_INVOICE_NOT_PAID') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_INVOICE_NOT_PAID</option>\n            <option value="MAIL_ORDER_TRACKING" ';
  if (!templateType || templateType === 'MAIL_ORDER_TRACKING') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_ORDER_TRACKING</option>\n            <option value="MAIL_ORDER_DELIVERED" ';
  if (!templateType || templateType === 'MAIL_ORDER_DELIVERED') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_ORDER_DELIVERED</option>\n            <option value="MAIL_SIGN" ';
  if (!templateType || templateType === 'MAIL_SIGN') { ;
__p += ' selected ';
 } ;
__p += ' >MAIL_SIGN</option>\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

