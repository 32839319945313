define('phoneCallsFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'buttonView',
  'selectView',
  'filtersBannerEditView',
  'template!phoneCallsFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  ButtonView,
  SelectView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'phone-calls-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('phone.calls.filters.edit'),

    ui: _.extend({
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to'
    }, FiltersBannerEditView.prototype.ui),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onRender: function() {
      if(this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if(this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
        dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : ''
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
    }
  });  
});

