
define('template!ordersDetailSupplierEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n  <div class="body-content">\n    <div class="left">\n       <div class="delivery-region"></div>\n    </div>\n    <div class="right">\n      <div class="product-region"></div>\n    </div>\n  </div>\n  <div class="loader-ctn">\n    <div class="loader-2"></div>\n  </div>\n</div>\n<div class="footer">\n  <div class="left">\n    <div class="load">\n      <div class="loader-2"></div>\n      <div class="text"></div>\n    </div>\n  </div>\n  <div class="right">\n    <div class="cancel-region"></div>\n    <div class="send-region"></div>\n  </div>\n</div>';

}
return __p
};});

