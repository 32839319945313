/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin', 'superAdminCAccountTabs'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin, SuperAdminCAccountTabs
) {
  'use strict';

  var SuperAdminRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'superAdmin': 'showSuperAdmin',
      'superAdmin/overview' : 'showOverviewTabSuperAdmin',
      'superAdmin/caccounts' : 'showCaccountsSuperadmin',
      'superAdmin/caccount/:secId(/:tab)': 'showCAccountDetails',
      'superAdmin/caccount/:secId/contacts/:secId/details/:tabName' : 'showContactsDetailsShow'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    checkLayout: function(tab) {
      var defer = $.Deferred();

      require([
        'superAdminLayoutController'
      ], function(ShowController) {
        ShowController.showLayoutSuperAdmin(tab).done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showSuperAdmin: function() {
      App.navigate('superAdmin/overview');
      this.showOverviewTabSuperAdmin();
    },

    showOverviewTabSuperAdmin: function() {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminOverviewController'
          ], function(OverviewController) {
            OverviewController.showSuperAdminTab();
            App.startSubModule('superAdminMain');
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_OVERVIEW);
          });
        });
      });
    },

    showCaccountsSuperadmin: function() {
        var that = this;

        this.checkContent().done(function() {
            that.checkLayout('overview').done(function() {
              require([
                'superAdminCAccountsController'
              ], function(CaccountsController) {
                  CaccountsController.showCaccountList({
                      type: 'caccounts'
                  });
                App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS);
              });
            });
        });
    },

    showCAccountDetails: function(secId, tab) {
        var that = this;
        this.checkContent().done(_.bind(function() {
            that.checkLayout('overview').done(_.bind(function() {
              require([
                'superAdminCAccountController'
              ], function(CaccountController) {
                  CaccountController.fetchCaccount({
                      secId: secId,
                      tab: _.findWhere(SuperAdminCAccountTabs, {name: tab})
                  });
                  App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS);
              });
            }, this));
        }, this));
    },

    showContactsDetailsShow: function(secId, contactSecId, contactSubTab) {
        var that = this;
        this.checkContent().done(_.bind(function() {
            that.checkLayout('overview').done(_.bind(function() {
                require([
                    'caccountContactsDetailsController',
                    'caccountContactsInfoTabController'
                ], function(
                    DetailsShowController,
                    InfoTabController
                ) {
                    var mainRegion;
                    mainRegion = App.regions.getRegion('main').currentView.getRegion('content');
                    DetailsShowController.showTabsLayout(secId, mainRegion, contactSubTab, contactSecId).done(function(region, model) {
                        InfoTabController.showInfoTab(region, model);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
                    });
                });
            }, this));
        }, this));
    }
  };

  App.on('superAdmin:show:cAccountDetails', function(secId){
      App.navigate('superAdmin/caccount/' + secId);
      API.showCAccountDetails(secId);
  });

  App.on('superAdmin:show', function() {
    App.navigate('superAdmin');
    API.showSuperAdmin();
  });

  App.on('before:start', function() {
    new SuperAdminRouter({
      controller: API
    });
  });

  module.exports = SuperAdminRouter;
});
