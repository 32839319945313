/**
 * Created by RKL on 26/08/2015.
 */
define('pluginMain',[
  'module',
  'logger',
  'pluginRouter'
], function(
  module,
  Logger
) {
  'use strict';

  var Plugin = {
    start: function() {
      Logger.log(module, 'starting PluginModule');
    },

    stop: function() {
      Logger.log(module, 'stopping PluginModule');
    }
  };

  module.exports = Plugin;
});
