define('headerSearchView',[
  'app',
  'module',
  'jquery',
  'marionette',
  'underscore',
  'moment',
  'settings',
  'entities/orders',
  'template!headerSearchView',
  'entities/global_search'
], function (
  App,
  Module,
  $,
  Marionette,
  _,  
  moment,
  Settings,
  Orders,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({

    template: Tpl,
    className: 'header-search-view', 
    offerStatuses: [],  
    orderStatuses: [],  

    initialize: function () {    
      
      this.searchResults = [];     

      this.groups = [
        {type: 'product', items: []},
        {type: 'command', items: []},
        {type: 'offer', items: []},
        {type: 'customer', items: []},
        {type: 'pimProduct', items: []}
      ];

      $(window).click(function(e) {
        if($(e.target).closest('.js-search-result') !== undefined)
        $('.js-search-result').hide();
      });

      $(document).keyup(function(e) {        
        if (e.which === 27) $('.js-search-result').hide();   // esc
      });

      var promises = [];
      promises.push(App.request('orders:statuses', 'offer'));
      promises.push(App.request('orders:statuses', 'command'));
  
      $.when.apply($, promises).then(_.bind(function(offerStatusResult,  orderStatusResult) {
        this.offerStatuses = offerStatusResult.models;
        this.orderStatuses = orderStatusResult.models;
      }, this));
    },

    ui: {    
      searchButton: '.js-search-button',
      searchInput: '.js-search-input',
      item: '.js-search-result .item',
      result: '.js-search-result',
      more: '.js-search-result .more',
      less: '.js-search-result .less'
    },

    events: {   
      'click @ui.searchButton': 'onSearchButtonClick',
      'click @ui.item': 'onItemClick',
      'click @ui.searchInput': 'onSearchInputClick',
      'keydown @ui.searchInput': 'onSearchInputChange',
      'click @ui.more': 'onCLickShowMore',
      'click @ui.less': 'onCLickShowLess'
    },

    serializeData: function () {      
      var templateData = {};     
      templateData.currentSearch = this.currentSearch ? this.currentSearch : '';
      return templateData;
    },

    onRender: function () {
            
      _.each(this.groups, _.bind(function (group) {
        
        _.each(group.items, _.bind(function (item, index) {
          var visible = '';

          if(index > 9) {
            visible = 'display:none;';
            this.ui.result.find('.group[data-type="'+ group.type +'"] .more').show();            
          }            

          var html = '<div class="item" data-route="'+ item.route +'" data-index="'+ index +'" style="'+ visible +'">';
            html += '<div class="text">';
              html += item.text;
            html += '</div>';
            html += '<div class="info">';
              if(item.info)
                html += item.info;
            html += '</div>';

            if(group.type === 'command' || group.type === 'offer') {
              html += '<div class="colors">';
              _.each(item.colors, _.bind(function (color) {
                html += '<div class="color" style="background-color: '+ color +'"></div>';
              }, this));

              html += '</div>';
            }
          html += '</div>';

          this.ui.result.find('.group[data-type="'+ group.type +'"]').show();
          this.ui.result.find('.group[data-type="'+ group.type +'"] .items').append(html);

        }, this));
      }, this));
    },
   
    onSearchButtonClick: function (e) {
      if (e) {
        e.stopPropagation();
      }
      
      this.groups = [
        {type: 'product', items: []},
        {type: 'command', items: []},
        {type: 'offer', items: []},
        {type: 'customer', items: []},
        {type: 'pimProduct', items: []}
      ];

      this.searchResults = [];

      this.currentSearch =  this.ui.searchInput.val().trim();         

      if (this.currentSearch && this.currentSearch.length > 2) {

        $('.js-progress-bar').show();

        var cAccountConfig = Settings.get('cAccountConfig');

        App.request('globalsearch', encodeURI(this.currentSearch)).done(_.bind(function(searchResults) {

          if (searchResults.status === 200) {

            this.searchResults = searchResults.responseJSON;

            _.each(searchResults.responseJSON, _.bind(function (searchResult) {        
              var group = _.findWhere(this.groups, {type: searchResult.type}); 

              var route = '';

              if (searchResult.type === 'product') {
                route = 'product/' + searchResult.id;             
              } else if (searchResult.type === 'command') {
                route = 'orders/' + searchResult.id;             
              } else if (searchResult.type === 'offer') {
                route = 'orders/' + searchResult.id;              
              } else if (searchResult.type === 'customer') {
                route = 'customers/' + searchResult.id;             
              } else if (searchResult.type === 'pimProduct') {
                route = 'pim/products/' + searchResult.id;             
              }

              if(group !== null) {
                var item = {   
                  text: '', 
                  info: '',
                  colors: [],
                  route: route
                };

                if (searchResult.type === 'product') {
                  item.text += searchResult.description;
                } else if (searchResult.type === 'pimProduct') {
                  item.text += searchResult.description;
                } else if (searchResult.type === 'command' || searchResult.type === 'offer') {

                  if(searchResult.item.companyName !== undefined && searchResult.item.companyName !== '')
                    item.text = searchResult.item.companyName;

                  if(item.text === '') {                    
                    if(searchResult.item.refUser && (searchResult.item.refUser.firstName || searchResult.item.refUser.lastName)) {
                      
                      item.text += searchResult.item.refUser.firstName;

                      if(searchResult.item.refUser.firstName !== undefined)
                        item.text += ' ';

                      item.text += searchResult.item.refUser.lastName;
                    }
                  }

                  if(item.text === '') {
                    item.text += searchResult.item.endUserId;
                  }

                  if(searchResult.item.productName !== undefined) {
                    if(item.text !== '')
                      item.text += ' | ';

                    item.text += searchResult.item.productName;
                  }

                  if(searchResult.item.date !== undefined) {
                    if(item.text !== '')
                      item.text += ' | ';

                    item.text += moment(searchResult.item.date).format('DD-MM-YY');
                  }

                  if(item.text !== '')
                      item.text += ' | ';

                  item.text += searchResult.id;

                  if(searchResult.item.archived === true)
                    item.text += '<svg class="archived" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"></path></svg>'; // jshint ignore:line

                  //----------

                  if(searchResult.item.status) {
                    var statuses = [];
                    if(searchResult.type === 'command')
                      statuses = this.orderStatuses;
                    if(searchResult.type === 'offer')
                      statuses = this.offerStatuses;

                    _.each(statuses, _.bind(function (status) {
                      if(status.get('statusConst') === searchResult.item.status)
                        item.info += status.get('status');
                    }, this));

                    var selectedIds =  searchResult.item.cardColor?searchResult.item.cardColor.split(','):[];

                    if (cAccountConfig && cAccountConfig.get('cardColors')) {
                      _.each(cAccountConfig.get('cardColors').models, function(color) {
                        _.each(selectedIds, function (rgb) {
                          if(rgb.toUpperCase() === color.get('color').replace('#', '').toUpperCase())
                            item.colors.push(color.get('color'));
                        }, this);
                      }, this);
                    }
                  }
                } 
                else if (searchResult.type === 'customer') {
                  if(searchResult.item.companyName !== undefined && searchResult.item.companyName !== '')
                  item.text = searchResult.item.companyName;

                  if(item.text === '') {
                    if(searchResult.item.firstName !== undefined)
                      item.text = searchResult.item.firstName;

                    if(searchResult.item.lastName !== undefined && searchResult.item.lastName !== '') {
                      if(item.text !== '')
                        item.text += ' ';

                      item.text += searchResult.item.lastName;
                    }
                  }

                  if(item.text === '') {
                    item.text += searchResult.item.email;
                  }
                }

                group.items.push(item);
              }    
            }, this));
            

            $('.js-progress-bar').hide();
            this.render();
            $('.js-search-result').show();

          } else {
            $('.js-progress-bar').hide();
            console.log(_.i18n('common.request.error') + ' ' + searchResults.responseJSON);
          }
        }, this));
      }
    },

    onCLickShowMore: function(e) {

      e.stopPropagation();    

      var groupEl = $(e.target).closest('.group');

      groupEl.find('.item').show();  

      groupEl.find('.more').hide();   
      groupEl.find('.less').show();         
    },

    onCLickShowLess: function(e) {

      e.stopPropagation();  

      var groupEl = $(e.target).closest('.group');

      _.each(groupEl.find('.item'), _.bind(function (item) {

        if(parseInt($(item).attr('data-index'), 10) > 9)
          $(item).hide();
        else
          $(item).show();

      }, this));

      groupEl.find('.more').show();   
      groupEl.find('.less').hide();   
    },

    onSearchInputClick: function () {
      if (this.searchResults.length > 0) {
        setTimeout(function () {
          $('.js-search-result').show();
        }, 10);
      }
    },

    onSearchInputChange: function (e) {
      var charCode = (e.which) ? e.which : e.keyCode;

      if (charCode === 13) {
        e.preventDefault();
        this.onSearchButtonClick();
      } else {
        $('.js-search-result').hide();      
      }
    },   

    onItemClick: function (e) {
      e.stopPropagation();
      $('.js-search-result').hide();
      var newRoute = $(e.target).closest('.item').attr('data-route');
      App.trigger('view:destroy', newRoute);

      if(newRoute.startsWith('orders') || newRoute.startsWith('offers')) {
        var items = newRoute.split('/');
        if(items.length > 1)          
          Orders.showOrder(items[1], '');      
      }
      else
        App.navigate(newRoute, {trigger: true});

    }
  });
});
