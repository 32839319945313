define('usersView',[
  'module',
  'marionette',
  'backbone',
  'underscore',
  'events',
  'settings',
  'userSelectorView',
  'userView',
  'template!usersView'
], function (
  Module,
  Marionette,
  Backbone, 
  _,
  Events,
  Settings,
  UserSelectorView,
  UserView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'users-view',
    childView: UserView,
    childViewContainer: '.users-container',
    childViewOptions: function () {
      return {
        showTitle: false
      };
    },

    attributes: function () {
      return {
        'tabindex': this.options.tabindex || 0
      };
    },

    ui: {
      addButton: '.add-button',
      usersContainer: '.users-container'
    },

    events: {
      'click': 'onEdit',
      'focus': 'onFocus',
      'blur': 'onBlur'
    },

    modelEvents: {
      'change:userpids': 'setCollection'
    },

    initialize: function() {
      this.multiselect = true;
      if(this.options.multiselect === false)
        this.multiselect = false;
      this.allCollection = new Backbone.Collection();
      this.collection = new Backbone.Collection();
      this.focused = false;
    },

    onDestroy: function() {
      Events.pop(this);
    },

    setCollection: function() {
      this.allCollection = new Backbone.Collection(this.model.get('userpids'));
      this.renderContent();
    },

    onShow: function() {
      this.setCollection();
      this.fetch();
    },

    onRender: function() {
      this.renderButton();
      this.renderTitle();
    },

    renderContent: function() {    
      this.fetch();
      this.renderButton();
      this.renderTitle();

      if(this.options.readOnly === true)
        this.$el.addClass('read-only');
      else
        this.$el.removeClass('read-only');
    },

    fetch: function() {
      this.collection.reset();
     
      var currentUser = null;   
      
      _.each(this.allCollection.models, _.bind(function(user) {
        if(user.get('secId') === Settings.get('currentUser').secId)
          currentUser = user;
      }, this));

      var cpt = 3;

      if(currentUser !== null) {
        cpt = 2;
        _.each(this.allCollection.models, _.bind(function(user) {
          if(currentUser.get('secId') !== user.get('secId')) {
            if(cpt-- > 0)
              this.collection.push(user);
          }

        }, this));
      } else {
        cpt = 3;
        _.each(this.allCollection.models, _.bind(function(user) {
          if(cpt-- > 0)
            this.collection.push(user);

        }, this));
      }

      if(currentUser !== null)
        this.collection.push(currentUser);
    },

    renderButton: function() {
      if(this.options.readOnly === true) {
        this.ui.addButton.css('display', 'none');
        return;
      }

      if(this.allCollection.models.length === 0)
        this.ui.addButton.css('display', 'flex');
      else
        this.ui.addButton.css('display', 'none');
    },

    renderTitle: function() {
      var title = '';

      _.each(this.allCollection.models, _.bind(function(userpid) {
        var fullName = '';

        if(userpid.get('firstName'))
          fullName += userpid.get('firstName');
        if(userpid.get('lastName')) {
          if(fullName !== '')
            fullName += ' ';
          fullName += userpid.get('lastName');
        }

        if(fullName) {
          if(title !== '')
            title += ' | ';
          title += fullName;
        }         
      }, this));   

      this.ui.usersContainer.attr('title', title);
    },

    onEdit: function () {
      if(this.options.readOnly === true)
        return;

      UserSelectorView.prototype.open({
        users: this.allCollection.models,
        multiselect: this.multiselect,
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          if(!this.multiselect)
            this.allCollection.reset();

          if(this.options.onSelected) {
            this.options.onSelected(user).done(_.bind(function() {
              this.allCollection.push(user);
              this.renderContent();
              deferred.resolve();
            }, this));
          } else {
            this.allCollection.push(user);
            this.renderContent();
            deferred.resolve();
          }
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          if(this.options.onUnSelected) {
            this.options.onUnSelected(user).done(_.bind(function() {
              this.allCollection.remove(user);
              this.renderContent();
              deferred.resolve();
            }, this));
          } else {
            this.allCollection.remove(user);
            this.renderContent();
            deferred.resolve();
          }
          return deferred;
        }, this)
      });
    },

    onFocus: function() {
      Events.push(this);
      this.focused = true;
    },

    onBlur: function() {
      Events.pop(this);
      this.focused = false;
    },

    onKeyDown: function(e) {
      if (e.which === 32 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        this.onEdit();
        return false;
      }
    }
  });
});
  
