/**
 * Created by OLD on 25/11/2015.
 */
define('billingDetailPopupView',[
  'module',
  'template!priceBillingDetailPopupTpl',
  'dialogFormView'
], function(
  module,
  viewTpl,
  DialogFormView
  ) {
  'use strict';

  var AddNewCodeView = DialogFormView.extend({
    template: viewTpl,

    ui: {
      input: '.js-form-input'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus'
    },

    onShow: function() {
      $.material.init();
    }
  });

  module.exports = AddNewCodeView;
});
