define('translationsPopupFormView',[
  'module',
  'marionette',
  'template!translationsPopupFormTpl',
  'dialogFormView',
  'translationsFilterLanguagesLayoutView',
  'translationsPopupTextsView',
  'app',
  'backbone',
  'stateMessages',
  'duplicatePopupBehavior',
  'underscore',
  'entities/languages',
  'entities/translations'
], function(
  module,
  Marionette,
  formTpl,
  DialogFormView,
  FilterLanguagesLayoutView,
  TextsView,
  App,
  Backbone,
  StateMessagesBehavior,
  DuplicatePopupBehavior,
  _
) {
  'use strict';

  var TranslationsFormView = DialogFormView.extend({
    template: formTpl,

    regions: {
      languages: '.js-languages-region',
      translations: '.js-translations-region'
    },

    ui: {
      save: '.js-save',
      cancel: '.js-cancel',
      inputs: 'input'
    },

    events: {
      'click @ui.save': '_onSave',
      'click @ui.cancel': '_onCancel',
      'change @ui.inputs': 'onInputChange'
    },

    modelEvents: {
      request: '_onLoading',
      sync: 'hide',
      saved: '_onSaved',
      error: '_onSaveError'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessagesBehavior
      },
      DuplicatePopup: {
        behaviorClass: DuplicatePopupBehavior
      }
    },

    dropdownShown: false,

    initialize: function(options) {
      if(!options.translationsModel) {
        this.model = App.request('translations:model');
        this.model.get('text').localized = {};
      } else {
        this.model = App.request('translations:model', options.translationsModel.toJSON());
      }
      this.localizedModel = new Backbone.Model(this.model.get('text').localized);
    },

    onShow: function() {
      var that = this,
        filterLanguagesView,
        textView;

      App.request('language:collection').getLanguages().done(function(languageCollection){
        that.languageCollection = languageCollection;
        that.languageCollection.sort();
        filterLanguagesView = new FilterLanguagesLayoutView({
          collection: that.languageCollection
        });
        textView = new TextsView({
          collection: that.languageCollection,
          model: that.localizedModel
        });
        that.listenTo(filterLanguagesView, 'show:dropdown', function() {
          that.dropdownShown = true;
        });
        that.listenTo(filterLanguagesView, 'hide:dropdown', function() {
          that.dropdownShown = false;
        });
        textView.listenTo(filterLanguagesView, 'hide:dropdown', textView.enableDeletes);
        textView.listenTo(filterLanguagesView, 'show:dropdown', textView.disableDeletes);
        that._checkLanguageCollection(that._getAbbreviationsToShow(that.model.get('text').localized));

        that.getRegion('languages').show(filterLanguagesView);
        that.getRegion('translations').show(textView);
        $.material.init();
      });


    },

    _onLoading: function() {
      this.triggerMethod('show:message', _.i18n('common.loading'));
    },

    _onSave: function() {
      if(this.dropdownShown) {
        return;
      }
      this.model.get('text').localized = this.localizedModel.toJSON();
      this.model.save();
    },

    _onCancel: function() {
      if(this.dropdownShown) {
        return;
      }
      this.hide();
    },

    _onSaved: function() {
      if(this.options.translationsModel) {
        this.options.translationsModel.set(this.model.toJSON());
      }
      this.trigger('translation:saved', this.model.clone());
      this.hide();
    },

    _onSaveError: function(model, resp) {
      this.triggerMethod('error:message', resp.statusText);
    },

    _checkLanguageCollection: function(languageAbbreviations) {
      var that = this,
        languageModel;
      _.each(languageAbbreviations, function(abbreviation) {
        languageModel = that.languageCollection.get(abbreviation);
        if(languageModel) languageModel.set('checked', true);
      });
    },

    _getAbbreviationsToShow: function(localized) {
      var abbreviations = [];
      _.each(localized, function(text, locale) {
        abbreviations.push(locale);
      });
      if(!abbreviations.length) {
        abbreviations.push('en');
      }

      return abbreviations;
    }

  });

  module.exports = TranslationsFormView;
});
