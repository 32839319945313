
define('template!pdfView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<iframe class="content"></iframe>\n';

}
return __p
};});

