/**
 * Created by OBL on 14-12-15.
 */
define('cataloguesSharedController',[
    'module',
    'underscore',
    'app',
    'cataloguesSharedView',
    'cataloguesSharedAddView',
    'settings',
    'bootbox',
    'logotoolUtils',
    'entities/catalogues'
], function(
    module,
    _,
    App,
    View,
    AddView,
    Settings,
    bootbox,
    logotoolUtils
    ) {
    'use strict';

    var CataloguesSharedController = {

        addShare: function( secId) {
            this.view = new View();
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this, secId));
            this.view.listenTo(this.view , 'catalogueShared:preview', _.bind(this.onPreviewCatalogue, this));
            this.view.listenTo(this.view , 'catalogueShared:remove', _.bind(this.onRemoveShare, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
        },

        showCatalogues: function(data) {
            this.view = new View({data: data});
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.view.listenTo(this.view , 'catalogueShared:preview', _.bind(this.onPreviewCatalogue, this));
            this.view.listenTo(this.view , 'catalogueShared:remove', _.bind(this.onRemoveShare, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
        },

        onGridLoaded: function(secId) {

            var that = this;
            var data = {
                url: App.request('catalogue:shared:get-collection-url'),
                rowCountUrl: App.request('catalogue:shared:row-count-url')
            };

            this.view.displayGrid(data);

            //add share
            if (secId) {
                App.request('catalogue:entity', secId).done(function (object) {
                    that.popupView = new AddView({
                        model: object
                    });
                    that.popupView.listenTo(that.popupView, 'catalogue:share:add', _.bind(that.onAcceptShare, that));
                    that.popupView.listenTo(that.popupView, 'catalogue:share:cancel', _.bind(that.onCancelShare, that));
                    that.popupView.show({
                        title: _.i18n('catalogues.shared.add.title')
                    });
                    $.material.init();
                });
            }
        },

        onCancelShare: function() {
            App.navigate('catalogues/shared', {trigger: true});
        },

        onAcceptShare: function(secId) {
            App.request('catalogue:acceptShare', secId)
                .done(_.bind(function(){
                        this.view.updatePagination('reloadGrid');
                        this.popupView.hide();
                    }, this))
                .fail(function(data){
                    if(data.responseText === 'AutoShare'){
                        bootbox.alert(_.i18n('catalogues.shared.add.autoshare'));
                        this.popupView.hide();
                    }
                });
        },

        onRemoveShare: function(secId){
            bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('catalogue:shared:delete', secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        },

        onPreviewCatalogue: function(secId){
            var url = logotoolUtils.getLogotoolCatalogueUrl({
                catalogueSecId: secId
            });
            var win = window.open(url, '_blank');
            win.focus();

        }
    };

    module.exports = CataloguesSharedController;
});
