define('productTabsData',[
  'module',
  'underscore',
  'rolesMixin'
], function(
  module,
  _,
  RolesMixin
) {
  'use strict';

  module.exports = [
    {
      name: 'price',
      title: _.i18n('menu.price'),
      role: RolesMixin.DASHBOARD_ORDERS_PRICE
    }, {
      name: 'prices',
      title: _.i18n('productDetails.prices.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PRICE
    }, {
      name: 'productdata',
      title: _.i18n('productDetails.productdata.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }, {
      name: 'translations',
      title: _.i18n('productDetails.translations.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }, {
      name: 'share',
      title: _.i18n('productDetails.share.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }, {
      name: 'filters',
      title: _.i18n('productDetails.filters.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }, {
      name: 'overview',
      title: _.i18n('menu.overview')
    }, {
      name: 'pictures',
      title: _.i18n('productDetails.pictures.tabTitle')
    }, {
      name: 'customfields',
      title: _.i18n('productDetails.customfields.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }, {
      name: 'stock',
      title: _.i18n('productDetails.stock.tabTitle'),
      role: RolesMixin.DASHBOARD_PRODUCTS_TRANSLATIONS
    }
  ];
});

