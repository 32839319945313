define('adminGlossariesListView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'tableView',
  'adminGlossariesListRowToolsView',
  'adminGlossariesListHeaderToolsView',
  'adminGlossariesListFiltersView',
  'adminGlossariesListCodeView',
  'adminGlossariesListTranslationView',
  'adminGlossariesListTypeView',
  'adminGlossariesListMissingView',
  'entities/admin/glossaries'
], function (
  Module,
  App,
  Backbone,
  _,
  TableView,
  RowToolsView,
  HeaderToolsView,
  FiltersView,
  CodeView,
  TranslationView,
  TypeView,
  MissingView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'admin-glossaries-list-view ' + TableView.prototype.className,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    setFields: function () {
      return [{
        name: 'Type',
        label: _.i18n('common.type'),
        className: 'type',
        bind: 'type',
        view: TypeView,
        options: [
          {id: 'color', value: _.i18n('glossaries.type.color')},
          {id: 'impt', value: _.i18n('glossaries.type.impt')},
          {id: 'material', value: _.i18n('glossaries.type.material')},
          {id: 'other', value: _.i18n('glossaries.type.other')},
          {id: 'paName', value: _.i18n('glossaries.type.paName')}
        ]
      }, {
        name: 'Code',
        label: _.i18n('common.code'),
        className: 'code',
        bind: 'code',
        view: CodeView
      }, {
        name: 'FR',
        label: 'FR',
        className: 'fr',
        lang: 'fr',
        view: TranslationView
      }, {
        name: 'EN',
        label: 'EN',
        className: 'en',
        lang: 'en',
        view: TranslationView
      }, {
        name: 'DE',
        label: 'DE',
        className: 'de',
        lang: 'de',
        view: TranslationView
      }, {
        name: 'ES',
        label: 'ES',
        className: 'es',
        lang: 'es',
        view: TranslationView
      }, {
        name: 'IT',
        label: 'IT',
        className: 'it',
        lang: 'it',
        view: TranslationView
      }, {
        name: 'NL',
        label: 'NL',
        className: 'nl',
        lang: 'nl',
        view: TranslationView
      }, {
        name: 'DA',
        label: 'DA',
        className: 'da',
        lang: 'da',
        view: TranslationView
      }, {
        name: 'Missing',
        label: _.i18n('glossary.missingTranslation'),
        className: 'missing',
        bind: 'missingTranslation',
        view: MissingView
      }, {
        name: 'Buttons',
        className: 'buttons',
        view: RowToolsView,
        headerView: HeaderToolsView
      }];
    },

    fetch: function (first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params.sidx = '';
      params.sord = 'ask';

      if (this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('glossaries:get-url', params),
        success: _.bind(function (items) {
          var models = [];
          _.each(items, _.bind(function (item) {
            models.push(App.request('glossaries:model', item));
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function (filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if (this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        url: App.request('glossaries:row-count-url', params),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getFiltersParams: function (filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if (value !== '')
          params[key] = value;
      }, this);

      return params;
    }
  });
});
