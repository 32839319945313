define('timetrackerFromTicketController',[
  'module',
  'logger',
  'underscore',
  'app',
  'backbone',
  'timetrackerFromTicketView',
  'galleryView',
  'moment',
  'settings',
  'entities/timetracker'
], function(
  module,
  Logger,
  _,
  App,
  Backbone,
  View,
  GalleryView,
  moment
) {
  'use strict';

  var Timetracker = {
    showTimetracker: function(gpaphicalService, secId) {
      var parsed,
          view = new View(), 
          timetrackerRequest = App.request(
            'timetracker:get-from-ticket', 
            secId
          );

      this.view = view;
      this.view.gpaphicalService = gpaphicalService;
      this.view.secId = secId;

      $.when(timetrackerRequest)
        .done(_.bind(function(data) {
          parsed = _.compact(_.map(data, function (item) {
            if (item.timeTrackingSnapshotJsons.length !== 0) {
              return {
                user: item.useerpidJson,
                start: moment(item.fromDate).startOf('day').format('DD/MM/YYYY, HH:mm:ss'),
                end: moment(item.toDate).endOf('day').format('DD/MM/YYYY, HH:mm:ss'),
                snaptshots: item.timeTrackingSnapshotJsons,
                task: item.comment,
                secId: item.secId
              };
            }
          }));

          view.model = new Backbone.Model(parsed);
          App.regions.getRegion('main').currentView.getRegion('content').show(view);

          view.listenTo(view, 'timetracker:onZoom', _.bind(this.onZoom, this));
          view.listenTo(view, 'timetracker:onDelete', _.bind(this.onDelete, this));
        }, this));
    },

    onZoom: function(id) {
      var that = this, 
        data = this.parseZoomData();

        this.galleryView = new GalleryView({
          id: id,
          data: data,
          onLoadEnd: function() {
            that.galleryLoading = false;
          }
        });

      if (!this.galleryLoading) {
        this.galleryLoading = true;
        App.regions.getRegion('main').currentView.getRegion('zoom').show(this.galleryView);
      }
    },

    onDelete: function(userSecId, screenSecId) {
      var that = this, 
        ttDeleteRequest = App.request('timetracker:delete-screen', userSecId, screenSecId);

      $.when(ttDeleteRequest).done(function() {
        that.view.render();
      });
    },

    parseZoomData: function() {
      var screens = [];

      _.map(this.view.model.toJSON(), function(item) {
        _.filter(item.snaptshots, function(snaptshots) {
          if (snaptshots.length !== 0) {
            var model = new Backbone.Model({
              downloadLink: snaptshots.fileUrl,
              fileLink: snaptshots.fileUrl,
              fileName: snaptshots.date,
              id: snaptshots.fileUid
            });
            screens.push(model);
          }
        });
      });
      return screens;
    }
  };

  module.exports = Timetracker;
});
