/**
 * Created by dev on 10-02-16.
 */
define('autoLoginRouter',[
    'require',
    'exports',
    'module',
    'marionette',
    'app'
], function(
    require,
    exports,
    module,
    Marionette,
    App
    ) {
    'use strict';

    var AutoLoginRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'autoLogin/:userSecId': 'autoLogin'
        }
    });

    var API = {
        autoLogin: function(user, tokenId){
            require(['autoLoginController'], function(Controller) {
                App.startSubModule('autoLoginMain');
                Controller.autoLogin(user, tokenId.split('=')[1]);
            });
        }
    };

    App.on('autoLogin', function() {
        App.navigate('login');
        API.autoLogin();
    });

    App.on('before:start', function() {
        new AutoLoginRouter({
            controller: API
        });
    });

    module.exports = AutoLoginRouter;
});

