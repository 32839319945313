/**
 * Created by RKL on 21/08/2015.
 */
define('mailingShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'mailingShowView',
  'settings'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var MailingController = {
    showMailing: function() {
      var view = new View();

      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    }
  };

  module.exports = MailingController;
});
