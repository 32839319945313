define('ordersListFiltersLabelsView',[
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore',
  'ordersListFiltersLabelView'
], function (
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  LabelView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'orders-list-filters-labels-view',
    childView: LabelView,

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {  
      this.collection.reset();
      var cAccountConfig = Settings.get('cAccountConfig');
      if (cAccountConfig && cAccountConfig.get('cardColors') &&
          this.options.selectedIds) {
        _.each(cAccountConfig.get('cardColors').models, function(color) {
          _.each(this.options.selectedIds.split(','), function (rgb) {
            if(rgb.toUpperCase() === color.get('color').replace('#', '').toUpperCase())
              this.collection.add(color);
          }, this);
        }, this);
      }
    }
  });
});
