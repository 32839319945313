/**
 * Created by OLD on 20/08/2015.
 */
define('vectorRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin
) {
  'use strict';

  var VectorizationRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'vectorization': 'showVectorization',
      'vectorization/:secId' : 'showVectorization'
    }
  });

  var API = {
    showVectorization: function(secId) {
      require([
        'vectorShowController',
        'contentShowController',
        'ticketsController'
      ], function(ShowController, ContentShowController, TicketsController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('vectorMain');
          if (!secId) {
            ShowController.showVectorization();
          } else {
            TicketsController.showTickets(secId);
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICALREQUESTS_VECTORIZATION);
        });
      });
    }
  };

  App.on('vectorization:show', function(secId) {
    if (secId) {
      App.navigate('vectorization/' + secId);
    } else {
      App.navigate('vectorization');
    }
    API.showVectorization(secId);
  });

  App.on('before:start', function() {
    new  VectorizationRouter({
      controller: API
    });
  });

  module.exports = VectorizationRouter;
});
