define('webshopCategoriesDetailBodyCategoryView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'selectView',
  'checkboxView',
  'webshopCategoriesDetailBodyCategoryIndexesView',
  'template!webshopCategoriesDetailBodyCategoryView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  SelectView,
  CheckboxView,
  IndexesView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-category-view',

    ui: {
      label: '.category-label input',
      code: '.category-code input'
    },

    modelEvents: {
      'change:label': 'renderLabel',
      'change:code': 'renderCode',
      'change:status': 'renderStatus'
    },

    regions: {
      statusRegion: '.status-region',
      indexesRegion: '.indexes-region',
      layoutRegion: '.layout-region',
      productsLayoutRegion: '.products-layout-region',
      defaultSortRegion: '.default-sort-region'
    },

    events: {
      'change @ui.label': 'onChangeLabel',
      'change @ui.code': 'onChangeCode'
    },

    serializeData: function () {
      var data = {};

      data.showNoindex = false;
      if(Settings.configValue('webshop.categories.showNoindex'))
        data.showNoindex = true;

      data.showPageLayout = false;
      if(Settings.configValue('webshop.categories.showPageLayout'))
        data.showPageLayout = true;

      return data;
    },

    onRender: function () {
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '0',  value: _.i18n('common.disabled')},
          {id: '1',  value: _.i18n('common.enabled')},
          {id: '-1', value: _.i18n('common.review')}
        ])
      });
      this.statusRegion.show(this.statusView);
      this.statusView.on('change', _.bind(this.onStatusChange, this));

      //--------------------
      if(Settings.configValue('webshop.categories.showPageLayout')) {
        this.layoutView = new SelectView({
          collection: new Backbone.Collection([
            {id: '', value: _.i18n('webshop.category.layouts.fromSettings')},
            {id: 'v1', value: 'V1'},
            {id: 'v2', value: 'V2'},
            {id: 'v3', value: 'V3: Groupe de produits'},
            {id: 'v4', value: 'V4: Standard pagination'}
          ])
        });
        this.layoutRegion.show(this.layoutView);
        this.layoutView.on('change', _.bind(this.onLayoutChange, this));
        this.layoutView.setSelected(this.model.getLayout());

        this.productsLayoutView = new SelectView({
          collection: new Backbone.Collection([
            {id: '', value: _.i18n('webshop.product.layouts.fromSettings')},
            {id: 'v1', value: 'V1'},
            {id: 'v2', value: 'V2'}
          ])
        });
        this.productsLayoutRegion.show(this.productsLayoutView);
        this.productsLayoutView.on('change', _.bind(this.onProductsLayoutChange, this));
        this.productsLayoutView.setSelected(this.model.getProductsLayout());
      }

      //--------------------
      if(Settings.configValue('webshop.categories.showNoindex')) {
        this.indexesView = new IndexesView({
          category: this.model,
          webshopConfig: this.options.webshopConfig
        });
        this.indexesRegion.show(this.indexesView);
      }

      //--------------------
      this.defaultSortView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: ''},
          {id: '+relevance', value: _.i18n('webshop.product.sort.relevance')},
          {id: '-name', value: _.i18n('webshop.product.sort.name-')},
          {id: '+name', value: _.i18n('webshop.product.sort.name+')},
          {id: '-price', value: _.i18n('webshop.product.sort.price-')},
          {id: '+price', value: _.i18n('webshop.product.sort.price+')}
        ])
      });
      this.defaultSortRegion.show(this.defaultSortView);
      this.defaultSortView.on('change', _.bind(this.onDefaultSortChange, this));
      this.defaultSortView.setSelected(this.model.getDefaultSort());

      //--------------------
      this.renderLabel();
      this.renderCode();
      this.renderStatus();
    },

    renderLabel: function () {
      this.ui.label.val(this.model.getLabel());
    },

    renderCode: function () {
      this.ui.code.val(this.model.getCode());
    },

    renderStatus: function () {
      this.statusView.setSelected(this.model.getStatus());
    },

    onStatusChange: function () {
      this.model.setStatus(this.statusView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
          this.renderStatus();
        }, this));
      }, this)).fail(_.bind(function () {
        this.model.fetch();
        this.renderStatus();
      }, this));
    },

    onChangeLabel: function () {
      this.model.setLabel(this.ui.label.val()).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onChangeCode: function () {
      this.model.setCode(this.ui.code.val()).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onLayoutChange: function () {
      this.model.setLayout(this.layoutView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onProductsLayoutChange: function () {
      this.model.setProductsLayout(
          this.productsLayoutView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    },

    onDefaultSortChange: function () {
      this.model.setDefaultSort(this.defaultSortView.getSelected().get('id')).done(_.bind(function () {
        this.model.fetch();
      }, this)).fail(_.bind(function () {
        this.model.fetch();
      }, this));
    }
  });
});
