define('adminWebhookHistoryContentView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'template!adminWebhookHistoryContentView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-webhook-history-content-view ' + FieldView.prototype.className,

    serializeData: function() {
      var data = {};
      data.content = '';

      if(this.model.get('type') === 'INVOICE_CREATED') {
        if(this.model.get('afterObject')) {
          try {
            var obj = JSON.parse(this.model.get('afterObject'));

            if((obj.invoice && obj.invoice.formatedInvoiceNumber))
              data.content = obj.invoice.formatedInvoiceNumber;
          } catch (e) {
            console.error(e);
          }
        }
      }

      return data;
    }
  });
});
