
define('template!caccountDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="account-detail-statistic">\t\n\t<div class="row">\n\t\t<!-- left-part -->\n\t\t<div class="col-lg-6 left-statistic">\n\t\t\t';
 if (orderRole) { ;
__p += '\n\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t<div class="well shadow-1 customers-st">\n\t\t\t\t\t<div class="col-xs-3 left-well-area">\n\t\t\t\t\t\t<i class="mdi-social-group"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t\t<p class="count">' +
((__t = ( customers )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t\t<label class="st-caption">' +
((__t = ( _.i18n('stats.customers') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t';
 if (offerRole) { ;
__p += '\n\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t<div class="well shadow-1 customers-st">\n\t\t\t\t\t<div class="col-xs-3 left-well-area">\n\t\t\t\t\t\t<i class="mdi-social-group"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t\t<p class="count">' +
((__t = ( offersCount )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t\t<label class="st-caption">' +
((__t = ( _.i18n('stats.offers') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t<div class="well shadow-1 customers-st">\n\t\t\t\t\t<div class="col-xs-3 left-well-area">\n\t\t\t\t\t\t<i class="mdi-social-group"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t\t<p class="count">' +
((__t = ( ordersCount )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t\t<label class="st-caption">' +
((__t = ( _.i18n('stats.orders') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t<div class="well shadow-1 vectors-st">\n\t\t\t\t\t<div class="col-xs-3 left-well-area">\n\t\t\t\t\t\t<i class="mdi-image-panorama"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t\t<p class="count">' +
((__t = ( vectors )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t\t<label class="st-caption">' +
((__t = ( _.i18n('stats.requests') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t<div class="well shadow-1 credits-st">\n\t\t\t\t\t<div class="col-xs-3 left-well-area">\n\t\t\t\t\t\t<i class="mdi-action-payment"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t\t<p class="count">' +
((__t = ( credits )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t\t<label class="st-caption">' +
((__t = ( _.i18n('stats.credits') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="col-xs-12 well shadow-1 canvas-container">\n\t\t\t\t<p class="title-st">' +
((__t = ( _.i18n("stats.lastRequests") )) == null ? '' : __t) +
'</p>\n\t\t\t\t<div id="requests-graph-region"></div>\n\t\t\t</div>\n\t\t\t';
 if (orderRole) { ;
__p += '\n\t\t\t<div class="col-xs-12 well shadow-1 canvas-container">\n\t\t\t\t<p class="title-st">' +
((__t = ( _.i18n("stats.orders") )) == null ? '' : __t) +
'</p>\n\t\t\t\t<div id="orders-graph-region"></div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\n\t\t<!-- right part -->\n\t\t<div class="col-lg-6 right-statistic">\n\t\t\t';
 if (orderRole) { ;
__p += '\n\t\t\t<div class="col-xs-12 well shadow-1 canvas-container month-activity">\n\t\t\t\t<p class="p-l-0 col-xs-12 title-st">' +
((__t = ( _.i18n("stats.monthActivity") )) == null ? '' : __t) +
'</p>\n\t\t\t\t<div class="p-0 col-xs-4">\n\t\t\t\t\t<label class="p-0 col-xs-9 progress-title-st">\n\t\t\t\t\t\t' +
((__t = ( _.i18n("stats.newOrders") )) == null ? '' : __t) +
'\n\t\t\t\t\t</label>\n\t\t\t\t\t<label class="col-xs-3 progress-label-st p-0">\n\t\t\t\t\t\t' +
((__t = ( newOrder )) == null ? '' : __t) +
'&#37\n\t\t\t\t\t\t<i class="icon-per mdi-action-trending-up"></i>\n\t\t\t\t\t</label>\n\t\t\t\t\t<div class="p-l-0 col-xs-12">\n\t\t\t\t\t\t<div class="progress new-orders-st">\n\t\t\t\t\t\t\t<div class="progress-bar primary-bg" style="width:' +
((__t = ( newOrder )) == null ? '' : __t) +
'%"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<label class="p-0 col-xs-9 progress-title-st">\n\t\t\t\t\t\t' +
((__t = ( _.i18n("stats.ordersInProgress") )) == null ? '' : __t) +
'\n\t\t\t\t\t</label>\n\t\t\t\t\t<label class="col-xs-3 progress-label-st p-0">\n\t\t\t\t\t\t' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
'&#37\n\t\t\t\t\t\t<i class="icon-per st-d mdi-action-trending-down"></i>\n\t\t\t\t\t</label>\n\t\t\t\t\t<div class="p-l-0 col-xs-12">\n\t\t\t\t\t\t<div class="progress orders-in-progress-st">\n\t\t\t\t\t\t\t<div class="progress-bar action-bg" style="width:' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
'%"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-5 p-0">\n                    <div id="month-graph-region"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-3 p-0">\n\t\t\t\t\t<div class="col-xs-12 p-0">\n\t\t\t\t\t\t<i class="mdi-av-stop primary-cl"></i>\n\t\t\t\t\t\t<label class="legend-st">\n\t\t\t\t\t\t\t' +
((__t = ( month.done )) == null ? '' : __t) +
'(' +
((__t = ( newOrder )) == null ? '' : __t) +
')&#37\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-12 p-0">\n\t\t\t\t\t\t<i class="mdi-av-stop action-cl"></i>\n\t\t\t\t\t\t<label class="legend-st">\n\t\t\t\t\t\t\t' +
((__t = ( month.inProgress )) == null ? '' : __t) +
'(' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
')&#37\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="col-xs-12 well shadow-1">\n\t\t\t\t<p class="p-l-0 col-xs-12 title-st">' +
((__t = ( _.i18n("stats.topproducts") )) == null ? '' : __t) +
'</p>\n\t\t\t\t<table class="table table-striped table-hover">\n\t\t\t\t\t<thead>\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<th>' +
((__t = ( _.i18n("product.code") )) == null ? '' : __t) +
'</th>\n\t\t\t\t\t\t\t<th>' +
((__t = ( _.i18n("product.name") )) == null ? '' : __t) +
'</th>\n\t\t\t\t\t\t\t<th>' +
((__t = ( _.i18n("stats.topproducts.count") )) == null ? '' : __t) +
'</th>\n\t\t\t\t\t\t</tr>\n\t\t\t\t\t</thead>\n\t\t\t\t\t<tbody>\n\t\t\t\t\t\t';
 _.each(products, function(item) { ;
__p += '\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<td>' +
((__t = ( item.code )) == null ? '' : __t) +
'</td>\n\t\t\t\t\t\t\t<td>' +
((__t = ( item.name )) == null ? '' : __t) +
'</td>\n\t\t\t\t\t\t\t<td>' +
((__t = ( item.count )) == null ? '' : __t) +
'</td>\n\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t';
 }); ;
__p += '\t\t\t\t\t\n\t\t\t\t\t</tbody>\n\t\t\t\t</table>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

