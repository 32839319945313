/**
 * Created by RKL on 07/09/2015.
 */
define('customersListController',[
  'module',
  'underscore',
  'app',
  'jquery',
  'customersListView',
  'settings',
  'bootbox',
  'entities/customers/customers'
], function(
  module,
  _,
  App,
  $,
  View,
  Settings,
  bootbox
) {
  'use strict';
  
  var CustomersListController = {
    showCustomersList: function() {
      var that = this;

      if(!Settings.get('countries')) {
        App.request('caccount:countries').done(function(countries) {
          that._showView(countries.toJSON());
        });
      } else {
        this._showView(Settings.get('countries'));
      }
    },

    onGridLoaded: function() {
      var data = {
          url: App.request('customers:get-url'),
          rowCountUrl: App.request('customers:row-count-url')
        };

      this.view.displayGrid(data, true);
    },

    _showView: function(countries) {
      var view = new View({
        countries: countries
      });

      this.view = view;

      view.listenTo(this.view, 'customers:import-excel', _.bind(this.onImportExel, this));
      view.listenTo(this.view, 'customers:import-excel-contacts', _.bind(this.onImportExcelContacts, this));
      view.listenTo(this.view, 'customers:remove', _.bind(this.onDelete, this));
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      var exportUrl = Settings.url('compuzz2', 'endusers.json/excel');
      view.ui.exportBtn.attr('href', exportUrl);
    },

     onImportExel: function(fileInfo) {

          $.when(App.request('customers:upload-excel', fileInfo)).done(_.bind(function() {
            this.view.updatePagination('reloadGrid');
          }, this));
      },

      onImportExcelContacts: function(fileInfo) {

          $.when(App.request('customers:upload-excel-contacts', fileInfo)).done(_.bind(function() {
              this.view.updatePagination('reloadGrid');
          }, this));
      },

      onDelete: function(secId){
          bootbox.confirm(_.i18n('customer.confirmDelete'), _.bind(function(result) {
              if(result){
                  App.request('customer:delete', secId)
                      .done(_.bind(function(){
                          this.view.updatePagination('reloadGrid');
                      }, this));
              }
          }, this));
      }
  };

  module.exports = CustomersListController;
});
