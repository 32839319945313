define('popupVariationView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'settings',
  'dynamicTextView',
  'dialogFormView',
  'template!popupVariationTpl'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  Settings,
  DynamicTextView,
  DialogFormView,
  popupVariationTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupVariationTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      categorySelect: '.category-select'
    },

    regions: {
      nameRegion: '.name-region'
    },

    events: {    
      'click .js-cancel-popup': 'hide',
      'click @ui.cancel': 'onCancel',
      'click @ui.confirm': 'onConfirm',
      'change @ui.categorySelect': 'selectOptionCategory',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange'
    },

    modelEvents: {
      sync: 'onSave'
    },  

    initialize: function () {
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      templateData.categories = [];
      templateData.selected = [];

      var _this = this;

      _.each(this.model.get('refProduct').get('productOptionCategories').models, function (category) {

        var sName = category.get('code');

        if(category.get('name').getTextInLocale(Settings.get('lang')) !== '')
          sName = category.get('name').getTextInLocale(Settings.get('lang'));

        var aOptions = [];

        _.each(category.get('productOptions').models, function (option) {

          var sName = option.get('code');

          if(option.get('name').getTextInLocale(Settings.get('lang')) !== '')
            sName = option.get('name').getTextInLocale(Settings.get('lang'));
          
            aOptions.push(
            {
              secId: option.get('secId'),
              name: sName
            }
          );         
        });

        templateData.categories.push({
          secId: category.get('secId'),
          name: sName,
          options: aOptions
        });
      });

      //---------------            

      _.each(this.model.get('refProduct').get('productOptionCategories').models, function (category) {

        templateData.selected[category.get('secId')] = '';

        _.each(category.get('productOptions').models, function (option) {
          _.each(_this.model.get('productOptions').models, function (variationOption) {        

            if(variationOption.get('refProductOptionCategory').get('secId') === category.get('secId')) {    
              if(variationOption.get('secId') === option.get('secId')) {
                templateData.selected[category.get('secId')] = variationOption.get('secId');
              }
            }
          });
        });
      });      

      return templateData;
    },

   /* onRender: function () {
      $.material.init();
    }, */

    selectOptionCategory: function (e) {     
      var $el = this.$el.find(e.currentTarget);
      var catSecId = $el.closest('.category').attr('data-cat-id');
      var optionSecId = $el.val();  

      if(optionSecId === '')
        return;
      
      var _this = this;

      _.each(_this.model.get('productOptions').models, function (variationOption) {    
        if(variationOption.get('refProductOptionCategory').get('secId') === catSecId) { 
          _this.model.get('productOptions').remove(variationOption);
          //_this.model.get('productOptions').models.splice( $.inArray(variationOption, _this.model.get('productOptions').models), 1);
        }
      }); 

      _.each(this.model.get('refProduct').get('productOptionCategories').models, function (category) {
        _.each(category.get('productOptions').models, function (option) {
          if(category.get('secId') === catSecId && option.get('secId') === optionSecId)
          _this.model.get('productOptions').push(option);
        });
      });     
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },
    onConfirm: function () {    
      this.model.save();     
    },
    onSave: function () {
      this.triggerMethod('saved');
      this.model = null;
      this.hide();
    }
  });
});
