/**
 * Created by OLD on 15/09/2015.
 */
define('caccountDetailsTabController',[
  'module',
  'underscore',
  'app',
  'backbone',
  'caccountDetailsTabView',
  'caccountDetailsTabMonthView',
  'caccountDetailsTabOrdersView',
  'caccountDetailsTabGraphicalRequest',
  'rolesMixin',
  'settings',
  'entities/statistics',
  'entities/tickets'
], function (
  module,
  _,
  App,
  Backbone,
  View,
  CAccountDetailsTabMonthView,
  CAccountDetailsTabOrdersView,
  CAccountDetailsTabGraphicalRequest,
  RolesMixin,
  Settings
) {
  'use strict';

  var CAccountsDetailsTabController = {
    showTab: function () {
      var displayOrdersStats = true;
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        displayOrdersStats = false;
      }
      var customersStat = App.request('customers:get-statistic'),
        vectorStat = App.request('vectors:get-statistic'),
        creditStat = App.request('credits:get-statistic'),
        ordersCountStat = displayOrdersStats ? App.request('ordersCount:get-statistic') : null,
        offersCountStat = displayOrdersStats ? App.request('offersCount:get-statistic') : null,
        ordersStat = displayOrdersStats ? App.request('orders:get-statistic') : null,
        offersStat = displayOrdersStats ? App.request('offers:get-statistic') : null,
        monthStat = displayOrdersStats ? App.request('month:get-statistic') : null,
        productStat = displayOrdersStats ? App.request('products:get-statistic') : null,
        model = new Backbone.Model(),
        view,
        that = this;

      $.when(customersStat,
        vectorStat,
        creditStat,
        ordersStat,
        offersStat,
        ordersCountStat,
        offersCountStat,
        monthStat,
        productStat
      ).done(function (
        customers,
        vectors,
        credits,
        orders,
        offers,
        ordersCount,
        offersCount,
        month,
        products
      ) {
        model.set('customers', customers);
        model.set('vectors', vectors);
        model.set('credits', credits);
        model.set('orders', orders);
        model.set('offers', offers);
        model.set('ordersCount', ordersCount);
        model.set('offersCount', offersCount);
        model.set('month', month);
        model.set('products', products);

        view = new View();
        view.model = model;
        if (App.regions.getRegion('main') &&
          App.regions.getRegion('main').currentView &&
          App.regions.getRegion('main').currentView.getRegion('content') &&
          App.regions.getRegion('main').currentView.getRegion('content').currentView &&
          App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent')) {
          App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);
        }

        if (displayOrdersStats && Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS]) {
          view.getRegion('canvasOrders').show(
            new CAccountDetailsTabOrdersView({
              type: 'Line',
              model: model
            })
          );
          view.getRegion('canvasMonth').show(
            new CAccountDetailsTabMonthView({
              type: 'Doughnut',
              model: model
            })
          );
        }

        that.requestsView = new CAccountDetailsTabGraphicalRequest();
        that.requestsView.listenTo(that.requestsView, 'onGridLoaded', _.bind(that.onGridLoaded, that));
        that.requestsView.listenTo(that.requestsView, 'tickets:show', _.bind(that.onTicketsShow, that));

        view.getRegion('canvasRequests').show(
          that.requestsView
        );
      });
    },

    onGridLoaded: function () {
      var data = {
        url: App.request('tickets:get-collection-url'),
        rowCountUrl: App.request('tickets:row-count-url'),
        displayRowToolTip: true
      };
      this.requestsView.displayGrid(data, true);
    },

    onTicketsShow: function (secId) {
      App.trigger('graphical_service_all_in_one:show', secId);
    }
  };

  module.exports = CAccountsDetailsTabController;
});
