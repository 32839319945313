/**
 * Created by Matteo on 11/07/2015.
 */
define('selectCaccountMainRouter',[
  'require',
  'exports',
  'module',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Marionette,
  App
) {
  'use strict';

  var SelectCAccountRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'select/caccount': 'listCAccounts'
    }
  });

  var API = {
    listCAccounts: function() {
      require(['selectCaccountListController'], function(ListController) {
        App.startSubModule('selectCaccountMain');
        ListController.listCAccounts();
      });
    }
  };

  App.on('caccounts:list', function() {
    App.navigate('select/caccount');
    API.listCAccounts();
  });

  App.on('before:start', function() {
    new SelectCAccountRouter({
      controller: API
    });
  });

  module.exports = SelectCAccountRouter;
});

