define('ordersDetailSupplierProductPrintZonesView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'ordersDetailSupplierProductPrintZoneView'
], function (
  App,
  module,
  Marionette,
  _,
  PrintZoneView
) {
  'use strict';

  module.exports = Marionette.CollectionView.extend({
    className: 'printzones-view',
    childView: PrintZoneView,

    childViewOptions: function () {
      return {
        product: this.options.product,
        order: this.options.order
      };
    }
  });
});
