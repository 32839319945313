define('webshopCategoriesDetailBodyProductsToolsView',[
  'app',
  'module',
  'underscore',
  'tableFieldView',
  'template!webshopCategoriesDetailBodyProductsToolsView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-products-tools-view ' + FieldView.prototype.className,

    ui: {
      deleteButton: '.delete-button'
    },

    events: {
      'click @ui.deleteButton': 'onDelete'
    },

    onDelete: function (e) {
      e.stopPropagation();
      this.tableView.model.deleteProduct(this.model.getId()).done(_.bind(function () {
        this.tableView.bodyView.collection.remove(this.model);
        this.tableView.footerView.fetch();
      }, this));
    }
  });
});
