
define('template!productPicturesTabPictureItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="image-texture-and-background" style="position: relative;">\n  <img class="js-image-holder" src="' +
((__t = ( pictureImageUrl )) == null ? '' : __t) +
'"/>\n  <div class="pa-code-container">\n    ';
 _.each(pacis, function(pa) { ;
__p += '\n    <div class="pa-code">' +
((__t = ( pa )) == null ? '' : __t) +
'\n    </div>\n    ';
 }); ;
__p += '\n  </div>\n  <div class="default-icon">\n    ';
 if (isDefaultPicture) { ;
__p += '\n    <i class="mdi-action-stars"></i>\n    ';
 } ;
__p += '\n    ';
 if (isAmbiance) { ;
__p += '\n    <i class="mdi-device-now-wallpaper "></i>\n    ';
 } ;
__p += '\n  </div>\n</div>\n\n<div class="picture-editor">\n  ';
 if (!readOnly) { ;
__p += '\n  <i class="mdi-editor-mode-edit icon js-edit"></i>\n  <i class="mdi-image-color-lens icon js-print"></i>\n  <i class="mdi-action-delete icon js-delete"></i>\n  ';
 } ;
__p += '\n  <i class="mdi-device-now-wallpaper icon js-ambiance"></i>\n</div>\n\n';

}
return __p
};});

