define('popupPrintZoneShareView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'settings',
  'dialogFormView',
  'template!popupCreatePrintZoneShareTpl',
  'autocompleteView',
  'entities/product_share',
  'entities/product_translation'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  Settings,
  DialogFormView,
  popupCreatePrintZoneTpl,
  AutocompleteView
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreatePrintZoneTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    regions: {
      printersRegion: '.printers-auto-complete',
      imprintTechnologyRegion: '.imprint-technology-region'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel'
    },

    modelEvents: {
      sync: 'onSave'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.hidePrinter = this.options.hidePrinter;
      return templateData;
    },

    onShow: function () {
      $.material.init();
      var printersView = new AutocompleteView(
        this.getAutocompleteOptions('compuzz2', 'v2/caccountshare/printers', 'code', 'code', 'code', _.bind(function (property, model) {
          this.selectedPrinter = model;
          this.showImprintList(model.get('secId'));
        }, this))
      );
      this.getRegion('printersRegion').show(printersView);
    },

    showImprintList: function (printerSecId) {
      if (this.imprintTechnologyView) {
        this.imprintTechnologyView.destroy();
      }
      this.imprintTechnologyView = new AutocompleteView(
        this.getAutocompleteOptions('compuzz2', 'v2/imprinttechnologies/fromprinter/' + printerSecId,
          'codeName', 'codeName', 'codeName', _.bind(function (propertyIt, modelIt) {
            this.selectedIt = modelIt;
            this.model.set('itSecId', modelIt.get('secId'));
          }, this))
      );
      this.getRegion('imprintTechnologyRegion').show(this.imprintTechnologyView);
    },

    onConfirm: function () {
      var dtIt = App.request('products-translation:dynamictext-model', this.selectedIt.get('name'));
      this.model.set('name', dtIt.getTextInLocale(Settings.get('lang')));
      this.model.set('printerName', this.selectedPrinter.get('code'));
      this.model.save(this.collection.modelProduct.get('refProduct').get('secId'));
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model.fetch();
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model.fetch();
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      if (this.newItem) {
        this.collection.add(App.request('products-share:model-printzone-share', {printZone: this.model.toJSON()}));
        this.model = null;
      } else {
        this.model = null;
      }
      this.hide();
    }
  });
});
