/**
 * Created by BFR on 02/01/2020.
 */
define('changeDatePopupView',[
    'module',
    'jquery',
    'underscore',
    'settings',
    'template!changeDatePopupTpl',
    'dialogFormView',
    'moment',
    'moment-business-days'
], function(
    module,
    $,
    _,
    Settings,
    viewTpl,
    DialogFormView,
    moment
) {
    'use strict';

    var OrderStatusPopupView = DialogFormView.extend({
        template: viewTpl,

        ui: {
            cdate: '.date'
        },

        serializeData: function() {
            var templateData = {};
            if (this.options.date) {
                templateData.date = moment(this.options.date).format('DD-MM-YYYY');
            } else {
                var offSetDate= moment(new Date()).businessAdd(this.options.offset).format('DD-MM-YYYY');
                templateData.date = offSetDate;
                this.options.date = moment(moment(templateData.date, 'DD-MM-YYYY')).format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
            }
            templateData.label = this.options.label;
            return templateData;
        },

        onShow: function() {
            $.material.init();
        },

        onRender: function() {
            this.initDatePicker(this.ui.cdate);
        },

        initDatePicker: function(element) {
            var that = this, format = 'dd-mm-yy';
            element.datepicker({
                autoSize: true,
                dateFormat: format,
                dayNamesMin: moment.weekdaysShort(),
                firstDay: 1,
                changeYear: true,
                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>',
                beforeShow: function() {
                    $('#ui-datepicker-div')
                        .removeClass('tt-datepicker');
                    $('#ui-datepicker-div')
                        .addClass('tt-datepicker');
                },
                onSelect: function() {
                    that.options.date = moment(element.val(), 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
                }
            });
        }
    });

    module.exports = OrderStatusPopupView;
});

