/**
 * Created by RKL on 05/08/2015.
 */
define('resetMainRouter',[
  'require',
  'exports',
  'module',
  'marionette',
  'app'
], function (
  require,
  exports,
  module,
  Marionette,
  App
) {
  'use strict';

  var ResetPwdRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'reset_password': 'showReset'
    }
  });

  var API = {
    showReset: function() {
      require(['resetShowController'], function(ShowController) {
        App.startSubModule('resetMain');
        ShowController.showReset();
      });
    }
  };

  App.on('before:start', function() {
    new  ResetPwdRouter({
      controller: API
    });
  });

  module.exports = ResetPwdRouter;
});

