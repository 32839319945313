define('ordersListToolsView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'ordersController',
  'ordersPaymentView',
  'ordersDeliveryView',
  'ordersProofsheetButtonView',
  'ordersValidationView',
  'tableFieldView',
  'template!ordersListToolsView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  Controller,
  PaymentView,
  DeliveryView,
  ProofsheetButtonView,
  OrdersValidationView,
  FieldView,
  Tpl,
  Orders
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-tools-view ' + FieldView.prototype.className,

    ui: {
      archived: '.archived',
      messages: '.messages',
      ukrMessages: '.ukr-messages',
      outsideEurope: '.outside-europe',
      pim: '.pim',
      vip: '.vip'
    },

    events: {
      'click @ui.archived': 'onArchivedClick',
      'click @ui.messages': 'onMessagesClick',
      'click @ui.ukrMessages': 'onMessagesClick'
    },

    regions: {
      paymentRegion: '.payment-region',
      deliveryRegion: '.delivery-region',
      proofsheetRegion: '.proofsheet-region',
      validationRegion: '.validation-region'
    },

    modelEvents: {
      'change:archived': 'renderArchived',
      'change:actionRequiredInternal': 'renderActionRequiredInternal',
      'change:actionRequiredUkr': 'renderActionRequiredUkr',
      'change:outsideEurope': 'renderOutsideEurope',
      'change:pim': 'renderPim',
      'change:customer': 'renderVip'
    },

    onRender: function () {
      this.paymentView = new PaymentView({
        model: this.model
      });
      this.getRegion('paymentRegion').show(this.paymentView);

      //--------------------
      this.deliveryView = new DeliveryView({
        model: this.model
      });
      this.getRegion('deliveryRegion').show(this.deliveryView);

      //--------------------

      this.proofsheetView = new ProofsheetButtonView({
        poi: this.model.get('purchaseOrderId'),
        endUserId: this.model.get('endUserId')
      });
      this.getRegion('proofsheetRegion').show(this.proofsheetView);

      //--------------------

      if(Settings.configValue('orders.showValidationButton') === true) {
        this.validationView = new OrdersValidationView({
          model: this.model
        });
        this.getRegion('validationRegion').show(this.validationView);
      }

      //--------------------

      this.renderArchived();
      this.renderActionRequiredInternal();
      this.renderActionRequiredUkr();
      this.renderOutsideEurope();
      this.renderPim();
      this.renderVip();
    },

    renderArchived: function () {
      if(this.model.get('archived') === true)
        this.ui.archived.addClass('active');
      else
        this.ui.archived.removeClass('active');
    },

    renderActionRequiredInternal: function () {
      if (this.model.get('actionRequiredInternal'))
        this.ui.messages.css('display', 'flex');
      else
        this.ui.messages.hide();
    },

    renderActionRequiredUkr: function () {
      if (this.model.get('actionRequiredUkr'))
        this.ui.ukrMessages.css('display', 'flex');
      else
        this.ui.ukrMessages.hide();
    },

    renderOutsideEurope: function () {
      if (this.model.get('outsideEurope'))
        this.ui.outsideEurope.css('display', 'flex');
      else
        this.ui.outsideEurope.hide();
    },

    renderPim: function () {
      if (this.model.get('pim') && Settings.configValue('orders.showPimIcon') === true)
        this.ui.pim.css('display', 'flex');
      else
        this.ui.pim.hide();
    },

    renderVip: function () {
      if (this.model.get('customer') && this.model.get('customer').vip)
        this.ui.vip.css('display', 'flex');
      else
        this.ui.vip.hide();
    },

    onArchivedClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if(this.model.get('archived') === true)
        Controller.unarchive([this.model]);
      else
        Controller.archive([this.model]);
    },

    onMessagesClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      Orders.showOrder(this.model.get('purchaseOrderId'), 'internal_messages');
    }
  });
});
