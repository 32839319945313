define('adminGlossariesListHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'windows',
  'adminGlossariesPopupView',
  'tableHeaderFieldView',
  'template!adminGlossariesListHeaderToolsView',
  'entities/admin/glossaries'
], function (
  App,
  Module,  
  _,
  Windows,
  PopupView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-glossaries-list-header-tools-view table-field-view',

    ui: {
      addButton: '.add'
    },

    events: {
      'click @ui.addButton': 'onAdd'
    },
    
    onAdd: function() {
      Windows.open({
        view: _.bind(function () {
          return new PopupView({
            model: App.request('glossaries:model'),
            onConfirm: _.bind(function () {
              this.tableView.bodyView.fetch();
              this.tableView.footerView.fetch();
            }, this)
          });
        }, this),
        title: _.i18n('glossaries.new'),
        width: 600,
        height: 500,
        modal: true
      });
    }
  });
});
