
define('template!translationsPopupFormTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n  <div class="loading-block js-loading-block">\n\t\t\t<span class="loading-message js-loading-message label"></span>\n\t\t</div>\n  <div class="form-group col-xs-11" data-field-name="code">\n      <input class="form-control floating-label js-unique" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" \\\n             data-field-name="code" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n  </div>\n</div>\n<div class="row">\n  <div class="form-group col-xs-11">\n    <div class="js-languages-region"></div>\n  </div>\n</div>\n<div class="js-translations-region"></div>\n<div class="row">\n    <div class="form-group pull-right m-b-0 m-r-10">\n      <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n      <button class="btn btn-primary js-save">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n    </div>\n</div>';

}
return __p
};});

