
define('template!priceBillingDetailPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="billingcodes-add-new-container">\n\t<div class="container-fluid">\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group" data-field-name="quantity">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.quantity") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\t';
 if (editable && tabName === 'pricelists') { ;
__p += ' disabled ';
 } ;
__p += '\n\t\t\t\t\t\tclass="form-control quantity js-form-input"\n\t\t\t\t\t\ttype="number"\n\t\t\t\t\t\tdata-field-name="quantity"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.quantity") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( quantity )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="costPrice1">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.costPrice") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control costPrice1 js-form-input"\n\t\t\t\t\t\ttype="number"\n\t\t\t\t\t\tdata-field-name="costPrice1"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.costPrice") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( costPrice1 )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="leadTime1">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.leadTime") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\t';
 if (editable && tabName === 'pricelists') { ;
__p += ' disabled ';
 } ;
__p += '\n\t\t\t\t\t\tclass="form-control leadTime1 js-form-input"\n\t\t\t\t\t\ttype="number"\n\t\t\t\t\t\tdata-field-name="leadTime1"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.leadTime") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( leadTime1 )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="rsPrice1">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("popup.price.rsPrice") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control rsPrice1 js-form-input"\n\t\t\t\t\t\ttype="number"\n\t\t\t\t\t\tdata-field-name="rsPrice1"\n\t\t\t\t\t\tplaceholder=\'' +
((__t = ( _.i18n("popup.price.rsPrice") )) == null ? '' : __t) +
'\'\n\t\t\t\t\t\tvalue="' +
((__t = ( rsPrice1 )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

