/**
 * Created by Matteo on 14/07/2015.
 */
define('selectCaccountListController',[
  'require',
  'module',
  'logger',
  'jquery',
  'underscore',
  'app',
  'settings',
  'entities/caccounts',
  'selectCaccountListView',
    'notificationCenter'
], function(
  require,
  module,
  Logger,
  $,
  _,
  App,
  Settings,
  CAccountEntities,
  ListView,
  NotificationCenter
) {
  'use strict';

  var SelectCAccountListController =  {

    listCAccounts: function() {
      if (!Settings.get('currentCAccount') || !Settings.get('tokenId')) {
        var that = this,
          cAccountCount = App.request('caccount:count'),
          listView;

        this.view = listView;
        $.when(cAccountCount).done(function(count) {
          if(count === 1) {
            that.skipSelectionPage();
            return;
          }

          listView = new ListView();

          that.view = listView;

          listView.listenTo(listView, 'onSignin', _.bind(that.onSignin, that));

          App.regions.getRegion('main').show(listView);
        });
      } else {
        App.trigger('index:show');
      }
    },

    onSignin: function() {
      var that = this,
        cAccount = this.view.selectedAccount,
        postCAccount;

      if (cAccount) {
        this.view.ui.signin.addClass('disabled');

        postCAccount = App.request(
          'caccount:post-caccount',
          cAccount.get('secId')
        );
        $.when(postCAccount).done(function(response) {
          Settings.set('tokenId', response.tokenId);
          var tempAccount = cAccount.clone();
          tempAccount.unset('refMainCAccountJson');
          Settings.set('currentCAccountCookie', JSON.stringify(tempAccount));
          Settings.set('currentCAccount', cAccount.toJSON());
          that.view.ui.signin.removeClass('disabled');
          App.trigger('index:show');
          if(response && response.loginTips){
              _.each(response.loginTips, function(loginTip){
                  NotificationCenter.getInstance().processNotification(loginTip);
              });
          }
        });
      } else {
        this.view.onError();
      }
    },

    skipSelectionPage: function() {
      var fetchCAccounts = App.request('caccount:entities'),
        postCAccount;

      $.when(fetchCAccounts).done(function(cAccounts) {
        postCAccount = App.request(
          'caccount:post-caccount',
          cAccounts.at(0).get('secId')
        );

        $.when(postCAccount).done(function(response) {
          Settings.set('tokenId', response.tokenId);
          Settings.set('currentCAccountCookie', JSON.stringify(cAccounts.at(0)));
          Settings.set('currentCAccount', cAccounts.at(0).toJSON());
          App.trigger('index:show');
        });
      });
    }
  };

  module.exports = SelectCAccountListController;
});
