/**
 * Created by BFR on 24/02/2020.
 */
define('translationCatsView',['module',
        'marionette',
        'translationCatView'],
  function(module,
           Marionette,
           TranslationCatView){
  'use strict';

  var TranslationCatsView = Marionette.CollectionView.extend({
    childView: TranslationCatView,
    className: 'col-md-12 p-t-10'
  });

  module.exports = TranslationCatsView;
});
