/**
 * Created by BFR on 24/02/2020.
 */
define('customFieldsView',[
  'app',
  'module',
        'marionette',
        'customFieldView'],
  function(App,
           module,
           Marionette,
           CustomFieldView){
  'use strict';

  var CustomFieldsView = Marionette.CollectionView.extend({
    childView: CustomFieldView,
    className: ' col-md-12 p-t-10'
  });

  module.exports = CustomFieldsView;
});

