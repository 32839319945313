define('adminNotificationsCreatorView',[
  'app',
  'module',
  'backbone',
  'underscore', 
  'tableFieldView',
  'userView',
  'template!adminNotificationsCreatorView'
], function (
  App,
  Module,
  Backbone,
  _,
  FieldView,
  UserView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-notifications-creator-view table-field-view',

    regions: {
      userRegion: '.user-region'
    },

    serializeData: function () {
      var data = {};

      data.showName = false;

      if(this.field.showName) {
        data.showName = true;

        var name = '';

        if(this.model.get('content').initiator) {
          if(this.model.get('content').initiator.firstName)
            name = this.model.get('content').initiator.firstName;

          if(this.model.get('content').initiator.lastName) {
            if(name !== '')
              name += ' ';
            name += this.model.get('content').initiator.lastName;
          }
        }

        if(name !== '')
          data.name = name;
      }




      return data;
    },

    onRender() {
      if (this.model.get('content').initiator) {
        this.userView = new UserView({
          model: new Backbone.Model(this.model.get('content').initiator)
        });

        this.getRegion('userRegion').show(this.userView);
      }
    }
  });
});
