/**
 * Created by dev on 10-02-16.
 */
define('autoLoginController',[
    'module',
    'underscore',
    'app',
    'settings',
    'plivoUtils'
], function(
    module,_, App,Settings, PlivoUtils
    ) {
    'use strict';

    var AutoLoginController = {
        autoLogin: function(userSecId, tokenId){ 
            Settings.set('currentUser', null);
            Settings.set('currentUserCookie', null);
            Settings.set('currentCAccountCookie', null);
            Settings.set('currentCAccount', null);
            Settings.set('tokenId', tokenId);
            require(['entities/session'], _.bind(function() {
                var getUser = App.request(
                        'session:userByToken',
                        {tokenId: tokenId, userSecId: userSecId}
                    );

                $.when(getUser).done(_.bind(function(response) {
                    Settings.set('plivoUser', response.plivoUser);
                    Settings.set('plivoPassword', response.plivoPassword);
                    Settings.set('currentUserCookie', JSON.stringify(response));
                    Settings.set('currentUser', response);
                    PlivoUtils.init();
                    App.trigger('caccounts:list');
                }, this));
            }, this));
        }
    };

    module.exports = AutoLoginController;
});
