/**
 * Created by OBL on 7/01/2016.
 */
define('rolesMixin',['module', 'settings'], function (module, Settings) {
  'use strict';
  var prefixApplication = Settings.get('application').toUpperCase();
  var RolesMixin = {
    LOGED: 'LOGED',
    AUTHENTICATED: 'AUTHENTICATED',
    SUPERUSER: 'SUPERUSER',
    SUPERADMINISTRATOR: 'SUPERADMINISTRATOR',
    ADMIN: 'ADMIN',
    EXPORT: 'EXPORT',
    VECTORIZE: 'VECTORIZE',
    ORDERENTRY: 'ORDERENTRY',
    DEVDOC: 'DEVDOC',
    VECTORIZATOR: 'VECTORIZATOR',
    VECTORIZATOR_ONLY_ASSIGN: 'VECTORIZATOR_ONLY_ASSIGN',
    VECTORIZATOR_VIEW_ALL: 'VECTORIZATOR_VIEW_ALL',
    CREDITS: 'CREDITS',
    //----LOGOTOOL------------------------------
    LOGOTOOL_TEMPLATE: 'LOGOTOOL_TEMPLATE',
    //----DASHBOARD------------------------------
    // ACCOUNT
    // -- ACCOUNT
    DASHBOARD_ACCOUNT: prefixApplication + '_ACCOUNT',
    DASHBOARD_ACCOUNT_ACCOUNT: prefixApplication + '_ACCOUNT_ACCOUNT',
    //MAILS
    DASHBOARD_MAILS: prefixApplication + '_WEBSHOP',
    //WEBSHOP
    DASHBOARD_WEBSHOP: prefixApplication + '_WEBSHOP',
    DASHBOARD_WEBSHOP_PRODUCTS: prefixApplication + '_WEBSHOP_PRODUCTS',
    DASHBOARD_WEBSHOP_PRODUCTS_PRODUCT: prefixApplication + '_WEBSHOP_PRODUCTS_PRODUCT',
    DASHBOARD_WEBSHOP_PRODUCTS_TEXTS: prefixApplication + '_WEBSHOP_PRODUCTS_TEXTS',
    DASHBOARD_WEBSHOP_PRODUCTS_SLUGS: prefixApplication + '_WEBSHOP_PRODUCTS_SLUGS',
    DASHBOARD_WEBSHOP_PRODUCTS_CATEGORIES: prefixApplication + '_WEBSHOP_PRODUCTS_CATEGORIES',
    DASHBOARD_WEBSHOP_PRODUCTS_URLS: prefixApplication + '_WEBSHOP_PRODUCTS_URLS',
    DASHBOARD_WEBSHOP_PRODUCTS_IMAGES: prefixApplication + '_WEBSHOP_PRODUCTS_IMAGES',
    DASHBOARD_WEBSHOP_CATEGORIES: prefixApplication + '_WEBSHOP_CATEGORIES',
    DASHBOARD_WEBSHOP_CATEGORIES_CATEGORY: prefixApplication + '_CATEGORIES_CATEGORY',
    DASHBOARD_WEBSHOP_CATEGORIES_TEXTS: prefixApplication + '_WEBSHOP_CATEGORIES_TEXTS',
    DASHBOARD_WEBSHOP_CATEGORIES_SLUGS: prefixApplication + '_WEBSHOP_CATEGORIES_SLUGS',
    DASHBOARD_WEBSHOP_CATEGORIES_PRODUCTS: prefixApplication + '_WEBSHOP_CATEGORIES_PRODUCTS',
    DASHBOARD_WEBSHOP_CATEGORIES_URLS: prefixApplication + '_WEBSHOP_CATEGORIES_URLS',
    DASHBOARD_WEBSHOP_CATEGORIES_IMAGES: prefixApplication + '_WEBSHOP_CATEGORIES_IMAGES',
    //--TABS
    DASHBOARD_ACCOUNT_ACCOUNT_PROFILE: prefixApplication + '_ACCOUNT_ACCOUNT_PROFILE',
    DASHBOARD_ACCOUNT_ACCOUNT_CREDITS: prefixApplication + '_ACCOUNT_ACCOUNT_CREDITS',
    DASHBOARD_ACCOUNT_ACCOUNT_INVOICES: prefixApplication + '_ACCOUNT_ACCOUNT_INVOICES',
    DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR: prefixApplication + '_ACCOUNT_ACCOUNT_ADMINISTRATOR',
    DASHBOARD_ACCOUNT_ACCOUNT_SHARE: prefixApplication + '_ACCOUNT_ACCOUNT_SHARE',
    DASHBOARD_ACCOUNT_ACCOUNT_SERVICES: prefixApplication + '_ACCOUNT_ACCOUNT_SERVICES',
    DASHBOARD_ACCOUNT_ACCOUNT_ACTIVITY: prefixApplication + '_ACCOUNT_ACCOUNT_ACTIVITY',
    DASHBOARD_ACCOUNT_ACCOUNT_ATTACHMENTS: prefixApplication + '_ACCOUNT_ACCOUNT_ATTACHMENTS',
    DASHBOARD_ACCOUNT_ACCOUNT_COMMANDSTATUS: prefixApplication + '_ACCOUNT_ACCOUNT_COMMANDSTATUS',
    DASHBOARD_ACCOUNT_ACCOUNT_OFFERSTATUS: prefixApplication + '_ACCOUNT_ACCOUNT_OFFERSTATUS',
    DASHBOARD_ACCOUNT_ACCOUNT_PRICEFACTORS: prefixApplication + '_ACCOUNT_ACCOUNT_PRICEFACTORS',
    DASHBOARD_ACCOUNT_ACCOUNT_CONFIG: prefixApplication + '_ACCOUNT_ACCOUNT_CONFIG',
    // -- GRAPHICAL ID
    DASHBOARD_ACCOUNT_GRAPHICALID: prefixApplication + '_ACCOUNT_GRAPHICALID',
    DASHBOARD_ACCOUNT_MAILS: prefixApplication + '_ACCOUNT_MAILS',
    //--TABS
    DASHBOARD_ACCOUNT_GRAPHICALID_FONTS: prefixApplication + '_ACCOUNT_GRAPHICALID_FONTS',
    DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES: prefixApplication + '_ACCOUNT_GRAPHICALID_OBJECTNAMES',
    DASHBOARD_ACCOUNT_GRAPHICALID_DESIGNELEMENTS: prefixApplication + '_ACCOUNT_GRAPHICALID_DESIGNELEMENTS',
    DASHBOARD_ACCOUNT_GRAPHICALID_TEMPLATES: prefixApplication + '_ACCOUNT_GRAPHICALID_TEMPLATES',
    // PRODUCTS
    DASHBOARD_PRODUCTS: prefixApplication + '_PRODUCTS',
    DASHBOARD_PRODUCTS_PRODUCTS: prefixApplication + '_PRODUCTS_PRODUCTS',
    DASHBOARD_PRODUCTS_PRICELISTS: prefixApplication + '_PRODUCTS_PRICELISTS',
    DASHBOARD_PRODUCTS_CATALOGUES: prefixApplication + '_PRODUCTS_CATALOGUES',
    DASHBOARD_PRODUCTS_TRANSLATIONS: prefixApplication + '_PRODUCTS_TRANSLATIONS',
    // PIM
    DASHBOARD_PIM: prefixApplication + '_PIM',
    DASHBOARD_PIM_PRODUCTS: prefixApplication + '_PIM_PRODUCTS',

    //CLIENTS
    DASHBOARD_CUSTOMERS: prefixApplication + '_CUSTOMERS',
    DASHBOARD_CUSTOMERS_CUSTOMERS: prefixApplication + '_CUSTOMERS_CUSTOMERS',
    DASHBOARD_CUSTOMERS_LEADS: prefixApplication + '_CUSTOMERS_LEADS',
    //--TABS
    DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE: prefixApplication + '_CUSTOMERS_CUSTOMERS_PROFILE',
    DASHBOARD_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES: prefixApplication + '_CUSTOMERS_CUSTOMERS_LOGOSIMAGES',
    DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS: prefixApplication + '_CUSTOMERS_CUSTOMERS_DATASETS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES: prefixApplication + '_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES',
    DASHBOARD_CUSTOMERS_CUSTOMERS_TEMPLATES: prefixApplication + '_CUSTOMERS_CUSTOMERS_TEMPLATES',
    DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS: prefixApplication + '_CUSTOMERS_CUSTOMERS_ORDERS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_OFFERS: prefixApplication + '_CUSTOMERS_CUSTOMERS_OFFERS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_ATTACHMENTS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_PROOFSHEETS: prefixApplication + '_CUSTOMERS_CUSTOMERS_PROOFSHEETS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_FONTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_FONTS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS: prefixApplication + '_CUSTOMERS_CUSTOMERS_CALLS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS: prefixApplication + '_CUSTOMERS_CUSTOMERS_MAILS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_TASKS: prefixApplication + '_CUSTOMERS_CUSTOMERS_TASKS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_INFO: prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS_INFO',
    DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_CALLS: prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS_CALLS',
    DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_MAILS: prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS_MAILS',
    //ORDERS
    DASHBOARD_ORDERS: prefixApplication + '_ORDERS',
    DASHBOARD_ORDERS_PRICE: prefixApplication + '_ORDERS_PRICE',
    DASHBOARD_ORDERS_PRICE_OLD: prefixApplication + '_ORDERS_PRICE_OLD',
    DASHBOARD_ORDERS_DETAIL: prefixApplication + '_ORDERS_DETAIL',
    DASHBOARD_ORDERS_DETAIL_OLD: prefixApplication + '_ORDERS_DETAIL_OLD',
    DASHBOARD_ORDERS_OVERVIEW: prefixApplication + '_ORDERS_OVERVIEW',
    DASHBOARD_ORDERS_PERSONALIZATION: prefixApplication + '_ORDERS_PERSONALIZATION',
    DASHBOARD_ORDERS_ATTACHMENT: prefixApplication + '_ORDERS_ATTACHMENT',
    DASHBOARD_ORDERS_TRACKING: prefixApplication + '_ORDERS_TRACKING',
    DASHBOARD_ORDERS_DESIGN2: prefixApplication + '_ORDERS_DESIGN2',
    DASHBOARD_ORDERS_INTERNAL_MESSAGES: prefixApplication + '_ORDERS_INTERNAL_MESSAGES',
    DASHBOARD_ORDERS_INVOICES: prefixApplication + '_ORDERS_INVOICE',
    DASHBOARD_ORDERS_PROOFSHEET_HISTORY: prefixApplication + '_ORDERS_PROOFSHEET_HISTORY',
    DASHBOARD_ORDERS_TO_SUPPLIER: prefixApplication + '_ORDERS_TO_SUPPLIER',
    //OFFERS
    DASHBOARD_OFFERS: prefixApplication + '_OFFERS',
    DASHBOARD_OFFERS_DESIGN2: prefixApplication + '_OFFERS_DESIGN2',
    //SUPPLIERS
    DASHBOARD_SUPPLIERS: prefixApplication + '_SUPPLIERS',
    DASHBOARD_SUPPLIERS_SUPPLIERS: prefixApplication + '_SUPPLIERS_SUPPLIERS',
    DASHBOARD_SUPPLIERS_PRODUCTFINDER: prefixApplication + '_SUPPLIERS_PRODUCTFINDER',
    DASHBOARD_SUPPLIERS_CATALOGUES: prefixApplication + '_SUPPLIERS_CATALOGUES',
    //GRAPHICAL REQUESTS
    DASHBOARD_GRAPHICALREQUESTS: prefixApplication + '_GRAPHICALREQUESTS',
    DASHBOARD_GRAPHICALREQUESTS_ALL_IN_ONE: prefixApplication + '_GRAPHICALREQUESTS_ALL_IN_ONE',
    DASHBOARD_GRAPHICALREQUESTS_VECTORIZATION: prefixApplication + '_GRAPHICALREQUESTS_VECTORIZATION',
    DASHBOARD_GRAPHICALREQUESTS_FILECONVERSION: prefixApplication + '_GRAPHICALREQUESTS_FILECONVERSION',
    DASHBOARD_GRAPHICALREQUESTS_REMOVEBACKGROUND: prefixApplication + '_GRAPHICALREQUESTS_REMOVEBACKGROUND',
    DASHBOARD_GRAPHICALREQUESTS_MODI_TARIFFSCALE: prefixApplication + '_GRAPHICALREQUESTS_MODI_TARIFFSCALE',
    DASHBOARD_GRAPHICALREQUESTS_MODI_ASSIGN: prefixApplication + '_GRAPHICALREQUESTS_MODI_ASSIGN',
    DASHBOARD_GRAPHICALREQUESTS_MODI_TYPE: prefixApplication + '_GRAPHICALREQUESTS_MODI_TYPE',
    DASHBOARD_GRAPHICALREQUESTS_MODI_CANCEL: prefixApplication + '_GRAPHICALREQUESTS_MODI_CANCEL',
    DASHBOARD_GRAPHICALREQUESTS_MODI_DONE: prefixApplication + '_GRAPHICALREQUESTS_MODI_DONE',
    DASHBOARD_GRAPHICALREQUESTS_MODI_CLOSE: prefixApplication + '_GRAPHICALREQUESTS_MODI_CLOSE',
    DASHBOARD_GRAPHICALREQUESTS_MODI_MOVE_TO_TOP: prefixApplication + '_GRAPHICALREQUESTS_MODI_MOVE_TO_TOP',
    DASHBOARD_GRAPHICALREQUESTS_MODI_FLAG: prefixApplication + '_GRAPHICALREQUESTS_MODI_FLAG',
    DASHBOARD_GRAPHICALREQUESTS_MODI_SET_URGENT: prefixApplication + '_GRAPHICALREQUESTS_MODI_SET_URGENT',
    DASHBOARD_GRAPHICALREQUESTS_SHOW_MORE_INFO: prefixApplication + '_GRAPHICALREQUESTS_SHOW_MORE_INFO',
    DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_MSG: prefixApplication + '_GRAPHICALREQUESTS_SHOW_CUSTOMER_MSG',
    DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_INFO: prefixApplication + '_GRAPHICALREQUESTS_SHOW_CUSTOMER_INFO',
    DASHBOARD_GRAPHICALREQUESTS_SHOW_HISTORY: prefixApplication + '_GRAPHICALREQUESTS_SHOW_HISTORY',
    //ADMIN
    DASHBOARD_MYWEBSITE: prefixApplication + '_MYWEBSITE',
    DASHBOARD_PHONE: prefixApplication + '_PHONE',
    //SUPERADMIN
    DASHBOARD_SUPERADMIN: prefixApplication + '_SUPERADMIN',
    DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE: prefixApplication + '_SUPERADMIN_ACCOUNT_PROFILE',
    DASHBOARD_SUPERADMIN_ACCOUNT_ACTIVITY: prefixApplication + '_SUPERADMIN_ACCOUNT_ACTIVITY',
    DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS: prefixApplication + '_SUPERADMIN_ACCOUNT_CREDITS',
    DASHBOARD_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS: prefixApplication + '_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS',
    DASHBOARD_SUPERADMIN_ACCOUNT_CONTACTS: prefixApplication + '_SUPERADMIN_ACCOUNT_CONTACTS',

    // OTHERS
    DASHBOARD_TIMETRACKER: prefixApplication + '_TIMETRACKER',
    DASHBOARD_SUBACCOUNTCREATOR: prefixApplication + '_SUBACCOUNTCREATOR',
    DASHBOARD_ACCOUNTCREATOR: prefixApplication + '_ACCOUNTCREATOR',
    DASHBOARD_ACCOUNTSHARECREATOR: prefixApplication + '_ACCOUNTSHARECREATOR',
    DASHBOARD_ADMIN: prefixApplication + '_ADMIN',
    DASHBOARD_ADMIN_TRANSLATIONS: prefixApplication + '_ADMIN_TRANSLATIONS',
    DASHBOARD_ADMIN_HTMLTEMPLATES: prefixApplication + '_ADMIN_HTMLTEMPLATES',
    DASHBOARD_CATALOGUES: prefixApplication + '_CATALOGUES',
    DASHBOARD_SUPERADMIN_STATS: prefixApplication + '_SUPERADMIN_STATS',
    DASHBOARD_COLLECTIONS: prefixApplication + '_COLLECTIONS',
    DASHBOARD_TASKS: prefixApplication + '_TASKS',
    DASHBOARD_NOTIFICATIONS: prefixApplication + '_NOTIFICATIONS',
    DASHBOARD_ADMIN_WEBHOOK_HISTORY: prefixApplication + '_ADMIN_WEBHOOK_HISTORY',

    DASHBOARD_INVITATIONS: prefixApplication + '_INVITATIONS',
    DASHBOARD_COMPUZZ_PRO: prefixApplication + '_COMPUZZ_PRO'
  };


  module.exports = RolesMixin;
});

