define('pimProductAttributeValueView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'template!pimProductAttributeValueTpl'
], function(
  module,           
  Marionette,
  App,
  _,
  viewTpl
){
  'use strict';

  var PimProductAttributeValueView = Marionette.ItemView.extend({   
    template: viewTpl,
    className: 'value',

    events: {       
      'change .cbx': 'onCbxChange'
    },

    onCbxChange: function (event) {
      var cbx = $(event.target);

      var customFields = this.options.productAttribute.get('customFields');

      var customField = null;

      _.each(customFields.models, function(cf) {
        if(cf.get('code') === this.options.attributeModel.get('code'))
          customField = cf;
      }, this);

      if(!customField) {      
        customField = App.request('pim:product:attribute:custom-field:model',{
          'code': this.options.attributeModel.get('code'),
          'name': {'en': this.options.attributeModel.get('code')},
          'cfValues': []
        });
        customFields.add(customField);        
      }

      //----------

      var value = null;

      _.each(customField.get('cfValues').models, function(val) {
        if(val.get('code') === this.model.get('code'))
          value = val;
      }, this);
      
      if(cbx.is(':checked')) {
        if(!value) {
          value = App.request('pim:product:attribute:custom-field:value:model', {
            'code': this.model.get('code'),
            'value': {
              'values': {
                 'en': this.model.get('code')
              }   
            }           
          });
    
          customField.get('cfValues').add(value);
        }
      }
      else {
        if(value) {       
          value.destroy();
        }
      }  
      
      //----------

      if(customField.get('cfValues').models.length === 0) {               
        customField.destroy();
      }   
    },
  
    serializeData: function () {     

      var activated = false;

      _.each(this.options.productAttribute.get('customFields').models, function(cf) {
        if(cf.get('code') === this.options.attributeModel.get('code')) {
          _.each(cf.get('cfValues').models, function(val) {
            if(val.get('code') === this.model.get('code'))
              activated = true;
          }, this);
        }
      }, this);

      return {
        'code': this.model.get('code'),
        'activated': activated
      };
    }
  });

  module.exports = PimProductAttributeValueView;
});
