define('mailsMailLabelsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsMailLabelView',
  'mailsMailLabelsAddView',
  'template!mailsMailLabelsView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  LabelsView,
  AddView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'labels-view',
    childView: LabelsView,
    childViewContainer: '.labels-container',

    childEvents: {
      'delete': 'onDeleteLabel'
    },

    ui: {
      addButton: '.add'
    },

    modelEvents: {
      'change': 'fetch'
    },

    events: {
      'click @ui.addButton': 'onAddLabel'
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
      this.fetch();
    },

    fetch: function() {
      this.collection.reset();

      _.each(this.model.get('labels'), function(label) {
        this.collection.add(new Backbone.Model({label: label}));
      }, this);
    },

    onAddLabel: function() {
      AddView.prototype.open({
        model: this.model,
        mailSecId: this.options.mailSecId
      });
    },

    onDeleteLabel: function(view) {
      this.model.removeLabel(this.options.mailSecId, view.model.get('label')).done(_.bind(function() {
        var labels = this.model.get('labels').slice();

        var newLabels = labels.filter(function(label) {
          return label.id !== view.model.get('label').id;
        });

        this.model.set('labels', newLabels);
      }, this));
    }
  });
});
