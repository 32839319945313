define('noItemsView',[
  'module',
  'marionette',
  'template!noItemsTpl'
], function(
  module,
  Marionette,
  noItemsTpl
) {
  'use strict';

  var NoItemsView = Marionette.ItemView.extend({
    template: noItemsTpl,

    tagName: 'div',

    className: 'no-items'
  });

  module.exports = NoItemsView;
});
