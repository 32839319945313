define('tasksRouter',[
  'app',
  'require',
  'module',
  'marionette',
  'underscore',
  'itemPagesNameMixin'
], function (
  App,
  require,
  module,
  Marionette,
  _,
  ItemPagesNameMixin
) {
  'use strict';

  var TasksRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'tasks': 'showTasks'
    }
  });

  var API = {
    showTasks: function () {
      require([
        'tasksView',
        'contentShowController'
      ], function (TasksView,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          this.tasksView = new TasksView();
          App.regions.getRegion('main').currentView.getRegion('content').show(this.tasksView);
          //App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_TASKS);
        });
      });
    }
  };

  App.on('before:start', function () {
    new TasksRouter({
      controller: API
    });
  });

  module.exports = TasksRouter;
});

