define( 'entities/customers/customers_comments',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings'
], function(
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

var CustomerComment = Backbone.Model.extend({
  defaults: {
    secId : null,
    content : null,
    refEndUserSecId: null,
    modDate:null,
    refUserpid: {}
  },
  idAttribute: 'secId',
  save: function(endUserSecId) {
    this.url = Settings.url('compuzz2', 'endusercomments.json/'+endUserSecId);
    return Backbone.Model.prototype.save.call(this);
  },

  destroy: function() {
    this.url =  Settings.url('compuzz2', 'endusercomments.json/' + this.get('secId'));
    return Backbone.Model.prototype.destroy.call(this);
  },

  parse: function (response, options) {
    if (response) {
      response.content = response.comment;
      response.userpid = response.refUserpid;
      response.msgDate = response.modDate;
    }

    return Backbone.Model.prototype.parse.call(this, response, options);
  }
});

var CustomerComments = Backbone.Collection.extend({
  model: CustomerComment,
  comparator: _.bind(function (a, b) {
    if(a.get('pin') && b.get('pin')) {
      return a.get('msgDate') < b.get('msgDate') ? 1 : -1;
    }

    if(a.get('pin') && !b.get('pin')) {
      return -1;
    }

    if(!a.get('pin') && b.get('pin')) {
      return 1;
    }

    if(!a.get('pin') && !b.get('pin')) {
      return a.get('msgDate') < b.get('msgDate') ? 1 : -1;
    }

    return 1;
  }, this),

  fetch: function(endUserSecId) {
     this.url = Settings.url('compuzz2', 'endusercomments.json/' + endUserSecId );
     return Backbone.Collection.prototype.fetch.call(this);
  }
});


  /**
   * REST API
   */
  var API = {
    getCustomerComments: function(endUserSecId) {

      var customerComments = new CustomerComments({}),
        defer = $.Deferred();

      customerComments.url = Settings.url( 'compuzz2', 'endusercomments.json', {endUserSecId: endUserSecId});
      customerComments.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },
    getCollection: function() {
      return new CustomerComments();
    },
    getModel: function() {
      return new CustomerComment();
    }
  };

  App.reqres.setHandler('customercomments:get', function (endUserSecId) {
    return API.getCustomerComments(endUserSecId);
  });

  App.reqres.setHandler('customercomments:get-collection', function () {
    return API.getCollection();
  });
  App.reqres.setHandler('customercomments:get-model', function () {
    return API.getModel();
  });
});

