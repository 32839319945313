/**
 * Created by RKL on 13/08/2015.
 */
define('caccountAdminsTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'dateUtils',
  'jqgridView',
  'template!caccountAdminsTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  dateConverter,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CAccountsAdminsTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountAdminsFilters',

    triggers: {
      'click @ui.addItem': 'onCreateItem'
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-admins',
        jqGridPager: '#jq-grid-pager-admins',
        addItem: '.js-add-admin',
        deleteItem: '.cell-delete'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click @ui.deleteItem': 'onDeleteItem'
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var that = this,
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.first.name'),
              name: 'firstName',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'firstName'
            }, {
              label: _.i18n('common.last.name'),
              name: 'lastName',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'lastName'
            }, {
              label: _.i18n('common.email'),
              name: 'email',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'email'
            }, {
              label: _.i18n('common.externalId'),
              name: 'externalId',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'externalId'
            }
          ],

          onSelectRow: function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              that.trigger('onEditItem', rowid);
            }
          }
        };

      if (this.userTypes && this.userTypes.length > 0) {
        options.colModel.push({
          label: _.i18n('common.type'),
          name: 'type',
          classes: 'cell-link',
          formatter: _.bind(this.userTypeFormatter, this),
          search: false
        });
      }

      options.colModel.push({
        label: '',
        name: '',
        classes: 'delete-link',
        formatter: this.deleteIconFormatter,
        search: false,
        width: 50
      });

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    userTypeFormatter: function (value, options) {
      if (!value || value === 'null') {
        value = _.findWhere(this.userTypes, {'default': true}).typeCode;
      }
      return this.defaultFormatter(value, options);
    },

    onDeleteItem: function (e) {
      var target = $(e.currentTarget),
        itemId = target.data('row-id');

      this.trigger('onDeleteItem', itemId);
    }
  });

  module.exports = CAccountsAdminsTabView;
});
