define('tasksToolsView',[
  'app',
  'module',
  'underscore',
  'bootbox',
  'windows',
  'checkboxView',
  'tableFieldView',
  'tasksEditView',
  'entities/orders',
  'template!tasksToolsView',
  'entities/admin/tasks'
], function (
  App,
  Module,  
  _,
  Bootbox,
  Windows,
  CheckboxView,
  FieldView,
  PopupView,
  Orders,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-tasks-tools-view ' + FieldView.prototype.className,

    ui: {
      deleteButton: '.delete-button',
      editButton: '.edit-button',
      openButton: '.open-button'
    },

    regions: {
      doneRegion: '.done-region'
    },

    events: {
      'click @ui.deleteButton': 'onDelete',
      'click @ui.editButton': 'onEdit',
      'click @ui.openButton': 'onOpen'
    },

    modelEvents: {
      'change:done': 'renderDone',
      'change:refCommandLine change:refEndUser change:refProduct': 'renderOpen'
    },

    onRender: function () {
      this.doneView = new CheckboxView({
        title: _.i18n('tasks.done'),
        value: this.model.get('done'),
        tabindex: 1,
        onChange: _.bind(function (value) {
          var defer = $.Deferred();

          this.model.set('done', value, {silent: true});
          this.model.save(this.model.toJSON()).done(_.bind(function () {
            this.doneView.setValue(this.model.get('done'));
            defer.resolve();
            this.model.fetch();
          }, this)).fail(_.bind(function () {
            defer.reject();
          }, this));

          return defer.promise();
        }, this)
      });
      this.getRegion('doneRegion').show(this.doneView);

      //--------------------
      this.renderOpen();
    },

    renderOpen: function () {
      if (this.model.get('refCommandLine') || this.model.get('refEndUser') || this.model.get('refProduct'))
        this.ui.openButton.removeClass('disabled');
      else
        this.ui.openButton.addClass('disabled');
    },

    renderDone: function () {
      this.doneView.setValue(this.model.get('done'));
    },

    onDelete: function (e) {
      e.stopPropagation();

      Bootbox.confirm(_.i18n('tasks.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.request('tasks:delete', this.model.get('secId'))
            .done(_.bind(function () {
              this.tableView.bodyView.deleteRow(this.rowView);
            }, this));
        }
      }, this));
    },

    onEdit: function (e) {
      e.stopPropagation();
      var title = _.i18n('common.task');

      if (this.model.get('refCommandLine'))
        title += ' | ' + _.i18n('common.order') + ' | ' + this.model.get('refCommandLine').purchaseOrderId;

      if (this.model.get('refEndUser'))
        title += ' | ' + _.i18n('common.customer') + ' | ' + this.model.get('refEndUser').name;

      if (this.model.get('refProduct'))
        title += ' | ' + _.i18n('common.product') + ' | ' + this.model.get('refProduct').cCode + ' | ' + this.model.get('refProduct').name;

      Windows.open({
        view: _.bind(function () {
          return new PopupView({
            model: this.model
          });
        }, this),
        id: 'task:' + this.model.get('secId'),
        title: title,
        configName: 'commonTask'
      });
    },

    onOpen: function (e) {
      e.stopPropagation();

      if (this.model.get('refCommandLine'))
        Orders.showOrder(this.model.get('refCommandLine').purchaseOrderId, '');
      if (this.model.get('refEndUser'))
        App.navigate('customers/' + this.model.get('refEndUser').secId, {trigger: true});
      if (this.model.get('refProduct'))
        App.navigate('product/' + this.model.get('refProduct').secId, {trigger: true});
    },

    onDone: function (e) {
      e.stopPropagation();

      this.model.set('done', false, {silent: true});
      this.model.save().done(_.bind(function () {
        this.renderDone();
        this.ui.loader.css('display', 'none');
      }, this));
    }
  });
});
