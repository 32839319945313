define('ordersDetailSupplierEditProductPrintZonesController',[
  'app',
  'module',
  'backbone',
  'ordersDetailSupplierEditProductPrintZonesView'
], function (
  App,
  module,
  Backbone,
  PrintZonesView
) {
  'use strict';

  module.exports = function (args) {
    this.parentView = args.view;

    var product = args.model.get('products')[0];

    this.collection = product.positions;

    this.view = new PrintZonesView({
      order: args.order,
      product: args.product,
      model: args.model,
      collection: new Backbone.Collection(product.positions)
    });
  };
});
