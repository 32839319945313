/**
 * Created by BFR on 05/08/2022.
 */
define('caccountTransportView',[
  'module',
  'jquery',
  'marionette',
  'app',
  'underscore',
  'caccountTransportPricesView',
  'caccountTransportExpressPricesView',
  'template!caccountTransportView'
], function (
  module,
  $,
  Marionette,
  App,
  _,
  PricesView,
  ExpressPricesView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,

    ui: {
      expressAvailable: '.express-available'
    },

    regions: {
      transportPricesRegion: '.transport-prices-region',
      transportExpressPricesRegion: '.transport-express-prices-region'
    },

    events: {
      'change .txt-input': 'onTxtChange',
      'change @ui.expressAvailable': 'onExpressAvailableChange'
    },

    serializeData: function () {
      var data = {};

      if(this.options.country === '')
        data = this.model.toJSON();
      else
        data = this.model.get('countryTransports')[this.options.country].toJSON();

      data.deliveryDays = data.deliveryDays || 0;
      data.smallOrderAmount = data.smallOrderAmount || 0;
      data.smallOrderFee = data.smallOrderFee || 0;
      data.deliveryThreshold = data.deliveryThreshold || 0;
      data.expressAvailable = data.expressAvailable || false;
      data.expressDeliveryThreshold = data.expressDeliveryThreshold || 0;
      data.expressDeliveryFee = data.expressDeliveryFee || 0;

      return data;
    },

    onRender: function () {
      var model = this.model;

      if(this.options.country !== '')
        model = this.model.get('countryTransports')[this.options.country];

      this.pricesView = new PricesView({
        model: this.model,
        collection: model.get('transport').get('prices'),
        supplierUid: this.options.supplierUid
      });
      this.getRegion('transportPricesRegion').show(this.pricesView);

      //-----------------
      this.expressPricesView = new ExpressPricesView({
        model: this.model,
        collection: model.get('expressTransport').get('prices'),
        supplierUid: this.options.supplierUid
      });
      this.getRegion('transportExpressPricesRegion').show(this.expressPricesView);
    },

    onTxtChange: function (e) {
      var txt = $(e.currentTarget);
      var subProperty = txt.data('subproperty');

      var model = this.model;

      if(this.options.country !== '')
        model = this.model.get('countryTransports')[this.options.country];

      var m = model;
      if (subProperty) {
        m = model.get(txt.data('property'));
        m.set(txt.data('subproperty'), txt.val());
      } else {
        m.set(txt.data('property'), Number(txt.val()));
      }

      this.model.save(this.options.supplierUid);
    },

    onExpressAvailableChange: function () {
      var model = this.model;

      if(this.options.country !== '')
        model = this.model.get('countryTransports')[this.options.country];

      model.set('expressAvailable', this.ui.expressAvailable.prop('checked'));
      this.model.save(this.options.supplierUid);
    }
  });
});
