/**
 * Created by GSP on 13/10/2015.
 */
define('browserUtils',['module'], function(module) {
  'use strict';

  var BrowserUtils = {

    getLanguage: function() {
      if (navigator) {
        return ((navigator.languages ? window.navigator.languages[0] : null) ||
                navigator.language ||
                navigator.userLanguage ||
                navigator.browserLanguage).split('-')[0];
      } else {
        return null;
      }
    },

    getHostName: function() {
      return window.location.hostname;
    },

    getHref: function() {
      return window.location.href;
    }

  };

  module.exports = BrowserUtils;
});
