/**
 * Created by BFR on 29/03/2023.
 */
define('editPimVariationsView',['module',
    'marionette',
    'editPimVariationView'],
  function (module,
            Marionette,
            EditPimVariationView) {
    'use strict';

    var EditPimVariationsView = Marionette.CollectionView.extend({
        childView: EditPimVariationView,
        className: 'variation-list',
        childViewOptions: function() {
            return {
              pimProduct: this.options.pimProduct,
              pimProductId: this.options.pimProductId,
              compuzzProductSecId: this.options.compuzzProductSecId,
              productVariations: this.options.productVariations,
              pimSupplierData: this.options.pimSupplierData
            };
        }
    });

    module.exports = EditPimVariationsView;
  });
