
define('template!headerLanguagesView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="dropdown-toggle switch" data-toggle="dropdown">' +
((__t = ( language )) == null ? '' : __t) +
'</div>\n<ul class="dropdown-menu" role="menu">\n    <li class="item"><span class="lang-lbl " lang="da"></span></li>\n    <li class="item"><span class="lang-lbl " lang="de"></span></li>\n    <li class="item"><span class="lang-lbl " lang="en"></span></li>\n    <li class="item"><span class="lang-lbl " lang="es"></span></li>\n    <li class="item"><span class="lang-lbl " lang="fr"></span></li>\n    <li class="item"><span class="lang-lbl " lang="it"></span></li>\n    <li class="item"><span class="lang-lbl " lang="nl"></span></li>\n    <li class="item"><span class="lang-lbl " lang="no"></span></li>\n    <li class="item"><span class="lang-lbl " lang="pt"></span></li>\n    <li class="item"><span class="lang-lbl " lang="pl"></span></li>\n    <li class="item"><span class="lang-lbl " lang="sv"></span></li>\n</ul>\n';

}
return __p
};});

