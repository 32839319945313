
define('template!customersDetailBodyProfileSirenView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">' +
((__t = ( _.i18n('customerDetail.profile.sirenInfo') )) == null ? '' : __t) +
'</div>\n</div>\n<hr class="well-separator"/>\n<pre class="detailed-info-form-container infos"></pre>';

}
return __p
};});

