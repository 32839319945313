define('pimProductFiltersView',[
  'module',
  'marionette',
  'pimProductFilterItemView'
], function (
  module,
  Marionette,
  PimProductFilterItemView
) {
  'use strict';

  module.exports = Marionette.CollectionView.extend({
    childView: PimProductFilterItemView,
    className: 'sa-list'
  });
});
