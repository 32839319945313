define('productTabsLayoutView',[
  'module',
  'app',
  'marionette',
  'settings',
  'template!productTabsLayoutTpl',
  'backToListBehavior',
  'imageUtils',
  'jquery',
  'underscore',
  'productTabsData',
  'supplierProductSelectDialogView',
  'rolesMixin',
  'tasksCreatorBehavior',
  'entities/product'
], function(
  module,
  App,
  Marionette,
  Settings,
  productTabsLayoutTpl,
  BackToListBehavior,
  imageUtils,
  $,
  _,
  ProductTabsData,
  SupplierProductSelectDialog,
  RolesMixin,
  TasksCreatorBehavior
) {
  'use strict';

  var ProductTabsLayoutView = Marionette.LayoutView.extend({
    template: productTabsLayoutTpl,
    tagName: 'div',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.js-tab-layout-tab',
      tabsRegion: '.js-tabs-region',
      header: '.js-tab-layout-header',
      warningSkipCompuzzData: '.warning-skip-compuzz-data',
      btnUseCompuzzData: '.btn-use-compuzz-data',
      btnSkipCompuzzData: '.btn-skip-compuzz-data'
    },

    regions: {
      tabs: '.js-tabs-region'
    },

    events: {
      'click .previous-product': 'onPreviousClick',
      'click .next-product': 'onNextClick',
      'click .btn-replace-product': 'onReplaceProductClick',
      'click @ui.btnUseCompuzzData': 'onClickUseCompuzzData',
      'click @ui.btnSkipCompuzzData': 'onClickSkipCompuzzData'
    },

    behaviors: function () {
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: 'products'
        },
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refProduct: this.options.model
        }
      };
    },

    onClickUseCompuzzData: function () {
      $('.js-loading-block').show();
      var skipCompuzzDataRequest = App.request('products:skip-compuzz-data', this.options.model.get('secId'), 'false');
      $.when(skipCompuzzDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
        this.model.set('skipCompuzzData', false);
        this.ui.warningSkipCompuzzData.hide();
        this.ui.btnUseCompuzzData.hide();
        this.ui.btnSkipCompuzzData.show();
        App.navigate('product/' + this.options.model.get('secId') + '/share', {trigger: true});
      }, this));
    },

    onClickSkipCompuzzData: function() {
      $('.js-loading-block').show();
      var skipCompuzzDataRequest = App.request('products:skip-compuzz-data', this.options.model.get('secId'), true);
      $.when(skipCompuzzDataRequest).done(_.bind(function() {
        $('.js-loading-block').hide();
        this.model.set('skipCompuzzData', true);
        this.ui.warningSkipCompuzzData.show();
        this.ui.btnUseCompuzzData.show();
        this.ui.btnSkipCompuzzData.hide();
        App.navigate('product/' + this.options.model.get('secId') + '/share', {trigger: true});
      }, this));
    },

    onKeyPress: function(event) {
      if (event.ctrlKey) {
        switch(event.key) {
          case 'n':
            event.data.onNextClick();
            break;
          case 'p':
            event.data.onPreviousClick();
            break;
        }
      }
    },

    onReplaceProductClick: function() {
      this.productSelectDialog = new SupplierProductSelectDialog({
        multiSelect: false,
        displayLeaveButton: false
      });
      this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
      this.productSelectDialog.show({
        title: _.i18n('dialog.offer.selectProduct'),
        className: 'product-selection-dialog'
      });
    },

    onProductsSelected: function (products) {
      $('.js-loading-block').show();
      products = _.pluck(products, 'rowid');
      var requestRs = App.request('products:replace-product', this.model.get('secId'), products[0]);
      requestRs.done(_.bind(function(p) {
        $('.js-loading-block').hide();
        App.navigate('product/' + p.secId, {trigger: true});
      }, this));
    },

    onPreviousClick: function() {
      var index = Settings.get('productListData').currentIndex;
      if (index > 0) {
        index -= 1;
      } else {
        index = Settings.get('productListData').rowCount - 1;
      }
      var requestRs = App.request('products:get-product', index,
                                        Settings.get('productListData').filters);
      requestRs.done(_.bind(function(p) {
        Settings.get('productListData').currentIndex = index;
        this.destroy();
        App.navigate('product/' + p.secId + '/' + this.tab, {trigger: true});
      }, this));
    },

    onNextClick: function() {
      var index = Settings.get('productListData').currentIndex;
      if (index === (Settings.get('productListData').rowCount - 1)) {
        index = 0;
      } else {
        index += 1;
      }
      var requestRs = App.request('products:get-product', index, 
                              Settings.get('productListData').filters);
      requestRs.done(_.bind(function(p) {
        Settings.get('productListData').currentIndex = index;
        this.destroy();
        App.navigate('product/' + p.secId + '/' + this.tab, {trigger: true});
      }, this));
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.tabs = [];

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.design2 = true;
      } else {
        templateData.design2 = false;
      }

      templateData.displaySkipCompuzzData = (templateData.manufacturerCode !== Settings.get('currentCAccount').code);

      if (Settings.get('productListData')) {
        templateData.displayNextPrevious = true;
      } else {
        templateData.displayNextPrevious = false;
      }

      _.each(ProductTabsData, function (tab) {
        if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
          templateData.tabs.push(tab);
        }
      });
      
      return templateData;
    },

    onShow: function() {
      $(document).bind('keydown', this, this.onKeyPress);
      var logo = imageUtils.getLogo(this.model.get('cataloguePictureUid'), this.model.get('code'));
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
      this._setHeight();
      var that = this;
      $(window).on('resize._setHeight', _.bind(this._setHeight, this));
      App.off('view:destroy');
      App.on('view:destroy', function(newRoute) {
        that.destroy();
        App.navigate(newRoute, {trigger: true});
      });
    },

    onShowActive: function(tabName) {
      this.tab = tabName;
      this.ui.tabs.each(function(index, tab) {
        var $tab = $(tab);
        if($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      });
    },

    onDestroy: function() {
      $(document).unbind('keydown', this.onKeyPress);
      $(window).off('resize._setHeight');
    },

    _setHeight: _.throttle(function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height() || 75,
        detailsHeaderHeight = this.ui.header.outerHeight(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 40;

      this.ui.tabsRegion.height(height);
    }, 500)
  });

  module.exports = ProductTabsLayoutView;
});
