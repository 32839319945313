define('customersPopupAddressView',[
  'module',
  'jquery',
  'app',
  'settings',
  'underscore',
  'dialogFormView',    
  'autocompleteView',
  'savingBehavior',
  'template!customersPopupAddressView'
], function (
  Module,
  $,
  App,
  Settings,
  _,
  DialogFormView, 
  AutocompleteView,
  SavingBehavior,
  Tpl
) {
  'use strict';

  Module.exports =  DialogFormView.extend({
    template: Tpl,
    className: 'customers-popup-address-view',

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      deliveryAddressL1: '.addressL1',
      deliveryAddressL2: '.addressL2',
      deliveryHouseNumber: '.houseNumber'
    },

    events: {
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange'
    },

    regions: {
        selectCountries: '.js-select-countries-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    onRender: function () {        

    },

    onShow: function () {
      if(!Settings.get('countries')) {
        var fetchCountries = App.request('caccount:countries');
        $.when(fetchCountries).done(_.bind(function (countries) {
            Settings.set('countries', countries.toJSON());
            this.options.countries = Settings.get('countries');
            this.showCountries();
        }, this));
      } else {
        this.options.countries = Settings.get('countries');
        this.showCountries();
      }
    },

    showCountries: function() {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.getRegion('selectCountries').show(countriesView);
    },

    _getCountriesAutocompleteOptions: function () {
      var countryCode, countryValue, country;

      countryCode = this.model.get('countryCode') || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    onChangeCountry: function (fieldName, model) {
      var code = model ? model.get('code') : '';
      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    }
  });
});
