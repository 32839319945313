/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintZonesView',['module',
    'underscore',
    'app',
    'marionette',
    'sharePrintZoneView',
    'template!sharePrintZonesTpl',
    'popupPrintZoneShareView',
    'entities/product_share'],
  function (module,
            _,
            App,
            Marionette,
            SharePrintZoneView,
            viewTpl,
            PopupPrintZoneView) {
    'use strict';

    var SharePrintZonesView = Marionette.CompositeView.extend({
      template: viewTpl,
      childViewContainer: '.printzone-items',
      childView: SharePrintZoneView,
      className: 'col-md-12',

      childEvents: {
        'item:selection:change': 'onItemSelectionChange'
      },

      events: {
        'click .btn-add-printzone': 'onAddPrintZoneClick'
      },

      onAddPrintZoneClick: function () {
        var m = {
          paSecId: this.options.pa.get('printAreaSecId')
        };

        if (this.options.pa.get('printArea')) {
          m.width = this.options.pa.get('printArea').get('width');
          m.height = this.options.pa.get('printArea').get('height');
        }
        var popupPrintZoneView = new PopupPrintZoneView({
          model: App.request('products-share:model-printzone', m),
          collection: this.collection
        });
        popupPrintZoneView.newItem = true;

        popupPrintZoneView.show({
          title: _.i18n('common.add')
        });
      },

      onItemSelectionChange: function () {
        this.trigger('item:selection:change');
      }
    });

    module.exports = SharePrintZonesView;
  });
