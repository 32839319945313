define('ordersHistoryView',[
  'module',
  'app',
  'underscore',
  'windows',
  'tableDatetimeFieldView',
  'ordersHistoryChangeView',
  'tableView',
  'entities/orders'
], function (
  Module,
  App,
  _,
  Windows,
  DatetimeFieldView,
  ChangeFieldView,
  TableView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'orders-history-view ' + TableView.prototype.className,
    defaultSortField: 'Date',
    defaultSortDirection: 'desc',
    pageSize: 50,
    pageMode: 'pagination',

    setFields: function() {
      return [{
        name: 'Date',
        label: _.i18n('history.creatDate'),
        title: _.i18n('history.creatDate'),
        view: DatetimeFieldView,
        bind: 'creatDate',
        className: 'create-date'
      }, {
        name: 'User',
        label: _.i18n('history.user'),
        title: _.i18n('history.user'),        
        className: 'user',
        onRender: function(view) {
          var html = '';
    
          if(view.model.get('refUserpid')) {
            if(view.model.get('refUserpid').firstName)
              html += view.model.get('refUserpid').firstName;
            
            if(html !== '')
              html += ' ';
    
            if(view.model.get('refUserpid').lastName)
              html += view.model.get('refUserpid').lastName;
          }     
            
          view.$el.html(html);
        }
      }, {
        name: 'type',
        label: _.i18n('history.field'),
        title: _.i18n('history.field'),
        className: 'type',
        onRender: function(view) {
          var html = '';

          if(view.model.get('field'))
            html = _.i18n('history.field.' + view.model.get('field'));
            
          view.$el.html(html);
        }
      }, {
        name: 'Change',
        label: _.i18n('history.change'),
        title: _.i18n('history.change'),
        view: ChangeFieldView,
        className: 'change'      
      }];
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;

      if (sortField !== '') {
        var sortParamName = '';
        if (sortField === 'Date')
          sortParamName = 'creatDate';

        if (sortField === 'User')
          sortParamName = 'user';

        if (sortParamName !== '') {
          params.sidx = sortParamName;
          params.sord = sortDirection;
        }
      }

      App.request('orders:statuses', this.model.get('commandType')).done(_.bind(function (statuses) {
        this.statuses = statuses;
        if(this.fetchRequest)
          this.fetchRequest.abort();

        this.fetchRequest = $.ajax({
          type: 'GET',
          url: App.request('order:history-get', this.model.get('purchaseOrderId'), params),
          success: function (items) {
            var filteredItems = [];

            _.each(items, function(item) {
              if(item.field !== 'savedBuyingPrice' && item.field !== 'savedPrice')
                filteredItems.push(item);
            }, this);

            defer.resolve(filteredItems);
          }
        });
      }, this));

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('order:history-row-count', this.model.get('purchaseOrderId'), this.getFiltersParams(filters)),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function() {
      var params = {};
      return params;
    },

    //Static methods

    open: function(model) {
      require([
        'windows',
        'ordersHistoryView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model
            });
          }, this),
          id: 'orders.history:' + model.get('purchaseOrderId'),
          title: _.i18n('orders.history.title') + ': ' + model.get('purchaseOrderId') + ' | ' + model.get('companyName'),
          width: 1024,
          height: 600,
          minWidth: 600,
          minHeight: 400
        });
      });
    }
  });
});
