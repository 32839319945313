/**
 * Created by BFR on 24/02/2020.
 */
define('translationOptionsView',['module',
        'marionette',
        'translationOptionView'],
  function(module,
           Marionette,
           TranslationOptionView){
  'use strict';

  var TranslationOptionsView = Marionette.CollectionView.extend({
    childView: TranslationOptionView,
    className: 'col-md-12'
  });

  module.exports = TranslationOptionsView;
});
