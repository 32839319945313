define('ordersDetailSupplierEditInvoicePackingListView',[
  'module',
  'marionette',
  'underscore',
  'pdfView',
  'template!ordersDetailSupplierEditInvoicePackingListView'
], function (
  Module,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'orders-detail-body-supplier-edit-invoice-packinglist-view',

    ui: {
      canvas: '.canvas-ctn .canvas'
    },

    events: {
      'click': 'onClick'
    },
    
    onRender: function () {
      this.renderAttachment();
    },

    renderAttachment: function() {
      if (this.model.get('packingList') && this.model.get('packingList').content) {
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var dataURI = 'data:application/pdf;base64, ' + this.model.get('packingList').content;
        var loadingTask = pdfjsLib.getDocument(dataURI);
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
            var scale = 2;

            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.canvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //console.log('Page rendered');
            });

          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      }
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersDetailSupplierEditInvoicePackingListView'
      ], function (Windows, View) {
        var title = '';

        if (data.model.get('packingList') && data.model.get('packingList').filename)
          title = data.model.get('packingList').filename;

        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: title,
          modal: true,
          height: '80%',
          width: '60%'
        });
      });
    }
  });
});
  
