/**
 * Created by OLD on 23/11/2015.
 */
define('priceLayoutView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'template!pricesLayoutTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl
) {
  'use strict';

  var PricesLayoutView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
    },

    ui: {
      tabTitle: '.js-tab-title'
    },

    events: {
      'click @ui.tabTitle': 'onChangeTab'
    },

    regions: {
      priceTabContent: '#js-pricepage-content-region'
    },

    serializeData: function() {
      return {tab: this.options.tab};
    },

    onRender: function() {      
    },

    onShow: function() {
      $.material.init();
    },

    onChangeTab: function(e) {
      var target = $(e.currentTarget),
        tab = target.data('tab');

      this.trigger('onChangeTab', tab);
    }
  });

  module.exports = PricesLayoutView;
});
