
define('template!superAdminAddCreditsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="my-vector-popup-container">\n        <form class="form-horizontal">\n            <fieldset>\n                <div class="col-xs-12 icon-container">\n                    <div class="text-center">\n                        <i class="fa fa-cogs"></i>\n                    </div>\n                </div>\n                <div class="row col-xs-12 vertical-aligned">\n                    <div class="col-xs-5 p-0 labels-container">\n                        <span>' +
((__t = ( _.i18n("credit.popup.paypal.howMany") )) == null ? '' : __t) +
'</span>\n                    </div>\n                    <div class="col-xs-4  ">\n                        <input type="number" class="form-control input-number input-number-quantity"\n                               value="' +
((__t = ( quantity )) == null ? '' : __t) +
'" min="0" max="999999" data-field-name="quantity"\n                               placeholder="' +
((__t = ( _.i18n('credit.popup.paypal.custom.value') )) == null ? '' : __t) +
'">\n                    </div>\n\n                </div>\n            </fieldset>\n        </form>\n    </div>\n</div>';

}
return __p
};});

