
define('template!productGridFiltersTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row m-l-0 m-r-0 grid-sort">\n  <div class="col-md-5ths col-xs-2"></div>\n  <div class="col-md-5ths col-xs-2 sort-block clickable js-change-code-sort">\n    ' +
((__t = ( _.i18n('productList.productCode') )) == null ? '' : __t) +
'\n    <span class="s-ico js-sort-icon" data-sort-value="productCode">\n      <span sort="asc" class="ui-icon ui-icon-triangle-1-n"></span>\n      <span sort="desc" class="ui-icon ui-icon-triangle-1-s"></span>\n    </span>\n  </div>\n  <div class="col-md-5ths col-xs-2 sort-block clickable js-change-name-sort">\n    ' +
((__t = ( _.i18n('productList.productName') )) == null ? '' : __t) +
'\n    <span class="s-ico js-sort-icon" data-sort-value="productName">\n      <span sort="asc" class="ui-icon ui-icon-triangle-1-n"></span>\n      <span sort="desc" class="ui-icon ui-icon-triangle-1-s"></span>\n    </span>\n  </div>\n  <div class="col-md-5ths col-xs-2 sort-block clickable js-change-manufacturer-sort">\n    ' +
((__t = ( _.i18n('productList.productManufacturer') )) == null ? '' : __t) +
'\n    <span class="s-ico js-sort-icon" data-sort-value="globalSearchField">\n      <span sort="asc" class="ui-icon ui-icon-triangle-1-n"></span>\n      <span sort="desc" class="ui-icon ui-icon-triangle-1-s"></span>\n    </span>\n  </div>\n  <div class="col-md-5ths col-xs-2 sort-block clickable js-change-status-sort">\n    ' +
((__t = ( _.i18n('productList.status') )) == null ? '' : __t) +
'\n    <span class="s-ico js-sort-icon" data-sort-value="status">\n      <span sort="asc" class="ui-icon ui-icon-triangle-1-n"></span>\n      <span sort="desc" class="ui-icon ui-icon-triangle-1-s"></span>\n    </span>\n  </div>\n</div>\n<div class="row lt-deviders-bg m-b-5 m-l-0 m-r-0 p-t-1 p-b-1">\n<div class="col-md-5ths col-xs-2"></div>\n<div class="col-md-5ths col-xs-2 grid-filter">\n  <input class="js-code-filter m-t-5 m-b-5 js-code-input" type="text" value="' +
((__t = ( productCode )) == null ? '' : __t) +
'"/>\n  <span class="p-l-2per clickable js-code-clear" style="width: 10%">x</span>\n</div>\n<div class="col-md-5ths col-xs-2 grid-filter">\n  <input class="js-name-filter m-t-5 m-b-5 js-name-input" type="text" value="' +
((__t = ( productName )) == null ? '' : __t) +
'"/>\n  <span class="p-l-2per clickable js-name-clear">x</span>\n</div>\n<div class="col-md-5ths col-xs-2 grid-filter">\n  <input class="js-manufacturer-filter m-t-5 m-b-5 js-manufacturer-input" type="text" value="' +
((__t = ( manufacturer )) == null ? '' : __t) +
'"/>\n  <span class="p-l-2per clickable js-manufacturer-clear">x</span>\n</div>\n<div class="col-md-5ths col-xs-2 grid-filter">\n  <input class="js-status-filter m-t-5 m-b-5 js-status-input" type="text" value="' +
((__t = ( status )) == null ? '' : __t) +
'"/>\n  <span class="p-l-2per clickable js-status-clear">x</span>\n</div>\n</div>';

}
return __p
};});

