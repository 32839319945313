define('emailAttachmentDefaultView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentDefaultView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-default-view',
    
    ui: {
    },

    events: {
    },
    
    onRender: function () {
   
    }
  });
});
  
