define('pricePopoverMixin',[
  'module',
  'jquery',
  'underscore',
  'pricePopoverView'
], function(
  module,
  $,
  _,
  PricePopoverView
) {
  'use strict';

  var pricePickerHelper = {
    jqGridDataInit: function (element) {
      function updateInput(pricesReq) {
        if (pricesReq) {
          $element.val(pricesReq.quantity + ' - ' + pricesReq.priceMin + ' - ' + pricesReq.priceMax).change();
        } else {
          $element.val('');
        }
        $element.popover('hide');
      }

      var $element = $(element),
        pricePopoverView = new PricePopoverView({
          datePeriod: $element.val()
        });
      $element.attr('type', 'period-picker-popover');
      $element.on('clear', function() {
          pricePopoverView.triggerMethod('should:clear');
      });
        pricePopoverView.render();

      $element.popover({
        html: true,
        content: pricePopoverView.$el,
        placement: 'bottom',
        trigger: 'manual',
        container: '._compuzz-dashboard'
      });

      $element.click(function () {
        if(!$element.hasClass('popover-shown')) {
          $('input.popover-shown[type="period-picker-popover"]').popover('toggle');
        }
        $element.popover('toggle');
      });
      $element.on('shown.bs.popover', function () {
        $.material.init();
          pricePopoverView.triggerMethod('clear:inputs');
        $element.addClass('popover-shown');
      });

      $element.on('hidden.bs.popover', function() {
        $element.removeClass('popover-shown');
      });
        pricePopoverView.listenTo(pricePopoverView, 'apply', updateInput);
        pricePopoverView.listenTo(pricePopoverView, 'close:popover', function() {
        $element.popover('hide');
      });
    },

    parse: function(value) {
      var values = value.split(' - ');
      return {
        quantity: values[0],
        priceMin: values[1],
        priceMax: values[2]
      };
    }
  };



  module.exports = pricePickerHelper;
});
