
define('template!caccountPrintCodesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '  <select class="ddl-printcodes">\n    ';
 _.each(printFactors, function(printFactor) { ;
__p += '\n      <option value="' +
((__t = ( printFactor.secId )) == null ? '' : __t) +
'" ' +
((__t = ( (printFactor.printCode === current.printCode?'selected':'') )) == null ? '' : __t) +
'>' +
((__t = ( printFactor.printCode )) == null ? '' : __t) +
'</option>\n    ';
 }); ;
__p += '\n  </select>\n  <div class="btn new-cAccount-button btn-add-printcode p-l-0 p-r-0 p-t-0 p-b-0">\n    <i class="mdi-content-add"></i>\n  </div>\n  <div class="btn new-cAccount-button btn-delete-printcode p-l-0 p-r-0 p-t-0 p-b-0">\n    <i class="mdi-content-remove"></i>\n  </div>\n  <input class="txt-printcode" style="display: none;" />\n  <div class="btn new-cAccount-button btn-save-printcode p-l-0 p-r-0 p-t-0 p-b-0"\n          style="display: none;">\n      <span>' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</span>\n  </div>\n  <form class="from-print-factors">\n    ';
 if (current) { ;
__p += ' \n    <div class="row">\n      <label class="col-xs-8">Factor</label>\n      <input class="col-xs-4 txt-factor" value="' +
((__t = ( current.factor )) == null ? '' : __t) +
'" />\n    </div>\n    <div class="row p-t-5">\n      <label class="col-xs-8">Setup</label>\n      <input class="col-xs-4 txt-factor-setup" value="' +
((__t = ( current.factorSetup )) == null ? '' : __t) +
'" />\n    </div>\n    <div class="row p-t-5">\n      <label class="col-xs-8">Handling</label>\n      <input class="col-xs-4 txt-factor-handling" value="' +
((__t = ( current.factorHandling )) == null ? '' : __t) +
'" />\n    </div>\n    <div class="row p-t-5">\n      <label class="col-xs-8">Default</label>\n      <input type="checkbox" style="width: 18px; height: 18px;" id="defaultPrice" class="defaultPrice" ' +
((__t = ( current.defaultPrice?'checked':'')) == null ? '' : __t) +
'>\n    </div>\n    ';
 } ;
__p += '\n  </form>\n  ';

}
return __p
};});

