
define('template!cataloguesAddNewProductPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="add-new-container">\n\t<div class="container-fluid">\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group catalogue-image-container" data-field-name="image">\n\t\t\t\t<label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.image') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="picture-change-container col-xs-9">\n\t\t\t\t\t<div class="product-picture-block">\n\t\t\t\t\t\t<div class="logo-holder clickable default-upload-image"></div>\n\t\t\t\t\t\t<img class="product-picture-img hidden" src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t<label for="uploadProductPicture" class="upload-title clickable"><i class="mdi-file-file-upload"></i></label>\n\t\t\t\t\t\t<input id="uploadProductPicture" data-key="file" multiple\n                               type="file" name="filename" class="hidden upload-product-picture"\n                               accept="application/pdf, application/postscript, image/*"/>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control code js-form-input"\n\t\t\t\t\t\tdata-field-name="code"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( code )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control name js-form-input"\n\t\t\t\t\t\tdata-field-name="name"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( name )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

