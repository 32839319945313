define('customersPopupSelectResultsView',[
  'module',
  'app',
  'settings',
  'underscore',
  'tableView',
  'customersPopupSelectResultView'
], function (
  Module,
  App,
  Settings,
  _,
  TableView,
  CustomersPopupSelectResultView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'select-customer-results-view table-view',
    defaultSortField: 'Date',
    defaultSortDirection: 'desc',
    header: false,

    setFields: function() {
      return [{
        name: 'Email',       
        bind: 'email',
        view: CustomersPopupSelectResultView
      }];
    },

    fetch: function(first, pageSize) {

      var defer = $.Deferred();

      var url = Settings.url('compuzz2', 'endusers.json/globalsearch');
      url += '&searchTerm=' + this.options.query;
      url += '&first=' + first;
      url += '&pageSize=' + pageSize;
    
      $.ajax({
        type: 'GET',
        url: url,
        success: function (items) {
          defer.resolve(items);
        }
      });  

      return defer.promise();
    }
  });
});
