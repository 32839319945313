define('entities/webhook_history',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings'
], function (
    App,
    $,
    _,
    Backbone,
    Settings
) {
  'use strict';

  App.reqres.setHandler('webhook-history-url', function (params) {
    return Settings.url('compuzz2', 'webhookHistory/', params);
  });

  App.reqres.setHandler('webhook-history-:row-count-url', function (params) {
    return Settings.url('compuzz2', 'webhookHistory/rowCount', params);
  });
});
