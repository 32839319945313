define('ordersDetailOrderStatusCustomerReferenceView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'template!ordersDetailOrderStatusCustomerReferenceView'
], function (
  Module,
  App,
  Marionette,
  _,    
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-status-customer-reference-view item',

    ui: {
      value: '.value',
      editButton: '.btn-edit-custom-name',
      input: '.edit > input'
    },
     
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.value': 'onEdit',
      'blur  @ui.input': 'onBlurClick',
      'click @ui.input': 'onInputClick'
    },

    modelEvents: {      
      'change:customerReference': 'renderContent'
    },

    initialize: function () {
      this.edit = false;
      this.windowKeydownHandler = _.bind(this.onWindowKeydown, this);
    },

    onShow: function () {
      $(document).on('keydown', this.windowKeydownHandler);
    },

    onWindowKeydown: function(event) {
      if (event.key === 'Escape') {
        this.edit = false; 
        this.renderContent();
      }                
    },

    onDestroy: function () {
      $(document).off('keydown', this.windowKeydownHandler);
    },   

    onRender: function () {    
      this.renderContent();
    },

    renderContent: function () {       
      this.$el.attr('edit', this.edit);

      //-------------------

      if (!this.model.get('customerReference'))
        this.ui.value.html('-');
      else
        this.ui.value.html(this.model.get('customerReference'));
    },

    onEdit: function () {   
      if(this.edit) 
        this.update();

      this.edit = !this.edit;     
      this.renderContent();     
      
      if(this.edit) {
        this.ui.input.val(this.model.get('customerReference'));
        this.ui.input.focus();
      }
    },

    onBlurClick: function () {
      this.update();
    },

    onInputClick: function (event) {
      event.stopPropagation();
    },

    update: function () {
      if(!this.edit)
        return;
      
      this.edit = false;
      this.renderContent();

      if(this.ui.input.val() !== this.model.get('customerReference')) {
        this.model.set('customerReference', this.ui.input.val());
        App.request('order:update', {
          purchaseOrderId: this.model.get('purchaseOrderId'),
          customerReference: this.ui.input.val()
        }).done(_.bind(function () {
          this.model.fetch();
        }, this));
      }         
    }
  });
});
