/**
 * Created by ASK on 28/01/2021.
 */
define('pimMain',[
  'module',
  'logger',
  'pimMainRouter'
], function(
  module,
  Logger
) {
  'use strict';
 	
  var PimMain = {
    start: function() {
      Logger.log(module, 'starting PIMModule');
    },

    stop: function() {
      Logger.log(module, 'stopping PIMModule');
    }
  };

  module.exports = PimMain;
});
