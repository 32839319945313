define('mailsView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'mailsListView',
  'mailsMenuView',
  'template!mailsView',
  'entities/mails'
], function (
  App,
  Module,
  Marionette,
  _,
  ListView,
  MenuView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mails-view',

    regions: {
      listRegion: '.list-region',
      menuRegion: '.menu-region',
      mailRegion: '.mail-region'
    },

    onRender: function() {
      this.listView = new ListView({
        parentView: this
      });
      this.getRegion('listRegion').show(this.listView);

      //--------------------
      this.menuView = new MenuView({
        from: this.options.from,
        folder: this.options.folder,
        listView: this.listView,
        mailView: this.mailView
      });
      this.getRegion('menuRegion').show(this.menuView);
      this.menuView.on('folder:selected', _.bind(function (id, messageCount) {
        this.listView.mailsView.setFolder(id, messageCount);
      }, this));
    }
  });
});

