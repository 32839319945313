define('adminGlossariesListFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'selectView',
  'filtersBannerEditView',
  'template!adminGlossariesListFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  SelectView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,
    className: 'admin-tasks-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('admin.glossaries.list.filters.edit'),

    ui: _.extend({
      code: '.body .code input',
      text: '.body .text input'
    }, FiltersBannerEditView.prototype.ui),

    regions: _.extend({
      missingTranslationSelectRegion: '.missing-translation .select-region',
      typeSelectRegion: '.type .select-region'
    }, FiltersBannerEditView.prototype.regions),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {
      this.typeView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('glossaries.type.all')},
          {id: 'color', value: _.i18n('glossaries.type.color')},
          {id: 'impt',  value: _.i18n('glossaries.type.impt')},
          {id: 'material',  value: _.i18n('glossaries.type.material')},
          {id: 'other',  value: _.i18n('glossaries.type.other')},
          {id: 'paName',  value: _.i18n('glossaries.type.paName')}
        ]),
        tabindex: 1
      });
      this.typeSelectRegion.show(this.typeView);
      this.typeView.setSelected(this.model.get('type'));

      //----------------
      this.missingTranslationView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'yes',  value: _.i18n('common.yes')},
          {id: 'no',  value: _.i18n('common.no')}
        ]),
        tabindex: 2
      });
      this.missingTranslationSelectRegion.show(this.missingTranslationView);
      this.missingTranslationView.setSelected(this.model.get('missingTranslation'));

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      this.ui.code.val(this.model.get('code'));
      this.ui.text.val(this.model.get('text'));

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        missingTranslation: this.missingTranslationView.getSelected().get('id'),
        type: this.typeView.getSelected().get('id'),
        code: this.ui.code.val().trim(),
        text: this.ui.text.val().trim()
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.missingTranslationView.setSelected('');
      this.typeView.setSelected('');
      this.ui.code.val('');
      this.ui.text.val('');
    }
  });  
});

