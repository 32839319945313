define('ordersLabelsEditLabelView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'template!ordersLabelsEditLabelView'
], function (
  module,
  Marionette,
  _,
  Events,
  Tpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: Tpl,
    className: 'orders-labels-edit-label-view',

    ui: {
      title: '.title',
      content: '.content',
      selected: '.selected'
    },

    events: {
      'click' : 'onClick'
    },

    modelEvents: {
      'change': 'onRender'
    },

    initialize: function () {
      this.color = this.model.get('color').replace('#', '');
      this.allSelected = false;
      this.selected = false;
    },
    
    onRender: function () {
      if (this.model.get('name')) {
        this.ui.title.html(this.model.get('name'));
        this.ui.content.attr('title', this.model.get('name'));
      }        

      if (this.model.get('color'))
        this.ui.content.css('background-color', this.model.get('color'));

        this.allSelected = true;     
        this.selected = false;

      _.each(this.options.orders, function (order) {
        var colors = [];

        if(order.get('cardColor'))
          colors = order.get('cardColor').split(',');

        if($.inArray(this.color, colors) === -1)
          this.allSelected = false;        
  
        if($.inArray(this.color, colors) !== -1)
          this.selected = true;
      }, this);

      if(this.selected)
        this.ui.selected.css('display', 'flex');

      if(this.allSelected)
        this.ui.selected.addClass('all');

      if(this.model.get('focused')) {
        Events.push(this);
        this.$el.addClass('focus');
      }
      else {
        Events.pop(this);
        this.$el.removeClass('focus');
      }
    },

    onClick: function () {
      if(!this.allSelected) {
        _.each(this.options.orders, function (order) {
          var colors = [];

          if(order.get('cardColor')) {
            colors = order.get('cardColor').split(',');
            colors = colors.filter(item => item);
          }                    

          if($.inArray(this.color, colors) === -1)
            colors.push(this.color);

          order.set('cardColor', colors.join(','));
        }, this);
      } else {
        _.each(this.options.orders, function (order) {
          var colors = [];

          if(order.get('cardColor')) {
            colors = order.get('cardColor').split(',');
            colors = colors.filter(item => item);
          }
         
          colors.splice($.inArray(this.color, colors), 1);
          order.set('cardColor', colors.join(','));
        }, this);
      }

      this.trigger('onSelect', this.model);
      this.render();    
    },

    onKeyDown: function(e) {
      if (e.which === 32 && this.model.get('focused') === true) {
        e.preventDefault();
        e.stopPropagation();
        this.onClick();
        return false;
      }
    }
  });
});
