/**
 * Created by OLD on 31/08/2015.
 */
define('ticketsAllController',[
  'module',
  'logger',
  'underscore',
  'backbone',
  'app',
  'ticketsAllView',
  'cAccountMoreInfoView',
  'graphicalRequestKanbanAssignPopupView',
  'bootbox',
  'settings',
  'rolesMixin',
  'userUtils',
  'translation',
  'entities/tickets',
  'entities/caccounts/credits',
  'entities/kanban_tickets'
], function(
  module,
  Logger,
  _,
  Backbone,
  App,
  CollectionView,
  CAccountMoreInfoView,
  PopupView,
  bootbox,
  Settings,
  RolesMixin,
  UserUtils,
  Translation
) {
  'use strict';

  var TicketsGraphicalRequestAllInOneController = {
    showTickets: function(secId, csMsg, region, tab) {
      this.secId = secId;
      this.getTickets(secId, csMsg, region, tab);
    },

    getTickets: function(secId, csMsg, region, tab) {
      var that = this,
        ticketRequest = App.request('tickets:get-data', secId, csMsg),
        balanceRequest,
        cAccountSecId,
        cAccountDuplicateId,
        cAccountUsedVat,
        collection, view;

      $.when(ticketRequest)
        .done(function(response) {
          cAccountSecId = response.get('cAccountJsonLight').secId;
          balanceRequest = App.request('caccount-credits:get-balance', cAccountSecId);
          $.when(balanceRequest).done(function(balance) {
            that.balance = balance;
            collection = new Backbone.Collection(response.get('messages'));
            view = new CollectionView({
              collection: collection, 
              ticket: response, 
              attachedFiles: [], 
              tab: tab,
              balance: that.balance
            });
            view.csMsg = csMsg;
            that.view = view;
            view.listenTo(view, 'state:change', _.bind(that.changeState, that));
            view.listenTo(view, 'type:change', _.bind(that.changeType, that));
            view.listenTo(view, 'ticket:update:urgency', _.bind(that.updateUrgency, that));
            view.listenTo(view, 'tickets:update:tariffScale', _.bind(that.updateTariffScale, that));
            view.listenTo(view, 'tickets:delete:fileAttachment', _.bind(that.deleteAttachment, that));
            view.listenTo(view, 'tickets:details:get-users', _.bind(that._onAssignTo, that));

            $.when(App.request('tickets:mark-read', response.get('id')))
              .done(function(response) {
                Logger.log(response);
              });

            view.listenTo(view, 'ticket:change-subject', _.bind(that.changeSubject, that));
            view.listenTo(view, 'show:more:caccount:info', _.bind(that.showCAccountInfo, that));

            cAccountDuplicateId = App.request('caccount:duplicate:ip', response.get('cAccountJsonLight' ).secId);
            $.when(cAccountDuplicateId).done(function(cAccounts) {
              that.view.cAccountDuplicateIP = cAccounts ;
              if ( cAccounts && cAccounts.length ) {
                that.view.setCAccountWarning();
              }
            });

            cAccountUsedVat = App.request('caccount:duplicate:vat', response.get('cAccountJsonLight' ).secId);
            $.when(cAccountUsedVat).done(function(cAccounts) {
              that.view.cAccountDuplicateVat = cAccounts;
              if ( cAccounts && cAccounts.length > 1) {
                  that.view.setCAccountWarning();
              }
            });

            //fetch the support text only for the vector team
            if(Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
              Translation.fetchTranslations(Settings.get('textSupport') ).always(function(result) {
                view.options.textsSupport = Translation.filterTranslations(result);
                if (region) {
                  region.show(view);
                } else {
                  App.regions.getRegion('main').currentView.getRegion('content').show(view);
                }
              });
            } else {
              if (region) {
                region.show(view);
              } else {
                App.regions.getRegion('main').currentView.getRegion('content').show(view);
              }
            }
          });          
        });
    },

    _onAssignTo: function(ticketSecId, logonId) {
      var that = this;
      
      if (_.isEmpty(this.users) || !this.users) {
        $.when(App.request('kanban-tickets:get-users'))
          .done(function(_users) {
            that.users = UserUtils._parseUsers(_users);
            that._onShowAssignPopup(ticketSecId, logonId);
          });
        } else {
          this._onShowAssignPopup(ticketSecId, logonId);
        }
      
    },

    _onShowAssignPopup: function(ticketId, userLogonId) {
      var that = this,
        popupView = new PopupView(
          {users: this.users, assignedUser: userLogonId}
        );

      popupView.show({
        title:  _.i18n('common.assignTo'),
        className: 'vectorizationAssignTo',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function() { return true; }
          },
          success: {
            label: _.i18n('common.assignButton'),
            className: 'btn-primary',
            callback: function() {
              if (popupView.selectedUser) {
                if (popupView.selectedUser.get('secId')) {
                  return that._updateTicket(ticketId, 
                    popupView.selectedUser.get('secId')
                  );
                } else {
                  return that._unassignTicket(ticketId);
                }                
              } else {
                return true;
              }              
            }
          }
        }
      });
    },

    _updateTicket: function(ticketId, userSecId) {
      var that = this;

      $.when(App.request('kanban-tickets:assign-users', ticketId, userSecId))
        .done(function(user) {
          var userName = user.refUser.logonId,
            userImg = user.refUser.logo ? Settings.url('file', user.refUser.logo, {size: 'thumbnail'}) : null;
            that.view.options.ticket.set('refUser', user.refUser);
            that.view.updateAssignUser(userName, userImg, ticketId);
        });
    },

    _unassignTicket: function(ticketId) {
      var that = this;
      
      $.when(App.request('kanban-tickets:unassigned-users', ticketId))
        .done(function() {
          that.view.options.ticket.set('refUser', null);
          that.view.updateAssignUser(null, null, ticketId);
        });
    },

    showCAccountInfo: function(model) {
      var balanceRequest = App.request('caccount-credits:get-balance', model.get('secId' ));
      $.when(balanceRequest).done( _.bind(function(balance, cAccounts) {
        model.set('balance', balance);
        model.set('cAccountDuplicateIP', this.view.cAccountDuplicateIP);
        model.set('cAccountDuplicateVat', this.view.cAccountDuplicateVat);
        if(cAccounts && cAccounts.length) {
          this.view.setCAccountWarning();
        }
        var popupView = new CAccountMoreInfoView({model: model});
        popupView.show({
          title: _.i18n('common.more.info'),
          className: 'caccount-more-info'
        });
      }, this));
    },

    deleteAttachment: function(attId, ticketSecId, messageSecId) {
      var request = App.request(
        'tickets:delete-attachment', 
        attId, 
        ticketSecId, 
        messageSecId
      );

      $.when(request).done();
    },

    changeState: function(state) {
      var that = this;

      this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
      $.when(App.request('tickets:change-state', this.secId, state)).
        done(function() {
          that.view.triggerMethod('hide:message');
          that.view.triggerMethod('changeStateUI', state);
        }).
        fail(function() {
          that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
        });
    },

    changeType: function(type) {
      var that = this;

      this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
      $.when(App.request('tickets:change-type', this.secId, type)).
        done(function() {
              location.reload();
        }).
        fail(function() {
          that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
        });
    },

    updateUrgency: function(ticketCID, checked) {
      $.when(App.request('ticket:update:urgency', ticketCID, checked))
        .done(function(){});
    },

    updateTariffScale: function(ticketSecID, tariffScale) {
      $.when(App.request('tickets:update:tariffScale', ticketSecID, tariffScale))
        .done(function() {});
    },

    changeSubject: function(subject) {
      var that = this, 
        subjectRequest = App.request('tickets:change-subject', subject, this.secId);

      this.view.triggerMethod('show:message', 'primary', _.i18n('common.saving'));
      $.when(subjectRequest).done(function() {
        that.view.triggerMethod('hide:message');
      }).fail(function() {
        that.view.triggerMethod('error:message', _.i18n('warning.systemError'));
      });
    }
  };

  module.exports = TicketsGraphicalRequestAllInOneController;
});
