define('windowsManagmentView',[
  'module',
  'marionette',
  'backbone',
  'underscore',
  'windowsManagmentWindowView',
  'template!windowsManagmentView'
], function (
  Module,
  Marionette,
  Backbone,
  _,
  WindowView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: WindowView,
    childViewContainer: '.windows-region',   
    className: 'windows-managment-view',      

    childViewOptions: function(model, index) {
      return {
        windows: this.options.windows,
        managmentView: this,
        attributes: {
          tabindex: index + 1
        }
      };
    },

    ui: {            
      minimizeAllButton: '.minimize-all-button',
      unminimizeAllButton: '.unminimize-all-button',
      closeAllButton: '.close-all-button' 
    },

    regions: {
      windowsRegion: '.windows-region'
    },

    events: {     
      'click @ui.minimizeAllButton': 'onMinimizeAll',
      'click @ui.unminimizeAllButton': 'onUnminimizeAll',
      'click @ui.closeAllButton': 'onCloseAll'
    },

    initialize: function () {  
      this.collection = new Backbone.Collection();
      this.opened = false;  

      $(document).keyup(_.bind(function(e) {       
        if (e.which === 27) this.hide();
      }, this));

      $(document).keydown(_.bind(function(e) {        
        if (e.ctrlKey && e.which === 77) {
          if(!this.opened)
            this.show();
          else
            this.hide();
        }
      }, this));
    },

    isOpened: function () {
      return this.opened;
    },

    show: function () {
      if(this.options.windows.views.length === 0) return;      

      for(var i = this.options.windows.views.length-1; i >=0; i--) {
        var model = new Backbone.Model({
          html: this.options.windows.views[i].view.el.outerHTML,
          windowView: this.options.windows.views[i]
        });
        this.collection.push(model);
      }

      this.$el.css('display', 'flex');
      this.opened = true;

      if(this.options.windows.toolsView)
        this.options.windows.toolsView.render();
    },

    hide: function () {
      this.$el.css('display', 'none');
      this.opened = false;

      this.collection.reset();

      if(this.options.windows.toolsView)
        this.options.windows.toolsView.render();
    },

    remove: function(view) {
      this.collection.remove(view.model);

      if(this.collection.length === 0)
        this.hide();
    },

    onMinimizeAll: function() {
      this.children.each(_.bind(function (view) {
        view.model.get('windowView').minimize();
      }, this));     
    },

    onUnminimizeAll: function() {
      this.children.each(_.bind(function (view) {
        view.model.get('windowView').unminimize();
      }, this));

      var lastView = this.children.last();
      if(lastView)
        lastView.model.get('windowView').focus();
    },

    onCloseAll: function() {
      this.children.each(_.bind(function (view) {
        view.model.get('windowView').close();        
      }, this));

      this.hide();
    }
  });
});

