define('tasksFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'checkboxView',
  'filtersBannerEditView',
  'template!tasksFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  CheckboxView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'admin-tasks-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('tasks.filters.edit'),

    ui: _.extend(FiltersBannerEditView.prototype.ui, {
      onlyMine: '.body .onlyMine input'
    }),

    regions: _.extend({
      onlyMineRegion: '.only-mine-region'
    }, FiltersBannerEditView.prototype.regions),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {
      this.onlyMineView = new CheckboxView({
        title: '',
        text: _.i18n('task.onlyMine'),
        value: this.model.get('onlyMine'),
        tabindex: 1
      });
      this.onlyMineRegion.show(this.onlyMineView);

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        onlyMine: this.onlyMineView.getValue()
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.onlyMineView.setValue(false);
    }
  });  
});

