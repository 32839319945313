/**
 * Created by BFR on 24/02/2020.
 */
define('translationOptionView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!translationOptionTpl',
    'dynamicTextView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView
  ) {
    'use strict';
  
    var TranslationOptionView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        nameRegion: '.name-region'
      },

      onShow: function () {
        this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = TranslationOptionView;
  });
