
define('template!cAccountMoreInfoViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="panel panel-info">\n    <div class="panel-heading">' +
((__t = ( _.i18n('caccount'))) == null ? '' : __t) +
'</div>\n    <div class="panel-body">\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">\n            <span class="credit-balance-label">' +
((__t = ( _.i18n('credits.balance') )) == null ? '' : __t) +
'</span>\n          </div>\n          <div class="col-xs-8 number-of-balence">\n            <span class="credits-balance-value">' +
((__t = ( balance )) == null ? '' : __t) +
'</span>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( contactEmail ? contactEmail : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( telephone ? telephone : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">' +
((__t = ( _.i18n('common.address') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( address ? address : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( zip ? zip : '-' )) == null ? '' : __t) +
' / ' +
((__t = ( city ? city : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-12">\n          <div class="col-xs-4 bold-text ">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( country ? country : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n    </div>\n  </div>\n  ';
 if (cAccountDuplicateVat.length > 1) { ;
__p += '\n    <div class="panel panel-warning">\n        <div class="panel-heading">' +
((__t = ( _.i18n('caccount.duplicate.vat'))) == null ? '' : __t) +
'</div>\n        <div class="panel-body">\n            <div class="bs-component">\n                <div class="list-group">\n                    ';
 _.each(cAccountDuplicateVat, function(caccount) { ;
__p += '\n                    <div class="list-group-item">\n                        <div class="row-picture">\n                            <img class="circle" src="' +
((__t = ( caccount.logo )) == null ? '' : __t) +
'" alt="icon">\n                        </div>\n                        <div class="row-content">\n                            <h4 class="list-group-item-heading">' +
((__t = ( caccount.code )) == null ? '' : __t) +
'</h4>\n                            <p class="list-group-item-text">' +
((__t = ( caccount.contactEmail )) == null ? '' : __t) +
'</p>\n                        </div>\n                        <div class="list-group-separator"></div>\n                    </div>\n                    ';
 }) ;
__p += '\n                </div>\n            </div>\n        </div>\n    </div>\n  ';
 } ;
__p += '\n  ';
 if (cAccountDuplicateIP.length) { ;
__p += '\n  <div class="panel panel-warning">\n    <div class="panel-heading">' +
((__t = ( _.i18n('caccount.duplicate.ip'))) == null ? '' : __t) +
'</div>\n    <div class="panel-body">\n      <div class="bs-component">\n        <div class="list-group">\n          ';
 _.each(cAccountDuplicateIP, function(caccount) { ;
__p += '\n            <div class="list-group-item">\n              <div class="row-picture">\n                <img class="circle" src="' +
((__t = ( caccount.logo )) == null ? '' : __t) +
'" alt="icon">\n              </div>\n              <div class="row-content">\n                <h4 class="list-group-item-heading">' +
((__t = ( caccount.code )) == null ? '' : __t) +
'</h4>\n                <p class="list-group-item-text">' +
((__t = ( caccount.contactEmail )) == null ? '' : __t) +
'</p>\n              </div>\n              <div class="list-group-separator"></div>\n            </div>\n          ';
 }) ;
__p += '\n        </div>\n      </div>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

