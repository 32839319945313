/**
 * Created by BFR on 24/02/2020.
 */
define('editOptionsView',['module',
    'marionette',
    'editOptionView'],
  function (module,
            Marionette,
            EditOptionView) {
    'use strict';

    var EditOptionsView = Marionette.CollectionView.extend({
      childView: EditOptionView,
      childViewOptions: function() {     
        return {
          editable: this.options.editable
        };
      }
    });

    module.exports = EditOptionsView;
  });
