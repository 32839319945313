/**
 * Created by ASK on 28/0/2021.
 */
define('pimMainRouter',[
  'require',
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'contentShowController',
  'pimTabsLayoutController',
  'itemPagesNameMixin',
  'pimTabsData'
], function (
  require,
  module,
  Marionette,
  App,
  _,
  Settings,
  ContentShowController,
  PimTabsLayoutController,
  ItemPagesNameMixin,
  PimTabsData
) {
  'use strict';

  //var localStorageKeyProductView = 'productView';

  var PimMainRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'pim/products': 'showPimProducts',
      'pim/products/:id': 'selectProduct',
      'pim/products/:id/resid/:resid': 'selectResProduct',
      'pim/products/:id/resid/:resid/:tab': 'selectResProduct',
      'pim/products/:id/:tab': 'selectProduct',
      'pim/attributes': 'showPimAttributes'
    }
  });

  var API = {
    showPimProducts: function () {
      App.startSubModule('pimMain');

      require([
        'pimProductsListController',
        'contentShowController'
      ], function (ShowController, ContentShowController) {
        ContentShowController.showContent().done(function () {
          ShowController.showProducts();
          App.execute('set:active:nav:item', ItemPagesNameMixin.PIM_PRODUCTS);
        });
      });
    },

    showPimAttributes: function () {
      App.startSubModule('pimMain');

      require([
        'pimAttributesController',
        'contentShowController'
      ], function (attributesController, contentShowController) {
        contentShowController.showContent().done(function () {
          attributesController.showAttributes();
          App.execute('set:active:nav:item', ItemPagesNameMixin.PIM_ATTRIBUTES);
        });
      });
    },

    selectProduct: function(id, tab) {
      PimTabsLayoutController.setProduct(id).done(_.bind(function() {
        PimTabsLayoutController.setResProduct(null);    
        this.selectTab(tab);
      }, this));        
    },

    selectResProduct: function(id, resId, tab) {
      PimTabsLayoutController.setProduct(id).done(_.bind(function() {
        PimTabsLayoutController.setResProduct(resId);   
        this.selectTab(tab);  
      }, this));  
    },

    selectTab: function (tab) {             
        ContentShowController.showContent().done(_.bind(function () {
        var currentTab;
        _.each(PimTabsData, _.bind(function (avTab) {
          if (!avTab.role || Settings.get('cAccountRoles')[avTab.role]) {
            if (!currentTab) {
              currentTab = 'overview';
            }
            if (avTab.name === tab) {
              currentTab = avTab.name;
            }
          }

          if(currentTab === 'prices' && PimTabsLayoutController.pimProduct.getCurrentResProduct() === null) {
            currentTab = 'overview';
            App.navigate('/pim/products/' + PimTabsLayoutController.pimProduct.get('id') + '/overview', {trigger: false});
          }
        }, this));
        this['show' + currentTab + 'Tab']();
        App.execute('set:active:nav:item', ItemPagesNameMixin.PIM_PRODUCTS);
      }, this));
    },

    redirectToPrice: function (secId) {
      API.showpriceTab(secId);
    },

    showoverviewTab: function () {
      PimTabsLayoutController.showLayout('overview').done(function (region, model) {
        require([
          'pimOverviewTabController'
        ], function (pimOverviewTabController) {
          pimOverviewTabController.showDetails(region, model);
        });
      });
    },

    showpricesTab: function () {
      PimTabsLayoutController.showLayout('prices').done(function (region, model) {
        require([
          'pimProductPricesTabController'
        ], function (pimPricesTabController) {
          pimPricesTabController.showDetails(region, model);
        });
      });
    },

    showattributesTab: function () {
      PimTabsLayoutController.showLayout('attributes').done(function (region, model) {
        require([
          'pimProductAttributesTabController'
        ], function (pimAttributesTabController) {
          pimAttributesTabController.showDetails(region, model);
        });
      });
    }
  };

  App.on('pim:show', function (id) {
    var productLink = id ? '/' + id : '';
    App.navigate('pim/products' + productLink, true);
  }); 

  App.on('pim:select-product-tab', function (name) {
    this.selectTab(name);
  }); 

  App.on('before:start', function () {
    new PimMainRouter({
      controller: API
    });
  });

  module.exports = PimMainRouter;
});
