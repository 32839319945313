define('adminWebhookHistoryFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'selectView',
  'filtersBannerEditView',
  'template!adminWebhookHistoryFiltersEditView'
], function(
    Module,
    Backbone,
    _,
    SelectView,
    FiltersBannerEditView,
    Tpl
) {
  'use strict';

  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,
    className: 'admin-webhook-history-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webhook-history.filters.edit'),

    ui: _.extend( {
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to'
    }, FiltersBannerEditView.prototype.ui),

    regions: _.extend( {
      typeSelectRegion: '.type .type-region',
      statusSelectRegion: '.status .status-region'
    }, FiltersBannerEditView.prototype.regions),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onShow: function() {
      this.typeView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'INVOICE_CREATED', value: _.i18n('webhook-history.type.INVOICE_CREATED')}
        ]),
        tabindex: 1
      });
      this.getRegion('typeSelectRegion').show(this.typeView);
      this.typeView.setSelected(this.model.get('type'));

      //----------------
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: 'OK', value: 'OK'},
          {id: 'ERROR', value: 'ERROR'},
          {id: 'TODO', value: 'TODO'}
        ]),
        tabindex: 1
      });
      this.getRegion('statusSelectRegion').show(this.statusView);
      this.statusView.setSelected(this.model.get('type'));

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      if(this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if(this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
        dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : '',
        type: this.typeView.getSelected().get('id'),
        status: this.statusView.getSelected().get('id')
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function() {
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
      this.typeView.setSelected('');
      this.statusView.setSelected('');
    }
  });
});

