
define('template!productPriceCalculatorItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-4 prices-editable">\n        <input type="text" class="txt-prod-unit-price" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( unitPrice )) == null ? '' : __t) +
'" />\n        <span class="lbl-prod-unit-price">€' +
((__t = ( unitPrice )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="col-xs-4">\n        €<span>' +
((__t = ( sPrice )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="col-xs-4 prices-editable">\n        <input type="text" class="txt-factor" style="display: none; height: 20px; width: 50px;" value="' +
((__t = ( factor )) == null ? '' : __t) +
'" />\n        <span class="lbl-factor">' +
((__t = ( factor )) == null ? '' : __t) +
'</span>\n    </div>\n</div>';

}
return __p
};});

