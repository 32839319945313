
define('template!caccountTransportPricesView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h2>Prices</h2>\n<div class="prices-region"></div>\n<div class="buttons">\n  <div class="btn new-cAccount-button btn-add-transport-price">\n    <i class="mdi-content-add"></i>\n    <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n  </div>\n  <div class="btn new-cAccount-button btn-delete-transport-price">\n    <i class="mdi-content-delete"></i>\n    <span>' +
((__t = ( _.i18n('factors.delete') )) == null ? '' : __t) +
'</span>\n  </div>\n</div>';

}
return __p
};});

