/**
 * Created by BFR on 03/04/2020.
 */
define('shareProductsView',[
    'module',
    'app',
    'backbone',
    'underscore',
    'template!shareProductsTpl',
    'dialogCollectionView',
    'shareProductView',
    'entities/product'
  ], function(
    module, 
    App,
    Backbone,
    _,
    viewTpl,
    DialogCollectionView,
    shareProductView
  ) {
    'use strict';
  
    var ShareProductsView = DialogCollectionView.extend({
      template: viewTpl,
      childView: shareProductView,
      childViewContainer: '.products-view',
      numberToShare: 0,
      className: 'row',
      region: {
          
      },
  
      ui: {
        childDiv: '.products-view',
        blockLoading: '.js-loading-block',
        countContainer: '.count-container',
        okButton: '.js-confirm'
      },
  
      events: {
        'click @ui.okButton': 'hide'
      },

      onShow: function() {
        if (this.options.selection) {
          this.numberToShare = this.options.selection.length;
          this.loadProductFromSelection();
        } else {
          App.request('products-supplier:count-with-filters', this.options.filters).then(_.bind(function(count) {
            this.numberToShare = count;
            this.loadProduct();
          }, this));
        }
      },

      loadProductFromSelection: function() {
        this.refreshCountContainer();
        this.ui.blockLoading.show();
        if (this.options.selection && this.options.selection.length > 0) {
          var ps = this.options.selection.pop();
          var product = App.request('products:model', { secId: ps.rowid });
          product.fetch().done(_.bind(function() {
            var m = new Backbone.Model({ parentCode: product.get('code'), code: '', inProgress: true });
            this.collection.push(m);
            var d = this.ui.childDiv;
            d.scrollTop(d.prop('scrollHeight'));
            App.request('products-supplier:share-product', product.get('secId')).then(_.bind(function(p) {
              m.set({
                code: p.code,
                inProgress: false
              });
              this.ui.blockLoading.hide();
              this.loadProductFromSelection();
            }, this));
          }, this));
        } else {
          this.ui.blockLoading.hide();
          this.ui.okButton.prop('disabled', false);
        }
      },

      getTemplate: function() {
        return this.template;
      },

      refreshCountContainer: function() {
        this.ui.countContainer.html(this.collection.length + '/' + this.numberToShare);
      },

      loadProduct: function () {
        this.refreshCountContainer();
        this.ui.blockLoading.show();
        App.request('products-supplier:load-with-filters-limit1', this.options.filters).then(_.bind(function(ps) {
          if (ps && ps.length > 0) {
            var m = new Backbone.Model({ parentCode: ps[0].code, code: '', inProgress: true });
            this.collection.push(m);
            var d = this.ui.childDiv;
            d.scrollTop(d.prop('scrollHeight'));
            App.request('products-supplier:share-product', ps[0].secId).then(_.bind(function(p) {
              m.set({
                code: p.code,
                inProgress: false
              });
              this.ui.blockLoading.hide();
              this.loadProduct();
            }, this));
          } else {
            this.ui.blockLoading.hide();
            this.ui.okButton.prop('disabled', false);
          }
        }, this));
      }
    });
  
    module.exports = ShareProductsView;
  });
