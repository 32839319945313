
define('template!caccountFontsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<div class="well caccount-font-container jq-grid-table-page">\n\t\t<!-- <span class="loading-message js-loading-message label label-primary"></span> -->\n\t\t<div class="loading-block js-loading-block">\n\t\t\t<span class="loading-message js-loading-message label"></span>\n\t\t</div>\n\t\t<div class="vectorization-table">\n\t\t\t<div class="form-group add-new-block">\n\t\t\t\t<input type="file" class="filestyle js-upload-font hidden" multiple="multiple" id="upload-font"\n               accept="application/x-font-ttf,application/x-font-truetype,application/x-font-opentype,.otf,.OTF,.ttf,TTF" />\n\t\t\t\t<div class="btn add-font-button add-btn-text">\n\t\t\t\t\t<label for="upload-font" class="upload-label-btn">\n\t\t\t\t\t\t<i class="mdi-content-add icon"></i>\n            <span>' +
((__t = ( _.i18n('fonts.new') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>      \n\t\t\t<table id="jq-grid-fonts" class="vectorTable" role="grid"></table>\n\t\t\t<div id="jq-grid-pager-fonts"></div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

