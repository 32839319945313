/**
 * Created by GSP on 4/08/2015.
 */
define('editImagePluginView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'iframeFullScreen',
  'template!editImagePluginTpl'
], function(
  module,
  Marionette,
  _,
  App,
  IframeFullScreen,
  viewTpl
) {
  'use strict';

  var editImagePluginView =  Marionette.ItemView.extend({
    template: viewTpl,

    behaviors: {
      IframeFullScreen: {
        behaviorClass: IframeFullScreen
      }
    },

    serializeData: function() {
      return {
        url: this.options.url,
        title: _.i18n('userDetail.edit.dataSet')
      };
    },

    onRender: function() {
      this.$el = this.$el.children();
      this.$el.unwrap();
      this.setElement(this.$el);
    }
  });

  module.exports = editImagePluginView;
});
