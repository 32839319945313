/**
 * Created by BFR on 29/01/2018.
 */
define('orderStatusDetailView',[
  'module',
  'orderStatusCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!orderStatusDetailTabTpl'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var OrderStatusDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        orderStatusModel: this.model
      });
      createEditView.show({
        title: _.i18n('orderstatus.edit')
      });
    }

  });

  module.exports = OrderStatusDetailView;
});
