define('ordersDetailTrackingListCarrierView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'template!ordersDetailTrackingListCarrierView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-list-carrier-view ' + FieldView.prototype.className,

    ui: {
      tnt: '.tnt',
      dhl: '.dhl'
    },

    modelEvents: {
      'change:carrier': 'render'
    },

    serializeData: function() {
      var data = {};
      data.carrier = this.model.get('carrier');      

      return data;
    }
  });
});
