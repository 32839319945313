/**
 * Created by Matteo on 11/07/2015.
 */
define('selectCaccountMain',[
  'module',
  'logger',
  'selectCaccountMainRouter'
], function(
  module,
  Logger
) {
  'use strict';
  
  var SelectCAccount =  {
    start: function() {
      Logger.log(module, 'starting SelectCAccountModule');
    },

    stop: function() {
      Logger.log(module, 'stopping SelectCAccountModule');
    }
  };

  module.exports = SelectCAccount;
});
