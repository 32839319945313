define('adminGlossariesListRowToolsView',[
  'app',
  'module',
  'windows',
  'underscore', 
  'bootbox',
  'tableFieldView',
  'template!adminGlossariesListRowToolsView'
], function (
  App,
  Module,
  Windows,
  _,
  Bootbox,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-glossaries-list-row-tools-view table-field-view',

    ui: {
      deleteButton: '.delete'
    },

    events: {
      'click @ui.deleteButton': 'onDelete'
    },

    onDelete: function () {
      Bootbox.confirm(_.i18n('glossaries.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('glossaries:delete', this.model.get('secId'))
            .done(_.bind(function(){
              this.tableView.bodyView.deleteRow(this.rowView);
            }, this));
        }
      }, this));
    }
  });
});
