/**
 * Created by RKL on 19/08/2015.
 */
define('caccountAddShareView',[
  'module',
  'app',
  'underscore',
  'settings',
  'template!caccountAddShareTpl',
  'autocompleteView',
  'dialogFormView'
], function (
  module,
  App,
  _,
  Settings,
  viewTpl,
  AutocompleteView,
  DialogFormView
) {
  'use strict';

  var CAccountShareTabpopupView = DialogFormView.extend({
    template: viewTpl,

    regions: {
      productsAutocomplete: '.select-caccount'
    },

    onShow: function() {
      var productsView;

      productsView = new AutocompleteView(
        this.getAutocompleteOptions()
      );

      this.getRegion('productsAutocomplete').show(productsView);
    },

    getAutocompleteOptions: function() {
      var params = {
          first: '0',
          pageSize: 10 
        },
        url = Settings.url('compuzz', 'v2/caccounts', params);

      return {
        type: 'remote',
        remote: url,
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('common.search'),
        value: null,
        name: 'caccount',
        keys: {
          query: 'code',
          limit: 'pageSize'
        },
        callback: _.bind(this.onSelectProduct, this)
      };
    },

    onSelectProduct: function(field, model) {
      var data;

      if (model) {
        this.hide();

        data = model.toJSON();
        delete data.autocompleteValue;
        this.options.callback(data, this.options.share);
      }
    }
  });

  module.exports = CAccountShareTabpopupView;
});
