
define('template!graphicalRequestKanbanBoardViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="container p-r-0 p-l-0" id="states-container"></div>';

}
return __p
};});

