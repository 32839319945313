/**
 * Created by BFR on 02/01/2020.
 */
define('changeNamePopupView',[
    'module',
    'jquery',
    'underscore',
    'settings',
    'template!changeNamePopupTpl',
    'dialogFormView'
], function(
    module,
    $,
    _,
    Settings,
    viewTpl,
    DialogFormView
) {
    'use strict';

    var NamePopupView = DialogFormView.extend({
        template: viewTpl,

        ui: {
            inputName: '.js-info-input'
        },

        events: {
            'change @ui.inputName': 'onNameChange'
        },

        onNameChange: function() {
            this.options.name = this.ui.inputName.val();
        },

        serializeData: function() {
            var templateData = {
                name: this.options.name
            };
            return templateData;
        },

        onShow: function() {
            $.material.init();
        }
    });

    module.exports = NamePopupView;
});
