/**
 * Created by BFR on 17/06/2020.
 */
define('pimProductPriceCalculatorView',['module',
        'marionette',
        'pimProductPriceCalculatorItemView'],
  function(module,
           Marionette,
           PimProductPriceCalculatorItemView){
  'use strict';

  var PimProductPriceCalculatorView = Marionette.CollectionView.extend({
    childView: PimProductPriceCalculatorItemView,
    className: 'table-body',
    
    childViewOptions: function() {
      return {customPrice: this.options.customPrice};
    },
    childEvents: {
      'select:next': 'onSelectNextChild',
      'select:previous': 'onSelectPreviousChild'
    },

    onSelectPreviousChild: function(childView, field) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            if (field.field === 'factor') {
              previous.onLblFactorClick();
            } else {
              previous.onLblProductUnitPriceClick();
            }
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextChild: function(childView, field) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          if (field.field === 'factor') {
            this.children._views[property].onLblFactorClick();
          } else {
            this.children._views[property].onLblProductUnitPriceClick();
          }
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    }
  });

  module.exports = PimProductPriceCalculatorView;
});
