
define('template!cataloguesSharedAddTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-7 container-fluid">\n        <form class="form-horizontal col-xs-12 " role="form">\n            <div class="breakWord"> ' +
((__t = ( _.i18n("catalogues.shared.add.confirm") )) == null ? '' : __t) +
'\n            </div>\n            <br/>\n        </form>\n    </div>\n    <div class="col-xs-5">\n        <!-- catalogue preview -->\n        <div class="card radius shadowDepth1">\n            <div class="card__image border-tlr-radius div-compuzz-vrp-catalogue-content-block">\n                <img src="' +
((__t = ( pictureUrl )) == null ? '' : __t) +
'" class="img-compuz-vrp-viewer border-tlr-radius">\n            </div>\n\n            <div class="card__content card__padding">\n                <div class="card__perso">\n                    <a id="perso" class="perso-toggle perso-icon" href="#"></a>\n                </div>\n\n                <div class="card__meta">\n                    <span href="#"><b>' +
((__t = ( code )) == null ? '' : __t) +
'</b><br/>' +
((__t = ( name )) == null ? '' : __t) +
' </span>\n                    <span href="#">' +
((__t = (description)) == null ? '' : __t) +
'</span>\n                </div>\n\n                <article class="card__article">\n                <span class="pull-right text-lighten-1 font-thin h5 " >\n                    <img src="' +
((__t = ( cAccountLogoUrl )) == null ? '' : __t) +
'" style="max-width: 50px; max-height: 30px;"/>\n                     ' +
((__t = ( cAccountName )) == null ? '' : __t) +
'\n                </span>\n                </article>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="row m-t-10">\n    <div class="pull-right p-r-15">\n        <a class="btn btn-default btn-flat flat-primary js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</a>\n        <a class="btn btn-primary pull-right sa-accept">common.ok</a>\n    </div>\n</div>';

}
return __p
};});

