
define('template!webshopProductsDetailBodyProductView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="left">\n    <div class="product-label item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.label') )) == null ? '' : __t) +
'</div>\n        <input type="text" class="value">\n    </div>\n    ';
 if(showDefaultCategory) { ;
__p += '\n      <div class="default-category item">\n          <div class="libelle">' +
((__t = ( _.i18n('common.category') )) == null ? '' : __t) +
'</div>\n          <input type="text" name="webshopCategories" list="webshop-categories" class="value">\n      </div>\n    ';
 } ;
__p += '\n    <div class="status item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n        <div class="status-region value"></div>\n    </div>\n    <div class="tags item">\n        <div class="libelle">' +
((__t = ( _.i18n('webshop.product.tag') )) == null ? '' : __t) +
'</div>\n        <div class="tags-region value"></div>\n    </div>\n    ';
 if(showPageLayout) { ;
__p += '\n      <div class="layout item">\n          <div class="libelle">' +
((__t = ( _.i18n('webshop.product.layout') )) == null ? '' : __t) +
'</div>\n          <div class="layout-region value"></div>\n      </div>\n    ';
 } ;
__p += '\n    ';
 if(showYoutubeVideo) { ;
__p += '\n      <div class="youtube-video item">\n          <div class="libelle">' +
((__t = ( _.i18n('webshop.product.youtube-video') )) == null ? '' : __t) +
'</div>\n          <input type="text" class="value">\n      </div>\n    ';
 } ;
__p += '\n    ';
 if(showOutdated) { ;
__p += '\n      <div class="outdated-region"></div>\n    ';
 } ;
__p += '\n    ';
 if(showUnfinished) { ;
__p += '\n      <div class="unfinished-region"></div>\n    ';
 } ;
__p += '\n    ';
 if(showUploadLogo) { ;
__p += '\n      <div class="logo-upload-region"></div>\n    ';
 } ;
__p += '\n    ';
 if(showCompositionEditor) { ;
__p += '\n        <div class="composition-editor-region"></div>\n    ';
 } ;
__p += '\n    <div class="google-shopping-region"></div>\n\n</div>\n<div class="right">\n  ';
 if(showNoindex) { ;
__p += '\n    <div class="indexes-region"></div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

