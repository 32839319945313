
define('template!productAddNewPicturePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="container-fluid add-new-template-container">\n  <div class="row form-group text-center">\n    <div class="col-sm-6 p-l-5 p-r-5">\n      <button type="button" class="btn btn-primary js-from-empty">' +
((__t = ( _.i18n("common.fromEmpty") )) == null ? '' : __t) +
'</button>\n    </div>\n\n    <div class="col-sm-6 p-l-5 p-r-5">\n      <input type="file" class="filestyle js-from-upload hidden" multiple="multiple" id="aprofile-image-input" accept="image/*,application/pdf,application/ai,application/eps,.cpzc"/>\n      <div class="bootstrap-filestyle">\n        <label for="aprofile-image-input" class="btn btn-primary">\n          <span>' +
((__t = ( _.i18n("common.fromUpload") )) == null ? '' : __t) +
'</span>\n        </label>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

