
define('template!translationsFilterLanguagesLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul class="nav nav-tabs language-filter-nav-bar m-b-10">\n  <li class="js-dropdown dropdown">\n    <a id="translations-filter-button-region" class="dropdown-toggle clickable" data-toggle="dropdown" data-target="#" style="padding-top: 0px;color: #808080 !important;">\n    </a>\n    <ul id="translations-filter-list-region" class="dropdown-menu">\n    </ul>\n  </li>\n</ul>';

}
return __p
};});

