/**
 * Created by OLD on 20/08/2015.
 */
define('caccountObjectNamesAddView',[
  'module',
  'app',
  'underscore',
  'template!caccountObjectNamesAddTpl',
  'dialogFormView',
  'autocompleteView'
], function(
  module,
  App,
  _,
  viewTpl,
  DialogFormView,
  AutocompleteView
) {
  'use strict';

  var CAccountsObjectNamesTabPopupView = DialogFormView.extend({
    template: viewTpl,

    ui: {
      input: '.js-popup-input'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus'
    },

    regions: {
      objectTypeSelect: '.type-select'
    },

    objectTypes: function() {
      return [
        {
          name: _.i18n('common.text'),
          value: 'text',
          id: 0
        }, {
          name: _.i18n('common.image'),
          value: 'image',
          id: 1
        }
      ];
    },

    onShow: function() {
      var dropdownView = new AutocompleteView(
        this.getDropdownOptions()
      );

      this.getRegion('objectTypeSelect').show(dropdownView);

      if (this.options.objectType) {
        $('.autocomplete').addClass('disabled');
      }

      $.material.init();
    },

    getDropdownOptions: function() {
      var type = this.model.get('type'),
        objectTypes = this.objectTypes(),
        objectType, name;

      if (type) {
        objectType = _.findWhere(objectTypes, {value: type});
      }

      if (type && objectType) {
        name = objectType.name;
      } else {
        name = objectTypes[0].name;
      }

      return {
        data: objectTypes,
        valueKey: 'name',
        apiKey: 'id',
        value: name,
        name: 'type',
        inputType: 'dropdown',
        callback: _.bind(this.onChangeDropdown, this)
      };
    },

    onChangeDropdown: function(fieldName, model) {
      var value = model ? model.get('value') : '';

      this.onChange(fieldName, value);
    }
  });

  module.exports = CAccountsObjectNamesTabPopupView;
});
