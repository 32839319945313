/**
 * Created by OLD on 31/08/2015.
 */
define('ticketsView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'bootbox',
  'imageUtils',  
  'stateMessages',
  'messagesView',
  'messageModel',
  'template!ticketsTpl',
  'notificationCenter',
  'changeStatusView',
  'ticketStates',
  'attachmentCollectionView',
  'rolesMixin',
  'translation',
  'tawlkToBehavior',
  'dropdownCompositeView'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  App,
  Settings,
  bootbox,
  ImageUtils,
  StateMessages,
  ItemView,
  MessageModel,
  viewTpl,
  NotificationCenter,
  ChangeStatusView,
  ticketStates,
  AttachmentCollectionView,
  RolesMixin,
  Translation,
  TawlkToBehavior,
  DropdownCompositeView
) {
  'use strict';

  var TicketsView = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.messages-container',
    childView: ItemView,
    childViewOptions: function(model) {
      model.set('translateFrom', this.options.ticket.get('cAccountJsonLight' ).lang);
    },

    ui: {
      sendMessage: '.js-send-new-m-b',
      content: '.tickets-content',
      textarea: '.create-new-message',
      attach: '.attach-input',
      attachBtn: '.attach-btn',
      attachBox: '.attachments-addon',
      attachList: '.attachments-addon-list',
      preview: '.img-preview-m',
      counter: '.counter-m',
      ticketState: '#ticket-state',
      backToList: '.back-to-list',
      subjectInput: '.subject-input',
      footer: '.js-footer',
      ticketsHeader: '.js-tickets-header',
      cAccountMoreInfo: '.caccount-more-info'
    },



    events: {
      'click @ui.sendMessage' : 'sendMessage',
      'click @ui.attachBtn': 'onFileBrowse',
      'change @ui.attach' : 'selectAttach',
      'click @ui.close' : 'closeTicket',
      'click @ui.backToList': 'onBackToList',
      'change @ui.subjectInput' : 'onChangeSubject',
      'autogrow @ui.footer': 'onTextAreaGrow',
      'click .dropdown-menu li a': 'onTextSupportSelected',
      'click @ui.cAccountMoreInfo': 'onShowCAccountMoreInfoDialog'
    },

    childEvents: {
      'onUploadImage' : 'onUploadImage',
      'attachedViewShown' : 'checkIfNeedHeightScroll'
    },


    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      },
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: false
      }

    },

    onShow: function () {
      this.messagesCouner = 0;
      $.material.init();
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.NEW_VECTORISATION_TICKET_MESSAGE, this.onMessage);
      this.showAttachmentsView();
      this.showChangeStatus();
      this.showChangeType();
      this.ui.textarea.autogrow();
      this.positionHeaderAndFooter();
      this.ui.textarea.css('max-height', (this.ui.content.height() + this.ui.footer.height()) / 2);
      $(window).on('resize.positionHeaderAndFooter', _.bind(this.positionHeaderAndFooter, this));
      App.on('sidebar:resize', this.positionHeaderAndFooter, this);
      App.on('sidebar:switch', this.positionHeaderAndFooter, this);
    },

    onShowCAccountMoreInfoDialog: function() {
      this.trigger('show:more:caccount:info', new Backbone.Model(this.options.ticket.get('cAccountJsonLight')));
    },

    onTextSupportSelected: function(e) {
      e.preventDefault();
      this.ui.textarea.val(
        _.i18n(Translation.getTextSupport($(e.currentTarget).data('key')),
        this.options.ticket.get('cAccountJsonLight' ).lang));
    },

    onUploadImage: function(childView) {
      var formdata = new FormData(),
        file = childView.ui.uploadInput[0].files[0],
        request, params, src, link;

      formdata.append('file', file);
      request = App.request('userRequestFile:upload', formdata);
      childView.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));

      $.when(request).done(function(resp) {
        params = { logoUID: resp };
        src = Settings.url('x2pdf', 'vectorteam/preparefile', params);

        link = window.document.createElement('a');
        link.href = src;
        link.download = 'download.pdf';
        link.click();

        childView.triggerMethod('hideMessage');
      });
    },

    showAttachmentsView: function() {
      this.attachmentsView = new AttachmentCollectionView({el: this.ui.attachList});
      this.attachmentsView.render();
    },

    showChangeStatus: function() {
      if(!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
        return;
      }
      this.changeStatusView = new ChangeStatusView({currentState: this.options.ticket.get('ticketState')});
      this.changeStatusView.render();
      this.listenTo(this.changeStatusView, 'status:change', this.changeStatus);
      this.$('#change-status-region').append(this.changeStatusView.el);
    },


    showChangeType: function() {
        var state = this.options.ticket.get('ticketState');
        if(!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] ||
            state === 'CLOSE' ||
            state === 'DONE' ||
            state === 'CANCELED') {
            return;
        }
        this.changeTypeView = new DropdownCompositeView({
            collection: new Backbone.Collection([{ label: _.i18n('menu.vectorizationRequest'), value: 'VECTORIZATION' },
                { label: _.i18n('menu.backRemove'), value: 'REMOVE_BACKGROUND' },
                { label: _.i18n('menu.fileconversion'),  value: 'CONVERSION' }]),
            onItemSelected: _.bind(function(type){
                this.trigger('type:change', type);
            }, this)
        });
        this.changeTypeView.render();
        this.changeTypeView.triggerMethod('before:show');
        this.$('#change-type-dropdown-region').append(this.changeTypeView.el);
    },

    onMessage: function (response) {
      if (this.options.ticket.get('secId') !== response.content.ticketSecId) {
        //if the message is not for me then ask notification center to display a notification.
        NotificationCenter.getInstance().processNotification(response);
      } else {
        if (!_.findWhere(this.collection.toArray(), {id:response.content.id})) {
          var messageModel = new MessageModel(response.content);
          this.addMessage(messageModel);
        }
      }
    },

    onChangeSubject: function() {
      this.ui.subjectInput.attr('title', this.ui.subjectInput.val());
      this.trigger('ticket:change-subject', this.ui.subjectInput.val());
    },

    serializeData: function() {
      var templateData = this.collection.toJSON();

      templateData.ticketState = this.options.ticket.get('ticketState');
      templateData.subject = this.options.ticket.get('name');
      templateData.vectorizator = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR];
      templateData.ticketStates = ticketStates;
      templateData.logoUrl = ImageUtils.getLogo(this.options.ticket.get('cAccountJsonLight').logo, templateData.code);
      templateData.textsSupport = this.options.textsSupport;
      templateData.ticketSecId = this.options.ticket.get('secId');
      return templateData;
    },



    onBackToList: function() {
      App.navigate('vectorization', {trigger: true});
    },

    /* Scroll messages to the bottom of visible area*/
    checkIfNeedHeightScroll: function() {
      this.messagesCouner++;
      if (this.messagesCouner === this.collection.length) {
          this.heightScroll();
      }
    },
    heightScroll: function (target) {
      var content = this.ui.content;

      if (target === 'attachment:add') {
        content.addClass('m-b-45');
      } else {
        content.removeClass('m-b-45');
      }
      //Get the last message position and height
      var $lastMsg = this.$('.messages-container').find('.tickets-message-container' ).last();
      //Set the new height on the message container
      this.ui.content.css('height', $lastMsg.position().top +
                                    $lastMsg.height() +
                                    this.ui.footer.height() + this.ui.ticketsHeader.height());
      //Scroll at the bottom of the page
      setTimeout(function() {
        $('#content-region').animate({scrollTop: content.height()}, 'slow');
      }, 0);
    },

    sendMessage: function() {
      var that = this,
        messageContent = this.ui.textarea.val(),
        textarea = this.ui.textarea,
        messageModel;
      that._disableSend();
      if (messageContent !== '' || that.options.attachedFiles !== []) {
        textarea.parent().removeClass('has-error');

        messageModel = new MessageModel();
        messageModel.set('content', messageContent);
        messageModel.set('name', that.options.ticket.get('name') || ' ');
        messageModel.set('files', that.options.attachedFiles);
        messageModel.url = messageModel.postUrl({}, that.options.ticket.get('secId'));
        textarea.prop('disabled', true);

        messageModel.save().done(function () {
          textarea.prop('disabled', false);
          textarea.val('');
          that.addMessage(messageModel);
          that.attachmentsView.collection.reset();
          that._enableSend();
        });
      } else {
        textarea.parent().addClass('has-error');
      }
    },

    addMessage: function (messageModel) {
      var counter = this.ui.counter.attr('data-count');
      this.collection.add(messageModel);
      this.options.attachedFiles = [];
      this.ui.attachBox.addClass('hidden');
      this.ui.counter.html(parseInt(counter, 10) + 1);
      this.ui.counter.attr('data-count', parseInt(counter, 10) + 1);
      this.heightScroll();
      this._triggerDeleteTextArea();
    },

    onFileBrowse: function(e) {
      e.preventDefault();
      this.ui.attach.click();
    },

    selectAttach: function(event) {
      var files = this.ui.attach[0].files;
      this.fileToLoad = files.length;
      this.fileLoaded = 0;
      if (this.fileToLoad === 0) {
        return;
      }
      this.ui.sendMessage.addClass('disabled');
      this.startMultiUpload(event.target);
    },

    startMultiUpload: function(fileInput){
      this.fileInputTmp = fileInput;
      this.postLoadedAttach(fileInput);
    },

    postLoadedAttach: function (fileInput) {
      if (fileInput) {
        this.fileInfo = ImageUtils.getFileInfo(fileInput, this.fileLoaded);

        //Load and encode the image
        ImageUtils.encodeInputFileToBase64(fileInput,
        this.fileLoaded, _.bind(this.onAttachmentEncoded, this));
      }
    },

    onAttachmentEncoded: function(base64File) {
      var file;
      file = {
        name: this.fileInfo.fileName,
        content: base64File, //base64 file encoded
        extension: this.fileInfo.fileExt //file extension
      };
      this.fileInfo.base64Value = base64File;

      this.options.attachedFiles.push(file);
      this.fileLoaded++;
      this.showAttach(file);
    },

    showAttach: function(file) {
      var attachmentModel = new Backbone.Model();

      attachmentModel.set('name', file.name);
      attachmentModel.set('extension', file.extension);
      this.attachmentsView.collection.add(attachmentModel);
      this.ui.attachBox.removeClass('hidden');
      if (this.fileLoaded < this.fileToLoad) {
        this.startMultiUpload(this.fileInputTmp);
      }
      else{
          this.ui.attach.val('');
      }
      this.heightScroll('attachment:add');
      this.ui.sendMessage.removeClass('disabled');
    },

    changeStatus: function(state) {
      this.ui.backToList.css('pointer-events', 'none');
      this.trigger('state:change', state);
    },

    onChangeStateUI: function(state) {
      this.ui.ticketState.text(ticketStates[state].label);
      this.ui.backToList.css('pointer-events', 'auto');
    },

    _disableSend: function() {
      this.ui.sendMessage.addClass('disabled');
      this.ui.attachBtn.addClass('disabled')
        .find('a.send-new-m-b').addClass('disabled');
      this.ui.attach.attr('disabled', true);
    },

    _enableSend: function() {
      this.ui.sendMessage.removeClass('disabled');
      this.ui.attachBtn.removeClass('disabled')
        .find('a.send-new-m-b').removeClass('disabled');
      this.ui.attach.attr('disabled', false);
    },

    _triggerDeleteTextArea: function() {
      var e = $.Event('keyup', {which: 8});
      this.ui.textarea.trigger(e);
    },

    onBeforeDestroy: function() {
      App.off('sidebar:resize', this.positionHeaderAndFooter, this);
      App.off('sidebar:switch', this.positionHeaderAndFooter, this);
      $(window).off('resize.positionHeaderAndFooter');
      if (this.attachmentsView) {
        this.attachmentsView.destroy();
      }
      if(this.changeStatusView) {
        this.attachmentsView.destroy();
      }
    },

    onTextAreaGrow: function() {
      this.heightScroll();
    },

    positionHeaderAndFooter: function() {
      var self = this;
      var $sideBarWidth = $('#sidebar-region').width();
      if($('#sidebar-region' ).hasClass('nav-off-screen')) {
        self.ui.ticketsHeader.css('top', 0);
        self.ui.ticketsHeader.css('left', 0);
        self.ui.footer.css('left',0);
      } else {
        self.ui.ticketsHeader.css('top', 75);
        self.ui.ticketsHeader.css('left', $sideBarWidth);
        self.ui.footer.css('left',$sideBarWidth);
      }
    },

    setCAccountWarning: function() {
      this.ui.cAccountMoreInfo.parent().addClass('caccount-more-info-warning');
      this.$('.caccount-more-info > i' ).removeClass('fa-info-circle' ).addClass('fa-exclamation-triangle');
    }
  });

  module.exports = TicketsView;
});
