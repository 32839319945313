define('envHandler',[
  'module',
  'jquery',
  'env',
  'browserUtils'
], function (module, $, Environment, BrowserUtils) {
  'use strict';
  
  var EnvHandler = {
    getValue: function (env, key) {
      if (!env || env === ' ') {
        switch (BrowserUtils.getHostName()) {
        case 'localhost':
        case '127.0.0.1':
          if (BrowserUtils.getHref().indexOf('dist') !== -1) {
            env = 'minified';
          } else {
            env = 'defaults';
          }
          break;
        case 'alpha.compuzz.com':
          env = 'alpha';
          break;
        case 'beta.compuzz.com':
          env = 'beta';
          break;
        default:
          throw('Unknown environment: ' + BrowserUtils.getHostName());
        }
      }
      return Environment.config[env][key];
    }
  };

  module.exports = EnvHandler;
});

