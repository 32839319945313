define('entities/pim_attribute',[
    'app',
    'jquery',
    'settings',
    'backbone',
    'backboneRelational'
  ], function (
    App,
    $,
    Settings,
    Backbone
  ) {
    'use strict';  
  
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
      app = { ns: Backbone.Relational.store._modelScopes[0]};
    } else {
      app = { ns: {}};
      Backbone.Relational.store.addModelScope(app.ns);
    } 
  
    //-----------------

    app.ns.PimAttributeValues = Backbone.Model.extend({      
      save: function(secId) {
        this.url = Settings.url('compuzz2', 'v2/attributes/' + secId + '/values');
        return Backbone.Model.prototype.save.call(this);
      },
      destroy: function(secId, valSecId) {
        this.url = Settings.url('compuzz2', 'v2/attributes/' + secId + '/values/' + valSecId);
        return Backbone.Model.prototype.destroy.call(this);
      },
      idAttribute: 'secId'
    });

    app.ns.PimAttributeValuesCollection = Backbone.Collection.extend({
      model: app.ns.PimAttributeValues,
      fetch: function (secId) {
        this.url = Settings.url('compuzz2', 'v2/attributes/' + secId + '/values', {'first': 0, 'pageSize': 999});
        return Backbone.Collection.prototype.fetch.call(this);
      }      
    });   
  
    app.ns.PimAttribute = Backbone.Model.extend({          
      fetch: function () {      
        this.url = Settings.url('compuzz2', 'v2/attributes/' + this.get('secId'));
        return Backbone.Collection.prototype.fetch.call(this);
      },
      save: function () {   
        this.url = Settings.url('compuzz2', 'v2/attributes');  
        return Backbone.Model.prototype.save.call(this);
      },
      destroy: function() {   
        this.url = Settings.url('compuzz2', 'v2/attributes/' + this.get('secId'));    
        return Backbone.Model.prototype.destroy.call(this);
      },
      idAttribute: 'secId'
    });
   
    //-----------------
    
    /*var API = {
        
    };*/

    //-----------------

    App.reqres.setHandler('pim:attribute:model', function (data) {
      return new app.ns.PimAttribute(data);
    });

    App.reqres.setHandler('pim:attribute:values:collection', function () {
      return new app.ns.PimAttributeValuesCollection({'model': new app.ns.PimAttributeValues()});
    });

    App.reqres.setHandler('pim:attributes:get-collection-url', function () {
      return Settings.url('compuzz2', 'v2/attributes');
    });

    /*App.reqres.setHandler('pim:attributes:model', function (product) {
      var tt = Backbone.Relational.store.find(app.ns.PimProduct, product.id );
      if (tt) {
        return tt;
      } else {
        return new app.ns.PimProduct(product);
      }
    }); */

    /*App.reqres.setHandler('pim:attributes:get-collection-url', function () {

      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'v2/attributes');
      $.ajax({
        type: 'GET',
        url: url,
        success: function (data, resp) {
          if(resp !== 'success')
            bootbox.confirm(_.i18n('error.addPimAttributes'), function () {});     
          else
            defer.resolve(data);
        }
      });
      return defer.promise();
    });*/
  });
  
