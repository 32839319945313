define('ordersBoardFiltersQuickAccessView',[
  'module',
  'marionette',
  'underscore',
  'filtersBannerQuickAccessView',
  'template!ordersBoardFiltersQuickAccessView'
], function (
  Module,
  Marionette,
  _,
  QuickAccessView,
  Tpl
) {
  'use strict';

  Module.exports = QuickAccessView.extend({
    template: Tpl,
    className: 'orders-board-filters-quick-access-view ' + QuickAccessView.prototype.className,

    ui: _.extend(QuickAccessView.prototype.ui, {
      user: '.user',
      poi: '.poi'
    }),

    events: _.extend(QuickAccessView.prototype.events, {
      'change @ui.user': 'onUserChange',
      'change @ui.poi': 'onPoiChange'
    }),

    modelEvents: {
      'change:endUserId': 'onRender',
      'change:purchaseOrderId': 'onRender'
    },

    initialize: function() {
      QuickAccessView.prototype.initialize.apply(this, arguments);
    },

    serializeData: function() {
      var data = {};
      data.type = this.options.bannerView.options.type;
      return data;
    },

    onRender: function() {
      this.ui.user.val(this.model.get('endUserId'));
      this.ui.poi.val(this.model.get('purchaseOrderId'));
    },

    onUserChange: function() {
      this.model.set('endUserId', this.ui.user.val().trim());
      this.trigger('confirm', this.model);
    },

    onPoiChange: function() {
      this.model.set('purchaseOrderId', this.ui.poi.val().trim());
      this.trigger('confirm', this.model);
    }
  });
});
  
