/**
 * Created by BFR on 05/09/2020.
 */
define('productSimilarsView',['module',
        'marionette',
        'app',
        'underscore',
        'productSimilarView',
        'template!productSimilarsTpl',
        'similarCreateEditLayoutView',
        'entities/product_similar'
  ],
  function(module,
           Marionette,
           App,
           _,
           ProductSimilarView,
           itemTpl,
           SimilarCreateEditLayoutView){
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: itemTpl,
    childViewContainer: '.similars-items',
    childView: ProductSimilarView,

    initialize: function() {
    },

    events: {
      'click .btn-add-similar': 'onBtnAddSimilarClick'
    },

    onBtnAddSimilarClick: function() {
      var similarCreateEditLayoutView = new SimilarCreateEditLayoutView({
        model: App.request('product-similar:model', { productSecId: this.options.model.get('secId') }),
        collection: this.collection,
        newItem: true
      });

      similarCreateEditLayoutView.show({
        title: _.i18n('similars.title')
      });
    }
  });
});
