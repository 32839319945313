define('entities/tracking',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function (
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Tracking = Backbone.Model.extend({
    defaults: {
      carrier: 'dpd',
      trackingNumber: '',
      trackingStatus: '',
      trackingUrl: '',
      destinationType: 'tocustomer'
    },

    idAttribute: 'secId',

    url: function () {
      return Settings.url('compuzz2', 'commands.json/undefined/tracking/' + this.get('secId'));
    },

    /*postUrl: function (poi) {
      return Settings.url('compuzz2', 'commands.json/' + poi + '/tracking');
    },

    save: function (poi) {
      this.url = this.postUrl(poi);
      return Backbone.Model.prototype.save.call(this);
    },*/

    remove: function () {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/undefined/tracking/' + this.get('secId'));

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },
  

    trackingUrl: function (customer) {
      if (Settings.configValue('order.trackingPageUrl')) {
        var url = Settings.configValue('order.trackingPageUrl') + this.get('trackingNumber');

        if(customer && customer.get('locales')) {
          var lang = null;
          lang = customer.get('locales').toLowerCase();
          url += '?lang=' + lang;
        }

        return url;
      } else {
        return this.get('trackingUrl');
      }
    }
  });

  var TrackingCollection = Backbone.Collection.extend({
    urlFetch: function (poi) {
      return Settings.url('compuzz2', 'commands.json/' + poi + '/tracking', {first: 0, pageSize: 10});
    },

    fetch: function (poi) {
      this.url = this.urlFetch(poi);
      return Backbone.Collection.prototype.fetch.call(this);
    },
    model: Tracking
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function (poi) {
      return 'commands.json/' + poi + '/tracking/rowCount';
    },

    getList: function (poi, params) {
      var defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/tracking', params),
        contentType: 'application/json',
        success: function (items) {
          defer.resolve(items);
        },
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer.promise();
    },

    deleteTrackingItem: function (poi, trackingSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/tracking/' + trackingSecId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('tracking-tab:row-count-url', function (poi) {
    return API.getRowCountUrl(poi);
  });

  App.reqres.setHandler('tracking-tab:list', function (poi, params) {
    return API.getList(poi, params);
  });

  App.reqres.setHandler('tracking-tab:new-model', function (val) {
    var newOne = new Tracking(val);
    return newOne;
  });

  App.reqres.setHandler('tracking-tab:new-collection', function () {
    return new TrackingCollection();
  });

  App.reqres.setHandler('tracking-tab:delete', function (poi, trackingSecId) {
    return API.deleteTrackingItem(poi, trackingSecId);
  });
});

