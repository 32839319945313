define('ordersDetailOrderStatusTypesView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'ordersDetailOrderStatusTypeView',
  'entities/orders'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  OrdersDetailOrderStatusTypeView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    tagName: 'select',
    className: 'orders-detail-order-status-types-view',
    childView: OrdersDetailOrderStatusTypeView,  

    ui: {
      'empty': '.empty'
    },

    events: {
      'change': 'onSelectChange'
    },

    modelEvents: {
      'change:commandType': 'setSelected'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      App.request('order:types').done(_.bind(function (types) {
        this.collection.reset(types);
      }, this));
    },

    onRender: function () {
      this.setSelected();
    },

    setSelected: function () {
      _.each(this.children._views, _.bind(function (view) {
        if(view.model.get('code') === this.model.get('commandType'))
          view.setSelected(true);
        else
          view.setSelected(false);
      }, this));
    },

    onSelectChange: function (e) {       
      this.model.updateType(e.target.value)
      .done(_.bind(function () {
        this.model.fetch();
      }, this))
      .fail(_.bind(function () {
        this.render();
      }, this));
    }
  });
});
