define('customersDetailBodyProfileAddressView',[
  'app',
  'module',
  'underscore',
  'marionette',
  'ordersController',
  'template!customersDetailBodyProfileAddressView'
], function (
  App,
  Module,
  _,
  Marionette,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,

    className: 'customers-detail-profile-address-view',

    ui: {
      copyButton: '.copy-button'
    },

    events: {
      'click @ui.copyButton': 'onCopyAddress'
    },

    modelEvents: {
      'change:name': 'render',
      'change:firstName': 'render',
      'change:lastName': 'render',
      'change:addressL1': 'render',
      'change:addressL2': 'render',
      'change:houseNumber': 'render',
      'change:zip': 'render',
      'change:city': 'render',
      'change:countryCode': 'render'
    },

    serializeData: function () {
      var data = {};

      data.title = this.options.title;

      //-------------------
      data.name = '-';
      if(this.model.get('name'))
        data.name = this.model.get('name');

      //-------------------
      data.firstName = '-';
      if(this.model.get('firstName'))
        data.firstName = this.model.get('firstName');

      //-------------------
      data.lastName = '-';
      if(this.model.get('lastName'))
        data.lastName = this.model.get('lastName');

      //-------------------
      data.addressL1 = '-';
      if(this.model.get('addressL1'))
        data.addressL1 = this.model.get('addressL1');

      //-------------------
      data.addressL2 = '-';
      if(this.model.get('addressL2'))
        data.addressL2 = this.model.get('addressL2');

      //-------------------
      data.houseNumber = '-';
      if(this.model.get('houseNumber'))
        data.houseNumber = this.model.get('houseNumber');

      //-------------------
      data.zip = '-';
      if(this.model.get('zip'))
        data.zip = this.model.get('zip');

      //-------------------
      data.city = '-';
      if(this.model.get('city'))
        data.city = this.model.get('city');

      //-------------------
      data.countryCode = '-';
      if(this.getCountryName(this.model.get('countryCode')))
        data.countryCode = this.getCountryName(this.model.get('countryCode')).name;

      return data;
    },

    onCopyAddress: function () {
      Controller.copyToClipboard(this.getAddressClipboard(this.model.toJSON()));
    },

    getAddressClipboard: function (data) {
      var result = '';

      if (data.name)
        result += data.name;

      //--------
      var str = '';

      if (data.lastName)
        str += data.lastName;

      if (data.firstName) {
        if (str !== '')
          str += ' ';
        str += data.firstName;
      }

      if (str !== '')
        result += '\n' + str;

      //--------
      str = '';

      if (data.addressL2)
        str += data.addressL2;

      if (data.houseNumber) {
        if (str !== '')
          str += ', ';
        str += data.houseNumber;
      }

      if (data.addressL1) {
        if (str !== '')
          str += ', ';
        str += data.addressL1;
      }

      if (str !== '')
        result += '\n' + str;

      //--------
      str = '';

      if (data.zip)
        str += data.zip;

      if (data.city) {
        if (str !== '')
          str += ' ';
        str += data.city;
      }

      var country = this.getCountryName(data.countryCode);

      if (country && country.name) {
        if (str !== '')
          str += '\n';
        str += country.name;
      }

      if (str !== '')
        result += '\n' + str;

      return result;
    },

    getCountryName: function (countryCode) {
      return _.findWhere(this.options.countries.toJSON(), {code: countryCode});
    }
  });
});
