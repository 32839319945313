/**
 * Created by BFR on 24/02/2020.
 */
define('editVariationView',[
  'module',
  'marionette',
  'app',
  'settings',
  'underscore',
  'template!editVariationTpl',
  'editOptionsView',
  'popupVariationView',
  'bootbox'
], function (
  module,
  Marionette,
  App,
  Settings,
  _,
  viewTpl,
  EditOptionsView,
  PopupVariationView,
  Bootbox
) {
  'use strict';

  var EditVariationView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'var-item',

    modelEvents: {
      sync: 'render'
    },

    events: {
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut',
      'click .btn-delete-var': 'onDeleteVariationClick',
      'click .btn-edit-var': 'onEditVariationClick'
    },

    ui: {
      btnContainer: '.btn-variation-container'
    },

    regions: {    
      editOptions: '.options-region'
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    /*onShow: function () {
      
      this.editOptionsView = new EditOptionsView(
        {
          collection: this.model.get('productOptions'),
          editable: false
        });
      this.getRegion('editOptions').show(this.editOptionsView);
      
    },*/

    onRender: function () {
      this.editOptionsView = new EditOptionsView(
        {
          collection: this.model.get('productOptions'),
          editable: false
        });
      this.getRegion('editOptions').show(this.editOptionsView);
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      templateData.name = '';
      
      if(this.model.get('name') !== null)
        templateData.name = this.model.get('name').getTextInLocale(Settings.get('lang'));
        
      return templateData;
    },

    onDeleteVariationClick: function () {
      Bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.destroy();
        }
      }, this));
    },

    onEditVariationClick: function () {    

      var popupVariationView = new PopupVariationView({
        model: this.model
      });
    
      popupVariationView.newItem = false;

      popupVariationView.show({
        title: _.i18n('common.edit')
      });
    }
  });

  module.exports = EditVariationView;
});
