define('ordersDetailOrderStatusStatusesView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'orderUtils',
  'ordersController',
  'ordersDetailOrderStatusStatusView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  OrderUtils,
  Controller,
  OrdersDetailOrderStatusStatusView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    tagName: 'select',
    className: 'orders-detail-order-status-status-view',
    childView: OrdersDetailOrderStatusStatusView,  
    
    ui: {
      'empty': '.empty'
    },

    events: {
      'change': 'onSelectChange'
    },

    modelEvents: {
      'change:commandType': 'setCollection',
      'change:status': 'setSelected'
    },

    initialize: function () {     
      this.collection = new Backbone.Collection();      
    },   

    onShow: function () {
      this.setCollection();
    },

    onRender: function () {
      this.setSelected();
    },

    setCollection: function () {
      OrderUtils.getAvailableStatuses(this.model.get('commandType')).done(_.bind(function (statuses) {
        this.collection.reset();
        this.collection.add(statuses);
        this.setSelected();
      }, this));      
    },

    setSelected: function () {
      _.each(this.children._views, _.bind(function (view) {
        if(view.model.get('statusConst') === this.model.get('status'))
          view.setSelected(true);
        else
          view.setSelected(false);
      }, this));
    },

    onSelectChange: function (e) {   
      this.model.updateStatus(e.target.value)
      .done(_.bind(function () {
        this.model.fetch();

        try {
          App.request('orders:statuses', this.model.get('commandType')).done(_.bind(function (statuses) {
            var status = null;
            _.each(statuses.models, _.bind(function (item) {
              if(item.get('statusConst') === e.target.value)
                status = item;
            }, this));

            if(status.get('params')) {
              var params = JSON.parse(status.get('params'));
              Controller.sendMail([this.model], params);
            }
          }, this));
        } catch (e) {
          console.log(e);
        }
      }, this))
      .fail(_.bind(function () {
        this.render();
      }, this));
    }
  });
});
