define('filtersBannerFiltersView',[
  'module',
  'backbone',
  'marionette',
  'filtersBannerFilterView'
], function(
  Module,
  Backbone,
  Marionette,
  FilterView
) {
  'use strict';
  
  Module.exports = Marionette.CollectionView.extend({
    className: 'filters-banner-filters-view',
    childView: FilterView,

    childEvents: {
      close: 'onCloseButtonClick'
    },

    onCloseButtonClick: function(childView, model) {
      this.collection.remove(model);
      this.collection.reset();
      this.trigger('close', model);
    }
  });  
});

