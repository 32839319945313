
define('template!webshopProductsFinderListFiltersEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n    <div class="line">\n        <div class="code item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.productCode') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productCode" tabindex="1">\n        </div>\n        <div class="name item">\n            <div class="libelle">' +
((__t = ( _.i18n('orderList.productName') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productName" tabindex="2">\n        </div>\n    </div>\n    <div class="line">\n        <div class="supplier-code item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.supplierCode') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productCode" tabindex="1">\n        </div>\n        <div class="supplier-name item">\n            <div class="libelle">' +
((__t = ( _.i18n('common.supplierName') )) == null ? '' : __t) +
'</div>\n            <input type="text" name="productName" tabindex="2">\n        </div>\n    </div>\n</div>\n<div class="footer">\n    <div class="cancel-region"></div>\n    <div class="reset-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

