
define('template!webshopCategoriesDetailBodyCategoryView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="left">\n    <div class="category-label item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.label') )) == null ? '' : __t) +
'</div>\n        <input type="text" class="value">\n    </div>\n    <div class="category-code item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <input type="text" class="value">\n    </div>\n    <div class="status item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n        <div class="status-region value"></div>\n    </div>\n    ';
 if(showPageLayout) { ;
__p += '\n       <div class="layout item">\n          <div class="libelle">' +
((__t = ( _.i18n('webshop.category.layout') )) == null ? '' : __t) +
'</div>\n          <div class="layout-region value"></div>\n       </div>\n       <div class="products-layout item">\n          <div class="libelle">' +
((__t = ( _.i18n('webshop.category-products.layout') )) == null ? '' : __t) +
'</div>\n          <div class="products-layout-region value"></div>\n       </div>\n    ';
 } ;
__p += '\n    <div class="default-sort item">\n       <div class="libelle">' +
((__t = ( _.i18n('webshop.common.default-sorts') )) == null ? '' : __t) +
'</div>\n       <div class="default-sort-region value"></div>\n    </div>\n</div>\n<div class="right">\n  ';
 if(showNoindex) { ;
__p += '\n    <div class="indexes-region"></div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

