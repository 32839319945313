
define('template!ordersConfirmationOrderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(companyName !== '') { ;
__p += '\n  <div class="company-name">' +
((__t = ( companyName )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n<div class="infos">' +
((__t = ( infos )) == null ? '' : __t) +
'</div>';

}
return __p
};});

