/**
 * Created by BFR on 05/03/2020.
 */
define('filtersView',['module',
        'marionette',
        'filterView'],
  function(module,
           Marionette,
           FilterView){
  'use strict';

  var FiltersView = Marionette.CollectionView.extend({
    childView: FilterView,
    className: 'col-md-12 p-t-10',

    childEvents: {
      'item:selection:change': function () {
        this.trigger('item:selection:change');
      }
    }
  });

  module.exports = FiltersView;
});
