
define('template!pricePopoverTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="panel panel-default shadow-0 m-b-0">\n  <div class="panel-body row p-b-0">\n    <div class="row">\n      <div class="form-group col-md-12">\n          <select role="select" name="type" id="gs_type" clearsearch="true" size="1" placeholder="' +
((__t = ( _.i18n('price.priceMin') )) == null ? '' : __t) +
'" class="ui-widget-content ui-corner-all js-quantity">\n              <option value="1">1</option>\n              <option value="50">50</option>\n              <option value="100">100</option>\n              <option value="250">250</option>\n              <option value="500">500</option>\n              <option value="1000">1000</option>\n              <option value="2500">2500</option>\n          </select>\n      </div>\n    </div>\n    <div class="row">\n      <div class="form-group col-md-12">\n        <input type="text" class="js-price-min form-control floating-label" placeholder="' +
((__t = ( _.i18n('price.priceMin') )) == null ? '' : __t) +
'"/>\n      </div>\n    </div>\n    <div class="row">\n      <div class="form-group col-md-12">\n        <input type="text" class="js-price-max form-control floating-label" placeholder="' +
((__t = ( _.i18n('price.priceMax') )) == null ? '' : __t) +
'"/>\n      </div>\n    </div>\n    <div class="row">\n      <button class="btn btn-primary btn-xs f-r m-r-10 js-apply">' +
((__t = ( _.i18n('common.apply') )) == null ? '' : __t) +
'</button>\n    </div>\n  </div>\n</div>';

}
return __p
};});

