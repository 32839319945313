define('userSelectorSelectedUsersView',[
  'module',
  'marionette',
  'backbone',
  'userSelectorUserView'
], function (
  Module, 
  Marionette,
  Backbone,
  UserView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'user-selector-selected-users-view',
    childView: UserView,

    childViewOptions: function () {
      return {
        showDeleteIcon: true,
        showName: true
      };
    },

    childEvents: {
      'onSelect': function (view) {
        this.trigger('onUnAssign', view.model);
      }
    },

    initialize: function () {
      this.collection = new Backbone.Collection(this.options.users); 
    }
  });
});
