
define('template!mailsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="menu-region"></div>\n<div class="list-region"></div>\n<div class="mail-region"></div>';

}
return __p
};});

