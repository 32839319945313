/**
 * Created by BFR on 18/02/2020.
 */
define('productTranslationsTabController',[
  'module',
  'underscore',
  'app',
  'productTranslationsTabView',
  'entities/product',
  'entities/product_translation',
  'entities/product_share'
], function(
  module,
  _,
  App,
  View
) {

    'use strict';
  var ProductTranslationsController = {
      
    showDetails: function (region, pm) {
        var product = App.request('products:model', {secId: pm.get('secId')});
        this.model = product;

        var productTranslation = App.request('products-translation:model', { secId: pm.get('secId') });
        productTranslation.fetch().done(_.bind(function() {
          this.view = new View({ model: productTranslation });
          region.show(this.view);
        }, this));

        var productShare = App.request('products-share:model', { secId: pm.get('secId') });
        productShare.fetch().done(_.bind(function() {
        }, this));
    }
  };

  module.exports = ProductTranslationsController;
});
