
define('template!caccountConfigTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row col-lg-12">\n  <div class="col-xs-12 sa-list">\n    <div class="col-xs-12 col-md-7 col-lg-5 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">Card Colors</div>\n      </div>\n      <div class="cards-colors-region">\n\n      </div>\n      <div style="display:flex; flex-direction:column; row-gap: 3px; margin-top:10px">\n        <div style="font-size: 12px; font-weight: 600; text-decoration: underline">New color</div>\n        <div style="display: flex; align-items:center; column-gap: 10px">\n          <input type="text" class="color-rgb" placeholder="Color RGB"/>\n          <input type="text" class="color-name" placeholder="Color name"/>\n          <svg class="add-button" style="height:15px; cursor:pointer;"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>\n        </div>\n        \n      </div>\n      <button class="btn btn-primary btn-save-colors-names">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n    </div>\n  </div>\n  <div class="col-xs-12 sa-list">\n    <div class="col-xs-12 col-md-7 col-lg-5 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">Gmail</div>\n      </div>\n      <div class="gmail-region">\n\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

