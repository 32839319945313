define('mailsListSearchView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'template!mailsListSearchView'
], function (
  App,
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'search-view',

    ui: {
      input: 'input',
      search: '.search-button'
    },

    events: {
      'keydown @ui.input': 'onKeydown',
      'blur @ui.input': 'onSearch',
      'click @ui.search': 'onSearch'
    },

    onKeydown: function(e) {
      if(e.keyCode === 13) {
        this.onSearch();
      }
    },

    onSearch: function() {
      var value = this.ui.input.val();
      this.trigger('search', value);
    }
  });
});

