/**
 * Created by GSP on 4/11/2015.
 */
define('collectionItemViewGoogleContacts',['module',
        'marionette',
        'jquery',
        'template!googleContactPopupTpl'],
    function(module,
             Marionette,
             $,
             GoogleContactPopupTpl){

        'use strict';

        var CollectionItemViewGoogleContacts = Marionette.ItemView.extend({
            tagName: 'div',
            template: GoogleContactPopupTpl,

            ui: {
                sendEmail: '.send-email'
            },

            events: {
                'change @ui.sendEmail': 'onCheckBoxChanged'
            },

            onCheckBoxChanged: function(e) {
                var $input = $(e.currentTarget);
                this.model.set('selected', $input.is(':checked'));
            },

            serializeData: function() {
                if (!this.model.has('selected')) {
                    this.model.set('selected', false);
                }
                var templateData = this.model.toJSON();
                return templateData;
            }
        });

        module.exports = CollectionItemViewGoogleContacts;
    });
