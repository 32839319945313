define('ordersListView',[
  'module',
  'underscore',
  'app',
  'notificationCenter',
  'tableView',
  'ordersController',
  'tableDatetimeFieldView',
  'ordersListFiltersView',
  'ordersListImageView',
  'ordersListInfosView',
  'ordersListClientView',
  'ordersListProductView',
  'ordersListStatusView',
  'ordersListLabelsView',
  'ordersListUsersView',
  'ordersListToolsView',
  'ordersListHeaderToolsView',
  'entities/orders'
], function (
    Module,
    _,
    App,
    NotificationCenter,
    TableView,
    Controller,
    DatetimeFieldView,
    FiltersView,
    ImageView,
    InfosView,
    ClientView,
    ProductView,
    StatusView,
    LabelsView,
    UsersView,
    ToolsView,
    HeaderToolsView,
    Orders
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'orders-list-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,
    lastSelected: null,
    selectable: true,

    contextMenu: function() {
      var orders = [];
      _.each(this.getSelected(), function (view) {
        orders.push(view.model);
      }, this);

      return Controller.contextMenu(this.options.type, orders, this.lastSelected.model, _.bind(function () {
        this.unSelectAll();
      }, this));
    },

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });
      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'createDate',
        className: 'date',
        view: DatetimeFieldView
      });
      fields.push({
        name: 'Infos',
        className: 'infos',
        label: this.options.type === 'offer' ? _.i18n('orders.list.offer-infos.title') : _.i18n('orders.list.order-infos.title'),
        view: InfosView
      });

      if(!this.options.endUserId) {
        fields.push({
          name: 'Client',
          className: 'client',
          label: _.i18n('orders.list.client.title'),
          view: ClientView
        });
      }

      fields.push({
        name: 'Product',
        className: 'product',
        label: _.i18n('orders.list.product.title'),
        view: ProductView
      });
      fields.push({
        name: 'Status',
        className: 'status',
        label: _.i18n('orders.list.status.title'),
        statuses: _.bind(function () {
          return this.statuses;
        }, this),
        view: StatusView
      });
      fields.push({
        name: 'Labels',
        className: 'labels',
        view: LabelsView,
        showHeader: false
      });
      fields.push({
        name: 'Tools',
        className: 'tools',
        view: ToolsView,
        showHeader: false
      });

      fields.push({
        name: 'Users',
        className: 'users',
        view: UsersView,
        showHeader: false
      });
      fields.push({
        name: 'headerTools',
        className: 'header-tools',
        headerView: HeaderToolsView,
        showBody: false
      });

      return fields;
    },

    rowAttributes: function(view) {
      return {
        'data-id': view.model.get('purchaseOrderId'),
        'data-priority': view.model.get('priority')
      };
    },

    rowModelEvents: function(view) {
      return {
        'change:priority': _.bind(function() {
            view.$el.attr('data-priority', view.model.get('priority'));
          }, this),
        'change:commandType': _.bind(function() {
          if(view.model.get('commandType') !== this.options.type) {
            this.bodyView.collection.remove(view.model);
            this.footerView.fetch();
          }
        }, this),
        'deleted': _.bind(function() {
          this.bodyView.collection.remove(view.model);
          this.footerView.fetch();
        }, this)
      };
    },

    getStatuses: function() {
      var defer = $.Deferred();
      App.request('orders:statuses', this.options.type).done(_.bind(function (statuses) {
        this.statuses = statuses;
        defer.resolve(statuses);
      }, this));
      return defer.promise();
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params.sord = 'DESC';
      params.sidx = 'date';

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('order:get-orders', params),
        success: _.bind(function (items) {
          var models = [];
          _.each(items, _.bind(function(item) {
            var model = App.request('order:model', App.request('order:parse-list-data', item));
            models.push(model);
          }, this));
          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('order:get-orders-count', this.getFiltersParams(filters)),
        success: _.bind(function (counts) {
          defer.resolve(counts.totalCount);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      params.includeArchived = true;

      if(this.options.type === 'offer')
        params.types = 'offer';

      _.each(filters.attributes, function (value, key) {
        if(key !== 'quantityFrom' &&
            key !== 'quantityTo' &&
            key !== 'priceFrom' &&
            key !== 'priceTo' &&
            key !== 'dateFrom' &&
            key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('quantityFrom') !== '' || filters.get('quantityTo') !== '') {
        var quantityFrom = filters.get('quantityFrom');
        var quantityTo = filters.get('quantityTo');

        if(quantityFrom === '')
          quantityFrom = 0;
        if(quantityTo === '')
          quantityTo = 999999999999;

        params.quantity = quantityFrom + '-' + quantityTo;
      }

      if(filters.get('priceFrom') !== '' || filters.get('priceTo') !== '') {
        var priceFrom = filters.get('priceFrom');
        var priceTo = filters.get('priceTo');

        if(priceFrom === '')
          priceFrom = 0;
        if(priceTo === '')
          priceTo = 999999999999;

        params.price = priceFrom + '-' + priceTo;
      }

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    },

    onRowDoubleClick: function(view) {
      Orders.showOrder(view.model.get('purchaseOrderId'), 'details');
    }
  });
});
