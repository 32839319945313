define('graphicalRequestKanbanRouter',[
    'require',
    'exports',
    'module',
    'settings',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Settings,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin
) {
  'use strict';

  var GraphicalRequestKanbanRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'graphical_service_kanban': 'showKanbanBoard',
      'graphical_service_kanban/:secId/:tab': 'showKanbanBoardMessageTab',
      'graphical_service_kanban/:secId/:tab/fromAllInOne': 'showKanbanBoardMessageTab'
    }
  });

  var API = {
    showKanbanBoard: function() {
      require([
        'graphicalRequestKanbanShowController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('graphicalRequestKanbanMain');
          ShowController.showKanbanBoard();
          App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICAL_SERVICE_KANBAN);
        });
      });
    },

    showKanbanBoardMessageTab: function(secId, tab) {
      require([
        'messagesShowController',
        'contentShowController'
      ], function(MessagesShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          MessagesShowController.showMessages(secId, tab);
          App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICAL_SERVICE_KANBAN);
        });
      });
    }
  };

  App.on('graphical_service_kanban:show:details', function(secId, fromAllInOne) {
    if (secId) {
      if (Settings.get('lastMsgTab') === 'messages') {
        App.navigate('graphical_service_kanban/' + secId + '/messages' + (fromAllInOne?'/fromAllInOne':''), { trigger: true });
      } else {
        App.navigate('graphical_service_kanban/' + secId + '/messages-cs' + (fromAllInOne?'/fromAllInOne':''), { trigger: true });
      }
    } else {
      App.navigate('graphical_service_kanban', { trigger: true });
    }
  });

  App.on('graphical_service_kanban:show', function() {
    App.navigate('graphical_service_kanban');
    API.showKanbanBoard();
  });

  App.on('before:start', function() {
    new GraphicalRequestKanbanRouter({
      controller: API
    });
  });


  module.exports = GraphicalRequestKanbanRouter;
});
