
define('template!ordersDetailOrderHistoryView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="title">' +
((__t = ( _.i18n('common.history') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n    <div class="history-region"></div>\n</div>';

}
return __p
};});

