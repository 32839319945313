
define('template!shareProductCodeVariationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-0">\n        <div class="col-xs-8" ' +
((__t = ( newPcv?'style="color: orange;font-weight: bold;"':'' )) == null ? '' : __t) +
' >\n            ';
 if (parentProductCodeVariation) { ;
__p += '\n            <div class="col-xs-1 m-t--7 m-b--5">\n                <input class="cbx-productcodevariation m-t-0" ' +
((__t = ( enabled?'':'disabled' )) == null ? '' : __t) +
' type="checkbox" style="width: 20px; height: 35px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n            </div>\n            ';
 } ;
__p += '\n            <div class="col-xs-2">' +
((__t = ( newPcv?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = (
                productCodeVariation?productCodeVariation.code:parentProductCodeVariation.code )) == null ? '' : __t) +
'\n            </div>\n            ';
 _.each(productOptions, function(productOption) { ;
__p += '\n                <div class="col-xs-4">\n                    <label class="col-xs-3">' +
((__t = ( productOption.code )) == null ? '' : __t) +
'</label>\n                    ';
 if (productOption.color) { ;
__p += '\n                        <div class="col-xs-3" style="width: 20px; height: 20px; background-color: ' +
((__t = ( productOption.color )) == null ? '' : __t) +
'"></div>\n                    ';
 } ;
__p += '\n                    <label class="p-l-15">' +
((__t = ( productOption.name )) == null ? '' : __t) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </div>\n    </div>\n    <hr class="col-xs-12 m-t-0 m-b-0"/>\n</div>';

}
return __p
};});

