/**
 * Created by BFR on 17/06/2020.
 */
define('pimProductPriceScaleView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!pimProductPriceScaleTpl',
  'orderUtils'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  OrderUtils
) {
  'use strict';

  var PimProductPriceScaleView = Marionette.ItemView.extend({
    template: viewTpl,
    className: 'row',

    ui: {
      lblPrice: '.lbl-sprice',
      txtPrice: '.txt-sprice',
      lblQuantity: '.lbl-quantity',
      txtQuantity: '.txt-quantity'
    },

    events: {
      'click @ui.lblPrice': 'onLblPriceClick',
      'change @ui.txtPrice': 'onTxtPriceChange',
      'focusout @ui.txtPrice': 'onTxtPriceChange',
      'keydown @ui.txtPrice': 'onTxtPriceKeyPress',
      'click @ui.lblQuantity': 'onLblQuantityClick',
      'change @ui.txtQuantity': 'onTxtQuantityChange',
      'focusout @ui.txtQuantity': 'onTxtQuantityChange',
      'keydown @ui.txtQuantity': 'onTxtQuantityKeyPress',
      'click .btn-remove-scale': 'onRemoveScaleClick'
    },

    onRemoveScaleClick: function () {
      this.model.collection.remove(this.model);
    },

    onTextPriceKeyDown: function (e) {
      $(e.target.nextSibling).focus();
    },

    onTxtPriceKeyPress: function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous');
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next');
        }
      }
    },

    onTxtQuantityKeyPress: function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous-qty');
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next-qty');
        }
      }
    },

    onTxtPriceChange: function () {
      this.ui.lblPrice.show();
      this.ui.txtPrice.hide();
      var newPrice = Number(this.ui.txtPrice.val().replace(',', '.'));
      var newFactor = newPrice / (Number(this.options.model.get('productUnitPrice')) > 0 ?
        Number(this.options.model.get('productUnitPrice')) : this.options.model.get('factorModel').price);
      this.options.model.get('factorModel').factor = newFactor.toFixed(5);
      this.render();
    },

    onLblPriceClick: function () {
      this.ui.lblPrice.hide();
      this.ui.txtPrice.show();
      this.ui.txtPrice.focus();
      this.ui.txtPrice.select();
    },

    onTxtQuantityChange: function () {
      this.ui.lblQuantity.show();
      this.ui.txtQuantity.hide();
      this.options.model.get('factorModel').quantity = Number(this.ui.txtQuantity.val());
      this.render();
    },

    onLblQuantityClick: function () {
      this.ui.lblQuantity.hide();
      this.ui.txtQuantity.show();
      this.ui.txtQuantity.focus();
      this.ui.txtQuantity.select();
    },

    onShow: function () {
    },

    serializeData: function () {
      var isCustomPrice = this.options.customPrice;
      var templateData = this.options.model.toJSON(),
        effectivePrice = Number(isCustomPrice ? templateData.factorModel.price :
          templateData.productUnitPrice);

      var productFactor = templateData.factorModel.factor;
      templateData.useFactorV2 = this.options.model.collection.useFactorV2;

      if (templateData.useFactorV2) {
        productFactor = OrderUtils.calculateFactorV2(this.options.model.collection.factorsV2,
          this.options.model.collection.models, this.model.get('quantity'));
      }

      templateData.price = Number(effectivePrice * templateData.previousFactor).toFixed(2);
      templateData.sPrice = Number(effectivePrice * productFactor).toFixed(2);
      templateData.print = {};
      templateData.print.unit = '0,00';
      templateData.print.setup = '0,00';
      templateData.total = effectivePrice * templateData.factorModel.quantity;
      var sTotal = Number(effectivePrice *
        templateData.factorModel.quantity * productFactor);

      if (!templateData.smallOrderFee) {
        templateData.smallOrderFee = '0.00';
      } else {
        templateData.smallOrderFee = Number(templateData.smallOrderFee).toFixed(2);
      }

      if (templateData.impt && templateData.impt.length > 0) {
        var imp = templateData.impt[0];
        var foundPz;
        var supplierPrintFactor = {
          factor: 1.3,
          factorSetup: 1.3,
          factorHandling: 1.3
        };
        _.each(this.options.model.collection.pzs, function (pz) {
          if (pz.pz && pz.pz.pimId === imp.secId) {
            foundPz = pz.pz;
          }
        });
        if (foundPz && this.options.model.collection.pimPrintFactors) {
          _.each(this.options.model.collection.pimPrintFactors.models, function (factor) {
            if ((!foundPz.factorCode && factor.get('defaultPrice')) || factor.get('printCode') === foundPz.factorCode) {
              supplierPrintFactor = factor.toJSON();
            }
          });
        }
        var setupFactor =
          this.options.model.collection.printZonePrices.findByPzIdAndQuantity(imp.secId, -1) ||
          supplierPrintFactor.factorSetup;
        var imptUnitUnitPriceFactor =
          this.options.model.collection.printZonePrices.findByPzIdAndQuantity(imp.secId, templateData.factorModel.quantity) ||
          supplierPrintFactor.factor;
        var handlingFactor =
          this.options.model.collection.printZonePrices.findByPzIdAndQuantity(imp.secId, -2) ||
          supplierPrintFactor.factorHandling;

        if (templateData.useFactorV2) {
          templateData.factorModel.factor = setupFactor = imptUnitUnitPriceFactor = handlingFactor = productFactor;
        }

        if (isCustomPrice) {
          var pzPrice = _.findWhere(this.options.model.collection.printZonePrices.attributes[imp.secId],
            {quantity: templateData.factorModel.quantity});
          templateData.print.unit = Number(Number((pzPrice && pzPrice.price) ? pzPrice.price : 0) * imptUnitUnitPriceFactor).toFixed(2);
          templateData.print.setup = Number(Number(imp.imptSetupPrice) * setupFactor).toFixed(2);
          templateData.print.handling = 0;
        } else {
          templateData.print.unit = Number(Number(imp.imptUnitUnitPrice) * imptUnitUnitPriceFactor).toFixed(2);
          templateData.print.setup = Number(Number(imp.imptSetupPrice) * setupFactor).toFixed(2);
          templateData.print.handling = Number(Number(imp.imptHandlingUnitPrice || 0) * handlingFactor).toFixed(2);
        }

        templateData.total += Number(imp.imptUnitUnitPrice) * templateData.factorModel.quantity;
        templateData.total += Number(imp.imptSetupPrice);
        templateData.total += Number(imp.imptHandlingPrice || 0);
        templateData.total += Number(templateData.smallOrderFee || 0);
        templateData.total += Number(templateData.shippingCost || 0);

        sTotal += Number(Number(imp.imptUnitUnitPrice) * templateData.factorModel.quantity * imptUnitUnitPriceFactor);
        sTotal += Number(Number(imp.imptSetupPrice) * setupFactor);
        sTotal += Number(Number(imp.imptHandlingPrice || 0) * handlingFactor);
      }
      if (templateData.useFactorV2) {
        sTotal += Number(templateData.smallOrderFee ? Number(templateData.smallOrderFee) * productFactor : 0);
        sTotal += Number(templateData.shippingCost ? Number(templateData.shippingCost) * productFactor : 0);
      } else {
        sTotal += Number(templateData.smallOrderFee || 0);
        sTotal += Number(templateData.shippingCost || 0);
      }

      templateData.totalUnit = (sTotal / templateData.factorModel.quantity).toFixed(2);

      templateData.sTotal = (Number(templateData.totalUnit) * templateData.factorModel.quantity).toFixed(2);
      templateData.percentMargin = Number(((sTotal - templateData.total) / sTotal) * 100).toFixed(2);
      templateData.margin = Number(templateData.sTotal - templateData.total).toFixed(2);

      if (templateData.useFactorV2) {
        templateData.shippingCost = (Number(templateData.shippingCost) * productFactor).toFixed(2);
      } else {
        templateData.shippingCost = Number(templateData.shippingCost).toFixed(2);
      }

      templateData.total = templateData.total.toFixed(2);
      return templateData;
    }
  });

  module.exports = PimProductPriceScaleView;
});
