/**
 * Created by ASK on 21/06/2022.
 */
define('editVariationsView',['module',
    'marionette',
    'editVariationView'],
  function (module,
            Marionette,
            EditVariationView) {
    'use strict';

    var EditVariationsView = Marionette.CollectionView.extend({
      childView: EditVariationView,
      className: 'variation-list'     
    });

    module.exports = EditVariationsView;
  });
