/**
 * Created by BFR on 24/06/2020.
 */
define('customQuantityView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!customQuantityTpl'
], function (
    module,
    Marionette,
    App,
    _,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: viewTpl,

        ui: {
            txtQuantity: '.txt-quantity'
        },

        events: {
            'change @ui.txtQuantity': 'onTxtFactorChange',
            'click .btn-remove': 'onBtnRemoveClick',
            'keydown @ui.txtQuantity': 'onTxtQuantityKeyPress'
        },

        onTxtQuantityKeyPress:function(e) {
            var charCode = (e.which) ? e.which : e.keyCode;

            if (charCode === 13) {
                e.preventDefault();
                this.model.collection.add({quantity: 0});
            }
        },

        onRender: function() {
          setTimeout(_.bind(this.setFocus, this), 1);
        },

        setFocus: function() {
            this.ui.txtQuantity.focus();
            this.ui.txtQuantity.select();
        },

        onBtnRemoveClick: function () {
            this.model.collection.remove(this.model);
        },

        onTxtFactorChange: function () {
            this.model.set('quantity', Number(this.ui.txtQuantity.val()));
        },

        serializeData: function () {
            var templateData = this.options.model.toJSON();
            templateData.idx = this.options.model.collection.indexOf(this.model) + 1;
            return templateData;
        }
    });
});
