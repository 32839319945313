/**
 * Created by OLD on 20/11/2015.
 */
define('fileConversionPopupView',[
  'module',
  'jquery',
  'underscore',
  'settings',
  'photoswipe',
  'photoswipeUi',
  'template!fileConversionPopupTpl',
  'template!fileConversionWarningTpl',
  'dialogFormView',
  'autocompleteView',
  'fColdBrowserFormView',
  'dropdownCompositeView',
  'bootbox',
  'imageUtils',
  'dropzone'
], function(
  module,
  $,
  _,
  Settings,
  PhotoSwipe,
  PhotoSwipeUI,
  viewTpl,
  viewWarningTpl,
  DialogFormView,
  AutocompleteView,
  OldBrowserFormView,
  DropdownCompositeView,
  bootbox,
  ImageUtils,
  DropZone
) {
  'use strict';

  var FileConversionPopupView = DialogFormView.extend({
    template: viewTpl,
    warningTemplate: viewWarningTpl,

    triggers: {
      'click @ui.imgNew' : 'gallery:init',
      'click @ui.fillUp' : 'onNavigateToCredit'
    },

    ui: {
      dropZone: 'div.upload-container-drag-drop',      
      buttonSendTo: '.send-to-d-b',
      dropArea: '.drag-and-drop-area',
      uploadBtn: '.dd-b-upload',
      uploadIcon: '.dd-icon',
      preview: '.preview-container',
      previewImg: '#previewImg',
      ticketNameBlock: '.ticket-name-new',
      ticketName: '.ticket-name-new input',
      ticketMessage: 'textArea.textarea-input',
      ticketUrgent: '.js-ticket-urgent',
      fillUp: '.fill-up-b',
      continueBtn: '.continue-b',
      creditArea: '.check-credit-container',
      uploadArea: '.upload-container',
      ticketUrgency: 'input[type=checkbox]',
      ticketTariffScaleRadio: 'form.tariff-scale-radio-group',
      uploadProgress: '.progress-striped'
    },

    regions: {
      dropdownOutputFileRegion: '.output-file-dropdown-region'
    },

    events: {      
      'click @ui.buttonSendTo'    : 'onSendPostRequest',
      'click @ui.continueBtn'     : 'onShowUploadContent',
      'change @ui.ticketUrgency'  : 'onChangeUrgency',
      'cut @ui.ticketMessage'     : 'onChangeTextArea',
      'paste @ui.ticketMessage'   : 'onChangeTextArea',
      'drop @ui.ticketMessage'    : 'onChangeTextArea',
      'keydown @ui.ticketMessage' : 'onChangeTextArea',
      'click @ui.autocomplete'    : 'onClearValidation',
      'keypress @ui.autocomplete' : 'onClearValidation'
    },

    serializeData: function() {
      var templateData = {};

      templateData.balance = this.options.balance;
      templateData.subject = Settings.get('currentCAccount').name;
      templateData.needHelpEmail = Settings.get('supportEmail');
      return templateData;
    },

    onChangeTextArea: function() {
      var that = this;

      setTimeout(function () {
        that.ui.ticketMessage.height('auto');
        that.ui.ticketMessage.height(that.ui.ticketMessage[0].scrollHeight + 'px');
      }, 0);
    },

    getTemplate: function() {
      if (this.options.balance <= 0) {
        return this.warningTemplate;
      } else if (this.options.balance < Settings.get('minBalance')) {
        this.balance = this.options.balance;
        return this.template;
      } else {
        return this.template;
      }
    },

    onRender: function() {
      var that = this,
        timerCount = 0,
        timer;

      if(this.options.balance > 0) {
        if(!this._browserCompatible()) {
          new OldBrowserFormView({el: this.ui.uploadArea}).render();
          return;
        }

        this.ui.dropZone.dropzone({
          url: Settings.url('compuzz'),
          autoProcessQueue: false,
          uploadMultiple: false,
          maxFiles: 1,
          init: function () {
            this.on('addedfile', function(file) {
              that.ui.uploadArea.hide();
              that.ui.uploadProgress.removeClass('hidden').show();
              timer = setInterval(function() {
                if(timerCount === 100) {
                  timerCount = 0;
                } else {
                  timerCount += 10;
                }
                that.ui.uploadProgress.children().first().css('width', timerCount + '%');
              }, 100);
              ImageUtils.encodeFileToBase64(
                file, function(base64) {
                  that.ui.previewImg.on('load', function() {
                    clearInterval(timer);
                    DropZone.forElement(that.ui.dropZone.selector ).destroy();
                    that.ui.uploadProgress.addClass('hidden');
                    that.imgOriginalBase64 = base64;
                    that.fileName = file.name;
                    that.extension = file.type.split('/')[1];
                    that.ui.preview.removeClass('hidden' ).show();
                  }).on('error', function() {
                    clearInterval(timer);
                    DropZone.forElement(that.ui.dropZone.selector ).destroy();
                    that.ui.uploadProgress.addClass('hidden');
                    that.imgOriginalBase64 = base64;
                    that.fileName = file.name;
                    that.extension = file.type.split('/')[1];
                    that.ui.previewImg.off('load');
                    that.createPreviewFromName();
                    that.ui.preview.removeClass('hidden' ).show();
                  }).attr('src', base64);
                });
            });
          },
          dictDefaultMessage: _.i18n('common.file.drag.drop')
        });

        this.getRegion('dropdownOutputFileRegion' ).show(new DropdownCompositeView(
          {
            modelProperty: 'extension',
            collection: this.options.outputFileCollection
          }));
      }
    },

    // when preview creation failed make preview from name
    createPreviewFromName: function() {
      var name = this.fileName,
        arrayFromName = name.split('.'),
        src,
        ext;

      if (arrayFromName.length > 1) {
        ext = arrayFromName[arrayFromName.length-1].substr(0,4);
      } else {
        ext = name.substr(0,3);
      }
      src = ImageUtils.getPreview(ext);

      this.ui.previewImg.attr('src', src);
    },

    onShowUploadContent: function() {
      this.ui.creditArea.hide();
      this.ui.uploadArea.show();
    },

    onChangeUrgency: function() {
      if(!this.ui.ticketUrgency.is(':checked')) {
        return;
      }
      bootbox.confirm(
        _.i18n('warning.updateTicketUrgency'),
        _.bind(function (result) {
          if (!result) {
            this.ui.ticketUrgency.prop('checked', false);
          }
        }, this)
      );
    },

    onSendPostRequest: function() {
      var ticketName = this.ui.ticketName.val();

      if (ticketName) {
        this.trigger('popup:view:close',
          ticketName,
          this.ui.ticketUrgency.is(':checked')
        );
      } else if (!ticketName) {
        this.ui.ticketNameBlock.addClass('has-error');
        this.ui.ticketName.focus();
      }
    },

    _browserCompatible: function() {
      var capableBrowser, regex, _i, _len, _ref;
      capableBrowser = true;
      if (window.File && window.FileReader && window.FileList && window.Blob && window.FormData && document.querySelector) {
        if (!('classList' in document.createElement('a'))) {
          capableBrowser = false;
        } else {
          _ref = [/opera.*Macintosh.*version\/12/i];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            regex = _ref[_i];
            if (regex.test(navigator.userAgent)) {
              capableBrowser = false;
              continue;
            }
          }
        }
      } else {
        capableBrowser = false;
      }
      return capableBrowser;
    }
  });

  module.exports = FileConversionPopupView;
});
