/**
 * Created by BFR on 20/09/2022.
 */
define('adminHtmlTemplatesView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!adminHtmlTemplatesTpl',
  'adminHtmlTemplatesCreateEditView',
  'entities/caccounts/htmltemplates'
], function (
  module,
  $,
  Marionette,
  _,
  Backbone,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'htmlTemplatesFilters',

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click .js-add-new': 'onAddNew'
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.subject'),
              name: 'subject',
              formatter: this.dynamicTextFormater,
              classes: 'code-link',
              search: false,
              index: 'subject',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('common.type'),
              name: 'templateType',
              formatter: this.templateType,
              classes: 'cell-link',
              search: false,
              index: 'templateType',
              sortable: false,
              width: 55
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEditItem(rowid);
            }
          }, this)
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onGridFilterChange: function (e) {
      this.onGridFilterChangeV2(e);
    },

    onEditItem: function (secId) {
      var m = App.request('htmltemplates:model', {secId: secId});
      m.fetch().done(_.bind(function () {
        var createEditView = new CreateEditLayoutView({
          tModel: m,
          newItem: false
        });
        createEditView.show({
          title: _.i18n('common.edit')
        });
        createEditView.listenTo(createEditView, 'saved', _.bind(function () {
          this.updatePagination(true);
        }, this));
      }, this));
    },

    onAddNew: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var createEditView = new CreateEditLayoutView({
        tModel: App.request('htmltemplates:model'),
        newItem: true
      });
      createEditView.show({
        title: _.i18n('common.new')
      });
      createEditView.listenTo(createEditView, 'saved', _.bind(function () {
        this.updatePagination(true);
      }, this));
    }
  });
});
