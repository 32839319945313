/**
 * Created by BFR on 18/02/2020.
 */
define('productOverviewTabController',[
  'module',
  'underscore',
  'app',
  'jquery',
  'productEditsTabView',
  'entities/product_rel'
], function (
  module,
  _,
  App,
  $,
  View
) {

  'use strict';
  var ProductEditsController = {

    showDetails: function (region, productModel) {
      var product = App.request('products-rel:model', {secId: productModel.get('secId')});
      this.model = productModel;
      this.region = region;

      var productFetch = product.fetch();

      $.when(productFetch).done(_.bind(function () {
        this.view = new View({
          model: product
        });
        this.view.listenTo(this.view, 'product-overview:reload', _.bind(this.onReload, this));
        region.show(this.view);
      }, this));
    },

    onReload: function () {
      this.showDetails(this.region, this.model);
    }
  };

  module.exports = ProductEditsController;
});
