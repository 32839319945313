define('customersDetailBodyProfileAddressesView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'marionette',
  'customersDetailBodyProfileAddressView',
  'template!customersDetailBodyProfileAddressesView'
], function (
  App,
  Module,
  _,
  Backbone,
  Marionette,
  AddressView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customers-detail-profile-addresses-view',

    regions: {
      invoiceRegion: '.invoice-region',
      deliveryRegion: '.delivery-region'
    },

    modelEvents: {
      'change:refDeliveryAddress': 'renderDeliveryAddress'
    },

    onShow: function () {
      this.invoiceView = new AddressView({
        model: this.model,
        countries: this.options.countries,
        title: _.i18n('endUsersDetail.invoiceAddress')
      });
      this.getRegion('invoiceRegion').show(this.invoiceView);

      //-------------------
      this.deliveryView = new AddressView({
        model: new Backbone.Model(this.model.get('refDeliveryAddress')),
        countries: this.options.countries,
        title: _.i18n('endUsersDetail.refDeliveryAddress')
      });
      this.getRegion('deliveryRegion').show(this.deliveryView);
    },

    renderDeliveryAddress: function () {
        this.deliveryView.model.set(this.model.get('refDeliveryAddress'));
    }
  });
});
