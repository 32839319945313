define('tableFieldTextEditEditView',[
    'module',
    'marionette',
    'underscore',
    'template!tableFieldTextEditEditView'
  ], function (
    Module,
    Marionette,
    _,
    Tpl
  ) {
    'use strict';
  
    Module.exports = Marionette.LayoutView.extend({
      template: Tpl,    
      className: 'table-field-text-edit-edit-view table-field-edit-view',
      tagName: 'textarea',
  
      initialize: function () {   
        this.field = this.options.field;
        this.view = this.options.view;
      },      

      onRender: function() {    
        this.renderContent();
      },
  
      renderContent: function() {
        try {
         this.$el.val(this.view.getValue());
        } catch(e) {
          console.log(e);
        }   
      },

      getValue: function() {
        return this.$el.val();
      }
    });
  });
    
