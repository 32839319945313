
define('template!ordersDetailOrderProductOptionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n<div class="value">' +
((__t = ( value )) == null ? '' : __t) +
'</div>';

}
return __p
};});

