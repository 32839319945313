
define('template!pimProductFilterItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-12">\n    <div class="sa">\n      <div class="sa-code">' +
((__t = ( value )) == null ? '' : __t) +
'</div>\n    </div>\n  </div>\n</div>\n<div class="row">\n  <div class="col-xs-12">\n    <div class="values">\n      <div class="values-region">\n      </div>\n    </div>\n  </div>\n</div>\n';
 if (displayShowMore) { ;
__p += '\n<span class="btn-show-more clickable">Show more</span>\n<span class="btn-show-less clickable" style="display: none;">Show less</span>\n';
 } ;


}
return __p
};});

