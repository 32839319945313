define('entities/phone/calls',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * REST API
   */
  var API = {
    getCallRecords: function (secId) {
      return $.ajax({
        url: Settings.url('compuzz2', 'v2/calls/' + secId + '/downloadRecord')
      });
    }
  };

  App.reqres.setHandler('calls:records', function (secId) {
    return API.getCallRecords(secId);
  });

  App.reqres.setHandler('calls:get-collection-url', function (params) {
    return Settings.url('compuzz2', 'v2/calls', params);
  });

  App.reqres.setHandler('calls:row-count-url', function (params) {
    return Settings.url('compuzz2', 'v2/calls/rowCount', params);
  });

  App.reqres.setHandler('calls:get-collection-url-phone-number', function (params) {
    return Settings.url('compuzz2', 'v2/calls/findByNumber', params);
  });

  App.reqres.setHandler('calls:row-count-url-phone-number', function (params) {
    return Settings.url('compuzz2', 'v2/calls/findByNumber/rowCount', params);
  });
});
