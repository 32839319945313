define('entities/languages',[
  'module',
  'app',
  'backbone',
  'settings',
  'underscore'
], function(
  module,
  App,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var languageSettingsKey = 'translationLanguages';

  var Language = Backbone.Model.extend({
    idAttribute: 'abbreviation'
  });

  var LanguageCollection = Backbone.Collection.extend({
    model: Language,

    comparator: function( collection ){
        return( collection.get('abbreviation'));
    },

    labels: [
      'label-primary',
      'label-success',
      'label-info',
      'label-warning',
      'label-danger',
      'label-violet-bg',
      'label-blue-bg',
      'label-yellow-bg',
      'label-purple-bg',
      'label-deep-purple-bg',
      'label-indigo-bg',
      'label-cyan-bg',
      'label-green-bg',
      'label-red-bg',
      'label-pink-bg',
      'label-brown-bg',
      'label-deep-orange-bg',
      'label-amber-bg',
      'label-orange-bg',
      'label-lime-bg',
      'label-light-green-bg',
      'label-blue-grey-bg',
      'label-grey-bg'
    ],

    getLanguages: function() {
      var that = this,
        defer = $.Deferred(),
        languages = [];
      if(Settings.get(languageSettingsKey)) {
        that.reset(Settings.get(languageSettingsKey));
        defer.resolve(that);
      } else {
        $.ajax({
          url: Settings.url('compuzz2', 'v2/languages'),
          success: function(response) {
            _.each(response.languages, function(language, index) {
              languages.push({
                abbreviation: language.code,
                name: language.name,
                label: that.labels[index] ? that.labels[index] : that.labels[that.labels.length - 1]
              });
            });
            Settings.set(languageSettingsKey, languages);
            that.reset(languages);

            defer.resolve(that);
          }
        });
      }

      return defer.promise();
    }
  });

  App.reqres.setHandler('language:model', function(languageJSON) {
    return new Language(languageJSON);
  });

  App.reqres.setHandler('language:collection', function(languagesJSON) {
    return new LanguageCollection(languagesJSON);
  });
});
