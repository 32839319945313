define('pimProductSelectedAttributesView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'pimProductSelectedAttributeView'
], function(
  module,           
  Marionette,
  App,
  _,
  PimProductSelectedAttributeView
){
  'use strict';

  var PimProductSelectedAttributesView = Marionette.CollectionView.extend({   
    childView: PimProductSelectedAttributeView,
    className: 'sa-list',
    childViewOptions: function() {      
      return {
        productAttributeModel: this.options.productAttributeModel
      };
    },
    
    onShow: function () {
    
    }
  });

  module.exports = PimProductSelectedAttributesView;
});
