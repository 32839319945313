define('ordersDetailOrderClientView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'rolesMixin',
  'ordersController',
  'ordersDetailOrderClientVatView',
  'ordersDetailOrderClientCommentsView',
  'ordersDetailOrderClientImagesView',
  'template!ordersDetailOrderClientView',
  'customersPopupAddressView',
  'entities/address',
  'entities/common'
], function (
  Module,
  App,
  Marionette,
  _,      
  Settings,
  DateUtils,
  RolesMixin,
  Controller,
  VatView,
  CommentsView,
  ImagesView,
  Tpl,
  AddressView
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-client-view',

    regions: {
      vatRegion: '.vat-region',
      commentsRegion: '.comments-region',
      imagesRegion: '.images-region'
    },
    
    ui: {
      editButton: '.btn-edit-client',
      copyCompanyNameButton: '.btn-copy-company-name',
      copyContactPersonButton: '.btn-copy-contact-person',
      copyMailButton: '.btn-copy-mail',
      copyInvoiceMailButton: '.btn-copy-invoice-mail',
      copyClientCode: '.btn-copy-client-code',
      copyPhoneButton: '.btn-copy-phone',   
      copyMobilePhoneButton: '.btn-copy-mobile-phone',      
      copyDeliveryAddressButton: '.btn-copy-delivery-address',
      copyInvoiceAddressButton: '.btn-copy-invoice-address',
      copyKvkButton: '.btn-copy-kvk',
      copySiretButton: '.siret .copy',
      toVatButton: '.siret .to-vat',
      companyName: '.company-name .value',
      contactPerson: '.contact-person .value',
      createDate: '.create-date .value',
      mail: '.mail .value',
      invoiceEmail: '.invoice-email .value',
      clientCode: '.client-code .value',
      phone: '.phone .value',
      mobilePhone: '.mobile-phone .value',
      vat: '.vat .value',
      kvkContainer: '.kvk',
      kvk: '.kvk .value',
      siretContainer: '.siret',
      siret: '.siret .value',
      countryCode: '.country .value',
      deliveryAddress: '.delivery-address .value',
      deliveryAddressFromClient: '.delivery-address .from-client',
      invoiceAddress: '.invoice-address .value',
      webShop: '.webshop .value',
      webShopItem: '.webshop',
      comment: '.comment',
      editDeliveryAddress: '.btn-edit-del-address',
      warning: '.warning'
    },
      
    events: {
      'click @ui.editButton': 'onEdit',
      'click @ui.copyCompanyNameButton': 'onCopyCompanyName',
      'click @ui.copyContactPersonButton': 'onCopyContactPerson',
      'click @ui.copyMailButton': 'onCopyMail',
      'click @ui.copyInvoiceMailButton': 'onCopyInvoiceMail',
      'click @ui.copyClientCode': 'onCopyClientCode',
      'click @ui.copyPhoneButton': 'onCopyPhone',
      'click @ui.copyMobilePhoneButton': 'onCopyMobilePhone',
      'click @ui.copyDeliveryAddressButton': 'onCopyDeliveryAddress',
      'click @ui.copyKvkButton': 'onCopyKvk',
      'click @ui.deliveryAddressFromClient': 'deliveryAddressFromClient',
      'click @ui.copyInvoiceAddressButton': 'onCopyInvoiceAddress',
      'click @ui.editDeliveryAddress': 'onEditDeliveryAddress',
      'click  @ui.copySiretButton': 'onCopySiret',
      'click @ui.toVatButton': 'onToVat',
      'click @ui.phone': 'onCallPhone',
      'click @ui.mobilePhone': 'onCallMobilePhone'
    },   

    modelEvents: {
      'change': 'renderContent'
    },

    onShow: function() {
      this.vatView = new VatView({
        model: this.options.customer
      });      
      this.getRegion('vatRegion').show(this.vatView);

      //-------------------

      this.commentsView = new CommentsView({
        model: this.model
      });      
      this.getRegion('commentsRegion').show(this.commentsView);

      //-------------------

      this.imagesView = new ImagesView({
        model: this.model,
        product: this.options.product
      });
      this.getRegion('imagesRegion').show(this.imagesView);
      this.listenTo(this.imagesView, 'update:complete', function (status,msg) {
        this.showWarning(status,msg);
      });

      //-------------------    

      this.listenTo(this.options.customer, 'change', _.bind(function() {
        this.renderContent();
      }, this));

    },

    showWarning: function (value,msg) {
      if (value) {
        this.ui.warning.show();
        this.ui.warning.text(msg);
      } else {
        this.ui.warning.hide();
      }
    },

    renderContent: function() {

      if (this.options.customer.get('name'))
        this.ui.companyName.html(this.options.customer.get('name'));
      else
        this.ui.companyName.html('-');

      //-------------------

      this.ui.createDate.html('-');

      if(this.options.customer.get('creatDate')) {
        var date = DateUtils.toMDYHHMMFormat(this.options.customer.get('creatDate'));

        if (date)
          this.ui.createDate.html(date);
      }

      //-------------------

      var contactPerson = '';

      if (this.options.customer.get('firstName'))
        contactPerson += this.options.customer.get('firstName');

      if (this.options.customer.get('firstName'))
        contactPerson += ' ';

      if (this.options.customer.get('lastName'))
        contactPerson += this.options.customer.get('lastName');

      if(contactPerson !== '')
        this.ui.contactPerson.html(contactPerson);
      else
        this.ui.contactPerson.html('-');

      //-------------------

      if (this.options.customer.get('email'))
        this.ui.mail.html(this.options.customer.get('email'));
      else
        this.ui.mail.html('-');

      //-------------------

      if (this.options.customer.get('invoiceEmail'))
        this.ui.invoiceEmail .html(this.options.customer.get('invoiceEmail'));
      else
        this.ui.invoiceEmail .html('-');

      //-------------------

      var clientCode = this.options.customer.getCustomerNumber();

      if (clientCode)
        this.ui.clientCode.html(clientCode);
      else
        this.ui.clientCode.html('-');

      //-------------------

      if (this.options.customer.get('tel'))
        this.ui.phone.html(this.options.customer.get('tel'));
      else
        this.ui.phone.html('-');


      //-------------------

      if (this.options.customer.get('mobile'))
        this.ui.mobilePhone.html(this.options.customer.get('mobile'));
      else
        this.ui.mobilePhone.html('-');

      //-------------------

      if (Settings.configValue('customer.profile.showKvk') === true)
        this.ui.kvkContainer.show();
      else
        this.ui.kvkContainer.hide();

      if(this.options.customer.get('siret')) {
        this.ui.kvk.html(this.options.customer.get('siret'));

        var kvkRegex = /^\d{8}$/;
        if (!kvkRegex.test(this.options.customer.get('siret')))
          this.ui.kvk.addClass('invalid');
      }
      else
        this.ui.kvk.html('-');

      //-------------------

      if (Settings.configValue('customer.profile.showSiret') === true)
        this.ui.siretContainer.show();
      else
        this.ui.siretContainer.hide();

      if(this.options.customer.get('siret'))
        this.ui.siret.html(this.options.customer.get('siret'));
      else
        this.ui.siret.html('-');

      //-------------------

      if (this.options.customer.get('countryCode'))
        this.ui.countryCode.html(this.options.customer.get('countryCode'));
      else
        this.ui.countryCode.html('-');

      if (Settings.isMultiWebshop()) {
        if (this.options.customer.get('webShopLocale')) {
          this.ui.webShop.html(this.options.customer.get('webShopLocale').slice(-2) + ' (' +
              Settings.get('webshopUrl')[this.options.customer.get('webShopLocale')] + ')');
        } else
          this.ui.webShop.html('-');
      } else {
        this.ui.webShopItem.hide();
      }
      //-------------------

      App.request('common:countries').done(_.bind(function() {
        var invoiceAddress = Controller.createAddress(this.options.customer.toJSON(), '<br>');
        if (invoiceAddress)
          this.ui.invoiceAddress.html(invoiceAddress);
        else
          this.ui.invoiceAddress.html('-');

        //-------------------
        var deliveryAddress = Controller.createAddress(this.getDeliveryAddress(), '<br>');

        if (deliveryAddress)
          this.ui.deliveryAddress.html(deliveryAddress);
        else
          this.ui.deliveryAddress.html('-');
      }, this));
    },

    onRender: function() {
      this.renderContent();
    },

    onEditDeliveryAddress: function() {
      this.addressView = new AddressView({
        model: App.request('address:model', this.getDeliveryAddress())
      });
      this.addressView.show({
        title: _.i18n('common.edit'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function () {
              return true;
            }
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: _.bind(function () {
              this.model.set('refDeliveryAddress', this.addressView.model.toJSON());
              this.model.saveDeliveryAddress();
            }, this)
          }
        }
      });
    },

    getDeliveryAddress: function() {
      var orderDelAddress = this.model.get('refDeliveryAddress');
      if (orderDelAddress && (orderDelAddress.name || orderDelAddress.firstName || orderDelAddress.lastName)) {
        return this.model.get('refDeliveryAddress');
      }
      return null;
    },

    getAddressClipboard: function (data) {
    
      var str = '';

      if (data.name) {
        str += data.name;
      }
      if (data.firstName || data.lastName) {
        if (str.trim() !== '')
          str += '\n';

        if (data.firstName) {
          str += data.firstName;
        }
        if (data.lastName) {
          str += ' ' + data.lastName;
        }
      }    

      var address = '';

      if (data.houseNumber) {
        address += data.houseNumber;
      }

      if (data.addressL1) {
        if (address.trim() !== '')
          address += ', ';

        address += data.addressL1;
      }

      if (data.addressL2 && data.addressL2 !== data.houseNumber) {
        if (address.trim() !== '')
          address += '\n';

        address += data.addressL2;
      }

      if (address.trim() !== '') {
        if(str.trim() !== '')
          str += '\n';
        str += address;
      }
    
      //--------

      var countryStr = '';

      if (data.zip) {
        countryStr += data.zip;
      }

      if (data.city) {
        if (countryStr.trim() !== '')
        countryStr += ' ';

          countryStr += data.city;
      }

      var country = _.findWhere(Settings.get('countries'), {code: data.countryCode}); 
         
      if (country && country.name) {
        if (countryStr.trim() !== '')
          countryStr += '\n';

        countryStr += country.name;
      }

      if (countryStr.trim() !== '') {
        if(str.trim() !== '')
          str += '\n';
        str += countryStr;
      }

      //--------

      if (data.tel) {
        if(str.trim() !== '')
          str += '\n';
        str +=  data.tel;
      }

      //--------

      return str;
    },

    onCopyCompanyName: function () {
     Controller.copyToClipboard(this.options.customer.get('name'));
    },

    onCopyContactPerson: function () {
      var value = '';

      if (this.options.customer.get('firstName'))
        value += this.options.customer.get('firstName');

      if (this.options.customer.get('firstName') !== '')
        value += ' ';

      if (this.options.customer.get('lastName'))
        value += this.options.customer.get('lastName');

      Controller.copyToClipboard(value);
    },

    onCopyMail: function () {
      Controller.copyToClipboard(this.options.customer.get('email'));
    },

    onCopyInvoiceMail: function () {
      Controller.copyToClipboard(this.options.customer.get('invoiceEmail'));
    },

    onCopyClientCode: function () {
      var clientCode = this.options.customer.getCustomerNumber();
      Controller.copyToClipboard(clientCode.toString());
    },    

    onCopyPhone: function () {
      Controller.copyToClipboard(this.options.customer.get('tel'));
    },

    onCallPhone: function (e) {
      e.stopPropagation();
      if(this.options.customer.get('tel')) {
        App.trigger('phone:numbering', {
          phoneNumber: this.options.customer.get('tel'),
          country: this.options.customer.get('countryCode')
        });
      }
    },
    
    onCopyMobilePhone: function () {
      Controller.copyToClipboard(this.options.customer.get('mobile'));
    },

    onCallMobilePhone: function (e) {
      e.stopPropagation();
      if(this.options.customer.get('mobile')) {
        App.trigger('phone:numbering', {
          phoneNumber: this.options.customer.get('mobile'),
          country: this.options.customer.get('countryCode')
        });
      }
    },

    onCopySiret: function () {
      if (this.options.customer.get('siret'))
        Controller.copyToClipboard(this.options.customer.get('siret'));
    },

    onToVat: function () {
      var vat = this.options.customer.siretToVat(this.options.customer.get('siret'));

      this.options.customer.set('vatNumber', vat);
      this.options.customer.save();
    },

    onCopyDeliveryAddress: function () {
      var object = this.getDeliveryAddress();
      Controller.copyToClipboard(Controller.createAddress(object, '\n'));
    },

    onCopyKvk: function () {
      if (this.options.customer.get('siret'))
        Controller.copyToClipboard(this.options.customer.get('siret'));
    },

    deliveryAddressFromClient: function () {
      if(this.options.customer.get('refDeliveryAddress')) {
        this.model.set('refDeliveryAddress', this.options.customer.get('refDeliveryAddress'));
        this.model.saveDeliveryAddress();
      }
    },

    onCopyInvoiceAddress: function () {
      var object = this.options.customer.toJSON();
      Controller.copyToClipboard(Controller.createAddress(object, '\n'));
    },

    onEdit: function () {
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS]) {
        if (Settings.configValue('orders.detail.editCustomerInNewTab')) {
          var baseURL = window.location.protocol + '//' + window.location.hostname;
          if (window.location.port) {
            baseURL += ':' + window.location.port;
          }
          var url = baseURL + '#customers/' + this.options.customer.get('secId');
          window.open(url);
        } else {
          App.navigate('customers/' + this.options.customer.get('secId'), {trigger: true});
        }        
      }
    }
  });
});

