define('caccountConfigView',[
  'module',
  'underscore',
  'app',
  'jquery',
  'backbone',
  'marionette',
  'settings',
  'template!caccountConfigTpl',
  'caccountCardsColorsView',
  'caccountConfigGmailView'
], function (
  module,
  _,
  App,
  $,
  Backbone,
  Marionette,
  Settings,
  viewTpl,
  CardsColorsView,
  ConfigGmailView
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      cardsColorsRegion: '.cards-colors-region',
      gmailRegion: '.gmail-region'
    },

    ui: {
      colorRgb: '.color-rgb',
      colorName: '.color-name',
      addButton: '.add-button'
    },

    events: {
      'click .btn-save-colors-names': 'onSaveColorsClick',
      'click .btn-add-color-name': 'onAddColorsClick',
      'click @ui.addButton': 'onAdd'
    },

    onRender: function () {
      $.material.init();

      this.cardsColorsView = new CardsColorsView({
        collection: this.model.get('cardColors')
      });
      this.getRegion('cardsColorsRegion').show(this.cardsColorsView);

      this.configGmailView = new ConfigGmailView();
      this.getRegion('gmailRegion').show(this.configGmailView);
    },

    onSaveColorsClick: function () {
      this.model.save().done(function () {
      });
    },

    onAdd: function () {
     

      if(this.ui.colorRgb.val() !== '' && this.ui.colorName.val()) {

        _.each(this.model.get('cardColors').models, function (color) {
          if(color.get('color') === this.ui.colorRgb.val())
            return;
        }, this);


        var color = {};
        color.color = this.ui.colorRgb.val();
        color.name = this.ui.colorName.val();

        this.model.get('cardColors').add(color);

        this.ui.colorRgb.val('');
        this.ui.colorName.val('');
      }
     
    }
  });
});
