
define('template!ordersDetailSupplierEditProductPrintZoneView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n  <div class="print-zone">\n    <div class="name-ctn">\n      <div class="name">' +
((__t = ( printAreaName )) == null ? '' : __t) +
'</div>\n      ';
 if(productOwner) { ;
__p += '\n        <div class="product-owner-ctn">\n          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n            <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/>\n          </svg>\n          <div class="product-owner">' +
((__t = ( _.i18n('printZone.printedBy') + ' ' + productOwner )) == null ? '' : __t) +
'</div>\n        </div>\n      ';
 } ;
__p += '\n    </div>\n    <div class="print">' +
((__t = ( print )) == null ? '' : __t) +
'</div>\n    <div class="size">' +
((__t = ( size )) == null ? '' : __t) +
'</div>\n    ';
 if (colorModeType === 'spot') { ;
__p += '\n        <div class="colors">' +
((__t = ( colors )) == null ? '' : __t) +
':</div>\n    ';
 } ;
__p += '\n    <div class="colors-region"></div>\n  </div>\n</div>\n<div class="body">\n  <div class="images-region"></div>\n</div>';

}
return __p
};});

