/**
 * Created by RKL on 21/08/2015.
 */
define('personalizeShowView',[
  'module',
  'marionette',
  'app',
  'template!personalizeViewTpl'
], function(
  module,
  Marionette,
  App,
  viewTpl
) {
  'use strict';

  var PersonalizeView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
    },

    onShow: function() {
      $.material.init();
    }
  });

  module.exports = PersonalizeView;
});
