define('commentView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'imageUtils',
  'dateUtils',
  'commentEditView',
  'settings',
  'template!commentView'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,
  ImageUtils,
  DateUtil,
  EditView,
  Settings,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({    
    className: 'common-comment-view',
    template: Tpl,

    ui: {
      date: '.date',
      name: '.name',
      logo: '.logo',
      body: '.body',
      buttons: '.buttons',
      pinButton: '.btn-pin'
    },

    events: {
      'click .btn-delete': 'onDelete',
      'click .btn-edit': 'onEdit',
      'click .btn-pin': 'onPin'
    },

    modelEvents: {      
      'change': 'render'
    },    

    onRender: function() {
      this.ui.date.html(DateUtil.toMDYHHMMFormat(this.model.get('msgDate')));

      this.ui.name.html('-');

      var user = this.model.get('userpid');

      var logo = '';

      if(user) {       
        logo = ImageUtils.getLogo(user.logo, user.email);
        this.ui.name.html(user.firstName + ' ' + user.lastName);        
      }

      if(logo) {
        this.ui.logo.css('background-image', 'url("'+ logo +'")');
        this.ui.logo.show();
      } else {
        this.ui.logo.hide();
      }

      if(this.model.get('content'))
        this.ui.body.html(this.model.get('content').replaceAll('\n', '<br>'));
      else
        this.ui.body.html('');

      if(this.options.showButtons === false) {
        this.ui.buttons.hide();
      }

      if(this.options.pin) {
        this.ui.pinButton.css('display', 'flex');

        if(this.model.get('pin'))
          this.ui.pinButton.attr('data-active', true);
        else
          this.ui.pinButton.attr('data-active', false);
      }
      else
        this.ui.pinButton.hide();
    },

    onDelete: function () {
      this.trigger('delete');
    },

    onPin: function () {
      this.trigger('pin');
    },

    onEdit: function () {
      EditView.prototype.open({
        model: new Backbone.Model({comment: this.model.get('content')}),
        title: _.i18n('common.comment'),
        onConfirm: _.bind(function (value) {
          var currentUserPid = Settings.get('currentUser');
          var data = {};

          data.content = value ? value : '';
          data.msgDate = new Date().getTime();
          data.externalUserId = currentUserPid.externalId ? currentUserPid.externalId : '';
          data.title = '';
          data.user = currentUserPid.secId;
          this.model.set(data);
          this.trigger('change');
        }, this)
      });


      /*var commentPopupView = new CommentPopupView({
        model: new Backbone.Model({comment: this.model.get('content')})
      });

      commentPopupView.show({
        title: _.i18n('common.comment'),
        buttons: {
          ok: {
            label: _.i18n('common.ok'),
            className: 'btn-default',
            callback: _.bind(function () {
              var currentUserPid = Settings.get('currentUser'),
                title = commentPopupView.model.get('title'),
                commentContent = commentPopupView.model.get('comment');

              var data = {};

              data.content = commentContent ? commentContent : '';
              data.msgDate = new Date().getTime();
              data.externalUserId = currentUserPid.externalId ? currentUserPid.externalId : '';
              data.title = title ? title : '';
              data.user = currentUserPid.secId;
              this.model.set(data);
              this.trigger('change');
               
              return true;
            }, this)
          }
        }
      });*/
    }
  });   
});
