/**
 * Created by RKL on 26/08/2015.
 */
define('editTemplateController',[
  'module',
  'underscore',
  'app',
  'settings',
  'editTemplatePluginView'
], function(
  module,
  _,
  App,
  Settings,
  View
) {
  'use strict';

  var EditTemplatePluginController = {
    showPlugin: function(data) {
      var userId = '',
          returnUrl, pluginUrl, view;

      if (data.endUserId) {
        userId = '&endUserId=' + data.endUserId;
        returnUrl = window.location.origin + window.location.pathname +
          '#customers/' + data.userSecId + '/' + data.returnTab + '/' +  data.groupSecId;
      } else {
        returnUrl = window.location.origin + window.location.pathname +
          '#caccount/' + data.cAccountId + '/' + data.returnTab + '/' +  data.groupSecId;
      }

      if (data.parentId) {
        returnUrl += '/variation/' + data.parentId;
      }

      returnUrl += '/reload';
      pluginUrl = Settings.pluginUrl() +
        '?action=editTemplate' +
        '&templateSecId=' + data.templateSecId +
        '&caccountSecId=' + data.cAccountId +
        '&isAdmin=true'+ userId +
        '&returnUrl=' + encodeURIComponent(returnUrl) +
        '&lang=' + Settings.get('lang');
      
      view = new View({url: pluginUrl});
      
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    }
  };

  module.exports = EditTemplatePluginController;
});
