/**
 * Created by RKL on 23/09/2015.
 */
define('caccountShareLayoutView',[
  'module',
  'marionette',
  'app',
  'settings',
  'rolesMixin',
  'template!caccountShareLayoutTpl'
], function(
  module,
  Marionette,
  App,
  Settings,
  RolesMixin,
  layoutTpl
) {
  'use strict';

  var CAccountShareTabLayoutView = Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      accountShareFrom: '.share-from-container',
      accountShareTo: '.share-to-container'
    },

    serializeData: function() {
      var data = {};
      var roles = Settings.get('cAccountRoles');
      if (roles[RolesMixin.SUPERUSER]) {
        data.displayShareTo = true;
      } else {
        data.displayShareTo = false;
      }
      return data;
    }
  });

  module.exports = CAccountShareTabLayoutView;
});
