define('catalogueMineSharingTabView',[
  'module',
  'jqgridView',
  'template!catalogueMineSharingTabTpl',
  'app',
  'settings',
  'underscore',
  'entities/catalogues'
], function(
  module,
  JqGridView,
  sharingTabTpl,
  App,
  Settings,
  _
) {
  'use strict';

  var SharingTabView = JqGridView.extend({
    filtersName: 'catalagueMineSharing',
    template: sharingTabTpl,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage'),
        currentPage: 1
      };
    },

    gridOptions: function(data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: ' ',
              name: 'logo',
              formatter: this.pictureUidFormatter,
              classes: 'cell-link',
              search: false
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: false
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'cAccountCode'
            }, {
              label: _.i18n('common.date'),
              name: 'date',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: false
            }
          ]
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },


    onGridLoaded: function() {
      var data = {
          url: App.request('catalogue:mine:shared-to:url', this.options.catalogueSecId),
          rowCountUrl: App.request('catalogue:mine:shared-to:count-url', this.options.catalogueSecId)
        };

      this.displayGrid(data);
    }
  });

  module.exports = SharingTabView;
});
