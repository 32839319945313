
define('template!changeDifficultyLevelPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group">\n  <div class="form-group" data-field-name="difficultyLevel">\n    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('orders.difficultyLevel') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-9">\n      <select class="form-control floating-label js-info-input" data-field-name="difficultyLevel"\n              placeholder="' +
((__t = ( _.i18n('orders.difficultyLevel') )) == null ? '' : __t) +
'" value="' +
((__t = ( difficultyLevel )) == null ? '' : __t) +
'">\n        <option></option>\n        <option ';
  if (difficultyLevel === 1) { ;
__p += ' selected ';
 } ;
__p += '>1</option>\n        <option ';
  if (difficultyLevel === 2) { ;
__p += ' selected ';
 } ;
__p += '>2</option>\n        <option ';
  if (difficultyLevel === 3) { ;
__p += ' selected ';
 } ;
__p += '>3</option>\n      </select>\n    </div>\n  </div>\n</div>';

}
return __p
};});

