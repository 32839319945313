define('pimProductAttributeView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'pimProductAttributeValuesView',
  'template!pimProductAttributeTpl'
], function(
  module,           
  Marionette,
  App,
  _,
  PimProductAttributeValuesView,
  viewTpl
){
  'use strict';

  var PimProductAttributeView = Marionette.LayoutView.extend({   
    template: viewTpl,
    className: 'attribute-view',

    regions: {
      valuesRegions: '.values-region'
    },

    onRender: function () {   
      
      if(this.options.viewModel.get('currentSecId') !== '') {
        this.options.valuesCollection.fetch(this.options.viewModel.get('currentSecId')).done(_.bind(function(){
          this.attributeValuesView = new PimProductAttributeValuesView({
            'collection': this.options.valuesCollection, 
            'attributeModel': this.model,
            'viewModel': this.options.viewModel,
            'productAttribute': this.options.productAttribute
          });
          this.getRegion('valuesRegions').show(this.attributeValuesView);
        }, this));
      }            
    },
  
    serializeData: function () {
      var templateData = {};

      templateData.code = this.model.get('code');
      return templateData;
    }
  });

  module.exports = PimProductAttributeView;
});
