define('webshopCategoriesListFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'selectView',
  'filtersBannerEditView',
  'template!webshopCategoriesListFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  SelectView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'webshop-categories-list-filters-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webshop.categories.list.filters.edit'),

    ui: _.extend({
      code: '.body .code input',
      name: '.body .name input',
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to'
    }, FiltersBannerEditView.prototype.ui),

    regions: _.extend({
      statusSelectRegion: '.status .select-region'
    }, FiltersBannerEditView.prototype.regions),

    onShow: function() {
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: '0',  value: _.i18n('common.disabled')},
          {id: '1',  value: _.i18n('common.enabled')},
          {id: '-1',  value: _.i18n('common.review')}
        ]),
        tabindex: 3
      });
      this.statusSelectRegion.show(this.statusView);
      this.statusView.setSelected(this.model.get('status'));

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function() {
      this.ui.code.val(this.model.get('code'));
      this.ui.name.val(this.model.get('name'));
      if(this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if(this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function() {
      this.model.set({
        status: this.statusView.getSelected().get('id'),
        code: this.ui.code.val().trim(),
        name: this.ui.name.val().trim(),
        dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
        dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : ''
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },
    onReset: function() {
      this.statusView.setSelected('');
      this.ui.code.val('');
      this.ui.name.val('');
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
    }
  });  
});

