
define('template!superAdminAccountStatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well  table-sa">\n\t<div class="row pagin-container">\n\t\t<a class="col-xs-1 btn btn-link prev-pg\n\t\t';
 if (pagin.first === 0) { ;
__p += ' disabled ';
 } ;
__p += '\n\t\t"><i class="mdi-hardware-keyboard-arrow-left"></i></a>\n\t\t<h2 class="col-xs-9 st-superadmin-title">' +
((__t = ( _.i18n("superAdmin.newAccounts") )) == null ? '' : __t) +
'</h2>\n\t\t<a class="col-xs-2 btn btn-link next-pg\n\t\t';
 if (pagin.currentPage === pagin.totalPages || (pagin.first + pagin.pageSize) >= pagin.total) { ;
__p += ' disabled ';
 } ;
__p += '\n\t\t"><i class="mdi-hardware-keyboard-arrow-right"></i></a>\n\t</div>\n\t<table class="table table-striped account-stat">\n\t\t<thead>\n\t\t\t<tr>\n\t\t\t\t<th>' +
((__t = ( _.i18n("common.code") )) == null ? '' : __t) +
'</th>\n\t\t\t\t<th>' +
((__t = ( _.i18n("common.username") )) == null ? '' : __t) +
'</th>\n\t\t\t</tr>\n\t\t</thead>\n\t\t<tbody>\n\t\t\t';
 _.each(accountsStats, function(account) { ;
__p += '\n\t\t\t<tr>\n\t\t\t\t<td class="sa-td" title="' +
((__t = ( account.cAccount.code )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( '#superAdmin/caccount/' + account.cAccount.secId )) == null ? '' : __t) +
'">' +
((__t = ( account.cAccount.code )) == null ? '' : __t) +
'</a>\n                </td>\n\t\t\t\t<td class="sa-td" title="' +
((__t = ( account.user.logonId )) == null ? '' : __t) +
'">' +
((__t = ( account.user.logonId )) == null ? '' : __t) +
'</td>\n\t\t\t</tr>\n\t\t\t';
 }); ;
__p += '\n\t\t\t<tr class="sa-pagin-container">\n\t\t\t\t<td colspan="2" class="sa-pager-cell">\n\t\t\t\t\t' +
((__t = ( _.i18n("common.page") )) == null ? '' : __t) +
' \n\t\t\t\t\t' +
((__t = ( pagin.currentPage + 1 )) == null ? '' : __t) +
' ' +
((__t = ( _.i18n("common.of") )) == null ? '' : __t) +
' \n\t\t\t\t\t' +
((__t = ( pagin.totalPages )) == null ? '' : __t) +
'\n\t\t\t\t</td>\n\t\t\t</tr>\n\t\t</tbody>\n\t</table>\n</div>';

}
return __p
};});

