define('ordersListProductView',[
  'app',
  'module',
  'underscore',
  'settings',
  'tableFieldView',
  'template!ordersListProductView'
], function (
  App,
  Module,  
  _,
  Settings,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-product-view ' + FieldView.prototype.className,

    modelEvents: {
      'change:productName': 'render',
      'change:productBaseCode': 'render',
      'change:productOwner': 'render',
      'change:pim': 'render',
      'change:productSecId': 'render'
    },

    ui: {
      name: '.name',
      infos: '.infos',
      link: '.link'
    },

    events: {
      'click @ui.link': 'onLinkClick'
    },

    serializeData: function() {
      var data = {};

      data.name = '';
      if(this.model.get('productName'))
        data.name = this.model.get('productName');

      if(this.model.get('productNameDt')) {
        _.each(this.model.get('productNameDt').values, function (value, lang) {
            if (Settings.get('lang') === lang)
                data.name = value;
        }, this);
      }

      //---------

      data.infos = '';

      if(this.model.get('productBaseCode')) {
        data.infos = '<a class="link" href="javascript:;">' + this.model.get('productBaseCode') + '</a>';
      }

      if(this.model.get('productOwner')) {
        if(data.infos.length > 0)
          data.infos += ' | ';

        data.infos += this.model.get('productOwner');
      }

      return data;
    },

    onLinkClick: function () {
      this.fetch().done(_.bind(function (productModel) {
        var url = '';
        if (productModel.get('pim'))
          url = '#pim/products/' + productModel.get('secId');
        else
          url = '#product/' + productModel.get('secId');
        App.navigate(url, {trigger: true});
      }, this));
    },

    fetch: function() {
      var defer = $.Deferred();

      var productModel = null;
      if (this.model.get('pim')) {
        productModel = App.request('products:model', {code: this.model.get('productBaseCode')});
        productModel.fetchByCode(this.model.get('pim')).done(_.bind(function () {
          defer.resolve(productModel);
        } ,this));
      } else {
        productModel = App.request('products:model', {code: this.model.get('productBaseCode')});
        productModel.fetchByCode(this.model.get('pim')).done(_.bind(function () {
          defer.resolve(productModel);
        } ,this));
      }
      return defer.promise();
    }
  });
});
