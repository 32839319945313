/**
 * Created by BFR on 25/06/2020.
 */
define('caccountPrintCodesView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'bootbox',
  'template!caccountPrintCodesTpl'
], function (
  module,
  Marionette,
  App,
  _,
  bootbox,
  viewTpl
) {
  'use strict';

  var PrintCodesView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      txtPrintCode: '.txt-printcode',
      btnAddPrintCode: '.btn-add-printcode',
      btnSavePrintCode: '.btn-save-printcode',
      printCodes: '.ddl-printcodes',
      txtFactor: '.txt-factor',
      txtFactorSetup: '.txt-factor-setup',
      txtFactorHandling: '.txt-factor-handling',
      formPrintFactors: '.from-print-factors',
      btnDeletePrintCode: '.btn-delete-printcode',
      cbxDefaultPrice: '.defaultPrice'
    },

    initialize: function () {
      this.currentPrintCode = this.options.currentPrintCode;
    },

    events: {
      'click .btn-add-printcode': 'onAddPrintCode',
      'click @ui.btnSavePrintCode': 'onSavePrintCode',
      'click @ui.btnDeletePrintCode': 'onDeletePrintCode',
      'change @ui.txtFactor': 'onValueInputChange',
      'change @ui.txtFactorSetup': 'onValueInputChange',
      'change @ui.txtFactorHandling': 'onValueInputChange',
      'change @ui.printCodes': 'onDdlPrintCodeChange',
      'change @ui.cbxDefaultPrice': 'onValueInputChange'
    },

    onDdlPrintCodeChange: function () {
      this.currentPrintCode = this.options.printFactors.get(this.ui.printCodes.val());
      this.render();
    },

    onValueInputChange: function () {
      this.currentPrintCode.set('factor', this.ui.txtFactor.val().replace(',', '.'));
      this.currentPrintCode.set('factorSetup', this.ui.txtFactorSetup.val().replace(',', '.'));
      this.currentPrintCode.set('factorHandling', this.ui.txtFactorHandling.val().replace(',', '.'));
      var isDefault = this.ui.cbxDefaultPrice.is(':checked');
      if (isDefault) {
        _.each(this.currentPrintCode.collection.models, function (m) {
          m.set('defaultPrice', false);
        });
      }
      this.currentPrintCode.set('defaultPrice', this.ui.cbxDefaultPrice.is(':checked'));
      this.currentPrintCode.save();
    },

    onSavePrintCode: function () {
      this.currentPrintCode = this.options.printFactors.create({
        printCode: this.ui.txtPrintCode.val(),
        refCAccountSupplier: {
          secId: this.options.currentSupplier
        }
      });
      this.ui.txtPrintCode.val('');
      this.ui.printCodes.show();
      this.ui.btnAddPrintCode.show();
      this.ui.btnSavePrintCode.hide();
      this.ui.txtPrintCode.hide();
      this.ui.btnDeletePrintCode.show();
      this.render();
    },

    onDeletePrintCode: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.options.printFactors.remove(this.currentPrintCode);
          this.currentPrintCode.destroy();
          this.currentPrintCode = this.options.printFactors.models[0];
          this.render();
        }
      }, this));
    },

    onAddPrintCode: function () {
      this.ui.printCodes.hide();
      this.ui.btnAddPrintCode.hide();
      this.ui.btnDeletePrintCode.hide();
      this.ui.btnSavePrintCode.show();
      this.ui.txtPrintCode.show();
      this.ui.formPrintFactors.hide();
    },

    serializeData: function () {
      var templateData = {};
      templateData.printFactors = this.options.printFactors.toJSON();
      templateData.current = this.currentPrintCode ? this.currentPrintCode.toJSON() : null;
      return templateData;
    }
  });

  module.exports = PrintCodesView;
});
