define('entities/pim_product_competitor',[
  'app',
  'jquery',
  'settings',
  'backbone'
], function (
  App,
  $,
  Settings,
  Backbone
) {
  'use strict';

  var ProductCompetitor = Backbone.Model.extend({
    defaults: {
      name: '',
      link: '',
      price: 0,
      comment: ''
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') +
        '/competitors/' + this.get('secId'));
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') +
        '/competitors/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/competitors/');
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId'
  });

  var ProductCompetitorCollection = Backbone.Collection.extend({
    fetch: function (productSecId) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + productSecId + '/competitors/', {
        first: 0,
        pageSize: 99
      });
      return Backbone.Collection.prototype.fetch.call(this);
    },

    getCount: function (params) {
      var that = this;

      that.trigger('count:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('productSecId') + '/competitors/rowCount', params),
        success: function (count) {
          that.trigger('count:sync', count);
        }
      });
    },
    model: ProductCompetitor
  });

  App.reqres.setHandler('pim:product-competitor:collection', function (productCompetitorsJSON) {
    return new ProductCompetitorCollection(productCompetitorsJSON);
  });
  App.reqres.setHandler('pim:product-competitor:model', function (productCompetitorJSON) {
    return new ProductCompetitor(productCompetitorJSON);
  });
});
