/**
 * Created by ASK on 27-10-22.
 */
define('ordersDetailOrderPrintZonesView',[
  'module',
  'app',
  'backbone',
  'marionette',
  'underscore',
  'ordersDetailOrderPrintZoneView',
  'ordersDetailOrderPrintZonesEditView',
  'template!ordersDetailOrderPrintZonesView'
], function (
  module,
  App,
  Backbone,
  Marionette,
  _,
  PrintAreaView,
  EditView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: PrintAreaView,
    className: 'orders-detail-order-printzones-view',
    childViewContainer: '.print-zones-ctn',

    childViewOptions: function () {
      return {
        order: this.model,
        product: this.options.product
      };
    },

    modelEvents: {
      'change': 'fetch'
    },

    ui: {
      addButton: '.btn-add'
    },

    events: {
      'click @ui.addButton': 'onAdd'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    renderAddButton: function () {
      var cpt = 0;
      _.each(this.options.product.get('printAreas'), _.bind(function (printArea) {
        var pa = _.findWhere(this.model.get('compositions'), {paId: printArea.id});
        if (!pa)
          cpt++;
      }, this));

      if (cpt > 0)
        this.ui.addButton.show();
      else
        this.ui.addButton.hide();
    },

    onAdd: function () {
      EditView.prototype.open({
        model: this.model,
        product: this.options.product,
        title: _.i18n('orders.detail.printzones.add.title')
      });
    },

    fetch: function () {
      this.collection.reset();
      this.collection.add(this.model.get('compositions'));
      this.renderAddButton();
    }
  });
});
