
define('template!superAdminCreditPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n  <span class="js-clear-filters clear-filters">\n    <span>\n      <strong>x</strong>\n    </span>\n    <span class="clear-filters-text">' +
((__t = ( _.i18n('common.resetFilters') )) == null ? '' : __t) +
'</span>\n  </span>\n</div>\n<div class="row">\n\t<!-- Date from -->\n\t<div class="form-group col-xs-4">\n\t  <div class="input-group">\n\t      <span class="input-group-addon">' +
((__t = ( _.i18n("common.fromDate") )) == null ? '' : __t) +
'</span>\n\t      <input type="text" class="form-control date-superadmin date-from" placeholder="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'" value="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'">\n\t      <span class="input-group-btn">\n\t          <span class="caret pull-right m-t-5"></span>\n\t      </span>\n\t  </div>\n\t</div>\n\n\t<!-- Date to -->\n\t<div class="form-group col-xs-4 date-to-form">\n\t  <div class="input-group">\n\t      <span class="input-group-addon">' +
((__t = ( _.i18n("common.toDate") )) == null ? '' : __t) +
'</span>\n\t      <input type="text" class="form-control date-superadmin date-to" placeholder="' +
((__t = ( dateTo )) == null ? '' : __t) +
'" value="' +
((__t = ( dateTo )) == null ? '' : __t) +
'">\n\t      <span class="input-group-btn">\n\t          <span class="caret pull-right m-t-5"></span>\n\t      </span>\n\t  </div>\n\t</div>\n\n\t<!-- In Day/week/month -->\n\t<div class="col-xs-4">\n\t  <div class="dropdown">\n\t    <button class="form-control dropdown-toggle" type="button" id="choosePeriodType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n\t      <span class="pull-left">' +
((__t = ( _.i18n("common.inFormat") )) == null ? '' : __t) +
'</span>\n\t      <span class="filter-by-type-sa" data-type="' +
((__t = ( filters.type )) == null ? '' : __t) +
'">' +
((__t = ( filters.type )) == null ? '' : __t) +
'</span>\n\t      <span class="caret pull-right m-t-5"></span>\n\t    </button>\n\t    <ul class="dropdown-menu col-xs-12" aria-labelledby="choosePeriodType">\n\t      <li class="item-type-sa" data-type="Day"><a>' +
((__t = ( _.i18n("common.days") )) == null ? '' : __t) +
'</a></li>\n\t      <li class="item-type-sa" data-type="Week"><a>' +
((__t = ( _.i18n("common.weeks") )) == null ? '' : __t) +
'</a></li>\n\t      <li class="item-type-sa" data-type="Month"><a>' +
((__t = ( _.i18n("common.months") )) == null ? '' : __t) +
'</a></li>\n\t      <li class="item-type-sa" data-type="Year"><a>' +
((__t = ( _.i18n("common.years") )) == null ? '' : __t) +
'</a></li>\n\t    </ul>\n\t  </div>\n\t</div>\n\t<div class="col-xs-12">\n\t\t<label class="date-validation-label"></label>\n\t</div>\n</div>\n<div class="footer-container row">\n\t<div class="col-xs-12">\n\t\t<a class="btn btn-primary pull-right save-sa">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n\t</div>\n</div>';

}
return __p
};});

