/**
 * Created by OLD on 21/09/2015.
 */
define('registerMainRouter',[
  'require',
  'exports',
  'module',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Marionette,
  App
) {
  'use strict';

  var RegisterRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'register': 'showRegister'
    }
  });

  var API = {
    showRegister: function(){
      require(['registerShowController'], function(ShowController) {
        App.startSubModule('registerMain');
        ShowController.showRegister();
      });
    }
  };

  App.on('before:start', function() {
    new RegisterRouter({
      controller: API
    });
  });

  module.exports = RegisterRouter;
});

