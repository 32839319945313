/**
 * Created by BFR on 14/04/2021.
 */
define('adminMainRouter',[
  'underscore',
  'require',
  'jquery',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin'
], function (
  _,
  require,
  $,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
  'use strict';

  var AdminRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'admin/imprintTechnologies': 'showImprintTechnologiesAdmin',
      'admin/htmlTemplates': 'showHtmlTemplatesAdmin',
      'admin/glossaries': 'showGlossariesAdmin',
      'admin/notifications': 'showNotificationsAdmin',
      'admin/webhookHistory': 'showWebhookHistory'
    },
    onRoute: function () {
    }
  });

  var API = {
    showNotificationsAdmin: function () {
      require([
        'adminNotificationsView',
        'contentShowController'
      ], function (AdminNotificationsView,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          this.notificationsView = new AdminNotificationsView();
          App.regions.getRegion('main').currentView.getRegion('content').show(this.notificationsView);
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_NOTIFICATIONS);
        });
      });
    },

    showImprintTechnologiesAdmin: function () {
      require([
        'adminImprintTechnologiesController',
        'contentShowController'
      ], function (AdminImprintTechnologiesController,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          AdminImprintTechnologiesController._showView();
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_IMPRINT_TECHNOLOGIES);
        });
      });
    },

    showHtmlTemplatesAdmin: function () {
      require([
        'adminHtmlTemplatesController',
        'contentShowController'
      ], function (adminHtmlTemplatesController,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          adminHtmlTemplatesController._showView();
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_HTMLTEMPLATES);
        });
      });
    },

    showGlossariesAdmin: function () {
      require([
        'adminGlossariesListView',
        'contentShowController'
      ], function (View,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          var view = new View();
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_IMPRINT_GLOSSARIES);
        });
      });
    },

    showWebhookHistory: function () {
      require([
        'adminWebhookHistoryView',
        'contentShowController'
      ], function (View,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          var view = new View();
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_WEBHOOK_HISTORY);
        });
      });
    }
  };

  App.on('admin:show', function () {
    App.navigate('admin');
    //API.showSuperAdmin();
  });

  App.on('before:start', function () {
    new AdminRouter({
      controller: API
    });
  });

  module.exports = AdminRouter;
});
