define('pimTabsData',[
  'module',
  'underscore',
  'rolesMixin'
], function(
  module,
  _,
  RolesMixin
) {
  'use strict';

  module.exports = [
    {
      name: 'prices',
      title: _.i18n('productDetails.prices.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PRICE
    }, {
      name: 'overview',
      title: _.i18n('menu.overview')
    }, {
      name: 'attributes',
      title: _.i18n('menu.attributes')
    }
  ];
});

