/**
 * Created by BFR on 25/06/2020.
 */
define('pimProductImprintsPricesView',['module',
        'marionette',
        'pimProductImprintPricesView'],
  function(module,
           Marionette,
           PimProductImprintPricesView){
  'use strict';

  var PimProductImprintsPricesView = Marionette.CollectionView.extend({
    childView: PimProductImprintPricesView,
    className: 'table-body'
   
  });

  module.exports = PimProductImprintsPricesView;
});
