/**
 * Created by RKL on 21/08/2015.
 */
define('pricesMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin
) {
  'use strict';

  var PricesRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'prices': 'showPrices',
      'prices/billingcodes' : 'showBillingCodesTab',
      'prices/pricelists' : 'showPriceListsTab'
    }
  });

  var API = {
    showPrices: function() {
      App.navigate('prices/billingcodes');
      this.showBillingCodesTab();
    },

    showBillingCodesTab: function() {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('billingcodes').done(function() {
          require([
            'priceTabsController'
          ], function(BillingCodesController) {
            BillingCodesController.showTab('billingcodes');
          });
        });
      });
    },

    showPriceListsTab: function() {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('pricelists').done(function() {
          require([
            'priceTabsController'
          ], function(PriceListsTabController) {
            PriceListsTabController.showTab('pricelists');
          });
        });
      });
    },

    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('pricesMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.PRODUCTS_PRICELISTS);
          defer.resolve();
        });
      });
      return defer.promise();
    },

    checkLayout: function(tab) {
      var defer = $.Deferred();

      require([
        'priceController'
      ], function(PriceController) {
        PriceController.showPrices(tab).done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    }
  };

  App.on('prices:show', function() {
    App.navigate('prices');
    API.showPrices();
  });

  App.on('before:start', function() {
    new  PricesRouter({
      controller: API
    });
  });

  module.exports = PricesRouter;
});
