/**
 * Created by OLD on 20/11/2015.
 */
define('googleContactsPopupView',[
    'module',
    'underscore',
    'settings',
    'photoswipe',
    'photoswipeUi',
    'template!googleContactsPopupTpl',
    'dialogFormView',
    'collectionViewGoogleContacts'
], function(
    module,
    _,
    Settings,
    PhotoSwipe,
    PhotoSwipeUI,
    viewTpl,
    DialogFormView,
    CollectionViewGoogleContacts
) {
    'use strict';

    var GoogleContactsPopupView = DialogFormView.extend({
        template: viewTpl,

        ui: {
            contactsList: '.contacts-list',
            btnSend: '.send-to-d-b',
            linkSelectAll: '.link-select-all',
            linkUnSelectAll: '.link-unselect-all'
        },

        events: {
            'click @ui.btnSend': 'onBtnSendClicked',
            'click @ui.linkSelectAll': 'onLinkSelectAllClicked',
            'click @ui.linkUnSelectAll': 'onLinkUnSelectAllClicked'
        },

        onLinkSelectAllClicked: function(e) {
            e.preventDefault();
            _.each (this.collectionViewGoogleContacts.collection.models, function(model) {
                model.set('selected', true);
            });
            this.collectionViewGoogleContacts.render();
        },

        onLinkUnSelectAllClicked: function(e) {
            e.preventDefault();
            _.each (this.collectionViewGoogleContacts.collection.models, function(model) {
                model.set('selected', false);
            });
            this.collectionViewGoogleContacts.render();
        },

        onBtnSendClicked: function() {
            var emailsToSend = [];
            _.each (this.collectionViewGoogleContacts.collection.models, function(model) {
                if (model.get('selected')) {
                    emailsToSend.push(model.toJSON());
                }
            });
        },

        onShow: function() {
            this.showContactListView();
        },

        onDestroy: function() {
            this.collectionViewGoogleContacts.destroy();
        },

        showContactListView: function() {
            this.collectionViewGoogleContacts = new CollectionViewGoogleContacts({el: this.ui.contactsList});
            this.collectionViewGoogleContacts.collection.add(this.options.emails);
            this.collectionViewGoogleContacts.render();
        }
    });

    module.exports = GoogleContactsPopupView;
});
