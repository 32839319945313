/**
 * Created by OLD on 21/09/2015.
 */
define('registerShowController',[
  'module',
  'underscore',
  'app',
  'registerShowView'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';

  var RegisterController = {
    showRegister: function() {
      var view = new View();

      this.view = view;
      view.listenTo(view, 'register:sign:in', _.bind(this.onRegister, this));
      view.listenTo(view, 'register:keyup', _.bind(this.onRegister, this));
      App.regions.getRegion('main').show(view);
    },

    onRegister: function() {
      var that = this,
        company = this.view.ui.company.val(),
        email = this.view.ui.email.val();

      if (!_.isEmpty(company) && !_.isEmpty(email)) {
        require(['entities/session'], function() {
          var registerNew = App.request(
              'session:register:new', 
              {email: email, code: company}
            ),
            error;

          $.when(registerNew).done(function(resp) {
            if (resp.status === 200) {
              App.trigger('thank_you:show', 'fromRegister');
            } else if (resp.status === 409 || resp.status === 500) {
              if (resp.responseText === 'Caccount already used') {
                error = _.i18n('register.accountExists');
              } else if (resp.responseText === 'Email already used') {
                error = _.i18n('register.emailExists');
              } else {
                error = _.i18n('warning.systemError');
              }

              that.view.onError(error);
            }
          });
        });
      } else {
        this.view.onError();
      }
    }
  };

  module.exports = RegisterController;
});
