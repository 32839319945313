/**
 * Created by OBL on 14-12-15.
 */
define('cataloguesMineController',[
    'module',
    'underscore',
    'app',
    'cataloguesMineView',
    'cataloguesMineShareView',
    'cataloguesAddNewProductPopupView',
    'settings',
    'bootbox',
    'logotoolUtils',
    'entities/catalogues'
], function(
    module,
    _,
    App,
    View,
    ShareView,
    CataloguesAddNewProductPopupView,
    Settings,
    bootbox,
    logotoolUtils
    ) {
    'use strict';

    var CataloguesMineController = {
        showCatalogues: function() {
            this.view = new View();
            this.view.listenTo(this.view , 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.view.listenTo(this.view , 'catalogue:share', _.bind(this.onShareCatalogue, this));
            this.view.listenTo(this.view , 'catalogue:preview', _.bind(this.onPreviewCatalogue, this));
            this.view.listenTo(this.view , 'catalogue:delete', _.bind(this.onDeleteCatalogue, this));
            this.view.listenTo(this.view, 'catalogue:addNewCatalogue', _.bind(this.onAddCatalogue, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(this.view );
        },

        onShareCatalogue: function(secId){
            var that = this;
            App.request('catalogue:entity', secId).done(function(object){
                that.shareView = new ShareView(
                    {
                        model: object
                    });
                that.shareView.listenTo(that.shareView , 'catalogue:share:send', _.bind(that.onSendShareEmail, that.shareView));
                that.shareView.show({
                    title: _.i18n('catalogues.mine.share.title')
                });
                $.material.init();
            });
        },

        onSendShareEmail: function(secId,emails){
            App.request('catalogue:share', secId, emails);
            this.hide();
        },

        onPreviewCatalogue: function(secId){
            var url = logotoolUtils.getLogotoolCatalogueUrl({
                    catalogueSecId: secId
                });
            var win = window.open(url, '_blank');
            win.focus();
        },

        onDeleteCatalogue: function(secId){
            bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('catalogue:delete', secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('catalogue:mine:get-collection-url'),
                rowCountUrl: App.request('catalogue:mine:row-count-url')
            };

            this.view.displayGrid(data);
        },

        onAddCatalogue: function () {
            var that = this;

            this.popupView = new CataloguesAddNewProductPopupView({
                model: App.request('catalogue:mine:add-new:get-model')
            });
            this.popupView.listenTo(this.popupView.model, 'sync', _.bind(this.onConfirmPopup, this));
            this.popupView.show({
                title: _.i18n('catalogues.new.title'),
                buttons: {
                    cancel: {
                        label: _.i18n('common.cancel'),
                        className: 'btn-default',
                        callback: function() { return true; }
                    },
                    success: {
                        label: _.i18n('common.confirm'),
                        className: 'btn-primary',
                        callback: function() {
                            that.popupView.model.saveModel();
                            return false;
                        }
                    }
                }
            });
        },

        onConfirmPopup: function() {
            this.popupView.hide();
            this.onCatalogueAdded();
        },

        onCatalogueAdded: function() {
            this.view.updatePagination('reloadGrid');
        }
    };

    module.exports = CataloguesMineController;
});
