/**
 * Created by BFR on 03/04/2020.
 */
define('shareProductView',[
    'module',
    'marionette',
    'underscore',
    'template!shareProductTpl'
  ], function(
    module, Marionette,
    _,
    viewTpl
  ) {
    'use strict';
  
    var ShareProductView = Marionette.ItemView.extend({
      template: viewTpl,
  
      region: {
      },
  
      ui: {
        
      },
  
      events: {
      },

      modelEvents: {'change:inProgress':'inProgressChanged'}, 
      
      inProgressChanged: function(){ 
        this.render();
      },

      onShow: function () {
        
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = ShareProductView;
  });
