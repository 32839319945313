/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'thankYouShowView',
  'settings'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var ThankYouController = {
    showThankYou: function(target) {
      var view = new View();
     
      if (target === 'fromForgot') {
        view.options = {
          target: _.i18n('pages.messages.forgotpassword.info')
        };
      } else if (target === 'fromReset') {
        view.options = {
          target: _.i18n('pages.messages.resetpassword.info')
        };
      } else if (target === 'fromRegister') {
        view.options = {
          target: _.i18n('account.create.success')
        };
      }

      App.regions.getRegion('main').show(view);
    }
  };

  module.exports = ThankYouController;
});
