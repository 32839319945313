define('addNewPictureButtonView',[
  'module',
  'marionette',
  'template!addNewPictureButtonTpl'
], function (
  module,
  Marionette,
  addNewPictureButtonTpl
) {
  'use strict';

  var AddNewPictureButtonView = Marionette.ItemView.extend({
    template: addNewPictureButtonTpl,
    tagName: 'div',
    className: function () {
      if (this.options.lg) {
        return 'add-new-picture-button lg';
      }
      return 'add-new-picture-button';
    },

    triggers: {
      click: 'click'
    }
  });

  module.exports = AddNewPictureButtonView;
});
