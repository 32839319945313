define('webshopProductsDetailBodyCategoriesAddView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'marionette',
  'template!webshopProductsDetailBodyCategoriesAddView'
], function (
  App,
  Module,
  _,
  Backbone,
  Marionette,
  Tpl

) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-categories-add-view',

    ui: {
      cancel: '.footer .cancel',
      confirm: '.footer .confirm',
      category: '.category input'
    },

    events: {
      'click @ui.cancel': 'onCancel',
      'click @ui.confirm': 'onConfirm'
    },

    onShow: function () {
      App.request('webshop.categories.datalist');
    },

    onConfirm: function () {
      if(this.options.onConfirm)
        this.options.onConfirm(this.ui.category.val());

      this.windowView.close();
    },

    onCancel: function () {
      this.windowView.close();
    }
  });
});
