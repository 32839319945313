define('ordersDetailSupplierEditProductInfosOptionsView',[
  'module',
  'app',
  'backbone',
  'marionette',
  'underscore',
  'ordersDetailSupplierEditProductInfosOptionView',
  'template!ordersDetailSupplierEditProductInfosOptionsView'
], function (
  Module,
  App,
  Backbone,
  Marionette,
  _,
  OptionView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: OptionView,
    className: 'options-view',
    childViewContainer: '.options-container',

    childViewOptions: function () {
      return {
        product: this.options.product
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();
      var product = this.model.get('products')[0];
      this.collection.add(product.options);
    }
  });
});
