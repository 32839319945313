define('translationsPopupTextsView',[
  'module',
  'marionette',
  'template!translationsPopupTextsTpl',
  'formView'
], function(
  module,
  Marionette,
  translationsTextsTpl,
  FormView
) {
  'use strict';

  var TranslationsTextsView = FormView.extend({
    template: translationsTextsTpl,

    ui: {
      textareas: 'textarea',
      deleteLanguage: '.js-remove-icon'
    },

    events: {
      'change @ui.textareas': 'onInputChange',
      'click @ui.deleteLanguage': '_onDeleteLanguage'
    },

    collectionEvents: {
      change: 'render'
    },

    deletesDisabled: false,

    serializeData: function() {
      return {
        items: this.collection.toJSON(),
        model: this.model.toJSON()
      };
    },

    onRender: function() {
      $.material.init();
    },

    disableDeletes: function() {
      this.deletesDisabled = true;
    },

    enableDeletes: function() {
      this.deletesDisabled = false;
    },

    _onDeleteLanguage: function(e) {
      if(this.deletesDisabled) {
        return;
      }
      var abbreviation = $(e.currentTarget).data('abbreviation');

      this.model.unset(abbreviation.toLowerCase());
      this.collection.get(abbreviation).set({checked: false});
    }
  });

  module.exports = TranslationsTextsView;
});
