/**
 * Created by OLD on 07/12/2015.
 */
define('editAttachmentsController',[
  'module',
  'underscore',
  'app',
  'settings',
  'editAttachmentsPluginView'
], function(
  module,
  _,
  App,
  Settings,
  View
) {
  'use strict';

  var EditAttachmentsPluginController = {
    showPlugin: function(data) {
      var returnUrl, pluginUrl, view;
      
      if (data.type === 'caccount') {
        returnUrl = window.location.origin + window.location.pathname +
          '#caccount/' + data.cAccountId + '/' + data.returnTab;
      } else {
        returnUrl = window.location.origin + window.location.pathname +
          '#customers/' + data.cAccountId + '/' + data.returnTab;
      }      
      returnUrl += '/reload';
      pluginUrl = Settings.pluginUrl() +
        '?action=editAttachment' +
        '&attachmentSecId=' + data.attachmentsId +
        '&isAdmin=true' +
        '&tokenId=' + Settings.get('tokenId') +
        '&returnUrl=' + encodeURIComponent(returnUrl) +
        '&lang=' + Settings.get('lang');

      view = new View({url: pluginUrl});      
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    }
  };

  module.exports = EditAttachmentsPluginController;
});
