define('ordersDetailAttachmentsOrderView',[
  'app',
  'module',
  'underscore',
  'settings',
  'attachmentsView',
  'entities/customers/customers'
], function(
  App,
  Module,  
  _,
  Settings,
  AttachmentsView
) {
  'use strict';
  
  Module.exports = AttachmentsView.extend({      

    setConfig() {
      return {     
        galleryTitle: 'Order Attachment',
        canCopy: Settings.configValue('order.attachments.showCustomerAttachments') ? true : false, 
        copyButton: {
          title: _.i18n('orders.detail.attachments.order.copy-button.title')
        },
        canFlag: true
      };
    },
   
    fetch: function(first, pageSize) {   
      return this.model.getAttachments(first, pageSize);
    },

    uploadItem: function(file) {
      return this.model.uploadAttachment(file);
    },

    deleteItem(attachment) {
      return this.model.deleteAttachment(attachment);
    },

    editItem: function(attachment) {
      App.navigate('attachments/attachments/' + this.model.get('secId') + '/' + attachment.get('secId') + '/commandline', {trigger: true});
    },

    copyItem: function(attachment) {
      this.model.copyAttachmentToCustomer(attachment, this.options.customer.get('secId')).done(_.bind(function(newAttachment) {
        var model = App.request('customer:model', newAttachment);
        this.options.attachmentsView.customerView.addAttachment(model);
      }, this));      
    }
  });  
});

