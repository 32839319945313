
define('template!caccountContactsTabDetailsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="tab-layout-header js-tab-layout-header">\n    <div class="p-l-10 tab-layout-info row">\n      <div class="col-left name-holder js-name-holder m-t-5 col-lg-4 p-l-10">\n        <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n\n        <p class="p-code">' +
((__t = ( email )) == null ? '' : __t) +
'</p>\n      </div>\n      <div class="js-header-region"></div>\n      <span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n    </div>\n      <div class="navbar navbar-default navbar-md row m-b-0">\n        <div class="navbar-collapse collapse">\n          <ul class="p-l-10 nav navbar-nav">\n            ';
 _.each(tabs, function(tab) { ;
__p += '\n              <li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
' clickable" data-tab-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a>' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n            ';
 }) ;
__p += '\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n<div class="tab-layout-content js-tabs-region height-100"></div>';

}
return __p
};});

