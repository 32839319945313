
define('template!ordersDetailInvoicesPricesView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="price item">\n  <div class="libelle">' +
((__t = ( _.i18n('common.amount') )) == null ? '' : __t) +
':</div>\n  <div class="value">' +
((__t = ( price )) == null ? '' : __t) +
'</div>\n</div>\n<div class="vat item">\n  <div class="libelle">' +
((__t = ( _.i18n('common.vat') )) == null ? '' : __t) +
':</div>\n  <div class="value">' +
((__t = ( vat )) == null ? '' : __t) +
'</div>\n</div>\n<div class="total item">\n  <div class="libelle">' +
((__t = ( _.i18n('common.total') )) == null ? '' : __t) +
':</div>\n  <div class="value">' +
((__t = ( total )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

