
define('template!headerCompuzzView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<img src="' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( name )) == null ? '' : __t) +
'"/>';

}
return __p
};});

