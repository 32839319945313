/**
 * Created by RKL on 21/08/2015.
 */
define('pricesMain',[
  'module',
  'logger',
  'pricesMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Prices = {
    start: function() {
      Logger.log(module, 'starting PricesModule');
    },

    stop: function() {
      Logger.log(module, 'stopping PricesModule');
    }
  };

  module.exports = Prices;
});
