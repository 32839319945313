define('modalsAlertView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'events',
  'buttonView',
  'template!modalsAlertView'
], function(
  App,
  Module,
  Marionette,
  _,
  Events,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'modals-alert-view',

    ui: {
      body: '.body',
      footer: '.footer'
    },

    regions: {
      confirmRegion: '.confirm-region'
    },

    initialize: function() {
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    serializeData: function() {
      var data = {};
      data.text = this.options.text;

      data.icon = 'alert';
      if(this.options.icon)
        data.icon = this.options.icon;

      return data;
    },

    onShow: function() {
      this.confirmButtonview = new ButtonView({
        type: 'ok',
        text: _.i18n('modals.alert.confirm'),
        title: _.i18n('modals.confirmation.confirm.title'),
        tabindex: 1
      });

      this.confirmRegion.show(this.confirmButtonview);
      this.confirmButtonview.on('click', _.bind(this.onConfirm, this));
    },

    onConfirm: function () {
      if(this.options.confirm)
        this.options.confirm();
      this.windowView.close();
    },

    onKeyDown: function(e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        this.onConfirm();
        return false;
      }
    },

    // Static methods
    open: function (title, text, confirm, icon) {
      require([
        'windows',
        'modalsAlertView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              text: text,
              confirm: confirm,
              icon: icon
            });
          }, this),
          title: title,
          modal: true
        });
      });
    }
  });
});
