define('productPicturesTabLayoutView',[
  'module',
  'app',
  'jquery',
  'marionette',
  'template!productPicturesTabLayoutTpl',
  'emptyView',
  'settings',
  'imageUploadBehavior',
  'productPicturesTabCollectionView',
  'addNewPictureButtonView',
  'productAddNewPicturePopupView',
  'underscore',
  'pluginUtils',
  'entities/product_picture'
], function (
  module,
  App,
  $,
  Marionette,
  picturesTabLayoutTpl,
  EmptyView,
  Settings,
  ImageUploadBehavior,
  PictureCollectionView,
  AddNewPictureButtonView,
  AddNewPicturePopupView,
  _,
  PluginUtils
) {
  'use strict';

  var ProductPicturesTabLayoutView = Marionette.LayoutView.extend({
    template: picturesTabLayoutTpl,

    regions: {
      newPicture: '.js-new-picture-region',
      pictures: '.js-pictures-region',
      loader: '.js-loader-region'
    },

    ui: {
      showImgTemplate: '.template-upload-img',
      showImgCatalog: '.catalog-upload-img'
    },

    collectionEvents: {
      request: '_showLoader',
      sync: '_loadMoreAfterTimeOut',
      'first:loaded': '_showPictureCollection',
      'all:loaded': '_finishLoading'
    },

    events: {
      scroll: '_onScroll'
    },

    className: 'content height-100 scrollable',

    _loaderView: undefined,

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      }
    },

    notShowRemoveBackgroundPopup: true,

    _showPopup: function () {
      PluginUtils.callPlugin({
        action: 'editProductPicture',
        productSecId: this.options.product.get('secId')
      });
    },

    onRender: function () {
      this.fd = {};
      this.customAddNewPictureButton = new AddNewPictureButtonView({
        productSecId: this.options.product.get('secId')
      });
      this.customAddNewPictureButton.listenTo(this.customAddNewPictureButton, 'click', _.bind(this._showPopup, this));
      this.getRegion('newPicture').show(this.customAddNewPictureButton);
      this._loaderView = new EmptyView();
      this.getRegion('loader').show(this._loaderView);
      this.collection.fetchByProduct(this.options.product.get('secId'), true);
    },

    serializeData: function () {
      var templateData = this.options.product.toJSON();
      templateData.templateFileUrl = templateData.templateFileUid ?
        Settings.url('file', templateData.templateFileUid) :
        Settings.get('notFoundImage');

      templateData.cataloguePictureUrl = templateData.cataloguePictureUid ?
        Settings.url('file', templateData.cataloguePictureUid) :
        Settings.get('notFoundImage');

      if (templateData.templateFileUid) {
        _.each(templateData.productPictures, function (picture) {
          picture.urlWithTemplate = Settings.url(
            'x2png-ws',
            '/product/' + templateData.cCode + '/compuLogo',
            {
              fUid: templateData.templateFileUid,
              productPictureSecId: picture.secId,
              mw: 200,
              mh: 200
            }
          );
        });
      } else {
        templateData.templateFileUid = null;
      }
      return templateData;
    },

    onImageUploadConfirm: function (base64Value, fileExt, property) {
      this._showLoader();
      this.fd.base64file = base64Value;
      this.fd.fileExt = fileExt;

      var formData = new FormData(),
        productRequest;

      _.each(this.fd, function (value, key) {
        formData.append(key, value);
      });

      switch (property) {
        case 'template':
          this.ui.showImgTemplate.attr('style',
            'background-image:url(data:image/' + fileExt + ';base64,' + base64Value + ');');
          productRequest = App.request('product:update-template-picture', this.options.product.get('secId'), formData);
          $.when(productRequest)
            .done(_.bind(function (response) {
              this.options.product.set('templateFileUid', response.templateFileUid);
              this._hideLoader();
              this.render();
            }, this));
          break;
        case 'catalog':
          this.ui.showImgCatalog.attr('style',
            'background-image:url(data:image/' + fileExt + ';base64,' + base64Value + ');');
          productRequest = App.request('product:update-catalog-picture', this.options.product.get('secId'), formData);
          $.when(productRequest)
            .done(_.bind(function (response) {
              this.options.product.set('cataloguePictureUid', response.cataloguePictureUid);
              this._hideLoader();
              this.render();
            }, this));
          break;
      }
    },

    _loadMore: _.throttle(function () {
      if (this._shouldLoadMore()) {
        this.collection.fetchByProduct(this.options.product.get('secId'));
      } else {
        this._hideLoader();
      }
    }, 500),

    _loadMoreAfterTimeOut: function () {
      setTimeout(_.bind(this._loadMore, this), 10);
    },

    _onScroll: function () {
      this._showLoader();
      this._loadMore();
    },

    _showPictureCollection: function () {
      var pictureCollectionView = new PictureCollectionView({
        collection: this.collection,
        productSecId: this.options.product.get('secId'),
        productCode: this.options.product.get('cCode'),
        templateFileUid: this.options.product.get('templateFileUid')
      });
      this.getRegion('pictures').show(pictureCollectionView);
    },

    _showLoader: function () {
      this._loaderView.$el.show();
    },

    _hideLoader: function () {
      this._loaderView.$el.fadeOut();
    },

    _finishLoading: function () {
      this.$el.off('scroll');
      this._loaderView.destroy();
      this._loadingFinished = true;
    },

    _shouldLoadMore: function () {
      if (!this._loadingFinished) {
        var scrollContainer = this.$el,
          height = scrollContainer.height() || 75,
          scrollBlock, top;

        scrollBlock = this._loaderView.$el;
        top = scrollBlock.offset().top - scrollContainer.offset().top;

        if (top < height) {
          return true;
        }
      }
    }
  });

  module.exports = ProductPicturesTabLayoutView;
});
