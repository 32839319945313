
define('template!ticketsAllTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="tickets-content col-xs-12 ';
 if (vectorizator) { ;
__p += ' with-tab-header ';
 } ;
__p += '">\n    <div class="messages-container">\n    </div>\n  </div>\n  ';
 if (isKanban) { ;
__p += '\n  <div class="caccount-details-header">\n    <div class="caccount-tabs-container">\n      <div class="navbar navbar-default navbar-md tickets-header tickets-header-with-tabs m-b-0">\n        <div class="navbar-header">\n          <button data-target="#bs-tabs-details-navbar-collapse" type="button" class="navbar-toggle collapsed m-b-0 p-b-0 f-l" data-toggle="collapse" aria-expanded="false">\n            <i class="mdi-action-view-headline"></i>\n          </button>\n        </div>\n        <div class="p-l-0 navbar-collapse collapse" id="bs-tabs-details-navbar-collapse">\n          <ul class="nav navbar-nav" id="sub-caccount-tabs">\n            ';
 if (vectorizator && showCustomerMsg) { ;
__p += '\n            <li class="m-t-10 js-tab-title ticket-tab" data-role="" data-name="messages" data-route="messages">\n              <a class="p-t-10 p-b-5" href="#messages-tab" data-toggle="tab">' +
((__t = ( _.i18n('tab.messages') )) == null ? '' : __t) +
'</a>\n            </li>\n            ';
 } ;
__p += '\n            <li class="m-t-10 js-tab-title ticket-tab active" data-role="" data-name="messages-cs" data-route="messages-cs">\n              <a class="p-t-10 p-b-5" href="#messages-cs-tab" data-toggle="tab">' +
((__t = ( _.i18n('tab.messagesCs') )) == null ? '' : __t) +
'</a>\n            </li>\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n  <div class="js-tickets-header tickets-header p-t-5">\n    <div class="row">\n      <div class="col-xs-12">\n        <div class="col-left div-header-type p-r-10 p-l-10">\n          <span><i class=" ' +
((__t = ( typeClass )) == null ? '' : __t) +
' header-type "></i></span>\n        </div>\n          ';
 if(vectorizator && showMoreInfo) { ;
__p += '\n        <div class="col-left p-r-0 p-l-0">\n          <div class="logo-holder" style="background-image: url(' +
((__t = ( logoUrl )) == null ? '' : __t) +
');"></div>\n        </div>\n          <span class="caccount-more-info normall-light-compuzz">\n            <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>\n            <a id="caccount-more-info" class="hidden-xs hidden-sm hidden-md">' +
((__t = ( _.i18n('common.more.info'))) == null ? '' : __t) +
'</a>\n          </span>\n        <div class="col-left name-holder m-t-5 p-r-10 p-l-10">\n          <p class="p-name">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n        </div>\n          ';
 } else { ;
__p += '\n        <label class="m-label p-15 hidden-xs hidden-sm hidden-md">' +
((__t = ( _.i18n("common.messages") )) == null ? '' : __t) +
'</label>\n          ';
 } ;
__p += '\n        ';
 if (vectorizator && showMoreInfo) { ;
__p += '\n        <span class="badge\n        ';
if(balance<=0){;
__p += ' warning-bg ';
}else{;
__p += ' primary-bg ';
};
__p += '\n        counter-m m-l-10 m-r-0"\n            data-count="' +
((__t = ( balance )) == null ? '' : __t) +
'">\n          ' +
((__t = ( balance )) == null ? '' : __t) +
'\n        </span>\n        <span class="vertical-divider hidden-xs hidden-sm hidden-md"></span>\n        <a href="#timetracker/vectorization/' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" class="open-timetracker-page">\n          <i class="fa fa-calendar fa-lg icon-sub"></i>\n        </a>\n        ';
 } ;
__p += '\n        <span class="vertical-divider hidden-xs hidden-sm hidden-md m-r-10 m-l-10"></span>\n        <span id="ticket-state" class="hidden-xs hidden-sm hidden-md">' +
((__t = ( ticketStates[ticketState].label )) == null ? '' : __t) +
'</span>\n        <span class="vertical-divider hidden-xs hidden-sm hidden-md m-r-10 m-l-10"></span>\n        <span class="output-type-area">\n          <span class="hidden-xs hidden-sm hidden-md">' +
((__t = ( _.i18n("common.outputType") )) == null ? '' : __t) +
':</span>\n          <span id="ticket-outputType" class="label-light hidden-xs hidden-sm hidden-md">' +
((__t = ( outputType )) == null ? '' : __t) +
'</span>\n        </span>\n        <span class="vertical-divider hidden-xs hidden-sm hidden-md m-r-10 m-l-10"></span>\n        <span class="subject-area">\n          <span class="hidden-xs hidden-sm hidden-md">' +
((__t = ( _.i18n("common.subject") )) == null ? '' : __t) +
':</span>\n          <input class="form-control subject-input" value="' +
((__t = ( subject )) == null ? '' : __t) +
'" title="' +
((__t = ( subject )) == null ? '' : __t) +
'"/>\n        </span>\n        <span class="vertical-divider hidden-xs hidden-sm hidden-md m-r-0 m-l-0"></span>        \n        <div class="loading-block js-loading-block">\n          <span class="loading-message js-loading-message label"></span>\n        </div>\n        <span class="back-to-list m-t-0 f-r">\n          <i class="mdi-navigation-arrow-back"></i>\n          <span class="hidden-xs hidden-md hidden-sm">' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n        </span>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-xs-12 ';
 if (!isKanban) { ;
__p += ' p-b-5 ';
 } ;
__p += '">\n        <div id="change-status-region" class="p-r-10 f-r"></div>\n        <div id="change-type-dropdown-region" class="p-r-10 f-r"></div>\n        ';
 if (vectorizator) { ;
__p += '\n        <div class="display-inline-block f-r m-t-0 m-r-10 m-b-0 form-group">\n          <span data-id="' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" class="assigned-face-block clickable js-assign-to">\n            ';
 if (user.userImg && user.userImg.indexOf('data:image') === -1) { ;
__p += '\n            <div class="assigned-user-logo" style="background-image:url(' +
((__t = ( user.userImg )) == null ? '' : __t) +
')"></div>\n            ';
 } else if (user.userImg && user.userImg.indexOf('data:image') !== -1) { ;
__p += '\n            <img class="logo-holder js-assigned-user-no-logo" src="' +
((__t = ( user.userImg )) == null ? '' : __t) +
'"/>\n            ';
 } else { ;
__p += '\n            <i class="mdi-action-face-unlock"></i>\n            ';
 } ;
__p += '\n          </span>\n        </div>\n        <div class="form-group checkbox-group display-inline-block f-r m-r-10 m-b-0 mark-ticket-as-urgent">\n          <div class="checkbox checkbox-primary p-l-5 m-b-0 m-t-0">\n            <label>\n              <input type="checkbox" ';
 if (ticketIsUrgent) { ;
__p += ' checked ';
 } ;
__p += ' data-ticket-cid="' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" class="js-mark-ticket-urgent" ' +
((__t = ( editUrgency?'':'disabled' )) == null ? '' : __t) +
'/>\n              Urgent\n            </label>\n          </div>\n        </div>\n        <div class="display-inline-block f-r m-t-0 m-r-10 m-b-0 form-group">\n          <div class="change-tariff-scale row">\n            <label class="control-label p-r-0 p-l-0 m-t-5 col-xs-7">' +
((__t = ( _.i18n('vectorization.tariffScale') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5 p-l-5 p-r-10">\n              <select class="form-control js-change-tafiff-scale" data-ticket-cid="' +
((__t = ( ticketSecId )) == null ? '' : __t) +
'" ' +
((__t = ( editTariffScale?'':'disabled' )) == null ? '' : __t) +
'>\n                ';
 _.each(tariffScales, function(tariffScaleInner) { ;
__p += '\n                  <option value="' +
((__t = ( tariffScaleInner.send )) == null ? '' : __t) +
'" ' +
((__t = ( tariffScale === tariffScaleInner.send ? 'selected' : '' )) == null ? '' : __t) +
'>\n                    ' +
((__t = ( tariffScaleInner.label )) == null ? '' : __t) +
'\n                  </option>\n                ';
 }) ;
__p += '\n              </select>\n            </div>\n          </div>\n        </div>        \n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </div>\n  <div class="js-footer footer-vbox">\n    ';
 if (ticketState === 'CLOSE') { ;
__p += '\n        <div class="footer-blinder"></div>\n   ';
 } ;
__p += '\n    <!-- Footer content -->\n    <div class="message-form">\n      <div class="row">\n        <div class="attachments-addon hidden">\n          <ul class="attachments-addon-list list-inline"></ul>\n        </div>\n      </div>\n      <div class="clearfix">\n        ';
 if (vectorizator) { ;
__p += '\n        <div class="hidden-xs hidden-sm hidden-md col-xs-2">\n          <div class="dropup dropdown ">\n            <button class="form-control dropdown-toggle text-support-chooser" type="button" id="text-support-dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              <span class="pull-left">' +
((__t = ( _.i18n("common.text-support") )) == null ? '' : __t) +
'</span>\n              <span class="caret pull-right"></span>\n            </button>\n            <ul class="dropdown-menu col-xs-12 text-support-dropdownMenu-data" aria-labelledby="text-support-dropdownMenu">\n              ';
 _.each(textsSupport, function(text) { ;
__p += '\n              <li class="text-support-filter-item">\n                <a data-key="' +
((__t = ( text.code )) == null ? '' : __t) +
'" class="ellipsis" title="' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'" href="#">' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'</a>\n              </li>\n              ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 if (vectorizator) { ;
__p += '\n        <div class="visible-xs visible-md col-xs-1">\n          <div class="dropup dropdown ">\n            <a class="btn btn-link  withripple small-icon-hd dropdown-toggle text-support-chooser" id="text-support-dropdownMenu-xs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              <span ><i class=" vert-align-middle mdi-action-list"></i><span class=" vert-align-middle caret"></span></span>\n            </a>\n            <ul class="dropdown-menu col-xs-12 text-support-dropdownMenu-data" aria-labelledby="text-support-dropdownMenu-xs">\n              ';
 _.each(textsSupport, function(text) { ;
__p += '\n              <li class=" withripple text-support-filter-item">\n                <a data-key="' +
((__t = ( text.code )) == null ? '' : __t) +
'" class="ellipsis" title="' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'" href="#">' +
((__t = (_.i18n( text.code ))) == null ? '' : __t) +
'</a>\n              </li>\n              ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        <div class="col-xs-11 col-lg-7">\n          <div class=" row">\n            <div class="col-xs-2 col-lg-1">\n              <label for="browseAttachFile" class="control-label attach-btn">\n                <a class="col-xs-1 btn btn-fab btn-fab-mini btn-raised btn-sm btn-primary send-new-m-b"><i class="mdi-editor-attach-file"></i></a>\n              </label>\n              <input id="browseAttachFile" multiple type="file" name="filename" accept="*" class="hidden attach-input"/>\n            </div>\n            <div class="col-xs-8 col-lg-10">\n              <textarea class="create-new-message" rows="2" id="textArea" placeholder="' +
((__t = ( _.i18n("common.type.message") )) == null ? '' : __t) +
'"></textarea>\n            </div>\n            <div class="col-xs-2 col-lg-1">\n              <a class="btn btn-fab btn-fab-mini btn-raised btn-sm action-btn send-new-m-b js-send-new-m-b">\n                <i class="mdi-content-send"></i>\n              </a>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

