
define('template!pimProductSelectedAttributeTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12">   \n        <div class="sa"> \n            <div class="sa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>  \n            </div>\n        </div>   \n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-12">\n        <div class="values">           \n            <div class="values-region"></div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

