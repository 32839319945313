/**
 * Created by OLD on 20/08/2015.
 */
define('caccountAddCreditsPopupReturnView',[
    'module',
    'template!caccountAddCreditsPopupSuccessTpl',
    'template!caccountAddCreditsPopupErrorTpl',
    'template!caccountAddCreditsPopupCancelTpl',
    'template!emptyViewTpl',
    'dialogView'
], function(
    module,
    viewSuccessTpl,
    viewErrorTpl,
    viewCancelTpl,
    emptyTpl,
    DialogView
    ) {
    'use strict';

    var AddCreditReturnPopupView = DialogView.extend({

        getTemplate: function(){
            switch(this.type || this.options.type){
                case 'success':
                    return viewSuccessTpl;
                case 'progress':
                    return emptyTpl;
                case 'cancel':
                    return viewCancelTpl;
                default :
                    return viewErrorTpl;
            }
        }
    });


    module.exports = AddCreditReturnPopupView;
});
