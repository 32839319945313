
define('template!orderProofsheetHistoryTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="well jq-grid-table-page">\n    <div class="invoices-table">\n      <div class="loading-block js-loading-block">\n        <span class="loading-message js-loading-message label"></span>\n      </div>\n      <table id="jq-grid-proofsheet-history" role="grid"></table>\n      <div id="jq-grid-pager-proofsheet-history"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

