
define('template!rBchangeStatusTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="m-t-0">\n  <div class="dropdown">\n    <button class="form-control dropdown-toggle" type="button" data-toggle="dropdown"\n            aria-haspopup="true" aria-expanded="false">\n      <span class="pull-left">' +
((__t = ( _.i18n("vectorization.changeStatus") )) == null ? '' : __t) +
'</span>\n      <span class="caret pull-right m-t-5"></span>\n    </button>\n    <ul class="dropdown-menu dropdown-menu-right" >\n        ';
 _.each(ticketStatuses, function(status) { ;
__p += '\n      <li data-state="' +
((__t = ( status.state )) == null ? '' : __t) +
'">\n          ';
 if (status.state === currentState) { ;
__p += '\n        <i class="fa fa-check fa-fw"></i>&nbsp;' +
((__t = ( status.label )) == null ? '' : __t) +
'\n          ';
 } else { ;
__p += '\n        <i class="fa fa-fw"></i>&nbsp;' +
((__t = ( status.label )) == null ? '' : __t) +
'\n          ';
 } ;
__p += '\n      </li>\n        ';
 }) ;
__p += '\n    </ul>\n  </div>\n</div>';

}
return __p
};});

