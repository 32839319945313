define('customerInternalCommentsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'commentsView',
  'template!customerInternalCommentsView',
  'entities/customers/customers_comments'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,  
  CommentsView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customer-comments-view',

    ui: {
      openButton: '.open-button',
      closeButton: '.close-button',
      commentsCounter: '.counter',
      AddCommentButton: '.add-button'
    },

    events: {
      'click @ui.openButton':'onOpen',
      'click @ui.closeButton':'onClose',
      'click @ui.AddCommentButton': 'onAddComment'
    },

    regions: {
      commentsRegion: '.comments-region'
    },

    initialize: function () {
      this.collection = App.request('customercomments:get-collection');
    },

    serializeData: function () {
      var templateData = {};
      templateData.count = this.collection.models.length;
      return templateData;
    },

    onShow: function() {
      //-------------------
      this.commentsView = new CommentsView({
        collection: this.collection,
        add: _.bind(function (commentModel) {
          var model = App.request('customercomments:get-model');
          model.set('comment', commentModel.get('content'));
          model.set('refUserpid', commentModel.get('userpid'));
          model.unset('content');
          model.unset('modDate');
          model.unset('userpid');

          model.save(this.options.secId).done(_.bind(function() {
            this.collection.fetch(this.options.secId);
          }, this)).fail(_.bind(function() {
            this.collection.fetch(this.options.secId);
          }, this));

        }, this),
        remove: _.bind(function (model) {
          model.destroy().done(_.bind(function () {
            this.fetch();
          }, this)).fail(_.bind(function() {
            this.fetch();
          }, this));

        }, this),
        change: _.bind(function (model) {
          model.set('comment', model.get('content'));
          model.set('refUserpid', model.get('userpid'));
          model.unset('content');
          model.unset('modDate');
          model.unset('userpid');

          model.save(this.options.secId).done(_.bind(function() {
            this.fetch();
          }, this)).fail(_.bind(function() {
            this.fetch();
          }, this));

        }, this),
        pin: _.bind(function (model) {
          model.set('pin', !model.get('pin'));
          model.unset('content');
          model.unset('modDate');
          model.unset('userpid');

          model.save(this.options.secId).done(_.bind(function() {
            this.fetch();
          }, this));
        }, this)
      });
      this.getRegion('commentsRegion').show(this.commentsView);

      //-------------------
      this.fetch();
    },

    fetch: function() {
      this.collection.fetch(this.options.secId).done(_.bind(function() {
        if(this.collection.models.length > 0)
          this.$el.addClass('open');
        this.ui.commentsCounter.html(this.collection.length);
      }, this));

      this.collection.sort();
    },

    onOpen: function() {      
      this.$el.addClass('open');
    },

    onClose: function(e) {     
      e.stopPropagation();
      this.$el.removeClass('open');
    },

    onAddComment: function() {
      this.commentsView.add();
    }
  });
});

