/**
 * Created by BFR on 24/02/2020.
 */
define('editPimPrintZoneView',[
  'module',
  'settings',
  'marionette',
  'backbone',
  'jquery',
  'app',
  'underscore',
  'template!editPimPrintZoneTpl',
  'pimUtil',
  'dynamicTextView'
], function (
  module,
  Settings,
  Marionette,
  Backbone,
  $,
  App,
  _,
  viewTpl,
  PimUtil,
  DynamicTextView
) {
  'use strict';

  var EditPimPrintZoneView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'printzone',

    regions: {
      nameRegion: '.pz-name-region'
    },

    onShow: function () {

      if (this.model.get('refImprintTechnology').get('name').get('glossaryDt')) {
        this.nameDtView = new DynamicTextView({
          model: this.model.get('refImprintTechnology').get('name').get('glossaryDt'),
          hideGlossary: true,
          showWebIcon: true
        });
        this.getRegion('nameRegion').show(this.nameDtView);
      }

      var aImages = this.model.get('printPictures');

      if (aImages.length > 0) {
        var url = aImages[0].imageUrl;

        var content = '<div class="img-responsive"><img class="img-popover" src="' + url + '"/></div>';

        var target = this.$el.find('.image img');

        target.attr('data-content', content);
        var popover = target.popover({
          trigger: 'hover',
          placement: 'left',
          html: 'true',
          container: $('#content')
        }).data('bs.popover');
        if (popover) {
          popover.tip().addClass('jqgrid-popover');
        }
      }
    },

    onDestroy: function () {
      $('.jqgrid-popover').remove();
    },

    serializeData: function () {
      var templateData = {};
      var aNames = this.model.get('refImprintTechnology').get('name').get('values');
      templateData.name = PimUtil.getTranslation(aNames);
      templateData.imppCode = this.model.get('refImprintTechnology').get('code');
      templateData.width = this.model.get('width');
      templateData.height = this.model.get('height');
      templateData.setupCode = this.model.get('setupCode');
      templateData.unitCode = this.model.get('unitCode');
      templateData.showFastDelivery = Settings.configValue('showFastDelivery');
      templateData.fastDelivery = this.model.get('fastDelivery');
      templateData.productionTimes = '';
      if (this.model.get('productionTimes') && this.model.get('productionTimes').length) {
        _.each(this.model.get('productionTimes'), function (oProductionTime) {
          templateData.productionTimes += oProductionTime.from + '-' + oProductionTime.to + ': ' +oProductionTime.days + '\n';
        });
      }
      templateData.imageUrl = '';
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').get('colorModeType') === 'spot') {
        templateData.maxColors = this.model.get('maxColors');
      } else {
        templateData.maxColors = '';
      }
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').get('colorModeType') === 'quadri') {
        templateData.digital = true;
      } else {
        templateData.digital = '';
      }
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').get('colorModeType') === 'fixedFilling') {
        templateData.eng = true;
      } else {
        templateData.eng = '';
      }
      var aImages = this.model.get('printPictures');

      if (aImages.length > 0) {
        templateData.imageUrl = aImages[0].imageUrl;
      }

      templateData.allowUpload = false;

      if (this.options.pimProduct.getCurrentResProduct()) {
        templateData.allowUpload = true;
      }

      return templateData;
    }
  });

  module.exports = EditPimPrintZoneView;
});
