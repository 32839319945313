define('imageUploadBehavior2',[
  'module',
  'marionette',
  'imageUtils',
  'imageSelectionPopupView',
  'app',
  'underscore',
  'imageUploadBehavior',
  'entities/images'
], function (
  module,
  Marionette,
  imageUtils,
  ImageSelectionPopupView,
  App,
  _,
  ImageUploadBehavior
) {
  'use strict';

  module.exports = ImageUploadBehavior.extend({
    ui: {
      changeLogo: '.js-change-logo2'
    },

    events: {
      'click .js-logo-holder2': '_onClickLogoHolder',
      'change @ui.changeLogo': '_onChangeLogo'
    }
  });
});
