/**
 * Created by OLD on 23/11/2015.
 */
define('invitationsLayoutView',[
    'module',
    'logger',
    'marionette',
    'underscore',
    'app',
    'settings',
    'template!invitationsLayoutTpl',
    'collectionViewInvited'
], function(
    module,
    Logger,
    Marionette,
    _,
    App,
    Settings,
    viewTpl,
    CollectionViewInvited
) {
    'use strict';

    var InvitationsLayoutView =  Marionette.LayoutView.extend({
        template: viewTpl,

        triggers: {
        },

        ui: {
            btnImportGmailContacts: '.btn-import-contacts',
            emailsInput: '.js-email-input',
            sendButton: '.sendButton',
            rewardConditionsButton: '.reward-conditions',
            invitedList: '.invited-list',
            showMoreBtn: '.show-more'
        },

        events: {
            'click @ui.btnImportGmailContacts': 'onImportGmailContacts',
            'click @ui.sendButton':  'onSendButtonClick',
            'click @ui.rewardConditionsButton': 'rewardConditions',
            'click @ui.showMoreBtn': 'showMoreInvited'
        },

        regions: {
            priceTabContent: '#js-pricepage-content-region'
        },

        serializeData: function() {
            var currentCAccount = Settings.get('currentCAccount'),
                currentUser = Settings.get('currentUser'),
                createAccountUrl = this.getCreateAccountUrl() + '?invitedByCa=' +
                    currentCAccount.publicId +
                    '&invitedByU=' +
                    currentUser.publicId;

            return {
                facebookUrl: 'https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURIComponent(createAccountUrl),
                twitterUrl: 'http://twitter.com/intent/tweet?url=' +
                    encodeURIComponent(createAccountUrl) + '&text=COMPUZZ',
                googlePlusUrl: 'https://plus.google.com/share?url=' +
                    encodeURIComponent(createAccountUrl) + '&title=COMPUZZ',
                linkedInUrl: 'https://www.linkedin.com/shareArticle?mini=true&url=' +
                    encodeURIComponent(createAccountUrl) + '&title=COMPUZZ&summary=&source='
            };
        },

        getCreateAccountUrl: function() {
            var url = Settings.get('compuzzCreateAccountUrl')[Settings.get('lang')];
            if (!url) {
                url = Settings.get('compuzzCreateAccountUrl').en;
            }
            return url;
        },

        onShow: function() {
            $.material.init();
        },

        showInvitedList: function(collection, count) {
            this.numberOfInvited = count;
            this.collectionViewInvited = new CollectionViewInvited({el: this.ui.invitedList});
            this.collectionViewInvited.collection = collection;
            this.collectionViewInvited.render();
            this.showOrHideBtnMoreButton();
        },

        addMoreInvitedToCollection: function(models) {
            this.collectionViewInvited.collection.add(models);
            this.showOrHideBtnMoreButton();
        },

        showOrHideBtnMoreButton: function() {
            if (this.collectionViewInvited.collection.length < this.numberOfInvited) {
                this.ui.showMoreBtn.show();
            } else {
                this.ui.showMoreBtn.hide();
            }
        },

        showMoreInvited: function(e) {
            e.preventDefault();
            this.trigger('showMoreInvited', this.collectionViewInvited.collection.length);
        },

        onDestroy: function() {
            if (this.collectionViewInvited) {
                this.collectionViewInvited.destroy();
            }
        },

        rewardConditions: function(e) {
            e.preventDefault();
            this.trigger('rewardConditionsClicked');
        },

        onSendButtonClick: function(e) {
            e.preventDefault();
            var emails = this.ui.emailsInput.val();
            this.trigger('checkAndSendEmails', emails);
        },

        clearEmailsInput: function() {
            this.ui.emailsInput.val('');
        },

        /*jshint camelcase: false */
        onImportGmailContacts: function(e) {
            e.preventDefault();
            var clientId = Settings.get('googleClientId');
            var scopes = 'https://www.googleapis.com/auth/contacts.readonly';
            window.gapi.auth.authorize({client_id: clientId, scope: scopes, immediate: false}, _.bind(this.handleAuthorization, this));
        },

        /*jshint camelcase: false */
        handleAuthorization: function(authorizationResult) {
            if (authorizationResult && !authorizationResult.error) {
                $.get('https://www.google.com/m8/feeds/contacts/default/thin?alt=json&access_token=' +
                    authorizationResult.access_token + '&max-results=500&v=3.0',
                    _.bind(function(response){
                        var emails = [];
                        //process the response here
                        _.each(response.feed.entry, function(contact) {
                            emails.push({
                                email: contact.gd$email[0].address
                            });
                        });
                        this.trigger('checkGoogleContactsEmails', emails);
                    }, this));
            } else {
                Logger.log(module, 'An error occurred');
            }
        }
    });

    module.exports = InvitationsLayoutView;
});
