define('entities/address',[
    'app',
    'jquery',
    'underscore',
    'backbone'
], function (
    App,
    $,
    _,
    Backbone
) {
    'use strict';

    var Address = Backbone.Model.extend({
        defaults: {
            firstName: '',
            lastName: '',
            name: '',
            tel: '',
            addressL1: '',
            addressL2: '',
            houseNumber: '',
            zip: '',
            city: '',
            countryCode: ''
    }});

    App.reqres.setHandler('address:model', function (data) {
        return new Address(data);
    });

});
