define('ordersDetailSupplierProductView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'ordersDetailSupplierProductInfosView',
  'ordersDetailSupplierProductPrintZonesController',
  'template!ordersDetailSupplierProductView',
  'entities/product'
], function (
  App,
  module,
  Marionette,
  _,
  InfosView,
  PrintZonesController,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'product-view',

    regions: {
      infoRegion: '.info-region',
      printzonesRegion: '.printzones-region'
    },

    onRender: function () {
      if(this.model.get('products') && this.model.get('products').length > 0) {
        this.productModel = App.request('products:model', {code: this.options.order.get('productBaseCode')});
        this.productModel.fetchByCode(true).done(_.bind(function () {
          this.infosView = new InfosView({
            order: this.options.order,
            product: this.productModel,
            model: this.model
          });
          this.getRegion('infoRegion').show(this.infosView);

          //----------------
          this.printZonesController = new PrintZonesController({
            order: this.options.order,
            product: this.productModel,
            model: this.model,
            view: this
          });

          this.getRegion('printzonesRegion').show(this.printZonesController.view);
        } ,this));
      }
    }
  });
});
