/**
 * Created by BFR on 02/03/2020.
 */
define('shareProductCodeVariationView',[
    'module',
    'marionette',
    'app',
    'jquery',
    'underscore',
    'colorUtils',
    'template!shareProductCodeVariationTpl',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    $,
    _,
    ColorUtils,
    viewTpl
  ) {
    'use strict';

  module.exports = Marionette.LayoutView.extend({
      template: viewTpl,

      events: {
        'change .cbx-productcodevariation': 'onSelectionChange'
      },

      onSelectionChange: function() {
        this.trigger('item:selection:change');

        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          this.model.set('productCodeVariation', { });
        } else {
          this.model.set('productCodeVariation', null);
        }
      },

      onItemSelectionChange: function() {
        this.trigger('item:selection:change');
      },

      findCheckedProductCodeVariation: function(code) {
        return _.findWhere(this.options.model.collection.checkedProductCodeVariations, {
          code: code
        });
      },

      checkIfVariationCanBeEnable: function () {
        var canBe = true;
        if (this.options.model.get('parentProductCodeVariation')) {
          _.each(this.options.model.get('parentProductCodeVariation').get('productOptions'), _.bind(function(option) {
            var found = false;
            _.each(this.model.collection.optionCategories.models, function(cat) {
              _.each(cat.get('productOptions').models, function(opt) {
                var parentOption = opt.get('parentOption');
                if (opt.get('option') && parentOption && parentOption.get('id') === option.id) {
                  found = true;
                }
              });
            });
            if (!found) {
              canBe = false;
            }
          }, this));
        }
        return canBe;
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.productCodeVariation?true:false;

        if (templateData.productCodeVariation) {
          templateData.productOptions = templateData.productCodeVariation.productOptions;
        } else {
          templateData.productOptions = templateData.parentProductCodeVariation.productOptions;
        }

        templateData.enabled = this.checkIfVariationCanBeEnable();

        templateData.productOptions = _.sortBy(templateData.productOptions, 'categoryName');

        _.each(templateData.productOptions, function (productOption) {
          productOption.color = (productOption.color1!==undefined && productOption.color1!==null)?
                ColorUtils.decimalToHexa(productOption.color1):
                null;
        });

        if (this.options.model.collection.displayNew &&
            templateData.parentProductCodeVariation &&
            !this.findCheckedProductCodeVariation(templateData.parentProductCodeVariation.code)) {
          templateData.newPcv = true;
        } else {
          templateData.newPcv = false;
        }

        return templateData;
      }
    });
  });
