/**
 * Created by BFR on 21/02/2023.
 */
define('userUtils',[
  'module',
  'underscore'
], function (
  module,
  _
) {
  'use strict';

  var UserUtils = {
    _parseUsers: function (_users) {
      _users = _.sortBy(_users, function (user) {
        return user.firstName.toLowerCase();
      });
      var users = _.map(_users, function (user) {
        if (user.firstName && user.lastName) {
          user.fullName = user.firstName + ' ' + user.lastName;
        } else if (user.firstName || user.lastName) {
          user.fullName = user.firstName ? user.firstName : user.lastName;
        } else if (user.nickName) {
          user.fullName = user.nickName;
        } else {
          user.fullName = user.logonId;
        }
        return user;
      });
      return users;
    }
  };

  module.exports = UserUtils;
});

