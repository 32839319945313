/**
 * Created by RKL on 13/08/2015.
 */
define('caccountFontsTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'stateMessages',
  'template!caccountFontsTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  StateMessages,
  viewTpl
) {
  'use strict';

  var CAccountsFontsTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'caccountFontsFilters',

    triggers: {
      'change @ui.uploadFont': 'onUploadFont'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-fonts',
        jqGridPager: '#jq-grid-pager-fonts',
        uploadFont: '.js-upload-font',
        deleteFont: '.cell-delete',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteFont': 'onDeleteFont'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var defaultsOptions, options;

      this.filtersName = data.type + 'FontsFilters';
      this.sidx = 'userTimestamp.modDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('font.displayName'),
            name: 'displayName',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'displayName'
          }, {
            label: _.i18n('font.style'),
            name: 'style',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'style'
          }, {
            label: _.i18n('font.fontImage'),
            name: 'fontImageUID',
            align: 'left',
            classes: 'cell-link font-image',
            formatter: this.pictureUidFormatter,
            search: false,
            sortable: false
          }, {
            label: '',
            name: '',
            classes: 'delete-link js-delete-font',
            formatter: this.deleteIconFormatter,
            search: false,
            width: 50
          }
        ]
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    showImageZoomPopover: function(fileLink, target) {
      var content = '<div class="img-responsive">' +
        '<img class="img-popover" src="' + Settings.url('base', fileLink) + '"/></div>';

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'left',
        html: 'true',
        container: $('#content')
      });
    },

    onDeleteFont: function(e) {
      var target = $(e.currentTarget),
        fontId = target.data('row-id');

      this.trigger('onDeleteFont', fontId);
    }
  });

  module.exports = CAccountsFontsTabView;
});
