define('ordersDetailOrderProductOptionsView',[
  'module',
  'app',
  'backbone',
  'marionette',
  'underscore',
  'colorUtils',
  'optionsConfigDialogView',
  'ordersDetailOrderProductOptionView',
  'ordersDetailOrderProductMixedOptionView',
  'template!ordersDetailOrderProductOptionsView',
  'entities/orders'
], function (
  Module,
  App,
  Backbone,
  Marionette,
  _,
  ColorUtils,
  Dialog,
  OptionView,
  MixedOptionView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'orders-detail-order-options-view item editable',
    childViewContainer: '.options-container',

    events: {
      'click': 'onEdit'
    },

    modelEvents: {      
      'change:options': 'fetch',
      'change:optionsMix': 'fetch'
    }, 

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    getChildView: function (model) {
      if (model.get('quantity')) {
        return MixedOptionView;
      } else {
        return OptionView;
      }
    },

    fetch: function () {
      this.collection.reset();

      _.each(this.model.get('options'), function (option) {
        this.collection.add(option);
      }, this);

      _.each(this.model.get('optionsMix'), function (option) {
        this.collection.add(option);
      }, this);
    },

    onEdit: function () {
      var options = this.options.product.get('productOptionCategories');

      var filteredOptions = _.filter(options, function (option) {
        if (option.productOptions && option.productOptions.length >= 1) {
          return option;
        }
      });

      this.dialog = new Dialog({
        purchaseOrderId: this.model.get('purchaseOrderId'),
        selectedOptions: this.model.get('options'),
        availableOptions: filteredOptions,
        mixedOptions: this.model.get('optionsMix'),
        options: this.model.get('options'),
        product: this.options.product
      });
      this.dialog.show({title: _.i18n('dialog.offer.changeProductConfig')});
      this.dialog.listenTo(this.dialog, 'options-changed', _.bind(this.onChanged, this));  
    },

    onChanged: function (configuration) {
      var nonMixOptions = [];
      if (configuration.options) {
        //var nonMixOptions = Array.from(configuration.options);
        nonMixOptions = $.map(configuration.options, function (value) {
          return [value];
        });
      }

      if (nonMixOptions.length > 0) {
        $.when(App.request('order:update-options-from-configuration',
          this.model.get('purchaseOrderId'),
          configuration.options
        )).done(_.bind(function () {                         
          this.model.fetch();   
        }, this));
      } else if (configuration.optionsMix) {
        $.when(App.request('order:update-mixoptions-from-configuration',
          this.model.get('purchaseOrderId'),
          configuration.optionsMix
        )).done(_.bind(function () {           
          this.model.fetch();   
        }, this));
      }
      this.dialog.hide();
    }
  });
});
