/**
 * Created by OLD on 7/12/2015.
 */
define('editAttachmentsPluginView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'iframeFullScreen',
  'template!editAttachmentsPluginTpl'
], function(
  module,
  Marionette,
  _,
  App,
  IframeFullScreen,
  layoutTpl
) {
  'use strict';

  var EditAttachmentsPluginView =  Marionette.ItemView.extend({
    template: layoutTpl,

    behaviors: {
      IframeFullScreen: {
        behaviorClass: IframeFullScreen
      }
    },

    onRender: function() {
      this.$el = this.$el.children();
      this.$el.unwrap();
      this.setElement(this.$el);
    },

    serializeData: function() {
      return {
        url: this.options.url,
        title: 'Edit Attachments'
      };
    }
  });

  module.exports = EditAttachmentsPluginView;
});
