
define('template!editEndUserAttachmentsPluginTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content full-height" style="height: 800px;">\n  <div class="well full-height">\n    <h3 class="iframeLabel">' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\n    <div class="iframe-full-screen-container">\n      <label class="btn_open_compuzz_plugin clickable">Click here to open compuzz Plugin</label>\n    </div>\n  </div>\n</div>';

}
return __p
};});

