define('headerAccountView',[
  'app',
  'module',
  'marionette',
  'settings',
  'underscore',
  'imageUtils',
  'autocompleteView',
  'template!headerCaccountAutocompleteTpl',
  'template!headerAccountView'
], function(
  App,
  Module,
  Marionette,
  Settings,
  _,
  ImageUtils,
  AutocompleteView,
  CaccountAutocompleteTpl,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'header-account-view',

    ui: {
      caccountLogo: '.js-caccount-logo',
      accountSwitch: '.js-account-switch',
      accountSwitchDropdownContainer: '.caccounts-dropdown-container',
      accountSwitchDropdown: '.js-account-switch-dropdown'
    },

    events: {
      'click @ui.availableCAccount': 'onSwitchCaccount',
      'click @ui.accountSwitch': 'onShowDropdown',
      'click @ui.accountSwitchDropdownContainer': 'onAccountContainerClick'
    },

    regions: {
      caccountAutocomplete: '.caccounts-dropdown-container'
    },

    _caccountAutocompleteView: undefined,

    initialize: function() {      
      App.on('header:update-caccount', _.bind(this.render, this));
    },

    onDestroy: function() {
      App.off('header:update-caccount');
    },

    serializeData: function() {
      var data = {};

      data.url = '';

      var account = Settings.get('currentCAccount');

      if (account) {
        data.url = ImageUtils.getLogo(
          account.logo,
          account.code, {
            action: 'res',
            params: 't'
          }
        );

        data.name = account.name;
      }

      return data;
    },

    onRender: function () {
      this.getAvailableCaccounts();
    },

    getAvailableCaccounts: function () {

      App.request('caccount:count').done(_.bind(function (count) {
        this.checkAvailableCaccounts(count);
      }, this));
    },

    checkAvailableCaccounts: function (count) {
      if (count > 1) {
        this.renderCaccountsDropdown();
        this.displayDropdown();
      } else {
        this.displayCurrentCaccountInfo();
      }
    },

    displayDropdown: function() {
      this.$('.navbar-brand').removeClass('disabled');
    },

    renderCaccountsDropdown: function () {
      this._caccountAutocompleteView = new AutocompleteView(this.getAccountsAutocompleteOptions());

      this.getRegion('caccountAutocomplete').show(this._caccountAutocompleteView);
      this._caccountAutocompleteView.$('.ac-suggestions.dropdown-menu').removeClass('dropdown-menu ac-suggestions').
      addClass('caccount-hover-dropdown');
      this.displayCurrentCaccountInfo(Settings.get('currentCAccount'));
    },

    getAccountsAutocompleteOptions: function() {
      return {
        type: 'remote',
        alwaysOpen: true,
        remote: Settings.url('compuzz2', 'sessions.json/token/byuser/caccounts', {env: Settings.compuzzEnv}),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectaccount.placeholder'),
        value: null,
        name: 'accounts',
        onPressKey: true,
        callback: _.bind(this.onSwitchCAccount, this),
        childTemplate: CaccountAutocompleteTpl,
        childViewSerializeData: this._autocompleteViewSerializeData,
        lazyLoad: true,
        keys: {
          query: 'caccountCode',
          limit: 'pageSize'
        }
      };
    },

    _autocompleteViewSerializeData: function() {
      var templateData = this.model.toJSON();

      templateData.url = ImageUtils.getLogo(this.model.get('logo'), this.model.get('code'), {
        action: 'res',
        params: 't'
      });

      return templateData;
    },

    onSwitchCAccount: function (fieldName, caccount) {

      var caccountJson = caccount.toJSON();
      var that = this,
          postCaccount = App.request('caccount:post-caccount', caccountJson.secId);

      $.when(postCaccount).done(function (data) {
        Settings.clearMemory();
        Settings.set('tokenId', data.tokenId);
        var caccountTemp = _.extend(caccountJson);
        caccountTemp.refMainCAccountJson = null;
        Settings.set('currentCAccountCookie', JSON.stringify(caccountTemp));
        Settings.set('currentCAccount', caccountJson);
        that.switchCaccount = true;
        App.navigate('', {trigger: true});
      });
    },

    displayCurrentCaccountInfo: function(account) {
      var currentCAccount = account || Settings.get('currentCAccount'),
          refMainCAccountJson = currentCAccount.refMainCAccountJson,
          logo, brandLogo;

      if (currentCAccount) {
        this.currentCAccount = currentCAccount;
        this.ui.caccountLogo.attr('title', currentCAccount.code);
        if (refMainCAccountJson ) {
          brandLogo  = ImageUtils.getLogo(
              refMainCAccountJson.logo,
              refMainCAccountJson.code, {
                action: 'res',
                params: 't'
              }
          );
          this.$('.compuzz_general_logo' ).attr('src', brandLogo );
        } else {
          this.$('.compuzz_general_logo' ).attr('src', Settings.url('base', Settings.get('compuzzLogo')) );
        }
        logo = ImageUtils.getLogo(
            currentCAccount.logo,
            currentCAccount.code, {
              action: 'res',
              params: 't'
            }
        );
        this.ui.caccountLogo.attr('src', logo);
        $('title').html('Compuzz ' + currentCAccount.code + ' Dashboard');
      }
    },

    onShowDropdown: function() {
      var that = this;

      setTimeout(function() {
        if (that._caccountAutocompleteView) {
          that._caccountAutocompleteView.ui.autocomplete.focus();
        }
      }, 10);
    }

  });
});
