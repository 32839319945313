/**
 * Created by GSP on 7/01/2016.
 */
define('itemPagesNameMixin',['module'], function (module) {
  'use strict';

  var ItemPagesNameMixin = {
    ACCOUNT: 'account',
    MY_ACCOUNT: 'account_account',
    GRAPHICAL_ID: 'account_graphicalid',

    //MAILS
    MAILS: 'mails',

    //WEBSHOP
    WEBSHOP: 'webshop',
    WEBSHOP_PRODUCTS: 'webshop_products',
    WEBSHOP_CATEGORIES: 'webshop_categories',

    // PIM
    PIM: 'pim',
    PIM_PRODUCTS: 'pim_products',
    PIM_PRODUCT_ATTRIBUTES: 'pim_product_attributes',

    // PRODUCTS
    PRODUCTS: 'products',
    MY_PRODUCTS: 'products_products',
    PRODUCTS_SUPPLIER: 'products_productsSupplier',
    PRODUCTS_PRICELISTS: 'products_pricelists',
    PRODUCTS_CATALOGUES: 'products_catalogues',

    //CLIENTS
    CLIENTS: 'customers',
    MY_CLIENTS: 'customers_customers',
    MY_LEADS: 'customers_leads',

    //ORDERS
    ORDERS: 'orders',
    ORDERS_BOARD: 'orders_board',
    ORDERS_LIST: 'orders_list',
    ORDERSTATUS: 'orderstatus',
    TRACKING: 'tracking',

    //OFFERS
    OFFERS: 'offers',
    OFFERS_BOARD: 'offers_board',
    OFFERS_LIST: 'offers_list',

    //SUPPLIERS
    SUPPLIERS: 'suppliers',
    SUPPLIERS_CATALOGUES: 'suppliers_catalogues',
    SUPPLIERS_PRODUCTS: 'suppliers_products',

    //GRAPHICAL REQUESTS
    GRAPHICALREQUESTS: 'graphicalRequests',
    GRAPHICAL_SERVICE_KANBAN: 'graphical_service_kanban',
    GRAPHICALREQUESTS_ALL_IN_ONE: 'graphicalRequests_all_In_One',
    GRAPHICALREQUESTS_VECTORIZATION: 'graphicalRequests_vectorization',
    GRAPHICALREQUESTS_FILECONVERSION: 'graphicalRequests_fileConversion',
    GRAPHICALREQUESTS_REMOVEBACKGROUND: 'graphicalRequests_RemoveBackground',

    //ADMIN
    ADMIN: 'admin',
    ADMIN_TRANSLATIONS: 'admin_translations',
    ADMIN_TIMETRACKING: 'admin_timeTracking',
    ADMIN_SUBACCOUNTS: 'subAccountCreator',
    ADMIN_IMPRINT_TECHNOLOGIES: 'admin_imprintTechnologies',
    ADMIN_HTMLTEMPLATES: 'admin_htmlTemplates',
    ADMIN_IMPRINT_GLOSSARIES: 'admin_glossaries',
    ADMIN_WEBHOOK_HISTORY: 'admin_webhookHistory',
    ADMIN_TASKS: 'admin_tasks',
    ADMIN_NOTIFICATIONS: 'admin_notifications',
    ACCOUNTCREATOR: 'ACCOUNTCREATOR',
    ACCOUNTSHARECREATOR: 'ACCOUNTSHARECREATOR',
    TIMETRACKER: 'TIMETRACKER',
    SUPERADMIN_STATS: 'SUPERADMIN_STATS',
    COLLECTIONS: 'COLLECTIONS',
    ADMIN_MY_WEBSITE: 'MYWEBSITE',

    PHONE_CALLS: 'phone_calls',
    PHONE_NUMBERS: 'phone_numbers',

    //SUPER ADMIN
    SUPERADMIN: 'SUPERADMIN',
    SUPERADMIN_OVERVIEW: 'superAdmin_overview',
    SUPERADMIN_CACCOUNTS: 'superAdmin_cAccounts',

    INVITATIONS: 'invitations'
  };

  module.exports = ItemPagesNameMixin;
});
