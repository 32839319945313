define('textareaView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'template!textareaView'
], function (
  Module,
  Marionette,
  _,
  Events,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'textarea-view',

    attributes: function () {
      return {
        'title': this.options.title || ''
      };
    },

    ui: {
      textarea: 'textarea',
      loading: '.loader-2'
    },

    initialize: function (options) {
      this.value = options.value;
      this.focused = false;
    },

    onDestroy: function() {
      Events.pop(this);
    },

    events: {
      'focus @ui.textarea': 'onFocus',
      'blur @ui.textarea': 'onBlur'
    },

    serializeData: function () {
      var data = {};
      data.value = '';
      if(this.options.value)
        data.value = this.options.value;
      return data;
    },

    onRender: function() {
      if(this.options.tabindex)
        this.ui.textarea.attr('tabindex', this.options.tabindex);
    },

    getValue: function () {
      return this.ui.textarea.val();
    },

    setValue: function (value) {
      this.ui.textarea.val(value);
    },

    onFocus: function() {
      Events.push(this);
      this.focused = true;
      //this.ui.textarea.select();
    },

    onBlur: function() {
      Events.pop(this);
      this.focused = false;
    },

    onKeyDown: function(e) {
      if (e.which === 13 && this.focused) {
        return false;
      }
    }
  });
});
  
