define('ordersLabelsEditLabelsView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'underscore',
  'events',
  'settings',
  'ordersLabelsEditLabelView'
], function(
  App,
  Module,
  Marionette,
  Backbone,
  _,
  Events,
  Settings,
  LabelView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'orders-labels-edit-labels-view',
    childView: LabelView,

    attributes: function () {
      return {
        'title': this.options.title || '',
        'tabindex': this.options.tabindex || 0
      };
    },

    events: {
      'focus': 'onFocus',
      'blur': 'onBlur'
    },

    childEvents: {
      'onSelect': 'onSelected'
    },

    childViewOptions: function () {
      return {
        orders: this.options.orders
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.focused = false;
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function() {
      if (Settings.get('cAccountConfig') && Settings.get('cAccountConfig').get('cardColors')) {
        _.each(Settings.get('cAccountConfig').get('cardColors').models, function(color) {
          color.set('selected', false);
          this.collection.push(color);
        }, this);
      }
    },

    onFocus: function() {
      this.focused = true;

      var index = 0;
      _.each(this.collection.models, function(model, modelIndex) {
        if(modelIndex === index)
          model.set('focused', true);
        else
          model.set('focused', false);
      }, this);
    },

    onSelected: function(view, model) {
      this.focused = true;

      var index = this.collection.indexOf(model);
      _.each(this.collection.models, function(model, modelIndex) {
        if(modelIndex === index)
          model.set('focused', true);
        else
          model.set('focused', false);
      }, this);
    },

    onBlur: function() {
      this.focused = false;
      _.each(this.collection.models, function(model) {
        model.set('focused', false);
      }, this);
    },

    onKeyDown: function(e) {
      var index = 0;

      if (e.which === 37 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        _.each(this.collection.models, function(model) {
          if(model.get('focused'))
            index = this.collection.indexOf(model);
        }, this);

        index--;
        if(index < 0)
          index = this.collection.models.length-1;

        _.each(this.collection.models, function(model, modelIndex) {
          if(modelIndex === index)
            model.set('focused', true);
          else
            model.set('focused', false);
        }, this);

        return false;
      }

      if (e.which === 39 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        _.each(this.collection.models, function(model) {
          if(model.get('focused'))
            index = this.collection.indexOf(model);
        }, this);

        index++;
        if(index >= this.collection.models.length)
          index = 0;

        _.each(this.collection.models, function(model, modelIndex) {
          if(modelIndex === index)
            model.set('focused', true);
          else
            model.set('focused', false);
        }, this);

        return false;
      }
    }
  });
});
