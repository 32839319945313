/**
 * Created by OLD on 20/11/2015.
 */
define('fileConversionMain',[
  'module',
  'logger',
  'fileConversionRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var FileConversion = {
    start: function() {
      Logger.log(module, 'starting FileConversionModule');
    },

    stop: function() {
      Logger.log(module, 'stopping FileConversionModule');
    }
  };

  module.exports = FileConversion;
});
