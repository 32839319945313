/**
 * Created by OLD on 25/11/2015.
 */
define('billingDetailTableView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!billingDetailTableTpl'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

var BillingDetailsTableView = JqGridView.extend({
    GRID_MIN_WIDTH: 300,
    template: viewTpl,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-biiling-detail',
        jqGridPager: '#jq-grid-pager-biiling-detail',
        deleteBtn: '.bililing-delete-link .cell-delete',
        editLink: '.bililing-edit-link .cell-edit'
      }, this.gridUi);     
    },

    events: function() {
      return _.extend({
        'click @ui.deleteBtn' : 'onDeletePriceItem',
        'click @ui.editLink' : 'onEditPriceItem'
      }, this.gridEvents);
    },

    pagination: function () {
        return {
            first: 0,
            rowCount: 0,
            pageSize: 99,
            currentPage: 1
        };
    },

    gridOptions: function(data) {
      var defaultsOptions, options,
        tabName = this.options.tabName,
        _colModel = [{
          label: '',
            name: 'secId',
            key: true,
            hidden: true
          },
          {
            label: _.i18n('popup.price.quantity'),
            name: 'quantity',
            search: false,
            classes: 'billing-detail-link',
            formatter: this.defaultFormatter,
            width: 25
          },
          {
            label: _.i18n('popup.price.costPrice'),
            name: 'costPrice1',
            search: false,
            classes: 'billing-detail-link',
            formatter: this.defaultFormatter,
            width: 25
          },
          {
            label: _.i18n('popup.price.leadTime'),
            name: 'leadTime1',
            classes: 'billing-detail-link',
            search: false,
            formatter: this.defaultFormatter,
            width: 25
          },
          {
            label: _.i18n('popup.price.rsPrice'),
            name: 'rsPrice1',
            search: false,
            classes: 'billing-detail-link',
            formatter: this.defaultFormatter,
            width: 25
          },
          {
            label: '',
            name: '',
            classes: 'bililing-edit-link',
            formatter: this.editIconFormatter,
            search: false,
            width: 8
          }];
      
      if (tabName === 'pricelists') {
        _colModel.push({
          label: '',
          name: '',
          classes: 'bililing-delete-link',
          formatter: this.refreshIconFormatter,
          search: false,
          width: 8
        });
      } else {
        _colModel.push({
          label: '',
          name: '',
          classes: 'bililing-delete-link',
          formatter: this.deleteIconFormatter,
          search: false,
          width: 8
        });
      }
      defaultsOptions = _.extend(this.gridInitOptions(data),
        {pgbuttons: false,     // disable page control like next, back button
          pgtext: null,         // disable pager text like 'Page 0 of 10'
          viewrecords: false,
          rowNum: -1});
      options = {colModel: _colModel};
      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onDeletePriceItem: function(e) {
      var rowId = $(e.currentTarget).attr('data-row-id');

      this.trigger('pricelists:delete-priceItem', rowId);
    },

    onEditPriceItem: function(e) {
      var rowId = $(e.currentTarget).attr('data-row-id');

       this.trigger('pricelists:update-item', rowId);
    },

    refreshIconFormatter: function(cellValue, options, row) {
      if (row.listSecId) {
        return '<span class="mdi-action-cached cell-delete" ' +
        'data-row-id="' + options.rowId + '"></span>';
      } else {
        return '<span></span>';
      }
    }
  });

  module.exports = BillingDetailsTableView;
});
