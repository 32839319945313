define('ordersDetailOrderStatusTrackingsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'ordersDetailOrderStatusTrackingView',
  'template!ordersDetailOrderStatusTrackingsView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  OrdersDetailOrderStatusTrackingView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'orders-detail-order-status-trackings-view',
    childView: OrdersDetailOrderStatusTrackingView,
    childViewContainer: '.trackingContainer',
    childViewOptions: function () {
      return {
        order: this.model,
        customer: this.options.customer
      };
    },

    modelEvents: {
      'change:nbrPackSentToPrinter': 'render',
      'change:nbrPackDeliveredToPrinter': 'render',
      'change:nbrPackSent': 'render',
      'change:nbrPackDelivered': 'render',
      'change:expShippingDate': 'render',
      'change:deadlineDate': 'render'
    },

    ui: {
      'empty': '.empty'
    },

    initialize: function () {     
      this.collection = new Backbone.Collection();
    },

    onRender: function () {
      this.collection.reset();
      this.fetch().done(_.bind(function (models) {
        this.collection.add(models);

        if(models.length > 0) 
          this.ui.empty.hide();
      } ,this));
    },

    fetch: function() {

      var defer = $.Deferred(); 

      var params = {
        first: 0,
        pageSize: 100
      };

      App.request('tracking-tab:list',
          this.model.get('poi'),
          params).done(_.bind(function (items) {
        var models = [];

        _.each(items, _.bind(function (item) {
          models.push(App.request('tracking-tab:new-model', item));
        }, this));

        defer.resolve(models);
      }, this));

      return defer.promise();
    }
  });
});
