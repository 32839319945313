define('emailAttachmentImageView',[
  'module',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentImageView'
], function (
  Module,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-image-view',

    ui: {
      image: '.image-ctn img',
      loader: '.loader-2',
      error: '.error'
    },

    modelEvents: {
      'change:base64': 'render',
      'change:error': 'render'
    },

    events: {
      'click': 'onClick'
    },
    
    serializeData: function () {
      return {
        src: 'data:image/png;base64, ' + this.model.get('base64')
      };
    },

    onRender: function () {
      if(this.options.zoom === true)
        this.$el.addClass('zoom');

      if(this.model.get('base64')) {
        this.ui.image.css('display', 'flex');
        this.ui.loader.hide();
      }

      if (this.model.get('error')) {
        this.ui.loader.hide();
        this.ui.error.css('display', 'flex');
      }

      if (this.model.get('loaded') === false)
        this.ui.loader.css('display', 'flex');
      else
        this.ui.loader.hide();
    },

    onClick: function () {
      if(this.options.zoom === true)
        return;

      require([
        'windows',
        'emailAttachmentImageView'
      ], _.bind(function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: this.model,
              zoom: true
            });
          }, this),
          modal: true,
          title: this.model.get('name'),
          width: '60%',
          height: '80%'
        });
      }, this));
    }
  });
});
  
