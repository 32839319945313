/**
 * Created by OLD on 06/08/2015.
 */
define('myWebSiteShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'backbone',
  'myWebSiteShowView',
  'settings',
  'entities/mywebsite'
], function(
  module,
  Logger,
  _,
  App,
  Backbone,
  View, Settings
) {
  'use strict';

  var MyWebSiteController = {
    showMyWebSite: function() {
      var that = this,
        myWebSiteRequest = App.request('my-website:get-data'),
        view;

      $.when(myWebSiteRequest)
        .done(function(response) {
          view = new View();
          view.model = new Backbone.Model(response.toJSON());
          that.model = view.model;
          that.view = view;
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          view.listenTo(view, 'onSave', _.bind(that.onSave, that));
        });      
    },

    onRender: function() {
      this.view.render();      $.material.init();
      this.view.initTokenField();
    },

    onSave: function () {
      var that = this,
          formData = new FormData(),
          myWebSiteRequest;

      _.each(this.view.fd, function (value, key) {
        if (key === 'advancedProperties') {
          //advancedProperties is a whole object and must be stringified
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });

      myWebSiteRequest = App.request('my-website:post-data', formData);
      $.when(myWebSiteRequest)
          .done(function (response) {
            that.model.attributes = response;
            Settings.get('currentCAccount').subDomain = that.model.get('subdomain');
            that.onRender();
          });
    }
  };

  module.exports = MyWebSiteController;
});
