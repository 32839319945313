
define('template!optionsConfigDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="container-fluid">\n    <div class="container-fluid">\n        <div class="form-product-config-container form-horizontal col-xs-12">\n            <div class="form-inputs-container">\n                    ';
 if (mixable) { ;
__p += '\n                        <div class="mix-table table-responsive row padding-all-0">\n                            <div class="row mix-table-body">\n\n                            </div>\n                            <div class="btn btn-orange custom-second-color add-mix-line">\n                                <i class="glyphicon glyphicon-plus"></i>\n                                ' +
((__t = ( _.i18n('perso.addOption') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    ';
 } ;
__p += '\n                ';
 if (!mixable) { ;
__p += '\n                ';
 for (var i = 0 ; i < availableOptions.length ; i++) { ;
__p += '\n\n                <div class="form-group" data-field-name="option-category-' +
((__t = ( i )) == null ? '' : __t) +
'" style="height:40px;">\n                    <label class="col-xs-6 control-label">' +
((__t = ( availableOptions[i].name )) == null ? '' : __t) +
'</label>\n                    <div class="dropdown col-xs-6">\n                        <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-changeoption-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n                            ';
if (availableOptions[i].selected) {;
__p += '\n                            <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">' +
((__t = ( availableOptions[i].selected.name )) == null ? '' : __t) +
'</span>\n                            ';
 } else { ;
__p += '\n                            <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n                            ';
} ;
__p += '\n                            <span class="caret pull-right m-t-5 dropdown__caret"></span>\n                        </button>\n                        <ul class="dropdown-menu dropdown-menu-option" role="menu" aria-labelledby="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'" style="width: initial">\n                            ';
 for (var j = 0 ; j < availableOptions[i].productOptions.length ; j++) { ;
__p += '\n                            <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                       data-option-id="' +
((__t = ( availableOptions[i].productOptions[j].id )) == null ? '' : __t) +
'"\n                                                       href="#">' +
((__t = ( availableOptions[i].productOptions[j].name )) == null ? '' : __t) +
'\n                            </a></li>\n                            ';
 } ;
__p += '\n                        </ul>\n                    </div>\n                </div>\n                ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n\n    <!-- SAVE / CANCEL -->\n    <div class="buttons-container">\n        <div class="form-group pull-right">\n            <a class="btn btn btn-s-md-cancel btn-primary " data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n            <a class="btn btn btn-s-md-save btn-primary js-confirm btn-s-md-save">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

