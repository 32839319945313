/**
 * Created by RKL on 13/08/2015.
 */
define('caccountOfferStatusTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'caccountOfferStatusTabView',
  'orderStatusCreateEditLayoutView',
  'entities/caccounts/orderstatus'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  CreateEditLayoutView
) {
  'use strict';

  var CAccountsOfferStatusTabController = {
    showTab: function(data) {
      this.secId = data.type === 'customer' ? data.id : null;
      this.data = data;

      this.view = new View();

      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'onCreateOfferStatus', _.bind(this.onCreateOfferStatus, this));
      this.view.listenTo(this.view, 'onDelete', _.bind(this.onDeleteOfferStatus, this));

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
    },

    onGridLoaded:function() {
      var data = {
          url: App.request('orderstatus-tab:get-url', this.secId, 'offer'),
          rowCountUrl: App.request('orderstatus-tab:row-count-url', this.secId, 'offer'),
          type: this.data.type
        };

      this.view.displayGrid(data, true);
    },

    onCreateOfferStatus: function() {
        var createEditView = new CreateEditLayoutView({
            orderStatusModel: App.request('orderstatus-tab:new-model', {tag: 'offer'}),
            newItem: true
        });
        createEditView.show({
            title: _.i18n('offerstatus.new')
        });
    },

    /*onEditOfferStatus: function(model) {
      var createEditView = new CreateEditLayoutView({
          offerStatusModel: App.request('offerstatus-tab:new-model')
      });
      createEditView.show({
          title: _.i18n('offerstatus.edit')
      });
    },*/

    onDeleteOfferStatus: function(offerStatusId) {
      var that = this,
        deleteRequest;

      bootbox.confirm(_.i18n('warning.deleteOfferStatus'), function (result) {
        if (result) {
          deleteRequest = App.request('orderstatus-tab:delete', offerStatusId);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination('reloadGrid');
          });
        }
      });
    }
  };

  module.exports = CAccountsOfferStatusTabController;
});

