define('contentShowController',[
  'module',
  'jquery',
  'underscore',
  'app',
  'settings',
  'contentView',
  'notificationCenter',
  'compuzzProUtils',
  'caccountCreditsAccountFormPopupView',
  'rolesMixin',
  'entities/orders',
  'entities/caccounts',  
  'entities/caccounts/config'
], function (
  module,
  $,
  _,
  App,
  Settings,
  ContentView,
  NotificationCenter,
  CompuzzProUtils,
  AccountFormPopupView,
  RolesMixin,
  Orders
) {
  'use strict';

  var ContentLayoutController = {
    showContent: function (closeOrder=true) {

      if(closeOrder)
        Orders.closeOrder(false);
      
      var that = this,
        defer = $.Deferred(),
        fetchRoles, currentCAccount = Settings.get('currentCAccount'),
        needToEncodeVat, currentUser = Settings.get('currentUser');

      if (App.regions &&
        _.isUndefined(App.regions.getRegion('main').currentView) ||
        (App.regions.getRegion('main').currentView &&
          _.isUndefined(App.regions.getRegion('main').currentView.getRegion)) ||
        _.isUndefined(App.regions.getRegion('main').currentView.getRegion('content')) ||
        this.switchCaccount || App.getCurrentRoute() === '') {

        this.switchCaccount = false;
        if (currentCAccount && currentUser) {
          var contentView = new ContentView();
          var configModel = App.request('caccount-config:model', {secId: Settings.get('currentCAccount').secId});
          configModel.fetch().done(_.bind(function () {
            Settings.set('cAccountConfig', configModel);
            fetchRoles = App.request('caccount:get-menu-roles');
            $.when(fetchRoles).done(_.bind(function (resp) {

              if (resp && _.isFunction(resp.toJSON)) {
                Settings.set('cAccountRoles', that.parseRoles(resp.toJSON()));
              }

              NotificationCenter.getInstance().subscribe(currentCAccount.secId);

              if (!Settings.compuzzAwsWsSend ||
                Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] ||
                Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS]) {
                CompuzzProUtils.getInstance().subscribe(Settings.get('tokenId'));
              }

              App.regions.getRegion('main').show(contentView);
              
              App.trigger('sidebar:show');

              App.off('sidebar:resize');
              App.on('sidebar:resize', contentView.onResizeSidebar, contentView);
              App.off('sidebar:switch');
              App.on('sidebar:switch', _.bind(contentView.onSidebarShow, contentView));

              App.off('switchCaccount');
              App.on('switchCaccount', _.bind(that.onSwitchCAccount, that));
              $(window).off('resize');
              $(window).on('resize', _.bind(contentView.onResizeWindow, contentView));

              needToEncodeVat = App.request('caccount:need-to-encode-vat');
              $.when(needToEncodeVat).done(_.bind(function (resp) {
                if (resp) {

                  if (!Settings.get('countries')) {
                    App.request('caccount:countries').done(_.bind(function (countries) {
                      Settings.set('countries', countries.toJSON());
                      this.displayPopupCAccountInfo(defer, currentCAccount);
                    }, this));
                  } else {
                    this.displayPopupCAccountInfo(defer, currentCAccount);
                  }
                } else {
                  defer.resolve();
                }
              }, this)).fail(function () {
                defer.resolve();
              });
            }, this));
          }, this));
        } else {
          Settings.set('lastUrlPageCookie', App.getCurrentRoute());
          App.clearAuthCookieValues();
          App.navigate('login', {trigger: true});
        }
      } else {
        App.regions.getRegion('main').currentView.triggerMethod('before:render');
        defer.resolve();
      }

      return defer.promise();
    },

    displayPopupCAccountInfo: function (defer, currentCAccount) {
      var accountFormPopupView = new AccountFormPopupView({
        model: App.request('caccount:model', currentCAccount),
        countries: Settings.get('countries'),
        first: true
      });

      accountFormPopupView.listenTo(accountFormPopupView, 'saved', _.bind(this.onAccountSaved, this));
      accountFormPopupView.listenTo(accountFormPopupView, 'error', _.bind(this.onAccountSaveError, this));
      accountFormPopupView.listenTo(accountFormPopupView, 'firstRequest:start', _.bind(this.onFirstRequestStart, this));
      accountFormPopupView.show({
        title: _.i18n('account.profile.billingInfo'),
        className: 'billingConfirm'
      });

      this.accountFormPopupView = accountFormPopupView;
      defer.resolve();
    },

    onFirstRequestStart: function () {
      App.navigate('/graphical_service_all_in_one/new', {trigger: true});
      this.accountFormPopupView.hide();
    },

    onAccountSaved: function (model) {
      var account = model.toJSON();
      Settings.set('currentCAccountCookie', JSON.stringify(account));
      Settings.set('currentCAccount', account);
      App.trigger('header:update-caccount');
      App.trigger('caccount:header:rerender', account);
      App.trigger('caccount:tab-profile:update-caccount', {cAccountId: model.get('secId')});
    },

    onAccountSaveError: function (error) {
      if (error.status === 406 && error.responseJSON) {
        this.accountFormPopupView.onSaveError(error.responseJSON);
      }
    },

    parseRoles: function (cAccountRoles) {
      var roles = {};
      if (cAccountRoles) {
        _.each(cAccountRoles, function (role) {
          if (role && _.isFunction(role.toLowerCase)) {
            roles[role] = true;
          }
        });
      }
      return roles;
    },

    onSwitchCAccount: function (caccount) {
      var that = this,
        postCaccount = App.request('caccount:post-caccount', caccount.secId);

      $.when(postCaccount).done(function (data) {
        Settings.clearMemory();
        Settings.set('tokenId', data.tokenId);
        var caccountTemp = _.extend(caccount);
        caccountTemp.refMainCAccountJson = null;
        Settings.set('currentCAccountCookie', JSON.stringify(caccountTemp));
        Settings.set('currentCAccount', caccount);
        that.switchCaccount = true;
        App.navigate('', {trigger: true});
      });
    }
  };

  module.exports = ContentLayoutController;
});
