/**
 * Created by BFR on 24/02/2020.
 */
define('translationCatView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!translationCatTpl',
    'dynamicTextView',
    'translationOptionsView'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView,
    TranslationOptionsView
  ) {
    'use strict';
  
    var TranslationCatView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        nameRegion: '.name-region',
        translationOptions: '.translation-options'
      },

      onShow: function () {
        this.nameDtView = new DynamicTextView({ model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);

        this.translationOptionsView = new TranslationOptionsView({ collection: this.model.get('productOptions')});
      this.getRegion('translationOptions').show(this.translationOptionsView);
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = TranslationCatView;
  });
