define('webshopProductsDetailBodySlugsLanguageHistoryToolsView',[
  'app',
  'module',
  'underscore',
  'tableFieldView',
  'template!webshopProductsDetailBodySlugsLanguageHistoryToolsView',
  'entities/webshop/products'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-slugs-language-history-tools-view ' + FieldView.prototype.className,

    ui: {
      deleteButton: '.delete-button'
    },

    events: {
      'click @ui.deleteButton': 'onDelete'
    },

    onDelete: function (e) {
      e.stopPropagation();
      App.request('webshop.product.history.delete',
          this.tableView.model.getId(),
          this.tableView.options.lang,
          this.model.get('slug')).done(_.bind(function () {
        this.tableView.bodyView.collection.remove(this.model);
        this.tableView.footerView.fetch();
      }, this));
    }
  });
});
