define('productGridCollectionView',[
  'module',
  'marionette',
  'template!productGridItemTpl',
  'imageUtils',
  'app'
], function(
  module,
  Marionette,
  productGridItemTpl,
  imageUtils,
  App
) {
  'use strict';

  var GridItemView = Marionette.ItemView.extend({
    template: productGridItemTpl,
    className: 'display-inline-block col-sm-6 col-md-4 col-lg-3 col-xxl-2',
    events: {
      click: '_onClick'
    },
    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.imageUrl = imageUtils.getLogo(this.model.get('cataloguePictureUid'), this.model.get('code'));

      return templateData;
    },

    _onClick: function() {
      App.navigate('product/' + this.model.get('secId'), {trigger: true});
    }
  });

  var GridCollectionView = Marionette.CollectionView.extend({
    childView: GridItemView
  });

  module.exports = GridCollectionView;
});
