/**
 * Created by BFR on 13/07/2022.
 */
define('pluginUtils',[
  'module',
  'app',
  'bootbox',
  'jquery',
  'underscore',
  'settings'
], function (module,
             App,
             bootbox,
             $,
             _,
             Settings) {
  'use strict';


  module.exports = {
    callPlugin: function (params) {
      params.tokenId = Settings.get('tokenId');
      params.lang = Settings.get('lang');
      params.isAdmin = 'true';
      var pluginUrl = Settings.url('pluginUrl', '', params);

      $.ajax({
        method: 'get',
        url: pluginUrl,
        success: _.bind(function () {

        }, this),
        error: function () {
          bootbox.alert('Compuzz plugin is not running or is outdated \n It can be downloaded on http://web.compuzz.com/app/plugin/');
        }
      });
    }
  };
});
