define('phoneCallsRowToolsView',[
  'app',
  'module',
  'underscore',
  'dateUtils',
  'phoneCallsRecordView',
  'tableFieldView',
  'template!phoneCallsRowToolsView'
], function (
  App,
  Module,  
  _,
  DateUtils,
  RecordView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'phone-calls-row-tools-view',

    ui: {
      listenButton: '.listen-button',
      callButton: '.call-button'
    },

    events: {
      'click @ui.listenButton': 'onListen',
      'click @ui.callButton': 'onCall'
    },

    onListen: function () {
      App.request('calls:records', this.model.get('secId')).done(_.bind(function (record) {
        //window.open(record.value, '_blank');

        var title = '';
        if(this.model.get('type') === 'INBOUND')
          title = _.i18n('calls.inbound');

        if(this.model.get('type') === 'OUTBOUND')
          title = _.i18n('calls.outbound');

        title += ' | ' + DateUtils.toMDYHHMMFormat(this.model.get('callDate'));
        title += ' | ' + this.model.get('customerPhoneNumber');

        if(this.model.get('refEndUser')) {
          if (this.model.get('refEndUser').code)
            title += ' | ' + this.model.get('refEndUser').code;
        }

        RecordView.prototype.open({
          url: record.value,
          title: title
        });
      }, this));
    }
  });
});
