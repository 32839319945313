
define('template!productShareTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="row">\n        <div class="col-xs-12 col-md-9">\n            <div class="col-xs-6">\n                <h2 class="col-xs-12">Print areas</h2>\n            </div>\n            <div class="col-xs-6">\n                <h2 class="col-xs-12">Print zones</h2>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="share-printareas col-xs-12 col-md-9">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12 col-md-9">\n            <div class="col-xs-6">\n                <h2 class="col-xs-12">Option categories</h2>\n            </div>\n            <div class="col-xs-6">\n                <h2 class="col-xs-12">Options</h2>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="share-optioncategories col-xs-12 col-md-9">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12 col-md-9">\n            <div class="col-xs-12">\n                <h2 class="col-xs-12">Product code variations</h2>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="share-productcodevariations col-xs-12 col-md-9">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-7">\n            <div class="pull-right p-r-15">\n                <button disabled class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n                <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n                ';
 if (product.toUpdateFromSupplier) { ;
__p += '\n                    <button class="btn btn-primary js-confirm btn-checked">' +
((__t = ( _.i18n('common.checked') )) == null ? '' : __t) +
'\n                    </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n    <div class="loading-block js-loading-block loader"></div>\n</div>';

}
return __p
};});

