define('tabsView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore', 
  'rolesMixin',
  'tabsTabView'
], function (
  App,
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  RolesMixin,
  TabView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'tabs-view',
    childView: TabView, 

    childEvents: {
      'select': '_onSelect'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();      
    },

    onShow: function () {
        this.fetch();
    },

    fetch: function () {},

    setSelected: function (tabName) {
      var found = false;
      this.collection.each(function (model) {
        model.set('selected', model.get('name') === tabName);
        if (model.get('selected'))
          found = true;
      });

      if(!found) {
        this.collection.each(function (model) {
          model.set('selected', model.get('defaultSelected') === true);
          if (model.get('defaultSelected'))
            found = true;
        });
      }

      if(!found) {
        if(this.collection.length > 0)
          this.collection.at(0).set('selected', true);
      }
    },

    getSelected: function () {
      return this.collection.findWhere({selected: true});
    },

    _onSelect: function (view) {
      this.setSelected(view.model.get('name'));
      this.trigger('select', view.model);
    },

    onSelect: function () {}
  });
});
