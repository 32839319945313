
define('template!editPimVariationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var cssDisabled = titleDisabled = '';
  if(activated == false) {
    cssDisabled = 'bg-danger';
    titleDisabled = 'This variation is disabled because the option #'+ optionCode + ' is.';
  }
;
__p += '\n<div class="variation ' +
((__t = ( cssDisabled )) == null ? '' : __t) +
'" style="position: relative;" title="' +
((__t = ( titleDisabled )) == null ? '' : __t) +
'">\n  <div class="row">\n    <div class="col-xs-4 var">\n      <div class="var">\n        <div class="row">\n          <div class="col-xs-9">\n            <div class="var-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="var-info">\n              <div class="var-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n        </div>\n        <div class="row">\n          <div class="col-xs-12 m-l-5 p-b-5">\n            <div class="var-actions">\n              <label for="uploadVariationImage-' +
((__t = ( code )) == null ? '' : __t) +
'" class="upload-title">\n                <i class="mdi-file-file-upload clickable"\n                   title="Upload an additional image for this variation. It will be available for all resellers.">\n                </i>\n              </label>\n              <input id="uploadVariationImage-' +
((__t = ( code )) == null ? '' : __t) +
'" data-key="file" data-code="' +
((__t = ( code )) == null ? '' : __t) +
'" multiple type="file" name="filename"\n                     class="hidden js-upload-variation-image f-my-ws" accept="image/*"/>\n            </div>\n            <div class="var-addImages">\n              ';
 _.each(images, function (image) { ;
__p += '\n              <div class=\'image context-menu-option-variation-add-images\'>\n                <img data-uid="' +
((__t = ( image.uid )) == null ? '' : __t) +
'" data-productId="' +
((__t = ( productId )) == null ? '' : __t) +
'" data-variationId="' +
((__t = ( code )) == null ? '' : __t) +
'"\n                     class="jq-picture js-image-cell p-r-5" src="' +
((__t = ( image.url )) == null ? '' : __t) +
'"/>\n              </div>\n              ';
 });;
__p += '\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-8 options">\n      <div class="options">\n        <div class="options-title">\n          Options\n        </div>\n        <div class="row options-header">\n          <div class="col-xs-2 code">Code</div>\n          <div class="col-xs-4 name">Name</div>\n          <div class="col-xs-2 color">Color</div>\n          <div class="col-xs-4 images">Images</div>\n        </div>\n        <div class="options-region"></div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

