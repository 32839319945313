/**
 * Created by ASK on 28/0/2021.
 */
define('pimAttributesController',[
  'module',
  'app',
  'pimAttributesView'
], function (
  module,
  App,
  View
) {
  'use strict';

  var PimAttributesController =
  {
    showAttributes: function() {
      this.view = new View();      
      
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    }
  };

  module.exports = PimAttributesController;
});
