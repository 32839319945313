
define('template!caccountAdminsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="well jq-grid-table-page">\n    <div class="vectorization-table">\n      <div class="form-group add-new-block">\n        <div class="btn js-add-admin add-btn-text">\n          <i class="mdi-content-add icon"></i>\n          <span>' +
((__t = ( _.i18n('admins.new') )) == null ? '' : __t) +
'</span>\n        </div>\n      </div>\n      <table id="jq-grid-admins" role="grid"></table>\n      <div id="jq-grid-pager-admins"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

