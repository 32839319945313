/**
 * Created by dev on 14-12-15.
 */
define('entities/catalogues',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function (App,
             $,
             Backbone,
             Settings) {
  'use strict';

  /**
   * Model
   */
  var Catalogue = Backbone.Model.extend({
      defaults: {
          image: {
              content: '',
              extension: '',
              name: ''
          },
          code: '',
          name: ''
      },

      getUrl: function (secId) {
      return Settings.url('compuzz', 'catalogues.json/secId/' + secId);
    },

    updateCatalogueImage: function(fileInfo) {
      var that = this,
        url = Settings.url('compuzz', 'catalogues.json/' + this.get('secId') + '/changePicture');

      that.trigger('request');
      $.ajax({
        url: url,
        method: 'POST',
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        success: function(resp) {
          that.set(resp);
          that.trigger('sync');
        }
      });
    },

     saveModel: function() {
          this.url = Settings.url('compuzz', 'catalogues.json');
          this.save();
     },


      idAttribute: 'secId'
  });


  /**
   * Collection
   */
  var CatalogueCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz', 'catalogues.json/');
    },
    model: Catalogue,
    comparator: 'code'
  });

  /**
   * REST API
   */
  var API = {
    getCatalogueEntities: function () {
      var cAccounts = new CatalogueCollection({}),
        defer = $.Deferred();

      cAccounts.url = Settings.url('compuzz', 'catalogues.json/all');
      cAccounts.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    shareCatalogue: function (secId, emails) {
      var url = Settings.url('compuzz', 'catalogues.json/mine/share?secId=' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        data: emails,
        processData: false,
        contentType: false,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    deleteCatalogue: function (secId) {
      var url = Settings.url('compuzz', 'catalogues.json/' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    removeShare: function (secId) {
      var url = Settings.url('compuzz', 'catalogues.json/shared/removeShare?catalogueSecId=' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    acceptShare: function (secId) {
      var url = Settings.url('compuzz', 'catalogues.json/shared/addShare?catalogueSecId=' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    },

    getMineCatalogueCollection: function () {
      var catalogues = new CatalogueCollection({});
      catalogues.url = Settings.url('compuzz', 'catalogues.json/mine');
      return catalogues;
    },

      deleteItemFromCatalogue: function (catalogueSecId, itemType, itemSecId) {
          var url = Settings.url('compuzz', 'catalogues.json/secId/'+catalogueSecId+'/addItem/'+itemType+'/'+itemSecId),
              defer = $.Deferred();

          $.ajax({
              type: 'DELETE',
              url: url,
              success: function (data) {
                  defer.resolve(data);
              }
          });

          return defer.promise();
    },

    getMineCatalogueCollectionUrl: function () {
      return Settings.url('compuzz', 'catalogues.json/mine');
    },

    getMineRowCountUrl: function () {
      return 'catalogues.json/mine/rowCount';
    },

    getSharedCatalogueCollection: function () {
      var catalogues = new CatalogueCollection({});
      catalogues.url = Settings.url('compuzz', 'catalogues.json/shared');
      return catalogues;
    },

    getSharedCatalogueCollectionUrl: function () {
      return Settings.url('compuzz', 'catalogues.json/shared');
    },

    getSharedRowCountUrl: function () {
      return 'catalogues.json/shared/rowCount';
    },

    getCatalogueModel: function (catalogueSecId) {
      var catalogue = new Catalogue(),
          defer = $.Deferred();

      catalogue.url = catalogue.getUrl(catalogueSecId);
      catalogue.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },

    postCatalogue: function (secId) {
      var url = Settings.url('compuzz', 'catalogues.json/' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('catalogue:entities', function () {
    return API.getCatalogueEntities();
  });

  App.reqres.setHandler('catalogue:share', function (secId, emails) {
    return API.shareCatalogue(secId, emails);
  });

  App.reqres.setHandler('catalogue:acceptShare', function (secId) {
    return API.acceptShare(secId);
  });

  App.reqres.setHandler('catalogue:delete', function (secId) {
    return API.deleteCatalogue(secId);
  });

  App.reqres.setHandler('catalogue:entity', function (catalogueSecId) {
    return API.getCatalogueModel(catalogueSecId);
  });

  App.reqres.setHandler('catalogue:mine:row-count-url', function () {
    return API.getMineRowCountUrl();
  });

  App.reqres.setHandler('catalogue:mine:get-collection', function () {
    return API.getMineCatalogueCollection();
  });

  App.reqres.setHandler('catalogue:item:delete', function (catalogSecId, itemType, itemSecId) {
    return API.deleteItemFromCatalogue(catalogSecId, itemType, itemSecId);
  });

  App.reqres.setHandler('catalogue:mine:get-collection-url', function () {
    return API.getMineCatalogueCollectionUrl();
  });

  App.reqres.setHandler('catalogue:shared:row-count-url', function () {
    return API.getSharedRowCountUrl();
  });

  App.reqres.setHandler('catalogue:shared:get-collection', function () {
    return API.getSharedCatalogueCollection();
  });
  App.reqres.setHandler('catalogue:shared:delete', function (secId) {
    return API.removeShare(secId);
  });

  App.reqres.setHandler('catalogue:shared:get-collection-url', function () {
    return API.getSharedCatalogueCollectionUrl();
  });

  App.reqres.setHandler('catalogue:post-catalogue', function (secId) {
    return API.postCatalogue(secId);
  });

  App.reqres.setHandler('catalogue:model', function (catalogueJSON) {
    return new Catalogue(catalogueJSON);
  });

  App.reqres.setHandler('catalogue:mine:shared-to:url', function(catalogueSecId) {
    return Settings.url('compuzz', 'catalogues.json/mine/' + catalogueSecId + '/sharedTo');
  });

  App.reqres.setHandler('catalogue:mine:shared-to:count-url', function(catalogueSecId) {
    return 'catalogues.json/mine/' + catalogueSecId + '/sharedTo/rowCount';
  });

  App.reqres.setHandler('catalogue:mine:add-new:get-model', function() {
    return new Catalogue();
  });

});
