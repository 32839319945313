define('ordersValidationView',[
  'module',
  'marionette',
  'underscore',
  'bootbox',
  'settings',
  'ordersController',
  'template!ordersValidationView'
], function (
  Module,
  Marionette,
  _,
  Bootbox,
  Settings,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-validation-view',    

    events: {
      'click': 'onClick'
    },

    modelEvents: {
      'change:validated': 'onRender'
    },

    onRender: function() {  
      if (this.model.get('validated')) {
        this.$el.addClass('activated');
        this.$el.attr('title', _.i18n('common.validated'));
      }        
      else {
        this.$el.removeClass('activated');
        this.$el.attr('title', _.i18n('common.notValidated'));
      }
    },    

    onClick: function() {
      if(this.model.get('validated'))
        Controller.invalidate([this.model], this.options.askConfirmation);
      else
        Controller.validate([this.model], this.options.askConfirmation);
    }
  });
});
  
