
define('template!webshopProductsDetailHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="left">\n    <div class="image">\n        <img src="' +
((__t = ( src )) == null ? '' : __t) +
'">\n    </div>\n</div>\n<div class="center">\n    <div class="name"></div>\n    <div class="code"></div>\n</div>\n<div class="right">\n    <div class="created-at">\n        <div class="libelle">' +
((__t = ( _.i18n("common.createdAt") )) == null ? '' : __t) +
':</div>\n        <div class="date"></div>\n    </div>\n    <div class="updated-at">\n        <div class="libelle">' +
((__t = ( _.i18n("common.updatedAt") )) == null ? '' : __t) +
':</div>\n        <div class="date"></div>\n    </div>\n</div>\n';

}
return __p
};});

