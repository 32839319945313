
define('template!noItemsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<p>\n  ' +
((__t = ( _.i18n("common.noItem") )) == null ? '' : __t) +
'\n</p>';

}
return __p
};});

