/**
 * Created by Matteo on 11/07/2015.
 */
define('messagesShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'imageUtils',
  'template!messagesViewTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  Utils,
  viewTpl
) {
  'use strict';

  var MessagesDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
    },

    ui: {
      tab: '.ticket-tab',
      tabPane: '.tab-pane'
    },

    events: {
      'click @ui.tab': 'onTabClick'
    },

    regions: {
      messagesTab: '#messages-tab',
      messagesCsTab: '#messages-cs-tab'
    },

    serializeData: function() {
      return {};
    },

    onRender: function() {
      if (this.options.tab) {
        this.$el.find('.tab-pane').removeClass('active');        
        this.$el
          .find('.tab-pane[data-name="' + this.options.tab + '"]')
          .addClass('active');
      }
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = $target.data('name');
        
      $('.tab-pane[data-name="' + tab + '"]').find('.ticket-tab');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab + '"]').addClass('active');
      this.triggerMethod('click:tab', tab, this.ui.tab);
    }
  });

  module.exports = MessagesDetailsView;
});
