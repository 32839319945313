/**
 * Created by BFR on 24/06/2020.
 */
define('caccountPriceFactorsController',[
  'module',
  'app',
  'jquery',
  'underscore',
  'settings',
  'caccountPriceFactorsView',
  'entities/caccounts/share',
  'entities/caccounts'
], function (
  module,
  App,
  $,
  _,
  Settings,
  View
) {
  'use strict';

  var CaccountProfileTabController = {
    showTab: function () {

      $.when(App.request('caccount:get-factors')).done(function (factors) {
        var request = App.request('caccounts:get-share', {
          cAccountId: Settings.get('currentCAccount').secId,
          share: 'from'
        });

        $.when(request).done(function (list) {
          var sup = [];
          _.each(list, function (item) {
            sup.push(item.caccountFrom);
          });
          sup = _.sortBy(sup, 'code');
          this.view = new View({
            suppliers: sup,
            factors: factors
          });
          App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
        });
      });

    }
  };

  module.exports = CaccountProfileTabController;
});
