
define('template!popupVariationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'>\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') + '/' + _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code"\n                 placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-7 name-region p-t-5"></div>\n      </div>\n      ';
 _.each(categories, function(category) { ;
__p += '\n      <div class="form-group category" data-cat-id="' +
((__t = ( category.secId )) == null ? '' : __t) +
'">\n        <label class="col-xs-3 control-label name">' +
((__t = ( category.name )) == null ? '' : __t) +
'</label>\n        <select class="category-select col-xs-5">\n          <option value=""></option>\n          ';
 _.each(category.options, function(option) { ;
__p += '\n            <option value="' +
((__t = ( option.secId )) == null ? '' : __t) +
'" ' +
((__t = ( selected[category.secId] === option.secId ? 'selected': '' )) == null ? '' : __t) +
'>' +
((__t = ( option.name )) == null ? '' : __t) +
'</option>\n          ';
 }) ;
__p += '\n        </select>\n      </div>\n      ';
 }) ;
__p += '\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

