/**
 * Created by BFR on 21/08/2020.
 */
define('productCompetitorsView',['module',
        'marionette',
        'app',
        'underscore',
        'productCompetitorView',
        'template!productCompetitorsTpl',
        'competitorCreateEditLayoutView',
        'entities/product_competitor'
  ],
  function(module,
           Marionette,
           App,
           _,
           ProductCompetitorView,
           itemTpl,
           CompetitorCreateEditLayoutView){
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: itemTpl,
    childViewContainer: '.competitors-items',
    childView: ProductCompetitorView,

    initialize: function() {
    },

    events: {
      'click .btn-add-competitor': 'onBtnAddCompetitorClick'
    },

    onBtnAddCompetitorClick: function() {
      var competitorCreateEditLayoutView = new CompetitorCreateEditLayoutView({
        model: App.request('product-competitor:model', { productSecId: this.options.model.get('secId') }),
        collection: this.collection,
        newItem: true
      });

      competitorCreateEditLayoutView.show({
        title: _.i18n('competitors.title')
      });
    }
  });
});
