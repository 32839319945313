/**
 * Created by RKL on 05/08/2015.
 */
define('forgotMain',[
  'module',
  'logger',
  'forgotMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var ForgotPwd = {
    start: function() {
      Logger.log(module, 'starting ForgotModule');
    },

    stop: function() {
      Logger.log(module, 'stopping ForgotModule');
    }
  };

  module.exports = ForgotPwd;
});
