
define('template!webshopProductsListCategoryView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<input type="text" class="default-category" name="webshopCategories" list="webshop-categories" class="value">';

}
return __p
};});

