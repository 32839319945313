define('customersDetailBodyTabsView',[
  'app',
  'module',
  'underscore',
  'rolesMixin',
  'settings',
  'tabsView'
], function (
  App,
  Module,
  _,
  RolesMixin,
  Settings,
  TabsView
) {
  'use strict';

  Module.exports = TabsView.extend({
    fetch: function () {
      var test = false;

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE] || test) {
        this.collection.push({
          name: 'profile',
          title: _.i18n('endUsersDetail.profile.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE
        });
      }

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES] || test) {
        this.collection.push({
          name: 'logosimages',
          title: _.i18n('endUsersDetail.logosImages.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS] || test) {
        this.collection.push({
          name: 'datasets',
          title: _.i18n('endUsersDetail.dataset.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES] || test) {
        this.collection.push({
          name: 'producttemplates',
          title: _.i18n('endUsersDetail.corporateTemplates.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS] || test) {
        this.collection.push({
          name: 'orders',
          title: _.i18n('endUsersDetail.orders.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS] || test) {
        this.collection.push({
          name: 'offers',
          title: _.i18n('endUsersDetail.offers.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS] || test) {
        this.collection.push({
          name: 'attachments',
          title: _.i18n('endUsersDetail.attachments.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_INVOICES] || test) {
        this.collection.push({
          name: 'invoices',
          title: _.i18n('cAccountDetail.invoices.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_INVOICES
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS] || test) {
        this.collection.push({
          name: 'contacts',
          title: _.i18n('endUsersDetail.contacts.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS] || test) {
        this.collection.push({
          name: 'calls',
          title: _.i18n('endUsersDetail.calls.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS] || test) {
        this.collection.push({
          name: 'mails',
          title: _.i18n('endUsersDetail.mails.tabTitle'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS
        });
      }

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_TASKS] || true) {
        this.collection.push({
          name: 'tasks',
          title: _.i18n('common.tasks'),
          role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_TASKS
        });
      }
    }
  });
});
