/**
 * Created by BFR on 04/03/2020.
 */
define('shareOptionView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!shareOptionTpl',
    'colorUtils',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    ColorUtils
  ) {
    'use strict';
  
    var ShareOptionView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
      },

      onShow: function () {
      },

      events: {
        'change .cbx-option': 'onSelectionChange'
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          this.model.set('option', { });
        } else {
          this.model.set('option', null);
        }
      },

      findCheckedOption: function(code) {
        return _.findWhere(this.options.model.collection.checkedOptions, {
          code: code
        });
      },

      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.option?true:false;
        if (templateData.parentOption) {
          templateData.color = (templateData.parentOption.color1!==undefined && templateData.parentOption.color1!==null)?
              ColorUtils.decimalToHexa(templateData.parentOption.color1):
              null;
        } else {
          templateData.color = (templateData.option.color1!==undefined && templateData.option.color1!==null)?
              ColorUtils.decimalToHexa(templateData.option.color1):null;
        }

        if (this.options.model.collection.displayNew &&
            templateData.parentOption &&
            !this.findCheckedOption(templateData.parentOption.code)) {
          templateData.newPo = true;
        } else {
          templateData.newPo = false;
        }

        return templateData;
      }
    });
  
    module.exports = ShareOptionView;
  });
