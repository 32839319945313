define('tableFieldView',[
  'module',
  'marionette',
  'underscore',
  'template!tableFieldView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'table-field-view',     

    initialize: function () {
      this.tableView = this.options.tableView;
      this.rowView = this.options.rowView;
      this.field = this.options.field;

      if(this.field.modelEvent)
        this.model.on(this.field.modelEvent, this.render, this);
    },

    /*modelEvents: {
      change: 'render'
    },*/

    onBeforeRender: function() {
      if(this.field.className)
        this.$el.addClass(this.field.className);
    },

    onRender: function() {    
      try { 
        if(this.field.onRender) {
          this.field.onRender(this);
        } else {
          if(this.field.bind) {
            if(this.model.get(this.field.bind)) {
              this.$el.html(this.model.get(this.field.bind));
              this.$el.attr('title', this.model.get(this.field.bind));
            }        
              
          } 
        }
      } catch(e) {
        console.log(e);
      }   
    }
  });
});
  
