define('adminGlossariesListTranslationView',[
  'module',
  'underscore',
  'tableFieldTextEditView'
], function (
  Module,
  _,
  TableFieldTextEditView
) {
  'use strict';

  Module.exports = TableFieldTextEditView.extend({
    className: 'admin-glossaries-list-translation-view table-field-view',

    getValue: function() {
      return this.model.get('refDynamicText').getTextInLocale(this.field.lang);
    },

    setValue: function(value) {
      var defer = $.Deferred();

      if(!this.model.get('refDynamicText'))
        return;

      var OldValue = this.model.get('refDynamicText').getTextInLocale(this.field.lang);
      if(OldValue !== value) {
        this.model.get('refDynamicText').setTextInLocale(this.field.lang, value);
        this.model.save()
            .done(_.bind(function() {
              defer.resolve();
            }, this))
            .fail(_.bind(function() {
              defer.reject();
        }, this));
      }

      defer.resolve();
      return defer.promise();
    }
  });
});
