/**
 * Created by BFR on 05/08/2022.
 */
define('caccountTransportItemPriceView',[
  'module',
  'jquery',
  'marionette',
  'app',
  'underscore',
  'template!caccountTransportItemPriceTpl'
], function (
  module,
  $,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'change .txt-price-input': 'onChangeAttribute'
    },

    onChangeAttribute: function (e) {
      var txt = $(e.currentTarget);
      var value = txt.val();
      if (value) {
        value = value.replace(',', '.');
      }
      this.model.set(txt.data('property'), Number(value));
      this.trigger('save');
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.currentIndex = this._index + 1;
      return templateData;
    }
  });
});
