/**
 * Created by BFR on 04/03/2020.
 */
define('shareOptionCategoriesView',['module',
        'marionette',
        'shareOptionCategoryView'],
  function(module,
           Marionette,
           ShareOptionCategoryView){
  'use strict';

  var ShareOptionCategoriesView = Marionette.CollectionView.extend({
    childView: ShareOptionCategoryView,
    className: 'col-md-12 p-t-10',

    childEvents: {
      'item:selection:change': function () {
        this.trigger('item:selection:change');
      }
    }
  });

  module.exports = ShareOptionCategoriesView;
});
