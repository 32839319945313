/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'menuItemsUtils',
  'template!sidebarViewTpl',
  'jquery'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  MenuItemUtils,
  viewTpl
) {
  'use strict';

  var SidebarView = Marionette.ItemView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.switcher': 'sidebar:resize'
    },

    ui: {
      items: 'li[item-page-name]',
      toHide: '.to-hide',
      toShow: '.to-show',
      switcher: '.switcher',
      openItems: '.open-items'
    },

    events: {
      'click @ui.openItems': 'onOpenSubMenuItems'
    },

    serializeData: function () {
      var templateData = {};
      templateData.menuItems = MenuItemUtils.getMenuItems();
      return templateData;
    },

    setActiveItem: function (name) {
      this.ui.items.removeClass('active');
      var $menuItem = this.$el.find('li[item-page-name=' + name + ']');
      var $a = $menuItem.find('a').first();

      if ($a.hasClass('open-items') === false) {
        $menuItem.addClass('active');
      }
      var firstMenuItemParent = $menuItem.parents('li[item-page-name]').first();

      $menuItem.find('a').first().focus();

      //check if the current menu is a sub menu and  if the parent menu is not already opened
      if ($menuItem.data('type') === 'sub-menu' && !firstMenuItemParent.find('.hide-sub').is(':visible')) {
        firstMenuItemParent.find('a.open-items').trigger('click', [true]);
      }

      this.$el.find('.nav-main li[data-type="menu"]').removeClass('active');
      $menuItem.closest('li[data-type="menu"]').addClass('active');
    },

    onResize: function () {
      if (this.ui.toHide.hasClass('hide')) {
        this.ui.toHide
          .show()
          .removeClass('hide');
        this.ui.toShow.css('display', 'none');
      } else {
        this.ui.toHide
          .hide()
          .addClass('hide');
        this.ui.toShow.css('display', 'block');
      }
    },

    onOpenSubMenuItems: function (e) {
      var $target = this.$(e.currentTarget);
      //slide effect
      this.showSubMenuItems($target);
    },

    showSubMenuItems: function ($target) {
      if ($target.next().is(':visible') === true) {
        $target.next().slideUp('slow', function () {
          $target.find('.open-sub').show();
          $target.find('.hide-sub').hide();
        });
      } else {
        $target.next().slideDown('slow', function () {
          $target.find('.open-sub').hide();
          $target.find('.hide-sub').show();
        });
      }
    }
  });

  module.exports = SidebarView;
});
