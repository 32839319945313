define('customersDetailView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'customersDetailHeaderView',
  'customersDetailBodyView',
  'template!customersDetailView',
  'entities/caccounts'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  HeaderView,
  BodyView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customers-detail-view',

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region'      
    },

    initialize: function () {
      this.model = App.request('customers:entity', this.options.id);
    },

    onShow: function() {
      $.when(App.request('caccount:countries'), this.model.fetch())
          .done(_.bind(function (countries) {

        this.headerView = new HeaderView({
          model: this.model,
          countries: countries
        });

        this.getRegion('headerRegion').show(this.headerView);

        this.bodyView = new BodyView({
          model: this.model,
          tabName: this.options.tabName,
          countries: countries
        });
        this.getRegion('bodyRegion').show(this.bodyView);
        this.bodyView.on('selectTab', _.bind(this.selectTab, this));
      }, this));
    },

    selectTab: function (tab) {
      App.navigate('customers/' + this.model.get('secId') + '/' + tab.get('name'));
    }
  });
});

