
define('template!superAdminTicketPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <span class="js-clear-filters clear-filters">\n    <span>\n      <strong>x</strong>\n    </span>\n    <span class="clear-filters-text">' +
((__t = ( _.i18n('common.resetFilters') )) == null ? '' : __t) +
'</span>\n  </span>\n</div>\n<div class="row">\n    <!-- Date from -->\n    <div class="form-group col-xs-6">\n      <div class="input-group">\n          <span class="p-l-0 input-group-addon">' +
((__t = ( _.i18n("common.fromDate") )) == null ? '' : __t) +
'</span>\n          <input type="text" class="form-control date-superadmin date-from" placeholder="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'" value="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n      </div>\n    </div>\n\n    <!-- Date to -->\n    <div class="form-group col-xs-6 date-to-form">\n      <div class="input-group">\n          <span class="p-l-0 input-group-addon">' +
((__t = ( _.i18n("common.toDate") )) == null ? '' : __t) +
'</span>\n          <input type="text" class="form-control date-superadmin date-to" placeholder="' +
((__t = ( dateTo )) == null ? '' : __t) +
'" value="' +
((__t = ( dateTo )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n      </div>\n    </div>\n</div>\n<div class="row">\n  <!-- In Day/week/month -->\n  <div class=" form-group col-xs-6">\n    <div class="dropdown">\n      <button class="form-control dropdown-toggle" type="button" id="choosePeriodType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n        <span class="pull-left">' +
((__t = ( _.i18n("common.inFormat") )) == null ? '' : __t) +
'</span>\n        <span class="filter-by-type-sa" data-type="' +
((__t = ( filters.type )) == null ? '' : __t) +
'">' +
((__t = ( filters.type )) == null ? '' : __t) +
'</span>\n        <span class="caret pull-right m-t-5"></span>\n      </button>\n      <ul class="dropdown-menu col-xs-12" aria-labelledby="choosePeriodType">\n        <li class="item-type-sa" data-type="Day"><a>' +
((__t = ( _.i18n("common.days") )) == null ? '' : __t) +
'</a></li>\n        <li class="item-type-sa" data-type="Week"><a>' +
((__t = ( _.i18n("common.weeks") )) == null ? '' : __t) +
'</a></li>\n        <li class="item-type-sa" data-type="Month"><a>' +
((__t = ( _.i18n("common.months") )) == null ? '' : __t) +
'</a></li>\n        <li class="item-type-sa" data-type="Year"><a>' +
((__t = ( _.i18n("common.years") )) == null ? '' : __t) +
'</a></li>\n      </ul>\n    </div>\n  </div>\n  <!-- In Status -->\n  <div class="form-group col-xs-6">\n    <div class="dropdown">\n      <button class="form-control dropdown-toggle" type="button" id="chooseStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n        <span class="pull-left"></span>\n        <span class="filter-by-status-sa">' +
((__t = ( _.i18n("superAdmin.status") )) == null ? '' : __t) +
'</span>\n        <span class="caret pull-right m-t-5"></span>\n      </button>\n      <ul class="dropdown-menu col-xs-12 p-r-15 p-l-15" aria-labelledby="chooseStatus">\n          <li class="item-status-sa" data-type="ALL"><div class="col-xs-12 m-b-10 m-t-10">' +
((__t = ( _.i18n("common.all") )) == null ? '' : __t) +
'</div></li>\n          ';
 _.each(statusData, function(status, key) { ;
__p += '\n            <li class="item-status-sa" data-type="' +
((__t = ( key )) == null ? '' : __t) +
'">\n              <div class="form-group checkbox-group col-xs-12 m-b-5">\n                <div class="checkbox checkbox-primary">\n                  <label class="status-checkbox-label p-t-5" for="status-' +
((__t = ( key )) == null ? '' : __t) +
'">' +
((__t = ( key )) == null ? '' : __t) +
'</label>\n                  <label class="pull-right">\n                    <input id="status-' +
((__t = ( key )) == null ? '' : __t) +
'" type="checkbox" data-type="' +
((__t = ( key )) == null ? '' : __t) +
'" class="js-status-checkbox" ';
 if (_.indexOf(filters.statusFilters, key) > -1) { ;
__p += 'checked';
 } ;
__p += '>\n                  </label>\n                </div>\n              </div>\n            </li>\n          ';
 }); ;
__p += '\n          <li class="status-apply-btn btn btn-primary col-xs-12" data-type="apply">' +
((__t = ( _.i18n("common.ok") )) == null ? '' : __t) +
'</li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-12">\n    <label class="date-validation-label"></label>\n  </div>\n</div>\n<div class="footer-container row">\n  <div class="col-xs-12">\n    <a class="btn btn-primary pull-right save-sa">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n  </div>\n</div>';

}
return __p
};});

