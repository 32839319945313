/**
 * Created by OBL on 14-12-15.
 */

define('cataloguesMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin) {
  'use strict';

  var CataloguesRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'catalogues/mine': 'showMine',
      'catalogues/mine/:secId': 'showMineDefaultTab',
      'catalogues/mine/:secId/overview': 'showMineOverview',
      'catalogues/mine/:secId/sharing': 'showMineSharing',
      'catalogues/mine/:secId/products': 'showMineProducts',
      'catalogues/shared': 'showShared',
      'catalogues/shared/add/:catalogueSecId': 'addShare'
    }
  });

  var API = {
    showMine: function () {
      require([
        'cataloguesMineController',
        'contentShowController'
      ], function (mineController, ContentShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('cataloguesMine');
          mineController.showCatalogues();
          App.execute('set:active:nav:item',ItemPagesNameMixin.PRODUCTS_CATALOGUES);
        });
      });
    },

    showMineDefaultTab: function(secId) {
      API.showMineOverview(secId);
    },

    showMineOverview: function (secId) {
      require([
        'contentShowController',
        'catalogueMineTabController',
        'catalogueMineOverviewTabController'
      ], function(ContentShowController, MineTabLayoutController, OverviewTabController) {
        var mainRegion;

        ContentShowController.showContent().done(function() {
          mainRegion = App.regions.getRegion('main').currentView.getRegion('content');
          MineTabLayoutController.showTabsLayout(mainRegion, 'overview', secId).done(function(region, model) {
            OverviewTabController.showOverviewTab(region, model);
            App.execute('set:active:nav:item',ItemPagesNameMixin.PRODUCTS_CATALOGUES);
          });
        });
      });
    },

    showMineSharing: function (secId) {
      require([
        'contentShowController',
        'catalogueMineTabController',
        'catalogueMineSharingTabController'
      ], function(ContentShowController, MineTabLayoutController, SharingTabController) {
        var mainRegion;
        ContentShowController.showContent().done(function() {
          mainRegion = App.regions.getRegion('main').currentView.getRegion('content');
          MineTabLayoutController.showTabsLayout(mainRegion, 'sharing', secId).done(function(region, model) {
            SharingTabController.showSharingTab(region, model);
            App.execute('set:active:nav:item',ItemPagesNameMixin.PRODUCTS_CATALOGUES);
          });
        });
      });
    },

    showMineProducts: function (secId) {
      require([
        'contentShowController',
        'catalogueMineTabController',
        'catalogueMineProductsTabController'
      ], function(ContentShowController, MineTabLayoutController, MineProductsTabController) {
        var mainRegion;
        ContentShowController.showContent().done(function() {
          mainRegion = App.regions.getRegion('main').currentView.getRegion('content');
          MineTabLayoutController.showTabsLayout(mainRegion, 'products', secId).done(function(region, model) {
            MineProductsTabController.showProductsTab(region, model);
            App.execute('set:active:nav:item',ItemPagesNameMixin.PRODUCTS_CATALOGUES);
          });
        });
      });
    },

    showShared: function () {
      require([
        'cataloguesSharedController',
        'contentShowController'
      ], function (sharedController, ContentShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('cataloguesShared');
          sharedController.showCatalogues();
          App.execute('set:active:nav:item', ItemPagesNameMixin.SUPPLIERS_CATALOGUES);
        });
      });
    },

    addShare: function (catalogueSecId) {
      require([
        'cataloguesSharedController',
        'contentShowController'
      ], function (sharedController, ContentShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('cataloguesShared');
          sharedController.addShare(catalogueSecId);
          App.execute('set:active:nav:item', ItemPagesNameMixin.SUPPLIERS_CATALOGUES);
        });
      });
    }
  };

  App.on('catalogues:mine:show', function () {
    App.navigate('catalogues/mine');
    API.showMine();
  });

  App.on('catalogues:shared:show', function () {
    App.navigate('catalogues/shared');
    API.showShared();
  });

  App.on('before:start', function () {
    new CataloguesRouter({
      controller: API
    });
  });

  module.exports = CataloguesRouter;
});
