/**
 * Created by GSP on 4/08/2015.
 */
define('contentMain',[
  'module',
  'logger',
  'app'
], function(
  module,
  Logger,
  App
) {
  'use strict';

  var Content = {
    start: function() {
      Logger.log(module, 'starting ContentModule');
      require(['contentShowController'], function(ShowController){
        ShowController.showContent();
      });
    },

    stop: function() {
      Logger.log(module, 'stopping ContentModule');
    }
  };

  App.on('content:show', function(){
    Content.start();
  });

  module.exports = Content;
});
