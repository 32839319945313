define('catalogueMineTabController',[
  'module',
  'app',
  'underscore',
  'catalogueMineTabLayoutView',
  'entities/catalogues'
], function(
  module,
  App,
  _,
  TabLayoutView
) {
  'use strict';

  var CatalogueMineTabsLayoutController = {
    _layoutView: undefined,

    showTabsLayout: function(region, tabName, catalogSecId) {
      var that = this,
        defer = $.Deferred();

      if(this._layoutView && !this._layoutView.isDestroyed) {
        defer.resolve(this._layoutView.getRegion('tabs'), that._layoutView.model);
        this._layoutView.triggerMethod('show:active', tabName);

        return defer.promise();
      }

      App.request('catalogue:entity', catalogSecId).done(function(catalogue) {
        that._layoutView = new TabLayoutView({
          model: catalogue
        });
        region.show(that._layoutView);
        that._layoutView.triggerMethod('show:active', tabName);

        defer.resolve(that._layoutView.getRegion('tabs'), that._layoutView.model);
      });

        App.on('catalogue:changed', _.bind(this.catalogueChanged, this));

      return defer.promise();
    },

    catalogueChanged : function(updatedModel){
        this._layoutView.update(updatedModel);
    }
  };

  module.exports = CatalogueMineTabsLayoutController;
});
