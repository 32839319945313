/**
 * Created by BFR on 25/06/2020.
 */
define('pimProductImprintPricesView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!pimProductImprintPricesTpl',
  'settings',
  'pimUtil',
  'entities/pim_product'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  Settings,
  PimUtil
) {
  'use strict';

  var PimProductImprintPricesView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'row',

    regions: {},

    ui: {
      lblPrice: '.lbl-price',
      txtPrice: '.txt-price',
      lblFactorizedPrice: '.lbl-factorized-price',
      txtFactorizedPrice: '.txt-factorized-price',
      selectPrintFactorCode: '.select-print-factor-code',
      customPrice: '.custom-price'
    },

    events: {
      'change .cbx-enable-pz': 'onCbxEnablePzChange',
      'change @ui.selectPrintFactorCode': 'onPrintFactorCodeChange',
      'click @ui.lblPrice': 'onLblPriceClick',
      'change @ui.txtPrice': 'onTxtPriceChange',
      'focusout @ui.txtPrice': 'onTxtPriceChange'
    },

    onCbxEnablePzChange: function (event) {
      var cbx = $(event.target), currentPz;

      _.each(this.options.model.get('product').get('resellerData').models, _.bind(function (resProduct) {
        _.each(resProduct.get('pimPzResellers'), _.bind(function (resPa) {
          if (this.options.model.get('pa').pimId === resPa.paId) {
            _.each(resPa.pzResellers, function (resPz) {
              currentPz = resPz;
            });
          }
        }, this));
      }, this));

      if (currentPz) {
        currentPz.enable = cbx.is(':checked');
      }
    },

    onLblPriceClick: function (event) {
      var index = this.$el.find(event.target).data('index');
      this.$el.find(this.ui.lblPrice[index]).hide();
      this.$el.find(this.ui.txtPrice[index]).show();
      this.$el.find(this.ui.txtPrice[index]).focus();
      this.$el.find(this.ui.txtPrice[index]).select();
    },

    onTextPriceKeyDown: function (e) {
      $(e.target.nextSibling).focus();
    },

    onTxtPriceChange: function (event) {
      var tin = this.$el.find(event.target),
        index = tin.data('index'),
        currTin, newFactor, i;
      this.$el.find(this.ui.lblPrice[index]).show();
      tin.hide();

      this.options.model.collection.printZonePrices.clear(tin.data('pz-id'));

      for (i = 0; i < this.ui.txtPrice.length; i++) {
        currTin = this.$el.find(this.ui.txtPrice[i]);
        var currFactorizedTin = this.$el.find(this.ui.txtFactorizedPrice[i]);
        if (this.customPrice) {
          if (i === index) {
            newFactor = 1;
          } else {
            newFactor = Number(currFactorizedTin.val()) / Number(currTin.val().replace(',', '.'));
          }

          if (currTin.data('quantity') === -1) {
            this.options.model.collection.printZonePrices.setCustomPzPrice
            (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')),
              Number($(this.ui.txtPrice[i]).val()), newFactor);
          } else {
            this.options.model.collection.printZonePrices.setCustomPzPrice
            (currTin.data('pz-id'), currTin.data('quantity'), Number($(this.ui.txtPrice[i]).val().replace(',', '.')), 0, newFactor);
          }

        } else {
          newFactor = Number(currTin.val().replace(',', '.')) / Number(currTin.data('price'));
          this.options.model.collection.printZonePrices.setPriceFactorForQuantity(currTin.data('pz-id'),
            currTin.data('quantity'), newFactor);
        }
      }
      this.render();
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      templateData.setupFactor = 1;
      templateData.paName = PimUtil.getTranslation(templateData.pa.name.values);
      templateData.pzName = PimUtil.getTranslation(templateData.pz.refImprintTechnology.name.values);
      templateData.activated = false;
      templateData.currentPzPrice = [];
      templateData.factorEditable = true;
      templateData.selectedFactor = '';
      templateData.useFactorV2 = this.options.model.collection.useFactorV2;

      templateData.printFactors = this.options.model.collection.pimPrintFactors.toJSON();
      var printFactor = {
        factor: 1.3,
        factorSetup: 1.3,
        factorHandling: 1.3
      };

      _.each(this.model.get('resProduct').get('pimPaResellers'), _.bind(function (paRes) {
        _.each(paRes.pzResellers, _.bind(function (pzRes) {
          if (pzRes.pzId === this.model.get('pz').pimId) {
            if (pzRes.printFactorCode)
              templateData.selectedFactor = pzRes.printFactorCode;
          }
        }, this));
      }, this));

      if (!templateData.selectedFactor) {
        _.each(templateData.printFactors, _.bind(function (pf) {
          if (pf.defaultPrice) {
            templateData.selectedFactor = pf.printCode;
            printFactor = pf;
          }
        }, this));
      } else {
        _.each(templateData.printFactors, function (factor) {
          if (factor.printCode === templateData.selectedFactor) {
            printFactor = factor;
          }
        });
      }

      templateData.customPrice = this.customPrice = this.options.customPrice;

      _.each(this.options.model.collection.scales, _.bind(function (scale) {
        if (scale.impt.length === 0 || scale.productUnitPrice === '0' || this.customPrice) {
          var that = this, setUpPrice;

          that.customPrice = templateData.customPrice = true;
          var pzId = null;
          if (templateData.pz)
            pzId = templateData.pz.pimId;

          if (pzId !== null) {
            if (that.options.model.collection.printZonePrices.get(pzId) &&
              that.options.model.collection.printZonePrices.get(pzId).length) {
              _.each(this.options.model.collection.printZonePrices.get(pzId), _.bind(function (impt) {
                var customPriceValue, currentPzId, customPrice, factor;

                if (pzId) {
                  currentPzId = templateData.pz.pimId;
                  customPrice = that.options.model.collection.printZonePrices.findCustomPriceByPzIdAndQuantity(
                    currentPzId, impt.quantity);
                  customPriceValue = customPrice.price;
                  factor = customPrice.factor ? customPrice.factor : 1;
                } else {
                  customPrice = that.options.model.collection.printZonePrices.findCustomPriceByPzIdAndQuantity(
                    null, impt.quantity);
                  customPriceValue = customPrice.price;
                  factor = customPrice.factor ? customPrice.factor : 1;
                }
                if (!setUpPrice) {
                  setUpPrice = (impt.quantity === -1) ? impt.price : 0;
                  templateData.setupFactor = customPrice.factor ? customPrice.factor : 1;
                }
                if (impt.quantity === Number(scale.quantity)) {
                  templateData.currentPzPrice.push({
                    quantity: scale.quantity,
                    secId: currentPzId,
                    price: (customPriceValue) ? customPriceValue : '0',
                    setUpPrice: setUpPrice,
                    factor: Number(factor).toFixed(2)
                  });
                }
              }), this);
            } else {
              templateData.currentPzPrice.push({
                quantity: scale.quantity,
                secId: pzId,
                price: '0'
              });
            }
          }
        } else {
          _.each(scale.impt, _.bind(function (impt) {
            if (impt.secId === templateData.pz.pimId) {
              impt.imptSetupPrice = impt.imptSetupPrice ? Number(impt.imptSetupPrice).toFixed(2) : '0';
              impt.setupFactor =
                this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, -1) ||
                printFactor.factorSetup;
              impt.imptSetupPriceSale = (Number(impt.imptSetupPrice) * impt.setupFactor).toFixed(2);

              if (impt.imptHandlingUnitPrice) {
                impt.handlingFactor =
                  this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, -2) ||
                  printFactor.factorHandling;
                impt.imptHandlingPriceSale = (Number(impt.imptHandlingUnitPrice) * impt.handlingFactor).toFixed(2);
              } else {
                impt.imptHandlingPrice = '0';
                impt.imptHandlingPriceSale = '0';
              }

              impt.imptUnitUnitPriceFactor =
                this.options.model.collection.printZonePrices.findByPzIdAndQuantity(impt.secId, scale.quantity) ||
                printFactor.factor;
              impt.imptUnitUnitPriceSale = (impt.imptUnitUnitPrice * impt.imptUnitUnitPriceFactor).toFixed(2);
              impt.quantity = scale.quantity;
              templateData.currentPzPrice.push(impt);
            }
          }, this));
        }
      }, this));
      return templateData;
    },

    onShow: function () {
      if (this.customPrice) {
        this.ui.customPrice.addClass('prices-editable');
      }
    },

    onPrintFactorCodeChange: function () {

      _.each(this.model.get('resProduct').get('pimPaResellers'), _.bind(function (paRes) {
        _.each(paRes.pzResellers, _.bind(function (pzRes) {
          if (pzRes.pzId === this.model.get('pz').pimId) {
            pzRes.printFactorCode = this.ui.selectPrintFactorCode.val();
            this.render();
          }
        }, this));
      }, this));
    }
  });

  module.exports = PimProductImprintPricesView;
});
