define('ordersDetailOrderStatusTrackingView',[
  'module',
  'marionette',
  'underscore',
  'ordersDetailTrackingEditView',
  'template!ordersDetailOrderStatusTrackingView'
], function (
  Module,
  Marionette,
  _,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'orders-detail-order-status-tracking-view',
    template: Tpl,

    ui: {
      editButton: '.btn-edit-client'
    },

    events: {
      'click @ui.editButton': 'onClick'
    },

    serializeData: function() {
      var data = {};
      data.url = this.model.trackingUrl(this.options.customer);
      data.number = this.model.get('trackingNumber');     

      return data;
    },

    onClick: function() {
      EditView.prototype.open(this.model, this.options.order);
    }
  });
});
