define('ordersDetailOrderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'ordersDetailOrderProductView',
  'ordersDetailOrderPrintZonesView',
  'commentsView',
  'ordersDetailOrderHistoryView',
  'ordersDetailOrderClientView',
  'ordersDetailOrderStatusView',  
  'template!ordersDetailOrderView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  ProductView,
  PrintZonesView,
  CommentsView,
  HistoryView,
  ClientView,
  StatusView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-view',

    ui: {
      commentsCounter: '.comments .counter',
      AddCommentButton: '.comments .btn-add'
    },

    events: {
      'click @ui.AddCommentButton': 'onAddComment'
    },

    regions: {
      productRegion: '.product-region',
      historyRegion: '.history-region',
      clientRegion: '.client-region',
      statusRegion: '.status-region',
      commentsRegion: '.comments-region',
      printZonesRegion: '.print-zones-region'
    },

    initialize: function() {
      this.commentsCollection = new Backbone.Collection();
      this.allCommentsCollection = new Backbone.Collection();
    },

    onShow: function() {
      this.listenTo(this.model.get('order'), 'change', _.bind(function(model) {
        if (model.hasChanged('comments'))
          this.fetch();
      }, this));

      //-------------------
      this.productView = new ProductView({
        model: this.model.get('order'),        
        product: this.model.get('product')        
      });
      this.getRegion('productRegion').show(this.productView);

      //-------------------
      this.printZonesView = new PrintZonesView({
        model: this.model.get('order'),        
        product: this.model.get('product')      
      });      
      this.getRegion('printZonesRegion').show(this.printZonesView);      

      //-------------------
      this.commentsView = new CommentsView({
        collection: this.commentsCollection,
        add: _.bind(function (comment) {
          this.model.get('order').addComment(comment).done(_.bind(function() {
            this.model.get('order').fetch().done(_.bind(function() {
              this.ui.commentsCounter.text(this.commentsCollection.length);
            }, this));
          }, this));
        }, this),
        remove: _.bind(function (comment) {
          this.model.get('order').deleteComment(comment).done(_.bind(function() {
            this.model.get('order').fetch().done(_.bind(function() {
              this.ui.commentsCounter.text(this.commentsCollection.length);
            }, this));
          }, this));
        }, this),
        change: _.bind(function (comment) {
          this.model.get('order').updateComment(comment).done(_.bind(function() {
            this.model.get('order').fetch().done(_.bind(function() {
              this.ui.commentsCounter.text(this.commentsCollection.length);
            }, this));
          }, this));
        }, this),
        pin: _.bind(function (comment) {
          comment.set('pin', !comment.get('pin'));
          this.model.get('order').updateComment(comment).done(_.bind(function() {
            this.model.get('order').fetch().done(_.bind(function() {
              this.ui.commentsCounter.text(this.commentsCollection.length);
            }, this));
          }, this));
        }, this)
      });
      this.getRegion('commentsRegion').show(this.commentsView);

      //-------------------
      this.clientView = new ClientView({
        model: this.model.get('order'),
        customer: this.options.customer,
        product: this.model.get('product')    
      });
      this.getRegion('clientRegion').show(this.clientView);

      //-------------------

      this.statusView = new StatusView({
        model: this.model.get('order'),
        customer: this.options.customer
      });
      this.getRegion('statusRegion').show(this.statusView);     

      //-------------------
      this.historyView = new HistoryView({
        model: this.model.get('order')
      });
      this.getRegion('historyRegion').show(this.historyView);

      //-------------------
      this.fetch();
    },

    fetch: function() {
      this.allCommentsCollection.set(this.model.get('order').get('comments'));

      var models = _.filter(this.allCommentsCollection.models, function(model) {
        if(model.get('type') && model.get('type') === 'order')
          return true;
        else
          return false;
      }, this);

      models = models.sort(function (a, b) {
        if(a.get('pin') && b.get('pin')) {
          return a.get('msgDate') < b.get('msgDate') ? 1 : -1;
        }

        if(a.get('pin') && !b.get('pin')) {
          return -1;
        }

        if(!a.get('pin') && b.get('pin')) {
          return 1;
        }

        if(!a.get('pin') && !b.get('pin')) {
          return a.get('msgDate') < b.get('msgDate') ? 1 : -1;
        }
      });

      this.commentsCollection.set(models);
      this.ui.commentsCounter.html(this.commentsCollection.length);
    },

    onAddComment: function() {
      this.commentsView.add();
    }
  });
});
