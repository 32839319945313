
define('template!translationPrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-2 text-right">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n<div class="col-xs-5 name-region"></div>\n<div class="col-xs-5 itname-region"></div>';

}
return __p
};});

