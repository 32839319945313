
define('template!productStockTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="margin:0 auto;position:relative;width:80%">\n    <div class="col-lg-12" style="width:100%">\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</strong></span>\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('common.options'))) == null ? '' : __t) +
'</strong></span>\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('product.stock.currentqty'))) == null ? '' : __t) +
'</strong></span>\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('product.stock.nextdate'))) == null ? '' : __t) +
'</strong></span>\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('product.stock.nextqty'))) == null ? '' : __t) +
'</strong></span>\n        <span class="col-lg-2"><strong>' +
((__t = ( _.i18n('product.stock.position'))) == null ? '' : __t) +
'</strong></span>\n        <div class="col-lg-12 js-table-div" style="height:100%;overflow-y: auto;float:left;width:100%;margin-top:20px;">\n            ';
 _.each(stock, function(st,index) {;
__p += '\n\n            <div class="row" style="' +
((__t = ( (index%2===0)?'':'background-color:#DDD')) == null ? '' : __t) +
'">\n                <span class="col-md-2">' +
((__t = (st.code)) == null ? '' : __t) +
'</span>\n                <span class="col-md-2">' +
((__t = (st.productOptions)) == null ? '' : __t) +
'</span>\n                ';
 if (!st.noStockInfo) { ;
__p += '\n                <span class="col-md-2 ">' +
((__t = (st.currentQuantity)) == null ? '' : __t) +
'</span>\n                <span class="col-md-2">' +
((__t = (st.newArrivalETA)) == null ? '' : __t) +
'</span>\n                <span class="col-md-2">' +
((__t = (st.newArrivalQuantity)) == null ? '' : __t) +
'</span>\n                <span class="col-md-2">' +
((__t = (st.stockPosition)) == null ? '' : __t) +
'</span>\n                ';
 } else { ;
__p += '\n                <span class="col-md-6">' +
((__t = ( _.i18n('product.stock.problem'))) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            </div>\n            ';
 }); ;
__p += '\n\n        </div>\n    </div>\n</div>';

}
return __p
};});

