define('phoneCallsRecordView',[
  'module',
  'marionette',
  'underscore',
  'windows',
  'template!phoneCallsRecordView'
], function(
  Module,  
  Marionette,
  _,
  Windows,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'call-record-view',

    ui: {
      content: '.content'
    },

    onRender: function() {
      if(this.options.url) 
        this.ui.content.attr('src', this.options.url);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'phoneCallsRecordView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: data.title,
          modal: true,
          height: 600,
          width: 600,
          maxWidth: 800,
          maxHeight: 600
        });
      });
    }
  });  
});

