define('changeStatusView',[
    'template!changeStatusTpl',
    'marionette',
    'module',
    'ticketStates',
    'jquery'
  ], function(
  changeStatusTpl,
  Marionette,
  module,
  ticketStates,
  $
) {
  'use strict';

  var ChangeStatusView = Marionette.ItemView.extend({
    template: changeStatusTpl,

    ui: {
      status: 'li'
    },

    events: {
      'click @ui.status': 'changeStatus'
    },

    onBeforeRender: function() {
      this.currentState = this.options.currentState;
    },

    serializeData: function() {
      return {ticketStatuses: ticketStates, currentState: this.currentState};
    },

    changeStatus: function(e) {
      var target = $(e.currentTarget ),
        state = target.data('state' );
      if (this.currentState !== state) {
        this.currentState = state;
        //remove  check on previous item
        this.$('li').attr('data-selected', 'false' )
          .find('i').removeClass('fa-check');
        //add check on current item
        target.attr('data-selected', 'true' )
          .find('i').addClass('fa-check');
        this.trigger('status:change', state);
      }
    }
  });

  module.exports = ChangeStatusView;
});
