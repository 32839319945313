/**
 * Created by BFR on 04/03/2020.
 */
define('shareOptionsView',['module',
        'underscore',
        'marionette',
        'shareOptionView'],
  function(module,
           _,
           Marionette,
           ShareOptionView){
  'use strict';

  var ShareOptionsView = Marionette.CollectionView.extend({
    childView: ShareOptionView,
    className: 'col-md-12',

    childEvents: {
      'item:selection:change': 'onItemSelectionChange'
    },

    onItemSelectionChange: function() {
      this.trigger('item:selection:change');
    }
  });

  module.exports = ShareOptionsView;
});
