/**
 * Created by BFR on 16/06/2020.
 */
define('productPricesTabView',[
  'module',
  'marionette',
  'jqgridView',
  'app',
  'jquery',
  'moment',
  'underscore',
  'backbone',
  'template!productPricesTabTpl',
  'productPriceScalesView',
  'productPriceCalculatorView',
  'productImprintsView',
  'productCompetitorsView',
  'productSimilarsView'
], function (
  module, Marionette,
  JqGridView,
  App,
  $,
  moment,
  _,
  Backbone,
  viewTpl,
  ProductPriceScalesView,
  ProductPriceCalculatorView,
  ProductImprintsView,
  ProductCompetitorsView,
  ProductSimilarsView
) {
  'use strict';

  var ProductPricesView = Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      lblModDate: '.lblModDate',
      lblPzModDate: '.lblPzModDate',
      blockLoading: '.js-loading-block',
      inputProductStatus2: '.input-product-status2',
      inputProductComment: '.input-product-comment',
      cbxShowInWebshop: '.cbx-show-in-webshop',
      approveLoading: '.js-app-loading'
    },

    regions: {
      priceRegion: '.price-region-scale',
      calculatorRegion: '.price-region-calculator',
      imprintsRegion: '.imprints-region',
      competitorsRegion: '.competitors-region',
      similarsRegion: '.similars-region'
    },

    events: {
      'click .btn-copy-to-scale': 'onBtnCopyToScaleClick',
      'click .btn-approve': 'onBtnApproveClick',
      'click .btn-save-pz': 'onBtnSavePzClick',
      'click .btn-approve-pz-price': 'onBtnApprovePzPriceClick',
      'click .btn-add-scale': 'onBtnAddScaleClick',
      'click .btn-save-product': 'onBtnSaveProductClick',
      'change .select-technic': 'onPrintTechnicChange'
    },

    onBtnSaveProductClick: function () {
      this.ui.blockLoading.show();
      var cbx = this.$el.find(this.ui.cbxShowInWebshop);
      this.options.model.set('showInWebshop', cbx.is(':checked'));
      this.options.model.set('status2', this.ui.inputProductStatus2.val());
      this.options.model.set('comment', this.ui.inputProductComment.val());
      this.options.model.save().then(_.bind(function () {
        this.options.model.refreshServerless();
        this.ui.blockLoading.hide();
      }, this));
    },

    onPrintTechnicChange: function (event) {
      var cbxTechnic = this.$el.find(event.target);
      this.getPriceAndRenderScale(Number(cbxTechnic.val()) - 1);
    },

    onBtnAddScaleClick: function () {
      var newScale = _.clone(this.productPriceCalculatorView.collection.models[
      this.productPriceCalculatorView.collection.length - 1].toJSON());
      newScale.quantity = Number(newScale.quantity) * 2;
      newScale.factorModel = _.clone(newScale.factorModel);
      newScale.factorModel.quantity *= 2;
      delete newScale.factorModel.secId;
      this.productPriceScalesView.collection.create(newScale);
    },

    onBtnSavePzClick: function () {
      this.ui.blockLoading.show();
      this.options.productShare.get('product').set('customFields', null);
      this.options.productShare.get('parentProduct').set('customFields', null);
      this.options.productShare.save().done(_.bind(function () {
        this.ui.blockLoading.hide();
        this.trigger('product-prices:refresh');
      }, this));
    },

    onBtnApproveClick: function () {
      var factors = [];
      _.each(this.productPriceScalesView.collection.models, function (price) {
        factors.push(price.get('factorModel'));
      });
      this.trigger('product-prices:update-prices', factors);
    },

    onBtnApprovePzPriceClick: function () {
      this.ui.blockLoading.show();
      this.options.printZonePrices.save().then(_.bind(function () {
        this.ui.blockLoading.hide();
        this.trigger('product-prices:refresh');
      }, this));
    },

    onBtnCopyToScaleClick: function () {
      this.options.price.scale = this.productPriceCalculatorView.collection.toJSON();
      this.renderScales(this.options.price);
    },

    getPriceAndRenderScale: function (index) {
      var that = this;
      return new Promise(function (resolve) {
        that.ui.blockLoading.show();
        var config = that.options.config;
        config.printZonesColors = {};
        if (that.printZones.length > index) {
          if (that.printZones[index].pz.printZone) {
            config.printZonesColors[that.printZones[index].pz.printZone.id] = 1;
          } else {
            config.printZonesColors[that.printZones[index].pz.parentPrintZone.id] = 1;
          }
        }
        var request = App.request('products:get-price-for-configuration',
          that.model.get('secId'), that.options.config, true);
        $.when(request).done(_.bind(function (price) {

          if (this.options.useFactorV2 && !price.customScales) {
            price.scale = _.filter(price.scale, _.bind(function (scale) {
              if (Number(scale.total) > this.options.factorsV2.param2 &&
                (!this.options.factorsV2.param3 ||
                  this.options.factorsV2.param3 === 0 ||
                  Number(scale.total) < this.options.factorsV2.param3)) {
                return true;
              }
              return false;
            }, this));
          }

          _.each(price.scale, _.bind(function (scale, index) {
            scale.factorModel = that.options.productPrices.findByQuantity(scale.quantity);
            if (scale.factorModel === null) {
              scale.factorModel = {
                quantity: Number(scale.quantity),
                factor: 1.3,
                defaultFactor:
                  that.options.pricesFactorSup.length > index ? that.options.pricesFactorSup[index].factor : 1.3
              };
              scale.previousFactor = scale.factorModel.factor;
            } else {
              scale.factorModel = scale.factorModel.toJSON();
              scale.factorModel.defaultFactor =
                that.options.pricesFactorSup.length > index ? that.options.pricesFactorSup[index].factor : 1.3;
              scale.previousFactor = scale.factorModel.factor;
            }
          }, that));
          that.renderScales(price);
          that.ui.blockLoading.hide();
          resolve();
        }, that));
      });
    },

    renderScales: function (price) {
      this.customPrice = (_.filter(price.scale, function (ps) {
        return ps.factorModel.price > 0;
      }).length > 0) || (_.filter(price.scale, function (ps) {
        return ps.productUnitPrice > 0;
      }).length === 0);

      var colPrice = new Backbone.Collection(price.scale);
      colPrice.supplierPrintFactors = this.options.supplierPrintFactors;
      colPrice.pzs = this.printZones;
      colPrice.printZonePrices = this.options.printZonePrices;
      colPrice.useFactorV2 = this.options.useFactorV2;
      colPrice.factorsV2 = this.options.factorsV2;
      this.productPriceScalesView = new ProductPriceScalesView({
        collection: colPrice,
        customPrice: this.customPrice
      });
      this.getRegion('priceRegion').show(this.productPriceScalesView);

      this.productPriceCalculatorView = new ProductPriceCalculatorView({
        collection: new Backbone.Collection(price.scale),
        customPrice: this.customPrice
      });
      this.getRegion('calculatorRegion').show(this.productPriceCalculatorView);
      this.generateOldestLabel(this.options.productPrices.toJSON());
      this.generatePzOldestLabel(this.options.printZonePrices.toJSON());
    },

    onRender: function () {
      this.printZones = this.buildPzs();
      this.getPriceAndRenderScale(0).then(_.bind(function () {
        var colPa = new Backbone.Collection(this.printZones);
        colPa.price = this.options.price.scale;
        colPa.productShare = this.options.productShare;
        colPa.supplierPrintFactors = this.options.supplierPrintFactors;
        colPa.printZonePrices = this.options.printZonePrices;
        colPa.useFactorV2 = this.options.useFactorV2;
        this.productImprintsView = new ProductImprintsView({
          collection: colPa,
          customPrice: this.customPrice
        });
        this.getRegion('imprintsRegion').show(this.productImprintsView);

        this.competitorsView = new ProductCompetitorsView({
          collection: this.options.competitorsCollection,
          model: this.options.model
        });
        this.getRegion('competitorsRegion').show(this.competitorsView);

        this.similarsView = new ProductSimilarsView({
          collection: this.options.similarsCollection,
          model: this.options.model
        });
        this.getRegion('similarsRegion').show(this.similarsView);
      }, this));


    },

    buildPzs: function () {
      var pzs = [], index = 1;
      _.each(this.options.productShare.get('printAreas').models, function (pa) {
        _.each(pa.get('printZones').models, function (pz) {
          pzs.push({
            pa: pa.toJSON(),
            pz: pz.toJSON(),
            index: index
          });
          index++;
        });
      });
      return pzs;
    },

    serializeData: function () {
      var templateData = {};
      templateData.product = this.options.model.toJSON();
      templateData.price = this.options.price;
      templateData.nbrTechnic = 0;
      _.each(this.options.model.get('printAreas'), function (pa) {
        _.each(pa.printZones, function () {
          templateData.nbrTechnic++;
        });
      });
      templateData.useFactorV2 = this.options.useFactorV2;
      return templateData;
    },

    generatePzOldestLabel: function (factors) {
      var modUser = null, oldest = null;
      if (factors) {
        // jshint ignore:start
        for (var p in factors) {

          _.each(factors[p], function (price) {
            if (price.modDate) {
              var currentDate = moment(price.modDate);
              if (oldest === null || currentDate.isAfter(oldest)) {
                oldest = currentDate;
                if (price.userMod) {
                  modUser = price.userMod.email;
                }
              }
            }
          });
        }
        // jshint ignore:end
      }
      if (oldest) {
        oldest = oldest.format('DD/MM/YYYY, HH:mm:ss');
        oldest = _.i18n('prices.modifiedOn') + ' ' + oldest;
        if (modUser) {
          oldest += ' ' + _.i18n('prices.by') + ' ' + modUser;
        }
      }
      this.ui.lblPzModDate.html(oldest);
    },

    generateOldestLabel: function (factors) {
      var modUser = null, oldest = null;
      if (factors) {
        _.each(factors, function (price) {
          if (price.modDate) {
            var currentDate = moment(price.modDate);
            if (oldest === null || currentDate.isAfter(oldest)) {
              oldest = currentDate;
              if (price.userMod) {
                modUser = price.userMod.email;
              }
            }
          }
        });
      }
      if (oldest) {
        oldest = oldest.format('DD/MM/YYYY, HH:mm:ss');
        oldest = _.i18n('prices.modifiedOn') + ' ' + oldest;
        if (modUser) {
          oldest += ' ' + _.i18n('prices.by') + ' ' + modUser;
        }
      }
      this.ui.lblModDate.html(oldest);
    }
  });

  module.exports = ProductPricesView;
});
