/**
 * Created by OLD on 23/11/2015.
 */
define('priceListsTabView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'template!pricelistsTabTpl',
  'template!pricelistsTabEmptyTpl'
], function(
  module,
  Marionette,
  _,
  App,
  viewTpl,
  emptyTpl
) {
  'use strict';

  var PriceListTabView =  Marionette.LayoutView.extend({
    template: viewTpl,
    emptyTpl: emptyTpl,

    regions: {
      billingCodeTable: '.pricelist-code-table',
      billingDetailTable: '.pricelist-detail-table'
    },

    ui: {
      priceDropdown: '.pricelist-select',
      exportBtn: '.pricelist-export',
      addNewPriceItem: '.add-new-pricelist-data-b',
      addNewCode: '.add-new-code-b',
      editPriceItem: '.edit-selected-pricelist-b',
      priceItem: '.pricedropdown-item',
      activePriceItem: '.selected-price-item',
      deletePriceItem: '.remove-selected-pricelist-b'
    },

    events: {
      'click @ui.addNewPriceItem' : 'onShowAddNewPopup',
      'click @ui.addNewCode' : 'onShowAddNewCodePopup',
      'click @ui.editPriceItem' : 'onEditePriceItem',
      'click @ui.priceItem' : 'onGetPriceListData',
      'click @ui.deletePriceItem' : 'onDeletePriceList'
    },

    getTemplate: function () {
      if (_.isEmpty(this.model.toJSON())) {
        return this.emptyTpl;
      } else {
        return this.template;
      }
    },

    serializeData: function() {
      return {data: _.isEmpty(this.model.toJSON()) ? null : this.model.toJSON()};
    },

    onShow: function() {
      $.material.init();
    },

    onGetPriceListData: function(e) {
      var pricelistId = $(e.currentTarget).attr('data-id'),
        pricelistName = $(e.currentTarget).text();

      this.preselectDropdownItem(pricelistId, pricelistName, $(e.currentTarget));
      this.trigger('pricedropdown:set-new-price-id', pricelistId);
    },

    preselectDropdownItem: function (pricelistId, pricelistName, dropDownItem) {
      this.ui.activePriceItem.attr('data-id', pricelistId);
      this.ui.activePriceItem.text(pricelistName);
      this.ui.priceItem.removeClass('active');

      if (!dropDownItem) {
        _.each(this.ui.priceItem, function (item) {
          if ($(item).data('id') === pricelistId) {
            dropDownItem = $(item);
          }
        });
      }

      dropDownItem.addClass('active');
    },

    onShowAddNewCodePopup: function() {
      this.trigger('add-new-code:open');
    },

    onShowAddNewPopup: function() {
      this.trigger('pricelist:show-add-new-popup');
    },

    onEditePriceItem: function() {
      var pricelistId = this.ui.activePriceItem.attr('data-id');

      this.trigger('pricelist:show-edit-priceitem-popup', pricelistId);
    },

    onDeletePriceList: function() {
      var pricelistId = this.ui.activePriceItem.attr('data-id');

      this.trigger('pricelist:delete-priceitem-popup', pricelistId);
    }
  });

  module.exports = PriceListTabView;
});
