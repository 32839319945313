define('entities/caccounts',[
  'module',
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'rolesMixin',
  'backboneRelational'
], function (
  Module,
  App,
  $,
  _,
  Backbone,
  Settings,
  RolesMixin
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  function createUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  var AttachmentModel = Backbone.Model.extend({
    defaults: {
      id: 0,
      downloadLink: null,
      fileLink: null,
      fileName: null
    }
  });

  /**
   * Model
   */
  var CAccount = Backbone.Model.extend({
    defaults: {
      vatNumber: undefined,
      name: undefined,
      zip: undefined,
      city: undefined,
      address: undefined,
      code: undefined,
      phone: undefined,
      contactEmail: undefined,
      forceInvoiceGeneration: false,
      country: undefined,
      activityArea: undefined
    },

    url: function (secId) {
      return Settings.url('compuzz2', 'v2/caccounts/' + secId);
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/caccounts', { createSupplierRoles: true });
    },

    postCAccount: function () {
      var url = Settings.url('compuzz2', 'sessions.json/token/byuser/' +
        this.get('secId'));

      return $.ajax({
        type: 'POST',
        url: url
      });
    },

    update: function () {
      this.url = this.postUrl();
      return this.save();
    },

    getAttachments: function (first, pageSize) {  
      var defer = $.Deferred();
     
      $.ajax({
        method: 'GET',
        url: Settings.url('compuzz2', 'attachments/', {
          caccountSecId: this.get('secId'),
          first: first,
          pageSize: pageSize
        }),
        contentType: 'application/json',
        success: function (items) {
          var attachments = [];
          _.each(items, _.bind(function (item) {
            attachments.push(new AttachmentModel(item));
          }, this));
          
          defer.resolve(attachments);
        }
      });
      return defer.promise();
    },

    uploadAttachment: function (file) {

      var formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);

      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'attachments/caccount/' + this.get('secId') + '/upload'),
        data: formData,
        processData: false,
        contentType: false,
        success: function (items) {
          if(items.length === 1)
            defer.resolve(new AttachmentModel(items[0]));
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    deleteAttachment: function (attachment) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz', 'attachments/caccount/' + this.get('secId') + '/' +  attachment.get('id')),
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    idAttribute: 'secId'
  });

  /**
   * Collection
   */
  var CAccountCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz2', 'v2/caccounts/');
    },
    model: CAccount,
    comparator: 'code'
  });

  var SupplierPrintFactors = Backbone.Model.extend({
    defaults: {
      factor: 1.12,
      factorSetup: 1.12,
      factorHandling: 1.12,
      defaultPrice: false
    },
    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/caccounts/printfactors' + (this.get('secId') ? '/' + this.get('secId') : ''));
      return Backbone.Model.prototype.destroy.call(this);
    },
    save: function () {
      this.url = Settings.url('compuzz2', 'v2/caccounts/printfactors');
      return Backbone.Model.prototype.save.call(this);
    },
    idAttribute: 'secId'
  });

  /**
   * Collection
   */
  var SupplierPrintFactorsCollection = Backbone.Collection.extend({
    model: SupplierPrintFactors,
    comparator: 'secId'
  });

  var SupplierFactors = Backbone.Model.extend({
    postUrl: function (secId) {
      return Settings.url('compuzz2', 'v2/caccounts/factors/' + secId);
    },
    save: function (secId) {
      this.url = this.postUrl(secId);
      return Backbone.Model.prototype.save.call(this);
    },
    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/caccounts/factors/' + this.get('secId') + '/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },
    idAttribute: 'secId'
  });

  app.ns.TransportItemPrice = Backbone.RelationalModel.extend({

    initialize: function () {
      this.set('id', createUUID());
    },

    defaults: {
      index: null,
      price: null
    }
  });

  app.ns.TransportItemPriceCollection = Backbone.Collection.extend({
    model: 'TransportPrice',
    comparator: 'index',

    addNew: function () {
      var newModel = new app.ns.TransportItemPrice();
      this.add(newModel);
      return newModel;
    },

    remove: function (item) {
      var r = Backbone.Collection.prototype.remove.call(this, item);
      //this.trigger('toSave');
      return r;
    }
  });

  app.ns.TransportItem = Backbone.RelationalModel.extend({
    initialize: function () {
      this.set('id', createUUID());
    },

    defaults: {
      mode: 'W',
      modeSample: null,
      samplePrice: null,
      prices: null
    },

    relations: [{
      type: Backbone.HasMany,
      key: 'prices',
      relatedModel: 'TransportItemPrice',
      collectionType: 'TransportItemPriceCollection',
      reverseRelation: {
        key: 'refTransportItem',
        includeInJSON: false
      }
    }]
  });


  app.ns.CountryTransport = Backbone.RelationalModel.extend({
    defaults: {
      smallOrderAmount: null,
      smallOrderFee: null,
      deliveryDays: null,
      transport: new app.ns.TransportItem(),
      expressTransport: new app.ns.TransportItem()
    },

    relations: [{
      type: Backbone.HasOne,
      key: 'transport',
      relatedModel: 'TransportItem'
    },
      {
        type: Backbone.HasOne,
        key: 'expressTransport',
        relatedModel: 'TransportItem'
      }]
  });

  app.ns.Transport = Backbone.RelationalModel.extend({

    initialize: function () {
      this.set('id', createUUID());
      this.set('countryTransports', this.get('countryTransports') || {});
      this.set('transport', this.get('transport') || new app.ns.TransportItem());
      this.set('expressTransport', this.get('expressTransport') || new app.ns.TransportItem());

      var countries = ['fr', 'be', 'at', 'de', 'en', 'it', 'es', 'dk', 'ua'];
      var countryTransports = this.get('countryTransports');
      var object = {};

      _.each(countries, function (key) {
        if (countryTransports.hasOwnProperty(key)) {
          object[key] = new app.ns.CountryTransport(countryTransports[key]);
        } else {
          object[key] = new app.ns.CountryTransport();
        }
      }, this);

      this.set('countryTransports', object);
    },
    defaults: {
      smallOrderAmount: null,
      smallOrderFee: null,
      deliveryDays: null,
      transport: null,
      expressTransport: null,
      countryTransports: null
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'transport',
      relatedModel: 'TransportItem'
    },
    {
      type: Backbone.HasOne,
      key: 'expressTransport',
      relatedModel: 'TransportItem'
    }],

    save: function (supplierSecId) {
      this.url = Settings.url('compuzz2', 'v2/caccounts/transport', {supplierSecId: supplierSecId});
      return Backbone.Model.prototype.save.call(this);
    }
  });

  /**
   * Collection
   */
  var SupplierFactorsCollection = Backbone.Collection.extend({
    model: SupplierFactors,
    comparator: 'idx'
  });

  /**
   * REST API
   */
  var API = {
    getCAccountEntities: function () {
      var cAccounts = new CAccountCollection({}),
        defer = $.Deferred();

      cAccounts.url = Settings.url('compuzz2', 'v2/caccounts/logged', {env: Settings.compuzzEnv});
      cAccounts.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCAccountCollection: function () {
      var cAccounts = new CAccountCollection({});

      cAccounts.url = Settings.url('compuzz2', 'v2/caccounts');

      return cAccounts;
    },

    getCAccountCollectionUrl: function () {
      return Settings.url('compuzz2', 'v2/caccounts');
    },

    getRowCountUrl: function () {
      return 'v2/caccounts/rowCount';
    },

    getCAccountModel: function (cAccountId) {
      var cAccount = new CAccount({
        address: null,
        city: null,
        code: null,
        colorSettings: null,
        contactEmail: null,
        country: null,
        displayPublicPictos: false,
        displayPublicPictures: false,
        encodedValue: null,
        exportUrl: null,
        extension: null,
        htmlServerUrl: null,
        forceInvoiceGeneration: false,
        language: null,
        logo: null,
        mailExpert: null,
        mailFrom: null,
        name: null,
        phone: null,
        refMainCAccountJson: null,
        returnUrl: null,
        secId: null,
        serverUrl: null,
        subDomain: null,
        zip: null,
        vatNumber: null
      });

      cAccount.url = cAccount.url(cAccountId);


      return cAccount;
    },

    getCountries: function () {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/countries');

      if (Settings.get('countries')) {
        defer.resolve(new Backbone.Collection(Settings.get('countries')));
      }

      $.ajax({
        url: url,
        success: function (data) {
          Settings.set('countries', data);
          defer.resolve(new Backbone.Collection(data));
        }
      });

      return defer.promise();
    },

    _getLanguages: function () {
      var cAccounts = new CAccount(),
        defer = $.Deferred();

      cAccounts.url = Settings.url('compuzz2', 'v2/languages');
      cAccounts.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getAvailableCAccounts: function () {
      var availableCAccounts = new CAccount({});

      availableCAccounts.url = Settings.url('compuzz2', 'sessions.json/token/byuser/caccounts');

      return availableCAccounts;
    },

    getMenuRoles: function () {
      var cAccounts = new CAccount(),
        defer = $.Deferred();

      cAccounts.url = Settings.url('compuzz2', 'v2/caccounts/roles');
      cAccounts.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    needToEncodeVat: function () {
      var url = Settings.url('compuzz2', 'v2/caccounts/needToEncodeVat'),
        defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    postCAccount: function (secId) {
      var url = Settings.url('compuzz2', 'sessions.json/token/byuser/' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    changeLanguageCAccount: function (secId, language) {
      var url = Settings.url('compuzz2', 'v2/caccounts/' + secId + '/changeLanguage', {newLanguage: language}),
        defer = $.Deferred();

      $.ajax({
        type: 'PUT',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    loginCAccount: function (data) {
      var params = {
          userpidSecId: data.userId,
          cAccountSecId: data.cAccountId
        },
        url = Settings.url('compuzz', 'v2/navigations/autologin', params),
        defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCAccountCount: function () {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'sessions.json/token/byuser/caccounts/rowcount', {env: Settings.compuzzEnv}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getCAccountWithDuplicateVat: function (secId) {
      var defer = $.Deferred();
      if (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
        $.ajax({
          url: Settings.url('compuzz2', 'v2/caccounts/' + secId + '/duplicate/vat'),
          success: function (resp) {
            _.each(resp, function (cAccount) {
              cAccount.logo = cAccount.logo ? Settings.url('file', cAccount.logo, {size: 'thumbnail'}) : Settings.get('notFoundImage');
            });
            defer.resolve(resp);
          }
        });
      } else {
        setTimeout(defer.resolve, []);
      }
      return defer.promise();
    },

    getCAccountWithDuplicateIP: function (secId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/' + secId + '/duplicate/ip'),
        success: function (resp) {
          _.each(resp, function (cAccount) {
            cAccount.logo = cAccount.logo ? Settings.url('file', cAccount.logo, {size: 'thumbnail'}) : Settings.get('notFoundImage');
          });
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getSupplierFactors: function (secId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/factors/' + secId),
        success: function (resp) {
          defer.resolve(new SupplierFactorsCollection(resp));
        }
      });

      return defer.promise();
    },

    getFactors: function (supplierSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/factors', {supplierSecId: supplierSecId}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getTransport: function (supplierSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/transport', {supplierSecId: supplierSecId}),
        success: function (resp) {
          if (resp) {
            defer.resolve(new app.ns.Transport(resp));
          } else {
            defer.resolve(new app.ns.Transport({transport: {prices: []}}));
          }
        }
      });

      return defer.promise();
    },

    getFactorsBySupplierUid: function (supplierCAccountUid) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/factors', {supplierCAccountUid: supplierCAccountUid}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getFactorsBySupplierId: function (supplierId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/factors', {supplierId: supplierId}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    updateFactors: function (factors, supplierSecId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'v2/caccounts/factors', {supplierSecId: supplierSecId}),
        data: JSON.stringify(factors),
        contentType: 'application/json',
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    deleteFactors: function (supplierSecId) {
      var defer = $.Deferred();
      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz2', 'v2/caccounts/factors', {supplierSecId: supplierSecId}),
        success: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    getSupplierPrintFactors: function (secId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/printfactors/' + secId),
        success: function (resp) {
          defer.resolve(new SupplierPrintFactorsCollection(resp));
        }
      });

      return defer.promise();
    },

    getSupplierPrintFactorsByCaId: function (caId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/printfactors/caid/' + caId),
        success: function (resp) {
          defer.resolve(new SupplierPrintFactorsCollection(resp));
        }
      });

      return defer.promise();
    },

    getUserTypes: function () {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/caccounts/usertypes'),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount:entities', function () {
    return API.getCAccountEntities();
  });

  App.reqres.setHandler('caccount:entity', function (cAccountId) {
    return API.getCAccountModel(cAccountId);
  });

  App.reqres.setHandler('caccount:countries', function () {
    return API.getCountries();
  });

  App.reqres.setHandler('caccount:languages', function () {
    return API._getLanguages();
  });

  App.reqres.setHandler('caccount:row-count-url', function () {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('caccount:get-collection', function () {
    return API.getCAccountCollection();
  });

  App.reqres.setHandler('caccount:get-collection-url', function () {
    return API.getCAccountCollectionUrl();
  });

  App.reqres.setHandler('caccount:get-available-caccounts', function () {
    return API.getAvailableCAccounts();
  });

  App.reqres.setHandler('caccount:get-menu-roles', function () {
    return API.getMenuRoles();
  });

  App.reqres.setHandler('caccount:need-to-encode-vat', function () {
    return API.needToEncodeVat();
  });

  App.reqres.setHandler('caccount:post-caccount', function (secId) {
    return API.postCAccount(secId);
  });

  App.reqres.setHandler('caccount:changeLanguage', function (secId, language) {
    return API.changeLanguageCAccount(secId, language);
  });

  App.reqres.setHandler('caccount:new-login', function (data) {
    return API.loginCAccount(data);
  });

  App.reqres.setHandler('caccount:count', function () {
    return API.getCAccountCount();
  });

  App.reqres.setHandler('caccount:model', function (caccountJSON) {
    return new CAccount(caccountJSON);
  });

  App.reqres.setHandler('caccount:duplicate:ip', function (secId) {
    return API.getCAccountWithDuplicateIP(secId);
  });

  App.reqres.setHandler('caccount:duplicate:vat', function (secId) {
    return API.getCAccountWithDuplicateVat(secId);
  });

  App.reqres.setHandler('caccount:get-factors', function (supplierSecId) {
    return API.getFactors(supplierSecId);
  });

  App.reqres.setHandler('caccount:get-transport', function (supplierSecId) {
    return API.getTransport(supplierSecId);
  });

  App.reqres.setHandler('caccount:get-factors-by-supplier-uid', function (supplierUid) {
    return API.getFactorsBySupplierUid(supplierUid);
  });

  App.reqres.setHandler('caccount:get-factors-by-supplier-id', function (supplierId) {
    return API.getFactorsBySupplierId(supplierId);
  });

  App.reqres.setHandler('caccount:update-factors', function (factors, supplierSecId) {
    return API.updateFactors(factors, supplierSecId);
  });

  App.reqres.setHandler('caccount:delete-factors', function (supplierSecId) {
    return API.deleteFactors(supplierSecId);
  });

  App.reqres.setHandler('caccount:get-supplier-factors', function (supplierSecId) {
    return API.getSupplierFactors(supplierSecId);
  });

  App.reqres.setHandler('caccount:get-supplier-print-factors', function (supplierSecId) {
    return API.getSupplierPrintFactors(supplierSecId);
  });

  App.reqres.setHandler('caccount:get-supplier-print-factors-ca-id', function (supplierId) {
    return API.getSupplierPrintFactorsByCaId(supplierId);
  });

  App.reqres.setHandler('caccount:get-user-types', function (secId) {
    return API.getUserTypes(secId);
  });

  Module.exports = {

  };
});
