define('caccountDetailsTabMonthView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'chartView'
], function (
  module,
  Marionette,
  _,
  App,
  ChartView
) {
  'use strict';

  var CAccountDetailsTabMonthView =  ChartView.extend({
    serializeData: function() {
      var month = this.options.model.get('month');
      this.data = [];
      if ( !_.isUndefined(month) && !_.isEmpty(month)) {
        this.data =
        [
          {
            value: month.done,
            color:'#00bea4',
            highlight: '#0DC7AE',
            label: ''
          },
          {
            value: month.inProgress,
            color:'#f47b00',
            highlight: '#FB8915',
            label: ''
          }
        ];
      }
      return this.data;
    }
  });

  module.exports = CAccountDetailsTabMonthView;
});
