/**
 * Created by BFR on 24/02/2020.
 */
define('editPimOptionView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!editPimOptionTpl',
  'pimUtil',
  'colorUtils',
  'dynamicTextView'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  PimUtil,
  ColorUtils,
  DynamicTextView
) {
  'use strict';

  var EditPimOptionView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'option',

    regions: {
      nameRegion: '.opt-name-region'
    },

    events: {
      'change .cbx-enable-opt': 'onCbxEnableOptChange'
    },

    onShow: function () {
      if (this.model.get('name').get('glossaryDt')) {
        this.nameDtView = new DynamicTextView({
          model: this.model.get('name').get('glossaryDt'),
          hideGlossary: true,
          showWebIcon: true
        });
        this.getRegion('nameRegion').show(this.nameDtView);
      }

      _.each(this.$el.find('.image'), _.bind(function (image) {
        var content = '<div class="img-responsive"><img class="img-popover" src="' + $(image).find('img').attr('src') + '"/></div>';

        var target = $(image).find('img');

        target.attr('data-content', content);
        target.popover({
          trigger: 'hover',
          placement: 'left',
          html: 'true',
          container: $('#content')
        }).data('bs.popover').tip().addClass('jqgrid-popover');
      }, this));
    },

    onDestroy: function () {
      $('.jqgrid-popover').remove();
    },

    serializeData: function () {
      var templateData = {};

      templateData.code = this.model.get('code');

      // On/Off true by default, unless specified otherwise in resellerData.
      templateData.activated = true;
      try {
        var currentModel = this.options.model;
        // If reseller data has an overridden value, use that.
        if (this.options.pimProduct && this.options.pimProduct.get('resellerData')) {
          _.each(this.options.pimProduct.get('resellerData').models, _.bind(
                  function (rd) {
                    if (rd && rd.get('pimPoResellers')) {
                      _.each(rd.get('pimPoResellers').models, _.bind(
                          function (poc) {
                            if (poc && poc.get('productOptions')) {
                              _.each(poc.get('productOptions').models, _.bind(
                                  function (po) {
                                    // Apply the activated value from the reseller data if the pimId is matching
                                    if (po && po.get('pimId') === currentModel.get('pimId')) {
                                      if (po.get('activated') !== undefined) {
                                        // Provide the template
                                        templateData.activated = po.get('activated');

                                        // Update the model with the activated value in order to save it to the backend, if sent
                                        _.each(currentModel.collection.models, _.bind(function (oc) {
                                          // Find the matching model by pimId
                                          if(currentModel.get('pimId') === oc.get('pimId')) {
                                            oc.set('activated', po.get('activated'));
                                          }
                                        }, this));

                                      }
                                    }
                                  }
                              ));
                            }
                          }
                      ));
                    }
                  }
              )
          );
        }
      } catch (e) {
          console.log('Error in EditPimOptionView. Could not apply any override from pimPoResellers', e);
      }
      var aNames = this.model.get('name').get('values');

      templateData.name = PimUtil.getTranslation(aNames);

      // This option comes from EditPimOptionsView
      templateData.enableOnOff = this.options.enableOnOff;

      templateData.color = '';

      var color1 = this.model.get('color1');

      if (color1) {
        templateData.color = ColorUtils.decimalToHexa(color1['int']);
      }

      var aImages = [];

      if (this.model.get('picture1') !== '')
        aImages.push(this.model.get('picture1'));

      if (this.model.get('picture2') !== '')
        aImages.push(this.model.get('picture2'));

      if (this.model.get('picture3') !== '')
        aImages.push(this.model.get('picture3'));

      if (this.model.get('picture4') !== '')
        aImages.push(this.model.get('picture4'));

      _.each(this.model.get('addImages'), _.bind(function (image) {
        aImages.push(image.url);
      }, this));

      templateData.images = aImages;

      templateData.allowUpload = false;

      var currentResProduct = this.options.pimProduct ? this.options.pimProduct.getCurrentResProduct() : null;

      if (currentResProduct) {
        templateData.allowUpload = true;
        templateData.defaultIndexPicture = currentResProduct.get('defaultIndexPicture');
      } else {
        templateData.defaultIndexPicture = null;
      }

      return templateData;
    },

    onCbxEnableOptChange: function (event) {
      var cbx = $(event.target), currentOc;
      _.each(this.options.model.collection.models, _.bind(function (oc) {
        // Find the matching model by pimId
        if(this.options.model.get('pimId') === oc.get('pimId')) {
          currentOc = oc;
        }
      }, this));

      if(currentOc){
        if (cbx.is(':checked')) {
          currentOc.set('activated', true);
        } else {
          currentOc.set('activated', false);
        }
      }
    }
  });

  module.exports = EditPimOptionView;
});
