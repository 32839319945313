
define('template!productCustomFieldsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="row">\n        <div class="col-md-12 col-lg-6 p-t-10">\n            ';
 if (display) { ;
__p += '\n            <div class="col-xs-2 text-right">\n                <h2>' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</h2></div>\n            <div class="col-xs-5">\n                <h2>' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</h2></div>\n            <div class="col-xs-5">\n                <h2>' +
((__t = ( _.i18n('common.value') )) == null ? '' : __t) +
'</h2></div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    <div class="row">\n        <div class="name-region col-md-12 col-lg-6"></div>\n    </div>\n</div>\n\n';

}
return __p
};});

