define('pimProductSelectedAttributeValuesView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'pimProductSelectedAttributeValueView'
], function(
  module,           
  Marionette,
  App,
  _,
  PimProductSelectedAttributeValueView
){
  'use strict';

  var PimProductSelectedAttributeValuesView = Marionette.CollectionView.extend({   
    childView: PimProductSelectedAttributeValueView,
    className: 'selected-attribute-values-view',
    
    onShow: function () {
    
    }
  });

  module.exports = PimProductSelectedAttributeValuesView;
});
