define('selectView',[
  'module',
  'marionette',
  'underscore',
  'selectOptionView'
], function (
  Module,
  Marionette,
  _,
  SelectOptionView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'select-view',
    tagName: 'select',
    childView: SelectOptionView,

    attributes: function () {
      return {
        'tabindex': this.options.tabindex || 0,
        'disabled': this.options.allowFromSelection === false ? true : false
      };
    },

    events: {
      'change': 'onChange'
    },

    initialize: function() {
      if(this.collection.models.length > 0)
        this.collection.models[0].set('selected', true);
    },

    setSelected: function(id) {
      this.$el.val(id);

      _.each(this.collection.models, function(model) {
        if(model.get('id') === id)
          model.set('selected', true);
        else
          model.set('selected', false);
      });
    },

    getSelected: function() {
      var selectdeModel = null;
      _.each(this.collection.models, function(model) {
        if(model.get('selected'))
          selectdeModel = model;
      });

      if(!selectdeModel)
        selectdeModel = this.collection.models[0];

      return selectdeModel;
    },

    onChange: function() {
      _.each(this.collection.models, _.bind(function(model) {
        if(model.get('id').toString() === this.$el.val())
          model.set('selected', true);
        else
          model.set('selected', false);
      }, this));

      this.trigger('change', this.getSelected());
    }
  });
});
  
