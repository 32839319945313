/**
 * Created by BFR on 13/03/2020.
 */
define('productsSupplierListController',[
  'module',
  'logger',
  'underscore',
  'app',
  'productsSupplierListView',
  'entities/product',
  'entities/caccounts/share'
], function (
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var ProductsController = {
    showProducts: function () {
      var view = new View();
      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    onGridLoaded: function () {
      App.request('caccounts:get-share-from-sorted').done(_.bind(function (list) {
        var selectDefault = this.view.selectDefault,
          gridStatuses = selectDefault + ':' + selectDefault;

        _.each(list, function (status) {
          gridStatuses += ';' + status.caccountFrom.code + ':' +
            status.caccountFrom.code;
        });
        var data = {
          url: App.request('products-supplier:get-collection-url'),
          rowCountUrl: App.request('products-supplier:row-count-url'),
          manufacturers: gridStatuses
        };

        this.view.displayGrid(data, true);
      }, this));
    }
  };

  module.exports = ProductsController;
});
