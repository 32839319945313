define('checkboxView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'template!checkboxView'
], function (
  Module,
  Marionette,
  _,
  Events,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'checkbox-view',

    attributes: function () {
      return {
        'title': this.options.title || '',
        'tabindex': this.options.tabindex || 0
      };
    },

    ui: {
      checked: '.checked',
      unchecked: '.unchecked',
      loading: '.loader-2'
    },

    initialize: function (options) {
      this.value = options.value;
      this.focused = false;
    },

    onDestroy: function() {
      Events.pop(this);
    },

    events: {
      'click': 'onClick',
      'focus': 'onFocus',
      'blur': 'onBlur'
    },

    serializeData: function () {
      var data = {};
      data.text = '';
      if(this.options.text)
        data.text = this.options.text;

      return data;
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      this.ui.loading.hide();

      if(this.value) {
        this.ui.checked.css('display', 'flex');
        this.ui.unchecked.hide();
      } else {
        this.ui.checked.hide();
        this.ui.unchecked.css('display', 'flex');
      }
    },

    onClick: function(e) {
      e.stopPropagation();

      if(this.options.onChange) {
        this.ui.checked.hide();
        this.ui.unchecked.hide();
        this.ui.loading.css('display', 'flex');
        this.options.onChange(!this.value).done(_.bind(function () {
          this.renderContent();
        }, this)).fail(_.bind(function () {
          this.renderContent();
        }, this));
      } else {
        this.value = !this.value;
        this.renderContent();

        this.trigger('change', this.value);
      }
    },

    getValue: function () {
      return this.value;
    },

    setValue: function (value) {
      this.value = value;
      this.renderContent();
    },

    onFocus: function() {
      Events.push(this);
      this.focused = true;
    },

    onBlur: function() {
      Events.pop(this);
      this.focused = false;
    },

    onKeyDown: function(e) {
      if (e.which === 32 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        this.onClick(e);
        return false;
      }
    }
  });
});
  
