define('pimProductAttributeValuesView',[
  'module',        
  'marionette',
  'app',
  'underscore',
  'pimProductAttributeValueView'
], function(
  module,           
  Marionette,
  App,
  _,
  PimProductAttributeValueView
){
  'use strict';

  var PimProductAttributeValuesView = Marionette.CollectionView.extend({   
    childView: PimProductAttributeValueView,
    className: 'list',
    childViewOptions: function() {     
      return {
        attributeModel: this.options.attributeModel,
        productAttribute: this.options.productAttribute
      };
    },
    onRender: function () {
      /*if(this.options.viewModel.get('currentSecId') !== '') {
        this.collection.fetch(this.options.viewModel.get('currentSecId'));
      }*/
    }
  });

  module.exports = PimProductAttributeValuesView;
});
