
define('template!editPimPrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="' +
((__t = ( showFastDelivery?'printzone-grid-production-time':'printzone-grid' )) == null ? '' : __t) +
'">\n  ';
 if (showFastDelivery) { ;
__p += '\n  <div title="' +
((__t = ( productionTimes )) == null ? '' : __t) +
'">\n    ';
 if (productionTimes) { ;
__p += '\n      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>\n    ';
 } ;
__p += '\n  </div>\n  ';
 } ;
__p += '\n  <div class="imppCode">' +
((__t = ( imppCode )) == null ? '' : __t) +
'</div>\n  <div class="name">' +
((__t = ( name )) == null ? '' : __t);
 if (maxColors) {
    ;
__p += ' (' +
((__t = ( maxColors )) == null ? '' : __t) +
' C) ';
 } else if (digital) { ;
__p += ' (DIG) ';
 } else if (eng) { ;
__p += ' (ENG) ';
 } ;
__p += '\n    <div class="glossary-dt-value pz-name-region"></div>\n  </div>\n  <div class="size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n  <div class="setupCode">' +
((__t = ( setupCode )) == null ? '' : __t) +
'</div>\n  <div class="unitCode">' +
((__t = ( unitCode )) == null ? '' : __t) +
'</div>\n  ';

  var css = '';
  if(allowUpload) {
  css = 'context-menu-pz';
  }
  ;
__p += '\n  <div class="image">\n    ';
 if(imageUrl != '') { ;
__p += '\n    <img data-link="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'" class="jq-picture js-image-cell ' +
((__t = ( css )) == null ? '' : __t) +
'" src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
};});

