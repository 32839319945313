define('phoneCallsView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'tableView',
  'moment',
  'settings',
  'phoneCallsRowToolsView',
  'tableDatetimeFieldView',
  'phoneCallsTypeView',
  'phoneCallsCustomerNumberView',
  'phoneCallsFiltersView',
  'entities/phone/calls'
], function (
  Module,
  _,
  App,
  Backbone,
  TableView,
  moment,
  Settings,
  RowToolsView,
  DatetimeFieldView,
  PhoneCallsTypeView,
  PhoneCallsCustomerNumberView,
  FiltersView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'phone-calls-view table-view ' + TableView.prototype.className,
    defaultSortField: 'Date',
    defaultSortDirection: 'desc',
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    setFields: function() {
      return [{
        name: 'Type',
        className: 'type',
        view: PhoneCallsTypeView
      }, {
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'callDate',
        className: 'date',
        view: DatetimeFieldView
      }, {
        name: 'Duration',
        label: _.i18n('calls.agentInteractionDuration'),
        className: 'duration',
        onRender: function(view) {
          var html = '';
          if(view.model.get('agentInteractionDuration')) {
            html = moment().startOf('day')
              .seconds(view.model.get('agentInteractionDuration'))
              .format('HH:mm:ss');
          }
          view.$el.html(html);
        }
      }, {
        name: 'User',
        label: _.i18n('common.user'),
        className: 'user',
        onRender: function(view) {
          var html = '';
          if(view.model.get('refUserpid')) {
            if(view.model.get('refUserpid').logonId)
              html = view.model.get('refUserpid').logonId;
          }
          view.$el.html(html);
        }
      }, {
        name: 'Customer',
        label: _.i18n('common.endUser'),
        className: 'customer',
        onRender: function(view) {
          var html = '';
          if(view.model.get('refEndUser')) {
            if(view.model.get('refEndUser').code)
              html = view.model.get('refEndUser').code;
          }
          view.$el.html(html);
        }
      }, {
        name: 'Contact',
        label: _.i18n('common.contact'),
        className: 'contact',
        onRender: function(view) {
          var html = '';
          if(view.model.get('refEndUserContact')) {
            if(view.model.get('refEndUserContact').email)
              html = view.model.get('refEndUserContact').email;
          }
          view.$el.html(html);
        }
      }, {
        name: 'UserPhoneNumber',
        label: _.i18n('calls.accountPhoneNumber'),
        bind: 'accountPhoneNumber',
        className: 'user-phone-number'
      }, {
        name: 'CustomerPhoneNumber',
        label: _.i18n('calls.customerPhoneNumber'),
        view: PhoneCallsCustomerNumberView,
        className: 'customer-phone-number'
      }, {
        name: 'Buttons',
        className: 'buttons',
        view: RowToolsView
      }];
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.pageSize = pageSize;
      params.first = first;
      params._search = 'false';
      params.rows = 'pageSize';

      if (sortField !== '') {
        var sortParamName = '';
        if (sortField === 'Date')
          sortParamName = 'userTimestamp.creatDate';

        if (sortParamName !== '') {
          params.sidx = sortParamName;
          params.sord = sortDirection;
        }
      }

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('calls:get-collection-url', params),
        success: _.bind(function (items) {
          var models = [];

          _.each(items, _.bind(function(item) {
            var model = new Backbone.Model(item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('calls:row-count-url', this.getFiltersParams(filters)),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(key !== 'dateFrom' && key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    }
  });
});
