/**
 * Created by ASK on 23/11/2022.
 */
define('customerUtils',[
  'module',
  'underscore',
  'app',
  'settings',
  'jquery'
], function (
  module,
  _,
  App,  
  Settings, 
  $
) {
  'use strict';

  module.exports = {

    checkVat: function (value) {  

      var defer = $.Deferred();

      if (!value) {
        defer.resolve(false);  
        return defer.promise();
      }
        

      if (!value.match('^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$')) {
        defer.resolve(false);   
      } else {
        var url = Settings.url('compuzz2', 'v2/vatservice/checkVatNumber?countryCode=' +
          value.substr(0, 2) +
          '&VATNumber=' + value.substr(2, value.length));

        $.ajax({
          type: 'GET',
          url: url,
          success: function (resp) {
            if (resp === 'false')
              defer.resolve(false);        
            else
              defer.resolve(true, resp);            
          },
          error: function () {
            defer.reject();
          }
        });
      }

      return defer.promise();
    }
  };
});
